import React, { useState, useEffect } from "react";
import { Icon } from "tabler-react";
import { Crud_Catalogos } from '../../../herramientas/Crud_Catalogos';
import Select from "react-select";
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
const Softphone =({ stor, elementos })=> {
  const [grupos, setGrupos]= useState([]);
  const [grupo, setGrupo]= useState({value: 0, label: "Selecciona un grupo", data: {}});
  const [configuracion, setConfiguracion]= useState(
    {//id
      grupo: "",//min = 3, max = 300
      extension: "",//min = 3, max = 300
      username: "",//min = 3, max = 100
      password: "",
      descripcion: "",//min = 3, max = 300
      estatus: false
    }
  );
  const [agente, setAgente]= useState(
    {
      name: "",
      lastname: "",
      username: "",
      password: "",
      email: "",
      role: "AGENTE",
      grupo: 0,
      recording: 1
    }
  );
  
  const [editar, setEditar]= useState(false);
  
  useEffect(() => {
    getfuntion("get", "", "", "softphone/grupos","grupos");    
  }, []);

  useEffect(() => {
    if(grupos.length){
      getfuntion("get", "", "", "softphone/"+elementos.id,"configuracion");
    }
  }, [grupos]);

  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "usuario", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if( stateVar === "grupos"){
              if( returnVal.data && returnVal.data.length ){
                let gruposTemp= [{value: 0, label: "Selecciona un grupo", data: {}}];
                for(let g of returnVal.data){
                  gruposTemp.push({value: g.id, label: g.name, data: g});
                }
                setGrupos(gruposTemp);                
              }/*
              {
                "message": "Extensiones obtenidas correctamente",
                "data": [
                  {
                    "id": 1,
                    "name": "CENTRAL_DHL",
                    "extension": "6001",
                    "musiconhold": null,
                    "description": "www",
                    "estatus": 1,
                    "created_at": "2024-03-11T02:22:18.000000Z",
                    "updated_at": "2024-03-11T02:22:18.000000Z"
                  },
                ]
              }*/
            }
            if( stateVar === "configuracion"){
              if(returnVal.id){
                for(let g of grupos){
                  if( g.value === returnVal.grupo)
                    setGrupo(g);
                }
                setConfiguracion(returnVal);
                setAgente(
                  {
                    name: elementos.persona.nombre,
                    lastname: elementos.persona.aPaterno+ " "+ elementos.persona.aMaterno,
                    username: returnVal.username,
                    password: "",
                    email: elementos.persona.correo,
                    role: "AGENTE",
                    grupo: 0,
                    recording: 1
                  }
                );
                setEditar(false);
              }else{
                setConfiguracion(
                  {//id
                    grupo: "",//min = 3, max = 300
                    extension: "",//min = 3, max = 300
                    username: cleanUsername(elementos.username),//min = 3, max = 100
                    password: "",
                    descripcion: "",//min = 3, max = 300
                    estatus: false
                  }
                );
                setAgente(
                  {
                    name: elementos.persona.nombre,
                    lastname: elementos.persona.aPaterno+ " "+ elementos.persona.aMaterno,
                    username: cleanUsername(elementos.username),
                    password: "",
                    email: elementos.persona.correo,
                    role: "AGENTE",
                    grupo: 0,
                    recording: 1,
                    predictive: 0
                  }
                );
                setEditar(true);
              }
            }
            break;
          case "post":
            /*
            if( stateVar === "crear_agente" ){
              let configuracionTemp= {...configuracion};
              configuracionTemp.id= elementos.id;
              getfuntion("post", configuracionTemp, "", "softphone", "configuracion"); 
            }*/
            if (stateVar === "crear_agente"){
              if(returnVal.status === 200 ){
                setConfiguracion(returnVal.data);
                NotificationManager.success("Guardado correctamente", "Nuevo");
                setEditar(false);
              }else{
                NotificationManager.error(returnVal.message, "Nuevo");
              }
            }
            break;
          case "put":
            if (stateVar === "configuracion"){
              if(returnVal.id){
                setConfiguracion(returnVal);
                NotificationManager.success(
                  <div>Guardado correctamente<button className="btn btn-primary" onClick={()=>{ alert("Holamundo")}} >
                    <Icon name="x"/> Guardado correctamente</button>
                  </div>,
                  "Actualizar",
                  (1000*20));
                setEditar(false);
              }else{
                NotificationManager.error("Error al actualizar configuración", "Actualizar");
              }
            }
            break;
          case "delete":
            if( stateVar === "configuracion" && stateVar){
              NotificationManager.success("Informacion receteada", "Remover");
              getfuntion("get", "", "", "softphone/"+elementos.id,"configuracion");
              setEditar(true);
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        console.log(err);
    })
  }
  const cleanUsername =(u)=>{//Solo minusculas y solo se adminte .
    const patt = new RegExp(/^[A-Za-z0-9.\s]+$/g);
    let usernameTemp= u;
    usernameTemp= usernameTemp.toLowerCase();
    usernameTemp= usernameTemp.replace(/\s/g, '');
    usernameTemp= usernameTemp.replace(/[^a-zA-Z0-9 ]/g, '.');
    /*
    for(let i=0; i< usernameTemp.length; i++){
      if( !patt.test(usernameTemp.charAt(i)) ){console.log("::> "+usernameTemp.charAt(i));
        usernameTemp= usernameTemp.replace(usernameTemp.charAt(i), "");
        i--;
      }
    }*/    
    return usernameTemp;
  }
  return (
    <div>
      <div>
        <div className="row" style={{height: "100%"}}>
          <div className="row">
            <div className="col-12">
              <h4>Configuración de Softphone</h4>
              <p>Esta informacion enlazara a su teléfono de Acv</p>              
              <form>
                <div className="form-group">
                  <label for="grupo">Grupo</label>
                  <Select value={grupo}
                    options={grupos}
                    onChange={(e)=>{
                      setConfiguracion({...configuracion, grupo: e.data.extension });
                      setAgente({...agente, grupo: e.value});
                      setGrupo(e);                      
                      }}
                      isDisabled={configuracion.id}
                  />
                  <small id="grupoHelp" className="form-text text-muted">{grupo.data.extension }. Es grupo que se usa para comunicarse hacia la Central</small>
                  {configuracion.extension? "Su extensión es: "+(configuracion.extension): "" }
                </div>{/*
                <div className="form-group">
                  <label for="grupo">Extensión del grupo</label>
                  <input type="text" className="form-control" id="grupo"
                    placeholder="0000" disabled={ true }
                    value={configuracion.grupo? configuracion.grupo: "" }
                    onChange={(e)=>{ setConfiguracion({...configuracion, grupo: e.target.value }); }}
                  />
                  <small id="grupoHelp" className="form-text text-muted">Es la extensión que se usa para comunicarse hacia la Central</small>
                </div>*/}
                {/*}
                <div className="form-group">
                  <label for="extension">Extensión del teléfono</label>
                  <input type="text" className="form-control" id="extension"
                    placeholder="0000" disabled={ configuracion.id }
                    value={configuracion.extension? configuracion.extension: "" }
                    onChange={(e)=>{ setConfiguracion({...configuracion, extension: e.target.value }); }}
                  />
                  <small id="extensionHelp" className="form-text text-muted">Es la extensión del teléfono</small>
                </div>*/}
                <div className="form-group">
                  <label for="username">Username</label>
                  <input type="text" className="form-control" id="username"
                    placeholder="user" disabled={ configuracion.id }
                    value={configuracion.username? configuracion.username: "" }
                    autocomplete="off"
                    onChange={(e)=>{
                      setConfiguracion({...configuracion, username: cleanUsername(e.target.value) });
                      setAgente({...agente, username: cleanUsername(e.target.value) });
                    }}
                  />
                  <small id="usernameHelp" className="form-text text-muted">Es el nombre de usuario para ingresar al sistema</small>
                </div>
                <div className="form-group">
                  <label for="password">Password</label>
                  <input type="password" className="form-control" id="password"
                    placeholder="0000" disabled={ configuracion.id }
                    value={configuracion.password? configuracion.password: "" }
                    autocomplete="off"
                    onChange={(e)=>{
                      setConfiguracion({...configuracion, password: e.target.value });
                      setAgente({...agente, password: e.target.value});
                    }}
                  />
                  <small id="passwordHelp" className="form-text text-muted">Es el contraseña de usuario para ingresar al sistema</small>
                </div>
                <div className="form-group">
                  <label for="descripcion">Descripción</label>
                  <input type="text" className="form-control" id="descripcion"
                    placeholder="Ingresa descripción para la extensión" disabled={ !editar }
                    value={ configuracion.descripcion? configuracion.descripcion: "" }
                    onChange={(e)=>{ setConfiguracion({...configuracion, descripcion: e.target.value }); }}
                  />
                  <small id="descripcionHelp" className="form-text text-muted">Descripción(podrias ingresar el cargo)</small>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="estatus" disabled={ !editar }
                    checked={ configuracion.estatus }
                    onChange={(e)=> setConfiguracion({ ...configuracion, estatus: e.target.checked }) }
                    />
                  <label class="form-check-label" for="exampleCheck1">Estatus</label>
                </div>
              </form>
              <br />
              <button
                className={"btn btn-"+(configuracion.id && !editar ? "primary": "danger")+" mr-3"}
                onClick={()=>{
                  setEditar(!editar);
                  if(editar)
                    getfuntion("get", "", "", "softphone/"+elementos.id,"configuracion");
                }}
              >
                  <Icon name={configuracion.id && !editar ? "edit": "x"}/> {configuracion.id && !editar ? "Editar": "Cancelar"}
              </button>
              <button
                className="btn btn-primary"
                onClick={()=>{
                  if(configuracion.id){
                    getfuntion("put", configuracion, "", "softphone", "configuracion");
                  }else{
                    getfuntion("post", {...agente}, "", "softphone/crear_agente/"+ elementos.id, "crear_agente");                   
                  }
                }}>
                  <Icon name="loader"/> Guardar
              </button>
            </div>
          </div>
        </div>
      </div>      
    </div>
  )
}

export default Softphone
