import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import Select from "react-select";
import Chart from "react-apexcharts";
import { Form} from "tabler-react";
import { TablePageable } from "../../Helpers";
const GpsDash = () => {
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais, hardware, desechable, tecnologia
  const [gpsList, setGpsList] = useState([]);
  const [gpsListFiltrados, setGpsListFiltrados] = useState([]);
  const [gpsEstatusOpciones, setGpsEstatusOpciones]= useState([]);
  const [gpsEstatusOpcion, setGpsEstatusOpcion] = useState({ value: 0, label: "Todos" });
  const [plataformasOpciones, setPlataformasOpciones]= useState([]);
  const [plataformasOpcion, setPlataformasOpcion] = useState({ value: 0, label: "Todos" });
  const [corredoresOpciones, setCorredoresOpciones]= useState([]);
  const [corredoresOpcion, setCorredoresOpcion] = useState({ value: 0, label: "Todos" });
  const [transportistasOpciones, setTransportistasOpciones]= useState([]);
  const [transportistasOpcion, setTransportistasOpcion] = useState({ value: 0, label: "Todos" });
  const [clientesOpciones, setClientesOpciones]= useState([]);
  const [clientesOpcion, setClientesOpcion] = useState({ value: 0, label: "Todos" });
  const [paisOpciones, setPaisOpciones]= useState([]);
  const [paisOpcion, setPaisOpcion] = useState({ value: 0, label: "Todos" });
  const [hardwareOpciones, setHardwareOpciones]= useState([]);
  const [hardwareOpcion, setHardwareOpcion] = useState({ value: 0, label: "Todos" });
  const [desechable, setDesechable] = useState(false);
  const [tecnologiaOpciones, setTecnologiaOpciones]= useState([]);
  const [tecnologiaOpcion, setTecnologiaOpcion] = useState({ value: 0, label: "Todos" });
  //Se realiza la consulta la primera vez.
  useEffect(() => {
    getfuntion("get", [], "", "gps/dash", "dash");
  }, []);

  useEffect(() => {
    filtroDeFiltros();
    filtro();
  }, 
    [ gpsEstatusOpcion,
      plataformasOpcion,
      corredoresOpcion,
      transportistasOpcion,
      clientesOpcion,
      paisOpcion,
      hardwareOpcion,
      desechable,
      tecnologiaOpcion,
    ]);

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",

    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "home",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "dash") {
                if (returnVal) {
                  let gpsRetunTemp= [];
                  for( let g of returnVal ){
                    if( !g.plataforma )
                      g.plataforma= "Sin servicio asignado"
                    if( !g.pais )
                      g.pais= "Sin servicio asignado"
                    if( !g.corredor )
                      g.corredor= "Sin servicio asignado"
                    if( !g.cliente )
                      g.cliente= "Sin servicio asignado"
                    if( !g.transportista )
                      g.transportista= "Sin servicio asignado"
                    gpsRetunTemp.push(g);
                  }
                  setGpsList(returnVal);
                  filtroDeFiltros(returnVal);
                  filtro(returnVal);
                  /*
                  let gpsEstatusTemp = {};
                  returnVal.map((gps, index) => {
                    if (!gpsEstatusTemp[gps["gpsEstatus"]]) {
                      gpsEstatusTemp[gps["gpsEstatus"]] = [];
                    }
                    gpsEstatusTemp[gps["gpsEstatus"]].push({
                      idGps: gps["idGps"],
                      color: gps["colorEstatus"],
                    });
                  });
                  */
                  /*
                  let gpsEstatusOpcionesTep = [];
                  Object.keys(gpsEstatusTemp).forEach((key, index) =>{
                    gpsEstatusOpcionesTep.push({value: key, label: key});
                  });
                  setGpsEstatusOpciones(gpsEstatusOpcionesTep);
                  setGpss(returnVal); */
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };
  const filtroDeFiltros=(dataGpsLisTemp)=>{
    let gpssTemp= dataGpsLisTemp? [...dataGpsLisTemp]: [...gpsList];
    let gpsEstatus = {};
    let plataformas = {};
    let pais = {};
    let corredores = {};
    let clientes = {};
    let transportistas = {};
    let hardware= {};
    let tecnologia= {};

    gpssTemp= gpssTemp.filter(g=> g.desechable === null && !desechable ? true: g.desechable === desechable );

    if( gpsEstatusOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.gpsEstatus === gpsEstatusOpcion.label );
    if( plataformasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.plataforma === plataformasOpcion.label );
    if( paisOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.pais === paisOpcion.label );
    if( corredoresOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.corredor === corredoresOpcion.label );
    if( clientesOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.cliente === clientesOpcion.label );
    if( transportistasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.transportista === transportistasOpcion.label );
    if( hardwareOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.hardware === hardwareOpcion.label );
    if( tecnologiaOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.tecnologia === tecnologiaOpcion.label );
    
    gpssTemp.map((gps, index) => {
      //|gps_estatus
      if (!gpsEstatus[gps["gpsEstatus"]]) gpsEstatus[gps["gpsEstatus"]] = [];
        gpsEstatus[gps["gpsEstatus"]].push(gps);

      //plataforma
      if (!plataformas[gps["plataforma"]]) plataformas[gps["plataforma"]] = [];
        plataformas[gps["plataforma"]].push(gps);

      //pais
      if (!pais[gps["pais"]]) pais[gps["pais"]] = [];
        pais[gps["pais"]].push(gps);

      // corredor
      if (!corredores[gps["corredor"]]) corredores[gps["corredor"]] = [];
        corredores[gps["corredor"]].push(gps);

      //transportista
      if (!transportistas[gps["transportista"]]) transportistas[gps["transportista"]] = [];
        transportistas[gps["transportista"]].push(gps);

      //cliente
      if (!clientes[gps["cliente"]]) clientes[gps["cliente"]] = [];
        clientes[gps["cliente"]].push(gps);
      //hardware
      if (!hardware[gps["hardware"]]) hardware[gps["hardware"]] = [];
      hardware[gps["hardware"]].push(gps);
      //tecnologia
      if (!tecnologia[gps["tecnologia"]]) tecnologia[gps["tecnologia"]] = [];
      tecnologia[gps["tecnologia"]].push(gps);
    });

    //creando headers
    let gpsEstatusOpcionesTemp = [];
    let plataformasOpcionesTemp = [];
    let paisOpcionesTemp = [];
    let corredoresOpcionesTemp = [];
    let transportistasOpcionesTemp = [];
    let clientesOpcionesTemp = [];
    let hardwareOpcionesTemp = [];
    let tecnologiaOpcionesTemp = [];
    Object.keys(gpsEstatus)     .forEach((key) => gpsEstatusOpcionesTemp.push({ value: key, label: key}));
    Object.keys(plataformas)    .forEach((key) => plataformasOpcionesTemp.push({ value: key, label: key}));
    Object.keys(pais)           .forEach((key) => paisOpcionesTemp.push({ value: key, label: key}));
    Object.keys(corredores)     .forEach((key) => corredoresOpcionesTemp.push({ value: key, label: key}));
    Object.keys(transportistas) .forEach((key) => transportistasOpcionesTemp.push({ value: key, label: key}));
    Object.keys(clientes)       .forEach((key) =>  clientesOpcionesTemp.push({ value: key, label: key}));
    Object.keys(hardware)       .forEach((key) => hardwareOpcionesTemp.push({ value: key, label: key}));
    Object.keys(tecnologia)           .forEach((key) => tecnologiaOpcionesTemp.push({ value: key, label: key}));

    setGpsEstatusOpciones(gpsEstatusOpcionesTemp);
    setPlataformasOpciones(plataformasOpcionesTemp);
    setPaisOpciones(paisOpcionesTemp);
    setCorredoresOpciones(corredoresOpcionesTemp);
    setTransportistasOpciones(transportistasOpcionesTemp);
    setClientesOpciones(clientesOpcionesTemp);
    setHardwareOpciones(hardwareOpcionesTemp);
    setTecnologiaOpciones(tecnologiaOpcionesTemp);
  }
  const filtro=(dataGpsLisTemp)=>{
    let gpssTemp= dataGpsLisTemp? [...dataGpsLisTemp] : [...gpsList];
    gpssTemp= gpssTemp.filter(g=> g.desechable === null && !desechable ? true: g.desechable === desechable );
    if( gpsEstatusOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.gpsEstatus === gpsEstatusOpcion.label );
    if( plataformasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.plataforma === plataformasOpcion.label );
    if( paisOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.pais === paisOpcion.label );
    if( corredoresOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.corredor === corredoresOpcion.label );
    if( clientesOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.cliente === clientesOpcion.label );
    if( transportistasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.transportista === transportistasOpcion.label );    
    if( hardwareOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.hardware === hardwareOpcion.label );
    if( tecnologiaOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.tecnologia === tecnologiaOpcion.label );
      setGpsListFiltrados(gpssTemp);
    //return gpssTemp;
  }
  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  /*
      {
          "idGps": 83902,
          "gps": "618191",
          "gpsEstatus": "Instalado",
          "plataforma": "Navieros",
          "corredor": "VERACRUZ",
          "transportista": "PENDIENTE NAVIERO",
          "cliente": "TRANSMODAL",
          "colorPlataforma": "#00789b",
          "colorEstatus": "#3195ff",
          "pais": "mx"
      }
    */
  const getGraficaGpsEstatus = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let gEstatus of gpsEstatusOpciones ){
      labels.push(gEstatus.label);
      data.push(gpssTemp.filter( g => g.gpsEstatus === gEstatus.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaPlataformas = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of plataformasOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.plataforma === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaPaises = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of paisOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.pais === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaCorredores = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of corredoresOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.corredor === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaHardaware = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let h of hardwareOpciones ){
      labels.push(h.label);
      data.push(gpssTemp.filter( g => g.hardware === h.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaTecnologia = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let t of tecnologiaOpciones ){
      labels.push(t.label);
      data.push(gpssTemp.filter( g => g.tecnologia === t.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaClientes = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of clientesOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.cliente === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaTransportistas = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of transportistasOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.transportista === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const reset =()=>{
    setGpsEstatusOpcion({ value: 0, label: "Todos" });
    setPlataformasOpcion({ value: 0, label: "Todos" });
    setCorredoresOpcion({ value: 0, label: "Todos" });
    setTransportistasOpcion({ value: 0, label: "Todos" });
    setClientesOpcion({ value: 0, label: "Todos" });
    setPaisOpcion({ value: 0, label: "Todos" });
    setHardwareOpcion({ value: 0, label: "Todos" });
    setTecnologiaOpcion({ value: 0, label: "Todos" });
  }

  return (
    <div className="container-fluit">
      <div className="row justify-content-center" style={{ margin: "16px" }}>
        <h1>Dashboard GPS </h1>
      </div>
      <div className="row">
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Estatus</label>
            <Select value={gpsEstatusOpcion} onChange={(e)=>{ setGpsEstatusOpcion(e); }} options={gpsEstatusOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Plataformas</label>
            <Select value={plataformasOpcion} onChange={(e)=>{ setPlataformasOpcion(e); }} options={plataformasOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Corredores</label>
            <Select value={corredoresOpcion} onChange={(e)=>{ setCorredoresOpcion(e); }} options={corredoresOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Pais</label>
            <Select value={paisOpcion} onChange={(e)=>{ setPaisOpcion(e); }} options={paisOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="hardware">Hardware</label>
            <Select value={hardwareOpcion} onChange={(e)=>{ setHardwareOpcion(e); }} options={hardwareOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="tecnologia">Tecnologia</label>
            <Select value={tecnologiaOpcion} onChange={(e)=>{ setTecnologiaOpcion(e); }} options={tecnologiaOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="desechable">Ver desechable</label>
            <Form.Switch type="Checkbox" name="toggle" value="desechable" checked={ desechable } onChange={ (e) => setDesechable(e.target.checked ) } />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="reset">Restablecer valores</label>
            <button onClick={()=> reset()}  className="btn-primary btn ">reset</button>
          </div>
        </div>
        {/*
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Transportistas</label>
            <Select value={transportistasOpcion} onChange={(e)=>{ setTransportistasOpcion(e); }} options={transportistasOpciones} />
          </div>
        </div>
        <div className="col-2 justify-content-start">
          <div className="form-group">
            <label for="estatus">Clientes</label>
            <Select value={clientesOpcion} onChange={(e)=>{ setClientesOpcion(e); }} options={clientesOpciones} />
          </div>
        </div>*/}        
        <div className="col-12">
          <span>
            <h3 className="text-right">Total {gpsListFiltrados.length}</h3>
          </span>
        </div>
      </div>
      { gpsListFiltrados.length &&
        <div className="row">
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Estatus
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                  <Chart
                    series= {getGraficaGpsEstatus().data}
                  options= {{
                    chart: {
                      width: 3000,
                      type: 'donut',
                    },
                    labels:getGraficaGpsEstatus().labels,
                    plotOptions: {
                      pie: {
                        startAngle: -90,
                        endAngle: 270
                      }
                    },              
                    dataLabels: {
                      enabled: false
                    },
                    fill: {
                      type: 'gradient',
                    },
                    legend: {
                      formatter: function(val, opts) {
                        return getGraficaGpsEstatus().labels[opts.seriesIndex]+" - "+ getGraficaGpsEstatus().data[opts.seriesIndex]
                        //return val + " - T" + opts.w.globals.series[opts.seriesIndex]
                      }
                    },
                    title: {
                      text: 'Estatus actual'
                    },
                    responsive: [{
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: 'bottom'
                        }
                      }
                    }]
                  }}
                    type="donut" width={"100%"} height={"300px"}/>
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-1">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Plataformas
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaPlataformas().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaPlataformas().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"300px"}
                />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Corredores
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaCorredores().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaCorredores().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"300px"}
                />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Pais
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                  <Chart
                    series= {getGraficaPaises().data}
                    options= {{
                      chart: {
                        width: 380,
                        type: 'pie',
                      },
                      labels:getGraficaPaises().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      },
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }]
                    }}
                    type="pie" width={"76%"} height={"300px"} />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Hardware
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                  <Chart
                    series= {getGraficaHardaware().data}
                    options= {{
                      chart: {
                        width: 380,
                        type: 'pie',
                      },
                      labels:getGraficaHardaware().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      },
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }]
                    }}
                    type="pie" width={"76%"} height={"300px"} />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-1">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4> Tecnologia </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaTecnologia().labels,
                    },             
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaTecnologia().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"300px"}
                />
                :null}
              </div>
            </div>
          </div>
          {/*
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Clientes
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaClientes().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaClientes().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Transportistas
                </h4>
              </div>
              <div class="card-body text-success">
                { gpsList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaTransportistas().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaTransportistas().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
                :null}
              </div>
            </div>
          </div>*/}
        </div>
      }
      <hr />
      <div className="row" hidden={!gpsListFiltrados.length}>
        <div className="col-12">
          <TablePageable
            titulo={<h3> Listado de gps </h3>}
            lista={gpsListFiltrados}
            columnas={
              [
                { columna: "idGps", label: "id" },
                { columna: "gps", label: "Nombre" },
                { columna: "imei", label: "IMEI" },
                { columna: "gpsEstatus", label: "Estatus" },
                { columna: "plataforma", label: "Plataforma" },
                { columna: "corredor", label: "Corredor" },
                { columna: "transportista", label: "Transportista" },
                { columna: "cliente", label: "Cliente" },
                { columna: "pais", label: "País" },
                { columna: "hardware", label: "HardWare" },
                { columna: "tecnologia", label: "Tecnologia" },
                { columna: "desechable", label: "Desechable",
                  form: (e)=> e.desechable === null? "Sin clasificar": e.desechable? "SI": "NO",
                  valor: (e)=> e.desechable === null? "Sin clasificar": e.desechable? "SI": "NO"
                },
              ]
            }
            id="idGps"
            selected={() => { }}
            csvname="Gps Mobiles"
            paginacion={true}
            elementosPorPagina={50}
          //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
          //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
        </div>
      </div>
    </div>
  );
};

export default GpsDash;
