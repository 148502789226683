export default [
 
  [27.0019066,-99.8544566,100.0,1,0],
  [25.94725,-98.366155,100.0,14,0],
  [23.713436,-106.597461,85.3658536585365800,41,0],
  [17.298441,-94.454281,0,153,0],
  [25.7955716,-100.5319833,98.0392156862745100,51,0],
  [19.175914,-96.96031,82.4561403508771900,57,0],
  [28.328209,-106.391279,100.0,14,0],
  [29.7190066,-106.6550166,100.0,1,0],
  [17.429303,-97.582401,58.8235294117647100,17,0],
  [17.055499,-97.563782,94.0476190476190500,84,0],
  [18.135649,-99.208328,87.500,16,0],
  [22.1683716,-100.9563,85.5450236966824700,422,0],
  [17.0038366,-100.1767833,100.0,4,0],
  [18.032221,-93.147028,100.0,23,0],
  [18.83625,-99.2020833,100.0,10,0],
  [17.917851,-94.154622,91.9191919191919200,99,0],
  [17.169968,-93.587037,61.176470588235300,85,0],
  [16.402278,-93.814304,96.4285714285714300,28,0],
  [22.79694,-103.5113166,50.0,4,0],
  [20.748675,-88.711923,98.9528795811518300,191,0],
  [20.7490859,-88.125152,99.3902439024390200,164,0],
  [20.905892,-87.418091,94.7727272727272800,880,0],
  [21.021302,-87.128604,99.3377483443708600,151,0],
  [28.817488,-106.837605,92.3076923076923100,13,0],
  [29.074954,-106.713508,79.0697674418604600,43,0],
  [18.385865,-100.64805,96.6573816155988800,1077,0],
  [20.73127,-87.21893,0,21,0],
  [17.101431,-94.868088,0,18,0],
  [24.31857,-110.3559166,85.7142857142857200,7,0],
  [29.0787833,-100.8992166,100.0,4,0],
  [29.3254116,-100.9757333,100.0,50,0],
  [19.063376,-97.044062,75.8620689655172400,58,0],
  [20.6898066,-105.25735,94.5033751205400200,1037,0],
  [30.4056083,-107.8997833,100.0,4,0],
  [31.317745,-109.32255,100.0,2,0],
  [30.9911766,-110.5719666,100.0,1,0],
  [17.9720939,-97.35744,88.4057971014492800,69,0],
  [17.8293259,-97.371807,76.7441860465116300,43,0],
  [17.265593,-97.6730819,83.1325301204819300,83,0],
  [16.932707,-97.631434,3.96825396825396500,126,0],
  [17.518953,-99.482927,63.7254901960784300,102,0],
  [17.103006,-99.585539,47.61904761904761600,21,0],
  [23.808132,-104.128305,100.0,13,0],
  [17.359915,-97.078822,45.4545454545454600,22,0],
  [17.325637,-97.040611,45.4545454545454600,22,0],
  [25.621862,-99.824673,100.0,22,0],
  [25.869958,-98.90685,100.0,7,0],
  [25.85724,-97.795737,100.0,11,0],
  [22.2093783,-100.9277999,95.035460992907800,141,0],
  [19.4295766,-98.0052933,80.0,5,0],
  [16.9610066,-99.9827683,66.6666666666666700,6,0],
  [17.2485033,-100.8483166,85.7142857142857200,7,0],
  [24.378915,-107.252383,96.0893854748603300,358,0],
  [21.079665,-86.881113,100.0,32,0],
  [18.706542,-97.411793,100.0,28,0],
  [19.972563,-102.261607,94.8598130841121500,214,0],
  [20.179169,-102.417706,100.0,12,0],
  [23.0505833,-103.1490666,50.0,2,0],
  [31.239217,-112.49817,95.1388888888888800,144,0],
  [26.893797,-109.398255,98.3333333333333300,300,0],
  [24.006187,-104.820962,83.0769230769230700,65,0],
  [27.8678733,-101.1319166,94.3283582089552200,335,50.0],
  [24.0036966,-104.65065,95.3078344945258100,14066,0],
  [19.9345366,-98.5621916,100.0,29,0],
  [21.76053,-102.2189666,100.0,4,0],
  [20.9628066,-101.31875,100.0,16,0],
  [20.11776,-98.385317,100.0,32,0],
  [19.7879149,-99.0948766,94.0298507462686600,67,0],
  [21.0000633,-105.2542666,100.0,16,0],
  [23.9749883,-104.6974666,93.0555555555555600,504,0],
  [25.9303049,-98.266055,94.4444444444444400,18,0],
  [29.063065,-110.992768,94.9308755760368700,217,0],
  [19.02494,-90.72479,33.33333333333333700,3,0],
  [28.2591216,-111.0302166,96.5517241379310400,203,0],
  [22.313194,-105.340784,87.8228782287822900,271,0],
  [24.832756,-107.432001,94.0890125173852600,1438,0],
  [19.974455,-98.5221299,100.0,38,0],
  [21.77206,-101.8334666,50.0,4,0],
  [30.89032,-106.48492,100.0,55,0],
  [31.519164,-106.472611,90.9090909090909100,66,0],
  [20.13979,-98.2546799,97.9591836734693900,49,0],
  [20.3951716,-97.8724116,86.1111111111111200,36,0],
  [20.4205433,-97.8230716,90.0,10,0],
  [21.76126,-101.503,87.9310344827586200,58,55.1724137931034500],
  [21.0424909,-89.610737,75.7142857142857100,140,0],
  [18.6739383,-97.45981330000001,90.2173913043478300,92,0],
  [19.7166083,-100.4619,100.0,12,0],
  [17.533679,-94.297154,0,68,0],
  [17.498836,-94.382137,0,70,0],
  [17.527762,-94.4703679,0,46,0],
  [17.277171,-94.4941599,0,161,0],
  [17.960351,-94.110816,91.2280701754385900,57,0],
  [26.4962533,-101.1021666,97.7272727272727300,44,0],
  [18.240054,-99.213526,95.2380952380952300,21,0],
  [25.689987,-99.58081,100.0,8,0],
  [25.845029,-108.982569,89.8901098901098900,910,0],
  [16.463393,-95.197956,60.0,150,0],
  [20.987406,-104.056299,89.0384615384615300,520,0],
  [21.060993,-104.229493,96.9339622641509400,424,0],
  [26.5011683,-104.1068166,88.3116883116883100,77,0],
  [21.26429,-100.6971333,61.5384615384615400,26,0],
  [20.48452,-102.7646,0,1,0],
  [21.1768266,-105.1411499,88.3720930232558200,43,0],
  [19.27601,-98.252781,0,0,84.8484848484848500],
  [18.994547,-98.253966,75.00,24,84.2105263157894700],
  [19.4244683,-103.5097499,99.3902439024390200,164,83.9062500],
  [20.6516466,-100.5056833,86.3509749303621200,359,91.150442477876100],
  [22.0368516,-100.8529,87.292132042690500,4029,87.2659176029962600],
  [19.5962583,-101.2941833,75.00,4,93.3174224343675400],
  [25.7893166,-100.0391666,94.8035487959442400,789,100.0],
  [20.685552,-89.876193,97.2762645914396900,257,100.0],
  [19.9342366,-98.9199566,94.2446043165467700,417,77.5510204081632600],
  [18.8416816,-97.2613183,86.9390103567318700,1738,53.8181818181818200],
  [18.8601949,-90.7397016,63.157894736842100,19,0],
  [24.620245,-103.978227,92.500,40,0],
  [23.875903,-105.175555,97.1428571428571400,105,0],
  [23.327327,-105.984813,88.8888888888888800,90,0],
  [30.823873,-112.756634,100.0,10,0],
  [31.306031,-113.524831,77.4193548387096800,62,0],
  [31.75583,-113.02531,88.8888888888888800,9,0],
  [28.306093,-111.04191,94.5312500,256,0],
  [26.559905,-109.142961,96.4601769911504400,339,0],
  [28.9388566,-110.95795,90.62500,128,0],
  [18.7061316,-90.73563,75.00,8,0],
  [32.551898,-115.082439,98.2142857142857100,112,0],
  [32.117854,-113.789245,25.9615384615384600,104,0],
  [25.652136,-102.771393,97.7777777777777700,45,0],
  [25.099429,-107.717384,86.3636363636363600,44,0],
  [20.3157433,-96.8612916,100.0,7,0],
  [22.3363266,-101.2219166,86.928104575163400,153,0],
  [22.3496966,-101.26495,62.9032258064516100,124,0],
  [26.982894,-109.428688,97.4895397489539800,239,0],
  [27.241599,-109.618062,98.5436893203883500,206,0],
  [24.074191,-104.64101,94.8174322732626600,2547,0],
  [23.939342,-105.099686,88.0952380952380900,84,0],
  [31.292914,-113.239223,100.0,8,0],
  [31.439709,-113.461782,50.0,8,0],
  [18.8741966,-99.2266299,100.0,13,0],
  [28.45408,-105.7185,87.9432624113475200,141,100.0],
  [19.53558,-99.2797299,97.9729729729729700,148,93.063583815028900],
  [21.7241866,-101.5364333,94.2028985507246300,69,88.4057971014492800],
  [21.80136,-101.01385,86.7132867132867100,143,58.1655480984340100],
  [15.755575,-93.31901,81.8181818181818100,44,63.6363636363636400],
  [19.4511499,-98.9186516,91.2280701754385900,399,91.4285714285714300],
  [20.803522,-103.709099,97.8823529411764800,425,50.0],
  [19.6101616,-98.9231616,98.3050847457627200,118,97.1153846153846100],
  [18.7997566,-96.6042866,76.8913342503438800,1454,51.7857142857142800],
  [22.800021,-105.714282,92.2178988326848300,514,50.0],
  [18.919068,-98.48362,100.0,26,92.3076923076923100],
  [23.626477,-103.379703,11.11111111111111600,9,0],
  [23.743855,-103.9943739,100.0,15,100.0],
  [23.776014,-104.087036,100.0,13,100.0],
  [22.057776,-100.057365,0,5,68.9655172413793100],
  [21.897573,-99.845333,0,9,2.325581395348841300],
  [21.890275,-99.791874,0,9,0],
  [21.883697,-99.740092,0,13,0],
  [21.87248,-99.693712,38.88888888888888400,18,27.7777777777777800],
  [21.861743,-99.644116,85.1851851851851900,27,83.7606837606837600],
  [21.869523,-99.594938,79.3103448275862100,29,56.3829787234042500],
  [21.869548,-99.510795,95.00,20,79.4117647058823600],
  [21.90851,-99.364942,62.0689655172413800,29,15.59633027522935300],
  [21.943734,-99.326676,96.00,25,91.6666666666666600],
  [21.950568,-99.225133,100.0,17,54.2857142857142900],
  [21.963911,-99.179455,100.0,23,56.4356435643564400],
  [21.987013,-99.1385729,100.0,26,65.0485436893203800],
  [21.979964,-99.090475,100.0,15,100.0],
  [21.953261,-99.05144,100.0,13,77.7777777777777800],
  [21.9342979,-99.007012,100.0,13,83.3333333333333400],
  [21.907684,-98.96893,66.6666666666666700,18,77.2727272727272700],
  [22.088121,-98.630253,100.0,16,87.8787878787878800],
  [22.109215,-98.589437,100.0,14,97.3684210526315800],
  [20.283095,-90.06956,94.1176470588235300,289,100.0],
  [20.0977216,-99.28975,87.0967741935483900,403,86.0294117647058900],
  [24.198424,-107.12312,94.0366972477064200,436,100.0],
  [18.7817016,-96.4676066,91.5742793791574300,451,70.3703703703703700],
  [22.493899,-105.384292,93.6170212765957500,94,71.4285714285714300],
  [19.5247616,-99.7431316,85.600,125,61.2903225806451600],
  [18.850067,-91.432346,96.2546816479400800,267,100.0],
  [21.033387,-104.360703,93.4497816593886400,458,88.8888888888888800],
  [19.3931466,-99.709935,84.0634920634920600,1575,81.1985898942420700],
  [21.75058,-105.082036,90.3225806451612900,310,33.33333333333333700],
  [29.123321,-106.349284,76.9230769230769200,65,33.33333333333333700],
  [29.990382,-106.370679,94.7368421052631600,57,100.0],
  [22.867573,-99.830841,100.0,15,56.2500],
  [20.132477,-101.15754,92.9824561403508800,57,93.4426229508196800],
  [19.3112216,-97.79644,96.774193548387100,31,79.9216454456415300],
  [25.4714199,-103.693,88.8888888888888800,90,66.6666666666666700],
  [27.3890799,-99.55105,96.1051283267707200,21079,84.6153846153846100],
  [27.45579,-99.56534,96.0966183574879300,20700,100.0],
  [30.978912,-112.368772,94.1176470588235300,153,100.0],
  [25.3890566,-100.1182333,100.0,4,96.1538461538461600],
  [30.665878,-111.727348,95.4887218045112800,133,100.0],
  [25.501096599999997,-100.96991660000002,80.0240673886883200,831,74.7141041931385100],
  [19.581601,-97.266251,100.0,2,92.8915662650602400],
  [19.3205066,-98.2622116,73.0769230769230800,26,80.4195804195804200],
  [19.71209,-103.50255,96.3057324840764300,785,91.1111111111111100],
  [19.4571266,-103.4776666,99.5283018867924500,212,85.5123674911660700],
  [18.9689183,-103.8684333,91.3043478260869600,23,90.0979325353645200],
  [27.55428,-99.60726,85.5319148936170200,940,75.00],
  [19.798155,-101.2829666,95.00,60,85.3251318101933200],
  [25.05124,-107.855507,96.296296296296300,243,100.0],
  [18.8122433,-96.6611533,94.946808510638300,376,70.9302325581395300],
  [19.55836,-101.3323333,80.0,5,89.4409937888198800],
  [24.434561,-100.353814,96.0809102402022800,791,81.012658227848100],
  [22.292368,-97.852981,80.0356506238859100,561,94.4444444444444400],
  [20.416525,-99.98071,92.6307448494453200,1262,82.8685258964143500],
  [22.565961,-100.533619,86.6666666666666700,15,83.3333333333333400],
  [22.165197,-98.228994,87.500,16,100.0],
  [20.665917,-100.243094,96.87500,32,12.88433382137628100],
  [18.642985,-90.2192166,92.8571428571428600,14,0],
  [18.5421366,-89.9450266,100.0,15,100.0],
  [18.48347,-89.214795,95.00,20,100.0],
  [18.5106416,-88.4969266,94.1176470588235300,34,100.0],
  [18.955225,-88.1574416,75.00,4,85.7142857142857200],
  [19.2873066,-88.0958599,80.0,5,33.33333333333333700],
  [19.4337816,-88.0415649,75.00,4,42.8571428571428600],
  [20.0314666,-87.6615216,50.0,4,0],
  [20.60641,-87.109015,82.6034063260340700,822,100.0],
  [15.346099,-92.253057,53.4722222222222200,576,85.7142857142857200],
  [23.369506,-102.257473,100.0,7,55.8823529411764700],
  [23.992949,-101.641045,0,3,0],
  [24.70104,-101.249027,0,2,0],
  [24.733043,-101.198711,40.0,5,0],
  [25.6088683,-102.6476,92.8571428571428600,28,100.0],
  [18.230365,-101.8946833,92.3076923076923100,13,79.500],
  [18.0014249,-102.1632666,98.6842105263157900,76,76.2750964207187500],
  [18.9462249,-98.441166,100.0,14,100.0],
  [19.228281,-98.196385,86.7647058823529400,68,60.8225108225108200],
  [23.585186,-103.277084,82.1428571428571400,28,50.0],
  [19.4067516,-99.5998549,93.6288088642659200,361,94.2307692307692300],
  [22.50954,-100.433866,9.99999999999999800,10,0],
  [21.978071,-98.790412,92.8571428571428600,14,93.7500],
  [22.171147,-98.042558,100.0,22,84.4827586206896600],
  [19.3510316,-99.1904816,79.8165137614678900,218,89.655172413793100],
  [25.530569,-108.38778,87.6434245366284200,1133,100.0],
  [20.796354,-89.811026,96.4143426294820700,251,100.0],
  [19.9433366,-98.9763266,93.500,400,81.8181818181818100],
  [20.90699,-103.9460579,92.1225382932166300,457,100.0],
  [20.3191466,-100.2055166,75.7500000000000100,400,73.3333333333333400],
  [23.767153,-106.829389,94.1704035874439500,446,100.0],
  [18.730331,-91.64196,97.3154362416107400,298,100.0],
  [18.9751333,-98.2109866,100.0,3,86.1538461538461600],
  [19.040485,-99.23074,85.7142857142857200,7,100.0],
  [19.505606,-98.420893,95.4063604240282700,283,92.1824104234527700],
  [20.739249,-100.3037,91.9354838709677400,124,50.0],
  [19.916181,-90.413995,88.0620155038759700,645,100.0],
  [20.5527866,-100.7725499,84.2001962708537800,1019,96.1259079903147700],
  [21.370831,-104.710289,92.2290388548057200,489,100.0],
  [18.439456,-92.801732,95.6340956340956400,481,100.0],
  [19.949576,-99.629973,93.3333333333333300,675,85.7758620689655100],
  [20.0123399,-101.9036579,96.782841823056300,373,93.037974683544300],
  [23.9500849,-99.0944233,100.0,2,90.0],
  [23.9375316,-100.4530666,94.0476190476190500,840,95.00],
  [26.8188,-104.3775166,93.6363636363636400,110,100.0],
  [20.6585949,-101.1944833,92.8961748633879800,183,84.7222222222222200],
  [22.7446069,-99.988619,100.0,14,40.0],
  [25.0228683,-103.7728166,85.7142857142857200,21,100.0],
  [21.3287933,-102.24585,95.1351351351351400,555,82.7586206896551700],
  [21.9454983,-102.3848333,94.2307692307692300,52,55.5555555555555600],
  [20.7381954975,-103.39759658749999,94.6127946127946200,297,48.27586206896551400],
  [20.997514116666665,-101.47247773333333,93.8958009331259700,2572,81.6938453445554900],
  [26.1155616,-100.1562166,94.9511400651465800,614,100.0],
  [27.3492899,-99.627855,88.6363636363636400,88,100.0],
  [19.394385,-99.6438183,92.8571428571428600,84,96.245733788395900],
  [15.482518,-92.924422,98.5294117647058900,68,86.6666666666666700],
  [18.5531616,-90.000385,78.5714285714285700,14,40.0],
  [19.335342,-90.736468,91.2804878048780500,1640,100.0],
  [19.491772,-90.702467,83.4146341463414600,410,100.0],
  [32.57881,-116.034116,89.1891891891891900,296,22.97297297297297100],
  [20.536044,-103.017672,94.0828402366863900,507,89.0710382513661200],
  [25.13727,-103.75671,90.8629441624365400,197,100.0],
  [19.882493,-100.259628,96.4944649446494500,542,87.6892028254288600],
  [19.881255,-100.93584,94.7467166979362100,533,91.7457305502846300],
  [20.081706,-101.9857619,97.9487179487179400,390,96.4285714285714300],
  [20.175076,-102.231573,95.7295373665480500,843,96.1538461538461600],
  [30.595648,-111.546266,97.7777777777777700,225,100.0],
  [25.45569,-101.104191,84.4444444444444400,270,100.0],
  [21.822195,-97.76447,90.2439024390243900,41,96.87500],
  [22.7267966,-102.3356666,89.6678966789667900,271,98.701298701298700],
  [16.8969966,-95.01114,74.5454545454545500,330,87.9551820728291300],
  [25.97943,-103.6347666,85.600,125,100.0],
  [16.0645016,-93.74988,97.9166666666666600,48,50.0],
  [16.0312366,-93.6925316,98.4615384615384700,65,56.8181818181818100],
  [26.7001716,-104.2220666,94.8717948717948700,117,100.0],
  [19.3927783,-99.29591,76.9696969696969700,330,89.1891891891891900],
  [20.8879516,-101.4838833,94.7761194029850700,134,81.8181818181818100],
  [19.100765,-99.2011316,100.0,8,100.0],
  [18.9527316,-99.1916116,87.500,8,100.0],
  [22.1525016,-101.07995,92.753623188405800,69,58.7301587301587300],
  [29.500832,-106.39055,67.187500,64,100.0],
  [22.428331,-97.888666,100.0,7,50.2293998898880600],
  [23.674166,-99.098896,100.0,19,90.0],
  [22.786807,-99.927721,100.0,15,77.7777777777777800],
  [22.5014049,-102.3657333,93.506493506493500,77,48.00],
  [22.19271,-102.2947333,100.0,56,86.5671641791044800],
  [21.73611,-100.6870833,83.208955223880600,1340,83.5714285714285700],
  [20.76005,-101.3780666,84.9137931034482800,464,89.1304347826086900],
  [24.546084,-107.411144,93.4432989690721600,2425,100.0],
  [20.742031,-103.636511,93.9086294416243700,197,0],
  [19.4519933,-98.9642816,95.6521739130434800,483,91.8032786885245900],
  [20.839229,-103.741207,98.054474708171200,257,60.0],
  [18.665685,-96.30177,91.6666666666666600,516,69.7142857142857100],
  [20.4433316,-100.5374166,81.7129629629629700,432,88.5057471264367800],
  [22.327413,-105.2841679,79.687500,64,100.0],
  [23.474085,-106.573013,91.9472913616398300,683,100.0],
  [19.64276,-99.783585,90.3508771929824600,114,90.0332225913621300],
  [20.565346,-97.35214,100.0,8,39.1304347826086900],
  [20.3435666,-99.9486033,92.7347147608083900,3909,87.8306878306878400],
  [18.0184633,-94.5667916,81.6882232811436300,1469,100.0],
  [20.3973966,-103.0384333,100.0,24,100.0],
  [20.5706433,-100.4806166,82.1788413098236800,1588,86.7979576951130600],
  [22.950684,-105.837278,90.5084745762711800,295,50.0],
  [20.629565,-103.43545,74.7374239911553300,1809,88.3040935672514600],
  [22.279595,-105.269701,81.3559322033898400,59,100.0],
  [19.62754,-97.155205,85.7142857142857200,7,92.7093596059113300],
  [19.31502,-97.8774916,93.7500,16,85.3968253968253900],
  [19.784615,-98.94774,65.4761904761904800,84,95.00],
  [27.02428,-99.7463399,96.7013888888888800,576,100.0],
  [19.4451383,-101.8493333,96.1538461538461600,78,85.0299401197604800],
  [19.5056233,-101.6593833,95.8904109589041200,73,91.5185783521809300],
  [22.185844,-105.261434,86.9426751592356700,314,66.6666666666666700],
  [18.0353566,-93.8399,94.5904173106646100,647,100.0],
  [32.379346,-114.43869,75.200,125,66.6666666666666700],
  [19.876588,-101.400056,97.1988795518207300,357,97.3094170403587500],
  [20.9158766,-102.6747333,92.8270042194092800,237,88.800],
  [30.648113,-111.68006,94.696969696969700,132,100.0],
  [30.355694,-111.098574,98.7577639751552800,161,100.0],
  [23.4495366,-98.8388766,100.0,15,69.7674418604651200],
  [20.9489649,-97.4826083,96.5517241379310400,29,100.0],
  [22.7466666,-102.3848,84.1432225063938600,391,84.6153846153846100],
  [16.85173,-95.02078,74.8677248677248600,378,61.1940298507462700],
  [26.6238516,-104.1082,96.296296296296300,108,100.0],
  [19.468875,-99.006592,94.6132596685082900,724,64.7482014388489200],
  [20.8787566,-102.73645,94.8717948717948700,234,87.2727272727272800],
  [21.1685199,-102.4744999,87.500,8,77.2357723577235800],
  [23.4908316,-100.6165166,94.9081803005008300,1198,97.3333333333333400],
  [17.668734,-95.077839,51.1904761904761900,168,26.4705882352941100],
  [22.1132866,-101.1091166,100.0,42,66.6666666666666700],
  [18.0858033,-95.4035766,92.3391812865497100,1710,76.6666666666666600],
  [17.9908066,-93.2067099,86.2433862433862400,1512,100.0],
  [18.0052666,-93.0200666,79.0361445783132500,830,100.0],
  [17.6995116,-92.5405216,76.1904761904761900,21,100.0],
  [18.586181,-92.5682719,95.035460992907800,282,100.0],
  [20.5773666,-100.3848666,94.5291479820627800,2230,88.5826771653543300],
  [18.2149569,-92.821198,85.4430379746835400,316,0],
  [32.539659,-116.426751,88.6075949367088700,79,50.0],
  [32.519542,-114.902104,89.0052356020942300,191,66.6666666666666700],
  [32.461767,-114.709546,94.312796208530800,211,100.0],
  [32.167864,-113.95147,34.6774193548387100,124,0],
  [31.9756,-113.136833,94.9579831932773100,119,100.0],
  [19.8816929,-101.200874,94.6859903381642500,414,84.8122866894197900],
  [20.615664,-103.243483,94.6666666666666700,750,87.6923076923076900],
  [20.200691,-102.322552,95.3038674033149100,362,85.8921161825726200],
  [30.848419,-112.271864,95.5414012738853500,157,100.0],
  [25.5844366,-100.25615,75.00,4,84.2105263157894700],
  [25.2917249,-100.0477499,72.7272727272727300,33,39.2592592592592600],
  [25.777056,-100.185391,82.9552065154159400,1719,71.6731898238747600],
  [30.565587,-111.253767,86.9918699186991800,123,100.0],
  [25.588066,-101.862997,95.6521739130434800,69,100.0],
  [25.2489016,-99.9711716,91.6666666666666600,12,79.4871794871794900],
  [25.649915,-102.143576,92.5233644859813100,107,100.0],
  [24.7034316,-99.5363733,100.0,2,76.5100671140939600],
  [24.2702849,-99.4360183,100.0,7,79.600],
  [21.5617416,-97.7469749,100.0,25,100.0],
  [25.2346916,-100.78385,93.2291666666666600,960,81.8181818181818100],
  [24.1640016,-100.3796333,95.9584295612009200,866,97.0149253731343300],
  [23.8024683,-100.5661333,92.4273858921161900,964,62.6436781609195400],
  [19.8356166,-96.4503166,100.0,3,44.4444444444444400],
  [23.206685,-102.8457333,91.6376306620209100,287,83.3333333333333400],
  [21.3667933,-100.6664,87.4089490114464100,961,76.3440860215053800],
  [17.06195,-95.0215066,76.4705882352941100,255,60.8695652173913100],
  [26.0734216,-103.6724333,92.2535211267605600,426,100.0],
  [26.2795616,-103.87355,96.3768115942028900,138,100.0],
  [26.6669816,-104.1738666,93.6363636363636400,110,100.0],
  [26.8804666,-104.5209833,86.928104575163400,153,100.0],
  [27.5481416,-105.0488166,95.798319327731100,119,100.0],
  [15.4184666,-92.8415666,95.00,60,85.00],
  [24.773998,-103.831593,97.9591836734693900,49,100.0],
  [24.161617,-104.508699,98.0582524271844700,103,100.0],
  [20.703365,-101.2662999,93.5960591133004900,203,91.8032786885245900],
  [21.122385,-101.8103,94.527363184079600,201,100.0],
  [21.37277,-102.2601833,97.560975609756100,41,81.2500],
  [21.525985,-101.7231166,88.2352941176470600,68,89.0243902439024400],
  [19.3844416,-96.6017983,100.0,24,89.9585062240663900],
  [18.5292766,-96.0943983,96.755162241887900,339,76.4705882352941100],
  [23.309905,-106.403026,89.7959183673469400,490,40.0],
  [21.23876,-101.3041499,43.5897435897435900,39,8.8888888888888900],
  [19.298012,-99.745491,0,0,42.8571428571428600],
  [19.326941,-99.781026,0,0,50.0],
  [19.11887,-104.385919,0,0,33.33333333333333700],
  [15.394814,-92.173724,0,0,66.6666666666666700],
  [21.629305,-100.7296166,84.7433116413593600,1383,67.0391061452513900],
  [19.4252216,-98.4327416,93.9024390243902400,574,87.3239436619718300],
  [22.828568,-105.753258,97.3333333333333400,375,100.0],
  [19.83317,-99.2680566,92.6710402999062800,5335,91.8032786885245900],
  [25.3695499,-101.00435,73.0061349693251500,163,100.0],
  [17.6970666,-92.1034649,87.500,16,75.00],
  [17.9048683,-91.7576783,29.03225806451612500,31,33.33333333333333700],
  [18.03906,-91.6605599,17.39130434782608600,23,100.0],
  [18.1584716,-91.5863916,30.00000000000000400,20,50.0],
  [19.964896,-101.697579,95.1742627345844500,373,95.8333333333333400],
  [30.733776,-112.171471,92.5636007827788700,511,85.7142857142857200],
  [25.1275649,-99.81199,100.0,3,79.7101449275362300],
  [21.06971,-97.5250866,92.3076923076923100,26,100.0],
  [24.4680266,-100.3216333,94.4256756756756800,1184,93.6507936507936500],
  [23.7139966,-100.6099833,93.1068931068931100,1001,92.682926829268300],
  [22.6191016,-100.53755,95.4087346024636100,893,91.7808219178082200],
  [22.4575466,-100.6966,94.7003154574132500,1585,89.3333333333333300],
  [22.3757566,-100.7830666,93.8384679433805200,1201,83.3333333333333400],
  [16.5538766,-94.6273266,100.0,3,66.1764705882352900],
  [20.6815399,-103.0014,94.5833333333333300,240,92.9347826086956500],
  [19.4302683,-99.151865,84.8894348894348900,814,19.10994764397905300],
  [19.3569783,-99.1440483,72.6760563380281600,710,60.0],
  [21.58402,-101.0946166,93.3333333333333300,45,92.500],
  [20.7741599,-101.3349666,90.5583756345177600,985,96.0784313725490200],
  [22.597305,-100.57975,94.4981412639405200,1345,84.0277777777777800],
  [20.605405,-103.136953,92.3611111111111200,576,71.8309859154929500],
  [19.69412,-99.1666549,75.784623316362700,31034,65.8986175115207300],
  [22.12055,-100.9234166,92.8391959798994900,3184,84.7826086956521700],
  [21.036348,-104.267205,96.518375241779500,517,100.0],
  [25.6908883,-100.2427166,78.9757412398921800,742,80.9160305343511500],
  [19.3899016,-88.0578516,100.0,4,100.0],
  [20.45494,-100.0886333,92.2680412371134100,2716,73.993808049535600],
  [19.442138,-90.7087109,97.4452554744525500,274,100.0],
  [17.973635,-93.1150266,89.0082644628099200,1210,92.3076923076923100],
  [17.684535,-92.3938066,85.7142857142857200,14,75.00],
  [32.549757,-116.802138,77.5280898876404500,89,41.66666666666666300],
  [17.7495666,-91.9002149,81.2500,16,100.0],
  [17.9496466,-91.7272599,25.7142857142857100,35,100.0],
  [18.2439716,-91.3518766,50.0,18,0],
  [23.392319,-106.510586,91.4122137404580100,524,100.0],
  [19.6702566,-103.4844,96.5770171149144200,818,89.0306122448979600],
  [19.59492,-103.4529833,98.5714285714285800,70,88.4931506849315100],
  [19.1942199,-103.7484499,90.62500,32,81.7593790426908200],
  [19.134615,-103.7728333,95.6521739130434800,23,93.225806451612900],
  [19.0519066,-104.2685666,94.0934065934065900,1456,83.1196581196581100],
  [26.19314,-100.1205666,92.9936305732484100,628,100.0],
  [27.12529,-99.6913199,95.340501792114700,558,100.0],
  [18.8645516,-96.9280683,75.4273504273504300,936,82.8571428571428500],
  [18.79198,-96.4229299,95.8456973293768600,337,90.4761904761904800],
  [25.5751966,-103.4745333,70.0,250,94.5454545454545400],
  [20.339617,-97.123414,100.0,5,62.500],
  [19.3970216,-99.1852016,74.9077490774907800,271,88.1188118811881200],
  [18.5766316,-90.0391216,83.3333333333333400,18,0],
  [18.32819,-91.2300083,38.88888888888888400,18,33.33333333333333700],
  [32.549543,-115.999544,83.3333333333333400,276,20.99999999999999600],
  [19.955078,-99.676377,95.1638065522620900,641,89.8066783831282900],
  [19.940871,-99.777071,88.0327868852459100,610,73.8197424892703900],
  [20.5894233,-103.3230166,89.2321671967287600,2201,76.5281173594132100],
  [30.703652,-111.914586,98.0132450331125800,151,100.0],
  [19.02064,-97.944244,96.9849246231155800,398,91.3043478260869600],
  [24.98345,-99.7426066,100.0,2,90.1960784313725500],
  [23.65923,-98.9771916,100.0,15,79.6610169491525400],
  [22.47699,-98.003715,100.0,19,66.0490968040759600],
  [22.4011383,-97.9266133,87.8048780487804900,82,72.1197837238319700],
  [19.496352,-99.190267,83.0681354549163600,4902,68.6082405046302600],
  [22.2161066,-97.9458783,100.0,77,86.2318840579710200],
  [25.2070533,-100.7442,92.9663608562691100,2289,87.5776397515527900],
  [19.618385,-99.0433266,89.8644286636539700,1549,61.3213598460551600],
  [17.7720716,-95.0436466,64.37500,160,60.7142857142857200],
  [19.6149216,-98.96916,100.0,121,92.5373134328358200],
  [17.3895716,-95.0612116,62.8378378378378400,148,8.75912408759124100],
  [25.4805516,-103.6461666,86.4271457085828300,501,100.0],
  [16.26749,-94.134959,100.0,1,59.0909090909090800],
  [25.002375,-101.077539,85.7142857142857200,7,44.7368421052631500],
  [25.047356,-101.0694709,85.7142857142857200,7,60.2272727272727300],
  [27.9606283,-101.1762166,100.0,7,100.0],
  [27.996935,-101.1486833,100.0,5,100.0],
  [20.644882,-97.4182,100.0,15,65.2173913043478300],
  [17.75752,-101.6555,100.0,6,75.00],
  [31.86355099999997,-112.84578599999992,92.5784238714613600,2614,88.8888888888888800],
  [25.835947696894408,-100.31079780108698,89.8032200357781800,12298,86.7684478371501300],
  [20.3093833,-99.9189766,82.6071080817916200,16432,83.6858006042296100],
  [20.629165,-100.2742333,90.8474576271186400,885,88.5964912280701700],
  [20.232415,-99.8234616,91.4929962418858900,2927,82.0441988950276200],
  [17.9149016,-94.8662733,93.6708860759493700,790,70.0],
  [24.867398,-107.638115,98.6607142857142900,224,100.0],
  [19.388573,-90.723663,93.9467312348668300,826,100.0],
  [20.1508516,-99.73926,86.904285142170600,2497,58.0851063829787200],
  [19.665833,-90.645342,82.5795644891122300,597,100.0],
  [19.769399,-90.557098,94.7229551451187400,379,100.0],
  [19.829459,-90.475948,93.6619718309859100,426,100.0],
  [19.996663,-90.290283,94.890510948905100,274,100.0],
  [25.6628529,-108.629765,97.187500,320,100.0],
  [20.612043,-89.96032,94.8126801152737700,347,100.0],
  [20.3102266,-102.7720666,100.0,41,100.0],
  [32.284901,-117.031391,83.7606837606837600,117,68.4210526315789500],
  [20.2662199,-102.4692,95.5942028985507300,1725,95.8268933539412700],
  [20.940897,-89.697468,71.8100890207715100,337,100.0],
  [20.332655,-102.2342666,93.4624697336561700,413,88.6904761904761900],
  [25.39947,-108.134994,97.2619047619047600,1680,100.0],
  [20.0696766,-99.2272466,93.7772925764192100,916,91.275167785234900],
  [20.4449349,-101.6508166,92.5675675675675700,148,90.7216494845360800],
  [20.6423266,-101.2690166,72.4747474747474700,792,100.0],
  [20.32642,-87.3609199,57.1428571428571400,7,100.0],
  [19.78569,-98.6482366,90.8898305084745800,472,81.2297734627831700],
  [19.6578666,-98.5767716,94.1704035874439500,446,71.2538226299694200],
  [18.8174716,-97.2231449,88.6630179827990600,1279,62.357414448669200],
  [19.470955,-98.4522416,95.207667731629400,626,95.698924731182800],
  [19.3599116,-98.4079016,92.0086393088552900,463,90.0],
  [18.9335816,-97.6876299,93.5763888888888800,576,92.3076923076923100],
  [19.0015816,-97.885895,94.4798301486199600,471,77.6315789473684200],
  [19.0950566,-98.22653,89.810231023102300,2424,91.9164396003633100],
  [23.163471,-106.0973,97.8723404255319100,282,100.0],
  [23.099488,-106.016374,98.2394366197183100,284,100.0],
  [19.3288016,-98.5368833,97.4747474747474700,198,92.8571428571428600],
  [19.3095816,-98.7456733,94.3243243243243300,370,55.4323725055432400],
  [24.242762,-107.150673,96.3144963144963100,407,100.0],
  [20.76802,-103.679999,95.5188679245283100,424,58.1395348837209200],
  [18.8572266,-96.8119766,97.2906403940886700,406,89.0909090909090900],
  [19.4957166,-98.947145,100.0,38,60.0],
  [19.6745066,-98.822055,90.3529411764705900,1700,87.6811594202898600],
  [24.1558369,-107.095988,93.4343434343434400,396,100.0],
  [20.41217,-100.4232,73.4831460674157300,445,90.9090909090909100],
  [20.3546666,-100.2982166,76.6666666666666600,390,78.12500],
  [18.2508566,-95.6773549,95.3890489913544600,347,74.1935483870967700],
  [18.1866216,-95.5835166,89.8255813953488400,344,60.4651162790697600],
  [18.1132766,-95.4604266,94.056308654848800,1918,74.0740740740740700],
  [22.361474,-105.318205,90.62500,128,66.6666666666666700],
  [23.807947,-106.868105,94.4933920704845800,454,100.0],
  [18.033365,-95.3103616,96.7005076142131900,394,80.0],
  [23.5439079,-106.661436,91.8767507002801100,357,50.0],
  [22.095135,-105.215682,93.0930930930930900,333,100.0],
  [21.03359,-104.192257,96.1493582263710600,857,62.9629629629629700],
  [21.989822,-105.220427,100.0,59,100.0],
  [23.352059,-106.468995,90.8067542213883600,533,100.0],
  [20.02965,-99.8828849,81.6326530612244900,49,44.8275862068965500],
  [23.264479,-106.310444,88.813559322033900,295,100.0],
  [19.8449816,-99.85875,90.869565217391300,920,62.0874219446922300],
  [18.972849,-91.222826,96.0655737704918100,305,100.0],
  [18.0694416,-94.3086366,80.4134366925064600,1935,92.00],
  [18.814295,-91.4632589,89.6072013093289700,1222,100.0],
  [18.781956,-91.497516,89.696969696969700,1320,86.3636363636363600],
  [18.0531266,-93.9421116,86.4077669902912700,515,100.0],
  [18.0163866,-93.7353416,94.0721649484536100,388,100.0],
  [18.67548,-91.741237,88.2882882882882900,666,100.0],
  [17.9990866,-93.3256816,90.156599552572700,447,70.0],
  [23.1806783,-100.5292,94.3826179120296800,1887,98.00],
  [18.62722,-91.898554,89.1544117647058900,544,75.00],
  [18.0346316,-92.9839916,93.506493506493500,308,100.0],
  [21.498192,-104.909302,94.5083014048531300,783,60.0],
  [17.9829516,-92.826565,77.7777777777777800,18,100.0],
  [21.29351,-104.64912,84.9747474747474700,792,90.0],
  [19.749592233267315,-99.12447491975247,79.2727272727272700,825,83.8461538461538500],
  [18.2928816,-91.2663633,47.05882352941176400,17,0],
  [18.37074,-91.2031916,55.5555555555555600,18,100.0],
  [18.492045,-91.1158816,80.0,25,0],
  [18.6209716,-90.89561,94.4444444444444400,18,100.0],
  [18.60876,-90.76248,85.9649122807017600,57,61.7647058823529400],
  [32.565366,-115.900246,93.7500,208,100.0],
  [24.406432,-103.467009,88.0239520958083800,167,100.0],
  [32.591537,-115.693956,98.3606557377049200,122,80.0],
  [32.533853,-115.438292,92.500,200,66.6666666666666700],
  [32.533265,-115.388772,88.1773399014778400,203,100.0],
  [32.552723,-115.188655,99.1071428571428600,112,100.0],
  [32.552423,-115.142465,99.1228070175438600,114,100.0],
  [32.554719,-115.034889,96.5811965811965800,117,100.0],
  [27.413583,-104.938789,93.6170212765957500,141,100.0],
  [32.491573,-114.808593,92.207792207792200,308,100.0],
  [19.972078,-99.535874,82.4747742963356300,1883,89.4459102902374600],
  [32.477441,-114.761477,93.7869822485207100,338,100.0],
  [19.941317,-99.731016,93.493150684931500,584,85.9243697478991700],
  [32.409594,-114.538177,69.306930693069300,505,100.0],
  [32.35458,-114.38751,70.5357142857142800,112,100.0],
  [19.854025,-99.9978409,95.5390334572490700,538,89.2102335928809800],
  [32.272809,-114.114903,83.6206896551724200,116,100.0],
  [32.2311,-114.032983,85.8490566037735900,106,100.0],
  [23.075245,-102.7882209,88.1118881118881100,143,85.7142857142857200],
  [19.907552,-100.430004,95.6272084805653700,2264,80.1319043693322400],
  [19.910739,-100.484568,94.9510173323285600,1327,93.3333333333333300],
  [19.897412,-100.585416,95.9790209790209800,572,88.5057471264367800],
  [32.056491,-113.586629,85.1239669421487700,121,100.0],
  [20.683088,-103.455363,97.1046770601336300,898,58.1818181818181800],
  [20.638846,-103.344092,91.7763157894736800,2432,88.1318681318681300],
  [19.899515,-100.87739,96.0079840319361300,501,94.5165945165945200],
  [31.988877,-113.288621,86.3636363636363600,110,100.0],
  [19.893694,-101.154793,97.2222222222222200,576,84.7965738758029900],
  [31.938868,-113.013903,99.099099099099100,111,100.0],
  [31.909143,-112.915988,96.0937500,128,100.0],
  [19.884606,-101.512888,94.7743467933491700,421,91.9753086419753100],
  [19.9534759,-101.653775,97.1982758620689600,464,95.8904109589041200],
  [31.638579,-112.809778,93.536121673003800,263,90.4761904761904800],
  [18.499561,-96.045475,97.3045822102425900,371,82.1428571428571400],
  [20.548085,-103.3015,90.1032395870416500,2809,62.8230616302186900],
  [20.108804,-102.038496,97.7900552486187900,362,93.6619718309859100],
  [31.464385,-112.679816,91.5094339622641500,212,100.0],
  [31.417947,-112.644898,89.3518518518518500,216,100.0],
  [20.181759,-102.277816,97.1428571428571400,350,93.7500],
  [17.7191866,-92.6001599,89.4736842105263200,19,100.0],
  [20.391091,-103.317537,94.2857142857142800,420,82.913669064748200],
  [30.684611,-111.773481,97.1631205673758800,141,100.0],
  [20.445185,-103.548408,95.5017301038062300,867,80.1568627450980300],
  [20.508306,-103.633731,70.5357142857142800,224,90.9090909090909100],
  [20.612003,-103.677268,85.2459016393442700,244,87.500],
  [20.702727,-103.709873,94.7540983606557400,305,77.7777777777777800],
  [25.4821719,-101.480442,98.4126984126984200,63,100.0],
  [25.508695,-101.5782849,88.8888888888888800,63,100.0],
  [25.52419,-101.635538,95.312500,64,100.0],
  [25.842016,-100.247856,83.8095238095238100,19635,89.7959183673469400],
  [25.603126,-101.907509,95.1612903225806500,62,100.0],
  [30.518448,-111.108738,94.7159841479524500,757,75.00],
  [30.411988,-111.090562,97.5155279503105500,161,100.0],
  [30.246243,-111.097994,99.342105263157900,152,100.0],
  [30.19019,-111.097648,98.7500,160,100.0],
  [30.023557,-111.104591,90.366350067842600,737,100.0],
  [29.915601,-111.117279,96.894409937888200,161,100.0],
  [25.67064,-102.525631,97.2222222222222200,72,100.0],
  [24.5238616,-99.5404566,100.0,3,89.4736842105263200],
  [29.4928,-111.000546,98.7421383647798700,159,100.0],
  [24.1960083,-99.3558366,100.0,2,70.0],
  [25.544932,-103.059519,95.3846153846153800,65,100.0],
  [23.7640133,-99.0123766,100.0,3,92.1052631578947300],
  [22.970875,-98.6435583,100.0,15,88.5714285714285700],
  [22.8598516,-98.4738833,100.0,16,100.0],
  [22.7502866,-98.3045816,100.0,17,65.7142857142857100],
  [22.4364666,-97.9635249,100.0,25,83.6734693877551100],
  [22.358045,-97.890865,79.4007490636704100,267,79.5937211449676800],
  [22.2058216,-97.85463,97.4358974358974300,117,86.554621848739500],
  [22.01465,-97.78148,97.1428571428571400,35,92.682926829268300],
  [21.7738716,-97.7892616,92.8571428571428600,42,63.8888888888888800],
  [21.6268916,-97.7705966,100.0,25,100.0],
  [21.2920716,-97.6198733,93.1623931623931600,234,100.0],
  [22.1925433,-100.8285333,92.5242718446601900,1030,93.6507936507936500],
  [25.587897,-103.252234,98.0769230769230700,52,100.0],
  [25.1189399,-100.6728666,93.8251804330392900,1247,96.9696969696969700],
  [24.8596916,-100.3698166,94.7955390334572500,807,98.3333333333333300],
  [23.46899,-102.95935,85.7142857142857200,147,100.0],
  [23.5948166,-102.9597499,81.9548872180451100,133,100.0],
  [23.64114,-102.9705833,85.7142857142857200,126,85.7142857142857200],
  [23.8840266,-103.1025333,85.5555555555555600,180,77.7777777777777800],
  [23.9822866,-103.20145,75.9259259259259300,108,16.66666666666666300],
  [24.0660399,-103.2611333,75.221238938053100,113,75.00],
  [17.8183466,-95.0115666,75.7462686567164200,268,38.63636363636363500],
  [24.1697899,-103.3354333,94.696969696969700,132,100.0],
  [17.715795,-95.0782516,38.5496183206106900,262,13.3333333333333300],
  [19.6738766,-99.1218416,90.4761904761904800,882,90.254237288135600],
  [17.14554,-95.0743416,72.1088435374149700,147,67.0886075949367100],
  [16.5977016,-94.9456966,39.0243902439024400,246,55.5555555555555600],
  [25.0149166,-103.7257333,89.7777777777777800,450,100.0],
  [16.4977566,-94.4556566,100.0,6,74.3589743589743600],
  [16.4765433,-94.3417799,100.0,5,76.6233766233766300],
  [16.4025333,-94.2152833,100.0,4,41.46341463414634500],
  [16.3036666,-94.1862016,100.0,3,79.6296296296296300],
  [25.5958,-103.5390166,82.1818181818181800,275,80.0],
  [25.8492516,-103.6043666,77.0916334661354600,502,0],
  [26.0218666,-103.6517333,93.1297709923664100,131,100.0],
  [26.1574066,-103.7328666,94.308943089430900,123,100.0],
  [26.4169499,-103.9529833,92.8571428571428600,112,100.0],
  [26.4976566,-103.9995833,91.5094339622641500,106,66.6666666666666700],
  [26.5835283,-104.0538166,88.9400921658986200,217,100.0],
  [26.7297016,-104.2612333,96.4285714285714300,112,100.0],
  [26.8588116,-104.4797666,83.9160839160839200,143,100.0],
  [26.9545249,-104.6455666,78.260869565217400,138,100.0],
  [27.6663916,-105.15405,89.2787524366471800,513,100.0],
  [15.5159266,-92.9624266,92.4369747899159700,119,71.4285714285714300],
  [27.8852916,-105.24105,96.1538461538461600,130,100.0],
  [15.361275,-92.739475,96.87500,64,81.2500],
  [15.2189466,-92.5770333,92.1052631578947300,76,73.4693877551020300],
  [15.181155,-92.54909,100.0,90,77.7777777777777800],
  [24.58115,-104.015621,100.0,45,50.0],
  [28.5814616,-105.9588333,81.1320754716981200,477,100.0],
  [24.34087,-104.302283,91.3043478260869600,46,100.0],
  [24.325352,-104.347557,90.3846153846153900,52,100.0],
  [14.723095,-92.1635049,91.6666666666666600,12,73.7288135593220400],
  [24.2083939,-104.477919,100.0,56,100.0],
  [24.121051,-104.543311,93.5239745811669600,17310,100.0],
  [24.092759,-104.587127,93.5503448275862100,18125,50.0],
  [23.354695,-100.5780999,93.0527383367139900,1972,96.7213114754098300],
  [20.51608,-101.0358333,93.548387096774200,31,100.0],
  [20.5659716,-101.2244166,95.2631578947368400,570,89.4736842105263200],
  [20.49622,-101.48295,91.7582417582417500,182,95.8333333333333400],
  [19.538801,-99.059489,90.4501216545012100,1644,80.9986130374479900],
  [19.30972,-99.34231,94.8863636363636400,176,85.1851851851851900],
  [20.9499133,-101.50845,87.9765395894428100,341,75.6097560975609800],
  [21.0844566,-101.75315,92.5438596491228100,228,89.655172413793100],
  [21.2005583,-101.9011499,96.2686567164179100,134,84.00],
  [21.2983499,-102.0164999,92.384105960264900,302,100.0],
  [21.222225,-102.3159833,89.9061032863849800,426,85.6269113149847100],
  [21.1158791,-102.45993329999999,91.7410714285714300,448,85.8407079646017700],
  [21.0472899,-102.5341666,91.2280701754385900,228,83.4532374100719500],
  [20.7582,-102.8630666,95.1754385964912200,228,85.0340136054421800],
  [20.662595,-103.0472499,94.495412844036700,218,85.0877192982456100],
  [20.610953,-100.419379,93.8683142354251200,27839,85.2272727272727300],
  [20.665749,-100.4329379,93.7410160581543400,24349,85.7142857142857200],
  [20.6664299,-103.3968166,97.070312500,512,100.0],
  [20.574110000000008,-103.21711659999997,91.9230769230769200,520,73.8095238095238100],
  [21.51216,-102.23495,82.4267782426778200,239,77.3437500],
  [21.72379,-102.2772666,80.4721030042918400,466,76.5217391304347800],
  [22.8021366,-100.4881333,91.5531335149863700,734,75.7575757575757600],
  [20.288525,-99.958903,89.4915254237288100,2655,83.7500],
  [19.0196566,-99.1370066,100.0,9,100.0],
  [24.2948716,-100.3850499,95.2380952380952300,819,87.500],
  [24.6311916,-100.2828166,95.2514948997537900,2843,98.1191222570532900],
  [25.0580666,-100.5927333,93.5650540159699400,2129,93.548387096774200],
  [25.5309316,-100.8637666,91.9484702093397700,1863,85.00],
  [23.3037716,-100.5637666,93.588082901554400,1544,93.4065934065934100],
  [17.617233,-95.083972,49.1071428571428600,112,4.76190476190476700],
  [17.570763,-95.09869,46.28571428571428600,175,3.70370370370370900],
  [17.525497,-95.087079,64.4736842105263200,152,27.08333333333333700],
  [17.474828,-95.086192,73.4042553191489400,94,5.263157894736847400],
  [16.9497329,-95.012552,45.1327433628318600,226,16.12903225806451300],
  [20.72446,-101.3374,94.6043165467625900,278,80.9523809523809500],
  [25.771684,-100.125249,91.2322274881516600,422,76.5463917525773100],
  [19.847115,-101.15005,96.30996309963100,271,82.4561403508771900],
  [19.8634983,-100.1006999,93.9244663382594400,609,94.0311418685121100],
  [25.3357466,-100.7958833,90.5817174515235500,1444,90.7692307692307700],
  [25.6798966,-100.66525,96.6756513926325200,1113,86.8055555555555600],
  [21.4899649,-101.7637166,94.9494949494949500,99,41.9491525423728800],
  [20.81895,-101.3203666,94.171779141104300,652,98.7951807228915600],
  [20.36571,-102.1341999,85.7461024498886400,449,87.1794871794871800],
  [20.8708183,-101.3314,91.1392405063291100,237,88.5714285714285700],
  [20.57628,-100.9399666,96.8208092485549200,346,96.5811965811965800],
  [21.3634,-101.2612,83.3333333333333400,84,91.1111111111111100],
  [20.7901783,-101.4217833,93.4579439252336400,107,76.4705882352941100],
  [20.61701,-101.229,95.4415954415954400,702,87.9591836734693900],
  [19.4912266,-99.0470516,92.8378378378378300,740,78.12500],
  [25.428425,-100.9636,84.4897959183673500,490,100.0],
  [25.26346,-101.1009,90.200,500,77.4193548387096800],
  [25.30163,-101.0767833,87.500,160,81.5789473684210500],
  [25.452035,-101.0030666,68.2065217391304300,368,82.550335570469800],
  [25.9620966,-100.2765,84.00,50,85.4304635761589400],
  [21.83164,-100.9286666,90.6976744186046600,43,100.0],
  [28.331975,-105.554816,94.0298507462686600,134,100.0],
  [28.683067,-105.9278269,95.7446808510638300,94,100.0],
  [29.269068,-106.388855,86.1538461538461600,65,100.0],
  [19.379483,-99.060763,85.2641334569045500,1079,89.1191709844559600],
  [19.659674,-99.063538,91.9583727530747400,1057,96.3636363636363600],
  [19.521677,-99.228526,94.9139865370231900,1337,87.9699248120300800],
  [19.604139,-99.242517,83.7945112270356100,8818,77.637947725072600],
  [20.293332,-102.633437,100.0,32,100.0],
  [19.338788,-99.527744,88.3870967741935500,155,89.8203592814371200],
  [22.6170699,-98.206038,100.0,17,84.8101265822784800],
  [22.164219,-97.828968,95.8333333333333400,72,91.3043478260869600],
  [23.49331,-98.873757,100.0,15,76.9230769230769200],
  [23.391199,-99.42111,100.0,16,91.6666666666666600],
  [19.3669916,-99.008615,88.00,875,82.7586206896551700],
  [19.1749183,-96.2374633,81.2362030905077300,453,74.4888140485927300],
  [19.3524366,-96.3289233,92.3076923076923100,26,78.1662781662781700],
  [19.3391833,-96.4893233,88.00,25,86.2220717670954700],
  [19.5075666,-97.4979033,100.0,14,74.8738081884464400],
  [19.4110116,-97.652425,100.0,16,64.0226628895184100],
  [19.3306383,-97.925175,95.4545454545454600,22,70.6896551724137900],
  [19.623335,-99.2836649,97.849462365591400,93,63.6363636363636400],
  [22.7508899,-102.4876166,92.89428076256500,577,51.5873015873015800],
  [21.775470000000006,-102.30780000000001,81.2389380530973400,565,71.2871287128712800],
  [21.4015,-102.2138833,89.2857142857142900,56,70.0],
  [19.948417,-101.156812,94.7368421052631600,19,95.7845433255269300],
  [22.0329033,-102.3218333,94.6502057613168700,243,84.8484848484848500],
  [22.8036299,-102.6244333,84.6153846153846100,39,64.8648648648648700],
  [22.6817916,-102.4503,88.079470198675500,151,58.0645161290322500],
  [22.002245,-102.3592333,85.8823529411764800,85,71.87500],
  [22.1443466,-102.2917833,96.9230769230769200,65,93.4782608695652200],
  [21.89236,-102.3974499,92.00,50,63.157894736842100],
  [20.4635983,-101.5198999,95.2631578947368400,190,90.0],
  [21.36421,-102.1236166,80.4347826086956500,46,100.0],
  [21.07092,-101.3950666,81.0810810810810700,74,15.64625850340135600],
  [27.145958,-104.929849,92.1985815602836900,282,100.0],
  [22.36289,-102.29575,95.4545454545454600,110,85.9459459459459500],
  [20.49623,-100.6619666,85.1785714285714300,560,100.0],
  [21.13426,-101.3856833,80.0,5,6.52173913043477800],
  [27.08278,-99.71513,94.6808510638297900,564,100.0],
  [25.7108366,-100.2915166,94.8478312964294300,4173,94.0594059405940500],
  [19.4321216,-101.8933166,97.0149253731343300,67,77.9340028694404600],
  [19.6876066,-101.3011499,92.682926829268300,205,56.7353407290015800],
  [19.6774866,-101.3582666,94.605809128630700,241,84.0521564694082200],
  [19.340175,-99.60171,79.7468354430379800,158,78.8537549407114600],
  [20.6227533,-100.2226833,94.328922495274100,1058,92.2382671480144400],
  [21.2380566,-101.93575,96.3768115942028900,138,100.0],
  [20.3942999,-103.5581333,97.8902953586497900,237,78.85279889426400],
  [19.3272833,-103.5895499,97.4683544303797400,79,88.0733944954128500],
  [19.2991716,-103.6375666,94.2528735632183900,87,95.291479820627800],
  [18.92756792352942,-104.08065633823531,96.9696969696969700,66,87.7659574468085100],
  [19.7614083,-103.5193833,98.7421383647798700,159,91.3043478260869600],
  [25.9759166,-100.2133,95.104895104895100,858,94.3820224719101100],
  [26.97328,-99.7724616,96.7272727272727300,550,100.0],
  [26.6590399,-99.9341766,94.3223443223443200,546,100.0],
  [19.84818,-101.0112166,95.26066350710900,422,94.9494949494949500],
  [19.8539233,-101.2762,79.4117647058823600,68,56.8493150684931600],
  [20.4632333,-103.4407666,95.8810068649885600,874,75.00],
  [20.4760816,-103.5011833,92.0903954802259800,1239,74.2857142857142900],
  [20.6585049,-103.7336833,97.6851851851851900,216,100.0],
  [27.4421883,-99.5224333,96.1089307935292500,20894,100.0],
  [25.4055733,-101.0660166,95.312500,64,62.2222222222222200],
  [25.667924999999997,-100.09176660000001,80.3278688524590200,183,77.1084337349397600],
  [25.623991599999997,-100.06588330000001,92.9824561403508800,57,79.797979797979800],
  [20.7293099,-103.3474333,97.1962616822429900,107,88.00],
  [20.5315816,-100.5081499,92.2297297297297300,296,48.0314960629921300],
  [19.9348666,-99.2374216,83.092394720301700,1591,58.8235294117647100],
  [17.7669716,-91.8483116,80.0,15,80.0],
  [18.6018066,-90.69865,100.0,24,100.0],
  [18.4586866,-88.9340466,96.5517241379310400,29,100.0],
  [18.5936916,-88.4473666,66.6666666666666700,3,100.0],
  [20.2047633,-87.4825849,52.6315789473684300,38,91.6666666666666600],
  [19.743476599999997,-87.918355,50.0,2,0],
  [32.584399,-115.593382,94.6428571428571400,224,100.0],
  [17.911538,-94.912831,88.4067796610169500,1475,72.7272727272727300],
  [17.802638,-91.8167579,61.5384615384615400,13,75.00],
  [20.799895,-100.41195,91.5029761904761900,6720,87.1700562527044500],
  [19.104295,-91.0185429,53.4313725490196100,204,50.0],
  [19.138547,-90.964156,80.6584362139917700,243,100.0],
  [25.021736,-107.81415,95.8762886597938200,291,100.0],
  [18.0132733,-94.51222,92.8327645051194600,586,100.0],
  [17.98596,-94.614505,82.387312186978300,1198,100.0],
  [19.6031316,-99.18884,83.8032998507402400,24789,81.825187142228100],
  [20.4053799,-100.0323833,93.3618139993427600,3043,78.0238500851788700],
  [17.9270133,-94.7495816,95.502645502645500,378,100.0],
  [19.289455,-90.775775,90.3328050713153700,631,100.0],
  [20.186482283908052,-99.77065188358542,93.2105263157894800,1900,65.9459459459459400],
  [17.926875,-94.7962333,95.4887218045112800,399,100.0],
  [19.6583716,-99.197965,76.2250744166693400,31243,83.422851562500],
  [24.88803,-107.679341,96.9026548672566300,226,100.0],
  [19.7300016,-99.2099033,86.7942128977752700,56816,87.7540106951871700],
  [19.8018,-99.2340966,93.8110181311018100,5736,93.4782608695652200],
  [17.9385633,-95.0467366,90.6336088154269900,363,80.6451612903225800],
  [24.788983,-107.581996,93.6430317848410800,409,100.0],
  [25.90469,-100.2848666,93.5975609756097600,656,79.7619047619047700],
  [17.9821466,-93.16048,86.3356288510329900,2759,100.0],
  [17.9780349,-93.0698366,93.6073059360730600,438,100.0],
  [17.948575766666664,-92.78593887777777,93.1034482758620700,29,100.0],
  [18.6192,-92.007486,92.3547400611620800,327,100.0],
  [17.8380266,-92.7225316,80.9523809523809500,21,100.0],
  [18.619166,-92.102876,91.2500,960,100.0],
  [17.7733866,-92.6717049,76.4705882352941100,17,100.0],
  [18.616208,-92.200604,87.2516556291390700,604,100.0],
  [18.619736,-92.262217,94.2857142857142800,315,100.0],
  [18.646529,-92.438288,92.1241050119331800,419,100.0],
  [17.8588466,-91.7842299,35.00,20,95.8333333333333400],
  [20.4216566,-100.4726,84.3023255813953400,516,91.3043478260869600],
  [18.098585,-91.6274566,15.7894736842105300,19,100.0],
  [32.554192,-116.596774,95.4545454545454600,88,93.7500],
  [32.511672,-116.2754,94.8275862068965500,116,100.0],
  [18.2560566,-91.3027816,47.61904761904761600,21,0],
  [18.5474566,-91.0720316,82.7586206896551700,29,100.0],
  [18.58362,-91.0433316,85.00,20,100.0],
  [32.5330749,-115.486614,97.2222222222222200,108,100.0],
  [19.885777,-100.317986,98.0237154150197600,506,91.2031047865459200],
  [19.7593883,-98.7507083,96.9696969696969700,132,94.3396226415094400],
  [20.820263,-103.79765,90.2777777777777800,576,88.8888888888888800],
  [31.324919,-112.574946,83.9416058394160600,137,100.0],
  [31.033116,-112.3932,96.0526315789473700,152,100.0],
  [25.6273466,-100.8122,90.9090909090909100,1342,86.5384615384615400],
  [25.4367519,-101.268795,99.2031872509960200,251,100.0],
  [20.425114,-102.771679,92.031425364758700,891,74.2765273311897100],
  [25.6979833,-100.5481333,89.7129186602870800,2090,74.050632911392400],
  [25.6853849,-100.4899666,81.6037735849056600,636,64.6464646464646400],
  [30.881733,-112.303024,95.1724137931034500,145,100.0],
  [30.706668,-112.12344,94.0594059405940500,404,75.00],
  [25.47889,-100.1793999,100.0,1,57.446808510638300],
  [30.690484,-112.026421,97.6958525345622100,217,50.0],
  [20.657602,-103.681467,92.3636363636363600,275,100.0],
  [25.212505,-99.9216866,100.0,3,72.2222222222222200],
  [30.078119,-111.097417,90.4632152588555900,734,100.0],
  [25.03034,-99.7619416,50.0,2,83.7837837837837800],
  [24.9137266,-99.6856033,100.0,2,89.696969696969700],
  [24.8872716,-99.6422683,100.0,3,100.0],
  [24.8794916,-99.5853633,100.0,6,80.5714285714285700],
  [25.605651,-100.922619,89.7178683385579900,1595,51.7721518987341800],
  [25.505707,-101.016757,76.7726161369193100,409,75.2941176470588200],
  [25.655354,-102.724538,100.0,45,100.0],
  [24.4711399,-99.493075,100.0,2,85.2941176470588200],
  [25.648836,-102.8194989,96.6666666666666700,60,100.0],
  [24.126995,-99.2516299,88.8888888888888800,9,92.9577464788732400],
  [25.539745,-103.1968629,96.1937716262975800,289,100.0],
  [24.0098316,-99.1104166,100.0,1,97.500],
  [23.8898699,-99.0661016,100.0,2,97.1428571428571400],
  [23.4031766,-98.8128816,100.0,15,82.9787234042553200],
  [23.1862016,-98.7719666,93.7500,16,82.5870646766169200],
  [22.9917899,-98.6904816,100.0,15,73.5849056603773600],
  [22.8842816,-98.5141933,100.0,15,79.3103448275862100],
  [22.2748299,-100.8810333,88.6666666666666700,150,59.0909090909090800],
  [22.4086866,-101.404,89.37500,160,0],
  [25.6576239,-103.436884,97.7777777777777700,45,100.0],
  [24.9541966,-100.4631166,95.6521739130434800,782,75.4285714285714200],
  [24.8121866,-100.3319166,95.6097560975609700,820,92.4242424242424200],
  [24.40109,-100.3852666,93.5589519650655100,916,86.8217054263565900],
  [25.756746,-103.570093,77.168949771689500,219,0],
  [20.0761166,-96.7078616,100.0,3,44.4444444444444400],
  [25.798942,-103.586245,76.434426229508200,488,0],
  [20.0311083,-96.65354,100.0,8,88.8888888888888800],
  [23.8854116,-100.4812333,93.707093821510300,874,95.7746478873239500],
  [23.7549416,-100.5896999,93.1140801644398700,973,95.5056179775280900],
  [23.544305,-100.6261666,94.5825932504440500,1126,62.3931623931623900],
  [19.6825266,-96.4049166,75.00,8,19.35483870967742400],
  [23.0484566,-100.49195,92.8759894459102900,758,82.6666666666666700],
  [22.8472016,-102.5633,93.9759036144578300,249,82.0652173913043500],
  [19.0550316,-96.218195,72.3404255319148900,141,71.4285714285714300],
  [19.0112416,-96.2485999,98.3870967741935500,62,82.0512820512820500],
  [22.8763016,-102.6378166,86.4864864864864900,296,73.2142857142857200],
  [22.4095283,-100.72665,93.6920222634508400,1078,85.9872611464968200],
  [18.8778366,-96.347055,98.1481481481481500,54,81.2500],
  [24.2155866,-103.3683333,90.6976744186046600,129,100.0],
  [17.3214466,-95.0516683,50.0,156,0],
  [24.5960799,-103.5284833,87.500,136,100.0],
  [16.5719533,-94.7267716,100.0,2,80.0],
  [16.5153366,-94.50567,100.0,3,67.8571428571428600],
  [25.20549,-103.6750166,89.5238095238095300,210,100.0],
  [25.3152316,-103.6435499,92.3497267759562900,183,100.0],
  [29.148254,-110.870465,85.6666666666666700,300,100.0],
  [29.100076,-110.849953,93.2098765432098800,162,100.0],
  [25.4115016,-103.6481666,90.0,200,100.0],
  [16.2368066,-93.999295,100.0,5,36.36363636363636500],
  [25.9178866,-103.6203666,82.0754716981132100,212,72.7272727272727300],
  [25.634214,-102.862758,95.8620689655172400,435,88.2352941176470600],
  [15.90182,-93.5148249,97.7777777777777700,45,75.00],
  [15.8195016,-93.42019,73.684210526315800,38,30.43478260869565400],
  [15.690305,-93.2065116,95.00,60,62.7450980392156900],
  [26.5381816,-104.02295,92.8571428571428600,112,100.0],
  [26.9992449,-104.7093833,76.859504132231400,121,100.0],
  [27.0846166,-104.8446166,91.1931818181818100,352,40.0],
  [27.5835866,-105.0810666,95.8333333333333400,120,100.0],
  [27.7801933,-105.1910666,89.4039735099337800,151,100.0],
  [27.9752199,-105.2906333,93.6170212765957500,141,100.0],
  [24.883628,-103.784316,100.0,45,0],
  [28.2017216,-105.4530833,83.5132482826300300,1019,100.0],
  [15.1573699,-92.5015766,93.6619718309859100,142,72.500],
  [28.25434,-105.4671166,92.7272727272727200,165,100.0],
  [28.3604666,-105.59365,93.2432432432432500,148,100.0],
  [24.65769,-103.938746,88.2352941176470600,34,100.0],
  [28.5051266,-105.8975666,90.3100775193798500,258,100.0],
  [22.0085866,-101.2480166,100.0,26,90.0],
  [22.097995,-101.1622,95.8333333333333400,48,63.5761589403973500],
  [21.9252499,-101.364,95.0617283950617300,81,77.2727272727272700],
  [21.662175,-101.5849499,95.3488372093023300,86,73.9495798319327800],
  [21.403505,-101.8606499,88.0341880341880300,117,76.9911504424778700],
  [21.97203,-100.8871,80.9523809523809500,294,80.1754385964912300],
  [25.8830366,-100.2218833,92.8808664259927800,6925,95.4545454545454600],
  [21.9056299,-101.207,76.1904761904761900,21,60.0],
  [28.983413,-106.300691,81.3333333333333400,75,100.0],
  [29.025355,-106.322455,72.9729729729729700,74,100.0],
  [29.069061,-106.334128,73.8461538461538500,65,33.33333333333333700],
  [29.174928,-106.370854,86.5671641791044800,67,100.0],
  [29.224464,-106.382623,93.7500,64,100.0],
  [29.336787,-106.407938,87.500,64,100.0],
  [29.447224,-106.407253,90.9090909090909100,66,66.6666666666666700],
  [29.56493,-106.363297,42.8571428571428600,42,0],
  [29.671011,-106.344378,90.1960784313725500,51,66.6666666666666700],
  [29.723526,-106.3629179,94.7368421052631600,57,100.0],
  [29.77604,-106.381406,89.655172413793100,58,100.0],
  [30.209404,-106.4610639,100.0,52,100.0],
  [30.261274,-106.479638,100.0,51,100.0],
  [20.6713466,-100.3038666,90.7022106631989600,1538,84.00],
  [23.702611,-98.990001,100.0,18,60.897435897435900],
  [23.61403,-99.154504,61.9047619047619100,21,19.99999999999999600],
  [23.608244,-99.283883,80.0,15,62.1621621621621600],
  [23.59095,-99.330202,87.500,16,92.8571428571428600],
  [23.46125,-99.360703,82.3529411764705800,17,100.0],
  [23.307687,-99.557266,93.7500,16,78.9473684210526300],
  [23.053246,-99.7057559,100.0,15,100.0],
  [23.002008,-99.72727,100.0,15,59.0909090909090800],
  [22.9548099,-99.757364,100.0,14,87.500],
  [22.91085,-99.7943839,100.0,14,100.0],
  [22.826473,-99.889162,100.0,15,57.1428571428571400],
  [23.298625,-99.630611,87.500,16,68.7500],
  [23.583962,-99.239045,100.0,16,58.3333333333333300],
  [22.855932,-100.141317,85.7142857142857200,7,26.31578947368421500],
  [22.864404,-100.198535,19.99999999999999600,5,0],
  [20.190085,-101.177797,80.0,10,89.3909626719056900],
  [20.237817,-101.20469,63.6363636363636400,11,92.7342256214149100],
  [20.395778,-101.144591,54.5454545454545400,11,80.0],
  [20.450736,-101.136234,88.8888888888888800,9,91.1980440097799500],
  [22.4092816,-102.31565,97.4137931034482800,116,89.1891891891891900],
  [19.422313,-99.459884,95.1612903225806500,372,23.85321100917431300],
  [19.432137,-99.3534449,95.4545454545454600,22,43.15789473684210600],
  [19.1324016,-96.1159049,77.9614325068870500,363,67.1532846715328500],
  [19.5713233,-96.867375,66.6666666666666700,6,47.89594491201224500],
  [19.60982,-96.9354266,97.0588235294117600,34,62.1722846441947500],
  [19.6383366,-96.9940949,100.0,16,48.41498559077810300],
  [19.6253466,-97.053785,100.0,12,64.9027589326096800],
  [19.6298766,-97.1074666,85.7142857142857200,7,88.1964117091595800],
  [19.6059883,-97.2247399,100.0,4,71.7247097844112800],
  [19.561095,-97.3071049,66.6666666666666700,6,88.9774236387782200],
  [19.5413416,-97.3646566,80.0,10,62.1523579201934700],
  [19.4900883,-97.55063,100.0,15,86.3402061855670100],
  [19.4489016,-97.5971583,100.0,10,92.7419354838709600],
  [20.2971783,-103.5575666,98.8636363636363600,88,96.30996309963100],
  [19.8686433,-103.5105333,95.3703703703703700,108,66.0120845921450200],
  [19.26725,-103.67885,94.9675324675324700,616,82.9268292682926800],
  [19.2266083,-103.7092333,94.190871369294600,482,78.7368421052631600],
  [21.7936933,-100.7110999,84.8156182212581400,1383,78.0701754385964900],
  [27.3492816,-99.5727899,95.8921694480102700,779,100.0],
  [19.4242466,-96.6505849,100.0,28,89.940239043824700],
  [19.4678333,-96.7676266,94.7368421052631600,19,85.4497354497354500],
  [19.3769933,-97.7143316,100.0,26,77.2151898734177200],
  [19.3984516,-98.0657216,74.1935483870967700,31,94.3283582089552200],
  [19.3817066,-98.1325883,82.1428571428571400,56,86.3057324840764400],
  [19.4677383,-98.3331116,100.0,9,75.8064516129032300],
  [19.5938966,-98.5961366,98.8095238095238100,84,77.1428571428571500],
  [19.5698733,-98.6525549,80.0,10,12.87128712871287200],
  [19.5606433,-98.6970599,80.0,10,6.2500],
  [19.5571083,-98.7471866,100.0,8,58.8235294117647100],
  [19.5497133,-98.8170866,100.0,4,59.0909090909090800],
  [26.75209,-99.9023799,93.4523809523809500,504,100.0],
  [26.86123,-99.84479,94.4444444444444400,954,100.0],
  [27.18682,-99.6594199,94.2567567567567500,592,100.0],
  [27.30951,-99.59389,94.8535564853556500,2390,90.0],
  [27.48507,-99.62796,92.7927927927927900,222,100.0],
  [27.43113,-99.6442,94.7368421052631600,114,100.0],
  [26.9240183,-99.79968,94.9194547707558800,807,81.8181818181818100],
  [25.729579968750002,-100.0924811875,94.00,50,55.1724137931034500],
  [19.4872649,-101.7206166,100.0,54,88.025659301496800],
  [19.5764849,-101.4813833,96.1538461538461600,52,89.2825509300265700],
  [19.6067633,-101.44585,96.774193548387100,62,85.3568800588668100],
  [19.7002749,-101.2429666,93.7321937321937400,2106,92.9113924050632900],
  [21.3022983,-102.1070999,98.0158730158730100,252,87.6543209876543200],
  [19.1038116,-104.3311333,94.8795180722891600,664,52.7272727272727300],
  [19.803755,-103.5023499,96.3414634146341400,82,75.8904109589041100],
  [26.023635,-100.1962499,95.3900709219858100,564,100.0],
  [26.0706883,-100.17845,94.0063091482649900,634,100.0],
  [26.348675,-100.0686166,95.3571428571428500,560,100.0],
  [26.617935,-99.9549366,94.3119266055045800,545,100.0],
  [20.4397383,-103.3538833,96.2616822429906500,107,80.0],
  [20.522285,-103.4812666,91.2240184757505800,433,93.4782608695652200],
  [20.57331,-103.4540333,98.3193277310924300,357,91.150442477876100],
  [19.1081783,-101.9993333,95.6521739130434800,23,70.9781729991915900],
  [19.1782799,-101.9427333,100.0,12,90.5882352941176500],
  [18.7572583,-102.0103999,85.7142857142857200,14,85.8543417366946800],
  [21.984781,-100.003827,100.0,5,93.548387096774200],
  [21.952232,-98.836439,92.8571428571428600,14,86.6666666666666700],
  [18.0140033,-94.4445866,96.3793103448275800,580,100.0],
  [19.707172,-90.626036,75.00,284,100.0],
  [20.023718,-90.248773,96.4052287581699400,306,100.0],
  [25.611699,-108.534003,97.6562500,256,100.0],
  [20.3764699,-102.9314833,100.0,27,90.9090909090909100],
  [20.1109566,-99.7101416,88.1871345029239800,2565,66.8789808917197500],
  [20.0787616,-99.6174366,93.2811211871393300,2426,81.3008130081300800],
  [20.2730166,-102.4137333,95.4342984409799600,1796,96.1722488038277500],
  [20.0520766,-99.40159,81.7500,400,73.8372093023255800],
  [20.3886266,-101.9537333,93.415637860082300,243,78.9473684210526300],
  [19.9603066,-99.07705,86.4686468646864700,303,91.9254658385093100],
  [21.456645,-100.7131333,90.019762845849800,1012,92.00],
  [18.8450183,-97.4387583,97.4315068493150600,584,85.1851851851851900],
  [19.1705266,-98.3024366,94.4809461235216800,761,75.565610859728500],
  [24.502649,-107.373982,98.5835694050991500,353,100.0],
  [22.702572,-105.607195,96.9512195121951200,328,66.6666666666666700],
  [20.4674616,-100.8088666,80.7453416149068300,1288,88.8888888888888800],
  [19.057529166666665,-91.08966291666668,53.4136546184738900,249,66.6666666666666700],
  [18.884299,-91.401245,72.1774193548387100,248,100.0],
  [18.0025916,-93.6310699,87.3417721518987300,395,57.1428571428571400],
  [20.411703,-97.200969,100.0,9,81.2500],
  [18.9573,-104.1405666,100.0,17,95.0248756218905500],
  [25.155943,-107.967599,94.0711462450592900,253,100.0],
  [20.5361899,-100.6874166,84.6007604562737600,1052,94.3396226415094400],
  [19.8912066,-98.7682216,90.0673400673400600,594,91.00529100529100],
  [22.0767016,-100.8910666,85.1129861313160700,10311,85.0729517396184100],
  [18.3372,-95.8257816,93.6539746158984600,1497,79.7687861271676200],
  [18.47309,-96.0075866,96.1956521739130400,368,80.6451612903225800],
  [18.5738733,-96.16543,93.2153392330383500,339,67.7419354838709700],
  [18.635625,-96.2576749,92.9313929313929300,481,60.0],
  [18.7854583,-96.5225466,84.0277777777777800,432,48.3870967741935500],
  [21.439695,-97.696902,91.3043478260869600,23,95.8333333333333400],
  [21.491908,-97.716279,86.3636363636363600,22,87.500],
  [22.463622,-97.923741,100.0,10,56.2693498452012400],
  [22.940275,-98.604532,100.0,14,80.0],
  [23.539344,-98.906949,100.0,14,70.4545454545454600],
  [23.718248,-99.039643,100.0,16,90.9090909090909100],
  [23.386936,-99.4944,27.2727272727272700,11,0],
  [23.351043,-99.530632,38.4615384615384600,13,42.8571428571428600],
  [23.217468,-99.678763,100.0,15,100.0],
  [22.779127,-100.035416,100.0,7,85.7142857142857200],
  [22.867259,-100.243545,50.0,6,7.1428571428571400],
  [19.999039,-101.140593,100.0,17,92.753623188405800],
  [20.054187,-101.135834,100.0,12,90.6497622820919200],
  [22.0671599,-102.2790166,98.2905982905982900,234,95.3488372093023300],
  [22.2798066,-102.306,98.5507246376811600,69,74.5454545454545500],
  [22.6039333,-102.4093666,94.1441441441441500,222,90.2439024390243900],
  [21.8104533,-102.3494,94.5373467112597500,897,74.5098039215686300],
  [22.2376283,-102.2901333,100.0,67,78.6666666666666600],
  [19.406039,-99.097396,92.8870292887029300,1195,77.5299463031805100],
  [19.45165,-99.093028,94.2962281508739600,1087,74.1176470588235300],
  [19.470893,-99.227464,65.1347068145800200,631,83.4615384615384600],
  [19.341441,-99.30951,82.1167883211678900,274,88.7195121951219500],
  [19.299267,-99.391057,96.6942148760330600,121,90.8296943231441100],
  [19.378335,-99.561719,93.3021806853582600,642,83.5496813783337300],
  [19.4098219,-99.515195,95.82245430809400,383,81.2500],
  [24.709649,-107.537752,92.6153846153846100,325,100.0],
  [24.66894,-107.507527,91.8238993710691800,318,100.0],
  [18.89195,-96.973345,92.4629878869448100,743,67.500],
  [21.6836883,-100.6957166,80.9523809523809500,1365,68.3168316831683100],
  [20.6781616,-103.26825,66.8326693227091700,1004,89.8437500],
  [15.211702,-92.416235,17.60000000000000500,125,16.66666666666666300],
  [19.2577433,-99.66291,95.9183673469387700,49,66.6666666666666700],
  [25.6995633,-100.1800666,83.5062240663900400,1928,50.1813784764207900],
  [19.0992666,-98.0336433,94.1176470588235300,17,79.0393013100436600],
  [19.2704233,-96.2798383,100.0,12,90.0347624565469400],
  [23.518347,-102.106744,100.0,7,51.6129032258064500],
  [23.364179,-103.005149,100.0,21,100.0],
  [19.2447466,-103.75865,95.9276018099547500,221,29.57540263543191300],
  [22.177805,-100.146753,100.0,6,100.0],
  [18.28672,-101.9042,90.9090909090909100,11,83.4944237918215600],
  [19.3332716,-98.9698616,95.6097560975609700,205,15.11111111111110800],
  [19.15642,-96.19111,86.4864864864864900,74,93.8931297709923700],
  [19.21738,-96.2194066,95.500,200,87.9024114391889200],
  [19.208285,-96.1742666,96.842105263157900,285,72.4626263024167300],
  [19.378735,-96.3844283,88.8888888888888800,18,73.5652173913043500],
  [19.3512166,-96.435455,100.0,12,80.594059405940600],
  [19.5441266,-96.8208666,75.00,8,59.8532494758909900],
  [19.525105,-97.41067,100.0,12,54.5722713864306800],
  [19.3391466,-97.9745616,100.0,9,90.5454545454545500],
  [19.3707066,-98.026195,100.0,12,80.8510638297872400],
  [19.489095,-98.3785316,100.0,11,80.190930787589500],
  [22.75303,-102.5458833,92.419825072886300,343,79.104477611940300],
  [22.76091,-102.6032499,94.2528735632183900,435,57.2815533980582500],
  [21.8960483,-101.3231833,87.1794871794871800,39,64.2857142857142800],
  [22.55945,-102.3880499,88.5245901639344200,61,78.8888888888888900],
  [22.63734,-102.4416333,93.0434782608695600,230,78.3333333333333300],
  [19.838247,-98.950627,60.280373831775700,214,86.2500],
  [18.8229366,-96.7080316,93.9979123173277700,1916,74.1379310344827600],
  [15.293541,-92.657453,100.0,50,62.500],
  [15.259178,-92.615927,82.6923076923076900,52,40.0],
  [20.5064866,-103.2053333,93.0693069306930600,303,100.0],
  [20.4630233,-100.6236333,50.0,28,66.6666666666666700],
  [19.9952116,-99.2478166,100.0,5,0],
  [17.9866716,-91.6957616,2.56410256410256600,39,100.0],
  [18.5852316,-90.6493833,96.1538461538461600,26,100.0],
  [18.5740216,-90.5201466,7.1428571428571400,14,0],
  [18.64377,-90.29565,96.87500,32,25.00],
  [18.5385366,-89.889265,92.3076923076923100,13,60.0],
  [18.529905,-89.5897416,12.500,16,0],
  [18.4572066,-88.9801516,95.8333333333333400,24,100.0],
  [18.551095,-88.4671666,91.3043478260869600,23,100.0],
  [18.8502316,-88.27148,100.0,4,100.0],
  [18.8923616,-88.23509,75.00,4,100.0],
  [19.1321866,-88.1053466,66.6666666666666700,6,100.0],
  [20.2202233,-87.434245,50.0,32,80.0],
  [20.2873816,-87.3862366,57.1428571428571400,7,100.0],
  [19.5324833,-96.9056933,96.0912052117263800,307,85.4166666666666600],
  [23.233777,-102.355832,100.0,7,63.6363636363636400],
  [23.592795,-102.0195339,100.0,7,66.6666666666666700],
  [23.893256,-101.725977,0,3,0],
  [21.958562848981583,-102.34104163240526,95.3703703703703700,108,74.0740740740740700],
  [32.624676,-115.443685,100.0,14,87.500],
  [19.547655,-101.4402833,83.3333333333333400,6,93.6688311688311700],
  [18.9915216,-102.1012833,95.2380952380952300,21,78.4530386740331500],
  [18.8811349,-102.0792499,100.0,19,88.437500],
  [26.69206,-101.3826333,95.8333333333333400,144,50.0],
  [27.2299883,-101.2251833,100.0,4,50.0],
  [27.0359483,-101.3356333,50.0,2,100.0],
  [18.977968,-98.327693,92.9824561403508800,57,65.2173913043478300],
  [23.475371,-103.09793,92.8571428571428600,14,100.0],
  [23.748477,-104.045425,100.0,14,100.0],
  [24.951289,-101.083995,100.0,7,64.2857142857142800],
  [22.096387,-100.0836379,25.00,4,91.1764705882352900],
  [21.866151,-99.455946,52.1739130434782600,23,9.56521739130434800],
  [21.895201,-99.411087,16.00000000000000300,25,10.0719424460431700],
  [21.917473,-98.876929,80.0,15,78.5714285714285700],
  [22.182479,-98.277543,100.0,16,100.0],
  [22.1643599,-98.138642,85.7142857142857200,28,91.3043478260869600],
  [18.488028,-101.967108,100.0,1,18.015824710894700],
  [19.0634533,-102.0348999,100.0,23,89.5306859205776100],
  [19.54121,-99.1550599,83.3721277230677500,16755,70.0854700854700800],
  [24.994024,-107.775435,91.2087912087912100,364,100.0],
  [19.942417,-90.372784,88.0715705765407600,503,100.0],
  [25.7717649,-108.834064,97.0464135021097100,237,100.0],
  [25.544406,-103.379879,96.638845025941800,4433,72.7272727272727300],
  [20.166772,-90.140309,97.8346456692913400,508,100.0],
  [25.690555,-108.681672,98.7500,240,100.0],
  [20.4327866,-103.2412666,87.2424722662440500,1262,80.4270462633451900],
  [25.638399,-108.584189,97.9522184300341300,293,100.0],
  [20.26249,-99.92197,82.2853861613551600,3483,85.7142857142857200],
  [20.3655266,-102.8634333,100.0,28,100.0],
  [32.328395,-117.050237,92.1052631578947300,114,85.7142857142857200],
  [25.512333,-108.346413,89.7750511247443800,978,100.0],
  [20.88825,-89.700435,86.9191049913941500,581,100.0],
  [20.3393333,-102.1804,86.3741339491916900,433,83.6538461538461600],
  [25.27438,-108.067436,97.356828193832600,227,100.0],
  [20.4510366,-101.56405,95.6204379562043800,137,97.7528089887640400],
  [25.245258,-108.022411,97.4248927038626600,233,100.0],
  [21.5170966,-100.7348666,90.5435591958302400,1343,93.4426229508196800],
  [18.8698883,-97.3839733,89.349112426035500,845,82.0224719101123600],
  [20.721737394444563,-103.48645364999997,87.226463104325700,1965,89.5348837209302400],
  [18.829435,-97.4866816,93.9691444600280500,713,62.5899280575539600],
  [22.6984,-100.5031166,94.0466613032984700,1243,88.3720930232558200],
  [18.8596233,-97.55232,96.2601626016260200,615,87.7192982456140400],
  [23.192594,-106.242216,93.1076178960096800,827,100.0],
  [19.0813566,-98.1634716,95.0664136622390900,1581,90.8340214698596200],
  [23.183913,-106.137873,96.327683615819200,354,100.0],
  [19.0659016,-98.1102716,93.9327804452204300,4582,92.8179129700042200],
  [21.0214199,-101.5147833,91.5125136017410200,2757,75.5555555555555500],
  [23.064096,-105.981094,96.99699699699700,333,100.0],
  [19.3089066,-98.4955116,95.897435897435900,195,78.7234042553191500],
  [24.459262,-107.336094,95.9302325581395400,344,100.0],
  [19.3415766,-98.5885966,93.1623931623931600,234,76.1194029850746200],
  [19.3313233,-98.68767,97.0909090909090900,275,60.5333333333333300],
  [20.729446,-103.53812,89.3724696356275300,988,96.3350785340314200],
  [24.334667,-107.2158989,94.9735449735449800,378,100.0],
  [20.737057,-103.5868,97.7578475336322900,223,63.157894736842100],
  [19.94344,-99.38942,95.7699619771863100,2104,89.9587345254470500],
  [24.029163,-106.997703,96.8571428571428500,350,100.0],
  [18.609545,-96.218065,96.8390804597701100,348,72.2222222222222200],
  [22.647505,-105.603931,98.6486486486486500,370,100.0],
  [23.989643,-106.969536,95.8579881656804700,338,100.0],
  [23.948077,-106.94347,95.2272727272727300,440,100.0],
  [22.555719,-105.575945,93.9313984168865500,379,100.0],
  [17.8779483,-101.7506,87.500,8,100.0],
  [20.4878916,-100.1214833,90.7599598259122800,2987,70.5394190871369200],
  [20.072979,-90.208173,96.9798657718120800,298,100.0],
  [19.9933416,-99.1331199,88.8157894736842100,304,85.211267605633800],
  [20.9975716,-100.4401833,90.95674967234600,1526,97.416974169741700],
  [23.130685,-106.057445,97.9591836734693900,294,100.0],
  [23.032251,-105.937692,93.0894308943089400,492,100.0],
  [20.5854083,-103.3810333,86.42039542143600,5766,87.8571428571428600],
  [22.546048,-105.5241659,89.5939086294416200,394,0],
  [20.4810116,-100.5747333,78.0911062906724500,461,87.1428571428571400],
  [18.6201216,-91.0143866,82.1428571428571400,28,83.3333333333333400],
  [32.553194,-115.299109,88.8888888888888800,216,100.0],
  [32.307177,-114.202288,81.7307692307692300,104,100.0],
  [31.821077,-112.86974,93.6363636363636400,220,100.0],
  [20.219572,-102.364745,95.4545454545454600,352,98.5074626865671600],
  [25.67028,-100.3827666,92.9577464788732400,71,78.3783783783783800],
  [20.391218,-103.268427,85.2205005959475600,839,87.9844961240310100],
  [25.618239,-101.952196,96.8253968253968300,63,100.0],
  [25.206692,-107.989693,98.0237154150197600,253,100.0],
  [20.2828883,-100.0729833,70.312500,384,83.3333333333333400],
  [19.9862616,-99.4467816,92.4890535533849800,2969,76.5734265734265700],
  [19.9001816,-99.35038,93.2845458458889300,2323,88.1188118811881200],
  [20.916041,-103.991911,93.3601609657947700,497,77.7777777777777800],
  [22.406707,-105.335589,100.0,52,66.6666666666666700],
  [20.953604,-104.02258,86.6890380313199100,894,100.0],
  [23.8586269,-106.89326,94.8087431693989100,366,100.0],
  [22.5726433,-98.1686466,100.0,16,85.3658536585365800],
  [20.83834,-97.4735999,93.1034482758620700,29,100.0],
  [24.2401366,-100.3807999,94.4976076555023900,836,91.9354838709677400],
  [23.3691916,-102.9588833,95.5882352941176500,136,100.0],
  [24.8266516,-103.6811333,84.5454545454545500,220,100.0],
  [15.8628166,-93.4535233,100.0,45,61.5384615384615400],
  [27.310835,-104.9353166,93.9393939393939400,132,100.0],
  [20.4997583,-100.8701166,87.8378378378378400,370,57.5528700906344300],
  [21.21071,-102.4075,93.548387096774200,93,43.7500],
  [25.47993,-100.8609,93.3318853171155500,4604,89.215686274509800],
  [16.647286,-94.943309,35.22012578616352500,159,5.55555555555555800],
  [23.009973,-102.750503,93.2098765432098800,162,100.0],
  [23.541128,-99.3418219,100.0,16,70.3703703703703700],
  [19.891603,-98.934738,93.063583815028900,173,84.6153846153846100],
  [22.580103,-100.216516,100.0,7,50.0],
  [20.7069616,-100.34575,90.0240384615384600,2496,85.7142857142857200],
  [20.3699349,-99.9855916,93.1503889369057900,4628,75.00],
  [18.0627716,-95.3632016,87.3614190687361500,451,65.62500],
  [18.621742,-91.8405949,93.4738273283480600,1471,100.0],
  [21.457197,-104.824318,91.4708442123585700,1149,100.0],
  [18.625773,-92.320643,95.9183673469387700,294,100.0],
  [17.7085716,-92.0218666,77.0491803278688500,183,88.8888888888888800],
  [18.233831000000002,-91.43279357500002,76.4705882352941100,17,100.0],
  [18.6148116,-90.8288266,83.3333333333333400,18,100.0],
  [19.830438,-99.90301,91.9531821506949600,1367,90.9448818897637700],
  [32.0582,-113.635142,80.3278688524590200,122,100.0],
  [32.044097,-113.540175,89.062500,128,100.0],
  [19.894635,-101.567625,95.734597156398100,422,92.5764192139737900],
  [31.71746,-112.861223,97.2789115646258500,147,50.0],
  [31.675984,-112.835227,93.1818181818181900,176,100.0],
  [22.540227,-105.46944,86.6666666666666700,150,100.0],
  [22.230726,-105.268653,88.0597014925373100,268,100.0],
  [18.0088816,-95.2617716,95.4648526077097500,441,85.2941176470588200],
  [17.9836316,-95.2117316,88.823529411764700,340,71.794871794871800],
  [23.589695,-106.696401,92.479108635097500,359,100.0],
  [17.9540866,-95.1124916,84.457478005865100,341,52.7777777777777800],
  [23.51105,-106.616624,87.2832369942196500,346,100.0],
  [22.040137,-105.217998,93.2489451476793300,237,100.0],
  [23.439493,-106.5397,94.8616600790513800,506,100.0],
  [25.774676600000006,-100.288,93.8204289349327500,2751,84.266886326194400],
  [20.1347266,-99.8644816,84.4444444444444400,45,66.6666666666666700],
  [19.023597,-91.1316499,83.5125448028673800,279,100.0],
  [19.001451,-91.171835,93.2926829268292600,328,100.0],
  [19.78546,-99.8663549,91.4285714285714300,245,84.1637010676156600],
  [21.836811,-105.119253,90.0763358778625900,262,70.0],
  [19.463845,-99.7291233,81.7460317460317400,126,90.756302521008400],
  [18.05256,-94.210745,89.2255892255892300,594,100.0],
  [18.04695,-94.0720316,92.4353019243530200,1507,100.0],
  [18.05976,-94.0249583,92.7651747394236700,1631,96.5517241379310400],
  [21.711269,-105.058423,89.2550143266475600,1396,75.00],
  [18.0437166,-93.8860366,89.7674418604651100,430,100.0],
  [21.036597,-104.405595,94.4700460829493100,434,90.9090909090909100],
  [18.026915,-93.7933716,93.7219730941704100,669,100.0],
  [18.0094166,-93.6880866,88.3720930232558200,387,70.0],
  [25.559347035714282,-100.93585826428571,89.3866299104066100,1451,54.3715846994535600],
  [17.9987816,-93.5169316,81.9852941176470600,544,77.7777777777777800],
  [18.653023,-91.793631,92.3037716615698200,1962,100.0],
  [24.4507683,-103.4959499,87.0786516853932600,178,81.2500],
  [18.4485883,-101.9442666,100.0,1,10.39963669391462700],
  [19.61826,-90.675078,89.2617449664429600,596,72.7272727272727300],
  [19.7024966,-98.5945916,95.0688073394495500,872,72.8682170542635600],
  [19.5807483,-98.5137149,92.9093422871815200,5063,91.3043478260869600],
  [18.389985,-95.898335,87.500,480,78.7234042553191500],
  [22.45518,-105.358685,93.3333333333333300,90,77.7777777777777800],
  [22.297985,-100.9322666,78.5992217898832700,257,80.3571428571428600],
  [25.6743199,-100.4352166,79.4117647058823600,68,60.2499256173757900],
  [25.551167,-101.723456,96.6666666666666700,60,100.0],
  [23.1433866,-98.7490816,100.0,15,91.2280701754385900],
  [22.919185,-100.4661,93.6823104693140800,2216,86.7647058823529400],
  [19.4793466,-99.1412733,83.9012345679012400,4050,75.3445635528330800],
  [25.700962,-100.134976,86.5638766519823700,1362,82.1052631578947400],
  [19.4331066,-99.05138,93.9130434782608700,1035,74.8691099476439700],
  [21.102733729166665,-101.63042496666667,92.0367534456355300,653,76.00],
  [20.9610366,-102.6327333,95.2380952380952300,210,96.0937500],
  [29.615908,-106.33857,78.7234042553191500,47,66.6666666666666700],
  [21.391117,-97.670028,100.0,24,96.00],
  [20.5373759,-103.247039,79.8742138364779900,636,84.2696629213483200],
  [19.316935,-98.3824983,91.0256410256410200,624,89.8437500],
  [19.0982816,-104.2809833,93.8461538461538500,1495,87.1282491608773700],
  [20.4310449,-103.2965333,93.5582822085889600,326,88.8888888888888800],
  [17.6861416,-92.4970466,75.00,16,100.0],
  [18.020128,-92.909398,70.0934579439252300,214,91.1585365853658600],
  [22.550165,-100.263418,50.0,4,0],
  [32.37696694933332,-117.05866366266667,89.2703862660944300,466,93.548387096774200],
  [18.017815,-94.3845049,89.4036697247706400,2180,100.0],
  [20.5779966,-100.2383666,90.6035544606721800,5683,74.1641337386018200],
  [19.206562,-90.87061,92.419825072886300,343,100.0],
  [24.945425,-107.751956,90.9090909090909100,297,100.0],
  [17.9123849,-94.9578799,88.0190605854322600,1469,50.3144654088050300],
  [17.9260816,-95.0011783,91.262135922330100,412,78.9473684210526300],
  [17.9981716,-93.27839,80.1282051282051300,468,45.4545454545454600],
  [18.0624966,-92.8897966,94.7325102880658400,1215,100.0],
  [21.464223,-104.875218,94.8233360723089600,2434,50.0],
  [18.621223,-91.95062,88.9105058365758700,514,100.0],
  [18.621317,-92.057737,94.3005181347150300,772,100.0],
  [17.8782,-92.7454816,88.8888888888888800,18,100.0],
  [21.3379939,-104.679451,87.2200263504611300,759,93.7500],
  [21.426711,-104.786949,90.9390444810543700,607,100.0],
  [27.0331166,-104.7577666,83.0645161290322600,124,100.0],
  [17.7000316,-92.2205516,95.8333333333333400,24,100.0],
  [21.26869,-101.9695666,96.0317460317460300,126,95.8333333333333400],
  [18.634933,-92.486773,90.8653846153846100,416,33.33333333333333700],
  [18.55758,-92.607827,96.6942148760330600,605,100.0],
  [18.496761,-92.707267,96.0975609756097500,410,100.0],
  [21.950734,-105.115093,89.4523326572008100,493,96.6292134831460700],
  [18.3239829,-92.794825,94.4983818770226500,309,33.33333333333333700],
  [32.526352,-116.696285,92.1568627450980400,102,75.00],
  [32.547583,-116.651159,94.6808510638297900,94,83.3333333333333400],
  [32.557662,-116.55167,92.8571428571428600,84,88.8888888888888800],
  [22.5750633,-100.62325,94.7949526813880100,3170,91.6897506925207800],
  [18.107491,-92.865472,95.1807228915662600,332,100.0],
  [21.613312,-105.009981,88.6111111111111100,360,100.0],
  [32.47919,-116.127665,97.8947368421052700,95,68.7500],
  [18.4545066,-91.1455266,68.1818181818181900,22,75.00],
  [32.509544,-116.091966,99.0196078431372600,102,60.0],
  [24.910875,-100.4151666,95.0777202072538900,772,94.5945945945945900],
  [18.6255716,-90.9453499,82.3529411764705800,17,100.0],
  [25.823926,-100.195487,75.4373177842565600,1372,90.4761904761904800],
  [25.755729,-100.500547,91.152263374485600,2430,86.9318181818181900],
  [19.632100831519654,-99.15109445044449,67.2207640711577800,20574,64.1575912876361300],
  [29.763256,-111.055715,98.8095238095238100,168,100.0],
  [25.66272,-102.617679,95.1612903225806500,62,100.0],
  [29.382213,-110.975385,99.3975903614457900,166,100.0],
  [24.0924083,-99.1417483,100.0,3,77.7777777777777800],
  [21.3358216,-97.632965,92.7659574468085100,235,89.0710382513661200],
  [20.676385,-97.4659899,75.3424657534246600,73,28.5714285714285700],
  [24.5883566,-100.2653333,96.9116738727609600,1619,89.8734177215189900],
  [19.7860483,-96.436705,100.0,7,57.1428571428571400],
  [22.9817116,-100.4731166,92.3366834170854300,796,98.1818181818181800],
  [19.5811933,-96.3904716,100.0,2,30.7692307692307700],
  [19.5260666,-99.0145116,90.8031088082901600,772,85.4166666666666600],
  [23.9142216,-103.1528833,86.842105263157900,152,100.0],
  [19.6866516,-99.81283,90.2912621359223300,103,87.8706199460916400],
  [24.5060949,-103.5131333,93.3823529411764700,136,100.0],
  [16.8064316,-95.0269216,53.0612244897959100,294,6.2500],
  [24.9669066,-103.7288333,88.5892116182572600,482,100.0],
  [16.572085,-94.8781899,100.0,5,82.3529411764705800],
  [21.40224,-104.747181,92.3208191126279800,586,100.0],
  [27.117283,-104.890581,92.551210428305400,537,100.0],
  [32.572321,-115.789419,68.6567164179104500,67,100.0],
  [21.22049,-104.598933,86.9565217391304300,391,100.0],
  [32.576815,-115.740108,78.260869565217400,69,100.0],
  [21.185827,-104.555165,90.2116402116402100,378,100.0],
  [32.567789,-115.640863,99.3589743589743600,156,100.0],
  [21.13134,-104.46804,97.8618421052631500,608,85.7142857142857200],
  [21.092032,-104.441682,98.0861244019138800,418,100.0],
  [30.769165,-112.202601,92.1182266009852200,203,46.15384615384615600],
  [32.541081,-115.342989,87.7697841726618700,139,100.0],
  [32.545763,-114.990617,98.3050847457627200,118,100.0],
  [32.532609,-114.946244,90.6077348066298400,181,100.0],
  [32.506071,-114.856321,89.1428571428571500,175,100.0],
  [28.4133,-105.661764,95.5882352941176500,136,100.0],
  [20.857123,-103.873764,96.562500,320,100.0],
  [19.956429,-99.585494,84.9030470914127400,722,82.3064770932069500],
  [32.32126,-114.248197,82.5242718446601900,103,100.0],
  [32.293092,-114.156414,80.9523809523809500,105,100.0],
  [19.875888,-100.202262,95.8473625140291800,891,89.0207715133531100],
  [19.2746483,-98.8836816,89.7155886235449400,8333,70.9677419354838700],
  [24.733447,-103.85891,98.1818181818181800,55,100.0],
  [14.8863333,-92.3187766,90.9090909090909100,33,70.9090909090909100],
  [24.413328,-104.238382,95.3488372093023300,43,100.0],
  [20.5354033,-101.1017666,88.00,50,100.0],
  [20.51374,-100.9185333,89.3890675241157500,311,94.8717948717948700],
  [25.083751,-107.8904,95.3307392996108900,257,100.0],
  [21.0159199,-102.57145,93.2038834951456300,206,92.9687500],
  [20.4222466,-101.7145,97.1943887775551100,499,91.5789473684210500],
  [22.047585,-101.19375,100.0,38,85.0746268656716400],
  [28.894306,-106.244258,90.9090909090909100,66,100.0],
  [23.272185,-99.674395,86.6666666666666700,15,84.2105263157894700],
  [22.813647,-100.082367,100.0,7,36.00],
  [19.3668683,-96.5600216,100.0,7,84.8664688427299700],
  [21.87759,-101.15525,90.0,20,15.38461538461538500],
  [19.697181,-98.973916,100.0,25,96.774193548387100],
  [32.121554,-113.837575,11.34020618556701400,97,0],
  [32.10053,-113.74549,17.79661016949152200,118,0],
  [19.909326,-100.537805,95.1423785594639900,597,90.8018867924528400],
  [19.714595,-98.7954016,97.7272727272727300,88,100.0],
  [19.888226,-100.681203,94.8770491803278700,488,89.6436525612472100],
  [21.045602,-101.630318,91.0313901345291400,223,78.9473684210526300],
  [19.900674,-100.774919,93.7353171495693100,1277,86.5439093484419300],
  [19.901305,-100.827929,94.6380697050938300,1119,95.3846153846153800],
  [32.027668,-113.424291,81.196581196581200,117,100.0],
  [32.011113,-113.37969,81.8965517241379300,116,100.0],
  [20.594836,-101.162787,90.1287553648068700,466,76.2500],
  [19.891365,-100.9923249,97.851562500,512,92.8484848484848500],
  [19.891727,-101.039667,97.989031078610600,547,94.2690058479532100],
  [19.896452,-101.099333,98.5416666666666700,480,88.4196185286103600],
  [19.893391,-101.244604,95.3545232273838600,409,69.8148148148148200],
  [19.904054,-101.2894419,97.1509971509971500,351,92.0731707317073200],
  [19.882703,-101.345772,98.2507288629737600,343,94.2857142857142800],
  [19.8860819,-101.454266,95.897435897435900,390,95.1871657754010700],
  [19.924504,-101.601883,96.728016359918200,489,95.1724137931034500],
  [19.987205,-101.796775,95.2153110047846900,627,94.8148148148148200],
  [19.999697,-101.847639,96.9387755102040800,392,92.9648241206030200],
  [20.048611,-101.949839,98.3914209115281500,373,96.103896103896100],
  [31.594516,-112.777661,97.5369458128078800,203,100.0],
  [20.144552,-102.085931,98.3002832861189800,353,93.9393939393939400],
  [20.164996,-102.126804,98.2708933717579300,347,97.0802919708029200],
  [20.174475,-102.174035,96.2529274004683800,854,90.8860759493670800],
  [20.628774,-103.295432,83.290155440414500,1544,91.5540540540540600],
  [31.371739,-112.610151,91.3669064748201400,139,100.0],
  [20.322118,-102.519946,90.8077994428969400,718,90.8653846153846100],
  [31.188705,-112.468442,95.1388888888888800,144,100.0],
  [20.336572,-102.598006,94.7368421052631600,513,98.7500],
  [31.140125,-112.438855,96.00,150,100.0],
  [25.5992949,-100.8732,90.2059852312475700,2573,82.6086956521739100],
  [20.37798,-102.68205,95.050946142649200,687,90.4958677685950400],
  [25.6457316,-100.7583666,89.8790579248886100,1571,77.600],
  [25.6985133,-100.6231666,96.8485780169100700,1301,90.683229813664600],
  [20.440296,-102.81555,91.1637931034482800,464,87.7659574468085100],
  [20.464944,-102.905999,93.0191972076788800,573,94.943820224719100],
  [20.499959,-102.950299,96.3139120095124800,841,91.4201183431952700],
  [30.80564,-112.239336,95.0310559006211200,161,100.0],
  [25.6271199,-100.2731166,95.2380952380952300,42,91.1392405063291100],
  [20.584461,-103.097059,96.2245885769603100,1033,81.9843342036553500],
  [30.687651,-112.071738,96.8085106382978700,188,100.0],
  [20.49209,-103.149917,95.4653937947494100,419,88.9312977099236600],
  [20.460013,-103.182414,95.4128440366972500,436,91.7748917748917800],
  [30.694394,-111.973119,99.3506493506493600,154,100.0],
  [20.42465,-103.506873,94.4199706314243800,1362,87.9166666666666700],
  [30.629648,-111.635144,95.5555555555555600,135,50.0],
  [30.611522,-111.591116,95.5882352941176500,136,100.0],
  [32.20153,-113.995704,79.4871794871794900,117,100.0],
  [25.4461489,-101.318303,99.2125984251968500,254,100.0],
  [25.45355,-101.374449,98.5294117647058900,68,100.0],
  [25.468003,-101.428261,97.183098591549300,71,100.0],
  [25.495342,-101.529019,93.1818181818181900,88,100.0],
  [25.537702,-101.680023,95.0819672131147500,61,100.0],
  [25.5653669,-101.775047,92.5373134328358200,67,100.0],
  [25.576569,-101.818962,92.00,75,100.0],
  [25.823546,-100.361242,91.5971062882582100,1797,94.6745562130177500],
  [25.814626,-100.4143519,90.9557408595253400,1559,87.6033057851239600],
  [25.792677,-100.461209,97.1274685816876100,1671,94.1860465116279100],
  [30.466278,-111.090708,97.6190476190476200,168,50.0],
  [30.301509,-111.09828,99.3333333333333300,150,100.0],
  [25.642963,-102.041029,99.2248062015503900,129,100.0],
  [25.646411,-102.091667,97.5903614457831400,83,100.0],
  [25.1663316,-99.8592983,100.0,2,97.0588235294117600],
  [25.0848816,-99.7936216,66.6666666666666700,3,95.00],
  [25.658116,-102.251839,97.1428571428571400,70,100.0],
  [25.669122,-102.362209,92.8571428571428600,70,100.0],
  [24.84116,-99.56136499999998,95.00,20,69.135802469135800],
  [25.674281,-102.420084,98.5714285714285800,70,100.0],
  [29.81763,-111.060451,98.7421383647798700,159,100.0],
  [24.64922,-99.53795,100.0,2,97.6190476190476200],
  [25.464112,-101.052627,90.9523809523809500,210,70.2970297029702900],
  [29.546945,-111.012388,99.3506493506493600,154,100.0],
  [24.34827,-99.502275,100.0,4,93.8775510204081700],
  [25.61242,-102.91053,95.8810068649885600,437,100.0],
  [29.327445,-110.96018,97.0059880239520900,167,100.0],
  [25.5689959,-103.004418,98.3870967741935500,62,100.0],
  [29.226053,-110.931938,97.1314741035856600,1255,75.00],
  [24.1167216,-99.2056266,100.0,4,100.0],
  [25.536385,-103.246984,96.4052287581699400,306,41.46341463414634500],
  [23.349375,-98.8037366,100.0,14,85.7142857142857200],
  [25.453744,-101.162445,93.1506849315068400,73,66.6666666666666700],
  [22.9089883,-98.5676416,100.0,16,92.1052631578947300],
  [22.841225,-98.4069266,100.0,16,86.2745098039215700],
  [22.6848433,-98.26557,100.0,14,92.1052631578947300],
  [22.5311516,-98.1313866,100.0,16,84.4827586206896600],
  [19.5889766,-99.770545,91.4473684210526300,152,66.3366336633663300],
  [21.943255,-97.7627849,97.297297297297300,37,64.5569620253164600],
  [21.873405,-97.7472399,97.2222222222222200,36,91.3043478260869600],
  [20.560334,-103.656223,78.3783783783783800,259,50.0],
  [21.1711266,-97.5854616,100.0,24,90.0],
  [20.9982966,-97.4961216,84.6153846153846100,26,100.0],
  [20.8986816,-97.4700216,100.0,36,74.3589743589743600],
  [22.0851283,-100.8322333,91.962025316455700,1580,93.3333333333333300],
  [20.7528666,-97.482485,87.500,32,82.7586206896551700],
  [20.4662483,-97.2516816,100.0,15,78.468899521531100],
  [25.5130866,-100.9184166,94.3371943371943400,777,92.8571428571428600],
  [22.298225,-100.9820166,74.3190661478599200,257,45.6896551724137900],
  [22.26729,-101.0283,73.4693877551020300,147,50.0],
  [25.4390466,-100.8248166,88.6710239651416200,1836,92.0454545454545400],
  [25.160476766666665,-100.71001524166667,93.0064614215127300,2631,81.2949640287769800],
  [25.657061,-103.383776,90.0,90,100.0],
  [24.98487,-100.4971499,94.4654088050314500,795,95.00],
  [22.5964366,-101.6911833,93.4782608695652200,184,100.0],
  [24.7425666,-100.3114499,95.8620689655172400,870,94.4444444444444400],
  [25.661895,-103.532342,72.6114649681528700,314,0],
  [25.709904,-103.551427,80.2884615384615400,208,0],
  [23.6079433,-100.6176666,90.9495548961424400,1348,80.6451612903225800],
  [19.727925,-96.4216966,88.8888888888888800,9,77.2727272727272700],
  [23.2516399,-100.5491333,93.6517913262099300,1591,93.9655172413793100],
  [23.1298416,-100.5148166,94.6908602150537600,1488,93.8461538461538500],
  [22.7515049,-102.4323166,85.405405405405400,370,83.3333333333333400],
  [22.8322766,-102.5208333,95.1871657754010700,187,80.5555555555555600],
  [19.47811,-96.383675,100.0,3,30.00000000000000400],
  [19.30795,-96.3093866,95.00,20,70.7317073170731700],
  [22.9112966,-102.66895,94.623655913978500,186,100.0],
  [18.95796,-96.2887966,100.0,55,88.8888888888888800],
  [18.8244866,-96.3837216,93.3333333333333300,60,90.9090909090909100],
  [22.96785,-102.71695,91.7355371900826500,242,88.8888888888888800],
  [19.700245,-99.085205,96.8449931412894400,729,50.4073319755600900],
  [23.3235299,-102.95875,88.8888888888888800,594,100.0],
  [23.4236766,-102.959,89.4366197183098600,142,100.0],
  [23.5399816,-102.9595833,84.37500,128,83.3333333333333400],
  [23.7701866,-103.0097499,88.4848484848484900,165,80.9523809523809500],
  [23.8569499,-103.0438833,89.0909090909090900,165,100.0],
  [24.0278566,-103.23385,72.2222222222222200,108,0],
  [24.12696,-103.3046833,93.6633663366336600,505,100.0],
  [19.5341566,-98.9014766,93.1818181818181900,44,71.186440677966100],
  [17.2696766,-95.0543866,68.3760683760683800,117,14.81481481481481400],
  [17.102435,-95.0414516,79.2951541850220200,227,66.6666666666666700],
  [16.9992383,-95.0166366,46.7836257309941500,171,22.85714285714285400],
  [21.7099083,-97.8117283,100.0,37,66.6666666666666700],
  [16.7392699,-94.9951116,69.5000000000000100,200,50.0],
  [24.8661816,-103.71325,86.5116279069767400,215,100.0],
  [24.9260249,-103.7078666,84.2553191489361700,235,75.00],
  [16.5760266,-94.8243016,100.0,4,68.1818181818181900],
  [25.0768516,-103.7312666,96.2732919254658400,161,100.0],
  [25.1719966,-103.7206333,86.4978902953586500,237,87.500],
  [29.179968,-110.915357,95.00,1280,100.0],
  [16.4915866,-94.3926133,100.0,6,78.5714285714285700],
  [25.369505,-103.6674833,91.8918918918918900,185,100.0],
  [29.046283,-110.854754,96.0975609756097500,205,40.0],
  [16.2220833,-93.9332099,100.0,3,83.3333333333333400],
  [16.162135,-93.82579,99.200,125,66.6666666666666700],
  [15.9687216,-93.6274716,95.6521739130434800,46,95.4545454545454600],
  [26.1884216,-103.7679499,95.1219512195121900,123,100.0],
  [26.3283666,-103.9017166,93.8931297709923700,131,100.0],
  [15.782755,-93.368175,35.2941176470588200,51,8.33333333333333700],
  [15.72754,-93.2665383,98.00,50,63.6363636363636400],
  [15.6603,-93.1569966,94.2307692307692300,52,46.2776659959758600],
  [15.5909483,-93.0662266,100.0,56,76.9230769230769200],
  [26.7648999,-104.3080833,94.0397350993377500,151,100.0],
  [26.8422316,-104.4325333,86.400,125,100.0],
  [27.06125,-104.7978499,88.6363636363636400,132,66.6666666666666700],
  [19.8052933,-99.1859716,90.0405327156919500,1727,93.6708860759493700],
  [27.241795,-104.9331,94.3005181347150300,193,100.0],
  [27.4560316,-104.9650833,93.6619718309859100,142,100.0],
  [27.7275349,-105.1982833,92.5650557620817800,538,57.1428571428571400],
  [27.8338266,-105.2007333,96.9924812030075200,133,100.0],
  [27.9342716,-105.2683833,94.1605839416058400,137,100.0],
  [28.0448666,-105.3286333,90.8396946564885500,262,100.0],
  [15.3265033,-92.69729,98.2142857142857100,56,47.3684210526315800],
  [28.0806466,-105.3717333,92.7419354838709600,124,100.0],
  [28.1185316,-105.4142,86.3247863247863300,234,66.6666666666666700],
  [14.9502616,-92.38797,92.3076923076923100,13,54.1666666666666700],
  [14.9197833,-92.3518533,91.6666666666666600,24,70.0],
  [28.48064,-105.8476,91.8518518518518500,135,100.0],
  [24.70033,-103.5563666,87.6712328767123200,146,100.0],
  [14.8563733,-92.268865,89.2857142857142900,28,83.0508474576271200],
  [24.450295,-104.189517,98.0769230769230700,52,100.0],
  [14.769975,-92.17855,86.2068965517241300,29,88.0952380952380900],
  [14.67619,-92.1690183,88.6395511921458600,1426,70.2333990795529300],
  [19.130076,-98.266588,92.9090909090909100,2200,83.8771135984758300],
  [20.510155,-100.9682166,92.9411764705882400,85,100.0],
  [20.549215,-101.1813666,95.4813359528487200,509,93.7500],
  [19.2871599,-99.5674099,91.8918918918918900,370,84.8748083801737400],
  [19.28338,-99.4634483,89.93710691823900,159,91.1627906976744200],
  [20.8328866,-101.4499333,91.6666666666666600,108,51.2820512820512800],
  [20.9848133,-101.5430666,94.763205828779600,2196,90.4761904761904800],
  [21.1565416,-101.8518666,96.9696969696969700,132,100.0],
  [21.3118949,-102.0600833,95.8158995815899600,239,81.1023622047244100],
  [21.2880616,-102.2689833,95.3800298062593100,671,44.03254972875225700],
  [21.1914699,-102.3493666,91.0543130990415300,313,73.4265734265734300],
  [20.7381766,-102.9101833,95.5326460481099700,291,91.6666666666666600],
  [20.6333216,-103.0902833,95.7792207792207800,308,82.191780821917800],
  [21.25999,-102.3478499,81.8181818181818100,11,76.5765765765765700],
  [21.56265,-102.2562833,91.4893617021276600,94,78.8461538461538400],
  [21.64097,-102.2744166,90.159574468085100,376,54.7872340425531900],
  [22.74791,-100.4938,92.4363057324840800,1256,82.6666666666666700],
  [22.8565516,-100.4825499,89.8448519040902700,709,71.7647058823529400],
  [23.65131,-100.6051666,92.3759791122715400,1915,95.2662721893491100],
  [19.2837833,-99.1611633,92.746113989637300,193,78.9473684210526300],
  [19.5496933,-103.4432,96.703296703296700,91,94.805194805194800],
  [19.0014466,-103.8367166,94.7368421052631600,19,94.4130127298444100],
  [18.9433766,-103.9271833,100.0,18,82.730093071354700],
  [18.9274316,-103.9720333,93.7500,16,80.1869158878504700],
  [18.9195849,-104.0207333,95.00,20,94.0711462450592900],
  [19.9195866,-98.8440716,93.6708860759493700,316,75.8064516129032300],
  [20.5322866,-100.6283666,86.6801210898082700,991,94.4055944055944100],
  [25.116718,-107.926629,93.5114503816793800,262,100.0],
  [19.0280816,-99.1817399,100.0,16,100.0],
  [25.0939316,-100.6354333,93.50503919372900,893,92.5373134328358200],
  [22.2818516,-100.8166333,94.3844492440604800,926,96.9696969696969700],
  [24.0411266,-104.5252166,94.9640287769784200,278,100.0],
  [19.7985383,-101.1629833,92.4035087719298200,5700,95.2688172043010700],
  [21.8937216,-101.4048,95.6521739130434800,92,80.198019801980200],
  [21.9695916,-101.3023,100.0,34,95.4545454545454600],
  [21.83161,-101.44595,78.3333333333333300,60,36.5079365079365100],
  [20.9704499,-101.42025,88.8051668460710500,929,92.346938775510200],
  [21.02723,-101.40865,83.6879432624113500,141,73.1343283582089600],
  [21.47158,-101.1635333,85.3658536585365800,123,90.0],
  [21.5384799,-101.12235,94.1176470588235300,34,50.9803921568627400],
  [21.8564999,-100.9911666,89.4736842105263200,114,66.6666666666666700],
  [20.8995899,-101.3667333,94.2748091603053400,262,94.8979591836734700],
  [21.05383,-101.57975,66.2735849056603800,424,73.684210526315800],
  [21.43397,-101.2356,87.9699248120300800,133,75.4098360655737700],
  [21.65631,-101.0510166,70.9302325581395300,86,97.6744186046511600],
  [19.411995,-99.0114666,89.5762711864406800,1180,64.7058823529411700],
  [19.5134316,-99.0987916,92.6767676767676800,1188,74.8815165876777200],
  [25.3437083,-101.0427333,87.7094972067039100,179,100.0],
  [19.173679,-90.911057,87.869822485207100,338,100.0],
  [25.93153,-100.2274333,94.5602605863192200,3070,69.7478991596638600],
  [20.5299416,-100.4591666,92.9054054054054100,296,25.00],
  [21.4414899,-102.1912666,87.6288659793814400,97,88.8888888888888800],
  [21.80058,-100.9680333,76.00,50,100.0],
  [23.822855,-103.0088789,87.9518072289156700,166,100.0],
  [28.629125,-105.945139,92.5925925925925900,162,100.0],
  [28.726242,-105.957415,98.8235294117647100,85,100.0],
  [28.764966,-106.042748,87.3873873873873800,111,100.0],
  [28.792263,-106.143088,88.5113268608414200,618,100.0],
  [29.39215,-106.415554,100.0,56,100.0],
  [29.831713,-106.388334,82.4561403508771900,57,100.0],
  [29.886358,-106.3951249,87.500,56,100.0],
  [20.9390499,-100.4180166,88.1087662337662300,2464,89.3129770992366500],
  [20.983705,-89.680769,58.0645161290322500,93,100.0],
  [18.7001066,-96.35183,94.3883277216610600,891,68.9655172413793100],
  [21.9381066,-100.8044333,89.3019038984587500,1103,87.500],
  [21.8768183,-100.7709333,85.9010270774976700,1071,93.8596491228070200],
  [21.8368566,-100.7330333,84.896661367249600,1258,80.8823529411764700],
  [18.8631766,-96.86344,75.8459743290548400,857,71.0526315789473700],
  [21.1295066,-100.55375,89.9159663865546200,1190,98.437500],
  [21.2432516,-100.584,92.0273348519362200,1317,98.0392156862745100],
  [24.581342,-107.439212,92.2275939010784600,2689,100.0],
  [21.2876516,-100.5927333,92.1730675741370900,2057,91.7948717948717900],
  [18.8385216,-97.080335,91.9480519480519500,770,72.2222222222222200],
  [15.167947,-92.428691,77.0420792079207900,1616,12.500],
  [25.6075466,-102.7909999,95.1219512195121900,41,100.0],
  [19.6396483,-101.2616,100.0,5,90.4411764705882400],
  [19.368405,-101.92665,91.6666666666666600,24,88.0450070323488100],
  [21.991975,-102.2884833,95.7746478873239500,426,68.6567164179104500],
  [18.1548233,-101.9026833,90.0,20,87.5310481867858900],
  [25.7953766,-100.0837833,96.0784313725490200,51,95.00],
  [23.189705,-106.4188333,95.4773869346733700,199,74.6478873239436600],
  [25.8995283,-100.3540166,95.2380952380952300,42,83.7209302325581300],
  [26.7636516,-101.41105,95.4607977991746900,727,90.0],
  [27.2906766,-101.23055,100.0,3,100.0],
  [27.908165,-101.1701833,100.0,11,100.0],
  [28.1358966,-101.0378999,100.0,2,50.0],
  [28.259975,-100.9398666,100.0,2,100.0],
  [27.82177,-101.1372166,94.5121951219512100,328,100.0],
  [27.7667849,-101.1501833,100.0,1,100.0],
  [24.383242,-101.38796,0,3,0],
  [24.841124,-101.092254,100.0,8,69.5652173913043500],
  [19.86356,-98.7268916,90.0485436893203900,412,92.8571428571428600],
  [18.732615,-98.815079,83.9506172839506100,81,100.0],
  [19.180482,-98.194644,93.1034482758620700,29,86.6666666666666700],
  [19.252813,-99.600107,73.0434782608695700,230,60.4166666666666700],
  [23.75354,-103.840464,88.2352941176470600,17,100.0],
  [22.119548,-97.80538,90.0,40,91.1111111111111100],
  [19.328717,-99.106857,71.5463917525773200,970,66.6666666666666700],
  [25.613949,-100.015254,87.8048780487804900,41,75.00],
  [19.135791,-97.995378,92.3076923076923100,13,46.7116357504215900],
  [19.27551,-97.8301819,96.00,25,91.2559618441971400],
  [25.7580016,-100.00045,95.5312810327706100,1007,100.0],
  [19.1644933,-98.377585,95.1923076923076900,832,62.500],
  [19.0173233,-98.1350433,96.00,100,72.4637681159420300],
  [20.810578,-102.753173,84.2293906810035900,279,86.4864864864864900],
  [21.89892,-99.55456,73.9130434782608600,23,15.92920353982301100],
  [22.0191566,-100.8948333,87.0284579748510900,1511,88.0452342487883600],
  [19.485024,-98.275745,100.0,14,78.4153005464480900],
  [18.0225133,-101.95495,100.0,7,87.80958507558700],
  [17.6653516,-101.5736166,96.9230769230769200,65,85.7142857142857200],
  [17.7060399,-101.59765,95.5223880597014900,67,100.0],
  [18.582335,-101.9728,100.0,6,72.5034835113794700],
  [19.212868000000004,-96.274899,78.2747603833865900,313,66.9490014265335300],
  [19.7300866,-101.1296666,94.4444444444444400,756,66.2387676508344100],
  [19.384531,-99.249198,65.3409090909090800,176,66.3265306122448900],
  [19.306974,-99.067192,69.4444444444444400,252,93.8775510204081700],
  [20.4333866,-103.5986333,92.8104575163398700,153,60.5263157894736800],
  [23.411897,-99.3775579,93.7500,16,90.0],
  [22.698886,-100.0017,100.0,9,100.0],
  [22.624944,-100.124425,87.500,8,100.0],
  [18.5747916,-90.5871216,37.500,16,0],
  [18.622735,-90.4040066,7.69230769230768700,13,80.0],
  [18.5979866,-90.084215,94.4444444444444400,18,0],
  [18.5184416,-89.8141766,13.3333333333333300,15,19.99999999999999600],
  [18.5231066,-89.7651416,6.66666666666666500,15,0],
  [18.520055,-89.5384866,19.99999999999999600,15,50.0],
  [18.5139716,-89.4820666,75.00,20,100.0],
  [18.5102816,-89.42497,95.5555555555555600,45,100.0],
  [18.5024416,-89.3662966,95.3488372093023300,172,95.00],
  [18.4927566,-89.2888516,76.9230769230769200,26,33.33333333333333700],
  [18.4740816,-89.138915,100.0,21,100.0],
  [18.4682116,-89.09161,95.2380952380952300,21,75.00],
  [18.4602116,-89.0272216,100.0,19,100.0],
  [18.4721,-88.8655699,100.0,26,83.3333333333333400],
  [18.4827716,-88.7678666,57.6923076923076900,26,0],
  [18.482285,-88.7085266,79.3103448275862100,29,100.0],
  [18.4890416,-88.6594716,92.5925925925925900,27,100.0],
  [18.4921116,-88.61262,95.8333333333333400,24,100.0],
  [18.4992916,-88.5651499,96.6666666666666700,30,100.0],
  [18.63253,-88.4232166,75.00,4,100.0],
  [18.6737383,-88.3987,80.0,5,95.4545454545454600],
  [18.7205566,-88.3756966,80.0,5,100.0],
  [18.7638166,-88.3409866,100.0,4,100.0],
  [18.8072366,-88.3060916,100.0,2,100.0],
  [18.9271966,-88.2039016,75.00,4,100.0],
  [19.0868566,-88.1075466,50.0,4,66.6666666666666700],
  [19.1877366,-88.1026516,100.0,3,100.0],
  [19.2332,-88.1004566,66.6666666666666700,3,50.0],
  [19.3471166,-88.0737516,100.0,4,100.0],
  [19.50346,-88.0349066,80.0,5,100.0],
  [19.5716699,-88.0696816,75.00,8,90.0],
  [19.6208,-88.0228016,55.5555555555555600,9,0],
  [19.789835,-87.8880866,66.6666666666666700,3,0],
  [19.8395449,-87.8656116,77.7777777777777800,9,0],
  [19.8963866,-87.8361266,75.00,8,0],
  [19.94385,-87.7775599,66.6666666666666700,3,0],
  [19.9890866,-87.7183,50.0,4,0],
  [20.07883,-87.61389,75.00,4,0],
  [20.15522,-87.5703516,80.0,5,0],
  [20.1877166,-87.5265816,87.500,8,100.0],
  [20.5181816,-87.2091949,75.00,8,100.0],
  [20.576155,-87.1486716,66.6666666666666700,6,100.0],
  [18.6367233,-90.1714716,91.6666666666666600,24,83.3333333333333400],
  [19.21154,-97.9204933,94.4444444444444400,18,92.147435897435900],
  [19.507385,-96.8542799,94.8717948717948700,78,77.6119402985074600],
  [23.158668,-102.4284,100.0,9,52.8571428571428600],
  [23.323584,-102.290766,100.0,7,51.2195121951219500],
  [23.414923,-102.224459,100.0,7,77.500],
  [23.453439,-102.182704,100.0,7,45.7142857142857200],
  [23.486769,-102.14319,98.3193277310924300,119,7.1428571428571400],
  [23.555706,-102.062989,100.0,7,72.9729729729729700],
  [23.630172,-101.97563,100.0,7,71.0526315789473700],
  [23.667085,-101.932258,100.0,7,80.4347826086956500],
  [23.781281,-101.796368,25.00,4,5.40540540540540600],
  [23.844898,-101.756421,0,3,0],
  [23.940448,-101.6962999,0,3,0],
  [24.023868,-101.593423,0,3,0],
  [24.140407,-101.508053,0,3,0],
  [24.193047,-101.470086,0,3,0],
  [24.253868,-101.432243,0,3,0],
  [24.317222,-101.404861,0,3,.273224043715847300],
  [24.469721,-101.375987,0,4,0],
  [24.548986,-101.36485,0,3,0],
  [24.607183,-101.362732,25.00,4,0],
  [24.6468269,-101.336983,33.33333333333333700,3,0],
  [24.7584589,-101.158118,85.7142857142857200,7,71.00],
  [24.795706,-101.128558,100.0,6,82.7586206896551700],
  [24.896777,-101.083301,100.0,8,71.4285714285714300],
  [18.9285583,-102.0856,98.0392156862745100,51,86.4785635764016100],
  [18.8043066,-102.0630666,100.0,15,79.4847328244274800],
  [18.0004683,-102.0504,76.9230769230769200,13,89.8076923076923100],
  [18.0018816,-102.1125833,100.0,18,96.0496613995485400],
  [17.972845,-102.2062666,97.0251716247139600,874,82.5469168900804200],
  [26.994835,-101.3641166,100.0,5,100.0],
  [27.141585,-101.2646666,100.0,4,100.0],
  [27.4272766,-101.2115166,100.0,5,100.0],
  [27.5189916,-101.2033499,100.0,4,100.0],
  [28.3515983,-100.8764,100.0,10,50.0],
  [27.6934283,-101.1720833,100.0,2,100.0],
  [27.6478666,-101.1852166,100.0,3,100.0],
  [27.5726066,-101.1855666,100.0,4,100.0],
  [26.91259,-101.4792333,100.0,48,33.33333333333333700],
  [32.623883,-115.490189,100.0,18,100.0],
  [18.81567,-98.671159,93.7500,16,71.4285714285714300],
  [18.7952939,-98.715717,94.1176470588235300,17,100.0],
  [18.754873,-98.742292,100.0,15,100.0],
  [18.716931,-98.768279,85.7142857142857200,84,82.6086956521739100],
  [23.646221,-103.44175,0,6,0],
  [23.653776,-103.496584,0,5,0],
  [23.657333,-103.553331,0,6,0],
  [23.657086,-103.663041,84.6153846153846100,13,0],
  [23.6861089,-103.7035329,86.6666666666666700,15,100.0],
  [23.716091,-103.744383,92.8571428571428600,14,100.0],
  [23.742981,-103.7890369,75.00,16,100.0],
  [23.869668,-104.263811,93.3333333333333300,15,100.0],
  [23.931957,-104.336495,93.3333333333333300,15,100.0],
  [23.974153,-104.357757,100.0,14,0],
  [24.011108,-104.4443919,100.0,15,100.0],
  [18.8348039,-98.5625139,100.0,14,80.0],
  [32.311456,-116.979766,60.0,70,80.0],
  [20.850272,-89.729212,90.9604519774011200,708,100.0],
  [25.495348,-108.302042,96.5014577259475300,343,100.0],
  [25.311059,-108.10788,98.2608695652173900,230,100.0],
  [20.3846016,-101.78765,90.2097902097902100,143,69.5121951219512100],
  [20.54286,-101.4557166,95.5882352941176500,136,86.6141732283464600],
  [19.9631266,-99.024905,94.3566591422121800,443,84.1860465116279100],
  [20.6487683,-101.3740333,70.9677419354838700,372,74.267100977198700],
  [20.5886066,-101.0744333,94.2675159235668800,314,92.7184466019417500],
  [20.5801366,-100.9877499,96.5417867435158400,347,97.1291866028708100],
  [20.5698016,-100.8906666,96.396396396396400,333,95.6616052060737500],
  [18.8308783,-97.1260383,93.5406698564593200,836,82.6589595375722500],
  [21.5863566,-100.7549999,90.5529953917050700,1736,96.1165048543689300],
  [19.2731616,-98.4160166,93.7692307692307700,1300,88.3864337101747200],
  [18.9137383,-97.6404483,95.9715639810426500,422,86.5384615384615400],
  [18.9568766,-97.7516349,94.9536560247167900,971,90.4411764705882400],
  [18.9759783,-97.8076733,97.4182444061962100,581,92.7927927927927900],
  [19.2012666,-98.3398216,96.103896103896100,616,89.8305084745762800],
  [23.189688,-106.190814,94.5828295042321700,4135,85.2941176470588200],
  [19.0562166,-98.0601383,94.0136054421768700,4410,86.3520408163265300],
  [20.2917216,-102.5613833,95.5399061032863900,426,93.1034482758620700],
  [22.066474,-98.672049,100.0,16,100.0],
  [27.2423,-99.62992,95.4108068097705400,1351,100.0],
  [19.4364299,-101.7958333,97.7777777777777700,90,89.5596239485403200],
  [19.6558366,-101.4030166,96.6292134831460700,89,81.0640216411181200],
  [19.4162833,-101.9381666,95.8904109589041200,73,73.9433348815606200],
  [19.4187666,-101.9843833,97.9166666666666600,96,88.5714285714285700],
  [26.14379,-100.1165,93.4684684684684700,444,100.0],
  [26.30464,-100.1032833,96.0176991150442500,452,100.0],
  [22.019893,-100.032012,60.0,5,88.2352941176470600],
  [21.902114,-99.8983779,37.500,8,21.44288577154308500],
  [23.86877,-106.615985,76.7441860465116300,43,0],
  [20.7246166,-100.4691833,84.7376788553259200,629,83.9363241678726400],
  [20.76833,-100.4474499,90.8460471567267700,1442,88.4848484848484900],
  [20.8627216,-100.4261499,92.6966292134831500,4806,91.3793103448275800],
  [19.744565,-98.6231799,93.6635944700460900,868,93.511759935117600],
  [20.7567733,-100.3919833,89.3980233602875200,1113,94.2307692307692300],
  [21.023765,-89.658976,100.0,9,0],
  [18.435085,-95.9622966,89.4308943089430900,369,41.9354838709677400],
  [23.356932,-106.416269,92.6267281105990800,217,0],
  [23.264055,-106.414203,83.962264150943400,424,0],
  [21.0479516,-100.4782166,87.6054510058403600,1541,98.2248520710059200],
  [24.622843,-107.473304,96.739130434782600,368,100.0],
  [23.224233,-106.382127,99.0291262135922300,103,0],
  [18.8936016,-97.0424716,97.600,625,86.1111111111111200],
  [32.587882,-115.352257,93.7500,32,0],
  [15.329952,-92.296062,64.1618497109826700,173,0],
  [16.780276,-93.222668,82.4707846410684500,599,0],
  [17.797361,-94.110508,60.0,20,0],
  [17.9809266,-101.9807,100.0,10,73.4524569240587100],
  [19.2135283,-101.9021999,91.6666666666666600,12,83.1277357739753300],
  [23.184013,-102.779819,100.0,10,0],
  [23.14645,-102.583273,100.0,7,0],
  [21.898487,-98.918035,52.6315789473684300,19,70.7317073170731700],
  [22.2161666,-100.9799999,100.0,11,33.5616438356164400],
  [18.399212,-101.896151,0,0,14.793213149522800],
  [17.840219,-94.089831,100.0,52,0],
  [17.8005,-94.061083,100.0,46,0],
  [17.426306,-93.623786,18.36734693877550800,49,0],
  [17.338178,-93.588449,7.27272727272727500,165,0],
  [17.03898,-93.550293,10.90909090909091300,55,0],
  [17.081105,-93.5787299,56.1643835616438400,73,0],
  [16.745799,-93.344134,68.2926829268292600,82,0],
  [16.824299,-93.375793,85.405405405405400,185,0],
  [16.491127,-93.693054,0,13,0],
  [16.53318,-93.6680839,21.4285714285714300,14,0],
  [18.5582866,-97.531165,95.2861952861952900,297,0],
  [18.5738166,-90.4446116,8.33333333333333700,12,0],
  [18.63834,-90.342885,35.7142857142857100,14,80.0],
  [18.5216116,-89.7085966,12.500,16,0],
  [18.5247766,-89.6558116,17.64705882352941600,17,0],
  [18.9745216,-88.1153816,33.33333333333333700,3,100.0],
  [19.0332866,-88.1101766,100.0,2,100.0],
  [20.4908483,-87.2475466,66.6666666666666700,6,100.0],
  [20.3744266,-87.3334416,57.1428571428571400,7,100.0],
  [20.883685,-89.251165,95.6112852664576800,319,0],
  [20.845541,-89.16531,84.1558441558441600,385,0],
  [20.716628,-88.408128,99.3710691823899400,159,0],
  [20.725921,-88.358495,100.0,169,0],
  [20.892584,-87.517334,97.560975609756100,164,0],
  [20.900372,-87.467981,95.1080773606370900,879,0],
  [15.25908,-92.4022279,.869565217391299300,115,0],
  [19.3470683,-97.7505483,100.0,17,93.385214007782100],
  [19.17597,-97.9568283,86.6666666666666700,15,76.2820512820512800],
  [18.113488,-94.562152,82.3529411764705800,17,0],
  [23.157934,-102.742265,100.0,8,0],
  [23.188522,-102.388656,100.0,7,58.3333333333333300],
  [23.278196,-102.323694,100.0,7,68.3615819209039600],
  [23.700752,-101.8926819,100.0,7,81.8181818181818100],
  [23.734615,-101.851713,85.7142857142857200,7,54.385964912280700],
  [25.187712,-101.092909,100.0,7,82.2580645161290300],
  [25.083068,-101.09716,100.0,9,80.392156862745100],
  [28.356943,-106.278527,100.0,16,0],
  [28.527316,-106.176758,87.8787878787878800,33,0],
  [28.370959,-106.6416969,100.0,14,0],
  [28.379602,-106.694602,100.0,14,0],
  [28.741411,-106.908354,100.0,69,0],
  [28.779296,-106.8723009,100.0,63,0],
  [32.615739,-115.394387,100.0,17,100.0],
  [18.9149916,-100.1512666,85.7142857142857200,21,0],
  [19.041305,-100.0526333,92.3076923076923100,39,0],
  [25.5805683,-102.7046166,56.3380281690140900,71,100.0],
  [19.55786,-101.3963666,100.0,5,94.9308755760368700],
  [19.262055,-101.8743833,91.3043478260869600,23,86.6390666164847600],
  [19.0469133,-102.3161,100.0,6,0],
  [19.3098316,-101.8814,94.1176470588235300,17,84.7347527968242500],
  [19.1508983,-101.9801666,95.2380952380952300,21,78.7653402751952400],
  [20.701736,-87.148702,52.2388059701492500,67,0],
  [20.813991,-87.368798,25.00,20,0],
  [17.750469,-94.111129,0,13,0],
  [17.52396,-94.134348,0,30,0],
  [17.529155,-94.5408719,0,33,0],
  [17.403656,-94.474693,19.99999999999999600,40,0],
  [17.200043,-94.769363,0,29,0],
  [17.209806750000002,-94.72338074999999,0,22,0],
  [17.130883,-94.833418,0,20,0],
  [18.101419,-94.456557,95.2830188679245300,106,0],
  [17.928062,-94.563857,100.0,49,0],
  [17.801259,-94.589731,80.5555555555555600,36,0],
  [18.6850883,-102.0078499,70.5882352941176400,17,87.5193798449612400],
  [18.0916166,-101.9188333,88.8888888888888800,18,88.9605157131345700],
  [24.2678033,-110.3491333,95.8904109589041200,73,0],
  [23.1715516,-106.5000833,100.0,8,0],
  [24.1634616,-110.2501333,97.4358974358974300,78,0],
  [25.9325983,-100.39325,95.1219512195121900,41,0],
  [26.3734783,-100.8968166,100.0,40,0],
  [26.418455,-100.9718833,100.0,68,0],
  [26.8471066,-101.4248166,94.7791164658634600,249,100.0],
  [27.4739333,-101.2073666,100.0,4,100.0],
  [27.3795383,-101.2157166,100.0,5,100.0],
  [28.1734233,-101.0112499,100.0,4,0],
  [28.06993,-101.0929499,100.0,2,100.0],
  [26.9560783,-101.4574833,89.4308943089430900,123,100.0],
  [26.2375433,-100.6785166,90.6666666666666600,75,0],
  [19.14172,-96.991174,72.0930232558139500,43,0],
  [18.976888,-97.016465,100.0,33,0],
  [19.15199,-96.780818,94.1176470588235300,17,0],
  [19.182932,-96.913541,87.7551020408163200,49,0],
  [18.740976,-97.257364,96.8085106382978700,94,0],
  [21.1305833,-104.9028,100.0,6,0],
  [29.9283666,-106.9460166,100.0,4,0],
  [29.9451533,-106.9917833,100.0,5,0],
  [31.3238066,-108.7715666,100.0,3,0],
  [31.3231916,-109.0526166,100.0,2,0],
  [31.0172066,-110.3289666,83.3333333333333400,6,0],
  [31.0049866,-110.4321833,100.0,3,0],
  [18.271418,-97.322496,73.8095238095238100,84,0],
  [17.689166,-97.359177,44.9275362318840600,69,0],
  [17.647267,-97.338852,9.09090909090909400,22,0],
  [17.5048749,-97.475255,65.00,20,0],
  [17.477268,-97.56319,100.0,19,0],
  [17.255671,-97.629181,62.2641509433962200,53,0],
  [17.392607,-97.614885,0,16,0],
  [17.179427,-97.533767,43.9024390243902400,82,0],
  [17.00891,-97.575144,75.00,76,0],
  [19.3801833,-99.8186583,95.4545454545454600,22,100.0],
  [19.3968216,-100.0699333,90.9090909090909100,11,0],
  [19.4456166,-100.2039999,91.6666666666666600,12,0],
  [18.863715,-98.516701,92.3076923076923100,13,100.0],
  [18.686554,-98.836106,100.0,17,0],
  [18.596944,-99.175307,100.0,30,0],
  [18.38692,-99.174051,81.2500,16,0],
  [17.971172,-99.337106,81.2500,16,0],
  [17.887106,-99.395501,88.4615384615384600,26,0],
  [16.941232,-99.781826,67.5324675324675300,77,0],
  [16.9990789,-99.691884,86.6666666666666700,15,0],
  [23.554411,-103.232843,84.6153846153846100,13,0],
  [23.514757,-103.131376,93.3333333333333300,15,50.0],
  [23.848572,-104.222215,92.3076923076923100,13,100.0],
  [18.9419766,-100.74765,91.3043478260869600,23,0],
  [18.6651616,-100.9032166,94.4881889763779500,127,0],
  [17.28344,-96.950557,49.2537313432835800,67,0],
  [17.2215859,-96.869409,100.0,36,0],
  [25.621669,-99.721099,100.0,8,0],
  [25.6616709,-99.62764,100.0,9,0],
  [25.810058,-99.188625,100.0,19,0],
  [25.81681,-99.135694,100.0,19,0],
  [25.957959,-98.61868,100.0,19,0],
  [26.045226,-97.914351,100.0,22,0],
  [26.033255,-97.814348,100.0,9,0],
  [25.755074,-97.795874,100.0,15,0],
  [22.327101,-100.291273,100.0,6,100.0],
  [22.371352,-100.3022529,100.0,5,100.0],
  [21.951602,-99.973229,90.0,20,100.0],
  [21.915503,-99.941743,90.4761904761904800,21,77.1428571428571500],
  [21.940511,-99.272762,100.0,16,64.4067796610169600],
  [21.989465,-98.736721,100.0,13,65.2173913043478300],
  [22.03719,-98.714874,100.0,14,84.6153846153846100],
  [22.205956,-98.005066,100.0,24,85.9813084112149500],
  [22.150413,-98.183399,83.3333333333333400,30,76.6233766233766300],
  [22.1323216,-101.0329999,100.0,35,0],
  [18.6534733,-101.9721333,63.6363636363636400,11,79.899074852817500],
  [17.139265,-100.3936666,100.0,9,0],
  [17.1465316,-100.45475,92.3076923076923100,13,0],
  [19.5551716,-99.1979966,91.6607916205055900,9929,91.0652920962199300],
  [25.932954,-109.049353,95.62500,320,0],
  [19.7972,-90.520521,94.3866943866943800,481,100.0],
  [19.889228,-90.464276,85.5172413793103400,580,100.0],
  [19.971067,-90.329598,94.5454545454545400,275,100.0],
  [25.744836,-108.783531,97.5708502024291500,247,100.0],
  [25.717437,-108.73211,98.7903225806451600,248,100.0],
  [25.534659,-103.332361,97.0548862115127100,747,100.0],
  [20.336794,-90.043102,91.4606741573033700,445,100.0],
  [20.3912683,-103.12325,100.0,38,100.0],
  [20.4615,-90.0089799,84.4155844155844100,462,100.0],
  [25.585108,-108.483828,90.2097902097902100,715,100.0],
  [20.564532,-89.978827,96.5909090909090900,264,100.0],
  [20.636796,-89.91938,93.4472934472934400,351,100.0],
  [20.2942366,-102.6798333,86.6666666666666700,45,100.0],
  [20.2928466,-102.7260166,86.6666666666666700,45,100.0],
  [20.8270209,-89.774801,90.9090909090909100,660,100.0],
  [20.276605,-102.5131,95.4154727793696300,349,100.0],
  [19.9988749,-99.4913549,90.1240135287485900,4435,82.4701195219123400],
  [25.486903,-108.250978,97.2899728997289900,369,75.00],
  [32.40253,-116.931563,94.1860465116279100,86,100.0],
  [20.081115,-99.3392766,85.3360488798370700,491,93.8775510204081700],
  [24.088062,-106.824095,50.0,38,0],
  [25.355299,-108.118795,97.2635336109458600,1681,100.0],
  [20.3791616,-101.8359,95.5414012738853500,157,98.7500],
  [20.3854516,-101.8928166,94.495412844036700,218,94.7916666666666600],
  [20.6115,-101.40765,67.3913043478260900,230,64.0449438202247200],
  [20.651765,-101.3233499,71.7197452229299400,785,93.9024390243902400],
  [23.911471,-106.655629,30.00000000000000400,40,0],
  [32.493422,-116.850041,91.9632606199770400,1742,100.0],
  [18.8177333,-97.1717716,94.9551569506726500,892,79.500],
  [21.4035699,-100.6925666,88.9583333333333400,960,88.6597938144329900],
  [24.09593,-100.3807999,95.1367781155015200,987,92.1348314606741600],
  [18.8908783,-97.601585,97.0588235294117600,578,94.3396226415094400],
  [19.237965,-98.3828816,95.2627045650301400,1161,88.9240506329113900],
  [19.036135,-97.9875833,88.0863039399624700,1066,87.8787878787878800],
  [16.333655,-95.278378,69.3877551020408200,245,0],
  [16.376955,-95.25,58.5034013605442200,147,0],
  [23.014421,-105.896012,92.7731092436974800,595,75.00],
  [18.8386466,-97.3417366,87.9573170731707400,656,67.7419354838709700],
  [22.99764,-105.849182,93.6111111111111100,360,80.0],
  [19.3156366,-98.7940966,95.8677685950413200,363,59.0163934426229500],
  [22.896999,-105.806824,75.8620689655172400,290,0],
  [19.4139716,-98.8709849,91.9540229885057500,348,89.3617021276595700],
  [19.6571683,-98.8699883,97.9166666666666600,96,93.9759036144578300],
  [22.771938,-105.670921,91.3194444444444400,576,100.0],
  [22.744362,-105.634553,94.6700507614213200,394,75.00],
  [24.073778,-107.029561,95.1704545454545400,352,100.0],
  [22.596349,-105.600942,96.7302452316076300,367,100.0],
  [20.4993916,-100.7685499,82.1499013806706100,1014,68.8888888888888900],
  [20.3386666,-100.2461666,74.1854636591478700,399,77.7777777777777800],
  [20.2964166,-100.1185833,76.5498652291105100,371,97.0588235294117600],
  [18.16068,-95.5435,94.1666666666666700,360,51.9607843137254800],
  [18.1364516,-95.50287,95.4198473282442800,524,76.00],
  [23.907701,-106.923019,94.9656750572082400,437,100.0],
  [20.999278,-104.104994,93.5897435897435900,468,100.0],
  [21.017953,-104.148582,96.2825278810408900,538,65.1515151515151500],
  [22.13378659999998,-100.82516660000006,90.3868031854379900,3516,66.0668380462724900],
  [21.94456,-105.213208,98.4615384615384700,65,75.00],
  [21.91194,-105.1751759,79.6610169491525400,59,66.6666666666666700],
  [17.9986466,-93.39672500000002,96.1467889908256900,545,80.0],
  [18.948219,-91.284313,82.6446280991735600,242,100.0],
  [21.793964,-105.096934,87.6190476190476200,315,16.66666666666666300],
  [18.0531066,-94.13801,89.0769230769230800,650,93.7500],
  [18.771399,-91.543817,92.5824175824175900,364,100.0],
  [25.438492442500007,-100.8896668225,77.6499774470004500,4434,25.00],
  [21.06042,-104.5048939,78.00,100,0],
  [18.702632,-91.691458,94.5762711864406700,295,100.0],
  [17.2096649,-95.05301,79.2746113989637300,193,28.0303030303030300],
  [20.607381,-97.385064,100.0,15,52.3809523809523800],
  [20.381837,-97.15712,100.0,9,62.8571428571428600],
  [20.855068,-102.616881,100.0,19,0],
  [18.218908,-93.202707,100.0,22,0],
  [18.07661,-93.163973,100.0,22,0],
  [18.6787959,-97.355754,100.0,48,0],
  [18.7561,-97.420294,100.0,26,0],
  [20.083123,-102.264731,94.7368421052631600,19,0],
  [17.802605,-101.6997666,100.0,7,100.0],
  [17.9740816,-101.9052833,100.0,5,100.0],
  [18.5324,-101.97485,100.0,6,52.392249901146700],
  [21.0080133,-100.6544666,100.0,17,0],
  [18.9905733,-100.7423833,100.0,5,0],
  [20.077458,-102.345903,81.8181818181818100,11,0],
  [20.709695,-100.267386,100.0,11,0],
  [20.0376549,-101.4705166,100.0,7,0],
  [20.0891016,-101.4598666,100.0,7,0],
  [19.297366,-99.023102,100.0,25,0],
  [20.5589966,-100.8332,93.3717579250720400,347,97.8260869565217400],
  [20.490957133348253,-103.26834439993982,93.5124462308722900,14181,82.7664399092970500],
  [23.446192,-106.466692,97.4358974358974300,39,0],
  [18.7383133,-96.4040699,95.4904171364148800,887,58.6206896551724200],
  [21.9947233,-100.83005,89.2483822797411700,2009,87.0370370370370300],
  [21.09081,-100.5286,86.5399239543726200,1315,97.3451327433628300],
  [20.576166480341886,-100.31681671678331,86.523437500,7680,90.1140684410646400],
  [17.7249749,-91.9722766,74.37500,160,100.0],
  [20.555796,-103.061215,96.6037735849056600,795,95.2127659574468100],
  [25.3367366,-100.0730833,100.0,3,100.0],
  [23.1583866,-102.8203333,86.2542955326460500,291,88.8888888888888800],
  [19.5753166,-99.0208083,93.3386837881219900,1246,76.4705882352941100],
  [23.670601,-105.611834,71.6981132075471800,159,0],
  [14.6277516,-92.1842233,91.6666666666666600,12,80.0],
  [19.2261866,-99.1362066,93.0107526881720500,186,100.0],
  [19.17336,-99.1515216,94.7368421052631600,19,75.00],
  [19.3412599,-99.6721166,60.0,80,75.00],
  [23.689429,-102.985247,87.3134328358208900,134,100.0],
  [19.30414,-98.935438,97.849462365591400,186,59.5238095238095200],
  [25.729526,-100.33252,93.2164881531970100,3081,75.4716981132075500],
  [20.2198416,-102.1612833,100.0,7,0],
  [20.2764816,-102.0708666,100.0,9,0],
  [22.66825,-100.042637,100.0,7,0],
  [22.605872,-100.168717,100.0,8,100.0],
  [22.495302,-100.293504,40.0,5,0],
  [26.640283,-109.212523,98.2608695652173900,230,0],
  [20.5309916,-100.1834333,92.9955290611028300,46299,85.276073619631900],
  [17.9492933,-94.679415,96.1009174311926600,436,100.0],
  [19.243468,-90.817536,93.1654676258992800,278,100.0],
  [24.838525,-107.602899,95.2069716775599200,459,100.0],
  [19.879105,-99.2372466,82.5855333254409900,8447,86.390532544378700],
  [24.753124,-107.515847,95.0579150579150500,2590,0],
  [26.283016,-109.038928,98.3050847457627200,236,0],
  [0.0,0.0,65.168539325842700,1068,57.0500245218244200],
  [25.3853616,-100.7959333,85.3425845620121400,2306,83.3333333333333400],
  [21.537979,-104.933603,92.8143712574850300,501,100.0],
  [18.0027666,-92.8674666,94.8717948717948700,39,60.0],
  [22.487934,-105.466922,87.5471698113207600,265,0],
  [22.453979,-105.424998,88.9423076923076900,624,0],
  [22.362537,-105.369107,92.1348314606741600,267,0],
  [18.619185,-92.151717,89.6453900709219800,705,88.8888888888888800],
  [22.264062,-105.318567,82.3970037453183500,267,0],
  [17.6697566,-92.4439016,92.3076923076923100,13,100.0],
  [17.698,-92.2697566,95.2380952380952300,21,100.0],
  [17.6987683,-92.1698016,95.4545454545454600,22,100.0],
  [18.615938,-92.532222,92.779783393501800,277,100.0],
  [18.512663,-92.652152,96.9834087481146300,663,100.0],
  [18.4584319,-92.759207,93.5567010309278400,388,100.0],
  [18.386518,-92.796687,90.7488986784140900,454,100.0],
  [32.540572,-116.745575,78.8888888888888900,90,50.0],
  [18.272149,-92.829338,89.8734177215189900,316,0],
  [18.166601,-92.858723,90.2912621359223300,309,66.6666666666666700],
  [18.1988916,-91.5348116,87.500,16,100.0],
  [18.2210766,-91.4905016,78.9473684210526300,19,100.0],
  [32.532364,-116.380216,95.8904109589041200,73,100.0],
  [25.29079,-100.7902333,91.5465898174831900,1041,86.8131868131868200],
  [32.50011,-116.226114,91.0891089108910900,101,57.1428571428571400],
  [32.536546,-116.052307,90.7834101382488400,217,93.548387096774200],
  [32.567812,-115.842713,91.7808219178082200,73,100.0],
  [21.163577,-104.50661,96.4083175803402600,529,92.3076923076923100],
  [31.954726,-113.064167,97.3913043478260900,115,66.6666666666666700],
  [31.686204625000762,-106.41000204999534,73.9130434782608600,161,0],
  [27.192075,-104.9316769,96.8992248062015400,129,100.0],
  [32.423076,-114.582633,77.5193798449612400,387,0],
  [22.073768,-97.798451,95.00,40,100.0],
  [19.854053,-100.055453,92.7667269439421300,553,88.445378151260500],
  [31.05226,-106.493667,96.3636363636363600,55,0],
  [25.574829,-103.5808,70.7142857142857100,140,60.0],
  [32.138029,-113.902876,9.99999999999999800,130,0],
  [32.074617,-113.69366,38.21138211382113500,123,25.00],
  [19.89147,-100.634512,97.2920696324951600,517,94.4055944055944100],
  [19.893221,-100.727745,93.3333333333333300,585,88.4175084175084200],
  [32.045949,-113.482898,86.1538461538461600,130,0],
  [31.995172,-113.33542,82.4561403508771900,114,0],
  [31.98253,-113.242055,93.6363636363636400,110,100.0],
  [31.925752,-112.970701,100.0,112,0],
  [31.770859,-112.873705,99.3630573248407600,157,100.0],
  [31.511309,-112.715116,100.0,138,100.0],
  [20.613406,-103.186256,93.3691756272401400,558,98.2758620689655100],
  [20.357287,-102.640835,93.9655172413793100,696,95.5974842767295600],
  [25.441446,-101.210796,98.4615384615384700,65,100.0],
  [25.65888,-100.7085333,95.395395395395400,999,85.4430379746835400],
  [20.444126,-102.8644489,91.1308203991130800,451,97.1098265895953700],
  [25.6633466,-100.3038833,95.7244655581947700,1263,77.2151898734177200],
  [25.5444366,-100.2234333,100.0,3,93.3333333333333300],
  [20.539688,-103.12211,95.921450151057400,662,82.3529411764705800],
  [20.392926,-103.37314,95.8549222797927400,386,77.7385159010600700],
  [20.4201419,-103.456734,95.6605593056894900,1037,80.9187279151943400],
  [20.479381,-103.596248,81.6666666666666700,240,85.7142857142857200],
  [30.585361,-111.448788,90.3703703703703700,135,100.0],
  [30.574662,-111.349377,72.00,125,100.0],
  [30.554519,-111.138893,91.1330049261083700,812,66.6666666666666700],
  [25.28588,-100.0130666,76.4705882352941100,119,76.8421052631578900],
  [25.632473,-101.997055,99.1935483870967700,124,100.0],
  [30.133769,-111.097315,88.4026258205689300,457,0],
  [25.653391,-102.194338,92.1052631578947300,76,100.0],
  [25.663747,-102.307927,47.6702508960573400,279,100.0],
  [25.672377,-102.479945,98.7804878048780500,82,66.6666666666666700],
  [24.7535666,-99.5414,100.0,2,94.1176470588235300],
  [29.600575,-111.024136,100.0,156,0],
  [20.5567716,-100.5579166,85.3615520282186900,567,98.2188295165394400],
  [24.4122266,-99.5019066,100.0,4,86.6666666666666700],
  [29.43706,-110.988276,100.0,154,100.0],
  [21.505689,-104.813079,97.500,520,0],
  [24.1595066,-99.2866233,87.500,8,100.0],
  [25.543603,-103.105729,95.2380952380952300,63,100.0],
  [24.980293,-107.560075,69.2307692307692300,39,0],
  [23.8393516,-99.0490666,100.0,3,97.2222222222222200],
  [23.6068466,-98.9486199,100.0,16,86.842105263157900],
  [23.2658966,-98.7958366,100.0,17,88.8888888888888800],
  [23.0878966,-98.755685,100.0,17,85.7142857142857200],
  [20.601969,-100.171282,90.0311526479750800,2247,77.8761061946902600],
  [22.7819633,-98.338675,100.0,15,74.5454545454545500],
  [22.6515616,-98.235075,100.0,16,100.0],
  [22.5016066,-98.0790433,100.0,15,72.5806451612903200],
  [25.412021,-108.045952,96.774193548387100,31,0],
  [21.1261966,-97.559335,100.0,25,93.7500],
  [20.530165,-97.3051199,100.0,12,54.1666666666666700],
  [22.2447899,-100.8438166,92.7130044843049300,892,94.4444444444444400],
  [22.2221099,-101.05185,87.8787878787878800,198,58.5714285714285600],
  [22.2817466,-101.13085,80.62500,160,0],
  [22.3754599,-101.3091999,23.7623762376237600,101,0],
  [25.641051,-103.341579,88.0434782608695700,92,100.0],
  [25.0284649,-100.5529,94.4652908067542200,2132,86.6666666666666700],
  [22.5697166,-101.6420333,94.2446043165467700,139,0],
  [24.6810766,-100.30065,94.2606616181745700,2509,96.0784313725490200],
  [24.5311016,-100.2794666,94.992846924177400,1398,96.5116279069767500],
  [24.3443033,-100.3894833,94.110854503464200,866,93.5897435897435900],
  [24.0455549,-100.4002333,94.5972495088408600,1018,94.5945945945945900],
  [20.1564283,-96.7198116,100.0,4,100.0],
  [23.8334733,-100.5312333,92.4180327868852500,976,88.4955752212389400],
  [19.9027866,-96.511895,100.0,3,9.99999999999999800],
  [23.4401183,-100.6020833,94.650900900900900,1776,92.1052631578947300],
  [22.79528,-102.4781999,94.501018329938900,491,86.842105263157900],
  [19.63796,-96.42271,85.7142857142857200,7,35.00],
  [19.52287,-96.3906649,100.0,2,30.00000000000000400],
  [22.5122399,-100.6654166,94.9213161659513600,2796,42.6332288401253900],
  [18.918325,-96.3223716,100.0,54,89.2857142857142900],
  [22.3283016,-100.8002,94.4532488114104600,1262,76.3052208835341300],
  [23.2275516,-102.8906999,98.0263157894736800,152,100.0],
  [17.8583116,-94.979985,78.8043478260869600,184,36.36363636363636500],
  [26.7198129,-109.281175,90.5213270142180100,211,0],
  [24.3058216,-103.4140666,84.5588235294117600,136,100.0],
  [26.847032,-109.371657,93.3130699088145900,329,0],
  [24.5514233,-103.5206499,90.2985074626865700,134,100.0],
  [24.641385,-103.5399333,88.00,125,100.0],
  [24.7525116,-103.5961666,89.677419354838700,155,100.0],
  [27.031537,-109.435252,96.9581749049429600,526,0],
  [16.6987616,-94.9580949,65.8959537572254300,173,52.7777777777777800],
  [27.064432,-109.468505,95.4918032786885300,976,0],
  [16.5704233,-94.7745566,100.0,3,84.00],
  [25.2351216,-103.6388333,93.3673469387755100,196,100.0],
  [27.151265,-109.502052,95.3488372093023300,215,0],
  [16.4521899,-94.2749316,100.0,3,8.12500000000000400],
  [16.36129,-94.1860166,100.0,4,46.87500],
  [28.942065,-110.875983,79.0209790209790200,143,0],
  [16.2117092875,-93.858749725,82.812500,256,64.0186915887850500],
  [16.10949,-93.7828766,100.0,45,62.0689655172413800],
  [26.11207,-103.69825,91.4942528735632200,435,100.0],
  [27.457013,-109.895424,85.0574712643678100,348,0],
  [26.2130366,-103.8212833,97.7777777777777700,135,100.0],
  [26.3685866,-103.9249833,93.1623931623931600,117,100.0],
  [26.4572366,-103.9762333,91.6666666666666600,108,100.0],
  [15.6264916,-93.116475,97.8260869565217400,46,76.9230769230769200],
  [15.5500116,-93.0078516,93.2203389830508400,118,72.2222222222222200],
  [26.9311016,-104.6020499,78.3582089552238800,134,100.0],
  [27.618685,-110.204306,98.4293193717277500,191,0],
  [27.626913,-110.250025,97.5155279503105500,322,0],
  [27.640716,-110.295034,97.6653696498054400,514,0],
  [27.910346,-110.559376,97.3118279569892500,186,0],
  [27.5127716,-105.0166666,98.3193277310924300,119,100.0],
  [27.6192716,-105.1134333,97.0370370370370300,135,100.0],
  [15.455905,-92.88573,79.439252336448600,214,73.1707317073170700],
  [15.39013,-92.7942133,96.5517241379310400,87,87.1621621621621600],
  [24.827941,-103.813984,95.5555555555555600,45,100.0],
  [15.1207066,-92.43921,79.5149968091895300,1567,51.0416666666666700],
  [15.01315,-92.402295,100.0,13,71.4285714285714300],
  [28.4614116,-105.7850166,78.6324786324786300,117,100.0],
  [24.544831,-104.057423,100.0,39,100.0],
  [28.536715,-105.9433666,82.7411167512690400,197,66.6666666666666700],
  [24.488254,-104.144667,98.0392156862745100,51,100.0],
  [14.89852,-92.2195483,88.2352941176470600,34,84.8484848484848500],
  [14.8131183,-92.1920766,86.2068965517241300,29,69.2307692307692300],
  [28.6579316,-106.0500333,88.3534136546184700,249,0],
  [24.249998,-104.437989,100.0,49,0],
  [19.46443,-99.30977,86.8852459016393400,244,92.2727272727272700],
  [19.28874,-99.6297299,86.2461220268872800,967,29.67581047381545600],
  [21.0733766,-101.6973666,68.7671232876712300,365,82.1852731591448900],
  [30.957387,-112.968976,100.0,10,0],
  [21.289485,-102.2053,94.1176470588235300,680,82.9508196721311500],
  [21.1540916,-102.4208666,91.943127962085300,211,85.0340136054421800],
  [21.0769849,-102.5003166,90.4564315352697100,241,90.756302521008400],
  [20.8022616,-102.8120166,93.7777777777777800,450,92.660550458715600],
  [20.709555,-102.9598833,92.660550458715600,218,90.6040268456375900],
  [23.9818966,-100.4244833,90.7823392718822600,1291,70.0],
  [18.4342899,-99.1770483,78.9473684210526300,19,0],
  [24.0434916,-104.5964166,94.9398797595190400,1996,0],
  [21.3670099,-101.9647666,87.4680306905370800,391,77.6595744680851100],
  [21.4488866,-101.8127666,86.9565217391304300,69,41.5584415584415600],
  [21.3726716,-101.9024999,84.8066298342541400,362,36.43006263048016400],
  [21.614755,-101.6282833,93.1818181818181900,88,83.3333333333333400],
  [21.3070099,-101.2799999,64.4444444444444400,90,80.7692307692307700],
  [21.9208183,-100.9422666,94.805194805194800,77,89.5833333333333400],
  [21.72442,-101.0099666,90.6976744186046600,86,83.3333333333333400],
  [20.0728616,-98.38999659999999,97.5308641975308600,81,0],
  [21.8916,-101.6291333,55.5555555555555600,9,0],
  [21.82508,-101.7506333,100.0,2,0],
  [28.74538,-105.9992709,98.7341772151898800,79,100.0],
  [28.817592,-106.181077,87.7044854881266500,1895,100.0],
  [28.943123,-106.275212,84.2857142857142900,70,100.0],
  [30.043514,-106.387113,100.0,53,100.0],
  [30.095922,-106.408213,94.4444444444444400,54,100.0],
  [31.156932,-106.50223,98.2142857142857100,56,0],
  [30.996202,-106.490287,92.4528301886792500,53,0],
  [31.608316,-106.451258,86.7647058823529400,68,0],
  [31.736572,-106.447344,71.6417910447761300,67,0],
  [20.267917,-98.003417,64.00,25,0],
  [20.330945,-97.958274,80.2325581395348800,86,0],
  [23.016971,-98.729084,100.0,14,77.7777777777777800],
  [23.769183,-99.123194,80.9917355371900800,121,0],
  [23.730936,-99.092476,100.0,55,0],
  [23.163297,-99.690015,100.0,15,100.0],
  [23.109409,-99.704368,100.0,15,100.0],
  [22.907951,-100.37793,0,4,12.500],
  [22.884947,-100.309499,33.33333333333333700,6,0],
  [20.2837919,-101.175714,88.8888888888888800,9,95.0310559006211200],
  [20.340708,-101.1501959,42.8571428571428600,7,39.1891891891891900],
  [22.458059999999996,-102.34450000000001,84.9514563106796200,206,65.5913978494623600],
  [19.282894,-99.519934,93.1506849315068400,365,90.1734104046242800],
  [19.416373,-99.404664,88.2352941176470600,17,47.45762711864406300],
  [20.5149183,-97.49466,70.9677419354838700,31,0],
  [20.5434366,-97.4543116,74.2857142857142900,35,0],
  [19.1780116,-96.1362999,84.7195357833655700,517,82.9907866761162300],
  [19.44162,-96.7085266,100.0,21,84.1230257689110500],
  [19.5011466,-96.8054016,95.8333333333333400,24,68.2605111294311700],
  [19.3959083,-98.18056,84.8101265822784800,79,85.5287569573283900],
  [19.43803,-98.2438449,88.00,25,85.5555555555555600],
  [25.51222,-103.53275,85.7142857142857200,7,60.0],
  [26.5352,-104.13751659999996,90.0,110,0],
  [21.80987,-101.1032499,90.0,20,33.33333333333333700],
  [21.8996499,-101.2618333,86.842105263157900,38,90.0],
  [19.660834,-99.006815,87.9310344827586200,116,100.0],
  [19.738853,-98.946498,91.6666666666666600,24,95.6521739130434800],
  [20.4448916,-100.156,77.7777777777777800,18,0],
  [20.9649066,-105.3063166,92.3076923076923100,13,0],
  [20.9306933,-105.353,83.3333333333333400,18,0],
  [21.229085,-104.87365,93.548387096774200,31,0],
  [26.53846,-100.1429499,100.0,4,0],
  [26.70395,-99.94234,93.3035714285714300,448,0],
  [19.5002066,-101.5860166,96.4876033057851200,484,87.4692874692874700],
  [19.535365,-101.54395,96.6887417218543100,151,83.5997220291869300],
  [19.4247516,-102.0387166,91.7901938426453800,877,93.358999037536100],
  [19.2339759,-98.273676,0,0,14.2857142857142900],
  [19.297132,-97.506634,0,0,63.3333333333333300],
  [16.561246,-94.673514,0,0,34.78260869565217300],
  [19.51099,-97.330874,0,2,88.5714285714285700],
  [19.463477,-97.383818,0,0,57.1428571428571400],
  [19.41295,-97.393485,0,0,75.00],
  [19.373017,-97.43501,0,0,71.87500],
  [19.337139,-97.475677,0,0,64.7058823529411700],
  [19.256146,-97.53863,0,0,50.0],
  [19.222563,-97.570089,0,0,100.0],
  [19.18596,-97.604459,0,0,100.0],
  [19.152703,-97.635747,0,0,100.0],
  [19.319068,-96.376491,0,0,97.9452054794520600],
  [19.115133,-97.660572,0,0,85.7142857142857200],
  [19.060696,-97.6952419,0,0,100.0],
  [19.013761,-97.716361,0,0,87.500],
  [18.911009,-97.817372,0,0,87.500],
  [16.550324,-94.575564,100.0,2,64.00],
  [23.973629,-106.729527,89.5833333333333400,48,0],
  [20.424575,-100.7939333,77.4074074074074100,810,0],
  [20.904684,-103.894993,93.6842105263157900,285,0],
  [24.6994459,-103.897066,94.2857142857142800,35,0],
  [23.711676,-105.529282,92.553191489361700,94,0],
  [31.315661,-113.479832,100.0,8,0],
  [0.0327695,-132.7942666,0,1,0],
  [17.59062,-93.721425,50.0,32,0],
  [23.0066233,-103.2021666,75.00,4,0],
  [28.3512966,-111.0424666,94.5098039215686200,255,0],
  [21.242169,-104.643405,86.5753424657534200,365,0],
  [21.544086,-104.838603,89.3939393939393900,264,0],
  [25.57598766666666,-103.41522999999997,97.4605020839391300,10317,90.0],
  [27.6697149,-99.6645966,100.0,14,0],
  [27.6412,-99.546775,94.6808510638297900,188,0],
  [23.573602,-106.53558,48.7179487179487200,39,0],
  [19.8594166,-100.45095,96.2500,880,0],
  [21.88592,-102.31715,94.308943089430900,1722,0],
  [18.005907,-94.122628,96.774193548387100,31,0],
  [24.82844,-107.5536833,94.4099378881987600,483,0],
  [26.0625999,-100.6070833,100.0,104,0],
  [18.06573,-94.574355,66.6666666666666700,36,0],
  [25.739087,-99.432655,100.0,8,0],
  [20.5195783,-103.33654990000001,92.8972917731221300,1957,0],
  [18.696936,-97.187626,77.3195876288659800,97,0],
  [17.639574,-93.816036,78.7234042553191500,47,0],
  [17.476264,-93.632101,13.63636363636363500,44,0],
  [17.123262,-93.5949009,72.4137931034482800,87,0],
  [16.580275,-93.630337,47.61904761904761600,21,0],
  [32.3927679,-114.482792,78.9062500,128,0],
  [32.349308,-114.340002,68.5714285714285700,105,0],
  [30.712114,-111.86087,98.4313725490196100,255,0],
  [19.3357216,-98.218465,84.37500,32,95.8333333333333400],
  [20.7926499,-88.967373,98.2758620689655100,174,0],
  [20.940683,-87.323121,94.4055944055944100,143,0],
  [20.957442,-87.268922,95.205479452054800,146,0],
  [21.109458,-86.956585,88.919498010407100,3267,0],
  [19.06015,-100.0079166,90.0,30,0],
  [18.52077,-100.5739166,80.0,15,0],
  [26.5512666,-101.19465,95.4545454545454600,44,0],
  [29.0045766,-100.9063833,100.0,3,0],
  [30.35609,-107.8630666,100.0,2,0],
  [20.768621,-102.550596,43.6363636363636400,55,0],
  [18.172968,-93.201264,100.0,19,0],
  [20.942255,-100.6803333,100.0,48,0],
  [20.121011,-102.364597,90.0,10,0],
  [19.9818433,-101.2545833,100.0,11,0],
  [20.0127566,-101.3673,66.6666666666666700,3,0],
  [20.0005533,-101.4198666,100.0,6,0],
  [26.336827,-109.023514,98.3673469387755100,735,0],
  [25.97859,-109.03211499999999,96.6604823747680900,539,0],
  [20.230935,-99.8702599,89.8986105895606500,2663,0],
  [24.45398,-107.104907,86.3636363636363600,44,0],
  [24.35433,-107.0972139,86.6666666666666700,45,0],
  [24.082438,-101.54984,0,3,0],
  [24.419286,-107.293985,96.5116279069767500,344,0],
  [20.863767,-103.779307,100.0,103,0],
  [21.583388,-104.935286,89.5833333333333400,384,0],
  [22.410596,-105.398801,88.718929254302100,523,0],
  [20.8221766,-100.4567833,94.0065798884279800,6991,86.6539561487130600],
  [18.6514766,-90.7395866,83.8709677419354900,31,0],
  [18.7607816,-90.7378066,75.00,8,0],
  [18.8101199,-90.7468066,66.6666666666666700,12,0],
  [32.533518,-115.593095,99.1935483870967700,124,0],
  [32.527584,-115.533876,97.1153846153846100,104,0],
  [31.5581,-112.750334,99.310344827586200,145,0],
  [32.543463,-116.853503,84.8387096774193500,310,78.12500],
  [30.570178,-111.301862,88.0952380952380900,126,0],
  [29.871301,-111.077341,96.3414634146341400,164,0],
  [25.665897,-102.571782,93.8461538461538500,65,0],
  [24.86882,-107.467985,100.0,28,0],
  [25.542022,-103.15111,96.8253968253968300,63,0],
  [25.343473,-107.958138,70.3703703703703700,27,0],
  [25.3805729,-108.012926,83.3333333333333400,24,0],
  [25.449417,-108.071176,98.0769230769230700,52,0],
  [25.4690629,-108.12112,97.1428571428571400,35,0],
  [20.4579116,-97.109695,100.0,4,0],
  [20.39918,-96.9922799,100.0,3,0],
  [20.3540266,-96.89847,100.0,7,0],
  [20.2726116,-96.817055,100.0,7,0],
  [22.4750666,-101.5320833,92.1052631578947300,114,0],
  [22.6314916,-101.8351166,93.6363636363636400,110,0],
  [31.087309,-112.416326,96.0526315789473700,152,66.6666666666666700],
  [26.933961,-109.418617,98.6254295532646100,291,0],
  [27.334683,-109.737875,90.1265822784810200,395,0],
  [28.084336,-110.955533,92.1282798833819200,343,0],
  [27.960337,-110.758357,97.1193415637860100,243,0],
  [27.946072,-110.657303,96.4102564102564100,195,0],
  [27.70102,-110.368483,97.9838709677419400,248,0],
  [27.808421,-110.442557,97.0443349753694600,203,0],
  [24.51968,-104.095405,100.0,39,0],
  [24.067134,-104.696338,87.9310344827586200,58,0],
  [24.0240839,-104.727622,92.8057553956834500,278,0],
  [23.267915,-102.9373666,87.883959044368600,586,100.0],
  [23.86748,-105.224022,91.1111111111111100,180,0],
  [23.846485,-105.271391,90.1408450704225400,142,0],
  [23.775371,-105.339358,88.5714285714285700,70,0],
  [14.5632599,-92.2261183,77.7777777777777800,9,0],
  [23.588169,-105.721589,39.56043956043956400,91,0],
  [23.554537,-105.754136,61.4754098360655800,122,0],
  [23.512843,-105.780913,72.8971962616822500,107,0],
  [23.487742,-105.821538,66.9902912621359100,103,0],
  [23.442314,-105.840356,73.8738738738738700,111,0],
  [23.423538,-105.88249,56.6265060240963800,83,0],
  [23.399676,-105.922031,57.7777777777777700,90,0],
  [23.362935,-105.9481969,83.4951456310679600,103,0],
  [23.2939219,-106.023291,93.9698492462311500,199,0],
  [23.237105,-106.099644,88.6075949367088700,79,0],
  [30.703458,-112.209027,100.0,22,0],
  [30.694135,-112.257696,100.0,7,0],
  [30.716842,-112.353718,100.0,7,0],
  [30.730879,-112.4012,100.0,7,0],
  [30.744761,-112.448283,100.0,7,0],
  [30.79984,-112.581133,100.0,8,0],
  [30.804905,-112.713117,100.0,11,0],
  [30.832902,-112.802959,100.0,9,0],
  [30.849405,-112.904438,100.0,9,0],
  [31.308977,-113.289659,100.0,9,0],
  [31.322265,-113.340322,100.0,8,0],
  [31.323716,-113.434877,100.0,8,0],
  [31.351445,-113.51756,78.3333333333333300,60,0],
  [31.581051,-113.372428,100.0,9,0],
  [31.627288,-113.343266,100.0,9,0],
  [31.672954,-113.314446,100.0,9,0],
  [31.714394,-113.213426,100.0,7,0],
  [31.716819,-113.167569,100.0,8,0],
  [31.713221,-113.115188,100.0,8,0],
  [19.352735,-98.632507,93.1407942238267200,277,73.6585365853658500],
  [23.919849,-105.146085,97.0588235294117600,102,0],
  [21.88243,-102.2585333,96.4383561643835700,730,0],
  [18.7950399,-99.2211466,100.0,7,0],
  [18.5863233,-99.2560616,90.0,10,0],
  [29.085522,-110.927132,98.0769230769230700,104,0],
  [32.53993,-116.902039,85.6573705179282900,502,0],
  [32.532777,-116.949586,85.0605652759084800,743,0],
  [16.593114,-94.992943,39.85507246376811500,276,0],
  [16.601722,-95.044051,44.56521739130434600,184,0],
  [16.600071,-95.095339,52.3809523809523800,147,0],
  [23.98478,-104.6048166,95.4990733386285400,11331,0],
  [26.0407916,-98.405295,83.3333333333333400,6,0],
  [20.696496599999996,-87.048005,92.7592954990215300,511,0],
  [19.8870416,-98.6320116,100.0,30,0],
  [25.3995783,-100.9234499,51.8723994452149800,1442,0],
  [20.532955,-100.4000333,95.7236842105263200,304,0],
  [21.0095799,-102.6270666,94.9275362318840600,138,68.1818181818181900],
  [31.840467,-112.91346,100.0,8,0],
  [30.365169,-106.520436,98.0392156862745100,51,0],
  [31.365563,-106.467749,77.7777777777777800,72,0],
  [20.152258,-98.340419,95.6521739130434800,23,0],
  [20.1268783,-98.30256,97.7777777777777700,45,0],
  [20.1501966,-98.0912116,92.4242424242424200,66,0],
  [20.3670183,-97.916445,87.0129870129870100,77,0],
  [20.4748966,-97.6872283,96.00,25,0],
  [22.53717,-101.610288,92.8571428571428600,112,0],
  [19.1136816,-96.17183,77.2727272727272700,88,0],
  [26.3789099,-103.9944833,88.8888888888888800,27,0],
  [26.46001,-104.07005,78.9473684210526300,19,0],
  [26.5742799,-104.17425,95.4545454545454600,22,0],
  [28.72928,-106.1288833,89.678030303030300,1056,0],
  [21.6972599,-101.6145666,100.0,3,0],
  [26.77198,-104.3607333,93.2330827067669100,133,0],
  [28.628575,-106.1190166,70.5882352941176400,17,0],
  [28.58435,-106.1068166,90.0,30,0],
  [21.65616,-101.4800666,100.0,1,0],
  [27.367286,-104.93724,93.7062937062937100,143,100.0],
  [22.1508899,-100.8887666,89.9408284023668700,169,0],
  [20.61712,-103.0059666,50.0,2,0],
  [24.6942083,-107.4444166,95.3548947254178400,4607,0],
  [20.71249,-103.2975333,50.0,368,0],
  [19.67818,-100.5313666,93.7500,160,0],
  [21.19075,-104.7306666,92.3076923076923100,13,0],
  [25.95597,-100.16465,84.6153846153846100,13,0],
  [19.292145,-90.8724199,100.0,2,0],
  [27.50385,-99.5811433,95.9247648902821300,319,0],
  [27.70531,-99.7394316,96.800,250,0],
  [27.685885,-99.4634883,88.0952380952380900,84,0],
  [27.6614416,-99.5879066,100.0,5,0],
  [21.2103583,-104.7868499,91.6666666666666600,12,0],
  [19.5458166,-101.5988166,96.9026548672566300,452,0],
  [19.1434016,-98.22131490000001,84.6310088016249200,1477,0],
  [25.9553133,-98.21237,86.3636363636363600,44,0],
  [19.1513083,-99.54655,84.6153846153846100,39,0],
  [21.199165,-100.6148833,90.8653846153846100,624,0],
  [21.1259883,-101.73515,94.1176470588235300,34,0],
  [16.974973,-93.483856,29.2035398230088500,113,0],
  [16.732357,-93.498476,53.8461538461538400,26,0],
  [16.282412,-93.869069,26.43678160919540300,87,0],
  [21.1980199,-101.3415666,55.5555555555555600,9,2.380952380952383600],
  [16.768418299999997,-93.28801000000001,64.6408839779005500,181,0],
  [18.7838949,-97.4881749,95.8549222797927400,193,0],
  [18.5724883,-97.46420160000001,94.1860465116279100,86,0],
  [17.98793,-92.966995,86.440677966101700,649,0],
  [20.905835,-89.45697,97.5206611570247900,242,0],
  [20.741495,-88.661029,100.0,182,0],
  [20.840431,-87.8011719,98.1927710843373500,166,0],
  [21.084244,-87.001367,94.623655913978500,186,0],
  [32.621907,-115.56632,88.7101860611794400,3171,0],
  [18.518109,-88.33261,86.0869565217391300,805,0],
  [24.911999,-107.717706,96.6942148760330600,242,0],
  [26.518382,-109.107059,96.9298245614035100,228,0],
  [26.474668,-109.069238,97.8165938864628800,229,0],
  [26.3921819,-109.008281,97.4537037037037100,864,0],
  [28.1348466,-110.9717166,98.0582524271844700,206,0],
  [28.2082066,-111.0094833,98.6607142857142900,224,0],
  [26.2291649,-109.054272,93.8461538461538500,260,0],
  [28.6260766,-111.0124333,94.0298507462686600,268,0],
  [24.7499189,-107.469116,95.559502664298400,10697,0],
  [28.7330099,-110.9775666,96.5517241379310400,203,0],
  [26.121111,-109.041126,96.0526315789473700,228,0],
  [28.8580566,-110.9583833,96.2085308056872100,211,0],
  [14.8582533,-92.1918916,76.1904761904761900,21,86.1111111111111200],
  [26.076559,-109.029167,92.207792207792200,308,0],
  [24.751397,-107.366028,94.7064485081809400,2078,0],
  [21.193296,-104.643457,85.2564102564102500,156,0],
  [21.0009816011782,-101.28776660234199,97.9969183359013800,649,0],
  [21.568876,-104.885014,87.2727272727272800,330,0],
  [19.1471649,-90.7270516,50.0,8,0],
  [30.585231,-111.397682,74.4360902255639100,133,0],
  [29.0268466,-110.9601666,90.0501672240802700,1196,71.4285714285714300],
  [25.593217,-102.95171,98.5915492957746500,71,0],
  [24.901373,-107.500981,100.0,36,0],
  [20.3814533,-96.9363916,100.0,4,0],
  [25.615041,-103.29485,96.1538461538461600,52,0],
  [25.634268,-103.491038,74.3055555555555600,288,0],
  [22.6228216,-101.7660833,93.8053097345132800,113,0],
  [22.64199,-101.8929666,92.9824561403508800,114,0],
  [26.759735,-109.315685,92.7272727272727200,220,0],
  [26.802704,-109.347694,96.2184873949579900,238,0],
  [28.910181,-110.915647,81.9512195121951300,205,0],
  [29.273625,-110.945244,96.5116279069767500,172,100.0],
  [27.423137,-109.852193,99.286733238231100,1402,0],
  [28.583556,-111.027719,94.833948339483400,271,0],
  [27.488222,-109.927971,87.7647058823529400,425,0],
  [28.015827,-110.848779,98.3516483516483500,182,0],
  [27.740754,-110.395492,97.0297029702970300,202,0],
  [27.593976,-109.876899,89.3617021276595700,188,0],
  [28.640125,-105.9963666,90.9090909090909100,275,0],
  [30.90588,-112.968767,100.0,10,0],
  [18.6349216,-99.2948033,83.3333333333333400,6,0],
  [20.0534916,-98.4579766,76.00,50,0],
  [21.86845,-101.6800333,100.0,2,0],
  [21.8789699,-101.5463,77.7777777777777800,9,0],
  [30.312404,-106.502187,98.0392156862745100,51,0],
  [30.419299,-106.521456,92.3076923076923100,52,0],
  [30.466154,-106.522244,91.2280701754385900,57,0],
  [15.9423266,-93.5881783,100.0,55,77.7777777777777800],
  [21.2209133,-101.7819333,66.6666666666666700,3,0],
  [30.520471,-106.52258,100.0,54,0],
  [30.671807,-106.51525,100.0,55,0],
  [30.94249,-106.47559,96.3636363636363600,55,0],
  [31.21206,-106.508113,98.2142857142857100,56,0],
  [31.267306,-106.504292,84.2105263157894700,57,0],
  [31.318756,-106.480041,66.6666666666666700,57,0],
  [20.139912,-98.04262,91.8367346938775500,49,0],
  [20.226389,-98.03012,73.3333333333333400,45,0],
  [20.4055329,-97.940445,90.0,30,0],
  [20.438834,-97.895863,71.4285714285714300,14,0],
  [20.49348,-97.79235,46.15384615384615600,13,0],
  [20.527651,-97.743687,64.2857142857142800,14,0],
  [20.55425,-97.7039999,92.8571428571428600,14,0],
  [20.593053,-97.648951,70.5882352941176400,17,0],
  [20.661176,-97.522117,76.1194029850746200,67,0],
  [20.45933,-97.7302066,85.1851851851851900,27,0],
  [21.0420999,-104.3137666,93.8875305623471900,409,76.6666666666666600],
  [27.59645,-99.4450549,98.8505747126436800,87,0],
  [23.615034,-106.566885,24.39024390243902400,41,0],
  [23.535922,-106.509347,76.3157894736842100,38,0],
  [16.7608469,-93.178413,79.4836956521739100,736,0],
  [17.83431,-94.55219,87.8787878787878800,33,0],
  [29.098598,-111.022542,87.8571428571428600,140,0],
  [17.015879,-93.509232,14.11764705882353500,85,0],
  [18.5178966,-97.5025766,94.1489361702127600,188,0],
  [22.7752266,-103.56355,95.1557093425605600,289,0],
  [20.87666,-89.202695,84.9206349206349200,378,0],
  [20.885222,-87.564245,96.5317919075144500,173,0],
  [18.516783,-88.378476,90.3846153846153900,52,0],
  [25.52452,-103.5973999,86.87500,160,0],
  [21.1329366,-105.1670833,91.4285714285714300,35,0],
  [20.8743533,-105.3952166,90.4761904761904800,21,0],
  [20.7541383,-105.3334166,97.2602739726027400,73,0],
  [20.7308916,-105.2835999,94.5490584737363700,1009,0],
  [21.1763933,-104.9013166,100.0,8,0],
  [26.35349,-100.118,100.0,1,0],
  [26.43536,-100.1533833,100.0,4,0],
  [26.6580399,-99.98831,100.0,3,0],
  [26.62871,-100.0235166,100.0,1,0],
  [26.59356,-100.0674166,100.0,1,0],
  [20.3485416,-103.5748166,96.3824289405684800,387,91.0112359550561800],
  [19.7921383,-101.0295499,93.0909090909090900,275,0],
  [19.7497883,-101.0843,100.0,10,0],
  [20.5020633,-103.4149333,100.0,16,0],
  [28.691569,-106.912507,100.0,16,0],
  [17.240008,-94.633037,58.8235294117647100,17,0],
  [17.87913,-94.563028,100.0,37,0],
  [19.099246,-97.012038,45.4545454545454600,44,0],
  [19.1722709,-96.869611,83.3333333333333400,78,0],
  [18.721249,-97.298996,93.9655172413793100,116,0],
  [18.350159,-97.35923,95.6521739130434800,46,0],
  [16.963477,-97.596832,9.99999999999999800,80,0],
  [18.6290039,-99.136593,96.5517241379310400,29,0],
  [19.0842683,-100.6674666,86.9565217391304300,23,0],
  [26.026224,-109.014383,92.553191489361700,376,0],
  [24.300154,-107.09067,93.4782608695652200,46,0],
  [18.267316,-93.207821,78.6324786324786300,117,0],
  [18.9802,-104.1954666,100.0,16,94.8571428571428600],
  [25.931285,-98.320005,100.0,13,0],
  [19.23695,-99.51926,94.3548387096774200,124,0],
  [19.1908816,-99.524965,89.4308943089430900,123,0],
  [19.1413999,-99.5904549,100.0,18,0],
  [26.682921,-109.249474,95.8333333333333400,216,0],
  [16.22159499999998,-95.1943680000003,57.320477036285200,3941,0],
  [25.2497266,-101.1448999,94.7488584474885800,438,0],
  [23.817464,-106.608198,98.2142857142857100,56,0],
  [23.767046,-106.601318,100.0,59,0],
  [23.490751,-106.480844,94.7368421052631600,38,0],
  [31.464285,-106.46747,91.8032786885245900,61,0],
  [20.138192,-98.19668,95.3488372093023300,43,0],
  [20.141535,-98.138718,95.5555555555555600,45,0],
  [20.6357469,-97.574045,100.0,15,0],
  [20.2283633,-97.9774916,87.500,24,0],
  [20.4355533,-97.771905,68.7500,16,0],
  [20.49828,-97.6132883,66.6666666666666700,3,0],
  [21.1537733,-101.6973833,92.3076923076923100,130,84.6153846153846100],
  [26.00247,-98.20728,90.7894736842105300,76,0],
  [21.93696,-102.2742666,94.5472249269717600,1027,0],
  [17.565585,-94.113801,0,27,0],
  [17.9731789,-94.568222,80.4878048780487900,1189,0],
  [26.9413816,-101.4137666,96.4644435516271500,2489,0],
  [20.6609833,-100.3689333,90.0,10,0],
  [21.1164249,-104.8259666,100.0,2,0],
  [31.1980466,-109.9277666,100.0,2,0],
  [25.93917,-98.667103,100.0,14,0],
  [20.868293,-102.660361,100.0,15,0],
  [22.108155,-100.9692,60.1503759398496200,133,0],
  [19.635985,-99.328565,95.4545454545454600,44,0],
  [26.4196399,-104.0324666,84.00,25,0],
  [26.61585,-104.2103499,91.6666666666666600,24,0],
  [26.65909,-104.2440333,90.4761904761904800,21,0],
  [21.7465983,-101.6076833,50.0,6,0],
  [21.85555,-101.5936666,66.6666666666666700,12,0],
  [28.60187,-106.0515,90.6976744186046600,172,0],
  [21.57366,-101.3691499,0,1,0],
  [20.66598,-103.2212,95.1612903225806500,62,0],
  [18.8413316,-96.7577766,94.1291585127201600,2044,69.798657718120800],
  [19.9625483,-101.2129666,100.0,7,0],
  [30.625296,-106.5141359,100.0,49,0],
  [17.96052,-94.180291,92.8571428571428600,84,0],
  [17.879403,-94.121435,86.8131868131868200,91,0],
  [17.739855,-93.98054,94.3396226415094400,53,0],
  [17.559416,-93.686019,7.1428571428571400,28,0],
  [17.526786,-93.650138,0,32,0],
  [17.289779,-93.583204,2.63157894736841800,76,0],
  [16.933912,-93.459863,29.0697674418604600,86,0],
  [16.861125,-93.402272,87.9807692307692300,208,0],
  [16.739209,-93.398246,94.7368421052631600,38,0],
  [16.705592,-93.540944,58.0645161290322500,31,0],
  [16.660555,-93.564447,92.8571428571428600,28,0],
  [16.460287,-93.729086,50.0,22,0],
  [18.7179666,-97.47163,100.0,94,0],
  [20.2467966,-102.1111666,100.0,10,0],
  [20.3226583,-102.0325333,94.4630872483221500,596,0],
  [19.6782466,-87.9703516,62.500,8,0],
  [20.8989629,-89.403736,98.7804878048780500,164,0],
  [20.876968,-89.30108,95.7186544342507600,327,0],
  [20.818424,-89.118103,100.0,160,0],
  [20.735962,-88.614828,95.312500,320,0],
  [20.7261099,-88.570029,95.5223880597014900,335,0],
  [20.745546,-88.261588,93.4482758620689600,290,0],
  [20.826564,-87.84577,98.7878787878787900,165,0],
  [20.864681,-87.710666,91.8781725888324900,197,0],
  [20.874159,-87.662031,92.1487603305785200,242,0],
  [23.14387,-102.690558,100.0,9,0],
  [23.177668,-102.478165,100.0,9,0],
  [19.3062866,-99.21031160000001,79.8449612403100800,129,85.7142857142857200],
  [28.481744,-106.193108,100.0,14,0],
  [28.347634,-106.336432,100.0,14,0],
  [28.30913,-106.5011339,100.0,14,0],
  [28.360822,-106.591128,100.0,13,0],
  [28.547695,-106.912121,100.0,21,0],
  [28.595838,-106.9122469,100.0,15,0],
  [19.1492116,-99.9138349,66.6666666666666700,9,0],
  [19.0014816,-102.2112833,66.6666666666666700,3,0],
  [20.7735379,-87.323789,0,19,0],
  [20.673139,-87.104814,89.6666666666666700,300,0],
  [17.708167,-94.08676,0,12,0],
  [17.494267,-94.169526,0,49,0],
  [17.449025,-94.549095,0,49,0],
  [17.343259,-94.447475,0,29,0],
  [17.223166,-94.679357,21.4285714285714300,14,0],
  [18.138307,-94.4285249,69.9324324324324300,296,0],
  [17.775436,-94.630019,50.7936507936507900,63,0],
  [25.9729416,-100.4408666,100.0,44,0],
  [26.1511316,-100.6599166,83.6363636363636400,55,0],
  [26.8973666,-101.4260333,96.8222043443282300,2486,0],
  [28.5507916,-100.9119333,83.3333333333333400,6,0],
  [19.020017,-97.029476,97.1428571428571400,35,0],
  [19.154269,-96.825916,81.8181818181818100,66,0],
  [30.003915,-107.2406833,100.0,2,0],
  [30.8895616,-108.1887166,83.3333333333333400,66,0],
  [31.2374866,-109.8624333,100.0,2,0],
  [30.99294,-110.2800666,100.0,4,0],
  [30.6784166,-110.8907166,100.0,1,0],
  [18.398929,-97.379332,97.8260869565217400,46,0],
  [17.737582,-97.350924,54.6666666666666600,75,0],
  [17.541342,-97.368661,25.00,16,0],
  [17.496845,-97.521556,100.0,19,0],
  [17.345069,-97.622063,0,8,0],
  [17.247086,-97.583132,15.55555555555555600,45,0],
  [17.09506,-97.5414739,98.437500,64,0],
  [19.3580733,-99.9588449,100.0,10,0],
  [19.43657,-100.34745,96.8571428571428500,350,0],
  [18.671377,-98.891114,100.0,26,0],
  [17.027468,-99.645788,100.0,15,0],
  [23.648276,-103.613576,50.0,10,0],
  [19.2700183,-100.4488833,90.0,20,0],
  [25.1320279,-101.1108129,100.0,9,77.9816513761467900],
  [25.612156,-99.8687099,100.0,12,0],
  [25.623116,-99.769205,100.0,17,0],
  [25.756284,-99.381186,100.0,8,0],
  [25.768954,-99.332192,100.0,7,0],
  [25.922258,-98.710616,100.0,13,0],
  [25.975064,-98.574314,100.0,19,0],
  [26.035136,-98.004186,100.0,8,0],
  [26.035303,-97.958958,100.0,21,0],
  [25.711536,-97.815937,100.0,25,0],
  [22.16633,-101.0013333,97.5460122699386500,326,0],
  [17.2062649,-100.6791833,100.0,8,0],
  [17.5178933,-101.2585666,87.8787878787878800,33,0],
  [24.711124,-107.341437,89.9159663865546200,119,0],
  [24.675681,-107.312745,90.5882352941176500,85,0],
  [24.6431809,-107.27838,95.1219512195121900,41,0],
  [25.886976,-109.015601,96.296296296296300,243,0],
  [24.605352,-107.241816,95.2380952380952300,42,0],
  [24.563669,-107.206584,77.1428571428571500,35,0],
  [24.523471,-107.1742019,77.5862068965517200,58,0],
  [24.48858,-107.144814,88.5245901639344200,61,0],
  [24.407474,-107.093877,80.4878048780487900,41,0],
  [24.212993,-107.048185,94.5945945945945900,37,0],
  [24.150624,-106.96671,91.4285714285714300,35,0],
  [24.0063,-106.768223,85.00,40,0],
  [23.936793,-106.693643,65.00,40,0],
  [22.426661,-100.317357,88.8888888888888800,27,78.8381742738589200],
  [17.292497,-96.99762,9.99999999999999800,20,0],
  [23.196007,-106.337786,90.9090909090909100,99,0],
  [16.30868,-95.235474,64.4776119402985100,335,0],
  [16.422091,-95.227571,59.7222222222222200,144,0],
  [16.512363,-95.175085,69.1964285714285700,224,0],
  [16.553251,-95.1419669,61.3861386138613900,303,0],
  [20.919194,-103.850226,90.8163265306122500,98,0],
  [18.8962633,-100.74285,90.9090909090909100,22,0],
  [21.3366066,-100.63185,91.04753114905400,2167,84.6153846153846100],
  [22.518348,-105.423688,100.0,68,0],
  [29.65464,-111.036191,97.6190476190476200,168,0],
  [27.494412,-109.842911,98.6577181208053700,149,0],
  [23.954997,-105.041054,75.8064516129032300,62,0],
  [23.7573009,-105.439823,65.1162790697674500,43,0],
  [23.621515,-105.688837,44.5783132530120500,83,0],
  [30.803652,-112.665888,100.0,7,0],
  [31.534848,-113.401559,100.0,8,0],
  [21.87274,-100.8965166,88.0952380952380900,42,0],
  [26.431974,-109.032869,96.135265700483100,414,0],
  [28.5068966,-111.0457166,94.4723618090452200,199,0],
  [28.787815,-110.9621833,97.0731707317073100,205,0],
  [20.36896,-100.7978499,77.0330652368185900,3357,0],
  [19.2165216,-90.7302099,33.33333333333333700,9,0],
  [20.3914016,-100.3697,79.6728971962616800,428,84.6846846846846900],
  [21.04832,-104.455792,90.3100775193798500,258,0],
  [21.1170699,-104.591409,100.0,41,0],
  [20.813462,-102.584597,100.0,22,0],
  [18.122705,-93.185685,100.0,21,0],
  [18.662972,-97.40037,100.0,35,0],
  [20.041077,-102.283838,81.4432989690721600,97,0],
  [21.07195,-100.5723,92.3076923076923100,104,0],
  [20.1661189,-102.371896,100.0,13,0],
  [19.985875,-101.3082833,100.0,10,0],
  [20.0921833,-101.5063,98.3050847457627200,59,0],
  [19.263655,-98.989023,96.5517241379310400,29,0],
  [30.811685,-106.501603,100.0,53,0],
  [31.975267,-113.183849,95.5357142857142900,112,0],
  [20.191180459375,-96.74879773125,69.2307692307692300,39,0],
  [23.73621,-106.779527,90.0,460,100.0],
  [24.004815,-104.771501,82.242990654205600,107,0],
  [23.7571489,-105.381489,75.4098360655737700,61,0],
  [23.256034,-106.053,93.2367149758454100,207,0],
  [31.789427,-112.988399,55.5555555555555600,9,0],
  [29.129254,-110.912462,85.9922178988326900,257,0],
  [21.30085,-101.9286166,52.7777777777777800,396,0],
  [20.956905,-101.3719,92.1921921921921900,333,0],
  [21.79908,-101.60095,40.0,5,0],
  [20.5325733,-0.023515,100.0,4,0],
  [26.3298083,-103.9488666,91.5492957746478900,71,0],
  [19.79055,-100.4556666,83.3333333333333400,12,0],
  [20.5315166,-100.2533166,89.4401698063146700,3769,0],
  [27.61813,-99.6822533,91.4285714285714300,35,0],
  [17.203727,-93.554817,36.36363636363636500,99,0],
  [23.1729033,-102.90055,91.0256410256410200,78,0],
  [18.519156,-88.424823,88.8888888888888800,36,0],
  [22.644991,-100.081892,85.7142857142857200,7,0],
  [24.74769,-107.563927,97.3484848484848500,264,0],
  [26.175107,-109.05567,94.3548387096774200,248,0],
  [24.740422,-107.419398,95.808895405669600,8184,0],
  [21.152863,-104.619707,87.7551020408163200,98,0],
  [21.2931316,-102.15555,95.1871657754010700,374,89.1625615763546800],
  [21.992128,-105.136445,91.9101123595505700,445,0],
  [21.894532,-105.10131,89.7959183673469400,294,0],
  [19.49672,-99.362932,63.7037037037037100,135,0],
  [25.183879,-107.832937,35.7142857142857100,28,0],
  [25.304571,-107.928875,60.0,25,0],
  [22.6766766,-102.0847333,86.842105263157900,114,0],
  [22.70036,-102.21625,83.962264150943400,106,0],
  [27.179393,-109.538129,96.330275229357800,218,0],
  [28.461479,-111.048602,95.734597156398100,211,0],
  [27.542203,-109.927141,98.7903225806451600,248,0],
  [27.602526,-110.056791,89.3382352941176500,272,0],
  [17.6930066,-92.3164816,89.4736842105263200,19,100.0],
  [18.921065,-90.72857,50.0,16,0],
  [19.1002816,-90.7255749,50.0,8,0],
  [32.553049,-115.247774,91.709844559585500,193,0],
  [32.444661,-114.653996,93.2702418506834900,951,0],
  [32.247943,-114.07682,86.5384615384615400,104,0],
  [27.601914,-110.112295,89.4736842105263200,266,0],
  [27.95022,-110.702124,96.4646464646464600,198,0],
  [31.220499,-113.101037,100.0,10,0],
  [31.392929,-113.491386,100.0,9,0],
  [21.8347425,-102.28409995000001,94.2018072289156700,1328,0],
  [21.81983,-102.0972833,100.0,4,0],
  [30.727045,-106.515762,100.0,55,0],
  [31.102544,-106.489187,96.3636363636363600,55,0],
  [31.565792,-106.467954,83.8235294117647100,68,0],
  [20.4942816,-97.5591566,100.0,8,0],
  [20.4058266,-100.1045166,90.9090909090909100,22,0],
  [32.566028,-115.955463,89.3700787401574800,254,53.8461538461538400],
  [31.283049,-112.53398,88.5714285714285700,140,0],
  [19.895297,-100.375494,96.4684014869888500,538,94.9421965317919100],
  [30.560605,-111.203814,83.8461538461538500,130,0],
  [29.968155,-111.110794,98.1818181818181800,165,0],
  [24.787116,-107.443095,95.4901123915208400,7029,0],
  [24.932604,-107.537248,77.0833333333333400,48,0],
  [25.010761,-107.595298,77.2727272727272700,22,0],
  [25.041047,-107.632968,80.9523809523809500,21,0],
  [25.071692,-107.673781,96.9696969696969700,33,0],
  [25.122653,-107.757574,65.00,40,0],
  [25.159995,-107.794377,36.6666666666666700,30,0],
  [25.208322,-107.876561,50.0,28,0],
  [25.254984,-107.907117,50.0,24,0],
  [30.588282,-111.495843,98.1981981981981900,222,85.7142857142857200],
  [20.4187466,-97.0421383,100.0,3,0],
  [22.2474316,-101.1015,87.500,168,0],
  [22.3218116,-101.1778666,88.3116883116883100,154,0],
  [20.23192,-96.7900983,100.0,9,0],
  [22.3950216,-101.3534166,56.1983471074380200,121,0],
  [22.4261299,-101.4618166,95.7264957264957300,117,0],
  [22.5068899,-101.5746499,92.5619834710743800,121,0],
  [22.6524916,-101.9510166,92.5619834710743800,121,0],
  [22.6662016,-102.0268166,91.6666666666666600,108,0],
  [22.68639,-102.13865,76.5217391304347800,115,0],
  [21.2313016,-97.6102833,100.0,26,95.4545454545454600],
  [27.111518,-109.470475,93.535278906538600,2707,0],
  [27.213403,-109.581829,97.630331753554500,211,0],
  [28.992202,-110.85346,95.1871657754010700,187,0],
  [27.303157,-109.697339,92.2818791946308700,298,0],
  [27.362457,-109.773858,97.0338983050847400,236,0],
  [27.390454,-109.80992,99.1144414168937300,1468,0],
  [27.589324,-109.926932,91.0344827586206900,290,0],
  [27.619382,-109.960587,94.9664429530201400,298,0],
  [27.610803,-110.009755,98.4455958549222700,193,0],
  [27.610218,-110.157405,98.4293193717277500,191,0],
  [28.030694,-110.893875,91.0979228486646900,337,0],
  [27.994925,-110.806405,97.340425531914900,188,0],
  [27.670614,-110.333723,98.2808022922636100,349,0],
  [27.892258,-110.517595,97.3958333333333400,192,0],
  [27.855384,-110.474956,97.087378640776700,206,0],
  [24.930211,-103.75882,95.4545454545454600,44,0],
  [24.7924616,-103.65,85.9872611464968200,157,100.0],
  [27.550354,-109.846843,95.9459459459459400,148,0],
  [27.446306,-109.812377,97.3451327433628300,452,0],
  [24.382717,-104.271716,93.6170212765957500,47,0],
  [28.6856916,-106.0086166,90.800,250,0],
  [24.287357,-104.392499,96.0784313725490200,51,0],
  [28.70211,-106.0889,84.431137724550900,167,0],
  [28.6733166,-106.1344666,74.6478873239436600,142,0],
  [23.967587,-104.990435,90.1639344262295100,61,0],
  [23.723838,-105.4826609,82.9268292682926800,41,0],
  [23.630076,-105.636217,54.6511627906976800,86,0],
  [30.702392,-112.305031,100.0,7,0],
  [30.758536,-112.495146,100.0,7,0],
  [30.772765,-112.543504,100.0,7,0],
  [30.819347,-112.6219,100.0,8,0],
  [30.843786,-112.858908,100.0,9,0],
  [30.86212,-112.94964,100.0,9,0],
  [31.00506,-112.960824,100.0,9,0],
  [31.046383,-112.941731,90.0,10,0],
  [31.092842,-112.972194,87.500,8,0],
  [31.129588,-112.998763,100.0,7,0],
  [31.161868,-113.032052,100.0,7,0],
  [31.19365,-113.064847,100.0,7,0],
  [31.26854,-113.192698,100.0,10,0],
  [31.332861,-113.385527,100.0,8,0],
  [31.487315,-113.431658,62.500,8,0],
  [31.691155,-113.26394,100.0,9,0],
  [31.733178,-113.073177,100.0,8,0],
  [19.4976433,-103.4571833,99.00,200,80.0608828006088300],
  [27.6075533,-99.49475,96.176470588235300,340,0],
  [27.59609,-99.54706,94.8640483383685800,331,0],
  [18.7527816,-99.2426233,55.5555555555555600,9,0],
  [18.7052366,-99.273785,60.0,10,0],
  [18.55365,-99.2090233,92.5925925925925900,27,0],
  [18.4866166,-99.1808283,100.0,18,0],
  [23.993825,-104.5430333,92.5806451612903200,310,0],
  [20.0210366,-98.4912366,82.3529411764705800,34,0],
  [21.7802599,-102.1514666,100.0,2,0],
  [21.7885699,-102.0016,100.0,2,0],
  [20.9273866,-101.44885,89.1191709844559600,772,0],
  [30.575782,-106.517873,100.0,55,0],
  [19.3958016,-103.54575,99.2187500,128,91.7667238421955400],
  [19.681810803577932,-101.17836441466198,94.5811051693404600,2805,0],
  [18.4233833,-100.6136166,100.0,12,0],
  [19.0133533,-102.27,100.0,3,0],
  [20.853394,-87.401291,75.8620689655172400,29,0],
  [17.532221,-94.2519839,0,71,0],
  [17.522454,-94.341723,0,72,0],
  [17.512453,-94.425748,0,68,0],
  [17.559387,-94.504925,0,43,0],
  [17.43837,-94.503894,17.7777777777777800,45,0],
  [17.262613,-94.541903,0,28,0],
  [17.155328,-94.794754,0,30,0],
  [17.956611,-94.83794,97.104247104247100,518,0],
  [18.06159,-94.490292,100.0,18,0],
  [23.1614483,-106.45405,95.4314720812182700,197,0],
  [24.2184666,-110.27665,100.0,2,0],
  [26.0323333,-100.5705166,100.0,107,0],
  [29.2893866,-100.9462499,93.7500,16,0],
  [18.769155,-97.218979,85.8823529411764800,170,0],
  [19.1920266,-99.58173,96.930946291560100,391,0],
  [20.310065,-99.868216,81.0303251735476800,13685,0],
  [28.562928,-106.14877,89.1891891891891900,37,0],
  [19.8304816,-98.6962516,91.3402061855670100,485,81.4041745730550300],
  [28.595245,-106.006808,92.682926829268300,41,0],
  [23.9538099,-104.6552833,90.3614457831325300,83,0],
  [27.395083,-109.891895,99.905482041587900,1058,0],
  [21.09735,-104.74765,100.0,1,0],
  [20.7792766,-105.3862166,95.00,60,0],
  [20.64754,-105.2388833,98.3471074380165300,121,0],
  [20.713455,-105.2188333,100.0,36,0],
  [21.2949083,-100.53171660000001,87.500,8,0],
  [29.5296683,-106.4469833,100.0,3,0],
  [30.58246,-107.9871333,100.0,2,0],
  [30.9041716,-108.2531833,83.0769230769230700,65,0],
  [30.99423,-108.3618166,100.0,2,0],
  [31.1437733,-109.97515,100.0,1,0],
  [32.49351,-116.9718833,83.7326607818411100,793,0],
  [18.468043,-97.447113,78.4905660377358500,265,0],
  [17.920808,-97.359995,95.312500,64,0],
  [17.476413,-97.297893,83.3333333333333400,24,0],
  [17.22543,-97.543319,15.51724137931034300,58,0],
  [16.875091666666666,-97.575651,3.846153846153843600,52,0],
  [16.894346,-97.655835,4.316546762589923400,139,0],
  [0.1638828,7.1048955,0,1,0],
  [19.3847666,-99.7683683,95.8333333333333400,24,0],
  [19.4523116,-100.3046166,97.5490196078431300,204,0],
  [19.3619933,-100.0118166,81.8181818181818100,11,0],
  [26.33964,-100.840339,92.682926829268300,41,0],
  [18.675018,-98.944423,100.0,25,0],
  [17.299496,-99.502945,88.2352941176470600,17,0],
  [17.195867,-99.532385,70.0,20,0],
  [23.755976,-103.9443749,100.0,15,0],
  [19.4008183,-100.3874499,85.1851851851851900,27,0],
  [19.2075966,-100.5795166,84.6153846153846100,13,0],
  [21.686283,-97.851625,86.7469879518072300,83,0],
  [19.2814416,-103.7297666,95.6896551724137900,348,0],
  [24.4467866,-104.1260833,94.4881889763779500,127,0],
  [17.434795,-97.208096,37.500,32,0],
  [17.407423,-97.165376,29.16666666666666300,24,0],
  [25.80712,-97.795809,100.0,23,0],
  [19.125517,-96.241865,84.5070422535211300,142,67.6470588235294200],
  [28.441655,-106.911192,90.8045977011494300,87,0],
  [21.1469,-86.868522,94.7891994315490300,2111,0],
  [21.0389599,-105.2264333,91.6666666666666600,12,0],
  [20.099149,-99.417316,82.4324324324324300,74,0],
  [19.39744,-97.9685466,75.00,4,0],
  [16.7702616,-99.787595,92.3076923076923100,91,0],
  [16.95787,-99.8465266,100.0,6,0],
  [17.11978,-100.3492666,100.0,7,0],
  [17.0411966,-100.2710333,100.0,2,0],
  [21.066343,-86.930008,100.0,26,0],
  [20.12871,-102.262988,100.0,15,0],
  [20.013125,-102.238092,89.7058823529411800,68,0],
  [20.4604766,-100.0215499,100.0,9,0],
  [18.8020229,-97.414107,95.4545454545454600,22,0],
  [18.74287,-97.18111,78.5714285714285700,112,0],
  [21.060635,-100.6363333,100.0,15,0],
  [19.4190866,-100.1501166,83.3333333333333400,6,0],
  [19.0373583,-100.7346166,100.0,5,0],
  [20.21506,-102.45589,100.0,10,0],
  [20.770505,-100.3468199,100.0,43,0],
  [23.1760283,-102.9500333,80.0,5,0],
  [17.0411499,-96.719423,73.1150793650793700,1008,0],
  [18.9792016,-90.72427,60.0,5,0],
  [17.766013,-94.025909,98.1818181818181800,55,0],
  [17.710278,-93.940001,88.00,50,0],
  [17.686846,-93.898784,83.3333333333333400,54,0],
  [17.663846,-93.859055,82.6923076923076900,52,0],
  [17.616935,-93.7697,71.1111111111111100,45,0],
  [16.895855,-93.430946,75.4491017964071800,167,0],
  [16.790152,-93.334855,60.0,100,0],
  [16.734416,-93.444763,88.5714285714285700,35,0],
  [16.625228,-93.598664,85.2941176470588200,34,0],
  [16.427821,-93.770551,86.9565217391304300,23,0],
  [16.3730179,-93.858349,65.9090909090909200,44,0],
  [16.327805,-93.856499,26.04166666666666300,96,0],
  [18.515075,-97.4543416,86.2385321100917400,218,0],
  [18.3494966,-101.89925,75.00,8,56.8033273915626900],
  [21.129992,-86.915456,90.0662251655629200,2416,0],
  [18.478865,-88.8151966,81.4814814814814900,27,0],
  [20.804516,-89.019962,98.7730061349693200,163,0],
  [20.78227,-88.916202,98.7654320987654300,162,0],
  [20.774733,-88.870029,95.424836601307200,153,0],
  [20.767828,-88.818382,93.567251461988300,171,0],
  [20.758444,-88.763471,98.3050847457627200,177,0],
  [20.716559,-88.51638,100.0,168,0],
  [20.708954,-88.463882,99.3902439024390200,164,0],
  [20.73668,-88.310552,100.0,168,0],
  [20.742841,-88.2164899,93.7086092715231800,302,0],
  [20.738886,-88.171373,100.0,176,0],
  [18.6201449,-90.1258166,91.3043478260869600,23,83.3333333333333400],
  [20.771729,-88.034148,99.3939393939393900,165,0],
  [20.785067,-87.986593,97.5155279503105500,161,0],
  [20.79878,-87.938068,95.5974842767295600,159,0],
  [20.812239,-87.892713,98.7500,160,0],
  [20.852635,-87.757567,94.37500,160,0],
  [20.878791,-87.61271,93.7500,224,0],
  [20.923067,-87.370707,94.5578231292517100,147,0],
  [20.97187,-87.222307,100.0,153,0],
  [20.993595,-87.1764,100.0,154,0],
  [21.04141,-87.087915,100.0,152,0],
  [21.061574,-87.047274,100.0,154,0],
  [18.139087,-94.5218469,67.8733031674208200,221,0],
  [23.14437,-102.639974,100.0,8,0],
  [23.160176,-102.530465,100.0,7,0],
  [28.326964,-106.549747,87.500,40,0],
  [28.379035,-106.746374,100.0,19,0],
  [28.40032,-106.7911699,100.0,19,0],
  [28.443096,-106.807736,100.0,21,0],
  [28.446942,-106.863636,89.3617021276595700,47,0],
  [28.494154,-106.911959,100.0,21,0],
  [28.643453,-106.912359,100.0,14,0],
  [28.856365,-106.80475,92.3076923076923100,13,0],
  [28.89196,-106.769231,100.0,13,0],
  [28.911409,-106.720229,100.0,14,0],
  [28.967495,-106.710498,100.0,14,0],
  [29.020154,-106.711845,100.0,14,0],
  [15.316988,-92.339394,45.1807228915662600,166,0],
  [20.759698,-88.078035,98.7951807228915600,166,0],
  [19.2550883,-99.72135,100.0,18,0],
  [19.239974949999997,-99.77185747499999,100.0,24,0],
  [18.9711933,-100.0698833,95.00,60,0],
  [18.78396,-100.4289333,0,3,0],
  [18.4733716,-100.5842666,100.0,30,0],
  [19.0762183,-102.3512666,95.2380952380952300,63,0],
  [18.991635,-102.16535,100.0,3,0],
  [20.739972,-87.26763,0,20,0],
  [17.655569,-94.092543,0,16,0],
  [17.606236,-94.09264,0,22,0],
  [17.508458,-94.212969,0,80,0],
  [17.491499,-94.568107,0,47,0],
  [17.3878689,-94.432359,0,29,0],
  [17.254521,-94.587701,27.2727272727272700,22,0],
  [17.099229,-94.917126,0,16,0],
  [17.0928289,-94.961714,7.1428571428571400,14,0],
  [23.2285616,-106.702,0,1,0],
  [26.0002333,-100.5059666,100.0,42,0],
  [26.0959133,-100.6408333,91.8032786885245900,61,0],
  [26.2859166,-100.7509999,88.5714285714285700,35,0],
  [26.3161966,-100.8013666,86.4864864864864900,37,0],
  [26.4532666,-101.0300833,100.0,40,0],
  [26.52509,-101.1505833,100.0,39,0],
  [26.58508,-101.2515666,100.0,42,0],
  [26.6111866,-101.2946666,100.0,40,0],
  [26.6430483,-101.33485,100.0,42,0],
  [27.07245,-101.3089833,100.0,5,0],
  [28.45501,-100.9051166,90.0,10,0],
  [28.4980516,-100.9202833,77.7777777777777800,9,0],
  [28.611705,-100.9076,50.0,2,0],
  [28.6587266,-100.9095666,50.0,2,0],
  [28.7045733,-100.9113833,100.0,2,0],
  [28.754525,-100.9133166,100.0,2,0],
  [28.8015116,-100.9151166,100.0,2,0],
  [28.8616283,-100.9174833,100.0,3,0],
  [28.90725,-100.91925,100.0,3,0],
  [29.135635,-100.8949166,100.0,4,0],
  [19.0243133,-102.0593666,97.0588235294117600,34,91.4867109634551500],
  [29.75157,-106.7149666,100.0,1,0],
  [29.8196816,-106.8675333,100.0,2,0],
  [29.8740366,-106.9164333,100.0,2,0],
  [29.97338,-107.0851,100.0,1,0],
  [29.98192,-107.1473333,100.0,2,0],
  [30.048915,-107.3471333,100.0,1,0],
  [30.1079,-107.4901833,100.0,1,0],
  [30.1782766,-107.6226833,100.0,1,0],
  [30.2835866,-107.7880333,100.0,1,0],
  [30.4542066,-107.9069166,100.0,4,0],
  [30.5008266,-107.9499,100.0,2,0],
  [30.6639283,-108.0235333,100.0,2,0],
  [30.745325,-108.0615,100.0,1,0],
  [30.7996166,-108.1094833,100.0,1,0],
  [30.8538666,-108.1575,100.0,2,0],
  [30.9399,-108.3152,100.0,2,0],
  [31.1569316,-108.4829166,0,1,0],
  [31.2383283,-108.5720833,100.0,1,0],
  [31.3168566,-109.2282666,100.0,1,0],
  [31.3249216,-109.4561166,100.0,4,0],
  [31.316075,-109.5287166,100.0,3,0],
  [31.30459,-109.6202166,100.0,1,0],
  [31.2901183,-109.6831666,100.0,1,0],
  [31.273205,-109.7461,100.0,1,0],
  [31.095965,-110.0556833,100.0,2,0],
  [31.031415,-110.1963166,100.0,1,0],
  [31.0272516,-110.3765,100.0,5,0],
  [31.0117766,-110.5071833,100.0,3,0],
  [30.9498966,-110.5968,50.0,2,0],
  [30.8635716,-110.7517166,90.0,10,0],
  [30.83323,-110.7919166,100.0,5,0],
  [30.78573,-110.8478499,100.0,3,0],
  [30.6267716,-110.9493333,100.0,3,0],
  [30.6033316,-111.0251166,100.0,2,0],
  [18.184835,-97.275513,48.00,50,0],
  [18.151615,-97.30599,6.45161290322581200,31,0],
  [18.118754,-97.3378369,9.30232558139535400,43,0],
  [17.875533,-97.374999,90.1960784313725500,51,0],
  [17.781583,-97.361385,64.5161290322580600,31,0],
  [17.598838,-97.321074,26.31578947368421500,19,0],
  [17.555425,-97.28681,63.6363636363636400,33,0],
  [17.5122,-97.259314,69.2307692307692300,39,0],
  [17.464588,-97.248369,66.6666666666666700,45,0],
  [17.512917,-97.33334,76.9230769230769200,13,0],
  [17.573664,-97.405186,0,18,0],
  [17.532891,-97.434774,6.2500,16,0],
  [17.307687,-97.655699,73.5849056603773600,53,0],
  [17.135933,-97.5200459,77.6470588235294100,85,0],
  [16.919016,-97.587717,.87719298245614300,114,0],
  [19.37731,-99.87857,88.8888888888888800,9,0],
  [19.4553533,-100.2519166,96.1538461538461600,52,0],
  [19.0391629,-98.270422,67.5675675675675700,37,80.4687500],
  [29.6377783,-106.5631166,100.0,1,0],
  [18.65374,-98.998237,92.5925925925925900,27,0],
  [18.66387,-99.0462639,80.7692307692307700,26,0],
  [18.334582,-99.192587,100.0,19,0],
  [18.29141,-99.206416,100.0,19,0],
  [18.192162,-99.205268,94.7368421052631600,19,0],
  [18.09207,-99.222486,76.4705882352941100,17,0],
  [18.051344,-99.252629,88.2352941176470600,17,0],
  [17.932878,-99.371773,90.0,20,0],
  [17.85217,-99.430528,84.00,25,0],
  [17.810277,-99.464307,94.7368421052631600,19,0],
  [17.764414,-99.481264,88.8888888888888800,18,0],
  [17.704928,-99.508881,50.0,12,0],
  [17.657864,-99.498036,73.3333333333333400,15,0],
  [17.61765,-99.519432,95.6521739130434800,23,0],
  [17.468567,-99.472819,64.4230769230769200,104,0],
  [17.420188,-99.464687,75.00,20,0],
  [17.340937,-99.480244,50.0,12,0],
  [17.246284,-99.522128,88.8888888888888800,18,0],
  [17.151832,-99.554271,75.00,24,0],
  [16.964824,-99.734722,61.1111111111111200,18,0],
  [16.901166,-99.755823,67.9999999999999900,25,0],
  [16.856086,-99.753916,75.00,24,0],
  [16.815498,-99.773419,90.62500,96,0],
  [23.605618,-103.327795,58.3333333333333300,12,0],
  [23.763673,-103.888546,100.0,15,0],
  [23.836226,-104.172939,100.0,14,0],
  [23.885487,-104.312692,100.0,16,0],
  [24.012533,-104.393234,100.0,14,0],
  [19.318055,-100.4345166,81.2500,16,0],
  [19.133185,-100.6603,73.3333333333333400,15,0],
  [17.381313,-97.124718,36.842105263157900,19,0],
  [17.26218,-96.897825,72.2222222222222200,72,0],
  [17.177232,-96.845444,100.0,36,0],
  [17.14496,-96.80938,89.3939393939393900,66,0],
  [17.122865,-96.764816,83.0985915492957700,142,0],
  [25.601488,-99.918904,100.0,14,0],
  [18.821243,-98.613922,100.0,14,50.0],
  [25.7040979,-99.536544,100.0,8,0],
  [25.720643,-99.484707,100.0,9,0],
  [25.783534,-99.283705,100.0,7,0],
  [25.796474,-99.237381,100.0,7,0],
  [25.82222,-99.089865,100.0,7,0],
  [25.833796,-99.045575,100.0,7,0],
  [25.845792,-98.99964,100.0,7,0],
  [25.857003,-98.952493,100.0,7,0],
  [25.882513,-98.859101,100.0,7,0],
  [25.894809,-98.813174,100.0,8,0],
  [25.908741,-98.757443,100.0,8,0],
  [25.991406,-98.532138,100.0,9,0],
  [26.013511,-98.099832,100.0,7,0],
  [26.018291,-98.054911,100.0,8,0],
  [26.045632,-97.865439,100.0,9,0],
  [26.00397,-97.769768,100.0,11,0],
  [25.954874,-97.767971,100.0,21,0],
  [25.909547,-97.790173,100.0,21,0],
  [25.665518,-97.816028,96.6573816155988800,359,0],
  [16.9547466,-99.9354433,66.6666666666666700,3,0],
  [17.010935,-100.1014833,100.0,8,0],
  [17.0790916,-100.3146666,80.0,5,0],
  [17.3188,-101.0449166,100.0,11,0],
  [17.3755666,-101.0511333,100.0,8,0],
  [17.3739633,-101.1130333,100.0,11,0],
  [17.4272399,-101.1896999,83.3333333333333400,6,0],
  [17.4553833,-101.22905,87.500,8,0],
  [26.598836,-109.176639,97.0414201183431900,338,0],
  [16.2612029,-95.2207449,65.9047619047619100,525,0],
  [23.194256,-106.28881,92.9133858267716600,381,0],
  [21.016162,-89.563952,68.2872101720269200,2674,0],
  [20.738727,-89.836187,93.0817610062893100,318,100.0],
  [25.6273899,-99.666704,100.0,9,0],
  [17.2164566,-100.7886166,100.0,6,0],
  [17.271425,-100.8995833,88.8888888888888800,9,0],
  [17.2725666,-100.9481,100.0,8,0],
  [17.2679583,-101.0321166,100.0,9,0],
  [22.199673,-98.414331,96.6666666666666700,30,89.8305084745762800],
  [31.410887,-106.461028,81.9444444444444400,72,0],
  [19.88009319375,-100.14585208124997,95.2325581395348800,860,89.4308943089430900],
  [26.8034499,-99.8647383,95.7720588235294200,544,100.0],
  [20.4832266,-100.1851166,88.3152173913043500,1104,0],
  [21.23160154074074,-104.9267287031746,95.9459459459459400,74,0],
  [21.2002466,-105.0943833,76.6666666666666600,30,0],
  [20.8226266,-105.3999666,95.8333333333333400,48,0],
  [21.2138116,-105.0356999,96.00,25,0],
  [21.2122633,-104.9905666,96.296296296296300,54,0],
  [22.547523,-100.481702,46.15384615384615600,13,25.7142857142857100],
  [22.478855,-100.3951,45.4545454545454600,11,46.6666666666666700],
  [22.453131,-100.354498,81.8181818181818100,22,56.2500],
  [22.279573,-100.255544,100.0,5,100.0],
  [22.242302,-100.229341,100.0,6,86.6666666666666700],
  [22.202455,-100.185252,100.0,5,93.548387096774200],
  [22.1345129,-100.109171,100.0,5,100.0],
  [18.621756599999998,-97.46787660000001,82.653061224489800,98,0],
  [20.958302,-89.567407,81.8628414226763600,5089,0],
  [20.91788,-89.612017,97.1428571428571400,315,0],
  [20.893203,-89.655171,92.0273348519362200,439,100.0],
  [19.3032983,-98.8458299,81.6167418957734900,2437,86.1788617886178900],
  [24.288567,-107.182085,95.5497382198952900,382,100.0],
  [19.3636633,-98.8636766,98.7854251012145700,247,76.5957446808510600],
  [20.2679566,-100.0018999,71.0256410256410200,390,71.6981132075471800],
  [19.8606566,-99.31568,93.2735426008968600,2453,90.1554404145077700],
  [23.684278,-106.759071,90.156599552572700,447,100.0],
  [23.635198,-106.731934,93.093922651933700,362,100.0],
  [17.96706,-95.1683516,82.4675324675324700,308,26.08695652173913500],
  [27.927467,-110.601282,96.3541666666666600,192,0],
  [23.819508,-105.313547,95.0819672131147500,61,0],
  [28.16023,-105.4354666,83.2695984703632800,1046,100.0],
  [23.1856633,-106.55005,100.0,6,0],
  [24.12098,-110.296,99.4736842105263100,190,0],
  [19.554645,-90.69067,95.8188153310104500,287,100.0],
  [21.660339,-104.993932,82.8804347826086900,368,0],
  [21.077691,-104.5544759,82.2222222222222200,45,0],
  [21.623196,-104.962329,46.0526315789473700,76,0],
  [21.668195,-105.038762,88.7942332896461300,1526,86.9565217391304300],
  [18.753443,-91.586377,95.3642384105960300,302,100.0],
  [17.9985366,-93.57395,94.9519230769230700,416,66.6666666666666700],
  [17.9988116,-93.468715,76.6544117647058900,544,70.0],
  [22.851671,-105.793103,88.5017421602787500,287,80.0],
  [19.909565,-99.8490999,88.9669007021063200,997,83.8883888388838900],
  [21.5674299,-101.6785333,87.500,80,85.5555555555555600],
  [28.785592,-106.097725,88.8888888888888800,126,100.0],
  [30.890395,-110.71075,75.00,8,0],
  [18.426443,-97.415034,80.5031446540880500,159,0],
  [18.018587,-97.3523869,45.0980392156862700,51,0],
  [18.66767,-99.106612,80.0,15,0],
  [17.072462,-99.621517,58.8235294117647100,17,0],
  [23.440317,-103.064966,100.0,13,0],
  [17.0792,-96.747298,68.3899556868537700,677,0],
  [17.3884666,-101.1615,100.0,9,0],
  [24.128341,-106.922925,74.4680851063829900,47,0],
  [24.046615,-106.79056,78.3783783783783800,37,0],
  [20.4641069,-97.842905,42.8571428571428600,14,0],
  [18.946067,-98.385746,84.2105263157894700,19,97.183098591549300],
  [31.0484633,-108.3982166,100.0,3,0],
  [23.399513,-106.448834,65.7142857142857100,35,0],
  [19.5140216,-98.46856,91.2152269399707200,683,78.4355179704016900],
  [21.18095,-100.5716,89.2482517482517500,1144,99.0196078431372600],
  [32.5499579,-116.491683,82.0512820512820500,78,100.0],
  [21.571013,-104.986534,93.2409012131715800,577,100.0],
  [20.395109,-102.724559,93.4950385887541300,907,79.6568627450980400],
  [30.932242,-112.332045,93.9597315436241600,149,100.0],
  [25.2829533,-100.0033833,76.0683760683760600,117,77.0334928229665100],
  [22.7124916,-102.2837333,91.3043478260869600,115,0],
  [25.4237099,-100.1473333,100.0,2,95.4545454545454600],
  [23.986504,-104.866766,97.6744186046511600,43,0],
  [23.970629,-104.918096,96.0784313725490200,51,0],
  [23.70355,-105.576932,84.2364532019704400,203,0],
  [31.823025,-112.956173,50.0,8,0],
  [21.9157099,-100.8607,80.7692307692307700,52,100.0],
  [21.4009499,-102.15105,82.3529411764705800,51,100.0],
  [0.1638425,0.4478361,100.0,2,0],
  [25.735722,-100.208141,84.0085287846481800,1407,96.3636363636363600],
  [21.8426083,-102.3924333,87.2727272727272800,55,61.2903225806451600],
  [18.005302,-94.2051649,87.8787878787878800,33,0],
  [21.177975,-101.7409666,76.9230769230769200,13,80.7017543859649200],
  [28.4153166,-111.0439333,98.1566820276497700,217,0],
  [28.6780916,-110.9931166,97.1698113207547200,212,0],
  [25.6586329,-102.677184,100.0,40,100.0],
  [24.5988016,-99.5354099,100.0,5,71.7647058823529400],
  [27.269552,-109.654146,97.6415094339622600,212,0],
  [25.5565466,-103.6372833,85.0549450549450500,455,85.7142857142857200],
  [16.21558,-94.0491349,100.0,3,62.9032258064516100],
  [29.937471,-106.377034,87.9310344827586200,58,100.0],
  [30.147558,-106.431205,92.4528301886792500,53,100.0],
  [19.4943149,-98.068805,90.0,310,0],
  [18.947081,-97.844397,99.5594713656387600,227,100.0],
  [20.1526716,-103.5027833,98.9473684210526300,95,90.5797101449275400],
  [19.986593,-99.181603,89.2307692307692200,195,0],
  [19.3265183,-98.316545,70.5882352941176400,17,95.00],
  [27.658765,-99.73545,96.6666666666666700,180,0],
  [21.0822483,-105.1997333,92.3076923076923100,13,0],
  [23.666989,-106.590475,43.5897435897435900,39,0],
  [19.6157866,-98.5447916,93.6571657968687200,4982,89.6226415094339600],
  [19.087618,-98.28662,94.8998178506375200,549,87.8048780487804900],
  [15.300679,-92.384321,8.75912408759124100,137,0],
  [19.245745,-97.873745,100.0,17,94.6037099494097900],
  [18.004743,-99.287555,93.7500,16,0],
  [17.564777,-99.510968,100.0,24,0],
  [25.466105,-108.208374,96.3455149501661100,301,100.0],
  [25.430872,-108.169551,97.0338983050847400,236,100.0],
  [32.445932,-116.841141,85.9523809523809600,840,85.7142857142857200],
  [20.879314,-103.823733,89.500,400,0],
  [24.112708,-107.059571,93.8028169014084500,355,0],
  [18.2807216,-95.7304166,98.0169971671388100,353,81.2500],
  [18.2241599,-95.6361116,90.2821316614420100,319,63.8888888888888800],
  [22.035103,-105.171643,92.653061224489800,245,0],
  [18.638495,-92.374769,97.8798586572438100,283,100.0],
  [20.926032,-89.506874,92.3672566371681400,4520,0],
  [18.0742683,-92.9342716,94.591029023746700,1516,100.0],
  [32.481819,-116.183644,95.0980392156862700,102,72.7272727272727300],
  [18.418565,-91.1730166,69.5652173913043500,23,100.0],
  [19.838287,-99.947809,93.198090692124100,838,89.0229885057471200],
  [32.335277,-114.29409,77.6785714285714300,112,100.0],
  [30.701152,-111.81642,97.5308641975308600,243,100.0],
  [20.417952,-103.410819,95.2500,400,79.6344647519582200],
  [22.3083066,-97.89586,81.8867924528301900,530,48.15281276238455600],
  [22.2364866,-97.8912466,82.1205821205821200,481,81.6176470588235300],
  [24.2547266,-103.3928166,90.410958904109600,146,100.0],
  [24.349225,-103.4275833,84.4961240310077500,129,100.0],
  [28.3022616,-105.5142,96.0784313725490200,153,100.0],
  [15.0630366,-92.40727,100.0,8,75.00],
  [20.2533616,-103.5428833,98.1132075471698100,106,93.8423645320197100],
  [20.036825,-103.5279,94.1176470588235300,85,92.803030303030300],
  [19.92458,-103.5289166,96.4788732394366200,142,84.6727423363711700],
  [26.233035,-100.0942666,95.047923322683700,626,100.0],
  [26.4454499,-100.0379166,97.3577235772357800,492,100.0],
  [26.57666,-99.9763949,95.2961672473867600,574,100.0],
  [27.59163,-99.6364883,95.8904109589041200,73,0],
  [18.929839,-97.012928,97.1066907775768500,553,0],
  [20.62885,-100.4648666,93.7944284341978900,26025,94.1176470588235300],
  [18.100698,-94.393158,57.8947368421052700,95,0],
  [26.009127,-98.155776,100.0,9,0],
  [25.60065,-99.96938,100.0,27,75.00],
  [17.383168,-93.604321,8.51063829787234200,141,0],
  [17.250228,-93.559347,10.23622047244094900,127,0],
  [20.890289,-89.351884,98.2248520710059200,169,0],
  [20.807004,-89.073334,100.0,161,0],
  [20.4298616,-87.3001566,75.00,8,100.0],
  [28.436386,-106.207618,100.0,14,0],
  [28.391635,-106.239159,100.0,14,0],
  [28.317637,-106.447985,100.0,14,0],
  [28.0328,-101.12135,100.0,4,0],
  [28.4084366,-100.8861333,100.0,11,0],
  [28.30207,-100.91055,100.0,8,100.0],
  [18.303804,-97.354388,95.8333333333333400,48,0],
  [18.228427,-97.295203,69.3181818181818100,88,0],
  [18.0683149,-97.352898,6.122448979591832400,49,0],
  [26.0189849,-98.4570816,96.00,25,0],
  [23.5434829,-103.170001,76.9230769230769200,13,0],
  [23.405004,-103.027816,100.0,14,100.0],
  [18.072495,-94.2629616,91.895424836601300,765,100.0],
  [19.7424399,-101.1762166,94.7619047619047600,2520,70.0070571630204700],
  [22.131776,-98.54574,100.0,15,100.0],
  [22.152492,-98.505543,100.0,15,92.1052631578947300],
  [22.175948,-98.460059,100.0,15,93.1034482758620700],
  [20.0831166,-99.6626166,94.7046843177189500,2455,86.9127516778523500],
  [20.0310616,-99.548375,93.0630630630630700,2220,88.2113821138211400],
  [32.360259,-116.955361,75.8620689655172400,87,62.500],
  [20.2792616,-102.3680999,98.5294117647058900,272,86.0824742268041300],
  [20.0410999,-99.4481566,79.5620437956204400,274,31.4102564102564100],
  [20.3208916,-102.2908166,92.0833333333333400,480,89.27203065134100],
  [32.425849,-116.88714,80.7881773399014800,203,66.6666666666666700],
  [20.3916416,-102.0963,96.9135802469135800,162,100.0],
  [20.0345266,-99.1769016,92.0634920634920700,441,91.5422885572139400],
  [20.4005766,-102.0125999,91.5841584158415900,202,88.4353741496598600],
  [18.3134466,-95.78458,93.3207902163687700,1063,79.0697674418604600],
  [22.143777,-105.238258,90.0,300,80.0],
  [20.185965,-99.8823,78.260869565217400,46,75.00],
  [20.0800033,-99.86308,88.6363636363636400,44,0],
  [23.293132,-106.360261,86.8055555555555600,288,100.0],
  [19.98301,-99.8711583,82.7272727272727300,110,19.04761904761904700],
  [21.870688,-105.149033,84.8837209302325500,86,55.5555555555555600],
  [18.0372366,-94.342315,94.3050193050193100,1036,100.0],
  [24.171081,-107.017138,97.1428571428571400,35,0],
  [24.112965,-106.869942,59.5238095238095200,42,0],
  [19.7256416,-99.8398016,90.5109489051094900,137,87.9895561357702300],
  [18.928365,-91.326672,64.4444444444444400,225,0],
  [28.859148,-106.211902,87.9184861717612800,1374,100.0],
  [32.523151,-116.327375,97.3684210526315800,114,91.6666666666666600],
  [19.974598,-101.741775,92.9347826086956500,552,87.7637130801687700],
  [17.15714,-100.5189333,100.0,6,0],
  [17.1661283,-100.5664333,100.0,5,0],
  [17.1851933,-100.6100499,100.0,8,0],
  [17.2084566,-100.7274666,100.0,9,0],
  [22.214213,-98.369872,94.2857142857142800,35,93.8144329896907200],
  [22.198516,-98.322535,100.0,18,100.0],
  [22.172519,-98.094003,100.0,27,57.7777777777777700],
  [29.707552,-111.04801,97.1264367816091900,174,100.0],
  [19.9693899,-96.5939949,100.0,2,33.33333333333333700],
  [24.256365,-107.0750319,97.7777777777777700,45,0],
  [19.74089,-90.593754,86.8055555555555600,288,100.0],
  [25.801877,-108.947787,90.1702361339923100,1821,39.81481481481481400],
  [25.786855,-108.890388,99.186991869918700,246,100.0],
  [20.120528,-90.1838889,96.4566929133858200,508,100.0],
  [20.222719,-90.097899,98.4615384615384700,260,100.0],
  [20.400091,-90.030576,91.9168591224018500,433,100.0],
  [20.4064266,-103.1903333,95.3535353535353600,495,84.1772151898734200],
  [20.513841,-89.9950549,85.8536585365853600,615,84.2105263157894700],
  [20.3863116,-102.9786166,100.0,24,100.0],
  [25.561248,-108.433588,86.3554757630161500,557,100.0],
  [20.3512166,-102.7944,100.0,37,73.2142857142857200],
  [26.907345,-104.5586,80.9210526315789500,152,100.0],
  [31.246613,-113.150981,100.0,11,0],
  [20.8435549,-102.7844499,88.801571709233800,509,89.6946564885496200],
  [19.4297916,-98.1349366,80.5970149253731300,67,0],
  [19.4810216,-98.1134866,50.7936507936507900,126,0],
  [20.2005966,-103.5253,96.2264150943396200,106,87.588652482269500],
  [20.0875583,-103.5169166,97.6470588235294100,85,95.5056179775280900],
  [19.0852583,-103.7761166,89.655172413793100,29,77.7327935222672100],
  [19.0325649,-103.7942333,88.00,25,82.9621380846325200],
  [19.012795,-104.2453499,100.0,15,76.3157894736842100],
  [19.9795883,-103.5357166,94.9494949494949500,99,95.2205882352941100],
  [26.3938649,-100.0573333,95.3307392996108900,514,100.0],
  [26.5268966,-100.0003166,95.6880152187698200,1577,60.7142857142857200],
  [27.5476433,-99.4520749,100.0,16,0],
  [27.5238299,-99.503415,100.0,13,0],
  [19.7552749,-101.3100499,94.9152542372881400,59,88.7175324675324700],
  [25.9729599,-98.408075,100.0,16,0],
  [28.04185592976191,-110.93898884761903,87.5236294896030300,529,0],
  
    
  ];
  