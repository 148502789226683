import React, { useState, useEffect } from "react";
import { NotificationManager} from 'react-notifications';
import { Crud_Catalogos } from "../herramientas/Crud_Catalogos";
import { tokenExpTime } from "../herramientas/JWT";
const softphone = sessionStorage.getItem("softphone") !== null? JSON.parse(sessionStorage.getItem("softphone")): {};
var tokenStore="tok";
const LogOut =()=> {
  useEffect(() => {
    desloguear();
  }, []);

  const desloguear=async()=>{    
    if(softphone && softphone.id && tokenExpTime(sessionStorage.getItem("tok")) ){
      await Crud_Catalogos( "softphone/desloguear_en_grupo", "softphone", "post", "", {}, "", "softphone")
        .then((data) => {
          NotificationManager.success(data.message, "Telefono");
        }).catch((err) => console.log(err));
    }
    sessionStorage.setItem("softphone", JSON.stringify({}));
    sessionStorage.setItem(tokenStore, "");
    window.location.href = "/login"; 
  }

  return (
    <>
    </>
  );
}
export default LogOut;
      