//local
//export const api_raiz = "http://localhost:8000/";

//qa
//export const api_raiz = "https://one.skynavieros.com:8080/skyone_qa/";

// Produccion
export const socket = "https://socket.skyangel.com.mx:8443/mensajeria_skyone/";
export const api_raiz = "https://one.skynavieros.com:8080/skyone/";
//export const api_raiz= "https://one2.skycopernico.com:8080/skyone/";

export const cctv = "https://cctv.skyangel.com.mx";
export const cctvToken = "";
//export const cctv= "http://localhost";
