import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table, Progress } from "tabler-react";
import { Line, Circle } from 'rc-progress';
import 'rc-progress/assets/index.css';
import axios from 'axios';

const colorMap = ['#5eba00', '#7bd235', '#f1c40f', '#fd9644', '#cd201f'];
function getColor(index) {
    return colorMap[(index + colorMap.length) % colorMap.length];
}

const customStyles = {
        "backgroundColor": "#5eba00",
};

class UsuarioCorredoresForm extends Component {
    constructor() {
        super();
        this.state = {
            percent: 100,
            colorIndex: 0,
            salasMonitoreo:[],
        };

      }

      componentWillMount(){

        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get("http://34.228.130.148:8080/skyone/salasMonitoreo").then(res => {
            const salasMonitoreo = res.data
            this.setState({salasMonitoreo})
        });

    }

    render() {
        const circleContainerStyle = { width: '200px', height: '200px',};
        const { percent, colorIndex } = this.state;
        console.log(percent);

        const color = getColor(colorIndex);
        return (

            <Page.Card>
                <Form.Group label="Selecciona la Sala">
                    <Form.Select onChange={this.handleChange}>
                    <option value={0}>Seleccione una Opciòn</option>
                        {this.state.salasMonitoreo.map((sala, index) =>
                            <option value={sala.id}>{sala.nombre}</option>
                        )}

                    </Form.Select>
                </Form.Group>
                <h3>Monitoreo en tiempo real (Arribos).</h3>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>#</Table.ColHeader>
                        <Table.ColHeader>Agente de custodia virtual</Table.ColHeader>
                        <Table.ColHeader>0 - 30 Min</Table.ColHeader>
                        <Table.ColHeader>30 Min - 45 Min</Table.ColHeader>
                        <Table.ColHeader>45 min - 1 Hora</Table.ColHeader>
                        <Table.ColHeader>1 - 2 Hrs</Table.ColHeader>
                        <Table.ColHeader>Màs 2 Hrs</Table.ColHeader>
                        <Table.ColHeader>Totales</Table.ColHeader>
                        <Table.ColHeader></Table.ColHeader>
                    </Table.Header>
                    <Table.Body>

                        <Table.Row align="center">
                            <Table.Col>
                                2612
                            </Table.Col>
                            <Table.Col>
                                Francisco Mendoza
                            </Table.Col>
                            <Table.Col>
                                <Button square color="green" >
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="lime">
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="yellow">
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="orange">
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="red">
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="github">
                                    14
                                </Button>
                            </Table.Col>
                            <Table.Col colSpan="10">
                            <div style={circleContainerStyle}>
                                    <Circle
                                        percent={[percent / 5, percent / 5, percent / 5, percent / 5, percent / 5]}
                                        gapDegree={70}
                                        gapPosition="bottom"
                                        strokeWidth="6"
                                        trailWidth="6"
                                        strokeLinecap="round"
                                        strokeColor={[color, getColor(colorIndex + 1), getColor(colorIndex + 2), getColor(colorIndex + 3), getColor(colorIndex + 4)]}
                                    />
                                </div>
                            </Table.Col>

                        </Table.Row>
                        <Table.Row align="center">
                            <Table.Col>6398</Table.Col>
                            <Table.Col>Yisus Matiu</Table.Col>
                            <Table.Col>
                                <Button square color="green" >28</Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="lime">28</Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="yellow">28</Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="orange">28</Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="red">28</Button>
                            </Table.Col>
                            <Table.Col>
                                <Button square color="github">28</Button>
                            </Table.Col>
                            <Table.Col colSpan="10">
                            <div style={circleContainerStyle}>
                                    <Circle
                                        percent={[percent / 5, percent / 5, percent / 5, percent / 5, percent / 5]}
                                        gapDegree={70}
                                        gapPosition="bottom"
                                        strokeWidth="6"
                                        trailWidth="6"
                                        strokeLinecap="round"
                                        label="we"
                                        strokeColor={[color, getColor(colorIndex + 1), getColor(colorIndex + 2), getColor(colorIndex + 3), getColor(colorIndex + 4)]}
                                    />
                                </div>
                            </Table.Col>

                        </Table.Row>

                    </Table.Body>
                </Table>
            </Page.Card>
        );
    }
}

export default UsuarioCorredoresForm;
