//React
import React from "react";
// @flow
import { Error401Page } from "tabler-react";

function Error401(props) {
  return <Error401Page />;
}

export default Error401;
