import LogOut from "../Auth/LogOut";
export function JWT(){
}

export function tokenExpTime (token) {
  if(token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
    let tok= JSON.parse(jsonPayload);
    return tok.exp*1000
  }
  return null;

  //console.log("user   : "+ tok.sub);
  //console.log("creado : "+ new Date(tok.iat*1000));
   //console.log("finnal : "+ new Date(tok.exp*1000));
};
export function validarToken (token) {
  if(token){
    if(window.location.pathname === "/"){
      window.history.back();
    }
    const exp= tokenExpTime(token);
    if(!exp || new Date().getTime() > new Date(exp).getTime() ){
      window.location.href = "/logout";
      //LogOut(); //Salir
    }
    
  }else{
    window.location.href = "/logout";
    //LogOut(); //Salir
  }
};
export function tokenGetUserName (token) {
  if(token){
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
  let tok= JSON.parse(jsonPayload);
  if(new Date() > new Date(tok.exp*1000) ){
    window.location.href = "/logout";
    //LogOut(); //Salir
  }
  return tok.sub;
  }else{
    window.location.href = "/logout";
    //LogOut(); //Salir
  }
};
export function getPrivilegio (token, privilegio) {
if(token && privilegio){
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
  let tok= JSON.parse(jsonPayload);
  if(new Date() > new Date(tok.exp*1000) ){
    window.location.href = "/logout";
    //LogOut(); //Salir
  }
  let privilegios= tok.scopes.split(",");
  return privilegios.filter((p) => p === privilegio).length === 1 ? true: false;
}
};
export default JWT;
