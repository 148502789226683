import React, { Component } from "react";
import { Grid, Table, Form, Dimmer } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { AlertasNAll } from '../../../notificaciones/AlertasNAll'
import { AlertasTAll } from '../../../notificaciones/AlertasTAll'
import { from } from "rxjs";
class BitacoraMov extends Component {
  constructor(props){
    super(props);
    this.state = {
      hiddenModals  : true,
      searchAlertas : "",
      usuariosLog   : [],
      filas         :[],
      loading       : true
    };
    this.filas= [];
  }
  componentWillMount=() =>{
    if (this.props.Tipo){
      if (this.props.Tipo==="Terrestre"){
        this.getfuntion("get", [], "", "usuariosLog/idmovimiento/"+this.props.Movimiento.id+"/plataformatipo/"+2, "usuariosLog");
        this.setState({ loading: true });
      }
      if (this.props.Tipo==="Naviera"){
        this.getfuntion("get", [], "", "usuariosLog/idmovimiento/"+this.props.Movimiento.id+"/plataformatipo/"+1, "usuariosLog");
        this.setState({ loading: true });
      }

    }
  }
  componentDidMount =() => {
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "usuariosLog" && returnVal) {
              this.setState({ usuariosLog: returnVal });
              this.setState({ loading: false });
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { this.setState({ loading: false }); console.log(err); });
  }
  render() {
    return (
      <Grid.Row>
        <Grid.Col>
          <Dimmer active={this.state.loading} loader>
            {this.state.usuariosLog.length > 0 ?

              <Table cards={true} striped={true} responsive={true} className="table-vcenter"
                      headerItems={[ { content: "evento" }, { content: "detalle" }, { content: "Nombre de usuario" }, { content: "fecha" }] } >
                <Table.Body>
                  {this.state.usuariosLog.map((log, index)=>
                  <Table.Row>
                    <Table.Col>{log.idBitacoraMonitoreo}</Table.Col>
                    <Table.Col>{log.descripcion}</Table.Col>
                    <Table.Col>{log.idUsuario}</Table.Col>
                    <Table.Col>{log.accionFecha}</Table.Col>
                  </Table.Row>
                  )}
                </Table.Body>
              </Table>
              : "  No hay Datos..!!"
            }
          </Dimmer>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
export default BitacoraMov;
