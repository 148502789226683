// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import TabsLayout from "../../components/TabsLayout.react";
import Perfil from "./Perfil";
import Privilegios from "./Privilegios";
import Funcionalidad from "./Funcionalidad";
import Bloques from "./Bloques";
import Informes from "./Informes";
class PerfilesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      estatus: false,
      Privilegio_arraylist: [],
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      privilegios: [],
      search: "",
      currentTap: 0,
    };
    this.Privilegio_array = [];
    this.perfilPrivilegioId = 0;
    this.perfilObject = [];
    this.enableSave = this.enableSave.bind(this);
    this.perfilesObject = [];

    this.checarPrivilegio = 0; //temporal render
    this.idPerfilPrivilegio = 0; //temporal render
    this.perfilPrivilegioObject = [];
    this.PrivilegiosArray = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ estatus: this.props.elementos.estatus });
      this.setState({ Privilegio_arraylist: this.Privilegio_array });
      this.setState({ privilegios: this.props.elementos.privilegios });
      this.perfilesObject = this.props.elementos;
      this.perfilObject = this.props.elementos;
    } else {
      this.perfilObject = { nombre: "", estatus: 0, privilegios: {} };
    }
  };
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.privilegios !== this.state.privilegios) {
      //alert("es igual")
    } else {
      /*alert(this.state.privilegios) */
    }
  };

  enableSave = () => {
    if (this.state.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  };
  changeEstatus = (event) => {
    event.target.checked
      ? this.setState({ estatus: 1 })
      : this.setState({ estatus: 0 });
  };

  changePrivilegio = (privilegioindex, privId, estatus) => {
    this.perfilPrivilegioObject = {
      idPerfil: this.state.id,
      idPrivilegio: privId,
      nombre: this.props.stor.privilegios_object
        .filter((word) =>
          word.nombre.toUpperCase().includes(this.state.search.toUpperCase())
        )
        .sort()[privilegioindex].nombre,
    };
    if (this.PrivilegiosArray[privilegioindex].estatus === 1) {
      this.perfilObject.privilegios.splice(privilegioindex, 1);
    }
    this.PrivilegiosArray[privilegioindex].estatus === 1
      ? this.props.getfuntion(
          "delete",
          "",
          this.PrivilegiosArray[privilegioindex].id,
          this.perfilObject,
          "perfilesPrivilegios",
          this.props.stor.perfiles_update
        )
      : this.props.getfuntion(
          "post",
          this.perfilPrivilegioObject,
          this.state.id,
          this.perfilObject,
          "perfilesPrivilegios",
          this.props.stor.perfiles_update,
          "privilegios"
        );
  };

  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      estatus: this.state.estatus,
    });
  }
  crud_post() {
    this.props.getfuntion("post", {
      nombre: this.state.nombre,
      estatus: this.state.estatus,
    });
  }

  checkedPrivilegio = (indice) => {
    return this.PrivilegiosArray[indice].estatus;
  };
  checarPrivilegios = (estatus, id) => {
    this.PrivilegiosArray.push({ estatus: estatus, id: id });
  };
  checarPrivilegioTem = (valor) => {
    this.checarPrivilegio = valor;
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  cleanArrayList = () => {
    this.PrivilegiosArray = [];
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.nombre} </h1>
        ) : null}
        <TabsLayout
          tabs={[
            {
              title: "Perfiles",
              icon: "layout",
              panel: (
                <Perfil
                  accion={this.props.accion}
                  elementos={this.props.elementos}
                  getfuntion={this.props.getfuntion}
                  stor={this.props.stor}
                  cerrarModal={this.props.cerrarModal}
                />
              ),
              close: false,
            },
            {
              title: "Privilegios",
              icon: "layout",
              panel: (
                <Privilegios
                  accion={this.props.accion}
                  elementos={this.props.elementos}
                  getfuntion={this.props.getfuntion}
                  stor={this.props.stor}
                />
              ),
              close: false,
            },
            {
              title: "Columnas",
              icon: "layout",
              panel: (
                <Funcionalidad
                  accion={this.props.accion}
                  elementos={this.props.elementos}
                  getfuntion={this.props.getfuntion}
                  stor={this.props.stor}
                />
              ),
              close: false,
            },
            {
              title: "Bloques",
              icon: "layout",
              panel: (
                <Bloques
                  accion={this.props.accion}
                  elementos={this.props.elementos}
                  getfuntion={this.props.getfuntion}
                  stor={this.props.stor}
                />
              ),
              close: false,
            },
            {
              title: "Informes",
              icon: "layout",
              panel: (
                <Informes
                  accion={this.props.accion}
                  elementos={this.props.elementos}
                  getfuntion={this.props.getfuntion}
                  stor={this.props.stor}
                />
              ),
              close: false,
            },
          ]}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}
export default PerfilesForm;
