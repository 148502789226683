import React, { Component }                      from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs                              from "../../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error }            from '../../../herramientas/Crud_Catalogos';
var FilaAct=[];
function validaActivo(evento, FHabilitados, columna) {
  let regresa = 0;
  if (FHabilitados.length>0){
    FHabilitados.map( (elementos, index) => {
      console.log("10 elementos")
      console.log(elementos)
      if (elementos.plataforma.id==columna.id)
        {
          regresa = 1;
        }
      });
  }
  FilaAct[columna.id]=regresa;
  return regresa
}
class PlataformasTransportistas extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id:"",
                 nombre:"",
                estatus:false,
            Plataformas:[],
           FHabilitados:[],
                 search:"",
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
             currentTap:0,
    };
    this.Array = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre:this.props.elementos.nombre});//, paterno:this.props.elementos.paterno, materno:this.props.elementos.materno });
      //this.PlataformasObject = this.props.elementos;
      this.cargaPlataformas("");
      this.cargaPlataformasTransportistas(this.props.elementos.id);
    } else {
      //this.PlataformasObject = { "id": 0, "nombre": "", "estatus": 0, "Plataformas": {} }
    }
  }


  //this.changeEvento(index, this.state.id, elemento)}
  changeEvento =(evento, transportistaId, columna) => {
    let objetoCE = {
                           "id":0,
                "transportista":{"id":transportistaId, "nombre":"", "nombreCorto":"", "contactoNombre":"", "telefono1":"", "telefono2":"", "correo":"", "tipo":"" },
                   "plataforma":{"id":columna.id, "nombre":"", "clave":"", "idGg":"", "colorFondo":"", "colorBoton":"", "plataformaTipo":{ "id":2, "nombre":"Terrestre" } },
                   }
    objetoCE.plataforma.id=columna.id
    if (FilaAct[columna.id]===0){
    //                               principal   temporal default       id     parametros opcional          state              cerrar modal       si error actualizar
    //              (apiRaiz="",         api="",metodo,id, params,                   storeFunction="",  stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
      Crud_Catalogos("plataformasTransportistas/",                                     "", "post", "", objetoCE , "" , "", "", [], "", "", "").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        tempo.push(returnVal);
        this.setState({ FHabilitados:tempo });
        tempo=[];
        FilaAct[columna.id]=1
      });
    }
    else
    {
    //Crud_Catalogos("clientesContactos"+"/borrar", "", "delete", parametrosLlave Compuesta, "" , "" , "FHabilitados")
      Crud_Catalogos("plataformasTransportistas", "", "delete", columna.id+"/"+transportistaId, "" , "" , "", "", [], "", "", "")
        .then((returnVal) => {
          console.log("80 Delete returnVal")
          console.log(returnVal)

          var tempo = this.state.FHabilitados;
          for(let i=0; i< tempo.length; i++){
            if (tempo[i].plataforma.id===columna.id){
              tempo.splice(i, 1);
              this.setState({ FHabilitados:tempo });
            }
          }
          tempo=[];
          FilaAct[columna.id]=0;
        });
    }

  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Plataformas !== this.state.Plataformas) {
      //alert("es igual")
    } else { /*alert(this.state.Plataformas) */ }
  }
  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
  }

  cargaPlataformas(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("plataformas/plataforma/"+this.props.elementos.tipo, "", "get", "",[],"" , "", "", [], "", "", "").then((regresaValores) => {
    this.setState({ Plataformas:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaPlataformasTransportistas(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("plataformasTransportistas", "", "get", "",[],"" , "", "", [], "", "", "/Transportistas/"+filtro).then((regresaValores) => {
    //this.setState({ clientesContactos:regresaValores });
    console.log("120 regresaValores");
    console.log(regresaValores);

    this.setState({ FHabilitados:regresaValores });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }

  delete()    { this.props.getfuntion("delete", "", this.state.id); }
  crud_put()  { this.props.getfuntion("put", {"id":this.state.id, "nombre":this.state.nombre, "estatus":this.state.estatus }); }
  crud_post() { this.props.getfuntion("post",{"nombre":this.state.nombre, "estatus":this.state.estatus }); }

  checkedPlataformas = (indice) => {
    return this.PlataformasArray[indice].estatus
  }
  checarPlataformas = (estatus, id) => {
    this.PlataformasArray.push({ estatus:estatus, id:id })
  }
  checarPersonaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search:event.target.value })
  }
  cleanArrayList = () => {
    this.PlataformasArray = [];
  }
  selectedTab = (index) => {
    this.setState({ currentTap:index })
  }
  render() {

    return (
      <div>

        {/*
          *Plataformas
          */
        }

        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Plataformas" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                            <Table.ColHeader>Asignada</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                          {
                            this.state.Plataformas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                              <Table.Row>
                                <Table.Col>{elemento["id"]}</Table.Col>
                                <Table.Col>{elemento["nombre"]}</Table.Col>
                                <Table.Col>{elemento["descripcion"]}</Table.Col>
                                <Table.Col>
                                  <Form.Switch type="Checkbox" name="toggle" value="option1" className={"disabled"} checked={ validaActivo(index, this.state.FHabilitados, elemento) } onChange={() => this.changeEvento(index, this.state.id, elemento)} />
                                </Table.Col>
                              </Table.Row>
                            )
                          }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}
      </div>
    );
  }
}
export default PlataformasTransportistas;
