import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Form, Table } from "tabler-react";
import Select from "react-select";


import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { validarToken } from "../../herramientas/JWT";


export const DRP = () => {
  
  const [unidadesActivadas, setUnidadesActivadas] = useState("");
  const [unidadesDesactivadas, setUnidadesDesactivadas] = useState("");
  const [respuestaRutaGerenciador, setRespuestaRutaGerenciador] = useState("");
  const [respuestaRutaProxy, setRespuestaRutaProxy] = useState("");

  const activaUnidades = () => {
    let respuesta = "";
    setUnidadesActivadas("Procesando...");
    //Se invoca servicio de activar Unidades DRP
    getfuntion("put", [], "", "wsGgateDRP/activaUnidades", "activaUnidades", "").then((returnVal) => {
      setUnidadesActivadas(returnVal);
    });
  }

  const desactivaUnidades = () => {
    let respuesta = "";
    setUnidadesDesactivadas("Procesando...");
    //Se invoca servicio de activar Unidades DRP
    getfuntion("delete", [], "", "wsGgateDRP/desactivaUnidades", "desactivaUnidades", "").then((returnVal) => {
      setUnidadesDesactivadas(returnVal);
    });
  }

  const cambiaRutaGerenciador = () => {
    let respuesta = "";
    setRespuestaRutaGerenciador("Procesando...");
    //Se invoca servicio de activar Unidades DRP
    getfuntion("post", [], "", "logEnviosGerenciador/cambiaServidor", "cambiaServidor", "").then((returnVal) => {
      let result = returnVal.Result;
      setRespuestaRutaGerenciador(result);
    });
  }

  const cambiaRutaProxy = (servidor = "") => {
    let respuesta = "";
    setRespuestaRutaProxy("Procesando...");
    //Se invoca servicio de activar Unidades DRP
    getfuntion("put", [], "", "wsGgateDRP/cambiaServidorProxy/"+servidor, "cambiaServidorProxy", "").then((returnVal) => {
      let result = returnVal.Result;
      setRespuestaRutaProxy(result);
    });
  }

  

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }

  return (
    <SiteWrapper>
      <div className="container">
        <h1>DRP</h1>

        <Grid.Col sm={12} lg={12}>
          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Activar unidades GPS Gate DRP">
                  <button onClick={activaUnidades} className="btn btn-primary ml-5">Activar</button>
              </Form.Group>
              <span className='h1 bg-red-lightest'>{unidadesActivadas}</span>
              
            </div>
          </Card>
          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Desactivar unidades GPS Gate DRP">
                <button onClick={desactivaUnidades} className="btn btn-primary ml-5">Desactivar</button>
              </Form.Group>
              <span className='h1 bg-red-lightest'>{unidadesDesactivadas}</span>
              
            </div>
          </Card>

          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Cambia la ruta del gerenciador">
                <button onClick={cambiaRutaGerenciador} className="btn btn-primary ml-5">Cambiar</button>
              </Form.Group>
              <span className='h4 bg-red-lightest'>{respuestaRutaGerenciador}</span>
              
            </div>
          </Card>

          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Cambiar la ruta del PROXY">
                <button onClick={()=>cambiaRutaProxy("gg")} className="btn btn-primary ml-5">Apuntar a GPS Gate</button>
                <button onClick={()=>cambiaRutaProxy("drp")} className="btn btn-primary ml-5">Apuntar al DRP</button>
              </Form.Group>
              <span className='h4 bg-red-lightest'>{respuestaRutaProxy}</span>
              
            </div>
          </Card>
          
        </Grid.Col>



      </div>

    </SiteWrapper>
  )
}
export default DRP;