import React, { Component } from "react";
import { Button } from "tabler-react";
import ModalLay from "../../components/ModalLay/ModalLay";

import "./excel.css";
import ActivosForm from "./ActivosForm.js";
function subCadena(str, I) {
  var res = str.slice(0, I);
  return res;
}
function obtenAtributos() {
  var tempo = window.location.href;
  var itemp = tempo.length;
  var i = itemp;
  do {
    let stext = tempo.charAt(i);
    if (stext == "/") {
      return subCadena(tempo, i) + "/manuales/";
      break;
    }
    i = i - 1;
  } while (i > 0);
  return subCadena(tempo, i) + "/manuales/";
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
let nombreActivos = "";
class Terminado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogo: this.props.catalogo | "",
      url: this.props.url | "",
      download: this.props.download | "",
      filtro: this.props.filtro | "",
      //Variables
      accion: this.props.accion,
      data: this.props.data | [],
    };
    this.cita = 0;
  }
  componentDidMount() {}
  componentWillMount() {
    switch (this.props.catalogo) {
      default:
        nombreActivos = this.props.catalogo;
        break;
    }
    this.setState({ url: obtenAtributos() }); //this.setState({ url:  "http://localhost:3000/manuales/" });
    this.setState({ download: "" + nombreActivos + ".pdf" });
  }

  render() {
    return (
      <div>
        <ModalLay
          tamanio={"80%"}
          title="Movimientos Activos"
          name={
            <Button pill size="sm" className="btn-success">
              Act
            </Button>
          }
          formulario={ActivosForm}
          datosForm={{
            accion: "modificar",
            tipos: "todas",
            desde: "Monitoreo.react",
            usuario: user,
          }}
        />
      </div>
    );
  }
}

export default Terminado;
