import React, { useEffect, useState } from "react";
import TabsLayout from "../../components/TabsLayout.react";
import { Unidades, GPS } from "./Formularios";
//this.props.accion, //'nuevo'// modificar,
const UnidadesNavierasForm = ({
  elementos,
  accion,
  getfuntion,
  stor,
  cerrarModal,
}) => {
  const [currentTap, setCurrentTap] = useState(0);
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    let unidades = {
      title: accion !== "nuevo" && elementos ? elementos.nombre : "",
      icon: "layout",
      panel: (
        <Unidades
          accion={accion}
          elementos={elementos}
          getfuntionProps={getfuntion}
          stor={stor}
          cerrarModal={cerrarModal}
        />
      ),
      close: false,
    };
    let gps = {
      title: "GPS",
      icon: "crosshair",
      panel: (
        <GPS
          accion={accion}
          elementos={elementos}
          getfuntionProps={getfuntion}
          stor={stor}
        />
      ),
      close: false,
    };
    if (accion !== "nuevo") {
      setTabs([unidades, gps]);
    } else {
      setTabs([unidades]);
    }
  }, []);

  return (
    <div>
      {tabs.length ? (
        <TabsLayout
          tabs={tabs}
          selectedTab={currentTap}
          selectTab={(e) => setCurrentTap(e)}
        />
      ) : null}
    </div>
  );
};

export default UnidadesNavierasForm;
