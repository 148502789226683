import { array } from "prop-types";
import React, { useState, useEffect } from "react";

import FetchData from "./FetchData.react";
import CheckForm from "./CheckForm.react";

export const Paginator = ({ data, setData, setLoading, setError, opciones }) => {
	const paginationLinks = () => {
		let contador = 0;
		let links = [];
		do {
			if (data.number + contador < data.totalPages - 1) {
				if (contador == 0) {
					links.push(
						<li
							key={data.number + contador + 1}
							className="page-item active"
							onClick={(opcion) => {
								changePage(opcion.target.innerText);
							}}
						>
							<span className="page-link">{data.number + contador + 1}</span>
						</li>
					);
				} else {
					links.push(
						<li
							key={data.number + contador + 1}
							className="page-item"
							onClick={(opcion) => {
								changePage(opcion.target.innerText);
							}}
						>
							<span className="page-link">{data.number + contador + 1}</span>
						</li>
					);
				}
			}
			contador++;
		} while (contador < 4);
		return links;
	};

	const changePage = (value) => {
		let pagina = 0;

		if (value == "Anterior") {
			pagina = data.number;
			if (data.number > 0) {
				pagina--;
			}
		} else if (value == "Siguiente") {
			pagina = data.number;
			if (data.number < data.totalPages - 1) {
				pagina++;
			}
		} else {
			pagina = value - 1;
		}
		if (pagina != data.number) {
			CheckForm(
				{
					pagina,
					opciones,
				},
				{
					setData,
					setLoading,
					setError,
				}
			);
		}
	};

	return (
		<div>
			<br></br>
			<div className="row">
				<div className="col-4 justify-content-center align-self-center">
					<nav aria-label="...">
						<ul className="pagination">
							<li
								className="page-item"
								id="Anterior"
								onClick={(opcion) => {
									changePage(opcion.target.innerText);
								}}
							>
								<span className="page-link">Anterior</span>
							</li>
							{paginationLinks()}
							<li
								className="page-item"
								onClick={(opcion) => {
									changePage(opcion.target.innerText);
								}}
							>
								<span className="page-link">Siguiente</span>
							</li>
						</ul>
					</nav>
				</div>
				<div className="col-4">
					<div className="row text-center">
						<div className="col-3 offset-3">
							<input
								type="number"
								className="form-control"
								onKeyUp={(opcion) => {
									if (
										opcion.target.value >= 1 &&
										opcion.target.value <= data.totalPages &&
										opcion.keyCode == 13
									) {
										changePage(opcion.target.value);
									}
								}}
							></input>
						</div>
						<label className="col-3">/ {data.totalPages} páginas</label>
					</div>
				</div>
				<div className="col-4 text-right">Total: {data.totalElements}</div>
			</div>
			<br></br>
		</div>
	);
};
