import React, { useState, useEffect } from "react"; //359, 361, 362, 363, 364
import { Form } from "tabler-react";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../../herramientas/Crud_Catalogos";
import { TablePageable } from "../../../../Helpers";
import WatsAppGrupos from "./WatsAppGrupos";
import Correos from "./Correos";

const Corredores = ({ accion, elementos, stor }) => {
  //elementos es  plataformasObject
  const [corredoresList, setCorredoresList] = useState([]);
  const [corredores, setCorredores] = useState([]);
  const [corredor, setCorredor] = useState({});
  const [buttonClicked, setButtonClicked] = useState();
  useEffect(() => {
    if (accion !== "nuevo") {
      getfuntion(
        "get",
        "",
        "",
        "plataformasCorredores/" + elementos.id,
        "corredores"
      );
      getfuntion(
        "get",
        "",
        "",
        "corredores/tipoId/" +
          (elementos.plataformaTipo.id === 3 ? 1 : elementos.plataformaTipo.id),
        "ListaCorredores"
      );
    }
  }, []);
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "ListaCorredores") {
              setCorredoresList(returnVal);
            }
            if (stateVar === "corredores") {
              setCorredores(returnVal);
            }
            break;
          case "post":
            if (stateVar === "corredores") {
              getfuntion(
                "get",
                "",
                "",
                "plataformasCorredores/" + elementos.id,
                "corredores"
              );
            }
            break;
          case "delete":
            if (stateVar === "corredores") {
              getfuntion(
                "get",
                "",
                "",
                "plataformasCorredores/" + elementos.id,
                "corredores"
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {accion === "modificar" ? (
        <div>
          {!corredor.id ? (
            <div hidden={corredor.id}>
              <TablePageable
                titulo={<h5> Seleccione los corredores para su Plataforma </h5>}
                lista={corredoresList}
                columnas={[
                  { columna: "id", label: "Id" },
                  { columna: "nombre", label: "Nombre" },
                  { columna: "nombreCorto", label: "Nombre Corto" },
                  { columna: "pais", label: "País" },
                  { columna: "tipoCorredor", label: "Tipo Corredor" },
                  {
                    columna: "estatus",
                    label: "Estatus",
                    valor: (e) =>
                      corredores.filter((c) => c.idCorredor.id === e.id).length
                        ? true
                        : false,
                    form: (e) => (
                      <Form.Switch
                        type="Checkbox"
                        name="toggle"
                        value="option1"
                        checked={
                          corredores.filter((c) => c.idCorredor.id === e.id)
                            .length
                        }
                        onChange={() => {
                          let plataformasCorredores = {
                            idPlataforma: elementos,
                            idCorredor: e,
                            estatus: 1,
                          };

                          let corredorTemp = corredores.filter(
                            (c) => c.idCorredor.id === e.id
                          );

                          corredorTemp.length && corredorTemp[0]
                            ? getfuntion(
                                "delete",
                                "",
                                corredorTemp[0].id,
                                "plataformasCorredores",
                                "corredores"
                              )
                            : getfuntion(
                                "post",
                                plataformasCorredores,
                                "",
                                "plataformasCorredores",
                                "corredores"
                              );
                        }}
                      />
                    ),
                  },
                  {
                    columna: "whats",
                    label: "Whatsapp Grupos",
                    form: (e) => {
                      if (
                        corredores.filter((c) => c.idCorredor.id === e.id)
                          .length
                      ) {
                        return (
                          <i
                            className="fa fa-whatsapp text-success"
                            style={{ "font-size": "24px" }}
                            onClick={() => {
                              setCorredor(e);
                              setButtonClicked("Whatsapp");
                            }}
                          ></i>
                        );
                      }
                    },
                  },
                  {
                    columna: "notificaciones",
                    label: "Notificación correos",
                    form: (e) => {
                      if (
                        corredores.filter((c) => c.idCorredor.id === e.id)
                          .length
                      ) {
                        return (
                          <i
                            className="fa fa-envelope text-primary"
                            style={{ "font-size": "24px" }}
                            onClick={() => {
                              setCorredor(e);
                              setButtonClicked("Correos");
                            }}
                          />
                        );
                      }
                    },
                  },
                ]}
                id="id"
                //selected={(e)=>{ setFormulario(e) }}
                //csvname= {"Corredores de la plataforma "+ elementos.nombre}
                resaltarFuncion={(e) =>
                  corredores.filter((c) => c.idCorredor.id === e.id).length
                    ? true
                    : false
                }
                resaltarColor={"rgba(58, 223, 149, 0.25)"}
                paginacion={true}
                elementosPorPagina={15}
              />
            </div>
          ) : (
            <div>
              <i
                class="fa fa-close"
                style={{ "font-size": "24px" }}
                onClick={() => setCorredor({})}
              ></i>
              {buttonClicked === "Whatsapp" && (
                <WatsAppGrupos corredor={corredor} plataforma={elementos} />
              )}
              {buttonClicked === "Correos" && (
                <Correos corredor={corredor} plataforma={elementos} />
              )}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default Corredores;
