import React from "react";

const Tecla = React.memo(({ character }) => {
  return (
    <button
      className="btn btn-light"
      style={{
        fontSize: 20,
        margin: 10,
        color: "black",
        fontWeight: "bold",
      }}
      disabled
    >
      {character}
    </button>
  );
});

export default Tecla;
