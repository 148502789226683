import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Form, Table } from "tabler-react";
import Select from "react-select";


import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';


export const EstatusGerenciador = () => {
  const [gerenciadorActivo, setGerenciadorActivo] = useState(false);
  const [lineaTransporte, setLineaTransporte] = useState({ value: 0, label: "Seleccione una" });
  const [lineasTransporte, setLineasTransporte] = useState([{ value: 0, label: "Seleccione una" }]);
  const [imeiConsultado, setImeiConsultado] = useState();
  const [imeiAgregado, setImeiAgregado] = useState();
  const [usuariosGerenciador, setUsuariosGerenciador] = useState([]);
  const [datosIMEI, setDatosIMEI] = useState({});
  const [datosIMEIAdd, setDatosIMEIAdd] = useState({});

  const imeiRef = useRef();

  const imeiAdd = useRef();

  useEffect(() => {
    actualizarEstatusGerenciador();
    obtenerLineasTransporte();

  }, [])

  const actualizarEstatusGerenciador = () => {
    // obtiene el estatus actual del gerenciador
    getUltRec("GENERAL").then((estatus) => {
      if (estatus.dateDiff) {
        let dateDiff = estatus.dateDiff;
        if (dateDiff < 6) {
          setGerenciadorActivo(true);
        } else {
          setGerenciadorActivo(false);
        }
      }

    });

  }
  const obtenerLineasTransporte = () => {
    let lineasTemp = [...lineasTransporte];
    //Se obtienen las lineas de transporte registradas en el gerenciador
    getfuntion("get", [], "", "lineaTransporte", "lineaTransp", "").then((lts) => {
      lts.forEach(lt => {
        lineasTemp.push({ value: lt.id, label: lt.razonSocial });
      });
    });

    setLineasTransporte(lineasTemp);
  }
  const changeLineaTransporte = (e) => {
    setLineaTransporte(e);
    let usuariosTemp = [];
    setUsuariosGerenciador([]);
    //Se obtienen los usuarios de la linea de transporte
    getfuntion("get", [], "", "usuariosGerenciador/linea_transporte/" + e.value, "usuariosGerenc", "").then((ugs) => {

      if (ugs) {
        ugs.forEach(ug => {
          let activo = false;
          let fechaUltRec = "";
          if (ug.usuario) {
            //se obtiene el estatus en gerenciador de cada usuario
            getUltRec(ug.usuario).then((estatus) => {

              if (estatus.dateDiff) {
                let dateDiff = estatus.dateDiff;
                if (dateDiff < 5) {
                  activo = true;
                } else {
                  activo = false;
                }
              }

              fechaUltRec = estatus.datos.Fecha ? estatus.datos.Fecha.substring(0, 19) : "Sin reportes";

              usuariosTemp.push({ usuario: ug.usuario, proveedor: ug.idProveedorGps.nombre, usrActivo: activo, fechaUltRec: fechaUltRec });

              setUsuariosGerenciador([...usuariosTemp]);
            });
          }

        });
      }
    });
    actualizarEstatusGerenciador();
  }
  const changeIMEIConsultado = (e) => {
    setImeiConsultado(e.target.value);
    // imeiConsultar = e.target.value;
  }

  const changeIMEIAgregado = (e) => {
    setImeiAgregado(e.target.value);
    // imeiAgregar = e.target.value;
  }

  const consultaIMEI = () => {
    setDatosIMEI({});

    let activo = false;
    let fechaUltRec = "";
    getUltRec(imeiRef.current.value).then((estatus) => {

      if (estatus.dateDiff) {
        let dateDiff = estatus.dateDiff;
        if (dateDiff < 5) {
          activo = true;
        } else {
          activo = false;
        }
      }
      fechaUltRec = estatus.datos.Fecha ? estatus.datos.Fecha.substring(0, 19) : "Sin reportes";

      let { Imei, Latitud, Longitud, Fechahora, Neconomico } = estatus.datos.Request ? estatus.datos.Request : "";

      let fechaReciente = false;
      try {
        let stringFecha = Fechahora ? Fechahora.replace(' 00:00', ' +00:00').replace('-00:00', '+00:00') : "";
        let fechaEvento = new Date(Date.parse(stringFecha));
        let fechaRec = new Date(Date.parse(fechaUltRec));
        let dateDiff = (fechaRec - fechaEvento) / (1000 * 60);
        fechaReciente = dateDiff < 20 ? true : false;
      } catch (e) {
        console.log("Error al calcular la diferencia de fechas" + e);
      }

      setDatosIMEI({ fecha: fechaUltRec, imeiActivo: activo, responseGG: traduceRespuestaGG(estatus.datos.ResponseGG), imei: Imei, lat: Latitud, long: Longitud, fechaEvento: Fechahora, neconomico: Neconomico, consultado: 1, fechaReciente: fechaReciente });
    });
    actualizarEstatusGerenciador();
  }

  const agregaIMEI = () => {
    setDatosIMEIAdd({});
    
    let activo = false;
    let fechaUltRec = "";
    let agregado = "No se pudo agregar el IMEI";
    addImei(imeiAdd.current.value).then((estatus) => {
      
      if (estatus.datos === "Unidad Registrada") {
        
        agregado = "El IMEI se agregó de forma exitosa";
        getUltRec(imeiAdd.current.value).then((estatus) => {

          if (estatus.dateDiff) {
            let dateDiff = estatus.dateDiff;
            if (dateDiff < 5) {
              activo = true;
            } else {
              activo = false;
            }
          }
          fechaUltRec = estatus.datos.Fecha ? estatus.datos.Fecha.substring(0, 19) : "Sin reportes";

          let { Imei, Latitud, Longitud, Fechahora, Neconomico } = estatus.datos.Request ? estatus.datos.Request : "";

          let fechaReciente = false;
          try {
            let stringFecha = Fechahora ? Fechahora.replace(' 00:00', ' +00:00').replace('-00:00', '+00:00') : "";
            let fechaEvento = new Date(Date.parse(stringFecha));
            let fechaRec = new Date(Date.parse(fechaUltRec));
            let dateDiff = (fechaRec - fechaEvento) / (1000 * 60);
            fechaReciente = dateDiff < 20 ? true : false;
          } catch (e) {
            console.log("Error al calcular la diferencia de fechas" + e);
          }

          setDatosIMEIAdd({ agregado: agregado, fecha: fechaUltRec, imeiActivo: activo, responseGG: traduceRespuestaGG(estatus.datos.ResponseGG), imei: Imei, lat: Latitud, long: Longitud, fechaEvento: Fechahora, neconomico: Neconomico, consultado: 1, fechaReciente: fechaReciente });
        });
      }


    });
    actualizarEstatusGerenciador();
  }

  const traduceRespuestaGG = (respuestaGG) => {
    let respuestaTraducida = respuestaGG;

    if (respuestaTraducida) {
      if (respuestaTraducida.includes("no _DeviceLogin privilege")) {
        return "La unidad no cuenta con rol _Unit en GG";
      }
      if (respuestaTraducida.includes("_SendMessage") || respuestaTraducida.includes("Se solicito una actualizacion para la unidad")) {
        return "OK";
      }
      if (respuestaTraducida.includes("Device was not found for identifier")) {
        return "La unidad no está registrada en GG"
      }
    }


    return respuestaTraducida;
  }

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }

  const getUltRec = async (imei) => {
    return getfuntion("get", [], "", "logEnviosGerenciador/obtieneUltRecAPI/" + imei, "", "").then(dato => {
      let respuesta = dato;
      let stringFecha = "";
      let dateDiff = 9999;
      if (respuesta) {

        stringFecha = respuesta.Fecha ? respuesta.Fecha.substring(0, 19) : "";
        let fechaObtenida = new Date(Date.parse(stringFecha));
        let hoy = new Date();
        dateDiff = (hoy - fechaObtenida) / (1000 * 60);
      }
      return { datos: respuesta, dateDiff: dateDiff }
    })
  }

  const addImei = async (imei) => {
    
    return getfuntion("put", [], "", "logEnviosGerenciador/cargaUnidadAPI/" + imei, "", "").then(dato => {
      
      let respuesta = dato;
      return { datos: respuesta }
    })
  }

  return (
    <SiteWrapper>
      <div className="container">
        <h1>Estatus del Gerenciador: <span className={`${gerenciadorActivo ? 'text-success' : 'text-danger'}`}><i className="fa fa-dot-circle-o"></i>{gerenciadorActivo ? "Activo" : "Inactivo"}</span></h1>

        <Grid.Col sm={12} lg={12}>
          <Card>

            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Estatus Linea de transporte">     <Select value={lineaTransporte} onChange={changeLineaTransporte} options={lineasTransporte} />   </Form.Group>
              {
                usuariosGerenciador.length > 0 ?
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter">
                    <Table.Header align="center">

                      <Table.ColHeader>Proveedor</Table.ColHeader>
                      <Table.ColHeader>Fecha Ultimo Reporte</Table.ColHeader>
                      <Table.ColHeader>Estatus</Table.ColHeader>

                    </Table.Header>
                    <Table.Body>
                      {usuariosGerenciador.map((usr, index) =>
                        <Table.Row align="center" className={`${usr.usrActivo ? 'bg-green-lightest' : 'bg-red-lightest'}`}>

                          <Table.Col> {usr.proveedor}    </Table.Col>
                          <Table.Col> {usr.fechaUltRec}      </Table.Col>
                          <Table.Col> {usr.usrActivo ? <span>Activo</span> : <span>Inactivo</span>}        </Table.Col>

                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                  :
                  lineaTransporte.value === 0 ? <span></span> : <span className='h1 bg-red-lightest'>Sin Proveedores vinculados con la Linea Transportista</span>

              }

            </div>
          </Card>
          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Consultar última posición por IMEI">
                <input ref={imeiRef} type='text' value={imeiConsultado} onchange={changeIMEIConsultado} placeholder="IMEI como lo reporta la LT" />
                <button onClick={consultaIMEI} className="btn btn-primary ml-5">Consultar</button>
              </Form.Group>

              {

                datosIMEI.imei ?
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter">
                    <Table.Header align="center">
                      <Table.ColHeader>IMEI</Table.ColHeader>
                      <Table.ColHeader>Economico</Table.ColHeader>
                      <Table.ColHeader>latitud, longitud</Table.ColHeader>
                      <Table.ColHeader>Fecha Evento</Table.ColHeader>
                      <Table.ColHeader>Respuesta de GG</Table.ColHeader>
                      <Table.ColHeader>Fecha Ultimo Reporte</Table.ColHeader>
                      <Table.ColHeader>Estatus</Table.ColHeader>

                    </Table.Header>
                    <Table.Body>

                      <Table.Row align="center" className={`${datosIMEI.imeiActivo ? 'bg-green-lightest' : 'bg-red-lightest'}`}>

                        <Table.Col> {datosIMEI.imei}    </Table.Col>
                        <Table.Col> {datosIMEI.neconomico}    </Table.Col>
                        <Table.Col> {datosIMEI.lat}, {datosIMEI.long}    </Table.Col>
                        <Table.Col> <span className={`${datosIMEI.fechaReciente ? '' : 'bg-red-lighter'}`}>{datosIMEI.fechaEvento}</span>    </Table.Col>
                        <Table.Col> <span className={`${datosIMEI.responseGG != "OK" ? 'bg-red-lighter' : ''}`}>{datosIMEI.responseGG}</span>      </Table.Col>
                        <Table.Col> {datosIMEI.fecha}      </Table.Col>
                        <Table.Col> {datosIMEI.imeiActivo ? <span>Activo</span> : <span>Inactivo</span>}        </Table.Col>

                      </Table.Row>

                    </Table.Body>
                  </Table>
                  :
                  <span>{datosIMEI.consultado === 1 ? <span className='h1 bg-red-lightest'>No se han recibido posiciones para el IMEI seleccionado</span> : ""}</span>
              }
            </div>
          </Card>
          <Card>
            <div className="col col-12 col-sm-12 ">
              <Form.Group label="Agregar unidad al gerenciador">
                <input ref={imeiAdd} type='text' value={imeiAgregado} onchange={changeIMEIAgregado} placeholder="IMEI como lo reporta la LT" />
                <button onClick={agregaIMEI} className="btn btn-primary ml-5">Agregar</button>
              </Form.Group>
              <div><span className='h1'>
                {
                  datosIMEIAdd.consultado === 1 ? datosIMEIAdd.agregado : ""
                }
              </span></div>
              {

                datosIMEIAdd.imei ?
                  <div><Table cards={true} striped={true} responsive={true} className="table-vcenter">
                    <Table.Header align="center">
                      <Table.ColHeader>IMEI</Table.ColHeader>
                      <Table.ColHeader>Economico</Table.ColHeader>
                      <Table.ColHeader>latitud, longitud</Table.ColHeader>
                      <Table.ColHeader>Fecha Evento</Table.ColHeader>
                      <Table.ColHeader>Respuesta de GG</Table.ColHeader>
                      <Table.ColHeader>Fecha Ultimo Reporte</Table.ColHeader>
                      <Table.ColHeader>Estatus</Table.ColHeader>

                    </Table.Header>
                    <Table.Body>

                      <Table.Row align="center" className={`${datosIMEIAdd.imeiActivo ? 'bg-green-lightest' : 'bg-red-lightest'}`}>

                        <Table.Col> {datosIMEIAdd.imei}    </Table.Col>
                        <Table.Col> {datosIMEIAdd.neconomico}    </Table.Col>
                        <Table.Col> {datosIMEIAdd.lat}, {datosIMEIAdd.long}    </Table.Col>
                        <Table.Col> <span className={`${datosIMEIAdd.fechaReciente ? '' : 'bg-red-lighter'}`}>{datosIMEIAdd.fechaEvento}</span>    </Table.Col>
                        <Table.Col> <span className={`${datosIMEIAdd.responseGG != "OK" ? 'bg-red-lighter' : ''}`}>{datosIMEIAdd.responseGG}</span>      </Table.Col>
                        <Table.Col> {datosIMEIAdd.fecha}      </Table.Col>
                        <Table.Col> {datosIMEIAdd.imeiActivo ? <span>Activo</span> : <span>Inactivo</span>}        </Table.Col>

                      </Table.Row>

                    </Table.Body>
                  </Table></div>
                  :
                  <div><span>{datosIMEIAdd.consultado === 1 ? <span className='h1 bg-red-lightest'>No se han recibido posiciones para el IMEI agregado</span> : ""}</span></div>
              }
            </div>
          </Card>

        </Grid.Col>



      </div>

    </SiteWrapper>
  )
}
export default EstatusGerenciador;