import React, { useState, useEffect, useCallback,useRef } from 'react'
import { Button,Icon,Grid, Card, Form, Table } from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { Doughnut, Pie } from 'react-chartjs-2'
import {Marker,Rectangle,Circle,LayersControl,LayerGroup,FeatureGroup,Popup,MapContainer,TileLayer,ScaleControl,useMapEvents,MapConsumer} from "react-leaflet";
import {HeatmapLayer} from 'react-leaflet-heatmap-layer-v3'
import icon from "./constants";
import L from "leaflet"; 
import basemapsSwitcher from "leaflet-switch-basemap";
import MarkerClusterGroup from 'react-leaflet-cluster';
import Control from 'react-leaflet-custom-control'
import points from "./points";
import reacciones from "./reacciones";
import { Modal } from 'bootstrap';
import Select from "react-select";

//import Modal from 'react-modal';
//import minichart from 'leaflet.minichart';
//Map,Marker,Popup,
export const MapaDeRiesgo = () => {
  const center = [51.505, -0.09]
  const rectangle = [
    [51.49, -0.08],
    [51.5, -0.06],
  ]
 

  var miMapa ;
  const [trackPoints, setTrackPoints] = useState([]);
  const [c, setC] = useState([]);
  const [url, setUrl] = useState([]);
  const [status, setStatus] = useState(true);
  const [mapa,setMapa]=useState(null);

  const [fechaInicio,setFechaInicio]=useState('20200101');
  const [fechaFin,setFechaFin]=useState('20240101');


  useEffect(() => {
    changeStatus();
  }, [])
  //const datos;
  const [datos, setDatos] = useState([]);
  useEffect(() => {
    tablero();
  }, [])

  const changeStatus = () => {
    //alert("prueba");
    window.scrollTo(10000, 10000);
    //flyTo([13.87992, 45.9791], 12);
    //this.mapRef.current.leafletElement.flyTo([40.73061, -73.935242], 15);
    status?setStatus(false):setStatus(true);

  }


//   const changeFechaInicio = (e) => {
//  setFechaInicio(e.target.value)
//   }
const entryDate = useRef()


const parseDate = useCallback((value) => {
  const month = (value.getMonth() + 1).toString().padStart(2, '0');
  const day = value.getDate().toString().padStart(2, '0');
  return value.getFullYear() +  month +  day 
}, [])


  const changeFechaInicio = useCallback((value) => {
    const parsedDate = parseDate(value)
    //entryDate.current = parsedDate;
    console.log(parsedDate);
    setFechaInicio(parsedDate);
  }, [])



  const changeFechaFin = useCallback((value) => {
    const parsedDate = parseDate(value)
    //entryDate.current = parsedDate;
    console.log(parsedDate);
    setFechaFin(parsedDate);
  }, [])


  const tablero = () => {
    // getfuntion("get", [], "", "reaccion", "").then((returnVal) => {      
    //    returnVal.map((linea) =>  {         
    //       setTrackPoints(trackPoints=>[...trackPoints,[linea.latitud,linea.longitud]]);
    //    }
    //     )
    //    });
  }


  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      //setTrackPoints({ returnVal });
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
            
          }
      }
    })
  }

 

 

  return (


    
   <div>



{/* <div>
    test1<Form.Switch
                    type="Checkbox"
                    name="toggle"
                     value={status}
                    checked={status}
                    onChange={changeStatus}
                  />test2</div> */}
    <SiteWrapper>
      <div>

      
               {  
               //trackPoints.length>770?//&&trackPoints.length<720?
               //status?
 
               <MapContainer
                                   center={[22, -100]}
                                   zoom={6}
                                   minZoom={4}
                                   style={{  height: "100vh", width:"100vw" }} 
                                   whenReady={(map) => {

                                   var center = [23, -105];
                                     function fakeData() {
                                         return [Math.random(), Math.random(), Math.random()];
                                   }

                                    // Create a barchart
                                    //var myBarChart = L.minichart(center, {data: fakeData(),type: 'pie'});
                                    //var myBarChart = L.minichart(center, {data: fakeData()});
                                    //map.addLayer(myBarChart);
                                    //myBarChart.addTo(map);
                                    // Update data every 2 seconds
                                    //  setInterval(function() {
                                    //   myBarChart.setOptions({data: fakeData()})
                                    // }, 20000);

                                  new L.basemapsSwitcher([
                                    // {
                                    //   layer:
                                    // L.tileLayer('https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png').addTo(map.target),  
                                    //           icon: {icon},
                                    // name: 'Contour'                 
                                    // },
                                    {
                                      layer:
                                    L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map.target),  
                                             // icon: './img1.PNG',
                                    name: 'OSM'                 
                                    },
                                    // {
                                    // layer:
                                    // L.tileLayer('https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png').addTo(map.target),  
                                    //          // icon: './img1.PNG',
                                    // name: 'Iron Maiden'                                                
                                    // },
                                    {
                                      layer:
                                      L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png').addTo(map.target),  
                                               // icon: 'https://cdn-icons-png.flaticon.com/512/44/44386.png',
                                      name: 'openToMap'   
                                      },
                                  
                                      {
                                        layer:
                                        L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                                //  icon: './img1.PNG',
                                        name: 'NatGeo'                                                
                                        },
                                        // {
                                        //   layer:
                                        //   L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                        //           //  icon: './img1.PNG',
                                        //   name: 'DEM'                                                
                                        //   },
                                          {
                                            layer:
                                            L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                                   //   icon: './img1.PNG',
                                            name: 'DeLorme'   
                                            },

                                            {
                                              layer:
                                              L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                                      //  icon: './img1.PNG',
                                              name: 'ArcGIS'                                                
                                              },
                                            // {
                                            //   layer:
                                            //   L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                            //         //    icon: './img1.PNG',
                                            //   name: 'Satelital'   
                                            //   },
                                              // {
                                              //   layer:
                                              //   L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                              //       //      icon: './img1.PNG',
                                              //   name: 'Oceano'   
                                              //   },
                                                // {
                                                //   layer:
                                                //   L.tileLayer('http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}').addTo(map.target),  
                                                //       //      icon: './img1.PNG',
                                                //   name: 'Gris'   
                                                //   },
                                                //   {
                                                //     layer:
                                                //     L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png').addTo(map.target),  
                                                //        //       icon: './img1.PNG',
                                                //     name: 'Stadia'   
                                                //     },
                                                  
                                              ], { position: 'topleft' }).addTo(map.target);
                                   }} 
                                    >                         
                                <Control prepend position='topright'>
                                <Form.Label  position='topright'
> 				  


                                <Form.DatePicker name="entryDateInicio" onChange={changeFechaInicio}          placeholderText='Select date'
                                />
                                <Form.DatePicker name="entryDateFin" onChange={changeFechaFin}          
                                placeholderText='Select date'
                                maxDate={'2020-01-01'}
                                />

                                  {/* <input type="text" onChange={changeFechaInicio}  /> */}

                                  <h2>Top 10 de Reacciones por Estado</h2>
                                  <p style={{   textAlign:"right"}}>1 México: 887</p>
                                   <p style={{   textAlign:"right"}}>2 Guadalajara: 432</p>
                                   <p style={{   textAlign:"right"}}>3 Michoacan: 370</p>
                                   <p style={{   textAlign:"right"}}>4 Puebla: 314</p>
                                   <p style={{   textAlign:"right"}}>5 Guanajuato: 293</p>
                                   <p style={{   textAlign:"right"}}>6 SLP: 267</p>
                                   <p style={{   textAlign:"right"}}>7 Veracruz: 246</p>
                                   <p style={{   textAlign:"right"}}>8 NL: 204</p>
                                   <p style={{   textAlign:"right"}}>9 Queretaro: 154</p>
                                   <p style={{   textAlign:"right"}}>10 Coahuila: 136</p>
                                   <p style={{  color:"grey", textAlign:"right"}}>11 Hidalgo: 131</p>
                                   <p style={{  color:"grey", textAlign:"right"}}>12 CDMX: 112</p>
                                   <p style={{  color:"grey", textAlign:"right"}}>13 Colima: 100</p>                                 
                                    {/*<p style={{  color:"grey", textAlign:"right"}}>14 Tlaxcala: 95</p>
                                   <p style={{  color:"grey", textAlign:"right"}}>15 Zacatecas: 85</p>
                                   <p style={{  color:"grey", textAlign:"right"}}>16 Tamaulipas: 56</p> */}
                                         
                                 </Form.Label>

                                 {/* <Form.Input name="preuba"  type="text"  placeholder="Escribe Algo" onChange={changeStatus}/>    
                                   <Button onClick={changeStatus} color='primary' title="Prueba" >                                      
                                     <Icon name="plus" />
                                   </Button > */}
                                  </Control>  
                                  <LayersControl position="topright">
                                     {/* <LayersControl.Overlay  name="bolitas">
                                     <LayerGroup>
                                     {trackPoints.map((reaccion) =>   
                                     <Circle
                                      center={reaccion}
                                      pathOptions={{ color: 'green', fillColor: 'green' }}
                                      radius={10000}
                                    />
                                    )}
                                     </LayerGroup>
                                     </LayersControl.Overlay> */}

                                     <LayersControl.Overlay checked name="Conteo de Reacciones">                                     
                                     <LayerGroup>    

                                

                                     <MarkerClusterGroup chunkedLoading>
                                             {reacciones.filter( address=>{return address[3] > fechaInicio && address[3] < fechaFin}).map((address, index) => (
                                               <Marker
                                                 //key={address[3]}
                                                 position={[address[0], address[1]]}
                                                 icon={icon}
                                               ></Marker>
                                             ))}
                                      </MarkerClusterGroup>
                                     </LayerGroup>
                                     </LayersControl.Overlay>

                                     <LayersControl.Overlay  name="HeatMap de Reacciones">                                     
                                     <LayerGroup>
                                     <HeatmapLayer                                                      
                                         points={reacciones}
                                         longitudeExtractor={(m) => m[1]}
                                         latitudeExtractor={(m) => m[0]}
                                         intensityExtractor={(m) => parseFloat(m[3])}
                                         radius={15}
                                         max={200}
                                         minOpacity={.30}
                                         maxOpacity={20}
                                         useLocalExtrema={true}                                         
                                     />
                                     </LayerGroup> 
                                     </LayersControl.Overlay>

                                     <LayersControl.Overlay  name="Cobertura Terrestre">                                     
                                     <LayerGroup>
                                     <HeatmapLayer                                                      
                                         points={points}
                                         longitudeExtractor={(m) => m[1]}
                                         latitudeExtractor={(m) => m[0]}
                                         intensityExtractor={(m) => parseFloat(m[2])}
                                         radius={15}
                                         max={10}
                                         minOpacity={.3}
                                         maxOpacity={100}
                                         useLocalExtrema={true}
                                     />
                                     </LayerGroup>     
                                    
                                     <LayersControl.Overlay  name="Cobertura Naviera">                                     
                                     <LayerGroup>
                                     <HeatmapLayer                                                      
                                         points={points}
                                         longitudeExtractor={(m) => m[1]}
                                         latitudeExtractor={(m) => m[0]}
                                         intensityExtractor={(m) => parseFloat(m[4])}
                                         radius={15}
                                         max={10}
                                         minOpacity={.3}
                                         maxOpacity={100}
                                         useLocalExtrema={true}
                                     />
                                     </LayerGroup>     
                                     </LayersControl.Overlay>

                                     <LayersControl.Overlay  name="Flujo terrestre">                                     
                                     <LayerGroup>
                                     <HeatmapLayer                                                      
                                         points={points}
                                         longitudeExtractor={(m) => m[1]}
                                         latitudeExtractor={(m) => m[0]}
                                         intensityExtractor={(m) => parseFloat(m[3])}
                                         radius={15}
                                         max={200}
                                         minOpacity={.30}
                                         maxOpacity={20}
                                         useLocalExtrema={true}
                                         
                                     />
                                     </LayerGroup> 
                                     </LayersControl.Overlay>




                                     {/* <LayersControl.Overlay  name="Marcas">                                     
                                     <LayerGroup>
                                     {trackPoints.map((reaccion) =>   
                                     <Marker
                                      position={reaccion}
                                      icon={icon}
                                      pathOptions={{ color: 'green', fillColor: 'green' }}
                                      
                                    />
                                    )}
                                     </LayerGroup>
                                     </LayersControl.Overlay> */}
                            </LayersControl.Overlay>
                                  </LayersControl>
                                    
               </MapContainer>   
               
                  
             
                         // :null                           
}       
      </div>
    </SiteWrapper>
   </div>
  )
}
export default MapaDeRiesgo;