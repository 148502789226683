import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

const GPSHardwareForm =({ accion, elementos, stor, cerrarModal, getfuntion })=> {//'nuevo'// modificar, nuev
  const [formulario, setFormulario]= useState(
    {
        "nombre": "",
        "tecnologia": "",
        "fijo": false,
        "desechable": false
    });
  const [crudbutonEnable, setCrudbutonEnable]= useState(true);

  useEffect(() => {
    if(accion !== 'nuevo')
      setFormulario({...elementos});
  },[]);

  const isEnable = (Accion) => {
    let borrar = "gps_hardware_borrar";
    let actualizar = "gps_hardware_editar";
    let agregar = "gps_hardware_agregar";
    var privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  

  const enableSave = () => {
    if ( formulario.nombre && formulario.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const guardar = () => {
    if (accion === "modificar")
      crud_put();
    else crud_post();
      cerrarModal();
  };
  const borrar =()=> {
    setCrudbutonEnable(false);
    getfuntion("delete", "", formulario.id);
  }
  const crud_put =()=> {
    setCrudbutonEnable(false);
    getfuntion("put", formulario);
  }
  const crud_post =()=> {
    setCrudbutonEnable(false);
    getfuntion("post", formulario);
  }

  return (
    <div>
      { accion === "modificar"
        ? ( <h1> { elementos.id}{ " " }{ elementos.nombre } </h1> )
        : null}
      <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <Grid.Row>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Nombre">
                <Form.Input
                  name="nombre"
                  type="text"
                  value={formulario.nombre}
                  onChange={(e) =>
                    setFormulario({ ...formulario, nombre: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Tecnologia">
                <Form.Input
                  name="tecnologia"
                  type="text"
                  value={formulario.tecnologia}
                  onChange={(e) =>
                    setFormulario({ ...formulario, tecnologia: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Fijo">
                <Form.Switch type="Checkbox" name="toggle" value="fijo"
                  checked={ formulario.fijo }
                  onChange={ (e) =>{
                    setFormulario({ ...formulario, fijo: e.target.checked })
                  }}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={9} lg={9}>
              <Form.Group label="Desechable">
                <Form.Switch type="Checkbox" name="toggle" value="desechable"
                  checked={ formulario.desechable }
                  onChange={ (e) =>{
                    setFormulario({ ...formulario, desechable: e.target.checked })
                  }}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <div className="float-right">
        {isEnable("actualizar") || isEnable("agregar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={enableSave() || !crudbutonEnable}
              onClick={guardar}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        { accion === "modificar" && isEnable("borrar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => borrar()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
}
export default GPSHardwareForm;
