import React, { Component, useEffect, useState } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import {  Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
import Select from "react-select";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
const NotificacionesAutomaticas =({movimiento})=> {
  const [configuracion, setConfiguracion]= useState({
    idPlataforma: movimiento.idPlataforma.id,
    idMovimiento: movimiento.id,
    idAcumulador: 3,
    idCampoLogistico: null,
    cron: "00:00:00",
    fecha_programada: null
  });
  const [actualizar, setActualizar]= useState(false);
  const [cargarCampos, setCargarCampos]= useState(false);
  const [camposLogisticos, setCamposLogisticos]= useState([]);
  const [idCampoLogistico, setIdCampoLogistico]= useState({ value: 0, label: " Seleccione un campoLogistico" });

  useEffect(()=>{
    getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");    
  },[]);
  useEffect(()=>{
    if( cargarCampos ){
      getfuntion(
        "get",
        "",
        "",
        "movimiento_notificaciones_automaticas/plataforma/"+movimiento.idPlataforma.id+"/id_movimiento/"+movimiento.id,
        "configuracion"
      );
    }   
  },[cargarCampos]);

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas",
    stateVar = "personas",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "configuracion") {
              if(returnVal.idMovimiento){
                setActualizar(true);
                setConfiguracion(returnVal);
                setIdCampoLogistico(camposLogisticos.filter(cl => cl.value === returnVal.idCampoLogistico)[0]);
              }else{
                setActualizar(false);
                setConfiguracion({
                  idPlataforma: movimiento.idPlataforma.id,
                  idMovimiento: movimiento.id,
                  idAcumulador: 3,
                  idCampoLogistico: null,
                  cron: "00:00:00",
                  fecha_programada: null
                });
                setIdCampoLogistico({ value: 0, label: " Seleccione un campoLogistico" });
              }
            }
            if (stateVar === "camposLogisticos" && returnVal) {
              const camposMapeados =
                "arribo_cliente_lleno, arribo_cliente_vacio, fecha_arribo, salida_cliente_vacio, salida_cliente_lleno, fecha_salida, arribo_patio_linea, arribo_terminal_vacio_2, arribo_terminal_cargado";
              let camposLogisticosTemp= [...returnVal];
              camposLogisticosTemp= camposLogisticosTemp.filter(cl=> camposMapeados.includes(cl.nombreCampo));
              let calogs = [{ value: 0, label: "Seleccione un campoLogistico", data: {} },];
              if ([1,3].includes(movimiento.idMovimientoTipo.id)) {
                camposLogisticosTemp= camposLogisticosTemp.filter(cl=> cl.idMovimientoTipo=== 1);
              }              
              if ([2,4].includes(movimiento.idMovimientoTipo.id)) {
                camposLogisticosTemp= camposLogisticosTemp.filter(cl=> cl.idMovimientoTipo=== 2);
              }
              for (let item of camposLogisticosTemp) {
                calogs.push({ value: item.id, label: item.nombre,data: item, });
              }
              setCamposLogisticos(calogs);
              setCargarCampos(true);
            }
            break;
          case "post":
            if (stateVar === "configuracion") {
              NotificationManager.success("Guardado correctamente", "Guardado");
              setConfiguracion(returnVal);
              getfuntion(
                "get",
                "",
                "",
                "movimiento_notificaciones_automaticas/plataforma/"+movimiento.idPlataforma.id+"/id_movimiento/"+movimiento.id,
                "configuracion"
              );
            }
            break;
          case "put":
            if (stateVar === "configuracion") {
              NotificationManager.success("Actualizado correctamente", "Guardado");
              setConfiguracion(returnVal);
              getfuntion(
                "get",
                "",
                "",
                "movimiento_notificaciones_automaticas/plataforma/"+movimiento.idPlataforma.id+"/id_movimiento/"+movimiento.id,
                "configuracion"
              );
            }
            break;
          case "delete":
            {
              if (stateVar === "configuracion") {
                if (returnVal) {
                  NotificationManager.success("Borrado correctamente", "Guardado");
                } else {
                  NotificationManager.error(
                    "No se ha podido borrar ..",
                    "Error"
                  );
                }
                getfuntion(
                  "get",
                  "",
                  "",
                  "movimiento_notificaciones_automaticas/plataforma/"+movimiento.idPlataforma.id+"/id_movimiento/"+movimiento.id,
                  "configuracion"
                );
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const borrar =()=> {
    getfuntion(
      "delete",
      "",
      "plataforma/"+movimiento.idPlataforma.id+"/id_movimiento/"+movimiento.id,
      "movimiento_notificaciones_automaticas",
      "configuracion"
    );
  }
  const guardar =()=> {
    getfuntion(
      (actualizar? "put": "post"),
      configuracion,
      "",
      "movimiento_notificaciones_automaticas",
      "configuracion"
    );
  }
  return (
    <div>
      <h1> Configuración para envio de notificaciones(Aplica unicamente para el movimiento seleccionado: {movimiento.id})</h1>
      <Grid.Row>
        <Grid.Col sm={5} md={5}>
          <Form.Group label="Campo logistico">
            <Select
              value={idCampoLogistico}
              onChange={(e)=>{
                setIdCampoLogistico(e);
                setConfiguracion({...configuracion, idCampoLogistico: e.value})
              }}
              options={camposLogisticos}
              isLoading={camposLogisticos.length === 0 ? true : false}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col>
          <Form.Group label="Tiempo de envío">
            <Form.MaskedInput
              placeholder="HH:MM:SS"
              value={configuracion.cron}
              onChange={(e)=> setConfiguracion({...configuracion, cron: e.target.value})}
              mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <div className="float-right">
              <span class="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  disabled={ !(configuracion
                    && configuracion.idPlataforma
                    && configuracion.idMovimiento
                    && configuracion.idAcumulador
                    && configuracion.idCampoLogistico)
                  }
                  onClick={()=>guardar()}
                >
                  <span class="badge">
                    <Icon link={true} name="save" />
                    Guardar
                  </span>
                </Button>
              </span>
              <span class="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={() => borrar()}
                  disabled={!actualizar}
                >
                  <span class="badge">
                    <Icon link={true} name="trash" />
                    Borrar
                  </span>
                </Button>
              </span>
          </div>
        </Grid.Col>
      </Grid.Row>
      {/**
       *   Elementos de plataformas
       */}
    </div>
  );
}

export default NotificacionesAutomaticas;
