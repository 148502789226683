//Componentes externos
import axios, { AxiosResponse, AxiosError } from 'axios';
//Hooks externos
import { useQuery } from '@tanstack/react-query';
//Api
import * as conf from "../config/config";
//Props
interface UseGetDataProps {
  url: string;
  name: string[];
  condition?: boolean;
}

/**
 * useGetData
 * @description: Hook para realizar peticiones get.
 * @date 28/03/2024
 * @param {UseGetDataProps<T>} { url, name, condition }
 * @returns JSX del Screen.
 */
export const useGetData = <T,>({
  url,
  name,
  condition = true,
}: UseGetDataProps) => {
  /* ------------------------------ Funciones -------------------------------*/
  const fetchData = async () => {
    try {
      const response: AxiosResponse<T> = await axios.get(
        `${conf.api_raiz}${url}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + sessionStorage.getItem("tok"),
          },
        }
      );
      return response.data;
    } catch (error) {
      //Previene que se ejecute con éxito
      throw new Error();
    }
  };
  /* ------------------------------ Return ----------------------------------*/
  return useQuery<T | null>({
    queryKey: name,
    queryFn: fetchData,
    enabled: condition,
  });
};
