// @flow

import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

class EtaConfiguracionesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      tiempoForaneo: "",
      tiempoLocal: "",
      velocidadPromedio: "",
      estatus: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
    };
    //  this.enableSave = this.enableSave.bind(this);
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.setState({ tiempoForaneo: this.props.elementos.tiempoForaneo });
      this.setState({ tiempoLocal: this.props.elementos.tiempoLocal });
      this.setState({
        velocidadPromedio: this.props.elementos.velocidadPromedio,
      });
      this.setState({ estatus: this.props.elementos.estatus });
    }
  };

  enableSave = () => {
    console.log("Enable button: " + this.state.nombre);
    if (this.state.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  changeName = (event) => this.setState({ nombre: event.target.value });

  changeTiempoForaneo = (event) =>
    this.setState({ tiempoForaneo: event.target.value });

  changeTiempoLocal = (event) =>
    this.setState({ tiempoLocal: event.target.value });

  changeVelocidadPromedio = (event) =>
    this.setState({ velocidadPromedio: event.target.value });

  changeEstatus = (event) => {
    event.target.checked
      ? this.setState({ estatus: 1 })
      : this.setState({ estatus: 0 });
  };
  guardarEta = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };

  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.props.getfuntion("put", {
      id: this.state.id,
      tiempoForaneo: this.state.tiempoForaneo,
      tiempoLocal: this.state.tiempoLocal,
      velocidadPromedio: this.state.velocidadPromedio,
      estatus: this.state.estatus,
    });
  }
  crud_post() {
    this.props.getfuntion("post", {
      tiempoForaneo: this.state.tiempoForaneo,
      tiempoLocal: this.state.tiempoLocal,
      velocidadPromedio: this.state.velocidadPromedio,
      estatus: this.state.estatus,
    });
  }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.tiempoForaneo} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={9}>
                <Form.Group label="Tiempo Foraneo">
                  <Form.Input
                    name="tiempoForaneo"
                    type="text"
                    value={this.state.tiempoForaneo}
                    onChange={this.changeTiempoForaneo}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Tiempo Local">
                  <Form.Input
                    name="tiempoLocal"
                    type="text"
                    value={this.state.tiempoLocal}
                    onChange={this.changeTiempoLocal}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Velocidad Promedio">
                  <Form.Input
                    name="velocidadPromedio"
                    type="text"
                    value={this.state.velocidadPromedio}
                    onChange={this.changeVelocidadPromedio}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="">
                  <Form.Switch
                    name="estatus"
                    label="Activo"
                    value={this.state.estatus}
                    checked={this.state.estatus}
                    onChange={this.changeEstatus}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              // disabled= { this.enableSave() }
              onClick={this.guardarEta}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
          {this.state.accion === "modificar" ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default EtaConfiguracionesForm;
