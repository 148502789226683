import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table, Card } from "tabler-react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import TabsLayout from "../../components/TabsLayout.react";

/**
 * Formularios
 */
import { Usuario, Perfiles, Reporte, UsuarioCliente, Softphone } from "./Formularios/";

class UsuarioForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      accion: this.props.accion,
      plataformasArray: [],
      currentUser: [],
      newPassword: false,
      loading: true
    };
    this.usuarioObject = [];
    this.plataformas = JSON.parse(sessionStorage.getItem("plataforma"));
    this.perfil = JSON.parse(sessionStorage.getItem("perfil"));
    this.currentUser = []; // JSON.parse(sessionStorage.getItem("currentUser"));
    /**
     * Plataformasarray list
     */
    this.plataformasLists = [];
    this.templataformas = false;
    this.temProfile = 0;
    this.perfilesList = [];
    this.crudMagaged = [];
    this.perfilId = [];
    this.tabs = [];
    /**
     * Tab's
     */
    this.Usuario = {
      title: this.props.elementos.username,
      icon: "user",
      panel: (
        <Usuario
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Perfiles = {
      title: "Perfiles",
      icon: "user",
      panel: (
        <Perfiles
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    this.softphone = {
      title: "Softphone",
      icon: "phone",
      panel: (
        <Softphone
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    /*
    this.Reporte = {
      title: "Reporte",
      icon: "list",
      panel: (
        <Reporte
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
        />
      ),
      close: false,
    };*/
  }
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };

  componentWillMount = () => {
    if (this.state.accion !== "nuevo") {
      this.getfuntion("get", "", "", "plataformas/usuario/"+this.props.elementos.id+"/bloque/564", "plataformas").then((plts)=>{//564 bloque movimientos_nav_clien
        this.UsuarioCliente = {
          title: "UsuarioCliente",
          icon: "list",
          panel: (
            <UsuarioCliente
              accion={this.props.accion}
              usuario={this.props.elementos}
              getfuntion={this.props.getfuntion}
              stor={this.props.stor}
              plataformas={plts}
            />
          ),
          close: false,
        };
        this.tabs = [
          this.Usuario,
          this.Perfiles,
          this.softphone,
          plts.length && this.UsuarioCliente
        ];
        this.setState({ loading: false });
      });
    } else {
      this.tabs = [this.Usuario];
      this.setState({ loading: false });
    }
  };
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.setState({ personaId: this.props.elementos.persona.id });
      this.setState({ username: this.props.elementos.username });
      this.setState({ password: this.props.elementos.password });
      this.setState({ estatus: this.props.elementos.estatus });
      this.usuarioObject = this.props.elementos;
      Crud_Catalogos(
        "plataformasUsuariosPerfiles/username/",
        "usuarios",
        "post",
        "",
        { username: this.props.elementos.username },
        "",
        "currentUser"
      )
        .then((returnVal) => {
          this.currentUser = returnVal;
          this.setState({ currentUser: this.currentUser });
          {
            var find = false;
            this.props.stor.plataformas_object.map((elemento, index) => {
              this.state.currentUser.map((Puser, ind) => {
                if (Puser.plataforma.id === elemento["id"] && find === false) {
                  this.plataformasLists.push({ plataforma: 1, perfil: 0 });
                  this.temProfile = Puser.perfil.id;
                  this.plataformasLists[index].perfil = Puser.perfil.id;
                  find = true;
                  this.crudMagaged.push({ accion: true });
                  this.perfilId.push({ perfilId: Puser.id });
                } else {
                  this.temProfile = 0;
                }
              });
              if (!find) {
                this.plataformasLists.push({ plataforma: 0, perfil: 0 });
                this.crudMagaged.push({ accion: false });
              }
              find = false;
            });
          }
          this.setState({ plataformasArray: this.plataformasLists });
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
          } else {
            console.log("Error desconocido ....");
            console.log(err);
          }
        });
    } else {
      this.usuarioObject = {
        username: "",
        password: "",
        estatus: 0,
        persona: {},
      };
    }
  };
  getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if(stateVar === "plataformas")
              return returnVal;
            break;
          case "post":
            break;
          case "put":
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changePlataforma = (indice) => {
    {
      this.plataformasLists[indice].plataforma === 0
        ? (this.plataformasLists[indice].plataforma = 1)
        : (this.plataformasLists[indice].plataforma = 0);
    }
    this.setState({ plataformasArray: this.plataformasLists });
  };
  changePerfil = (event) => {
    var row = JSON.parse(event.target.value);
    this.plataformasLists[row.index].perfil = row.perfil;
    this.setState({ plataformasArray: this.plataformasLists });
  };

  crud_changePerfil = (indice) => {
    this.props.stor.perfiles_object.map((elemento, index) => {
      if (this.plataformasLists[indice].perfil === elemento.id) {
        if (this.crudMagaged[indice].accion) {
          if (this.plataformasLists[indice].plataforma === 0) {
            this.props.getfuntion(
              "delete",
              [],
              "idUsuario/" +
                this.state.id +
                "/idPlataforma/" +
                this.props.stor.plataformas_object[indice].id,
              this.usuarioObject,
              "plataformasUsuariosPerfiles"
            );
          }
          if (this.plataformasLists[indice].plataforma === 1) {
            this.props.getfuntion(
              "put",
              {
                id: this.perfilId[indice].perfilId,
                perfil: elemento,
                usuario: this.usuarioObject,
                plataforma: this.props.stor.plataformas_object[indice],
              },
              "",
              this.usuarioObject,
              "plataformasUsuariosPerfiles"
            );
          }
        } else {
          if (!this.crudMagaged[indice].accion) {
            this.props.getfuntion(
              "post",
              {
                perfil: elemento,
                usuario: this.usuarioObject,
                plataforma: this.props.stor.plataformas_object[indice],
              },
              "",
              this.usuarioObject,
              "plataformasUsuariosPerfiles"
            );
          }
        }
      }
    });
  };

  render() {
    return (
      <div>
        { !this.state.loading?
          <TabsLayout
            tabs={this.tabs}
            selectedTab={this.state.currentTap}
            selectTab={this.selectedTab}
          />
          :null}
      </div>
    );
  }
}
export default UsuarioForm;
