import React, { Component } from "react";
import { Table, Card, Button, Form, Icon, Grid, List } from "tabler-react";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import { ArrobaIncidenciaTerrestre } from "./Arroba";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
//import DatePicker from "react-datepicker";
//import 'react-datepicker/dist/react-datepicker.css';
import { timeToDateFill } from "../../herramientas/DateFormat";
import { Tab } from "react-tabs";
let reader = new FileReader();
let upImage = null;
var user = JSON.parse(sessionStorage.getItem("usuario"));
var disabledSaveV = false;

class MovimientoIncidenciasTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camposLogisticos: [],
      incidencias: [],
      tmpEntregas: [],
      entregas: [],
      entregaActiva: [],
      Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice: 0 },
      incidencia: { value: 0, label: "Elige Tipo de Incidencia", data: null },
      entrega: this.props.entrega || this.props.entrega,
      idEntrega: this.props.idEntrega || 1,
      comentario: this.props.comentario || "",
      latitud: this.props.latitud || "",
      longitud: this.props.longitud || "",
      enviar: false,
      destinosTransportistas: [],
      destinosMonitoreo: [],
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: false,
      hiddenModals: false,
      listarIncidencias: [],
      idMovimiento: this.props.movimiento.id,
      zona: null,
      indisciplinaOperativa: false
    };
    this.Entregas = [];
    this.enableSave = this.enableSave.bind(this);
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      idPlataformaTipo: 2,
      accionFecha: "",
      descripcion: "",
    };

    this.campLogistObj = {
      /*Exportacion*/
      retiroContenedor: this.props.retiroContenedor,
      arriboClienteVacio: this.props.arriboClienteVacio,
      salidaClienteLleno: this.props.salidaClienteLleno,
      arriboTerminalCargado: this.props.arriboTerminalCargado,
      salidaPredioVacio: this.props.salidaPredioVacio,
      citaFechaDespacho: this.props.citaFechaDespacho,
      entradaCiudadPortuaria: this.props.entradaCiudadPortuaria,
      arriboPatioLinea: this.props.arriboPatioLinea,
      salidaTerminalVacio: this.props.salidaTerminalVacio,
      /**Importacion */
      arriboTerminalVacio: this.props.arriboTerminalVacio,
      salidaTerminalLleno: this.props.salidaTerminalLleno,
      arriboClienteLleno: this.props.arriboClienteLleno,
      salidaClienteVacio: this.props.salidaClienteVacio,
      arriboTerminalVacio2: this.props.arriboTerminalVacio2,
      asignacionPiloto: this.props.asignacionPiloto,
      inicioRuta: this.props.inicioRuta,
      fechaArribo: this.props.fechaArribo,
      fechaSalida: this.props.fechaSalida,
      despachoPredioCliente: this.props.despachoPredioCliente,
      llegadaFrontera: this.props.llegadaFrontera,
      salidaFrontera: this.props.salidaFrontera,
      arriboAduana: this.props.arriboAduana,
      salidaAduana: this.props.salidaAduana,
    };
  }
  SetEntregaActiva(entregas, activa) {
    let entAtva = [];
    if (activa == 1) {
      entAtva = entregas.filter((word) => word.estatus == 1);
      if (entAtva[0]) {
        this.setState({ entregaActiva: entAtva[0] });
        this.setState({
          Entregas: {
            value: entAtva[0].idEntrega,
            label:
              entAtva[0].idDestino > 1
                ? entAtva[0].destino
                : entAtva[0].direccion,
            indice: 0,
          },
        });
        this.setState({ idEntrega: entAtva[0].idEntrega });
        if (entAtva[0].arriboTerminalCargado) {
          this.setState({
            arriboTerminalCargado: entAtva[0].arriboTerminalCargado
              ? new Date(entAtva[0].arriboTerminalCargado)
              : "",
          });
          this.campLogistObj.arriboTerminalCargado =
            entAtva[0].arriboTerminalCargado;
        }
        if (entAtva[0].arriboDescarga) {
          this.setState({
            arriboDescarga: entAtva[0].arriboDescarga
              ? new Date(entAtva[0].arriboDescarga)
              : "",
          });
          this.campLogistObj.arriboDescarga = entAtva[0].arriboDescarga;
        }
        if (entAtva[0].arriboPatioLinea) {
          this.setState({
            arriboPatioLinea: entAtva[0].arriboPatioLinea
              ? new Date(entAtva[0].arriboPatioLinea)
              : "",
          });
          this.campLogistObj.arriboPatioLinea = entAtva[0].arriboPatioLinea;
        }
        if (entAtva[0].arriboUnidadContenedor) {
          this.setState({
            arriboUnidadContenedor: entAtva[0].arriboUnidadContenedor
              ? new Date(entAtva[0].arriboUnidadContenedor)
              : "",
          });
          this.campLogistObj.arriboUnidadContenedor =
            entAtva[0].arriboUnidadContenedor;
        }
        if (entAtva[0].entregaDocumentos) {
          this.setState({
            entregaDocumentos: entAtva[0].entregaDocumentos
              ? new Date(entAtva[0].entregaDocumentos)
              : "",
          });
          this.campLogistObj.entregaDocumentos = entAtva[0].entregaDocumentos;
        }
        if (entAtva[0].fechaAlta) {
          this.setState({
            fechaAlta: entAtva[0].fechaAlta
              ? new Date(entAtva[0].fechaAlta)
              : "",
          });
          this.campLogistObj.fechaAlta = entAtva[0].fechaAlta;
        }
        if (entAtva[0].fechaArribo) {
          this.setState({
            fechaArribo: entAtva[0].fechaArribo
              ? new Date(entAtva[0].fechaArribo)
              : "",
          });
          this.campLogistObj.fechaArribo = entAtva[0].fechaArribo;
        }
        if (entAtva[0].fechaSalida) {
          this.setState({
            fechaSalida: entAtva[0].fechaSalida
              ? new Date(entAtva[0].fechaSalida)
              : "",
          });
          this.campLogistObj.fechaSalida = entAtva[0].fechaSalida;
        }
        if (entAtva[0].ingresoBodega) {
          this.setState({
            ingresoBodega: entAtva[0].ingresoBodega
              ? new Date(entAtva[0].ingresoBodega)
              : "",
          });
          this.campLogistObj.ingresoBodega = entAtva[0].ingresoBodega;
        }
        if (entAtva[0].inicioRuta) {
          this.setState({
            inicioRuta: entAtva[0].inicioRuta
              ? new Date(entAtva[0].inicioRuta)
              : "",
          });
          this.campLogistObj.inicioRuta = entAtva[0].inicioRuta;
        }
        if (entAtva[0].salidaUnidadContenedor) {
          this.setState({
            salidaUnidadContenedor: entAtva[0].salidaUnidadContenedor
              ? new Date(entAtva[0].salidaUnidadContenedor)
              : "",
          });
          this.campLogistObj.ingresoBodega = entAtva[0].salidaUnidadContenedor;
        }
        if (entAtva[0].arriboUnidadContenedor) {
          this.setState({
            arriboUnidadContenedor: entAtva[0].arriboUnidadContenedor
              ? new Date(entAtva[0].arriboUnidadContenedor)
              : "",
          });
          this.campLogistObj.arriboUnidadContenedor =
            entAtva[0].arriboUnidadContenedor;
        }
        if (entAtva[0].tiempoEstadia) {
          this.setState({ tiempoEstadia: entAtva[0].tiempoEstadia });
          this.campLogistObj.tiempoEstadia = entAtva[0].tiempoEstadia;
        }
        if (entAtva[0].tiempoTraslado) {
          this.setState({ tiempoTraslado: entAtva[0].tiempoTraslado });
          this.campLogistObj.tiempoTraslado = entAtva[0].tiempoTraslado;
        }
        if (entAtva[0].salidaFrontera) {
          this.setState({
            salidaFrontera: entAtva[0].salidaFrontera
              ? new Date(entAtva[0].salidaFrontera)
              : "",
          });
          this.campLogistObj.salidaFrontera = entAtva[0].salidaFrontera;
        }
        if (entAtva[0].llegadaFrontera) {
          this.setState({
            llegadaFrontera: entAtva[0].llegadaFrontera
              ? new Date(entAtva[0].llegadaFrontera)
              : "",
          });
          this.campLogistObj.llegadaFrontera = entAtva[0].llegadaFrontera;
        }
        if (entAtva[0].citaCarga) {
          this.setState({
            citaCarga: entAtva[0].citaCarga
              ? new Date(entAtva[0].citaCarga)
              : "",
          });
          this.campLogistObj.citaCarga = entAtva[0].citaCarga;
        }
      }
    } else {
      if (entregas) {
        //this.setState({ entregaActiva:entAtva[0] });
        this.setState({
          Entregas: {
            value: entregas.idEntrega,
            label:
              entregas.idDestino > 1 ? entregas.destino : entregas.direccion,
            indice: 0,
          },
        });
        this.setState({ idEntrega: entregas.idEntrega });
        //if (entregas.arriboTerminalCargado){
        this.setState({
          arriboTerminalCargado: entregas.arriboTerminalCargado
            ? new Date(entregas.arriboTerminalCargado)
            : "",
        });
        this.campLogistObj.arriboTerminalCargado =
          entregas.arriboTerminalCargado;
        //}
        //if (entregas.arriboDescarga){
        this.setState({
          arriboDescarga: entregas.arriboDescarga
            ? new Date(entregas.arriboDescarga)
            : "",
        });
        this.campLogistObj.arriboDescarga = entregas.arriboDescarga;
        //}
        //if (entregas.arriboPatioLinea){
        this.setState({
          arriboPatioLinea: entregas.arriboPatioLinea
            ? new Date(entregas.arriboPatioLinea)
            : "",
        });
        this.campLogistObj.arriboPatioLinea = entregas.arriboPatioLinea;
        //}
        //if (entregas.arriboUnidadContenedor){
        this.setState({
          arriboUnidadContenedor: entregas.arriboUnidadContenedor
            ? new Date(entregas.arriboUnidadContenedor)
            : "",
        });
        this.campLogistObj.arriboUnidadContenedor =
          entregas.arriboUnidadContenedor;
        //}
        //if (entregas.entregaDocumentos){
        this.setState({
          entregaDocumentos: entregas.entregaDocumentos
            ? new Date(entregas.entregaDocumentos)
            : "",
        });
        this.campLogistObj.entregaDocumentos = entregas.entregaDocumentos;
        //}
        //if (entregas.fechaAlta){
        this.setState({
          fechaAlta: entregas.fechaAlta ? new Date(entregas.fechaAlta) : "",
        });
        this.campLogistObj.fechaAlta = entregas.fechaAlta;
        //}
        //if (entregas.fechaArribo){
        this.setState({
          fechaArribo: entregas.fechaArribo
            ? new Date(entregas.fechaArribo)
            : "",
        });
        this.campLogistObj.fechaArribo = entregas.fechaArribo;
        //}
        //if (entregas.fechaSalida){
        this.setState({
          fechaSalida: entregas.fechaSalida
            ? new Date(entregas.fechaSalida)
            : "",
        });
        this.campLogistObj.fechaSalida = entregas.fechaSalida;
        //}
        //if (entregas.ingresoBodega){
        this.setState({
          ingresoBodega: entregas.ingresoBodega
            ? new Date(entregas.ingresoBodega)
            : "",
        });
        this.campLogistObj.ingresoBodega = entregas.ingresoBodega;
        //}
        //if (entregas.inicioRuta){
        this.setState({
          inicioRuta: entregas.inicioRuta ? new Date(entregas.inicioRuta) : "",
        });
        this.campLogistObj.inicioRuta = entregas.inicioRuta;
        //}
        //if (entregas.salidaUnidadContenedor){
        this.setState({
          salidaUnidadContenedor: entregas.salidaUnidadContenedor
            ? new Date(entregas.salidaUnidadContenedor)
            : "",
        });
        this.campLogistObj.salidaUnidadContenedor =
          entregas.salidaUnidadContenedor;
        //}
        //if (entregas.arriboUnidadContenedor){
        this.setState({
          arriboUnidadContenedor: entregas.arriboUnidadContenedor
            ? new Date(entregas.arriboUnidadContenedor)
            : "",
        });
        this.campLogistObj.arriboUnidadContenedor =
          entregas.arriboUnidadContenedor;
        //}
        //if (entregas.tiempoEstadia){
        this.setState({ tiempoEstadia: entregas.tiempoEstadia });
        this.campLogistObj.tiempoEstadia = entregas.tiempoEstadia;
        //}
        //if (entregas.tiempoTraslado){
        this.setState({ tiempoTraslado: entregas.tiempoTraslado });
        this.campLogistObj.tiempoTraslado = entregas.tiempoTraslado;
        //}
        //if (entregas.salidaFrontera){
        this.setState({
          salidaFrontera: entregas.salidaFrontera
            ? new Date(entregas.salidaFrontera)
            : "",
        });
        this.campLogistObj.salidaFrontera = entregas.salidaFrontera;
        //}
        //if (entregas.llegadaFrontera){
        this.setState({
          llegadaFrontera: entregas.llegadaFrontera
            ? new Date(entregas.llegadaFrontera)
            : "",
        });
        this.campLogistObj.llegadaFrontera = entregas.llegadaFrontera;
        //}
        //if (entregas.citaCarga){
        this.setState({
          citaCarga: entregas.citaCarga ? new Date(entregas.citaCarga) : "",
        });
        this.campLogistObj.citaCarga = entregas.citaCarga;
        //}
      }
    }
  }
  enableSave = () => {
    if (
      this.state.comentario.length > 10 &&
      this.state.incidencia.value
    ) {
      if(![25,820].includes(this.props.movimiento.plataforma.id)){
        if(this.state.idEntrega <= 1 )
          return true;
      }
      return false;
    } else {
      return true;
    }
  };
  componentWillUpdate = () => {};
  componentWillMount = () => {
    upImage = null;
    this.getfuntion(
      "get",
      "",
      "",
      "entregas/movimiento/" + this.props.movimiento.id,
      "entregas"
    );
    this.getfuntion(
      "get",
      [],
      "",
      "incidencias/idplataforma/" + this.props.movimiento.plataforma.id,
      "incidenciasAll"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "camposLogisticos/plataforma/" + this.props.movimiento.plataforma.id,
      "camposLogisticos"
    );
    //Obtiene las incidencias del listado del modal. Se almacena en la variable listarIncidencias.
    this.getfuntion(
      "get",
      [],
      "",
      "terrestreEntregasIncidencias/movimiento/" + this.props.movimiento.id,
      "listarIncidencias"
    );
  };
  getCamposLogisticos = () => {
    let camposLogist = [];
    if (!this.state.camposLogisticos) {
      return;
    }
    this.state.camposLogisticos.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        case "arribo_descarga":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arribo_descarga}
                      onChange={this.onChangeArriboDescarga}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.arribo_descarga === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_descarga", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_terminal_vacio":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboTerminalVacio}
                      onChange={this.onChangeArriboTerminalVacio}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboTerminalVacio === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_terminal_vacio", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_terminal_lleno":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaTerminalLleno}
                      onChange={this.onChangeSalidaTerminalLleno}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaTerminalLleno === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_terminal_lleno", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_cliente_lleno":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboClienteLleno}
                      onChange={this.onChangeArriboClienteLleno}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboClienteLleno === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_cliente_lleno", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_cliente_vacio":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaClienteVacio}
                      onChange={this.onChangeSalidaClienteVacio}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaClienteVacio === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_cliente_vacio", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_terminal_vacio_2":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboTerminalVacio2}
                      onChange={this.onChangeArriboTerminalVacio2}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboTerminalVacio2 === null}
                      onClick={() =>
                        this.ChangeGeneral(
                          "arribo_terminal_vacio_2",
                          new Date()
                        )
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "asignacion_piloto":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.asignacionPiloto}
                      onChange={this.onChangeAsignacionDePiloto}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.asignacionPiloto === null}
                      onClick={() =>
                        this.ChangeGeneral("asignacion_piloto", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "inicio_ruta":
          {
            // console.log("==>  Render "+ this.state.inicioRuta);
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      onChange={this.onChangeInicioRuta}
                      value={this.state.inicioRuta}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.inicioRuta === null}
                      onClick={() =>
                        this.ChangeGeneral("inicio_ruta", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "fecha_arribo":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.fechaArribo}
                      onChange={this.onChangeFechaArribo}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.fechaArribo === null}
                      onClick={() =>
                        this.ChangeGeneral("fecha_arribo", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="danger" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "fecha_salida":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.fechaSalida}
                      onChange={this.onChangeFechaSalida}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      size="sm"
                      color="primary"
                      disabled={this.state.fechaSalida === null}
                      onClick={() =>
                        this.ChangeGeneral("fecha_salida", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "despacho_predio_cliente":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.despachoPredioCliente}
                      onChange={this.onChangeDespachoPredioCliente}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.despachoPredioCliente}
                      size="sm"
                      color="primary"
                      disabled={this.state.despachoPredioCliente === null}
                      onClick={() =>
                        this.ChangeGeneral(
                          "despacho_predio_cliente",
                          new Date()
                        )
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "llegada_frontera":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.llegadaFrontera}
                      onChange={this.onChangeLlegadaFrontera}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.llegadaFrontera}
                      size="sm"
                      color="primary"
                      disabled={this.state.llegadaFrontera === null}
                      onClick={() =>
                        this.ChangeGeneral("llegada_frontera", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_frontera":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaFrontera}
                      onChange={this.onChangeSalidaFrontera}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.salidaFrontera}
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaFrontera === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_frontera", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_aduana":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboAduana}
                      onChange={this.onChangeArriboAduana}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.arriboAduana}
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboAduana === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_aduana", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_aduana":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaAduana}
                      onChange={this.onChangeSalidaAduana}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.salidaAduana}
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaAduana === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_aduana", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        //************************************************
        case "retiro_contenedor":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.retiroContenedor}
                      onChange={this.onChangeRetiroContenedor}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.retiroContenedor}
                      size="sm"
                      color="primary"
                      disabled={this.state.retiroContenedor === null}
                      onClick={() =>
                        this.ChangeGeneral("retiro_contenedor", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_cliente_vacio":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboClienteVacio}
                      onChange={this.onChangeArriboClienteVacio}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.arriboClienteVacio}
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboClienteVacio === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_cliente_vacio", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_cliente_lleno":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaClienteLleno}
                      onChange={this.onChangeSalidaClienteLleno}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.salidaClienteLleno}
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaClienteLleno === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_cliente_lleno", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_terminal_cargado":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboTerminalCargado}
                      onChange={this.onChangeArriboTerminalCargado}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.arriboTerminalCargado}
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboTerminalCargado === null}
                      onClick={() =>
                        this.ChangeGeneral(
                          "arribo_terminal_cargado",
                          new Date()
                        )
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_predio_vacio":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaPredioVacio}
                      onChange={this.onChangeSalidaPredioVacio}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.salidaPredioVacio}
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaPredioVacio === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_predio_vacio", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "cita_fecha_despacho":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.citaFechaDespacho}
                      onChange={this.onChangeCitaFechaDespacho}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.citaFechaDespacho}
                      size="sm"
                      color="primary"
                      disabled={this.state.citaFechaDespacho === null}
                      onClick={() =>
                        this.ChangeGeneral("cita_fecha_despacho", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "entrada_ciudad_portuaria":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.entradaCiudadPortuaria}
                      onChange={this.onChangeEntradaCiudadPortuaria}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.entradaCiudadPortuaria}
                      size="sm"
                      color="primary"
                      disabled={this.state.entradaCiudadPortuaria === null}
                      onClick={() =>
                        this.ChangeGeneral(
                          "entrada_ciudad_portuaria",
                          new Date()
                        )
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_patio_linea":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.arriboPatioLinea}
                      onChange={this.onChangeArriboPatioLinea}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.arriboPatioLinea}
                      size="sm"
                      color="primary"
                      disabled={this.state.arriboPatioLinea === null}
                      onClick={() =>
                        this.ChangeGeneral("arribo_patio_linea", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_terminal_vacio":
          {
            camposLogist.push(
              <Grid.Col md={12} lg={12}>
                <Form.Group label={cLog.nombre}>
                  <Grid.Col md={8} lg={8}>
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={this.state.salidaTerminalVacio}
                      onChange={this.onChangeSalidaTerminalVacio}
                    />
                    <spam> </spam>
                    <Button
                      target="_blank"
                      consola={this.state.salidaTerminalVacio}
                      size="sm"
                      color="primary"
                      disabled={this.state.salidaTerminalVacio === null}
                      onClick={() =>
                        this.ChangeGeneral("salida_terminal_vacio", new Date())
                      }
                    >
                      <span class="badge">
                        <Icon link={true} name="update" />
                        Now
                      </span>
                    </Button>
                  </Grid.Col>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;

        default:
          break;
      }
    });
    return camposLogist;
  };

  changeEntregas = (event) => {
    let tmpIdEntre = 0;
    let tmpEntrega = "";
    if (event.value === 1) {
      this.setState({
        Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice: 0 },
      });
      this.setState({ idEntrega: 1 });
    } else {
      if (this.props.entregas) {
        this.props.entregas.map((entre, index) => {
          if (entre.idDestino === 1) {
            if (entre.idEntrega === event.value) {
              this.setState({
                Entregas: {
                  value: entre.idEntrega,
                  label: entre.direccion,
                  indice: index,
                },
              });
              this.setState({ idEntrega: event.value });
              this.SetEntregaActiva(entre, 2);
            }
          } else {
            if (entre.idEntrega === event.value) {
              this.setState({
                Entregas: {
                  value: entre.idEntrega,
                  label: entre.destino,
                  indice: index,
                },
              });
              this.setState({ idEntrega: event.value });
              this.SetEntregaActiva(entre, 2);
            }
          }
        });
      }

      if (this.state.tmpEntregas) {
        this.state.tmpEntregas.map((entre, index) => {
          if (entre.idDestino === 1) {
            if (entre.idEntrega === event.value) {
              this.setState({
                Entregas: {
                  value: entre.idEntrega,
                  label: entre.direccion,
                  indice: index,
                },
              });
              this.setState({ idEntrega: event.value });
              tmpIdEntre = event.value;
              tmpEntrega = entre;
              this.SetEntregaActiva(entre, 2);
            }
          } else {
            if (entre.idEntrega === event.value) {
              this.setState({
                Entregas: {
                  value: entre.idEntrega,
                  label: entre.destino,
                  indice: index,
                },
              });
              this.setState({ idEntrega: event.value });
              tmpIdEntre = event.value;
              tmpEntrega = entre;
              this.SetEntregaActiva(entre, 2);
            }
          }
        });
      }
    }
    //tmpEntrega.inicioRuta=this.campLogistObj.inicioRuta;
    //this.getfuntion("put", [], "", "entregas/InicioRuta/"+this.campLogistObj.inicioRuta+"/"+tmpIdEntre, "entregas");
  };
  componentDidMount = () => {
    this.setState({
      Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice: 0 },
    });
    if (this.props.entregas) {
      this.props.entregas.map((entre, index) => {
        if (entre.idDestino === 1) {
          this.Entregas.push({
            value: entre.idEntrega,
            label: entre.direccion,
            indice: index,
          });
        } else {
          this.Entregas.push({
            value: entre.idEntrega,
            label: entre.destino,
            indice: index,
          });
        }
      });
    } else {
      this.getfuntion(
        "get",
        "",
        "",
        "entregas/movimiento/" + this.props.movimiento.id,
        "entregas"
      ).then((returnVal) => {
        this.setState({ tmpEntregas: returnVal });
        returnVal.map((entre, index) => {
          if (entre.idDestino === 1) {
            this.Entregas.push({
              value: entre.idEntrega,
              label: entre.direccion,
              indice: index,
            });
          } else {
            this.Entregas.push({
              value: entre.idEntrega,
              label: entre.destino,
              indice: index,
            });
          }
        });
      });
    }
    if (this.state.accion === "nuevo") {
      let latitud = "";
      let longitud = "";
      if (this.props.latitud) {
        latitud = this.props.latitud;
      } else {
        latitud = this.props.gpsReciente ? this.props.gpsReciente.latitud : "";
      }
      if (this.props.longitud) {
        longitud = this.props.longitud;
      } else {
        longitud = this.props.gpsReciente
          ? this.props.gpsReciente.longitud
          : "";
      }
      this.setState({ latitud: latitud });
      this.setState({ longitud: longitud });
    } else {
      this.terrestreEntregasIncidencia =
        this.props.terrestreEntregasIncidencias;
    }
    if (this.props.movimiento) {
      if (this.props.movimiento.idUltimaEntrega > 0) {
      } //else{ Se queda Igual porque no existe Ultima Entrega }
    }
  };
  //Se manda nueva incidencia a Servidor para ser guardada.
  crud_post() {
    if (!disabledSaveV) {
      disabledSaveV = true;
      this.setState({ disabledSave: true });
      this.getfuntion(
        "post",
        {
          idIncidencia: this.state.incidencia.value,
          idMovimiento: this.state.idMovimiento,
          idEntrega: this.state.idEntrega,
          zona: this.state.zona,
          comentario: this.state.comentario,
          creado: null,
          atencionUsuario: this.props.idusuario,
          atencionFecha: null,
          latitud: this.state.latitud,
          longitud: this.state.longitud,
          indisciplinaOperativa: this.state.indisciplinaOperativa
        },
        "",
        "terrestreEntregasIncidencias",
        "incidenciasTerrestre",
        this.props.closeModal,
        this.state.enviar
      );
    }
  }
  //getfuntion   (metodo="get", obj=[], id="", catalogo="entregas", stateVar= "gpsestatus", hiddenModl="") {
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    enviar = false
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "put":
            {
              if (stateVar === "entregas") {
                if (stateVar === "entregas") {
                  this.setState({ entregas: returnVal });
                  if (returnVal.idDestino) {
                    NotificationManager.info(
                      returnVal.destino,
                      "Terrestre, Actualización de Horario (" +
                        returnVal.idEntrega +
                        ")"
                    );
                  } else {
                    NotificationManager.info(
                      returnVal.direccion,
                      "Terrestre, Actualización de Horario (" +
                        returnVal.idEntrega +
                        ")"
                    );
                  }
                }
              }
            }
            break;
          case "get":
            {
              if (stateVar === "incidenciasAll") {
                let incidenciasTemp = [
                  { value: 0, label: "Elige Tipo de Incidencia", data: null },
                ];
                returnVal.map((incidencia, index) => {
                  //if(this.state.accion === "modificar"){ this.setState({incidencia: { value: incidencia.id, label: incidencia.nombre, incidencia: null } }); }
                  incidenciasTemp.push({
                    value: incidencia.id,
                    label: incidencia.nombre,
                    data: incidencia,
                  });
                });
                this.setState({ incidencias: incidenciasTemp });
              }
              if (stateVar === "destinosTransportistas") {
                this.setState({ destinosTransportistas: returnVal });
              }
              if (stateVar === "destinatarios_sky_terrestre") {
                this.setState({ destinosMonitoreo: returnVal });
              }
              if (stateVar === "entregas") {
                if (returnVal) {
                  this.setState({ entregas: returnVal });
                  this.SetEntregaActiva(returnVal, 1);
                }
                return returnVal;
              }
              if (stateVar === "camposLogisticos") {
                this.setState({ camposLogisticos: returnVal });
              }
              //Se ordenan las incidencias.
              if (stateVar === "listarIncidencias" && returnVal) {
                const ordenado = returnVal
                  .slice()
                  .sort(
                    (a, b) => b.idEntregaIncidencia - a.idEntregaIncidencia
                  );

                this.setState({ listarIncidencias: ordenado });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "incidenciasTerrestre") {
                this.props.closeModal();
                this.setState({ algo: true });
                let destMonitoreo = "";
                let destTransportistas = "";
                try {
                  this.state.destinosMonitoreo.forEach((key, index) => {
                    destMonitoreo =
                      destMonitoreo.length > 0
                        ? destMonitoreo + ", "
                        : destMonitoreo;
                    destMonitoreo =
                      destMonitoreo +
                      key.nombre +
                      " " +
                      key.aPaterno +
                      " " +
                      key.aMaterno +
                      "<" +
                      key.correo +
                      ">";
                  });
                } catch (e) {
                  console.log(e);
                }
                try {
                  this.state.destinosTransportistas.forEach((key, index) => {
                    destTransportistas =
                      destTransportistas.length > 0
                        ? destTransportistas + ", "
                        : destTransportistas;
                    destTransportistas =
                      destTransportistas +
                      key.nombre +
                      " " +
                      key.aPaterno +
                      " " +
                      key.aMaterno +
                      "<" +
                      key.correo +
                      ">";
                  });
                } catch (e) {
                  console.log(e);
                }
                if (returnVal) {
                  if (returnVal.idEntrega > 1) {
                    if (this.campLogistObj.retiroContenedor != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/RetiroContenedor/" +
                          this.campLogistObj.retiroContenedor +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaClienteLleno != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaClienteLleno/" +
                          this.campLogistObj.salidaClienteLleno +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboClienteVacio != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboClienteVacio/" +
                          this.campLogistObj.arriboClienteVacio +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboTerminalCargado != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboTerminalCargado/" +
                          this.campLogistObj.arriboTerminalCargado +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaPredioVacio != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaPredioVacio/" +
                          this.campLogistObj.salidaPredioVacio +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.citaFechaDespacho != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/CitaFechaDespacho/" +
                          this.campLogistObj.citaFechaDespacho +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (
                      this.campLogistObj.entradaCiudadPortuaria != undefined
                    ) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/EntradaCiudadPortuaria/" +
                          this.campLogistObj.entradaCiudadPortuaria +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboPatioLinea != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboPatioLinea/" +
                          this.campLogistObj.arriboPatioLinea +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaTerminalVacio != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaTerminalVacio/" +
                          this.campLogistObj.salidaTerminalVacio +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboTerminalVacio != undefined) {
                      //                              catalogo/Campo              /Fecha                                     /idEntrega            ,stateVar
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboTerminalVacio/" +
                          this.campLogistObj.arriboTerminalVacio +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaTerminalLleno != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaTerminalLleno/" +
                          this.campLogistObj.salidaTerminalLleno +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboClienteLleno != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboClienteLleno/" +
                          this.campLogistObj.arriboClienteLleno +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaClienteVacio != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaClienteVacio/" +
                          this.campLogistObj.salidaClienteVacio +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboTerminalVacio2 != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboTerminalVacio2/" +
                          this.campLogistObj.arriboTerminalVacio2 +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.asignacionPiloto != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/AsignacionPiloto/" +
                          this.campLogistObj.asignacionPiloto +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.inicioRuta != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/InicioRuta/" +
                          this.campLogistObj.inicioRuta +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.fechaArribo != undefined) {
                      console.log("hola" + this.campLogistObj.fechaArribo);
                      console.log("hola" + returnVal.idEntrega);
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/FechaArribo/" +
                          this.campLogistObj.fechaArribo +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.fechaSalida != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/FechaSalida/" +
                          this.campLogistObj.fechaSalida +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.despachoPredioCliente != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/DespachoPredioCliente/" +
                          this.campLogistObj.despachoPredioCliente +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.llegadaFrontera != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/LlegadaFrontera/" +
                          this.campLogistObj.llegadaFrontera +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaFrontera != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaFrontera/" +
                          this.campLogistObj.salidaFrontera +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.arriboAduana != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/ArriboAduana/" +
                          this.campLogistObj.arriboAduana +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                    if (this.campLogistObj.salidaAduana != undefined) {
                      this.getfuntion(
                        "put",
                        [],
                        "",
                        "entregas/SalidaAduana/" +
                          this.campLogistObj.salidaAduana +
                          "/" +
                          returnVal.idEntrega,
                        "entregas"
                      );
                    }
                  }
                }
                NotificationManager.info(
                  returnVal.comentario,
                  "Terrestre, Incidencia Nueva (" + returnVal.idEntrega + ")"
                );
                if (enviar && returnVal.idEntrega) {
                  ArrobaIncidenciaTerrestre(
                    this.props.movimiento.plataforma.id,
                    this.props.movimiento.id,
                    returnVal.idEntregaIncidencia,
                    upImage,
                    this.state.incidencia.data.idEtiqueta
                  ).then((returnVals) => {
                    NotificationManager.info(
                      returnVals,
                      "Notificación de incidencia"
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento =
                      returnVal.idMovimiento;
                    this.usuariosLogObject.idPlataformaTipo = 2;
                    this.usuariosLogObject.accionFecha = timeToDateFill(
                      new Date().getTime()
                    );
                    this.usuariosLogObject.descripcion =
                      "Arroba Incidencia (" +
                      destMonitoreo +
                      destTransportistas +
                      " ), Detalle: " +
                      returnVals;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                } else {
                  if (enviar && !returnVal.idEntrega) {
                    NotificationManager.error(
                      "No se puede enviar notificación",
                      "Incidencia Nueva"
                    );
                  }
                }
                //this.hiddenmodal();
                this.usuariosLogObject.idBitacoraMonitoreo = 11;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                this.usuariosLogObject.idPlataformaTipo = 2;
                this.usuariosLogObject.accionFecha = timeToDateFill(
                  new Date().getTime()
                );
                this.usuariosLogObject.descripcion =
                  "idIncidencia: " +
                  returnVal.idIncidencia +
                  ", comentario: " +
                  returnVal.comentario;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                disabledSaveV = false;
                this.setState({ disabledSave: false });
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /** Exportacion */
  ChangeGeneral(campo, date) {
    switch (campo) {
      case "arribo_descarga":
        {
          this.setState({ arriboDescarga: date });
          this.campLogistObj.arriboDescarga = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_terminal_vacio":
        {
          this.setState({ arriboTerminalVacio: date });
          this.campLogistObj.arriboTerminalVacio = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_terminal_lleno":
        {
          this.setState({ salidaTerminalLleno: date });
          this.campLogistObj.salidaTerminalLleno = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_cliente_lleno":
        {
          this.setState({ arriboClienteLleno: date });
          this.campLogistObj.arriboClienteLleno = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_cliente_vacio":
        {
          this.setState({ salidaClienteVacio: date });
          this.campLogistObj.salidaClienteVacio = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_terminal_vacio_2":
        {
          this.setState({ arriboTerminalVacio2: date });
          this.campLogistObj.arriboTerminalVacio2 = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "asignacion_piloto":
        {
          this.setState({ asignacionPiloto: date });
          this.campLogistObj.asignacionPiloto = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "inicio_ruta":
        {
          this.setState({ inicioRuta: date });
          this.campLogistObj.inicioRuta = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "fecha_arribo":
        {
          this.setState({ fechaArribo: date });
          this.campLogistObj.fechaArribo = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "fecha_salida":
        {
          this.setState({ fechaSalida: date });
          this.campLogistObj.fechaSalida = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "despacho_predio_cliente":
        {
          this.setState({ despachoPredioCliente: date });
          this.campLogistObj.despachoPredioCliente = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "llegada_frontera":
        {
          this.setState({ llegadaFrontera: date });
          this.campLogistObj.llegadaFrontera = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_frontera":
        {
          this.setState({ salidaFrontera: date });
          this.campLogistObj.salidaFrontera = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_aduana":
        {
          this.setState({ arriboAduana: date });
          this.campLogistObj.arriboAduana = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_aduana":
        {
          this.setState({ salidaAduana: date });
          this.campLogistObj.salidaAduana = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "retiro_contenedor":
        {
          this.setState({ retiroContenedor: date });
          this.campLogistObj.retiroContenedor = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_cliente_vacio":
        {
          this.setState({ arriboClienteVacio: date });
          this.campLogistObj.arriboClienteVacio = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_cliente_lleno":
        {
          this.setState({ salidaClienteLleno: date });
          this.campLogistObj.salidaClienteLleno = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_terminal_cargado":
        {
          this.setState({ arriboTerminalCargado: date });
          this.campLogistObj.arriboTerminalCargado = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_predio_vacio":
        {
          this.setState({ salidaPredioVacio: date });
          this.campLogistObj.salidaPredioVacio = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "cita_fecha_despacho":
        {
          this.setState({ citaFechaDespacho: date });
          this.campLogistObj.citaFechaDespacho = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "entrada_ciudad_portuaria":
        {
          this.setState({ entradaCiudadPortuaria: date });
          this.campLogistObj.entradaCiudadPortuaria = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "arribo_patio_linea":
        {
          this.setState({ arriboPatioLinea: date });
          this.campLogistObj.arriboPatioLinea = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      case "salida_terminal_vacio":
        {
          this.setState({ salidaTerminalVacio: date });
          this.campLogistObj.salidaTerminalVacio = date
            ? timeToDateFill(new Date(date).getTime())
            : date;
        }
        break;
      default:
        break;
    }
  }

  onChangeArriboDescarga = (date) => {
    this.setState({ arriboDescarga: date });
    this.campLogistObj.arriboDescarga = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeRetiroContenedor = (date) => {
    this.setState({ retiroContenedor: date });
    this.campLogistObj.retiroContenedor = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaClienteLleno = (date) => {
    this.setState({ salidaClienteLleno: date });
    this.campLogistObj.salidaClienteLleno = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboClienteVacio = (date) => {
    this.setState({ arriboClienteVacio: date });
    this.campLogistObj.arriboClienteVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboTerminalCargado = (date) => {
    this.setState({ arriboTerminalCargado: date });
    this.campLogistObj.arriboTerminalCargado = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaPredioVacio = (date) => {
    this.setState({ salidaPredioVacio: date });
    this.campLogistObj.salidaPredioVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeCitaFechaDespacho = (date) => {
    this.setState({ citaFechaDespacho: date });
    this.campLogistObj.citaFechaDespacho = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeEntradaCiudadPortuaria = (date) => {
    this.setState({ entradaCiudadPortuaria: date });
    this.campLogistObj.entradaCiudadPortuaria = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboPatioLinea = (date) => {
    this.setState({ arriboPatioLinea: date });
    this.campLogistObj.arriboPatioLinea = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaTerminalVacio = (date) => {
    this.setState({ salidaTerminalVacio: date });
    this.campLogistObj.salidaTerminalVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  /**Importacion */
  onChangeArriboTerminalVacio = (date) => {
    this.setState({ arriboTerminalVacio: date });
    this.campLogistObj.arriboTerminalVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaTerminalLleno = (date) => {
    this.setState({ salidaTerminalLleno: date });
    this.campLogistObj.salidaTerminalLleno = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboClienteLleno = (date) => {
    this.setState({ arriboClienteLleno: date });
    this.campLogistObj.arriboClienteLleno = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaClienteVacio = (date) => {
    this.setState({ salidaClienteVacio: date });
    this.campLogistObj.salidaClienteVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboTerminalVacio2 = (date) => {
    this.setState({ arriboTerminalVacio2: date });
    this.campLogistObj.arriboTerminalVacio2 = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeAsignacionDePiloto = (date) => {
    this.setState({ asignacionPiloto: date });
    this.campLogistObj.asignacionPiloto = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  /* Terrestres */

  onChangeInicioRuta = (date) => {
    this.setState({ inicioRuta: date });
    this.campLogistObj.inicioRuta = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeFechaArribo = (date) => {
    this.setState({ fechaArribo: date });
    this.campLogistObj.fechaArribo = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeFechaSalida = (date) => {
    this.setState({ fechaSalida: date });
    this.campLogistObj.fechaSalida = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };

  onChangeDespachoPredioCliente = (date) => {
    this.setState({ despachoPredioCliente: date });
    this.campLogistObj.despachoPredioCliente = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeLlegadaFrontera = (date) => {
    this.setState({ llegadaFrontera: date });
    this.campLogistObj.llegadaFrontera = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaFrontera = (date) => {
    this.setState({ salidaFrontera: date });
    this.campLogistObj.salidaFrontera = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboAduana = (date) => {
    this.setState({ arriboAduana: date });
    this.campLogistObj.arriboAduana = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaAduana = (date) => {
    this.setState({ salidaAduana: date });
    this.campLogistObj.salidaAduana = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };

  changeIncidencias = (event) => {
    this.setState({ incidencia: event });

    if (event.data != null) {
      this.getfuntion(
        "get",
        [],
        "",
        "personas/destinatarios_transportistas_incidencias_ter/" +
          this.props.movimiento.id +
          "/" +
          event.data.idEtiqueta,
        "destinosTransportistas"
      );
      this.getfuntion(
        "get",
        [],
        "",
        "personas/destinatarios_sky_terrestre_incidencias/" +
          this.props.movimiento.id +
          "/" +
          event.data.idEtiqueta,
        "destinatarios_sky_terrestre"
      );
      this.setState({ enviar: true });
    } else {
      this.setState({ destinosTransportistas: [] });
      this.setState({ destinosMonitoreo: [] });
      this.setState({ enviar: false });
    }
  };
  changeComentario = (event) => this.setState({ comentario: event.target.value });

  changeZona = (event) => this.setState({ zona: event.target.value });

  changeEnviar = (e) => {
    this.setState({ enviar: e.target.checked });
  };

  fileOnloadNew = (e) => {
    document.querySelector('img[name="prev"]').src = reader.result;
  };
  onChangeImage = (event) => {
    try {
      upImage = event.target.files[0];
      reader.onload = this.fileOnloadNew;
      reader.readAsDataURL(upImage);
      //this.campLogistObj.imagen = upImage;
    } catch (error) {}
  };

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    let privilegios = ""; //this.props.stor.privilegios_object;
    if (this.props.stor.privilegios_object) {
      privilegios = this.props.stor.privilegios_object;
    } else {
      privilegios = this.props.stor.stor.privilegios_object;
    }
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          this.privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  guardarIncidencia = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.comentario} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={12}>
                <h1>ID Movimiento {this.props.movimiento.id}</h1>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Incidencias">
                  <Select
                    value={this.state.incidencia}
                    onChange={this.changeIncidencias}
                    options={this.state.incidencias}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Latitud">
                  <Form.Input
                    type= "number"
                    name="latitud"
                    value={this.state.latitud}
                    onChange={(e)=> this.setState({ latitud: e.target.value})}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Longitud">
                  <Form.Input
                    type= "number"
                    name="longitud"
                    value={this.state.longitud}
                    onChange={(e)=> this.setState({ longitud: e.target.value})}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group label="Comentario">
                  <Form.Textarea
                    name="comentario"
                    type="text"
                    value={this.state.comentario}
                    onChange={this.changeComentario}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <div>
                  <Grid.Col md={4}>
                    <Form.Group label="Imagen">
                      <input
                        type="file"
                        id="imagen"
                        ref={this.fileInput}
                        className="file"
                        accept="image/*"
                        onChange={this.onChangeImage}
                      />
                      <img
                        name="prev"
                        src="images/loadPicture.png"
                        width="50%"
                        align="center"
                        border="0"
                      />
                      <Button
                        target="_blank"
                        size="sm"
                        RootComponent="a"
                        color="primary"
                        pill
                        onClick={(e) => {
                          try {
                            //navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
                            //  if (result.state == "granted" || result.state == "prompt") {
                            navigator.clipboard.read().then((data) => {
                              for (let i = 0; i < data.length; i++) {
                                if (!data[i].types.includes("image/png")) {
                                  alert("No parece ser una imagen");
                                } else {
                                  data[i]
                                    .getType("image/png")
                                    .then((blob) => {
                                      upImage = blob;
                                      reader.onload = this.fileOnloadNew;
                                      reader.readAsDataURL(upImage);
                                      upImage = new File(
                                        [blob],
                                        "imagen" +
                                          new Date().getTime() +
                                          ".png"
                                      );
                                    });
                                }
                              }
                            });
                            //  }
                            //});
                          } catch (error) {}
                        }}
                      >
                        <span class="badge">
                          <Icon link={true} name="image" />
                          pegar
                        </span>
                      </Button>
                    </Form.Group>
                  </Grid.Col>
                </div>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>

          <Grid.Col md={12}>
            {/* Se pinta el input si se trata de una plataforma de Traxion */}
            {[93, 490, 557, 25].includes(this.props.idplataforma) && (
              <Form.Group label="Zona">
                <Form.Input
                  name="Zona"
                  value={this.state.zona}
                  onChange={this.changeZona}
                />
              </Form.Group>
            )}
            <Form.Group label="Indisciplina Operativa">
              <Form.Switch
                type="Checkbox"
                checked={this.state.indisciplinaOperativa}
                onChange={(e)=> this.setState({indisciplinaOperativa: e.target.checked })}
              />
            </Form.Group>
            <Form.Group label="Enviar correo">
              {this.estaPrivilegioActivo("arroba_incidencia") ? (
                <Form.Switch
                  type="Checkbox"
                  checked={this.state.enviar}
                  onChange={this.changeEnviar}
                />
              ) : (
                <Form.Switch type="Checkbox" checked={false} />
              )}
            </Form.Group>
            {this.state.destinosTransportistas ? (
              <List.Group>
                <h3>
                  {this.state.destinosTransportistas.length > 0
                    ? "Transportista"
                    : null}
                </h3>
                {this.state.destinosTransportistas.map((transp, index) => (
                  <List.GroupItem action icon="rss">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <Icon name="user" />
                        {transp.nombre +
                          " " +
                          transp.aPaterno +
                          " " +
                          transp.aMaterno}{" "}
                      </div>
                      <div className="col-5">
                        {" "}
                        <Icon name="mail" />
                        {transp.correo}{" "}
                      </div>
                    </div>
                  </List.GroupItem>
                ))}
              </List.Group>
            ) : null}
            {this.state.destinosMonitoreo ? (
              <List.Group>
                <h3>
                  {this.state.destinosMonitoreo.length > 0 ? "Monitoreo" : null}
                </h3>
                {this.state.destinosMonitoreo.map((moni, index) => (
                  <List.GroupItem action icon="rss">
                    <div className="row">
                      <div className="col-5">
                        {" "}
                        <Icon name="user" />
                        {moni.nombre +
                          " " +
                          moni.aPaterno +
                          " " +
                          moni.aMaterno}{" "}
                      </div>
                      <div className="col-5">
                        {" "}
                        <Icon name="mail" />
                        {moni.correo}{" "}
                      </div>
                    </div>
                  </List.GroupItem>
                ))}
              </List.Group>
            ) : null}
            {this.state.enviar ? (
              this.state.destinosTransportistas.length > 0 ||
              this.state.destinosMonitoreo.length > 0 ? null : (
                <List.GroupItem action icon="rss">
                  {" "}
                  Al parecer no se ha configurado la plantilla, o no hay
                  destinatarios, revise su configuración{" "}
                </List.GroupItem>
              )
            ) : null}
          </Grid.Col>
        </Grid.Row>
        { ![820].includes(this.props.movimiento.plataforma.id) &&
          <List.GroupItem action icon="message-square" label="Entrega">
            <Select
              value={this.state.Entregas}
              onChange={this.changeEntregas}
              options={this.Entregas}
            />

            {this.getCamposLogisticos()}
          </List.GroupItem>
        }

        <Grid.Row>
          <div className="float-right">
            &nbsp;&nbsp;{" "}
            <span class="badge">
              {this.props.desde != "AlertasListar" ? (
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  disabled={this.enableSave() || this.state.disabledSave}
                  onClick={this.guardarIncidencia}
                >
                  <span class="badge">
                    <Icon link={true} name="save" />
                    Guardar Incidencia
                  </span>
                </Button>
              ) : (
                <div></div>
              )}
            </span>
            {this.state.accion === "modificar" ? (
              <span class="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={() => this.delete()}
                >
                  <span class="badge">
                    <Icon link={true} name="trash" />
                    Borrar
                  </span>
                </Button>
              </span>
            ) : null}
          </div>

          <Grid.Col md={12} xl={12}>
            <Card title="Ultimas incidencias">
              <Card.Body>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={[
                    { content: "Id" },
                    { content: "Incidencia tipo" },
                    { content: "Comentario" },
                    { content: "Fecha de creacion" },
                    { content: "Indisciplina" },
                  ]}
                >
                  <Table.Body>
                    {/* Se pinta el listado de las incidencias */}
                    {this.state.listarIncidencias.map((res, index) => {
                      const incidAmostrar = 10;
                      if (index < incidAmostrar) {
                        let nombreIncidencia = this.getIncidenciaNombre(
                          res.idIncidencia
                        );

                        return (
                          <Table.Row>
                            <Table.Col>
                              <small>{res.idEntregaIncidencia}</small>
                            </Table.Col>
                            <Table.Col>
                              <small>{nombreIncidencia}</small>
                            </Table.Col>
                            <Table.Col>
                              <small>
                                {res.comentario === "" ||
                                res.comentario === null
                                  ? "Sin comentario"
                                  : res.comentario}
                              </small>
                            </Table.Col>
                            <Table.Col>
                              <small>{res.creado}</small>
                            </Table.Col>
                            <Table.Col>
                              <small>
                              <Form.Switch type="Checkbox" checked={res.indisciplinaOperativa} />
                              </small>
                            </Table.Col>
                          </Table.Row>
                        );
                      }
                    })}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </div>
    );
  }
  getIncidenciaNombre = (id) => {
    for (let item of this.state.incidencias) {
      if (id === item.value) {
        return item.label;
      }
    }
    return "";
  };
}
export default MovimientoIncidenciasTerrestreForm;
