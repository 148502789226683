import { Crud_Catalogos, Crud_error } from "../herramientas/Crud_Catalogos";
import { timeToDateFill } from "../herramientas/DateFormat";

export function MovsTerrestresToObjects(propiedades){
}
export const MovsTerrToObjectsList = async (data, props) =>{
  const propsObj= getPropsObj(props);
  let movimientos= [];
  return await getfuntion("get","","","terrestreGps", "terrestreGps").then((movGps) => {
    let movGpsObj= {};
    for( let mg of movGps){
      if(!movGpsObj[mg.idMovimiento])
        movGpsObj[mg.idMovimiento]= [];
      movGpsObj[mg.idMovimiento].push(mg);
    }
    if(data && data.length){      
      for(let m of data){
        movimientos.push(
          {
            "corredor": {...propsObj['corredores'][m.id_corredor]},
            "cartaPorte": m.carta_porte,
            "rutaInicio": m.ruta_inicio? timeToDateFill(new Date(m.ruta_inicio).getTime()): m.ruta_inicio,
            "origen": m.origen,
            "nota": m.nota,
            "deRegreso": m.de_regreso,
            "custodia": m.custodia,
            "custodiaNombre": m.custodia_nombre,
            "checklist": m.checklist,
            "ultimaIncidencia": m.ultima_incidencia? timeToDateFill(new Date(m.ultima_incidencia).getTime()): m.ultima_incidencia,
            "nombreOperador": m.nombre_operador,
            "ruta": m.ruta,
            "base": m.base,
            "gerente": m.gerente,
            "grupo": m.grupo,
            "estatusRuta": m.estatus_ruta,
            "caja": m.caja,
            "comentarioIncidencia": m.comentario_incidencia,
            "economico": m.economico,
            "creationDate": m.creation_date? timeToDateFill(new Date(m.creation_date).getTime()): m.creation_date,
            "endDate": m.end_date? timeToDateFill(new Date(m.end_date).getTime()): m.end_date,
            "movimientoGps": (movGpsObj[m.id_movimiento]? movGpsObj[m.id_movimiento]: []),
            "mbRutaTransporte": m.mb_ruta_transporte,
            "mbTipoProveedor": m.mb_tipo_proveedor,
            "mbReferenciaAuxiliar": m.mb_referencia_auxiliar,
            "fechaInActCarga": m.fecha_in_act_carga? timeToDateFill(new Date(m.fecha_in_act_carga).getTime()): m.fecha_in_act_carga,
            "fechaInActTransp": m.fecha_in_act_transp? timeToDateFill(new Date(m.fecha_in_act_transp).getTime()): m.fecha_in_act_transp,
            "modalidad": (m.id_modalidad ? {...propsObj['modalidades'][m.id_modalidad]}: m.id_modalidad),//
            "origenCatalogoMabe": (m.id_origen_catalogo_mabe? {...propsObj['origenCatalogoMabe'][m.id_origen_catalogo_mabe]}: m.id_origen_catalogo_mabe),//
            "personalizados": m.personalizados,
            "placa": m.placa,
            "ultimaEntrega": m.ultima_entrega,
            "idUltimaEntrega": m.id_ultima_entrega,
            "fechaEntrega": m.fecha_entrega? timeToDateFill(new Date(m.fecha_entrega).getTime()): m.fecha_entrega,
            "operacionEstatus": {...propsObj['operacionesEstatus'][m.id_operacion_estatus]},
            "plataforma": {...propsObj['plataformas'][m.id_plataforma]},
            "id": m.id_movimiento,
            "transportista": {...propsObj['transportistas'][m.id_transportista]},
            "operador": {...propsObj['operadores'][m.id_operador]},
            "movimientoTipo": {...propsObj['movimientosTipo'][m.id_movimiento_tipo]},
            "nombreIncidencia": m.nombre_incidencia,
            "idUltimaIncidencia": m.id_ultima_incidencia,
            "operacionTipo": {...propsObj['operacionesTipo'][m.id_operacion_tipo]}
          }
        );
      }
    }    
    return movimientos;
  });
};
function getPropsObj (props) {
  //console.log(props);
  const propiedades= {};
  propiedades['corredores']= {};
  propiedades['operacionesEstatus']= {};
  propiedades['plataformas']= {};
  propiedades['transportistas']= {};
  propiedades['operadores']= {};
  propiedades['movimientosTipo']= {};
  propiedades['operacionesTipo']= {};
  propiedades['modalidades']= {};
  propiedades['origenCatalogoMabe']= {};
  for(let corr of props.corredores_object){
      propiedades['corredores'][corr.id]= corr;
  }
  for(let oe of props.operacionesEstatus_object){
    propiedades['operacionesEstatus'][oe.id]= oe;
  }
  for(let p of props.plataformas_object){
    propiedades['plataformas'][p.id]= p;
  }
  for(let t of props.transportistas_object){
    propiedades['transportistas'][t.id]= t;
  }
  for(let o of props.operadores_object){
    propiedades['operadores'][o.id]= o;
  }
  for(let mt of props.movimientosTipo_object){
    propiedades['movimientosTipo'][mt.id]= mt;
  }
  for(let ot of props.operacionesTipo_object){
    propiedades['operacionesTipo'][ot.id]= ot;
  }
  for(let m of props.modalidades_object){
    propiedades['modalidades'][m.id]= m;
  }
  for(let ocm of props.origenCatalogoMabe_object){
    propiedades['origenCatalogoMabe'][ocm.id]= ocm;
  }

  return propiedades;
};
const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
  return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    switch (metodo) {
      case "get": {
        if (stateVar === "terrestreGps"){
          return returnVal;
        }
        
       } break;
      default:    break;
    }
  }).catch(err => {
    console.log(err);
  });
}
export default MovsTerrestresToObjects;
/*

*/
