import React, { useMemo, useCallback, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import axios from 'axios';
import 'react-table/react-table.css'
import { api_raiz } from "../../config/config";
import SiteWrapper from "../../SiteWrapper.react";
import { Form, Button, Header, Grid } from "tabler-react";

const columns = [{
  Header: 'Id',
  accessor: 'id', // String-based value accessors!
  filterable: true,
}, {
  Header: 'Nombre',
  accessor: 'name', // String-based value accessors!
  filterable: true,

}, {
  Header: 'Código postal',
  accessor: 'zipCode', // String-based value accessors!
  filterable: true,

},
{
  Header: 'Estatus',
  accessor: 'parsedStatus',
}, {
  Header: 'Fecha de registro',
  accessor: 'parsedDate',
}]

const CoberturasPage = () => {


  const [data, setData] = useState([]);


  const instance = useMemo(() => {
    const instance = axios.create({
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem("tok")
      }
    });
    return instance;
  }, [])

  const getData = useCallback(async () => {
    try {
      const response = await instance.get(api_raiz + "coberturas");

      console.log(response.data)

      response.data = response.data.map((cobertura) => {
        cobertura.parsedDate = new Date(cobertura.registrationDate).toLocaleString()
        cobertura.parsedStatus = cobertura.status ? "Activo" : ""
        return cobertura
      })

      setData(response.data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const submitEntry = () => { }



  return (
    <SiteWrapper>
      <div className="container mt-3">
        <Grid.Row>
          <Grid.Col>
            <Form onSubmit={submitEntry} >
              <Form.FieldSet >
                <Header.H4>Nuevo</Header.H4>
                <Form.Group label="Nombre" isRequired>
                  <Form.Input name="name" onChange={() => { }} />
                </Form.Group>
                <Form.Group label="Código Postal" isRequired>
                  <Form.Input name="zipCode" onChange={() => { }} />
                </Form.Group>
                <Button className="mt-3" icon="layout" type="submit" value="Submit" >Subir entrada</Button>
              </Form.FieldSet>
            </Form>
          </Grid.Col>
        </Grid.Row>
        <ReactTable
          data={data}
          columns={columns}
          previousText='Anterior'
          nextText='Siguiente'
          noDataText='Sin información disponible'
          pageText='Página'
          ofText='de'
          rowsText='Elementos'
          sorted={[{
            id: 'id',
            desc: true
          }
          ]}
          className="mb-8"
        />
      </div>
    </SiteWrapper>
  );
}

export default CoberturasPage;