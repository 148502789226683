import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props) {
    
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.transportista}
        </TableCell>
        <TableCell align="right">{row.delay}</TableCell>
        <TableCell align="right">{row.ontime}</TableCell>
        <TableCell align="right">{row.totalMov}</TableCell>
        <TableCell align="right">
       
        <div class="background">	
		
			
          { row.porcent >= 93 ?
				  <div id="circulo1" class="color1"></div>
          : row.porcent < 93 && row.porcent > 80 ? 
				  <div id="circulo2" class="color2"></div>
          :
				  <div id="circulo3" class="color3"></div>
       }
      {row.percent} 
		
	</div>	
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Movimientos por Transportista
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>WO</TableCell>
                    <TableCell align="right">Booking</TableCell>
                    <TableCell align="right">Cita Programada</TableCell>
                    <TableCell align="right">Cliente</TableCell>
                    <TableCell align="right">Transportista</TableCell>
                    <TableCell align="right">Estatus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.idMovimiento}>
                      <TableCell component="th" scope="row">
                        {historyRow.idMovimiento}
                      </TableCell>
                      <TableCell>{historyRow.po}</TableCell>
                      <TableCell align="right">{historyRow.booking}</TableCell>
                      <TableCell align="right">
                        {historyRow.citaProgramada}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.cliente}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.idTransportista}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

export default function CollapsibleTable(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: '100%' }}>
    <TableContainer sx={{ maxHeight: 440 }}  >
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell />
            {props.heads.map((head,i) => (
                 i == 0  ? 
            <TableCell >{head}</TableCell>
            : 
            <TableCell  align="right">{head}</TableCell>
        ))}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <Row key={row.corredor} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     <TablePagination
     rowsPerPageOptions={[5, 10, 20]}
     component="div"
     count={rows.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
   />
    </Paper>
  );
}