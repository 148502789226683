import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";
import Select from "react-select";
/** alertas */
import { NotificationManager } from 'react-notifications';
class RecuperarGpsView extends Component {
    constructor() {
        super();
        this.state = { gps: [], gpsR: {label: "Seleccione un gps", value: 0, data: null}, };
    }
    getFuncionalidad=(elemento)=>{
        if(!this.props.funcionalidades){ return false; }
        for(let item of this.props.funcionalidades){
          if(item.nombre === elemento ){ return true; break;}
        }
        return false;
      }
    render() {
        return (
            <div>
                { this.props.bloque == "Recuperar_GPS" && this.getFuncionalidad("Recuperar GPS")?
                <Grid.Row>
                    <Grid.Col md={4} lg={4}>
                        <Form.Group label="NOMBRE GPS"><Select value={this.state.gpsR} options={this.state.gps} /></Form.Group>
                    </Grid.Col>
                    <Grid.Col md={8} lg={8}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Detalles</Card.Title>
                                <Card.Options>
                                        <div><Button color="primary" size="sm">Recuperar</Button></div>
                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                { this.state.gpsR.data ?
                                    <Table cards={true} striped={true} responsive={true} className="table-vcenter"
                                    headerItems={[ { content: "ID" },{ content: "NOMBRE" },{ content: "MARCA" },{ content: "IMEI" },{ content: "SIM" },{ content: "IDGG" } ] } >
                                    
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Col> {this.state.gpsR.data.id        }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.data.nombre    }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.data.marca     }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.data.imei      }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.data.sim       }  </Table.Col>
                                                <Table.Col> {this.state.gpsR.data.idGg      }  </Table.Col>                            
                                            </Table.Row>                    
                                        </Table.Body>
                                    </Table>
                                : <div>No se ha seleccionado ningun GPS</div>}
                            </Card.Body>
                            <Card.Footer></Card.Footer>
                        </Card>                    
                    </Grid.Col>     
                </Grid.Row>
                :null}
            </div>
        );
    }
}

export default RecuperarGpsView;
