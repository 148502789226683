import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { Dimmer } from "tabler-react";
import {NotificationContainer, NotificationManager} from 'react-notifications';

var terrores=0;
var hora 		=0;
var minuto	=0;
var segundo =0;

var fechas	=0;
var horas		=0;
var arrayErr=[];
let Errores	=[];
let FilaErr	=[];


function myModal(Muestra) {
  var x = document.getElementById("xlsModalT");
  x.style.display = Muestra;
}
function pad(number) {  if (number < 10) { return '0' + number; }  return number; }
function myFormato(date) { var nuevos = new Date(date);
  return nuevos.getFullYear() + '-' + pad(nuevos.getMonth() + 1) + '-' + pad(nuevos.getDate());
}

function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class Importar extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",
//Catalogo corredores
          plataformaTipo:this.props.plataformaTipo          |[],

//Catalogo Funcionalidades
                 bloque:this.props.bloque                   |[],

//Catalogo Funcionalidades
              categorias:this.props.categorias              |[],

//Catalogo gpsFijos y Unidades
              plataforma:this.props.plataforma              |[],

//Tabla gps
              idCorredor:this.props.idCorredor              |[],
              gpsEstatus:this.props.gpsEstatus              |[],
             gpsHardware:this.props.gpsHardware             |[],

//Catalogo Unidades
           transportista:this.props.transportista           |[],
              unidadTipo:this.props.unidadTipo              |[],
                 estatus:this.props.gpsEstatus              |[],

//Catalogo unidadElementos
             unidadMarca:this.props.unidadMarca             |[],

//Variables
            movimientos:this.props.movimientos							|[],
            privilegios:this.props.privilegios							|[],

                  accion:this.props.accion,
                validado:this.props.validado                |false,
									  data:this.props.data										|[],
    };
    this.cita=0;
  }
  componentDidMount(){
		myModal("none");//Oculta el Modal
  }
  componentWillMount(){
    switch (this.props.catalogo) {
      case "corredores":
        {
        this.cargaPlataformaTipo();
        };
        break;
      case "gps":
        {
        this.cargaCorredor();
        this.cargaGpsEstatus();
        this.cargaGpsHardware();
        }
        break;
      case "gpsFijos":
        {
        this.cargaPlataforma();
        this.cargaGpsHardware();
        }
        break;
      case "funcionalidades":
        { this.cargaBloque() };
        break;
      case "privilegios":
        { this.cargaCategorias() };
        break;
      case "unidades":
        { this.cargaPlataforma()   };
        { this.cargaTransportista()};
        { this.cargaUnidadTipo()   };
        { this.cargaEstatus()      };
        { this.cargaUnidadMarca() };
        break;
      case "unidadTerNav":
        { this.cargaUnidadTipo()   };
        { this.cargaEstatus()      };
        { this.cargaUnidadMarca() };
        break;        
      case "unidadElementos":        
        break;
      case "operadores":
        { this.cargaTransportista() };
        break;
      default:
        break;
    }
  }
cargaPlataformaTipo() {
  Crud_Catalogos("plataformasTipos", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ plataformaTipo:returnVal });
  }).catch(err =>{
    console.log(err);
    console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");
  });
}
cargaUnidadMarca() {
    Crud_Catalogos("unidadesMarcas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadMarca:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaCategorias() {
  Crud_Catalogos("categoria", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ categorias:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaBloque() {
  Crud_Catalogos("bloque", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ bloque:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaCorredor() {
  Crud_Catalogos("corredores", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ idCorredor:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdCorredor() {
  Crud_Catalogos("corredores", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ corredores:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaGpsEstatus() {
  Crud_Catalogos("gpsEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ gpsEstatus:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaGpsHardware() {
  Crud_Catalogos("gpsHardware", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ gpsHardware:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaPlataforma() {
  Crud_Catalogos("plataformas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ plataforma:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaUnidadTipo() {
    Crud_Catalogos("unidadTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaTransportista() {
    Crud_Catalogos("transportistas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ transportista:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaEstatus() {
    Crud_Catalogos("unidadEstatusOperacion", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ estatus:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}

valYaExiste(Catalogo, Po, I, Campo){
	var k=0;
  switch (Catalogo) {
    case "movimientos":
      k=0;
      this.state.movimientos.map(ids => {
        if (this.state.movimientos[k].po===Po){//Campo.po=Po; //this.state.bloque[I].id;//this.state.data[I].movimientos = Campo;
					terrores=terrores+1;//NotificationManager.warning("El Po !" + Po + "! YA Existe! en la BD, Eliminelo del Archivo de Excel en la Fila: " + (I+1) );
        } k=k+1;
      });
      break;
    default:
      break;
  }
}

valCatalogoId(Catalogo, Id, I, Campo){
  var k=0;
  switch (Catalogo) {
    case "bloque":
      k=0;
      this.state.bloque.map(ids => {
        if (this.state.bloque[k].id===Id){
          Campo.id=Id; //this.state.bloque[I].id;
          this.state.data[I].bloque = Campo;
        } k=k+1;
      });
      break;
    case "categoria":
      k=0;
      this.state.categorias.map(ids => {
        if (this.state.categorias[k].id===Id){
          Campo.id=Id;
          this.state.data[I].categorias = Campo;
        } k=k+1;
      });
      break;
    case "estatus":
      k=0;
      this.state.estatus.map(ids => {
        if (this.state.estatus[k].id===Id){
          Campo.id=Id;
          this.state.data[I].unidadEstatus = Campo;
        } k=k+1;
      });
      break;
    case "idUnidadEstatusOperacion":
      k=0;
      this.state.estatus.map(ids => {
        if (this.state.estatus[k].id===Id){
          Campo.id=Id;
          this.state.data[I].idUnidadEstatusOperacion = Campo;
        } k=k+1;
      });
      break;
    case "gpsEstatus":
      k=0;
      this.state.gpsEstatus.map(ids => {
        if (this.state.gpsEstatus[k].id===Id){
          Campo=this.state.gpsEstatus[I];
          this.state.data[I].gpsEstatus = Campo;
        } k=k+1; });
      break;
    case "idCorredor":
      k=0;
      this.state.idCorredor.map(ids => {
        if (this.state.idCorredor[k].id===Id){
          Campo=this.state.idCorredor[I];
          this.state.data[I].idCorredor = Campo;
        } k=k+1; });
      break;
    case "gpsHardware":
      k=0;
      this.state.gpsHardware.map(ids => {
        if (this.state.gpsHardware[k].id===Id){
          Campo=this.state.gpsHardware[I];
          this.state.data[I].gpsHardware = Campo;
        } k=k+1; });
      break;
    case "plataforma":
      k=0;
      this.state.plataforma.map(ids => {
        if (this.state.plataforma[k].id===Id){
          Campo.id=Id;
          this.state.data[I].plataforma = Campo;
        } k=k+1; });
      break;
    case "plataformaTipo":
      k=0;
      this.state.plataformaTipo.map(ids => {
        if (this.state.plataformaTipo[k].id===Id){
          Campo.id=Id;
          this.state.data[I].plataformaTipo = Campo;
        } k=k+1; });
      break;
    case "transportista":
      k=0;
      this.state.transportista.map(ids => {
        if (this.state.transportista[k].id===Id){
          Campo.id=Id;
          this.state.data[I].transportista = Campo;
        } k=k+1; });
      break;
    case "unidadMarca":
      k=0;
      this.state.unidadMarca.map(ids => {
        if (this.state.unidadMarca[k].id===Id){
          Campo.id=Id;
          this.state.data[I].unidadMarca = Campo;
        } k=k+1; });
      break;
    case "idUnidadMarca":
      k=0;
      this.state.unidadMarca.map(ids => {
        if (this.state.unidadMarca[k].id===Id){
          Campo.id=Id;
          this.state.data[I].idUnidadMarca = Campo;
        } k=k+1; });
      break;
    case "unidadTipo":
      k=0;
      this.state.unidadTipo.map(ids => {
        if (this.state.unidadTipo[k].id===Id){
          Campo.id=Id;
          this.state.data[I].unidadTipo = Campo;
        } k=k+1; });
      break;
    case "idUnidadTipo":
      k=0;
      this.state.unidadTipo.map(ids => {
        if (this.state.unidadTipo[k].id===Id){
          Campo.id=Id;
          this.state.data[I].idUnidadTipo = Campo;
        } k=k+1; });
      break;
    case "tipo":
      if (1===Id){
          Campo=Id;
          this.state.data[I].tipo = Campo;
      }else{
          this.state.data[I].tipo = 2;
      }
      break;
    case "cron":
      fechas = parseInt(Id, 10);
      horas = Number.parseFloat(Id)-fechas;
      serieHoras(horas);
      Campo=""+hora+":"+minuto+":"+segundo+"";
      this.state.data[I].cron = Campo;
      break;
    default:
      break;
  }
}

getCatalogoId(Catalogo, Nombre, I, Campo){
  var k=0;
  switch (Catalogo) {

    case "bloque":
      k=0;
      this.state.bloque.map(ids => {
        if (this.state.bloque[k].nombre===Nombre){
              Campo.id=this.state.bloque[k].id;
              this.state.data[I].bloque = Campo;
        } k=k+1;
      });
      break;

    case "categoria":
      let tempoCat=[];
      tempoCat = this.state.categorias.filter(ids => (ids.nombre===Nombre));
      if (tempoCat.length>0){
        Campo.id=tempoCat[0].id
        this.state.data[I].categorias = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].categorias = Campo;
      }
      break;

    case "estatus":
      let tempoEstatus=[];
      tempoEstatus = this.state.estatus.filter(ids => (ids.nombre===Nombre));
      if (tempoEstatus.length>0){
        Campo.id=tempoEstatus[0].id
        this.state.data[I].unidadEstatus = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].unidadEstatus = Campo;
      }
      break;
    case "idUnidadEstatusOperacion":
      let tempidUnidadEstatusOperacion=[];
      tempidUnidadEstatusOperacion = this.state.estatus.filter(ids => (ids.nombre===Nombre));
      if (tempidUnidadEstatusOperacion.length>0){
        Campo.id=tempidUnidadEstatusOperacion[0].id
        this.state.data[I].idUnidadEstatusOperacion = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].idUnidadEstatusOperacion = Campo;
      }
      break;      
    case "gpsEstatus":
      let tempogpsEstatus=[];
      tempogpsEstatus = this.state.gpsEstatus.filter(ids => (ids.nombre===Nombre));
      if (tempogpsEstatus.length>0){
        Campo.id=tempogpsEstatus[0].id
        this.state.data[I].gpsEstatus = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].gpsEstatus = Campo;
      }
      break;
    case "idCorredor":
      let tempoCorredor=[];
      tempoCorredor = this.state.idCorredor.filter(ids => (ids.nombre===Nombre));
      if (tempoCorredor.length>0){
        Campo.id=tempoCorredor[0].id
        this.state.data[I].idCorredor = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].idCorredor = Campo;
      }
      break;
    case "gpsHardware":
      let tempoHardware=[];
      tempoHardware = this.state.gpsHardware.filter(ids => (ids.nombre===Nombre));
      if (tempoHardware.length>0){
        Campo.id=tempoHardware[0].id
        this.state.data[I].gpsHardware = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].gpsHardware = Campo;
      }
      break;
    case "plataforma":
      let tempoplataforma=[];
      tempoplataforma = this.state.plataforma.filter(ids => (ids.nombre===Nombre));
      if (tempoplataforma.length>0){
        Campo.id=tempoplataforma[0].id
        this.state.data[I].plataforma = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].plataforma = Campo;
      }
      break;

    case "plataformaTipo":
      let tempoPlataformaTipo=[];
      tempoPlataformaTipo = this.state.plataformaTipo.filter(ids => (ids.nombre===Nombre));
      if (tempoPlataformaTipo.length>0){
        Campo.id=tempoPlataformaTipo[0].id
        this.state.data[I].plataformaTipo = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].plataformaTipo = Campo;
      }
      break;

    case "transportista":
      let tempotransportista=[];
      tempotransportista = this.state.transportista.filter(ids => (ids.nombre===Nombre));
      if (tempotransportista.length>0){
        Campo.id=tempotransportista[0].id
        this.state.data[I].transportista = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].transportista = Campo;
      }
      break;

    case "unidadMarca":
      let tempounidadMarca=[];
      tempounidadMarca = this.state.unidadMarca.filter(ids => (ids.nombre===Nombre));
      if (tempounidadMarca.length>0){
        Campo.id=tempounidadMarca[0].id
        this.state.data[I].unidadMarca = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].unidadMarca = Campo;
      }
      break;
    case "idUnidadMarca":
      let tempoidunidadMarca=[];
      tempoidunidadMarca = this.state.unidadMarca.filter(ids => (ids.nombre===Nombre));
      if (tempoidunidadMarca.length>0){
        Campo.id=tempoidunidadMarca[0].id
        this.state.data[I].idUnidadMarca = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].idUnidadMarca = Campo;
      }
      break;
    case "unidadTipo":
      let tempounidadTipo=[];
      tempounidadTipo = this.state.unidadTipo.filter(ids => (ids.nombre===Nombre));
      if (tempounidadTipo.length>0){
        Campo.id=tempounidadTipo[0].id
        this.state.data[I].unidadTipo = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].unidadTipo = Campo;
      }
      break;
    case "idUnidadTipo":
      let tempoidunidadTipo=[];
      tempoidunidadTipo = this.state.unidadTipo.filter(ids => (ids.nombre===Nombre));
      if (tempoidunidadTipo.length>0){
        Campo.id=tempoidunidadTipo[0].id
        this.state.data[I].idUnidadTipo = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].idUnidadTipo = Campo;
      }
      break;

		case "po":
      k=0;
      break;

    default:
    NotificationManager.warning("No Existe el Catalogo Definido");
  }
}

  validaArchivo() {
    switch (this.props.catalogo) {
      case 'destinos'://Inicia Case Destinos
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
/*
                case 'idPlataforma':
                      var idPlataforma = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idPlataforma", datos[num], i, idPlataforma) }
                        if (idPlataforma.id===0 || idPlataforma.id===null || idPlataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idPlataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idPlataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idPlataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        idPlataforma.nombre=datos[num];
                        { this.getCatalogoId("idPlataforma", datos[num], i, idPlataforma) }
                        if (idPlataforma.id===0 || idPlataforma.id===null || idPlataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idPlataforma !" + idPlataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idPlataforma !" + idPlataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idPlataforma !" + idPlataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                default://FuncionalidadesObject[num] = datos[num];
                break;
*/
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        //var err = terrores;
        if (terrores>0){

        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Operadores

      case 'transportistas'://Inicia Case Transportistas
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'tipo':
                      var tipo = 1;
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("tipo", datos[num], i, tipo) }
                        if (tipo.id===0 || tipo.id===null || tipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El Tipo:" + datos[num] + " No Puede ser Cero, Tiene que ser entre (1:Naviero o 2:Terrestre) Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataformaTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El Tipo:" + datos[num] + " No Puede ser Cero, Tiene que ser entre (1:Naviero o 2:Terrestre) Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        terrores=terrores+1;
                        FilaErr[i]="Error en la Fila " + (i+1);
                        arrayErr[terrores]="El Tipo:" + datos[num] + " Solo puede ser entre (1:Naviero o 2:Terrestre) Verifique en Excel la Fila: " + (i+1);
                        //NotificationManager.warning("El plataformaTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                        Errores.push( <div>{"El Tipo:" + datos[num] + " Solo puede ser entre (1:Naviero o 2:Terrestre) Verifique en Excel la Fila: " + (i+1) }</div> );
                      }
                  break;
                case 'cron':
                      var cron = 1;
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("cron", datos[num], i, cron) }
                        if (cron.id===0 || cron.id===null || cron.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1);
                          NotificationManager.warning("El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        terrores=terrores+1;
                        FilaErr[i]="Error en la Fila " + (i+1);
                        arrayErr[terrores]="El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1);
                        NotificationManager.warning("El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1) );
                        Errores.push( <div>{"El cron:" + datos[num] + " No Puede ser Vacío, Verifique en Excel la Fila: " + (i+1) }</div> );
                      }
                  break;
                default:
                  //corredores[num] = datos[num];
                break;
              }

            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case corredores
      case 'corredores'://Inicia Case Corredores
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'plataformaTipo':
                      var plataformaTipo = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("plataformaTipo", datos[num], i, plataformaTipo) }
                        if (plataformaTipo.id===0 || plataformaTipo.id===null || plataformaTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El plataformaTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataformaTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El plataformaTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        plataformaTipo.nombre=datos[num];
                        { this.getCatalogoId("plataformaTipo", datos[num], i, plataformaTipo) }
                        if (plataformaTipo.id===0 || plataformaTipo.id===null || plataformaTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El plataformaTipo !" + plataformaTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataformaTipo !" + plataformaTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El plataformaTipo !" + plataformaTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;

                default:
                  //corredores[num] = datos[num];
                break;
              }

            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case corredores

      case 'funcionalidades'://Inicia Case Funcionalidades
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'bloque':
                      var bloque = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("bloque", datos[num], i, bloque) }
                        if (bloque.id===0 || bloque.id===null || bloque.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        bloque.nombre=datos[num];
                        { this.getCatalogoId("bloque", datos[num], i, bloque) }
                        if (bloque.id===0 || bloque.id===null || bloque.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                default://FuncionalidadesObject[num] = datos[num];
                break;
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        //var err = terrores;
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Funcionalidades

      case 'gps'://Inicia Case GPS
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'idCorredor':
                      var idCorredor = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idCorredor", datos[num], i, idCorredor) }
                        if (this.state.data[i].idCorredor.id===0 || this.state.data[i].idCorredor.id===null || this.state.data[i].idCorredor.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idCorredor !" + this.state.data[i].idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idCorredor !" + idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idCorredor !" + this.state.data[i].idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }

                      }else{
                        idCorredor.nombre=datos[num];
                        { this.getCatalogoId("idCorredor", datos[num], i, idCorredor) }
                        if (this.state.data[i].idCorredor.id===0 || this.state.data[i].idCorredor.id===null || this.state.data[i].idCorredor.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idCorredor id:" + this.state.data[i].idCorredor.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idCorredor id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idCorredor id:" + this.state.data[i].idCorredor.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }

                      }
                  break;
                case 'gpsEstatus':
                      var gpsEstatus = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsEstatus", datos[num], i, gpsEstatus) }
                        if (this.state.data[i].gpsEstatus.id===0 || this.state.data[i].gpsEstatus.id===null || this.state.data[i].gpsEstatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsEstatus id:" + this.state.data[i].gpsEstatus.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsEstatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsEstatus id:" + this.state.data[i].gpsEstatus.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        gpsEstatus.nombre=datos[num];
                        { this.getCatalogoId("gpsEstatus", datos[num], i, gpsEstatus) }
                        if (this.state.data[i].gpsEstatus.id===0 || this.state.data[i].gpsEstatus.id===null || this.state.data[i].gpsEstatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsEstatus !" + this.state.data[i].gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsEstatus !" + gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsEstatus !" + this.state.data[i].gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'gpsHardware':
                      var gpsHardware = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (this.state.data[i].gpsHardware.id===0 || this.state.data[i].gpsHardware.id===null || this.state.data[i].gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware id:" + this.state.data[i].gpsHardware.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware id:" + this.state.data[i].gpsHardware.id + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        gpsHardware.nombre=datos[num];
                        { this.getCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (this.state.data[i].gpsHardware.id===0 || this.state.data[i].gpsHardware.id===null || this.state.data[i].gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware !" + this.state.data[i].gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware !" + this.state.data[i].gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                default://gpsObject[num] = datos[num];
                  break;

              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case GPS

      case 'gpsFijos'://Inicia Case gpsFijos
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'gpsHardware':
                      var gpsHardware = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        gpsHardware.nombre=datos[num];
                        { this.getCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'plataforma':
                      var plataforma = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        plataforma.nombre=datos[num];
                        { this.getCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;

                default:
                  //FuncionalidadesObject[num] = datos[num];
                break;
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case gpsFijos

      case 'privilegios'://Inicia Case Privilegios
        var i = 0;
        this.state.data.forEach(datos =>{
          if ( (datos["categorias"]==="")||(datos["categorias"]===undefined)||(datos["categorias"]===null) ) {
          }else{
            var categorias = { "id":"", "nombre":"", "icono":"","menuBloque":"" }
            if (Number.isInteger(datos["categorias"])){
              { this.valCatalogoId("categoria", datos["categorias"], i, categorias) }
              if (categorias.id===0 || categorias.id===null || categorias.id===""){
                terrores=terrores+1;
                FilaErr[i]="Error en la Fila " + (i+1);
                arrayErr[terrores]="La Categoria id:" + datos["categorias"] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                Errores.push( <div>{"La Categoria id:" + datos["categorias"] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
              }
            }else{
                categorias.nombre=datos["categorias"];
                { this.getCatalogoId("categoria", datos["categorias"], i, categorias) }
                if (categorias.id===0 || categorias.id===null || categorias.id===""){
                terrores=terrores+1;
                FilaErr[i]="Error en la Fila " + (i+1);
                arrayErr[terrores]="La Categoria !" + categorias.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                //NotificationManager.warning("El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                Errores.push( <div>{"La Categoria !" + categorias.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
              }
            }
          }
          i=i+1;
        })
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Privilegios

      case 'unidadTerNav'://Inicia Case Unidades
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'idUnidadTipo':
                      var unidadTipo = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idUnidadTipo", datos[num], i, unidadTipo) }
                        if (unidadTipo.id===0 || unidadTipo.id===null || unidadTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        unidadTipo.nombre=datos[num];
                        { this.getCatalogoId("idUnidadTipo", datos[num], i, unidadTipo) }
                        if (unidadTipo.id===0 || unidadTipo.id===null || unidadTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'idUnidadEstatusOperacion':
                      var estatus = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idUnidadEstatusOperacion", datos[num], i, estatus) }
                        if (estatus.id===null || estatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        estatus.nombre=datos[num];
                        { this.getCatalogoId("idUnidadEstatusOperacion", datos[num], i, estatus) }
                        if (estatus.id===null || estatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'idUnidadMarca':
                  var unidadMarca = { "id":"", "nombre":"" }
                  if (Number.isInteger(datos[num])){
                    { this.valCatalogoId("idUnidadMarca", datos[num], i, unidadMarca) }
                    if (unidadMarca.id===null || unidadMarca.id===""){
                      terrores=terrores+1;
                      FilaErr[i]="Error en la Fila " + (i+1);
                      arrayErr[terrores]="El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                      //NotificationManager.warning("El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                      Errores.push( <div>{"El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                    }
                  }else{
                    unidadMarca.nombre=datos[num];
                    { this.getCatalogoId("idUnidadMarca", datos[num], i, unidadMarca) }
                    if (unidadMarca.id===null || unidadMarca.id===""){
                      terrores=terrores+1;
                      FilaErr[i]="Error en la Fila " + (i+1);
                      arrayErr[terrores]="El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                      //NotificationManager.warning("El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                      Errores.push( <div>{"El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                    }
                  }
                break;
                default:
                  //Nada
                break;
              }
            }
          );
          i=i+1;
        });
        //  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Unidades
        case 'unidades'://Inicia Case Unidades
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
                case 'plataforma':
                      var plataforma = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        plataforma.nombre=datos[num];
                        { this.getCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'unidadTipo':
                      var unidadTipo = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("unidadTipo", datos[num], i, unidadTipo) }
                        if (unidadTipo.id===0 || unidadTipo.id===null || unidadTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El unidadTipo id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        unidadTipo.nombre=datos[num];
                        { this.getCatalogoId("unidadTipo", datos[num], i, unidadTipo) }
                        if (unidadTipo.id===0 || unidadTipo.id===null || unidadTipo.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El unidadTipo !" + unidadTipo.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'transportista':
                      var transportista = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("transportista", datos[num], i, transportista) }
                        if (transportista.id===0 || transportista.id===null || transportista.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La transportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El transportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La transportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        transportista.nombre=datos[num];
                        { this.getCatalogoId("transportista", datos[num], i, transportista) }
                        if (transportista.id===0 || transportista.id===null || transportista.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La transportista !" + transportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El transportista !" + transportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La transportista !" + transportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'unidadEstatus':
                      var estatus = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("estatus", datos[num], i, estatus) }
                        if (estatus.id===null || estatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La estatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        estatus.nombre=datos[num];
                        { this.getCatalogoId("estatus", datos[num], i, estatus) }
                        if (estatus.id===null || estatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La estatus !" + estatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'unidadMarca':
                  var unidadMarca = { "id":"", "nombre":"" }
                  if (Number.isInteger(datos[num])){
                    { this.valCatalogoId("unidadMarca", datos[num], i, unidadMarca) }
                    if (unidadMarca.id===null || unidadMarca.id===""){
                      terrores=terrores+1;
                      FilaErr[i]="Error en la Fila " + (i+1);
                      arrayErr[terrores]="El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                      //NotificationManager.warning("El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                      Errores.push( <div>{"El unidadMarca id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                    }
                  }else{
                    unidadMarca.nombre=datos[num];
                    { this.getCatalogoId("unidadMarca", datos[num], i, unidadMarca) }
                    if (unidadMarca.id===null || unidadMarca.id===""){
                      terrores=terrores+1;
                      FilaErr[i]="Error en la Fila " + (i+1);
                      arrayErr[terrores]="El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                      //NotificationManager.warning("El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                      Errores.push( <div>{"El unidadMarca !" + unidadMarca.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                    }
                  }
                break;
                default:
                  //Nada
                break;
              }
            }
          );
          i=i+1;
        });
        //  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Unidades
      case 'operadores'://Inicia Case Operadores
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
              switch (num) {
/*
                case 'idTransportista':
                      var idTransportista = { "id":"", "nombre":"" }
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idTransportista", datos[num], i, idTransportista) }
                        if (idTransportista.id===0 || idTransportista.id===null || idTransportista.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idTransportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idTransportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idTransportista id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        idTransportista.nombre=datos[num];
                        { this.getCatalogoId("idTransportista", datos[num], i, idTransportista) }
                        if (idTransportista.id===0 || idTransportista.id===null || idTransportista.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idTransportista !" + idTransportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idTransportista !" + idTransportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idTransportista !" + idTransportista.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                default://FuncionalidadesObject[num] = datos[num];
                break;
*/
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos

        //var err = terrores;
        if (terrores>0){
					//arrayErr[terrores+1]="Post Deshabilidado por " + err + " Errores";
          //NotificationManager.warning("Post Deshabilidado por " + err + " Errores", "Alerta");
        }else{
					//terrores=terrores+10;
					//Errores.push( <div>{"Archivo Validado!"}</div> );
          //this.state.data.map(datos =>{
            //this.props.crud("post", datos);
						//arrayErr[terrores+1]="Post en Catalogo: " + this.props.catalogo;
          NotificationManager.info("Archivo Correcto!", "Alerta");
          //});
        }
        break;//Fin Case Operadores

      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para este Catalogo";
        break;
    }
    this.setState({validado:true });
  }

  cargarDatos = (carga, errores) => {
    let w=0;
    let errResponse=[];
    for (let i = 0; i < carga.length; i++) {
      let nDe = 0;
      let nEn = 0;
      if (errores==0){
        Crud_Catalogos(this.props.catalogo, this.catalogo, "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          nEn = i+1;
          nDe = carga.length;
          if (returnVal) {
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo," Id:"+returnVal.id + " i:"+nEn + " de "+nDe + " datos Masivos ");
            this.usuariosLogObject.idBitacoraMonitoreo = 1;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = returnVal.id;
            this.usuariosLogObject.idPlataformaTipo = 1;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Nuevo Movimiento Masivo Naviero Post id=" + returnVal.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], this.props.catalogo);
            NotificationManager.information("Carga Masiva en Catalogo: " + this.props.catalogo,"Carga con "+nDe + " Registros Finalizada! ");
            myModal("none");
          }
        }).catch(err =>{
          if (err>0){
            nEn = i+1;
            nDe = carga.length;
            NotificationManager.error("Carga Masiva en Catalogo: " + this.props.catalogo, "Error en la Fila: " + nEn + " de " + nDe + " Favor de Verificar Sus Datos!");
          }
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], this.props.catalogo);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
          errResponse.push({datos: carga[i]});
          if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
        });
      }else{
        if ( (FilaErr[i]!="") || (FilaErr[i]!=null) || (FilaErr[i]!=undefined) ){
        }else{
          Crud_Catalogos(this.props.catalogo, ""           , "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          if (returnVal) {
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo,"(TErr) Id:"+returnVal.id + " i:"+nEn + " de "+nDe + " datos Masivos ");
            this.usuariosLogObject.idBitacoraMonitoreo = 1;
            this.usuariosLogObject.idUsuario = user.id;
            this.usuariosLogObject.idMovimiento = returnVal.id;
            this.usuariosLogObject.idPlataformaTipo = 1;
            this.usuariosLogObject.accionFecha = myFormato(new Date());
            this.usuariosLogObject.descripcion = "Nuevo Movimiento Masivo Naviero (TErr) Post id=" + returnVal.id;
            Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
          }
          if(i === carga.length-1){
            this.props.crud("get", [], "", [], this.props.catalogo);
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
          }).catch(err =>{
            if (err){
              nEn = i+1;
              nDe = carga.length;
              NotificationManager.danger("Carga Masiva en Catalogo: " + this.props.catalogo, "Error en la Fila: " + nEn + " de " + nDe + " Favor de Verificar Sus Datos!");
            }
            if(i === carga.length-1){
              this.props.crud("get", [], "", [], this.props.catalogo);
              NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "(TErr) Finalizada! ");
              myModal("none");
          }
            errResponse.push({datos: carga[i]});
            if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
          });
        }
      }
    }
	}

  onImportExcel = file => {
		//this.state.data= [];
    const { files } = file.target;

    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            //this.setState({catalogo: sheet });//this.props.data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          }
        }
        terrores=0;
        { this.validaArchivo() }
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModal("block");
}

render() {
    return (
      <div className="excelT" id="excelT">
        <p className="etiquetaT" id="etiquetaT"><a href="#miModalT">IMP</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />

				<div id="xlsModalT" className="xlsModalT">
					<div className="modal-contenidoT">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-200">
						<div className="scroll-col col-sm-12">
						{
              this.state.validado===false ?
              <div>
                <br></br>Verificando Errores ..!!
                <Dimmer active={true} loader></Dimmer> por favor Espere!
              </div>
              :
              terrores==0?
              <div>Archivo Correcto</div>
              :
              Errores
						}
						</div>

					</div>

					<br></br>
					<center>
						<button className="azul" onClick={()=>myModal('none')} >Cerrar y Corregir Archivo de Excel</button>
						<spam>   </spam>
						<button className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar Datos Correctos</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}

export default Importar;
