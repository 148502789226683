import React, { Component } from "react";
import { Button, Form, Icon, Card, Dimmer, List } from "tabler-react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import Select from "react-select";
/** alertas */
import { NotificationManager } from "react-notifications";
/**
 * usuario
 */
var user = JSON.parse(sessionStorage.getItem("usuario"));
var perfil = JSON.parse(sessionStorage.getItem("perfil"));
let incidencia = null;
let coordenadas;
let alertaIncidencia = {
  id: null,
  idTransportista: -1,
  idIncidencia: -1,
  idAlertaTipo: -1,
  operadorNombre: "",
  operadorMedio: "",
  unidadPlaca: "",
  responsable: "",
  fechaRegistro: null,
  problema: "",
  solucion: "",
  observaciones: "",
};
let alerta = {
  idUnidad: null, // obj
  idAlertaTipo: null, //obj
  latitud: 0.0,
  longitud: 0.0,
  triggerValue: "",
  triggerName: "", // igual al tipo de alerta
  mensaje: "",
  fechaCreacion: null,
  causa: "", // igual al tipo de alerta
  fechaRegistro: null,
  atendida: 1,
  atiendeUsuario: user ? user.persona : null,
  atiendeFecha: null,
  placa: "",
  detalle: "",
};
class PanelUsRuta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transportista: { value: 0, label: "", data: null },
      unidades: [],
      unidad: { value: 0, label: "", data: null },
      currentAlerta: {},
      incidencias: [],
      incidencia: { label: "Selecciona una opción", value: 0, data: [] },
      operador: "",
      medioCom: "",
      problema: "",
      solucion: "",
      observaciones: "",
      responsable: "",
      destinatarios: [],
      loading: false,
      latitud: 0.0,
      longitud: 0.0,
    };
    this.incidencias = [];
    this.privilegio = [];
  }
  componentWillMount = () => {};
  componentDidMount = () => {};
  componentDidUpdate = () => {
    if (this.props.currentAlerta !== this.state.currentAlerta) {
      // && this.props.currentAlerta.id
      if (this.props.currentAlerta.atendida) {
        this.setState({ loading: true });
      }
      this.reset();
      alertaIncidencia.idAlertaTipo = this.props.currentAlerta.idAlertaTipo
        ? this.props.currentAlerta.idAlertaTipo.id
        : null;
      alertaIncidencia.unidadPlaca = this.props.currentAlerta.placa
        ? this.props.currentAlerta.placa
        : "";
      alertaIncidencia.id = this.props.currentAlerta.id;
      alertaIncidencia.responsable = this.state.responsable;
      this.setState({
        incidencia: { label: "Selecciona una opción", value: 0, data: [] },
      });
      if (this.props.currentAlerta.atendida) {
        this.getfuntion(
          "get",
          "",
          "",
          "terrestresAlertasSinRutaIncidencia/" + this.props.currentAlerta.id,
          "unidadIncidencia"
        );
      }
      this.setState({ currentAlerta: this.props.currentAlerta });
    }
    if (
      this.props.incidencias !== this.incidencias &&
      this.props.alertasTipos.length
    ) {
      this.incidencias = this.props.incidencias;
      let incidenciasTem = [];
      this.incidencias.map((inc, index) => {
        if (inc.incidenciaTipo.id === 11) {
          incidenciasTem.push({
            value: inc.id,
            label: inc.nombre,
            data: inc,
            alerta: this.getAlerta(inc.nombre),
          });
        }
      });
      this.setState({ incidencias: incidenciasTem });
    }
  };
  reset = () => {
    this.setState({
      operador: "",
      medioCom: "",
      problema: "",
      solucion: "",
      observaciones: "",
      transportista: { value: 0, label: "", data: null },
      unidad: { value: 0, label: "", data: null },
      incidencia: { label: "Selecciona una opción", value: 0, data: [] },
    });
  };
  getAlerta = (inc) => {
    for (let a of this.props.alertasTipos) {
      if (a.nombre === inc) return a;
    }
    return null;
  };
  changeOperador = (e) => {
    this.setState({ operador: e.target.value });
    alertaIncidencia.operadorNombre = e.target.value;
  };
  changeMedioCom = (e) => {
    this.setState({ medioCom: e.target.value });
    alertaIncidencia.operadorMedio = e.target.value;
  };
  changeProblema = (e) => {
    this.setState({ problema: e.target.value });
    alertaIncidencia.problema = e.target.value;
  };
  changeSolucion = (e) => {
    this.setState({ solucion: e.target.value });
    alertaIncidencia.solucion = e.target.value;
  };
  changeObservaciones = (e) => {
    this.setState({ observaciones: e.target.value });
    alertaIncidencia.observaciones = e.target.value;
  };
  save = () => {
    if (this.props.manual) {
      this.getfuntion(
        "post",
        alerta,
        "",
        "terrestresAlertasSinRuta",
        "terrestresAlertasSinRuta"
      );
    } else {
      this.getfuntion(
        "post",
        alertaIncidencia,
        "",
        "terrestresAlertasSinRutaIncidencia",
        "unidadIncidencia"
      );
    }
  };
  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          this.privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
      }
    }
  };
  //stateVar: es el nombre de la variable del State que se va actualizar.
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "unidadIncidencia") {
                setTimeout(() => {
                  this.setState({ loading: false });
                }, 150);
                if (returnVal.id) {
                  this.setState({ operador: returnVal.operadorNombre });
                  this.setState({ medioCom: returnVal.operadorMedio });
                  this.setState({ problema: returnVal.problema });
                  this.setState({ solucion: returnVal.solucion });
                  this.setState({ observaciones: returnVal.observaciones });
                  this.setState({ responsable: returnVal.responsable });
                  let transportistaTemp = this.props.transportistas.filter(
                    (t) => t.value === returnVal.idTransportista
                  );
                  if (transportistaTemp.length === 1)
                    this.setState({ transportista: transportistaTemp[0] });
                  this.state.incidencias.map((inc, index) => {
                    if (inc.value == returnVal.idIncidencia) {
                      this.setState({ incidencia: inc });
                    }
                  });
                  if (this.props.currentAlerta.idUnidad) {
                    incidencia =
                      this.state.incidencias.filter(
                        (i) => i.value === returnVal.idIncidencia
                      ).length === 1
                        ? this.state.incidencias.filter(
                            (i) => i.value === returnVal.idIncidencia
                          )[0]
                        : null;
                    if (incidencia)
                      this.getfuntion(
                        "get",
                        "",
                        "",
                        "mail/arroba_sin_Ruta_contactos/plt/" +
                          1 +
                          "/trans/" +
                          returnVal.idTransportista +
                          "/etiq/" +
                          incidencia.data.idEtiqueta,
                        "contactos"
                      );
                  }
                }
              }
              if (stateVar === "unidades" && returnVal) {
                let uTemp = [];
                returnVal.map((u) =>
                  uTemp.push({ value: u.id, label: u.nombre, data: u })
                );
                this.setState({ unidades: uTemp });
              }
              if (stateVar === "contactos" && returnVal) {
                let cTemp = [];
                returnVal.map(
                  (c, index) =>
                    (cTemp =
                      cTemp +
                      (cTemp.length ? ", " : "") +
                      c.nombre +
                      "<" +
                      c.correo +
                      ">")
                );
                this.setState({ destinatarios: cTemp });
              }
              if (stateVar === "arroba" && returnVal) {
                NotificationManager.info(returnVal, "Incidencia nueva");
              }
            }
            break;
          case "post":
            {
              if (stateVar === "terrestresAlertasSinRuta" && returnVal.id) {
                if (this.props.manual) {
                  alertaIncidencia.id = returnVal.id;
                  this.getfuntion(
                    "post",
                    alertaIncidencia,
                    "",
                    "terrestresAlertasSinRutaIncidencia",
                    "unidadIncidencia"
                  );
                }
              }
              if (stateVar === "unidadIncidencia" && returnVal.id) {
                if (!this.props.manual) {
                  let cAlerta = this.state.currentAlerta;
                  cAlerta.atiendeUsuario = user.persona;
                  cAlerta.atendida = 1;
                  this.getfuntion(
                    "put",
                    cAlerta,
                    "",
                    "terrestresAlertasSinRuta",
                    "currentAlerta"
                  );
                } else {
                  this.props.setManual();
                }
                NotificationManager.info(
                  "Atendida por: " +
                    (user.persona.nombre +
                      " " +
                      user.persona.aPaterno +
                      " " +
                      user.persona.aMaterno),
                  "Se crea incidencia (" + returnVal.unidadPlaca + ")"
                );
              }
            }
            break;
          case "put":
            {
              if (stateVar === "currentAlerta" && returnVal.id) {
                this.setState({ currentAlerta: returnVal });
                let alertasTem = this.props.getAlertas();
                for (let item of alertasTem) {
                  if (item.id == returnVal.id) {
                    item = returnVal;
                    this.props.setAlertas(alertasTem);
                    NotificationManager.info(
                      "Atendida por: " +
                        (user.persona.nombre +
                          " " +
                          user.persona.aPaterno +
                          " " +
                          user.persona.aMaterno),
                      "Alerta atendida (" + returnVal.placa + ")"
                    );
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        NotificationManager.error("Ha ocurrido un error", "Tome precauciones");
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log(err);
        }
      });
  };
  render() {
    return (
      <div className="m-3">
        <Card>
          <Card.Header>
            <Card.Title>Panel de informatión</Card.Title>
            <Card.Options>
              <span
                className="float-right btn"
                data-toggle="tooltip"
                data-placement="top"
                title="Alerta Manual"
                onClick={() => {
                  this.props.setManual();
                  this.props.setCurrentAlerta({});
                }}
              >
                <Icon
                  name={this.props.manual ? "x" : "plus"}
                  className="h2 text-dark"
                />
              </span>
            </Card.Options>
          </Card.Header>
          <Card.Body>
            <Dimmer active={this.state.loading} loader>
              {!this.props.manual && this.state.currentAlerta.id ? (
                <List.Group>
                  <List.GroupItem action icon="credit-card">
                    ID:
                    <strong>
                      {" "}
                      {this.state.currentAlerta
                        ? this.state.currentAlerta.id
                        : null}
                    </strong>
                  </List.GroupItem>
                  {this.state.currentAlerta.atendida &&
                  this.state.currentAlerta.id ? (
                    <List.GroupItem action icon="truck">
                      LINEA TRANSPORTISTA:
                      <strong>
                        {" "}
                        {this.state.transportista.value
                          ? this.state.transportista.label
                          : ""}
                      </strong>
                    </List.GroupItem>
                  ) : null}
                  <List.GroupItem action icon="layers">
                    PLACAS/ECO:
                    <strong>
                      {" "}
                      {this.state.currentAlerta
                        ? this.state.currentAlerta.placa
                        : null}
                    </strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="hard-drive">
                    UNIDAD TIPO:
                    <strong>
                      {" "}
                      {this.state.currentAlerta
                        ? this.state.currentAlerta.idUnidad
                          ? this.state.currentAlerta.idUnidad.nombre
                          : null
                        : null}
                    </strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="calendar">
                    <span>FECHA:</span>
                    <strong>
                      {" "}
                      {this.state.currentAlerta
                        ? this.state.currentAlerta.fechaCreacion
                        : null}
                    </strong>
                  </List.GroupItem>
                </List.Group>
              ) : null}
              {this.state.currentAlerta.atendida &&
              this.state.currentAlerta.id ? (
                <List.Group>
                  <List.GroupItem action icon="user-check">
                    RESPONSABLE:
                    <strong> {this.state.responsable}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="user">
                    NOMBRE DEL OPERADOR:
                    <strong> {this.state.operador}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="smartphone">
                    MEDIO DE COMUNICACIÓN:
                    <strong> {this.state.medioCom}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="activity">
                    TIPO DE INCIDENTE:
                    <strong> {this.state.incidencia.label}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="alert-triangle">
                    PROBLEMA:
                    <strong> {this.state.problema}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="award">
                    SOLUCIÓN:
                    <strong> {this.state.solucion}</strong>
                  </List.GroupItem>
                  <List.GroupItem action icon="file-text">
                    OBSERVACIONES:
                    <strong> {this.state.observaciones}</strong>
                  </List.GroupItem>
                </List.Group>
              ) : null}
              {(!this.state.currentAlerta.atendida &&
                this.state.currentAlerta.id) ||
              this.props.manual ? (
                <List.GroupItem action icon="truck">
                  LINEA TRANSPORTISTA:
                  <span>
                    <Select
                      value={this.state.transportista}
                      onChange={(e) => {
                        this.setState({ transportista: e });
                        alertaIncidencia.idTransportista = e.value;
                        if (this.props.manual) {
                          this.getfuntion(
                            "get",
                            "",
                            "",
                            "unidades/transportista/" + e.value,
                            "unidades"
                          );
                        }
                      }}
                      options={this.props.transportistas}
                    />
                  </span>
                </List.GroupItem>
              ) : null}
              {this.props.manual ? (
                <List.Group>
                  <List.GroupItem action icon="truck">
                    PLACAS/ECO:
                    <span>
                      <Select
                        value={this.state.unidad}
                        onChange={(e) => {
                          this.setState({ unidad: e });
                          alertaIncidencia.unidadPlaca = e.data.placa;
                          alerta.idUnidad = e.data;
                        }}
                        options={this.state.unidades}
                      />
                      {this.state.unidad.data ? (
                        <p>placa: {this.state.unidad.data.placa}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </List.GroupItem>
                </List.Group>
              ) : null}
              {(!this.state.currentAlerta.atendida &&
                this.state.currentAlerta.id) ||
              this.props.manual ? (
                <List.Group>
                  <List.GroupItem action icon="user-check">
                    RESPONSABLE:
                    <span>
                      <Form.Input
                        name="responsable"
                        type="text"
                        value={this.state.responsable}
                        onChange={(e) => {
                          this.setState({ responsable: e.target.value });
                          alertaIncidencia.responsable = e.target.value;
                        }}
                        placeholder="ingresa.."
                      />
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="user">
                    <span>NOMBRE DEL OPERADOR:</span>
                    <span>
                      <Form.Input
                        name="operador"
                        type="text"
                        value={this.state.operador}
                        onChange={this.changeOperador}
                        placeholder="ingresa.."
                      />
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="smartphone">
                    <span>MEDIO DE COMUNICACIÓN:</span>
                    <span>
                      <Form.Input
                        name="medioCom"
                        type="text"
                        value={this.state.medioCom}
                        onChange={this.changeMedioCom}
                        placeholder="ingresa.."
                      />
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="activity">
                    <span>TIPO DE INCIDENTE:</span>
                    <span>
                      <Select
                        value={this.state.incidencia}
                        onChange={(e) => {
                          this.setState({ incidencia: e });
                          alertaIncidencia.idIncidencia = e.value;
                          alertaIncidencia.idAlertaTipo = e.alerta
                            ? e.alerta.id
                            : null;
                          alerta.idAlertaTipo = e.alerta;
                          alerta.triggerName = e.alerta ? e.alerta.nombre : "";
                          alerta.causa = e.alerta ? e.alerta.nombre : "";
                          alerta.placa = alerta.idUnidad
                            ? alerta.idUnidad.placa
                            : "";
                        }}
                        options={this.state.incidencias}
                      />
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="map-pin">
                    <span>Coordenadas:</span>
                    <span>
                      <Form.Input
                        name="coordenadas"
                        onChange={(e) => {
                          coordenadas = e.target.value.split(",");
                          if (coordenadas.length == 2) {
                            this.setState({ latitud: coordenadas[0] });
                            alerta.latitud = coordenadas[0];
                            this.setState({ longitud: coordenadas[1].trim() });
                            alerta.longitud = coordenadas[1].trim();
                          }
                        }}
                        placeholder="ingresa.."
                      />
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="alert-triangle">
                    <span>PROBLEMA:</span>
                    <span>
                      <Form.Textarea
                        name="showHtml"
                        placeholder="Problema.."
                        rows={2}
                        onChange={this.changeProblema}
                      >
                        {this.state.problema}
                      </Form.Textarea>
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="award">
                    <span>SOLUCIÓN:</span>
                    <span>
                      <Form.Textarea
                        name="showHtml"
                        placeholder="Solución.."
                        rows={2}
                        onChange={this.changeSolucion}
                      >
                        {this.state.solucion}
                      </Form.Textarea>
                    </span>
                  </List.GroupItem>
                  <List.GroupItem action icon="file-text">
                    <span>OBSERVACIONES:</span>
                    <span>
                      <Form.Textarea
                        name="showHtml"
                        placeholder="Obserbaciones.."
                        rows={2}
                        onChange={this.changeObservaciones}
                      >
                        {this.state.observaciones}
                      </Form.Textarea>
                    </span>
                  </List.GroupItem>
                </List.Group>
              ) : null}
            </Dimmer>
          </Card.Body>
          <Card.Footer>
            {(this.state.currentAlerta && this.state.currentAlerta.id) ||
            this.props.manual ? (
              <p
                className="float-right"
                data-toggle="tooltip"
                data-placement="top"
                title="Guardar cambios"
              >
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => this.save()}
                >
                  <Icon name="save" />
                </Button>
              </p>
            ) : null}
            {this.state.currentAlerta.atendida &&
            this.state.currentAlerta.id ? (
              <span
                className="badge"
                onClick={() =>
                  incidencia
                    ? this.getfuntion(
                        "get",
                        "",
                        "",
                        "mail/arroba_sin_Ruta/" +
                          this.props.currentAlerta.id +
                          "/" +
                          incidencia.data.idEtiqueta,
                        "arroba"
                      )
                    : null
                }
                data-toggle="tooltip"
                data-placement="top"
                title={"Enviar notificación: " + this.state.destinatarios}
              >
                <label style={{ color: "black", "font-size": "200%" }}>
                  <Icon name="at-sign" />
                </label>
              </span>
            ) : null}
          </Card.Footer>
        </Card>
      </div>
    );
  }
}
export default PanelUsRuta;
