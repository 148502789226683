import React, { useEffect, useState } from 'react';
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import { TablePageable } from '../../Helpers';
import { Button, Form, Col, Grid, Icon } from 'tabler-react';

const CotizacionMovimientoTransportistas = ({idPlataforma, idMovimiento}) => {    
    //console.log(idPlataforma);
    //console.log(idMovimiento);

    const [ transportistasPlataformaSubasta, setTransportistasPlataformaSubasta ] = useState([]);
    const [ movimientoSubastaTransportistaCotizacion, setMovimientoSubastaTransportistaCotizacion ] = useState([]);
    const [ movimientoSubasta, setMovimientoSubasta ] = useState({});

    useEffect(() => {
        crud("get", "", "", "transportistas/plataforma/" + idPlataforma, "transportistasPlataformaSubasta"); 
        crud("get", "", "", "movimientosSubasta/idMovimiento/" + idMovimiento, "movimientoSubasta");
        crud("get", "", "", "movimientoSubastaTransportistasCotizacion/movimiento/" + idMovimiento, "movimientoSubastaTransportistasCotizacion");
    }, []);

     
    const agregarEliminar = (id, bandera) => {
        if(bandera) {
            const nuevoMovimientoSubastaTransportistaCotizacion = {
                idMovimiento: idMovimiento,
                idTransportista: id
            }
            setMovimientoSubastaTransportistaCotizacion([...movimientoSubastaTransportistaCotizacion, nuevoMovimientoSubastaTransportistaCotizacion]);
        } else {
            let newMovimientoSubastaTransportistaCotizacion = [...movimientoSubastaTransportistaCotizacion];
            for(let i=0; i < newMovimientoSubastaTransportistaCotizacion.length; i++){
                if(newMovimientoSubastaTransportistaCotizacion[i].idTransportista === id ){
                    newMovimientoSubastaTransportistaCotizacion.splice(i, 1);
                    setMovimientoSubastaTransportistaCotizacion(newMovimientoSubastaTransportistaCotizacion);
                    return;
                }
            }
        }       
    }

    const cambioEstatusSubasta = () => {
        if( movimientoSubasta.idMovimiento){
            let newMovimientoSubasta = {...movimientoSubasta};
            if(movimientoSubastaTransportistaCotizacion.length > 0){
                newMovimientoSubasta.idEstatusSubasta = 2;     
                crud("post", movimientoSubastaTransportistaCotizacion, "", "movimientoSubastaTransportistasCotizacion", "movimientoSubastaTransportistasCotizacion");           
            } else {
                newMovimientoSubasta.idEstatusSubasta = 1;  
                crud("delete", "", idMovimiento, "movimientoSubastaTransportistasCotizacion/movimiento", "movimientoSubastaTransportistasCotizacion");
            }
            setMovimientoSubasta( newMovimientoSubasta);            
            crud("put", newMovimientoSubasta, "", "movimientosSubasta", "movimientoSubasta");
            
        }
    }

    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                    if(stateVar === "transportistasPlataformaSubasta" && returnVal) {
                        setTransportistasPlataformaSubasta(returnVal);     
                    }
                    if(stateVar === "movimientoSubasta" && returnVal) {
                        setMovimientoSubasta(returnVal);     
                    }
                    if(stateVar === "movimientoSubastaTransportistasCotizacion" && returnVal) {
                        setMovimientoSubastaTransportistaCotizacion(returnVal);     
                    }
                break;   
                case "put":                    
                    if(stateVar === "movimientoSubasta") {
                        
                    }
                break; 
                case "post":                    
                    if(stateVar === "movimientoSubastaTransportistasCotizacion") {
                        
                    }
                break;  
                case "delete":                    
                    if(stateVar === "movimientoSubastaTransportistasCotizacion") {
                        
                    }
                break;                   
            default:
                break;
            }
        }).catch(err => { 
            if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
        });
    }

    return (
        <>
            <div className='bg-light'>
                {/*console.log(movimientoSubastaTransportistaCotizacion)*/}
            <TablePageable
                title={<></>}
                lista={transportistasPlataformaSubasta}
                columnas={[                  
                  { columna: "id", label: "ID" },
                  { columna: "nombre", label: "NOMBRE TRANSPORTISTA" },
                  { columna: "nombreCorto", label: "NOMBRE CORTO TRANPORTISTA" },                             
                  { columna: "cotizar", label: "COTIZAR",
                    form: (e) =>  <Form.Switch
                                    name="cotizar"
                                    label=""
                                    value={true}
                                    checked={movimientoSubastaTransportistaCotizacion.filter(t => t.idTransportista === e.id).length === 1}
                                    onChange={(event) => agregarEliminar(e.id, event.target.checked)}
                                /> 
                  },
                ]}
                id={"id"}                        
                paginacion={true}
                elementosPorPagina={20}
            />
            </div>  
            <div className="float-right">
            <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                //disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={cambioEstatusSubasta}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </div>
        </>
    )
};

export default CotizacionMovimientoTransportistas;
