import React from 'react';
import QlikComponent from '../../components/QlikComponent/QlikComponent';
import SiteWrapper from '../../SiteWrapper.react';

const gpsConfig = {
  kid: '8bd8d1f2-260f-49d2-97e8-7089553fe8e7',
  issuer: 'xm4nqy9w30zjgpw.us.qlikcloud.com',
  baseUrl: 'https://xm4nqy9w30zjgpw.us.qlikcloud.com',
  //Se mueve por usuario
  sub: '668c1cf995ceadbee356e0c5',
  //Se mueve por usuario
  name: 'Skyangel Graficos',
  //Se mueve por usuario
  email: 'skyangelgraficos@gmail.com',
  //Se mueve por sitio web
  webIntegrationId: '4qTIDC2HXQMt4ggVPr9Ic46zMQLAFBOD',
  //Se mueve reporte
  iframeSrc:
    'https://xm4nqy9w30zjgpw.us.qlikcloud.com/single/?appid=b304bb88-429a-4460-ad7c-26a7213ef671&sheet=rTdDPjN&theme=horizon&opt=ctxmenu,currsel',
};

const MapaGpsQlikPage = () => (
  <SiteWrapper>
    <QlikComponent qlikConfig={gpsConfig} title="Mapa GPS" />
  </SiteWrapper>
);

export default MapaGpsQlikPage;
