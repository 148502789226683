import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {UsuarioConsolaForm}  from "../../Consola";

function UsuarioConsolaPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
        < UsuarioConsolaForm  />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default UsuarioConsolaPage;
