import React, { Component } from 'react';
import API from './Apis';
import './bootstrap.min.css';

let Encabezados   = [];
let FilaXFila     = [];
let ColXCol       = [];
let ETab          = [];
let ETabPanel     = [];
let totalColumnas = 0;
let lbEncabezados = [];
let totalPaginas  = 0;
let temindex      = 0;
let tempcolor     = "";
let JsonObj       = [];
let filaClickeada = "";

const row = { backgroundColor: "blue", color: "white", bold: true, padding: 0 }

class TablaCatalogo extends Component {
  constructor(props){
    super(props)
    this.state = {
            url: this.props.url       || "https://jsonplaceholder.typicode.com/",
       catalogo: this.props.catalogo  || "vEmpleados",
     parametros: this.props.parametros|| "",
        headers: this.props.headers   || "",
      contenido: this.props.contenido || [],
          vacio: this.props.vacio     || false,
     totalFilas: this.props.totalFilas|| 0,
   exampleItems: this.props.exampleItems || 1,
       elemXpag: this.props.elemXpag  || 5,
       indexPag: this.props.indexPag  || 0,
            key: this.props.key       || ''
    }
    let noData=false;
  }
  //https://jsonplaceholder.typicode.com/users
  //https://api.github.com/graphql
  Click(indexFila, datos, label ){
    console.log("CeldaClickeada " + " indexFila: ")
    console.log(indexFila);
    console.log(" label:");
    console.log(label);
    console.log(datos);
    filaClickeada = document.getElementById("Fila"+indexFila);
  }
  ClickPag(pagina, evento ){
    tempcolor="";
    temindex = 0;
    console.log("Click Pag: " + pagina + " evento: " + evento + " tempcolor: " + tempcolor + " temindex: " + temindex);
  }

  tableHeader(item) {
    Encabezados = [];
    totalColumnas=0;
    let Jsons = item[0]
    for (let j in Jsons){
        lbEncabezados[totalColumnas = totalColumnas + 1] = j;
        Encabezados.push( <th key={totalColumnas+"-"+this.state.catalogo} id={0} className="Encabezado" headid={j}>{j}</th> );
    }return Encabezados;
  }
  componentDidUpdate(){
  }
  componentWillMount() {
    if (this.state.contenido){
      //console.log("247 componentWillMount");
      //console.log("248 json recibido vacio:" + this.state.vacio);
    }else{
      //API.get(
      //  this.state.catalogo + this.state.parametros, this.state.headers
      //).then(response => { this.setState({ contenido: response.data }); })
    }
  }
  componentDidMount() {
    //console.log("256 Catalogo:"+this.state.catalogo+" en this.state.contenido:");
    //console.log(this.state.contenido);
  }
  //bgcolor={unoAuno[lbEncabezados[col]].id>0?unoAuno[lbEncabezados[col]].nombre!==""?"white":"red":"red"}
  tablesColXCol(unoAuno, jfila){
    let lbuno="";
    let lbnum="";
    ColXCol=[];
    for (let col=1;col<=totalColumnas;col++)
    {
      if( typeof unoAuno[lbEncabezados[col]] === 'object'){
        this.JsonObj = JSON.stringify(unoAuno[lbEncabezados[col]]);
        console.log("268 this.state.contenido[jfila][lbuno]");
        console.log(this.state.contenido[jfila][lbuno]);
        let estiloCol="";
        lbuno = lbEncabezados[col];
        lbnum = this.state.contenido[jfila][lbuno].id;
        ColXCol.push
        (
          <td key={+"-"+this.state.catalogo} col={col} id={this.state.catalogo+lbEncabezados[col]+jfila} name={this.state.catalogo+lbEncabezados[col]+jfila} label={unoAuno[lbEncabezados[col]]} style={{color:"black"}}
              bgcolor={this.state.contenido[jfila][lbuno].id>0?this.state.contenido[jfila][lbuno].nombre!=""?"white":"red":"red"}
              onClick={this.Click.bind(this, this.JsonObj, this.state.totalFilas )} >
            {this.state.contenido[jfila][lbuno].nombre!==""?this.state.contenido[jfila][lbuno].nombre:this.state.contenido[jfila][lbuno].id}
          </td>
        )
      }else{
        let JsonObj = this.state.contenido[this.state.totalFilas]
        ColXCol.push(
            <td key={col+"-"+this.state.catalogo} col={col} id={this.state.catalogo+lbEncabezados[col]+jfila} name={this.state.catalogo+lbEncabezados[col]+jfila} label={unoAuno[lbEncabezados[col]]} style={{color:"black"}}
                //bgcolor={ unoAuno[lbEncabezados[col]].length>0?"white":"red" }
                datos={JsonObj} onClick={this.Click.bind(this, JsonObj, this.state.totalFilas )}>
                {unoAuno[lbEncabezados[col]]}
            </td>)
      }
    }
    return ColXCol;
  }

  paginaFilas(filas){
    this.state.contenido.map( unoAuno =>
      {
        FilaXFila.push(<tr id={"Fila"+this.state.totalFilas} rowid={this.state.totalFilas} key={this.state.totalFilas+"-"+this.state.catalogo} ref={ this.fila } bgcolor={(this.state.totalFilas%2)?"#ffffcf":"#ffffff"} value={this.state.totalFilas} rowname={"Fila"+this.state.totalFilas}>{this.tablesColXCol(unoAuno, this.state.totalFilas)}</tr>)
      })
    return(FilaXFila);
  }

  render() {
    //console.log("Render: ")
    //bgcolor={(this.state.totalFilas%2)?"#ffffcf":"#ffffff"}
    return(<>
      { this.TablaVar = [] }
      {
        this.TablaVar.push(
          <table id="simple" name={this.state.catalogo} className="table">
            <thead>
            <tr rowid={totalPaginas} value={totalPaginas} style={row} >{ this.tableHeader(this.state.contenido) }</tr>
            </thead>
            <tbody>
            { this.Trs =[] }
            {
              this.state.contenido.map( (unoAuno, index) => {
                this.Trs.push(
                  <tr id={"Fila"+this.state.catalogo+this.state.totalFilas} conteo={this.state.totalFilas=this.state.totalFilas+1} ref={ this.fila }
                    bgcolor={(this.state.totalFilas%2)?"#ffffff":"#ffffff"} >{this.tablesColXCol(unoAuno, index)}
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        )
      }
    </>);
  }
}

export default TablaCatalogo;
