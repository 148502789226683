import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { NotificationManager} from 'react-notifications';
import { timingSafeEqual } from "crypto";
class  Perfiles extends Component {
  constructor(props) {
    super(props);
              this.state  = {
                              currentTap  : 0,
                                  accion  : this.props.accion, //'nuevo'// modificar, nuevo
                        plataformasArray  : [],
                            newPassword   : false,
                            currentUser   : []
              };
      this.usuarioObject  = [];
        this.currentUser  = [];
      this.usuarioACtual  = JSON.parse(sessionStorage.getItem("currentUser"));
    /**
     * Plataformasarray list
     */
    this.plataformasLists = [];
      this.templataformas = false;
          this.temProfile = 0;
        this.perfilesList = [];
         this.crudMagaged = [];
            this.perfilId = [];
  }


  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.usuarioObject=this.props.elementos;
      this.getfuntion("post", {"username": this.props.elementos.username}, "", "plataformasUsuariosPerfiles/username/", "currentUser"); 
    }
  }

  
  changePlataforma = ( indice ) => {
    { this.plataformasLists[indice].plataforma === 0 ? this.plataformasLists[indice].plataforma=1 : this.plataformasLists[indice].plataforma=0 }
    this.setState( { plataformasArray: this.plataformasLists } )
  }

  changePerfil = (event) => {
    var row= JSON.parse(event.target.value);
    this.plataformasLists[row.index].perfil = row.perfil;
    this.setState( { plataformasArray: this.plataformasLists } );
  }
  

  

  crud_changePerfil = (indice) => {
    this.props.stor.perfiles_object.map((elemento, index) =>{
      if(this.plataformasLists[indice].perfil === elemento.id){
        if( this.crudMagaged[indice].accion ){
          if( this.plataformasLists[indice].plataforma === 0 ){
            //this.props.getfuntion("delete", [], "idUsuario/"+this.props.elementos.id+"/idPlataforma/"+this.props.stor.plataformas_object[indice].id, "", "plataformasUsuariosPerfiles");
            this.getfuntion("delete", [], "idUsuario/"+this.props.elementos.id+"/idPlataforma/"+this.props.stor.plataformas_object[indice].id, "plataformasUsuariosPerfiles", "currentUser");
          }
          if( this.plataformasLists[indice].plataforma === 1 ){ /** metofdo put */
            this.getfuntion("put", {"id": this.perfilId[indice].perfilId, "perfil": elemento, "usuario":this.usuarioObject, "plataforma": this.props.stor.plataformas_object[indice]}, "", "plataformasUsuariosPerfiles", "currentUser");
          }
        }else{
          if( !this.crudMagaged[indice].accion ){
            this.getfuntion("post", {"perfil": elemento, "usuario":this.usuarioObject, "plataforma": this.props.stor.plataformas_object[indice]}, "", "plataformasUsuariosPerfiles", "currentUser"); 
          }
        }
      }
    });
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "currentUser", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "usuarios", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "post":
            {
              this.plataformasLists= [];
              this.crudMagaged=[];
              this.perfilId=[];
              if (stateVar === "currentUser" && catalogo === "plataformasUsuariosPerfiles/username/"){                
                this.currentUser= returnVal;
                this.setState({ currentUser: this.currentUser }); 
              }else{
                if (stateVar === "currentUser"){ this.currentUser.push(returnVal); this.setState({ currentUser: this.currentUser }); 
                  NotificationManager.info("Se agrega perfil "+ returnVal.perfil.nombre+", en la plataforma: "+ returnVal.plataforma.nombre , "Correcto" ); }
              }
            }
            break; 
            case "put":
            {
              if (stateVar === "currentUser"){
                this.currentUser.map((item, index)=>{ if(item.id === returnVal.id){ this.currentUser[index]= returnVal } });
                this.setState({ currentUser: this.currentUser });
                NotificationManager.info("El perfil a cambiado: "+ returnVal.perfil.nombre+", en la plataforma: "+ returnVal.plataforma.nombre , "Correcto" );
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "currentUser"){
                if( returnVal ){
                  console.log(returnVal)                  
                  NotificationManager.info("El perfil Borrado", "Correcto" );
                }else{ NotificationManager.error("Error al borrar perfil" ); }

                this.getfuntion("post", {"username": this.props.elementos.username}, "", "plataformasUsuariosPerfiles/username/", "currentUser");
              }
            }
              break;
          default:
            break;
        }

        var find =false;
        this.props.stor.plataformas_object.map((elemento, index) =>
          {
            this.state.currentUser.map((Puser, ind) =>{              
              if(Puser.plataforma.id === elemento["id"] && find === false){               
                  this.plataformasLists.push({"plataforma": 1, "perfil": 0} );
                  this.temProfile= Puser.perfil.id;
                  this.plataformasLists[index].perfil= Puser.perfil.id
                  find=true;
                  this.crudMagaged.push({"accion": true});
                  this.perfilId.push({"perfilId": Puser.id});                
              }else{
                this.temProfile= 0;
              }
            }
            );
            if(!find){
              this.perfilId.push({"perfilId": 0});
              this.plataformasLists.push({"plataforma": 0, "perfil": 0} );
              this.crudMagaged.push({"accion": false});
            }
            find= false;
          }
        );
        this.setState( { plataformasArray: this.plataformasLists } );
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
        NotificationManager.error("Error al ejecutar la acción" );
    });
  }

  selectedTab = (index) =>{
    this.setState({ currentTap:index })
  }
  render(){
    return (
      <div>

        {
          /**
           * Lista de perfiles
           */
        }

        {
          this.props.elementos.estatus ?
            <Grid.Row>
              <Grid.Col md={12} xl={12}>
                <Card
                  title="Perfiles"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false }
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>PLataforma</Table.ColHeader>
                            <Table.ColHeader>Perfil</Table.ColHeader>
                            <Table.ColHeader>Actualizar</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.plataformas_object.map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>
                                    <Grid.Row>
                                      <Grid.Col md={9} xl={9}>
                                        { elemento["nombre"] }
                                      </Grid.Col>
                                      <Grid.Col md={3} xl={3}>
                                      <Form.Switch type="Checkbox" name="toggle" value={index}
                                        checked={ this.state.plataformasArray[index] ? this.state.plataformasArray[index].plataforma: 0 } //{ this.state.Etiqueta_arraylist[index] }
                                        onChange={ () => this.changePlataforma(index) }
                                        />
                                      </Grid.Col>
                                    </Grid.Row>
                                  </Table.Col>
                                  <Table.Col>
                                  { this.state.plataformasArray[index] ?
                                    <Form.Select name="Perfil" value={ JSON.stringify( {"index":index, "perfil": this.state.plataformasArray[index].perfil}) }
                                    onChange={ this.changePerfil}> { /** index, elemento["id"]  */ }
                                      <option value={JSON.stringify( {"index":index, "perfil": 0}) }>Selecciona Un perfil</option>
                                      {this.props.stor.perfiles_object.map((elemento, inde) => 
                                        <option value={ JSON.stringify( {"index": index, "perfil":elemento["id"]} ) } > { elemento["nombre"] } </option>)
                                      }
                                    </Form.Select>
                                  :null
                                  }
                                  </Table.Col>
                                  <Table.Col>
                                    <Button target="_blank" size="sm" RootComponent="a" color="primary"
                                    disabled= { this.state.plataformasArray[index] ? this.state.plataformasArray[index].perfil ? 0 :1 : 1 }
                                    onClick={() => this.crud_changePerfil(index) } >
                                      <span class="badge"><Icon link={true} name="refresh-ccw" />Actualizar</span>
                                    </Button>
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            </Grid.Row>
          : null 
        }
      </div>
    )
  }
}

export default Perfiles
