//Author F.R. Betancourt
import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

const LocalidadesForm = ({
  stor,
  accion,
  elementos,
  getfuntion,
  cerrarModal,
}) => {
  const [formulario, setFormulario] = useState({
    nombre: "",
    estatus: false,
    pais: "",
    latitud: "",
    longitud: "",
  });
  const [crudbutonEnable, setCrudbutonEnable] = useState(true); // Se desabilita cuando se hace click
  useEffect(() => {
    if (accion === "nuevo") {
    } else {
      setFormulario(elementos);
    }
  }, []);

  const isEnable = (Accion) => {
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    var privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const enableSave = () => {
    if (formulario.nombre ? (formulario.nombre.length ? true : false) : false) {
      return false;
    } else {
      return true;
    }
  };

  const guardarLocalidades = () => {
    if (accion === "modificar") crud_put();
    else crud_post();
    cerrarModal();
  };

  const crud_delete = () => {
    setCrudbutonEnable(false);
    getfuntion("delete", "", elementos.id);
  };

  const crud_put = () => {
    setCrudbutonEnable(false);
    getfuntion("put", formulario);
  };
  const crud_post = () => {
    setCrudbutonEnable(false);
    getfuntion("post", formulario);
  };
  return (
    <div>
      {accion === "modificar" ? <h1> {elementos.nombre} </h1> : null}
      <Grid.Row>
        <Grid.Col md={6}>
          <Form.Input
            type="text"
            id="nombre"
            name="nombre"
            label="Nombre"
            placeholder="Nombre"
            value={formulario.nombre}
            onChange={(e) => {
              setFormulario({ ...formulario, nombre: e.target.value });
            }}
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Form.Switch
            name="estatus"
            label="Activo"
            checked={formulario.estatus}
            onChange={(e) => {
              setFormulario({ ...formulario, estatus: e.target.checked });
            }}
          />
        </Grid.Col>
        <Grid.Col md={3}>
          <Form.Input
            type="text"
            id="pais"
            name="pais"
            label="Pais"
            placeholder="Pais"
            value={formulario.pais}
            onChange={(e) => {
              setFormulario({ ...formulario, pais: e.target.value });
            }}
          />
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col md={6}>
          <Form.Input
            type="number"
            id="latitud"
            name="latitud"
            label="Latitud"
            placeholder="Latitud"
            value={formulario.latitud}
            onChange={(e) => {
              setFormulario({ ...formulario, latitud: e.target.value });
            }}
          />
        </Grid.Col>
        <Grid.Col md={6}>
          <Form.Input
            type="number"
            id="longitud"
            name="longitud"
            label="Longitud"
            placeholder="Longitud"
            value={formulario.longitud}
            onChange={(e) => {
              setFormulario({ ...formulario, longitud: e.target.value });
            }}
          />
        </Grid.Col>
      </Grid.Row>

      <div className="float-right">
        {isEnable("actualizar") || isEnable("agregar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={enableSave() || !crudbutonEnable}
              onClick={guardarLocalidades}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        {accion === "modificar" && isEnable("borrar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => crud_delete()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default LocalidadesForm;
