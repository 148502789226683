import React,  { useState, useEffect } from 'react';
import { Button, Form, Grid, Icon, Table } from "tabler-react";
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { TablePageable } from '../Helpers';
import DetalleAlertaSinRuta from './DetalleAlertaSinRuta';
import ModalLay from './ModalLay/ModalLay';
import { timeToDatefull } from '../herramientas/DateFormat';
import AlertasSinRutaMensajeria from '../socket/AlertasSinRutaMensajeria';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

const AlertasUnidadesSinRuta = () => {  
  
  const [ alertasAtendidasTerrestresUnidadesSinRuta, setAlertasAtendidasTerrestresUnidadesSinRuta ] = useState([]);
  const [ alertasNoAtendidasTerrestresUnidadesSinRuta, setAlertasNoAtendidasTerrestresUnidadesSinRuta ] = useState([]);
  const [ noAtendidas, setNoAtendidas ] = useState(true);   
  const [ date1, setDate1] = useState(timeToDatefull(new Date().getTime()));
  const [ date2, setDate2] = useState(timeToDatefull(new Date().getTime()));
  const [ topics, setTopics] = useState([]);

  useEffect(() => {
    let topicsTemp = [];  
    for(let p of currentUser){      
      topicsTemp.push("/topic/alertasSinRutaTer-" + p.plataforma.idGg );         
    }    
    setTopics(topicsTemp);    
  }, []);
 
  useEffect(() => {     
      setAlertasNoAtendidasTerrestresUnidadesSinRuta([]);
      setAlertasAtendidasTerrestresUnidadesSinRuta([]);
      refrescar();
      
  }, [noAtendidas]);

  const refrescar = () => {      
      if( noAtendidas === true){
        crud("get", "", "", "terrestresAlertasSinRuta/listaNoAtendidasTerrestres/" + currentUser[0].usuario.id, "alertasNoAtendidasTerrestres"); 
      }
      if( noAtendidas === false){       
        crud("get", "", "", "terrestresAlertasSinRuta/listaAtendidasTerrestres/" + currentUser[0].usuario.id, "alertasAtendidasTerrestres");                                  
      }     
  }

  const cambioNoAtendidas = () => {
    //console.log(noAtendidas);
    if(noAtendidas === true){
      setNoAtendidas(false);
    }else{
      setNoAtendidas(true);
    }
  }
  
  const getAlertas = () => {
    if(noAtendidas){
      return alertasNoAtendidasTerrestresUnidadesSinRuta;
    } else {
      return alertasAtendidasTerrestresUnidadesSinRuta;
    }
  }

  const getByDates = () => {                         
      crud("get", "", "", "terrestresAlertasSinRuta/listaAtendidasTerrestres/" + currentUser[0].usuario.id + "/fecha1/"+ date1 + " 00:00:00/fecha2/"+ date2 + " 23:59:59", "alertasAtendidasTerrestres");                  
  }

  const changeDate1 = (e) => {
    let today= new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha= new Date(e.target.value).getTime();
    let fecha2= new Date(date2).getTime();
    //console.log("date1: " + e.target.value);   
    if(fecha > fecha2 && fecha < today){ setDate2( e.target.value ) }
    if(fecha <= today ){ setDate1( e.target.value ) }       
    //console.log("Date2: " + date2);
  }

  const changeDate2 = (e) => {    
    let today= new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha= new Date(e.target.value).getTime();
    let fecha1= new Date(date1).getTime();
    //console.log("date2: " + e.target.value);    
    if(fecha < fecha1 && fecha < today){  setDate1( e.target.value ) }
    if(fecha <= today ){ setDate2( e.target.value ) }    
    //console.log("Date1: " + date1);          
     
  }

  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":             
          if(stateVar === "alertasAtendidasTerrestres" && returnVal) {
            let alertasAtendidasTerrestresTemp = [];
            returnVal.map((i, index) => {
              i.atendida = i.atendida ? true : false;             
              alertasAtendidasTerrestresTemp.push(i)
            });
            //console.log(returnVal);
            setAlertasAtendidasTerrestresUnidadesSinRuta(alertasAtendidasTerrestresTemp);          
          }
          if(stateVar === "alertasNoAtendidasTerrestres" && returnVal) {
            let alertasNoAtendidasTerrestresTemp = [];
            returnVal.map((i, index) => { 
              i.atendida = i.atendida ? true : false;
              alertasNoAtendidasTerrestresTemp.push(i)
            });
            //console.log(returnVal);           
            setAlertasNoAtendidasTerrestresUnidadesSinRuta(alertasNoAtendidasTerrestresTemp);                  
          }
          /*if(stateVar === "alertasAtendidasTerrestresRefrescar" && returnVal) {
            let alertasAtendidasTerrestresRefrescarTemp = [];
            returnVal.map((i, index) => { 
              i.atendida = i.atendida ? true : false;
              alertasAtendidasTerrestresRefrescarTemp.push(i)
            });
            //console.log(returnVal);           
            setAlertasAtendidasTerrestresUnidadesSinRuta(alertasAtendidasTerrestresRefrescarTemp);                  
          }*/
            break;            
          default:
            break;
        }
    }).catch(err => { console.log(err); });
  }    

  return (
    <> 
      {<AlertasSinRutaMensajeria
        topics={topics}
        refrescar={refrescar}        
      />}    
      <div className='container-fluid p-6'>
        <h3> {noAtendidas ? "No Atendidas" : "Atendidas"} </h3>
        <hr color='white'/>
        <div className='row mt-5'>
          <div className='col-md-12'>
          <TablePageable
            titulo={  <>                
                        <Grid.Row> 
                          <Grid.Col>
                            <div>
                              <span className="mr-1" data-toggle="tooltip" data-placement="top" title={noAtendidas ? "Estas viendo alertas no atendidas" : "Estas viendo alertas por fechas"}>
                                <Form.Switch type="Checkbox" checked={noAtendidas}  onChange={ cambioNoAtendidas } />
                              </span>                                                   
                            </div>                           
                            <div>
                              {!noAtendidas 
                                ? <span className="mr-1 btn text-dark" onClick={getByDates} ><Icon name="refresh-cw"/></span>
                                : null
                              } 
                              {!noAtendidas                                                                                                 
                                ? <span className="mr-1 float-right"><Form.Input name="date2" type="date" value={ date2 } onChange={(e) => { changeDate2(e) }} disabled={ noAtendidas } /></span>
                                : null
                              }
                              {!noAtendidas
                                ? <span className="mr-1 ml-1 float-right">y</span>
                                :null
                              }
                              {!noAtendidas
                                ? <span className="mr-1 float-right"><Form.Input name="date1" type="date" value={ date1 } onChange={(e) => { changeDate1(e) }}  disabled={ noAtendidas } /></span>
                                :null}
                            </div>                    
                          </Grid.Col>                                                             
                        </Grid.Row>                                                                  
                      </> 
                    }
            lista= { getAlertas() }
            columnas={
              [
                { columna: "detalle", label: "DETALLE",                  
                  form: (e) =>  <ModalLay                  
                      tamanio={"60%"}
                      title="Detalle de la Alerta"
                      name={ 
                      <Button pill size="sm" outline color="primary">
                          <div
                          className={"fa fa-bell"}
                          style={{ color: "green" }}
                          ></div>
                      </Button>
                      }
                      formulario={DetalleAlertaSinRuta}
                      datosForm={{                      
                          esquema: "terrestre",                                                                 
                          alerta: e,
                          refresh: refrescar                                                                        
                      }}
                  />    
                },               
                { columna: "mapa", label: "ver",                  
                  form: (e)=> <Button pill size="sm" outline color="primary"
                  onClick= { ()=> window.open("https://maps.google.com/?q="+e.latitud+","+e.longitud+"&t=m", 'sharer', 'width=900,height=600,scrollbars=NO') }
                  >
                  <div className={'fa fa-map-marker'} style={{ color:'green'}} ></div>
                </Button>
                },
                { columna: "plataforma", label: "PLATAFORMA" },
                { columna: "id_alerta_sin_ruta", label: "ID" },  
                { columna: "unidad", label: "UNIDAD" },
                { columna: "tipo_alerta", label: "TIPO DE ALERTA" },  
                { columna: "latitud", label: "LATITUD" },
                { columna: "longitud", label: "LONGITUD" },  
                { columna: "trigger_name", label: "TRIGGER" },                
                { columna: "fecha", label: "FECHA REGISTRO" },
                { columna: "causa", label: "CAUSA" },  
                { columna: "atendida", label: "ATENDIDA" },             
                { columna: "atiende_fecha", label: "FECHA DE ATENCIÓN" },
                { columna: "id_transportista", label: "TRANSPORTISTA" },  
                { columna: "placa", label: "PLACA" },                        
                { columna: "mensaje", label: "MENSAJE" },
              ]
            }           
            id= "id_alerta_sin_ruta"            
            csvname= {"Alertas sin ruta "+ (noAtendidas ? "no atendidas" : "atendidas")}            
            paginacion={true}
            elementosPorPagina={20}
          />
          </div>
        </div>
      </div>     
    </>    
  )
}

export default AlertasUnidadesSinRuta;