function componentToHex(c) {
    var hex = parseInt(c).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
export function RgbATohex(rgba){ 
    var r="", g="", b="", a="";
    var Cr=false, Cg=true, Cb=true, Ca=true;
    var coppy= false;
    for(let i of rgba){
        if(i ==="("){ coppy= true; }
        if(i !=="(" && i !=="," && i !==" " && coppy && !Cr){ r= r+i }
        if(coppy && !Cr && i === ","){ Cr= true;  Cg= false;}
        if(i !=="(" && i !=="," && i !==" " && coppy && !Cg){ g= g+i }
        if(coppy && !Cg && i === "," && g.length > 0){ Cg= true;  Cb= false;}
        if(i !=="(" && i !==")" && i !=="," && i !==" " && coppy && !Cb){ b= b+i }
        if(coppy && !Cb && i === "," && b.length > 0){ Cb= true;  Ca= false;}
        if(i !=="(" && i !==")" && i !=="," && i !==" " && coppy && !Ca){ a= a+i }
        if(coppy && !Ca && i === ")" && a.length > 0){ Ca= true;}
    } 
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
  
  export default RgbATohex;