import React, { useEffect, useState } from "react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../herramientas/DateFormat";
import { TablePageable } from "../../../Helpers";
const WatsAppGrupos =({transportista, stor, plataforma})=> {
  const [transportistasWhatsappGrupos, setContactosTransportistasWhatsappGrupos]= useState([]);
  const [whatsappGrupos, setWhatsappGrupos]= useState([]);
  const [whatsappGrupo, setWhatsappGrupo]= useState([]);
  const [agregar, setAgregar]= useState(false);

  useEffect(() => {
		getTransportistasWhatsAppGrupos();
	}, []);
 const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          if (stateVar === "transportistas_whatsapp_grupos") {
            let transportistasWhatsappGruposTemp= [];
            for(let c of returnVal){
              c.fecha= timeToDateFill(c.fecha);
              transportistasWhatsappGruposTemp.push(c);
            }
            setContactosTransportistasWhatsappGrupos(transportistasWhatsappGruposTemp);
          }
          if (stateVar === "whatsapp_grupos") {
            let whatsappGruposTemp= [];
            for(let c of returnVal){
              c.fecha= timeToDateFill(c.fecha);
              whatsappGruposTemp.push(c);
            }
            setWhatsappGrupos(whatsappGruposTemp);
          }
          break;
        case "post":
          if (stateVar === "transportistas_whatsapp_grupos") {
            getTransportistasWhatsAppGrupos();
            setAgregar(false);
          }
          break;
        case "delete":
          if (stateVar === "transportistas_whatsapp_grupos") {
            getTransportistasWhatsAppGrupos();
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  const getTransportistasWhatsAppGrupos =()=>{
    getfuntion("get", "", "", "whatsapp_grupos/transportista/"+transportista.id, "transportistas_whatsapp_grupos");
  }
  return (
    
    <div className= "mt-1">
      <div hidden={ agregar }>
        <h4>Grupos de whatsApp asignados</h4>
        <TablePageable
          titulo={
            <div className="row">
              <div className="col">
                <i className="fa fa-plus"  style={{"font-size": "15px"}}
                  onClick={()=> {
                    setAgregar(true);
                    getfuntion("get", "", "", "whatsapp_grupos/plataforma/"+plataforma.id, "whatsapp_grupos");
                  }}
                ></i>
              </div>
              <div className="col">
                <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                  onClick={()=> getTransportistasWhatsAppGrupos() }
                ></i>
              </div>
            </div>
          }
          lista= { transportistasWhatsappGrupos }
          columnas={
            [
              { columna: "remover", label:"Remover",
                form: (e)=>
                  <i className="fa fa-remove" style={{"font-size": "24px"}}
                    onClick={()=>{
                      const cwt={
                        idTransportista: transportista.id,
                        idWhatsappGrupo: e.id,
                        fecha: null,
                      };
                      getfuntion(
                        "delete",
                        "",
                        transportista.id+"?idWhatsappGrupo="+e.id,
                        "transportistas_whatsapp_grupos",
                        "transportistas_whatsapp_grupos");
                    }}>
                  </i> },
              { columna: "id", label:"Id" },
              { columna: "nombreGrupo", label:"Grupo" },
              { columna: "estatus", label:"Estatus" },
              { columna: "fecha", label:"Fecha" },
            ]
          }
          id= "id"
          selected={(e)=> setWhatsappGrupo(e)}
        />
      </div>
      <div hidden={ !agregar }>
        <TablePageable
          titulo={
            <h4>Grupos de la plataforma {plataforma.nombre}
              <button type="button" class="btn btn-danger ml-3" onClick={()=> setAgregar(false) }>
                Cancelar
              </button>
            </h4> }
          lista= { whatsappGrupos }
          columnas={
            [
              { columna: "agregar", label:"Agregar",
                form: (e)=>
                  <i className="fa fa-plus" style={{"font-size": "24px"}}
                    onClick={()=>{
                      const cwt={
                        idTransportista: transportista.id,
                        idWhatsappGrupo: e.id,
                        fecha: null,
                      };
                      getfuntion("post", cwt, "", "transportistas_whatsapp_grupos", "transportistas_whatsapp_grupos");
                    }}>
                  </i> },
              { columna: "id", label:"Id" },
              { columna: "nombreGrupo", label:"Grupo" },
              { columna: "estatus", label:"Estatus" },
              { columna: "fecha", label:"Fecha" },
            ]
          }
          id= "id"
          selected={(e)=> setWhatsappGrupo(e)}
        />
      </div>
    </div>
          
  );
}
export default WatsAppGrupos;