import React from "react";
import classes from "./TableMenu.module.css";

const TableMenu = (props) => {
  const closeSideBarHandler = () => {
    props.methflagSideB();
  };
  return (
    <div
      id="mySidebar"
      className={`${props.className} ${classes.sidebar}`}
      style={props.flagSideB ? { width: "25%" } : { width: "0px" }}
    >
      <button
        type="button"
        className={classes.closebtn}
        onClick={closeSideBarHandler}
      >
        ×
      </button>
      {props.children}
    </div>
  );
};

export default TableMenu;
