import React, { useState, useEffect } from "react";
import FetchData from "./FetchData.react";

const CheckForm = (variables, sets) => {
	//Checks
	variables.bloque.value === 0 ? sets.setErrorBloque(true) : sets.setErrorBloque(false);
	variables.funcionalidad.value === 0
		? sets.setErrorFuncionalidad(true)
		: sets.setErrorFuncionalidad(false);
	variables.plataforma.value === 0
		? sets.setErrorPlataforma(true)
		: sets.setErrorPlataforma(false);
	variables.transportista.value === 0
		? sets.setErrorTransportista(true)
		: sets.setErrorTransportista(false);
	variables.fechaInicio.length === 0 ? sets.setErrorFechaIni(true) : sets.setErrorFechaIni(false);
	variables.fechaFin.length === 0 ||
	Date.parse(variables.fechaInicio) > Date.parse(variables.fechaFin)
		? sets.setErrorFechaFin(true)
		: sets.setErrorFechaFin(false);

	let errorFechaIni = variables.fechaInicio.length === 0 ? true : false;
	let errorFechaFin = variables.fechaFin.length === 0 ||
	Date.parse(variables.fechaInicio) > Date.parse(variables.fechaFin) ? true : false;
	let errorFuncionalidad = variables.funcionalidad.value === 0 ? true : false;
	let errorPlataforma = variables.plataforma.value === 0 ? true : false;
	let errorTransportista = variables.transportista.value === 0 ? true : false;

	//Acciones
	switch (variables.funcionalidad.label) {
		case "Alertas naviera generales":
			if (!( errorFuncionalidad || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/alertasGeneralNaviero/" +
						variables.idUsuario +
						"/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Alertas naviera por línea de transporte":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/alertasTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Entregas naviera generales":
			if (!( errorFuncionalidad || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/entregasGeneralNaviero/" +
						variables.idUsuario +
						"/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Entregas naviera por línea de transporte":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/entregasTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Incidencias naviera generales":
			if (!( errorFuncionalidad || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/incidenciasGeneralNaviero/" +
						variables.idUsuario +
						"/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Incidencias naviera por línea de transporte":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/incidenciasTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Movimientos naviera generales":
			if (!( errorFuncionalidad || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/movimientosGeneralNaviero/" +
						variables.idUsuario +
						"/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Movimientos naviera por línea de transporte":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/movimientosTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Tipos de alertas naviera por plataforma":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/alertasTipoPlataformaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Tipos de alertas naviera por línea de transporte":
			if (!( errorTransportista || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/alertasTipoTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value +
						"/" +
						variables.transportista.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Tipos de incidencias naviera por plataforma":
			if (!( errorPlataforma || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/incidenciasTipoPlataformaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		case "Tipos de incidencias naviera por línea de transporte":
			if (!( errorTransportista || errorFechaIni || errorFechaFin )) {
				FetchData(
					"informes/contador/incidenciasTipoTransportistaNaviero/" +
						variables.fechaInicio +
						"/" +
						variables.fechaFin +
						"/" +
						variables.plataforma.value +
						"/" +
						variables.transportista.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError
				);
			}
			break;
		default:
			break;
	}
};

export default CheckForm;