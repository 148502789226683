import React from "react";
import Divider from "../components/Sidebar/Divider";
import classes from "./TableHeader.module.css";

const TableHeader = (props) => {
  return (
    <div
      className={`${classes.navSoft} nav nav-pills mb-4 px-3`}
      id="course-pills-tab"
      role="tablist"
    >
      <div className="container">
        <div className="row">
          <div className="col-1">
            <button
              onClick={props.clickLeftButton}
              type="button"
              className={classes.myButton}
              style={{ color: "white" }}
            >
              {props.leftButton}
            </button>
          </div>
          <div className="col-10">
            <div className="d-flex flex-row justify-content-around">
              {props.alertasAtendidas}
            </div>
            <Divider />
            <div className="d-flex flex-row justify-content-around">
              {props.alertasNoAtendidas}
            </div>
          </div>
          <div className="col-1">
            <ul className="nav flex-column">
              {props.permisoButton1 && (
                <li key={"itemnav-item2"} className="nav-item">
                  <button
                    onClick={props.clickRightButton1}
                    type="button"
                    className={classes.myButton}
                    style={{
                      color: props.estadoButton1,
                      borderColor: props.estadoButton1,
                    }}
                  >
                    {props.rightButton1}
                  </button>
                </li>
              )}

              {props.permisoButton2 && (
                <li key={"itemnav-item"} className="nav-item">
                  <button
                    onClick={props.clickRightButton2}
                    type="button"
                    className={classes.myButton}
                    style={{
                      color: props.estadoButton2,
                      borderColor: props.estadoButton2,
                      marginTop: "4px",
                    }}
                  >
                    {props.rightButton2}
                  </button>
                </li>
              )}

              {props.modalResumer && (
                <li key={"itemnav-item4"} className="nav-item">
                  <button
                    onClick={props.clickModalResumer}
                    type="button"
                    class={classes.myButton}
                    style={{ color: "white", marginTop: "4px" }}
                  >
                    {props.modalResumer}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
