import React, { Component } from "react";
import { Card, Button, Form, Icon, Grid, List, Table } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";

class MovimientoIncidenciasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      incidencias: {
        value: 0,
        label: "Selecciona  tipo de incidencia",
        data: null,
      },
      comentario: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
      latitud: "",
      longitud: "",
      listarIncidencias: [],
    };
    this.enableSave = this.enableSave.bind(this);
    this.movimientoIncidencia = [];
    this.incidencias = [];
    this.privilegio = [];
    this.sevenIncidencias = [];
  }
  componentDidMount = () => {
    this.props.incidencias.map((i, index) =>
      this.incidencias.push({ value: i.id, label: i.nombre, data: i })
    );
    this.getfuntion(
      "get",
      [],
      "",
      "movimientoIncidencias/movimiento/" + this.props.idmovimiento,
      "listarIncidencias"
    );
    if (this.state.accion === "nuevo") {
      let latitud = "";
      let longitud = "";
      if (this.props.latitud) {
        latitud = this.props.latitud;
      }
      if (this.props.longitud) {
        longitud = this.props.longitud;
      }
      this.movimientoIncidencia = {
        idMovimiento: this.props.idmovimiento,
        comentario: "",
        fechaCreado: null,
        atencionUsuario: this.props.idusuario,
        atencionFecha: null,
        latitud: latitud,
        longitud: longitud,
        incidencia: {},
      };
      this.setState({ latitud: latitud });
      this.setState({ longitud: longitud });
    } else {
      this.setState({ incidencias: this.props.elementos.incidencias });
      this.setState({ comentario: this.props.elementos.comentario });
    }
  };

  enableSave = () => {
    if (this.state.incidencias.value > 0) {
      return false;
    } else {
      return true;
    }
  };

  delete() {
    this.props.getfuntion("delete", "", this.props.elementos.id);
  }
  crud_put() {
    this.props.getfuntion("put", this.movimientoIncidencia);
  }
  crud_post() {
    this.movimientoIncidencia.comentario = this.state.comentario;
    this.movimientoIncidencia.incidencia = this.state.incidencias.data;
    this.props.getfuntion(
      "post",
      this.movimientoIncidencia,
      "",
      "movimientoIncidencias",
      "incidencias"
    );
  }

  guardarIncidencia = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "listarIncidencias") {
              //43974
              if (this.state.listarIncidencias) {
                this.setState({
                  listarIncidencias: returnVal
                    .sort(function (a, b) {
                      if (a["fechaCreado"] === null) {
                        return -1;
                      }
                      if (a["fechaCreado"] < b["fechaCreado"]) {
                        return 1;
                      }
                      if (a["fechaCreado"] > b["fechaCreado"]) {
                        return -1;
                      }
                      return 0;
                    })
                    .slice(0, 6),
                });
              } else {
                this.setState({ listarIncidencias: [] });
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ...." + err.response);
        }
      });
  };
  changeIncidencias = (event) => {
    this.setState({ incidencias: event });
  };
  changeLatitud = (e) => {
    this.setState({ latitud: e.target.value });
  };
  changeLongitud = (e) => {
    this.setState({ longitud: e.target.value });
  };
  changeComentario = (e) => {
    this.setState({ comentario: e.target.value });
  };

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.comentario} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={8} lg={8}>
                <Form.Group label="incidencias">
                  <Select
                    value={this.state.incidencias}
                    onChange={this.changeIncidencias}
                    options={this.incidencias}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Latitud">
                  <Form.Input
                    name="latitud"
                    value={this.state.latitud}
                    onChange={this.changeLatitud}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Longitud">
                  <Form.Input
                    name="longitud"
                    value={this.state.longitud}
                    onChange={this.changeLongitud}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group label="Comentario">
                  <Form.Textarea
                    name="comentario"
                    type="text"
                    value={this.state.comentario}
                    onChange={this.changeComentario}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
          <div className="float-right">
            <span class="badge">
              &nbsp;&nbsp;&nbsp;
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave()}
                onClick={this.guardarIncidencia}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar incidencia
                </span>
              </Button>
            </span>
            {this.state.accion === "modificar" ? (
              <span class="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={() => this.delete()}
                >
                  <span class="badge">
                    <Icon link={true} name="trash" />
                    Borrar
                  </span>
                </Button>
              </span>
            ) : null}
          </div>
          <br />
          <br />
        </Grid.Row>

        <div class="row">
          <div className="col col-12 col-md-12">
            <Card title="Lista de Incidencias">
              <Card.Body>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={[
                    { content: "Id" },
                    { content: "Nombre" },
                    { content: "Fecha" },
                    { content: "comentario" },
                  ]}
                >
                  <Table.Body>
                    {this.state.listarIncidencias.length ? (
                      this.state.listarIncidencias.map((i) => (
                        <Table.Row>
                          <Table.Col>{i.id}</Table.Col>
                          <Table.Col>{i.incidencia.nombre}</Table.Col>
                          <Table.Col>{i.fechaCreado}</Table.Col>
                          <Table.Col>{i.comentario}</Table.Col>
                        </Table.Row>
                      ))
                    ) : (
                      <Table.Row>
                        <Table.Col>Sin incidencias</Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default MovimientoIncidenciasForm;
