import React, { Component } from "react";
import "./excel.css";

function subCadena(str, I) {
  //console.log("Str: " + str + " I: " + I);//var res = str.split(-I);
  var res = str.slice(0, I); //console.log("Res: " + res);
  return res;
}

function obtenAtributos() {
  var tempo = window.location.href;
  var itemp = tempo.length;
  var i = itemp; //console.log("tempo: " + tempo + " length: " +  itemp);
  do {
    //console.log("Do i: "+ i );
    let stext = tempo.charAt(i);
    if (stext == "/") {
      //console.log("stext: " + stext + " i: " + i );
      return subCadena(tempo, i) + "/archivos/";
      break;
    }
    i = i - 1;
  } while (i > 0);
  return subCadena(tempo, i) + "/archivos/";
}

class Plantilla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogo: this.props.catalogo | "",
      url: this.props.url | "",
      download: this.props.download | "",

      //Variables
      accion: this.props.accion,
      data: this.props.data | [],
    };
    this.cita = 0;
  }
  componentDidMount() {}
  componentWillMount() {
    //obtenAtributos()
    this.setState({ url: obtenAtributos() });
    this.setState({ download: "" + this.props.catalogo + ".xlsx" });
  }

  render() {
    return (
      <a
        download={this.state.download}
        href={this.state.url + this.state.download}
        disabled={this.state.url != "" ? false : true}
      >
        <button className="btn btn-secondary active">Plantilla</button>
      </a>
    );
  }
}

export default Plantilla;
