import React, { Component } from "react";
import { Grid } from "tabler-react";
import SplitterLayout from "react-splitter-layout";
import "react-splitter-layout/lib/index.css";
import SiteWrapper from "../../SiteWrapper.react";
import EspecificoN from "../../components/EspecificoNaviero";
import EspecificoT from "../../components/EspecificoTerrestre";
import * as conf from "../../config/config";
import {
  MonitoreoNavieroForm,
  MonitoreoTerrestreForm,
} from "../../Monitoristas";
import Panel from "../../Monitoristas/Monitoreo/Panel";
import "./MonitoreoPage.react.css";
import * as ACTIONS from "../../store/actions/actions";
import { connect } from "react-redux";
import PanelMensajeria from "../../socket/PanelMensajeria.react";
import { GpsEstatusNaviero } from "../../Monitoristas/notificaciones/GpsEstatusNaviero";
import { GpsEstatusTerrestre } from "../../Monitoristas/notificaciones/GpsEstatusTerrestre";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import { AlertasN } from "../../Monitoristas/notificaciones/AlertasN";
import { AlertasT } from "../../Monitoristas/notificaciones/AlertasT";
import { EtasN } from "../../Monitoristas/notificaciones/EtasN";
import { timeToMilisegundos } from "../../herramientas/DateFormat";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import LoadStore from "../../store/LoadStore";
import ModalLay from "../../components/ModalLay/ModalLay";
import { AlertasListar } from "../../Monitoristas/Monitoreo/panelForms/index.js"; //ActualizarHorariosTerrestres
import SideMenu from "../../components/Sidebar/SideMenu";
import ItemMenu from "../../components/Sidebar/ItemMenu";
import Divider from "../../components/Sidebar/Divider";
import TableHeader from "../../Layout/TableHeader";
import classes from "./LightedAnimations.module.css";
import { timeToDateFill } from "../../herramientas/DateFormat";
import Alert from "../../components/Alert/Alert";

var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var modulosMonitoreo = JSON.parse(localStorage.getItem("modulosMonitoreo"));
var colsN = [];
var colsT = [];
let tiempos = [];
const url = conf.api_raiz;

class Monitoreo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PlataformasPerfiles: {},
      Monitoreo_Naviero: [],
      currentMovimientoNaviero: {},
      currentMovimientoTerrestre: {},
      estatusRuta: {},
      headersDash: [],
      headersDashT: [],
      alertasN: {},
      alertasNCat: {},
      alertasTCat: {},
      alertasT: {},
      alertasTipo: {},
      etasN: {},
      flagSideBar: false, //bandera SideBar.
      flagMaximizeModal: false, //bandera para maximizar o minimizar modal.
      columnasN: [],
      columnasT: [],
      usuariosCorredoresN: [],
      usuariosCorredoresT: [],
      navieras: false,
      terrestres: false,
      plataformaTipo: null,
      hiddenModal: false,
      movimentosNavieros: [],
      movimentosTerrestres: [],
      gpsEstatusNavieros: null,
      movsGpsEstatus: [],
      gpsEstatusTerrestres: null,
      gpsTerrestreReciente: null,
      gpsNavieroReciente: null,
      topics: [],
      metodo: "get",
      totalAlertCatSpanN: [],
      totalAlertCatSpanT: [],
      MovimientosFull: {},
      movPatio: {},
      semaforoN: 0,
      semaforoT: 0,
      entregas: "",
      incidencias: "",
      alertasCategoriasT: [],
      alertasCategoriasN: [],
      actualizandoGps: false,
      plataformasAlertas: {},
      divIsHighLighted: { opcion: false, tipo: "" },
      tiemposAlertas: [],
      idToFilter: 0,
    };

    this.categoriasObj = {};
    this.navieras = false;
    this.terrestres = false;
    this.gpsPlataformaGPsgG = {};
    this.funcionalidades = [];
    this.mensaje = {
      mensaje: "",
      plataforma: "",
      corredor: "",
      vista: "",
      data: null,
      accion: "get",
    };
    this.loadedN = false;
    this.loadedT = false;
    this.totalAlert = 0;
    this.totalAlertCatN = [];
    this.totalAlertCatT = [];
    this.totalAlertCatSpanN = [];
    this.totalAlertCatSpanT = [];
    this.movimentosNavieros = {};
    this.alertT = [];
    this.TalertA = [];
    this.NalertA = [];
    this.TalertS = [];
    this.NalertS = [];
    this.TalertAPanico = [];
    this.TalertSPanico = [];
    this.NalertAPanico = [];
    this.NalertSPanico = [];
    this.TalertABateria = [];
    this.NalertABateria = [];
    this.TalertSBateria = [];
    this.NalertSBateria = [];
    this.TalertAParada = [];
    this.NalertAParada = [];
    this.TalertSParada = [];
    this.NalertSParada = [];
    this.TalertAGPS = [];
    this.NalertAGPS = [];
    this.TalertSGPS = [];
    this.NalertSGPS = [];
    this.TalertAGeocerca = [];
    this.NalertAGeocerca = [];
    this.TalertSGeocerca = [];
    this.NalertSGeocerca = [];
    this.TalertAJaming = [];
    this.NalertAJaming = [];
    this.TalertSJaming = [];
    this.NalertSJaming = [];
    this.TalertARuta = [];
    this.NalertARuta = [];
    this.TalertSRuta = [];
    this.NalertSRuta = [];

    this.TalertAAditamentos = [];
    this.TalertSAditamentos = [];

    this.TalertAFunciones = [];
    this.TalertSFunciones = [];

    this.TalertASN = [];
    this.NalertASN = [];
    this.TalertSSN = [];
    this.NalertSSN = [];
  }
  temporizadores = [];
  conteoIncidencias = [];

  /**
   * Función para hacer el efecto de salto en los iconos de las alertas.
   */
  makeEffect = (categoria) => {
    this.setState({ divIsHighLighted: { opcion: true, tipo: categoria } });
    const timer = setTimeout(() => {
      this.setState({ divIsHighLighted: { opcion: false, tipo: "" } });
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  };

  /**
   * Función para agregar el el tiempo en la BD y en array.
   */
  addToArray = async (element) => {
    const response = await fetch(url + "temporizadores", {
      method: "POST",
      body: JSON.stringify({
        ...element,
        creacion: timeToDateFill(element.creacion),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + "Bearer " + sessionStorage.getItem("tok"),
      },
    });
    if (!response.ok) {
      throw new Error("Algo salió mal!");
    }
    let data = await response.json();
    this.temporizadores.push({
      ...element,
      creacion: element.creacion.getTime(),
      idTemporizadores: data.idTemporizadores,
    });
  };

  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  };
  /**
   * Función para cerrar el conteo de incidencias (SideBar).
   */
  closeStatusSideBar = () => {
    this.setState({ flagSideBar: false });
  };
  /**
   * Función para contar las icnidencias de todos los objetos según el tipo de incidencia.
   */
  conteo = () => {
    this.conteoIncidencias = [];
    let incidencias = this.state.incidencias;
    let movimientos_filtrados = undefined;
    for (var i = 0; i < incidencias.length; i++) {
      movimientos_filtrados = this.props.movimientos_terrestres_object.filter(
        (mov) => mov.idUltimaIncidencia === incidencias[i].id
      );
      if (movimientos_filtrados && movimientos_filtrados.length > 0) {
        this.conteoIncidencias.push(
          <ItemMenu
            color="#F59CF5" //rosa
            text={incidencias[i].nombre}
            count={movimientos_filtrados.length}
          />
        );
      }
    }
  };
  /**
   * Función para notificar si el temporizador ha terminado.
   */
  revisionTemporizador = () => {
    this.temporizadores = this.temporizadores.filter((element, index) => {
      if (
        (new Date().getTime() - new Date(element.creacion).getTime()) / 60000 <
        element.minutos
      ) {
        return element;
      } else {
        this.setState((prevstate) => ({
          tiemposAlertas: [...prevstate.tiemposAlertas, element],
        }));
        tiempos.splice(index, 1);
        //DELETE FROM DATABASE
        fetch(url + "temporizadores/" + element.idTemporizadores, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + "Bearer " + sessionStorage.getItem("tok"),
          },
        });
      }
    });
  };

  /**
   * Función para pintar las alertas sin tratar y las tratadas.
   */
  calcularAlertas = (catalogos, alertasCategorias, tipo, opcion) => {    
    return catalogos
      .sort(function (a, b) {
        if (a["categoria"] > b["categoria"]) {
          return 1;
        }
        if (a["categoria"] < b["categoria"]) {
          return -1;
        }
        return 0;
      })
      .map((c, index) => (
        <div        
          className={`${!alertasCategorias[c.categoria] ? "collapse " : ""}${
            this.state.divIsHighLighted.opcion &&
            this.state.divIsHighLighted.tipo === c.categoria &&
            tipo === "Atendidas"
              ? "p-2 " + classes.bump
              : "p-2"
          }`}
        >
          <ModalLay
            tamanio={"60%"}
            title={
              <div>               
                {alertasCategorias[c.categoria] && (
                  <div>
                    <i
                      className={`fa ${alertasCategorias[c.categoria][0].logo}`}
                      style={{
                        "fontSize": "20px",
                        color: alertasCategorias[c.categoria][0].color,
                      }}
                    ></i>
                    {c.categoria}
                    {tipo === "NoAtentidas"
                      ? "(" +
                        alertasCategorias[c.categoria].filter(
                          (word) => word.alert.atendida
                        ).length +
                        ")"
                      : "(" +
                        alertasCategorias[c.categoria].filter(
                          (word) => !word.alert.atendida
                        ).length +
                        ")"}
                  </div>
                )}
              </div>
            }
            name={
              <div
                className={
                  tipo === "NoAtendidas" ? "text-primary" : "text-danger"
                }
              >
                {alertasCategorias[c.categoria] && (
                  <div>
                    <i
                      className={
                        this.state.divIsHighLighted.opcion &&
                        this.state.divIsHighLighted.tipo === c.categoria &&
                        tipo === "Atendidas"
                          ? `fa ${alertasCategorias[c.categoria][0].logo} ${
                              classes.lightyOut
                            }`
                          : `fa ${alertasCategorias[c.categoria][0].logo}`
                      }
                      style={{
                        "fontSize": "20px",
                        color: alertasCategorias[c.categoria][0].color,
                        cursor: "pointer",
                      }}
                      title={c.categoria}
                    />
                    <span className="h5 ml-1">
                      {
                        alertasCategorias[c.categoria].filter((word) =>
                          tipo === "NoAtendidas"
                            ? word.alert.atendida
                            : !word.alert.atendida
                        ).length
                      }
                    </span>
                  </div>
                )}
              </div>
            }
            formulario={AlertasListar}
            datosForm={{
              accion: tipo === "NoAtendidas" ? "atendidas" : "modificar",
              usuario: user,
              categoria: this.categoriasObj[c.categoria] ? this.categoriasObj[c.categoria].alertaCategoria : {},              
              alerta: alertasCategorias[c.categoria]
                ? alertasCategorias[c.categoria].filter((word) =>
                    tipo === "NoAtendidas"
                      ? word.alert.atendida
                      : !word.alert.atendida
                  )
                : [],

              getfuntion: this.getfuntion,
              stor: this.props,
              closeModal: this.hiddenmodal,
              setAlerta: this.setAlerta,
              plataformaTipo: opcion,
              MovimientosFull: this.state.MovimientosFull,
              gps:
                tipo !== "NoAtendidas" && opcion === "Terrestre"
                  ? this.state.gpsTerrestreReciente
                  : tipo !== "NoAtendidas" && opcion === "Naviera"
                  ? this.state.gpsNavieroReciente
                  : null,
              movimientos:
                tipo !== "NoAtendidas" && opcion === "Terrestre"
                  ? this.state.movimentosTerrestres
                  : tipo !== "NoAtendidas" && opcion === "Naviera"
                  ? this.state.movimentosNavieros
                  : null,
            }}
          />
        </div>
      ));
  };

  /**
   * Función para cerrar modal y mostrar botón de maximizado.
   */
  maximizeModal = () => {
    this.setState({ flagMaximizeModal: true });
  };
  /**
   * Función que arma los perfiles del Usuario por cada Plataforma el Perfil
   */
  getPerfiles = () => {
    let perf = {};
    for (let item of currentUser) {
      if (item) {
        if (item.perfil && item.plataforma) {
          if (perf[item.perfil.id]) {
            if (!perf[item.perfil.id][item.plataforma.plataformaTipo.id]) {
              perf[item.perfil.id][item.plataforma.plataformaTipo.id] = [];
            }
          } else {
            perf[item.perfil.id] = {};
            perf[item.perfil.id][item.plataforma.plataformaTipo.id] = [];
          }
          perf[item.perfil.id][item.plataforma.plataformaTipo.id] = {
            perfil: item.perfil,
            plataforma: item.plataforma,
            funcionalidades: [],
          };
        }
      }
    }
    Object.keys(perf).forEach((key, indice) => {
      Object.keys(perf[key]).forEach((key2, indice2) => {
        if (perf[key][key2].plataforma.plataformaTipo) {
          if (
            perf[key][key2].plataforma.plataformaTipo.nombre.toUpperCase() ===
            "TERRESTRE"
          ) {
            this.getfuntion(
              "get",
              "",
              "",
              "funcionalidades/Monitoreo_Terrestre/" + key,
              "Monitoreo_Terrestre"
            )
              .then((returnVal) => {
                perf[key][key2].funcionalidades = returnVal;
                let PlataformasPerfiles = {};
                for (let item of currentUser) {
                  if (item) {
                    if (item.perfil && item.plataforma) {
                      PlataformasPerfiles[item.plataforma.id] = {
                        perfil: item.perfil,
                        plataforma: item.plataforma,
                        funcionalidades:
                          perf[item.perfil.id][
                            item.plataforma.plataformaTipo.id
                          ].funcionalidades,
                      };
                    }
                  }
                }
                this.setState({ PlataformasPerfiles: PlataformasPerfiles });
              })
              .catch((err) => {
                console.log("291 Error: " + err);
              });
          }
          if (
            perf[key][key2].plataforma.plataformaTipo.nombre
              .toUpperCase()
              .includes("NAVIERA")
          ) {
            this.getfuntion(
              "get",
              "",
              "",
              "funcionalidades/Monitoreo_Naviero/" + key,
              "Monitoreo_Naviero"
            )
              .then((returnVal) => {
                perf[key][key2].funcionalidades = returnVal;
                let PlataformasPerfiles = {};
                for (let item of currentUser) {
                  if (item) {
                    if (item.perfil && item.plataforma) {
                      PlataformasPerfiles[item.plataforma.id] = {
                        perfil: item.perfil,
                        plataforma: item.plataforma,
                        funcionalidades:
                          perf[item.perfil.id][
                            item.plataforma.plataformaTipo.id
                          ].funcionalidades,
                      };
                      //PlataformasPerfiles[item.plataforma.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: perf[key][key2].funcionalidades };
                    }
                  }
                }
                this.setState({ PlataformasPerfiles: PlataformasPerfiles });
                this.setState({
                  Monitoreo_Naviero:
                    perf[currentUser[0].perfil.id][
                      currentUser[0].plataforma.plataformaTipo.id
                    ].funcionalidades,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      });
    });
  };
  componentWillMount = () => {
    this.getfuntion("get", "", "", "incidencias", "incidencias", "", "");
    this.getfuntion(
      "get",
      "",
      "",
      "plataformasAlertas/usuario/" + user.id,
      "plataformasAlertas",
      "",
      ""
    );
    this.getPerfiles();
    if (modulosMonitoreo && modulosMonitoreo) {
      this.setState({ terrestres: modulosMonitoreo.estatusT });
      this.terrestres = modulosMonitoreo.terrestres;
      this.setState({ navieras: modulosMonitoreo.estatusN });
      this.navieras = modulosMonitoreo.navieras;
    } else {
      for (let item of currentUser) {
        if (item) {
          if (item.plataforma) {
            if (item.plataforma.plataformaTipo) {
              switch (item.plataforma.plataformaTipo.nombre) {
                case "Terrestre":
                  {
                    this.setState({ terrestres: true });
                    this.terrestres = true;
                  }
                  break;
                case "Naviera":
                  {
                    this.setState({ navieras: true });
                    this.navieras = true;
                  }
                  break;
                case "Terrestre naviera":
                  {
                    this.setState({ navieras: true });
                    this.navieras = true;
                  }
                  break;
                default:
                  break;
              }
            }/*
            if (this.state.terrestres && this.state.navieras) {
              localStorage.setItem(
                "modulosMonitoreo",
                JSON.stringify({
                  navieras: this.navieras,
                  estatusN: this.navieras,
                  terrestres: this.terrestres,
                  estatusT: this.terrestres,
                })
              );
              break;
            }*/
          }
        }
      }
      localStorage.setItem(
        "modulosMonitoreo",
        JSON.stringify({
          navieras: this.navieras,
          estatusN: this.navieras,
          terrestres: this.terrestres,
          estatusT: this.terrestres,
        })
      );
    }
  };
  componentDidMount = () => {
    //Función que trae los temporizadores de la BD
     this.fetchData();
     this.getCicloFetch();      
     this.getTemporizador();
     
  
  }

  fetchData = async () => {
    this.temporizadores = [];
    await fetch(
      url + "temporizadores/" + currentUser[0].usuario.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + "Bearer " + sessionStorage.getItem("tok"),
        },
      }
    ).then((response) => response.json()).then(data => {
      tiempos = data;
      if(tiempos && tiempos.length > 0){
          this.temporizadores.push(...tiempos);
      }
    }).catch((err)=>{
      console.log(err);
    });
  }

  getCicloFetch = () =>{

    const cicloFetch   = setInterval(() => {
      this.fetchData();
    
        }, 60000);
      
        return () => {
          clearInterval(cicloFetch)
        };

  }

  getTemporizador = () =>{
    const interval   = setInterval(() => {
      this.revisionTemporizador();
    
        }, 1000);
      
        return () => {
          clearInterval(interval)
        };
  }

  componentDidUpdate = () => {
    if (
      this.props.funcionalidades_object !== this.funcionalidades &&
      this.props.funcionalidades_object.length > 0
    ) {
      this.funcionalidades = this.props.funcionalidades_object;
      this.getfuntion(
        "get",
        "",
        "",
        "perfilesFuncionalidades/" + currentUser[0].perfil.id
      );
    }
    if (this.state.movimentosNavieros.length > 0 && !this.loadedN) {
      this.loadedN = true;
      this.alertasN(this.state.movimentosNavieros);
      this.gpsNavieros();
      EtasN(this.state.movimentosNavieros, this.props.mtipo)
        .then((returnVal) => {
          this.setState({ etasN: returnVal });
        })
        .catch((err) => {
          console.log(err);
        });
      var modulosMonitoreoN = JSON.parse(
        localStorage.getItem("modulosMonitoreo")
      );
      if (modulosMonitoreoN.estatusN) {
        this.getfuntion(
          "get",
          "",
          "",
          "usuariosCorredores/usuario/" + currentUser[0].usuario.id,
          "usuariosCorredores"
        );
      }
    }
    if (this.state.movimentosTerrestres.length > 0 && !this.loadedT) {
      this.loadedT = true;
      this.alertasT(this.state.movimentosTerrestres);
      this.gpsTerrestres();
      var modulosMonitoreoT = JSON.parse(
        localStorage.getItem("modulosMonitoreo")
      );
      if (modulosMonitoreoT.estatusT) {
        this.getfuntion(
          "get",
          "",
          "",
          "usuarioOperacionCliente/usuario/" + currentUser[0].usuario.id,
          "usuarioOperacionCliente"
        );
      }
    }
  };
  /**
   * Funciones que actualiza los GPS de Naviera y Terrestres
   */
  gpsNavieros = () => {
    if (this.state.navieras) {
      /** Movimientos navieros  ,    fuancion que actualiza , Movimientos que estan en full    */
      this.setState({ actualizandoGps: true });
      GpsEstatusNaviero(
        this.getMovimientosNavieros(),
        this.setGpsEstatusNavieros,
        this.state.MovimientosFull,
        this.props.mtipo
      );
    }
    setTimeout(this.gpsNavieros, 1000 * 60 * 4); //1000*60*1
  };
  gpsTerrestres = () => {
    if (this.state.terrestres) {
      this.setState({ actualizandoGps: true });
      GpsEstatusTerrestre(
        this.getMovimientosTerrestres(),
        this.setGpsEstatusTerrestres
      );
    }
    setTimeout(this.gpsTerrestres, 1000 * 60 * 4); //1000*60*1
  };
  /**
   * Funciones que actualizan los status de GPS de Naviera y Terrestres
   */
  setGpsEstatusNavieros = (gpsestatus) => {
    this.setState({ actualizandoGps: false });
    if ( gpsestatus.gpsEstatus ) {
      this.setState({ gpsEstatusNavieros: gpsestatus.gpsEstatus });
      this.setState({ gpsNavieroReciente: gpsestatus.gpsReciente });
      this.setState({ movsGpsEstatus: gpsestatus.movsGpsEstatus });
    }
  };
  getGpsEstatusNavieros = () => {
    return this.state.gpsEstatusNavieros;
  };
  setGpsEstatusTerrestres = (gpsestatus) => {
    this.setState({ actualizandoGps: false });
    if (gpsestatus.gpsEstatus) {
      this.setState({ gpsEstatusTerrestres: gpsestatus.gpsEstatus });
      this.setState({ gpsTerrestreReciente: gpsestatus.gpsReciente });
      
    }
  };

  /**
   * Movimientos Navieros
   */
  getMovimientosNavieros = () => {
    return this.state.movimentosNavieros;
  };
  setMovimientosNavieros = (movimientos) => {
    let movs = {};
    let estatusRuta = {};
    let headersDash = [
      { content: "Activos" },
      { content: "ontime" },
      { content: "delay's" },
    ];
    let movFull = this.state.MovimientosFull;
    for (let item of movimientos) {
      if (this.state.currentMovimientoNaviero && this.state.currentMovimientoNaviero.id) {
        if (
          item.id === this.state.currentMovimientoNaviero.id &&
          item !== this.state.currentMovimientoNaviero
        ) {
          this.setState({ currentMovimientoNaviero: item });
        }
      }
      movs[item.id] = item;
      if (item.idRuta) {
        estatusRuta[item.idRuta.nombre] = item.idRuta.nombre;
        let had = false;
        for (let h of headersDash) {
          if (h.content === item.idRuta.nombre) {
            had = true;
          }
        }
        if (!had) {
          headersDash.push({ content: item.idRuta.nombre });
        }
      }
    }
    this.setState({ estatusRuta: estatusRuta });
    this.setState({ headersDash: headersDash });

    this.getfuntion(
      "get",
      "",
      "",
      "movimientosPatio/usuario/" + currentUser[0].usuario.id,
      "movPatio"
    ).then((returnVal) => {
      let mpatio = {};
      if (returnVal) {
        returnVal.map((movPatio, index) => {
          movFull[movPatio.idMovimiento] = {
            movimiento: movs[movPatio.idMovimiento],
            full: movPatio.esFull,
          };
          mpatio[movPatio.idMovimiento] = movPatio;
        });
        for (let item of movimientos) {
          if (item.movimientoGps) {
            if (!item.movimientoGps[0]) {
              if (movFull[item.id]) {
                if (movFull[item.id].full) {
                  if (movs[movFull[item.id].full]) {
                    let mfull = movs[movFull[item.id].full];
                    if (mfull.movimientoGps[0]) {
                      for (let temmovGps of item.movimientoGps) {
                        temmovGps.idMovimiento = item.id;
                      }
                      movs[item.id].movimientoGps = item.movimientoGps; //Actualiza Movs Keys
                      movFull[item.id].movimiento = item;
                    }
                  }
                }
              }
            }
          }
        }
        this.movimentosNavieros = movs;
        this.setState({ MovimientosFull: movFull });
        this.setState({ movimentosNavieros: movimientos });
        this.setState({ movPatio: mpatio });
      }
    });
  };
  getMovimientoNavieroById = (id) => {
    return this.movimentosNavieros[id];
  };
  getMovimientoTerrestreById = (id) => {
    return this.movimentosTerrestres[id];
  };

  getMovimientosTerrestres = () => {
    return this.state.movimentosTerrestres;
  };
  setMovimientosTerrestres = (movimientos) => {
    if (movimientos !== this.state.movimentosTerrestres) {
      this.state.headersDashT = [
        { content: "Activos" },
        { content: "Logistico" },
        { content: "Pro Salud" },
        { content: "Local" },
        { content: "Transito" },
        { content: "PFIZER" },
        { content: "Dedicado" },
        { content: "Custodia" },
        { content: "BrennTag" },
        { content: "Externo B&S" },
        { content: "Externo Elite" },
        { content: "Fletes Sunlog" },
        { content: "Logistico 1" },
        { content: "Logistico 2" },
        { content: "PENDIENTE TERRESTRE" },
      ];
      this.setState({ movimentosTerrestres: movimientos });
    }
  };

  /** movimiento Naviero */
  getMovimientoNaviero = () => {
    return this.state.currentMovimientoNaviero;
  };
  setMovimientoNaviero = (movimiento) => {
    this.setState({ plataformaTipo: "Naviera" });
    this.setState({
      currentMovimientoNaviero: this.movimentosNavieros[movimiento.id],
    });
    this.mensaje.data = null;
    this.setState({ metodo: "get" });
  };
  /**  Current Movimientos */
  getMovimientoTerrestre = () => {
    return this.state.currentMovimientoTerrestre;
  };
  setMovimientoTerrestre = (movimiento) => {
    var dataTemp = {};
    Object.keys(movimiento).forEach((key) => {
      dataTemp[key] = movimiento[key];
    });
    this.setState({ plataformaTipo: "Terrestre" });
    this.setState({ currentMovimientoTerrestre: dataTemp });
    this.mensaje.data = null;
    this.setState({ metodo: "get" });
  };

  /**
   * Alertas
   */
  setAlerta = (alerta, vista) => {
    this.mensaje.accion = "put";
    this.mensaje.vista = vista;
    this.mensaje.data = alerta;
    this.mensaje.mensaje = "Se atiende alerta(" + alerta["triggerName"] + ")";
    this.props.updateStatusMensaje(true);
    this.setState({ metodo: "put" });
  };
  updateAlertasN = (data, ByCategorias) => {
    this.setState({ alertasN: data });
    this.setState({ alertasNCat: ByCategorias });
  };
  updateAlertasT = (data, ByCategorias) => {
    this.setState({ alertasT: data });
    this.setState({ alerstasTCat: ByCategorias });
  };
  /**
   * Funciones que obtienen las categroias de las alertas de Navieros y Terrestres
   */
  alertasN = (movimientos) => {
    AlertasN(movimientos, this.props.mtipo)
      .then((returnVal) => {
        this.categoriasObj = returnVal.categoriasObj;
        if (this.state.alertasN !== returnVal) {
          this.setState({ alertasN: returnVal.alertas });
          let catTemp = [];
          for (let i = 0; i < returnVal.plataformas.length; i++) {
            if (returnVal.categoriasNombres[returnVal.plataformas[i]]) {
              returnVal.categoriasNombres[returnVal.plataformas[i]].forEach(
                (key, indice) => {
                  if (!catTemp.find((elemento) => elemento.categoria === key)) {                    
                    catTemp.push({ categoria: key });
                  }
                }
              );
            }
          }
          catTemp.push({ categoria: "Desconocido" });
          this.setState({ alertasCategoriasN: catTemp });
          this.setState({ alertasNCat: returnVal.alertasCat });
        }
      })
      .catch((err) => {
        setTimeout(
          function () {
            this.alertasN(this.state.movimentosNavieros);
          }.bind(this),
          1000 * 60
        );
        console.log(err);
      });
  };

  alertasT = (movimientos) => {
    AlertasT(movimientos)
      .then((returnVal) => {
        this.categoriasObj = returnVal.categoriasObj;
        if (this.state.alertasT !== returnVal) {
          this.setState({ alertasT: returnVal.alertas });
          let catTemp = [];
          for (let i = 0; i < returnVal.plataformas.length; i++) {
            if (returnVal.categoriasNombres[returnVal.plataformas[i]]) {
              returnVal.categoriasNombres[returnVal.plataformas[i]].forEach(
                (key, indice) => {
                  if (!catTemp.find((elemento) => elemento.categoria === key)) {
                    catTemp.push({ categoria: key });
                  }
                }
              );
            }
          }
          catTemp.push({ categoria: "Desconocido" });
          this.setState({ alertasCategoriasT: catTemp });
          this.setState({ alertasTCat: returnVal.alertasCat });
        }
      })
      .catch((err) => {
        setTimeout(
          function () {
            this.alertasT(this.state.movimentosTerrestres);
          }.bind(this),
          1000 * 60
        );
        console.log("ErrorT: " + err);
      });
  };

  /**
   * Fin Alertas
   */

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "perfilesFuncionalidades",
    stateVar = "columnas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "monitoreoPage",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /*** filtro de variables  */
        switch (metodo) {
          case "get":
            {
              //Se obtiene el listdo de incidencias.
              if (stateVar === "incidencias") {
                if (returnVal) {
                  this.setState({ incidencias: returnVal });
                }
              }
              //se obtiene un listado de plataforma, cada una con un tipo de alerta.
              if (stateVar === "plataformasAlertas") {
                if (returnVal) {
                  let cat = {};
                  returnVal.map((c, index) => {
                    if (c.alertaCategoria) {
                      if (cat[c.plataforma]) {
                        if (cat[c.plataforma][c.alertaTipo.id]) {
                          cat[c.plataforma][c.alertaTipo.id] = c;
                        } else {
                          cat[c.plataforma][c.alertaTipo.id] = c;
                        }
                      } else {
                        cat[c.plataforma] = {};
                        cat[c.plataforma][c.alertaTipo.id] = c;
                      }
                    }
                  });
                  this.setState({ plataformasAlertas: cat });
                }
              }

              if (stateVar === "entregas") {
                if (returnVal) {
                  this.setState({ entregas: returnVal });
                }
              }
              if (stateVar === "columnas") {
                colsT = [];
                colsN = [];
                if (returnVal[0].id) {
                  for (let item of returnVal.sort(function (a, b) {
                    return a.id - b.id;
                  })) {
                    //var nom= this.getFuncionalidadById(item.idFuncionalidad);
                    this.getFuncionalidadById(item.idFuncionalidad);
                  } //this.setState({ columnas:cols });
                  this.setState({ columnasN: colsN });
                  this.setState({ columnasT: colsT });
                }
              }
              if (stateVar === "usuariosCorredores") {
                if (returnVal[0].idUsuario) {
                  this.setState({ usuariosCorredoresN: returnVal });
                  
                  let topics = [];
                  for (let item of returnVal) {
                    topics.push("/topic/" + item.idPlataforma + "-" + item.idCorredor);
                  }
                  this.setState({ topics: topics });
                }
              }
              if (stateVar === "usuarioOperacionCliente") {
                if (returnVal[0].idUsuario) {
                  this.setState({ usuariosCorredoresT: returnVal });
                  let topics = [];
                  for (let item of returnVal) {
                    topics.push("/topic/" + item.idPlataforma + "-" + item.idCorredor);
                  }
                  this.setState({ topics: topics });
                }
              }
              if (stateVar === "gpsPlataformaGPsgG") {
                if (returnVal[0]) {
                  if (returnVal[0].idMovimiento) {
                    this.getfuntion(
                      "get",
                      "",
                      returnVal[0].idMovimiento,
                      "unidades/" + returnVal[0].idUnidad,
                      "unidad"
                    );
                  }
                }
              }
              if (stateVar === "unidad") {
                if (returnVal.id) {
                  this.getfuntion(
                    "get",
                    "",
                    id,
                    "gpsFijo/" + returnVal.idGpsFijo,
                    "gpsFijo"
                  );
                }
              }
              if (stateVar === "gpsFijo") {
                if (returnVal.id) {
                  this.gpsPlataformaGPsgG[id].gps = returnVal.idGg;
                }
              }
              if (stateVar === "movPatio") {
                return returnVal;
              }
              if (stateVar === "Monitoreo_Naviero") {
                return returnVal;
              }
              if (stateVar === "Monitoreo_Terrestre") {
                return returnVal;
              }
            }
            break;
          case "put":
            {
              if (catalogo === "terrestresAlertas") {
                this.setAlerta(returnVal, "terrestresAlertas");
                return returnVal;
              }
              if (catalogo === "navierasAlertas") {
                this.setAlerta(returnVal, "navierasAlertas");
                return returnVal;
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };

  updateEtas = (data) => this.setState({ etasN: data });

  getFuncionalidadById = (id) => {
    for (let item of this.props.funcionalidades_object) {
      if (item.bloque.nombre == "navieraMovimientos") {
        if (item.id == id) {
          colsN.push({
            content: item.nombre,
            columna: item.nombre,
            nombre: item.nombre,
            label: item.label,
          });
        }
      }
      if (item.bloque.nombre == "terrestreMovimientos") {
        if (item.id == id) {
          colsT.push({
            content: item.nombre,
            columna: item.nombre,
            nombre: item.nombre,
            label: item.label,
          });
        }
      }
    }
  };
  hiddenNaviera = () => {
    this.setState((prevState) => {
      return { navieras: !prevState.navieras, terrestres: false };
    });
    //this.setState({ navieras: !this.state.navieras });
    localStorage.setItem(
      "modulosMonitoreo",
      JSON.stringify({
        navieras: this.navieras,
        estatusN: !this.state.navieras,
        terrestres: this.terrestres,
        estatusT: false,
      })
    );
    if (this.state.navieras) {
      this.setState({ plataformaTipo: "" });
    } else {
      this.setState({ plataformaTipo: "Naviera" });
    }
  };
  hiddenTerrestre = () => {
    this.setState((prevState) => {
      return { terrestres: !prevState.terrestres, navieras: false };
    });
    //this.setState({ terrestres: !this.state.terrestres });
    localStorage.setItem(
      "modulosMonitoreo",
      JSON.stringify({
        navieras: this.navieras,
        estatusN: false,
        terrestres: this.terrestres,
        estatusT: !this.state.terrestres,
      })
    );
    if (this.state.terrestres) {
      this.setState({ plataformaTipo: "" });
    } else {
      this.setState({ plataformaTipo: "Terrestre" });
    }
  };

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  getFuncionalidad = (elemento) => {
    var BreakException = {};
    var resultado = false;

    try {
      Object.keys(this.state.PlataformasPerfiles).forEach((key, indice) => {
        if (this.state.PlataformasPerfiles[key].funcionalidades) {
          for (let item of this.state.PlataformasPerfiles[key]
            .funcionalidades) {
            if (item.nombre == elemento) {
              resultado = true;
              throw BreakException;
            }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  };

  /**Elimina la alerta en el visualizador de alertas del Temporizador */
  removeItem = (idTemporizador) => {
    this.setState({
      tiemposAlertas: this.state.tiemposAlertas.filter(
        (item) => item.idTemporizadores !== idTemporizador
      ),
    });
  };

  changeColToFilter = (idMovimiento) => {
    this.setState({ idToFilter: idMovimiento });
  };

  render() {
    return (
      <>
        {this.state.tiemposAlertas.length > 0 && (
          <ul
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 5,
            }}
          >
            {this.state.tiemposAlertas.map((tiempo, index) => (
              <Alert
                key={index}
                tiempo={tiempo}
                setTiempos={this.removeItem}
                changeColToFilter={this.changeColToFilter}
                movimiento={
                  this.state.plataformaTipo == "Terrestre"
                    ? this.state.currentMovimientoTerrestre
                    : this.state.currentMovimientoNaviero
                }
                tipo={this.state.plataformaTipo}
                stor={this.props.privilegios_object}
                getfuntion={this.getfuntion}
                gpsReciente={
                  this.state.plataformaTipo == "Terrestre"
                    ? this.state.gpsTerrestreReciente
                    : this.state.gpsNavieroReciente
                }
                closeModal={this.hiddenmodal}
              />
            ))}
          </ul>
        )}
        {<LoadStore catalogos={["privilegios"]} />}
        <SideMenu
          flagSideBar={this.state.flagSideBar}
          setflagSideBar={this.closeStatusSideBar}
        >
          {/* ----------------- Se pintan el conteo de las Navieras -----------------*/}
          {this.state.movimentosNavieros.length > 0 && this.state.navieras && (
            <div>
              <ItemMenu
                color="black"
                text="ACTIVOS"
                count={this.state.movimentosNavieros.length}
              />
              <ItemMenu
                color="black"
                text="ONTIME"
                count={
                  this.state.movimentosNavieros.filter(
                    (mov) => timeToMilisegundos(mov["ontime"]) > 0
                  ).length
                }
              />
              <ItemMenu
                color="black"
                text="DELAYS"
                count={
                  this.state.movimentosNavieros.filter(
                    (mov) => timeToMilisegundos(mov["delay"]) > 0
                  ).length
                }
              />
              {(this.colum = [])}
              {Object.keys(this.state.estatusRuta).forEach((key, indice) =>
                this.colum.push(
                  <ItemMenu
                    color="black"
                    text={key}
                    count={
                      this.state.movimentosNavieros.filter(
                        (mov) => mov.idRuta.nombre == key
                      ).length
                    }
                  />
                )
              )}
            </div>
          )}

          {/* ----------------- Se pintan el conteo de las Terrestres -----------------*/}
          <div>
            {this.props.movimientos_terrestres_object.length && this.state.terrestres && this.conteo()}
            {this.props.movimientos_terrestres_object.length && this.state.terrestres && (
              <ItemMenu
                color="#08DB5E" //verde
                text="Activos"
                count={this.props.movimientos_terrestres_object.length}
              />
            )}
            {(this.OperacionesTipo = [])}
            { this.props.movimientos_terrestres_object.length &&
              this.props.operacionesTipo_object.map((item, i) => {
              if (
                this.props.movimientos_terrestres_object.filter(
                  (mov) => mov.operacionTipo.nombre === item.nombre
                ).length > 0 &&
                this.state.terrestres
              ) {
                this.OperacionesTipo.push(
                  <ItemMenu
                    color="#0D7AE4" //azul
                    text={item.nombre}
                    count={
                      this.props.movimientos_terrestres_object.filter(
                        (mov) => mov.operacionTipo.nombre === item.nombre
                      ).length
                    }
                  />
                );
              }
            })}
            {/* ----------------- Se pintan el conteo de las incidencias -----------------*/}

            {this.state.terrestres && <Divider dotOn={true} marginTop="20px" />}
            {this.state.terrestres && this.conteoIncidencias}
          </div>
          {/* ----------------- Se pintan el conteo de los corredores -----------------*/}
          {this.state.terrestres && <Divider dotOn={true} marginTop="20px" />}
          <div>
            {(this.Corredores = [])}

            {this.props.corredores_object.map((item, i) => {
              if (
                this.props.movimientos_terrestres_object.filter(
                  (mov) => mov.corredor.nombre === item.nombre
                ).length > 0 &&
                this.state.terrestres
              ) {
                this.Corredores.push(
                  <ItemMenu
                    color="black"
                    text={item.nombre}
                    count={
                      this.props.movimientos_terrestres_object.filter(
                        (mov) => mov.corredor.nombre === item.nombre
                      ).length
                    }
                  />
                );
              }
            })}
          </div>
        </SideMenu>
        <SiteWrapper>
          <Grid.Col sm={12} lg={12}>
            <audio id="alerta12" src="songs/alert.mp3"></audio>
            <PanelMensajeria
              hacerEvento={this.makeEffect}
              topics={this.state.topics}
              mensaje={this.mensaje}
              elementosN={this.state.alertasN}
              alertasNCat={this.state.alertasNCat}
              alertasTCat={this.state.alertasTCat}
              elementosT={this.state.alertasT}
              etasN={this.state.etasN}
              update={this.updateAlertasN}
              updateT={this.updateAlertasT}
              updateEtas={this.updateEtas}
              metodo={this.state.metodo}
              stor={this.props}
              movimientos={this.state.movimentosNavieros}
              movimientosT={this.state.movimentosTerrestres}
              navieras={this.state.navieras}
              terrestres={this.state.terrestres}
              categorias={
                this.state.terrestres
                  ? this.state.alertasCategoriasT
                  : this.state.alertasCategoriasN
              }
              plataformasAlertas={this.state.plataformasAlertas}
              movPatio={this.state.movPatio}
            />
            <TableHeader key="encabezado"
              /* ----------------- Botón de Menú de contadores -----------------*/
              clickLeftButton={() => {
                this.setState({ flagSideBar: true });
              }}
              leftButton={
                <i key="contadores" className="fa fa-bars" style={{ "fontSize": "1em" }} />
              }
              /* ----------------- Botón de Navieras -----------------*/
              rightButton1={
                <i key="botonNavieras" className="fa fa-anchor" style={{ "fontSize": "1em" }} />
              }
              clickRightButton1={this.hiddenNaviera}
              permisoButton1={
                this.estaPrivilegioActivo("monitoreo_naviero") && this.navieras 
              }
              /* ----------------- Botón de Terrestres -----------------*/
              permisoButton2={
                this.estaPrivilegioActivo("monitoreo_terrestre") && this.terrestres
              }
              estadoButton1={this.state.navieras ? "blue" : "white"}
              estadoButton2={this.state.terrestres ? "blue" : "white"}
              rightButton2={
                <i key="botonTerrestres" className="fa fa-truck" style={{ "fontSize": "1em" }} />
              }
              clickRightButton2={this.hiddenTerrestre}
              /* ----------------- Botón del Modal -----------------*/
              clickModalResumer={() => {
                this.setState({ flagMaximizeModal: false });
              }}
              modalResumer={
                this.state.flagMaximizeModal ? (
                  <i
                  key="botonModal"
                    className="fa fa-window-maximize"
                    style={{ "fontSize": "1em" }}
                  />
                ) : (
                  false
                )
              }
              alertasAtendidas={
                this.state.navieras
                  ? this.calcularAlertas(
                      this.state.alertasCategoriasN,
                      this.state.alertasNCat,
                      "Atendidas",
                      "Naviera"
                    )
                  : this.state.terrestres
                  ? this.calcularAlertas(
                      this.state.alertasCategoriasT,
                      this.state.alertasTCat,
                      "Atendidas",
                      "Terrestre"
                    )
                  : false
              }
              alertasNoAtendidas={
                this.state.navieras
                  ? this.calcularAlertas(
                      this.state.alertasCategoriasN,
                      this.state.alertasNCat,
                      "NoAtendidas",
                      "Naviera"
                    )
                  : this.state.terrestres
                  ? this.calcularAlertas(
                      this.state.alertasCategoriasT,
                      this.state.alertasTCat,
                      "NoAtendidas",
                      "Terrestre"
                    )
                  : false
              }
            />
            <NotificationContainer />
            {this.state.actualizandoGps && (
              <p className="font-italic font-weight-bold text-info">
                Actualizando Gps...
              </p>
            )}
            <div style={{ height: "53vh", "fontSize": "smaller" }}>
              <SplitterLayout
                vertical={false}
                percentage={true}
                secondaryInitialSize={30}
              >
                <div>
                  {(this.state.navieras || this.state.terrestres) && (
                    <SplitterLayout vertical={true}>
                      {this.state.navieras &&
                      this.estaPrivilegioActivo("monitoreo_naviero") &&
                      this.getFuncionalidad("Movimientos") ? (
                        <div>
                          <div>
                            <div className="panel panel-default">
                              <EspecificoN
                                resumeModal={this.state.flagMaximizeModal}
                                functionReanudarModal={this.maximizeModal}
                                mtipo={this.props.mtipo}
                                Ctlogo="navieraMovimientos"
                                setMovimiento={this.setMovimientoNaviero}
                                tamanio="75%"
                                FCtlogo={MonitoreoNavieroForm}
                                alertasN={this.state.alertasN}
                                etasN={this.state.etasN}
                                gpsNavieroReciente={
                                  this.state.gpsNavieroReciente
                                }
                                movsGpsEstatus={ this.state.movsGpsEstatus }
                                catalogos={{
                                  0: "etiquetas",
                                  1: "privilegios",
                                  2: "operacionesEstatus",
                                  3: "funcionalidades",
                                  4: "localidades",
                                  5: "navieraMovimientos",
                                }}
                                movPatio={this.state.movPatio}
                                columnas={this.state.columnasN}
                                usuariosCorredores={
                                  this.state.usuariosCorredoresN
                                }
                                setMovimientosNavieros={
                                  this.setMovimientosNavieros
                                }
                                PlataformasPerfiles={
                                  this.state.PlataformasPerfiles
                                }
                                idToFilter={this.state.idToFilter}
                                changeColToFilter={this.changeColToFilter}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.estaPrivilegioActivo("monitoreo_terrestre") &&
                      this.getFuncionalidad("MovimientosT") &&
                      this.state.terrestres ? (
                        <div>
                          <div>
                            <div className="panel panel-default">
                              <EspecificoT
                                resumeModal={this.state.flagMaximizeModal}
                                functionReanudarModal={this.maximizeModal}
                                mtipo={this.props.mtipo}
                                Ctlogo="terrestreMovimientos"
                                setMovimiento={this.setMovimientoTerrestre}
                                tamanio="75%"
                                FCtlogo={MonitoreoTerrestreForm}
                                alertasT={this.state.alertasT}
                                gpsTerrestreReciente={
                                  this.state.gpsTerrestreReciente
                                }
                                catalogos={{
                                  0: "operacionesEstatus",
                                  1: "operacionesTipo",
                                  2: "plataformas",
                                  3: "rutasEstatus",
                                  4: "transportistas",
                                  5: "corredores",
                                  6: "funcionalidades",
                                  7: "operadores",
                                  8: "incidencias",
                                  9: "movimientosTipo",
                                  10: "modalidades",
                                  11: "origenCatalogoMabe",
                                  12: "terrestreMovimientos",
                                }}
                                columnas={this.state.columnasT}
                                usuariosCorredores={
                                  this.state.usuariosCorredoresT
                                }
                                setMovimientosTerrestres={
                                  this.setMovimientosTerrestres
                                }
                                PlataformasPerfiles={
                                  this.state.PlataformasPerfiles
                                }
                                idToFilter={this.state.idToFilter}
                                changeColToFilter={this.changeColToFilter}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </SplitterLayout>
                  )}
                </div>
                <div>
                  {this.state.plataformaTipo == "Naviera" ? (
                    this.state.currentMovimientoNaviero ? (
                      <Panel
                        movimiento={this.state.currentMovimientoNaviero.id}
                        getMovimiento={this.state.currentMovimientoNaviero}
                        setMovimiento={this.setMovimiento}
                        rutasEstatus={this.props.rutasEstatus_object}
                        operacionesEstatus={
                          this.props.operacionesEstatus_object
                        }
                        stor={this.props}
                        plataformaTipo={"Naviera"}
                        getplataformaTipo={this.getplataformaTipo}
                        gpsEstatusNavieros={this.state.gpsEstatusNavieros}
                        privilegios_object={this.props.privilegios_object}
                        alertasN={this.state.alertasN}
                        setAlerta={this.setAlerta}
                        getMovimientoNavieroById={this.getMovimientoNavieroById}
                        movimentosNavieros={this.movimentosNavieros}
                        gpsReciente={this.state.gpsNavieroReciente}
                        PlataformasPerfiles={this.state.PlataformasPerfiles}
                        MovimientosFull={this.state.MovimientosFull}
                        otd_object={this.props.otd_object}
                        tiempos={this.temporizadores}
                        usuario={currentUser[0].usuario.id}
                        addToArray={this.addToArray}
                      />
                    ) : null
                  ) : null}
                  {this.state.plataformaTipo == "Terrestre" ? (
                    this.state.currentMovimientoTerrestre ? (
                      <Panel
                        movimiento={this.state.currentMovimientoTerrestre.id}
                        getMovimiento={this.state.currentMovimientoTerrestre}
                        setMovimiento={this.setMovimiento}
                        rutasEstatus={this.props.rutasEstatus_object}
                        operacionesEstatus={
                          this.props.operacionesEstatus_object
                        }
                        incidencias={this.props.incidencias_object}
                        plataformaTipo={"Terrestre"}
                        getplataformaTipo={this.getplataformaTipo}
                        gpsEstatusTerrestres={this.state.gpsEstatusTerrestres}
                        privilegios_object={this.props.privilegios_object}
                        alertasT={this.state.alertasT}
                        setAlerta={this.setAlerta}
                        getMovimientoTerrestreById={
                          this.getMovimientoTerrestreById
                        }
                        movimentosTerrestres={this.movimentosTerrestres}
                        gpsReciente={this.state.gpsTerrestreReciente}
                        PlataformasPerfiles={this.state.PlataformasPerfiles}
                        tiempos={this.temporizadores}
                        usuario={currentUser[0].usuario.id}
                        addToArray={this.addToArray}
                      />
                    ) : null
                  ) : null}
                  {!this.state.plataformaTipo ? (
                    this.state.currentMovimientoTerrestre ? (
                      <Panel
                        movimiento={0}
                        getMovimiento={{}}
                        setMovimiento={null}
                        updateMovimiento={null}
                        rutasEstatus={null}
                        operacionesEstatus={null}
                        plataformaTipo={null}
                        getplataformaTipo={this.getplataformaTipo}
                        gpsReciente={null}
                        privilegios_object={this.props.privilegios_object}
                      />
                    ) : null
                  ) : null}
                </div>
              </SplitterLayout>
            </div>
          </Grid.Col>
        </SiteWrapper>
      </>
    );
  }

  getplataformaTipo = (plataformaT) => {
    switch (plataformaT) {
      case "Naviera":
        if (this.state.navieras) {
          return this.state.navieras;
        }
        break;
      case "Terrestre":
        if (this.state.terrestres) {
          return this.state.terrestres;
        }
      default:
        return false;
    }
  };
}

function mapStateToProps(state) {
  return {
    movimientos_naviera_object:
      state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object:
      state.catalogos_reducer.movimientos_terrestres_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operacionesEstatus_object:
      state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operacionesClientes_object:
      state.catalogos_reducer.operacionesClientes_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object: state.catalogos_reducer.update_mensaje,
    etiquetas_object: state.catalogos_reducer.etiquetas_object,
  };
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    movimientosTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) =>
      dispach(ACTIONS.elementos_clientes_imput(elementos)),
    navieras_update: (elementos) =>
      dispach(ACTIONS.elementos_navieras_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) =>
      dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) =>
      dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) =>
      dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) =>
      dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    operacionesEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operacionesClientes_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operadores_update: (elementos) =>
      dispach(ACTIONS.elementos_operadores_imput(elementos)),
    etiquetas_update: (elementos) =>
      dispach(ACTIONS.elementos_etiquetas_imput(elementos)),
  };
}
export default connect(mapStateToProps, mapDispachToProps)(Monitoreo);