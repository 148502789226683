import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import { NotificationManager } from 'react-notifications';
import Select from "react-select";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../herramientas/DateFormat";
import { TablePageable } from "../../Helpers";
const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
let plataformasByKey= {};
const WhatsAppGruposPages =()=> {
  const [idPlataforma, setIdPlataforma]= useState({ value: 0, label: "Seleciona una plataforma"});
  const [whatsAppGrupo, setWhatsAppGrupo]= useState({});
  const [whatsAppGrupos, setWhatsAppGrupos]= useState([]);
  const [editar, setEditar]= useState(false);
  const [plataformas, setPlataformas]= useState([]);

  useEffect(()=>{
    let plataformasTemp= [];
    for( let usr of currentUser ){
      plataformasTemp.push({ value: usr.plataforma.id, label: usr.plataforma.nombre});
      plataformasByKey[usr.plataforma.id]= { value: usr.plataforma.id, label: usr.plataforma.nombre};
    }
    setPlataformas(plataformasTemp);
    getfuntion("get", [], "", "whatsapp_grupos", "whatsapp_grupos");
  },[]);
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",

    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "home",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "whatsapp_grupos") {
              let gwTemp= [];
              for( let g of returnVal){
                g.fecha= timeToDateFill(g.fecha);
                gwTemp.push(g);
              }
              setWhatsAppGrupos(gwTemp);
            }
            break;
          case "put":
            if (stateVar === "whatsapp_grupos") {
              setEditar(false);
              getfuntion("get", [], "", "whatsapp_grupos", "whatsapp_grupos");
            }
            if( stateVar === "actualizar_grupos" ){
              if( returnVal.status ){
                NotificationManager.info(returnVal.message, "Actualizar listado de grupos" );
                getfuntion("get", [], "", "whatsapp_grupos", "whatsapp_grupos");
              }else{
                NotificationManager.error(returnVal.message, "Actualizar listado de grupos" );
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => console.log(err.response) );
  };
  const guardar =()=>{
    let whatsAppGrupoTemp= {...whatsAppGrupo};
    whatsAppGrupoTemp.idPlataforma= idPlataforma.value;
    whatsAppGrupoTemp.fecha= new Date().getTime();//whatsAppGrupo.fecha
    getfuntion("put", whatsAppGrupoTemp, "", "whatsapp_grupos", "whatsapp_grupos");
  }
  return (
    <SiteWrapper>
      <div className="container-fluid" style={{ height: "90vh", "font-size": "smaller"}}>{/** width: "100%",  */}        
        <div className="row" hidden={editar}>{/** Listado de gps */}
          <div className="col-12">
            <TablePageable
              titulo={
                <h5> Gruspos de whatsApp
                  <span className="badge ml-2">
                    <button type="button" className="btn btn-primary btn-sm"
                      onClick={()=>{ getfuntion("put", true, "", "whatsapp_grupos/actualizar_grupos", "actualizar_grupos"); }}
                    >
                      <i class="fa fa-refresh" style={{"font-size": "15px"}}></i>
                      <label className="ml-2">Actualizar Listado e grupos</label>
                    </button>
                  </span>
                </h5>
              }
              lista= { whatsAppGrupos }
              columnas={
                [ 
                  { columna: "editar", label: "Editar",
                    form: (e)=>
                      <i className="fa fa-edit text-primary" style={{"font-size": "24px"}}
                        onClick={()=>{ setEditar(true); }}>
                      </i> },
                  { columna: "id", label: "Id" },
                  { columna: "nombreGrupo", label: "Nombre Grupo" },
                  { columna: "idPlataforma", label: "Plataforma",
                    form: (e)=> plataformasByKey[e.idPlataforma]? plataformasByKey[e.idPlataforma].label: e.idPlataforma,
                    valor: (e)=> plataformasByKey[e.idPlataforma]? plataformasByKey[e.idPlataforma].label: e.idPlataforma,
                  },
                  { columna: "estatus", label: "Estatus" },
                  { columna: "fecha", label: "fecha" },
                ]
              }
              id= "id"
              selected={(e)=>{
                if( !e.idPlataforma){
                  setIdPlataforma({ value: 0, label: "Seleciona una plataforma"});
                }else{
                  if(plataformasByKey[e.idPlataforma]){
                    setIdPlataforma(plataformasByKey[e.idPlataforma]);
                  }else{
                    setIdPlataforma({ value: e.idPlataforma, label: e.idPlataforma});
                  }
                }
                setWhatsAppGrupo(e);
              }}
              //csvname= "Gps Mobiles"
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>
      
        <div className="row" hidden={!editar}>
          <div className="col-12">
            <h4>{whatsAppGrupo.id}/{whatsAppGrupo.nombreGrupo}/{whatsAppGrupo.estatus? "Activo": "Inactivo"}/{whatsAppGrupo.fecha}/</h4>
          </div>
          <div className="col col-12 col-sm-12 col-md-4">
            <div className="form-group">
              <label for="envio">plataforma</label>
              <Select
                value={idPlataforma}
                onChange={(e)=>{ setIdPlataforma(e); }}
                options={plataformas} />
              <small id="envio" className="form-text text-muted">Se utilizara para mostrar en formularios</small>
            </div>
          </div>
          
          {/* ********************************************************************************************************** */}
          <div className="col-12">
            <span className="badge float-right">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="danger"
                onClick={()=>{ setEditar(false); }}
              >
                <span className="badge">
                  <Icon link={true} name="x" />
                  Cancelar
                </span>
              </Button>
            </span>
            <span className="badge float-right">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled= {!idPlataforma.value}
                onClick={()=>{ if(idPlataforma.value){ guardar();  } }}
              >
                <span className="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
}

export default WhatsAppGruposPages;
