import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import Select from "react-select";

/**
 * Date tools
 */
import { timeToDate, timeToDatetime, timeToGMTS, timeToISOS, timeToJSON, timeToLocaleTime,
         timeToLocale, timeToString, timeGetTime, timeToUTC, timeToDateFill } from "../../../../herramientas/DateFormat";
import { from } from "rxjs";
class  GpsFijo extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id : "",
                 accion : "post", //'nuevo'// modificar, nuevo
                gpsFijo : [],
          GpsHardware : { value: 0, label: "Seleccione una opción " },
                 nombre : "",
                  marca : "",
                   imei : "",
                    sim : "",
                latitud : "",
               longitud : "",
                   idGg : "",
                estatus : 0,
                 search : ""
      };
           this.gpsFijo = [];
       this.gpsHardware = [];
        this.gpsFijoObj = {
                            "id":0,
                            "plataforma": {"id":this.props.elementos.id, "nombre":"" },
                            "gpsHardware":{"id":"", "nombre":"" },
                            "nombre": "", "marca": "", "imei": "", "sim": "",
                            "latitud": "", "longitud": "", "altaFecha": null,
                            "idGg": "",
                            "estatus": 0
                          };
             this.valid = { gpsHardware: false, nombre: false, marca: false, imei: false, sim: false, latitud: false,
                            longitud: false, idGg: false };
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.getfuntion("get", "", "", "gpsFijos/plataforma/"+this.props.elementos.id,"gpsFijo")
    }else{
    }
    this.gpsHardware.push({ value: 0, label: "Seleccione una opción " })
    this.props.stor.gpsHardware_object.map((elemento, index) => {
      this.gpsHardware.push({ value: elemento.id, label: elemento.nombre });
    });

  }
  getHardware =() =>{}
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
      //console.log( returnVal )
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "gpsFijo")
              {
                this.gpsFijo= returnVal;
                this.setState({ gpsFijo: returnVal });
                //console.log(returnVal) 
              }
            }
            break;
          case "post":
            {
            if (stateVar === "gpsFijo"){
                this.gpsFijo.push(returnVal); this.setState({ gpsFijo: this.gpsFijo });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "gpsFijo" && returnVal.id){
                for (let item of this.gpsFijo) {
                  if( item.id === returnVal.id ){
                    item= returnVal;
                    this.setState({ gpsFijo: this.gpsFijo });
                    break;
                  }
                }
              }
            }
          break;
          case "delete":
            {
              if (stateVar === "gpsFijo"){
                for (let i=0; i< this.gpsFijo.length; i++) {
                  if( this.gpsFijo[i].id === id ){ this.gpsFijo.splice(i, 1); this.setState({ gpsFijo: this.gpsFijo });
                    break;
                  }
                }
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  isValid=(campo) =>{
    switch (campo) {
      case "gpsHardware": return this.valid.gpsHardware;
        break;
      case "nombre": return this.valid.nombre;
        break;
      case "marca": return this.valid.marca;
        break;
      case "imei": return this.valid.imei;
        break;
      case "sim": return this.valid.sim;
        break;
      case "latitud": return this.valid.latitud;
        break;
      case "longitud": return this.valid.longitud;
        break;
      case "idGg": return this.valid.idGg;
        break;
      default:
        break;
    }
  }

  changeGpsHardware =(event) =>{
    this.setState({gpsHardware: event});
    if(event.value > 0){ this.valid.gpsHardware= true;}else{ this.valid.gpsHardware= false }
    this.gpsFijoObj.gpsHardware = {"id":event.value, "nombre":event.label };
  }
  changeNombre =(event) =>{
    let val= event.target.value; this.setState({ nombre: val });
    if(val.length > 2){ this.valid.nombre= true; }else{ if( val.length <=2){ this.valid.nombre= false; } }
    this.gpsFijoObj.nombre = val;
  }
  changeMarca =(event) =>{
    let val= event.target.value; this.setState({ marca: val });
    if(val.length > 1){ this.valid.marca= true; }else{ if( val.length <=1){ this.valid.marca= false; } }
    this.gpsFijoObj.marca = val;
  }
  changeImei =(event) =>{
    let val= event.target.value; this.setState({ imei: val });
    if(val.length > 5){ this.valid.imei= true; }else{ if( val.length <=5){ this.valid.imei= false; } }
    this.gpsFijoObj.imei = val;
  }
  changeSim =(event) =>{
    let val= event.target.value; this.setState({ sim: val });
    if(val.length > 5){ this.valid.sim= true; }else{ if( val.length <=5){ this.valid.sim= false; } }
    this.gpsFijoObj.sim = val;
  }
  changeLatitud =(event) =>{
    let val= event.target.value; this.setState({ latitud: val })
    if(val >= -90 && val <= 90 && val !== "" ){ this.valid.latitud= true; }else{
      if( val < -90 || val > 90  || val == ""){
        this.valid.latitud= false;
      }
    }
    this.gpsFijoObj.latitud = val;
  }
  changeLongitud =(event) =>{
    let val= event.target.value; this.setState({ longitud: val })
    if(val >= -180 && val <= 180 && val !== ""){
      this.valid.longitud= true;
    }else{
      if( val < -180 || val > 180  || val == ""){
        this.valid.longitud= false;
      }
    }
    this.gpsFijoObj.longitud = val;
  }
  changeIdGG =(event) =>{
    let val= event.target.value;
    this.setState({ idGg:val });
    if(val > 2){ this.valid.idGg= true; }else{ if( val < 0){ this.valid.idGg= false; } }
    this.gpsFijoObj.id  = val;
    this.gpsFijoObj.idGg= val;
  }
  changeEstatus =(event) =>{
    let val= event.target.checked; this.setState({ estatus: val? 1 : 0 });
    this.gpsFijoObj.estatus = val? 1 : 0;
  }


  gpsEdit =(index) =>{
    let current= this.state.gpsFijo.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[index];
    this.gpsHardware.map((elem, index)=>{
      console.log(elem.value+ " === "+ current.gpsHardware)
      if(elem.value === current.gpsHardware){
        console.log("elem")
        console.log(elem)
      this.setState({ gpsHardware : elem })}
    })

    this.setState({ nombre    : current.nombre });
    this.setState({ marca     : current.marca });
    this.setState({ imei      : current.imei });
    this.setState({ sim       : current.sim });
    this.setState({ latitud   : current.latitud || "" });
    this.setState({ longitud  : current.longitud || ""});
    this.setState({ idGg      : current.idGg });
    this.setState({ estatus   : current.estatus });

    this.gpsFijoObj = current;
    this.valid  = { gpsHardware: true, nombre: true, marca: true, imei: true, sim: true, latitud: true, longitud: true, idGg: true };
    this.setState({ accion: "modificar" });
  }

  crud_put =() =>{
    this.getfuntion("put", this.gpsFijoObj, "", "gpsFijo","gpsFijo");
    this.setState({ nombre        : "" });
    this.setState({ marca         : "" });
    this.setState({ imei          : "" });
    this.setState({ sim           : "" });
    this.setState({ latitud       : "" });
    this.setState({ longitud      : ""});
    this.setState({ idGg          : "" });
    this.setState({ estatus       : "" });
    this.setState({ gpsHardware : { value: 0, label: "Seleccione una opción " } });

    this.gpsFijoObj  = {
                         "plataforma": { "id":this.props.elementos.id, "nombre":"" },
                         "gpsHardware":{ "id":"", "nombre":"" },
                         "nombre": "", "marca": "", "imei": "", "sim": "",
                         "latitud": "", "longitud": "", "altaFecha": null, "idGg": "", "estatus": 0 };
         this.valid  = { gpsHardware: false, nombre: false, marca: false, imei: false, sim: false, latitud: false,
                            longitud: false, idGg: false };
    this.setState({ accion: "nuevo" });
    this.setState({ accion: "nuevo" });
  }
  crud_delete =(id) =>{
    this.getfuntion("delete", "", id, "gpsFijos","gpsFijo");
  }
  crud_post =() =>{
    console.log( this.gpsFijoObj)
    this.getfuntion("post", this.gpsFijoObj, "", "gpsFijos","gpsFijo");
    this.setState({ nombre        : "" });
    this.setState({ marca         : "" });
    this.setState({ imei          : "" });
    this.setState({ sim           : "" });
    this.setState({ latitud       : "" });
    this.setState({ longitud      : ""});
    this.setState({ idGg          : "" });
    this.setState({ estatus       : "" });
    this.setState({ gpsHardware : { value: 0, label: "Seleccione una opción " } });

    this.gpsFijoObj  = {
                         "plataforma": {"id":this.props.elementos.id, "nombre":"" },
                         "gpsHardware":{"id":"", "nombre":"" },
                         "nombre": "", "marca": "", "imei": "", "sim": "",
                         "latitud": "", "longitud": "", "altaFecha": null, "idGg": "", "estatus": 0
                       };
         this.valid  = { gpsHardware: false, nombre: false, marca: false, imei: false, sim: false, latitud: false,
                            longitud: false, idGg: false };
    this.setState({ accion: "nuevo" });
  }

  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }



  render(){
    return (
      <div>
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card //isClosable //options={ }
                  title="gpsFijo" isCollapsible={ false } isCollapsed={ false}
                  body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar por placa..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      {
                        //console.log( this.props.stor.unidadesmarcas_object )
                      }
                      <Form.Group label="Hardware"><Select value={this.state.gpsHardware} onChange={this.changeGpsHardware} options={this.gpsHardware} /></Form.Group>
                      <Form.Group label="Nombre">
                        <Form.Input
                          type="text" name="nombre" feedback="Al menos 3 caracteres" value={this.state.nombre} placeholder="Ingresa nombre ..."
                          invalid={!this.isValid("nombre") && this.state.nombre.length > 0} valid={this.isValid("nombre") && this.state.nombre.length > 0 }
                          onChange={this.changeNombre}
                        />
                      </Form.Group>
                      <Form.Group label="Marca">
                        <Form.Input
                          type="text" name="marca" feedback="Al menos 2 caracteres" value={this.state.marca} placeholder="Ingresa marca ..."
                          invalid={!this.isValid("marca") && this.state.marca.length > 0} valid={this.isValid("marca") && this.state.marca.length > 0 }
                          onChange={this.changeMarca}
                        />
                      </Form.Group>
                      <Form.Group label="Imei">
                        <Form.Input
                          type="text" name="imei" feedback="Al menos 6 caracteres" value={this.state.imei} placeholder="Ingresa imei ..."
                          invalid={!this.isValid("imei") && this.state.imei.length > 0} valid={this.isValid("imei") && this.state.imei.length > 0 }
                          onChange={this.changeImei}
                        />
                      </Form.Group>
                      <Form.Group label="Sim">
                        <Form.Input
                          type="text" name="sim" feedback="Al menos 6 caracteres" value={this.state.sim} placeholder="Ingresa sim ..."
                          invalid={!this.isValid("sim") && this.state.sim.length > 0} valid={this.isValid("sim") && this.state.sim.length > 0 }
                          onChange={this.changeSim}
                        />
                      </Form.Group>
                      <Form.Group label="idGg">
                        <Form.Input
                          type="number" name="idGg" feedback="idGg no puede ser menor a 1" value={this.state.idGg} placeholder="Ingresa idGg ..."
                          invalid={!this.isValid("idGg") && this.state.idGg < 0} valid={this.isValid("idGg") && this.state.idGg > 0 }
                          onChange={this.changeIdGG}
                        />
                      </Form.Group>
                      <Form.Group label="Latitud">
                        <Form.Input
                          type="number" name="latitudDes" feedback="Invalida latidud" value={this.state.latitud} placeholder="Ingresa latitud ..."
                          invalid={!this.isValid("latitud") && this.state.latitud !== "" } valid={this.isValid("latitud") && this.state.latitud >= -90 && this.state.latitud <= 90  && this.state.latitud !== "" }
                          onChange={this.changeLatitud}
                        />
                      </Form.Group>
                      <Form.Group label="Longitud">
                        <Form.Input
                          type="number" name="longitudDes" feedback="Invalida longitud" value={this.state.longitud} placeholder="Ingresa longitud ..."
                          invalid={!this.isValid("longitud") && this.state.longitud !== ""} valid={this.isValid("longitud") && this.state.longitud >= -180 && this.state.longitud <= 180 && this.state.longitud !== ""}
                          onChange={this.changeLongitud}
                        />
                      </Form.Group>
                      <Form.Group label="Estatus">
                      <Form.Switch type="Checkbox" checked={ this.state.estatus } onChange={ this.changeEstatus  } />
                      </Form.Group>
                      <div className="float-right">
                        <span class="badge">
                            <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() }
                                onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
                            >
                                <span class="badge"><Icon link={true} name="save" />Guardar</span>
                            </Button>
                        </span>
                      </div>
                    </Grid.Col>
                      <Grid.Col sm={12} md={8}>
                        <Form.Group>

                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>Marca</Table.ColHeader>
                              <Table.ColHeader>Imei</Table.ColHeader>
                              <Table.ColHeader>Sim</Table.ColHeader>
                              <Table.ColHeader>Latitud</Table.ColHeader>
                              <Table.ColHeader>Longitud</Table.ColHeader>
                              <Table.ColHeader>Alta Fecha</Table.ColHeader>
                              <Table.ColHeader>idGg</Table.ColHeader>
                              <Table.ColHeader>Estatus</Table.ColHeader>
                              <Table.ColHeader>Acción</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.state.gpsFijo.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento["id"] }</Table.Col>
                                    <Table.Col>{ elemento["marca"] }</Table.Col>
                                    <Table.Col>{ elemento["imei"] }</Table.Col>
                                    <Table.Col>{ elemento["sim"] }</Table.Col>
                                    <Table.Col>{ elemento["latitud"] }</Table.Col>
                                    <Table.Col>{ elemento["longitud"] }</Table.Col>
                                    <Table.Col>{ timeToDateFill(elemento["altaFecha"]) }</Table.Col>
                                    <Table.Col>{ elemento["idGg"] }</Table.Col>
                                    <Table.Col><Form.Switch type="Checkbox" checked={ elemento["estatus"] } className={"disabled"} /></Table.Col>
                                    <Table.Col>
                                      <div className="float-right">
                                        <Button pill  size="sm" outline color="primary" onClick={ ()=> this.gpsEdit(index) }><Icon name="edit"/></Button>
                                      </div>
                                      <div className="float-right">
                                          <Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.id) }><Icon name="trash"/></Button>
                                      </div>
                                    </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>

                        </Form.Group>


                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras activar o desactivar los diferentes destinos, y eliminarlos ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default GpsFijo;
