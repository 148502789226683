import React, { Component } from "react";
import { Grid, Table, Form, Dimmer } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { from } from "rxjs";

class IncidenciasNListar extends Component {
  constructor(props){
    super(props);
    this.state = {
      searchIncidencias : "",
      usuarios          : [],
      filas             : [],
      loading           : true
    };
    this.filas= [];
  }
  componentWillMount=() =>{
      this.getfuntion("get", [], "", "usuarios", "usuarios");
      this.setState({ loading: true });
  }
  componentDidMount =() => { }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "usuarios") {
              var users={};
              returnVal.map((usr, index) =>{ users[usr.id]=usr; });
              this.setState({ usuarios: users });
              this.props.incidencias.map((elemento, index) =>{
                    var userid= elemento.atencionUsuario;
                    var persona= this.state.usuarios[userid].persona.aPaterno+" "+ this.state.usuarios[userid].persona.aMaterno+" "+this.state.usuarios[userid].persona.nombre;
                    this.filas.push(
                      <Table.Row >
                        <Table.Col>{elemento.id}</Table.Col>
                        <Table.Col>{elemento.comentario}</Table.Col>
                        <Table.Col>{elemento.fechaCreado}</Table.Col>
                        <Table.Col>{persona}</Table.Col>
                        <Table.Col><Form.Switch type="Checkbox" name="toggle" value="status" checked={ elemento.incidencia.estatus } /></Table.Col>
                        <Table.Col>{elemento.incidencia.incidenciaTipo.nombre}</Table.Col>
                        <Table.Col>{elemento.incidencia.nombre}</Table.Col>
                      </Table.Row>);
                  });
                this.setState({ loading: false });
                this.setState({filas: this.filas });
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err); });
  }
  render() {
    return (
      <Grid.Row>
        <Grid.Col>
          <Dimmer active={this.state.loading} loader>
            {this.state.filas ?

              <Table cards={true} striped={true} responsive={true} className="table-vcenter"
              headerItems={[{ content: "ID" }, { content: "Comentario" }, { content: "Fecha" }, { content: "Usuario" }, { content: "Estatus" },
                            { content: "Incidencia Tipo" }, { content: "Incidencia " } ]} >
                <Table.Body>
                  {this.state.filas}
                </Table.Body>
              </Table>
              : "  No hay Incidencias..!!"
            }
          </Dimmer>
        </Grid.Col>
      </Grid.Row>
    );
  }
}
export default IncidenciasNListar;
