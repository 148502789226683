//@flow
const strings = {
  title: "Ingresa tus credenciales",
  buttonText: "Login",
  usernameLabel: "Usuario (Username)",
  usernamePlaceholder: "Username",
  passwordLabel: "Contraseña",
  passwordPlaceholder: "Contraseña",
};

export default strings;

