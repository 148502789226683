/** Custom Hook 'useFetch' 
* Descripción: Para realizar consultas a la BD. HTTP requests
  Parámetros: 
    @requestConfig Objeto con los datos para hacer la consulta (url, método, headers y body)
    @applyData Función que se le aplica a la variable @data una vez que recibió los datos.
  Return values:
    @1 Objeto con el state error para saber si falló la consulta, sendRequest es la función para hacer la consulta. 
*/

import { useState, useCallback } from "react";
import * as conf from "../config/config";

const useFetch = () => {
  const [error, setError] = useState(null);
  const url = conf.api_raiz;

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setError(null);
    try {
      const response = await fetch(url + requestConfig.url, {
        method: requestConfig.method ? requestConfig.method : "GET",
        headers: requestConfig.headers
          ? requestConfig.headers
          : {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("tok"),
            },
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });

      if (!response.ok) {
        throw new Error("Request failed!");
      }

      const data = await response.json();
      applyData(data);
    } catch (err) {
      setError(err.message || "Something went wrong!");
    }
  }, []);

  return { error, sendRequest };
};
export default useFetch;
