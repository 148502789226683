import React from "react";
import classes from "./ItemMenu.module.css";

const ItemMenu = (props) => {
  return (
    <article class={classes.profile}>
      <span style={{ color: `${props.color}` }}>●</span>
      <span class={classes.name}>{props.text}:</span>
      <span class={classes.value}>{props.count}</span>
    </article>
  );
};

export default ItemMenu;
