import React from 'react'
import { Dimmer,Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { NotificationManager} from 'react-notifications';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class ReactivarMovimientosTerrestre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
         idMovimiento:"",
         movi:[],
         dimmerCarga:false,
         tabla:""

    };
    this.movimiento=[]
  }
  onChangeBuscador=(event)=>{
      this.setState({idMovimiento: event.target.value})
  }
 
  buscarMovimiento=()=>{
    this.setState({dimmerCarga:!this.state.dimmerCarga}) 
    Crud_Catalogos("terrestreMovimientos/"+this.state.idMovimiento,"","get","",[],"","","",[]).then((returnVal)=>{
    console.log(returnVal);   
       this.setState({movi:returnVal});

       if(this.state.movi){
           if (this.state.movi.length>1){
                 this.state.movi.map((data,index)=>{
                  this.setState({tabla:<Table.Row>
                     <Table.Col>{data.idPlataforma.nombre}</Table.Col>         
                 
            </Table.Row>});
                 })
                 
           }
           else{
              this.setState({tabla:<Table.Row>
                <Table.Col>{this.state.idMovimiento}</Table.Col>
                <Table.Col>{this.state.movi.cartaPorte }</Table.Col>
                <Table.Col>{this.state.movi.origen}</Table.Col>
                <Table.Col>{this.state.movi.deRegreso}</Table.Col>
                <Table.Col>{this.state.movi.custodia}</Table.Col>
                <Table.Col>{this.state.custodiaNombre}</Table.Col>
                <Table.Col>{this.state.checklist}</Table.Col>
                <Table.Col>{this.state.ultimaIncidencia}</Table.Col>

                <Table.Col>{<Button pill size="sm" fill color="info" onClick={()=>this.reMovimiento(this.state.movi.id)}>Reactivar</Button>}</Table.Col> 
                 
       </Table.Row>});
       
       this.setState({dimmerCarga:!this.state.dimmerCarga}) 
           }
          } 
         else{
          this.setState({tabla:<div>No hay informacion disponible</div>})
           this.setState({dimmerCarga:!this.state.dimmerCarga}) 
         }    
         
      
  
      }).catch(err =>{ console.log(err);});
  }
  
  reMovimiento = (idMovimiento) =>{
    Crud_Catalogos("terrestreMovimientos/reactivar/"+this.state.idMovimiento,"","get","",[],"","","",[]).then((returnVal)=>{
    NotificationManager.info(returnVal, returnVal);
       
    }).catch(err =>{ console.log(err);});
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {
          <div>
              
            <Grid.Row>
              <Grid.Col>
              <Dimmer active={this.state.dimmerCarga} loader>
                <Card title="Reactivacion del movimiento" isCollapsible={false} isCollapsed={false}//isClosable
                  options={<Form.Group>
                      <Grid.Row>
                        <Grid.Col>
                    <span className="float-right">
                      <Form.Input className="mb-3" placeholder="Ingresa id mov..." position="append" onChange={this.onChangeBuscador} />
                    </span>
                    </Grid.Col>
                    <Grid.Col>
                    <Button pill size="sm" outline color="info" onClick={()=>this.buscarMovimiento()}>Buscar</Button>
                    </Grid.Col>
                    </Grid.Row>
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>PLATAFORMA</Table.ColHeader>
                            <Table.ColHeader>CARTA PORTE</Table.ColHeader>
                            <Table.ColHeader>ORIGEN</Table.ColHeader>
                            <Table.ColHeader>DE REGRESO</Table.ColHeader>
                            <Table.ColHeader>ID CUSTODIA</Table.ColHeader>
                            <Table.ColHeader>NOMBRE CUSTODIA</Table.ColHeader>
                            <Table.ColHeader>CHECKLIST</Table.ColHeader>
                            <Table.ColHeader>ULTIMA INCIDENCIA</Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                            <Table.ColHeader></Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                          
                            {
                           this.state.tabla=== "" ?
                          null : this.state.tabla
                            }
                            
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
                  </Dimmer>
              </Grid.Col>

            </Grid.Row>
           
          </div>
          
          }
      </div>
      
    )
  }
}
export default ReactivarMovimientosTerrestre;
