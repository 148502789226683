import React, { Component ,useState,useEffect,ReactDOM} from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { Dimmer, Button, Icon ,Table,List } from "tabler-react";
import TableRemolques  from "./Table.react";
import TransportistasForm  from "../../../Catalogos/Transportistas/TransportistasForm";
import Call_Otds from "./Call_Otds";
import { NotificationManager} from 'react-notifications';
import ModalLayout from "../../../components/ModalLayout.react.js";
import Modal from "react-modal";
import Select from "react-select";



const UpdateRemolques = (props) => {

  const plataforma = JSON.parse(sessionStorage.getItem("plataforma")).id;
  const usuario  = JSON.parse(sessionStorage.getItem("usuario")).id
  const [listaUnidades, setListaUnidades] = useState([]);
  const [listaTransportista, setListaTransportista] = useState([]);
  const [listaColumns,setListaColumns] = useState();
  const [data,setData] =  useState();
    //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(false);
  const [transportista, setTransportista] = useState();
  const [updateData, setUpdateData] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idArrastreColumna,setIdArrastreColumna] =  useState({col: "", Desc: true});
  const [bitacoraTableHeader,setBitacoraTableHeader] =  useState([]);
  const [bitacora,setBitacora]= useState([]);
  const [loadingEntregas,setLoadingEntregas] = useState(false);
  const [hiddenModals,setHiddenModals] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  let dataTemp;

    const updateProps = (newValue,lista,unidades) =>{
      openModal()
      let newValor = {listaRemolques:unidades,idCorredor:newValue,tiposRemolques:lista};
      let datosForm={
        accion: "modificar",
        isEnable:"agregar",
        plataforma: {plataformaTipo:{id:plataforma}},
        getfuntion: getfuntion,
        tipo:2,
        stor: {privilegios_object:[],plataforma:plataforma,usuario:usuario,props:newValor},
        elementos:{}};
        setData(datosForm)
    
      
      
  }


  function BitacoraView ()
 {
  return (
    <div >
          <ModalLayout
            tamanio={"1200px"}
            title={"Bitacora de arrastre "}
            name={<Button pill size="sm" outline color="primary" 
            ><Icon name="list" />{"Bitacora de cambios ("+ ")"}</Button>}
            hiddenModal={hiddenModals} Fclose={hiddenmodal}
            formulario={
              <Dimmer active={loadingEntregas} loader>
                <Table cards={false} striped={false} responsive={true} className="table-vcenter" style={{color:"black", "font-size": "80%"}} > {/** headerItems={ state.incidenciatableheader} */}
                  <Table.Header>
                    {bitacoraTableHeader.map((item, i) =>
                        <Table.ColHeader key={i}>
                          {item.content}
                        </Table.ColHeader>)}
                  </Table.Header>{/* incidenciaColumna* */}
                  <Table.Body>
                    {
                  
                  //se ocultan bitacora de gps(7) ejp: Gps reportando sin velocidad
                  bitacora.length > 0&&  
                  bitacora.map((e, index) =>
                        <Table.Row >
                          <Table.Col>{e.idBitacoraMonitoreo}</Table.Col>
                          <Table.Col>{e.descripcion}</Table.Col>
                          <Table.Col>{e.idUsuario}</Table.Col>
                          <Table.Col>{e.accionFecha}</Table.Col>
                                                                            
                        </Table.Row>
                      )}
                    
                  </Table.Body>
                </Table>
              </Dimmer>                                    
            }
          />
        </div>
        )

 }
   
  useEffect(() => {

    getfuntion("get",{id:plataforma},{}, "plataformasTransportistas/plataforma",3);

    getfuntion("get",{id:plataforma},{}, "remolques/plataforma",2);

  
  },[data])

  useEffect(() => { 
    if(transportista){
      console.log(transportista)
      getfuntion("get",{ids:transportista.value + "/"+plataforma + "/" +usuario},{}, "transportistas_remolques/remolques/transportistas",1);
      getfuntion("get",{},{},"arrastreLog"+ "/idTransportista/"+ transportista.value+  "/plataformatipo/1",4); 
    }
    
  }, [updateData]);

  const getColumns = (lista)=>{
    let arrayColumns = [
      { columna: "corredor.id", label: "Id" },
      { columna: "corredor.nombre", label: "Localidad" },
      { columna: "fechaCreacion", label: "Hora" }

    ]
  
    lista.forEach((remolque)=>{
      arrayColumns.push({columna:"",label:remolque.nombre})
    });
    // arrayColumns.push({ columna: (<Html tiposRemolques={lista} updateProps={updateProps} />),label:"Actualizar"});
    arrayColumns.push( { columna: "Actualizar", label: "Actualizar",
     
      form: (e)=> <Button pill size="sm" outline color="primary" type="Checkbox" name="toggle" value="option1"
     // checked={ incidencias.filter(i => i.incidencia.id === e.id  ).length }
      onClick={()=> updateProps(e,lista)} ><Icon name="edit" /></Button>
    })
    arrayColumns.push({ columna:"comentarios",label:"Comentarios"})

    setListaColumns(arrayColumns)
  }

  let Html = (props) => ({
    render: function() {
      return <div>{<ModalLay
        tamanio={"90%"}
        title="Actualizar"
        name={
        <Button pill size="sm" outline color="primary">
              <Icon name="edit" />
        </Button>
        }
        formulario={TransportistasForm}
        datosForm={{
          accion: "modificar",
          isEnable:"agregar",
          plataforma: {plataformaTipo:{id:plataforma}},
          getfuntion: getfuntion,
          tipo:2,
          stor: {plataforma:plataforma,usuario:usuario,props:props},
          elementos:{},
      }}
    />}</div>;
    }
  });
  const handleChange = (e)=>{
    console.log(e)
    setTransportista(e);
    getfuntion("get",{ids:e.value + "/"+plataforma + "/" +usuario},{}, "transportistas_remolques/remolques/transportistas",1);
    getfuntion("get",{},{},"arrastreLog"+ "/idTransportista/"+ e.value+  "/plataformatipo/1",4); 
  
  }




  const table_headers_bitacora = (asc, col) => {
    //console.log(col)
      const columsTemp=[
        { col:"idBitacoraMonitoreo", label: "Evento" },
        { col:"descripcion", label: "Detalle" },
        { col:"idUsuario", label: "N0mbre Usuario" },
        { col:"accionFecha", label: "Fecha" }
      ];
      let columnaTemp=[];
      for(let c of columsTemp){//incidenciaColumna: {col: "fechaCreado", Desc: true},
        columnaTemp.push({
          content: (
            <div  onClick={() =>  sortArrastre(c.col) } >
              { c.label }
              { col === c.col ? ( <Icon name={asc ? "chevron-down":"chevron-up"} />) : null}
            </div>
          ),
        });
      }
      setBitacoraTableHeader(columnaTemp);
    };
  
  
   const sortArrastre = (columna= idArrastreColumna.col) => {
      let oldCol = idArrastreColumna.col;
      let AscDescTemp= idArrastreColumna.Desc;
      if (oldCol === columna) {
        AscDescTemp = !AscDescTemp;
      } else {
        AscDescTemp = true;
      }
  
      setIdArrastreColumna ({col: columna, Desc: AscDescTemp});
      if (oldCol === columna) {
        table_headers_bitacora( AscDescTemp, columna );
      } else {
        table_headers_bitacora(true, columna);
      }
  
      var originalArray = [...bitacora];
      if (!originalArray[0]) return;
  
      if (AscDescTemp) {
        if (typeof originalArray[0][columna] == "object") {
          originalArray = originalArray.sort(function(a, b) {
            if (a[columna] === null || b[columna] === null) {
              return -1;
            } // si es nulo lo ordena al final
            if (a[columna].nombre < b[columna].nombre) {
              return 1;
            }
            if (a[columna].nombre > b[columna].nombre) {
              return -1;
            }
            return 0;
          });
        } else {
          originalArray = originalArray.sort(function(a, b) {
            if (a[columna] < b[columna]) {
              return 1;
            }
            if (a[columna] > b[columna]) {
              return -1;
            }
            return 0;
          });
        }
        //return a[columna]-b[columna] }); //Asecendente
      } else {
        if (typeof originalArray[0][columna] == "object") {
          originalArray = originalArray.sort(function(a, b) {
            if (a[columna] === null || b[columna] === null) {
              return 1;
            } // si es nulo lo ordena al final
            if (a[columna].nombre > b[columna].nombre) {
              return 1;
            }
            if (a[columna].nombre < b[columna].nombre) {
              return -1;
            }
            return 0;
          });
        } else {
          originalArray = originalArray.sort(function(a, b) {
            if (a[columna] > b[columna]) {
              return 1;
            }
            if (a[columna] < b[columna]) {
              return -1;
            }
            return 0;
          });
        }
      }
      setBitacora(originalArray);
    }
  
    const hiddenmodal = () => {
      setHiddenModals(!hiddenModals );
    };

    const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    type,
  ) => {
    Call_Otds(
      endPoint,
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (returnVal && type == 1 ) {
                setListaUnidades(returnVal);
              }
              if(returnVal && type  == 2){
                getColumns(returnVal.remolquesTipos)
              }

              if(returnVal && type  == 3){
                let transportistasTemp = [];
                Object.keys(returnVal).forEach((key) => transportistasTemp.push({ value: returnVal[key].transportista.id, label: returnVal[key].transportista.nombre} )); 
                setListaTransportista(transportistasTemp)
              }

              if(returnVal && type== 4){
                setBitacora(returnVal);

                setTimeout(() => {
                table_headers_bitacora(true, "fechaCreado");
                }, 100);
              }
              setisLoading(true);
            }
            break;
          case "post":{
            setUpdateData(returnVal)
            NotificationManager.info("Guardado correctamente");
            
            
          } 
          break; 
          case "put":{
            setUpdateData(returnVal)
            NotificationManager.info("Remolques actualizados correctamente");
           // getfuntion("get",{ids:transportista.value + "/"+plataforma + "/" +usuario},{}, "transportistas_remolques/remolques/transportistas",1);
            
          } 
          break; 
          default:
            break;
        }
      })
      .catch((err) => {
        setisLoading(false);
        if (err.response) {
          console.log(err.response);
        } else {
        console.log("Error desconocido ...." + err);
        }
      });
  };
  

  return (
    <SiteWrapper>
          <div className="container remolques-container">
          <div>
          <List.Group>
             <List.GroupItem action icon="user"   className="operador-flex"> Selecciona a un transportista:<Select className="select-operador" name="idTransportista" value={transportista } defaultValue={transportista} onChange={(e,a)=>{ handleChange(e); }} options={listaTransportista} />    
              </List.GroupItem>      
          </List.Group>
          </div>
          <div className="row">
              <div className="col-12">
                {isloading ? ( 
                <TableRemolques
                  lista={listaUnidades}
                  columnas={
                    listaColumns
                  }
                  id="corredor"
                  selected={() => { }}
                  titulo={<h3>  Remolques </h3>}
                  csvname="Remolques Transportistas"
                  paginacion={true}
                  elementosPorPagina={4}
                  update={true}
                  bitacora={<BitacoraView/>}

                />
              ):null}  

          <Modal
            closeTimeoutMS={50}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
            style={
              props.resumirModal
                ? {
                    overlay: { display: "none" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
                : {
                    overlay: { display: "block" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
            }
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"> {"Agregar"}</h4>
                <div class="btn-group">
                  {["Modificar", "Nuevo"].includes("Agregar") && (
                    <button
                      type="button"
                      className="btn btn-primary fa fa-minus"
                      onClick={props.funcionReanudar}
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-danger fa fa-close outline pill"
                    onClick={closeModal}
                  />
                </div>
              </div>
              <div className="modal-body">
                <TransportistasForm {...data} cerrarModal={closeModal} />
              </div>
            </div>
          </Modal>
              </div>
            </div>
				  </div>
    </SiteWrapper>
      
    )
}
export default (UpdateRemolques);
