import * as ACTIONS_TYPES from './action_types'

export const UPDATE_MENSAJE = { type:ACTIONS_TYPES.UPDATE_MENSAJE }
export const update_mensaje =( status) => { return{ type: ACTIONS_TYPES.UPDATE_MENSAJE, payload: status } }

export const DASHBOARD_USUARIOS = { type:ACTIONS_TYPES.DASHBOARD_USUARIOS }
export const update_dashboard_usuarios =( usuarios ) => { return{ type: ACTIONS_TYPES.DASHBOARD_USUARIOS, payload: usuarios } }

/**
 * CATALOGOS
 */
export const ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT }
export const elementos_movimientos_naviera_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT, payload: elementos } }

export const ELEMENTOS_CLIENTES_NAVIEROS_IMPUT  = { type: ACTIONS_TYPES.ELEMENTOS_CLIENTES_NAVIEROS_IMPUT }
export const elementos_clientes_navieros_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_CLIENTES_NAVIEROS_IMPUT, payload: elementos } }
/**
 * TERRESTRES
 */
export const ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT }
export const elementos_movimientos_terrestres_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT, payload: elementos } }

export const ELEMENTOS_MOVIMIENTOSTIPO_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOSTIPO_IMPUT }
export const elementos_movimientosTipo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOSTIPO_IMPUT, payload: elementos } }

export const ELEMENTOS_ALERTASCATEGORIAS_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_ALERTASCATEGORIAS_IMPUT }
export const elementos_alertasCategorias_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_ALERTASCATEGORIAS_IMPUT, payload: elementos } }

export const ELEMENTOS_ALERTASTIPOS_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_ALERTASTIPOS_IMPUT }
export const elementos_alertasTipos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_ALERTASTIPOS_IMPUT, payload: elementos } }

export const ELEMENTOS_BLOQUES_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_BLOQUES_IMPUT }
export const elementos_bloques_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_BLOQUES_IMPUT, payload: elementos } }

export const ElEMENTOS_SERVICE_FAILURE_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_SERVICE_FAILURE_IMPUT }
export const elementos_service_failure_input = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_SERVICE_FAILURE_IMPUT, payload: elementos } }

export const ELEMENTOS_DESTINOS_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_DESTINOS_IMPUT }
export const elementos_destinos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_DESTINOS_IMPUT, payload: elementos } }

export const ELEMENTOS_INFORMES_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_INFORMES_IMPUT }
export const elementos_informes_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_INFORMES_IMPUT, payload: elementos } }

export const ElEMENTOS_INFORMESFUNCIONALIDADES_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_INFORMESFUNCIONALIDADES_IMPUT }
export const elementos_informesFuncionalidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_INFORMESFUNCIONALIDADES_IMPUT, payload: elementos } }

export const ELEMENTOS_RUTASESTATUS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_RUTASESTATUS_IMPUT }
export const elementos_rutasEstatus_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_RUTASESTATUS_IMPUT, payload: elementos } }

export const ELEMENTOS_CATEGORIAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_CATEGORIAS_IMPUT }
export const elementos_categorias_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_CATEGORIAS_IMPUT, payload: elementos } }

export const ELEMENTOS_CLIENTES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_CLIENTES_IMPUT }
export const elementos_clientes_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_CLIENTES_IMPUT, payload: elementos } }

export const ELEMENTOS_CLIENTESCONTACTOS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_CLIENTESCONTACTOS_IMPUT }
export const elementos_clientescontactos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_CLIENTESCONTACTOS_IMPUT, payload: elementos } }

export const ELEMENTOS_OPERACIONESCLIENTES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_OPERACIONESCLIENTES_IMPUT }
export const elementos_operacionesClientes_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_OPERACIONESCLIENTES_IMPUT, payload: elementos } }

export const ELEMENTOS_CORREDORES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_CORREDORES_IMPUT }
export const elementos_corredores_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_CORREDORES_IMPUT, payload: elementos } }

export const ELEMENTOS_LOCALIDADES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_LOCALIDADES_IMPUT }
export const elementos_localidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_LOCALIDADES_IMPUT, payload: elementos } }

export const ELEMENTOS_ETIQUETAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_ETIQUETAS_IMPUT }
export const elementos_etiquetas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_ETIQUETAS_IMPUT, payload: elementos } }

export const ELEMENTOS_EVENTOS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_EVENTOS_IMPUT }
export const elementos_eventos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_EVENTOS_IMPUT, payload: elementos } }

export const ELEMENTOS_FUNCIONALIDADES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_FUNCIONALIDADES_IMPUT }
export const elementos_funcionalidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_FUNCIONALIDADES_IMPUT, payload: elementos } }

export const ELEMENTOS_INCIDENCIASTIPO_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_INCIDENCIASTIPO_IMPUT }
export const elementos_incidenciastipo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_INCIDENCIASTIPO_IMPUT, payload: elementos } }

export const ELEMENTOS_PERSONAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_PERSONAS_IMPUT }
export const elementos_personas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_PERSONAS_IMPUT, payload: elementos } }

export const ELEMENTOS_PLATAFORMAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_PLATAFORMAS_IMPUT }
export const elementos_plataformas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_PLATAFORMAS_IMPUT, payload: elementos } }

export const ELEMENTOS_PLATAFORMASTIPO_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_PLATAFORMASTIPO_IMPUT }
export const elementos_plataformastipo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_PLATAFORMASTIPO_IMPUT, payload: elementos } }

export const ELEMENTOS_REGLAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_REGLAS_IMPUT }
export const elementos_reglas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_REGLAS_IMPUT, payload: elementos } }

export const ELEMENTOS_PRIVILEGIOS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_PRIVILEGIOS_IMPUT }
export const elementos_privilegios_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_PRIVILEGIOS_IMPUT, payload: elementos } }

export const ElEMENTOS_USUARIOS_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_USUARIOS_IMPUT }
export const elementos_usuarios_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_USUARIOS_IMPUT, payload: elementos } }

export const ElEMENTOS_USUARIOPERFIL_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_USUARIOPERFIL_IMPUT }
export const elementos_usuarioperfil_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_USUARIOPERFIL_IMPUT, payload: elementos } }

export const ELEMENTOS_INCIDENCIAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_INCIDENCIAS_IMPUT }
export const elementos_incidencias_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_INCIDENCIAS_IMPUT, payload: elementos } }

export const ELEMENTOS_PERFILES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_PERFILES_IMPUT }
export const elementos_perfiles_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_PERFILES_IMPUT, payload: elementos } }

export const ELEMENTOS_MOVIMIENTOSBY_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOSBY_IMPUT }
export const elementos_movimientosby_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_MOVIMIENTOSBY_IMPUT, payload: elementos } }

export const ELEMENTOS_GPS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_GPS_IMPUT }
export const elementos_gps_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_GPS_IMPUT, payload: elementos } }

export const ELEMENTOS_GPSFIJOS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_GPSFIJOS_IMPUT }
export const elementos_gpsFijos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_GPSFIJOS_IMPUT, payload: elementos } }

export const ELEMENTOS_GPSESTATUS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_GPSESTATUS_IMPUT }
export const elementos_gpsestatus_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_GPSESTATUS_IMPUT, payload: elementos } }

export const ELEMENTOS_GPSHARDWARE_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_GPSHARDWARE_IMPUT }
export const elementos_gpshardware_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_GPSHARDWARE_IMPUT, payload: elementos } }

export const ElEMENTOS_GPSTIPOCOLOCACION_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_GPSTIPOCOLOCACION_IMPUT }
export const elementos_gpstipocolocacion_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_GPSTIPOCOLOCACION_IMPUT, payload: elementos } }

export const ELEMENTOS_OPERACIONESESTATUS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_OPERACIONESESTATUS_IMPUT }
export const elementos_operacionesEstatus_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_OPERACIONESESTATUS_IMPUT, payload: elementos } }

export const elementos_operacionestipo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_OPERACIONESTIPO_IMPUT, payload: elementos } }

export const ELEMENTOS_OPERADORES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_OPERADORES_IMPUT }
export const elementos_operadores_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_OPERADORES_IMPUT, payload: elementos } }

export const elementos_transportistas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_TRANSPORTISTAS_IMPUT, payload: elementos } }

export const ELEMENTOS_OTD_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_OTD_IMPUT }
export const elementos_otd_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_OTD_IMPUT, payload: elementos } }

export const ELEMENTOS_ETACONFIGURACION_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_ETACONFIGURACION_IMPUT }
export const elementos_etaConfiguracion_imput = (elementos) =>{ return{ type:ACTIONS_TYPES.ELEMENTOS_ETACONFIGURACION_IMPUT, payload:elementos } }

export const ELEMENTOS_NAVIERAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_NAVIERAS_IMPUT }
export const elementos_navieras_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_NAVIERAS_IMPUT, payload: elementos } }

export const ELEMENTOS_ACUMULADORES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_ACUMULADORES_IMPUT }
export const elementos_acumuladores_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_ACUMULADORES_IMPUT, payload: elementos } }

export const ELEMENTOS_BITACORAMONITOREO_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_BITACORAMONITOREO_IMPUT }
export const elementos_bitacoraMonitoreo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_BITACORAMONITOREO_IMPUT, payload: elementos } }

export const elementos_salasMonitoreo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_SALASMONITOREO_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADES_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_UNIDADES_IMPUT }
export const elementos_unidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADES_IMPUT, payload: elementos } }

export const ElEMENTOS_UNIDADES_NAVIERAS_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_UNIDADES_NAVIERAS_IMPUT }
export const elementos_unidades_navieras_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADES_NAVIERAS_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADES_MARCAS_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_UNIDADES_MARCAS_IMPUT }
export const elementos_unidadesmarcas_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_UNIDADES_MARCAS_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADTIPO_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_UNIDADTIPO_IMPUT }
export const elementos_unidadTipo_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADTIPO_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADELEMENTO_IMPUT = {type: ACTIONS_TYPES.ElEMENTOS_UNIDADELEMENTO_IMPUT }
export const elementos_unidadElementos_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADELEMENTO_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADESTATUSOPERACION_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_UNIDADESTATUSOPERACION_IMPUT }
export const elementos_unidadEstatusOperacion_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADESTATUSOPERACION_IMPUT, payload: elementos } }

export const ELEMENTOS_UNIDADTIPOCOLOCACION_IMPUT = { type: ACTIONS_TYPES.ElEMENTOS_UNIDADTIPOCOLOCACION_IMPUT }
export const elementos_unidadTipoColocacion_imput = ( elementos) => { return { type: ACTIONS_TYPES.ElEMENTOS_UNIDADTIPOCOLOCACION_IMPUT, payload: elementos } }

export const ELEMENTOS_ALERTASUNIDADES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_ALERTASUNIDADES_IMPUT }
export const elementos_alertasUnidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_ALERTASUNIDADES_IMPUT, payload: elementos } }

export const ELEMENTOS_MODALIDADES_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_MODALIDADES_IMPUT }
export const elementos_modalidades_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_MODALIDADES_IMPUT, payload: elementos } }

export const ELEMENTOS_ORIGENCATALOGOMABE_IMPUT = { type: ACTIONS_TYPES.ELEMENTOS_ORIGENCATALOGOMABE_IMPUT }
export const elementos_origencatalogomabe_imput = ( elementos) => { return { type: ACTIONS_TYPES.ELEMENTOS_ORIGENCATALOGOMABE_IMPUT, payload: elementos } }
