import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToDatefull } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";


const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

let funcionalidades= [];
//let fecha1= null; timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),


const ReportesPage = () => {
  const [ plataformas, setPlataformas ] = useState( []);
  const [ plataformasChecked, setPlataformasChecked ] = useState( []);
  const [ plataforma, setPlataforma ] = useState( { value: 0, label: "Seleccione una opción", data: null } );
  const [ informes, setInformes ] = useState([]);
  const [ informe, setInforme ] = useState( {} );
  const [ datos, seDatos ] = useState([]);
  const [ fecha1, setFecha1]= useState(null);
  const [ fecha2, setFecha2]= useState(null);
  const [ todas, setTodas]= useState(false);
  const [ tipoMovimiento, setTipoMovimiento]= useState("0");
  const [ ano, setAno ] = useState(new Date().getFullYear());
  const [ semana, setSemana ] = useState(new Date().getWeekNumber());

  useEffect(() => {
    let plataformasTemp= [];
    currentUser.map((cUser, index)=> plataformasTemp.push({ value: cUser.plataforma.id, label: cUser.plataforma.nombre, data: cUser }) );
    setTimeout(() => {
      setPlataformas(plataformasTemp);  
    }, 1000);
  },[]);

  const changePlataforma=(e)=>{
    setPlataforma(e);
    if( e.value ){
      crud("get", "", "", "informes/perfil/" + e.data.perfil.id, "informes");   
    }
  }
  const changePlataformasChecked=(e)=>{
   // alert(plataformasChecked.indexOf(e.target.value));

    var updatedList = [...plataformasChecked];
    if (e.target.checked) {
      updatedList = [...plataformasChecked, e.target.value];
    } else {
      updatedList.splice(plataformasChecked.indexOf(e.target.value), 1);
    }
    setPlataformasChecked(updatedList);   
   // console.log(plataformasChecked);
  }

  const changeInforme=(e)=>{
    seDatos([]);
    if([3].includes(e.value)){
      setFecha1(timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*1) ));
      setFecha2(timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*1) ));
    }else{
      setFecha1(null);
      setFecha2(null);
    }
    setInforme(e);
    if(e.value){
      crud("get", "", e.value, "funcionalidades/informe/" + e.value, "funcionalidades");
    }    
  }
  
  const changeFecha1 =(e)=> { setFecha1(e.target.value);};
  const changeFecha2 =(e)=> { setFecha2(e.target.value);};

  const changeWeek =(e)=> { setSemana(e.target.value)};
  const changeAno =(e)=> { setAno(e.target.value)};

  const getData =()=>{
   // alert(informe.data.bloque);
   // alert(informe.value);
    switch (informe.data.bloque) {
      case 102: //Reporte Naviero
      {
        if( !plataforma.data.perfil.movimientosBy ){ return; }
        switch (informe.value) {
          case 1://Reporte naviero(generico)
            if( !(fecha1 && fecha2 && informe.data) ){ return; }//Se valida que este seleccionado un informe y fechas seleccionadas
            if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }//Validamos que la fecha 2 sea mayor a la fecha 1
            crud("get", [], "", "navieraMovimientos/reporte_movimientos/plt/"+plataforma.value+"/"+fecha1+" 00:00:00/"+fecha2+" 23:59:59", "data");
            break;        
        
          default:{
            if( !(fecha1 && fecha2 && informe.data) ){ return; }//Se valida que este seleccionado un informe y fechas seleccionadas
            if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }//Validamos que la fecha 2 sea mayor a la fecha 1
            crud("get", [], "", "navieraMovimientos/reporte_movimientos/plt/"+plataforma.value+"/"+fecha1+" 00:00:00/"+fecha2+" 23:59:59", "data");
          }
            break;
        }            
      }break;
      case 201: //Reporte_C4_Msk
        {
          if( !plataforma.data.perfil.movimientosBy ){ return; }
          switch (informe.value) {
            case 4://ad - hoc
              if( plataforma.data.perfil.movimientosBy === 2){  //corredores
                if(todas){
                  if(tipoMovimiento === "0") {
                    crud("get", [], "", "navieraMovimientos/usuario/" + currentUser[0].usuario.id, "data");
                  }else{
                    crud("get", [], "", "navieraMovimientos/usuario/" + currentUser[0].usuario.id+"/"+tipoMovimiento, "data");
                  }                    
                }else{//plataforma
                  crud("get", [], "", "navieraMovimientos/activos/plt/"+plataforma.value+"/usr/"+currentUser[0].usuario.id, "data");
                }
              }
              break;
            case 3://equipos vacios //1 solo importación
              if( plataforma.data.perfil.movimientosBy === 2){
                if( !(fecha1 && fecha2 && informe.data) ){ return; }
                if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }
                crud("get", [], "", "navieraMovimientos/fecha_termino/plt/"+plataforma.value+"/mtipo/1/usr/"+currentUser[0].usuario.id+"/fecha1/"+fecha1+" 00:00:00/fecha2/"+fecha2+" 23:59:59", "data");
                //crud("get", [], "", "navieraMovimientos/fecha_termino/plt/"+plataforma.value+"/usr/"+currentUser[0].usuario.id+"/fecha1/"+fecha1+" 00:00:00/fecha2/"+fecha2+" 23:59:59", "data");
              }
              break;
            
          
            default:{
              if( !(fecha1 && fecha2 && informe.data) ){ return; }//Se valida que este seleccionado un informe y fechas seleccionadas
              if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }//Validamos que la fecha 2 sea mayor a la fecha 1
              if( plataforma.data.perfil.movimientosBy === 2){  //corredores                
                crud("get", [], "", "navieraMovimientosPerfil/usuario/" + currentUser[0].usuario.id, "data");
              }
              if( plataforma.data.perfil.movimientosBy === 3){//transportistas
                crud("get", [], "", "navieraMovimientosPerfil/usuario_transportista/" + currentUser[0].usuario.id+"/fecha1/"+fecha1+" 00:00:00/fecha2/"+fecha2+" 23:59:59", "data");
              }
            }
              break;
          }            
        }
          break;



          case 234: // reporte movimientos importacion semanal
          switch (informe.value) {

            case 299: 
              alert('Reporte de Movimientos de Mabe: LL');
             // crud("get", [], "", "informes/movimientosGeneralesTerrestreMabe/pagina/0/total/50/variables/" + fecha1 +"/"+ fecha2 +"/529/1,2,3,4/1,2,3,4", "data"); 
              crud("get", [], "", "informes/mabe/" + fecha1 +"/"+ fecha2 , "data"); 
             break;           

           case 399: 
             alert(plataforma.value);
             crud("get", [], "", "informes/acv_actividades/" + fecha1 +"/"+ fecha2  +"/"+plataforma.value, "data"); 
            break;

             case 332: 
            // console.log('Captura en tiempo'+todas);
            // crud("get", [], "", "informes/movimientosGeneralesTerrestreMabe/pagina/0/total/50/variables/" + fecha1 +"/"+ fecha2 +"/529/1,2,3,4/1,2,3,4", "data"); 
             let plataformasStringArray='';
            // plataformas.map(  (plataformaChecada,index)=>(
             // console.log(index,plataformaChecada, plataformaChecada.value+"---"+plataformasChecked)
                // plataformasChecked!=index?console.log(index,plataformaChecada, plataformaChecada.value+"---"+plataformasChecked):null            //  alert(index)
               // plataformasChecked!=index? stringForUrl=stringForUrl+","+plataformaChecada.value:null
           //   ));
           plataformasChecked.map(plataforma=>(plataformasStringArray=plataformasStringArray+","+plataforma.value
            
            ))
            
           // console.log(plataformasChecked);
           console.log(plataformasChecked.toString())
            crud("get", [], "", "informes/capturaEnTiempo/" + fecha1 +"/"+ fecha2 +"/"+ plataformasChecked.toString(), "data"); 
            break; 
            default:{          
            }
              break;


              
          }
          break;





      case 267: // reporte otd semanal
        switch (informe.value) {
          case 68: 
            if( ano >= 2021 && semana ){  //corredores
              if(todas){                 
                  crud("get", [], "", "navieraMovimientos/otdSemanal_c4/" + ano +"/"+ semana, "data");                                      
              }else{//plataforma
                //crud("get", [], "", "navieraMovimientos/activos/plt/"+plataforma.value+"/usr/"+currentUser[0].usuario.id, "data");
              }
            }
            break; 
        
          default:{
        
          }
            break;
        }
        break;
      case 268: // reporte movimientos importacion semanal
        switch (informe.value) {
          case 69: 
            if( ano >= 2021 && semana ){  //corredores
              if(todas){                 
                  crud("get", [], "", "navieraMovimientos/movimientosImportacionSemanal_c4/" + ano +"/"+ semana, "data");                                      
              }else{//plataforma
                //crud("get", [], "", "navieraMovimientos/activos/plt/"+plataforma.value+"/usr/"+currentUser[0].usuario.id, "data");
              }         
            }
            break; 
        
          default:{
        
          }
            break;
        }
        break;
      case 269: // reporte movimientos exportacion semanal
        switch (informe.value) {
          case 70: 
            if( ano >= 2021 && semana ){  //corredores
              if(todas){                 
                  crud("get", [], "", "navieraMovimientos/movimientosExportacionSemanal_c4/" + ano +"/"+ semana, "data");                                      
              }else{//plataforma
                //crud("get", [], "", "navieraMovimientos/activos/plt/"+plataforma.value+"/usr/"+currentUser[0].usuario.id, "data");
              }
            }
            break; 
        
          default:{
        
          }break;
        }
        //case 333: // reporte suvi 
      case 39: // bloque
      {
        switch (informe.value) {
          case 134: 
          alert("Formulario de Suvi");
          crud("get", [], "", "incidencias/traxion/fecha1/" + fecha1 +" 00:00:00/fecha2/"+ fecha2 +" 23:59:59", "data"); 
          break;
          
          case 168:          
          alert("Formulario de Egoba");
          crud("get", [], "", "incidencias/egoba/fecha1/" + fecha1 +" 00:00:00/fecha2/"+ fecha2 +" 23:59:59", "data");    
          break;
          default:
            break;
        }
      }break;
      case 367: // Reporte_Contador_Arrobas_Navieras
      {
        switch (informe.value) {
          case 167://contador_arrobas_navieras
            if( !(fecha1 && fecha2 && informe.data) ){ return; }//Se valida que este seleccionado un informe y fechas seleccionadas
            if( new Date(fecha1).getTime() > new Date(fecha2).getTime() ){ return; }//Validamos que la fecha 2 sea mayor a la fecha 1
            crud("get", [], "", "informes/contador_arrobas_navieras/"+plataforma.value+"/"+fecha1+" 00:00:00/"+fecha2+" 23:59:59", "data");
            break;
          default:{
          }break;
        }            
      }break;
      
      default:
          break;
    }
  }
  const changeTodas=(e)=> setTodas(e.target.checked);

  const redirecciona=()=>{

      fetch(conf.api_raiz+'gpsLog/csv', { 
        method: 'get', 
        headers: new Headers({
        'Authorization': "Bearer "+sessionStorage.getItem("tok"),
        'Content-Type':'text/plain; charset=UTF-8'
        })
        }).then((res) => {
          return res.blob();
     })   
     .then((blob) => {
      const href = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'reporte.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
     })
}

// var checkedItems = plataformasChecked.length
//     ? plataformasChecked.reduce((total, item) => {
//         return total + ", " + item;
//       })
//     : "";

  return (
    <SiteWrapper>
      <Grid.Row>
          <Grid.Col sm={3}>
            <Select value={plataforma} onChange={ changePlataforma } options={plataformas} />
          </Grid.Col>
          <Grid.Col sm={3}>
            <Select value={informe} onChange={ changeInforme } options={informes} />
            <label>{informe.value ? informe.data.descripcion : ""}</label>
          </Grid.Col>
          {  informe.value? ![4, 68, 69, 70].includes(informe.value)?
            <Grid.Col sm={2}>
              <input type="date" id="start" name="trip-start" onChange={ changeFecha1 } value={fecha1} ></input>{/**value={ this.state.fecha1 } onChange={ this.onChangeFecha1 } */}
            </Grid.Col>
          :null :null}
          {  informe.value? ![4, 68, 69, 70].includes(informe.value)?
            <Grid.Col sm={2}>
              <input type="date" id="start" name="trip-start" onChange={ changeFecha2 } value={ fecha2 }></input>
            </Grid.Col>
          :null: null}
          {
            informe.value ? [68, 69, 70].includes(informe.value) ?            
            <Grid.Col sm={2}>
              <Form.Group label="Año: ">
                <Form.Input name="year" type="number" value={ano} onChange={changeAno} />                
              </Form.Group>
            </Grid.Col>
          :null: null}
          {
            informe.value ? [68, 69, 70].includes(informe.value) ?
            <Grid.Col sm={2}>
              <Form.Group label="Semana: ">
                <Form.Input name="week" type="number" value={semana} onChange={changeWeek} />                
              </Form.Group>
            </Grid.Col>
          :null: null}
          {  informe.value? [4, 68, 69, 70].includes(informe.value)?
            <Grid.Col>
             <Form.Switch type="Checkbox" checked={todas} onChange={ changeTodas } label={todas? "Todas las plataformas":plataforma.label}/>
            </Grid.Col>
          :null:null
         }
 <Grid.Row>
          { 
          informe.value? [332].includes(informe.value)?
             plataformas.map(  (plataformaChecada,index)=>(                     
              <Grid.Col>
              <Form.Switch  value={plataformaChecada.value}   type="Checkbox"  onChange={changePlataformasChecked } label={plataformaChecada.label}/>
              </Grid.Col>
          )) :null:null
          //console.log(plataformas)
          }
</Grid.Row>


{/* <div>
  {`Las Plataformas seleccionadas para el reporte son: ${checkedItems}`}
</div> */}


          {  informe.value? [4].includes(informe.value) && todas?
            <Grid.Col>
              <Form.Group label="Tipo de movimiento">
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "0"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >General</div>}
                  value="0"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "1"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Importación</div>}
                  value="1"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "2"? true: false}
                  disabled={false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Exportación</div>}
                  value="2"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "3"? true: false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Impo Crossborder</div>}
                  value="3"
                  isInline
                />
                <Form.Radio onChange={(a)=> setTipoMovimiento(a.target.value) }
                  checked={tipoMovimiento === "4"? true: false}
                  disabled={false}
                  name="tipoMovimiento"
                  label={<div className="ml-5" >Expo Crossborder</div>}
                  value="4"
                  isInline
                />
              </Form.Group>
            </Grid.Col>
          :null:null}
          {  informe.value? [4, 68, 69, 70].includes(informe.value) || fecha1 && fecha2 ?
            <Grid.Col sm={2}>
              <Button pill size="sm" outline color="primary" onClick={ ()=> getData() } ><Icon name="refresh-cw" /></Button>
            </Grid.Col>
          :null : null }          
          <Grid.Col sm={2}>
            <h4>{ datos.length? <Exportar crud={ null } catalogo={informe.data? informe.data.nombre: ""} data={ datos } title= {informe.data? informe.data.nombre: ""} />:null }</h4>
          </Grid.Col>

          <Grid.Col sm={2}>
                       {/* <Button   size="sm"   color="primary"   onClick={()=>redirecciona()} >
                      <span class="badge">Reporte de Productividad de Patios</span>
              </Button> */}
          </Grid.Col>
      </Grid.Row>


      
    </SiteWrapper>
  );



  function crud(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
        return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": {
                    if (stateVar === "informes") {
                        let informesTemp = [{value: 0, label: "Seleccione informe", data: null }];
                        for (let item of returnVal) {
                            informesTemp.push({ value: item.id, label: item.nombre, data: item });
                        }
                        setInformes(informesTemp);
                        setInforme({ value: 0, label: "Seleccione informe", data: null });
                    }
                  /*  if (stateVar === "csv") {
                      alert('aa');
                      window.location.href = "http://localhost:8080/employees";
                  }*/



                    if (stateVar === "funcionalidades" && returnVal) {
                        funcionalidades= returnVal;
                    }
                    if (stateVar === "data" && returnVal) {                      
                      let datosTemp= [];
                      if( tipoMovimiento === "0" ){                                                
                        returnVal.map((d, index)=>{
                          datosTemp[index]= {};
                          for( let item of funcionalidades){
                            switch (item.nombre) {
                              case 'movimientoGps':
                                  d[item.nombre].map((gps, indice)=> datosTemp[index][item.label+"-"+gps.gpsTipo.nombre]= gps.gps.nombre );
                                break;
                            
                              default:  datosTemp[index][item.label]= d[item.nombre];
                                break;
                            }
                          }
                        });
                      }else{
                        const camposT={
                          "1" : [
                              "arriboTerminalVacio",
                              "salidaTerminalLleno",
                              "inicioRuta",
                              "arriboClienteLleno",
                              "salidaClienteVacio",
                              ],
                          "2" : [
                            "arriboClienteVacio",
                            "salidaClienteLleno",
                            "arriboTerminalCargado",
                            "salidaTerminalVacio",
                            ],
                          "3":[
                            "arriboTerminalVacio",
                            "salidaTerminalLleno",
                            "inicioRuta",
                            "llegadaFrontera",
                            "salidaFrontera",
                            "arriboClienteLleno",
                            "salidaClienteVacio"
                            ],
                          "4":[
                            "arriboClienteVacio",
                            "salidaClienteLleno",
                            "llegadaFrontera",
                            "salidaFrontera",
                            "arriboTerminalCargado",
                            "salidaTerminalVacio"
                            ]
                        };
                        returnVal.map((d, index)=>{
                          datosTemp[index]= {};
                          for( let item of funcionalidades){
                            let bandera= true;
                            Object.keys(camposT).forEach(key => {
                              for(let ct of camposT[key]){
                                
                                if( ct === item.nombre){
                                  bandera= false;
                                  if(key === tipoMovimiento){
                                    datosTemp[index][item.label]= d[item.nombre];
                                  }
                                }
                              }
                            });
                            if(bandera){
                              switch (item.nombre) {
                                case 'movimientoGps':
                                    d[item.nombre].map((gps, indice)=> datosTemp[index][item.label+"-"+gps.gpsTipo.nombre]= gps.gps.nombre );
                                  break;
                              
                                default:  datosTemp[index][item.label]= d[item.nombre];
                                  break;
                              }
                            }
                            bandera= true;
                          }
                        });
                      }

                        seDatos(datosTemp);
                    }
                } break;
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
}


export default ReportesPage;
