import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
class  TeDestinos extends Component {
  constructor(props) {
    super(props);
    this.state = {
                                id: "",
                            accion: "post", //'nuevo'// modificar, nuevo
                            nombre: "",
                         direccion: "",
                           latitud: "",
                          longitud: "",
                           estatus: 0,

                  plataformaCorreo:[],
                          destinos:[],
                            search:"",
                 };
                   this.destinos  = [];
                     this.DestinosObj  = { "id":0, "idPlataforma":this.props.elementos.id, "nombre": "", "direccion": "", "latitud": -90, "longitud": -180, "estatus": 0 };
                         this.valid = {nombre: false, latitud: false, longitud: false};
  }
  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.getfuntion("get", "", "", "destinos/plataforma/"+this.props.elementos.id,"destinos")
    }else{
    }
  }

  isValid=(campo) =>{
    switch (campo) {
      case "nombre": return this.valid.nombre;
        break;
      case "direccion": return this.valid.direccion;
        break;
      case "latitud": return this.valid.latitud;
        break;
      case "longitud": return this.valid.longitud;
        break;
      default:
        break;
    }
  }
  changeNombre =(event) =>{
    this.setState({nombre: event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.nombre= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.nombre= false;
      }
    }
    this.DestinosObj.nombre = event.target.value;
  }
  changeDireccion =(event) =>{
    this.setState({direccion: event.target.value});
    if(event.target.value.length > 3 ){
      this.valid.direccion= true;
    }else{
      if(event.target.value.length > 0 && event.target.value.length <= 3){
        this.valid.direccion= false;
      }
    }
    this.DestinosObj.direccion = event.target.value;
  }

  changeLatitud =(event) =>{
    this.setState({ latitud: event.target.value })
    if(event.target.value >= -90 && event.target.value <= 90 && event.target.value !== "" ){
      this.valid.latitud= true;
    }else{
      if( event.target.value < -90 || event.target.value > 90  || event.target.value == ""){
        this.valid.latitud= false;
      }
    }
    this.DestinosObj.latitud = event.target.value;
  }
  changeLongitud =(event) =>{
    this.setState({ longitud: event.target.value })
    if(event.target.value >= -180 && event.target.value <= 180 && event.target.value !== ""){
      this.valid.longitud= true;
    }else{
      if( event.target.value < -180 || event.target.value > 180  || event.target.value == ""){
        this.valid.longitud= false;
      }
    }
    this.DestinosObj.longitud = event.target.value;
  }
  changeEstatus =(event) =>{
    this.DestinosObj.estatus = event.target.checked? 1: 0;
    this.setState({ estatus: this.DestinosObj.estatus })
  }

  DestinoEdit =(index) =>{
    let current= this.state.destinos.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[index];
    this.setState({ nombre: current.nombre });
    this.setState({ latitud: current.latitud });
    this.setState({ longitud: current.longitud });
    this.setState({ estatus: current.estatus });
    this.DestinosObj = current;
    this.valid = {nombre: true, latitud: true, longitud: true};
    this.setState({ accion: "modificar" });
  }

  crud_put =() =>{
    this.getfuntion("put", this.DestinosObj, "", "destinos","destinos");
    this.setState({ nombre: "" });
    this.setState({ latitud: "" });
    this.setState({ longitud: "" });
    this.setState({ estatus: 0 });
    this.DestinosObj = { "idPlataforma": this.props.elementos.id, "nombre": "", "latitud": -90, "longitud": -180, "estatus": 0 };
    this.valid = {nombre: false, latitud: false, longitud: false};
    this.setState({ accion: "nuevo" });
  }
  crud_delete =(id) =>{
    this.getfuntion("delete", "", id, "destinos","destinos");
  }
  crud_post =() =>{
    this.getfuntion("post", this.DestinosObj, "", "destinos","destinos");
    this.setState({ nombre: "" });
    this.setState({ direccion: "" });
    this.setState({ latitud: "" });
    this.setState({ longitud: "" });
    this.setState({ estatus: 0 });
    this.DestinosObj = { "idPlataforma": this.props.elementos.id, "nombre": "", "latitud": -90, "longitud": -180, "estatus": 0 };
    this.valid = {nombre: false, latitud: false, longitud: false};
    this.setState({ accion: "nuevo" });
  }
  disableSave =() =>{
    let band= false;
    Object.keys(this.valid).forEach(key =>{ if( !this.valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
  }



  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "destinos"){ this.destinos= returnVal; this.setState({ destinos: returnVal }); }
            }
            break;
          case "post":
            {
            if (stateVar === "destinos"){
                this.destinos.push(returnVal);
                this.setState({ destinos: this.destinos });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "destinos" && returnVal.id){
                for (let item of this.destinos) {
                  if( item.id === returnVal.id ){
                    item= returnVal;
                    this.setState({ destinos: this.destinos });
                    break;
                  }
                }
              }
            }
          break;
          case "delete":
            {
              if (stateVar === "destinos"){
                for (let i=0; i< this.destinos.length; i++) {
                  if( this.destinos[i].id === id ){ this.destinos.splice(i, 1); this.setState({ destinos: this.destinos });
                    break;
                  }
                }
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    });
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }


  render(){
    return (
      <div>
        {/**
          *   Configuracion de correo
          */
        }
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Destinos"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  //options={ }
                  body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar destino..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      <Form.Group label="Nombre">
                        <Form.Input
                          type="text" name="nombreDes" feedback="Al menos 4 caracteres" value={this.state.nombre} placeholder="Ingresa destino ..."
                          invalid={!this.isValid("nombre") && this.state.nombre.length > 0} valid={this.isValid("nombre") && this.state.nombre.length > 0 }
                          onChange={this.changeNombre}
                        />
                      </Form.Group>
                      <Form.Group label="Direccion">
                        <Form.Input
                          type="text" name="direccion" feedback="Al menos 4 caracteres" value={this.state.direccion} placeholder="Ingresa direccion ..."
                          invalid={!this.isValid("direccion") && this.state.direccion.length > 0} valid={this.isValid("direccion") && this.state.direccion.length > 0 }
                          onChange={this.changeDireccion}
                        />
                      </Form.Group>
                      <Form.Group label="Latitud">
                        <Form.Input
                          type="number" name="latitudDes" feedback="Invalida latidud" value={this.state.latitud} placeholder="Ingresa latitud ..."
                          invalid={!this.isValid("latitud") && this.state.latitud !== "" } valid={this.isValid("latitud") && this.state.latitud >= -90 && this.state.latitud <= 90  && this.state.latitud !== "" }
                          onChange={this.changeLatitud}
                        />
                      </Form.Group>
                      <Form.Group label="Longitud">
                        <Form.Input
                          type="number" name="longitudDes" feedback="Invalida longitud" value={this.state.longitud} placeholder="Ingresa longitud ..."
                          invalid={!this.isValid("longitud") && this.state.longitud !== ""} valid={this.isValid("longitud") && this.state.longitud >= -180 && this.state.longitud <= 180 && this.state.longitud !== ""}
                          onChange={this.changeLongitud}
                        />
                      </Form.Group>
                      <Form.Group label="Estatus">
                        <Form.Switch type="Checkbox" name="toggle" value="estatus"
checked={ this.state.estatus }
className={"disabled"}
onChange={ this.changeEstatus }
                                                            />
                      </Form.Group>
                      <div className="float-right">
                        <span class="badge">
                            <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() }
                                onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post() }
                            >
                                <span class="badge"><Icon link={true} name="save" />Guardar</span>
                            </Button>
                        </span>
                      </div>
                    </Grid.Col>
                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
<Table.ColHeader>ID</Table.ColHeader>
<Table.ColHeader>Nombre</Table.ColHeader>
<Table.ColHeader>Latitud</Table.ColHeader>
<Table.ColHeader>Longitud</Table.ColHeader>
<Table.ColHeader>Acción</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {
                                this.state.destinos.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ elemento["id"] }</Table.Col>
                                    <Table.Col>{ elemento["nombre"] }</Table.Col>
                                    <Table.Col>{ elemento["latitud"] }</Table.Col>
                                    <Table.Col>{ elemento["longitud"] }</Table.Col>
                                    <Table.Col>
                                      <div className="float-right">
                                        <Button pill  size="sm" outline color="primary" onClick={ ()=> this.DestinoEdit(index) }><Icon name="edit"/></Button>
                                        <span className="ml-2">
                                          <Button pill size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.id) }><Icon name="trash"/></Button>
                                        </span>
                                      </div>
                                    </Table.Col>
                                  </Table.Row>
                                )
                              }
                            </Table.Body>
                          </Table>
                        </Form.Group>


                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras activar o desactivar los diferentes destinos, y eliminarlos ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default TeDestinos;
