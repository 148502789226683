import React from 'react';
import { Form, Grid, Card, Table, Alert, Icon, Dimmer, Button } from "tabler-react";
import Select from "react-select";
let columnas= [
      {"content":"col1", "columna":"col1"},
      {"content":"col2", "columna":"col2"},
      {"content":"col3", "columna":"col3"},
      {"content":"col4", "columna":"col4"},

    ]
let elementos=[
      {col1:"dato1",col2: "dato1", col3:"dato1", col4: "dato1" },
      {col1:"dato2",col2: "dato2", col3:"dato2", col4: "dato2" },
      {col1:"dato3",col2: "dato3", col3:"dato3", col4: "dato3" },
      {col1:"dato4",col2: "dato4", col3:"dato4", col4: "dato4" }
    ]
class NavieroView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        plataforma: { value: 0, label: "Todas ...", search: "" },
            search: "",
    };
    this.plataformas = [{ value: 0, label: "Todas ...", search: "" }];
  }

  componentDidMount = () => {

    
  }
  getFuncionalidad=(elemento)=>{
    if(!this.props.funcionalidades){ return false; }
    for(let item of this.props.funcionalidades){
      if(item.nombre === elemento ){ return true; break;}
    }
    return false;
  }
  render() {
    return (
      <Grid.Col>
        <div className="m-3"></div>

        <Card>
          <Card.Header>
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-light">
                <ul className="navbar-nav mr-auto">
                  { this.getFuncionalidad("Importar")? 
                      <li className="nav-item active">  <a> <div className="float-right fa fa-cloud-upload" style={{ "width": "45px" }}>Imp       </div> </a> </li>: <div></div>}
                  { this.getFuncionalidad("Importar")? 
                      <li className="nav-item active">  <a> <div className="float-right fa fa-file-excel-o" style={{ "width": "45px" }}>Plant     </div> </a> </li>: <div></div>}
                  { this.getFuncionalidad("Exportar")?
                      <li className="nav-item active">  <a> <div className="float-right fa fa-cloud-download" style={{ "width": "45px" }}>Exp     </div> </a> </li>: <div></div>}

                  <li className="nav-item active">
                    <a> <div className="float-right" style={{ "width": "160px" }}>  <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} /> </div> </a>
                  </li>
                  <li className="nav-item active">
                    <a> <Form.Input className="lg-12" icon="search" placeholder={this.state.colToFilter} position="append" onChange={this.searching} /> </a>
                  </li>
                  { this.getFuncionalidad("Movimientos Completos")? 
                    <li className="nav-item active " data-toggle="tooltip" data-placement="top" title="Movimientos terminados">
                      <a><Button pill size="sm" outline color="primary"><Icon name="file-plus" /></Button></a>
                    </li>
                  : <div></div>}
                  { this.getFuncionalidad("Movimientos Incompletos")? 
                    <li className="nav-item active"  data-toggle="tooltip" data-placement="top" title="Movimientos terminados con datos incompletos">
                      <a><Button pill size="sm" outline color="primary"><Icon name="file-minus" /></Button></a>
                    </li>                  
                  :<div></div>}
                  { this.getFuncionalidad("Agregar Movimiento")? 
                    <li className="nav-item active">
                      <a><Button pill size="sm" outline color="primary"><Icon name="plus" /></Button></a>
                    </li>
                  :<div></div>}
                </ul>
              </nav>
            </div>
            <div className="w-5"></div>
          </Card.Header>
          <Card.Body>
            <Table cards={true} striped={false} responsive={true} className="table-vcenter" headerItems={this.state.tableheaderItems} >
              <Table.Header></Table.Header>
              <Table.Body>
                {elementos.map((elemento, index) =>
                    <Table.Row>
                      { columnas.map((columna, index) => <Table.Col>{ elemento[columna.columna] }</Table.Col> )}
                    </Table.Row>
                  )}
              </Table.Body>
            </Table>
          </Card.Body>
        </Card>
        <div>Error</div>
      </Grid.Col>
    );
  }
}
export default NavieroView
