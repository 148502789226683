import React, {  useEffect, useState} from "react";
import { List, Button, Icon } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateNumbers } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import { TablePageable } from "../../../../Helpers";

const SolicitudesTransportista =({solicitud, fresh})=> {
  const [transportistas, setTransportistas]= useState([]);
  const [transportista, setTransportista]= useState([]);
  const [ transportistaS, setTransportistaS]= useState(solicitud.transportistaS? JSON.parse(solicitud.transportistaS): null);
  useEffect(() => {
    getfuntion("get", "", "", "transportistas/tipo/1", "transportistas");
	}, []);
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if( stateVar === "transportistas" && returnVal){
              setTransportistas(returnVal);
            }
          }
          break;
        case "put":
          {
            if( stateVar === "solicitudes_cliente"){
              NotificationManager.info( "Solicitud actualizada", "Actualizacion" );
              fresh();
            }
          }
          break;
        case "post":
          {
            if( stateVar === "transportistas"){
              return returnVal;
            }
          }
          break;  
        default:
          break;
      }
    }).catch(err => { if (err.response) { console.log(err.response);  } else {console.log("Error desconocido ...." + err); } });
  }
  const crearTransportista=()=>{
    let solicitudTemp= {...solicitud};
    getfuntion("post", transportistaS, "", "transportistas", "transportistas").then((rest) => {
      getfuntion(
        "post",
        { plataforma: solicitudTemp.idPlataforma, transportista: rest, },
        "",
        "plataformasTransportistas",
        "plataformasTransportistas"
      );
  
      getfuntion(
        "post",
        {idUsuario: solicitudTemp.idUsuarioCrea, idTransportista: rest.id, idPlataforma: solicitudTemp.idPlataforma.id },
        "",
        "cli_usuarios_transportistas",
        "usuarios_transportistas"
      );
      solicitudTemp.idTransportista= rest;
      getfuntion(
        "put",
        solicitudTemp,
        "",
        "solicitudes_cliente",
        "solicitudes_cliente"
      );
    });
  }
  const vincular=()=>{
    let solicitudTemp= {...solicitud};
    getfuntion(
      "post",
      { plataforma: solicitudTemp.idPlataforma, transportista: transportista, },
      "",
      "plataformasTransportistas",
      "plataformasTransportistas"
    );

    getfuntion(
      "post",
      {idUsuario: solicitudTemp.idUsuarioCrea, idTransportista: transportista.id, idPlataforma: solicitudTemp.idPlataforma.id },
      "",
      "cli_usuarios_transportistas",
      "usuarios_transportistas"
    );
    solicitudTemp.idTransportista= transportista;
    getfuntion(
      "put",
      solicitudTemp,
      "",
      "solicitudes_cliente",
      "solicitudes_cliente"
    );
  }

  return (

    <div className= "mt-1">
      {transportistaS &&
        <p>Transportista=  
          nombre: { transportistaS.nombre}
          , nombreCorto: {transportistaS.nombreCorto}
          , nombre contacto: {transportistaS.contactoNombre}
          , telefono1: {transportistaS.telefono1}
          , telefono2: {transportistaS.telefono2}
          , correo: {transportistaS.correo}
          . Si el registro no existe en el sistema, 
            <a onClick={()=> 
              confirmAlert({
                title: 'Atención',
                message: '¿Esta usted seguro de utilizar el registro '+transportistaS.nombre+'?',
                buttons: [
                  {
                    label: 'Confirmar',
                    onClick: () => { crearTransportista() }
                  },
                  {
                    label: 'Cancelar',
                    onClick: () => {return false}
                  }
                ]
              })
            }
            href={null} className="btn text-primary"> crear registro con los datos proporcionados.</a>
        </p>
      }
      <TablePageable
        titulo={
          <div>            
            <button
              type="button"
              className="btn btn-primary float-right ml-4"
              hidden={!transportista.id}
              onClick={()=>
                confirmAlert({
                  title: 'Atención',
                  message: '¿Esta usted seguro de utilizar el registro '+transportista.nombre+'?',
                  buttons: [
                    {
                      label: 'Confirmar',
                      onClick: () => { vincular() }
                    },
                    {
                      label: 'Cancelar',
                      onClick: () => {return false}
                    }
                  ]
                })
              }
              >
              <i className="fa fa-plus" style={{"font-size": "24px"}}>Vincular a plataforma</i>
            </button>
            <h3 className="float-left"> Transportistas </h3>            
          </div> }
        lista= { transportistas }
        columnas={ [
          { columna: "id", label: "Id" },
          { columna: "nombre", label: "Nombre" },
          { columna: "contactoNombre", label: "Nombre" },
          { columna: "telefono1", label: "Tel 1" },
          { columna: "telefono1", label: "Tel 2" },
          { columna: "correo", label: "Correo" },
        ] }
        id= "id"
        selected={(e)=> { setTransportista(e) }}
        //csvname= ""
      />
    </div>

  );
}
export default SolicitudesTransportista;
