import React, { useEffect, useState } from 'react';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { Button, Card, Dimmer, Form, Icon, List } from 'tabler-react';
import Select from "react-select";
import {NotificationManager} from 'react-notifications';

var user = JSON.parse(sessionStorage.getItem("usuario"));

const DetalleAlertaSinRuta = ({esquema, alerta, refresh}) => {
    //console.log(alerta);
    
    const [ alertaSinRuta, setAlertaSinRuta ] = useState({});
    const [ incidenciaSinRuta, setIncidenciaSinRuta] = useState({
            idTransportista: null,
            idIncidencia: null,
            idAlertaTipo: null,
            operadorNombre: "",
            operadorMedio: "",
            unidadPlaca: "",
            responsable: "",
            fechaRegistro: null,
            problema: "",
            solucion: "",
            observaciones: "", 
    });
    const [ alertaSinRutaIncidencia, setAlertaSinRutaIncidencia ] = useState({});
    const [ nombreTransportista, setNombreTransportista ] = useState("");
    const [ listaTransportistas, setListaTransportistas ] = useState([]);
    const [ listaIncidencias, setListaIncidencias ] = useState([]);
    const [ transportista, setTransportista] = useState({ value: 0, label: "Seleccione una opción", data:{}});
    const [ incidencia, setIncidencia] = useState({ value: 0, label: "Seleccione una opción", data:{}});    

    useEffect(() => {  
        if(esquema === "terrestre"){            
            crud("get", "", "", "terrestresAlertasSinRuta/" + alerta.id_alerta_sin_ruta, "detalleAlerta");
            crud("get", "", "", "transportistas/listaPorIdUnidad/"+ alerta.id_unidad, "listaTransportista");
            crud("get", "", "", "incidencias/incidencia_tipo_id/662", "listaIncidencias");
            if(alerta.id_transportista !== null){
                crud("get", "", "", "transportistas/" + alerta.id_transportista, "transportista"); 
            }
            if(alerta.atendida){
                crud("get", "", "", "terrestresAlertasSinRutaIncidencia/" + alerta.id_alerta_sin_ruta, "IncidenciaSinRuta");
            }
        }       
    }, [alerta, esquema]);

    const guardar = () => {
        crud("put", alertaSinRuta , "", "terrestresAlertasSinRuta/", "alertaSinRuta");
    }    

    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                if(stateVar === "detalleAlerta") {
                    if(returnVal){
                        let alertaTemp = {...returnVal};
                        if(!alertaTemp.atendida){
                            alertaTemp.atiendeUsuario = user.persona;
                            alertaTemp.atendida = 1;
                            setIncidenciaSinRuta({
                                ...incidenciaSinRuta, 
                                id: alertaTemp.id,
                                idAlertaTipo: alertaTemp.idAlertaTipo.id,
                                responsable: user.persona.nombre + " " + user.persona.aPaterno + " " + user.persona.aMaterno,
                                unidadPlaca: alertaTemp.placa    
                            });

                        }                       
                        //console.log(returnVal);
                        setAlertaSinRuta(alertaTemp);                        
                    }
                } 
                if(stateVar === "transportista") {                    
                    if(returnVal){
                        //console.log(returnVal.nombre);
                        setNombreTransportista(returnVal.nombre); 
                    }                                                                     
                }
                if(stateVar === "IncidenciaSinRuta") {                    
                    if(returnVal){
                        //console.log(returnVal);
                        setAlertaSinRutaIncidencia(returnVal); 
                    }                                                                     
                }
                if(stateVar === "listaTransportista" && returnVal) {
                    let listaTransportistaTemp = [];
                    returnVal.map((i, index) => {                      
                        listaTransportistaTemp.push({value: i.id, label: i.nombre, data:i})
                    });
                    //console.log(returnVal);
                    setListaTransportistas(listaTransportistaTemp);          
                }
                if(stateVar === "listaIncidencias" && returnVal) {
                    let listaIncidenciasTemp = [];
                    returnVal.map((i, index) => {                      
                        listaIncidenciasTemp.push({value: i.id, label: i.nombre, data:i})
                    });
                    //console.log(returnVal);
                    setListaIncidencias(listaIncidenciasTemp);          
                  }                                                               
                    break;
                case "put":
                  if(stateVar === "alertaSinRuta" && returnVal.atendida){
                    refresh();
                    crud("post", incidenciaSinRuta , "", "terrestresAlertasSinRutaIncidencia", "IncidenciaSinRuta");
                    NotificationManager.info("Se atendio alerta de la placa: " + returnVal.placa, "Alerta", 2500);
                  }
                    break;   
                case "post":
                    if(stateVar === "IncidenciaSinRuta"){
                        //console.log(returnVal);
                        NotificationManager.info("Se creo incidencia de la placa: " + returnVal.unidadPlaca, "Incidencia", 2500);
                    }
                    break;      
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
    
  return ( 
     alerta.atendida === true ? 
     <div className= "mt-1">        
        <List.Group>
            <List.GroupItem action icon="credit-card"> id: { alerta.id_alerta_sin_ruta } </List.GroupItem>
            <List.GroupItem action icon="truck"> Linea Transportista: { alerta.id_transportista !== null ? nombreTransportista : "" }</List.GroupItem>
            <List.GroupItem action icon="layers"> Placa: { alerta.placa }</List.GroupItem>            
            {/*<List.GroupItem action icon="map-pin"> latitud: { alerta.latitud } </List.GroupItem>
            <List.GroupItem action icon="map-pin"> longitud: { alerta.longitud } </List.GroupItem>
            <List.GroupItem action icon="pen-tool"> trigger Value: { alerta.trigger_value }</List.GroupItem>
            <List.GroupItem action icon="pen-tool"> trigger Name: { alerta.trigger_name }</List.GroupItem>
            <List.GroupItem action icon="calendar"> Fecha de creación: { alerta.fecha }</List.GroupItem>
            <List.GroupItem action icon="alert-circle"> Causa: { alerta.causa }</List.GroupItem>
            <List.GroupItem action icon="message-square"> Mensaje: { alerta.mensaje }</List.GroupItem>
            <List.GroupItem action icon="circle"> Atendido: { alerta.atendida === false ? "No" : "Si" }</List.GroupItem>*/}
            <List.GroupItem action icon="calendar"> Fecha atención: { alerta.atiende_fecha}</List.GroupItem>
            <List.GroupItem action icon="user-check"> Usuario atendio: { alerta.usuario }</List.GroupItem>
            <List.GroupItem action icon="user">NOMBRE DEL OPERADOR: { alertaSinRutaIncidencia.operadorNombre }</List.GroupItem>
            <List.GroupItem action icon="smartphone">MEDIO DE COMUNICACIÓN: { alertaSinRutaIncidencia.operadorMedio }</List.GroupItem>
            <List.GroupItem action icon="activity">TIPO DE INCIDENTE: { alerta.tipo_alerta }</List.GroupItem>
            <List.GroupItem action icon="alert-triangle">PROBLEMA: { alertaSinRutaIncidencia.problema }</List.GroupItem>
            <List.GroupItem action icon="award">SOLUCIÓN: { alertaSinRutaIncidencia.solucion }</List.GroupItem>
            <List.GroupItem action icon="file-text">OBSERVACIONES: { alertaSinRutaIncidencia.observaciones}</List.GroupItem>
        </List.Group>
    </div>
    :
    <div className= "mt-1">
        <Card>
            <Card.Header>
                <Card.Title>Panel de información</Card.Title>                   
            </Card.Header>
            <Card.Body>
                <Dimmer active={false} loader>
                    <List.Group>
                        <List.GroupItem action icon="truck">
                        LINEA TRANSPORTISTA:
                        <span>
                            <Select
                            value={transportista}
                            onChange={(e) => {
                                 setTransportista(e);
                                 setAlertaSinRuta({...alertaSinRuta, idTransportista: e.data}); 
                                 setIncidenciaSinRuta({...incidenciaSinRuta, idTransportista: e.value});
                                }}
                            options={listaTransportistas}
                            />
                        </span>
                        </List.GroupItem>
                    </List.Group>
                    <List.Group>
                        <List.GroupItem action icon="truck">
                            <span>PLACAS:</span>
                            <span>
                            <Form.Input
                                name="placa"   
                                value={alerta.placa}                             
                                placeholder="ingresa.."
                            />
                            </span>
                        </List.GroupItem>
                    </List.Group>
                    <List.Group>
                        <List.GroupItem action icon="user-check">
                            <span>RESPONSABLE:</span>
                            <span>
                            <Form.Input
                                name="responsable"
                                type="text"
                                value={alerta.usuario}                               
                                placeholder="ingresa.."
                            />
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="user">
                            <span>NOMBRE DEL OPERADOR:</span>
                            <span>
                            <Form.Input
                                name="operador"
                                type="text"
                                value={incidenciaSinRuta.operadorNombre}  
                                onChange={(e) => { setIncidenciaSinRuta({...incidenciaSinRuta, operadorNombre: e.target.value}) }}                            
                                placeholder="ingresa.."
                            />
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="smartphone">
                            <span>MEDIO DE COMUNICACIÓN:</span>
                            <span>
                            <Form.Input
                                name="medioCom"
                                type="text"
                                value={incidenciaSinRuta.operadorMedio}
                                onChange={(e) => { setIncidenciaSinRuta({...incidenciaSinRuta, operadorMedio: e.target.value}) }}  
                                placeholder="ingresa.."
                            />
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="activity">
                            <span>TIPO DE INCIDENTE:</span>
                            <span>
                            <Select
                                 value={incidencia}
                                 onChange={(e) => { 
                                    setIncidencia(e); 
                                    setIncidenciaSinRuta({...incidenciaSinRuta, idIncidencia: e.value});
                                }}
                                 options={listaIncidencias}
                            />
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="map-pin">
                            <span>Coordenadas:</span>
                            <span>
                            <Form.Input
                                name="coordenadas"
                                value={alerta.latitud + ", " + alerta.longitud}
                                onChange={(e) => {
                                    let coordenadas = e.value.split(",");
                                    if (coordenadas.length == 2) {
                                        setIncidenciaSinRuta({...incidenciaSinRuta, latitud: coordenadas[0], longitud: coordenadas[1].trim()});                                                                            
                                    }
                                }}
                                placeholder="ingresa.."
                            />
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="alert-triangle">
                            <span>PROBLEMA:</span>
                            <span>
                            <Form.Textarea
                                name="showHtml"
                                placeholder="Problema.."
                                rows={2}
                                onChange={(e) => { setIncidenciaSinRuta({...incidenciaSinRuta, problema: e.target.value}) }}  
                            >
                                {incidenciaSinRuta.problema}
                            </Form.Textarea>
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="award">
                            <span>SOLUCIÓN:</span>
                            <span>
                            <Form.Textarea
                                name="showHtml"
                                placeholder="Solución.."
                                rows={2}
                                onChange={(e) => { setIncidenciaSinRuta({...incidenciaSinRuta, solucion: e.target.value}) }}  
                            >
                                {incidenciaSinRuta.solucion}
                            </Form.Textarea>
                            </span>
                        </List.GroupItem>
                        <List.GroupItem action icon="file-text">
                            <span>OBSERVACIONES:</span>
                            <span>
                            <Form.Textarea
                                name="showHtml"
                                placeholder="Obserbaciones.."
                                rows={2}
                                onChange={(e) => { setIncidenciaSinRuta({...incidenciaSinRuta, observaciones: e.target.value}) }} 
                            >
                                {incidenciaSinRuta.observaciones}
                            </Form.Textarea>
                            </span>
                        </List.GroupItem>
                    </List.Group>
                </Dimmer>
            </Card.Body>
            <Card.Footer>
            <p
                className="float-right"
                data-toggle="tooltip"
                data-placement="top"
                title="Guardar cambios"
              >
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => {guardar()}}
                >
                  <Icon name="save" />
                </Button>
              </p>
            </Card.Footer>
        </Card>
    </div>    
    
  )
};

export default DetalleAlertaSinRuta;