/**
 * DateFormat
 * Recibe como parametro Fecha string y el formato requerido ,retorna Strin formateado
 */
export function DateFormat(rgba){ 
}

// yyyy-mm-dd hh:mm:ss
export function timeToDateFill(timestamp){ 
  if (timestamp!=null){
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;
  

  return dat.getFullYear() + "-" + mesD + "-" + diaD+" "+ hora+":"+min+":"+seg
  } else {
    return null;
  }
}
// yyyymmddhhmmss
export function timeToDateNumbers(timestamp){ 
  if (timestamp!=null){
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;
  

  return dat.getFullYear() + mesD + diaD + hora +min + seg;
  } else {
    return null;
  }
}
// yyyy-mm-ddThh:mm:ssz
export function timeToDateFillZ(timestamp){ 
  if (timestamp!=null){
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;
  

  return dat.getFullYear() + "-" + mesD + "-" + diaD+"T"+ hora+":"+min+":"+seg+"z"
  } else {
    return null;
  }
}

// 00:00:00 to 0000(milisegunbos)
export function timeToMilisegundos(StringTime){
  if(StringTime){
    var data=StringTime.split(':'); //parseInt("10", 10)
    let horas= 0; let minutos= 0; let segundos= 0;  
    if( data[0]>0 ){ horas= data[0] }
    if( data[1]>0 ){ minutos= data[1] }
    if( data[2]>0 ){ segundos= data[2] }  
    horas= horas*60*60;
    minutos= minutos*60;
    return (horas+minutos+segundos)*1000;
  }else{
    return 0;
  }
}
// dd-mm-yyyy
export function timeToDatefull(timestamp){ 
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  return dat.getFullYear() + "-" + mesD + "-" + diaD
}

// yyyy-mm-dd
export function timeToYearmonthDay(timestamp){ 
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  return dat.getFullYear() + "-" + mesD + "-" + diaD
}

// 00:00:00 to 0000(milisegunbos)
export function timeToTimeFull(timestamp){ 
    if (timestamp!=null){
    var dat= new Date(timestamp);
    var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
    hora  < 10 ? hora = "0" + hora  : hora  = hora;
    min   < 10 ? min  = "0" + min   : min   = min;
    seg   < 10 ? seg  = "0" + seg   : seg   = seg;
    return hora+":"+min+":"+seg
    } else {
      return null;
    }
  }

// 6 dias, 13 hrs, 6 min 
export function diff(fecha1, fecha2){
  let dif = fecha2 - fecha1;					
	let horas = Math.round(dif / (1000 * 60 * 60));
	let minutos = Math.round( (dif % (1000 * 60 * 60)) / (1000 * 60) );
	let	segundos = Math.round( ((dif % (1000 * 60 * 60)) % (1000 * 60)) / 1000);
  let tiempo= "";
  if (segundos < 0) { segundos = 0; }
  let dias;
  let years;
  if (horas > 0) {
    tiempo = horas + " hrs, " + minutos + " min";
    if (horas >= 24) {
      dias = Math.round( horas / 24);
      tiempo = dias + " dias, "+(horas%24)+ " hrs, " + minutos + " min";
      if (dias > 365) {
        years = Math.round(dias / 365);
        tiempo = years + " años, " + (dias % 365) + " dias";
      }
    }
  } else {
    if (minutos > 0) {
      tiempo = minutos + " min, "+segundos + " seg";
    } else { tiempo = segundos + " segundos"; }
  }
  return tiempo;
}

export function timeString(fechaMs) {
  let dias=   Math.trunc((fechaMs)/(1000*60*60*24));
  let horas=  Math.trunc((fechaMs%(1000*60*60*24))/(1000*60*60));
  let min=    Math.trunc((fechaMs%(1000*60*60))/(1000*60));
  let tiempoString= "";
  tiempoString= tiempoString+( dias? dias+ " días, ": "");
  tiempoString= tiempoString+( horas? horas+ " hrs, ": "");
  tiempoString= tiempoString+( min? min+ " min": "");
  return tiempoString;
}

// funcion que valida una fecha
export function fechaValida(timestamp) { 
  if( timestamp != null ){
    //console.log(timestamp);       
    const dat= new Date(timestamp);
    //console.log(dat); 
    const year = dat.getFullYear(); 
    const mes = (dat.getMonth() + 1); 
    const dia = dat.getDate();
    //console.log(year, mes, dia);
    //const hora = dat.getHours(); 
    //const min = dat.getMinutes(); 
    //const seg = dat.getSeconds();
    //console.log(hora, min, seg);
    if ( year >= 2020 && (mes >= 1 && mes < 13) && (dia >= 1 && dia < 32) ) {
      //console.log(dat+"-> fecha valida");
      return true;     
    } else {
      //console.log(dat+"-> fecha invalida");
      return false;
    }
  } else {
    //console.log(timestamp+"-> No es una fechafecha");
    return false;
  }
}


//# 4/9/2019
export function timeToDate(timestamp){ return new Date(timestamp).toDateString(); }
export function timeToDatetime(timestamp){ return new Date(timestamp).toLocaleString(); }

//#Wed, 04 Sep 2019 14:52:19 GMT
export function timeToGMTS(timestamp){ return new Date(timestamp).toGMTString(); }

//# 2019-09-04T14:52:19.413Z
export function timeToISOS(timestamp){ return new Date(timestamp).toISOString(); }

//# 2019-09-04T14:52:19.413Z
export function timeToJSON(timestamp){ return new Date(timestamp).toJSON(); }

//# 9:52:19
export function timeToLocaleTime(timestamp){ return new Date(timestamp).toLocaleTimeString(); }

//# 4/9/2019 9:52:19
export function timeToLocale(timestamp){ return new Date(timestamp).toLocaleString(); }

//# Wed Sep 04 2019 09:52:19 GMT-0500 (hora de verano central)
export function timeToString(timestamp){ return new Date(timestamp).toString(); }

//# 09:52:19 GMT-0500 (hora de verano central)
export function timeGetTime(timestamp){ return new Date(timestamp).toTimeString(); }

//# Wed, 04 Sep 2019 14:52:19 GMT
export function timeToUTC(timestamp){ return new Date(timestamp).toUTCString(); }

/*
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes < 10 ? mesD = "0" + mes : mesD = mes; dia < 10 ? diaD = "0" + dia : diaD = dia;*/

  
  export default DateFormat;