import React, { useEffect, useState } from "react";
import classes from "./Reloj.module.css";

const Reloj = (props) => {
  const [color, setColor] = useState("#2E7D32");
  const FULL_DASH_ARRAY = 283;
  const WARNING_THRESHOLD = props.time * 0.5;
  const ALERT_THRESHOLD = props.time * 0.1;

  const COLOR_CODES = {
    info: {
      color: "green",
    },
    warning: {
      color: "orange",
      threshold: WARNING_THRESHOLD,
    },
    alert: {
      color: "red",
      threshold: ALERT_THRESHOLD,
    },
  };

  let TIME_LIMIT = 0;
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;
  let timerInterval = null;
  let remainingPathColor = COLOR_CODES.info.color;

  /**
   * Función que elimina el interval cuando se acaba el tiempo.
   */
  const onTimesUp = () => {
    clearInterval(timerInterval);
  };

  /**
   * Función para dar formato a los números del reloj.
   */
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  };

  /**
   * Función para establecer los colores del reloj según el tiempo transcurrido.
   */
  const setRemainingPathColor = (timeLeft) => {
    const { alert, warning, info } = COLOR_CODES;
    if (timeLeft <= alert.threshold) {
      setColor("#B71C1C");
    } else if (timeLeft <= warning.threshold) {
      setColor("#FFCA28");
    }
  };

  /**
   * Función que calcula las fracciones de tiempo a avanzar de la animación.
   */
  const calculateTimeFraction = () => {
    const rawTimeFraction = timeLeft / TIME_LIMIT;
    return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
  };

  /**
   * Función para agregar el reloj.
   */
  const setCircleDasharray = () => {
    const circleDasharray = `${(
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dasharray", circleDasharray);
  };

  /**
   * Función para establecer los valores iniciales cuando se abre el reloj.
   */
  useEffect(() => {
    TIME_LIMIT = props.time;
    timePassed = props.timepassed;
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;
      document.getElementById("base-timer-label").innerHTML =
        formatTime(timeLeft);
      setCircleDasharray();
      setRemainingPathColor(timeLeft);

      if (timeLeft === 0) {
        onTimesUp();
      }
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  return (
    <div id="reloj">
      <div className={classes.baseTimer}>
        <svg
          className={classes.svg}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g className={classes.circle}>
            <circle className={classes.elapsed} cx="50" cy="50" r="45"></circle>
            <path
              id="base-timer-path-remaining"
              strokeDasharray="283"
              className={`${classes.remaining} ${remainingPathColor}`}
              style={{ color: color }}
              d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
            ></path>
          </g>
        </svg>
        <span id="base-timer-label" className={classes.label}>
          {formatTime(timeLeft)}
        </span>
      </div>
    </div>
  );
};

export default Reloj;
