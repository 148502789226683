import React, { useEffect, useContext, useState } from "react";
import Stomp from "stompjs";
//const url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
//const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
//
let url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url1 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const user = "WEBSOCKET";
const pass = "w3bs0k3t2024";
let client = Stomp.client(url);
const WhatsAppBotMensajeria =({ topics, obtenerMensaje })=> {
  const [mensajeEntrante, setMensajeEntrante] = useState(null);
  useEffect(() => {
    if(topics.length){
      console.log("conectar al websocket: ");
      conectar();
    }    
  }, [topics]);
  
  const conectar = () => {
    client = Stomp.client(url);
    client.heartbeat = {
      incoming: 20000,
      outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        //Conect callback       
        if (topics && topics.length) {
          for (let t of topics) {
            client.subscribe(t, (message) => {      
              if (message.body.includes("{")) {
                setMensajeEntrante(message.body);
              }              
            });
          }
        }
      },
      (err) => {
        url = (url === url1) ? url2 : url1;
        //error useCallback
        console.log(err);
        if (typeof err === "string" && err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }        
      }
    );
  }

  useEffect(() => {
    if(mensajeEntrante){
      //console.log("mensajeEntrante: ", mensajeEntrante); 
      obtenerMensaje(JSON.parse(mensajeEntrante));
    }    
  }, [mensajeEntrante]);

/*
  const sendMessage = (topic, msg, data) => {
    client.send(topic, {}, msg);
  };
  */
    
  return <></>;
}
export default WhatsAppBotMensajeria;
