import React from "react";
import classes from "./Smartphone.module.css";
import Tecla from "./Tecla";

const Smartphone = ({ enLlamada, llamante, numero }) => {
  return (
    <div
      className={classes.smartphone}
      style={{ position: "absolute", top: 0, left: 10, display: "none" }}
    >
      <div className={classes.camera}></div>
      <div className={classes.speaker}></div>
      <div className={classes.flashlight}></div>
      <div className={classes.btnVol}></div>
      <div className={classes.btnPower}></div>
      <div className={classes.innerMargin}>
        {/** Display de numeros */}
        <div
          style={{
            backgroundColor: !enLlamada ? "white" : "#9CFFD9",
            width: "90%",
            height: "10%",
            borderRadius: 10,
            margin: "0 auto",
            padding: 10,
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "black",
              fontSize: !enLlamada ? 14 : 10,
            }}
          >
            {!enLlamada ? numero : llamante}
          </p>
        </div>
        {/** Teclado 1 - 3*/}
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "20%",
            left: "15%",
          }}
        >
          <Tecla character="1" />
          <Tecla character="2" />
          <Tecla character="3" />
        </div>
        {/** Teclado 4 - 6*/}
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "35%",
            left: "15%",
          }}
        >
          <Tecla character="4" />
          <Tecla character="5" />
          <Tecla character="6" />
        </div>
        {/** Teclado 7 - 9*/}
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "15%",
          }}
        >
          <Tecla character="7" />
          <Tecla character="8" />
          <Tecla character="9" />
        </div>
        {/** Teclado * 0 #*/}
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "65%",
            left: "15%",
          }}
        >
          <Tecla character="*" />
          <Tecla character="0" />
          <Tecla character="#" />
        </div>
        {/** Botones de colgar y borrar */}
        <div
          style={{
            margin: 0,
            position: "absolute",
            top: "82%",
            left: "15%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "#B9BFCA",
              borderRadius: 10,
              padding: 10,
              cursor: "pointer",
              margin: "0px 10px",
            }}
          >
            <i className="fe fe-skip-back" style={{ fontWeight: "bold" }} />
          </div>
          {!enLlamada && (
            <div
              style={{
                backgroundColor: "#519F63",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
                margin: "0px 10px",
              }}
            >
              <i
                className="fe fe-phone-call"
                style={{ fontWeight: "bold", color: "black" }}
              />
            </div>
          )}
          {enLlamada && (
            <div
              style={{
                backgroundColor: "red",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
                margin: "0px 10px",
              }}
            >
              <i
                className="fe fe-phone-call"
                style={{ fontWeight: "bold", color: "black" }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Smartphone;
