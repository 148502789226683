import React, { Component,useState, useEffect} from "react";
import { Grid, Table, Form, Dimmer, Tab } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import classes from "./index.module.css";
import ReactToPrint from 'react-to-print';
import { NotificationManager } from "react-notifications";
import L from "leaflet";  
import {MapContainer,TileLayer,ScaleControl,useMapEvents,MapConsumer} from "react-leaflet";
import icon from "./constants";
//import { addressPoints } from "./addressPoints";
import "leaflet/dist/leaflet.css";
//import map from 'Map';  

//En esta ruta de local podemos extraer las placas del tractor y del remolque: http://localhost:8000///this.getfuntion("get", [], "", "personas/destinatarios_transportistas_incidencias_ter/"+this.props.idmovimiento+"/"+event.data.idEtiqueta, "destinosTransportistas");
//
// }

// Función para convertir grados a radianes
function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

// Función para calcular la distancia entre dos puntos geográficos usando la fórmula de Haversine
function haversine(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);          
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
 return R * c; // Distancia en kilómetros

//return  111.11*Math.sqrt(  (lat1-lat2)*(lat1-lat2) +    (lon1-lon2)*(lon1-lon2)     );
//return 111*lat1; 

}

// Función para calcular la distancia total recorrida por el vehículo
function calcularDistanciaTotal(coordenadas) {
  let distanciaTotal = 0;

  for (let i = 0; i < coordenadas.length - 2; i += 2) {
      const lat1 = parseFloat(coordenadas[i]);
      const lon1 = parseFloat(coordenadas[i + 1]);
      const lat2 = parseFloat(coordenadas[i + 2]);
      const lon2 = parseFloat(coordenadas[i + 3]);
      
      distanciaTotal += haversine(lat1, lon1, lat2, lon2);
  }
  console.log("c++ en funcion... "+distanciaTotal);        

  return distanciaTotal; // Distancia total en kilómetros
}






class BitacoraPdf extends Component {
  constructor(props){
    super(props);

    this.state = {
      hiddenModals  : true,
      searchAlertas : "",
      usuariosLog   : [],
      filas         : [],
      patio         : [],
      paradas       : [],
      trackPoints   : [],
      distancia     : 0,
      addressPoints : [],
      conteoParadas : 0,
      contadorImagenes:0,
      loading       : true
    };
    //this.trackPoints=[];
    this.img=[4];
    this.img2=null;
    this.img3=null;
    this.filas= [];
    this.contador=0;
    this.tiempoRuta=0;
    this.estadia=0;  
    this.avgLat=0;
    this.avgLon=0;
    this.c = [];
   // this.conteoParadas=0;
    //this.patio=null;  
  }

  imageUpload = (e) => {
    const file = e.target.files[0];
    this.state.contadorImagenes=1;
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      //this.img[this.contador]=URL.createObjectURL(file);
      //this.contador=this.contador+1;
      this.img=URL.createObjectURL(file);
      //this.img=null;
       //document.getElementById("imageFile1").value = "";

    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
    //this.contador=this.contador+1;
   }


  };

  imageUpload2 = (e) => {
    this.state.contadorImagenes=2;

    const file = e.target.files[0];
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      this.img2=URL.createObjectURL(file);
    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
   }
  };

  imageUpload3 = (e) => {
    const file = e.target.files[0];
    // getBase64(file).then(base64 => {
    //   localStorage["fileBase64"] = base64;
    // });
    try{
      this.img3=URL.createObjectURL(file);
    }
   catch{
    console.log("Hubo un error al recuperar la imagen");  
   }
  };
    componentWillMount = () => { 
      
      //Crud_Catalogos("navieraMovimientos/tracks/73114/371031", "","get","",[],"","","",[]).then((returnVal) => {
      Crud_Catalogos("navieraMovimientos/tracks/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        this.setState({ trackPoints:returnVal });//.catch(err=>{console.log(err)});  
        
      });

       //Crud_Catalogos("distanciaRecorridaView/distanciaRecorrida/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        //this.setState({ distancia:returnVal });//.catch(err=>{console.log(err)});   
       // });




      Crud_Catalogos("movimientosPatio/idMovimiento/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        this.setState({ patio:returnVal });//.catch(err=>{console.log(err)});
      });

      //https://one.skynavieros.com:8080/skyone/navierasAlertas/movimiento/283005

      //Crud_Catalogos("navierasAlertas/movimiento/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        Crud_Catalogos("movimientoIncidencias/movimiento/"+this.props.Movimiento.id, "","get","",[],"","","",[]).then((returnVal) => {
        this.setState({ paradas:returnVal });//.catch(err=>{console.log(err)});
      });
   }
   
   componentDidMount = () => {   

      this.state.conteoParadas=0;
      this.state.paradas = [];
   }

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "cliente",
    stateVar = "clientes",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "movimientosPatio") {
            return returnVal;
            }
            break;
          case "post":
            break;
          case "put":
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
        }
      });
  };
render() {

  //let trackPoints = this.state.trackPoints;
  this.state.conteoParadas=0;
  var position;
  for(var con=0;con<this.state.paradas.length;con++){//parada, detenida, alimento, wc
    position = this.state.paradas[con].incidencia.incidenciaTipo.nombre.toLowerCase().search("detención");
   if(position!=-1)
    {
    this.state.conteoParadas++;
    }
 }

  let  arriboClienteVacio =  new Date(this.props.Movimiento.arriboClienteVacio);
  let  arriboClienteLleno =  new Date(this.props.Movimiento.arriboClienteLleno);
  let  inicioRuta         =  new Date(this.props.Movimiento.inicioRuta);
  let  salidaClienteLleno =  new Date(this.props.Movimiento.salidaClienteLleno);
  let  arriboPatioLinea   =  new Date(this.props.Movimiento.arriboPatioLinea);
  let  salidaClienteVacio   =  new Date(this.props.Movimiento.salidaClienteVacio);
  let distancia = 0;
  if(distancia==0) distancia=calcularDistanciaTotal(this.c);
  

  

   if(this.props.Movimiento.idMovimientoTipo.id==1 ){
       this.tiempoRuta = arriboClienteLleno-inicioRuta;
       this.estadia = salidaClienteVacio - arriboClienteLleno;
   }
   if(this.props.Movimiento.idMovimientoTipo.id==2 ){
    let f1 = new Date(this.props.Movimiento.arriboTerminalCargado);
    let f2 = new Date(this.props.Movimiento.salidaPredioVacio);
    this.estadia = salidaClienteLleno - arriboClienteVacio;
    this.tiempoRuta=Math.abs(f2-f1);
   }
   

   if( Math.abs(this.tiempoRuta) > 1600000000000 ){
    this.tiempoRuta = 0;
   }
   else{
    this.tiempoRuta = (this.tiempoRuta/(1000*3600)).toFixed(2) ;
   }

   if( Math.abs(this.estadia) > 1600000000000 ){
    this.estadia = 0;
   }
   else{
    this.estadia = (this.estadia/(1000*3600)).toFixed(2) ;
   }

this.avgLat=0;
this.avgLon=0;
if(distancia==0)
   this.state.trackPoints.map((onePoint, index) => ( 
    onePoint.position.latitude?
    (this.avgLat=this.avgLat+onePoint.position.latitude)&&(this.avgLon=this.avgLon+onePoint.position.longitude)&&this.c.push([onePoint.position.latitude,onePoint.position.longitude]):null     
    )) 
    this.avgLat=this.avgLat/this.c.length;
    this.avgLon=this.avgLon/this.c.length;
 
  



   return (
    <div>
    <Grid.Row>
       {(this.props.padre!='BitacoraPdf')?  
            <div>   
                 <BitacoraPdf  ref={(response) => (this.componentRef = response)} padre={'BitacoraPdf'} Movimiento={this.props.Movimiento}  Img={this.props.img}/>                               
                 <ReactToPrint content={() => this.componentRef} trigger={() => <button  className="btn btn-primary"> PDF</button>}/>
            </div>
    :<div> 
      <input type="file"  id={"imageFile"+this.contador} name={"imageFile"+this.contador} onChange={this.imageUpload} />
      <input type="file"  id="imageFile2" name='imageFile2' onChange={this.imageUpload2} />
      <input type="file"  id="imageFile3" name='imageFile3' onChange={this.imageUpload3} />
        <img src="images/head.png" />
        <br/><br/><br/><Grid.Col><h2 align="right">Ciudad de México a {new Date().getDate()} de {Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date())} de{new Date().getFullYear()}&nbsp;&nbsp;&nbsp;&nbsp;</h2>    
        <br/><br/>
          <div>          
             &nbsp;&nbsp;&nbsp; Servicio:<b>{this.props.Movimiento.idCliente.nombreCorto}</b>
            <p>&nbsp;&nbsp;&nbsp; Sky Angel </p> <br/><br/>
            <Table>
                <Table.Body>                           
                  <Table.Row>              
                    <Table.Col><b>Solicitó:</b>&nbsp;&nbsp;{this.props.Movimiento.idTransportista.contactoNombre}</Table.Col>
                    <Table.Col><b>Solicitado:</b>&nbsp;&nbsp;{new Date(new Date(this.props.Movimiento.creationDate)).getDate()} de {Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date(this.props.Movimiento.creationDate))}</Table.Col>                    
                    <Table.Col><b>Colocado:</b>&nbsp;&nbsp;{new Date(this.props.Movimiento.citaProgramada).getDate()} de {Intl.DateTimeFormat('es-ES', { month: 'long'}).format(new Date(this.props.Movimiento.citaProgramada))}</Table.Col>                   
                  </Table.Row>                       
                </Table.Body>
              </Table>                          
              <Table  >
                <Table.Body>
                  <Table.Row>
                    <Table.Col> <br/><br/> <br/>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Col><b>Transporte:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.idTransportista.nombre}</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col><b>Booking:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.booking}</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col><b>Contenedor:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.contenedor}</Table.Col>
                  </Table.Row>
                    </Table.Body>
                      </Table>
              </Table.Col>  
              <Table.Col><br/><br/><br/>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Col><b>Unidad:</b>{this.state.patio.tractorPlaca} </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col><b>Remolque:</b>{this.state.patio.remolquePlaca} </Table.Col>
                  </Table.Row>                 
                    </Table.Body>
              </Table>
            </Table.Col> </Table.Row></Table.Body>
            </Table>
              <Table >
                <Table.Body>
                <Table.Row>
                    <Table.Col><b>Origen:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.idCorredor.nombre }</Table.Col>
                    <Table.Col><b>Destino:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.destino}</Table.Col>
                  </Table.Row>                   
                   <Table.Row>
                    <Table.Col><b>Salida de dirección de entrega:</b></Table.Col>
                    {/* <Table.Col>{this.props.Movimiento.salidaPredioVacio}</Table.Col> */}
                    <Table.Col>{this.props.Movimiento.salidaClienteLleno}</Table.Col>

                    <Table.Col><b>Ingreso a terminal:</b></Table.Col>
                    <Table.Col>{this.props.Movimiento.arriboTerminalCargado}</Table.Col>
                  </Table.Row> 
                  <Table.Row>
                    <Table.Col><b>Tiempo total de ruta:&nbsp;&nbsp;&nbsp;</b> {this.tiempoRuta} hrs</Table.Col>
                    <Table.Col>   </Table.Col>
                    <Table.Col><b>Estadía en cliente:&nbsp;&nbsp;&nbsp; </b>{this.estadia} hrs</Table.Col>
                    <Table.Col></Table.Col>
                  </Table.Row> 
                  <Table.Row> 
                    <Table.Col><b>Detenciones en Ruta</b></Table.Col>
                    <Table.Col> 
                      {this.state.conteoParadas}
                     </Table.Col>
                    <Table.Col><b>Tiempo Detenida en Ruta</b></Table.Col>
                    <Table.Col> {14.7*this.state.conteoParadas} minutos</Table.Col>
                  </Table.Row>
                  <Table.Row>
                  <Table.Col><b>Distancia recorrida</b></Table.Col>
                    <Table.Col> 
                      { distancia === 0 ? "No disponible": distancia}
                     </Table.Col>
                  </Table.Row>
                  <Table.Row>
                  <Table.Col><b>Evidencia de colocación</b></Table.Col>    
                  </Table.Row>                                 
              </Table.Body>
            </Table>
            <Table>
            <Table.Body>
            <Table.Row>
                    {/* <Table.Col><img src={this.img[0]} alt=""  /></Table.Col> */}
                    
                    <Table.Col>     {



                             this.state.trackPoints.length?           
                               <MapContainer

                               
                                                     //center={[20, -100]}
                                                     zoom={6}
                                                     style={{  height: "400px", width:"350px" }}
                                                     whenReady={(map) => { 
                                                     
                                                      
                                                    

                                                      //L.marker([20, -100], { icon } ).addTo(map.target);        
                                                           
                                                       
                                                       

                                                       L.polyline(this.c).addTo(map.target)
                     
                                                     }} 
                                                     //center={[20, -100]}
                                                     center={[this.avgLat, this.avgLon]}
                                                >    
                                                    <TileLayer      

                                                   url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                                                />     
                     
                                                      
                               </MapContainer>           
                                 :null
                                    }
                    </Table.Col> 
                     {this.state.contadorImagenes == 1 ?  
                     <Table.Col><img src={this.img} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col> 
                      :null}

                       { this.state.contadorImagenes == 2 ?  
                      <>
                        <Table.Col><img src={this.img} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col>                     
                        <Table.Col><img src={this.img2} alt=""  style={{  height: "25vh", width:"32vh" }}/></Table.Col>
                      </>
                    :null}
                    {/* <Table.Col><img src={this.img3} alt=""  style={{  height: "50vh", width:"25vh" }}/></Table.Col> */}


                    <Table.Col>
                    </Table.Col>
                  </Table.Row>
            </Table.Body>      
            </Table>

           
   
            </div>
           {/* <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}




            <div class="footer2">
               <h5 align="center"  >Río Danubio 87, Colonica Cuahutemoc, Delegación Cuahutémoc. C.P. 06500, Ciudad de México</h5>
               <h5 align="center" >Skyangel México•EUA•Centroamérica</h5>
               <h5 align="center">52 76 76 70 | 01 800 750 54 63</h5>
            </div> 
 
          </Grid.Col>



               </div>            
        }





    </Grid.Row>
    
    
    

    </div>





    );   
  } 
}



export default BitacoraPdf;
