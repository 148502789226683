// @flow
import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table } from "tabler-react";


class Columnas extends Component {
  constructor(props) {
    super(props);
    this.state = { search:"", labels: this.props.elementos?this.props.elementos: [], accion: this.props.accion };
    this.labels= this.props.elementos?this.props.elementos: [];
}

  componentDidMount =() => { console.log( JSON.parse(sessionStorage.getItem("tableHeaders")) )}

  changeValue =(event) =>{
    for(let item of this.labels){
      if(item.value == event.target.name){ item.status= event.target.checked }
    }
    this.setState({ labels: this.labels});
    this.props.setLabels(this.labels);
    localStorage.setItem('tableHeaders', JSON.stringify(this.labels) );
  }

  render() {
    return (
      <div>
        {
          console.log(this.props.elementos)
        }
        <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={this.state.content} >
          <Table.Header>
            <Table.ColHeader>Columna</Table.ColHeader>
            <Table.ColHeader>Estatus</Table.ColHeader>
          </Table.Header>
          <Table.Body>
            {this.state.labels.filter(word => word.label.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                <Table.Row>
                  <Table.Col>{elemento.label}</Table.Col>
                  <Table.Col>{<Form.Switch type="Checkbox" name={elemento.value} checked={ elemento.status } onChange={this.changeValue} />}</Table.Col>
                </Table.Row>
            )}
          </Table.Body>
        </Table>
              
      </div>
    );
  }
}
export default Columnas;
