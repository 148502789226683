import React, { Component, useState, useEffect } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { TablePageable } from "../../../../Helpers";

const user= JSON.parse(sessionStorage.getItem("usuario"));
let disabeleButton= false;
const MovimientoUnidad=({accion, movimiento, stor})=> {
  const [unidades, setUnidades]= useState([]);
  const [unidadesAgregadas, setUnidadesAgregadas]= useState([]);
  const [unidadesAgregada, setUnidadesAgregada]= useState({});
  const [unidadAgregar, setUnidadAgregar]= useState({});
  const [full, setFull]= useState(false);
  const [movimientos, setMovimientos]= useState([]);
  const [incidenciasCruzadas, setIncidenciasCruzadas]= useState([]);

  const [movimientoObcion, setMovimientoObcion]= useState({ value: 0, label: "Selecione un mov", data: null });
  useEffect(() => {
    if(movimiento){
      getfuntion("get","","","unidadTerNav/idMovimiento/"+movimiento.id, "unidadesAgregadas");
      getfuntion("get","","","unidadTerNav/idTransportista/"+movimiento.idTransportista.id, "unidades");  
    }
    traerIncidenciaDePlataformaCruzada();


	}, [movimiento]);
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === "unidadesAgregadas"){
            setUnidadesAgregadas(returnVal? returnVal: []);
          }
          if (stateVar === "unidades" && returnVal){
            setUnidades(returnVal);
          }
          if (stateVar === "movimientoUnidad"){
            return returnVal;
          }

          if (stateVar === "incidenciasCruzadas"){
            return returnVal;
          }
          
          
         } break;
         case "post": {
          if (stateVar === "unidad"){
            disabeleButton= false;
            getfuntion("get","","","unidadTerNav/idMovimiento/"+movimiento.id, "unidad");
            if( full && movimientoObcion.value){
              getfuntion(
                "post",
                {"idMovimiento": movimiento.id, "idUnidadTerNav": unidadAgregar.id},
                "",
                "movimientoUnidadTerNav/full/"+movimientoObcion.value,
                "unidadFull");
            }        
          }
        } break;
        case "delete": {
          if (stateVar === "unidad"){
            getfuntion("get","","","unidadTerNav/idMovimiento/"+movimiento.id, "unidadesAgregadas");          
          }
        } break;
        case "put": {
          
        } break;
        default:    break;
      }
    }).catch(err => {
      console.log(err);
      switch (metodo) {
         case "post": {
          if (stateVar === "unidad"){
            disabeleButton= false;
            alert("Ocurrio un arror al asignar la unidad vuelve a intentarlo");
          }
        } break;
        default:    break;
      }
    });
  }

  useEffect(() => {
    traerIncidenciaDePlataformaCruzada();

  }, []);

  // useEffect(() => {
  //   alert(incidenciasCruzadas);

  // }, [incidenciasCruzadas]);
  
  const removerUnidad =(id)=>{
    confirmAlert({
      title: 'Atención',
      message: '¿Esta seguro de remover la unidad del mov('+movimiento.id+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { getfuntion("delete","",movimiento.id+"/"+id,"movimientoUnidadTerNav", "unidad"); }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }
  const hacerFull=(e)=>{
    setFull(e);
    getfuntion("get","","","movimientoUnidadTerNav/idUnidadTerNav/"+unidadAgregar.id, "movimientoUnidad").then((unidades) => {
      if( e ){
        let movsTemp= [];
        for(let mu of unidades){
          let movTemp= stor.getMovimientoNavieroById(mu.idMovimiento);
          if( movTemp && movTemp.id && movTemp.idPlataforma.id === movimiento.idPlataforma.id ){
            movsTemp.push({value: movTemp.id, label:movTemp.id+"-"+ movTemp.contenedor+"-"+movTemp.booking+"-"+movTemp.po, data: movTemp});
          }
        }
        setMovimientos(movsTemp);
      }else{
        //this.getfuntion("get","","","unidadTerNav/disponibles/"+this.props.movimiento.idPlataforma.id+"/"+this.props.movimiento.idTransportista.id, "unidades");
      }
    });
  }
  const traerIncidenciaDePlataformaCruzada=()=>{
    //console.log('Incidencias cruzadas');
    
    getfuntion("get","","","incidencias/incidenciasCruzadas/"+movimiento.id, "incidenciasCruzadas").then((returnVal) => {
      //});
      //getfuntion("get","","","movimientoUnidadTerNav/idUnidadTerNav/"+unidadAgregar.id, "movimientoUnidad").then((returnVal) => {
       // getfuntion("get","","","unidadTerNav/idTransportista/1", "unidades").then((returnVal) => {
        //console.log(returnVal);
        setIncidenciasCruzadas(returnVal? returnVal: []);
       // console.log("IncidenciasCruzadas en getfunction:"+returnVal);
      })
      // console.log("IncidenciasCruzadas fuera:"+incidenciasCruzadas);
  }



  const agregar=(un)=>{
    if( !disabeleButton ){
      disabeleButton= true;
      getfuntion("post",{"idMovimiento": movimiento.id, "idUnidadTerNav": un.id}, "","movimientoUnidadTerNav/idusuario/"+user.id, "unidad");
    }
  }
  return (
    <div>
      <Grid.Row>
        <Grid.Col><h1>{movimiento.idPlataforma.nombre.toUpperCase()}, mov: {movimiento.id}, LT: {movimiento.idTransportista.nombre}</h1> </Grid.Col>
      </Grid.Row>
                  Incidencias reportadas por otras plataformas

      <Table>
          <Table.Header align="center">
                          <Table.ColHeader>   Plataforma           </Table.ColHeader>
                          <Table.ColHeader>   Fecha          </Table.ColHeader>
                          <Table.ColHeader>   Hora          </Table.ColHeader>
                          <Table.ColHeader>   Económico           </Table.ColHeader>
                          <Table.ColHeader>   Comentario          </Table.ColHeader>

          </Table.Header>
              <Table.Body>
                   { incidenciasCruzadas.map((i,index) => (                
                        <Table.Row> <Table.Col> {i.plataforma} </Table.Col> 
                        <Table.Col> {i.fecha_creado.split('T')[0]} </Table.Col> 
                        <Table.Col> {i.fecha_creado.split('T')[1]
                        } </Table.Col> 

                        <Table.Col> {i.economicot} </Table.Col>  <Table.Col>  {  i.comentario }</Table.Col></Table.Row> 
             
                      ))}
              </Table.Body>
          </Table>
      <Grid.Row>
        <div className="col" hidden={!unidadesAgregadas.length}>
          <TablePageable
            titulo={
              <div className="row">
                <div className="col">
                  Unidades Agregadas
                </div>
                <div className="col" hidden={!unidadesAgregada.id}>
                  <Button
                    target="_blank" size="sm" color="danger"
                    onClick={() => removerUnidad(unidadesAgregada.id) } >
                      <span class="badge"><Icon link={true} name="trash" />Remover {unidadesAgregada.nombre}</span>
                  </Button>
                </div>
              </div> }
            lista= { unidadesAgregadas }
            columnas={
              [
                { columna: "id", label: "Id unidad" },
                { columna: "nombre", label: "Nombre" },
                { columna: "placa", label: "Placa" },
              ]
            }
            id= "id"
            selected={(e)=>{ setUnidadesAgregada(e); }}
          />
        </div>        
      </Grid.Row>
      <hr/>
      <div className="mt-3">
        <div className="row">
          <div className="col-12">                   
            <TablePageable
              titulo={
                <div className="row">
                  <div className="col">
                    Agregar Unidad 
                  </div>
                  <div className="col" hidden={!unidadAgregar.id}>
                    <Form.Switch type="Checkbox" name="full" value="option1" label="Hacer full" checked={full}  onChange={ (e)=> hacerFull(e.target.checked) } />
                  </div>
                  <div className="col-5" hidden={!unidadAgregar.id || !full}>
                    <div className="form-group">
                        <label for="mov">Hacer full con:</label>
                        <label for="mov">id-contenedor-booking-po</label>
                        <Select value={movimientoObcion} onChange={(e)=> setMovimientoObcion(e)} options={movimientos} />
                      </div>
                  </div>
                  <div className="col" hidden={!unidadAgregar.id}>
                    <span className="badge btn" onClick={() => agregar(unidadAgregar)}><Icon link={true} name="save"  className="text-primary"/></span>
                  </div>
                </div> }
              lista= { unidades }
              columnas={
                [
                  { columna: "id", label: "Id unidad" },
                  { columna: "nombre", label: "Nombre" },
                  { columna: "placa", label: "Placa" },
                ]
              }
              id= "id"
              selected={(e)=>{
                setUnidadAgregar(e);
                setMovimientoObcion({ value: 0, label: "Selecione un mov", data: null });
                setFull(false);
              }}
              //csvname= "Gps Mobiles"
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />      
          </div>
        </div>
      </div>
    </div>
  );
}
export default MovimientoUnidad;
