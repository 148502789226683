//React
import React from "react";
// @flow
import { Error500Page } from "tabler-react";

function Error500(props) {
  return <Error500Page />;
}

export default Error500;
