import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import {MovimientoGpsForm}  from "../../AgregarGps";

function MovimientoGpsPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
        < MovimientoGpsForm  />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default MovimientoGpsPage;
