import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../SiteWrapper.react";
import '../navBarItems/MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import CatalogosLog from "../../components/CatalogosLog";
import PanelBitacoraMonitoreo from "../monitoreoPages/PanelBitacoraMonitoreo";

/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";
import { from } from "rxjs";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
let elements
class CatalogosLogPage extends Component {
  constructor(props) {
    super(props);
      this.state= {
        fecha1:"",
        fecha2:"",
        catalogo:"",
        metodo:"",
        request:"",
        resultado:[],
        id_data:0,
        band:false
      }
      this.changeFecha1 = this.changeFecha1.bind(this);
      this.changeFecha2 = this.changeFecha2.bind(this);
      this.changeCatalogo = this.changeCatalogo.bind(this);
      this.changeMetodo=this.changeMetodo.bind(this);
      this.changeRequest=this.changeRequest.bind(this);
      this.onclickButton=this.onclickButton.bind(this);
  }
  getfuntion =(api,cat,metodo)=>{
   
    Crud_Catalogos(api, cat,metodo, "","","", "", "", []).then((returnVal) => {
      switch(metodo){
        case 'get':
          this.setState({resultado:returnVal})
          break;
        case  'post':
          break;
        case 'put':
          break;
        case 'delete':
          break;
      }
      
    }).catch(err => { console.log("Error desconocido ...."+ err); })
  }
  changeFecha1 (event){
    this.setState({fecha1:event.target.value + " 00:00:00"});
  }
  changeFecha2 (event) {
    this.setState({fecha2: event.target.value + " 00:00:00"});
  }
  changeCatalogo(event){
    this.setState({catalogo: event.target.value});
  }
  changeMetodo(event){
    this.setState({metodo: event.target.value});
  }
  changeRequest(event){
    this.setState({request: event.target.value});
  }
  handleLog=(index)=>{
    this.setState({id_data:index});
  }
  onclickButton(event){
    switch(event.target.value){
          case "consultar": 
          this.getfuntion("catalogosLog/personalizado?fecha1="+this.state.fecha1+"&fecha2="+this.state.fecha2+
          "&catalogo="+this.state.catalogo+"&metodo="+this.state.metodo+"&request="+this.state.request,"catalago","get")
          break;
    }
  }
 
  render() {
   
    return (
      <>
        
      <SiteWrapper>
      <div className="container">
            <Grid.Row>
                  <div className="col col-2 col-md-2"><Form.Group label="Fecha inicial*"><Form.MaskedInput placeholder="0000-00-00"
                    mask={[ /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/,]} onChange={this.changeFecha1} value={this.state.fecha1}/> </Form.Group></div>
                  <div className="col col-2 col-md-2"><Form.Group label="Fecha final*"><Form.MaskedInput placeholder="0000-00-00"
                    mask={[ /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/,]} onChange={this.changeFecha2} value={this.state.fecha2}/></Form.Group></div>
                  <div className="col col-2 col-md-2"><Form.Group label="Catalogo*"><Form.Input name="catalogo" onChange={this.changeCatalogo} value={this.state.catalogo}/></Form.Group></div>
                  <div className="col col-2 col-md-2"><Form.Group label="Metodo*"><Form.Select name="metodo"  onChange={this.changeMetodo} value={this.state.metodo}>
                  <option value="get">GET</option>
                  <option value="post">POST</option>
                  <option value="put">PUT</option>
                  <option value="delete">DELETE</option>
                  </Form.Select></Form.Group></div>
                  <div className="col col-2 col-md-2"><Form.Group label="Request"><Form.Input name="request"  onChange={this.changeRequest} value={this.state.request}/></Form.Group></div>
                  <div className="col col-2 col-md-2">
                  <Form.Group label="-">
                  <Button pill  outline color="primary" value="consultar"
                   onClick={this.onclickButton}>Consultar</Button></Form.Group></div>
            </Grid.Row>
            <Grid.Row><div className="col col-12 col-md-12"><Form.StaticText className="text-center"><strong>*Campos Obligatorios</strong></Form.StaticText></div></Grid.Row>
            </div>
                <div>
               {
                 this.state.resultado.length >0 ?
                 
                 <Grid.Col sm={12} lg={12}>
                 <div style={{ height: "100vh", "font-size": "smaller"}}>
                <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
                <div><CatalogosLog resultados={this.state.resultado} handleLog={this.handleLog}/></div>
                <div><PanelBitacoraMonitoreo resultados={this.state.resultado} id={this.state.id_data}/></div>
                 </SplitterLayout>
                </div>
                 </Grid.Col> 
                 : 
                 <Grid.Col sm={12} lg={12}>
                 <div style={{ height: "100vh", "font-size": "smaller"}}>
                   </div>
                   </Grid.Col>
               }
               </div> 
      </SiteWrapper>
      </> 
    );
  }
}


export default (CatalogosLogPage);
