import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {ReglasForm}  from "../../Catalogos";

function ReglasPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
        <Catalogo Ctlogo="reglas" tamanio="45%" FCtlogo= { ReglasForm } catalogos= {{ 0: "reglas"} }/>
        </Grid.Col>
    </SiteWrapper>
  );
}
export default ReglasPage;
