import React, { Component } from "react";
import { Card, Form, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

class UsuarioLocalidades extends Component {
  constructor() {
    super();
    this.state = {
        localidades:[],
        pLocalidades:[],
    };
  }
  componentWillMount(){
    this.getfuntion("get", "", "", "cli_usuarios_localidades/usuario/"+this.props.usuario.id+"/plataforma/"+this.props.idPlataforma, "usuarios_localidades");
    this.getfuntion("get", "", "", "localidades", "localidades");
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
      return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
          /*** filtro de variables  */
          switch (metodo) {
            case "get":
              if (stateVar === "localidades")
                this.setState({ localidades: returnVal });
              if (stateVar === "usuarios_localidades")
                this.setState({ pLocalidades: returnVal });
              break;
            case "post":
              if (stateVar === "usuarios_localidades"){
                let pLocalidadesTemp= this.state.pLocalidades;  
                pLocalidadesTemp.push(returnVal);
                this.setState({ pLocalidades: pLocalidadesTemp });
              }
              break;
            case "delete":
              if (stateVar === "usuarios_localidades" && returnVal){ 
                let varTemp= this.state.pLocalidades;
                varTemp.map((elem, index )=>{
                  if(obj.idLocalidad === elem.idLocalidad ){ varTemp.splice( index, 1);  this.setState({ pLocalidades: varTemp }); }
                });
              }
              break;
            default:
              break;
          }
        }).catch(err =>{
            console.log(err);
      })
    }
  changeLocalidad=(event)=>{
    let objTemp= {idUsuario: this.props.usuario.id, idLocalidad: parseInt(event.target.value, 10), idPlataforma: this.props.idPlataforma };
    if( event.target.checked ){
      this.getfuntion("post", objTemp, "", "cli_usuarios_localidades","usuarios_localidades");  
    }else{
      this.getfuntion("delete", objTemp, this.props.usuario.id+"/"+parseInt(event.target.value, 10)+"/"+this.props.idPlataforma, "cli_usuarios_localidades","usuarios_localidades");
    }      
  }
  getLocalidad    =(id)=>{ for( let  item of this.state.pLocalidades ){  if( item.idLocalidad === id ){   return true; } } return false; }
  render() {
      return (
          <div>
            <Card>
              <Card.Body>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                  <Table.Header align="center">
                      <Table.ColHeader>Id</Table.ColHeader>
                      <Table.ColHeader>Localidad  </Table.ColHeader>
                      <Table.ColHeader>estatus </Table.ColHeader>
                  </Table.Header>
                  <Table.Body>
                    {this.state.localidades.map((t, index) =>
                      <Table.Row align="center">
                          <Table.Col>{ t.id }</Table.Col>
                          <Table.Col>{ t.nombre }</Table.Col>
                          <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getLocalidad(t.id) } onChange={ this.changeLocalidad} /></Table.Col>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>                                                            
              </Card.Body>
            </Card>
              <br/>
          </div>
      );
  }
}

export default UsuarioLocalidades;