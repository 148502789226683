import React, { useEffect, useState, useContext, Fragment } from "react";
import classes from "./Smartphone.module.css";
import Tecla from "./Tecla";
import TelefonoMensajeria from "../../socket/TelefonoMensajeria";
import TransferModal from "./TransferModal";
import PausaModal from "./PausaModal";
import { createPortal } from "react-dom";
import "react-phone-number-input/style.css";
import es from "react-phone-number-input/locale/es.json";
import PhoneInput from "react-phone-number-input";
import { softphoneContext } from "../../context/softphoneContext";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import ContactosModal from "./ContactosModal";
const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
let topics = []; //["/topic/users"];

const countryList = [
  {
    n: "AF (93)",
    i: "af",
    code: 93,
  },
  {
    n: "AX (93)",
    i: "ax",
    code: 0,
  },
  {
    n: "AL (355)",
    i: "al",
    code: 355,
  },
  {
    n: "DZ (213)",
    i: "dz",
    code: 213,
  },
  {
    n: "AS (1684)",
    i: "as",
    code: 1684,
  },
  {
    n: "AD (376)",
    i: "ad",
    code: 376,
  },
  {
    n: "AO (244)",
    i: "ao",
    code: 244,
  },
  {
    n: "AI (1-264)",
    i: "ai",
    code: 1264,
  },
  {
    n: "AQ (672)",
    i: "aq",
    code: 672,
  },
  {
    n: "AG (1-268)",
    i: "ag",
    code: 268,
  },
  {
    n: "AR (54)",
    i: "ar",
    code: 54,
  },
  {
    n: "AM (374)",
    i: "am",
    code: 374,
  },
  {
    n: "AW (297)",
    i: "aw",
    code: 297,
  },
  {
    n: "AU (61)",
    i: "au",
    code: 61,
  },
  {
    n: "AT (43)",
    i: "at",
    code: 43,
  },
  {
    n: "AZ (994)",
    i: "az",
    code: 994,
  },
  {
    n: "BS (1-242)",
    i: "bs",
    code: 1242,
  },
  {
    n: "BS (973)",
    i: "BH",
    code: 973,
  },
  {
    n: "BD (880)",
    i: "bd",
    code: 880,
  },
  {
    n: "BB (1-246)",
    i: "bb",
    code: 1246,
  },
  {
    n: "BY (375)",
    i: "by",
    code: 375,
  },
  {
    n: "BE (32)",
    i: "be",
    code: 32,
  },
  {
    n: "BZ (501)",
    i: "bz",
    code: 501,
  },
  {
    n: "BJ (229)",
    i: "bj",
    code: 229,
  },
  {
    n: "BM (1-441)",
    i: "bm",
    code: 441,
  },
  {
    n: "BT (975)",
    i: "bt",
    code: 975,
  },
  {
    n: "BO (591)",
    i: "bo",
    code: 591,
  },
  {
    n: "BA (387)",
    i: "ba",
    code: 387,
  },
  {
    n: "BW (267)",
    i: "bw",
    code: 267,
  },
  {
    n: "BV (0)",
    i: "bv",
    code: 0,
  },
  {
    n: "BR (55)",
    i: "br",
    code: 55,
  },
  {
    n: "IO (246)",
    i: "io",
    code: 246,
  },
  {
    n: "VG (1-284)",
    i: "vg",
    code: 1284,
  },
  {
    n: "BN (673)",
    i: "bn",
    code: 673,
  },
  {
    n: "BG (359)",
    i: "bg",
    code: 359,
  },
  {
    n: "BF (226)",
    i: "bf",
    code: 226,
  },
  {
    n: "BI (257)",
    i: "bi",
    code: 257,
  },
  {
    n: "KH (855)",
    i: "kh",
    code: 855,
  },
  {
    n: "CM (237)",
    i: "cm",
    code: 237,
  },
  {
    n: "CA (1)",
    i: "ca",
    code: 1,
  },
  {
    n: "CV (238)",
    i: "cv",
    code: 238,
  },
  {
    n: "Caribbean Netherlands",
    i: "bq",
    code: 257,
  },
  {
    n: "KY (1345)",
    i: "ky",
    code: 1345,
  },
  {
    n: "CF (236)",
    i: "cf",
    code: 236,
  },
  {
    n: "TD (235)",
    i: "td",
    code: 235,
  },
  {
    n: "CL (56)",
    i: "cl",
    code: 56,
  },
  {
    n: "CN (86)",
    i: "cn",
    code: 86,
  },
  {
    n: "CX (61)",
    i: "cx",
    code: 61,
  },
  {
    n: "CC (61)",
    i: "cc",
    code: 61,
  },
  {
    n: "CO (57)",
    i: "co",
    code: 57,
  },
  {
    n: "KM (269)",
    i: "km",
    code: 269,
  },
  {
    n: "CD (243)",
    i: "cd",
    code: 243,
  },
  {
    n: "CG (242)",
    i: "cg",
    code: 242,
  },
  {
    n: "CK (682)",
    i: "ck",
    code: 682,
  },
  {
    n: "CR (506)",
    i: "cr",
    code: 506,
  },
  {
    n: "CI (225)",
    i: "ci",
    code: 225,
  },
  {
    n: "HR (385)",
    i: "hr",
    code: 385,
  },
  {
    n: "CU (53)",
    i: "cu",
    code: 53,
  },
  {
    n: "CW (599)",
    i: "cw",
    code: 599,
  },
  {
    n: "CY (357)",
    i: "cy",
    code: 357,
  },
  {
    n: "CZ (420)",
    i: "cz",
    code: 420,
  },
  {
    n: "DK (45)",
    i: "dk",
    code: 45,
  },
  {
    n: "DJ (253)",
    i: "dj",
    code: 253,
  },
  {
    n: "DM (1767)",
    i: "dm",
    code: 1767,
  },
  {
    n: "DO (1-809)",
    i: "do",
    code: 1809,
  },
  {
    n: "EC (593)",
    i: "ec",
    code: 593,
  },
  {
    n: "EG (20)",
    i: "eg",
    code: 20,
  },
  {
    n: "SV (503)",
    i: "sv",
    code: 503,
  },
  {
    n: "GQ (240)",
    i: "gq",
    code: 240,
  },
  {
    n: "ER (291)",
    i: "er",
    code: 291,
  },
  {
    n: "EE (372)",
    i: "ee",
    code: 372,
  },
  {
    n: "ET (251)",
    i: "et",
    code: 251,
  },
  {
    n: "FK (500)",
    i: "fk",
    code: 500,
  },
  {
    n: "FO (298)",
    i: "fo",
    code: 298,
  },
  {
    n: "FJ (679)",
    i: "fj",
    code: 679,
  },
  {
    n: "FI (358)",
    i: "fi",
    code: 358,
  },
  {
    n: "FR (33)",
    i: "fr",
    code: 33,
  },
  {
    n: "GF (254)",
    i: "gf",
    code: 257,
  },
  {
    n: "PF (689)",
    i: "pf",
    code: 689,
  },
  {
    n: "TF (260)",
    i: "tf",
    code: 257,
  },
  {
    n: "GA (241)",
    i: "ga",
    code: 241,
  },
  {
    n: "GM (220)",
    i: "gm",
    code: 220,
  },
  {
    n: "GE (995)",
    i: "ge",
    code: 995,
  },
  {
    n: "DE (49)",
    i: "de",
    code: 49,
  },
  {
    n: "GH (233)",
    i: "gh",
    code: 233,
  },
  {
    n: "GI (350)",
    i: "gi",
    code: 350,
  },
  {
    n: "GR (30)",
    i: "gr",
    code: 30,
  },
  {
    n: "GL (299)",
    i: "gl",
    code: 299,
  },
  {
    n: "GD (1473)",
    i: "gd",
    code: 1473,
  },
  {
    n: "GP (312)",
    i: "gp",
    code: 257,
  },
  {
    n: "GU (1671)",
    i: "gu",
    code: 1671,
  },
  {
    n: "GT (502)",
    i: "gt",
    code: 502,
  },
  {
    n: "GG (1481)",
    i: "gg",
    code: 1481,
  },
  {
    n: "GN (224)",
    i: "gn",
    code: 224,
  },
  {
    n: "GW (245)",
    i: "gw",
    code: 245,
  },
  {
    n: "GY (592)",
    i: "gy",
    code: 592,
  },
  {
    n: "HT (509)",
    i: "ht",
    code: 509,
  },
  {
    n: "HM (334)",
    i: "hm",
    code: 257,
  },
  {
    n: "HN (504)",
    i: "HN",
    code: 504,
  },
  {
    n: "HK (852)",
    i: "hk",
    code: 852,
  },
  {
    n: "HU (36)",
    i: "hu",
    code: 36,
  },
  {
    n: "IS (354)",
    i: "is",
    code: 354,
  },
  {
    n: "IN (91)",
    i: "in",
    code: 91,
  },
  {
    n: "ID (62)",
    i: "id",
    code: 62,
  },
  {
    n: "IR (98)",
    i: "ir",
    code: 98,
  },
  {
    n: "IQ (964)",
    i: "iq",
    code: 964,
  },
  {
    n: "IE (353)",
    i: "ie",
    code: 353,
  },
  {
    n: "IM (1624)",
    i: "im",
    code: 1624,
  },
  {
    n: "IL (972)",
    i: "il",
    code: 972,
  },
  {
    n: "IT (39)",
    i: "it",
    code: 39,
  },
  {
    n: "JM (1876)",
    i: "jm",
    code: 1876,
  },
  {
    n: "JP (81)",
    i: "jp",
    code: 81,
  },
  {
    n: "JE (1534)",
    i: "je",
    code: 1534,
  },
  {
    n: "JO (962)",
    i: "jo",
    code: 962,
  },
  {
    n: "KZ (7)",
    i: "kz",
    code: 7,
  },
  {
    n: "KE (254)",
    i: "ke",
    code: 254,
  },
  {
    n: "KI (686)",
    i: "ki",
    code: 686,
  },
  {
    n: "XK (383)",
    i: "xk",
    code: 383,
  },
  {
    n: "KW (965)",
    i: "kw",
    code: 965,
  },
  {
    n: "KG (996)",
    i: "kg",
    code: 996,
  },
  {
    n: "LA (856)",
    i: "la",
    code: 856,
  },
  {
    n: "LV (371)",
    i: "lv",
    code: 371,
  },
  {
    n: "LB (961)",
    i: "lb",
    code: 961,
  },
  {
    n: "LS (266)",
    i: "ls",
    code: 266,
  },
  {
    n: "LR (231)",
    i: "lr",
    code: 231,
  },
  {
    n: "LY (218)",
    i: "ly",
    code: 218,
  },
  {
    n: "LI (423)",
    i: "li",
    code: 423,
  },
  {
    n: "LT (370)",
    i: "lt",
    code: 370,
  },
  {
    n: "LU (352)",
    i: "lu",
    code: 352,
  },
  {
    n: "MO (853)",
    i: "mo",
    code: 853,
  },
  {
    n: "MK (389)",
    i: "mk",
    code: 257,
  },
  {
    n: "MG (261)",
    i: "mg",
    code: 261,
  },
  {
    n: "MW (265)",
    i: "mw",
    code: 265,
  },
  {
    n: "MY (60)",
    i: "my",
    code: 60,
  },
  {
    n: "MV (960)",
    i: "mv",
    code: 960,
  },
  {
    n: "ML (223)",
    i: "ml",
    code: 223,
  },
  {
    n: "MT (356)",
    i: "mt",
    code: 356,
  },
  {
    n: "MH (692)",
    i: "mh",
    code: 692,
  },
  {
    n: "MQ (474)",
    i: "mq",
    code: 257,
  },
  {
    n: "MR (222)",
    i: "mr",
    code: 222,
  },
  {
    n: "MU (230)",
    i: "mu",
    code: 230,
  },
  {
    n: "YT (262)",
    i: "yt",
    code: 262,
  },
  {
    n: "MX (52)",
    i: "mx",
    code: 52,
  },
  {
    n: "FM (691)",
    i: "fm",
    code: 691,
  },
  {
    n: "MD (373)",
    i: "md",
    code: 373,
  },
  {
    n: "MC (377)",
    i: "mc",
    code: 377,
  },
  {
    n: "MN (976)",
    i: "mn",
    code: 976,
  },
  {
    n: "ME (382)",
    i: "me",
    code: 382,
  },
  {
    n: "MS (1664)",
    i: "ms",
    code: 1664,
  },
  {
    n: "MA (212)",
    i: "ma",
    code: 212,
  },
  {
    n: "MZ (258)",
    i: "mz",
    code: 258,
  },
  {
    n: "MM (95)",
    i: "mm",
    code: 95,
  },
  {
    n: "NA (264)",
    i: "na",
    code: 264,
  },
  {
    n: "NR (674)",
    i: "nr",
    code: 674,
  },
  {
    n: "NP (977)",
    i: "np",
    code: 977,
  },
  {
    n: "NL (31)",
    i: "nl",
    code: 31,
  },
  {
    n: "NC (687)",
    i: "nc",
    code: 687,
  },
  {
    n: "NZ (64)",
    i: "nz",
    code: 64,
  },
  {
    n: "NI (505)",
    i: "ni",
    code: 505,
  },
  {
    n: "NE (227)",
    i: "ne",
    code: 227,
  },
  {
    n: "NG (234)",
    i: "ng",
    code: 234,
  },
  {
    n: "NU (683)",
    i: "nu",
    code: 683,
  },
  {
    n: "NF (016)",
    i: "nf",
    code: 257,
  },
  {
    n: "KP (850)",
    i: "kp",
    code: 850,
  },
  {
    n: "MP (1670)",
    i: "mp",
    code: 1670,
  },
  {
    n: "NO (47)",
    i: "no",
    code: 47,
  },
  {
    n: "OM (968)",
    i: "om",
    code: 968,
  },
  {
    n: "PK (92)",
    i: "pk",
    code: 92,
  },
  {
    n: "PW (680)",
    i: "pw",
    code: 680,
  },
  {
    n: "PS (970)",
    i: "ps",
    code: 970,
  },
  {
    n: "PA (507)",
    i: "pa",
    code: 507,
  },
  {
    n: "PG (675)",
    i: "pg",
    code: 675,
  },
  {
    n: "PY (595)",
    i: "py",
    code: 595,
  },
  {
    n: "PE (51)",
    i: "pe",
    code: 51,
  },
  {
    n: "PH (63)",
    i: "ph",
    code: 63,
  },
  {
    n: "PN (64)",
    i: "pn",
    code: 64,
  },
  {
    n: "PL (48)",
    i: "pl",
    code: 48,
  },
  {
    n: "PT (351)",
    i: "pt",
    code: 351,
  },
  {
    n: "PR (1787)",
    i: "pr",
    code: 1787,
  },
  {
    n: "QA (974)",
    i: "qa",
    code: 974,
  },
  {
    n: "RE (262)",
    i: "re",
    code: 262,
  },
  {
    n: "RO (40)",
    i: "ro",
    code: 40,
  },
  {
    n: "RU (7)",
    i: "ru",
    code: 7,
  },
  {
    n: "RW (250)",
    i: "rw",
    code: 250,
  },
  {
    n: "BL (590)",
    i: "bl",
    code: 590,
  },
  {
    n: "SH (290)",
    i: "sh",
    code: 290,
  },
  {
    n: "KN (1869)",
    i: "kn",
    code: 1869,
  },
  {
    n: "LC (1758)",
    i: "lc",
    code: 1758,
  },
  {
    n: "MF (590)",
    i: "mf",
    code: 590,
  },
  {
    n: "PM (508)",
    i: "pm",
    code: 508,
  },
  {
    n: "VC (1784)",
    i: "vc",
    code: 1784,
  },
  {
    n: "WS (685)",
    i: "ws",
    code: 685,
  },
  {
    n: "SM (378)",
    i: "sm",
    code: 378,
  },
  {
    n: "ST (239)",
    i: "st",
    code: 239,
  },
  {
    n: "SA (966)",
    i: "sa",
    code: 966,
  },
  {
    n: "SN (221)",
    i: "sn",
    code: 221,
  },
  {
    n: "RS (381)",
    i: "rs",
    code: 381,
  },
  {
    n: "SC (248)",
    i: "sc",
    code: 248,
  },
  {
    n: "SL (232)",
    i: "sl",
    code: 232,
  },
  {
    n: "SG (65)",
    i: "sg",
    code: 65,
  },
  {
    n: "SX (1721)",
    i: "sx",
    code: 1721,
  },
  {
    n: "SK (421)",
    i: "sk",
    code: 421,
  },
  {
    n: "SI (386)",
    i: "si",
    code: 386,
  },
  {
    n: "SB (677)",
    i: "sb",
    code: 677,
  },
  {
    n: "SO (252)",
    i: "so",
    code: 252,
  },
  {
    n: "ZA (27)",
    i: "za",
    code: 27,
  },
  {
    n: "GS (016)",
    i: "gs",
    code: 257,
  },
  {
    n: "KR (82)",
    i: "kr",
    code: 82,
  },
  {
    n: "SS (211)",
    i: "ss",
    code: 211,
  },
  {
    n: "ES (34)",
    i: "es",
    code: 34,
  },
  {
    n: "LK (94)",
    i: "lk",
    code: 94,
  },
  {
    n: "SD (249)",
    i: "sd",
    code: 249,
  },
  {
    n: "SR (597)",
    i: "sr",
    code: 597,
  },
  {
    n: "SJ (47)",
    i: "sj",
    code: 47,
  },
  {
    n: "SZ (268)",
    i: "sz",
    code: 268,
  },
  {
    n: "SE (46)",
    i: "se",
    code: 46,
  },
  {
    n: "CH (41)",
    i: "ch",
    code: 41,
  },
  {
    n: "SY (963)",
    i: "sy",
    code: 963,
  },
  {
    n: "TW (886)",
    i: "tw",
    code: 886,
  },
  {
    n: "TJ (992)",
    i: "tj",
    code: 992,
  },
  {
    n: "TZ (255)",
    i: "tz",
    code: 255,
  },
  {
    n: "TH (66)",
    i: "th",
    code: 66,
  },
  {
    n: "TL (670)",
    i: "tl",
    code: 670,
  },
  {
    n: "TG (228)",
    i: "tg",
    code: 228,
  },
  {
    n: "TK (690)",
    i: "tk",
    code: 690,
  },
  {
    n: "TO (676)",
    i: "to",
    code: 676,
  },
  {
    n: "TT (1868)",
    i: "tt",
    code: 1868,
  },
  {
    n: "TN (216)",
    i: "tn",
    code: 216,
  },
  {
    n: "90 (016)",
    i: "tr",
    code: 90,
  },
  {
    n: "TM (993)",
    i: "tm",
    code: 993,
  },
  {
    n: "TC (1649)",
    i: "tc",
    code: 1649,
  },
  {
    n: "TV (688)",
    i: "tv",
    code: 688,
  },
  {
    n: "UG (256)",
    i: "ug",
    code: 256,
  },
  {
    n: "UA (380)",
    i: "ua",
    code: 380,
  },
  {
    n: "AE (971)",
    i: "ae",
    code: 971,
  },
  {
    n: "GB (44)",
    i: "gb",
    code: 44,
  },
  {
    n: "US (1)",
    i: "us",
    code: 1,
  },
  {
    n: "UM (016)",
    i: "um",
    code: 257,
  },
  {
    n: "VI (1340)",
    i: "vi",
    code: 1340,
  },
  {
    n: "UY (598)",
    i: "uy",
    code: 598,
  },
  {
    n: "UZ (998)",
    i: "uz",
    code: 998,
  },
  {
    n: "VU (678)",
    i: "vu",
    code: 678,
  },
  {
    n: "VA (379)",
    i: "va",
    code: 379,
  },
  {
    n: "VE (58)",
    i: "ve",
    code: 58,
  },
  {
    n: "VN (84)",
    i: "vn",
    code: 84,
  },
  {
    n: "WF (681)",
    i: "wf",
    code: 681,
  },
  {
    n: "EH (212)",
    i: "eh",
    code: 212,
  },
  {
    n: "YE (967)",
    i: "ye",
    code: 967,
  },
  {
    n: "ZM (260)",
    i: "zm",
    code: 260,
  },
  {
    n: "ZW (263)",
    i: "zw",
    code: 263,
  },
];

const Smartphone = ({
  setShowIncommingCall,
  setUsuarioModal,
  contestar,
  ignorar,
  setContestar,
  setIgnorar,
  enllamada,
  setEnLlamada,
}) => {
  const softphone = useContext(softphoneContext);
  const [mensaje, setMensaje] = useState({
    id: "SENDER",
    subject: "",
    msg: {},
  });

  const [phoneNumber, setPhoneNumber] = useState("");
  const [usuarioEntrante, setUsuarioEntrante] = useState("");
  const [showtransferModal, setShowTranferModal] = useState(false);
  const [showContactosModal, setShowContactosModal] = useState(false);
  const [countryCode, setCountryCode] = useState(52);
  const [mute, setMute] = useState(false);
  const key_sound = new Audio("songs/phone_key_type.mp3");
  const [showPausaModal, setShowPausaModal] = useState(false);

  //Use Effect para estilos del input de banderas
  useEffect(() => {
    document.getElementsByClassName("PhoneInputInput")[0].disabled = true;
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (currentUser[0]) {
        topics.push("/topic/telefono-" + currentUser[0].usuario.username);
      }/*
      console.log(topics);
      getfuntion(
        "get",
        "",
        "",
        "softphone/obtener_informacion_de_telefono?telefono=7621004239",
        "informacion_de_telefono"
      );*/
    }
  }, []);

  useEffect(() => {
    if (usuarioEntrante && usuarioEntrante.length && Number(usuarioEntrante)) {
      //alert("lamanda = : "+usuarioEntrante);
      getfuntion(
        "get",
        "",
        "",
        "softphone/obtener_informacion_de_telefono?telefono=" + usuarioEntrante,
        "informacion_de_telefono"
      );
    }
  }, [usuarioEntrante]);

  useEffect(() => {
    if (mensaje.subject !== "") {
      setMensaje({ ...mensaje, subject: "" });
    }
  }, [mensaje.subject]);

  useEffect(() => {
    if (contestar) {
      setMensaje((prevM) => {
        return {
          ...prevM,
          subject: "ANSWER CALL",
          msg: { responder: true },
        };
      });
      setContestar(false);
      setShowIncommingCall(false);
    }
  }, [contestar]);

  useEffect(() => {
    if (ignorar) {
      setMensaje((prevM) => {
        return {
          ...prevM,
          subject: "IGNORE CALL",
          msg: { ignorar: true },
        };
      });
      setIgnorar(false);
      setShowIncommingCall(false);
    }
  }, [ignorar]);

  useEffect(()=>{
    if(Number(softphone.getLlamarClic())){      
      addNumber(softphone.getLlamarClic());   
    }    
  },[softphone.getLlamarClic()]);

  useEffect(()=>{
    if(Number(softphone.getLlamarClic()) && softphone.getLlamarClic().length === 10){
      softphone.setLlamarClic(null);
      handleCall();     
    }    
  },[phoneNumber]);

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "usuario",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "informacion_de_telefono") {
              softphone.setMovimiento({ data: returnVal, estatus: true });
            }
            break;
          case "post":
            if (stateVar === "softphone_log") {
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addNumber = (number) => {
    key_sound.play();
    if (phoneNumber.length < 37) {
      setPhoneNumber((prev) => {
        return prev + number;
      });
    }
  };

  const removeNumber = () => {
    if (phoneNumber.length > 0) {
      setPhoneNumber((prev) => {
        // setMensaje((prevM) => {
        //   return {
        //     ...prevM,
        //     subject: "ADD NUMBER",
        //     msg: { numero: prev.slice(0, -1) },
        //   };
        // });
        return prev.slice(0, -1);
      });
    }
  };

  const setUsuario = (usuario) => {
    setUsuarioModal(usuario);
    setUsuarioEntrante(usuario);
  };

  const handleCall = () => {
    //llamada en un click
    if (!/^[A-z\d*#+.]{2,36}$/.test(phoneNumber)) {
      window.alert("Colocar un número válido");
      return;
    }
    let numeroALlamar =
      /^[A-z.]*$/.test(phoneNumber) || phoneNumber.length === 4
        ? phoneNumber
        : countryCode + phoneNumber;
    setEnLlamada(true);
    setUsuarioEntrante(numeroALlamar);
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "CALL USER",
        msg: { llamar: true, numero: numeroALlamar },
      };
    });
  };

  const handleHangUp = () => {
    setEnLlamada(false);
    setUsuario("");
    setMute(false);
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "END CALL",
        msg: { colgar: true },
      };
    });
  };

  const handleTransfer = (numero) => {
    setUsuarioEntrante("Transfiriendo...");
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "TRANSFER",
        msg: { transfer: true, numero },
      };
    });
  };

  const handleMute = () => {
    setMensaje((prevM) => {
      return {
        ...prevM,
        subject: "MUTE",
        msg: { mute: true },
      };
    });
  };

  return (
    <Fragment>
      <div
        className={classes.smartphone}
        style={{ position: "absolute", top: -180, right: 10, zIndex: 300 }}
      >
        {/* <audio id="phone_key_sound" src="songs/phone_key_type.mp3" /> */}
        <TelefonoMensajeria
          topic={topics}
          mensaje={mensaje}
          setMensaje={setMensaje}
          setUsuarioEntrante={setUsuario}
          setShowIncommingCall={setShowIncommingCall}
          setShowTranferModal={setShowTranferModal}
          setEnLlamada={setEnLlamada}
          usuario={
            currentUser[0]
              ? currentUser[0].usuario
                ? currentUser[0].usuario.username
                  ? currentUser[0].usuario.username
                  : ""
                : ""
              : ""
          }
        />
        <div className={classes.camera}></div>
        <div className={classes.speaker}></div>
        <div className={classes.flashlight}></div>
        <div className={classes.btnVol}></div>
        <div className={classes.btnPower}></div>
        <div className={classes.innerMargin}>
          <div style={{ display: "grid", gridAutoFlow: "column" }}>
            <div style={{ alignSelf: "center", paddingLeft: 20 }}>
              <img src={"/skyangel.png"} alt="SkyOne" width="35" height="50" />
            </div>
            <div
              style={{
                alignSelf: "center",
                textAlign: "left",
                paddingTop: 10,
              }}
            >
              {!usuarioEntrante ? (
                <p style={{ fontSize: 25, fontWeight: "bold", color: "white" }}>
                  SkyOne
                </p>
              ) : (
                <>
                  <p>En llamada</p>
                  <p
                    style={{ fontSize: 16, fontWeight: "bold", color: "white" }}
                  >
                    {usuarioEntrante}
                  </p>
                </>
              )}
            </div>
          </div>

          {/** Display de numeros */}
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(54, 54, 54)",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <div
              style={{
                display: "inline-block",
                width: "40%",
              }}
            >
              <PhoneInput
                placeholder={`(${countryCode})`}
                international={false}
                onChange={() => {}}
                onCountryChange={(e) => {
                  let countryFound = countryList.find(
                    (country) => country.i === e.toLocaleLowerCase()
                  );
                  if (countryFound) {
                    setCountryCode(countryFound.code);
                  }
                }}
                country={"MX"}
                defaultCountry="MX"
                labels={es}
                className={classes.flagInput}
              />
            </div>
            <div
              style={{
                display: "inline-block",
                width: "60%",
              }}
            >
              <input
                type="text"
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  borderStyle: "none",
                  outline: "none",
                  color: "white",
                  fontWeight: "bold",
                }}
                value={phoneNumber}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    removeNumber();
                  } else {
                    addNumber(e.key);
                  }
                }}
              />
            </div>
          </div>
          {/* <div
          style={{
            backgroundColor: "white",
            width: "90%",
            height: "10%",
            borderRadius: 10,
            margin: "0 auto",
            padding: 10,
          }}
        >
          <p style={{ textAlign: "center", fontWeight: "bold" }}>
            {usuarioEntrante ? usuarioEntrante : phoneNumber}
          </p>
        </div> */}
          {/** Teclado 1 - 3*/}
          <div
            style={{
              paddingLeft: 15,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <Tecla
              character="1"
              appendFunction={() => addNumber("1")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="2"
              appendFunction={() => addNumber("2")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="3"
              appendFunction={() => addNumber("3")}
              customStyles={{
                borderBottomWidth: 2,
                borderBottomColor: "#6A6A6A",
              }}
            />
          </div>
          {/** Teclado 4 - 6*/}
          <div
            style={{
              paddingLeft: 15,
            }}
          >
            <Tecla
              character="4"
              appendFunction={() => addNumber("4")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="5"
              appendFunction={() => addNumber("5")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="6"
              appendFunction={() => addNumber("6")}
              customStyles={{
                borderBottomWidth: 2,
                borderBottomColor: "#6A6A6A",
              }}
            />
          </div>
          {/** Teclado 7 - 9*/}
          <div
            style={{
              paddingLeft: 15,
            }}
          >
            <Tecla
              character="7"
              appendFunction={() => addNumber("7")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="8"
              appendFunction={() => addNumber("8")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="9"
              appendFunction={() => addNumber("9")}
              customStyles={{
                borderBottomWidth: 2,
                borderBottomColor: "#6A6A6A",
              }}
            />
          </div>
          {/** Teclado * 0 #*/}
          <div
            style={{
              paddingLeft: 15,
            }}
          >
            <Tecla
              character="*"
              appendFunction={() => addNumber("*")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="0"
              appendFunction={() => addNumber("0")}
              customStyles={{
                borderRightWidth: 2,
                borderBottomWidth: 2,
                borderRightColor: "#6A6A6A",
                borderBottomColor: "#6A6A6A",
              }}
            />
            <Tecla
              character="#"
              appendFunction={() => addNumber("#")}
              customStyles={{
                borderBottomWidth: 2,
                borderBottomColor: "#6A6A6A",
              }}
            />
          </div>
          {/** Botones de colgar y borrar */}
          <div
            style={{
              margin: 0,
              position: "absolute",
              top: "83%",
              left: "15%",
              display: "flex",
              flexDirection: "row",
            }}
            console
          >
            <div
              style={{
                backgroundColor: "#676767",
                borderRadius: 50,
                cursor: "pointer",
                paddingTop: 5,
                paddingLeft: 18,
                paddingRight: 18,
                marginRight: 2,
              }}
              onClick={removeNumber}
            >
              <i
                className="fe fe-skip-back"
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                  color: "white",
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: !enllamada ? "#1F618D" : "#7B241C",
                borderRadius: 50,
                cursor: "pointer",
                paddingTop: 7,
                paddingLeft: 18,
                paddingRight: 18,
                marginRight: 2,
              }}
              onClick={!enllamada ? handleCall : handleHangUp}
            >
              <i
                className={`fe ${
                  !enllamada ? "fe-phone-call" : "fe-phone-off"
                }`}
                style={{ fontWeight: "bold", color: "white" }}
              />
            </div>

            <div
              style={{
                backgroundColor: !mute ? "#5499C7" : "white",
                borderRadius: 50,
                cursor: "pointer",
                paddingTop: 7,
                paddingLeft: 18,
                paddingRight: 18,
              }}
              onClick={() => {
                if (enllamada) {
                  setMute((prev) => !prev);
                  handleMute();
                } else {
                  alert("Sólo es posible mutearse en una llamada activa");
                }
              }}
            >
              <i
                className={`fe ${!mute ? "fe-mic" : "fe-mic-off"}`}
                style={{
                  fontWeight: "bold",
                  color: !mute ? "white" : "#5499C7",
                }}
              />
            </div>
          </div>
          <div
            style={{
              margin: 0,
              position: "absolute",
              top: "90%",
              left: "5%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                setShowPausaModal(true);
              }}
            >
              <i
                className={`fe fe-bell`}
                style={{
                  fontWeight: "bold",
                  color: softphone.getValues().pausaEstatus ? "red" : "white",
                  marginLeft: 8,
                }}
              />
              <p style={{ fontSize: 10, color: "white" }}>Pausas</p>
            </div>
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => setShowContactosModal(true)}
            >
              <i
                className={`fe fe-users`}
                style={{ fontWeight: "bold", color: "white", marginLeft: 8 }}
              />
              <p style={{ fontSize: 10, color: "white" }}>Contactos</p>
            </div>
            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => {}}
            >
              <i
                className={`fe fe-clock`}
                style={{ fontWeight: "bold", color: "white", marginLeft: 8 }}
              />
              <p style={{ fontSize: 10, color: "white" }}>Recientes</p>
            </div>

            <div
              style={{
                backgroundColor: "transparent",
                borderRadius: 10,
                padding: 10,
                cursor: "pointer",
              }}
              onClick={() => {
                if (enllamada) setShowTranferModal(true);
                else alert("Sólo es posible transferir una llamada activa");
              }}
            >
              <i
                className={`fe fe-share`}
                style={{ fontWeight: "bold", color: "white", marginLeft: 8 }}
              />
              <p style={{ fontSize: 10, color: "white" }}>Transferir</p>
            </div>
          </div>
        </div>
      </div>
      {createPortal(
        <TransferModal
          show={showtransferModal}
          handleCloseModal={() => setShowTranferModal(false)}
          handleOKButton={handleTransfer}
          countryList={countryList}
        />,
        document.body
      )}
      {createPortal(
        <PausaModal
          show={showPausaModal}
          handleCloseModal={() => {
            setShowPausaModal(false);
          }}
        />,
        document.body
      )}
      {createPortal(
        <ContactosModal
          show={showContactosModal}
          handleCloseModal={() => setShowContactosModal(false)}
          handleOKButton={(numero) => {
            setPhoneNumber(numero);
            setShowContactosModal(false);
          }}
        />,
        document.body
      )}
    </Fragment>
  );
};

export default Smartphone;
