import React, { Component } from "react";
import { Form, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";


var contMt = 0;
class CamposLogisticos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      camposLogisticos: [],
      movimientosTipoKey: {},
      movimientosTipo: [],
      search: "",
      hiddenModals: true,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.camposLogisticos = [];
    this.movimientosTipoKey = {};
    this.movimientosTipoCampos = {};
  }
  componentWillMount = () => {
    this.getfuntion(
      "get",
      "",
      "",
      "plataformasmovimientostipo/plataforma/" + this.props.elementos.id,
      "Movimientostipo"
    );
  };
  componentDidMount = () => {};
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "Movimientostipo") {
                let mtipo = [];
                for (let item of returnVal) {
                  let idMTipo;
                 
                  switch (item.idMovimientoTipo.id) {
                    case 3:
                      idMTipo = 1;
                      break;
                    case 4:
                      idMTipo = 2;
                      break;
                     case 336:
                       idMTipo = 336;
                       break;
                     case 337:
                       idMTipo = 337;
                       break;
                    default:
                      idMTipo = item.idMovimientoTipo.id;
                      break;
                  }
                  mtipo.push({
                    label: item.idMovimientoTipo.nombre,
                    value: idMTipo,
                  });
                  this.movimientosTipoKey[idMTipo] = {
                    label: item.idMovimientoTipo.nombre,
                    value: idMTipo,
                  };
                }
                this.setState({ movimientosTipo: mtipo });
                if (this.state.accion !== "nuevo") {

                  switch (
                    this.props.elementos.plataformaTipo.nombre.toUpperCase()
                  ) {
                    case "NAVIERA":
                      {
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/1",
                          "camposLogisticos"
                        );
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/2",
                          "camposLogisticos"
                        );
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/336",
                          "camposLogisticos"
                        );
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/337",
                          "camposLogisticos"
                        );
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/270",
                          "camposLogisticos"
                        );
                      }
                      break;
                    case "TERRESTRE":
                      {
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/3",
                          "camposLogisticos"
                        );

                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/270",
                          "camposLogisticos"
                        );
                      }
                      break;
                    case "TERRESTRE NAVIERA":
                      {
                        console.log("TERRESTRES NAVIERA")
                        //this.getfuntion("get", "", "", "camposLogisticos/movimientoTipo/3", "camposLogisticos");
                        //this.getfuntion("get", "", "", "camposLogisticos/movimientoTipo/4", "camposLogisticos");
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/1",
                          "camposLogisticos"
                        );
                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/2",
                          "camposLogisticos"
                        );

                        this.getfuntion(
                          "get",
                          "",
                          "",
                          "camposLogisticos/movimientoTipo/270",
                          "camposLogisticos"
                        );
                      }
                      break;
                    default:
                      break;
                  }
                } else {
                }
              }
              if (stateVar === "camposLogisticos") {
                this.camposLogisticos = this.camposLogisticos.concat(returnVal);
                this.setState({ camposLogisticos: this.camposLogisticos });
                contMt++;
                if (
                  this.props.elementos.plataformaTipo.nombre
                    .toUpperCase()
                    .includes("NAVIERA") &&
                  contMt > 1
                ) {
                  this.getfuntion(
                    "get",
                    "",
                    "",
                    "plataformaCamposLogisticos/plataforma/" +
                      this.props.elementos.id,
                    "plataformaCampos"
                  );
                } else {
                  this.getfuntion(
                    "get",
                    "",
                    "",
                    "plataformaCamposLogisticos/plataforma/" +
                      this.props.elementos.id,
                    "plataformaCampos"
                  );
                }
              }
              if (stateVar === "plataformaCampos") {
                
                let pCampos = {};
                for (let item of returnVal) {
                  pCampos[item["idCamposLogisticos"]] = item;
                }
                this.camposLogisticos.map((campo, index) => {
                  
                  if (pCampos[campo["id"]]) {
                    let mtipoid;
                    if (
                      this.props.elementos.plataformaTipo.nombre
                        .toUpperCase()
                        .includes("NAVIERA")
                    ) {
                      mtipoid = campo.idMovimientoTipo;
                    } else {
                      if (campo.idMovimientoTipo === 3) {
                        mtipoid = 1;
                      }
                      //if(campo.idMovimientoTipo ===4 ){ mtipoid= 2;}
                    }
                    this.movimientosTipoCampos[campo.id] =
                      this.movimientosTipoKey[mtipoid];
                  } else {

                    if (campo.idMovimientoTipo == 1) {
                      this.movimientosTipoCampos[campo["id"]] =
                        this.movimientosTipoKey[1];
                    }
                    if (campo.idMovimientoTipo == 2) {
                      this.movimientosTipoCampos[campo["id"]] =
                        this.movimientosTipoKey[2];
                    }
                    if (campo.idMovimientoTipo == 3) {
                      this.movimientosTipoCampos[campo["id"]] =
                        this.movimientosTipoKey[1];
                    }

                    if (campo.idMovimientoTipo == 270) {
                      this.movimientosTipoCampos[campo["id"]] =
                        this.movimientosTipoKey[270];
                    }
                    //if(campo.idMovimientoTipo == 4) { this.movimientosTipoCampos[campo['id']]= this.movimientosTipoKey[2]; }
                  }
                });
                this.setState({ plataformaCampos: pCampos });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "plataformaCampos") {
                this.getfuntion(
                  "get",
                  "",
                  "",
                  "plataformaCamposLogisticos/plataforma/" +
                    this.props.elementos.id,
                  "plataformaCampos"
                );
                /*
                  let plCamposLog = this.state.plataformaCampos;
                  plCamposLog[returnVal["idCamposLogisticos"]] = returnVal;
                  this.setState({ plataformaCampos: plCamposLog });
                */
                NotificationManager.info("Nuevo", "Agregado correctamente");
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "plataformaCampos") {
                if (returnVal) {
                  let plCamposLog = this.state.plataformaCampos;
                  plCamposLog[obj] = null;
                  this.setState({ plataformaCampos: plCamposLog });
                  NotificationManager.info("Nuevo", "Borrado correctamente");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkCampo = (e) => {
    if (this.movimientosTipoCampos[e.target.name]) {
      let plataformaCampo = {
        idMovimientoTipo: this.movimientosTipoCampos[e.target.name].value,
        idPlataforma: this.props.elementos.id,
        idCamposLogisticos: parseInt(e.target.name),
      };
      if (e.target.checked) {
        this.getfuntion(
          "post",
          plataformaCampo,
          "",
          "plataformaCamposLogisticos",
          "plataformaCampos"
        );
      } else {
        this.getfuntion(
          "delete",
          e.target.name,
          this.state.plataformaCampos[e.target.name].id,
          "plataformaCamposLogisticos",
          "plataformaCampos"
        );
      }
    } else {
      NotificationManager.error(
        "Ha ocurrido un error (Movimiento tipo no configurado)",
        "Error"
      );
    }
  };
  isChecked = (campoId) => {
    if (this.state.plataformaCampos) {
      if (this.state.plataformaCampos[campoId]) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  getTipoMovmiento = (id) => {
    if (this.movimientosTipoCampos[id]) {
      return this.movimientosTipoCampos[id].label;
    } else {
      return "...";
    }
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  render() {
    return (
      <div>
        {/**
         *   Elementos de plataformas
         */}
        {this.state.accion === "modificar" ? (
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Campos Logisticos"
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={false}
                  options={
                    <Form.Group>
                      <Form.Input
                        className="mb-3"
                        icon="search"
                        placeholder="Buscar Campo Logistico..."
                        position="append"
                        onChange={this.searching}
                      />
                    </Form.Group>
                  }
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Movimiento Tipo</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {this.state.camposLogisticos
                              .filter((word) =>
                                word.nombre
                                  .toUpperCase()
                                  .includes(this.state.search.toUpperCase())
                              )
                              .sort()
                              .map((elemento, index) => (
                                <Table.Row>
                                  <Table.Col> {elemento["id"]}</Table.Col>
                                  <Table.Col> {elemento["nombre"]}</Table.Col>
                                  <Table.Col>
                                    {" "}
                                    {this.getTipoMovmiento(elemento["id"])}
                                  </Table.Col>
                                  <Table.Col>
                                    {" "}
                                    <Form.Switch
                                      type="Checkbox"
                                      name={elemento["id"]}
                                      value={index}
                                      checked={this.isChecked(elemento["id"])}
                                      onChange={this.checkCampo}
                                    />{" "}
                                  </Table.Col>
                                </Table.Row>
                              ))}
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            </Grid.Row>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CamposLogisticos;
