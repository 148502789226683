import React from "react";

const DisplayPhoneButton = ({ phoneDiplayed, updatePhoneDisplayed }) => {
  return (
    <div>
      <span
        className={`badge badge-${!phoneDiplayed ? "info" : "danger"} btn`}
        onClick={updatePhoneDisplayed}
        style={{ margin: 5 }}
      >
        Teléfono{" "}
        <i className={`fa ${phoneDiplayed ? "fa-times" : "fa-phone"}`} />
      </span>
    </div>
  );
};

export default DisplayPhoneButton;
