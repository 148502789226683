import React, { useState } from "react";
import Modal from "react-modal";
import "./ModalLay.css";

var appElement = document.getElementById("example");
Modal.setAppElement(appElement);
/** ModalLayout
 * Descripción: Permite Desplegar un Modal con su respectivo contenido. Siempre envía la función para cerrar el modal al formulario recibido por props.
 * Props:
 * @name Se pasa la etiqueta con el icono que identifica al botón para abrir el modal.
 * @resumirModal Variable booleana que permite ver si se reanuda o no el modal una vez minimizado.
 * @tamanio Tamaño del modal
 * @title Título en el Modal
 * @funcionReanudar Función que cambia el valor de la variable resumirModal
 * @formulario Contenido del modal
 * @datosForm Props que se envían al contenido del modal (formulario).
 *
 */
const ModalLay = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const Formulario = props.formulario;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <label className="transparent-input thumbnail" onClick={openModal}>
        {props.name}
      </label>
      <Modal
        closeTimeoutMS={50}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="Modal"
        overlayClassName="Overlay"
        style={
          props.resumirModal
            ? {
                overlay: { display: "none" },
                content: {
                  position: "relative",
                  top: "60px",
                  width:
                    window.screen.width > 900
                      ? props.tamanio
                      : window.screen.width - 20,
                  height: "auto",
                  margin: "auto",
                },
              }
            : {
                overlay: { display: "block" },
                content: {
                  position: "relative",
                  top: "60px",
                  width:
                    window.screen.width > 900
                      ? props.tamanio
                      : window.screen.width - 20,
                  height: "auto",
                  margin: "auto",
                },
              }
        }
      >
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title"> {props.title}</h4>
            <div class="btn-group">
              {["Modificar", "Nuevo"].includes(props.title) && (
                <button
                  type="button"
                  className="btn btn-primary fe fe-minus"
                  onClick={props.funcionReanudar}
                />
              )}
              <button
                type="button"
                className="btn btn-danger fe fe-x outline pill"
                onClick={closeModal}
              />
            </div>
          </div>
          <div className="modal-body">
            <Formulario {...props.datosForm} cerrarModal={closeModal} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalLay;
