import React, { Component } from "react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { height } from "window-size";
import { Doughnut, Bar, Polar } from 'react-chartjs-2';
//https://www.npmjs.com/package/react-chartjs-2
//http://jerairrest.github.io/react-chartjs-2/
const data = {
	labels: [
		'MXSKYANGEL',
		'MX APM',
		'MX COLOMBIA'
	],
	datasets: [{		
		data: [5, 10, 12],
		backgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		],
		hoverBackgroundColor: [
		'#FF6384',
		'#36A2EB',
		'#FFCE56'
		]
	}]
};
class DashboardView extends Component {
	constructor(props) {
		super(props);
		this.operacionesEstatus = [{i:"En ruta"}, {i:"Sin GPS"}, {i:"Terminados"}];
	  }
	  getFuncionalidad=(elemento)=>{
        if(!this.props.funcionalidades){ return false; }
        for(let item of this.props.funcionalidades){
          if(item.nombre === elemento ){ return true; break;}
        }
        return false;
      }
	render() {
		return (
				<div>
				  { this.props.bloque == "Dashboard" && this.getFuncionalidad("Dashboard")?
					<div className="row" style={{ height: "70vh", textAlign: "center" }}>
						{this.operacionesEstatus.map((operacion, index)=> 
							<div className="col-6">				
								<div>
									<h5>{ operacion.nombre }</h5>
								</div>
								<div style={{ height: "80%" }}>
									<Bar
										data={data }
										width={100}
										height={90}
										options={{ maintainAspectRatio: false }}
									/>
								</div>
							</div>
						)}
					</div>
				  :null}
				</div>
		  );
	}
  
}
export default DashboardView;
