import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import Select from "react-select";
import Chart from "react-apexcharts";
import { TablePageable } from "../../Helpers";
import { timeToDateFill } from "../../herramientas/DateFormat";
const meses = [
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" },
];
const DashBoardGpsEstatusPage = () => {
  const [years, setYears] = useState([]);
  const [gpsList, setGpsList] = useState({ meses: {}, dias: {} });
  const [year, setYear] = useState({ value: new Date().getFullYear(), label: new Date().getFullYear() });
  const [mes, setMes] = useState([]);
  const [dia, setDia] = useState([]);
  const [mes1, setMes1] = useState({ value: 1, label: "Enero" });
  const [mes2, setMes2] = useState({ value: 12, label: "Diciembre" });

  useEffect(() => {
    let yearsTemp = [];
    for (let y = 2022; y <= new Date().getFullYear(); y++) { yearsTemp.push({ value: y, label: y }); }
    setYears(yearsTemp);
  }, []);
  useEffect(() => {
    if (year.value && mes1.value, mes2.value)
      getfuntion("get", "", "", "gps_estatus_log/tiempo/" + year.value + "/" + mes1.value + "/" + mes2.value + "/estatus/137", "gps_estatus_log");
  }, [year, mes1, mes2]);

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "perfilesFuncionalidades", stateVar = "nada", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "gps_estatus_log") {
              let gpsListTemp = { meses: {}, dias:{} };
              for (let g of returnVal) {//console.log(g.id);
                //const yearTemp= new Date(g.altaFechaGg).getFullYear() === 1969? "Sin fecha": new Date(g.altaFechaGg).getFullYear();
                const mesTemp = new Date(g.fechaCrea).getMonth() + 1;
                //Meses
                if (!gpsListTemp["meses"][mesTemp])
                  gpsListTemp["meses"][mesTemp] = [];

                if(!g.dias){
                  let fechaActual= new Date().getTime();
                  g.dias= Math.round( (fechaActual-g.fechaInicio)/(1000*60*60*24))
                }
                g.fechaInicio = timeToDateFill(g.fechaInicio);
                g.fechaFin = timeToDateFill(g.fechaFin);
                gpsListTemp["meses"][mesTemp].push(g);

                //Dias
                if (!gpsListTemp["dias"][g.dias])
                  gpsListTemp["dias"][g.dias] = [];
                gpsListTemp["dias"][g.dias].push(g);
              }/*
                let yearsTemp= [{value: 0, label : "Todos"}];
                Object.keys(gpsList["years"]).forEach((y, indice) => {
                  yearsTemp.push({value: y, label : y}); 
                  setYears(yearsTemp);
                });*/
              setGpsList(gpsListTemp);
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err); })
  }
  //new Date().getWeekNumber()
  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  const getGraficaGpsMes = () => {
    let labels = [];
    let data = [];

    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    Object.keys(gpsList["meses"]).forEach((m, indice) => {
      const meseString = meses.filter(ms => ms.value === Number(m))[0];
      labels.push(meseString.label);
      data.push(gpsList["meses"][meseString.value].length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    });
    //console.log({ labels, data });
    return { labels, data };
  }
  const getGraficaGpsDias = () => {
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];

    Object.keys(gpsList["dias"]).forEach((m, indice) => {
      const meseString = meses.filter(ms => ms.value === Number(m))[0];
      labels.push(m);
      data.push(gpsList["dias"][m].length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    });
    return { labels, data };
  }
  const getGps = () => {
    let gpsListTemp = [];
    if ( mes && mes.value )
      gpsListTemp = gpsList["meses"][mes.value];
    if ( dia && Number(dia) )
      gpsListTemp = gpsList["dias"][dia];
    return gpsListTemp;
  }
  /*
  <div class="form-group">
    <label for="modulo">Modulo</label>
    <Select value= {modulo} options= { modulos } onChange= {(e)=>{setModulo(e);}} />
  </div>
  */
  return (
    <SiteWrapper>
      <div className="container-fluid" style={{ height: "90vh", "font-size": "smaller" }}>{/** width: "100%",  */}
        <div className="row">
          <div className="col">
            <div class="form-group">
              <label for="modulo">Años</label>
              <Select value={year} options={years} onChange={(e) => { setYear(e); }} />
            </div>
          </div>
          <div className="col">
            <div class="form-group">
              <label for="modulo">Mes 1</label>
              <Select value={mes1} options={meses} onChange={(e) => { setMes1(e); }} />
            </div>
          </div>
          <div className="col">
            <div class="form-group">
              <label for="modulo">Mes 2</label>
              <Select value={mes2} options={meses} onChange={(e) => { setMes2(e); }} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6" hidden={!year.value}>
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Envios por mes
                </h4>
              </div>
              <div class="card-body text-success">
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                          setMes(
                            meses.filter(ms =>
                              ms.label === config.globals.labels[config.dataPointIndex]
                            )[0]
                          );
                          setDia({});
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaGpsMes().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaGpsMes().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
              </div>
            </div>
          </div>
          <div className="col-6" hidden={!year.value}>
            <div class="card border-primary mb-3">
              <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Tiempos en envio por dias
                </h4>
              </div>
              <div class="card-body text-success">
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                          setDia(config.globals.labels[config.dataPointIndex]);
                          setMes( {});
                        }
                      }
                    },
                    xaxis: {
                      categories: getGraficaGpsDias().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Gps", data: getGraficaGpsDias().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={300}
                />
              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <div className="row" hidden={!year.value && !mes.value}>
          <div className="col-12">
            <TablePageable
              titulo={<h3> Listado de gps </h3>}
              lista={getGps() ? getGps() : []}
              columnas={
                [
                  { columna: "idGpsGstatusLog", label: "Id" },
                  { columna: "idGps", label: "idGps" },
                  { columna: "imei", label: "Imei" },
                  { columna: "idEstatus", label: "idEstatus" },
                  { columna: "fechaInicio", label: "fechaInicio" },
                  { columna: "fechaFin", label: "fechaFin" },
                  { columna: "dias", label: "dias" },
                  { columna: "origen", label: "origen" },
                  { columna: "destino", label: "destino" },
                  { columna: "descripcion", label: "descripcion" },
                  { columna: "cordenadasOrigen", label: "cordenadasOrigen" },
                  { columna: "cordenadasDestino", label: "cordenadasDestino" },
                ]
              }
              id="idGps"
              selected={() => { }}
              csvname="Gps Mobiles"
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            paginacion={true}
            elementosPorPagina={50}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
}

export default DashBoardGpsEstatusPage;
