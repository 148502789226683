import * as conf from "../config/config";
import axios from 'axios';
//import $ from "jquery";
export function Auth(type="logged", user="", passwor=""){ //type="logged" //loging
  var tokenStore="tok";
  var deadTokenTime="expired";
  let instance = axios.create();
  /* ya funciona  http://34.228.130.148:8080/skyone */
  return axios.post(conf.api_raiz+"token/generate-token",{"username": user, "password": passwor}).then(res => {
    if(res.data["token"]){
      sessionStorage.setItem(tokenStore, res.data["token"]);
      sessionStorage.setItem(deadTokenTime, (new Date().getTime()/1000)+ ((60*60*5)-60) );
    }
    return res.data;
  });
}
  export default Auth;
