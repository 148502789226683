import React, { useState, useEffect, useContext } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import axios from 'axios';
import { NotificationManager } from "react-notifications";
import { timeToDateFill, timeToYearmonthDay } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";
import { TablePageable } from "../../Helpers";
const perfil= JSON.parse(sessionStorage.getItem("perfil"));
let instance = axios.create();
//instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
const ReportesAlertasCctvPage = () => {
  const [ reporte, setReporte ] = useState( []);
  const [funcionalidades, setFuncionalidades]= useState([]);
  const [requestBody, setRequestBody]= useState({
    "key": "KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=",
    "dispositivos": [],//[332]
    "tipos": [],//[62]
    "starttime": timeToYearmonthDay(new Date().getTime()),
    "endtime": timeToYearmonthDay(new Date().getTime())
  });
  const [tipos, setTipos] = useState([]);
  const [tiposList, setTiposList ] = useState( [] );
  const [dispositivosList, setDispositivosList] = useState([]);
  const [dispositivos, setDispositivos ] = useState([]);
  const [alarma, setAlarma]= useState({});

  useEffect(() => {
    crud("get", "", "", "funcionalidades/reporte_alarmas_cctv/"+perfil.id, "funcionalidades");
    delete axios.defaults.headers.common["Authorization"];//:8443/video_back
    instance.get(conf.cctv+":8443/video_back/alarmas/alarmas_tipos?key=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=").then(res => {
      if(res.data){
        let tiposTemp= [];
        for( let tipo of res.data ){
          tiposTemp.push({ value: tipo.type, label: tipo.description, data: tipo});
        }
        setTipos(tiposTemp);
      }else{
        console.log(res);
      }
    }).catch(errors => console.log(errors));
    //:8443/video_back
    instance.get(conf.cctv+":8443/video_back/dispositivos/idproveedor?idProveedor=4&key=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=").then(res => {
      if(res.data.data){
        let dispositivosTemp= [];
        for( let d of res.data.data ){
          dispositivosTemp.push({ value: d.id, label: d.nombre, data: d});
        }
        setDispositivosList(dispositivosTemp);
      }else{
        console.log(res);
      }
    }).catch(errors => console.log(errors));

  },[]);

  useEffect(() => {
    if(funcionalidades.length && dispositivosList.length && tipos.length){
      refrescar();
    }    
  },[funcionalidades, tipos]);

  const refrescar =()=>{
    delete axios.defaults.headers.common["Authorization"];
    let requestBodyTemp= {...requestBody};
    requestBodyTemp.starttime= requestBodyTemp.starttime+" 00:00:00";
    requestBodyTemp.endtime= requestBodyTemp.endtime+" 23:59:59";
    instance.post(conf.cctv+":8443/video_back/alarmas/video/4", requestBodyTemp).then(res => {//:8443/video_back
      if(res.data.status === 200){
        for( let r of res.data.data ){
          r.servidorFecha= timeToDateFill(r.servidorFecha);
        }
        setReporte(res.data.data);
      }else{
        NotificationManager.error(
          res.data.message,
          "Oops ..!!"
        );
      }
    }).catch(errors => console.log(errors));
  }

  const crud=(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get":
              if( stateVar === "funcionalidades"){
                setFuncionalidades(returnVal);
              }
            break;
            default:
                break;
        }
    }).catch(err => { console.log(err); });
  }
  return (
    <SiteWrapper>
      { funcionalidades.filter(f=> f.nombre === "listar").length?
        <div className='container-fluid p-6' style={{height: "90vh"}}>
          <h5> Reporte de Alarmas </h5>
          <hr color='white'/>
          <div className='row mt-5'>
            <div className='col-8'>
              <TablePageable
                titulo={
                  <div className="row">
                    <div className='col'>
                      <label>Fecha inicio</label>
                      <input className="form-control" type="date" id="start" name="trip-start"
                        value={requestBody.starttime}
                        onChange={(e)=>{
                          setRequestBody({...requestBody, starttime: e.target.value});
                          if( new Date(e.target.value).getTime() > new Date(requestBody.endtime).getTime() ){
                            setRequestBody({...requestBody, endtime: e.target.value});
                          }else{
                            if( (new Date(requestBody.endtime).getTime() - new Date(e.target.value).getTime()) > (31*24*60*60*1000) ){                      
                              setRequestBody({...requestBody, endtime: timeToYearmonthDay(new Date(e.target.value).getTime()+(31*24*60*60*1000))});
                            }
                          }
                        }}
                        min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                    </div>
                    <div className='col'>
                      <label>Fecha fin</label>
                      <input className="form-control" type="date" id="start" name="trip-start"
                        value={requestBody.endtime}
                        onChange={(e)=>{
                          setRequestBody({...requestBody, endtime: e.target.value});
                          if( new Date(e.target.value).getTime() < new Date(requestBody.starttime).getTime() ){
                            setRequestBody({...requestBody, starttime: e.target.value});
                          }else{
                            if( (new Date(e.target.value).getTime() - new Date(requestBody.starttime).getTime()) > (31*24*60*60*1000) ){                      
                              setRequestBody({...requestBody, endtime: timeToYearmonthDay(new Date(e.target.value).getTime()-(31*24*60*60*1000))});
                            }
                          }
                        }}
                        min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                    </div>
                    <div className='col'>
                      <label>Tipo</label>
                      <Select
                        placeholder="Todos"
                        value={tiposList}
                        onChange={ (e)=>{
                          setTiposList(e);
                          let tiposTemp= [];
                          for(let t of e){
                            tiposTemp.push(t.value);
                          }
                          setRequestBody({...requestBody, tipos: tiposTemp});
                        }}
                        options={tipos}
                        isMulti={true}
                      />
                    </div>
                    <div className='col'>
                      <label>Dispositivos</label>
                      <Select
                        placeholder="Todos"
                        value={dispositivos}
                        onChange={ (e)=>{
                          setDispositivos(e);
                          let dispositivosTemp= [];
                          for(let d of e){
                            dispositivosTemp.push(d.value);
                          }
                          setRequestBody({...requestBody, dispositivos: dispositivosTemp});
                        }}
                        options={dispositivosList}
                        isMulti={true}
                      />
                    </div>
                    <div className='col'>
                        <i className="fa fa-refresh text-primary btn"
                          style={{ fontSize: "40px"}}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Actualizar"}
                          onClick={()=>{
                            refrescar();             
                          }}></i>
                    </div>
                  </div>
                }
                lista= { reporte }
                columnas={
                  [ 
                    { columna: "id", label: "ID"},
                    { columna: "video", label: "Video",
                      form: (e)=>{
                        return <i className="fa fa-film" style={{ color: e.videoGenerado && e.existeVideo? "green": "red"}}/>
                      }
                    },
                    { columna: "tipoInt", label: "idTipo"},
                    { columna: "tipoString", label: "Tipo",},
                    { columna: "servidorFecha", label: "Fecha" },
                    { columna: "idDispositivo", label: "Unidad",
                      valor: (e)=>{
                        const dispositivosTemp= dispositivosList.filter(d=> Number(d.value) === e.idDispositivo);
                        return dispositivosTemp.length === 1 ? dispositivosTemp[0].label: "Indefinido"
                      },
                      form: (e)=>{
                        const dispositivosTemp= dispositivosList.filter(d=> Number(d.value) === e.idDispositivo);
                        return dispositivosTemp.length === 1 ? dispositivosTemp[0].label: "Indefinido"
                      }
                    },
                    { columna: "latitud", label: "Latitud" },
                    { columna: "longitud", label: "Longitud" },/*
                    { columna: "audio", label: "Audio",
                      form: (e)=>
                        funcionalidades.filter(f=> f.nombre === "audio").length?
                          <audio controls>
                            <source src={e.recordingfile} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        : ""
                    },*/
                  ]
                }
                id= "id"
                selected={(e)=>{ setAlarma(e); }}
                csvname= {funcionalidades.filter(f=> f.nombre === "exportar").length?("Reporte de alarmas "): null}
                /*
                resaltarFuncion={(e)=>{
                  return incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false
                }}
                resaltarColor={(e)=>{
                  const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                  if( etiquetaTemp.length !== 1){
                    return "rgba(242, 151, 151, 0.41)";
                  }
                  return "rgba(58, 223, 149, 0.25)";
                }}*/
                paginacion={true}
                elementosPorPagina={15}
              />
            </div>
            <div className='col-4' style={{ backgroundColor: "rgba(24, 31, 37, 0.10)"}}>
              { alarma.id && alarma.videoGenerado && alarma.existeVideo?
                <video width="100%"// height="240"
                  src={conf.cctv+":8443/video_back/alarmas/obtener_video/alarma?id="+alarma.id+"&indice=0"+"&key=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk="}
                  type="video/mp4"             
                  //poster="/images/videoPoster.png"
                  autoPlay
                  controls>
                  Your browser does not support the video tag.
                </video>
                : <img src="images/videoPoster.png" width="100%" />
              }
              <p>
                {alarma.id && !alarma.videoGenerado? "Video el video aun no se ha generado": ""}
                {alarma.id && alarma.videoGenerado && !alarma.existeVideo? "El video no existe": ""}
              </p>
            </div>
          </div>
        </div>
      :null   
      }
               
    </SiteWrapper>
  );
}


export default ReportesAlertasCctvPage;
