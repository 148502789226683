import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import Select from "react-select";
import Chart from "react-apexcharts";
import { TablePageable } from "../../Helpers";
import { timeToDateFill } from "../../herramientas/DateFormat";
import SiteWrapper from "../../SiteWrapper.react";
const GpsRecuperarDash = () => {
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais
  const [gpsList, setGpsList] = useState([]);
  const [gpsListFiltrados, setGpsListFiltrados] = useState([]);
  const [gpsEstatusOpciones, setGpsEstatusOpciones]= useState([]);
  const [gpsEstatusOpcion, setGpsEstatusOpcion] = useState({ value: 0, label: "Seleciona un estatus" });
  const [plataformasOpciones, setPlataformasOpciones]= useState([]);
  const [plataformasOpcion, setPlataformasOpcion] = useState({ value: 0, label: "Todos" });
  const [corredoresOpciones, setCorredoresOpciones]= useState([]);
  const [corredoresOpcion, setCorredoresOpcion] = useState({ value: 0, label: "Todos" });
  const [paisOpciones, setPaisOpciones]= useState([]);
  const [paisOpcion, setPaisOpcion] = useState({ value: 0, label: "Todos" });
  const [ultReportOpciones, setUltReportOpciones]= useState([]);
  const [ultReportOpcion, setUltReportOpcion] = useState({ value: 0, label: "Todos" });
  const [intervalosDiasOpciones, setIntervalosDiasOpciones]=
  useState([ 
    {value: 0, label: "Todos"},
    {value: 1, label: "1 a 3 días"},
    {value: 2, label: "4 a 6 días"},
    {value: 3, label: "7 a 9 días"},
    {value: 4, label: "10 días o más"},
  ]);
  const [intervalosDiasOpcion, setIntervalosDiasOpcion] = useState({ value: 0, label: "Todos" });
  //Se realiza la consulta la primera vez.
  useEffect(() => {
    getfuntion("get", [], "", "gps/gps_recuperar_funcion", "gps_recuperar_funcion");
  }, []);

  useEffect(() => {
    filtroDeFiltros();
    filtro();
  }, 
    [ gpsEstatusOpcion,
      plataformasOpcion,
      corredoresOpcion,
      paisOpcion,
      ultReportOpcion,
      intervalosDiasOpcion,
    ]);

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",

    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "home",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "gps_recuperar_funcion") {
                if (returnVal) {
                  let GpsListTemp= [...returnVal];
                  let fechaActual= new Date().getTime();
                  for(let g of GpsListTemp){                  
                    g.utc = g.utc? timeToDateFill(new Date(g["utc"]+"z").getTime()): null;
                    g.deviceactivity =
                      g.deviceactivity? timeToDateFill(new Date(g["deviceactivity"]+"z").getTime()): null;
                    if(g["deviceactivity"]){
                      g["deviceactivityDias"]=
                        Math.round( 
                          (fechaActual-new Date(g["deviceactivity"]).getTime())
                          /(1000*60*60*24)
                        );
                    }else{
                      g["deviceactivityDias"]= null;
                    }                    
                  }
                  //setGpsList(returnVal);
                  //filtroDeFiltros(returnVal);
                  //filtro(returnVal);
                  setGpsList(GpsListTemp);
                  filtroDeFiltros(GpsListTemp);
                  filtro(GpsListTemp);
                  setTimeout(() => {
                    setGpsEstatusOpcion({ value: 3, label: "Pendientes por Recuperar" });
                  }, 1000);
                }
              }
            }
            break;
          default:
            break;
        }
      }).catch((err) => { console.log(err); });
  };
  const filtroDeFiltros=(dataGpsLisTemp)=>{
    let gpssTemp= dataGpsLisTemp? [...dataGpsLisTemp]: [...gpsList];
    let gpsEstatus = {};
    let plataformas = {};
    let pais = {};
    let corredores = {};
    let ult_report = {};
    if( gpsEstatusOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_gps_estatus === gpsEstatusOpcion.value );
    if( plataformasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_plataforma === plataformasOpcion.value );
    if( paisOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.pais === paisOpcion.label );
    if( corredoresOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_corredor === corredoresOpcion.value );
    if( ultReportOpcion.value )
      gpssTemp= gpssTemp.filter(g=>{
        if( ultReportOpcion.value === null)
          return !g.deviceactivityDias? true: false;
        if( g.deviceactivityDias && [3,10,30,90,365].includes(ultReportOpcion.value))
          return g.deviceactivityDias <= Number.parseInt(ultReportOpcion.value)? true: false;
        if( g.deviceactivityDias && ultReportOpcion.value > 365)
          return g.deviceactivityDias >= ultReportOpcion.value? true: false;
      });
    if( intervalosDiasOpcion.value )
      gpssTemp= gpssTemp.filter(g=>{
        if( intervalosDiasOpcion.value === 1)
          return g.dias >= 1 && g.dias <= 3; //1 a 3 días
        if( intervalosDiasOpcion.value === 2)
          return g.dias >= 4 && g.dias <= 6;//4 a 6 días
        if( intervalosDiasOpcion.value === 3)
          return g.dias >= 7 && g.dias <= 9;//7 a 9 días
        if( intervalosDiasOpcion.value === 4)
          return g.dias >= 10;//más de 10 días.
      });      
    gpssTemp.map((gps, index) => {
      //|gps_estatus
      if (!gpsEstatus[gps["id_gps_estatus"]]) gpsEstatus[gps["id_gps_estatus"]] = [];
        gpsEstatus[gps["id_gps_estatus"]].push(gps);

      //plataforma
      if (!plataformas[gps["id_plataforma"]]) plataformas[gps["id_plataforma"]] = [];
      plataformas[gps["id_plataforma"]].push(gps);

      //pais
      if (!pais[gps["pais"]]) pais[gps["pais"]] = [];
      pais[gps["pais"]].push(gps);

      // corredor
      if (!corredores[gps["id_corredor"]]) corredores[gps["id_corredor"]] = [];
        corredores[gps["id_corredor"]].push(gps);
      
      //deviceactivity
      if( gps["deviceactivityDias"] === null ){
        if( !ult_report["Sin reporte"] )
          ult_report["Sin reporte"]= [];
        ult_report["Sin reporte"].push(gps);
      }else{
        if( gps["deviceactivityDias"] <= 3 && (ultReportOpcion.value? 3 <= ultReportOpcion.value: true) ){//3 días o menos
          if( !ult_report["3 días o menos"] )
            ult_report["3 días o menos"]= [];
          ult_report["3 días o menos"].push(gps);
        }
        if( gps["deviceactivityDias"] <= 10 && (ultReportOpcion.value? 10 <= ultReportOpcion.value: true) ){//10 días o menos
          if( !ult_report["10 días o menos"] )
            ult_report["10 días o menos"]= [];
          ult_report["10 días o menos"].push(gps);
        }
        if( gps["deviceactivityDias"] <= 30 && (ultReportOpcion.value? 30 <= ultReportOpcion.value: true) ){//30 días o menos
          if( !ult_report["30 días o menos"] )
            ult_report["30 días o menos"]= [];
          ult_report["30 días o menos"].push(gps);
        }
        if( gps["deviceactivityDias"] <= 90 && (ultReportOpcion.value? 90 <= ultReportOpcion.value: true) ){//90 días o menos
          if( !ult_report["90 días o menos"] )
            ult_report["90 días o menos"]= [];
          ult_report["90 días o menos"].push(gps);
        }
        if( gps["deviceactivityDias"] <= 365 && (ultReportOpcion.value? 365 < ultReportOpcion.value: true) ){//365 días o menos
          if( !ult_report["365 días o menos"] )
            ult_report["365 días o menos"]= [];
          ult_report["365 días o menos"].push(gps);
        }
        if( gps["deviceactivityDias"] > 365 ){//mas de 365 días
          if( !ult_report["mas de 365 días"] )
            ult_report["mas de 365 días"]= [];
          ult_report["mas de 365 días"].push(gps);
        }
      }
    });

    //creando headers
    let gpsEstatusOpcionesTemp = [];
    let plataformasOpcionesTemp = [];
    let paisOpcionesTemp = [];
    let corredoresOpcionesTemp = [];
    let ultReportOpcionesTemp = [];
    Object.keys(gpsEstatus)     .forEach((key) =>{
      gpsEstatusOpcionesTemp.push({
        value: Number.parseInt(key),
        label: (key === '1'? "Recuperados": key === '3'? "Pendientes por Recuperar":"")
      });      
    });
    Object.keys(plataformas)    .forEach((key) => plataformasOpcionesTemp.push({ value: Number.parseInt(key), label: plataformas[key][0]['plataforma']}));
    Object.keys(pais)           .forEach((key) => paisOpcionesTemp.push({ value: key, label: key}));
    Object.keys(corredores)     .forEach((key) => corredoresOpcionesTemp.push({ value: Number.parseInt(key), label: corredores[key][0]['corredor']}));
    Object.keys(ult_report)     .forEach((key) =>{      
      switch (key) {
        case "Sin reporte":
          ultReportOpcionesTemp.push({ value: 0, label: key, data: {primero: null, ultimo: null} });
          break;
        case "3 días o menos":
          ultReportOpcionesTemp.push({ value: 3, label: key, data: {primero: 1, ultimo: 3} });
          break;
        case "10 días o menos":
          ultReportOpcionesTemp.push({ value: 10, label: key, data: {primero: 4, ultimo: 10} });
          break;
        case "30 días o menos":
          ultReportOpcionesTemp.push({ value: 30, label: key, data: {primero: 11, ultimo: 30} });
          break;
        case "90 días o menos":
          ultReportOpcionesTemp.push({ value: 90, label: key, data: {primero: 31, ultimo: 90} });
          break;
        case "365 días o menos":
          ultReportOpcionesTemp.push({ value: 365, label: key, data: {primero: 91, ultimo: 365} });
          break;
        case "mas de 365 días":
          ultReportOpcionesTemp.push({ value: 366, label: key, data: {primero: 366, ultimo: null} });
            break;
        default:
          break;
      }
    });
    
    setGpsEstatusOpciones(gpsEstatusOpcionesTemp);
    setPlataformasOpciones(plataformasOpcionesTemp);
    setPaisOpciones(paisOpcionesTemp);
    setCorredoresOpciones(corredoresOpcionesTemp);
    setUltReportOpciones(ultReportOpcionesTemp);
  }
  const filtro=(dataGpsLisTemp)=>{
    let gpssTemp= dataGpsLisTemp? [...dataGpsLisTemp] : [...gpsList];
    gpssTemp= gpssTemp.filter(g=> g.id_gps_hardware !==  168 );
    if( gpsEstatusOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_gps_estatus === gpsEstatusOpcion.value );
    if( plataformasOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_plataforma === plataformasOpcion.value );
    if( paisOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.pais === paisOpcion.label );
    if( corredoresOpcion.value )
      gpssTemp= gpssTemp.filter(g=> g.id_corredor === corredoresOpcion.value );
    if( ultReportOpcion.value )
      gpssTemp= gpssTemp.filter(g=>{
        if( !ultReportOpcion.value){
          return !g.deviceactivityDias? true: false;
        }
        if( g.deviceactivityDias && [3,10,30,90,365].includes(ultReportOpcion.value)){
          return g.deviceactivityDias <= Number.parseInt(ultReportOpcion.value);
        }
        if( g.deviceactivityDias && ultReportOpcion.value > 365){console.log("Error 2");
          return g.deviceactivityDias >= ultReportOpcion.value? true: false;
        }
      });
    if( intervalosDiasOpcion.value )
      gpssTemp= gpssTemp.filter(g=>{
        if( intervalosDiasOpcion.value === 1){
          return g.dias >= 1 && g.dias <= 3; //1 a 3 días
        }
        if( intervalosDiasOpcion.value === 2){
          return g.dias >= 4 && g.dias <= 6;//4 a 6 días
        }
        if( intervalosDiasOpcion.value === 3){
          return g.dias >= 7 && g.dias <= 9;//7 a 9 días
        }
        if( intervalosDiasOpcion.value === 4){
          return g.dias >= 10;//más de 10 días.
        }
        return false;
      });  
    setGpsListFiltrados(gpssTemp);
    //return gpssTemp;
  }
  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  const getGraficaGpsEstatus = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];
    for( let gEstatus of gpsEstatusOpciones ){
      labels.push(gEstatus.label);
      data.push(gpssTemp.filter( g => g.id_gps_estatus === gEstatus.value).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaPlataformas = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];
    for( let p of plataformasOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.id_plataforma === p.value).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaPaises = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of paisOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.pais === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaCorredores = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of corredoresOpciones ){
      labels.push(p.label);
      data.push(gpssTemp.filter( g => g.corredor === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }  
  const getGraficaIntervalos = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    //1 a 3 días
    labels.push("1 a 3 días");
    data.push(gpssTemp.filter( g => g.dias >= 1 && g.dias <= 3 ).length);
    backgroundColor.push(random_rgba(0.5));
    hoverBackgroundColor.push(random_rgba());
    //4 a 6 días
    labels.push("4 a 6 días");
    data.push(gpssTemp.filter( g => g.dias >= 4 && g.dias <= 6 ).length);
    backgroundColor.push(random_rgba(0.5));
    hoverBackgroundColor.push(random_rgba());
    //7 a 9 días
    labels.push("7 a 9 días");
    data.push(gpssTemp.filter( g => g.dias >= 7 && g.dias <= 9 ).length);
    backgroundColor.push(random_rgba(0.5));
    hoverBackgroundColor.push(random_rgba());
    //más de 10 días.
    labels.push("10 días o más");
    data.push(gpssTemp.filter( g => g.dias >= 10 ).length);
    backgroundColor.push(random_rgba(0.5));
    hoverBackgroundColor.push(random_rgba());
    return { labels, data };
  }
  const getGraficaUltimoReporte = () => {
    let gpssTemp= [...gpsListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];
    for( let u of ultReportOpciones ){      
      if(u.data.primero && u.data.ultimo)
        labels.push("De "+u.data.primero+" a "+ u.data.ultimo );
      if(!u.data.primero && !u.data.ultimo)
        labels.push("Sin reporte" );
      if(u.data.primero && !u.data.ultimo)
        labels.push("Mas de "+u.data.primero );
      data.push(gpssTemp.filter(g=>{
        if( u.data.primero === null && u.data.ultimo === null)
          return !g.deviceactivityDias? true: false;
        if( u.data.primero !== null && u.data.ultimo !== null)
          return g.deviceactivityDias >= u.data.primero && g.deviceactivityDias <= u.data.ultimo? true: false;
        if( u.data.primero !== null && u.data.ultimo === null)
          return g.deviceactivityDias >= u.value? true: false;
        /*
        if( !u.value)
          return !g.deviceactivityDias? true: false
        if( g.deviceactivityDias && [3,10,30,90,365].includes(u.value))
          return g.deviceactivityDias <= u.value? true: false
        if( g.deviceactivityDias && u.value > 365 )
          return g.deviceactivityDias >= u.value? true: false
          */
      }).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const reset =()=>{
    setGpsEstatusOpcion({ value: 0, label: "Todos" });
    setPlataformasOpcion({ value: 0, label: "Todos" });
    setCorredoresOpcion({ value: 0, label: "Todos" });
    setPaisOpcion({ value: 0, label: "Todos" });
    setUltReportOpcion({ value: 0, label: "Todos" });
  }  
  return (
    <SiteWrapper>
      <div className="container-fluid">
        <div className="row justify-content-center" style={{ margin: "16px" }}>
        </div>
        <div className="row">
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus"><h1>Estatus Actual</h1></label>
              <Select value={gpsEstatusOpcion} onChange={(e)=>{ setGpsEstatusOpcion(e); }} options={gpsEstatusOpciones} />
              <small class="form-text text-muted">Selecciona un estatus para empezar.</small>
            </div>
          </div>
          <div className="col mt-8"><button onClick={()=> reset()}  className="btn-primary btn">reset</button></div>
        </div>
        <hr />
        <div className="row" hidden={ !gpsEstatusOpcion.value }>
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus">Plataformas</label>
              <Select value={plataformasOpcion} onChange={(e)=>{ setPlataformasOpcion(e); }} options={plataformasOpciones} />
            </div>
          </div>
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus">Corredores</label>
              <Select value={corredoresOpcion} onChange={(e)=>{ setCorredoresOpcion(e); }} options={corredoresOpciones} />
            </div>
          </div>
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus">Pais</label>
              <Select value={paisOpcion} onChange={(e)=>{ setPaisOpcion(e); }} options={paisOpciones} />
            </div>
          </div>
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus">Intervalos en días</label>
              <Select value={intervalosDiasOpcion} onChange={(e)=>{ setIntervalosDiasOpcion(e); }} options={intervalosDiasOpciones} />
            </div>
          </div>
          <div className="col justify-content-start">
            <div className="form-group">
              <label for="estatus">último reporte</label>
              <Select value={ultReportOpcion} onChange={(e)=>{ setUltReportOpcion(e); }} options={ultReportOpciones} />
            </div>
          </div>     
          <div className="col-12">
            <span>
              <h3 className="text-right">Total {gpsListFiltrados.length}</h3>
            </span>
            <span>
              <h3 className="text-right">
                Gerenciados( GpsGate TrackerOne ) Total { gpsListFiltrados.filter(g=> g.id_gps_hardware ===  168 ).length }</h3>
            </span>
          </div>
        </div>
        { gpsEstatusOpcion.value && gpsListFiltrados.length &&
          <div className="row">
            <div className="col-4">
              <div class="card border-primary mb-3">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4>
                    Estatus
                  </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                    <Chart
                      series= {getGraficaGpsEstatus().data}
                    options= {{
                      chart: {
                        width: 3000,
                        type: 'donut',
                      },
                      labels:getGraficaGpsEstatus().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      },              
                      dataLabels: {
                        enabled: false
                      },
                      fill: {
                        type: 'gradient',
                      },
                      legend: {
                        formatter: function(val, opts) {
                          return getGraficaGpsEstatus().labels[opts.seriesIndex];//+" - "+ getGraficaGpsEstatus().data[opts.seriesIndex]
                          //return val + " - T" + opts.w.globals.series[opts.seriesIndex]
                        }
                      },
                      title: {
                        text: 'Estatus Actual'
                      },
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }]
                    }}
                      type="donut" width={"100%"} height={"300px"}/>
                  :null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class="card border-primary mb-3">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4> Intervalos en días </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                    <Chart
                      series= {getGraficaIntervalos().data}
                    options= {{
                      chart: {
                        width: 3000,
                        type: 'donut',
                        events: {
                          click: function (event, chartContext, config) {
                            console.log(event, chartContext, config);
                            //alert(config.labels[config.dataPointIndex]);
                          }
                        }
                      },
                      labels:getGraficaIntervalos().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      },              
                      dataLabels: {
                        enabled: false
                      },
                      fill: {
                        type: 'gradient',
                      },
                      legend: {
                        formatter: function(val, opts) {
                          //console.log(getGraficaIntervalos(),getGraficaIntervalos().labels[opts.seriesIndex], opts.seriesIndex)
                          return getGraficaIntervalos().labels[opts.seriesIndex];//+" - "+ getGraficaIntervalos().data[opts.seriesIndex]
                          //return val + " - T" + opts.w.globals.series[opts.seriesIndex]
                        }
                      },
                      title: {
                        text: 'Información agrupada en días.'
                      },
                      responsive: [{
                        breakpoint: 480,
                        options: {
                          chart: {
                            width: 200
                          },
                          legend: {
                            position: 'bottom'
                          }
                        }
                      }]
                    }}
                      type="donut" width={"100%"} height={"300px"}/>
                  :null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class="card border-primary mb-1">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4>
                  Plataformas
                  </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                  <Chart
                    options={{
                      chart: {
                        id: 'apexchart-example',
                        events: {
                          click: function (event, chartContext, config) {
                            //alert(config.labels[config.dataPointIndex]);
                          }
                        }
                      },
                      xaxis: {
                        categories: getGraficaPlataformas().labels,
                      },
                      //colors:["#F44336", "#E91E63", "#9C27B0"]               
                    }}
                    series={
                      [
                        { name: "Gps", data: getGraficaPlataformas().data },
                      ]
                    }
                    type="bar"
                    width={"100%"}
                    height={"300px"}
                  />
                  :null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class="card border-primary mb-3">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4>
                    Pais
                  </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                    <Chart
                      series= {getGraficaPaises().data}
                      options= {{
                        chart: {
                          width: 380,
                          type: 'pie',
                        },
                        labels:getGraficaPaises().labels,
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 270
                          }
                        },
                        responsive: [{
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200
                            },
                            legend: {
                              position: 'bottom'
                            }
                          }
                        }]
                      }}
                      type="pie" width={"100%"} height={"300px"} />
                  :null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class="card border-primary mb-3">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4> Corredores </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                  <Chart
                    options={{
                      chart: {
                        id: 'apexchart-example',
                        events: {
                          click: function (event, chartContext, config) {
                            //alert(config.labels[config.dataPointIndex]);
                          }
                        }
                      },
                      xaxis: {
                        categories: getGraficaCorredores().labels,
                      },
                      //colors:["#F44336", "#E91E63", "#9C27B0"]               
                    }}
                    series={
                      [
                        { name: "Gps", data: getGraficaCorredores().data },
                      ]
                    }
                    type="bar"
                    width={"100%"}
                    height={"300px"}
                  />
                  :null}
                </div>
              </div>
            </div>
            <div className="col-4">
              <div class="card border-primary mb-3">
                <div class="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                  <h4> Último reporte </h4>
                </div>
                <div class="card-body text-success">
                  { gpsList.length ?
                    <Chart
                      series= {getGraficaUltimoReporte().data}
                      options= {{
                        chart: {
                          width: 380,
                          type: 'pie',
                        },
                        labels:getGraficaUltimoReporte().labels,
                        plotOptions: {
                          pie: {
                            startAngle: -90,
                            endAngle: 270
                          }
                        },
                        responsive: [{
                          breakpoint: 480,
                          options: {
                            chart: {
                              width: 200
                            },
                            legend: {
                              position: 'bottom'
                            }
                          }
                        }]
                      }}
                      type="pie" width={"100%"} height={"300px"} />
                  :null}
                </div>
              </div>
            </div>
          </div>
        }
        <hr />
        <div className="row" hidden={ !gpsEstatusOpcion.value || !gpsListFiltrados.length }>
          <div className="col-12">
            <TablePageable
              titulo={<h3> Listado de gps </h3>}
              lista={gpsListFiltrados}
              columnas={
                [
                  { columna: "id_gps", label: "Id" },
                  { columna: "plataforma", label: "Plataforma" },
                  { columna: "corredor", label: "Corredor" },
                  { columna: "pais", label: "País" },
                  { columna: "hardware", label: "Hardware" },
                  { columna: "nombre", label: "Nombre" },
                  { columna: "gps_estatus", label: "Gps estatus" },
                  { columna: "deviceactivity", label: "Deviceactivity" },
                  { columna: "bateria", label: "Batería" },
                  { columna: "id_movimiento", label: "idMovimiento" },
                  { columna: "booking", label: "Booking" },
                  { columna: "dias", label: "Días" },
                  { columna: "end_date", label: "Find de Mov" },
                ]
              }
              id="idGps"
              selected={() => { }}
              csvname="Gps Mobiles"
              paginacion={true}
              elementosPorPagina={50}
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};

export default GpsRecuperarDash;
