import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import { NotificationManager } from 'react-notifications';
const Cliente =({accion, elementos, setCliente})=> {
  const [formulario, setFormulario]= useState({
    nombre: "",
    nombreCorto: "",
    domicilio: "",
    contacto: "",
    telefono: "",
    extension: "",
    nota: "",
    latitud: 0,
    longitud: 0,
    vip: 1,
    estatus: 1,
    idGeocerca: "",
    cron: "00:00:00",
    wt: "12:00:00",
    idPlataformaTipo: 1,
  });
  useEffect(()=>{
    if (accion !== "nuevo") {
      if(elementos)
        setFormulario(elementos);
    }
  },[]);

  const enableSave = () => {
    if (
      formulario.nombre.length > 0 &&
      formulario.nombreCorto.length > 0 &&
      formulario.domicilio.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {accion === "modificar" ? (
        <h1> {elementos.nombre} </h1>
      ) : null}
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <Card
            title="Cliente"
            isCollapsible
            isCollapsed={false}
            body={
              <div>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="nombre">
                      <Form.Input
                        name="nombre"
                        type="text"
                        value={formulario.nombre}
                        onChange={(event)=> setFormulario({ ...formulario, nombre: event.target.value }) }
                        maxlength="40"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="nombreCorto">
                      <Form.Input
                        name="nombreCorto"
                        type="text"
                        value={formulario.nombreCorto}
                        onChange={(event)=> setFormulario({ ...formulario, nombreCorto: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={6} lg={6}>
                    <Form.Group label="Domicilio">
                      <Form.Input
                        name="domicilio"
                        type="text"
                        value={formulario.domicilio}
                        onChange={(event)=> setFormulario({ ...formulario, domicilio: event.target.value })}
                        maxlength="40"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Contacto">
                      <Form.Input
                        name="contacto"
                        type="text"
                        value={formulario.contacto}
                        onChange={(event)=> setFormulario({ ...formulario, contacto: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Telefono">
                      <Form.Input
                        name="telefono"
                        type="number"
                        value={formulario.telefono}
                        onChange={(event)=> setFormulario({ ...formulario, telefono: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={6} lg={3}>
                    <Form.Group label="Extension">
                      <Form.Input
                        name="extension"
                        type="text"
                        value={formulario.extension}
                        onChange={(event)=> setFormulario({ ...formulario, extension: event.target.value }) }
                        maxlength="10"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Latitud">
                      <Form.Input
                        name="latitud"
                        type="text"
                        value={formulario.latitud}
                        onChange={(event)=> setFormulario({ ...formulario, latitud: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Longitud">
                      <Form.Input
                        name="longitud"
                        type="text"
                        value={formulario.longitud}
                        onChange={(event)=> setFormulario({ ...formulario, longitud: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={4} lg={4}>
                    <Form.Group label="Nota">
                      <Form.Input
                        name="nota"
                        type="text"
                        value={formulario.nota}
                        onChange={(event)=> setFormulario({ ...formulario, nota: event.target.value }) }
                        maxlength="10"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </div>
            }
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <div className="float-right">
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={ enableSave() }
                onClick={()=>{ setCliente(formulario); NotificationManager.info(
                  "Se ha agregado el cliente " + formulario.nombre, "Nuevo");
                }}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          </div>
        </Grid.Col>
      </Grid.Row>
      {/**
       *   Elementos de plataformas
       */}
    </div>
  );
}

export default Cliente;
