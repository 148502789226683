import React from "react";
import MovimientoIncidenciasTerrestreForm from "../../Monitoristas/Monitoreo/MovimientoIncidenciasTerrestre";
import ModalLay from "../ModalLay/ModalLay";
import classes from "./Alert.module.css";
import MovimientoIncidencias from "../../Monitoristas/Monitoreo/MovimientoIncidencias";

var user = JSON.parse(sessionStorage.getItem("usuario"));

const Alert = React.memo(
  ({
    tiempo,
    setTiempos,
    changeColToFilter,
    movimiento,
    stor,
    getfuntion,
    gpsReciente,
    closeModal,
    tipo,
  }) => {
    return (
      <li className={`${classes.Message} ${classes.AnimationAppear}`}>
        <div
          className={`${classes.MessageIcon}`}
          onClick={() => console.log("click")}
        >
          <ModalLay
            tamanio={"50%"}
            title="Nueva Incidencia"
            name={
              <i
                className={`fe fe-clock ${classes.AnimationBlink}`}
                style={{ "font-size": "2em" }}
              />
            }
            formulario={
              tipo === "Terrestre"
                ? MovimientoIncidenciasTerrestreForm
                : MovimientoIncidencias
            }
            datosForm={
              tipo === "Terrestre"
                ? {
                    accion: "nuevo",
                    desde: "EspecificoTerrestre",
                    idusuario: user.id,
                    idmovimiento: tiempo.idMovimiento,
                    idplataforma: movimiento.plataforma.id,
                    movimiento: movimiento,
                    stor: { privilegios_object: stor },
                    getfuntion: getfuntion,
                    plataformaTipo: movimiento.plataforma? movimiento.plataforma.plataformaTipo.id: null,
                    gpsReciente: gpsReciente
                      ? gpsReciente[movimiento.id]
                        ? gpsReciente[movimiento.id]
                        : null
                      : null,
                    closeModal: closeModal,
                  }
                : {
                    accion: "nuevo",
                    idusuario: user.id,
                    idmovimiento: tiempo.idMovimiento,
                    movimiento: movimiento,
                    getfuntion: getfuntion,
                    stor: { privilegios_object: stor },
                    plataformaTipo: movimiento.idPlataforma? movimiento.idPlataforma.plataformaTipo.id: null,
                    closeModal: closeModal,
                    //movimientoFull: this.state.movimientoFull,
                    gpsReciente: gpsReciente
                      ? gpsReciente[movimiento.id]
                      : null,
                    latitud: null,
                    longitud: null,
                  }
            }
          />
        </div>
        <div className={classes.MessageBody}>
          <div onClick={() => console.log("click")}>
            <p className={classes.MessageBodyTitle}>
              ID del Movimiento: {tiempo.idMovimiento}
            </p>
            <p className={classes.MessageBodyP}>{tiempo.asunto}</p>
            <p className={classes.MessageBodyP}>Placa: {tiempo.placa}</p>
            <p className={classes.MessageBodyP}>
              Carta Porte: {tiempo.cartaPorte}
            </p>
          </div>
          <button
            className={`${classes.MessageButton}`}
            id="js-showMe"
            onClick={() => changeColToFilter(tiempo.idMovimiento)}
          >
            Ir al Movimiento
          </button>
          <button
            className={`${classes.MessageClose}`}
            onClick={() => setTiempos(tiempo.idTemporizadores)}
          >
            <i class="fa fa-times" style={{ "font-size": "1em" }} />
          </button>
        </div>
      </li>
    );
  }
);

export default Alert;
