import React, { useState, useEffect } from "react";
import { MessageList } from 'react-chat-elements';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import "react-chat-elements/dist/main.css";
import { set } from "date-fns";
import WhatsAppBotMensajeria from '../../socket/WhatsAppBotMensajeria.react';
//https://cctv.skyangel.com.mx:8443/video_back/alarmas/read_msg_post
//PMR20240806-1224
const acumulador= 13;//Patios Cordinación Colocación GPS
const etiqueta= 1519;//Arroba Coordinación de Colocación de Gps
const WhatsAppChatBot = ({movimiento}) => {
    const [mensaje, setMensaje]= useState("Buen (día/tarde/noche), estimado (nombre de cliente transporte) por medio del presente me permito dar seguimiento a una solicitud que tenemos asignada por parte de (nombre de cliente solicitante) para colocar un equipo GPS con número de contenedor o referencia: "+movimiento.contenedor+" y nos brindaron su contacto para coordinar la colocación. \nFavor de confirmar si esta solicitud pertenece a su Línea de transporte.");
    const [mensajes, setMensajes]= useState([
        {
            position:"right",
            type:"text",
            title:"Bot",
            text:mensaje,
        },                        
    ]);
    const [respuesta, setRespuesta]= useState({});
    useEffect(() => {
        setMensaje("Buen (día/tarde/noche), estimado (nombre de cliente transporte) por medio del presente me permito dar seguimiento a una solicitud que tenemos asignada por parte de (nombre de cliente solicitante) para colocar un equipo GPS con número de contenedor o referencia: "+movimiento.contenedor+" y nos brindaron su contacto para coordinar la colocación. \nFavor de confirmar si esta solicitud pertenece a su Línea de transporte.");
    }, []);

    useEffect(() => {
        if(respuesta.event){
            if( respuesta.event.Info ){
                if(respuesta.event.Info.Sender.includes("5584451510")){
                    console.log(respuesta); 
                    if( respuesta.event.Message && respuesta.event.Message.conversation
                        && (
                                respuesta.event.Message.conversation.toUpperCase().includes("SI")
                                || respuesta.event.Message.conversation.toUpperCase().includes("NO")
                            )
                    ){
                        let mensajesTem= [...mensajes];
                        mensajesTem.push({
                            position:"left",
                            type:"text",
                            title:"LT PAMIRO pruebas",
                            text: respuesta.event.Message.conversation,
                        }); 
                        setMensajes(mensajesTem);
                    }
                }
            }
        }
    }, [respuesta]);

    const enviarMensaje =()=>{
        console.log(movimiento);
        getfuntion(
            'post',
            [],
            '',
            "whatsapp/v2/enviar_mensajes?id_plt="+movimiento.plataformaId+"&id_mov="+movimiento.idMovimiento+"&acumulador="+acumulador+"&etiqueta="+etiqueta,
            'enviar_whatsApp'
          );
          //.then((rmGps) => {});
        /*
        Crud_Catalogos("whatsapp/v2/enviar_mensajes?id_plt="+idPlataforma+"&id_mov="+m+"&acumulador="+acumulador+"&etiqueta="+etiqueta, "movimientos", "post", "").then((returnVal) => {
            NotificationManager.info(
                "Notificación de whatsApp enviada, mov: "+ m, "Notificación"
                );
        }).catch(err =>{ console.log("error: "+err);});
        */
    }
    const getfuntion = (
        metodo = 'get',
        obj = [],
        id = '',
        catalogo = 'chat',
        stateVar = 'chatbot',
        hiddenModl = ''
      ) => {
        return Crud_Catalogos(
          catalogo,
          'enviar_whatsApp',
          metodo,
          id,
          obj,
          '',
          stateVar,
          hiddenModl,
          []
        )
          .then((returnVal) => {
            /**
             * filtro de variables
             */
            switch (metodo) {
              case 'get':/*
                if (stateVar === 'movimiento') {
                    return returnVal;
                }*/
                break;
              case 'post':
                if (stateVar === 'movimiento') {
                    console.log(returnVal);
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      
    return (
        <div>
            <WhatsAppBotMensajeria topics={["/topic/whatsapp-boot-read"]} obtenerMensaje={setRespuesta} />
            <h1>WhatsAppChatBot</h1>
            <span className="btn btn-primary" onClick={()=> enviarMensaje()}>Enviar mensaje</span>
            <hr className="mt-6"/>
            <div>
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={mensajes}
                />
            </div>
        </div>
    );
}
export default WhatsAppChatBot;