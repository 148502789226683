import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import ModalLayout from "../../../components/ModalLayout.react"
import Select from "react-select";
import { from } from "rxjs";
//import { GPSFijos } from "./";

class Unidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
               //Campos
             idMovimiento :this.props.elementos.id,
                Elementos :{ value:0, label:"Seleccione una Unidad " },
               //Variables
                   accion :"post", //'nuevo'// modificar, nuevo
terrestreMovimientoUnidad :[],
            ListaUnidades :[],
                   cuenta :[],
                   search :""
      };
                    this.cuenta=[];
 this.terrestreMovimientoUnidad=[];
                 this.Elementos=[];

         //Para contar los GPS por Elemento
     this.unidadGpsFijo=[];
       this.EnsambleObj={
                          "idMovimiento":this.props.elementos.id,
                          "unidad":
                              {
                                "id":0,
                                "nombre":"",
                                "estatus":	    {"id":1,"nombre":"Disponible"},
                                "unidadTipo": 	{"id":1,"nombre":"Autobus"},
                                "plataforma":  	{"id":0,"nombre":"Panasonic","clave": "PAN","idGg":0,"colorFondo": "#46ad43","colorBoton": "#734333","plataformaTipo": {"id": 2,"nombre":"Terrestre"}},
                                "transportista":{"id":0,"nombre":"ALMER","nombreCorto": "ALMER","contactoNombre": "PENDIENTE","telefono1": "56879011","telefono2": null,"correo": null,"tipo": 2}
                              }
                        };
             this.valid={ Elemento:false };
  this.AlertasCategoria=[];
       this.AlertasTipo=[];
  }
  componentWillMount =() =>{
    this.getfuntion("get", "", "", "unidades/disponibles_transportista/"+this.props.elementos.transportista.id,"ListaUnidades", "ListaUnidades");
    if(this.state.accion !== 'nuevo'){
      this.getfuntion("get", "", "", "terrestreMovimientoUnidad/movimiento/"+this.props.elementos.id,"terrestreMovimientoUnidad","terrestreMovimientoUnidad")
    }else{
    }
  }
  componentDidMount =() => {
  }
  cuentaGps =(idMovimientoElemento, index) => {
    console.log("idMovimientoElemento: "+idMovimientoElemento + " index: " + index);
    //this.getfuntion("get", "", "", "unidadGpsFijo/unidadElemento/"+idMovimientoElemento, "unidadGpsFijo");
    //this.getfuntion("get", "", "", "unidadGpsFijo/unidadElemento/"+this.props.idMovimientoElemento,"unidadGpsFijo")
    Crud_Catalogos("unidadGpsFijo", "loquequieras", "get", "unidadGpsFijo/unidadElemento/"+idMovimientoElemento, "","" , "unidadGpsFijo", "", []).then((returnVal) => {
      this.setState({cuenta:returnVal.length});
    });
    return this.state.cuenta[index];
  }
  isValid=(campo) =>{
    switch (campo) {
      case "Elemento":return this.valid.Elemento;
        break;
      default:
        break;
    }
  }

  changeUnidad =(event) =>{
    let val= event.target.value;
    this.setState({ plataforma:val });
    this.EnsambleObj.plataforma = val;
  }
  changeElementos =(event) =>{
    this.setState({Elementos:event});
    this.EnsambleObj.unidad.id = event.value;
    this.EnsambleObj.unidad.nombre = event.label;

    if (this.EnsambleObj.unidad.id>0 ) {
        this.state.terrestreMovimientoUnidad.map( elemento => {
              if (elemento.unidad.id === this.EnsambleObj.unidad.id){
                this.valid  = { Elemento:false };
              }else{
                this.valid  = { Elemento:true };
              }
        })
        if(event.value > 0){ this.valid.Elemento= true }else{ this.valid.Elemento= false }
    }
  }

  disableSave =() =>{
    if (this.valid.Elemento){
      return false;
    }else{
      return true;
    }
  }
  searching =(event) =>{
    this.setState({ search: event.target.value })
  }

  crud_put =() =>{
    console.log("99 Put this.EnsambleObj");
    console.log(this.EnsambleObj);
    this.getfuntion("put", this.EnsambleObj, "", "terrestreMovimientoUnidad","terrestreMovimientoUnidad");
    this.EnsambleObj  = { "plataforma":this.props.elementos.id, "alertaCategoria": {'id':0, 'nombre':'' }, "alertaTipo": {'id':0, 'nombre':'' } };
         this.valid  = { Elemento:false };
    this.setState({ accion: "nuevo" });
  }
  crud_post =() =>{
    console.log("107 Post this.EnsambleObj");
    console.log(this.EnsambleObj);
    this.getfuntion("post", this.EnsambleObj, "", "terrestreMovimientoUnidad","terrestreMovimientoUnidad");
    this.valid  = { Elemento:false };
    this.setState({ accion: "nuevo" });
  }
  crud_delete =(idMovimiento, idElemento) =>{
    console.log("114 Del this.EnsambleObj");
    console.log(this.EnsambleObj);
    this.EnsambleObj.idMovimiento=idMovimiento;
    this.EnsambleObj.unidad.id=idElemento;
    this.getfuntion("delete", this.EnsambleObj, idMovimiento+"/"+idElemento, "terrestreMovimientoUnidad","terrestreMovimientoUnidad", "", idMovimiento, idElemento);
  }
//getHardware =() =>{}
//getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="", categoria="", tipo="" ) =>{
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="", idMovimiento="", idElemento="") =>{
    Crud_Catalogos(catalogo, "loquequieras", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
     if (stateVar === "ListaUnidades") {
       this.setState({ ListaUnidades:returnVal });
       this.state.ListaUnidades.map((elemento, index) => {
           this.Elementos.push({ value:elemento.id, label:elemento.nombre });
       });
     }

    if (stateVar === "terrestreMovimientoUnidad"){
        if (returnVal!=null){
          switch (metodo) {
            case "post":
              {
                  this.terrestreMovimientoUnidad.push(returnVal);
                  this.setState({ terrestreMovimientoUnidad:this.terrestreMovimientoUnidad });
                  //returnVal.Unidad.estatus=1;
                  //this.getfuntion("put", returnVal.elemento, returnVal.elemento.id, "unidadElementos","unidadElementos");
                  this.Elementos.map((elemento, index) => {
                    if (elemento.value===returnVal.unidad.id){
                      this.Elementos.splice(index, 1);
                    }
                  })
              }
              break;
            case "get":
              {
                  this.terrestreMovimientoUnidad=returnVal;
                  this.setState({ terrestreMovimientoUnidad:returnVal });
              }
              break;
            case "put":
              {
                  for (let item of this.terrestreMovimientoUnidad) {
                    if( item.id === returnVal.id ){
                      item= returnVal;
                      this.setState({ terrestreMovimientoUnidad:this.terrestreMovimientoUnidad });
                      break;
                    }
                  }
              }
              break;
            default:
              console.log("Default");
              break;
          }
        }
        if (returnVal){
          switch (metodo) {
            case "delete":
              {
                console.log("Delete");
                  for (let i=0; i< this.terrestreMovimientoUnidad.length; i++) {
                    if( this.terrestreMovimientoUnidad[i].unidad.id === idElemento ){
                        var Obj = this.terrestreMovimientoUnidad[i].unidad;
                        this.terrestreMovimientoUnidad.splice(i, 1);
                        this.setState({ terrestreMovimientoUnidad:this.terrestreMovimientoUnidad });
                        this.Elementos.push({ value:Obj.id, label:Obj.nombre });
                        break;
                    }
                }//End if stateVar
              }
              break;
            default:
              break;
          }
        }
      }//if (stateVar === "terrestreMovimientoUnidad")
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
//<Button pill  size="sm" outline color="primary" onClick={ ()=> this.AlertasEdit(elemento.alertaCategoria.id, elemento.alertaTipo.id) }><Icon name="edit"/></Button>
  render(){
    return (
      <div>
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Asignacion de Unidades" isCollapsible={ false } isCollapsed={ false}
                  body={
                  <div>
                    <Grid.Row>
                    <Grid.Col sm={12} md={12}>
                      <Grid.Col sm={12} md={4} className="float-right">
                        <Form.Input className="mb-3" icon="search" placeholder="Buscar nombre de Unidad..." position="append" onChange={this.searching} />
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col sm={12} md={4}>
                      {

                      }
                      <Form.Group label="Id Movimiento">
                        <Form.Input
                          type="number" name="idMovimiento" feedback="idMovimiento" value={this.state.idMovimiento} placeholder="Id Unidad" onChange={this.changeUnidad} disabled={true} />
                      </Form.Group>
                      <Form.Group label="Unidades">
                          <Select value={this.state.Elementos} onChange={this.changeElementos} options={this.Elementos} />
                      </Form.Group>
                      <div className="float-right">
                        <span class="badge">
                          <Button size="sm" RootComponent="a" color="primary" >
                              <span class="badge"><Icon link={true} name="new" />Nueva Unidad</span>
                          </Button>
                        </span>
                        <span class="badge">
                          <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() } onClick={this.state.accion==="modificar"?()=>this.crud_put():()=>this.crud_post()} >
                            <span class="badge"><Icon link={true} name="save" />Guardar</span>
                          </Button>
                        </span>
                      </div>
                    </Grid.Col>
                    <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>Nombre</Table.ColHeader>
                              <Table.ColHeader>Acción</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                            {
                              //console.log("270 this.state.terrestreMovimientoUnidad")
                            }
                            {
                              //console.log(this.state.terrestreMovimientoUnidad)
                            }
                            {
                              this.state.terrestreMovimientoUnidad.filter(word => word.unidad.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map(
                              //this.state.terrestreMovimientoUnidad.map(
                                  (elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento.unidad.id }</Table.Col>
                                  <Table.Col>{ elemento.unidad.nombre }</Table.Col>
                                  <Table.Col>
                                    <Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento.idMovimiento, elemento.unidad.id) }>
                                      <Icon name="trash"/>
                                    </Button>
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                            </Table.Body>
                          </Table>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar, del lado derecho podras agregar o eliminar las diferentes Unidades Vinculadas al Movimiento...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
      </div>
    )
  }
}

export default Unidades;
