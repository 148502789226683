import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import TabsLayout from "../../components/TabsLayout.react";
import { Movimiento, Unidades } from "./Formularios"; //Movimiento
import { from } from "rxjs";

class MonitoreoTerrestreForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      ready: false,
    };
    this.tabs = [];
    /**
     * Tab's
     */
    this.Movimiento = {
      title:
        this.props.elementos != undefined ? this.props.elementos.nombre : "",
      icon: "layout",
      panel: (
        <Movimiento
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Unidades = {
      title: "Unidades",
      icon: "map-pin",
      panel: (
        <Unidades
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
    } else {
    }
  };

  componentWillMount = () => {
    if (this.state.accion !== "nuevo") {
      //if (this.props.elementos.id>0){
      //this.setState({id:this.props.elementos.id});
      //this.tabs= [ ,  ];
      this.tabs = [this.Movimiento, this.Unidades];
    } else {
      this.tabs = [this.Movimiento];
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        {}
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default MonitoreoTerrestreForm;
