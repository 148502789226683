import React from "react";
import { Button, Card, Container, Spinner } from "react-bootstrap";
import cube from "./cube.svg";
import console from "./console.svg";
import "./ConsolaTelefono.css";

const ConsolaTelefono = ({ logs, isConnected }) => {
  return (
    <Container style={{ fontFamily: "sans-serif" }}>
      <Card>
        <Card.Header style={{ display: "block" }}>
          <div className="d-flex justify-content-between">
            <div>
              <img src={console} alt="console" className="App-console" />
            </div>
            <div>Consola de agente</div>
            <div>
              <Spinner
                animation="grow"
                size="sm"
                style={{ backgroundColor: isConnected ? "#04630A" : "#AF2305" }}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body style={{ textAlign: "center" }}>
          <img src={cube} alt="cube-console" className="App-cube" />
          <Card.Title>@SkyAngel 2024</Card.Title>
          <Card.Text>
            <code
              style={{
                color: "var(--bs-code-color)",
                fontSize: "1em",
                background: "white",
                border: "none",
              }}
            >
              {new Date().toString()}
            </code>
          </Card.Text>
          {logs.map((log, index) => (
            <Card.Text key={"logs-" + index}>
              <code style={{ color: "#e06da6" }}>{log}</code>
            </Card.Text>
          ))}
          <Button variant="primary" onClick={() => window.location.reload()}>
            Refrescar
          </Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ConsolaTelefono;
