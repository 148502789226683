import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Button, Form, Icon, Grid, Card, Table } from 'tabler-react';
import { FetchData, PostData, DeleteData } from './FetchData.react';
import CheckForm from './CheckForm.react';

export const MenuReportesGenerales = ({
  setLoading,
  setError,
  setData,
  setOpciones,
  setSinColumnas,
}) => {
  const idUsuario = JSON.parse(sessionStorage.getItem('currentUser'))[0].usuario
    .id;
  const idPerfil = JSON.parse(sessionStorage.getItem('currentUser'))[0].perfil
    .id;

  //Bloques
  const [bloques, setBloques] = useState([]);
  const [bloque, setBloque] = useState({
    value: 0,
    label: 'Seleccione un tipo de reporte',
  });
  //Funcionalidades
  const [funcionalidades, setFuncionalidades] = useState([]);
  const [funcionalidad, setFuncionalidad] = useState({
    value: 0,
    label: 'Seleccione un reporte',
  });
  //Tipo de incidencias
  const [tipoIncidencias, setTipoIncidencias] = useState([]);
  const [tipoIncidencia, setTipoIncidencia] = useState([]);
  //Operacioón Estatus
  const [operacionesEstatus, setOperacionesEstatus] = useState([]);
  const [operacionEstatus, setOperacionEstatus] = useState([]);
  //Plataformas
  const [plataformasTerrestre, setPlataformasTerrestre] = useState([]);
  const [plataformaT, setPlataformaT] = useState([]);
  //Plataformas Navieras
  const [plataformasNavieras, setPlataformasNavieras] = useState([]);
  const [plataformasTN, setPlataformasTN] = useState([]);
  const [plataformaN, setPlataformaN] = useState([]);
  //Movimiento tipo
  const [movimientosTipo, setMovimientosTipo] = useState([]);
  const [movimientoTipo, setMovimientoTipo] = useState([]);
  //Navieras
  const [navieras, setNavieras] = useState([]);
  const [naviera, setNaviera] = useState([]);
  //Alertas tipo
  const [alertasTipo, setAlertasTipo] = useState([]);
  const [alertaTipo, setAlertaTipo] = useState([]);
  //Fechas
  const [fechaInicio, setFechaInicio] = useState([]);
  const [fechaFin, setFechaFin] = useState([]);
  //Variables check form
  const [errorBloque, setErrorBloque] = useState(false);
  const [errorFuncionalidad, setErrorFuncionalidad] = useState(false);
  const [errorFechaIni, setErrorFechaIni] = useState(false);
  const [errorFechaFin, setErrorFechaFin] = useState(false);
  //Collapse
  const [boolFiltros, setBoolFiltros] = useState(false);
  const [boolColumnas, setBoolColumnas] = useState(false);
  //Columnas
  const [columnas, setColumnas] = useState([]);
  const [columnasChecked, setColumnasChecked] = useState([]);
  //Solo para delete y post
  const [temp, setTemp] = useState([]);
  //Boolean movimientos navieros cita programada
  const [citaProgramada, setCitaProgramada] = useState(false);

  useEffect(() => {
    FetchData('bloque', 'Bloques', setBloques, setLoading, setError);
  }, []);

  useEffect(() => {
    if (bloque.value != 0) {
      FetchData(
        'perfilesInformes/perfil/' + idPerfil + '/bloque/' + bloque.value,
        'Funcionalidades',
        setFuncionalidades,
        setLoading,
        setError
      );

      FetchData(
        'funcionalidades/bloque/id/' + bloque.value,
        'Columnas',
        setColumnas,
        setLoading,
        setError
      );
      setFuncionalidad({ value: 0, label: 'Seleccione un reporte ' });
    }
  }, [bloque]);

  useEffect(() => {
    //Reset de flitros
    setTipoIncidencia([]);
    setOperacionEstatus([]);
    setPlataformaT([]);
    setPlataformaN([]);
    setMovimientoTipo([]);
    setNaviera([]);
    setAlertaTipo([]);

    FetchData(
      'informesFuncionalidades/informe/' + funcionalidad.value,
      'Columnas checked',
      setColumnasChecked,
      setLoading,
      setError
    );

    if (funcionalidad.label == 'Reporte General Incidencias Terrestre') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/2',
        'Plataformas',
        setPlataformasTerrestre,
        setLoading,
        setError
      );
      FetchData(
        'incidenciastipo',
        'Tipo de incidencias',
        setTipoIncidencias,
        setLoading,
        setError
      );
      FetchData(
        'operacionesEstatus',
        'Operacion estatus',
        setOperacionesEstatus,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Incidencias Naviera') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/1',
        'Plataformas',
        setPlataformasNavieras,
        setLoading,
        setError
      );
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/3',
        'Plataformas',
        setPlataformasTN,
        setLoading,
        setError
      );
      FetchData(
        'incidenciastipo',
        'Tipo de incidencias',
        setTipoIncidencias,
        setLoading,
        setError
      );
      FetchData(
        'operacionesEstatus',
        'Operacion estatus',
        setOperacionesEstatus,
        setLoading,
        setError
      );
      FetchData('navieras', 'Navieras', setNavieras, setLoading, setError);
    }

    if (funcionalidad.label == 'Reporte General Alertas Terrestre') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/2',
        'Plataformas',
        setPlataformasTerrestre,
        setLoading,
        setError
      );
      FetchData(
        'alertasTipos',
        'Alertas tipo',
        setAlertasTipo,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Alertas Naviera') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/1',
        'Plataformas',
        setPlataformasNavieras,
        setLoading,
        setError
      );
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/3',
        'Plataformas',
        setPlataformasTN,
        setLoading,
        setError
      );
      FetchData(
        'alertasTipos',
        'Alertas tipo',
        setAlertasTipo,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Movimientos Terrestre') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/2',
        'Plataformas',
        setPlataformasTerrestre,
        setLoading,
        setError
      );
      FetchData(
        'operacionesEstatus',
        'Operacion estatus',
        setOperacionesEstatus,
        setLoading,
        setError
      );
      FetchData(
        'movimientosTipo',
        'Movimientos tipo',
        setMovimientosTipo,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Movimientos Naviera') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/1',
        'Plataformas',
        setPlataformasNavieras,
        setLoading,
        setError
      );
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/3',
        'Plataformas',
        setPlataformasTN,
        setLoading,
        setError
      );
      FetchData(
        'operacionesEstatus',
        'Operacion estatus',
        setOperacionesEstatus,
        setLoading,
        setError
      );
      FetchData(
        'movimientosTipo',
        'Movimientos tipo',
        setMovimientosTipo,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Entregas Terrestre') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/2',
        'Plataformas',
        setPlataformasTerrestre,
        setLoading,
        setError
      );
    }

    if (funcionalidad.label == 'Reporte General Entregas Naviera') {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/1',
        'Plataformas',
        setPlataformasNavieras,
        setLoading,
        setError
      );
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/3',
        'Plataformas',
        setPlataformasTN,
        setLoading,
        setError
      );
    }
  }, [funcionalidad]);

  const handleOnChangeCita = () => {
    setCitaProgramada(!citaProgramada);
  };

  const check = () => {
    let sinColumnas = [];
    columnas.forEach((element) => {
      if (!columnasChecked.includes(element.value)) {
        sinColumnas.push(element.label);
      }
    });
    setSinColumnas(sinColumnas);
    let tempTipoIncidencia =
      tipoIncidencia == null || tipoIncidencia.length == 0
        ? tipoIncidencias
        : tipoIncidencia;
    let tempOperacionEstatus =
      operacionEstatus == null || operacionEstatus.length == 0
        ? operacionesEstatus
        : operacionEstatus;
    let tempNaviera =
      naviera == null || naviera.length == 0 ? navieras : naviera;
    let tempPlataformaN =
      plataformaN == null || plataformaN.length == 0
        ? [...plataformasNavieras, ...plataformasTN]
        : plataformaN;
    let tempPlataformaT =
      plataformaT == null || plataformaT.length == 0
        ? plataformasTerrestre
        : plataformaT;
    let tempMovimientoTipo =
      movimientoTipo == null || movimientoTipo.length == 0
        ? movimientosTipo
        : movimientoTipo;
    let tempAlertasTipo =
      alertaTipo == null || alertaTipo.length == 0 ? alertasTipo : alertaTipo;
    setOpciones({
      idUsuario,
      bloque,
      funcionalidad,
      tempTipoIncidencia,
      tempOperacionEstatus,
      tempNaviera,
      tempPlataformaN,
      tempPlataformaT,
      tempMovimientoTipo,
      tempAlertasTipo,
      fechaInicio,
      fechaFin,
      citaProgramada,
    });
    CheckForm(
      {
        idUsuario,
        bloque,
        funcionalidad,
        fechaInicio,
        fechaFin,
        tempTipoIncidencia,
        tempOperacionEstatus,
        tipoIncidencia,
        tempNaviera,
        tempPlataformaN,
        tempPlataformaT,
        tempMovimientoTipo,
        tempAlertasTipo,
        errorBloque,
        errorFuncionalidad,
        errorFechaIni,
        errorFechaFin,
        citaProgramada,
      },
      {
        setData,
        setLoading,
        setError,
        setErrorBloque,
        setErrorFuncionalidad,
        setErrorFechaIni,
        setErrorFechaFin,
      }
    );
  };

  const changeFuncionalidades = (columna) => {
    let informeFuncionalidad = {
      idInformes: funcionalidad.value,
      idFuncionalidades: columna.value,
    };
    if (columnasChecked.includes(columna.value)) {
      DeleteData(
        'informesFuncionalidades',
        funcionalidad.value + '/' + columna.value,
        setTemp,
        setLoading,
        setError
      );
    } else {
      PostData(
        'informesFuncionalidades',
        informeFuncionalidad,
        setTemp,
        setLoading,
        setError
      );
    }
    setTimeout(() => {
      FetchData(
        'informesFuncionalidades/informe/' + funcionalidad.value,
        'Columnas checked',
        setColumnasChecked,
        setLoading,
        setError
      );
    }, 150);
  };

  return (
    <div>
      <div className="row" style={{ textAlign: 'center' }}>
        <div className="col-12">
          <h1 style={{ marginTop: '0.5em' }}>Reportes Generales</h1>
        </div>
        <div className="col-4">
          <label className="form-label required">Tipo de reporte</label>
          <Select
            onChange={(opcion) => {
              setBloque(opcion);
            }}
            options={bloques}
            hideSelectedOptions
            value={bloque}
          />
          {errorBloque ? (
            <div style={{ color: '#dc3545' }}>
              Elija un tipo de reporte antes de continuar.
            </div>
          ) : null}
        </div>
        <div className="col-4 ">
          <label htmlFor="fechaIni" className="form-label required">
            Fecha Inicial
          </label>
          <input
            onChange={(opcion) => {
              setFechaInicio(opcion.target.value);
            }}
            type="date"
            id="fechaIni"
            name="fechaIni"
          ></input>
          {errorFechaIni ? (
            <div style={{ color: '#dc3545' }}>Elija una fecha inicial.</div>
          ) : null}
        </div>
        <div className="col-4 ">
          <label htmlFor="fechaFin" className="form-label required">
            Fecha fin
          </label>
          <input
            onChange={(opcion) => {
              var fecha = new Date(opcion.target.value);
              setFechaFin(fecha.toISOString().split('T')[0]);
            }}
            type="date"
            id="fechaFin"
            name="fechaFin"
          ></input>
          {errorFechaFin ? (
            <div style={{ color: '#dc3545' }}>
              Elija una fecha fin posterior a la inicial.
            </div>
          ) : null}
        </div>
      </div>

      {bloque.value != 0 ? (
        <div
          className="row mt-3 align-items-center"
          style={{ textAlign: 'center' }}
        >
          <div className="col-4">
            <label className="form-label required">Reporte</label>
            <Select
              onChange={(opcion) => {
                setFuncionalidad(opcion);
              }}
              options={funcionalidades}
              hideSelectedOptions
              value={funcionalidad}
            />
            {errorFuncionalidad ? (
              <div style={{ color: '#dc3545' }}>
                Elija un reporte antes de continuar.
              </div>
            ) : null}
          </div>
          {funcionalidad.value != 0 &&
          funcionalidad.label.includes('General Movimientos Naviera') ? (
            <div className="col-4 justify-content-center">
              <Form.Switch
                type="Checkbox"
                label="Rango de fechas por cita programadal"
                checked={citaProgramada}
                onChange={handleOnChangeCita}
              />
              <div class="form-check form-switch"></div>
            </div>
          ) : null}
        </div>
      ) : null}
      <hr />

      {funcionalidad.value != 0 ? (
        <>
          <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => setBoolFiltros(!boolFiltros)}
              >
                Filtros
              </button>
            </div>
          </div>
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Incidencias Terrestre') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaT(opcion);
                }}
                isMulti
                options={plataformasTerrestre}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de incidencias</label>
              <Select
                onChange={(opcion) => {
                  setTipoIncidencia(opcion);
                }}
                isMulti
                options={tipoIncidencias}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Operación estatus</label>
              <Select
                onChange={(opcion) => {
                  setOperacionEstatus(opcion);
                }}
                isMulti
                options={operacionesEstatus}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Incidencias Naviera') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaN(opcion);
                }}
                isMulti
                options={[...plataformasNavieras, ...plataformasTN]}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Estatus de operación</label>
              <Select
                onChange={(opcion) => {
                  setOperacionEstatus(opcion);
                }}
                isMulti
                options={operacionesEstatus}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de incidencia</label>
              <Select
                onChange={(opcion) => {
                  setTipoIncidencia(opcion);
                }}
                isMulti
                options={tipoIncidencias}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Naviera</label>
              <Select
                onChange={(opcion) => {
                  setNaviera(opcion);
                }}
                isMulti
                options={navieras}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Alertas Terrestre') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaT(opcion);
                }}
                isMulti
                options={plataformasTerrestre}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de alerta</label>
              <Select
                onChange={(opcion) => {
                  setAlertaTipo(opcion);
                }}
                isMulti
                options={alertasTipo}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Alertas Naviera') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaN(opcion);
                }}
                isMulti
                options={[...plataformasNavieras, ...plataformasTN]}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de alerta</label>
              <Select
                onChange={(opcion) => {
                  setAlertaTipo(opcion);
                }}
                isMulti
                options={alertasTipo}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Movimientos Terrestre') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaT(opcion);
                }}
                isMulti
                options={plataformasTerrestre}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Operación estatus</label>
              <Select
                onChange={(opcion) => {
                  setOperacionEstatus(opcion);
                }}
                isMulti
                options={operacionesEstatus}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de movimiento</label>
              <Select
                onChange={(opcion) => {
                  setMovimientoTipo(opcion);
                }}
                isMulti
                options={movimientosTipo}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Movimientos Naviera') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaN(opcion);
                }}
                isMulti
                options={[...plataformasNavieras, ...plataformasTN]}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Operación estatus</label>
              <Select
                onChange={(opcion) => {
                  setOperacionEstatus(opcion);
                }}
                isMulti
                options={operacionesEstatus}
              />
            </div>
            <div className="col-4">
              <label className="form-label">Tipo de movimiento</label>
              <Select
                onChange={(opcion) => {
                  setMovimientoTipo(opcion);
                }}
                isMulti
                options={movimientosTipo}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Entregas Terrestre') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaT(opcion);
                }}
                isMulti
                options={plataformasTerrestre}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 &&
      funcionalidad.label.includes('General Entregas Naviera') ? (
        <>
          <div
            className={boolFiltros ? 'row mt-3' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataformaN(opcion);
                }}
                isMulti
                options={[...plataformasNavieras, ...plataformasTN]}
              />
            </div>
          </div>
          <hr />
        </>
      ) : null}

      {funcionalidad.value != 0 ? (
        <>
          <div className="row" style={{ textAlign: 'center' }}>
            <div className="col-12">
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={() => setBoolColumnas(!boolColumnas)}
              >
                Columnas
              </button>
            </div>
          </div>

          <div
            className={boolColumnas ? 'row' : 'collapse'}
            style={{ textAlign: 'center' }}
          >
            <div className="col-4 offset-4 mt-3">
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Nombre</Table.ColHeader>
                    <Table.ColHeader>Activar</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {columnas.map((resultado, index) => (
                    <Table.Row>
                      <Table.Col>{resultado.label}</Table.Col>
                      <Table.Col>
                        <Form.Switch
                          type="Checkbox"
                          name="toggle"
                          value="option1"
                          className={'disabled'}
                          checked={
                            columnasChecked.includes(resultado.value)
                              ? true
                              : false
                          }
                          onChange={() => {
                            changeFuncionalidades(resultado);
                          }}
                        />
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </div>
          <hr />
        </>
      ) : null}

      <div className="row mt-3 mb-3">
        <div className="col text-center">
          <button className="btn btn-info" onClick={check}>
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};
