import React from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

const LlamadaModal = ({
  showModal,
  handleCloseModal,
  llamante,
  handleRefuse,
  handleAnswer,
}) => {
  useEffect(() => {
    if (showModal) {
      document.getElementById("alerta12").play();
    }
  }, [showModal]);
  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <audio id="alerta12" src="songs/ringtone.mp3" loop></audio>
      <Modal.Header>
        <Modal.Title>LLamada entrante</Modal.Title>
      </Modal.Header>
      <Modal.Body>LLamada entrante de {llamante}!</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleRefuse}>
          Colgar
        </Button>
        <Button variant="primary" onClick={handleAnswer}>
          Responder
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LlamadaModal;
