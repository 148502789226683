import React, { Component } from "react";
import { List, Button, Icon, Form, Grid } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';

class AlertaNForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      showIncidenciaF: false,
      hiddenModals  : false,
      usuario       : this.props.usuario.persona.nombre+" "+this.props.usuario.persona.aPaterno+" "+this.props.usuario.persona.aMaterno
    };
  }
  componentDidMount =() => {
    if( this.props.alerta.atendida ){
      this.getfuntion("get", [], "", "usuarios/"+this.props.alerta.atiendeUsuario, "usuario");
    }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "usuario") { this.setState({ usuario: returnVal.persona.nombre+" "+ returnVal.persona.aPaterno+" "+ returnVal.persona.aMaterno }); }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ...." + err.response);
      }
    })
  }
  crud_put() {
    var d = new Date();
      var mes=(d.getMonth()+1);
      var dia=d.getDate();
      var mes1 = "";
      var dia1 = "";
      mes < 10 ? mes1="0"+mes: mes1=mes;
      dia < 10 ? dia1="0"+dia: dia1=dia;
      var horas= d.getHours();
      var minutos= d.getMinutes();
      var segundos= d.getSeconds();
      var horas1= "";
      var minutos1= "";
      var segundos1= "";
      horas < 10 ? horas1="0"+horas: horas1= horas;
      minutos < 10 ? minutos1="0"+minutos: minutos1= minutos;
      segundos < 10 ? segundos1="0"+segundos: segundos1= segundos;
      this.props.alerta.atendida= 1;
      this.props.alerta.atiendeFecha= d.getFullYear()+"-"+mes1+"-"+dia1+" "+horas1+":"+minutos1+":"+segundos1;
      this.props.alerta.atiendeUsuario= this.props.usuario.id;
    this.props.getfuntion("put", this.props.alerta, "", "navierasAlertas","", this.props.closeModal);
}
  render() {
    return (

      <div className= "mt-1">
        <List.Group>
          <List.GroupItem action icon="credit-card"> id: { this.props.alerta.id } </List.GroupItem>
          <List.GroupItem action icon="truck"> Movimiento: { this.props.alerta.idMovimiento }</List.GroupItem>
          <List.GroupItem action icon="map-pin"> latitud: { this.props.alerta.latitud } </List.GroupItem>
          <List.GroupItem action icon="map-pin"> longitud: { this.props.alerta.longitud } </List.GroupItem>
          <List.GroupItem action icon="pen-tool"> trigger Value: { this.props.alerta.triggerValue }</List.GroupItem>
          <List.GroupItem action icon="pen-tool"> trigger Name: { this.props.alerta.triggerName }</List.GroupItem>
          <List.GroupItem action icon="calendar"> Fecha de creación: { this.props.alerta.fechaCreacion }</List.GroupItem>
          <List.GroupItem action icon="alert-circle"> Causa: { this.props.alerta.causa }</List.GroupItem>
          <List.GroupItem action icon="message-square"> Mensaje: { this.props.alerta.mensaje }</List.GroupItem>
          <List.GroupItem action icon="circle"> Atendido: { this.props.alerta.atendida === 0 || this.props.alerta.atendida === null? "No":"Si" }</List.GroupItem>
          <List.GroupItem action icon="calendar"> Fecha atendido: { this.props.alerta.atiendeFecha}</List.GroupItem>
          <List.GroupItem action icon="user-check"> Usuario: { this.state.usuario }</List.GroupItem>
        </List.Group>
        <div style={{ backgroundColor: "rgba(49, 68, 94, 0.15)"}}>
          <Grid.Row>
            <Grid.Col md={12} lg={12}>
              <Form.Group label={this.state.showIncidenciaF ? "Crear Incidencia":"Desea crear una incidencia"}>
                <Form.Switch type="Checkbox" onChange={ this.showIncidenciaForm } />
              </Form.Group>        
              {this.state.showIncidenciaF ? this.props.IncidenciasFrom: null }
            </Grid.Col>
          </Grid.Row>
        </div>
        <div className="float-right">
          <span class="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick= { () => this.crud_put()} disabled={ this.props.alerta.atendida }>
              <span class="badge"><Icon link={true} name="anchor" />Marcar como atendida</span>
            </Button>
          </span>
        </div>
      </div>
    );
  }
  showIncidenciaForm=(e)=>{  this.setState({ showIncidenciaF: e.target.checked })}
}
export default AlertaNForm;
