import React, { Component } from "react";
import { List, Button, Icon } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
class Actividad extends Component {
  constructor(props){
    super(props);
    this.state = {
      incidencias: [],
      hiddenModals: true,
      gps: [],
      puntoDeInteres: []
    };
  }
  componentDidMount =() => {
    if(this.props.gpsEstatusTerrestres){
      this.setState({gps: this.props.obj.movimientoGps? this.props.obj.movimientoGps: [] });
      //if( this.props.gpsEstatusTerrestres[this.props.obj.id] ){ this.setState({gps: this.props.gpsEstatusTerrestres[this.props.obj.id]}); }
    }
    if( this.props.plataformaTipo === "Naviera"){
      this.setState({gps: this.props.gps });
      this.setState({ puntoDeInteres: [] });
      this.props.gps.map((gps, index)=>{
        this.getfuntion("get", gps.gps, "", "gpsubicacion/"+gps.gps.id, "puntoDeInteres");
      });
    }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
          if( stateVar === "puntoDeInteres" && returnVal){
            let temp= this.state.puntoDeInteres;
            temp.push(obj.nombre+": "+timeToDateFill(new Date(returnVal.fecha).getTime())+", "+ returnVal.ubicacion);
            this.setState({ puntoDeInteres: temp });
            }
          }
          break;
        case "post":
            {
              if (stateVar === "removerActivo") {
                if(returnVal.OK ){
                  NotificationManager.info("GPS: "+ obj.gps, "Recuperado, tal vez tenga que refrescar pantalla para ver los cambios reflejados");
                }
                if( returnVal.Error ){
                NotificationManager.error("GPS: "+ obj.gps, returnVal.Error);
                }
              }               
            }
            break;
        default:
          break;
      }
    }).catch(err => { if (err.response) { console.log(err.response);  } else {console.log("Error desconocido ...." + err); } });
  }

  render() {
    return (

      <div className= "mt-1">
        {this.props.plataformaTipo === "Naviera"?
          <List.Group>
            <List.GroupItem action icon="flag"> Estatus: {this.props.obj.idOperacionEstatus ?this.props.obj.idOperacionEstatus.id: null}, {this.props.obj.idOperacionEstatus ? this.props.obj.idOperacionEstatus.nombre:null } </List.GroupItem>
            <List.GroupItem action icon="bookmark"> Booking: { this.props.obj.booking }</List.GroupItem>
            <List.GroupItem action icon="paperclip"> PO: { this.props.obj.po } </List.GroupItem>
            <List.GroupItem action icon="square"> Contenedor: { this.props.obj.contenedor }</List.GroupItem>
            <List.GroupItem action icon="map-pin"> Origen:
              {
                this.props.obj.idMovimientoTipo.nombre.toUpperCase().includes("IMPORTACIÓN") || this.props.obj.idMovimientoTipo.nombre.toUpperCase().includes("IMPORTACION")?
                this.props.obj.idCorredor.nombre
                : this.props.obj.idCliente.domicilio
              }
            </List.GroupItem>
            <List.GroupItem action icon="map-pin"> Destino:
              {
                this.props.obj.idMovimientoTipo.nombre.toUpperCase().includes("IMPORTACIÓN") || this.props.obj.idMovimientoTipo.nombre.toUpperCase().includes("IMPORTACION")?
                  this.props.obj.idCliente.domicilio
                : this.props.obj.idCorredor.nombre
              }
            </List.GroupItem>
            <List.GroupItem action icon="rss">
            GPS: {this.state.gps[0]? this.state.gps[0].idMovimiento === this.props.obj.id?"": "No instalado":"" } {  this.props.plataformaTipo === "Naviera" && this.state.gps?
                    this.state.gps.map((gps, index)=>
                      <div>
                        <div>
                          <span style={{float:"left"}}>
                            <div onClick={() => this.props.abrirmodalGps(gps.gps)} data-toggle="tooltip" data-placement="top" title={"Rastrear ("+gps.gps.nombre+")"} >
                              <label style={{ "color": "black", "font-size": "200%" }}><Icon name="map-pin" /></label>
                            </div>
                          </span>
                          { this.props.estaPrivilegioActivo("movimiento_gps_borrar") && this.props.obj?
                            this.props.obj.idPlataforma.plataformaTipo.id === 1?
                              <span style={{float:"left"}}  data-toggle="tooltip" data-placement="top" title={"Remover ("+gps.gps.nombre+")"} >
                                <Button target="_blank" size="sm" RootComponent="a" color="danger" onClick={() => this.removerGps(gps) } > <span class="badge"><Icon link={true} name="trash" /></span> </Button>
                              </span>   
                            :null:null
                          }
                        </div>
                        <br/>
                        <div className="mt-3"> <Icon name="speaker"/>{ gps.gps.nombre } / <Icon name="hash"/>Imei: { gps.gps.imei } / <Icon name="battery"/>
                              {this.props.gpsEstatusNavieros?
                                this.props.gpsEstatusNavieros[gps.gps.id]?
                                  this.props.gpsEstatusNavieros[gps.gps.id]["bateria"]:"":""} % /

                              <Icon name="clock"/>
                              { this.props.gpsEstatusNavieros?
                                this.props.gpsEstatusNavieros[gps.gps.id]?
                                  this.props.gpsEstatusNavieros[gps.gps.id]["deviceActivity"]:"":"" }
                        </div>
                      </div>
                      )
                    : null }
            </List.GroupItem>
            <List.GroupItem>
              <h5>Puntos de interes</h5>
              {
                this.state.puntoDeInteres.map((punto, index)=> <div> {punto} </div> )
              }
            </List.GroupItem>
          </List.Group>
        :
          <List.Group>
            <List.GroupItem action icon="rss">
              GPS:{ this.state.gps.map((g, index)=>
                <div>
                  <div onClick={() => this.props.abrirmodalGps(g)} data-toggle="tooltip" data-placement="top" title={"Rastrear ("+g.nombre+")"} >
                      <label style={{ "color": "black", "font-size": "200%" }}><Icon name="map-pin" /></label>
                  </div>
                  <Icon name="wifi"/>idGg:{ g.gps.idGg } /
                  <Icon name="speaker"/>{ g.gps.nombre } /
                  <Icon name="hash"/>Imei: { g.gps.imei } /
                  <Icon name="battery"/>
                    {this.props.gpsEstatusTerrestres?
                      this.props.gpsEstatusTerrestres[g.gps.id]?
                          this.props.gpsEstatusTerrestres[g.gps.id]["bateria"]:"":"" } % /
                  <Icon name="clock"/>
                    { this.props.gpsEstatusTerrestres?
                        this.props.gpsEstatusTerrestres[g.gps.id]?
                            this.props.gpsEstatusTerrestres[g.gps.id]["deviceActivity"]:"":"" }
                </div>
              )}
            </List.GroupItem>
          </List.Group>
        }
      </div>

    );
  }
  removerGps =(gps)=>{
    console.log(this.props.obj);
    var recGps={gps: gps.gps.id , plataforma:this.props.obj.idPlataforma.id, estatus: 1, idMovimiento: gps.idMovimiento}
    confirmAlert({
      title: 'Atención',
      message: '¿Esta seguro de remover el gps: '+gps.gps.nombre+'( del mov: '+gps.idMovimiento+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.getfuntion("post", recGps, "", "gps/remover_activo", "removerActivo"); }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }
}
export default Actividad;
