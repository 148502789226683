import React, { Component } from "react";
import { Card, Form, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

class UsuarioTransportistas extends Component {
    constructor() {
        super();
        this.state = {
            transportistas:[],
            pTransportistas:[],
        };
    }
    componentWillMount(){
      this.getfuntion("get", "", "", "cli_usuarios_transportistas/usuario/"+this.props.usuario.id+"/plataforma/"+this.props.idPlataforma, "usuarios_transportistas");
      this.getfuntion("get", "", "", "transportistas/plataforma/"+this.props.idPlataforma, "transportistas");
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                if (stateVar === "transportistas")
                  this.setState({ transportistas: returnVal });
                if (stateVar === "usuarios_transportistas")
                  this.setState({ pTransportistas: returnVal });
                break;
              case "post":
                if (stateVar === "usuarios_transportistas"){
                  let ptransportistasTemp= this.state.pTransportistas;  
                  ptransportistasTemp.push(returnVal);
                  this.setState({ pTransportistas: ptransportistasTemp });
                }
                break;
              case "delete":
                if (stateVar === "usuarios_transportistas" && returnVal){ 
                  let varTemp= this.state.pTransportistas;
                  varTemp.map((elem, index )=>{
                    if(obj.idTransportista === elem.idTransportista ){ varTemp.splice( index, 1);  this.setState({ pTransportistas: varTemp }); }
                  });
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
    changeTransportista=(event)=>{
      let objTemp= {idUsuario: this.props.usuario.id, idTransportista: parseInt(event.target.value, 10), idPlataforma: this.props.idPlataforma };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "cli_usuarios_transportistas","usuarios_transportistas");  
      }else{
        this.getfuntion("delete", objTemp, this.props.usuario.id+"/"+parseInt(event.target.value, 10)+"/"+this.props.idPlataforma, "cli_usuarios_transportistas","usuarios_transportistas");
      }      
    }
    getTransportista    =(id)=>{ for( let  item of this.state.pTransportistas ){  if( item.idTransportista === id ){   return true; } } return false; }
    render() {
        return (
            <div>
              <Card>
                <Card.Body>
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>Id</Table.ColHeader>
                        <Table.ColHeader>Transportista  </Table.ColHeader>
                        <Table.ColHeader>estatus </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.transportistas.map((t, index) =>
                        <Table.Row align="center">
                            <Table.Col>{ t.id }</Table.Col>
                            <Table.Col>{ t.nombre }</Table.Col>
                            <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getTransportista(t.id) } onChange={ this.changeTransportista} /></Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>                                                            
                </Card.Body>
              </Card>
                <br/>
            </div>
        );
    }
}

export default UsuarioTransportistas;