//import React, { Component } from 'react';
import axios from 'axios';
import * as conf from "../config/config";
import { validarToken } from "./JWT";
var user = JSON.parse(sessionStorage.getItem("usuario"));
var catalogosLogObject = { nombreCatalogo: "", usuario: user? user.persona: 0, accionFecha: null, metodo:"", data:[], request: "", error: "" };
//catalogos a excluir

const catalogosLogBlackList=[  { api: "catalogosLog" },
                               { api: "plataformasUsuariosPerfiles/username" },
                               { api: "usuariosLog" },
                               { api: "validacionDatos/terrestre" },
                               { api: "validacionDatos/naviera" },
                               { api: "terrestreEntregasIncidencias" },
                               { api: "mail" },
                               { api: "terrestresAlertas" },
                               //{ api: "entregas" },
                               //{ api: "terrestreMovimientos" },
                               { api: "navierasAlertas" },
                               { api: "movimientoIncidencias" },
                               { api: "movimiento_operador" },
                               { api: "terrestreMovimientoUnidad" },
                               { api: "incidencias_traxion" },
                               //{ api: "navieraMovimientos" },
                               { api: "movimientoGps" },
                               { api: "gps/asignar_gps" },
                               { api: "movimientosPatio" },
                            ];
export function Crud_error(ErrorCode=-99, Catalogo=""){
        switch(ErrorCode){
                case 400: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Solicitud incorrecta";
                case 401: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+'"'+ Catalogo +'"'+ ", Pongase en contacto con el administrador del sistema";
                case 403: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+'"'+ Catalogo +'"'+ ", Pongase en contacto con el administrador del sistema";
                case 404: return "( Codigo de error: "+ErrorCode+"),   No se pudo acceder a "+'"'+ Catalogo +'"'+ ", intente mas tarde o pongase en contacto con el administrador del sistema";
                case 405: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Método no permitido";
                case 408: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Tiempo agotado, la solicitud no se llevó a cabo dentro del tiempo establecido";
                case 415: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servidor se niega a aceptar la solicitud porque el formato de carga útil está en un formato no compatible";
                case 500: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Algo ha ido mal en el servidor del sitio web";
                case 502: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Si se conecta a un servidor que actúa como una puerta de enlace o proxy, Es posible que los servidores que se comunican no están de acuerdo sobre el protocolo para intercambiar datos.";
                case 503: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servicio está temporalmente no disponible";
                default: return "Error desconocido, Contacta a sistemas";
        }
}
//                             principal   temporal default      id     parametros opcional          state              cerrar modal    si error actualizar
export const Crud_Catalogos = async(apiRaiz="", api="", metodo="get", id="", params=[], storeFunction="", stateElementos, hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" )=>{
  var tokenStore="tok";
  validarToken(sessionStorage.getItem(tokenStore));
  let instance = axios.create();
  if (url==""){
    url=conf.api_raiz;
  }
  instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.defaults.headers.common['Accept'] = 'application/json';  
        switch(metodo){
                case "get":{
                                return axios.get(url+apiRaiz+filtro).then(res => {                                        
                                        if( storeFunction !== "" ){
                                                storeFunction(res.data);
                                        }
                                        return res.data;
                                });

                }
                case "post":
                        if(params !== "" ){
                                return axios.post(url+apiRaiz, params).then(res => { 
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;                                      
                                        catalogosLogObject.data= JSON.stringify(res.data);
                                        catalogosLogObject.request= JSON.stringify( params );
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        if( typeof stateElementos === "object" ){
                                                if(api === apiRaiz){
                                                        stateElementos.push(res.data);
                                                        if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                        if( hiddenModal !== "" ){ hiddenModal(); }
                                                        return res.data;
                                                }else if(apiRaiz === "operadoresSkylogistic") {
                                                        return res.data
                                                }else{
                                                        stateElementos.map((elemento, index) =>{
                                                                if(elemento["id"] === id){
                                                                        if(columna !== "" && elemento[columna]){
                                                                                arraytoUpdate[columna].push(res.data);
                                                                        }
                                                                }
                                                                if(elemento["id"] === params["id"]){
                                                                        if( storeFunction !== "" ){ storeFunction(arraytoUpdate); }
                                                                }
                                                        })
                                                        return arraytoUpdate;
                                                }
                                        }else{
                                                return res.data;
                                        }
                                }).catch(err => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.request= JSON.stringify( params );
                                        catalogosLogObject.data= null;//Response
                                        if( err.response ){
                                                catalogosLogObject.error= JSON.stringify( err.response );
                                        } else {
                                                catalogosLogObject.error= JSON.stringify( { error: "Error desconocido"} );
                                        }
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        return false;
                                 });
                        }else{
                          return false;
                        }
                case "put":
                        if(params !== "" && params !== null ){
                                return axios.put(url+apiRaiz, params).then(res => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;                                      
                                        catalogosLogObject.data= JSON.stringify(res.data);
                                        catalogosLogObject.request= JSON.stringify( params );
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        if( typeof stateElementos === "object" ){
                                                if(api === apiRaiz){
                                                        stateElementos.map((elemento, index) =>{
                                                                if(elemento["id"] === params["id"]){
                                                                        stateElementos[index]= res.data
                                                                }
                                                        })
                                                        if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                        if( hiddenModal !== "" ){ hiddenModal(); }
                                                        return res
                                                }else if(apiRaiz === "operadoresSkylogistic") {
                                                        return res.data
                                                }else{
                                                  return res.data;
                                                }
                                        }else{
                                                if( hiddenModal !== "" ){ hiddenModal(); }
                                                return res.data;
                                        }
                                }).catch(err => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.request= JSON.stringify( params );
                                        catalogosLogObject.data= null;//Response
                                        if( err.response ){
                                                catalogosLogObject.error= JSON.stringify( err.response );
                                        } else {
                                                catalogosLogObject.error= JSON.stringify( { error: "Error desconocido"} );
                                        }
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        return false;
                                });
                        }else{
                          return false;
                        }
                case "delete":
                        if(id !== "" && id !== null ){
                                var temelemento="";
                                return axios.delete(url+apiRaiz+"/"+id).then(res => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.data=JSON.stringify({ id: id,  Respuesta: res.data}) ;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.request= JSON.stringify( { id: id } );
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        if(api === apiRaiz){
                                                stateElementos.map((elemento, index) =>{
                                                        if(elemento["id"] === id){
                                                                stateElementos.splice(index, 1)
                                                                temelemento= elemento;
                                                        }
                                                })
                                                if( storeFunction !== "" ){ storeFunction(stateElementos); }
                                                if( hiddenModal !== "" ){ hiddenModal(); }
                                                return res.data;
                                        }else{

                                                var idFila=""; var ban= true;
                                                if(id.length){
                                                        for (let item of id) {
                                                                if(item !== "/" && ban){
                                                                        idFila= idFila+item
                                                                }else{ if(item === "/"){ban=false;} }
                                                        }

                                                }else{  idFila=id}
                                                if( typeof stateElementos === "object" ){
                                                stateElementos.map((elemento, index) =>{
                                                        if(elemento["id"] === idFila){
                                                                stateElementos[index]=arraytoUpdate;
                                                                return arraytoUpdate;
                                                        }
                                                })
                                                }
                                                return res.data;
                                        }
                                }).catch(err => { 
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.request= JSON.stringify( { id: id} );
                                        catalogosLogObject.data= null;//Response
                                        if( err.response ){
                                                catalogosLogObject.error= JSON.stringify( err.response );
                                        } else {
                                                catalogosLogObject.error= JSON.stringify( { error: "Error desconocido"} );
                                        }
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        return false;
                                 });
                        }else{
                          let restpuesta= false;
                          if( typeof params === "object" ){
                            return axios.delete(url+apiRaiz, params).then(res => {
                                catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                catalogosLogObject.metodo= metodo;                                      
                                catalogosLogObject.data= JSON.stringify(res.data);
                                catalogosLogObject.request= JSON.stringify( params );
                                let blacklistBan= false;
                                for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                
                               
                                    if(res){ restpuesta= res.data}else{ restpuesta=false }
                                    return restpuesta;
                                }).catch(err => {
                                        catalogosLogObject.nombreCatalogo= apiRaiz+filtro;
                                        catalogosLogObject.metodo= metodo;
                                        catalogosLogObject.request= JSON.stringify( params );
                                        catalogosLogObject.data= null;//Response
                                        if( err.response ){
                                                catalogosLogObject.error= JSON.stringify( err.response );
                                        } else {
                                                catalogosLogObject.error= JSON.stringify( { error: "Error desconocido"} );
                                        }
                                        let blacklistBan= false;
                                        for( let item of catalogosLogBlackList ){ if( apiRaiz.includes(item.api) ){ blacklistBan= true; }  }
                                        if(!blacklistBan){  Crud_Catalogos("catalogosLog", "", "post", "", catalogosLogObject); }
                                        
                                        return false;
                                });
                          }
                          
                          return restpuesta;
                        }
                default: return null;
        }
}

export default Crud_Catalogos;
