import React, { useState, useEffect } from "react";
import Dash from "../Dash";

export const ContentDashboardNaviera = ({ data }) => {
  const [contador, setContador] = useState([]);

  useEffect(() => {
    let contadorTemp = 0;
    if (data.length !== 0) {
      data.forEach(function (resultado) {
        contadorTemp += resultado.count;
      });
      setContador(contadorTemp);
    } else {
      setContador(0);
    }
  }, [data]);

  return (
    <div>
      <div className="row mt-3">
        <div className="ml-auto mr-3">
          <h1>Total {contador}</h1>
        </div>
      </div>
      <div className="row mt-3">
        {data.length > 0 ? (
          data
            .sort()
            .map((res, index) => (
              <Dash
                texto={res.value}
                cantidad={res.count}
                color={`hsl(24,100%, ${
                  -20 + (100 - (100 * res.count) / contador)
                }%)`}
                total={contador}
                showBar={true}
                instalados={false}
                recuperados={false}
              />
            ))
        ) : (
          <div className="alert alert-dark w-100" role="alert">
            No hay resultados que mostrar...
          </div>
        )}
      </div>
    </div>
  );
};
