import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
var user= JSON.parse(sessionStorage.getItem("usuario"));

class MovimientoOperador extends Component {
  constructor(props) {
    super(props);
    this.state = {
                 operadores: [],
                   operador: null,
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.movimiento= [];
  }
  componentWillMount = () => {
  }
  componentWillUpdate = ()=>{
    if(this.movimiento !== this.props.movimiento){
      this.movimiento =  this.props.movimiento;
      this.getfuntion("get","","","operadores/idmovimiento_nav/"+this.props.movimiento.id, "operador");
    }
  }
  componentDidMount = () => {
    this.setState({ id                    : this.props.movimiento.id });
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get": {
          if (stateVar === "operador"){
            if(returnVal){
              this.setState({ operador: returnVal });
            }else{
              this.getfuntion("get","","","operadores/transportista/"+this.props.movimiento.idTransportista.id, "operadores");
            }
          }
          if (stateVar === "operadores" && returnVal){
              this.setState({ operadores: returnVal });
          }
         } break;
         case "post": {
          if (stateVar === "operador"){
            this.getfuntion("get","","","operadores/idmovimiento_nav/"+this.props.movimiento.id, "operador");           
          }
        } break;
        case "delete": {
          if (stateVar === "operador"){
            if(this.state.operador.id){
              if(this.props.movimiento.id+"/"+this.state.operador.id){
                this.setState({ operador: null });
              }
            }            
          }
        } break;
        case "put": {
        } break;
        default:    break;
      }
    }).catch(err => { console.log(err); });
  }
  
  removerOperador =(id)=>{
    confirmAlert({
      title: 'Atención',
      message: '¿Esta seguro de remover el operador del mov('+this.props.movimiento.id+') ?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { this.getfuntion("delete","",this.props.movimiento.id+"/"+id,"movimiento_operador", "operador"); }
        },
        {
          label: 'Cancelar',
          onClick: () => {return false}
        }
      ]
    });
  }
  
  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Col><h1>{this.props.movimiento.idPlataforma.nombre.toUpperCase()}, LT: {this.props.movimiento.idTransportista.nombre}</h1> </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          { this.state.operador?
            <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={[{ content: "ID Mov" }, { content: "nombre" }, { content: "licencia" }, { content: "vigencia" }, { content: "telefono" }, { content: "Remover" }]} >
              <Table.Body>
                <Table.Row>
                  <Table.Col> {this.props.movimiento.id}  </Table.Col>
                  <Table.Col> {this.state.operador? this.state.operador.nombre+" "+this.state.operador.paterno+" "+this.state.operador.materno: "" }</Table.Col>
                  <Table.Col> {this.state.operador? this.state.operador.licenciaTipo: "" }</Table.Col>
                  <Table.Col> {this.state.operador? this.state.operador.licenciaVigencia: "" }</Table.Col>
                  <Table.Col> {this.state.operador? this.state.operador.medioComunicacion: "" }</Table.Col>
                  <Table.Col> <Button target="_blank" size="sm" color="danger" onClick={() => this.removerOperador(this.state.operador.id) } > <span class="badge"><Icon link={true} name="trash" />Remover operador</span> </Button></Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>: null
          }          
        </Grid.Row>
        <div hidden={this.state.operador} >
          <h2> Selecciona un operador </h2>
          <Grid.Row>
            <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={[{ content: "ID Operador" }, { content: "nombre" }, { content: "licencia" }, { content: "vigencia" }, { content: "telefono" }, { content: "Agregar" }]} >
              <Table.Body>
                { this.state.operadores.map((op, index)=>
                  <Table.Row>
                    <Table.Col> {op.id}  </Table.Col>
                    <Table.Col> {op.nombre+" "+op.paterno+" "+op.materno}</Table.Col>
                    <Table.Col> {op.licenciaTipo}</Table.Col>
                    <Table.Col> {op.licenciaVigencia}</Table.Col>
                    <Table.Col> {op.medioComunicacion}</Table.Col>
                    <Table.Col> <Button target="_blank" size="sm" color="primary" onClick={() => this.getfuntion("post",{"idMovimiento": this.props.movimiento.id, "idOperador": op.id}, "","movimiento_operador", "operador") } >
                                  <span class="badge"><Icon link={true} name="plus" />Agregar</span>
                                </Button></Table.Col>
                  </Table.Row>
                ) }                
              </Table.Body>
            </Table>
          </Grid.Row>
        </div>
      </div>
    );
  }
}
export default MovimientoOperador;
