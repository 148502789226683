//Author F.R. Betancourt
import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";

class CorredoresForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      nombreCorto: "",
      latitud: "",
      longitud: "",
      stipoCorredor: "",
      splataformaTipo: "",
      plataTNombre: "",
      estatus: false,
      pais: "",
      crudbutonEnable: true, // Se desabilita cuando se hace click
      accion: this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.aplataformaTipo = [];
    this.atipoCorredor = [];
    this.enableSave = this.enableSave.bind(this);
    this.corredoresObject = [];
  }
  isEnable = (Accion) => {
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  componentWillMount = () => {
    this.aplataformaTipo.push({ value: 1, label: "Naviera" });
    this.aplataformaTipo.push({ value: 2, label: "Terrestre" });
    this.atipoCorredor.push({ value: "L", label: "Local" });
    this.atipoCorredor.push({ value: "F", label: "Foraneo" });
    console.log("44 this.props.elementos.aplataformaTipo");
    console.log(this.props.elementos.aplataformaTipo);
  };
  componentDidMount = () => {
    if (this.state.accion === "nuevo") {
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ nombreCorto: this.props.elementos.nombreCorto });
      this.setState({ latitud: this.props.elementos.latitud });
      this.setState({ pais: this.props.elementos.pais });
      this.setState({ longitud: this.props.elementos.longitud });
      if (this.props.elementos.tipoCorredor === "L") {
        this.setState({ stipoCorredor: { value: "L", label: "Local" } });
      } else {
        this.setState({ stipoCorredor: { value: "F", label: "Foraneo" } });
      }
      if (this.props.elementos.plataformaTipo.id === 1) {
        this.setState({ splataformaTipo: { value: 1, label: "Naviera" } });
      } else {
        this.setState({ splataformaTipo: { value: 2, label: "Terrestre" } });
      }
      //this.setState({ plataformaTipo: this.props.elementos.plataformaTipo});
      //this.setState({   plataTNombre: this.props.elementos.plataTNombre});
      this.setState({ estatus: this.props.elementos.estatus });
    }
  };

  enableSave = () => {
    console.log("Enable button: " + this.state.nombre);
    if (this.state.nombre.length > 0 && this.state.nombreCorto.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
    this.setState({ disabledSave: false });
  };

  changePais = (event) => {
    this.setState({ pais: event.target.value });
  };
  changePlataformaTipo = (event) => {
    console.log("73 PlataformaTipo event:");
    console.log(event);
    //this.setState({plataformaTipo:{ value:event.value, label:event.label} });
    if (1 === event.value) {
      this.setState({ splataformaTipo: { value: 1, label: "Naviera" } });
      this.setState({ plataformaTipo: 1 });
    } else {
      this.setState({ splataformaTipo: { value: 2, label: "Terrestre" } });
      this.setState({ plataformaTipo: 2 });
    }
  };
  changeTipoCorredor = (event) => {
    if (event.value === "F") {
      this.setState({ stipoCorredor: { value: "F", label: "Foraneo" } });
      this.setState({ tipoCorredor: "F" });
    } else {
      this.setState({ stipoCorredor: { value: "L", label: "Local" } });
      this.setState({ tipoCorredor: "L" });
    }
  };
  changeStatus = (event) => {
    //event.target.checked
    event.target.checked
      ? this.setState({ status: 1 })
      : this.setState({ status: 0 });
    this.setState({ disabledSave: false });
  };
  guardarCorredor = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      nombreCorto: this.state.nombreCorto,
      latitud: this.state.latitud,
      longitud: this.state.longitud,
      estatus: this.state.status,
      tipoCorredor: this.state.stipoCorredor.value,
      plataformaTipo: {
        id: this.state.splataformaTipo.value,
        nombre: this.state.splataformaTipo.label,
      },
      pais: this.state.pais,
    });
  }
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", {
      id: this.state.id,
      nombre: this.state.nombre,
      nombreCorto: this.state.nombreCorto,
      latitud: this.state.latitud,
      longitud: this.state.longitud,
      estatus: this.state.status,
      tipoCorredor: this.state.stipoCorredor.value,
      plataformaTipo: {
        id: this.state.splataformaTipo.value,
        nombre: this.state.splataformaTipo.label,
        pais: this.state.pais,
      },
    });
  }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.nombre} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={6}>
            <Form.Input
              type="text"
              id="nombre"
              name="nombre"
              label="Nombre"
              placeholder="Nombre"
              value={this.state.nombre}
              onChange={this.handleChange}
              maxlength="50"
            />
            <span>{this.state.valid}</span>
          </Grid.Col>
          <Grid.Col md={6}>
            <Form.Input
              type="text"
              id="nombreCorto"
              name="nombreCorto"
              label="Nombre Corto"
              placeholder="Nombre Corto"
              value={this.state.nombreCorto}
              onChange={this.handleChange}
              maxlength="20"
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Form.Input
              type="text"
              id="pais"
              name="pais"
              label="Pais"
              placeholder="pais"
              value={this.state.pais}
              onChange={this.changePais}
              maxlength="50"
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={6}>
            <Form.Input
              type="number"
              id="latitud"
              name="latitud"
              label="Latitud"
              placeholder="Latitud"
              value={this.state.latitud}
              onChange={this.handleChange}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <Form.Input
              type="number"
              id="longitud"
              name="longitud"
              label="Longitud"
              placeholder="Longitud"
              value={this.state.longitud}
              onChange={this.handleChange}
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Plataforma Tipo">
              <Select
                value={this.state.splataformaTipo}
                onChange={this.changePlataformaTipo}
                options={this.aplataformaTipo}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Tipo Corredor">
              <Select
                value={this.state.stipoCorredor}
                onChange={this.changeTipoCorredor}
                options={this.atipoCorredor}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Switch
              name="estatus"
              label="Activo"
              checked={this.state.status}
              value=""
              onChange={this.changeStatus}
            />
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarCorredor}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default CorredoresForm;
