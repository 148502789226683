//Componentes externos
import axios from "axios";
//Hooks externos
import { useMutation, useQueryClient } from "@tanstack/react-query";
//Api
import * as conf from "../config/config";
//Props
interface Props {
  url: string;
  reload?: any[];
}
interface PropsMutation {
  id?: number | string;
  data?: any;
  tipo: string;
}

export const useMutationData = ({ url, reload }: Props) => {
  const queryClient = useQueryClient();

  const mutationData = ({ id, data, tipo }: PropsMutation) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("tok"),
      },
    };
    switch (tipo) {
      case "get":
        if (id) {
          return axios.get(conf.api_raiz + url + `/${id}`, options);
        }
        return axios.get(conf.api_raiz + url, options);
      case "patch":
        if (id) {
          return axios.patch(conf.api_raiz + url + `/${id}`, data, options);
        }
        return axios.patch(conf.api_raiz + url, data, options);
      case "put":
        if (id) {
          return axios.put(conf.api_raiz + url + `/${id}`, data, options);
        }
        return axios.put(conf.api_raiz + url, data, options);
      case "delete":
        return axios.delete(conf.api_raiz + url + `/${id}`, options);
      case "post":
      default:
        if (id) {
          return axios.post(conf.api_raiz + url + `/${id}`, data, options);
        }
        return axios.post(conf.api_raiz + url, data, options);
    }
  };

  return useMutation({
    mutationFn: ({ id, data, tipo }: PropsMutation) =>
      mutationData({ id, data, tipo }),
    onSuccess: () => {
      if (reload) {
        queryClient.invalidateQueries({ queryKey: reload });
      }
    },
  });
};
