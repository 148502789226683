import React, { Component } from "react";
import { List, Button, Icon, Grid, Form, Card } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { NotificationManager } from 'react-notifications';
import * as conf from "../../../../config/config";

const rutaImagen = conf.api_raiz + "evidencia/";

class AlertaTForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      Entregas        : { value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 },
      entregas        : "",
      entregaActiva   : "",
      showIncidenciaF : false,
      incidencias     : [],
      hiddenModals    : true,
      imgEvidencias   : []
    };
    this.enableSave = this.enableSave.bind(this);
    this.Entregas = [];
    this.alerta = {
                        "id"              : this.props.alerta.id,
                        "idMovimiento"    : this.props.alerta.idmovimiento,
                        "idEntrega"       : this.props.alerta.idEntrega,
                        "idRuta"          : this.props.alerta.idRuta,
                        "idalertaTipo"    : this.props.alerta.idAlertaTipo,

                        "latitud"         : this.props.alerta.latitud,
                        "longitud"        : this.props.alerta.longitud,

                        "triggerValue"    : this.props.alerta.triggerValue,
                        "triggerName"     : this.props.alerta.triggerName,
                        "mensaje"         : this.props.alerta.mensaje,
                        "fechaCreacion"   : this.props.alerta.fechaCreacion,
                        "fechaRegistro"   : this.props.alerta.fechaRegistro,

                        "atendida"        : this.props.alerta.atendida,
                        "atiendeFecha"    : this.props.alerta.atiendeFecha,
                        "atiendeUsuario"  : this.props.alerta.atiendeUsuario,
                      };
  }
  enableSave = () => {
      if (this.props.alerta.idEntrega > 1) {
        return false;
      } else {
        return true
      }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "", enviar) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "entregas"){
              if (returnVal){
                this.setState({ entregas: returnVal });
                returnVal.map( (entre, index) => {
                  if (entre.estatus == 1) {this.setState({ entregasActiva:entre})}
                });
              return returnVal;
              }
            } else {
              return returnVal;
            }
          }
          break;
        case "put":{
          if (catalogo ==="terrestresAlertas"){
            //this.usuariosLogObject.idBitacoraMonitoreo = 4;
            //this.usuariosLogObject.idUsuario = user.id;
            //this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
            //this.usuariosLogObject.idPlataformaTipo = 2;
            //this.usuariosLogObject.accionFecha = myFormato(new Date());
            //this.usuariosLogObject.descripcion = "Alerta Atendida id: " + returnVal.id + " de Tipo: " + returnVal.triggerName + " del Movimiento id: " + returnVal.idMovimiento;
            this.props.setAlerta(returnVal, "terrestresAlertas");
            obj.estatus=1;
            Crud_Catalogos("entregas", "entregas", "put", "", obj, "", "", this.hiddenmodal, "");
          }
        }
        default:
          break;
      }
    }).catch(err => {
        console.log("Error "+err.response);
    })
  }
  componentWillMount(){
    this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.idMovimiento, "entregas").then((returnVal) =>
    {
      if (returnVal){
        this.setState({ entregas:returnVal});
        let tmpAtiva = returnVal.filter(word => word.estatus===1);
        if (tmpAtiva[0]){
            this.state.entregaActiva= tmpAtiva[0];
            this.setState({ Entregas: { value: this.state.entregaActiva.idEntrega, label: this.state.entregaActiva.idDestino>1?this.state.entregaActiva.destino:this.state.entregaActiva.direccion, indice:0 } });
            this.alerta.idEntrega=this.state.entregaActiva.idEntrega;
        }
        returnVal.map((entre, index) => {
          this.Entregas.push({ value: entre.idEntrega, label: entre.destino, indice: index });
            if (this.props.alerta){
              if (this.props.alerta.idEntrega === 1){
                this.setState({ Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 } });
              }
              if (entre.idEntrega === this.props.alerta.idEntrega){
                this.setState({ Entregas: { value: entre.id, label: entre.destino, indice: index } });
              }
            }
          });
        }
        if (this.props.entregas){
          //Si Existen entregas
          let tmpAtiva = this.props.entregas.filter(word => word.estatus===1);
          if (tmpAtiva[0]){
            this.state.entregaActiva= tmpAtiva[0];
            this.setState({ Entregas: { value: this.state.entregaActiva.idEntrega, label: this.state.entregaActiva.idDestino>1?this.state.entregaActiva.destino:this.state.entregaActiva.direccion, indice:0 } });
            this.alerta.idEntrega=this.state.entregaActiva.idEntrega;
          }
        }
      }

    );
  }
  componentDidMount(){
    //this.props.IncidenciasFrom.props.idEntregaActiva=1;
    this.Entregas.push({ value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 });
    //alert(this.props.entregas);
    this.alerta = this.props.alerta;
    //Si las Entregas se Recuperan desde el Form Padre
    if (this.props.entregas){
      this.props.entregas.map((entre, index) => {
        this.Entregas.push({ value: entre.idEntrega, label: entre.destino, indice: index });
        if (this.props.alerta){
          if (this.props.alerta.idEntrega === 1){
            this.setState({ Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 } });
          }
          if (entre.idEntrega === this.props.alerta.idEntrega){
            this.setState({ Entregas: { value: entre.id, label: entre.destino, indice: index } });
          }
        }
      });
    }else{
      //Si No Contamos con Entregas
      if (this.state.entregas){
        this.state.entregas.map((entre, index) => {
          this.Entregas.push({ value: entre.idEntrega, label: entre.destino, indice: index });
          if (this.props.alerta){
            if (this.props.alerta.idEntrega === 1){
              this.setState({ Entregas: { value: 1, label: "SIN ENTREGA ASIGNADA", indice:0 } });
            }
            if (entre.idEntrega === this.props.alerta.idEntrega){
              this.setState({ Entregas: { value: entre.id, label: entre.destino, indice: index } });
            }
          }
        });
      }
    }
    //img evidencias
    this.setState({ imgEvidencias: [] });
    if (this.props.alerta.causa=="Imágen Evidencia") {
      this.getfuntion("get", "", "", "imagenMovimiento/movimiento/" + this.props.idMovimiento, "imagenMovimiento").then((returnVal) => {
        //console.log(returnVal);
        this.setState({ imgEvidencias: returnVal ? returnVal : [] });
      });
    }
  }
  changeEntregas=(event) =>{
    if (event.value===1){
        this.setState({ Entregas: { value: event.value, label: event.label, indice: event.indice } });
    }else{
      if(this.props.entregas){
        this.props.entregas.map((entre, index) => {
          if (entre.idEntrega === event.value){
            this.setState({ Entregas: { value: entre.id, label: entre.destino, indice: index } });
            this.props.alerta.idEntrega = event.value;
          }
        });
      }else {
        this.state.entregas.map((entre, index) => {
          if (entre.idEntrega === event.value){
            this.setState({ Entregas: { value: entre.id, label: entre.destino, indice: index } });
            this.props.alerta.idEntrega = event.value;
          }
        });
      }
    }
  }

  crud_put() {
    var d = new Date();
      var mes=(d.getMonth()+1);
      var dia=d.getDate();
      var mes1 = "";
      var dia1 = "";
      mes < 10 ? mes1="0"+mes: mes1=mes;
      dia < 10 ? dia1="0"+dia: dia1=dia;
      var horas= d.getHours();
      var minutos= d.getMinutes();
      var segundos= d.getSeconds();
      var horas1= "";
      var minutos1= "";
      var segundos1= "";
      horas < 10 ? horas1="0"+horas: horas1= horas;
      minutos < 10 ? minutos1="0"+minutos: minutos1= minutos;
      segundos < 10 ? segundos1="0"+segundos: segundos1= segundos;
      //this.props.alerta.atendida= 1;
      this.alerta.atendida=1;
      //this.props.alerta.atiendeFecha= d.getFullYear()+"-"+mes1+"-"+dia1+" "+horas1+":"+minutos1+":"+segundos1;
      this.alerta.atiendeFecha = d.getFullYear()+"-"+mes1+"-"+dia1+" "+horas1+":"+minutos1+":"+segundos1;
      //this.props.alerta.atiendeUsuario= this.props.usuario.id;
      this.alerta.atiendeUsuario=this.props.usuario.id;
    //metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl=""

    //this.props.f("put", this.props.alerta, "", "terrestresAlertas","", this.props.closeModal);
    this.props.getfuntion("put", this.alerta, "", "terrestresAlertas","", this.props.closeModal);
}
  render() {
    return (

      <div className= "mt-1">
        <List.Group>
          <Grid.Row>
            <Grid.Col md={4}>
              <List.GroupItem action icon="credit-card"> id-Alerta: { this.props.alerta.id } </List.GroupItem>
            </Grid.Col>
            <Grid.Col md={4}>
              <List.GroupItem action icon="credit-card"> id-Movimiento: { this.props.alerta.idMovimiento } </List.GroupItem>
            </Grid.Col>
            <Grid.Col md={4}>
              <List.GroupItem action icon="truck"> id-Entrega: { this.props.alerta.idEntrega } </List.GroupItem>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6}>
              <List.GroupItem action icon="map-pin"> latitud: { this.props.alerta.latitud } </List.GroupItem>
            </Grid.Col>
            <Grid.Col md={6}>
              <List.GroupItem action icon="map-pin"> longitud: { this.props.alerta.longitud } </List.GroupItem>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6}>
              <List.GroupItem action icon="pen-tool"> trigger Name: { this.props.alerta.triggerName }</List.GroupItem>
            </Grid.Col>
            <Grid.Col md={6}>
              <List.GroupItem action icon="pen-tool"> trigger Value: { this.props.alerta.triggerValue }</List.GroupItem>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6}>
              <List.GroupItem action icon="user-check"> Usuario: { this.props.usuario.persona.nombre+" "+this.props.usuario.persona.aPaterno+" "+this.props.usuario.persona.aMaterno }</List.GroupItem>
            </Grid.Col>
            <Grid.Col md={2}>
              <List.GroupItem action icon="calendar"> Fecha atendido: { this.props.alerta.atiendeFecha}</List.GroupItem>
            </Grid.Col>
            <Grid.Col md={2}>
              <List.GroupItem action icon="calendar"> Fecha de creación: { this.props.alerta.fechaCreacion }</List.GroupItem>
            </Grid.Col>
            <Grid.Col md={2}>
              <List.GroupItem action icon="circle"> Atendido: { this.props.alerta.atendida === 0 || this.props.alerta.atendida === null? "No":"Si" }</List.GroupItem>
            </Grid.Col>
          </Grid.Row>
          <List.GroupItem action icon="alert-circle"> Causa: { this.props.alerta.causa }</List.GroupItem>
          <List.GroupItem action icon="message-square"> Mensaje: { this.props.alerta.mensaje }</List.GroupItem>
          <List.GroupItem action icon="message-square">
            <Select value={this.state.Entregas} onChange={this.changeEntregas} options={this.Entregas} />
          </List.GroupItem>
        </List.Group>
        <div className="float-right">
          <span class="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick= { () => this.crud_put()} disabled={this.enableSave()} >
              <span class="badge"><Icon link={true} name="save" />Marcar como atendida</span>
            </Button>
          </span>
        </div>
        <div>
        {this.state.imgEvidencias.length ? (
            <Card.Footer>
              <h3>Evidencias</h3>
              <div>
                {this.state.imgEvidencias.map((imagen, index) => (
                  <div>
                      <div>
                       Imágen: <a href={rutaImagen+imagen.nombreArchivo} target="_blank">{imagen.nombreArchivo}</a>{imagen.fechaModificado?" cargada con fecha "+ imagen.fechaModificado.substring(0,20):""}
                      </div>
                      <div>
                        <a href ={rutaImagen+imagen.nombreArchivo} target="_blank"><img src={rutaImagen+imagen.nombreArchivo}/></a>
                      </div>
                  </div>
                ))}
              </div>
            </Card.Footer>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  showIncidenciaForm=(e)=>{  this.setState({ showIncidenciaF: e.target.checked })}
}
export default AlertaTForm;
