import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";

const user = JSON.parse(sessionStorage.getItem("usuario"));

export const NavieraServiceFailure = ({
  getMovimiento,
  idMovimiento,
  cerrarModal,
}) => {
  const {
    idCorredor,
    id,
    idMovimientoTipo,
    booking,
    po,
    idOperacionTipo,
    contenedor,
  } = getMovimiento;
  //console.log(idCorredor, id, idMovimientoTipo, booking, po, idOperacionTipo, contenedor);

  const [state, setState] = useState({
    serviceFailure: { value: 0, label: "Seleccione una opción" },
    serviceFailures: {},
    nota: "",
    crudbutonEnable: true, // Se desabilita cuando se hace click
  });
  const [listadoServiceFailures, setListadoServiceFailures] = useState([]);

  useEffect(() => {
    crud("get", "", "", "service_failure", "select_service_failure");
    crud(
      "get",
      "",
      "",
      "navieraMovimientos_service_failure/movimiento/" + id,
      "list_service_failure"
    );
  }, []);

  const changeFailure = (event) => {
    //console.log(event);
    setState({
      ...state,
      serviceFailure: event,
    });
  };

  const changeNota = (event) => {
    //console.log(event);
    setState({
      ...state,
      nota: event.target.value,
    });
  };

  const enableSave = () => {
    if (state.serviceFailure.value > 0) {
      return false;
    } else {
      return true;
    }
  };

  const guardarServiceFailure = () => {
    //console.log(state.serviceFailure);
    crud(
      "post",
      {
        idMovimiento: id,
        idServiceFailure: state.serviceFailure.value,
        comentario: state.nota,
        fecha: "0000-00-00 00:00:00",
        id_usuario: user.persona,
      },
      "",
      "navieraMovimientos_service_failure",
      "guardarServiceFailure"
    );

    setState({
      ...state,
      crudbutonEnable: false,
    });
  };

  const serviceFailureNombre = (idServiceFailure) => {
    for (let sf of state.serviceFailures) {
      if (sf.value === idServiceFailure) {
        return sf.data.nombre;
      }
    }
    return "";
  };

  function crud(
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) {
    Crud_Catalogos(
      catalogo,
      "service_failure",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "select_service_failure" && returnVal) {
              let serviceFailuresTem = [];
              returnVal.map((i, index) =>
                serviceFailuresTem.push({
                  value: i.id,
                  label: i.nombre,
                  data: i,
                })
              );
              //console.log(`serviceFailuresTem`, serviceFailuresTem)
              setState({ ...state, serviceFailures: serviceFailuresTem });
              //setServiceFailures(serviceFailuresTem);
            }
            if (stateVar === "list_service_failure" && returnVal) {
              //console.log(returnVal);
              setListadoServiceFailures(returnVal);
            }
            break;
          case "post":
            if (stateVar === "guardarServiceFailure" && returnVal) {
              NotificationManager.success(
                "Datos guardados correctamente",
                "Service Failure",
                3000
              );
              crud(
                "get",
                "",
                "",
                "navieraMovimientos_service_failure/movimiento/" + idMovimiento,
                "list_service_failure"
              );
              cerrarModal();
            }

            break;
          case "put":
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <Grid.Row>
        <Grid.Col md={12} xl={12}>
          <div>
            <strong> Corredor: </strong>
            {idCorredor ? idCorredor.nombre : ""}
          </div>
          <div>
            <strong> Movimento id: </strong>
            {id ? id : ""}
          </div>
          <div>
            <strong> Movimiento Tipo: </strong>
            {idMovimientoTipo ? idMovimientoTipo.nombre : ""}
          </div>
          <div>
            <strong> Booking: </strong>
            {booking ? booking : ""}
          </div>
          <div>
            <strong> Po: </strong>
            {po ? po : ""}
          </div>
          <div>
            <strong> Operacion: </strong>
            {idOperacionTipo ? idOperacionTipo.nombre : ""}
          </div>
          <div>
            <strong> Contenedor: </strong>
            {contenedor ? contenedor : ""}
          </div>
          <div></div>
          <Form.Group label="Service Failure">
            <Select
              value={state.serviceFailure}
              onChange={changeFailure}
              options={state.serviceFailures}
            />
          </Form.Group>
          <Form.Group
            label={
              <Form.Label aside={state.nota.length + "/200"}>Nota</Form.Label>
            }
          >
            <Form.Textarea
              value={state.nota}
              name="nota"
              onChange={changeNota}
              placeholder="Comentario.."
              rows={6}
            />
          </Form.Group>
        </Grid.Col>
      </Grid.Row>

      <div className="float-right">
        <span class="badge">
          <Button
            target="_blank"
            size="sm"
            RootComponent="a"
            color="primary"
            disabled={enableSave() || !state.crudbutonEnable}
            onClick={guardarServiceFailure}
          >
            <span class="badge">
              <Icon link={true} name="save" />
              Guardar
            </span>
          </Button>
        </span>
        {state.crudbutonEnable === false ? (
          <div>
            <strong size="3" className="text-red">
              Gracias, ya quedo registrado el Service Failure
            </strong>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      {listadoServiceFailures.length > 0 ? (
        <div>
          <Grid.Row>
            <Grid.Col>
              <font size="2">
                <h1></h1>
              </font>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <font size="2">
                <h1></h1>
              </font>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <font size="1">
                <h4 align="left" className="text-blue">
                  Listado de Service Failure
                </h4>
              </font>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Table
              border={3}
              cards={true}
              striped={false}
              responsive={true}
              className="table-vcenter text-dark border border-primary"
              style={{ "background-color": "#F3F5E9" }}
              headerItems={[
                { content: "Movimiento" },
                { content: "Service Failure" },
                { content: "Comentario" },
                { content: "Usuario" },
                { content: "Fecha registro" },
              ]}
            >
              <Table.Header></Table.Header>
              <Table.Body>
                {listadoServiceFailures.map((lsf, indice1) => (
                  <Table.Row align="center">
                    <Table.Col>
                      <font size="2"> {lsf.idMovimiento} </font>
                    </Table.Col>
                    <Table.Col>
                      <font size="2">
                        {" "}
                        {serviceFailureNombre(lsf.idServiceFailure)}{" "}
                      </font>
                    </Table.Col>
                    <Table.Col>
                      <font size="2"> {lsf.comentario} </font>
                    </Table.Col>
                    <Table.Col>
                      <font size="2">
                        {" "}
                        {lsf.id_usuario.nombre +
                          " " +
                          lsf.id_usuario.aPaterno +
                          " " +
                          lsf.id_usuario.aMaterno}{" "}
                      </font>
                    </Table.Col>
                    <Table.Col>
                      <font size="2"> {lsf.fecha} </font>
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
