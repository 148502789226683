import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { event } from "jquery";

class FuncionalidadesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      label: "",
      IdBloques: { value: "id", label: "Selecciona un Bloque" },
      NombreBloque: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.funcionalidadesObject = [];
    this.enableSave = this.enableSave.bind(this);
    this.bloque = [];
  }
  isEnable = (Accion) => {
    let borrar = "funcionalidades_borrar";
    let actualizar = "funcionalidades_editar";
    let agregar = "funcionalidades_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  componentDidMount = () => {
    if (this.state.accion === "nuevo") {
      this.funcionalidadesObject = {
        nombre: "",
        label: "",
        bloque: { nombre: "", id: 0 },
      };
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ label: this.props.elementos.label });
      this.setState({
        IdBloques: {
          value: this.props.elementos.bloque.id,
          label: this.props.elementos.bloque.nombre,
        },
      });
      this.setState({ NombreBloque: this.props.elementos.bloque.nombre });
      this.funcionalidadesObject = this.props.elementos;
    }

    this.bloque.push({ value: 0, label: "Selecciona un Bloque " });
    this.props.stor.bloques_object.map((bloque, index) => {
      this.bloque.push({ value: bloque.id, label: bloque.nombre });
    });
  };
  enableSave = () => {
    console.log(
      "Enable button: " +
        this.state.nombre +
        " IdBloques: " +
        this.state.IdBloques +
        " NombreBloque: " +
        this.state.NombreBloque
    );
    if (this.state.nombre.length > 0 && this.state.IdBloques.value > 0) {
      return false;
    } else {
      return true;
    }
  };

  changeBloque = (event) => {
    this.setState({ IdBloques: { value: event.value, label: event.label } });
    for (let item of this.props.stor.bloques_object) {
      console.log("item: " + item.id + " target: " + event.value);
      if (item.id === event.value) {
        this.setState({ NombreBloque: item.nombre });
        this.funcionalidadesObject.bloque = item;
        //console.log("igual item: " + item.id + " = " + event.target.value);
        break;
      }
    }
  };
  changeLabel = (e) => {
    this.setState({ label: e.target.value });
    this.funcionalidadesObject.label = e.target.value;
  };

  changeName = (event) => {
    this.setState({ nombre: event.target.value });
    this.funcionalidadesObject.nombre = event.target.value;
  };
  guardarFuncionalidad = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", this.funcionalidadesObject);
  }
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", this.funcionalidadesObject);
  }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>{this.props.elementos.nombre}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeName}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="label">
                  <Form.Input
                    name="lbl"
                    type="text"
                    value={this.state.label}
                    onChange={this.changeLabel}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Form.Group label="Bloques">
              <Select
                value={this.state.IdBloques}
                onChange={this.changeBloque}
                options={this.bloque}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarFuncionalidad}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default FuncionalidadesForm;
