import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid } from "tabler-react";
import Select from "react-select";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { PersonasForm } from "../../../Catalogos";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: 0,
      persona: { value: 0, label: "Selecione una persona", data: null },
      username: "",
      password: "",
      Cpassword: "",
      msmPAsss: "",
      estatus: 0,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      newPassword: false,
      crudbutonEnable: true, // Se desabilita cuando se hace click
      idPlataforma: { value: 61, label: "Default", data: { id: 61 } },
      hiddenModal: false,
    };
    this.usuarioObject = [];
    this.personas = [];
    this.plataformas = [];
  }
  isEnable = (Accion) => {
    let borrar = "usuarios_borrar";
    let actualizar = "usuarios_editar";
    let agregar = "usuarios_agregar";

    let actualizarPersona = "personas_editar";
    let agregarPersona = "personas_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "actualizarPersona") {
        if (privilegios[i].clave == actualizarPersona) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarPersona") {
        if (privilegios[i].clave == agregarPersona) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  componentDidMount = () => {
    this.plataformas.push({ value: 61, label: "Default", data: { id: 61 } });
    currentUser.map((elemento, index) => {
      this.plataformas.push({
        value: elemento.plataforma.id,
        label: elemento.plataforma.nombre,
        data: elemento.plataforma,
      });
    });
    if (this.state.accion !== "nuevo") {
      let p = this.props.elementos.persona;
      this.personas.push({
        value: p.id,
        label: p["nombre"] + " " + p["aPaterno"] + " " + p["aMaterno"],
        data: p,
      });
      this.setState({
        persona: {
          value: p.id,
          label: p["nombre"] + " " + p["aPaterno"] + " " + p["aMaterno"],
          data: p,
        },
      });
      this.setState({ id: this.props.elementos.id });
      this.setState({ username: this.props.elementos.username });
      this.setState({ password: this.props.elementos.password });
      this.setState({ estatus: this.props.elementos.estatus });
      this.usuarioObject = this.props.elementos;
    } else {
      this.props.stor.personas_object.map((p, index) => {
        this.personas.push({
          value: p.id,
          label: p["nombre"] + " " + p["aPaterno"] + " " + p["aMaterno"],
          data: p,
        });
      });
      this.usuarioObject = {
        username: "",
        password: "",
        estatus: 0,
        persona: {},
      };
    }
  };
  changePlataformas = (event) => {
    this.setState({ idPlataforma: event });
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  changePersona = (event) => {
    this.setState({ persona: event });
    this.usuarioObject.persona = event.data;
  };
  changeUsername = (event) => {
    this.usuarioObject.username = event.target.value;
    this.setState({ username: event.target.value });
  };
  changePassword = (event) => {
    this.usuarioObject.password = event.target.value;
    this.setState({ password: event.target.value });
    if (
      this.state.Cpassword !== event.target.value &&
      this.state.Cpassword !== ""
    ) {
    }
  };
  changeCpassword = (event) => {
    if (
      this.state.password !== event.target.value &&
      this.state.password !== ""
    ) {
    }
    this.setState({ Cpassword: event.target.value });
  };
  newPassword = (event) => {
    this.setState({ newPassword: !this.state.newPassword });
    if (this.state.newPassword) {
      this.setState({ password: this.props.elementos.password });
    } else {
      this.setState({ password: "" });
      this.setState({ Cpassword: "" });
    }
  };
  changeEstatus = (event) => {
    this.state.estatus === 0
      ? this.setState({ estatus: 1 })
      : this.setState({ estatus: 0 });
    this.state.estatus === 0
      ? (this.usuarioObject.estatus = 1)
      : (this.usuarioObject.estatus = 0);
  };
  guardarUsuario = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.props.getfuntion("delete", "", this.state.id);
    this.setState({ crudbutonEnable: false });
  }
  crud_put() {
    this.props.getfuntion("put", this.usuarioObject);
    this.setState({ crudbutonEnable: false });
  }
  crud_post() {
    this.props.getfuntion("post", this.usuarioObject);
    this.setState({ crudbutonEnable: false });
  }
  crud_putPassword() {
    //this.props.getfuntion("put", "", this.usuarioObject, "plataformasUsuariosPerfiles");
    this.props.getfuntion(
      "put",
      this.usuarioObject,
      "",
      this.usuarioObject,
      "usuarios/password"
    );
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }; //Solo personas
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas/plataforma/" + this.state.idPlataforma.value,
    stateVar = "personas",
    hiddenModl = this.hiddenmodal()
  ) => {
    return Crud_Catalogos(
      metodo !== "post" && catalogo.includes("personas")
        ? "personas"
        : catalogo,
      "tracking",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            break;
          case "post":
            {
              if (stateVar === "personas") {
                if (returnVal.id) {
                  this.personas.unshift({
                    value: returnVal.id,
                    label:
                      returnVal["nombre"] +
                      " " +
                      returnVal["aPaterno"] +
                      " " +
                      returnVal["aMaterno"],
                    data: returnVal,
                  });
                  this.usuarioObject.persona = returnVal;
                  this.setState({
                    persona: {
                      value: returnVal.id,
                      label:
                        returnVal["nombre"] +
                        " " +
                        returnVal["aPaterno"] +
                        " " +
                        returnVal["aMaterno"],
                      data: returnVal,
                    },
                  });
                }
              }
            }
            break;
          case "put":
            {
              if (stateVar === "personas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se han agregado las modificaciones de ( " +
                      returnVal.nombre +
                      " ), al catlogo general",
                    "Modificación"
                  );
                  this.personas.map((p, index) => {
                    if (returnVal.id === p.id) {
                      this.personas[index] = returnVal;
                    }
                  });
                  this.setState({
                    persona: {
                      value: returnVal.id,
                      label:
                        returnVal["nombre"] +
                        " " +
                        returnVal["aPaterno"] +
                        " " +
                        returnVal["aMaterno"],
                      data: returnVal,
                    },
                  });
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div>
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="De la plataforma se toma la plantilla para validación de correo">
                  <Select
                    value={this.state.idPlataforma}
                    onChange={this.changePlataformas}
                    options={this.plataformas}
                  />
                </Form.Group>
              </Grid.Col>
              {/*
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input name="id" disabled type="number" value={this.state.id} />
                </Form.Group>
              </Grid.Col> */}
              <Grid.Col>
                <Form.Group label="Persona">
                  <Select
                    value={this.state.persona}
                    onChange={this.changePersona}
                    options={this.personas}
                  />
                </Form.Group>
              </Grid.Col>
              {this.state.idPlataforma.value > 0 &&
              (this.isEnable("actualizarPersona") ||
                this.isEnable("agregarPersona")) ? (
                <Grid.Col md={2} lg={2}>
                  {this.isEnable("agregarPersona") ? (
                    <span className="float-right">
                      <ModalLay
                        tamanio="85%"
                        title={"Nuevo"}
                        name={
                          <span
                            className="fa fa-plus ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={
                              "Nueva persona(Antes de crear  valida que no exista)"
                            }
                          ></span>
                        }
                        formulario={PersonasForm}
                        datosForm={{
                          accion: "nuevo",
                          plataforma: this.state.idPlataforma.data,
                          elementos: this.state.persona.data,
                          getfuntion: this.getfuntion,
                          stor: this.props.stor,
                        }}
                      />
                    </span>
                  ) : null}
                  {this.isEnable("actualizarPersona") ? (
                    <span className="float-right">
                      {this.state.persona.value > 0 ? (
                        <ModalLay
                          tamanio="85%"
                          title="Modificar"
                          name={
                            <span
                              className="fa fa-edit"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={
                                "Modificar (" + this.state.persona.label + ")"
                              }
                            ></span>
                          }
                          formulario={PersonasForm}
                          datosForm={{
                            accion: "modificar",
                            plataforma: this.state.idPlataforma.data,
                            elementos: this.state.persona.data,
                            getfuntion: this.getfuntion,
                            stor: this.props.stor,
                          }}
                        />
                      ) : null}
                    </span>
                  ) : null}
                </Grid.Col>
              ) : null}
            </Grid.Row>
            <Grid.Row>
              <Grid.Col
                md={this.state.accion === "modificar" ? 3 : 4}
                lg={this.state.accion === "modificar" ? 3 : 4}
              >
                <Form.Group label="username">
                  <Form.Input
                    name="username"
                    type="text"
                    value={this.state.username}
                    onChange={this.changeUsername}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col
                md={this.state.accion === "modificar" ? 3 : 4}
                lg={this.state.accion === "modificar" ? 3 : 4}
              >
                <Form.Group label="password">
                  <Form.Input
                    name="passwrod"
                    type="password"
                    disabled={
                      this.state.accion !== "nuevo" && !this.state.newPassword
                    }
                    value={this.state.password}
                    onChange={this.changePassword}
                  />
                </Form.Group>
              </Grid.Col>
              {this.state.newPassword ? (
                <Grid.Col
                  md={this.state.accion === "modificar" ? 3 : 4}
                  lg={this.state.accion === "modificar" ? 3 : 4}
                >
                  <Form.Group label="confirmar password">
                    <Form.Input
                      name="passwrod"
                      type="password"
                      disabled={
                        this.state.accion !== "nuevo" && !this.state.newPassword
                      }
                      value={this.state.Cpassword}
                      onChange={this.changeCpassword}
                    />
                  </Form.Group>
                </Grid.Col>
              ) : null}
              {this.state.accion === "modificar" ? (
                <Table.Col>
                  <Form.Group>
                    <Form.Switch
                      name="estatus"
                      label="Reestablecer contraseña"
                      value={this.state.newPassword}
                      checked={this.state.newPassword}
                      onChange={this.newPassword}
                    />
                  </Form.Group>
                  {this.state.newPassword ? (
                    <Button
                      target="_blank"
                      size="sm"
                      RootComponent="a"
                      color="primary"
                      disabled={
                        this.state.Cpassword === this.state.password &&
                        this.state.password.length > 0 &&
                        this.state.Cpassword.length > 0
                          ? false
                          : true
                      }
                      onClick={() => this.crud_putPassword()}
                    >
                      <span class="badge">
                        <Icon link={true} name="refresh-ccw" />
                        Actualizar
                      </span>
                    </Button>
                  ) : null}
                </Table.Col>
              ) : null}
            </Grid.Row>
          </Grid.Col>
          <Form.Group>
            <Form.Switch
              name="estatus"
              label="Activo"
              value={this.state.estatus}
              checked={this.state.estatus}
              onChange={this.changeEstatus}
            />
          </Form.Group>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={
                  (this.state.persona.value > 0
                    ? this.state.username.length > 0
                      ? this.state.password.length > 0
                        ? !this.state.newPassword
                          ? false
                          : true
                        : true
                      : true
                    : true) || !this.state.crudbutonEnable
                }
                onClick={this.guardarUsuario}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Reporte;
