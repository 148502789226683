import React, { useState, useEffect, useRef} from "react";
import Select from "react-select";
import axios from 'axios';

//import FetchData from "./FetchData.react";
//import CheckForm from "./CheckForm.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';


export const MenuChatBot = ({ setLoading, setError, setData }) => {
	const idUsuario = JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id;
	const idPerfil = JSON.parse(sessionStorage.getItem("currentUser"))[0].perfil.id;


	//const [respuesta, setRespuesta] = useState([]);
	const [pregunta, setPregunta] = useState("");
	const [respuesta, setRespuesta] = useState("");
	const [conversacion, setConversacion] = useState("En este chat puede consultar dudas sobre La norma ISO-27001. ¿En qué podemos ayudarle?");

	const divRef = useRef(null);
	const chatParent = useRef(null);

	const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
		return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
		  //setTrackPoints({ returnVal });
		  switch (metodo) {
			case "get":
			  {
				switch (stateVar) {
	
				  default: return returnVal;
				}
				
			  }
		  }
		})
	  }

	useEffect(() => {

		
		setConversacion( "<div className='alert alert-dark w-100' role='alert'>" +  conversacion +"</div><p align='right'><b>"+pregunta+"</b><p/>"+respuesta);
		//divRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		//chatParent.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
		//const messagesContainer = ReactDOM.findDOMNode(this.messagesContainer);
		//messagesContainer.scrollTop = messagesContainer.scrollHeight;

	
	
	}, [respuesta]);

const changeTextBox = (e) => {
	setPregunta(e.target.value);
	
	 
}

		// const response1 =  fetch('https://api.giphy.com/v1/gifs/search?api_key=SGV8EtIpxB5q9yrD50aO2hCvRKGnjQFN&q=goku&limit=10');
	const handleSubmit = async () => {
		setLoading(true);
		// const _pregunta=pregunta+". Contesta en español. Contesta en menos de 20 palabras.";
        // //ChatBot con lamda:
		// const data11 = {
		// 	pregunta: {_pregunta},
		//   };
		//  const resp =  await  fetch("https://x8bnlc9gy7.execute-api.us-east-1.amazonaws.com/dev/elon", {
		//    headers: {
		//  	"Content-Type": "application/json",			
		//    },
		//    mode: 'cors',
		//    body: JSON.stringify(data11),
		//    llm: 'claude',
		//    method: "POST",
		//  }).catch(e => {
		//  	console.log(e);
		//  	return e;
		//    }
		//    ) ;
		//    const names = await resp.json();
		//    setRespuesta("<div class='alert alert-dark w-100' role='alert'>"+names.completion+"</div>");


		//ChatBot con RAGs
		// let pregunta_sin_espacios=pregunta.replaceAll(' ','_');
        // await getfuntion("get", [], "", "informes/chatbot/"+pregunta, "").then((returnVal) => {      
        //   	setRespuesta("<div class='alert alert-dark w-100' role='alert'>"+returnVal+"</div>");	 	 
        // });

        //ChatBot con Node.js
		//const _pregunta=pregunta+". Contesta en español. Contesta en menos de 20 palabras.";

		 const peticion =  await  fetch("http://34.203.226.33:3000/?message="+pregunta);
		//  .catch(e => {
		//  	console.log(e);
		//  	return e;
		//    }
		//    ) ;

		   // await peticion.then(resp => {
			peticion.json().then(data =>{
					    data.sort((a, b) => b.score - a.score).map( (res1,index) => index==3?setRespuesta("<div class='alert alert-dark w-100' role='alert'>"+res1.respuesta+"</div>"+res1.score):null
					);
								setPregunta("");


				})
			// })
            // .catch(console.warn);  

		 //  const names = await resp;
		//    setRespuesta("<div class='alert alert-dark w-100' role='alert'>"+resp.body.respuesta+"</div>");

         //console.log(respuestas);


//   if (Array.isArray(respuestas)) {

// 		    await respuestas.json.map((respuesta) => (
// 		 	 console.log(respuesta.score)
// 	    ))
// 	}



	   setLoading(false);


		    
			//const names = await resp.json();

			

			window.scrollTo({
				top: document.documentElement.scrollHeight,
				behavior: 'smooth',
			  });

			//setPregunta("");


	  };
	




	return (
		<div >
		 
		 <div>
		 <div  dangerouslySetInnerHTML={{ __html: conversacion }} />

     

      {/* <button type="submit">Enviar</button> */}
    
    {/* {  <p>Respuesta: {respuesta}</p>} */}
  </div>
		

			<div align='right' className="row mt-3">
				<div className="col text-center">
				<p align='right'>Pregunta:
                    <textarea name="pregunta"  rows="4" cols="50" onChange={changeTextBox}
	                  value={pregunta}/>
	            </p>
					<button className="btn btn-info" onClick={handleSubmit}>
						Buscar
					</button>
				</div>
			</div>
		</div>
	);
};
