import React, { useState, useEffect } from "react";
import Select from "react-select";
import Chart from "react-apexcharts";
import { TablePageable } from "../../Helpers";
import { DateRange, DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import moment from "moment";
import { parse } from "date-fns";
import { LoadingScreen } from "../../components/LoadingScreen";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import Timer from "../../herramientas/Time/setTimer";
import language from "react-syntax-highlighter/dist/esm/languages/hljs/1c";
import MonitoreoOTD from "../../socket/MonitoreoOTD";
import CollapsibleTable from "./components/tableCollapsed"
import { forEach } from "jszip";
import clsx from "clsx";
import { C } from "react-native-jssip";


const ContentOtd = () => {
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais
  const [otdList, setOtdList] = useState([]);
  const [flagUniversal,setFlagUniversal] = useState(false);
  const [otdListFiltrados, setotdListFiltrados] = useState([]);
  const [otdListFiltradosTemp, setotdListFiltradosTemp] = useState([]);
  const [otdEstatusOpciones, setOtdEstatusOpciones]= useState([]);
  const [otdEstatusOpcion, setOtdEstatusOpcion] = useState();
  const [corredoresOpciones, setCorredoresOpciones]= useState([]);
  const [corredoresOpcion, setCorredoresOpcion] = useState();
  const [clientesOpciones, setClientesOpciones]= useState([]);
  const [clientesOpcion, setClientesOpcion] = useState([]);
  const [tipoMovimientoOpciones, setTipoMovimientosOpciones]= useState([]);
  const [tipoMovimientoOpcion, setTipoMovimientoOpcion] = useState();
  const [lineaTransportistaOpciones, setLineaTransportistasOpciones]= useState([]);
  const [lineaTransportistaOpcion, setLineaTransportistaOpcion] = useState();
  const [arrayFilters,setArrayFilters] = useState([{}]);
  //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(true);
  
  // Obtiene  mes, y dia  del año actual
  let dateNow = new Date();
  let startDate = `${dateNow.getFullYear()}-01-01`;
  
  const year = dateNow.toLocaleString("default", { year: "numeric" });
  const month = dateNow.toLocaleString("default", { month: "2-digit" });
  const day = dateNow.toLocaleString("default", { day: "2-digit" });

  // Genera yyyy-mm-dd date string
  let endDate = year + "-" + month + "-" + day;
  let id = JSON.parse(sessionStorage.getItem("plataforma")).id;

  const time = new Date();
  const topic = "/topic/Maersk-OTD"

 
  time.setHours(12);
  time.setMinutes(0);
  time.setSeconds(0);
  

  useEffect(() => {
    getfuntion("get", {"startDate":startDate,"endDate":endDate}, "", "movimientosOTD/otdsGps/" + id + "/" + startDate + "/" + endDate);
  }, []);

  const [dates, setDates] = useState([
    {
      startDate: moment(endDate).toDate(),
      endDate: moment(startDate).toDate(),
      key: 'selection'
    }
  ]);


  const formatDate = (date)=>{

    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Genera yyyy-mm-dd  string
    return year + "-" + month + "-" + day;

  }

  //Funcion de cambio en seleccion de fechas

  const handleDates = (range)=>{

  setisLoading(true);

  let startDate = formatDate(range.selection.startDate);

  let endDate = formatDate(range.selection.endDate)

  setDates([range.selection]);

  getfuntion("get",{"starDate":startDate,"endDate":endDate},"", "movimientosOTD/otdsGps/" + id +"/" + startDate + "/" +endDate);
    

  }


  const updateList = (newMovs)=>{
    console.log("update")
    console.log(newMovs)
    
    const newMovs2 =  [...newMovs];

    filtroDeFiltros(newMovs2)
    filtro(newMovs2)
    setOtdList(newMovs2);
    
    

  }

  

  
 //Se realiza la consulta la primera vez.
  useEffect(() => {
    filtro()
  }, 
    [ 
      
      otdList,
      
    ]);

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      endPoint,
      "",
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        console.log("get function")
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal) {
                console.log(returnVal)
                
                filtroDeFiltros(returnVal);
                filtro(returnVal);
                setOtdList(returnVal);
                
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };
  const filtroDeFiltros=(dataOtdLisTemp)=>{
    console.log("otd",dataOtdLisTemp)
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];
    let otdEstatus = {};
    let plataformas = {};
    let tipoMovimiento = {};
    let corredores = {};
    let clientes = {};
    let lineaTransportista={};
    
    console.log("paso")
    
    otdTemp.map((otd, index) => {
  

      //|otd_estatus
      if (!otdEstatus[otd?.status]) otdEstatus[otd?.status] = [];
        otdEstatus[otd?.status].push(otd);


      //tipoMovimiento
      // if (!tipoMovimiento[otd["tipoMovimiento"]]) tipoMovimiento[otd["tipoMovimiento"]] = [];
      // tipoMovimiento[otd["tipoMovimiento"]].push(otd);

      // // corredor
      // if (!corredores[otd["corredor"]]) corredores[otd["corredor"]] = [];
      // corredores[otd["corredor"]].push(otd);


      // //cliente
      // if (!clientes[otd["cliente"]]) clientes[otd["cliente"]] = [];
      // clientes[otd["cliente"]].push(otd);

      //line transportista
      if(otd){
        if (!lineaTransportista[otd["idTransportista"]]) lineaTransportista[otd["idTransportista"]] = [];
        lineaTransportista[otd["idTransportista"]].push(otd);
      }
      
    });

    console.log("termno forrrrrrrrrrrr")
    //creando headers
    let otdEstatusOpcionesTemp = [];
    let tipoMovimientoOpcionesTemp = [];
    let corredoresOpcionesTemp = [];
    let lineaTransportistasOpcionesTemp = [];
    let clientesOpcionesTemp = [];

    Object.keys(lineaTransportista) .forEach((key) => lineaTransportistasOpcionesTemp.push({ value: key, label: key}));
    Object.keys(otdEstatus)         .forEach((key) => otdEstatusOpcionesTemp.push({ value: key, label: key}));

    console.log("termino keys")
   


    setLineaTransportistasOpciones(lineaTransportistasOpcionesTemp);
    console.log("salioooooo")
    console.log(lineaTransportistasOpcionesTemp)
    setOtdEstatusOpciones(otdEstatusOpcionesTemp);
    console.log("opcionessss")
    //setTipoMovimientosOpciones(tipoMovimientoOpcionesTemp);
    //setCorredoresOpciones(corredoresOpcionesTemp);
    //setClientesOpciones(clientesOpcionesTemp);

    console.log("termino setetettetetete")
  }

const handleChangeOptions = (event=null,data=null)=>{

    let arrayFiltersTemp;
    let selectOption;

    console.log(data ? data.length : null)

    if((event&& event.action === "select-option")){
      selectOption = event.name;
      arrayFiltersTemp = [...arrayFilters,{"name": selectOption,"value": event.option.value}];
    }

    if((event && event.action === "remove-value")){
      arrayFiltersTemp = arrayFilters;
      let index = arrayFilters.findIndex((item)=> item.value === event.removedValue.value)
      if(index > 0)arrayFiltersTemp.splice(index, 1);

    }

    if(!arrayFiltersTemp){
      arrayFiltersTemp = [...arrayFilters]
    }

    

    console.log(arrayFiltersTemp);

    let otdTemp  = data ? data: [...otdList];
    

    arrayFiltersTemp.forEach((filter)=>{
   
      console.log(filter.name,otdTemp)
      {switch(filter.name){
      
        case "cliente":{
        
          console.log("entro cliente",otdTemp.length)
            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
             
                if(opcion.value === o.cliente ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
            console.log("sale cliente",otdTemp)
           break; 
        }
        case "estatus":{
            console.log("status",otdTemp.length)

            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
                if(opcion.value === o.status ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
         
          break;
        }
          
        case "corredor":{
          console.log("corredor")
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.corredor ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
          break;
        }
                
        case "movimiento":{
          console.log("Movimiento")
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.tipoMovimiento ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }

        case "idTransportista":{
          console.log("transportista")
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            console.log(o.tipoMovimiento)
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.idTransportista ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }
      }
    }

    })
    

    setArrayFilters(arrayFiltersTemp)
    setotdListFiltrados(otdTemp)
  

}

  const filtro=(dataOtdLisTemp)=>{
    
    console.log(dataOtdLisTemp)
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];
    let opciones = [...lineaTransportistaOpciones]
    let data = [];
    console.log(opciones)

    console.log("first")

    console.log(otdTemp)

//     if(opciones[0] && opciones[0].idTransportista == null){
// return;
//     }

   
let total = otdTemp.length;
    for( let p of opciones ){
   
    let structurDataOTD = {
        "transportista":null,
        "clientes":[],
        "delay":0,
        "ontime":0,
        "totalMov":0,
        "sin_calcular":0,
        "porcent":100,
        "history":[]
  
      }


   
      
      let  newData = (otdTemp.filter( g => {
     
        if(g.idTransportista === p.label){
          structurDataOTD.transportista = p.label;
          structurDataOTD.totalMov += 1;
          if(!structurDataOTD.clientes.includes(g.cliente)){
            structurDataOTD.clientes.push(g.cliente);
          }
          
          if(g.status == "DELAY") { 
            
            structurDataOTD.delay += 1;
           
           }
          if(g.status == "ONTIME"){
            structurDataOTD.ontime += 1;
            
          } 
          if(g.status == "SIN_CALCULAR") {
            structurDataOTD.sin_calcular += 1;
          }

          structurDataOTD.history.push(g)
          
         
        }

        
        
        
      }));
      
      data.push(structurDataOTD);
      
     
    }

   console.log(data);

   for (let index = 0; index < data.length; index++) {
    
    data[index].porcent = (100 * data[index].ontime) / data[index].totalMov;
      
    
   }

    setotdListFiltrados(data);
    //return otdTemp;
  }
  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  const getGraficaOtdEstatus = () => {
    let otdTemp= [...otdList];
    let labels = [];
    let data = new Array();
    let index = 0;

    console.log("otdestatus")
    console.log(otdEstatusOpciones)
    console.log(otdTemp)
    
    for( let gEstatus of otdEstatusOpciones ){
      labels.push(gEstatus.label);
      // otdTemp.forEach(element => {
      //   element.history.forEach(mov => {
      //     if(mov.status == gEstatus.label){
      //       if(data[index]){
      //         data[index] += 1;
      //       }else{
      //         data[index] = 1;
      //       }
             
      //     }
          
          
      //   });
      //  // 
      // });
      // index++;

      data.push(otdTemp.filter( g => g.status === gEstatus.label).length);
      
    }

    console.log(data)

    

   
    return { labels, data };
  }
  const getGraficaTipoMovimiento = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of tipoMovimientoOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.tipoMovimiento === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaCorredores = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of corredoresOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.corredor === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaClientes = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of clientesOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.cliente === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  
  const reset =()=>{
    setOtdEstatusOpcion([]);
    setCorredoresOpcion([]);
    setClientesOpcion([]);
    setTipoMovimientoOpcion([]);
    setArrayFilters([{}])
  }

  return (
    <div className="container-fluit">
      <div className="row justify-content-center" style={{ margin: "16px" }}>
        <h1>Dashboard OTD </h1>
      
      </div>
      <div className="row row--filters" >
        {/* <div class="filter col-12">
          <div className="filter--form col-6">
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Estatus</label>
                <Select name="estatus" isMulti value={otdEstatusOpcion} onChange={(e,a)=>{ setOtdEstatusOpcion(e); handleChangeOptions(a); }} options={otdEstatusOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Corredor</label>
                <Select isMulti name="corredor" value={corredoresOpcion} onChange={(e,a)=>{ setCorredoresOpcion(e); handleChangeOptions(a); }} options={corredoresOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Cliente</label>
                <Select isMulti name="cliente" value={clientesOpcion} onChange={(e,a)=>{ setClientesOpcion(e);  handleChangeOptions(a) }} options={clientesOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Tipo de Movimiento</label>
                <Select  isMulti name="movimiento" value={tipoMovimientoOpcion} onChange={(e,a)=>{ setTipoMovimientoOpcion(e); handleChangeOptions(a); }} options={tipoMovimientoOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Linea Transportista</label>
                <Select  isMulti name="idTransportista" value={lineaTransportistaOpcion} onChange={(e,a)=>{ setLineaTransportistaOpcion(e); handleChangeOptions(a); }} options={lineaTransportistaOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="reset">Restablecer valores</label>
                <button onClick={()=> reset()}  className="btn-primary btn d-block"><i class="fe fe-refresh-ccw"></i></button>
              </div>
            </div>

          </div>
          <div className="col-6 filter--calendar">
            <div className="row">
              <div className="col-12 d-c">
              <DateRange
               startDatePlaceholder={parse(startDate, "yyyy-MM-dd", new Date())}
                endDatePlaceholder={parse(endDate, "yyy-MM-dd", new Date())}
                dateDisplayFormat={"yyyy-MM-dd"}
                editableDateInputs={true}
                dragSelectionEnabled={true}
                showDateDisplay={true}
                locale={es}
                showSelectionPreview={true}
                onChange={handleDates}
                months={1}
                ranges={dates}
                direction="horizontal"
              />
              </div>
            </div>
          </div>
         </div>
         <div className="col-12">
                <span>
                  <h3 className="text-center">Total {otdListFiltrados.length}</h3>
                </span>
         </div>         */}
        </div>

      
      { otdListFiltrados.length &&
        <div className=" row--grafs--panel">
          <div className="col-12 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Estatus
                </h4>
              </div>
              <div className="card-body text-success">
                { otdListFiltrados.length ?
                  <Chart
                    series= {getGraficaOtdEstatus().data}
                  options= {{
                    chart: {
                      width: 3000,
                      type: 'donut',
                      animations:{
                        enabled:false
                      },
                    },
                    labels:getGraficaOtdEstatus().labels,
                    plotOptions: {
                      pie: {
                        startAngle: -90,
                        endAngle: 270
                      }
                    },  
                    animations:{
                      enabled:false
                    },            
                    dataLabels: {
                      enabled: true
                    },
                    fill: {
                      type: 'gradient',
                    },
                    legend: {
                      formatter: function(val, opts) {
                        return getGraficaOtdEstatus().labels[opts.seriesIndex]+" - "+ opts.w.globals.seriesTotals[opts.seriesIndex]
                      }
                    },
                    title: {
                      text: 'Estados del Movimiento'
                    }
                  }}
                    type="donut" width={"100%"} height={"auto"}/>
                :null}
              </div>
            </div>
          </div>
          {/* <div className="col-6 otd-heigth">
            <div className="card border-primary ">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Clientes
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      },
                      animations:{
                        enabled:false
                      }
                    },
                    xaxis: {
                      categories: getGraficaClientes().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Otd", data: getGraficaClientes().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"auto"}
                />
                :null}
              </div>
            </div>
          </div> */}
          {/* <div className="col-6 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Corredores
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                <Chart
                  options={{
                    chart: {

                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      },
                      animations:{
                        enabled:false
                      }
                    },
                    xaxis: {
                      categories: getGraficaCorredores().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Otd", data: getGraficaCorredores().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"auto"}
                />
                :null}
              </div>
            </div>
          </div> */}
          {/* <div className="col-6 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Tipo de Movimientos
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                  <Chart
                    series= {getGraficaTipoMovimiento().data}
                    options= {{
                      chart: {
                        width: 380,
                        type: 'pie',
                        animations:{
                          enabled:false
                        },
                      },
                      labels:getGraficaTipoMovimiento().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      }
                    }}
                    type="pie" width={"90%"} height={"auto"} />
                :null}
              </div>
            </div>
          </div> */}
        </div>
      }
      <hr />
      <div className="row otd-container" hidden={!otdListFiltrados.length}>
{/* 
 "corredor":null,
        "clientes":[],
        "delay":0,
        "ontime":0,
        "totalMov":0,
        "sin_calcular":0,
        "porcent":100,
      1 ? element.arriboTerminalVacio : element.arriboClienteVacio */}
        <div className="col-12 table-collapsed">
          {/* <TablePageable
            lista={otdListFiltrados}
            columnas={
              [
                // { columna: "mes", label: "mes" },
                { columna: "corredor", label: "Corredor" },
                // { columna: "tipoMovimiento", label: "Tipo de Movimiento" },
                // { columna: "booking", label: "Booking" },
                // { columna: "po", label: "Wo" },
                // { columna: "contenedorSize", label: "ContenedorSize" },
                // { columna: "contenedor", label: "Contenedor" },
                { columna: "clientes", label: "Cliente" },
                // { columna: "direccion", label: "Direcciòn Cliente" },
                // { columna: "idTransportista", label: "Transportista" },
                // { columna: "citaProgramada", label: "Cita Programada" },
                // { columna: "fechaArribo", label: "Fecha de Arribo" },
                //{ columna: "salidaCLienteLleno", label: "Salida Cliente Lleno" },
                { columna: "delay", label: "Retrasados" },
                { columna: "ontime", label: "En tiempo" },
                { columna: "sin_calcular", label: "Sin cita" },
                { columna: "porcent", label: "Porcentage" },
                // { columna: "citaProgramada" , label:"faltan" ,
                  
                //   form: (e)=>
                //     <Timer date={e.citaProgramada} />}

              ]
            }
            id="idMovimiento"
            selected={() => { }}
            titulo={<h3> Listado de Movimientos </h3>}
            csvname="Movimientos"
            paginacion={true}
            elementosPorPagina={5}

          /> */}
            {console.log(otdList)}
          {otdListFiltrados.length > 0 ?
          <CollapsibleTable data={otdListFiltrados} heads={["Transportista","Retrasados","En tiempo","Total","Porcentage"]} ></CollapsibleTable>
         : null }
          { otdList.length ?
            <MonitoreoOTD
              topic={topic}
              otd={otdList}
              movsUpdate={updateList}
            />
       : null}     
        </div>
      </div>
      {isloading ? (
				<LoadingScreen
					loading={isloading}
				/>
			):null}
    </div>

    
  );
};

export default ContentOtd;