import React, { useEffect, useState, useContext } from "react";
import {
  Badge,
  Button,
  Container,
  Image,
  ListGroup,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { softphoneContext } from "../../context/softphoneContext";
import classes from "./ContactosModal.module.css";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";

const ContactosModal = ({ show, handleCloseModal, handleOKButton }) => {
  const softphone = useContext(softphoneContext);
  const grupo = softphone.getValues().grupo;
  const [usuariosFormateados, setUsuariosFormateados] = useState();

  //Use Effect para estilos del input de banderas
  useEffect(() => {
    getfuntion(
      "get",
      "",
      "",
      `softphone/grupo_contactos/${grupo}`,
      "grupo_contactos"
    );
  }, []);

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "usuario",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            setUsuariosFormateados(
              returnVal.map((usuario) => {
                return {
                  id: usuario.id,
                  value: usuario.username,
                  label: `${usuario.username} / ${usuario.extension}`,
                  extension: usuario.extension,
                  nombre: `${usuario.persona.nombre} ${
                    usuario.persona.aPaterno
                  } ${usuario.persona.aMaterno ?? ""}`,
                };
              })
            );
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNumberInput = () => {
    handleOKButton();
  };

  if (!usuariosFormateados) {
    return <ProgressBar animated />;
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Lista de contactos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <ListGroup as="ol" numbered>
            {usuariosFormateados.map((usuario) => (
              <ListGroup.Item
                as="li"
                key={`contacto-${usuario.id}`}
                className={`d-flex justify-content-between align-items-start ${classes.tarjeta}`}
                onClick={() => handleOKButton(usuario.extension)}
              >
                <div className="ms-1 me-auto" style={{ width: "100%" }}>
                  <div className="fw-bold">
                    <h5>{usuario.nombre}</h5>
                  </div>
                  <h6 className="text-secondary font-italic">
                    {usuario.value}
                  </h6>
                </div>
                <Badge bg="dark" pill>
                  {usuario.extension}
                </Badge>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactosModal;
