import React, { Component } from "react";
import { Card, Button, Form, Icon, Grid, List, Table } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";
import { timeToDateFill } from "../../herramientas/DateFormat";
import { ArrobaIncidencia } from "./Arroba";
let reader = new FileReader();
let upImage = null;
/**
 * usuario
 */
const user = JSON.parse(sessionStorage.getItem("usuario"));
class MovimientoIncidenciasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      incidencias: {
        value: 0,
        label: "Selecciona  tipo de incidencia",
        data: null,
      },
      incidenciasPlataforma: [],
      comentario: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
      latitud: "",
      longitud: "",
      enviar: false,
      destinosClientes: [],
      destinosTransportistas: [],
      destinosMonitoreo: [],
      listarIncidencias: [],
      whatsAppGrupos: [],
      contactosCorreo: "",
      contactosWhatsApp: "",
    };
    this.enableSave = this.enableSave.bind(this);
    this.movimientoIncidencia = [];
    this.privilegio = [];
    this.sevenIncidencias = [];
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: "",
    };
  }
  componentDidMount = () => {
    upImage = null;
    this.getfuntion(
      "get",
      [],
      "",
      "incidencias/idplataforma/" + this.props.movimiento.idPlataforma.id,
      "incidenciasAll"
    );
    if (this.state.accion === "nuevo") {
      let latitud = "";
      let longitud = "";
      if (this.props.latitud) {
        latitud = this.props.latitud;
      } else {
        latitud = this.props.gpsReciente ? this.props.gpsReciente.latitud : "";
      }
      if (this.props.longitud) {
        longitud = this.props.longitud;
      } else {
        longitud = this.props.gpsReciente
          ? this.props.gpsReciente.longitud
          : "";
      }
      this.movimientoIncidencia = {
        idMovimiento: this.props.idmovimiento,
        comentario: "",
        fechaCreado: null,
        atencionUsuario: this.props.idusuario,
        atencionFecha: null,
        latitud: latitud,
        longitud: longitud,
        destinosMonitoreo: [],
        destinosClientes: [],
        destinosTransportistas: [],
        incidencia: {},
      };
      this.setState({ latitud: latitud });
      this.setState({ longitud: longitud });
    } else {
      this.setState({ incidencias: this.props.elementos.incidencias });
      this.setState({ comentario: this.props.elementos.comentario });
    }
  };

  enableSave = () => {
    if (this.state.incidencias.value > 0) {
      return false;
    } else {
      return true;
    }
  };
  guardarIncidencia = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.props.getfuntion("delete", "", this.props.elementos.id);
  }
  crud_put() {
    this.props.getfuntion("put", this.movimientoIncidencia);
  }
  crud_post() {
    this.movimientoIncidencia.comentario = this.state.comentario;
    this.movimientoIncidencia.incidencia = this.state.incidencias.data;
    //Crea la incidencia y luego envia  correo si asi de sea
    this.getfuntion(
      "post",
      this.movimientoIncidencia,
      "",
      "movimientoIncidencias",
      "incidencias",
      this.props.closeModal,
      this.state.enviar
    );
  }

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    enviar
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "destinosClientes") { console.log("destinosClientes", returnVal);
              let contactosCorreo= this.state.contactosCorreo;
              let contactosWhatsApp= this.state.contactosWhatsApp;
              for(let p of returnVal){
                contactosCorreo=
                  contactosCorreo + (contactosCorreo.length> 0? ", ": "") + 
                  "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.correo+">";
                  if( p.whatsappEstatus && p.telefono && p.telefono.length === 10){
                    contactosWhatsApp=
                      contactosWhatsApp + (contactosWhatsApp.length> 0? ", ": "") + 
                      "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.telefono+">'";
                  }
              }
              this.setState({
                contactosCorreo: contactosCorreo,
                contactosWhatsApp: contactosWhatsApp
              });
              this.setState({ destinosClientes: returnVal });
              //this.movimientoIncidencia.destinosClientes = returnVal;
              return returnVal;
            }
            if (stateVar === "destinosTransportistas") { console.log("destinosTransportistas", returnVal);
              let contactosCorreo= this.state.contactosCorreo;
              let contactosWhatsApp= this.state.contactosWhatsApp;
              for(let p of returnVal){
                contactosCorreo=
                  contactosCorreo + (contactosCorreo.length> 0? ", ": "") + 
                  "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.correo+">";
                  if( p.whatsappEstatus && p.telefono && p.telefono.length === 10){
                    contactosWhatsApp=
                      contactosWhatsApp + (contactosWhatsApp.length> 0? ", ": "") + 
                      "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.telefono+">'";
                  }
              }
              this.setState({
                contactosCorreo: contactosCorreo,
                contactosWhatsApp: contactosWhatsApp
              });
              this.setState({ destinosTransportistas: returnVal });
              //this.movimientoIncidencia.destinosTransportistas = returnVal;
              return returnVal;
            }
            if (stateVar === "destinosMonitoreo") { console.log("destinosMonitoreo", returnVal);
              let contactosCorreo= this.state.contactosCorreo;
              let contactosWhatsApp= this.state.contactosWhatsApp;
              for(let p of returnVal){
                contactosCorreo=
                  contactosCorreo + (contactosCorreo.length> 0? ", ": "") + 
                  "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.correo+">";
                  if( p.whatsappEstatus && p.telefono && p.telefono.length === 10){
                    contactosWhatsApp=
                      contactosWhatsApp + (contactosWhatsApp.length> 0? ", ": "") + 
                      "'"+p.nombre + (p.aPaterno? " "+p.aPaterno: "") + (p.aMaterno? " "+p.aMaterno: "")+"' <"+p.telefono+">'";
                  }
              }
              this.setState({
                contactosCorreo: contactosCorreo,
                contactosWhatsApp: contactosWhatsApp
              });
              this.setState({ destinosMonitoreo: returnVal });
              //this.movimientoIncidencia.destinosMonitoreo = returnVal;
              return returnVal;
            }
            if (stateVar === "whatsAppGrupos") { console.log("destinosMonitoreo", returnVal);
              let contactosWhatsApp= this.state.contactosWhatsApp;
              for(let wg of returnVal){
                contactosWhatsApp= contactosWhatsApp + (contactosWhatsApp.length> 0? ", ": "") + wg.nombreGrupo;
              }
              this.setState({ contactosWhatsApp: contactosWhatsApp });
              this.setState({ whatsAppGrupos: returnVal });
              return returnVal;
            }
            if (stateVar === "listarIncidencias") {
              this.setState({ listarIncidencias: returnVal });
            }

            if (stateVar === "incidenciasAll") {
              let incidenciasTemp = [];
              returnVal.map((inc, index) => {
                incidenciasTemp.push({
                  value: inc.id,
                  label: inc.nombre,
                  data: inc,
                });
              });
              this.setState({ incidenciasPlataforma: incidenciasTemp });
            }
            break;
          case "post":
            {
              if (stateVar === "incidencias") {
                let destMonitoreo = "";
                let destTransportistas = "";
                let destClientes = "";
                this.state.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo + ", " + key.correo;
                });
                this.state.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas + ", " + key.correo;
                });
                this.state.destinosClientes.forEach((key, index) => {
                  destClientes = destClientes + ", " + key.correo;
                });
                this.usuariosLogObject.idBitacoraMonitoreo = 2;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = this.props.movimiento.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = timeToDateFill(
                  new Date().getTime()
                );
                this.usuariosLogObject.descripcion =
                  returnVal.incidencia.nombre;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                if (enviar && returnVal.id) {
                  ArrobaIncidencia(this.props.movimiento.idPlataforma.id, this.props.movimiento.id, returnVal.id, upImage, this.state.incidencias.data.idEtiqueta).then((arrobaInc) => {
                    this.getfuntion(
                      "get",
                      "",
                      "",
                      "movimientoAcumuladores/mov/" + this.props.movimiento.id,
                      "acumuladores"
                    );
                    NotificationManager.info(
                      arrobaInc,
                      "envio de correo incidencia"
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento =
                      this.props.movimiento.id;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = timeToDateFill(
                      new Date().getTime()
                    );
                    this.usuariosLogObject.descripcion =
                      "Notificación de incidencia(" +
                      returnVal.incidencia.nombre +
                      "): " +
                      destMonitoreo +
                      " " +
                      destTransportistas +
                      " " +
                      destClientes +
                      " " +
                      arrobaInc;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                }
                if (
                  this.props.movimiento.actualizarFull &&
                  this.props.movimientoFull && this.props.movimientoFull.id
                ) {
                  let incidenciaFull = {
                    atencionFecha: null,
                    atencionUsuario: obj.atencionUsuario,
                    comentario: obj.comentario,
                    fechaCreado: null,
                    idMovimiento: this.props.movimientoFull.id,
                    incidencia: obj.incidencia,
                    latitud: obj.latitud,
                    longitud: obj.longitud,
                  };
                  incidenciaFull = this.getfuntion(
                    "post",
                    incidenciaFull,
                    "",
                    "movimientoIncidencias",
                    "newIncidenciaFull",
                    "",
                    enviar
                  );
                }
              }
              if (stateVar === "newIncidenciaFull") {
                let destMonitoreo = "";
                let destTransportistas = "";
                let destClientes = "";
                this.state.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo + ", " + key.correo;
                });
                this.state.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas + ", " + key.correo;
                });
                this.state.destinosClientes.forEach((key, index) => {
                  destClientes = destClientes + ", " + key.correo;
                });
                NotificationManager.info(
                  returnVal.comentario,
                  "Nueva incidencia movFull (" + returnVal.idMovimiento + ")"
                );
                this.usuariosLogObject.idBitacoraMonitoreo = 2;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = this.props.movimientoFull.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = timeToDateFill(
                  new Date().getTime()
                );
                this.usuariosLogObject.descripcion =
                  returnVal.incidencia.nombre;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                //Envia Arroba y Nueva Incidencia Naviero
                if (enviar && returnVal.id && this.props.movimientoFull.id) {
                  ArrobaIncidencia(this.props.movimientoFull.idPlataforma.id, this.props.movimientoFull.id, returnVal.id, upImage, this.state.incidencias.data.idEtiqueta).then((arrobaInc) => {
                    NotificationManager.info(
                      arrobaInc,
                      "envio de correo incidencia"
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = timeToDateFill(
                      new Date().getTime()
                    );
                    this.usuariosLogObject.descripcion =
                      "Notificación de incidencia(" +
                      returnVal.incidencia.nombre +
                      "): " +
                      destMonitoreo +
                      " " +
                      destTransportistas +
                      " " +
                      destClientes +
                      " " +
                      arrobaInc;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log("Error desconocido ...." + err);
      });
  };
  changeIncidencias = async (event) => {
    this.setState({ incidencias: event });
    if (event.data.idEtiqueta) {
      await this.getfuntion(
        "get",
        [],
        "",
        "personas/destinatarios_clientes_incidencias/" +
          this.props.idmovimiento +
          "/" +
          event.data.idEtiqueta,
        "destinosClientes"
      );
      await this.getfuntion(
        "get",
        [],
        "",
        "personas/destinatarios_transportistas_incidencias/" +
          this.props.idmovimiento +
          "/" +
          event.data.idEtiqueta,
        "destinosTransportistas"
      );
      await this.getfuntion(
        "get",
        [],
        "",
        "personas/destinatarios_sky_naviera_incidencias/" +
          this.props.idmovimiento +
          "/" +
          event.data.idEtiqueta,
        "destinosMonitoreo"
      );
      await this.getfuntion(
        "get",
        [],
        "",
        "whatsapp_grupos/mov/" + this.props.idmovimiento+"/"+event.data.idEtiqueta,
        "whatsAppGrupos"
      );
      this.getfuntion(
        "get",
        [],
        "",
        "movimientoIncidencias/movimiento/" + this.props.idmovimiento,
        "listarIncidencias"
      );      
      this.setState({ enviar: true });
    } else {
      this.setState({ destinosClientes: [] });
      this.setState({ destinosTransportistas: [] });
      this.setState({ destinosMonitoreo: [] });
      this.setState({ listarIncidencias: [] });
      this.setState({ enviar: false });
    }
  };
  changeComentario = (event) =>
    this.setState({ comentario: event.target.value });
  changeEnviar = (e) => {
    this.setState({ enviar: e.target.checked });
  };

  fileOnloadNew = (e) => {
    document.querySelector('img[name="prev"]').src = reader.result;
  };
  onChangeImage = (event) => {
    try {
      upImage = event.target.files[0];
      reader.onload = this.fileOnloadNew;
      reader.readAsDataURL(upImage);
      //this.campLogistObj.imagen = upImage;
    } catch (error) {}
  };

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          this.privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.comentario} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={8} lg={8}>
                <Form.Group label="incidencias">
                  <Select
                    value={this.state.incidencias}
                    onChange={this.changeIncidencias}
                    options={this.state.incidenciasPlataforma}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Latitud">
                  <Form.Input
                    name="latitud"
                    value={this.state.latitud}
                    onChange={this.changeLatitud}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={2} lg={2}>
                <Form.Group label="Longitud">
                  <Form.Input
                    name="longitud"
                    value={this.state.longitud}
                    onChange={this.changeLongitud}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group label="Comentario">
                  <Form.Textarea
                    name="comentario"
                    type="text"
                    value={this.state.comentario}
                    onChange={this.changeComentario}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <div>
                  <Grid.Col md={4}>
                    <Form.Group label="Imagen">
                      <input
                        type="file"
                        id="imagen"
                        ref={this.fileInput}
                        className="file"
                        accept="image/*"
                        onChange={this.onChangeImage}
                      />
                      <img
                        name="prev"
                        src="images/loadPicture.png"
                        width="50%"
                        align="center"
                        border="0"
                      />
                      <Button
                        target="_blank"
                        size="sm"
                        RootComponent="a"
                        color="primary"
                        pill
                        onClick={(e) => {
                          try {
                            //navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
                            //  if (result.state == "granted" || result.state == "prompt") {
                            navigator.clipboard.read().then((data) => {
                              for (let i = 0; i < data.length; i++) {
                                if (!data[i].types.includes("image/png")) {
                                  alert("No parece ser una imagen");
                                } else {
                                  data[i]
                                    .getType("image/png")
                                    .then((blob) => {
                                      upImage = blob;
                                      reader.onload = this.fileOnloadNew;
                                      reader.readAsDataURL(upImage);
                                      upImage = new File(
                                        [blob],
                                        "imagen" +
                                          new Date().getTime() +
                                          ".png"
                                      );
                                    });
                                }
                              }
                            });
                            //  }
                            //});
                          } catch (error) {}
                        }}
                      >
                        <span class="badge">
                          <Icon link={true} name="image" />
                          pegar
                        </span>
                      </Button>
                    </Form.Group>
                  </Grid.Col>
                </div>
              </Grid.Col>
              <Grid.Col md={12}>
                <Form.Group label="Enviar correo">
                  {this.estaPrivilegioActivo("arroba_incidencia") ? (
                    <Form.Switch
                      type="Checkbox"
                      checked={this.state.enviar}
                      onChange={this.changeEnviar}
                    />
                  ) : (
                    <Form.Switch type="Checkbox" checked={false} />
                  )}
                </Form.Group>
                {this.state.destinosClientes ? (
                  <List.Group>
                    <h3>
                      {this.state.destinosClientes.length > 0
                        ? "Cliente"
                        : null}
                    </h3>
                    {this.state.destinosClientes.map((cliente, index) => (
                      <List.GroupItem action icon="rss">
                        <div className="row">
                          <div className="col-5">
                            {" "}
                            <Icon name="user" />
                            {cliente.nombre +
                              " " +
                              cliente.aPaterno +
                              " " +
                              cliente.aMaterno}{" "}
                          </div>
                          <div className="col-5">
                            {" "}
                            <Icon name="mail" />
                            {cliente.correo}{" "}
                          </div>
                        </div>
                      </List.GroupItem>
                    ))}
                  </List.Group>
                ) : null}
                {this.state.destinosTransportistas ? (
                  <List.Group>
                    <h3>
                      {this.state.destinosTransportistas.length > 0
                        ? "Transportista"
                        : null}
                    </h3>
                    {this.state.destinosTransportistas.map((transp, index) => (
                      <List.GroupItem action icon="rss">
                        <div className="row">
                          <div className="col-5">
                            {" "}
                            <Icon name="user" />
                            {transp.nombre +
                              " " +
                              transp.aPaterno +
                              " " +
                              transp.aMaterno}{" "}
                          </div>
                          <div className="col-5">
                            {" "}
                            <Icon name="mail" />
                            {transp.correo}{" "}
                          </div>
                        </div>
                      </List.GroupItem>
                    ))}
                  </List.Group>
                ) : null}
                {this.state.destinosMonitoreo ? (
                  <List.Group>
                    <h3>
                      {this.state.destinosMonitoreo.length > 0 ? "Otros" : null}
                    </h3>
                    {this.state.destinosMonitoreo.map((moni, index) => (
                      <List.GroupItem action icon="rss">
                        <div className="row">
                          <div className="col-5">
                            {" "}
                            <Icon name="user" />
                            {moni.nombre +
                              " " +
                              moni.aPaterno +
                              " " +
                              moni.aMaterno}{" "}
                          </div>
                          <div className="col-5">
                            {" "}
                            <Icon name="mail" />
                            {moni.correo}{" "}
                          </div>
                        </div>
                      </List.GroupItem>
                    ))}
                  </List.Group>
                ) : null}
                {this.state.enviar ? (
                  this.state.destinosClientes.length > 0 ||
                  this.state.destinosTransportistas.length > 0 ||
                  this.state.destinosMonitoreo.length > 0 ? null : (
                    <List.GroupItem action icon="rss">
                      {" "}
                      Al parecer no se ha configurado la plantilla, o no hay
                      destinatarios, revise su configuración{" "}
                    </List.GroupItem>
                  )
                ) : null}
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <div class="row">
          <div className="col col-12 col-md-12">
            <Card title="Lista de Incidencias">
              <Card.Body>
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={[
                    { content: "Id" },
                    { content: "Nombre" },
                    { content: "Fecha" },
                    { content: "Incidencia tipo" },
                  ]}
                >
                  <Table.Body>
                    {
                      //   this.state.newResult.map((res)=>{
                      //    return(
                      <Table.Row>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                        <Table.Col></Table.Col>
                      </Table.Row>
                      //  )
                      //  })
                    }
                  </Table.Body>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="float-right">
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={this.enableSave()}
              onClick={this.guardarIncidencia}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar incidencia
              </span>
            </Button>
          </span>
          {this.state.accion === "modificar" ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default MovimientoIncidenciasForm;
