import React, { useCallback, useMemo, useState } from 'react'
import { Button, Form, Header, Grid, Alert, Loader, Container } from "tabler-react";
import axios from 'axios';
import { api_raiz } from "../../config/config";

const CargaMasiva = ({ getData }) => {

    const [entryFile, setEntryFile] = useState();

    const [exitFile, setExitFile] = useState();

    const [isEntryLoading, setIsEntryLoading] = useState(false);

    const [isExitLoading, setIsExitLoading] = useState(false);

    const [entryError, setEntryError] = useState();

    const [exitError, setExitError] = useState();

    const instance = useMemo(() => {
        const instance = axios.create({
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem("tok")
            }
        });
        return instance;
    }, [])

    const changeEntryFileHandler = useCallback(
        async (event) => {
            setEntryFile(event.target.files[0]);
        }, [])

    const changeExitFileHandler = useCallback(
        async (event) => {
            setExitFile(event.target.files[0]);
        }, [])


    const submitMassiveEntryHandler = useCallback(async (event) => {
        event.preventDefault();
        setEntryError("")
        if (!entryFile) return;
        const formData = new FormData();
        formData.append("file", entryFile);
        try {
            setIsEntryLoading(true)
            await instance.post(api_raiz + "gps-almacen/entrada-masiva", formData);
            getData()
        } catch (error) {
            setEntryError(error?.response?.data ?? error)
        } finally {
            setIsEntryLoading(false)
        }
    }, [entryFile])

    const submitMassiveExitHandler = useCallback(async (event) => {
        event.preventDefault();
        setExitError("")
        if (!exitFile) return;
        const formData = new FormData();
        formData.append("file", exitFile);
        try {
            setIsExitLoading(true)
            await instance.post(api_raiz + "gps-almacen/salida-masiva", formData);
            getData()
            
        } catch (error) {
            setExitError(error?.response?.data ?? error)
        } finally {
            setIsExitLoading(false)
        }
    }, [exitFile])

    return (
        <Grid.Row>
            <Grid.Col>
                <Form onSubmit={submitMassiveEntryHandler}>
                    <Form.FieldSet>
                        <Header.H4>Importar archivo para entrada masiva</Header.H4>
                        <Form.Group >
                            <Form.FileInput label="Escoger archivo" lang="Abrir" onChange={changeEntryFileHandler} />
                        </Form.Group>
                        <Button icon="file" type="submit" value="Submit" disabled={isEntryLoading} title="Subir entradas">Subir entradas</Button>
                    </Form.FieldSet>
                </Form>
                {entryError && <Alert type="danger" hasExtraSpace>
                    <strong>Error subiendo archivo</strong> {entryError}
                </Alert>}
                {isEntryLoading && <Loader/>}
            </Grid.Col>
            <Grid.Col>
                <Form onSubmit={submitMassiveExitHandler}>
                    <Form.FieldSet>
                        <Header.H4>Importar archivo para salida masiva</Header.H4>
                        <Form.Group>
                            <Form.FileInput label="Escoger archivo" lang="Abrir" onChange={changeExitFileHandler} />
                        </Form.Group>
                        <Button icon="file" type="submit" value="Submit" disabled={isExitLoading} >Subir salidas</Button>
                    </Form.FieldSet>
                    {exitError && <Alert type="danger" hasExtraSpace>
                        <strong>Error subiendo archivo</strong> {exitError}
                    </Alert>}
                    {isExitLoading && <Loader/>}
                </Form></Grid.Col>
        </Grid.Row>
    )
}
export default CargaMasiva;