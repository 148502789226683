import React, { useState } from "react";
import {
  Card,
  FormTextInput,
  Button,
  Icon,
  Grid,
  Dimmer,
  Alert,
} from "tabler-react";
import Auth from "../../Auth/Auth";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";

const LoginTelefonoPage = () => {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const login = async () => {
    setLoading(true);
    await Auth("login", usuario, password)
      .then((res) => {
        if (res.token) {
          getUserInfo();
        } else {
          setLoading(false);
          setMessage("Usuario o contraseña incorrectos.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserInfo = async () => {
    await Crud_Catalogos(
      "plataformasUsuariosPerfiles/username",
      "usuarios",
      "post",
      "",
      { username: usuario },
      "",
      "currentUser"
    )
      .then((returnVal) => {
        sessionStorage.setItem("usuario", JSON.stringify(returnVal[0].usuario));
        sessionStorage.setItem("perfil", JSON.stringify(returnVal[0].perfil));
        sessionStorage.setItem(
          "plataforma",
          JSON.stringify(returnVal[0].plataforma)
        );
        sessionStorage.setItem("currentUser", JSON.stringify(returnVal));
        window.location.href = "/telefono";
        setMessage(undefined);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
          console.log(err);
        }
      });
  };

  return (
    <Grid.Col sm={12} lg={4} alignItems="center">
      <div className="p-4"></div>
      <Card>
        <Card.Body>
          <Dimmer active={loading} loader />
          {!loading && (
            <div className="col-12 text-center">
              <img src="images/allone.svg" width="100" height="50" />
            </div>
          )}
          {message && !loading && (
            <Alert type="danger" icon="alert-triangle">
              {message}
            </Alert>
          )}
          {!loading && (
            <div>
              <FormTextInput
                name="username"
                type="text"
                label={"Usuario"}
                onChange={(event) => setUsuario(event.target.value)}
                value={usuario}
              />
              <FormTextInput
                name="password"
                type="password"
                label={"Contraseña"}
                onChange={(event) => setPassword(event.target.value)}
                value={password}
              />
              <div className="col-12 text-right">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={login}
                >
                  <span class="badge">
                    <Icon link={true} name="user" />
                    Login
                  </span>
                </Button>
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </Grid.Col>
  );
};

export default LoginTelefonoPage;
