import React,  { useState, useEffect } from 'react';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { List } from 'tabler-react';

const DetalleAlerta = ({idAlerta, esquema}) => {
    //console.log(idAlerta);
    //console.log(esquema);

    const [ detalleAlerta, setDetalleAlerta ] = useState({});

    useEffect(() => {
        if(esquema === "naviera"){
            crud("get", "", "", "navierasAlertas/" + idAlerta, "detalleAlerta"); 
        }
        if(esquema === "terrestre"){
            crud("get", "", "", "terrestresAlertas/id/" + idAlerta, "detalleAlerta"); 
        }
    }, [idAlerta, esquema]);

    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                if(stateVar === "detalleAlerta") {
                    if(returnVal){
                        //console.log(returnVal);
                        setDetalleAlerta(returnVal);
                    }
                }
                    break;          
                default:
                    break;
            }
        }).catch(err => { console.log(err); });
    }
    
  return (
    <div className= "mt-1">        
        <List.Group>
            <List.GroupItem action icon="credit-card"> id: { detalleAlerta.id } </List.GroupItem>
            <List.GroupItem action icon="truck"> Movimiento: { detalleAlerta.idMovimiento }</List.GroupItem>
            <List.GroupItem action icon="map-pin"> latitud: { detalleAlerta.latitud } </List.GroupItem>
            <List.GroupItem action icon="map-pin"> longitud: { detalleAlerta.longitud } </List.GroupItem>
            <List.GroupItem action icon="pen-tool"> trigger Value: { detalleAlerta.triggerValue }</List.GroupItem>
            <List.GroupItem action icon="pen-tool"> trigger Name: { detalleAlerta.triggerName }</List.GroupItem>
            <List.GroupItem action icon="calendar"> Fecha de creación: { detalleAlerta.fechaCreacion }</List.GroupItem>
            <List.GroupItem action icon="alert-circle"> Causa: { detalleAlerta.causa }</List.GroupItem>
            <List.GroupItem action icon="message-square"> Mensaje: { detalleAlerta.mensaje }</List.GroupItem>
            <List.GroupItem action icon="circle"> Atendido: { detalleAlerta.atendida === 0 || detalleAlerta.atendida === null ? "No" : "Si" }</List.GroupItem>
            <List.GroupItem action icon="calendar"> Fecha atendido: { detalleAlerta.atiendeFecha}</List.GroupItem>
            <List.GroupItem action icon="user-check"> Usuario: { detalleAlerta.atiendeusuario }</List.GroupItem>
        </List.Group>
    </div>
  )
}

export default DetalleAlerta