import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../../SiteWrapper.react";
import NavieroView from "./NavieroView";
import PanelView from "../PanelView";

import * as ACTIONS from '../../../store/actions/actions'
import { connect } from 'react-redux';

import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


var currentUser     = JSON.parse(sessionStorage.getItem("currentUser"));
var colsN=[];
var colsT=[];

class MonitoreoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     currentMovimiento  : {},
            currentMovimientoTerrestre  : {},
                    movimientoToUpdate  : null,
           movimientoToUpdateTerrestre  : null,
                              alertasN  : {},
                              alertasT  : {},
                                 etasN  : {},
                               //alertas  : {},
                             columnasN  : [],
                             columnasT  : [],
                    usuariosCorredores  : [],
                   usuariosCorredoresT  : [],
                           hiddenPanel  : false,
                     hiddenSiteWrapper  : true,
                              navieras  : false,
                            terrestres  : false,
                        plataformaTipo  : null,
                    movimentosNavieros  : [],
                  movimentosTerrestres  : [],
                    gpsEstatusNavieros  : null,
                  gpsEstatusTerrestres  : null,
                  gpsTerrestreReciente  : null,
                  gpsNavieroReciente   : null,
                                topics  : [],
                                topicsT : [],
                                metodo  : "get",
                     totalAlertCatSpan  : [],
                       MovimientosFull  : {}
    };
    this.navieras= false;
    this.terrestres= false;
    this.gpsPlataformaGPsgG={};
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
    this.loadedN= false;
    this.loadedT= false;
    this.totalAlert= 0;
    this.totalAlertCat= [];
    this.totalAlertCatSpan=[];
    this.gpsFijos= [];
    this.movimentosNavieros= {};
  }

  componentWillMount=() =>{
    for (let item of currentUser) {
      if(item){
        if(item.plataforma){
          if(item.plataforma.plataformaTipo){
            switch (item.plataforma.plataformaTipo.nombre) {
              case 'Terrestre': {this.setState({ terrestres: true }); this.terrestres= true;} break;
              case 'Naviera':   {this.setState({ navieras: true });   this.navieras  = true;} break;
              default:
                break;
            }
          }
        }
      }
    }
    if(this.props.bloque === "Monitoreo_Naviero" && this.navieras ){ this.setState({ navieras: true }); }else{ this.setState({ navieras: false }); }
    if(this.props.bloque === "Monitoreo_Terrestre" && this.terrestres ){ this.setState({ terrestres: true }); }else{ this.setState({ terrestres: false }); }
    //if(this.state.funcionalidades !== this.props.funcionalidades){ this.setState({funcionalidades: this.props.funcionalidades}); }
  }
  componentDidMount =() => {
  }
  componentDidUpdate=()=>{
    //if(this.state.funcionalidades !== this.props.funcionalidades){ this.setState({funcionalidades: this.props.funcionalidades}); }
  }

    
      alertasNCount=() =>{
        this.totalAlert= 0;
        this.totalAlertCat=[];
        this.totalAlertCatSpan=[];
        Object.keys( this.state.alertasN ).forEach(
          (key, indice) => {
            Object.keys( this.state.alertasN[key] ).forEach((key2, indice2) => {
              this.totalAlert= this.totalAlert + this.state.alertasN[key][key2].length
              if(this.totalAlertCat[key2]){
                this.totalAlertCat[key2]= this.totalAlertCat[key2]+this.state.alertasN[key][key2].length
              }else{this.totalAlertCat[key2]= this.state.alertasN[key][key2].length}
            })
          });
    
          Object.keys( this.totalAlertCat ).forEach((key, indice) => {
            this.totalAlertCatSpan.push({ categoria: key, val: this.totalAlertCat[key] })
          });
          if(this.state.totalAlertCatSpan !== this.totalAlertCatSpan ){this.setState({totalAlertCatSpan: this.totalAlertCatSpan}); }
      }
  /**
   * Fin Alertas
   */


  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }
  getFuncionalidad=(elemento)=>{
    for(let item of this.props.funcionalidades){
      if(item.nombre === elemento ){ return true; break;}
    }
    return false;
  }
  getFuncionalidadT=(elemento)=>{
    for(let item of this.props.funcionalidadesT){
      if(item.nombre === elemento ){ return true; break;}
    }
    return false;
  }
  render() {
    return (
            <Grid.Col sm={12} lg={12}>{ console.log(this.props.funcionalidades) }
              <div className="row">
                <div style={{textAlign: "center"}} className="col-10" onClick={()=> this.setState({ hiddenSiteWrapper: false }) }><Icon name="arrow-down"/></div>
                <div className="col-2 " style={{"background-color": "rgba(43, 41, 33, 0.22)"}}>
                  <div className="float-left ml-4">
                    <i className= "fa fa-anchor ml-2 text-success"  style={{"font-size": "2em"}} ></i>
                    <i className= "fa fa-truck ml-2 text-success"   style={{"font-size": "2em"}} ></i>
                  </div>
                </div>
              </div>
              <div style={{ height: "100vh", "font-setMovimientoTerrestreize": "smaller"}}>
                <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
                  <div>
                      <SplitterLayout vertical= {true}>
                          {this.state.navieras?
                            <div>
                                <div>
                                  <div className="col-12 bg-primary text-light">
                                    <div className="row">
                                      <div className="col-4">
                                        <h4>
                                          Navieras
                                        </h4>
                                      </div>
                                      <div className="col-8">
                                        { this.getFuncionalidad("Alertas(Resumen)")?
                                            <span className='fa fa fa-bell   float-right' style={{width: "10vh", 'font-size':'120%'}}>: {this.totalAlert} </span>: <div></div>}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="panel panel-default">
                                    {this.getFuncionalidad("Movimientos")? <NavieroView funcionalidades={ this.props.funcionalidades } />: <div></div>}
                                  </div>
                                </div>

                            </div>
                          :null}
                          {console.log(this.state.terrestres)}
                          {this.state.terrestres ?
                            <div>
                              <div>
                                <div className="col-12 bg-primary text-light"><h4>Terrestres<div className="float-right col-1 right" style={{"background-color": "rgba(6, 0, 0, 0.2)"}}></div></h4></div>
                                <div className="panel panel-default">
                                  {this.getFuncionalidadT("Movimientos")? <NavieroView funcionalidades={ this.props.funcionalidadesT }/>: <div></div> }
                                </div>
                              </div>

                            </div>
                          :null
                          }
                      </SplitterLayout>
                  </div>
                  <div>
                      <div  onClick={ ()=> this.setState({ hiddenPanel: !this.state.hiddenPanel })} className="float-left border-primary bg-dark"
                            style={{ position:"absolute", right: "0%", height: "100%", "border-left-style": "solid" }}>
                            <span className="text-primary"><Icon name="arrow-right"/></span>
                      </div>
                      {this.state.navieras?
                      <PanelView funcionalidades={ this.props.funcionalidades } naviera= {this.state.navieras} terrestre= {this.state.terrestres} updatePanel={this.props.updatePanel} />:null}
                      {this.state.terrestres?
                      <PanelView funcionalidades={ this.props.funcionalidadesT } naviera= {this.state.navieras} terrestre= {this.state.terrestres} updatePanel={this.props.updatePanel} />:null}
                  </div>
                </SplitterLayout>
              </div>

            </Grid.Col>
    );
  }
}

function mapStateToProps(state) {
  return {
    movimientos_naviera_object: state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operacionesEstatus_object: state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operacionesClientes_object: state.catalogos_reducer.operacionesClientes_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object:state.catalogos_reducer.update_mensaje,
  }
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) => dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) => dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    movimientosTipo_update: (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    navieras_update: (elementos) => dispach(ACTIONS.elementos_navieras_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    operacionesEstatus_update: (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operacionesClientes_update: (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operadores_update: (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(MonitoreoPage);
