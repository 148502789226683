import React, {useState} from 'react';
const softphoneContext = React.createContext();

const SoftphoneProvider = ({children}) => {
	const [softphone, setSoftphone] = useState(
		sessionStorage.getItem("softphone") !== null
		? JSON.parse(sessionStorage.getItem("softphone"))
		: {}
	);
	const [movimiento, setMovimiento] = useState({data: {}, estatus: false});
	const [llamarClic, setLlamarClic] = useState(null);
	
	const getValues=()=>{
		return softphone;
	}
	const getMovimiento=()=>{
		return movimiento;
	}
	const getLlamarClic=()=>{
		return llamarClic;
	}
	return (
		<softphoneContext.Provider
			value={{
				getValues: getValues,
				setSoftphone: setSoftphone,
				getMovimiento: getMovimiento,
				setMovimiento: setMovimiento,
				getLlamarClic: getLlamarClic,
				setLlamarClic: setLlamarClic
			}}
		>
			{children}
		</softphoneContext.Provider>
	);
}
 
export {SoftphoneProvider, softphoneContext};