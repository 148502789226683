import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";
import Chart from "react-apexcharts";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../SiteWrapper.react";
import '../navBarItems/MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import AlertasUnidades from "../../components/AlertasUnidades";
import PanelUsRuta from "./PanelUsRuta";
/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";
import { from } from "rxjs";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
class SkyColdPage extends Component {
  constructor(props) {
    super(props);
    this.state = { currentAlerta: [], alertas: [],
      series: [
      {
        name: "Sensor 1",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      },
      {
        name: "Sensor 2",
        data: [11, 30, 29, 51, 49, 62, 60, 80, 100]
      },
      {
        name: "Sensor 3",
        data: [10, 35, 25, 41, 39, 42, 59, 21, 28]
      },
      {
        name: "Sensor 4",
        data: [11, 23, 22, 21, 29, 22, 20, 20, 20]
      },
      
  ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: true
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: 'Temperature °C',
        align: 'left'
      },
      xaxis: {
        categories: ['2020-01-27 08:00:00', '2020-01-27 08:05:00', '2020-01-27 08:10:00', '2020-01-27 08:15:00', '2020-01-27 08:20:00', '2020-01-27 08:25:00', '2020-01-27 08:30:00', '2020-01-27 08:35:00', '2020-01-27 08:40:00', '2020-01-27 08:45:00'],
        type: 'datetime'
      },
      annotations: {
        yaxis: [{
          y: 12,
          borderColor: '#00E396',
          label: {
            borderColor: '#00E396',
            style: {
              color: '#fff',
              background: '#00E396',
            },
            text: 'My Frio',
          }
        }, {
          y: 15,
          y2: 35,
          borderColor: '#000',
          fillColor: '#FEB019',
          opacity: 0.2,
          label: {
            borderColor: '#333',
            style: {
              fontSize: '10px',
              color: '#333',
              background: '#FEB019',
            },
            text: 'Rango de temperatura',
          }
        }],
        xaxis: [{
          x: new Date('2020-01-27 08:35:00').getTime(),
          strokeDashArray: 0,
          borderColor: '#775DD0',
          label: {
            borderColor: '#775DD0',
            style: {
              color: '#fff',
              background: '#775DD0',
            },
            text: 'Anno Test',
          }
        }
       ]
      }
    },
      /**Fin state */
    };
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
  }

  componentWillMount=() =>{}
  componentDidMount =() => {}
  componentDidUpdate=()=>{}
  getCurrentAlerta=()       =>{ return this.state.currentAlerta };
  setCurrentAlerta=(alerta) =>{ this.setState({ currentAlerta: alerta }) };
  setAlertas=(alertas)      =>{ if(this.state.alertas !== alertas){this.setState({ alertas: alertas })} }
  getAlertas=()             =>{ return this.state.alertas;}
  getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /*** filtro de variables  */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "columnas"){}
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
    })
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }
  render() {
    return (
      <>
      {<LoadStore catalogos={["privilegios", "incidencias"]}/>}
        
      <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "85vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
              <div>
                  <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="area"
                    width="65%"
                  /> 
              </div>
              <div><PanelUsRuta currentAlerta={ this.state.currentAlerta } incidencias={ this.props.incidencias_object } getAlertas={ this.getAlertas} setAlertas={ this.setAlertas } /></div>
            </SplitterLayout>
          </div>

        </Grid.Col>
      </SiteWrapper>
  
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privilegios_object: state.catalogos_reducer.privilegios_object,
    incidencias_object: state.catalogos_reducer.incidencias_object
  }
}
function mapDispachToProps(dispach) {
  return {
    incidencias_update:             (elementos) => dispach(ACTIONS.elementos_incidencias_imput(elementos))
  }
}
export default connect(mapStateToProps, mapDispachToProps)(SkyColdPage);
