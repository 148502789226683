//React
import React, { useState, useEffect, useCallback, useMemo } from 'react';
//Componentes externos
import { Container, Row, Col, Image } from 'react-bootstrap';
import axios from 'axios';
//Variables
import { api_raiz } from '../../config/config';

/**
 * MovimientoGpsEvidenciaModal
 * @description: Modal que mustra las evidencias de los gps.
 * @date 01/07/2024.
 * @returns JSX del componente.
 */
export const MovimientoGpsEvidenciaModal = (props) => {
  /* ----------------------------- useState's -------------------------------*/
  const [imagesMovGps, setImagesMovGps] = useState([]);
  const [loading, setLoading] = useState(false);
  /* ----------------------------- Hooks ------------------------------------*/
  const instance = useMemo(() => {
    const instance = axios.create({
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('tok'),
      },
    });
    return instance;
  }, []);
  const getImagesMovGps = useCallback(async () => {
    try {
      setLoading(true);
      if (!props.movimiento_gps_id) {
        await instance
          .get(api_raiz + `movimientoGps/movimiento/${props.movimiento_id}`)
          .then(async (response) => {
            let movGps = response.data.find(
              (element) => element?.gps?.nombre === props?.gps
            )?.id;
            if (movGps) {
              const mGps = await instance.get(
                api_raiz + `getImagesMovGps/${movGps}/asignacion`
              );
              setImagesMovGps(mGps.data);
            }
          });
        setLoading(false);
      } else {
        const response = await instance.get(
          api_raiz + `getImagesMovGps/${props.movimiento_gps_id}/asignacion`
        );
        setImagesMovGps(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  /* ----------------------------- useEffect's ------------------------------*/
  useEffect(() => {
    getImagesMovGps();
  }, []);
  /* -------------------------------- return --------------------------------*/
  if (loading) {
    return (
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center vh-100"
      >
        {/*Logo*/}
        <Row className="mb-4 w-100">
          <Col xs={12} className="d-flex justify-content-center">
            <img src="/images/loader.gif"></img>
          </Col>
        </Row>
        <Row className="my-5">
          <Col xs={12}>
            <h2 className="text-center font-weight-bold">Cargando...</h2>
          </Col>
        </Row>
      </Container>
    );
  }
  //Return si no hay información
  if (imagesMovGps?.length === 0) {
    return (
      <Container
        fluid
        className="d-flex flex-column justify-content-center align-items-center vh-100"
      >
        {/*Logo*/}
        <Row className="mb-4 w-100">
          <Col xs={12} className="d-flex justify-content-center">
            <Image
              src={'/images/400.png'}
              alt="Not Found"
              style={{ maxHeight: '50vh', width: 'auto' }}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col xs={12}>
            <h2 className="text-center font-weight-bold">Sin información</h2>
          </Col>
        </Row>
      </Container>
    );
  }
  //Return principal
  return (
    <Container fluid className="p-0">
      <div
        className="d-flex overflow-auto"
        style={{ maxWidth: '100%', overflowX: 'auto' }}
      >
        {imagesMovGps?.map((image, index) => (
          <Col key={index} xs="auto" className="px-1 ">
            <div
              key={index}
              className="flex-shrink-0 mx-2"
              style={{ width: '300px', height: '300px' }}
            >
              <img
                src={`data:image/png;base64,${image}`}
                className="w-100 h-100 object-fit-contain"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
              />
            </div>
          </Col>
        ))}
      </div>
    </Container>
  );
};
