import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class Navieras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Navieras: [],
      search: ""
    };
    this.Navieras = [];
    this.plataformasObject = [];
    this.checarNavieras = 0;      //temporal render
    this.idPlataformaNavieras = 0; //temporal render
    this.plataformasNavierasObject = [];
    this.NavierasArray = [];
  }

  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.plataformasObject = this.props.elementos;
      this.getfuntion("get", "", "", "plataformasNavieras/plataforma/"+this.props.elementos.id, "Navieras")
    } else { }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Navieras") { this.Navieras = returnVal; this.setState({ Navieras: returnVal }); }
          }
          break;
        case "post":
          {
            if (stateVar === "Navieras") {
              this.Navieras.push(returnVal);
              this.setState({ Navieras: this.Navieras });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Navieras") {
              this.Navieras.map((elemento, index) => {
                if (id === elemento.id) {
                  this.Navieras.splice(index, 1);
                }
              }
              )
              this.setState({ Navieras: this.Navieras })
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
    })
  }

  changeNaviera = (NavieraIndex, OtdId, OtdStatus) => {
    this.plataformasNavierasObject = {
      "idPlataforma": this.plataformasObject,
      "idNaviera": this.props.stor.navieras_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[NavieraIndex]
    }

    this.NavierasArray[NavieraIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.NavierasArray[NavieraIndex].id, "plataformasNavieras", "Navieras")
      :
      this.getfuntion("post", this.plataformasNavierasObject, "", "plataformasNavieras", "Navieras");
  }
  checkedNaviera = (indice) => {
    return this.NavierasArray[indice].estatus
  }
  checarNaviera = (estatus, id) => {
    this.NavierasArray.push({ estatus: estatus, id: id })
  }
  checarNavieraTem = (valor) => {
    this.checarNavieras = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.NavierasArray = [];
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Navieras"
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={false}
                  options={<Form.Group>
                    <Form.Input
                      className="mb-3"
                      icon="search"
                      placeholder="Buscar Naviera..."
                      position="append"
                      onChange={this.searching}
                    />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.navieras_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  {this.checarNavieraTem(0)}
                                  {this.idPlataformaNavieras = ""}
                                  {
                                    this.state.Navieras.map((elemNaviera, ind) => {
                                      if (elemNaviera.idNaviera.id === elemento.id) {
                                        { this.checarNavieraTem(1) }
                                        this.idPlataformaNavieras = elemNaviera.id;
                                      }
                                    })
                                  }
                                  {this.checarNaviera(this.checarNavieras, this.idPlataformaNavieras)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                    checked={this.checkedNaviera(index)}
                                    className={"disabled"}
                                    onChange={() => this.changeNaviera(index, elemento["id"], this.checarNavieras)} /></Table.Col>
                                </Table.Row>
                              )
                            }
                            {console.log()}
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}
      </div>
    )
  }
}

export default Navieras
