import React, { Component } from "react";
import { Table } from "tabler-react";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";

class EntregasClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregas: [],     
      destinos: [],      
      elementos: [],      
      eliminada: false
    };   
    this.entregas = [];    
  }

  componentWillMount = () => {    
    this.getfuntion(
      "get",
      "",
      "",
      "destinos/plataforma/" + this.props.obj.plataforma.id,
      "destinos"
    );
  };

  componentDidMount = () => {
    if (this.props.entregas) {
      this.setState({ entregas: this.props.entregas });
      this.entregas = this.props.entregas;      
    }
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "entregas",
    stateVar = "entregas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "entregasTerrestre",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "destinos" && returnVal[0].id) {
                this.setState({ destinos: returnVal });
              }
            }
            break;
          
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  render() {
    return (
      <div className="mt-1">
        <div className="row">
          <div className="col"></div>                    
        </div>       
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter bg-light"
          headerItems={[
            { content: "Destino" },                        
            { content: "Inicio Ruta" },
            { content: "Arribo" },
            { content: "Salida" },            
          ]}
        >
          <Table.Header></Table.Header>
          <Table.Body>
            {this.state.entregas.filter(e => this.state.eliminada ? (e.cancelada || e.canceladaManual) : (!e.cancelada && !e.canceladaManual))
              .sort(function (a, b) {
                //actualizar el state de entregas, no solo hacer el PUT
                if (a["orden"] === null) {
                  return -1;
                }
                if (a["orden"] > b["orden"]) {
                  return 1;
                }
                if (a["orden"] < b["orden"]) {
                  return -1;
                }
                return 0;
              })
              .map((elemento, index) => (
                <>
                  <Table.Row
                    style={
                      elemento.estatus
                        ? { "background-color": "rgba(95, 231, 50, 0.30)" }
                        : null
                    }
                  >
                    <Table.Col>{elemento.destino}</Table.Col>                    
                    <Table.Col>{elemento.inicioRuta}</Table.Col>
                    <Table.Col>{elemento.fechaArribo}</Table.Col>
                    <Table.Col>{elemento.fechaSalida}</Table.Col>
                  </Table.Row>
                </>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default EntregasClientes;
