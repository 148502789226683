import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";

const enviar = (accion, payload, setData, setLoading, setError) => {
	let resultados = [];
	if (payload === "") {
		setData([]);
		setLoading(false);
		setError(false);
		return;
	}
	switch (accion) {
		case "Inicio":
			setLoading(true);
			setError(false);
			break;
		case "Error":
			setLoading(false);
			setError(true);
			break;
		case "Plataformas":
			payload.map((plataforma, index) =>
				resultados.push({
					value: plataforma.id_plataforma,
					label: plataforma.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Transportistas":
			payload.map((transportista, index) =>
				resultados.push({
					value: transportista.id,
					label: transportista.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Resultados":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.nombre,
					count: resultado.count,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Bloques":
			payload.filter(
				bloque => bloque.nombre.includes("Contador ") && bloque.nombre.includes("Naviera")
			).map((bloque, index) =>
				resultados.push({
					value: bloque.id,
					label: bloque.nombre
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Funcionalidades":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.id,
					label: resultado.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		default:
			throw new Error();
	}
};

const FetchData = (url, opcion, setData, setLoading, setError) => {
	enviar("Inicio", null, setData, setLoading, setError);

	Crud_Catalogos(url, "dashboardNaviera", "get", "", "", "", opcion, "", [])
		.then((returnVal) => {
			enviar(opcion, returnVal, setData, setLoading, setError);
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading, setError);
		});
};

export default FetchData;
