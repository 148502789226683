import React,  { useState, useEffect } from 'react';
import { Alert  } from '@mui/material';
import { Grid } from "tabler-react";
import { Crud_Catalogos } from '../herramientas/Crud_Catalogos';
import ModalLayout from './ModalLayout.react';
import ListarAlertasPorCategorias from './ListarAlertasPorCategorias';
import AlertasMensajeria from '../socket/AlertasMensajeria';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var perfil = JSON.parse(sessionStorage.getItem("perfil"));

export const AlertasUnidades = () => {
  //console.log(currentUser[0].usuario.id);

  const [ alertasCategoriasNavieras, setAlertasCategoriasNavieras ] = useState([]);
  const [ alertasCategoriasTerrestres, setAlertasCategoriasTerrestres ] = useState([]);
  const [ conteoAlertasAtendidas, setConteoAlertasAtendidas] = useState([]);
  const [ conteoAlertasNoAtendidas, setConteoAlertasNoAtendidas] = useState([]);
  const [ conteoAlertasAtendidasTerrestres, setConteoAlertasAtendidasTerrestres] = useState([]);
  const [ conteoAlertasNoAtendidasTerrestres, setConteoAlertasNoAtendidasTerrestres] = useState([]);
  const [ funcionalidades, setFuncionalidades] = useState([]);
  const [ topics, setTopics] = useState([]);


  useEffect(() => {
    let topicsTemp = [];  
    for(let p of currentUser){      
      topicsTemp.push("/topic/alertasEnRuta-" + p.plataforma.id );         
    }    
    setTopics(topicsTemp);  
    crud("get", "", "", "funcionalidades/Modulo_de_Alertas/"+ perfil.id, "funcionalidades");   
  }, []);

  useEffect(() => {    
   if(funcionalidades.filter(f => f.nombre === "Alertas_en_ruta" ).length ){
    crud("get", "", "", "alertasCategorias/navierasusuario/" + currentUser[0].usuario.id, "alertasCategoriasNavieras");
    crud("get", "", "", "alertasCategorias/terrestresusuario/" + currentUser[0].usuario.id, "alertasCategoriasTerrestres");
   }
  }, [funcionalidades]);

  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get": 
            if(stateVar === "funcionalidades" && returnVal){
              setFuncionalidades(returnVal);
            }
            if(stateVar === "alertasCategoriasNavieras" && returnVal) {
              let alertasCategoriasTemp = [];
              returnVal.map((i, index) => alertasCategoriasTemp.push({value:i.id, label:i.nombre, data:i}));
              //console.log(returnVal);
              setAlertasCategoriasNavieras(alertasCategoriasTemp);
              crud("get", "", "", "alertas/atendidasNavieras/" + currentUser[0].usuario.id, "alertasAtendiadasNavieras");
              crud("get", "", "", "alertas/noAtendidasNavieras/" + currentUser[0].usuario.id, "alertasNoAtendiadasNavieras");
            }
            if(stateVar === "alertasCategoriasTerrestres" && returnVal) {
              let alertasCategoriasTemp = [];
              returnVal.map((i, index) => alertasCategoriasTemp.push({value:i.id, label:i.nombre, data:i}));
              //console.log(returnVal);           
              setAlertasCategoriasTerrestres(alertasCategoriasTemp); 
              crud("get", "", "", "terrestresAlertas/atendidas/" + currentUser[0].usuario.id, "alertasAtendiadasTerrestres");
              crud("get", "", "", "terrestresAlertas/noAtendidas/" + currentUser[0].usuario.id, "alertasNoAtendiadasTerrestres");            
            }
            if(stateVar === "alertasAtendiadasNavieras" && returnVal) {              
              let alertasAtendiadasNavierasTemp = {};
              for(let cuenta of returnVal){               
                if( !alertasAtendiadasNavierasTemp[cuenta.id_alerta_categoria] ){
                  alertasAtendiadasNavierasTemp[cuenta.id_alerta_categoria] = {};
                }
                alertasAtendiadasNavierasTemp[cuenta.id_alerta_categoria] = cuenta;
              }             
              //console.log(returnVal);
              setConteoAlertasAtendidas(alertasAtendiadasNavierasTemp);             
            }
            if(stateVar === "alertasNoAtendiadasNavieras" && returnVal) {             
              let alertasNoAtendiadasNavierasTemp = {};
              for(let cuenta of returnVal){                
                if( !alertasNoAtendiadasNavierasTemp[cuenta.id_alerta_categoria] ){
                  alertasNoAtendiadasNavierasTemp[cuenta.id_alerta_categoria] = {};
                }
                alertasNoAtendiadasNavierasTemp[cuenta.id_alerta_categoria] = cuenta;
              }             
              //console.log(returnVal);
              setConteoAlertasNoAtendidas(alertasNoAtendiadasNavierasTemp);                  
            }

            if(stateVar === "alertasAtendiadasTerrestres" && returnVal) {
              let alertasAtendiadasTerrestresTemp = {};
              for(let cuenta of returnVal){               
                if( !alertasAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] ){
                  alertasAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] = {};
                }
                alertasAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] = cuenta;
              }             
              //console.log(returnVal);
              setConteoAlertasAtendidasTerrestres(alertasAtendiadasTerrestresTemp);
            }
            if(stateVar === "alertasNoAtendiadasTerrestres" && returnVal) {
              let alertasNoAtendiadasTerrestresTemp = {};
              for(let cuenta of returnVal){               
                if( !alertasNoAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] ){
                  alertasNoAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] = {};
                }
                alertasNoAtendiadasTerrestresTemp[cuenta.id_alerta_categoria] = cuenta;
              }             
              //console.log(returnVal);
              setConteoAlertasNoAtendidasTerrestres(alertasNoAtendiadasTerrestresTemp);
            }
             break;            
            default:
                break;
        }
    }).catch(err => { console.log(err); });
  }  

  return (   
    <> 
      {<AlertasMensajeria 
        topics={topics}
        refrescar={() => {
          crud("get", "", "", "alertasCategorias/navierasusuario/" + currentUser[0].usuario.id, "alertasCategoriasNavieras");                             
        }} 
        refrescarTerrestres={() => {
          crud("get", "", "", "alertasCategorias/terrestresusuario/" + currentUser[0].usuario.id, "alertasCategoriasTerrestres");                             
        }}  
      />}
      <div className="mt-3 ml-3" >        
        <div className="mt-3">
          <br/>                          
          {alertasCategoriasNavieras.length > 0 ? (            
          <div>
            <h3>Navieras</h3>
            <br/>
            {/*console.log(alertasCategoriasNavieras)*/}
            <div className="row">          
            {alertasCategoriasNavieras.map((acn) =>           
            <div className="col-2">
              <i className={acn.data.logo} style={{"font-size": "24px"}}></i><span style={{"font-size": "13px"}} key={acn}>{ acn.label }</span>
              <ModalLayout 
                 tamanio={'90%'}
                 title={acn.label + " Alertas Atendidas"}
                 name= {  
                          <Alert style={{"font-size": "14px"}} variant="outlined" severity="success" className= "alert alert-primary">
                            Atendidas: { conteoAlertasAtendidas[acn.value] ? conteoAlertasAtendidas[acn.value].count : 0 } 
                          </Alert>
                        }    
                 formulario={<ListarAlertasPorCategorias 
                              idAlertaCategoria={acn.value}
                              atendida={true} 
                              plataformas={"navieras"}
                            />}           
                 hiddenModal={""}
                 Fclose={""}
              />
              <ModalLayout 
                tamanio={'90%'}
                title={acn.label + " Alertas No Atendidas"}
                name= { 
                        <Alert style={{"font-size": "14px"}} variant="outlined" severity="error" className= "alert alert-secundary">
                          No Atendidas: { conteoAlertasNoAtendidas[acn.value] ? conteoAlertasNoAtendidas[acn.value].count : 0 }
                        </Alert>
                 }
                formulario={<ListarAlertasPorCategorias 
                            idAlertaCategoria={acn.value}
                            atendida={false} 
                            plataformas={"navieras"}     
                            refrescar={() => {
                              crud("get", "", "", "alertasCategorias/navierasusuario/" + currentUser[0].usuario.id, "alertasCategoriasNavieras");                             
                            }}                      
                />}           
                hiddenModal={""}
                Fclose={""}
              />                  
            </div>                            
            )}  
          </div> 
          </div>
          ) : (
          <div></div>
          )}          
        </div> 

        <div className="mt-3">
          <br/>           
          {alertasCategoriasTerrestres.length > 0 ? (            
          <div>
            <h3>Terrestres</h3><br/>
            <br/>
            <div className="row">
            {alertasCategoriasTerrestres.map((act) =>                    
            <div className="col-2">
              <i className={act.data.logo} style={{"font-size": "24px"}}></i><span style={{"font-size": "13px"}} key={act}>    { act.label } </span>
              <ModalLayout 
                 tamanio={'90%'}
                 title={act.label + " Alertas Atendidas"}
                 name= {  
                          <Alert style={{"font-size": "14px"}} variant="outlined" severity="success" className= "alert alert-primary">
                            Atendidas: { conteoAlertasAtendidasTerrestres[act.value] ? conteoAlertasAtendidasTerrestres[act.value].count : 0 } 
                          </Alert>
                        }    
                 formulario={<ListarAlertasPorCategorias 
                              idAlertaCategoria={act.value}
                              atendida={true} 
                              plataformas={"terrestres"}
                            />}           
                 hiddenModal={""}
                 Fclose={""}
              />
              <ModalLayout 
                tamanio={'90%'}
                title={act.label + " Alertas No Atendidas"}
                name= { 
                        <Alert style={{"font-size": "14px"}} variant="outlined" severity="error" className= "alert alert-secundary">
                          No Atendidas: { conteoAlertasNoAtendidasTerrestres[act.value] ? conteoAlertasNoAtendidasTerrestres[act.value].count : 0 }
                        </Alert>
                 }
                formulario={<ListarAlertasPorCategorias 
                            idAlertaCategoria={act.value}
                            atendida={false} 
                            plataformas={"terrestres"}
                            refrescar={() => {
                              crud("get", "", "", "alertasCategorias/terrestresusuario/" + currentUser[0].usuario.id, "alertasCategoriasTerrestres");                             
                            }}                           
                />}           
                hiddenModal={""}
                Fclose={""}
              />               
            </div>                            
            )}  
          </div> 
          </div>
          ) : (
          <div></div>
          )}           
        </div>      
      </div> 
      <br/>      
    </>
  );  
}

export default AlertasUnidades;
