import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs from "../../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
class Etiquetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
transportistasEtiquetas:[],
              Etiquetas:[],
                 search:"",
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.Etiquetas = {};
    this.disabled = false;
  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.etiquetas !== this.state.etiquetas) { //alert("es igual")
    } else { /*alert(this.state.Etiquetas) */ }
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ nombre: this.props.elementos.nombre });
      this.getfuntion("get", "", "", "etiquetas", "etiquetas");
      this.getfuntion("get", "", "", "transportistasEtiquetas/id_transportista/"+this.props.elementos.id, "transportistasEtiquetas");
    }else{ }
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get": {
              if (stateVar === "etiquetas"){
                this.setState({ Etiquetas: returnVal });
              }
              if (stateVar === "transportistasEtiquetas"){
                for( let item of returnVal ){
                  this.Etiquetas[item.idEtiqueta]= [];
                  this.Etiquetas[item.idEtiqueta]= item;
                }
                this.setState({ transportistasEtiquetas: returnVal });
              }              
            } break;
            case "post": {
              if (stateVar === "transportistasEtiquetas" ){
                if(returnVal){
                  if( !this.Etiquetas[returnVal.idEtiqueta] ){  this.Etiquetas[returnVal.idEtiqueta]= []; }
                  this.Etiquetas[returnVal.idEtiqueta]= returnVal;
                  let transEtiqTemp= this.state.transportistasEtiquetas;
                  transEtiqTemp.push( returnVal );
                  this.setState({ transportistasEtiquetas: transEtiqTemp });
                  NotificationManager.info("Agregada ..", "Guargado");
                }
                this.disabled = false; //Termina accion
              }              
            } break;
            case "delete": {
              if (stateVar === "transportistasEtiquetas" ){
                let transEtiqTemp= this.state.transportistasEtiquetas
                transEtiqTemp.map((item, index)=>{
                  if( item.idTransportista+"/"+item.idEtiqueta === id ){
                    if(returnVal){
                      transEtiqTemp.splice(index, 1);
                      NotificationManager.info("Borrado ..", "Guargado");
                    }else{
                      NotificationManager.info("No se ha podido borrar ..", "Error");
                    }
                    this.Etiquetas= {};
                    for( let item of transEtiqTemp ){
                      this.Etiquetas[item.idEtiqueta]= [];
                      this.Etiquetas[item.idEtiqueta]= item;
                    }
                    this.setState({ transportistasEtiquetas: transEtiqTemp });
                  }
                });
                this.disabled = false; //Termina accion             
              }              
            } break;
          default:
            break;
        }
      }).catch(err =>{ console.log(err); this.disabled = false; }); //Termina accion
  }

  changeEtiqueta =(idEtiqueta) => {
    let objetoCE = {"idTransportista": this.props.elementos.id,"idEtiqueta": idEtiqueta};
    if( !this.disabled ){ //Esperar aque termine una accion
      this.disabled= true; //Inicia accion
      if( this.checkedEtiqueta(idEtiqueta) ){//Vardadero borrar
        this.getfuntion("delete", "", this.props.elementos.id+"/"+idEtiqueta, "transportistasEtiquetas", "transportistasEtiquetas");
      }else{ //Falso agregar
        this.getfuntion("post", objetoCE, "", "transportistasEtiquetas", "transportistasEtiquetas");
      }
    }else{alert("procesando ..")}
  }

  checkedEtiqueta = (idEtiqueta) => { return this.Etiquetas[idEtiqueta] ? true : false;}

  searching = (event) => {  this.setState({ search: event.target.value }); }

  render() {

    return (
      <div>
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Etiquetas" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                            <Table.ColHeader>Asignada</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.Etiquetas.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>{elemento["descripcion"]}</Table.Col>
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1"
                                    checked={ this.checkedEtiqueta(elemento["id"]) }
                                    onChange={() => this.changeEtiqueta( elemento["id"] )} />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}

      </div>
    );
  }
}
export default Etiquetas;
