import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { softphoneContext } from "../../context/softphoneContext";
const user = JSON.parse(sessionStorage.getItem("usuario"));
const PausaModal = ({ show, handleCloseModal }) => {
  const softphone = useContext(softphoneContext);
  const [pausa, setPausa] = useState({
    value: 0,
    label: "Selecciona una opción",
  });
  const [pausas, setPausas] = useState([
    { value: 0, label: "Selecciona una opción", data: {} },
  ]);
  const [configuracion, setConfiguracion] = useState(
    sessionStorage.getItem("softphone") !== null
      ? JSON.parse(sessionStorage.getItem("softphone"))
      : {}
  );

  useEffect(() => {
    getfuntion("get", "", "", "softphone/pausas", "pausas");
  }, []);

  //sessionStorage.setItem("usuario", JSON.stringify(usuario));
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "usuario",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "pausas") {
              if (returnVal.data && returnVal.data.length) {
                let pausasTemp = [
                  { value: 0, label: "Selecciona una opción", data: {} },
                ];
                for (let p of returnVal.data) {
                  pausasTemp.push({ value: p.id, label: p.name, data: p });
                  if (
                    configuracion.pausaEstatus &&
                    p.name === configuracion.pausaMotivo
                  )
                    setPausa(pausasTemp[pausasTemp.length - 1]);
                }
                setPausas(pausasTemp);
              }
            }
            if (stateVar === "configuracion") {
              if (returnVal) {
                setConfiguracion(returnVal);
                sessionStorage.setItem("softphone", JSON.stringify(returnVal));
                softphone.setSoftphone(returnVal);
                if (returnVal.pausaEstatus) {
                  const pausaTemp = pausas.filter(
                    (p) => p.label === returnVal.pausaMotivo
                  );
                  if (pausaTemp[0].value) {
                    setPausa(pausaTemp[0]);
                  }
                }
              }
              handleCloseModal();
            }
            break;
          case "post":
            if (stateVar === "pausa") {
              if (returnVal.message) {
                getfuntion(
                  "get",
                  "",
                  "",
                  "softphone/" + user.id,
                  "configuracion"
                );
                NotificationManager.success(returnVal.message, "Pausa");
              } else {
                NotificationManager.error(returnVal.message, "Pausa");
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Establecer pausa</Modal.Title>
        </Modal.Header>
        <Modal.Body>Selecciona motivo de pausa</Modal.Body>
        <Container>
          <Form>
            <Select
              value={pausa}
              options={pausas}
              isDisabled={configuracion.pausaEstatus}
              onChange={(e) => {
                setPausa(e);
              }}
            />
          </Form>
        </Container>
        <Modal.Footer>
          <Button
            variant={configuracion.pausaEstatus ? "danger" : "primary"}
            disabled={!pausa.value}
            onClick={() => {
              getfuntion(
                "post",
                {},
                "",
                "softphone/pausa?estatus=" +
                  (configuracion.pausaEstatus ? false : true) +
                  "&motivo=" +
                  (configuracion.pausaEstatus
                    ? configuracion.pausaMotivo
                    : pausa.label),
                "pausa"
              );
            }}
          >
            {configuracion.pausaEstatus ? "Quitar pausa" : "Establecer pausa"}
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            <i
              className={"fe fe-x"}
              style={{ fontWeight: "bold", color: "black" }}
            />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PausaModal;
