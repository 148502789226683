import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs from "../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';

var FilaAct=[];

function validaActivo(evento, FHabilitados, columna) {//console.log("elementos.idFuncionalidades: " + elementos.idFuncionalidades + " columna.id: " + columna.id + " columna.nombre: " + columna.nombre + " regresa: " + regresa);
  let regresa = 0;
  //console.log("FHabilitados");
  //console.log(FHabilitados);
  FHabilitados.map( (elementos, index) => {
    if (elementos.idInformes==columna.id)
      {
        regresa = 1;
        //console.log("elementos.idFuncionalidades:"+elementos.idFuncionalidades + " columna.id:"+columna.id);
      }
    }
    )
  FilaAct[columna.id]=regresa;
  //console.log(FilaAct);
  return regresa
}
class Informes extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id: "",
                 nombre: "",
                estatus: false,
    Informes_arraylist: [],
                 accion: this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
      //perfilesInformes: [],
              Informes: [],
           FHabilitados:[],
                 search: "",
             currentTap: 0,
    };

    //this.Etiqueta_array = [];
    //this.perfilEtiquetaId = 0;
    //this.perfilObject = [];
    //this.perfilesObject = [];

    //this.checarEtiqueta = 0;      //temporal render
    //this.idPerfilEtiqueta = 0; //temporal render
    //this.perfilEtiquetaObject = [];
    this.Array = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({          id:this.props.elementos.id });
      this.setState({      nombre:this.props.elementos.nombre });
      this.setState({ descripcion:this.props.elementos.descripcion });
      //this.InformesObject = this.props.elementos;
      //console.log("this.props.elementos:");
      //console.log(this.props.elementos);
      this.cargaInformes("");
      this.cargaPerfilesInformes(this.props.elementos.id);
    } else {
    }
  }
  changeEvento =(evento, InformesId, columna) => {
    let objetoIF = { "idPerfil": InformesId, "idInformes":evento+1}
    objetoIF.idInformes=columna.id
    if (FilaAct[columna.id]===0){
    //                               principal   temporal default       id     parametros opcional          state              cerrar modal       si error actualizar
    //              (apiRaiz="",              api="",metodo,id, params,                   storeFunction="",  stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
      Crud_Catalogos("perfilesInformes", "", "post", "", objetoIF , "" , "", "", [], "", "", "").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        tempo.push(returnVal);
        this.setState({ FHabilitados:tempo });
        tempo=[];
        FilaAct[columna.id]=1
      })
    }else{
      Crud_Catalogos("perfilesInformes"+"/"+InformesId, "", "delete", columna.id, "" , "" , "FHabilitados").then((returnVal) => {
        var tempo = this.state.FHabilitados;
        for(let i=0; i< tempo.length; i++){
          if (tempo[i].idInformes===columna.id){
            tempo.splice(i, 1);
            this.setState({ FHabilitados:tempo });
          }
        }
        //console.log(tempo);
        tempo=[];
        FilaAct[columna.id]=0;
      })
    }
  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Informes !== this.state.Informes) {
      //alert("es igual")
    } else { /*alert(this.state.Informes) */ }
  }
  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
  }

  changeEtiqueta = (etiquetaindex, privId, estatus) => {
    this.perfilEtiquetaObject = {
      "idPerfil": this.state.id,
      "idInformes": privId,
      "nombre": this.props.stor.Informes_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[etiquetaindex].nombre
    }
    if (this.InformesArray[etiquetaindex].estatus === 1) { this.InformesObject.Informes.splice(etiquetaindex, 1) }
    this.InformesArray[etiquetaindex].estatus === 1 ?
      this.props.getfuntion("delete", "", this.InformesArray[etiquetaindex].id, this.perfilObject, "perfilesInformes", this.props.stor.perfiles_update)
      :
      this.props.getfuntion("post", this.perfilEtiquetaObject, this.state.id, this.perfilObject, "perfilesInformes", this.props.stor.perfiles_update, "informes");
  }


  cargaInformes(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("informes", "", "get", "",[],"" , "", "", [], "", "", "").then((regresaValores) => {
    this.setState({ Informes:regresaValores });
    //console.log("regresaInformes");
    //console.log(regresaValores);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaPerfilesInformes(filtro) {
    //console.log("Filtro:"+filtro);
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("perfilesInformes", "", "get", "",[],"" , "", "", [], "", "", "/perfil/"+filtro).then((regresaValores) => {
    //this.setState({ perfilesInformes:regresaValores });
    this.setState({ FHabilitados:regresaValores });
    //console.log("perfilesInformes");
    //console.log(regresaValores);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }

  delete()   { this.props.getfuntion("delete", "", this.state.id); }
  crud_put() { this.props.getfuntion("put", { "id":this.state.id, "nombre":this.state.nombre,"estatus":this.state.estatus}); }
  crud_post(){ this.props.getfuntion("post",{ "nombre":this.state.nombre, "estatus":this.state.estatus }); }

  checkedEtiqueta = (indice) => {
    return this.InformesArray[indice].estatus
  }
  checarInformes = (estatus, id) => {
    this.InformesArray.push({ estatus: estatus, id: id })
  }
  checarEtiquetaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.InformesArray = [];
  }
  selectedTab = (index) => {
    this.setState({ currentTap: index })
  }
  render() {

    return (
      <div>

        {/*
          *Informes
          */
        }

        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Informes" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.Informes.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1" className={"disabled"}
                                    checked={ validaActivo(index, this.state.FHabilitados, elemento) }
                                    onChange={() => this.changeEvento(index, this.state.id, elemento)} />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}

      </div>
    );
  }
}
export default Informes;
