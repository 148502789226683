// @flow

import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";

class GPSForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: " ",
      estatus: "",
      color: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.enableSave = this.enableSave.bind(this);
  }
  isEnable = (Accion) => {
    let borrar = "gps_estatus_borrar";
    let actualizar = "gps_estatus_editar";
    let agregar = "gps_estatus_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ estatus: this.props.elementos.estatus });
      this.setState({ color: this.props.elementos.color });
    }
  };

  enableSave = () => {
    if (this.state.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  changeName = (event) => this.setState({ nombre: event.target.value });

  changeEstatus = (event) => {
    event.target.checked
      ? this.setState({ estatus: 1 })
      : this.setState({ estatus: 0 });
  };
  changeColor = (event) => {
    this.setState({ color: event.target.value });
  };
  guardarGPS = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      estatus: this.state.estatus,
      color: this.state.color,
    });
  }
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", {
      nombre: this.state.nombre,
      estatus: this.state.estatus,
      color: this.state.color,
    });
  }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.nombre} </h1>
        ) : null}
        <div class="row">
          <div className="col col-12 col-sm-12 col-md-4">
            <Form.Group label="id">
              <Form.Input
                name="id"
                disabled
                type="number"
                value={this.state.id}
              />
            </Form.Group>
          </div>
          <div className="col col-12 col-sm-12 col-md-4">
            <Form.Group label="Nombre">
              <Form.Input
                name="nombre"
                type="text"
                value={this.state.nombre}
                onChange={this.changeName}
                maxlength="50"
              />
            </Form.Group>
          </div>
          <div className="col col-12 col-sm-12 col-md-4">
            <Form.Group label="Estatus">
              <Form.Switch
                name="estatus"
                label="Activo"
                value={this.state.estatus}
                checked={this.state.estatus}
                onChange={this.changeEstatus}
              />
            </Form.Group>
          </div>
          <div className="col col-12 col-sm-12 col-md-4">
            <Form.Group label="Color">
              <input
                type="color"
                name="color"
                onChange={this.changeColor}
                value={this.state.color}
              />
            </Form.Group>
          </div>
        </div>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarGPS}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default GPSForm;
