import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {OperacionesClientesForm}  from "../../Catalogos";

function OperacionesClientesPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
        <Catalogo Ctlogo="operacionesClientes" tamanio="45%" FCtlogo= { OperacionesClientesForm } catalogos= {{ 0: "operacionesClientes"} } />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default OperacionesClientesPage;
