import React, { useEffect, useState } from "react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";

const WatsAppGrupos =({corredor, plataforma})=> {
  const [corredoresWhatsappGrupos, setContactosCorredoresWhatsappGrupos]= useState([]);
  const [whatsappGrupos, setWhatsappGrupos]= useState([]);
  const [whatsappGrupo, setWhatsappGrupo]= useState([]);
  const [agregar, setAgregar]= useState(false);

  useEffect(() => {
    getfuntion("get", "", "", "whatsapp_grupos/corredor/"+plataforma.id+"/"+corredor.id, "corredores_whatsapp_grupos");
	}, []);
 const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          if (stateVar === "corredores_whatsapp_grupos") {
            let corredoresWhatsappGruposTemp= [];
            for(let c of returnVal){
              c.fecha= timeToDateFill(c.fecha);
              corredoresWhatsappGruposTemp.push(c);
            }
            setContactosCorredoresWhatsappGrupos(corredoresWhatsappGruposTemp);
          }
          if (stateVar === "whatsapp_grupos") {
            let whatsappGruposTemp= [];
            for(let c of returnVal){
              c.fecha= timeToDateFill(c.fecha);
              whatsappGruposTemp.push(c);
            }
            setWhatsappGrupos(whatsappGruposTemp);
          }
          break;
        case "post":
          if (stateVar === "corredores_whatsapp_grupos") {
            getfuntion("get", "", "", "whatsapp_grupos/corredor/"+plataforma.id+"/"+corredor.id, "corredores_whatsapp_grupos");
            setAgregar(false);
          }
          break;
        case "delete":
          if (stateVar === "corredores_whatsapp_grupos") {
            getfuntion("get", "", "", "whatsapp_grupos/corredor/"+plataforma.id+"/"+corredor.id, "corredores_whatsapp_grupos");
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  return (
    
    <div className= "mt-1">
      <div hidden={ agregar }>
        <h4>Grupos de whatsApp asignados</h4>
        <TablePageable
          titulo={
            <div className="row">
              <div className="col">
                <i className="fa fa-plus"  style={{"font-size": "15px"}}
                  onClick={()=> {
                    setAgregar(true);
                    getfuntion("get", "", "", "whatsapp_grupos/plataforma/"+plataforma.id, "whatsapp_grupos");
                  }}
                ></i>
              </div>
              <div className="col">
                <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                  onClick={()=> getfuntion("get", "", "", "whatsapp_grupos/corredor/"+plataforma.id+"/"+corredor.id, "corredores_whatsapp_grupos") }
                ></i>
              </div>
            </div>
          }
          lista= { corredoresWhatsappGrupos }
          columnas={
            [
              { columna: "remover", label:"Remover",
                form: (e)=>
                  <i className="fa fa-remove" style={{"font-size": "24px"}}
                    onClick={()=>{
                      getfuntion(
                        "delete",
                        "",
                        plataforma.id+"/"+corredor.id+"?idWhatsappGrupo="+e.id,
                        "corredores_whatsapp_grupos",
                        "corredores_whatsapp_grupos");
                    }}>
                  </i> },
              { columna: "id", label:"Id" },
              { columna: "nombreGrupo", label:"Grupo" },
              { columna: "estatus", label:"Estatus" },
              { columna: "fecha", label:"Fecha" },
            ]
          }
          id= "id"
          selected={(e)=> setWhatsappGrupo(e)}
        />
      </div>
      <div hidden={ !agregar }>
        <TablePageable
          titulo={
            <h4>Grupos de la plataforma {plataforma.nombre}
              <button type="button" class="btn btn-danger ml-3" onClick={()=> setAgregar(false) }>
                Cancelar
              </button>
            </h4> }
          lista= { whatsappGrupos }
          columnas={
            [
              { columna: "agregar", label:"Agregar",
                form: (e)=>
                  <i className="fa fa-plus" style={{"font-size": "24px"}}
                    onClick={()=>{
                      const cwt=
                        {
                          idPlataforma: plataforma.id,
                          idCorredor: corredor.id,
                          idWhatsappGrupo: e.id,
                          fecha: null
                        };
                      getfuntion("post", cwt, "", "corredores_whatsapp_grupos", "corredores_whatsapp_grupos");
                    }}>
                  </i> },
              { columna: "id", label:"Id" },
              { columna: "nombreGrupo", label:"Grupo" },
              { columna: "estatus", label:"Estatus" },
              { columna: "fecha", label:"Fecha" },
            ]
          }
          id= "id"
          selected={(e)=> setWhatsappGrupo(e)}
        />
      </div>
    </div>
          
  );
}
export default WatsAppGrupos;