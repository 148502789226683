import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs from "../../components/TabsLayout.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import Select from 'react-select';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class Funcionalidad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Funcionalidades: [],
      search: "",
      bloque: { value: 0, label: "Selecciona una opción" }
    };
    this.Funcionalidades = [];
    this.perfilesObject = [];
    this.checarFuncionalidades= 0;      //temporal render
    this.idPerfilesFuncionalidades = 0; //temporal render
    this.perfilesFuncionalidadesObject = [];
    this.FuncionalidadesArray = [];

    this.bloques=  [];
    let terrestre= false;
    let naviera= false;
    for(let item of currentUser ){
      switch ( item.plataforma.plataformaTipo.id ) {
        case 1:    naviera= true;   break;
        case 3:    naviera= true;   break;
        case 2:  terrestre= true; break; 
        default:
          break;
      }
    }
    if(naviera){
      this.bloques.push({ value: 1, label: "NAVIERA", opcion: "navieraMovimientos" });
      this.bloques.push({ value: 3, label: "perfil", opcion: "perfilNaviera" });
    }
    if(terrestre){
      this.bloques.push( { value: 2, label: "TERRESTRE", opcion: "terrestreMovimientos" } );
    }
  }

  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({ id: this.props.elementos.id });
      this.perfilesObject = this.props.elementos;
      //console.log("this.props.elementos.id:"+this.props.elementos.id);

          for( let item  of this.bloques ){
            if( currentUser[0].plataforma.plataformaTipo.nombre.toUpperCase().includes(item.label.toUpperCase()) ){
              this.setState({ bloque: item });
              break;
            }
          }
     this.getfuntion("get", "", "", "perfilesFuncionalidades/"+ this.props.elementos.id, "Funcionalidades");
    } else { }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      //console.log("returnVal");
      //console.log(returnVal);
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Funcionalidades") {
              this.Funcionalidades = returnVal;
              this.setState({ Funcionalidades: returnVal });
            }
          }
          break;
        case "post":
          {
            if (stateVar === "Funcionalidades") {
              this.Funcionalidades.push(returnVal);
              this.setState({ Funcionalidades: this.Funcionalidades });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Funcionalidades") {
              this.Funcionalidades.map((elemento, index) => {
                if (id === elemento.id) {
                  this.Funcionalidades.splice(index, 1);
                }
              }
              )
              this.setState({ Funcionalidades: this.Funcionalidades })
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ...."+ err);
        //console.log(err)
      }
    })
  }


  changeFuncionalidades = (FuncionalidadIndex, OtdId, OtdStatus) => {
    this.perfilesFuncionalidadesObject = {
      "idPerfil": this.perfilesObject.id,
      "idFuncionalidad": this.props.stor.funcionalidades_object.filter( word => word.bloque.nombre.includes( this.state.bloque.opcion ) &&
                                                                        word.nombre.toUpperCase().includes(this.state.search.toUpperCase()) ).sort()[FuncionalidadIndex].id
    }

    this.FuncionalidadesArray[FuncionalidadIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.FuncionalidadesArray[FuncionalidadIndex].id, "perfilesFuncionalidades", "Funcionalidades")
      :
      this.getfuntion("post", this.perfilesFuncionalidadesObject, "", "perfilesFuncionalidades", "Funcionalidades");
  }
  checkedFuncionalidades = (indice) => {
    //console.log("INdice "+this.FuncionalidadesArray[indice].estatus);

    return this.FuncionalidadesArray[indice].estatus
  }
  checarFuncionalidad = (estatus, id) => {
    this.FuncionalidadesArray.push({ estatus: estatus, id: id })
  }
  checarFuncionalidadesTeam = (valor) => {
    this.checarFuncionalidades = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.FuncionalidadesArray = [];
  }
  changeBloque = (event) => {
    this.setState({ bloque: event });
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Columnas Habilitadas en Pantalla de Monitoreo" isCollapsible={false} isCollapsed={false}//isClosable
                  options={<Form.Group>
                    <span className="float-right">
                      <Form.Input className="mb-3" icon="search" placeholder="Buscar Nombre Columna..." position="append" onChange={this.searching} />
                    </span>
                    <div className="float-right" style={{ "width": "160px" }}>
                      <Select value={ this.state.bloque } onChange={this.changeBloque} options={ this.bloques } />
                    </div>

                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>LABEL</Table.ColHeader>
                            <Table.ColHeader>Bloque</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.funcionalidades_object
                                .filter(word => word.bloque.nombre.includes( this.state.bloque.opcion ) &&
                                                word.nombre.toUpperCase().includes(this.state.search.toUpperCase())
                                                ).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>{elemento["label"]}</Table.Col>
                                  <Table.Col>{elemento.bloque.nombre}</Table.Col>
                                  {this.checarFuncionalidadesTeam(0)}
                                  {this.idPerfilesFuncionalidades = ""}
                                  {
                                    this.state.Funcionalidades.map((elemFuncionalidad, ind) => {
                                      if (elemFuncionalidad.idFuncionalidad === elemento.id) {

                                        { this.checarFuncionalidadesTeam(1) }
                                        this.idPerfilesFuncionalidades = elemFuncionalidad.id;
                                      }
                                    })
                                  }
                                  {this.checarFuncionalidad(this.checarFuncionalidades, this.idPerfilesFuncionalidades)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                    checked={this.checkedFuncionalidades(index)}
                                    className={"disabled"}
                                    onChange={() => this.changeFuncionalidades(index, elemento["id"], this.checarFuncionalidades)} /></Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}
      </div>
    )
  }
}
export default Funcionalidad;
