import React, { useState, useEffect } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Table, Icon, Form } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToDatefull } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";
import { connect } from 'react-redux';
import * as ACTIONS from '../../store/actions/actions';
import { timeToDateFill } from "../../herramientas/DateFormat";


const currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
let usuariosList=[];
const DashBoardUsersPage = ({dashboard_usuarios_object}) => {
  const [ usuarios, setUsuarios ] = useState( []);

  useEffect(() => {
    update();
    //Object.keys(  ).forEach((key2, indice2) => {    });
  },[]);
  const update=()=>{
    setTimeout(() => {  
      let usuariosTemp=[];
      Object.keys( dashboard_usuarios_object  ).forEach((key, indice) => {
        usuariosTemp.push({ user: dashboard_usuarios_object[key][0], sesiones: dashboard_usuarios_object[key]  });
      });
      setUsuarios(usuariosTemp);
      update();
    },2000);
  }

  return (
    <SiteWrapper>
      <h2>Dashboard de usuarios count({usuarios.length})</h2>
      <Grid.Row>
          <Grid.Col>
            <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-light text-dark">
              <Table.Header>
                <Table.Col>id</Table.Col>
                <Table.Col>nombre</Table.Col>
                <Table.Col>locations</Table.Col>                      
              </Table.Header>
              <Table.Body>
                { usuarios.map((u, index)=>
                  <Table.Row>
                    <Table.Col>{u.user.id_usuario}</Table.Col>
                    <Table.Col>{u.user.nombre}</Table.Col>
                    <Table.Col>
                      <ul>
                        { u.sesiones.map((s, indes2)=>
                          <li className={
                            (new Date().getTime()-s.lastUpdate)<= (25*1000)? 'text-success'
                              : (new Date().getTime()-s.lastUpdate) > (25*1000) && (new Date().getTime()-s.lastUpdate) <= (35*1000) ? 'text-warning': 'text-danger'}>
                            { "["+timeToDateFill(s.lastUpdate) +"] ----"+ s.location }
                          </li>
                        )}
                      </ul>
                    </Table.Col>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Col>
      </Grid.Row>


      
    </SiteWrapper>
  );

}

function mapStateToProps(state) {
  return {
    dashboard_usuarios_object: state.catalogos_reducer.dashboard_usuarios_object,
  }
}
function mapDispachToProps(dispach) {
  return {
    updateDashboardUsuarios: (usuarios) => dispach(ACTIONS.update_dashboard_usuarios(usuarios)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(DashBoardUsersPage);
