import React, { Component } from "react";
import { Page, Card, Icon, Form, Button, Dropdown, Table } from "tabler-react";
class AsignarGpsView extends Component {
    constructor() {
        super();
        this.state = {
            plataformas:[],
            
        };

    }
    getFuncionalidad=(elemento)=>{
        if(!this.props.funcionalidades){ return false; }
        for(let item of this.props.funcionalidades){
          if(item.nombre === elemento ){ return true; break;}
        }
        return false;
      }
    render() {
        return (
            <Page.Card>
              { this.props.bloque == "Asignar_GPS" && this.getFuncionalidad("Asignar GPS")?
                <div>
                    <Form.Group label="Selecciona la Plataforma">
                        <Form.Select>
                            <option value={0}>Seleccione una Opción</option>
                            <option value={1}>Plataforma</option>
                        </Form.Select>
                    </Form.Group>
                    <Table cards={true} striped={true}  responsive={true}className="table-vcenter">
                        <Table.Header align="center">
                            <Table.ColHeader>ID MOVIMIENTO</Table.ColHeader>
                            <Table.ColHeader>PO</Table.ColHeader>
                            <Table.ColHeader>CONTENEDOR</Table.ColHeader>
                            <Table.ColHeader>CITA</Table.ColHeader>
                            <Table.ColHeader>TRANSPORTISTA</Table.ColHeader>
                            <Table.ColHeader>CORREDOR</Table.ColHeader>
                            <Table.ColHeader>MOVIMIENTO</Table.ColHeader>
                            <Table.ColHeader>AGREGAR GPS</Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row align="center">
                                <Table.Col> 1 </Table.Col>
                                <Table.Col>po</Table.Col>
                                <Table.Col>cont</Table.Col>
                                <Table.Col>date</Table.Col>
                                <Table.Col>nombre</Table.Col>
                                <Table.Col>MxCorredor</Table.Col>
                                <Table.Col>importación</Table.Col>
                                <Table.Col >GPS</Table.Col>
                            </Table.Row>                        
                        </Table.Body>                    
                    </Table>
                </div>
              :null}  
            </Page.Card>
        );
    }
}

export default AsignarGpsView;
