import React, { Component } from "react";
import { Button, Icon, Table } from "tabler-react";
import { AlertaNForm, AlertaTForm } from "./index";
import MovimientoIncidencias from "../MovimientoIncidencias";
import MovimientoIncidenciasTerrestre from "../MovimientoIncidenciasTerrestre";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";
import { ArrobaIncidencia } from "../Arroba";
import { Exportar } from "../../../herramientas/Excel";
import IncidenciasTraxion from "../../Monitoreo/IncidenciasTraxion";
import ModalLay from "../../../components/ModalLay/ModalLay";

/**
 * Date tools
 */
import { timeToDateFill } from "../../../herramientas/DateFormat";
import { confirmAlert } from "react-confirm-alert";

var user = JSON.parse(sessionStorage.getItem("usuario"));
let time= new Date().getTime()-(1000*60*60*5);

//Formato de Fecha
function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}
function myFormato(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}
let deshab= false;
class AlertasListar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showIncidenciaF: false,
      incidencias: [],
      hiddenModal: false,
      entregas: [],
      entregasActiva: "",
      arrayEActivas: [],
      filas: [],      
    };
    this.encabezados = [];
    this.aPlacas = [];
    this.alertas = [];
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  };
  movimientoTerrestrePlaca(id) {
    let temporal = "";
    if (id) {
      temporal = this.props.movimientos
        ? this.props.movimientos.filter((word) => word.id === id)
        : [];
      if (temporal[0]) {
        return temporal[0].placa;
      } else {
        try {
          temporal = this.props.gps[id] ? this.props.gps[id].nombre : "";
        } catch (e) {
          console.log("Error al obtener la placa: " + e);
        }
        if (temporal != "") {
          return temporal;
        }
      }
    }
  }
  getMovimiento(id) {
    let movTem = this.props.movimientos
      ? this.props.movimientos.filter((word) => word.id === id)
      : [];
    if (movTem[0]) {
      return movTem[0];
    } else {
      return null;
    }
  }
  movimientoTerrestreEco(id) {
    if (id) {
      try {
        if (this.props.gps[id].iconBattery.color) {
          return (
            <div>
              <div
                className="badge"
                style={{
                  color: this.props.gps[id].iconBattery.color,
                  "font-size": "80%",
                }}
              >
                {this.props.gps[id].nombre}
              </div>
              <div
                className="badge"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  this.props.gps[id].nombre +
                  ", " +
                  this.props.gps[id].nivel_bateria +
                  " %"
                }
                style={{
                  color: this.props.gps[id].iconBattery.color,
                  "font-size": "70%",
                }}
              >
                <span className={this.props.gps[id].iconBattery.icono}></span>
              </div>
              <div
                className="badge"
                data-toggle="tooltip"
                data-placement="top"
                title={this.props.gps[id].ultimo_reporte}
                style={{
                  color: this.props.gps[id].iconTime.color,
                  "font-size": "70%",
                }}
              >
                <span className={this.props.gps[id].iconTime.icono}></span>
              </div>
            </div>
          );
        }
      } catch (e) {}
    }
  }

  movimientoTerrestreEActiva(id) {
    let entrGas = [];
    entrGas = this.state.arrayEActivas.filter(
      (word) => word.idMovimiento == id
    );
    if (entrGas) {
      //entrAtva=entrGas.filter(word => word.estatus==1);
      return entrGas.idEntregaActiva;
    }
    /*
    let entrAtva = [];

    if (this.props.entregas){
      entrGas = this.props.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    else//if (this.state.entregas)
    {
      entrGas = this.state.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    if (entrAtva[0]){
      return entrAtva[0];
    }else{
      return 0;
    }
    */
  }
  isDisable =()=>{
    if(deshab){
      return true;
    }else{
      return false;
    }
  }
  alertaTer = async (tmpAlerta, index) =>  {
    deshab= true;
    tmpAlerta.atendida = 1;
    tmpAlerta.atiendeFecha = timeToDateFill(new Date().getTime());
    tmpAlerta.atiendeUsuario = this.props.usuario.id;
    await this.props.getfuntion(
      "put",
      tmpAlerta,
      "",
      "terrestresAlertas",
      "",
      this.props.closeModal
    ).then((respuesta)=>{
      deshab= false; 
    });
    
    this.state.filas.splice(index, 1);
    this.setState({ filas: this.state.filas });
  }

  alertaNav = async (alertN) => {
    deshab= true;
    alertN.atendida = 1;
    alertN.atiendeFecha = timeToDateFill(new Date().getTime());
    alertN.atiendeUsuario = this.props.usuario.id;
    await this.props.getfuntion("put", alertN, "", "navierasAlertas", "").then((respuesta)=>{
      if (respuesta) {
        deshab= false; 
      }
    });
  }
  ordenarAsc(p_array_json, p_key) {
    p_array_json.sort(function (a, b) {
      return a[p_key] > b[p_key];
    });
  }
  getAlertas() {
    this.setState({ filas: [] });
    let filasTemp = [];
    if (this.props.accion === "modificar") {
      this.encabezados = [
        { content: "MovId" },
        { content: "Placa" },
        { content: "AlertaId" },
        { content: "Triggername" },
        { content: "Geocerca" },
        { content: "Fecha-GPSGate" },
        { content: "Atender" },
        { content: "incidencia" },
        { content: "Detalle" },
        { content: "Ubicacion" },
      ];
      if (this.props.alerta.length > 0) {
        if (this.props.plataformaTipo === "Naviera") {
          this.props.alerta
            .sort(function (a, b) {
              if (a.alert["fechaRegistro"] > b.alert["fechaRegistro"]) {
                return -1;
              }
              if (a.alert["fechaRegistro"] < b.alert["fechaRegistro"]) {
                return 1;
              }
              return 0;
            })
            .map((a, index) => {
              let alert = a.alert;
              let gps = this.props.gpsReciente
                ? this.props.gpsReciente[alert.idMovimiento]
                  ? this.props.gpsReciente[alert.idMovimiento]
                  : null
                : null;
              filasTemp.push(
                <Table.Row key={alert.id}>
                  <Table.Col>{alert.idMovimiento}</Table.Col>
                  {/* <Table.Col>{this.movimientoTerrestreEco(alert.idMovimiento)}</Table.Col> */}
                  <Table.Col>
                    {this.movimientoTerrestrePlaca(alert.idMovimiento)}
                  </Table.Col>
                  <Table.Col>{alert.id}</Table.Col>
                  <Table.Col>{alert.triggerName}</Table.Col>
                  <Table.Col>{alert.triggerValue}</Table.Col>
                  <Table.Col>{alert.fechaRegistro}</Table.Col>
                  <Table.Col>
                    <Button
                      pill
                      size="sm"
                      outline
                      color="primary"
                      onClick={() =>
                        this.props.plataformaTipo === "Naviera"
                          ? this.alertaNav(alert)
                          : this.alertaTer(alert, index)
                      }
                      disabled={this.isDisable()}
                    >
                      <div
                        className={"fa fa-bell"}
                        style={{ color: "blue" }}
                      ></div>
                    </Button>
                  </Table.Col>
                  <Table.Col>
                    <ModalLay
                      tamanio={"50%"}
                      title="Nueva Incidencia"
                      name={
                        <Button pill size="sm" outline color="primary">
                          <Icon name="plus" />
                        </Button>
                      }
                      formulario={MovimientoIncidencias}
                      datosForm={{
                        accion: "nuevo",
                        idusuario: user.id,
                        idmovimiento: alert.idMovimiento,
                        movimiento: this.getMovimiento(alert.idMovimiento),
                        incidencias: null,
                        getfuntion: this.getfuntion,
                        stor: this.props.stor,
                        plataformaTipo: this.props.plataformaTipo,
                        closeModal: this.hiddenmodal,
                        movimientoFull: this.props.MovimientosFull[
                          alert.idMovimiento
                        ]
                          ? this.props.MovimientosFull[alert.idMovimiento].movimiento
                          : null,
                        gpsReciente: null,
                        latitud: alert.latitud,
                        longitud: alert.longitud,
                      }}
                    />
                  </Table.Col>
                  <Table.Col>
                    {
                      //Detalle de Alerta
                      <ModalLay
                        tamanio={"60%"}
                        title="Atender Alerta"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <div
                              className={"fa fa-bell"}
                              style={{ color: "green" }}
                            ></div>
                          </Button>
                        }
                        formulario={AlertaNForm}
                        datosForm={{
                          accion: "modificar",
                          usuario: this.props.usuario,
                          alerta: alert,
                          getfuntion: this.getfuntion,
                          idMovimiento: alert.idMovimiento,
                          idEntregaActiva: this.movimientoTerrestreEActiva(
                            alert.idMovimiento
                          ),
                          stor: this.props,
                          closeModal: this.hiddenmodal,
                          Fclose: this.hiddenmodal,
                          IncidenciasFrom: (
                            <MovimientoIncidencias
                              accion={"nuevo"}
                              idusuario={user.id}
                              idmovimiento={alert.idMovimiento}
                              movimiento={this.getMovimiento(
                                alert.idMovimiento
                              )}
                              incidencias={null}
                              getfuntion={this.getfuntion}
                              stor={this.props.stor}
                              plataformaTipo={this.props.plataformaTipo}
                              closeModal={this.hiddenmodal}
                              movimientoFull={
                                this.props.MovimientosFull[alert.idMovimiento]
                                  ? this.props.MovimientosFull[
                                      alert.idMovimiento
                                    ].full
                                  : null
                              }
                              gpsReciente={null}
                              latitud={alert.latitud}
                              longitud={alert.longitud}
                            />
                          ),
                        }}
                      />
                    }
                  </Table.Col>
                  <Table.Col>
                    {
                      <Button
                        pill
                        size="sm"
                        outline
                        color="primary"
                        onClick={() =>
                          window.open(
                            "https://maps.google.com/?q=" +
                              alert.latitud +
                              "," +
                              alert.longitud +
                              "&t=m",
                            "sharer",
                            "width=900,height=600,scrollbars=NO"
                          )
                        }
                      >
                        <div
                          className={"fa fa-map-marker"}
                          style={{ color: "green|" }}
                        ></div>
                      </Button>
                    }
                  </Table.Col>
                </Table.Row>
              );
            });
        } else {
          this.props.alerta.map((a, index) => {
            let alert = a.alert;
            let gps = this.props.gpsReciente
              ? this.props.gpsReciente[alert.idMovimiento]
                ? this.props.gpsReciente[alert.idMovimiento]
                : null
              : null;
            filasTemp.push(
              <Table.Row>
                <Table.Col>{alert.idMovimiento}</Table.Col>
                {/* <Table.Col>{this.movimientoTerrestreEco(alert.idMovimiento)}</Table.Col> */}
                <Table.Col>
                  {this.movimientoTerrestrePlaca(alert.idMovimiento)}
                </Table.Col>
                <Table.Col>{alert.id}</Table.Col>
                <Table.Col>{alert.triggerName}</Table.Col>
                <Table.Col>{alert.triggerValue}</Table.Col>
                <Table.Col>{alert.fechaRegistro}</Table.Col>
                <Table.Col>
                  <Button
                    pill
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => 
                      this.props.plataformaTipo === "Naviera"
                        ? this.alertaNav(alert)
                        : 
                        this.alertaTer(alert, index) 
                    }
                    disabled={this.isDisable()}
                  >
                    <div
                      className={"fa fa-bell"}
                      style={{ color: "blue" }}
                    ></div>
                  </Button>
                </Table.Col>
                <Table.Col>
                  {this.getMovimiento(alert.idMovimiento) && (
                    <ModalLay
                      tamanio={"50%"}
                      title="Nueva Incidencia"
                      name={
                        <Button pill size="sm" outline color="primary">
                          <Icon name="plus" />
                        </Button>
                      }
                      formulario={
                        this.getMovimiento(alert.idMovimiento)
                          .plataforma.nombre.toUpperCase()
                          .includes("TRAXION")
                          ? IncidenciasTraxion
                          : MovimientoIncidenciasTerrestre
                      }
                      datosForm={{
                        accion: "nuevo",
                        idusuario: user.id,
                        idmovimiento: alert.idMovimiento,
                        movimiento: this.getMovimiento(alert.idMovimiento),
                        idplataforma: this.getMovimiento(alert.idMovimiento)
                          .plataforma.id,
                        stor: this.props.stor,
                        getfuntion: this.getfuntion,
                        plataformaTipo: 2,
                        gpsReciente: gps,
                        latitud: alert.latitud,
                        longitud: alert.longitud,
                        closeModal: this.hiddenmodal,
                        unidad: null,
                        idEntregaActiva: null,
                        entregas: null,
                        desde: "EspecificoTerrestre",
                      }}
                    />
                  )}
                </Table.Col>
                <Table.Col>
                  {
                    //Detalle de Alerta
                    <ModalLay
                      tamanio={"60%"}
                      title="Atender Alertas Terrestres"
                      name={
                        <Button pill size="sm" outline color="primary">
                          <div
                            className={"fa fa-bell"}
                            style={{ color: "green" }}
                          ></div>
                        </Button>
                      }
                      formulario={AlertaTForm}
                      datosForm={{
                        accion: "modificar",
                        usuario: this.props.usuario,
                        alerta: alert,
                        getfuntion: this.getfuntion,
                        idMovimiento: alert.idMovimiento,
                        idEntregaActiva: this.movimientoTerrestreEActiva(
                          alert.idMovimiento
                        ),
                        stor: this.props,
                        closeModal: this.hiddenmodal,
                        Fclose: this.hiddenmodal,
                        IncidenciasFrom: (
                          <MovimientoIncidenciasTerrestre
                            accion={"nuevo"}
                            desde={"AlertasListar"}
                            idusuario={this.props.usuario.id}
                            idmovimiento={alert.idMovimiento}
                            movimiento={this.state.movimiento}
                            incidencias={this.state.incidenciasAll}
                            getfuntion={this.getfuntion}
                            stor={this.props}
                            idEntregaActiva={this.state.idEntregaActiva}
                            plataformaTipo={this.props.plataformaTipo}
                            gpsReciente={gps}
                            latitud={alert.latitud}
                            longitud={alert.longitud}
                            closeModal={this.hiddenmodal}
                          />
                        ),
                      }}
                    />
                  }
                </Table.Col>
                <Table.Col>
                  {
                    <Button
                      pill
                      size="sm"
                      outline
                      color="primary"
                      onClick={() =>
                        window.open(
                          "https://maps.google.com/?q=" +
                            alert.latitud +
                            "," +
                            alert.longitud +
                            "&t=m",
                          "sharer",
                          "width=900,height=600,scrollbars=NO"
                        )
                      }
                    >
                      <div
                        className={"fa fa-map-marker"}
                        style={{ color: "green|" }}
                      ></div>
                    </Button>
                  }
                </Table.Col>
              </Table.Row>
            );
          });
        }
      } else {
        filasTemp.push(<Table.Row>No Hay Alertas por Atender</Table.Row>);
      }
    } else {
      //this.props.accion==="Atendidas"
      this.encabezados = [
        { content: "MovId" },
        { content: "AlertaId" },
        { content: "Tiggername" },
        { content: "UsuarioId" },
        { content: "Atención Fecha" },
      ];
      if (this.props.alerta.length > 0) {
        if (this.props.plataformaTipo === "Naviera") {
          this.props.alerta
            .sort(function (a, b) {
              if (a.alert["fechaRegistro"] > b.alert["fechaRegistro"]) {
                return -1;
              }
              if (a.alert["fechaRegistro"] < b.alert["fechaRegistro"]) {
                return 1;
              }
              return 0;
            })
            .map((a, index) => {
              let alert = a.alert;
              filasTemp.push(
                <Table.Row>
                  <Table.Col>{alert.idMovimiento}</Table.Col>
                  <Table.Col>{alert.id}</Table.Col>
                  <Table.Col>{alert.triggerName}</Table.Col>
                  <Table.Col>{alert.atiendeUsuario}</Table.Col>
                  <Table.Col>{alert.atiendeFecha}</Table.Col>
                </Table.Row>
              );
            });
        } else {
          this.props.alerta
            .sort(function (a, b) {
              if (a["fechaRegistro"] > b["fechaRegistro"]) {
                return -1;
              }
              if (a["fechaRegistro"] < b["fechaRegistro"]) {
                return 1;
              }
              return 0;
            })
            .map((a, index) => {
              let alert = a.alert;
              filasTemp.push(
                <Table.Row>
                  <Table.Col>{alert.idMovimiento}</Table.Col>
                  <Table.Col>{alert.id}</Table.Col>
                  <Table.Col>{alert.triggerName}</Table.Col>
                  <Table.Col>{alert.atiendeUsuario}</Table.Col>
                  <Table.Col>{alert.atiendeFecha}</Table.Col>
                </Table.Row>
              );
            });
        }
      } else {
        filasTemp.push(<Table.Row>No Hay Alertas Atendidas</Table.Row>);
      }
    }
    this.setState({ filas: filasTemp });
  }
  componentDidMount() {
    this.alertas = this.props.alerta;
    this.getAlertas();
  }
  componentDidUpdate = () => {
    if (this.alertas !== this.props.alerta) {
      this.alertas = this.props.alerta;
      this.getAlertas();
    }
  };

  atenderAlertasPorCategoria = (plataforma_tipo, id_usuario, id_plataforma, id_alerta_categoria) => {   
    if(plataforma_tipo === "Naviera") {      
      this.getfuntion("put", {}, "", "navierasAlertas/atenderAlertasPorCategoria/" + id_usuario + "/" + id_plataforma + "/" + id_alerta_categoria, "atender_alertas");      
    } else { 
      this.getfuntion("put", {}, "", "terrestresAlertas/atenderAlertasPorCategoria/" + id_usuario + "/" + id_plataforma + "/" + id_alerta_categoria , "atender_alertas");
    }
  }

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    enviar
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "camposLogisticos") {
                return returnVal;
              }
              if (stateVar === "entregasActiva") {
                return returnVal;
              }
              if (stateVar === "incidenciasAll") {
                this.setState({ incidenciasAll: returnVal });
              }
              if (stateVar === "entregas") {
                if (returnVal) {
                  this.setState({ entregas: returnVal });
                }
                return returnVal;
              }
              if (stateVar === "incidenciasTerrestre") {
                this.incidenciasTerrestre = returnVal;
                this.setState({ incidenciasTerrestre: returnVal });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "incidencias") {
                let destMonitoreo = "";
                let destTransportistas = "";
                let destClientes = "";
                obj.destinosMonitoreo.forEach((key, index) => {
                  destMonitoreo = destMonitoreo + ", " + key.correo;
                });
                obj.destinosTransportistas.forEach((key, index) => {
                  destTransportistas = destTransportistas + ", " + key.correo;
                });
                obj.destinosClientes.forEach((key, index) => {
                  destClientes = destClientes + ", " + key.correo;
                });
                this.incidencias.unshift(returnVal);
                this.setState({ incidencias: this.incidencias });
                NotificationManager.info(
                  returnVal.comentario,
                  "Incidencia Nueva Naviera (" + returnVal.idMovimiento + ")"
                );
                this.usuariosLogObject.idBitacoraMonitoreo = 2;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = this.props.movimiento;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = myFormato(new Date());
                this.usuariosLogObject.descripcion =
                  returnVal.incidencia.nombre;
                Crud_Catalogos(
                  "usuariosLog",
                  this.catalogo,
                  "post",
                  "",
                  this.usuariosLogObject,
                  "",
                  "",
                  "",
                  ""
                );
                if (enviar && returnVal.id) {
                  ArrobaIncidencia(returnVal).then((arrobaInc) => {
                    NotificationManager.info(
                      arrobaInc,
                      this.privilegioDescripcion("arroba_incidencia")
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 6;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = this.props.movimiento;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Notificación de incidencia(" +
                      returnVal.incidencia.nombre +
                      "): " +
                      destMonitoreo +
                      " " +
                      destTransportistas +
                      " " +
                      destClientes +
                      " " +
                      arrobaInc;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      "",
                      ""
                    );
                  });
                }
                if (
                  this.state.movimiento.actualizarFull &&
                  this.state.movimientoFull
                ) {
                  let incidenciaFull = {
                    atencionFecha: null,
                    atencionUsuario: obj.atencionUsuario,
                    comentario: obj.comentario,
                    fechaCreado: null,
                    idMovimiento: this.state.movimientoFull,
                    incidencia: obj.incidencia,
                    latitud: obj.latitud,
                    longitud: obj.longitud,
                  };
                  incidenciaFull = this.getfuntion(
                    "post",
                    incidenciaFull,
                    "",
                    "movimientoIncidencias",
                    "newIncidenciaFull",
                    "",
                    enviar
                  );
                }
              }
            }
            break;
          case "put":
            {
              if (stateVar === "terrestresAlertas") {
                this.props.setAlerta(returnVal, "terrestresAlertas");
              }
              switch (catalogo) {
                case "terrestresAlertas":
                  this.props.setAlerta(returnVal, "terrestresAlertas");
                  obj.estatus = 1;
                  break;
                default:
                  break;
              }
              switch (stateVar) {
                case "terrestresAlertas":
                  NotificationManager.info(
                    returnVal.comentario,
                    "Terrestre, Alerta Atendida (" + returnVal.id + ")"
                  );
                  this.usuariosLogObject.idBitacoraMonitoreo = 4;
                  this.usuariosLogObject.idUsuario = user.id;
                  this.usuariosLogObject.idMovimiento = returnVal.idMovimiento;
                  this.usuariosLogObject.idPlataformaTipo = 2;
                  this.usuariosLogObject.accionFecha = myFormato(new Date());
                  this.usuariosLogObject.descripcion =
                    "Alerta Atendida id: " +
                    returnVal.id +
                    " de Tipo: " +
                    returnVal.triggerName +
                    " del Movimiento id: " +
                    returnVal.idMovimiento;
                  this.props.setAlerta(returnVal, "terrestresAlertas");
                  Crud_Catalogos(
                    "usuariosLog",
                    this.catalogo,
                    "post",
                    "",
                    this.usuariosLogObject,
                    "",
                    "",
                    this.hiddenmodal,
                    ""
                  );
                  break;
                case "updateFechas":
                  {
                    this.hiddenmodal();
                    NotificationManager.info(
                      "Se han registrado horarios en campos logisticos",
                      "Actualizacion, mov (" + returnVal.id + ")"
                    );
                    let cambios = this.getCambiosNav(id, returnVal); //id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
                    if (obj.actualizarFull) {
                      let mFull = this.props.MovimientosFull[
                        this.state.movimiento.id
                      ]
                        ? this.props.getMovimientoNavieroById(
                            this.state.movimientoFull
                          )
                        : this.props.MovimientosFull[this.state.movimientoFull]
                            .movimiento;
                      let campLogistObj = {
                        idOperacionEstatus: obj.idOperacionEstatus,
                        idRuta: obj.idRuta,
                        citaProgramada: obj.citaProgramada,
                        sello: mFull.sello,
                        contenedor: mFull.contenedor,
                        idOtd: obj.idOtd,
                        idCliente: obj.idCliente,
                        actualizarFull: obj.actualizarFull,
                        idMovimientoTipo: obj.idMovimientoTipo,
                        idOperacionTipo: obj.idOperacionTipo,
                        idRuta2: obj.idRuta2,
                        /*Exportacion*/
                        retiroContenedor: obj.retiroContenedor,
                        arriboClienteVacio: obj.arriboClienteVacio,
                        salidaClienteLleno: obj.salidaClienteLleno,
                        arriboTerminalCargado: obj.arriboTerminalCargado,
                        salidaPredioVacio: obj.salidaPredioVacio,
                        citaFechaDespacho: obj.citaFechaDespacho,
                        entradaCiudadPortuaria: obj.entradaCiudadPortuaria,
                        arriboPatioLinea: obj.arriboPatioLinea,
                        salidaTerminalVacio: obj.salidaTerminalVacio,
                        /**Importacion */
                        arriboTerminalVacio: obj.arriboTerminalVacio,
                        salidaTerminalLleno: obj.salidaTerminalLleno,
                        arriboClienteLleno: obj.arriboClienteLleno,
                        salidaClienteVacio: obj.salidaClienteVacio,
                        arriboTerminalVacio2: obj.arriboTerminalVacio2,
                        asignacionPiloto: obj.asignacionPiloto,
                        inicioRuta: obj.inicioRuta,
                        despachoPredioCliente: obj.despachoPredioCliente,
                        llegadaFrontera: obj.llegadaFrontera,
                        salidaFrontera: obj.salidaFrontera,
                        arriboAduana: obj.arriboAduana,
                        salidaAduana: obj.salidaAduana,
                      };
                      this.getfuntion(
                        "put",
                        campLogistObj,
                        mFull,
                        "navieraMovimientos/updateFechas/" + mFull.id,
                        "updateFechasFull"
                      );
                    }
                    this.usuariosLogObject.idBitacoraMonitoreo =
                      cambios.bitacora;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 1;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    //this.usuariosLogObject.descripcion = id;//en id vienen los campos modificados
                    //Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject);
                    this.usuariosLogObject.descripcion =
                      cambios.camposmodificados; //en id vienen los campos modificados
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject
                    );
                  }
                  break;
                case "terrestreMovimientos":
                  {
                    NotificationManager.info(
                      "Se Terminara el movimiento",
                      "Actualizacion, mov (" +
                        returnVal.id +
                        ") por Usuario: " +
                        user.id
                    );
                    this.usuariosLogObject.idBitacoraMonitoreo = 5;
                    this.usuariosLogObject.idUsuario = user.id;
                    this.usuariosLogObject.idMovimiento = returnVal.id;
                    this.usuariosLogObject.idPlataformaTipo = 2;
                    this.usuariosLogObject.accionFecha = myFormato(new Date());
                    this.usuariosLogObject.descripcion =
                      "Finalizado Movimiento id:" +
                      returnVal.id +
                      " por Usuario id: " +
                      user.id;
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                  break;
                case "entregas":
                  {
                    let entregasTem = this.state.entregas;
                    entregasTem.map((entre, index) => {
                      if (returnVal.idEntrega == entre.idEntrega) {
                        this.usuariosLogObject.idBitacoraMonitoreo = 3;
                        this.usuariosLogObject.idUsuario = user.id;
                        this.usuariosLogObject.idMovimiento =
                          returnVal.idMovimiento; //obj.id;//returnVal.id;
                        this.usuariosLogObject.idPlataformaTipo = 2;
                        this.usuariosLogObject.accionFecha = myFormato(
                          new Date()
                        );
                        this.usuariosLogObject.descripcion =
                          "Actualizacion Entrega: " +
                          entre.idEntrega +
                          " Movimiento id:" +
                          returnVal.idMovimiento +
                          " Campo: " +
                          entre.campo; //returnVal.id;
                        entre = returnVal;
                        if (returnVal.estatus == 1) {
                          this.setIdEntregaActiva(returnVal);
                        }
                        this.setState({ entregas: entregasTem });
                        NotificationManager.info(
                          "Se modifica entrega",
                          "Actualizacion, mov (" + returnVal.idMovimiento + ")"
                        );
                      }
                    });
                    Crud_Catalogos(
                      "usuariosLog",
                      this.catalogo,
                      "post",
                      "",
                      this.usuariosLogObject,
                      "",
                      "",
                      this.hiddenmodal,
                      ""
                    );
                  }
                  break;
                  case "atender_alertas":
                    {
                      window.location.reload();
                    }
                    break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log(err);
        }
      });
  };
  render() {    
    return (      
      <div>                
        <div className="navbar navbar-expand-sm p-2">
        <div style={{ "max-width": "35px" }}>
          <Exportar
            crud={this.getfuntion}
            catalogo={"Alertas"}
            data={this.props.alerta}
          />
        </div>        
        <div className="navbar-collapse collapse w-100 order-3 dual-collapse2 d-flex justify-content-end">
          {  this.props.accion === "modificar" && this.props.alerta.length && this.props.plataformaTipo === "Naviera" && this.props.categoria.nombre ?
            <button type="button" className="btn btn-danger" onClick={
              () => confirmAlert({
                title: 'Atender Alertas de ' + this.props.categoria.nombre,
                message: '¿Estas seguro de Atender todas las alertas a partir de las ' + timeToDateFill(time) +' hacia atrás?',
                buttons: [
                  {
                    label: 'Aceptar',
                    onClick: () => { this.atenderAlertasPorCategoria(this.props.plataformaTipo, user.id, this.getMovimiento(this.props.alerta[0].alert.idMovimiento).idPlataforma.id, this.props.categoria.id) } 
                  },
                  {
                    label: 'Cancelar',
                    onClick: () => { return false }
                  }
                ]
              })
            }>
              Atender Alertas Navieras
            </button>
          : this.props.accion === "modificar" && this.props.alerta.length && this.props.categoria.nombre ?
            <button type="button" className="btn btn-danger" onClick={
              () => confirmAlert({
                title: 'Atender Alertas de ' + this.props.categoria.nombre,
                message: '¿Estas seguro de Atender todas las alertas a partir de las ' + timeToDateFill(time) +' hacia atrás?',
                buttons: [
                  {
                    label: 'Aceptar',
                    onClick: () => { this.atenderAlertasPorCategoria(this.props.plataformaTipo, user.id,this.getMovimiento(this.props.alerta[0].alert.idMovimiento).plataforma.id, this.props.categoria.id) } 
                  },
                  {
                    label: 'Cancelar',
                    onClick: () => { return false }
                  }
                ]
              })
            }>
              Atender Alertas Terrestres
            </button>
            : null
          }
          
        </div>
        </div>       
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter"
          headerItems={this.encabezados}
        >
          <Table.Body>{this.state.filas}</Table.Body>
        </Table>
      </div>
    );
  }
}
export default AlertasListar;
 