import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table, Alert } from "tabler-react";

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import '../navBarItems/MonitoreoPage.react.css';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import ModalLay from "../../components/ModalLay/ModalLay";
import { TransportistasForm } from "../../Catalogos";
import LoadStore from "../../store/LoadStore";
import { timeToDateFill } from "../../herramientas/DateFormat";
import Cliente from "./Cliente";
import Transportista from "./Transportista";
const user = JSON.parse(sessionStorage.getItem("usuario"));
const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));


class MovimientosNavierosClientePage extends Component {
  constructor(props) { 
    super(props);
    this.state = {
      plataformas: [],
      solicitudes: [],
      hiddenPanel: false,
      hiddenModals: false,
      currentMovimiento: {},
      gpsTipoColocaciones: [],
      gpsTipoColocacion: { value: 0, label: "Se lecciona una opción" },
      idCorredor: { value: 0, label: "No se ha seleccionado plataforma" },
      localidad: { value: 0, label: "No se ha seleccionado plataforma" },
      idCliente: { value: 0, label: "No se ha seleccionado plataforma" },
      idClienteNaviero: { value: 0, label: "No se ha seleccionado plataforma" },
      idMovimientoTipo: { value: 0, label: "No se ha seleccionado plataforma" },
      idPlataforma: { value: 0, label: "Selecciona una plataforma" },
      idTransportista: { value: 0, label: "No se ha seleccionado plataforma" },
      idOperacionTipo: { value: 0, label: "No se ha seleccionado plataforma" },
      noContenedores: 1,
      contenedor: [""],
      citaProgramada: "",
      accion: 'nuevo', //'nuevo'// modificar, nuevo
      corredores: [],
      localidades: [],      
      clientesNavieros: [],
      clientes: [],
      movimientosTipo: [],
      transportistas: [],
      contenedoresTipo: [],
      contenedorTipo: { value: 0, label: "Selecciona el tipo de carga", data: [],},
      remolquesTipo: [],//contenedorSize
      contenedorSize: { value: 0, label: "Selecciona el tamaño" },
      destino: "",
      nota: "",
      variables: [],
      ejecutivo: "",
      referencia_facturacion: "",
      referencia_chubb: "",
      cita_colocacion: null,
      cuenta_espejo:{
        url:"",
        usuario:"",
        password: ""},
      modalidad: false,
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.MovimientosObject = {
      idPlataforma: {},//requerido
      idNaviera: { id: "1", nombre: "Sin naviera" },//Default
      idOperacionTipo: {},//requerido
      idMovimientoTipo: {},//requerido
      idTransportista: null,//opcional
      idCliente: null,//opcional
      idClienteNaviero: null,//opcional
      idCorredor: {},//requerido
      idLocalidad: {},//requerido
      destino: "",//opcional
      contenedor: "",//requerido
      contenedorTipo: 1,
      contenedorSize: 0,
      booking: "",
      po: "",
      sello: "",
      vip: true,
      nota: "",
      citaProgramada: null,
      variables: {
        ejecutivo: "",
        referencia_facturacion: "",
        referencia_chubb: "",
        cita_colocacion: null,
        cuenta_espejo:{
          url:"",
          usuario:"",
          password: ""},
        modalidad: false
      },
      clienteS: null,
      transportistaS: null
    };
    this.estatusOperacion = [];
    this.estatusRutas = [];
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 1,
    };
  }
  isEnable = (Accion) => {
    //let borrar= "clientes_borrar";

    let actualizar = "movimientos_editar";
    let agregar = "movimientos_agregar";

    let actualizarCliente = "clientes_editar";
    let agregarCliente = "clientes_agregar";

    let actualizarTransportista = "transportistas_editar";
    let agregarTransportista = "transportistas_agregar";

    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "actualizarCliente") {
        if (privilegios[i].clave == actualizarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarCliente") {
        if (privilegios[i].clave == agregarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      if (Accion === "actualizarTransportista") {
        if (privilegios[i].clave == actualizarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarTransportista") {
        if (privilegios[i].clave == agregarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      //if(Accion === "borrar"){ if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "variables")
              this.setState({ variables: returnVal});
            if(stateVar === "solicitudes_cliente"){
              let solicitudesTmp= [];
              for(let v of returnVal){
                v.variables= JSON.parse(v.variables);
                solicitudesTmp.push(v);
              }
              this.setState({ solicitudes: solicitudesTmp});
            }
            if(stateVar === "plataformas" && returnVal){
              this.getfuntion("get", "", "", "gpsTipoColocacion", "gpsTipoColocacion");
              let plataformasTmp= [];
              for(let p of returnVal)
                plataformasTmp.push({value: p.id, label: p.nombre, data: p});
              this.setState({ plataformas: plataformasTmp});
              if(plataformasTmp.length === 1 )
                this.changePlataformas(plataformasTmp[0]);
            }
            if (stateVar === "gpsTipoColocacion") {
              let gpsTipoColocacionTemp = [];
              returnVal.map((gt, index) =>{
                if(gt.id !== 6)
                  gpsTipoColocacionTemp.push({  value: gt.id, label: gt.nombre, data: gt, })
              });
              this.setState({ gpsTipoColocaciones: gpsTipoColocacionTemp });
              this.setState({ gpsTipoColocacion: { value: 0, label: "Selecciona una opción"}, });
            }
            if (stateVar === "corredores") {
              let corredoresTemp = [];
              returnVal.map((corredor, index) =>
              corredoresTemp.push({
                  value: corredor.id,
                  label: corredor.nombre,
                  data: corredor,
                })
              );
              this.setState({ corredores: corredoresTemp });
              if (this.state.accion == "nuevo") {
                this.setState({
                  idCorredor: { value: 0, label: "Selecciona un corredor" },
                });
              }
            }
            if (stateVar === "localidades") {
              let localidadesTemp = [];
              returnVal.map((l, index) =>
                localidadesTemp.push({ value: l.id, label: l.nombre, data: l,})
              );
              this.setState({ localidades: localidadesTemp });
              if (this.state.accion == "nuevo") {
                this.setState({
                  localidad: { value: 0, label: "Selecciona una localidad" },
                });
              }
            }
            if (stateVar === "contenedorTipo") {
              let ContTipoTemp = [];
              returnVal.map((contTipo, index) => {
                if (this.state.accion == "nuevo") {
                  if (contTipo.id === 1)
                    this.setState({
                      contenedorTipo: {
                        value: contTipo.id,
                        label: contTipo.nombre,
                        data: contTipo,
                      },
                    });
                } else {
                  if (this.state.currentMovimiento.contenedorTipo === contTipo.id)
                    this.setState({
                      contenedorTipo: {
                        value: contTipo.id,
                        label: contTipo.nombre,
                        data: contTipo,
                      },
                    });
                }
                ContTipoTemp.push({
                  value: contTipo.id,
                  label: contTipo.nombre,
                  data: contTipo,
                });
              });
              this.setState({ contenedoresTipo: ContTipoTemp });
            }
            if (stateVar === "remolquesTipo") {
              let remolquesTipoTemp = [];
              returnVal.map((remTipo, index) => {
                if ( this.state.accion !== "nuevo" && this.state.currentMovimiento.contenedorSize === remTipo.idRemolque )
                    this.setState({ contenedorSize: { value: remTipo.idRemolque, label: remTipo.nombre, data: remTipo, }, });
                remolquesTipoTemp.push({ value: remTipo.idRemolque, label: remTipo.nombre, data: remTipo, });
              });
              this.setState({ remolquesTipo: remolquesTipoTemp });
              this.setState({ contenedorSize: { value: 0, label: "Selecciona una tamaño de remolque" },
              });
            }
            if (stateVar === "clientes") {
              let clientesTemp = [];
              if (returnVal.length > 0) {
                returnVal.map((client, index) =>
                clientesTemp.push({
                    value: client.id,
                    label: client.nombre,
                    data: client,
                  })
                );
                this.setState({ clientes: clientesTemp });
                if (this.state.accion == "nuevo") {
                  this.setState({
                    idCliente: { value: 0, label: "Selecciona un cliente" },
                  });
                }
              } else {
                this.setState({
                  idCliente: { value: 0, label: "No hay clientes" },
                });
              }
            }
            if (stateVar === "clientesNavieros") {
              let clientesNav = [];
              if (returnVal.length > 0) {
                returnVal.map((client, index) =>
                  clientesNav.push({
                    value: client.id,
                    label: client.nombre,
                    data: client,
                  })
                );
                this.setState({ clientesNavieros: clientesNav });
                if (this.state.accion == "nuevo") {
                  this.setState({
                    idClienteNaviero  : { value: 0, label: "Selecciona un cliente" },
                  });
                }
              } else {
                this.setState({
                  idClienteNaviero: { value: 0, label: "No hay clientes" },
                });
              }
            }
            if (stateVar === "operacionesTipo") {
              let operacionesTemp = [];
              returnVal.map((op, index) =>{
                operacionesTemp.push({
                  value: op.id,
                  label: op.nombre,
                  data: op,
                });
                if(op.id === 2 && this.state.accion == "nuevo"){
                  this.setState({ idOperacionTipo: operacionesTemp[index] });
                  this.MovimientosObject.idOperacionTipo = operacionesTemp[index].data;
                }
              });                
            }
            if (stateVar === "movimientosTipo") {
              let movimientoTiposTemp = [];
              returnVal.map((mt, index) =>
              movimientoTiposTemp.push({
                  value: mt.id,
                  label: mt.nombre,
                  data: mt,
                })
              );
              this.setState({ movimientosTipo: movimientoTiposTemp });
              if (this.state.accion == "nuevo") {
                this.setState({
                  idMovimientoTipo: {
                    value: 0,
                    label: "Selecciona un Tipo de movimiento",
                  },
                });
              }
            }
            if (stateVar === "transportistas") {
              let transportistasTemp = [];
              returnVal.map((t, index) => {
                transportistasTemp.push({
                  value: t.id,
                  label: t.nombre,
                  data: t,
                });
              });
              this.setState({ transportistas: transportistasTemp });
              if (this.state.accion == "nuevo") {
                this.setState({
                  idTransportista: {
                    value: 0,
                    label: "Selecciona un Transportistas",
                  },
                });
              }
            }
            break;
          case "post":
            if(stateVar === "solicitudes_cliente")
              this.getSolicitudes();
            if (stateVar === "clientes") {
              if (returnVal.id) {
                this.getfuntion(
                  "post",
                  {
                    idPlataforma: this.state.idPlataforma.value,
                    idCliente: returnVal.id,
                  },
                  "",
                  "plataformasClientes",
                  "plataformasClientes"
                );
                NotificationManager.info(
                  "Se ha agregado el cliente al catlogo general( " +
                    returnVal.nombre +
                    " )",
                  "Nuevo"
                );
                let clientesTemp = this.state.clientes;
                clientesTemp.push({
                  value: returnVal.id,
                  label: returnVal.nombre,
                  data: returnVal,
                });
                this.MovimientosObject.idCliente = returnVal;
                this.setState({ clientes: clientesTemp });

                this.setState({
                  idCliente: {
                    value: returnVal.id,
                    label: returnVal.nombre,
                    data: returnVal,
                  },
                });
              }
            }
            if (stateVar === "plataformasClientes") {
              if (returnVal.idPlataforma) {
                NotificationManager.info(
                  "El cliente ahora pertenece a la plataforma( " +
                    this.state.idPlataforma.value +
                    " )",
                  "Nuevo"
                );
              }
              this.hiddenmodal();
            }
            if (stateVar === "navieraMovimientos" && returnVal.id) {                
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = timeToDateFill(
                new Date().getTime()
              );
              this.usuariosLogObject.descripcion = "Nuevo Movimiento";
              Crud_Catalogos(
                "usuariosLog",
                "bitacoraMov",
                "post",
                "",
                this.usuariosLogObject
              );
            }
            break;
          case "put":
            if (stateVar === "clientes") {
              if (returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del cliente al catlogo general( " +
                    returnVal.nombre +
                    " )",
                  "Modificación"
                );
                let clientesTem = this.state.clientes;
                for (let item of clientesTem) {
                  if (item.id == returnVal.id) {
                    item = returnVal;
                  }
                }
                this.setState({ clientes: clientesTem });
                this.hiddenmodal();
              }
            }
            if (stateVar === "navieraMovimientos" && returnVal.id) {
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = timeToDateFill(
                new Date().getTime()
              );
              this.usuariosLogObject.descripcion =
                "Actualización de Movimiento";
              Crud_Catalogos(
                "usuariosLog",
                "bitacoraMov",
                "post",
                "",
                this.usuariosLogObject
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getfuntionTrans = ( metodo = "get", obj = [], id = "", catalogo = "transportistas", stateVar = "transportistas", hiddenModl = "") => {
    Crud_Catalogos(catalogo,"movimientos",metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "post":
            if (stateVar === "transportistas") {
              if (returnVal.id) {
                this.getfuntionTrans(
                  "post",
                  {
                    plataforma: this.state.currentMovimiento.idPlataforma,
                    transportista: returnVal,
                  },
                  "",
                  "plataformasTransportistas",
                  "plataformasTransportistas"
                );
                NotificationManager.info(
                  "Se ha agregado el transportista al catlogo general( " +
                    returnVal.nombre +
                    " )",
                  "Nuevo"
                );
                let transportistasTemp = this.state.transportistas;
                transportistasTemp.push({
                  value: returnVal.id,
                  label: returnVal.nombre,
                  data: returnVal,
                });
                this.MovimientosObject.idTransportista = returnVal;
                this.setState({ transportistas: transportistasTemp });
                this.setState({
                  idTransportista: {
                    value: returnVal.id,
                    label: returnVal.nombre,
                    data: returnVal,
                  },
                });
              }
            }
            if (stateVar === "plataformasTransportistas") {
              if (returnVal.plataforma) {
                NotificationManager.info(
                  "El transportista ahora pertenece a la plataforma( " +
                    returnVal.plataforma.nombre +
                    " )",
                  "Nuevo"
                );
              }
              this.hiddenmodal();
            }
            break;
          case "put":
            if (stateVar === "transportistas") {
              if (returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del transportista al catalogo general( " +
                    returnVal.nombre +
                    " )",
                  "Modificación"
                );
                let transportistasTem = this.state.transportistas;
                for (let item of transportistasTem) {
                  if (item.id == returnVal.id) {
                    item = returnVal;
                  }
                }
                this.setState({ transportistas: transportistasTem });
                this.setState({
                  idTransportista: {
                    value: returnVal.id,
                    label: returnVal.nombre,
                    data: returnVal,
                  },
                });
                this.hiddenmodal();
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => console.log(err) );
  };
  componentWillMount = () => {
    this.getfuntion("get", "", "", "plataformas/usuario/"+user.id+"/bloque/564", "plataformas");//564 bloque movimientos_nav_clien
  };
  componentDidMount = () => {
    /////////////////////////////////////////////////////////////////////////////////77
    this.getSolicitudes();
    /**
       * Cargar catalogos
       */
    const mov= this.state.currentMovimiento;
    const idPlt = mov.idPlataforma? mov.idPlataforma.id : 0;
    this.getfuntion("get", "", "", "contenedorTipo", "contenedorTipo");
    this.getfuntion("get", "", "", "remolquesTipo", "remolquesTipo");
    if (idPlt)
      this.getCatalogosPorPlataforma(idPlt);
    
    if (this.state.accion !== "nuevo") {
      if (mov.idPlataforma)//idPlataforma
        this.setState({
          idPlataforma: {
            value: mov.idPlataforma.id,
            label: mov.idPlataforma.nombre,
            data: mov.idPlataforma,
          },
        });
        if (mov.idMovimientoTipo)//idMovimientoTipo
          this.setState({
            idMovimientoTipo: {
              value: mov.idMovimientoTipo.id,
              label: mov.idMovimientoTipo.nombre,
              data: mov.idMovimientoTipo
            },
          });
      if (mov.idCorredor)//idCorredor
        this.setState({
          idCorredor: {
            value: mov.idCorredor.id,
            label: mov.idCorredor.nombre,
          },
        });
      if (mov.idLocalidad)//idLocalidad
        this.setState({
          localidad: {
            value: mov.idLocalidad.id,
            label: mov.idLocalidad.nombre,
          }
      });
      if (mov.idTransportista)//idTransportista
        this.setState({
          idTransportista: {
            value: mov.idTransportista.id,
            label: mov.idTransportista.nombre,
            data: mov.idTransportista
          },
        });
      if (mov.idCliente)//idCliente
        this.setState({
          idCliente: {
            value: mov.idCliente.id,
            label: mov.idCliente.nombre,
            data: mov.idCliente,
          },
        });
      if (mov.idClienteNaviero)//idClienteNaviero
        this.setState({
          idClienteNaviero: {
            value: mov.idClienteNaviero.id,
            label: mov.idClienteNaviero.nombre,
            data: mov.idClienteNaviero,
          },
        });

      this.setState({ destino: mov.destino ? mov.destino: "", });
      this.setState({ nota: mov.nota ? mov.nota : "", });
      this.setState({ contenedor: mov.contenedor });

      if (mov.citaProgramada != null) {
        this.setState({
          citaProgramada: new Date(mov.citaProgramada),
        });
      }
      this.MovimientosObject = mov;
    } else {
      
    }
  };
  getCatalogosPorPlataforma=(idPlt)=>{
    this.getfuntion(
      "get",
      "",
      "",
      "variables/" + idPlt,
      "variables"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "corredores/cli_usuario/" + idPlt,
      "corredores"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "localidades/cli_usuario/" + idPlt,
      "localidades"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "cliente/cli_usuario/" + idPlt,
      "clientes"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "clientes_navieros/cli_usuario/" + idPlt,
      "clientesNavieros"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "operacionesTipo/plataforma/" + idPlt,
      "operacionesTipo"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "movimientosTipo/cli_usuario/" + idPlt,
      "movimientosTipo"
    );
    // Trae los transportistas
    this.getfuntion(
      "get",
      "",
      "",
      "transportistas/cli_usuario/" + idPlt,
      "transportistas"
    );
  }
  enableSave = () => {
    if (
      this.state.idPlataforma.value &&
      this.state.idCliente.value &&
      this.state.idMovimientoTipo.value &&
      this.state.destino.length &&
      this.state.idCorredor.value &&
      this.state.localidad.value &&
      this.state.idTransportista.value &&
      this.state.contenedorSize.value &&
      this.state.contenedorTipo.value &&
      this.state.contenedor[0] && this.state.contenedor[0].length
    ) {
      
      return false;
    } else {
      return true;
    }
  };

  guardarMovimiento = () => {
    for( let i= 0; i< this.state.noContenedores; i++ ){
      let movTemp= {...this.MovimientosObject};
      if( this.state.contenedor[i] && this.state.contenedor[i].length ){
        movTemp.contenedor= this.state.contenedor[i];
        movTemp.variables= JSON.stringify(movTemp.variables);
        movTemp.clienteS= JSON.stringify(movTemp.clienteS);
        movTemp.transportistaS= JSON.stringify(movTemp.transportistaS);
        if (this.state.accion === "modificar")
          this.crud_put(movTemp);
        else
          this.crud_post(movTemp);
      }
    }
  };

  //************ Combos ***********************
  changePlataformas = (event) => {
    this.MovimientosObject.idPlataforma = event.data;
    this.setState({ idPlataforma: event });
    this.setState({ idMovimientoTipo: { value: 0, label: "Cargando Tipos de movimiento" } });
    this.setState({ idOperacionTipo: { value: 0, label: "Cargando Tipos de operación" } });
    this.setState({ idCorredor: { value: 0, label: "Cargando corredores" } });
    this.setState({ localidad: { value: 0, label: "Cargando localidades" } });
    this.setState({ idCliente: { value: 0, label: "Cargando Clientes" } });
    this.setState({ idClienteNaviero: { value: 0, label: "Cargando Clientes Finales" } });    
    this.setState({ idTransportista: { value: 0, label: "Cargando Transportistas" } }); 
    this.setState({ contenedorSize: { value: 0, label: "Cargando Tipos de remolque" } });    
    if(event.value)
      this.getCatalogosPorPlataforma(event.value);
  };

  changeCliente = (event) => {
    this.MovimientosObject.idCliente = event.data;
    this.setState({ idCliente: event });
  };
  changeClienteNaviero = (event) => {
    this.MovimientosObject.idClienteNaviero = event.data;
    this.setState({ idClienteNaviero: event });
  };

  changeContenedorTipo = (event) => {
    this.MovimientosObject.contenedorTipo = event.value;
    this.setState({ contenedorTipo: event });
  };
  changeContenedorSize = (event) => {
    this.MovimientosObject.contenedorSize = event.value;
    this.setState({ contenedorSize: event });
  };
  changeDestino = (event) => {
    this.MovimientosObject.destino = event.target.value;
    this.setState({ destino: event.target.value });
  };
  changeNota = (event) => {
    if (event.target.value.length <= 2000) {
      this.MovimientosObject.nota = event.target.value;
      this.setState({ nota: event.target.value });
    }
  };

  changeCorredor = (event) => {
    this.MovimientosObject.idCorredor = event.data;
    this.setState({ idCorredor: event });
  };
  changeLocalidad = (event) => {
    this.MovimientosObject.idLocalidad = event.data;
    this.setState({ localidad: event });
  };
  changeMovimientoTipo = (event) => {
    this.MovimientosObject.idMovimientoTipo = event.data;
    this.setState({ idMovimientoTipo: event });
  };
  changeTransportista = (event) => {
    this.MovimientosObject.idTransportista = event.data;
    this.setState({ idTransportista: event });
  };
  //Campos Normales

  onChangeDate = (date) => {
    this.MovimientosObject.citaProgramada = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ citaProgramada: date })
      : this.setState({ citaProgramada: date });
  };
  crud_put(obj) {
    this.getfuntion(
      "put",
      obj,
      "",
      "solicitudes_cliente",
      "solicitudes_cliente"
    );
    this.setState({ crudbutonEnable: false });
  }

  crud_post(obj) {
    this.getfuntion(
      "post",
      obj,
      "",
      "solicitudes_cliente",
      "solicitudes_cliente"
    );
    this.setState({ crudbutonEnable: false });    
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }
  getForm=(tipo, longitud, nombre, etiqueta)=>{
    {/*

                      variables: {
                        ejecutivo: "",
                        referencia_facturacion: "",
                        referencia_chubb: "",
                        cita_colocacion: null,
                        cuenta_espejo:{
                          url:"",
                          usuario:"",
                          password: ""},
                        modalidad: false
                      } */}
    switch (tipo) {
      case "String":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="text"
                value={this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "DateTime":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                value={this.state[nombre]}
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e ?timeToDateFill( new Date(e).getTime()): e;
                }}
              />
            </Form.Group>
          </Grid.Col>)
      case "Any":
        switch (nombre) {
          case "cuenta_espejo":
            if(this.state.gpsTipoColocacion.value === 7){
              let colsTemp= [] ;
              colsTemp.push(this.getForm("String", 400, "cuenta_espejo.url", "Url (Cuenta espejo)"));
              colsTemp.push(this.getForm("String", 200, "cuenta_espejo.usuario", "Usuario (Cuenta espejo)"));
              colsTemp.push(this.getForm("String", 200, "cuenta_espejo.password", "Password (Cuenta espejo)"));
              return colsTemp;
            }else{
              return null;
            }
          default:
            break;
        }
        break;
      case "Boolean":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <Form.Switch
                name={nombre}
                checked={this.state[nombre]}
                label= { this.state[nombre]? "Full": "Sencillo" }
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                  this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.checked });
                  if(1 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0]]= e.target.checked;
                  if(2 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.checked;
                  if(3 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.checked;
                }}
              />
            </Form.Group>
          </Grid.Col>)    
      default:
        break;
    }
  }
  getNoContenedores=()=>{
    let noCotenedorCols= [];
    for( let i= 0; i< this.state.noContenedores; i++ ){
      noCotenedorCols.push(
        <Grid.Col md={4} key= {i} >
          <Form.Group label={
            <div>
              {"Contenedor("+(i+1)+")"}
              <label className="font-weight-bold text-danger">*</label>
            </div>}>
            {" "}
            <Form.Input
              name={"contenedor"+i}
              type="text"
              value={this.state.contenedor[i]? this.state.contenedor[i]: this.state.contenedor[i]}
              onChange={(e)=>{
                let contenedorTemp= {...this.state.contenedor};
                contenedorTemp[i]= e.target.value;
                this.setState({ contenedor: contenedorTemp });
              }}
            />
          </Form.Group>{" "}
        </Grid.Col>
      );
    }
    return noCotenedorCols;
  }
  getSolicitudes=()=> this.getfuntion( "get", "", "", "solicitudes_cliente", "solicitudes_cliente" );
  setCliente =(c)=>{
    this.MovimientosObject.clienteS = c;
    this.MovimientosObject.idCliente = null;
    this.setState({ idCliente: { value: "temparal", label: c.nombre } });
  }//transportistaS
  setTransportista =(t)=>{
    this.MovimientosObject.transportistaS = t;
    this.MovimientosObject.idTransportista = null;
    this.setState({ idTransportista: { value: "temparal", label: t.nombre } });
  }
  render() {
    return (
      <>
      {<LoadStore catalogos={["privilegios", "incidencias"]}/>}
        
      <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "90vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {40}>
              <div>
                <i className={(this.state.hiddenPanel? "fa fa-reply": "fa fa-share")+" "+(this.state.hiddenPanel? "text-success": "text-danger")}
                  style={
                    { 'font-size': "24px",
                      'float': "right",
                      'position': 'sticky',
                      'top': '0%',
                      'z-index': '50',
                    }
                  }
                  onClick={()=>{
                    for(let c of document.getElementsByClassName("layout-pane")){
                      if(c.className && c.className === "layout-pane")
                        c.style.width = !this.state.hiddenPanel? "0%": "40%";
                    }
                    this.setState({hiddenPanel: !this.state.hiddenPanel});
                  }}
                  ></i>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter bg-light text-dark">
                  <Table.Header>
                    <Table.Col>id</Table.Col>
                    <Table.Col>idMovimiento</Table.Col>
                    <Table.Col>contenedor</Table.Col>
                    <Table.Col>contenedorTipo</Table.Col>
                    <Table.Col>contenedorSize</Table.Col>
                    <Table.Col>idOperacionTipo</Table.Col>
                    <Table.Col>idMovimientoTipo</Table.Col>
                    <Table.Col>idNaviera</Table.Col>
                    <Table.Col>idTransportista</Table.Col>
                    <Table.Col>idCliente</Table.Col>
                    <Table.Col>idClienteNaviero</Table.Col>
                    <Table.Col>idLocalidad</Table.Col>
                    <Table.Col>origen</Table.Col>
                    <Table.Col>destino</Table.Col>
                  </Table.Header>
                  <Table.Body>
                    { this.state.solicitudes.map((s, index)=>
                      <Table.Row key={index}>
                        <Table.Col>{s.idSolicitud}</Table.Col>
                        <Table.Col>{s.idMovimiento}</Table.Col>
                        <Table.Col>{s.contenedor}</Table.Col>
                        <Table.Col>{s.contenedorTipo}</Table.Col>
                        <Table.Col>{s.contenedorSize}</Table.Col>
                        <Table.Col>{s.idOperacionTipo.nombre}</Table.Col>
                        <Table.Col>{s.idMovimientoTipo.nombre}</Table.Col>
                        <Table.Col>{s.idNaviera.nombre}</Table.Col>
                        <Table.Col>{s.idTransportista? s.idTransportista.nombre: s.transportistaS? s.transportistaS.nombre: ""}</Table.Col>
                        <Table.Col>{s.idCliente? s.idCliente.nombre: s.clienteS ? s.clienteS.nombre: ""}</Table.Col>
                        <Table.Col>{s.idClienteNaviero.nombre}</Table.Col>
                        <Table.Col>{s.idLocalidad.nombre}</Table.Col>
                        <Table.Col>
                          { [1,3].includes(s.idMovimientoTipo.id)?
                            s.idCorredor.nombre
                            : [2,4].includes(s.idMovimientoTipo.id)?
                              s.destino
                              :""
                          }
                        </Table.Col>
                        <Table.Col>
                          { [1,3].includes(s.idMovimientoTipo.id)?
                            s.destino
                            : [2,4].includes(s.idMovimientoTipo.id)?
                              s.idCorredor.nombre
                              :""
                          }
                        </Table.Col>
                        
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </div>
              <div class="container ml-3">
                <h5>Tipo de Operación: {this.state.idOperacionTipo.label}</h5>
                <Grid.Row>
                  <Grid.Col md={12} xl={12}>
                    <Grid.Row>
                      <Grid.Col md={4} lg={4}>
                        <Form.Group label="Plataforma">
                          <Select
                            value={this.state.idPlataforma}
                            onChange={this.changePlataformas}
                            options={this.state.plataformas}
                            isDisabled={this.state.plataformas.length === 1}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Cliente
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Grid.Row>
                            <Grid.Col>
                              <Select
                                value={this.state.idCliente}
                                onChange={this.changeCliente}
                                options={this.state.clientes}
                                isLoading={
                                  this.state.clientes.length == 0 ? true : false
                                }
                              />
                              {this.state.idCliente.value > 0 ? (
                                <p>{this.state.idCliente.data.domicilio}</p>
                              ) : null}
                            </Grid.Col>
                            { this.state.idPlataforma.value > 0 &&
                            (this.isEnable("actualizarCliente") ||
                              this.isEnable("agregarCliente")) ? (
                              <Grid.Col md={2} lg={2}>
                                {this.isEnable("agregarCliente") ? (
                                  <span className="float-right">
                                    <ModalLay
                                      tamanio="85%"
                                      title={"Nuevo"}
                                      name={
                                        <span
                                          className="fa fa-plus ml-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Nuevo cliente(Antes de crear  valida que no exista)"
                                          }
                                        ></span>
                                      }
                                      formulario={Cliente}
                                      datosForm={{
                                        accion: "nuevo",
                                        elementos: this.state.idCliente.data,
                                        setCliente: this.setCliente
                                      }}
                                    />
                                  </span>
                                ) : null}
                                {false && this.isEnable("actualizarCliente") ? (
                                  <span className="float-right">
                                    {this.state.idCliente.value > 0 ? (
                                      <ModalLay
                                        tamanio="85%"
                                        title="Modificar"
                                        name={
                                          <span
                                            className="fa fa-edit"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              "Modificar (" +
                                              this.state.idCliente.label +
                                              ")"
                                            }
                                          ></span>
                                        }
                                        formulario={Cliente}
                                        datosForm={{
                                          accion: "modificar",
                                          elementos: this.state.idCliente.data,
                                          setCliente: this.setCliente
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                ) : null}
                              </Grid.Col>
                            ) : null}
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Cliente Final.">
                          <Select
                            value={this.state.idClienteNaviero}
                            onChange={this.changeClienteNaviero}
                            options={this.state.clientesNavieros}
                            isLoading={
                              this.state.clientesNavieros.length == 0
                                ? true
                                : false
                            }
                          />
                        </Form.Group>
                      </Grid.Col>
                      
                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Tipo de Movimiento
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Select
                            value={this.state.idMovimientoTipo}
                            onChange={this.changeMovimientoTipo}
                            options={this.state.movimientosTipo}
                            isLoading={
                              this.state.movimientosTipo.length == 0 ? true : false
                            }
                          />
                        </Form.Group>
                      </Grid.Col>
                      { this.state.idMovimientoTipo.value?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              {
                                [1,3].includes(this.state.idMovimientoTipo.value)?
                                "Origen"
                                : [2,4].includes(this.state.idMovimientoTipo.value)?
                                  "Destino"
                                  :"Corredor"
                              }
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={this.state.idCorredor}
                              onChange={this.changeCorredor}
                              options={this.state.corredores}
                              isLoading={this.state.corredores.length == 0 ? true : false}
                            />
                          </Form.Group>
                        </Grid.Col>
                        :null}
                      { this.state.idMovimientoTipo.value?
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              {
                                [1,3].includes(this.state.idMovimientoTipo.value)?
                                  "Destino"
                                  : [2,4].includes(this.state.idMovimientoTipo.value)?
                                  "Origen"
                                    :"Destino"
                              }
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            {" "}
                            <Form.Input
                              name="destino"
                              type="text"
                              value={this.state.destino}
                              onChange={this.changeDestino}
                              maxlength="30"
                            />
                          </Form.Group>{" "}
                        </Grid.Col>
                        :null}
                        <Grid.Col md={4}>
                          <Form.Group label={
                            <div>
                              Localidad
                              <label className="font-weight-bold text-danger">*</label>
                            </div>}>
                            <Select
                              value={this.state.localidad}
                              onChange={this.changeLocalidad}
                              options={this.state.localidades}
                            />
                          </Form.Group>
                        </Grid.Col>

                      <Grid.Col md={4}>
                        <Form.Group label={
                          <div>
                            Transportistas
                            <label className="font-weight-bold text-danger">*</label>
                          </div>}>
                          <Grid.Row>
                            <Grid.Col>
                              <Select
                                value={this.state.idTransportista}
                                onChange={this.changeTransportista}
                                options={this.state.transportistas}
                                isLoading={
                                  this.state.transportistas.length == 0 ? true : false
                                }
                              />
                            </Grid.Col>
                            { this.state.idPlataforma.value > 0 &&
                            (this.isEnable("actualizarTransportista") ||
                              this.isEnable("agregarTransportista")) ? (
                              <Grid.Col md={2} lg={2}>
                                {this.isEnable("agregarTransportista") ? (
                                  <span className="float-right">
                                    <ModalLay
                                      tamanio="85%"
                                      title={"Nuevo"}
                                      name={
                                        <span
                                          className="fa fa-plus ml-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={
                                            "Nuevo Transportista(Antes de crear  valida que no exista)"
                                          }
                                        ></span>
                                      }
                                      formulario={Transportista}
                                      datosForm={{
                                        accion: "nuevo",
                                        elementos: this.state.idCliente.data,
                                        setTransportista: this.setTransportista
                                      }}
                                    />
                                  </span>
                                ) : null}
                                {false && this.isEnable("actualizarTransportista") ? (
                                  <span className="float-right">
                                    {this.state.idTransportista.value > 0 ? (
                                      <ModalLay
                                        tamanio="85%"
                                        title="Modificar"
                                        name={
                                          <span
                                            className="fa fa-edit"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={
                                              "Modificar (" +
                                              this.state.idTransportista.label +
                                              ")"
                                            }
                                          ></span>
                                        }
                                        formulario={TransportistasForm}
                                        datosForm={{
                                          accion: "modificar",
                                          plataforma: this.state.idPlataforma.data,
                                          elementos: this.state.idTransportista.data,
                                          getfuntion: this.getfuntionTrans,
                                          stor: this.props,
                                        }}
                                      />
                                    ) : null}
                                  </span>
                                ) : null}
                              </Grid.Col>
                            ) : null}
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                      {this.state.idPlataforma.value > 0 ? (
                        this.state.idPlataforma.data.plataformaTipo.id === 1 ? (
                          <Grid.Col md={4}>
                            <Form.Group label={
                              <div>
                                Tipo de carga
                                <label className="font-weight-bold text-danger">*</label>
                              </div>}>
                              <Select
                                value={this.state.contenedorTipo}
                                onChange={this.changeContenedorTipo}
                                options={this.state.contenedoresTipo}
                                isLoading={
                                  this.state.contenedoresTipo.length == 0 ? true : false
                                }
                              />
                            </Form.Group>
                          </Grid.Col>
                        ) : null
                      ) : null}
                      {this.state.idPlataforma.value > 0 ? (
                        this.state.idPlataforma.data.plataformaTipo.id === 1 ? (
                          <Grid.Col md={4}>
                            <Form.Group label={
                              <div>
                                Tamaño
                                <label className="font-weight-bold text-danger">*</label>
                              </div>}>
                              <Select
                                value={this.state.contenedorSize}
                                onChange={this.changeContenedorSize}
                                options={this.state.remolquesTipo}
                              />
                            </Form.Group>
                          </Grid.Col>
                        ) : null
                      ) : null}                    

                      <Grid.Col md={4}>
                        <Form.Group label="Cita programada">
                          <DateTimePicker
                            format="y-MM-dd h:mm:ss a"
                            value={this.state.citaProgramada}
                            onChange={this.onChangeDate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      {this.state.idPlataforma.value > 0 ? (
                        this.state.idPlataforma.data.plataformaTipo.id === 1 ? (
                          <Grid.Col md={4}>
                            <Form.Group label={
                              <div>
                                Tipo de colocación
                                <label className="font-weight-bold text-danger">*</label>
                              </div>}>
                              <Select
                                value={this.state.gpsTipoColocacion}
                                onChange={(e)=> this.setState({ gpsTipoColocacion: e})}
                                options={this.state.gpsTipoColocaciones}
                              />
                            </Form.Group>
                          </Grid.Col>
                        ) : null
                      ) : null}                    
                      {this.state.variables.map((v, inex)=>
                        this.getForm(v.tipo, v.longitud, v.nombre, v.etiqueta)
                      )}                      
                      <Grid.Col>
                        <Form.Group
                          label={
                            <Form.Label aside={this.state.nota.length + "/2000"}>
                              Nota
                            </Form.Label>
                          }
                        >
                          {" "}
                          <Form.Textarea
                            value={this.state.nota}
                            name="nota"
                            onChange={this.changeNota}
                            placeholder="Nota del movimiento.."
                            rows={6}
                          />{" "}
                        </Form.Group>{" "}
                      </Grid.Col>
                      <Grid.Col md={12}>
                        <Form.Group label={
                          <div class="row">
                            <label for="NoCotenedores" class="col-2 col-form-label">Contenedores</label>
                            <div class="col-2">
                              <Form.Input name="noContenedores" type="number" value={this.state.noContenedores}
                                onChange={(e)=>{
                                  if(e.target.value < 11)
                                    this.setState({ noContenedores: e.target.value });
                                  }}
                              />
                            </div>
                          </div>}
                        >
                          <Grid.Row>
                            { this.getNoContenedores() }
                          </Grid.Row>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>
                <div className="float-right">
                  {this.isEnable("actualizar") || this.isEnable("agregar") ? (
                    <span class="badge">
                      <Button
                        target="_blank"
                        size="sm"
                        RootComponent="a"
                        color="primary"
                        disabled={this.enableSave() || !this.state.crudbutonEnable}
                        onClick={this.guardarMovimiento}
                      >
                        <span class="badge">
                          <Icon link={true} name="save" />
                          Guardar
                        </span>
                      </Button>
                    </span>
                  ) : null}
                </div>
                <br/><br/><br/><br/>
              </div>
            </SplitterLayout>
          </div>

        </Grid.Col>
      </SiteWrapper>
  
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    privilegios_object: state.catalogos_reducer.privilegios_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    localidades_object:  state.catalogos_reducer.localidades_object,
  }
}
function mapDispachToProps(dispach) {
  return {
    incidencias_update:             (elementos) => dispach(ACTIONS.elementos_incidencias_imput(elementos))
  }
}
export default connect(mapStateToProps, mapDispachToProps)(MovimientosNavierosClientePage);
