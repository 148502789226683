import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";

import Monitoreo from "./Monitoreo.react";

class MonitoreoImpoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
                        plataformaTipo  : 1,
    };
  }

  componentWillMount=() =>{}
  componentDidMount =() => {}
  componentDidUpdate=()=>{}
  render() {
    return (
      <Monitoreo mtipo={this.state.plataformaTipo} />
    )
  }
}
export default MonitoreoImpoPage;
