import React, { Component } from "react";
import { Table, Button, Icon } from "tabler-react";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
import CartasFrom from "./CartasForm";
import ModalLay from "../../../../components/ModalLay/ModalLay";

class Cartas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregas: [],
      obj: "",

      hiddenModals: false,

      destinos: [],
      cartaPorte: [],
      movimientoTerrestre: "",
      elementos: [],
    };
    this.searchingentregas = [];
    this.entregas = [];
    this.movimientoAcumuladoresTerrestre = {};
  }
  componentWillMount = () => {
    //this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.obj.id, "entregas");
    this.getfuntion(
      "get",
      "",
      "",
      "cartaPorte/" + this.props.obj.id,
      "cartaPorte"
    );
  };
  componentDidMount = () => {
    if (this.props.entregas) {
      this.setState({ entregas: this.props.entregas });
      this.entregas = this.props.entregas;
      this.entregas.map((entrega, index) => {
        this.AcumuladoresEtaTerrestre(entrega.idEntrega);
      });
    }
  };

  AcumuladoresEtaTerrestre = (idEntrega) => {
    var conteo = 0;
    Crud_Catalogos(
      "terrestreEntregasAcumuladores/etasEntrega/" + idEntrega,
      "terrestreEntregasAcumuladores",
      "get"
    )
      .then((returnVal) => {
        if (returnVal > 0) {
          this.etasCalculadas[idEntrega] = returnVal;
          this.setState({ etasCalculadas: this.etasCalculadas });
          console.log(this.etasCalculadas);
        } else {
        }
        //return returnVal;
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          /**
           * Error
           */
        }
      });
    setTimeout(() => {
      //console.log("VALORES "+conteo);
      return conteo;
    }, 1000);
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "entregas",
    stateVar = "entregas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "cartaPorte",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "destinos" && returnVal[0].id) {
                this.setState({ destinos: returnVal });
              }
              if (stateVar === "cartaPorte") {
                this.setState({ cartaPorte: returnVal });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "cartaPorte") {
                this.hiddenmodal();
                this.state.cartaPorte.push(returnVal);
                this.setState({ cartaPorte: this.state.cartaPorte });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "entregas" && returnVal.idEntrega) {
                this.hiddenmodal();
                //Colocamos todas las entregas en estatus 0, solo si el estatus de la entrega ha cambiado
                if (returnVal.estatus !== obj.estatus || returnVal.estatus) {
                  this.entregas.map(
                    (et, index) => (this.entregas[index].estatus = 0)
                  );
                }
                //Sustituir entrega actualizada
                this.entregas.map((et, index) => {
                  if (et.idEntrega === returnVal.idEntrega) {
                    this.entregas[index] = returnVal;
                    this.setState({ entregas: this.entregas });
                  }
                });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "entregas" && returnVal === true) {
                this.hiddenmodal();
                for (let i = 0; i < this.entregas.length; i++) {
                  if (this.entregas[i].idEntrega == id) {
                    this.entregas.splice(i, 1);
                    console.log(this.entregas);
                    this.setState({ entregas: this.entregas });
                    break;
                  }
                }
              }
              if (stateVar === "cartaPorte") {
                this.hiddenmodal();
                for (let i = 0; i < this.state.cartaPorte.length; i++) {
                  if (
                    this.state.cartaPorte[i].idEntrega === obj.idEntrega &&
                    this.state.cartaPorte[i].idMovimiento ===
                      obj.idMovimiento &&
                    this.state.cartaPorte[i].cartaPorte === obj.cartaPorte
                  ) {
                    this.state.cartaPorte.splice(i, 1);
                    this.setState({ cartaPorte: this.state.cartaPorte });
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  changeEstatus = (e) => {
    for (let item of this.state.entregas) {
      if (item.idEntrega === parseInt(e.target.value, 10)) {
        let entTem = item;
        entTem.estatus = e.target.checked ? 1 : 0;
        this.getfuntion("put", entTem, "", "entregas", "entregas");
        break;
      }
    }
  };
  render() {
    return (
      <div className="mt-1">
        <div className="row">
          <div className="col-9"></div>
          <div className="col">
            <ModalLay
              tamanio={800}
              title="Nuevo Shipment"
              name={
                <Button pill size="sm" outline color="primary">
                  <Icon name="plus" />
                </Button>
              }
              formulario={CartasFrom}
              datosForm={{
                accion: "nuevo",
                idmovimiento: this.props.obj.id,
                destinos: this.state.destinos,
                stor: this.props,
                getfuntion: this.getfuntion,
                closeModal: this.hiddenmodal,
              }}
            />
          </div>
        </div>
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter bg-light"
          headerItems={[
            { content: "Entrega" },
            { content: "Carta Porte" },
            { content: "Editar" },
          ]}
        >
          <Table.Header></Table.Header>
          <Table.Body>
            {this.state.cartaPorte
              .sort(function (a, b) {
                if (a["cartaPorte"] === null) {
                  return -1;
                }
                if (a["cartaPorte"] > b["cartaPorte"]) {
                  return 1;
                }
                if (a["cartaPorte"] < b["cartaPorte"]) {
                  return -1;
                }
                return 0;
              })
              .map((elemento, index) => (
                <>
                  <Table.Row
                    style={
                      elemento.estatus
                        ? { "background-color": "rgba(95, 231, 50, 0.30)" }
                        : null
                    }
                  >
                    <Table.Col>{elemento.entrega.direccion}</Table.Col>
                    <Table.Col>{elemento.cartaPorte}</Table.Col>
                    <Table.Col>
                      <ModalLay
                        tamanio={800}
                        title="Editar"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <Icon name="edit" />
                          </Button>
                        }
                        formulario={CartasFrom}
                        datosForm={{
                          accion: "modificar",
                          idmovimiento: this.props.obj.id,
                          destinos: this.state.destinos,
                          elementos: elemento,
                          stor: this.props,
                          getfuntion: this.getfuntion,
                          closeModal: this.hiddenmodal,
                        }}
                      />
                    </Table.Col>
                  </Table.Row>
                </>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
export default Cartas;
