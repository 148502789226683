import { useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as KJUR from 'jsrsasign';
import { PRIVATE_KEY } from '../constants/constants';

export const useAuth = (qlikConfig) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const generateJWT = useCallback(() => {
    const header = { alg: 'RS256', typ: 'JWT', kid: qlikConfig.kid };
    const now = KJUR.jws.IntDate.get('now');
    const exp = KJUR.jws.IntDate.get('now') + 30;

    const payload = {
      jti: uuidv4(),
      sub: qlikConfig.sub,
      subType: 'user',
      name: qlikConfig.name,
      email: qlikConfig.email,
      email_verified: true,
      groups: [],
      aud: 'qlik.api/login/jwt-session',
      iss: qlikConfig.issuer,
      iat: now,
      exp: exp,
      nbf: '1s'
    };

    const sHeader = JSON.stringify(header);
    const sPayload = JSON.stringify(payload);

    return KJUR.jws.JWS.sign(
      'RS256',
      sHeader,
      sPayload,
      PRIVATE_KEY
    );
  }, [qlikConfig]);

  const performLogin = useCallback(async (token) => {
    try {
      const response = await fetch(
        `${qlikConfig.baseUrl}/login/jwt-session?qlik-web-integration-id=${qlikConfig.webIntegrationId}`,
        {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
            'qlik-web-integration-id': qlikConfig.webIntegrationId,
          },
          rejectunAuthorized: false,
        }
      );

      if (!response.ok) {
        throw new Error('Login failed');
      }

      setIsAuthenticated(true);
    } catch (error) {
      console.error('Login error:', error);
      setIsAuthenticated(false);
    }
  }, [qlikConfig]);

  const handleAutomaticLogin = useCallback(async () => {
    const token = generateJWT();
    await performLogin(token);
  }, [generateJWT, performLogin]);

  return { isAuthenticated, handleAutomaticLogin };
};