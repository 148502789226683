import {Grid, StampCard} from "tabler-react";
import SiteWrapper from "../SiteWrapper.react";
import React, { Component } from "react";
//import UsuarioPrivilegios from "../AdminPage/AdminUsuarioPrivilegios";

const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        privilegiosUser: []
    };
}
componentDidMount=() =>{
  /*UsuarioPrivilegios().then(response => {
    this.setState({ privilegiosUser: response })
    }).catch(err => { console.log(err)

  });*/
}
render() {
  return (
    <SiteWrapper>
      <div className="container mt-3">
        <Grid.Row>
          <h3> </h3>
        </Grid.Row>
        <Grid.Row>
          {this.state.privilegiosUser.map(privilegioUser =>
            <Grid.Col sm={6} lg={4} md={4} className= "mt-3">
              <a href={privilegioUser.categorias.nombre} target="_top">
                <StampCard color="primary" icon="map"  footer={privilegioUser["descripcion"]}>
                  {privilegioUser["nombre"]} <br></br>
                </StampCard>
              </a>
            </Grid.Col>
          )}

        </Grid.Row>
      </div>
    </SiteWrapper>
  );
}
}
export default AdminPage;
