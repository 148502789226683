import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
let lenEntregas = 0;
class CartasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entrega: [],
      idMovimiento: this.props.idmovimiento,
      cartaPorte: "",

      idDestino: { value: 1, label: "DESTINO SIN CATALOGO", indice: 0 },
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
      Entregas: { value: "id", label: "Selecciona una Entrega", index: "id" },
    };
    this.valid = { entrega: false, cartaPorte: false };
    this.Entregas = [];
    this.cartas = {
      entrega: [],
      idMovimiento: this.props.idmovimiento,
      cartaPorte: "",
    };
  }
  componentWillMount = () => {
    //this.Entregas.push( { value:0, label:"Elija una Entrega", indice:0 } )
    try {
      lenEntregas = this.props.stor.entregas.length;
    } catch (e) {
      console.log("No hay Entregas: " + e);
    }
    if (this.props.stor.entregas) {
      this.props.stor.entregas.map((entrega, index) => {
        if (entrega) {
          if (entrega.idDestino === 1) {
            this.Entregas.push({
              value: entrega.idEntrega,
              label: entrega.direccion,
              indice: entrega.idEntrega,
            });
            this.setState({
              idEntrega: {
                value: entrega.idEntrega,
                label: entrega.direccion,
                indice: entrega.idEntrega,
              },
            });
          } else {
            //          if (entrega.idDestino === this.entrega.idDestino){
            this.Entregas.push({
              value: entrega.idEntrega,
              label: entrega.direccion,
              indice: entrega.idEntrega,
            });
            this.setState({
              idEntrega: {
                value: entrega.idEntrega,
                label: entrega.direccion,
                indice: entrega.idEntrega,
              },
            });
            //          }
          }
        }
      });
    }

    if (this.state.accion === "nuevo") {
      this.setState({ idMovimiento: this.props.idmovimiento });
      this.setState({ idEntrega: 0 });
      this.setState({ cartaPorte: "PENDIENTE" });
    } else {
      //Se Modifica Registro Actual
      this.cartas = this.props.elementos;
      for (let item of this.props.stor.entregas) {
        if (item.idEntrega === this.props.elementos.entrega.idEntrega) {
          //this.cartas.entrega= item;
          this.setState({ idMovimiento: this.props.elementos.idMovimiento });
          this.setState({
            entrega: {
              indice: this.props.elementos.entrega.idEntrega,
              label: this.props.elementos.entrega.direccion,
              value: this.props.elementos.entrega.idEntrega,
            },
          });
          this.setState({ cartaPorte: this.props.elementos.cartaPorte });
          break;
        }
      }
      //this.setState({entrega:event});
    }
  };

  componentDidMount = () => {};

  isValid = (campo) => {
    switch (campo) {
      //case "idDestino": return this.valid.idDestino;
      //  break;
      case "entrega":
        return this.valid.entrega;
        break;
      case "cartaPorte":
        return this.valid.cartaPorte;
        break;
      //case "direccion": return this.valid.direccion;
      //  break;
      default:
        break;
    }
  };

  disableSave = () => {
    if (this.state.entrega) {
      if (this.state.entrega.idEntrega > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  delete() {
    //this.props.getfuntion("delete","",this.cartas, "cartaPorte", "cartaPorte");
    this.props.getfuntion(
      "delete",
      this.cartas,
      "",
      "cartaPorte/" +
        this.cartas.idMovimiento +
        "/" +
        this.cartas.entrega.idEntrega +
        "/" +
        this.cartas.cartaPorte,
      "cartaPorte"
    );
  }
  crud_put() {
    let tmp = "";
    tmp = this.cartas.cartaPorte;
    this.cartas.cartaPorte = tmp.trim();
    this.props.getfuntion("put", this.cartas, "", "cartaPorte", "cartaPorte");
  }
  crud_post() {
    this.props.getfuntion("post", this.cartas, "", "cartaPorte", "cartaPorte");
  }

  changeEntregas = (event) => {
    console.log("120 event"); //indice:11503 label:"Islas el Itzmo de Tehuantepec" value: 11503
    for (let item of this.props.stor.entregas) {
      if (item.idEntrega === event.value) {
        this.cartas.entrega = item;
        break;
      }
    }
    this.setState({ entrega: event });
  };
  changeCartaPorte = (event) => {
    this.cartas.cartaPorte = event.target.value;
    this.setState({ cartaPorte: event.target.value });
    if (event.target.value.length > 3) {
      this.valid.cartaPorte = true;
    } else {
      this.valid.cartaPorte = false;
    }
  };
  guardarCarta = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  render() {
    return (
      <div>
        {
          //this.props.destinos?
          <div>
            {this.state.accion === "modificar" ? (
              <h1> {this.props.elementos.destino} </h1>
            ) : null}
            <Grid.Row>
              <Grid.Col md={12} xl={12}>
                <Grid.Row>
                  <Grid.Col md={2} lg={2}>
                    <Form.Group label="Id Movimiento:">
                      <Form.Input
                        value={this.state.idMovimiento}
                        type="number"
                        name="idMovimiento"
                        placeholder="IdMovimiento..."
                        disabled
                        onChange={this.changeIdMovimiento}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col md={7} lg={7}>
                    <Form.Group label="Entrega:">
                      <Select
                        value={this.state.entrega}
                        onChange={this.changeEntregas}
                        options={this.Entregas}
                        isOptionDisabled={
                          this.state.accion === "nuevo" ? false : true
                        }
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col md={3} lg={3}>
                    <Form.Group label="Carta Porte">
                      <Form.Input
                        type="text"
                        name="Carta Porte"
                        feedback="Al menos 3"
                        value={this.state.cartaPorte}
                        placeholder="Carta Porte..."
                        onChange={this.changeCartaPorte}
                        disabled={this.state.accion === "nuevo" ? false : true}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <div className="float-right">
              <span class="badge">
                <Button
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  //disabled= { this.state.idDestino==='1'? false : true }
                  disabled={
                    this.state.accion === "nuevo"
                      ? lenEntregas === 0
                        ? true
                        : false
                      : true
                  }
                  onClick={this.guardarCarta}
                >
                  <span class="badge">
                    <Icon link={true} name="save" />
                    Guardar
                  </span>
                </Button>
              </span>
              {this.state.accion === "modificar" ? (
                <span className="ml-2">
                  <Button
                    pill
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => this.delete()}
                  >
                    <Icon name="trash" />
                  </Button>
                </span>
              ) : null}
            </div>
          </div>
          //  :null
        }
      </div>
    );
  }
}
export default CartasForm;
