import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../../components/TabsLayout.react";
import { Configuracion, Plantillas, EtiquetadoMasivo, Destinatarios } from "./correo/index.js";
import { WhatsApp } from "./whatsapp";
class  Correo extends Component {
  constructor(props) {
    super(props);
    this.state = {
                      id: "",
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
                  correoPlataforma: null,
                  currentTap:0
                 };
                               this.configuracion= [];
                          this.plataformasObject= [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "correoPlataformas/plataforma/"+this.props.elementos.id,"plataformaCorreo")
    }else{}
  }
  selectedTab = (index) =>{
    this.setState({ currentTap:index })
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get": {
              if (stateVar === "plataformaCorreo"){
                if(returnVal[0]){
                  this.setState({correoPlataforma: returnVal[0] });
                }else{ this.setState({correoPlataforma: returnVal }); }
              }
            } break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }

  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        <div>
          { !this.state.correoPlataforma ?
              <div>
                <h3> Cargando configuraciones ..</h3> <Dimmer active={ !this.state.correoPlataforma } loader></Dimmer>
                <div style={{ height: '20vh' }}></div>
              </div>
            :
              <div>
                { this.state.correoPlataforma.id ?
                    <TabsLayout
                            tabs={ [{ title: "Configuración", icon:"layout", panel: <Configuracion
                                                                                      accion={ this.props.accion }
                                                                                      elementos= { this.props.elementos }
                                                                                      getfuntion= { this.props.getfuntion}
                                                                                      stor= { this.props.stor }
                                                                                      getCorreoPlataforma={ this.state.correoPlataforma }
                                                                                      />, close: false },
                                    { title: "Plantillas Mail", icon:"mail", panel: <Plantillas
                                                                                      accion={ this.props.accion }
                                                                                      elementos= { this.props.elementos }
                                                                                      getfuntion= { this.props.getfuntion}
                                                                                      stor= { this.props.stor }
                                                                                      getCorreoPlataforma={ this.state.correoPlataforma }
                                                                                      />, close: false },
                                    { title: "WhatsApp", icon:"message-circle", panel: <WhatsApp
                                                                                        accion={ this.props.accion }
                                                                                        elementos= { this.props.elementos }
                                                                                        getfuntion= { this.props.getfuntion}
                                                                                        stor= { this.props.stor }
                                                                                        getCorreoPlataforma={ this.state.correoPlataforma }
                                                                                        />, close: false },                                                                                      
                                    { title: "Etiquetas", icon:"tag",    panel: <EtiquetadoMasivo
                                                                                      accion={ this.props.accion }
                                                                                      elementos= { this.props.elementos }
                                                                                      getfuntion= { this.props.getfuntion}
                                                                                      stor= { this.props.stor }
                                                                                      getCorreoPlataforma={ this.state.correoPlataforma }
                                                                                      />, close: false },
                                    { title: "Destinatarios", icon:"user",  panel: <Destinatarios
                                                                                      accion={ this.props.accion }
                                                                                      elementos= { this.props.elementos }
                                                                                      getfuntion= { this.props.getfuntion}
                                                                                      stor= { this.props.stor }
                                                                                      getCorreoPlataforma={ this.state.correoPlataforma }
                                                                                      />, close: false }
                                                                        ]}
                            selectedTab= { this.state.currentTap }
                            selectTab= { this.selectedTab }
                    />
                  :
                    <div>
                      <h3>Pide a sistemas que configure tu cuenta de correo ..</h3>
                      <TabsLayout
                              tabs={ [{ title: "Configuración", icon:"layout", panel: <Configuracion
                                                                            accion={ "nuevo" }
                                                                            elementos= { this.props.elementos }
                                                                            getfuntion= { this.props.getfuntion}
                                                                            stor= { this.props.stor }
                                                                            getCorreoPlataforma={ null }
                                                                            />, close: false }
                                    ]}
                              selectedTab= { this.state.currentTap }
                              selectTab= { this.selectedTab }
                      />
                    </div>
                }
              </div>
          }
        </div>
      </div>
    )
  }
}

export default Correo
