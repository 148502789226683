import Crud_Catalogos from "../../herramientas/Crud_Catalogos";

export const getfuntion = (
  metodo = 'get',
  obj = [],
  id = '',
  catalogo = 'cliente',
  stateVar = 'clientes',
  hiddenModl = ''
) => {
  return Crud_Catalogos(
    catalogo,
    'movimientoGps',
    metodo,
    id,
    obj,
    '',
    stateVar,
    hiddenModl,
    []
  );
};
