import React, { useEffect } from 'react';
import { IframeComponent } from '../IframeComponent/IframeComponent';
import { useAuth } from '../../hooks/useAuth';

const QlikComponent = ({ qlikConfig, title }) => {
  const { isAuthenticated, handleAutomaticLogin } = useAuth(qlikConfig);

  useEffect(() => {
    handleAutomaticLogin();
  }, [handleAutomaticLogin]);

  return (
    <div className="d-flex flex-column" style={{height: '100vh'}}>
      <div className="toolbar flex-shrink-0">{title}</div>
      <div id="bar" className="flex-shrink-0"></div>
      <div id="iframe" className="flex-grow-1">
        {isAuthenticated ? (
          <IframeComponent src={qlikConfig.iframeSrc} title={title} />
        ) : (
          <div>Authenticating...</div>
        )}
      </div>
    </div>
  );
};

export default QlikComponent;