import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table, Dimmer } from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { PersonasForm } from "../../../Catalogos";
import ModalLay from "../../../components/ModalLay/ModalLay";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";

class Contactos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactos: [],
      agregar: false,
      selectedRow: -1,
      ContactosDisponibles: [],
      clientesContactos: [],
      Personas: [],
      FHabilitados: [],
      searchP: "",
      searchM: "",
      searchN: "",
      searchCorreo: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      currentTap: 0,
      pagination: [],
      pagina: 1,
      pagesize: 10,
      agregar: false,
    };
    this.content = [];
    this.Array = [];
    this.pagination = [];
    this.content = [];
    this.contactosPagination = [];
    this.contactosDisponiblesPagination = [];
  }
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "personas/cliente/" + this.props.elementos.id,
        "contactos"
      );
      //this.cargaPersonas("");
      //this.cargaClientesContactos(this.props.elementos.id);
    }
  };
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Personas !== this.state.Personas) {
      //alert("es igual")
    } else {
      /*alert(this.state.personas) */
    }
  };
  checkedPersonas = (indice) => {
    return this.personasArray[indice].estatus;
  };
  checarPersonas = (estatus, id) => {
    this.personasArray.push({ estatus: estatus, id: id });
  };
  checarPersonaTem = (valor) => {
    this.checarEtiqueta = valor;
  };
  searchingPaterno = (event) => {
    this.setState({ searchP: event.target.value });
    if (
      this.state.agregar &&
      /*
        this.state.ContactosDisponibles.filter(
          (word) =>
            word.aPaterno
              .toUpperCase()
              .includes(event.target.value.toUpperCase()) &&
            word.aMaterno
              .toUpperCase()
              .includes(this.state.searchM.toUpperCase()) &&
            word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase())
        ).sort().length == 0 && */
      !event.target.value.toUpperCase().includes("/")
    ) {
      this.paginarNombre(
        this.state.searchN,
        event.target.value,
        this.state.searchM
      );
    }
  };
  searchingMaterno = (event) => {
    this.setState({ searchM: event.target.value });
    if (
      this.state.agregar &&
      /*
        this.state.ContactosDisponibles.filter(
          (word) =>
            word.aPaterno
              .toUpperCase()
              .includes(this.state.searchP.toUpperCase()) &&
            word.aMaterno
              .toUpperCase()
              .includes(event.target.value.toUpperCase()) &&
            word.nombre.toUpperCase().includes(this.state.searchN.toUpperCase())
        ).sort().length == 0 && */
      !event.target.value.toUpperCase().includes("/")
    ) {
      this.paginarNombre(
        this.state.searchN,
        this.state.searchP,
        event.target.value
      );
    }
  };
  searchingNombre = (event) => {
    this.setState({ searchN: event.target.value });
    if (
      this.state.agregar &&
      /*
        this.state.ContactosDisponibles.filter(
          (word) =>
            word.aPaterno
              .toUpperCase()
              .includes(this.state.searchP.toUpperCase()) &&
            word.aMaterno
              .toUpperCase()
              .includes(this.state.searchM.toUpperCase()) &&
            word.nombre.toUpperCase().includes(event.target.value.toUpperCase())
        ).sort().length == 0 && */
      !event.target.value.toUpperCase().includes("/")
    ) {
      this.paginarNombre(
        event.target.value,
        this.state.searchP,
        this.state.searchM
      );
    }
  };
  searchingCorreo = (event) => {
    this.setState({ searchCorreo: event.target.value });
    if(event.target.value.length > 2)
      this.getfuntion(
        "get",
        "",
        "",
        "personas/correo?correo=" + event.target.value+"&validar="+ false, "ContactosDisponibles" );
  };
  paginarNombre = (nombre, paterno, materno) => {
    if (this.state.agregar) {
      this.getfuntion(
        "get",
        "",
        "",
        "personas/disponibles/cliente/" +
          this.props.elementos.id +
          "/nombre/" +
          (nombre.length > 0 ? nombre : null) +
          "/paterno/" +
          (paterno.length > 0 ? paterno : null) +
          "/materno/" +
          (materno.length > 0 ? materno : null) +
          "/pagina/0/total/" +
          this.state.pagesize,
        "ContactosDisponibles"
      );
    }
  };

  cleanArrayList = () => {
    this.personasArray = [];
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas",
    stateVar = "personas",
    hiddenModl = ""
  ) => {
    let catTemp = "";
    if (metodo === "post" && catalogo === "personas") {
      catTemp =
        "personas/plataforma/" +
        (this.props.plataforma ? this.props.plataforma.id : 61);
    } else {
      catTemp = catalogo;
    }
    Crud_Catalogos(
      catTemp,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "contactos") {
                for (let item of returnVal) {
                  if (!item.aMaterno) {
                    item.aMaterno = "";
                  }
                  if (!item.aPaterno) {
                    item.aPaterno = "";
                  }
                  if (!item.nombre) {
                    item.nombre = "";
                  }
                }
                this.setState({ contactos: returnVal });
              }
              if (stateVar === "ContactosDisponibles") {
                if (returnVal.content) {
                  for (let item of returnVal.content) {
                    if (!item.aMaterno) {
                      item.aMaterno = "";
                    }
                    if (!item.aPaterno) {
                      item.aPaterno = "";
                    }
                    if (!item.nombre) {
                      item.nombre = "";
                    }
                  }
                  this.setState({ ContactosDisponibles: returnVal.content });
                  this.paginacion(returnVal);
                  this.contactosDisponiblesPagination = returnVal;
                }else{
                  let personasTemp=[];
                  for(let p of returnVal){
                    if(p.aPaterno === null)  p.aPaterno= "";
                    if(p.aMaterno === null)  p.aMaterno= "";
                    personasTemp.push(p);
                  }
                  this.setState({ ContactosDisponibles: personasTemp });
                }
              }
            }
            break;
          case "post":
            {
              if (stateVar === "personas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se ha agregado contacto al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  let contactosTem = this.state.contactos;
                  contactosTem.unshift(returnVal);
                  this.setState({ contactos: contactosTem });
                  this.getfuntion(
                    "post",
                    { idCliente: this.props.elementos, idPersona: returnVal },
                    "",
                    "clientesContactos",
                    "clientesContactos"
                  );
                }
              }
              if (stateVar === "clientesContactos") {
                if (returnVal.idPersona) {
                  NotificationManager.info(
                    "El contacto ahora pertenece al cliente( " +
                      this.props.elementos.nombre +
                      " )",
                    "Nuevo"
                  );
                  if (this.state.agregar) {
                    let contactosTem = this.state.ContactosDisponibles;
                    contactosTem.map((item, index) => {
                      if (item.id == returnVal.idPersona.id) {
                        contactosTem.splice(index, 1);
                        let contactossTem = this.state.contactos;
                        contactossTem.unshift(item);
                        this.setState({ contactos: contactossTem });
                      }
                    });
                    this.setState({ ContactosDisponibles: contactosTem });
                  }
                  this.setState({ searchP: "" });
                  this.setState({ searchM: "" });
                  this.setState({ searchN: "" });
                  this.setState({ agregar: false });
                }
                this.hiddenmodal();
              }
            }
            break;
          case "put":
            {
              if (stateVar === "clientes") {
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "clientesContactos") {
                if (returnVal) {
                  let contactosTem = this.state.contactos;
                  contactosTem.map((item, index) => {
                    if (item.id == obj.id) {
                      contactosTem.splice(index, 1);
                      this.setState({ contactos: contactosTem });
                    }
                  });
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  table_headers = () => {
    this.content = [];
    let cont = this.state.agregar
      ? this.state.ContactosDisponibles
      : this.state.contactos;
    if (cont) {
      if (cont[0]) {
        Object.keys(cont[0]).forEach((key) =>
          this.content.push({ content: key })
        );
        this.content.push({
          content: this.state.agregar ? "agregar" : "editar",
        });
        if (!this.state.agregar) {
          this.content.push({ content: "eliminar" });
        }
      }
    }
  };
  _editCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <ModalLay
          tamanio={"90%"}
          title="Modificar"
          name={
            <Button pill size="sm" outline color="primary">
              <Icon name="edit" />
            </Button>
          }
          formulario={PersonasForm}
          datosForm={{
            accion: "modificar",
            getfuntion: this.getfuntion,
            stor: this.props.stor,
            elementos: elemento,
            plataforma: this.props.plataforma,
          }}
        />
      </Table.Col>
    );
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Remover"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "delete",
                elemento,
                this.props.elementos.id + "/" + elemento.id,
                "clientesContactos/borrar",
                "clientesContactos"
              )
            }
          >
            <Icon name="trash" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  contactosDisponibles = () => {
    this.setState({ searchP: "" });
    this.setState({ searchM: "" });
    this.setState({ searchN: "" });

    this.setState({ agregar: true });
    this.setState({ pagina: 1 });
    if (!this.state.ContactosDisponibles[0]) {
      this.getfuntion(
        "get",
        "",
        "",
        "personas/disponibles/cliente/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize,
        "ContactosDisponibles"
      );
    }
    this.paginacion(this.contactosDisponiblesPagination);
  };
  paginacion = (pag) => {
    this.pagination = pag;
    this.pagination.content = [];
    this.setState({ pagination: this.pagination });
  };
  cancelar = () => {
    this.setState({ searchP: "" });
    this.setState({ searchM: "" });
    this.setState({ searchN: "" });

    this.setState({ agregar: false });
  };
  _addCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Agregar"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "post",
                { idCliente: this.props.elementos, idPersona: elemento },
                "",
                "clientesContactos",
                "clientesContactos"
              )
            }
          >
            <Icon name="plus" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  render() {
    return (
      <div>
        {/**
         *   Contactos del cliente
         */}
        {this.state.accion === "modificar" ? (
          <div>
            <Grid.Row>
              <Grid.Col>
                {!this.state.agregar ? (
                  <Card
                    title={"."}
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div className="row">
                        <div className="col">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Paterno..."
                              position="append"
                              value={this.state.searchP}
                              onChange={this.searchingPaterno}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>
                        <div className="col">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Materno..."
                              position="append"
                              value={this.state.searchM}
                              onChange={this.searchingMaterno}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>
                        <div className="col">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Nombre..."
                              position="append"
                              value={this.state.searchN}
                              onChange={this.searchingNombre}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>
                        <div className="col">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Nuevo contacto"}
                            onClick={() => this.contactosDisponibles()}
                          >
                            <h3 className="text-primary"><Icon name="plus" /></h3>
                          </span>
                        </div>
                      </div>
                    }
                    body={
                      <div>
                        <h> El Cliente {this.props.elementos.nombre } cuenta con los siguientes contactos </h>
                        {this.state.contactos ? (
                          <Form.Group>
                            <Table
                              cards={true}
                              striped={true}
                              responsive={true}
                              className="table-vcenter"
                              {...this.table_headers()}
                              headerItems={this.content}
                            >
                              <Table.Body>
                                {this.state.contactos
                                  .filter(
                                    (word) =>
                                      word.aPaterno
                                        .toUpperCase()
                                        .includes(
                                          this.state.searchP.toUpperCase()
                                        ) &&
                                      word.aMaterno
                                        .toUpperCase()
                                        .includes(
                                          this.state.searchM.toUpperCase()
                                        ) &&
                                      word.nombre
                                        .toUpperCase()
                                        .includes(
                                          this.state.searchN.toUpperCase()
                                        )
                                  )
                                  .sort()
                                  .map((elemento, index) => (
                                    <Table.Row>
                                      {(this.colum = [])}
                                      {
                                        (Object.keys(elemento).forEach(
                                          (key, index) => {
                                            this.colum.push(
                                              <Table.Col>
                                                {" "}
                                                {elemento[key]}{" "}
                                              </Table.Col>
                                            );
                                          }
                                        ),
                                        this._editCol(elemento))
                                      }
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        ) : null}
                      </div>
                    }
                  />
                ) : (
                  <Card
                    title="."
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div className="row">
                        <div className="col-3">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="correo"
                              position="append"
                              value={this.state.searchCorreo}
                              onChange={this.searchingCorreo}
                            />
                          </span>
                        </div>
                        <div className="col-3">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Paterno..."
                              position="append"
                              value={this.state.searchP}
                              onChange={this.searchingPaterno}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>{/*
                        <div className="col-3">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Materno..."
                              position="append"
                              value={this.state.searchM}
                              onChange={this.searchingMaterno}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>*/}
                        <div className="col-3">
                          <span>
                            <Form.Input
                              className="mb-2"
                              icon="search"
                              placeholder="Nombre..."
                              position="append"
                              value={this.state.searchN}
                              onChange={this.searchingNombre}
                              onKeyDown={this._searchingkey}
                            />
                          </span>
                        </div>
                        <div className="col-2">
                          <span>
                            <ModalLay
                              tamanio={"80%"}
                              hiddenModal={this.state.hiddenModals}
                              Fclose={this.hiddenmodal}
                              title="Nuevo"
                              name={
                                <h3 className="text-primary"><Icon name="plus" /></h3>
                              }
                              formulario={PersonasForm}
                              datosForm={{
                                accion: "nuevo",
                                elementos: [],
                                getfuntion: this.getfuntion,
                                stor: this.props.stor,
                              }}
                            />
                          </span>
                        </div>
                        <div className="col-1">
                          <span
                            className="float-right ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Cancelar"}
                            onClick={() => this.cancelar()}
                          >
                            <h3 className="text-danger"><Icon name="x" /></h3>
                          </span>
                        </div>
                      </div>
                    }
                    body={
                      <div>
                        <h5>Acontinuación se muestra del catalogo general, los contactos ya agregados disponibles</h5>
                        <Form.Group>
                          <Grid.Row>
                            <Grid.Col>
                              <Dimmer
                                active={!this.state.ContactosDisponibles[0]}
                                loader
                              ></Dimmer>
                            </Grid.Col>
                          </Grid.Row>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                            {...this.table_headers()}
                            headerItems={this.content}
                          >
                            <Table.Body>
                              {this.state.ContactosDisponibles.filter(
                                (word) =>
                                  word.aPaterno
                                    .toUpperCase()
                                    .includes(
                                      this.state.searchP.toUpperCase()
                                    ) &&
                                  word.aMaterno
                                    .toUpperCase()
                                    .includes(
                                      this.state.searchM.toUpperCase()
                                    ) &&
                                  word.nombre
                                    .toUpperCase()
                                    .includes(this.state.searchN.toUpperCase())
                              )
                                .sort()
                                .map((elemento, index) => (
                                  <Table.Row
                                    style={
                                      this.state.selectedRow === index
                                        ? {
                                            "background-color":
                                              "rgba(95, 231, 50, 0.30)",
                                          }
                                        : null
                                    }
                                    onClick={() => {
                                      this.setState({ selectedRow: index });
                                    }}
                                  >
                                    {(this.colum = [])}
                                    {
                                      (Object.keys(elemento).forEach(
                                        (key, index) => {
                                          this.colum.push(
                                            <Table.Col>
                                              {" "}
                                              {elemento[key]}{" "}
                                            </Table.Col>
                                          );
                                        }
                                      ),
                                      this._addCol(elemento))
                                    }
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-4">
                            <nav aria-label="...">
                              <ul class="pagination">
                                <li className="page-item">
                                  <span
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number > 0
                                          ? this.paginar(
                                              this.state.pagination.number - 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Anterior
                                  </span>
                                </li>
                                {this.getpaginationLinks()}
                                <li class="page-item">
                                  <a
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number <
                                          this.state.pagination.totalPages
                                          ? this.paginar(
                                              this.state.pagination.number + 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Siguiente
                                  </a>
                                </li>
                                <li class="page-item">
                                  <h3>
                                    <div className="row">
                                      <div className="col-4 text-right">
                                        {" "}
                                        <Form.Input
                                          name="pag212"
                                          type="number"
                                          value={this.state.pagina}
                                          onKeyDown={this._buscar}
                                          onChange={this._pag}
                                        />{" "}
                                      </div>
                                      <div className="col-8 text-left">
                                        {" "}
                                        /
                                        {this.state.pagination
                                          ? this.state.pagination.totalPages
                                            ? this.state.pagination.totalPages
                                            : 1
                                          : 0}
                                        Páginas{" "}
                                      </div>
                                    </div>
                                  </h3>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-6 text-right">
                                {" "}
                                Total por página{" "}
                              </div>
                              <div className="col-6">
                                {" "}
                                <Form.Input
                                  name="pagTotal"
                                  type="number"
                                  value={this.state.pagesize}
                                  onChange={this._pagesize}
                                  onKeyDown={this._changePagesize}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
              </Grid.Col>
            </Grid.Row>
          </div>
        ) : null}
      </div>
    );
  }
  paginar = (pag) => {
    if (this.state.agregar) {
      if (
        this.state.searchP.length > 0 ||
        this.state.searchM.length > 0 ||
        this.state.searchN.length > 0
      ) {
        //http://localhost:8000/personas/disponibles/cliente/8254/nombre/pedro/paterno/gallardo/materno/null/pagina/0/total/100
        this.getfuntion(
          "get",
          "",
          "",
          "personas/disponibles/cliente/" +
            this.props.elementos.id +
            "/nombre/" +
            (this.state.searchN ? this.state.searchN : null)+
            "/paterno/" +
            (this.state.searchP ? this.state.searchP : null) +
            "/materno/" +
            (this.state.searchM ? this.state.searchM : null)+
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "ContactosDisponibles"
        );
      } else {
        //http://localhost:8000/personas/disponibles/cliente/8254/pagina/0/total/100
        this.getfuntion(
          "get",
          "",
          "",
          "personas/disponibles/cliente/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "ContactosDisponibles"
        );
      }
      this.setState({ pagina: pag + 1 });
    }
  };
  getpaginationLinks = () => {
    var links = [];
    var numberPag = this.pagination.number;
    if (this.pagination) {
      for (let i = numberPag; i < numberPag + 5; i++) {
        if (i === numberPag) {
          links.push(
            <li class="page-item active" aria-current="page">
              {" "}
              <span class="page-link">
                {" "}
                {i + 1} <span class="sr-only">(current)</span>{" "}
              </span>{" "}
            </li>
          );
        } else {
          if (i < this.pagination.totalPages) {
            links.push(
              <li class="page-item">
                <a class="page-link" onClick={() => this.paginar(i)}>
                  {i + 1}
                </a>
              </li>
            );
          }
        }
      }
    }
    return links;
  };
  _changePagesize = (e) => {
    if (e.key === "Enter") {
      this.paginar(0);
    }
  };
  _searchingkey = (e) => {
    if (e.key === "Enter") {
      if (e.target.value == 0) {
        this.paginar(0);
      }
    }
  };
  _pagesize = (e) => this.setState({ pagesize: e.target.value });
  _buscar = (e) => {
    if (e.key === "Enter") {
      if (this.pagination.totalPages) {
        this.paginar(e.target.value - 1);
      }
    }
  };
  _pag = (event) => {
    this.setState({
      pagina:
        event.target.value > this.pagination.totalPages
          ? this.pagination.totalPages
          : this.pagination.totalPages
          ? event.target.value
          : 1,
    });
  };
}
export default Contactos;
