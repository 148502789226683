import React from "react";
import classes from "./Dash.module.css";

const Dash = (props) => {
  const value = ((100 / props.total) * props.cantidad).toFixed(3) + "%";
  return (
    <div
      className={`${classes.card} col-lg-3 col-6 mt-2`}
      style={{
        backgroundColor: props.color ? props.color : "rgba(88, 208, 186, 0.3)",
      }}
    >
      <div className="row">
        {props.showBar && (
          <div className="col-6">
            <div className="ml-2">
              <h3 className={classes.textWhite}>{props.cantidad}</h3>
              <h5 className={classes.textWhite}>{props.texto}</h5>
            </div>
          </div>
        )}
        {!props.showBar && (
          <div>
            <div className="col-3">
              <div className="ml-2">
                <h3>{props.instalados}</h3>
                <h5>{"Instalados"}</h5>
              </div>
            </div>
            <div className="col-3">
              <div className="ml-2">
                <h3>{props.recuperar}</h3>
                <h5>{"Por recuperar"}</h5>
              </div>
            </div>
          </div>
        )}
        <span className="icon col-6">
          <i
            className="fa fa-bar-chart float-right mt-2 mr-2"
            style={{ "font-size": "60px" }}
          ></i>
        </span>
      </div>

      <div className="small-box-footer">
        {!props.showBar && <p>{props.texto}</p>}
        {props.showBar && (
          <div className="progress">
            <div
              className="progress-bar text-dark"
              role="progressbar"
              style={{
                width: value,
              }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <strong className={classes.textWhite}>{value}</strong>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dash;
