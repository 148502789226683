import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Form, Table } from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { Doughnut, Pie } from 'react-chartjs-2'
//import classes from "./tablero.css";


export const TableroCapturaEnTiempo = () => {

  const [acv, setAcv] = useState([]);
  const [url, setUrl] = useState([]);
  const [status, setStatus] = useState();
  useEffect(() => {
    changeStatus();
  }, [])
  //const datos;
  const [datos, setDatos] = useState([]);
  useEffect(() => {
    tablero();
  })

  const changeStatus = () => {
    status?setStatus(false):setStatus(true);
    const today = new Date();
    const yesterday= new Date();
    const tomorroy= new Date();

    yesterday.setDate(today.getDate() - 1);
    tomorroy.setDate(today.getDate() + 1);

//    var fechaInicio=yesterday.getFullYear().toString()+"-"+(yesterday.getMonth() + 1).toString()+"-"+yesterday.getDate();
//    var fechaFin=today.getFullYear().toString()+"-"+(today.getMonth() + 1).toString()+"-"+today.getDate();
    var fechaInicio=today.getFullYear().toString()+"-"+(today.getMonth() + 1).toString()+"-"+today.getDate();

    var fechaFin=tomorroy.getFullYear().toString()+"-"+(tomorroy.getMonth() + 1).toString()+"-"+tomorroy.getDate();
    


   // alert("informes/capturaEnTiempo/"+fechaInicio+"/"+fechaFin+"/1,2,7");

/*    mm: date.getMonth() + 1,
    dd: date.getDate(),
    yy: date.getFullYear().toString().slice(-2),
    yyyy: date.getFullYear()*/

    status?setUrl("informes/capturaEnTiempo/"+fechaInicio+"/"+fechaFin+"/721,7,2,1,130,130,95,4,259,6"):setUrl      ("informes/capturaEnTiempo/"+fechaInicio+"/"+fechaFin+"/3");
    //alert(url);
    //setDatos(null);
    //setDatos({datos: []}); // class component
    //setDatos([]); // functional component
    //datos.length = 0;

    setDatos([]);
    setAcv([]);

    getfuntion("get", [], "", url, "").then((returnVal) => {
      setAcv([...returnVal]);
      returnVal.map((usr,index) =>  
       setDatos(datos=>[...datos,{
         datasets: [{
            backgroundColor: [
            "#83ce83",
            "#f96a5d",
            "#6800B4",
            "#00A6B4",
           ],
         data : [usr.on_time,usr.middle_time,usr.out_time] 
       }],
        //label: '# of Votes',
        //labels: ['En tiempo','40 minutos','Retrasado']
         }])
        )
       });

  }

  const tablero = () => {


  }


  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
      }
    })
  }

 

 

  return (
    <SiteWrapper>
      <div className="container">
        <h1>Tablero de captura en tiempo: </h1>
        <div>CMA-APL<Form.Switch
                    type="Checkbox"
                    name="toggle"
                     value={status}
                    checked={status}
                    onChange={changeStatus}
                  />CROWLEY,MSC,HS,MAERS,ONE LINE...</div>
        <Grid.Col sm={12} lg={12}>
          <Card>
 
            <div className="col col-12 col-sm-12 ">
            
                  {
                  acv.map((usr,index) =>
                        <div   className="container mt-3"  style={{width:'33%',float:'left',}} >                                                    
                       {usr.username}<Doughnut data={datos[index]}/>
                        </div>
                     ) 
                  }  
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter">
                    <Table.Header align="center">
                    <Table.ColHeader>Id Usuario</Table.ColHeader>
                      <Table.ColHeader>Nombre de usuario</Table.ColHeader>
                      <Table.ColHeader>0-20 min</Table.ColHeader>
                      <Table.ColHeader>20-40 min</Table.ColHeader>
                      <Table.ColHeader>&gt;40min</Table.ColHeader>
                      <Table.ColHeader>Porcentaje</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>                
                        {                        
                        acv.map((usr,index) =>
                        <Table.Row align="center" >
                          <Table.Col> {usr.id_usuario}    </Table.Col>
                          <Table.Col> {usr.username}    </Table.Col>
                          <Table.Col> {usr.on_time}      </Table.Col>
                          <Table.Col> {usr.middle_time }         </Table.Col>
                          <Table.Col> {usr.out_time }        </Table.Col>
                          <Table.Col> {(100*usr.on_time/(usr.on_time+usr.middle_time+usr.out_time)).toFixed(0) }  %      </Table.Col>
                        </Table.Row>
                      ) 
                      }   
                    </Table.Body>
                  </Table>
                  
               
              

            </div>
          </Card>
 

        </Grid.Col>



      </div>

    </SiteWrapper>
  )
}
export default TableroCapturaEnTiempo;