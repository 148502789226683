import React, { useState, useEffect } from "react";//359, 361, 362, 363, 364
import { Form } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { TablePageable } from "../../../../Helpers";
const  Incidencias =({ accion, elementos, stor })=> {//elementos es  plataformasObject
  const [incidencias, setIncidencias]= useState([]);
  useEffect(() => {
    if(accion !== 'nuevo'){
      getfuntion("get", "", "", "plataformasIncidencias/plataforma/"+elementos.id,"Incidencias");
    }
  },[]);
  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "Incidencias"){
              setIncidencias(returnVal);
            }
            break;
          case "post":
            if (stateVar === "Incidencias"){
              getfuntion("get", "", "", "plataformasIncidencias/plataforma/"+elementos.id,"Incidencias");
            }
            break;
          case "delete":
            if (stateVar === "Incidencias"){
              getfuntion("get", "", "", "plataformasIncidencias/plataforma/"+elementos.id,"Incidencias");
            }
            break;
          default:
            break;
        }
      }).catch(err =>{ console.log(err); });
  }
  
  return (
    <div>
      { accion === "modificar" ?
        <div>
          <TablePageable
            titulo={ <h5> Seleccione las Incidencias para su Plataforma </h5> }
            lista= { stor.incidencias_object }
            columnas={
              [
                { columna: "id", label: "Id" },
                { columna: "nombre", label: "Nombre" },
                { columna: "incidenciaTipo.nombre", label: "Tipo" },
                { columna: "idEtiqueta", label: "idEtiqueta" },
                { columna: "etiqueta", label: "Etiqueta",
                  valor: (e)=> {
                    const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                    if(e.idEtiqueta === 871){ console.log("871********************* "+ (etiquetaTemp.length === 1 ? etiquetaTemp[0].nombre: ""))}
                    return etiquetaTemp.length === 1 ? etiquetaTemp[0].nombre: "";
                  },
                  form: (e)=>{
                    const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                    return etiquetaTemp.length === 1 ? etiquetaTemp[0].nombre: "";
                  }
                },
                { columna: "estatus", label: "Estatus",
                  valor: (e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length? true: false,
                  form: (e)=> <Form.Switch type="Checkbox" name="toggle" value="option1"
                  checked={ incidencias.filter(i => i.incidencia.id === e.id  ).length }
                  onChange={ () =>{
                    let plataformasIncidencias={ "plataforma": elementos, "incidencia": e };
                    let incidenciaTemp= incidencias.filter(i => i.incidencia.id === e.id  );
                    incidenciaTemp.length
                    ? getfuntion("delete", "", incidenciaTemp[0].id, "plataformasIncidencias", "Incidencias")
                    :
                      getfuntion("post", plataformasIncidencias, "", "plataformasIncidencias", "Incidencias");
                  }} />
                },
              ]
            }
            id= "id"
            selected={(e)=>{
              const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
              if( etiquetaTemp.length !== 1){
                alert("Precaución la incidencia: \n"+ e.nombre + " \n No esta configurada para envío de notificaciones "+ "\n Contacta a sistemas");
              }
            }}
            csvname= {"Incidencias de la plataforma "+ elementos.nombre}
            resaltarFuncion={(e)=>{
              return incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false
            }}
            resaltarColor={(e)=>{
              const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
              if( etiquetaTemp.length !== 1){
                return "rgba(242, 151, 151, 0.41)";
              }
              return "rgba(58, 223, 149, 0.25)";
            }}
            paginacion={true}
            elementosPorPagina={15}
          />
        </div>
      :null}
    </div>
  )
}

export default Incidencias
