import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class  RutasEstatus extends Component {
  constructor(props) {
    super(props);
                            this.state  = { 
                                              id: "",
                                          accion: this.props.accion, //'nuevo'// modificar, nuevo
                                          RutasEstatus:[],
                                          search:""
                                        };
                     this.RutasEstatus  = [];
                this.plataformasObject  = [];
               this.checarRutasEstatus  = 0;      //temporal render
        this.idplataformasRutasEstatus  = 0; //temporal render
    this.plataformasRutasEstatusObject  = [];
                this.RutasEstatusArray  = [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "plataformasRutasEstatus/plataforma/"+this.props.elementos.id,"RutasEstatus")
    }else{}
  }
  getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "RutasEstatus"){ this.RutasEstatus= returnVal; this.setState({ RutasEstatus: returnVal }); }
            }
            break;
          case "post":
            {
            if (stateVar === "RutasEstatus"){
                this.RutasEstatus.push(returnVal);
                this.setState({ RutasEstatus: this.RutasEstatus });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "RutasEstatus"){
                this.RutasEstatus.map((elemento, index)=>
                  {if(id === elemento.id){
                    this.RutasEstatus.splice(index, 1);
                  }}
                )
                this.setState({ RutasEstatus: this.RutasEstatus })
              }
            }
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  
  changeRutasEstatus =(RutasEstatusIndex, OtdId, OtdStatus) => {
      this.plataformasRutasEstatusObject={
        "plataforma": this.plataformasObject,
        "ruta": this.props.stor.rutasEstatus_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[RutasEstatusIndex]
      }  
      
      this.RutasEstatusArray[RutasEstatusIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.RutasEstatusArray[RutasEstatusIndex].id, "plataformasRutasEstatus", "RutasEstatus")
      :
      this.getfuntion("post",this.plataformasRutasEstatusObject, "", "plataformasRutasEstatus", "RutasEstatus");
    }
    checkedRutasEstatus =(indice) =>{
      return this.RutasEstatusArray[indice].estatus
    }
    checarRutasEstatuss =(estatus, id) =>{
      this.RutasEstatusArray.push({ estatus: estatus, id: id })
    }
    checarRutasEstatusTem =(valor) =>{
      this.checarRutasEstatus=valor;
    }
    searching =(event) =>{
      this.setState({ search: event.target.value })
    }
    cleanArrayList =() =>{
      this.RutasEstatusArray= [];
    }
  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {this.cleanArrayList() }
        { this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Rutas Estatus"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed={ false}
                  options={ <Form.Group>
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Ruta estatus..."
                                position="append"
                                onChange={this.searching}
                              />
                            </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.rutasEstatus_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["nombre"] }</Table.Col>
                                  { this.checarRutasEstatusTem(0)}
                                  { this.idplataformasRutasEstatus="" }
                                  { 
                                    this.state.RutasEstatus.map((elemRutasEstatus, ind) =>
                                    {
                                      if(elemRutasEstatus.ruta.id === elemento.id){
                                        { this.checarRutasEstatusTem(1)}
                                        this.idplataformasRutasEstatus=elemRutasEstatus.id;
                                      }
                                    })
                                  }
                                  { this.checarRutasEstatuss(this.checarRutasEstatus, this.idplataformasRutasEstatus)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                          checked={ this.checkedRutasEstatus(index) }
                                                          className={"disabled"}
                                                          onChange={ () => this.changeRutasEstatus(index, elemento["id"], this.checarRutasEstatus) } /></Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default RutasEstatus
