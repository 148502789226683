import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";
import Select from "react-select";
class Variables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      tipo: { value: 0, label: "Selecciona una opción" },
      etiqueta: "",
      longitud: 0,
      variables: [],
      search: "",
      crear: true, // true nuevo, false editar
    };

    this.tipos = [
      {value: 0, label: "Seleccione una opción"},
      {value: 1, label: "String"},
      {value: 2, label: "Number"},
      {value: 3, label: "Boolean"},
      {value: 4, label: "Date"},
      {value: 5, label: "DateTime"},
      {value: 6, label: "Time"},
      {value: 7, label: "Double"},
      {value: 8, label: "Any"},
    ];
    this.campos = [];
    this.variableObject = {
      idPlataforma: this.props.elementos.id,
      nombre: "",
      etiqueta: "",
      tipo: "",
      longitud: 0
    };
    this.estatusRutas = [];
  }

  componentDidMount = () => {
    if (this.props.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "variables/" + this.props.elementos.id,
        "variables"
      );
    }
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "loquequieras",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "variables") {
                this.setState({ variables: returnVal });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "variables") {
                this.limpiar();
                let variablesTemp= this.state.variables;
                variablesTemp.push(returnVal);
                this.setState({ variables: variablesTemp});
              }
            }
            break;
          case "put":
            {
              if (stateVar === "variables") {
                if (returnVal.id) {
                  this.limpiar();
                  let variablesTemp= this.state.variables;
                  for (let item of variablesTemp) {
                    if (
                      item.idPlataforma === returnVal.idPlataforma
                      && item.nombre === returnVal.nombre
                      ) {
                      item = returnVal;
                      this.setState({
                        variables: variablesTemp,
                      });
                      break;
                    }
                  }
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "variables") {
                let variablesTemp= this.state.variables;
                for (let i = 0; i < variablesTemp.length; i++) {
                  if (
                    variablesTemp[i].idPlataforma === returnVal.idPlataforma
                    && variablesTemp[i].nombre === returnVal.nombre
                    ) {
                      variablesTemp.splice(i, 1);
                      this.setState({ variables: variablesTemp });
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  limpiar = () => {
    this.setState({ nombre: "" });
    this.setState({ etiqueta: "" });
    this.setState({ longitud: 0 });
    this.setState({ tipo: { value: 0, label: "Selecciona una opción" }});    
    this.setState({ crear: true }); //se cambia a crear
    this.variableObject = {
      idPlataforma: this.props.elementos.id,
      nombre: "",
      etiqueta: "",
      tipo: "",
      longitud: 0
    };
  };
  disableSave = () => {
    if (
      this.state.nombre.length &&
      this.state.etiqueta.length &&
      this.state.longitud &&
      this.state.tipo.value
    ) {
      return false;
    } else {
      return true;
    }
  };

  changeNombre = (event) => {
    this.variableObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  };
  changeEtiqueta = (event) => {
    this.variableObject.etiqueta = event.target.value;
    this.setState({ etiqueta: event.target.value });
  };
  changeLongitud = (event) => {
    this.variableObject.longitud = event.target.value;
    this.setState({ longitud: event.target.value });
  };
  
  changeTipo = (event) => {
    this.variableObject.tipo = event.label;
    this.setState({ tipo: event });
  };

  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  crud_delete = (idPlataforma, nombre) => {
    this.getfuntion(
      "delete",
      "",
      idPlataforma+"/"+nombre,
      "variables",
      "variables"
    );
  };
  crud_put = () => {
    this.getfuntion(
      "put",
      this.variableObject,
      "",
      "variables",
      "variables"
    );
  };
  crud_post = () => {
    this.getfuntion(
      "post",
      this.variableObject,
      "",
      "variables",
      "variables"
    );
  };

  guardar = () => {
    if (this.state.crear) {
      this.crud_post();
    } else {
      this.crud_put();
    }
    this.props.cerrarModal();
  };
  setEdit = (obj) => {
    this.variableObject = obj;
    this.setState({ nombre: obj.nombre });
    this.setState({ etiqueta: obj.etiqueta });
    this.setState({ longitud: obj.longitud });    
    for (let item of this.tipos)
      if (item.label === obj.tipo)
        this.setState({ tipo: item });
    this.setState({ crear: false });
  };

  render() {
    return (
      <div>
        <div>
          <Grid.Row>
            <Grid.Col>
              <Card
                title="Alertas"
                isCollapsible={false}
                isCollapsed={false}
                body={
                  <div>
                    <h2>{this.state.crear ? "Nuevo" : "Editar"}</h2>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        <Grid.Col sm={12} md={4} className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar..."
                            position="append"
                            onChange={this.searching}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col sm={12} md={4}>
                        <Form.Group label="Nombre (*)">
                          <Form.Input
                            type="text"
                            value={this.state.nombre}
                            onChange={this.changeNombre}
                            disabled={!this.state.crear}
                          />
                        </Form.Group>
                        <Form.Group label="Etiqueta (*)">
                          <Form.Input
                            type="text"
                            value={this.state.etiqueta}
                            onChange={this.changeEtiqueta}
                          />
                        </Form.Group>
                        <Form.Group label="Longitud (*)">
                          <Form.Input
                            type="number"
                            value={this.state.longitud}
                            onChange={this.changeLongitud}
                          />
                        </Form.Group>                        
                        <Form.Group label="Tipo (*)">
                          <Select
                            value={this.state.tipo}
                            onChange={this.changeTipo}
                            options={this.tipos}
                            isDisabled={!this.state.crear}
                          />
                        </Form.Group>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="danger"
                              onClick={() => this.limpiar()}
                            >
                              <span class="badge">
                                <Icon link={true} name="x" />
                              </span>
                            </Button>
                          </span>
                        </div>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="primary"
                              disabled={this.disableSave()}
                              onClick={this.guardar}
                            >
                              <span class="badge">
                                <Icon link={true} name="save" />
                                Guardar
                              </span>
                            </Button>
                          </span>
                        </div>
                      </Grid.Col>

                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>Nombre</Table.ColHeader>
                              <Table.ColHeader>Etiqueta</Table.ColHeader>
                              <Table.ColHeader>Longitud</Table.ColHeader>
                              <Table.ColHeader>Tipo</Table.ColHeader>
                              <Table.ColHeader>Editar</Table.ColHeader>
                              {/**  <Table.ColHeader>Acción</Table.ColHeader> */}
                            </Table.Header>
                            <Table.Body>
                              {this.state.variables.filter(v=>
                                v.nombre.toUpperCase().includes(this.state.search.toUpperCase()) ||
                                v.etiqueta.toUpperCase().includes(this.state.search.toUpperCase()))
                                .sort()
                                .map((elemento, index) => (
                                  <Table.Row>
                                    <Table.Col>
                                      {elemento["nombre"]}
                                    </Table.Col>
                                    <Table.Col>
                                      {elemento["etiqueta"]}
                                    </Table.Col>
                                    <Table.Col>
                                      {elemento["longitud"]}
                                    </Table.Col>
                                    <Table.Col>
                                      { elemento["tipo"] }
                                    </Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="primary"
                                        onClick={() => this.setEdit(elemento)}
                                      >
                                        <Icon name="edit" />
                                      </Button>
                                    </Table.Col>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tús datos antes de guardar ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
              />
            </Grid.Col>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

export default Variables;