import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import { NotificationManager } from 'react-notifications';
import { mail } from "../../herramientas/Forms";
const Transportista =({accion, elementos, setTransportista})=> {
  const [formulario, setFormulario]= useState({
    nombre: "",
    nombreCorto: "",
    contactoNombre: "",
    telefono1: "",
    telefono2: "",
    correo: "",
    tipo: 1,
    cron: "00:00:00",
    mabeCveTransportista: ""
  });
  useEffect(()=>{
    if (accion !== "nuevo") {
      if(elementos)
        setFormulario(elementos);
    }
  },[]);

  const enableSave = () => {
    if (
      formulario.nombre.length > 0 &&
      formulario.nombreCorto.length > 0 &&
      formulario.contactoNombre.length > 0 &&
      formulario.telefono1.length > 0 &&
      mail(formulario.correo).estatus
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {accion === "modificar" ? (
        <h1> {elementos.nombre} </h1>
      ) : null}
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <Card
            title="Tensportista"
            isCollapsible
            isCollapsed={false}
            body={
              <div>
                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="nombre">
                      <Form.Input
                        name="nombre"
                        type="text"
                        value={formulario.nombre}
                        onChange={(event)=> setFormulario({ ...formulario, nombre: event.target.value }) }
                        maxlength="40"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="nombreCorto">
                      <Form.Input
                        name="nombreCorto"
                        type="text"
                        value={formulario.nombreCorto}
                        onChange={(event)=> setFormulario({ ...formulario, nombreCorto: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={6} lg={6}>
                    <Form.Group label="Nombre Contacto">
                      <Form.Input
                        name="contactoNombre"
                        type="text"
                        value={formulario.contactoNombre}
                        onChange={(event)=> setFormulario({ ...formulario, contactoNombre: event.target.value })}
                        maxlength="40"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Telefono 1">
                      <Form.Input
                        name="telefono1"
                        type="number"
                        value={formulario.telefono1}
                        onChange={(event)=> setFormulario({ ...formulario, telefono1: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Telefono 2">
                      <Form.Input
                        name="telefono2"
                        type="number"
                        value={formulario.telefono2}
                        onChange={(event)=> setFormulario({ ...formulario, telefono2: event.target.value }) }
                        maxlength="20"
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col md={6} lg={3}>
                    <Form.Group label="Correo">
                      <Form.Input
                        name="correo"
                        type="mail"
                        value={formulario.correo}
                        onChange={(event)=> setFormulario({ ...formulario, correo: event.target.value }) }
                        maxlength="10"
                      />
                      { formulario.correo.length > 3 ? (
                        <label style={{ color: "red" }}>
                          { mail(formulario.correo).mensaje }
                        </label>
                      ) : null}
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </div>
            }
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={12} lg={12}>
          <div className="float-right">
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={ enableSave() }
                onClick={()=>{ setTransportista(formulario); NotificationManager.info(
                  "Se ha agregado el transportista " + formulario.nombre, "Nuevo");
                }}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          </div>
        </Grid.Col>
      </Grid.Row>
      {/**
       *   Elementos de plataformas
       */}
    </div>
  );
}

export default Transportista;
