import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";
import axios from 'axios';
import Modal from 'react-modal';
import Select from "react-select";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import ModalLayout from "../../components/ModalLayout.react";
import * as conf from "../../config/config";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


class UsuarioOperacionClienteForm extends Component {
    constructor() {
        super();
        this.state = {
            plataforma: { value: 0, label: "Seleccione una plataforma" },
            corredores: [],
            tabla_usuario: false,
            usuarios: [],
            nombreUsuario: "",
            active: null,
            tabla_corredores: false,
            plataformaid: 0,
            usuariosCorredores: [],
            nombreUsuarioId: "",
            turno: "1",
            nombrePantallaId: "",
            modalIsOpen: false,
            salasMonitoreo: [],
            salaMonitorOcupada: [],
            idusuarioSala: [],
            idSalas: [],
            hiddenModals: false,
            transportistas:[],
            pTransportistas:[]
        };
        this.closeModal = this.closeModal.bind(this);
        this.plataformas= [];
    }
    closeModal() {
        this.setState({modalIsOpen: false});
    }
    componentWillMount(){
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        this.plataformas.push({ value: 0, label: "Seleccione una plataforma" });
        currentUser.map((usr, index) => {
          if(usr.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE" ){
            this.plataformas.push({ value: usr.plataforma.id, label: usr.plataforma.nombre });
          }
        });

        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            if (res.data.length>0){
              const salaMonitorOcupada = res.data
              this.setState({salaMonitorOcupada})
            }
        });

    }

    changePlataformas = (event) =>{
        //this.setState({plataformaid: event.value});
        this.setState({ plataforma: event });
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        this.getfuntion("get", "", "", "corredores/plataforma/"+event.value, "corredores");

        axios.get(conf.api_raiz+"usuarios/plataforma/"+event.value).then(res => {
          console.log(res);
          if (res.data.length>0){
            const usuarios = res.data
            this.setState({usuarios})
          }
        });
        /*
        axios.get(conf.api_raiz+"operacionesClientes"+"/tipoId/"+2).then(res => {
          console.log(res);
          if (res.data.length>0){
            const corredores = res.data
            this.setState({corredores})
          }
        });
        */

        axios.get(conf.api_raiz+"salasMonitoreo").then(res => {
          if (res.data.length>0){
            const salasMonitoreo = res.data
            this.setState({salasMonitoreo})
          }
        });
        this.state.salaMonitorOcupada.map(
          (salasMonitorOcu, index) =>{
            if (salasMonitorOcu){
              this.state.idusuarioSala.push(salasMonitorOcu.idUsuario);
            }
          }
        );
        if (this.state.salaMonitorOcupada) {
          this.state.salaMonitorOcupada.map( (salasMonitorOcu, index) => {
            if (salasMonitorOcu) {
              this.state.idSalas.push(salasMonitorOcu.idSalaMonitoreo);
            }
          });
        }
        this.setState({tabla_usuario: true});
        this.setState({tabla_corredores: false});

    }

    editarCorredor(id, nombre){
        this.setState({nombreUsuario: nombre});
        this.setState({nombreUsuarioId: id});
        this.actualizainformacion(id);
        this.setState({modalIsOpen: true});
        this.setState({tabla_corredores: true});
    }


    actualizainformacion = (id) => {
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz+"usuarioOperacionCliente/usuario/"+id+"/plataforma/"+this.state.plataforma.value).then(res => {
            const usuariosCorredores = res.data
            this.setState({usuariosCorredores})
        });
    }

    agregaroaliminar(idCorredor){
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;
        let arrayes = [] ;
        this.state.usuariosCorredores.map((usuarioCorredor, index) =>
            arrayes.push(usuarioCorredor.idOperacionCliente)
        );
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        if (arrayes.indexOf(idCorredor) === -1) {
            axios.post(conf.api_raiz+"usuarioOperacionCliente", {
                    "idUsuario": this.state.nombreUsuarioId,
                    "idOperacionCliente": idCorredor,
                    "idPlataforma": this.state.plataforma.value,
                    "fechaAsignacion": fechaIngresar,
                    "turnoMonitoreo": this.state.turno
                }).then(res => {

                this.actualizainformacion(this.state.nombreUsuarioId);

            });
        }
        if (arrayes.indexOf(idCorredor) > -1){
            axios.delete(conf.api_raiz+"usuarioOperacionCliente/"+this.state.nombreUsuarioId+"/"+idCorredor+"/"+this.state.plataforma.value).then(res => {
                this.actualizainformacion(this.state.nombreUsuarioId);
            });
        }
    }

    agregarPantalla(idPantallaUsuario){
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;
        let arrayes = [] ;
        this.state.usuariosCorredores.map((usuarioCorredor, index) =>
            arrayes.push(usuarioCorredor.idOperacionCliente)
        );

        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        if (arrayes.indexOf(idPantallaUsuario) === -1) {
            axios.post(conf.api_raiz+"usuariosPantalla", {
                    "idUsuario": idPantallaUsuario,
                    "idPlataforma": this.state.plataforma.value,
                    "fechaAsignacion": fechaIngresar
                }).then(res => {
            });
        }
        if (arrayes.indexOf(idPantallaUsuario) > -1){
            axios.delete(conf.api_raiz+"usuariosPantalla/"+idPantallaUsuario+"/"+this.state.plataforma.value).then(res => {
            });
        }
        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            const salaMonitorOcupada = res.data
            this.setState({salaMonitorOcupada})
        });
    }

    actualizaUsuarios(){
        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
            const salaMonitorOcupada = res.data
            this.setState({salaMonitorOcupada})
        });

    }

    salaMonitoreoRegistro(e, idUsuario) {

        var salaid = e.target.value;
        var fecha = new Date(); //Fecha actual
        var mes = fecha.getMonth()+1; //obteniendo mes
        var dia = fecha.getDate(); //obteniendo dia
        var ano = fecha.getFullYear(); //obteniendo año
        var hora = fecha.getHours();
        var minuto = fecha.getMinutes();
        var seg = fecha.getSeconds();
        if(dia<10)
            dia='0'+dia; //agrega cero si el menor de 10
        if(mes<10)
            mes='0'+mes;
        let fechaIngresar = ano+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+seg;

        let instance = axios.create();
        var tokenStore="tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        axios.get(conf.api_raiz+"usuariosPantalla/"+idUsuario).then(res => {
            if(Object.keys(res.data).length === 0){
                axios.post(conf.api_raiz+"usuariosPantalla", {
                        "idUsuario": idUsuario,
                        "idSalaMonitoreo": salaid,
                        "fechaAsignacion": fechaIngresar
                    }).then(res => {
                        this.actualizaUsuarios();
                });

            }else{
                axios.delete(conf.api_raiz+"usuariosPantalla/"+idUsuario).then(res => {
                    axios.get(conf.api_raiz+"usuariosPantalla").then(res => {
                        const salaMonitorOcupada = res.data;
                        this.setState({salaMonitorOcupada});
                    });
                    if(Object.keys(res.data).length === 0){
                        axios.post(conf.api_raiz+"usuariosPantalla", {
                                "idUsuario": idUsuario,
                                "idSalaMonitoreo": salaid,
                                "fechaAsignacion": fechaIngresar
                            }).then(res => {
                                this.actualizaUsuarios();
                        });
                    }
                });
            }
        });
    }

    myColor(position) {
        let arrayes = [] ;
        this.state.usuariosCorredores.map((usuarioCorredor, index) =>
            arrayes.push(usuarioCorredor.idOperacionCliente)
        );
        for (let index = 0; index < arrayes.length; index++) {
            if (arrayes[index] == position) {
                return "green";
            }
        }
        return "gray";
    }

    myColors(position) {
        let arrayes = [] ;
        this.state.usuariosPantallas.map((usuarioPantalla, index) =>
            arrayes.push(usuarioPantalla.idUsuario)
        );
        for (let index = 0; index < arrayes.length; index++) {
            if (arrayes[index] == position) {
                return "green";
            }
        }
        return "gray";
    }

    tipoTurno = (event) =>{
        this.setState({turno: event.target.value});
    }
    verificarSalaUsuario = (idUsuario, salasMonitor) =>{
        if(this.state.idusuarioSala.indexOf(idUsuario) != -1){
            if(this.state.idSalas[this.state.idusuarioSala.indexOf(idUsuario)] === salasMonitor){
                //console.log("INGRESO ESTOS DATOS-> "+this.state.idSalas[this.state.idusuarioSala.indexOf(idUsuario)]+" salaMonitor"+salasMonitor);
                return true;
            }
        }
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                {
                  if (stateVar === "perfil"){ return returnVal }
                  
                  if (stateVar === "transportistas"){
                    this.setState({ transportistas: returnVal });
                  }
                  if (stateVar === "usuariosCorredores"){
                    this.setState({ pcorredores: returnVal });
                    this.setState({ usuariosCorredores: returnVal });
                  }
                  if (stateVar === "usuarios_transportistas"){
                    this.setState({ pTransportistas: returnVal });
                  }
                  if(stateVar === "corredores"){
                    this.setState({ corredores: returnVal });
                  }
                }
                break;
              case "post":
                {
                  if (stateVar === "usuariosCorredores" && returnVal.idCorredor ){
                    let pcorredoresTemp= this.state.pcorredores;  
                    pcorredoresTemp.push(returnVal);
                    this.setState({ pcorredores: pcorredoresTemp });
                  }
                  if (stateVar === "usuarios_transportistas"){
                    let ptransportistasTemp= this.state.pTransportistas;  
                    ptransportistasTemp.push(returnVal);
                    this.setState({ pTransportistas: ptransportistasTemp });
                  }
                }
                break;
              case "delete":
                {
                  if (stateVar === "usuariosCorredores" && returnVal){
                    let varTemp= this.state.pcorredores;                    
                    varTemp.map((elem, index )=>{
                      if(obj.idCorredor === elem.idCorredor ){ varTemp.splice( index, 1);  this.setState({ pcorredores: varTemp }); }
                    });
                  }
                  if (stateVar === "usuarios_transportistas" && returnVal){ 
                    let varTemp= this.state.pTransportistas;
                    varTemp.map((elem, index )=>{
                      if(obj.idTransportista === elem.idTransportista ){ varTemp.splice( index, 1);  this.setState({ pTransportistas: varTemp }); }
                    });
                  }
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
      buscarConfiguracion=(idUsuario)=>{
        this.getfuntion("get", "", "", "plataformasUsuariosPerfiles/idUsuario/"+idUsuario+"/idPlataforma/"+this.state.plataforma.value, "perfil").then((returnVal) => {
            this.setState({ currentPerfil: returnVal });
            this.editarCorredor(idUsuario, returnVal.usuario.username);
            if(this.state.currentPerfil.perfil){
              switch (this.state.currentPerfil.perfil.movimientosBy) {
                case 1://personalizado
                  {
                  }
                  break;
                case 2://corredores
                
                break;
                case 3://transportista
                {
                  this.getfuntion("get", "", "", "usuarioOperacionTransportista/usuario/"+idUsuario+"/plataforma/"+this.state.plataforma.value, "usuarios_transportistas");
                  this.getfuntion("get", "", "", "transportistas/plataforma/"+this.state.plataforma.value, "transportistas");
                }
                break;
                default:
                  break;
              }
            }
        });
    }
    changeTransportista=(event)=>{
        let objTemp= {idUsuario: this.state.nombreUsuarioId, idTransportista: parseInt(event.target.value, 10), idPlataforma: this.state.plataforma.value };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "usuarioOperacionTransportista","usuarios_transportistas");  
      }else{
        this.getfuntion("delete", objTemp, this.state.nombreUsuarioId+"/"+parseInt(event.target.value, 10)+"/"+this.state.plataforma.value, "usuarioOperacionTransportista","usuarios_transportistas");
      } 
    }
    getTransportista    =(id)=>{ for( let  item of this.state.pTransportistas ){  if( item.idTransportista === id ){   return true; } } return false; }

    render() {
        return (
            <Page.Card>
                <Form.Group label="Solo se muestran las plataformas asignadas">
                    <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} />
                </Form.Group>

                { this.state.tabla_usuario ?
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>UserName</Table.ColHeader>
                        <Table.ColHeader>Nombre</Table.ColHeader>
                        <Table.ColHeader>A. Paterno</Table.ColHeader>
                        <Table.ColHeader>A. Materno</Table.ColHeader>
                        <Table.ColHeader>Segmento</Table.ColHeader>
                        <Table.ColHeader>Usuario en Sala</Table.ColHeader>

                    </Table.Header>
                    <Table.Body>

                    {this.state.usuarios.map((usuario, index) =>
                        <Table.Row align="center">
                            <Table.Col>
                                {usuario.id}
                            </Table.Col>
                            <Table.Col>
                                {usuario.username}
                            </Table.Col>
                            <Table.Col>
                                {usuario.persona.nombre}
                            </Table.Col>
                            <Table.Col>
                                {usuario.persona.aPaterno}
                            </Table.Col>
                            <Table.Col>
                                {usuario.persona.aMaterno}
                            </Table.Col>
                            <Table.Col >
                                <ModalLayout  tamanio={"90%"} title="Actualizar"
                                              name={ <Button onClick={() => this.buscarConfiguracion(usuario.id) } pill color="azure"><i class="fa fa-pencil"></i></Button> }
                                              hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                                              formulario={
                                                <div>
                                                 {this.state.currentPerfil?
                                                  <div>
                                                      { this.state.currentPerfil.perfil.movimientosBy === 2 ?//corredores
                                                        <Card>
                                                          <Card.Header>
                                                            <Card.Title>Corredores del usuario {this.state.nombreUsuario} </Card.Title>
                                                            <Card.Options>
                                                                <Form.Group >
                                                                    <Form.Select onChange={this.tipoTurno}>
                                                                    <option value={1}>Matutino</option>
                                                                    <option value={2}>Vespertino</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Card.Options>
                                                          </Card.Header>
                                                          <Card.Body>
                                                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                              <Table.Header align="center">
                                                                <Table.ColHeader>ID</Table.ColHeader>
                                                                <Table.ColHeader>Nombre</Table.ColHeader>
                                                                <Table.ColHeader>Nombre Corto</Table.ColHeader>
                                                                <Table.ColHeader>Agregar/editar Corredor </Table.ColHeader>
                                                              </Table.Header>
                                                              <Table.Body>
                                                                {this.state.corredores.map((corredor, index) =>
                                                                  <Table.Row align="center">
                                                                    <Table.Col>{corredor.id}</Table.Col>
                                                                    <Table.Col>{corredor.nombre}</Table.Col>
                                                                    <Table.Col>{corredor.nombreCorto}</Table.Col>
                                                                    <Table.Col>
                                                                      <Button onClick={() => {this.agregaroaliminar(corredor.id)}} pill color={this.myColor(corredor.id)}><i class="fa fa-pencil"></i></Button>
                                                                    </Table.Col>
                                                                  </Table.Row>
                                                                )}
                                                              </Table.Body>
                                                            </Table>
                                                          </Card.Body>
                                                        </Card>
                                                      : "" }
                                                      { this.state.currentPerfil.perfil.movimientosBy === 3 ? //transportista
                                                        <Card>
                                                          <Card.Body>
                                                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                                              <Table.Header align="center">
                                                                  <Table.ColHeader>Id</Table.ColHeader>
                                                                  <Table.ColHeader>Transportista  </Table.ColHeader>
                                                                  <Table.ColHeader>estatus </Table.ColHeader>
                                                              </Table.Header>
                                                              <Table.Body>
                                                                {this.state.transportistas.map((t, index) =>
                                                                  <Table.Row align="center">
                                                                      <Table.Col>{ t.id }</Table.Col>
                                                                      <Table.Col>{ t.nombre }</Table.Col>
                                                                      <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getTransportista(t.id) } onChange={ this.changeTransportista} /></Table.Col>
                                                                  </Table.Row>
                                                                )}
                                                              </Table.Body>
                                                            </Table>                                                            
                                                          </Card.Body>
                                                        </Card>
                                                      : ""  }
                                                  </div>
                                                 :null}
                                                </div>                                                
                                               }
                                />
                            </Table.Col>{/*
                            <Table.Col >
                                    <Button onClick={() => this.editarCorredor(usuario.id, usuario.username)} pill color="azure"><i class="fa fa-pencil"></i></Button>
                            </Table.Col>*/}
                            <Table.Col>
                                <Form.Select onChange={e => this.salaMonitoreoRegistro(e, usuario.id)}>
                                <option value={0}>Seleccione una Opcion Terrestres</option>
                                    {this.state.salasMonitoreo.map((salasMonitor, index) =>
                                    //this.verificarSalaUsuario(usuario.id, salasMonitor.id),
                                    //console.log("FALSO O VERDADERO "+index)
                                    <option id={salasMonitor.id} value={salasMonitor.id} selected={this.verificarSalaUsuario(usuario.id, salasMonitor.id)}>{salasMonitor.nombre}</option>
                                    )}
                                </Form.Select>
                            </Table.Col>
                        </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                : null}
<br/>
              {/*
                <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal} >
                    <Card>
                        <Card.Header>
                            <Card.Title>Operaciones Clientes {this.state.nombreUsuario} </Card.Title>
                            <Card.Options>
                                <Form.Group >
                                    <Form.Select onChange={this.tipoTurno}>
                                    <option value={1}>Matutino</option>
                                    <option value={2}>Vespertino</option>
                                    </Form.Select>
                                </Form.Group>
                            </Card.Options>
                        </Card.Header>
                        <Card.Body>
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                                <Table.Header align="center">
                                    <Table.ColHeader>ID</Table.ColHeader>
                                    <Table.ColHeader>Nombre</Table.ColHeader>
                                    <Table.ColHeader>Nombre Corto</Table.ColHeader>
                                    <Table.ColHeader>Agregar/editar Corredor </Table.ColHeader>
                                </Table.Header>
                                <Table.Body>
                                {console.log("437 this.state.corredores")}
                                {console.log(this.state.corredores)}
                                {this.state.corredores.map((corredor, index) =>
                                    <Table.Row align="center">
                                        <Table.Col>{corredor.id}</Table.Col>
                                        <Table.Col>{corredor.nombre}</Table.Col>
                                        <Table.Col>{corredor.nombreCorto}</Table.Col>
                                        <Table.Col>
                                            <Button onClick={() => {this.agregaroaliminar(corredor.id)}} pill color={this.myColor(corredor.id)}><i class="fa fa-pencil"></i></Button>
                                        </Table.Col>
                                    </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Button.List align="right">
                                <Button onClick={() => {this.closeModal()}} color="danger">Cerrar</Button>
                            </Button.List>
                        </Card.Footer>
                    </Card>
                </Modal>
              */}                 
            </Page.Card>
        );
    }
}

export default UsuarioOperacionClienteForm;
