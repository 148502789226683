export default [
 
  [19.60685,-99.21134,'LC53507','20200103'],
  [19.60697,-99.16367,'LC11907','20200103'],
  [19.63112,-99.19331,'42AL1C','20200103'],
  [19.60541,-99.18498,'7342ZP','20200103'],
  [19.36037,-99.48405,'LB96746','20200103'],
  [18.83296,-97.33080,'056FD4','20200103'],
  [20.59478,-101.13943,'LC24307','20200104'],
  [19.41743,-97.44490,'LC73730','20200104'],
  [20.000937,-102.715458,'59AA9K','20200111'],
  [20.42973,-103.42638,'61UE6D','20200112'],
  [19.63540,-99.11168,'LC32897','20200113'],
  [19.715273,-99.207522,'666FE3/MRKU9260912','20200114'],
  [19.66205,-99.08025,'LB38767','20200114'],
  [19.242217,-99.644652,'188EX5','20200114'],
  [20.684539,-103.27279,'JNL3541','20200116'],
  [20.57653,-103.31834,'JV18939','20200120'],
  [19.40452,-98.19044,'902DN9','20200121'],
  [19.69738,-99.09341,'30ER5G','20200122'],
  [19.72586,-98.95489,'68AK5W/MRKU5403379','20200122'],
  [19.62923,-99.04879,'97AN2U','20200123'],
  [21.10329,-100.53895,'901EV8','20200123'],
  [20.61588,-103.26940,'90AL5P','20200125'],
  [19.64987,-99.19251,'LD39624','20200127'],
  [19.34751,-99.10666,'NKL9555','20200127'],
  [19.44557,-99.28240,'98AK1A','20200129'],
  [20.10657,-102.66889,'262WC1','20200131'],
  [20.51441,-103.28713,'32AD9T','20200201'],
  [19.63948,-99.14978,'313AR5','20200204'],
  [21.07568,-100.51087,'90AN2F','20200131'],
  [19.75141,-98.75679,'105EV5','20200204'],
  [19.586516,-99.200916,'52AD8C','20200206'],
  [19.26744,-98.44276,'97AK1A','20200208'],
  [20.103625,-98.397208,'51AF2M','20200210'],
  [32.54338,-116.93698,'36AN5S','20200211'],
  [19.63956,-99.15216,'LB18377','20200211'],
  [19.55400,-99.18528,'LB69757','20200212'],
  [20.95515,-100.42388,'79AD3D','20200213'],
  [19.57855,-99.21119,'19AL39','20200215'],
  [20.375355666667,-102.10873383333,'765AM4/981UN1','20200215'],
  [18.01487,-94.39292,'95AN7F','20200216'],
  [19.60395,-99.18782,'822EN8','20200220'],
  [19.52767,-99.17424,'6442ZP','20200220'],
  [19.84771,-102.10802,'98AR4X','20200221'],
  [19.46872,-99.15777,'LC33595','20200224'],
  [19.33603,-99.54842,'80AF4T','20200225'],
  [19.29959,-99.36966,'74AJ7C','20200225'],
  [19.65307,-99.17347,'383FE8','20200227'],
  [19.93118,-99.09657,'84AD9S','20200227'],
  [19.37594,-99.56520,'876EJ6','20200303'],
  [19.45754,-98.88165,'68AA1P','20200303'],
  [19.16490,-97.96755,'24AJ4S','20200305'],
  [19.63612,-99.19970,'963AM2','20200305'],
  [19.534300,-102.073085,'95AB9B','20200305'],
  [18.90628,-96.98983,'35AK4Z','20200306'],
  [20.59665,-103.32156,'41AK7G','20200308'],
  [19.71377,-99.19471,'387DF1','20200309'],
  [18.017479,-94.472736,'81AN9W','20200310'],
  [19.60054,-99.18866,'572AU4','20200311'],
  [19.42067,-99.0558,'LB92365','20200311'],
  [19.62333,-99.19315,'LB93215','20200312'],
  [19.48902,-99.21931,'LC64781','20200312'],
  [19.44715,-102.07585,'73AG1B','20200312'],
  [18.89654,-103.85877,'848EH9','20200313'],
  [20.45036,-103.52060,'949EX9','20200314'],
  [19.71374,-99.19824,'03AJ3X','20200317'],
  [20.56264,-103.28368,'71AJ5X','20200318'],
  [19.761318,-99.215507,'15AD5J','20200318'],
  [20.36919,-99.98269,'39AF5Y','20200318'],
  [19.41176,-99.00633,'LB24157','20200319'],
  [19.65784,-99.17319,'LD73973','20200320'],
  [19.61629,-99.19004,'56AJ8B','20200323'],
  [19.298745,-98.876109,'21AJ4J','20200324'],
  [19.61113,-99.19168,'NUV7101','20200324'],
  [19.56238,-99.20344,'911FE9','20200324'],
  [19.32933,-98.86694,'LB38613','20200324'],
  [19.32556,-101.75627,'39AJ3H','20200325'],
  [19.52021,-99.16524,'LC20494','20200325'],
  [19.60969,-99.15338,'12AN4D','20200325'],
  [19.84171,-98.95039,'760AF3','20200326'],
  [20.51069,-100.96210,'LB06384','20200326'],
  [23.71897,-99.09573,'41AM6Y','20200326'],
  [24.17237,-101.48485,'36AB8G','20200330'],
  [19.29327,-98.47762,'93AC4V','20200401'],
  [19.33823,-97.96827,'24AK2V','20200402'],
  [19.75988,-99.21487,'308DN9','20200403'],
  [19.28563,-98.42909,'68AM5N','20200407'],
  [19.61499,-99.03129,'90AD1S','20200407'],
  [20.04663,-99.58402,'LB51265','20200408'],
  [19.61342,-99.19057,'36AN5S','20200408'],
  [19.56392,-99.20699,'19AN8B','20200413'],
  [20.579819999999998,-100.9818333333333,'691AR6','20200415'],
  [18.813701666666667,-97.18850499999999,'17AL5N','20200416'],
  [20.64556,-103.3585,'LC23173','20200417'],
  [20.424006,-100.053008,'628EV8','20200418'],
  [20.020918333333334,-101.13781666666667,'909EP9','20200419'],
  [19.066851,-102.342342,'CGMU5227089','20200419'],
  [20.62107,-100.8232,'09AD2L','20200420'],
  [19.849508,-98.707022,'NRV1525','20200422'],
  [19.888558,-99.341561,'88AK4J','20200426'],
  [19.67461,-99.15763,'LD14030','20200427'],
  [20.553628333333332,-100.83099333333332,'688AR6','20200429'],
  [20.585389,-100.991889,'616EV8','20200430'],
  [20.59729,-100.95836,'26AK4Z','20200430'],
  [20.56487,-100.24420,'99AK5G','20200502'],
  [20.63007,-103.26428,'LC23155','20200505'],
  [19.69501,-99.08132,'80AC9T','20200506'],
  [19.63840,-99.15163,'36AD2A','20200506'],
  [19.158138,-98.293054,'627EV8','20200508'],
  [19.59771,-99.2002,'16AN8B','20200512'],
  [19.45416,-99.21748,'LC20494','20200512'],
  [19.69010,-99.10188,'18AT6W','20200512'],
  [19.06227,-98.09149,'64AE5U','20200512'],
  [20.583188333333332,-100.99213,'513EV8','20200514'],
  [20.60970,-103.32632,'68AN3K','20200515'],
  [19.351899,-98.668284,'51AH6P','20200515'],
  [19.374514,-99.007375,'NDJ1863','20200518'],
  [20.49105,-103,26733,'83AB7U','20200519'],
  [18.83804,-97.08093,'85AE8J/50AM3A','20200519'],
  [23.07876,-100.50019,'27AK4Z','20200520'],
  [26.89302,-101.42049,'37AB5Z','20200520'],
  [19.491131,-99.308928,'855TD7','20200520'],
  [19.453724,-99.305986,'494AR6/119ES5','20200520'],
  [20.580145,-100.98577666666667,'99AN8V','20200520'],
  [18.82805,-97.11969,'26AJ4S','20200521'],
  [19.46009,-97.69323,'6849ZP','20200522'],
  [19.54411,-99.23657,'LC20494','20200526'],
  [19.302618,-98.409511,'980EW2','20200526'],
  [19.65398,-99.06667,'98AK1A','20200527'],
  [20.715720,-101.369041,'930YC9','20200530'],
  [19.26462,-98.43247,'HB5644C','20200530'],
  [19.64132,-99.15252,'NAJ9694','20200601'],
  [19.92657,-99.37567,'966ZMS','20200602'],
  [19.82187,-98.58719,'109FE3','20200603'],
  [20.40651,-103.23006,'MEDU7512640/MSDU7246969','20200603'],
  [19.739592,-99.21189,'15AA9U','20200604'],
  [19.23838,-98.85294,'735DB9','20200605'],
  [19.62337,-97.18019,'MNBU3950211,MNBU3853736','20200606'],
  [20.63292,-100.40556,'24AN7D','20200606'],
  [20.57849,-103.36523,'11AB6T','20200608'],
  [19.54974,-99.07831,'LE06978','20200609'],
  [19.070549,-98.131218,'39AK8U','20200609'],
  [19.272254999999998,-98.43187166666667,'603EV8','20200609'],
  [19.63784666666667,-99.1645516666668,'73AL2N','20200610'],
  [19.637846666666667,-99.16455166666668,'179Y5','20200610'],
  [20.64631,-103.34935,'688DP1','20200610'],
  [19.64847,-99.15548,'12AN4F','20200611'],
  [20.087806,-99.679750,'NUS2706','20200612'],
  [19.30263,-98.40929,'68AD5T','20200613'],
  [19.72165,-101.19520,'79AF6Z','20200613'],
  [19.692639,-99.143111,'873ES2','20200616'],
  [20.64116,-100.28214,'51AD1S','20200616'],
  [20.40548,-100.03335,'16AM8U','20200617'],
  [20.572029,-100.906197,'17AL5N','20200617'],
  [19.750833,-99.176791,'07AL5D','20200617'],
  [19.53040,-99.17856,'LC20494','20200618'],
  [20.583352,-101.009806,'71AN5Z','20200618'],
  [19.27182,-98.43178,'46AA3F','20200618'],
  [20.03567,-98.75274,'87AH3W','20200618'],
  [19.72184,-99.20812,'428FD9','20200618'],
  [20.558245,-100.8222783333333,'18AN7D','20200619'],
  [19.23097,-97.89292,'55AG2T','20200622'],
  [24.042645,-104.680723,'FYN364B','20200622'],
  [18.768253333333334,-96.44811999999999,'41AN7V','20200624'],
  [19.7084,-99.1930,'12AD9V','20200625'],
  [19.31260,-98.50141,'LC37424','20200626'],
  [19.69557,-99.20449,'76AE9P','20200701'],
  [21.02981,-101.54968,'800DE2','20200701'],
  [19.36332,-99.08214,'64AF5H','20200701'],
  [19.70134,-99.20541,'Carlos Cadenas','20200702'],
  [20.547762,-100.798866,'75AA6F','20200702'],
  [19.62647,-99.13579,'51AD1S','20200702'],
  [9.64536,-98.98642,'LC64781','20200703'],
  [7.85583,-101.12970,'19AA4H','20200703'],
  [19.37469,-97.61514,'199EG1','20200703'],
  [20.552553,-100.802629,'33AD9K','20200704'],
  [19.62265,-99.16137,'LB84711','20200706'],
  [20.6307265,-103.3341213,'JW68300','20200707'],
  [20.582917,-100.992194,'608EV8//079YE2','20200707'],
  [19.28511,-99.53111,'41AJ4Z','20200707'],
  [19.62409,-99.18873,'LE48807','20200709'],
  [19.71623,-99.20710,'92AD4X','20200710'],
  [19.33149,-98.71975,'41AN9W','20200710'],
  [16.84602,-96.78605,'98AK1A','20200711'],
  [19.740397,-99.102681,'51AC4R','20200712'],
  [19.318205,-98.860623,'637VV3','20200712'],
  [19.993248,-99.470844,'52UA4Z','20200712'],
  [20.35327,-101.14864,'42AD1F','20200714'],
  [19.72416,-99.19756,'LC66732','20200714'],
  [19.6184066667,-99.0658488888,'743EV6','20200714'],
  [19.40728,-98.09835,'116YE3','20200714'],
  [19.10931,-97.59154,'30AM2Y','20200714'],
  [20.57235,-103.21340,'LB84727','20200715'],
  [20.093438,-99.29941,'APZU3652573','20200715'],
  [17.779941,-92.679889,'05AH4P','20200716'],
  [19.63585,-99.18069,'LB33557','20200716'],
  [20.57978,-103.35004,'580DP1','20200716'],
  [20.569599999999998,-100.89008833333334,'022ER1','20200720'],
  [20.55822,-100.82228666666667,'693EP9','20200721'],
  [20.56,-100.83989833333332,'393YE2','20200721'],
  [20.571747,-100.902178,'69AM7A','20200721'],
  [20.571747,-100.902178,'528AR6','20200721'],
  [20.571747,-100.902178,'90AN5Z','20200721'],
  [19.52952,-99.18016,'KZ84841','20200722'],
  [20.57159,-100.900420,'055EW3','20200722'],
  [19.214756,-96.208537,'72AD9Z-,MNBU3814180,MNBU3598693','20200723'],
  [19.65513,-99.18323,'NAJ9694','20200723'],
  [19.606199,-99.189606,'MRKU5316227','20200726'],
  [19.606199,-99.189606,'17AL7X','20200726'],
  [19.68902,-99.19821,'LD38897','20200727'],
  [19.37639,-99.09485,'310FE9','20200728'],
  [20.551572,-103.303142,'33AJ3J','20200731'],
  [19.90192,-100.57917,'LC25514','20200802'],
  [20.54105333333333,-101.13787833333332,'37AK3X','20200803'],
  [19.59853,-99.19372,'84AC3V','20200804'],
  [19.89594,-98.93044,'LC25514','20200804'],
  [19.7220122,-99.1900057,'43AN4V','20200805'],
  [19.24665,-98.86868,'45AF2H','20200805'],
  [23.116827,-98.750687,'472DC4','20200805'],
  [18.81927,-97.52092,'51AG9S','20200805'],
  [20.58087,-100.69574,'32AA4H','20200810'],
  [19.840406,-99.27808,'16AD8U','20200812'],
  [20.530701,-100.720403,'02AA8N','20200813'],
  [20.56293,-100.85470,'624EV8/Eco,3526','20200815'],
  [20.542866665,-100.734405,'055EW3/Eco,3658','20200815'],
  [19.332867,-99.314007,'HL5986E','20200817'],
  [24.74687,-107.43984,'96AM5R','20200818'],
  [21.10084,-101.78173,'483DF1','20200818'],
  [18.96964,-99.22060,'LF11287','20200819'],
  [19.74229,-98.94607,'LD44149','20200820'],
  [16.928258,-99.801668,'01AH2M','20200820'],
  [18.28181,-96.17779,'18AL5G','20200821'],
  [20.51820,-100.85672,'040DC2','20200822'],
  [19.31405,-98.50353,'LC25795','20200824'],
  [19.66738,-99.18059,'LD38728','20200824'],
  [21.46785,-104.84049,'07AF1M','20200824'],
  [19.28468,-98.45591,'24AP6B','20200826'],
  [19.49621,-102.07096,'TLLU1059680','20200901'],
  [19.49621,-102.07096,'TTNU8631546/TLLU1052392','20200901'],
  [19.59934,-99.18363,'61AL4G','20200901'],
  [19.27230,-98.46096,'86AP2P','20200902'],
  [21.63680,-100.72254,'13AN4F','20200903'],
  [19.87031,-98.94113,'LE06994','20200904'],
  [19.25870,-98.41091,'MRKU0679390','20200905'],
  [20.534384,-100.531449,'GJX791B','20200905'],
  [19.65099,-99.16487,'LD38877','20200907'],
  [16.769153,-93.194860,'552AU4','20200909'],
  [19.297405,-99.354516,'LC22200','20200910'],
  [19.829324,-99.898366,'27AN4V','20200910'],
  [19.53070,-99.17167,'3618ZP','20200911'],
  [19.92303,-98.90066,'19AL5G','20200911'],
  [20.58883,-100.81209,'90AK2G','20200911'],
  [19.66848,-99.16741,'LB04734','20200914'],
  [19.296003,-98.925424,'03AL1K','20200914'],
  [19.36211,-99.07091,'792EY5','20200915'],
  [19.62457,-99.18502,'92AK8K','20200917'],
  [19.64505,-99.19633,'LD84662','20200917'],
  [19.519664,-99.219105,'LD39855','20200917'],
  [19.61823,-99.18620,'LE48807','20200918'],
  [19.28551,-98.44128,'58AL1M','20200918'],
  [22.33873,-100.79722,'MSDU9031764/MSDU9014730','20200918'],
  [22.33873,-100.79722,'MSDU9031403','20200918'],
  [20.52770,-100.72923,'su-612','20200918'],
  [21.68752,-100.69204,'93AM5R','20200919'],
  [18.86132,-97.06139,'47AG6A','20200919'],
  [19.50692,-99.17510,'98AK1A','20200919'],
  [19.51479,-99.15394,'51AF2M','20200921'],
  [19.63739,-99.19097,'10AN4F','20200921'],
  [19.64886,-99.15803,'95AG3X','20200921'],
  [20.52932,-103.24002,'23AD5K','20200922'],
  [19.59848,-99.19286,'47AB3R','20200923'],
  [19.255217,-98.914615,'47AF8P','20200923'],
  [19.68460,-99.14896,'3796CM','20200924'],
  [18.83735,-97.08111,'625ET4','20200924'],
  [25.85274,-97.50012,'35AB5Z','20200924'],
  [19.621578,-99.159675,'427EH9','20200929'],
  [19.59895,-99.19848,'26AF4T','20200929'],
  [19.45930,-99.23039,'LB23431','20201001'],
  [19.56535,-99.20580,'45AE4J','20201002'],
  [19.89129,-101.025767,'435EY4','20201002'],
  [19.35050,-96.32621,'70AB4Z','20201002'],
  [20.5296684,-101.2057720,'MTT3408','20201006'],
  [20.81364,-101.66219,'29AN8Y','20201007'],
  [19.93894,-99.23838,'48AH6P','20201007'],
  [19.704141,-99.186816,'LC94068','20201007'],
  [19.68090,-99.19421,'373EN7','20201008'],
  [19.42388,-98.99969,'44AG1P','20201008'],
  [17.76723,-93.47841,'LB90491','20201009'],
  [19.68605,-99.20242,'86AJ4C','20201009'],
  [19.69414,-99.08080,'916FE9','20201013'],
  [18.94522,-97.41993,'683EH8','20201013'],
  [19.52491,-99.19875,'6801ZP','20201015'],
  [17.99287,-93.21822,'LB94156','20201015'],
  [19.64869,-99.05821,'57AJ5V','20201016'],
  [19.50367,-99.01500,'81AF3J','20201016'],
  [19.63873,-99.15472,'44AB3V','20201016'],
  [20.76631,-100.40119,'13AH4P','20201020'],
  [24.77444,-107.42488,'VHZ046B','20201021'],
  [20.56571,-100.9702,'JW25707','20201022'],
  [18.18777,-96.09480,'66AH7L','20201023'],
  [19.32154,-98.40610,'77AK6L','20201024'],
  [18.790341666666666,-96.56606833333332,'546EH8','20201025'],
  [19.70304,-99.20211,'LB04734','20201027'],
  [19.86818,-99.23477,'02AM9E','20201029'],
  [19.74883,-99.11552,'83AF4N','20201029'],
  [19.61293,-99.32066,'27AK4Z','20201029'],
  [19.7567,-101.308,'69AL5X','20201029'],
  [19.32001,-98.72570,'050DT9','20201029'],
  [19.37469,-99.2174,'KW74803','20201029'],
  [19.48016,-99.03093,'26AJ4S','20201030'],
  [19.88404,-100.92266,'88AK4J','20201030'],
  [18.075585,-92.931829,'84AL8D','20201102'],
  [19.63835,-99.18306,'90AJ9C','20201103'],
  [19.66943,-99.21683,'159XN5','20201103'],
  [20.56572,-100.96941,'10AD7S','20201104'],
  [19.30859,-98.83236,'01AP7L','20201104'],
  [20.58133,-100.99510,'26AA7N','20201105'],
  [20.21796,-99.80079,'16AA8N','20201106'],
  [17.98274,-93.16118,'709YC4','20201107'],
  [19.14844,-98.19320,'674EZ1','20201109'],
  [19.580336666666668,-99.04744666666666,'78AL8D','20201110'],
  [20.17493,-102.19136,'GL0240A','20201110'],
  [20.61725,-103.22731,'08AE6J','20201111'],
  [19.89286,-101.06955,'70AK1A','20201111'],
  [20.39254,-100.01807,'LC14899','20201114'],
  [19.60315,-99.03734,'LD73406','20201118'],
  [32.074429,-113.678224,'905EY7','20201123'],
  [19.65386,-99.06778,'JV18939','20201124'],
  [19.664795,-99.195588,'06AH3R','20201125'],





  [20.28939,-99.95363,'APHU6725407/CMAU0835133','20210105'],
  [20.57256,-100.47023,'71AH2P','20210106'],
  [19.38326,-99.00547,'60AJ2N','20210106'],
  [20.55412,-100.77911,'86AN8J','20210107'],
  [19.71500,-99.19476,'bLB68554','20210108'],
  [19.5937,-98.9313,'60AK8W','20210108'],
  [19.68744,-99.20263,'Eco,GTZ-11,CB','20210111'],
  [20.881349,-101.509158,'TGBU9365392/SEKU4445493','20210111'],
  [19.74715,-99.19659,'LC05711','20210112'],
  [19.29061,-99.54332,'308DN9','20210112'],
  [20.72560,-101.28723,'LF10446','20210112'],
  [19.141288333333332,-98.27848,'51AG9S','20210112'],
  [20.689389,-99.959889,'89AD8V','20210113'],
  [19.70376,-99.08824,'46AE5D','20210113'],
  [19.094503,-104.280895,'OOCU7398957/UETU5201740','20210113'],
  [20.53662,-100.60804,'ELF300','20210113'],
  [19.60650,-99.18970,'LD85064','20210114'],
  [21.07855,-101.72716,'65AF5H','20210115'],
  [19.062502,-98.316355,'82AL8D','20210115'],
  [19.96420,-99.16684,'425DT9','20210116'],
  [19.29232,-99.55663,'GF3016A','20210118'],
  [19.60523,-99.18269,'LB76993','20210118'],
  [20.44462,-103.24599,'96AH5S','20210118'],
  [19.60247,-99.18032,'827DE2','20210118'],
  [20.58104,-100.99473,'80AL5X','20210118'],
  [19.845951,-99.123880,'MSMU7367289/MSMU7367859','20210120'],
  [19.506834,-99.085258,'NCL9345','20210120'],
  [19.83550,-98.70262,'102FE3','20210123'],
  [19.09273,-98.18697,'36AE9S','20210123'],
  [21.07255,-101.69329,'LF18800','20210125'],
  [19.61950,-99.19190,'311FE9','20210125'],
  [19.767356,-98.125551,'LD04798','20210126'],
  [19.62178,-99.00338,'GR21673','20210126'],
  [19.299721666666667,-98.87565833333335,'628EV8','20210126'],
  [20.60345,-100.85964,'S/D','20210128'],
  [18.42792,-97.41441,'70AP1L','20210129'],
  [19.05882,-104.23984,'67AD5T','20210130'],
  [20.133546,-102.071121,'82AL6N','20210130'],
  
  [19.66759,-99.00515,'G99BHA','20210202'],  
  [20.55611,-103.31984,'83AL4G','20210202'],
  [18.88718,-96.96719,'LD82172','20210205'],
  [19.55220,-99.20725,'21AJ3H','20210205'],
  [20.11784,-99.48078,'892EX3','20210206'],
  [20.62198,-99.91331,'69AE1K','20210207'],
  [19.79715,-99.17602,'014EH7','20210208'],
  [20.55657,-100.55971,'622DH8','20210208'],
  [19.73142,-99.09848,'33AJ3J','20210209'],
  [18.900082,-99.223130,'LF25590','20210209'],
  [19.63967,-99.14636,'900EV5','20210209'],
  [20.03326,-99.97870,'NWG9585','20210209'],
  [19.63814,-99.18349,'LB18645','20210211'],
  [20.20011,-103.52508,'87AJ4C','20210212'],
  [20.71686,-100.47727,'835EW3','20210212'],
  [19.38293,-99.06075,'KW19396','20210212'],
  [18.80583,-96.63159,'36AP4X','20210213'],
  [19.638818,-99.136458,'LC80670','20210215'],
  [20.57692,-100.94553,'64AF5H','20210217'],
  [19.604295,-99.205586,'PCIU4615092/PCIU1568103','20210217'],
  [19.90334,-101.13716,'71AD4J','20210217'],
  [19.40488,-99.07086,'58AP7Z','20210219'],
  [19.37531,-99.54935,'CKU7553375/MTSU9618598','20210220'],
  [20.55406,-100.78320,'82AB3Z','20210222'],
  [17.90861,-95.01361,'77AJ1P','20210223'],
  [25.82461,-108.96615,'21AR8Y','20210223'],
  [20.604996,-103.260166,'GCXU2270944','20210223'],
  [19.68141,-99.19866,'009DC3','20210223'],
  [19.47102,-99.20713,'788DC2','20210223'],
  [19.73223,-99.09724,'06AG6K','20210224'],
  [19.507085,-97.500735,'BMOU6559071/FFAU1369415','20210225'],
  [19.529474,-99.176996,'WHLU4306089','20210225'],
  [16.80654,-92.90759,'68AB4Z','20210227'],

  
  [19.390838,-99.084860,'NCX5480','20210301'],       
  [19.22090,-99.12957,'90AD5K','20210302'],
  [17.50142,-99.47057,'90AD5K','20210302'],
  [18.90657,-97.02662,'50AH6P','20210303'],
  [20.720445,-103.324844,'AF0109A','20210310'],
  [19.668445666666667,-99.20007066666668,'09AF4T','20210310'],
  [19.30255,-98.74953,'38AR5C','20210310'],
  [19.67369,-99.18408,'386FE8','20210311'],
  [19.88527,-101.45110,'LB68167','20210312'],
  [21.05302,-101.56225,'368AT6','20210313'],
  [19.59681,-99.17529,'009DC3','20210313'],
  [24.75946,-107.35129,'37TX6K','20210315'],
  [19.75176,-99.14830,'155DT9','20210316'],
  [20.30929,-103.56167,'963EX1','20210317'],
  [19.88965,-99.85354,'725EW3','20210318'],
  [19.67866,-99.19939,'095DH6','20210318'],
  [18.34638,-101.89996,'MSKU2600870/MRKU9459000','20210318'],
  [19.48074,-99.04209,'NDJ4379','20210322'],
  [18.18893,-97.27807,'42AN9W','20210323'],
  [18.35201,-101.89861,'14AF4T','20210324'],
  [19.98505,-99.44123,'778EP9','20210325'],
  [21.64566,-102.27800,'89AN5Z','20210325'],
  [18.833616,-97.108697,'06AL5D','20210325'],
  [19.28463,-98.45537,'792EY5','20210328'],
  [19.66647,-99.18733,'LC08859','20210329'],
  [19.62850,-99.04712,'A925AB','20210329'],
  [19.62850,-99.04712,'LE03032','20210329'],
  [19.64848,-101.27957,'73AH9Z','20210330'],
  [16.76685,-93.29390,'514EV8','20210330'],
  [20.57932,-100.97446,'31AK3X','20210331'],


  [19.56556,-99.20576,'6442ZP','20210401'],               
  [19.481432,-99.031750,'B63APZ','20210402'],
  [20.03879,-103.52752,'346AS5','20210405'],
  [18.87023,-97.38440,'10AJ4J','20210405'],
  [18.01832,-94.38376,'980EW2','20210406'],
  [19.82176,-98.94796,'HKT648B','20210406'],
  [19.93435,-99.38317,'15AK5C','20210410'],
  [18.16831,-96.09471,'73AP1L','20210412'],
  [19.60392,-99.03544,'LF27448','20210413'],
  [19.71517,-99.21029,'LC11809','20210413'],
  [19.06255,-98.09436,'508DG2','20210413'],
  [20.71268,-103.37926,'282FE9','20210413'],
  [16.90495,-99.96218,'65AJ9W','20210414'],
  [25.68482,-100.46416,'36AJ3J','20210414'],
  [18.65219,-99.66473,'RCR660B','20210415'],
  [19.63519,-99.10334,'902ZFP','20210416'],
  [19.442554,-99.159281,'NKU7520','20210416'],
  [18.6567963,-96.6522753,'LF18800','20210417'],
  [19.56678,-99.04493,'LE12169','20210421'],
  [20.57834,-100.96283,'92AN7D','20210421'],
  [20.59165,-101.13668,'LB90453','20210421'],
  [19.83455,-99.27047,'50AJ3H','20210421'],
  [19.27986,-99.59442,'NBL6944','20210423'],
  [19.73729,-98.58116,'112FE3','20210423'],
  [21.11385,-102.00417,'64AF5H','20210423'],
  [24.76007,-107.35227,'06AH4P','20210426'],
  [19.66050,-99.21590,'23AH8C','20210430'],
  
  [19.65916,-99.18919,'416AN5','20210503'],            
  [18.17426,-92.85261,'4697ZP','20210504'],
  [19.513135,-99.087066,'G96AJF','20210504'],
  [18.76894,-96.44753,'17AN9Y','20210505'],
  [19.17604,-98.30923,'48AA3F','20210505'],
  [21.025109,-101.442918,'94AJ2R','20210505'],
  [19.51380,-98.46050,'86AK2G','20210505'],
  [19.57821,-99.20980,'857AN4','20210505'],
  [19.05473,-98.05350,'94AN5Z','20210506'],
  [19.585993,-99.191697,'04AN2W','20210507'],
  [19.3365,-99.5392,'665EV8','20210507'],
  [19.21996,-96.22399,'MRSU3240700','20210508'],
  [19.63551,-99.11140,'27AM3P','20210511'],
  [19.296315,-99.587981,'NFV4041','20210515'],
  [18.07193,-94.29890,'730AS2','20210518'],
  [19.39469,-98.86612,'125AN8','20210519'],
  [19.07290,-98.13816,'022ES5','20210524'],
  [20.558901,-100.546276,'650EV8','20210525'],
  [19.07728,-98.15093,'FFAU2217045/TCLU5843259','20210526'],
  [19.67279,-99.22394,'49AR4C','20210526'],
  [21.48342,-104.82724,'726ER5','20210527'],
  [21.90617,-100.79889,'63AJ9M','20210529'],
  [19.06142,-98.08790,'218ES5','20210531'],
  [18.72730,-96.38921,'13AJ7R','20210531'],

  [18.88042,-96.92357,'33AJ3J','20210601'],               
  [18.93357,-99.19834,'311FE9','20210603'],
  [18.84959,-97.53693,'136ER4','20210603'],
  [19.63853,-99.16716,'LC57416','20210604'],
  [18.78976,-96.56294,'65AN5D','20210606'],
  [20.76652,-103.63177,'91AN7D','20210606'],
  [22.04408,-100.85747,'16AN8U','20210607'],
  [19.64887,-99.05829,'69AK1A','20210608'],
  [19.43523,-98.99853,'LD14030','20210608'],
  [22.34100,-100.79782,'596EV8','20210608'],
  [19.60505,-99.18529,'6346ZP','20210611'],
  [19.24266,-98.85932,'LB90453','20210611'],
  [19.96180,-99.42242,'52AM5A','20210613'],
  [19.57441,-99.04268,'502AR6','20210615'],
  [20.447958,-103.186655,'SUDU8224496/MNBU3456180','20210616'],
  [20.17358,-101.15699,'15AS3C','20210616'],
  [19.612361,-99.039336,'16AF1F','20210616'],
  [20.57714,-100.94920,'81AL6N','20210617'],
  [19.28519,-98.43831,'269EZ1','20210618'],
  [19.63758,-99.18459,'NDX9412','20210618'],
  [21.20102,-101.90152,'15AN4D','20210619'],
  [20.765173,-100.446395,'55-AD-8G','20210619'],
  [19.83946,-98.96781,'38AF2l','20210621'],
  [16.75284,-93.19049,'25AH4W','20210622'],
  [19.354461,-99.081159,'16AF1F','20210624'],
  [19.87473,-101.37287,'74AJ7C','20210624'],
  [19.64047,-99.15285,'79AF8V','20210626'],
  [19.63752,-99.13692,'36AT6A','20210626'],
  [19.46236,-99.21572,'HL5986E','20210628'],
  [21.20973,-103.17951,'58AJ4B','20210629'],
  
  [20.60025,-103.32622,'85AF4Y','20210701'],                  
  [20.58986,-101.12347,'09AF4R','20210701'],
  [20.58986,-101.12347,'07AF4R','20210701'],
  [20.70119,-101.26156,'87AF3J//FBIU0109290','20210701'],
  [20.31473,-99.93288,'17AM8U','20210702'],
  [19.62570,-99.02582,'17AJ3X','20210706'],
  [19.64777,-99.04169,'42AT3C','20210707'],
  [19.66401,-99.13767,'LF32704','20210707'],
  [19.74344,-99.19139,'LC15881','20210707'],
  [20.119383,-99.720996,'75AL2N','20210708'],
  [19.63537,-99.05169,'300FE9','20210709'],
  [19.09143,-98.18397,'LF39223','20210709'],
  [19.12410,-98.07437,'GP68617','20210710'],
  [20.360420,-99.961549,'861EP9','20210713'],
  [19.54662,-101.35403,'NCW2837','20210714'],
  [19.29678,-98.88111,'909FE9','20210716'],
  [19.25052,-98.42777,'18AH1W','20210719'],
  [19.62394,-99.18568,'LC89652','20210720'],
  [18.45793,-96.37241,'35AH1W','20210720'],
  [19.73956,-99.21185,'83AB3S','20210720'],
  [20.496058,-100.131951,'55AJ9M','20210723'],
  [18.65040,-96.28040,'01AA8N','20210724'],
  [19.53240,-99.15388,'LD34790','20210726'],
  [20.52565,-103.00176,'JW25708','20210726'],
  [19.63879,-99.13625,'06AT9E','20210727'],
  [21.92714,-100.80271,'111FE3','20210727'],
  [19.71426,-99.19670,'LD85852','20210730'],



  [20.44729,-100.07859,'893EX3','20210802'],   
  [19.61372,-99.04089,'51AS5U','20210803'],
  [19.76901855747693,-99.21661946696275,'TEMU7382256/TGBU4150241','20210803'],
  [19.27769,-98.42627,'36AM9E','20210805'],
  [19.373069,-99.157617,'F71ACX','20210807'],
  [19.54504,-101.53736,'76AB3S','20210809'],
  [19.32283,-97.48514,'15AH5L','20210810'],
  [19.32858,-98.52706,'321ET1','20210812'],
  [19.66540,-99.06631,'LD78270','20210812'],
  [18.59461,-96.19588,'822DG5','20210812'],
  [21.07414,-100.60949,'64AP6H','20210812'],
  [19.351898,-99.038755,'VZX611A','20210814'],
  [19.306520,-98.456889,'870EN3','20210816'],
  [18.17148,-98.08326,'335AL1','20210817'],
  [19.75974,-99.21588,'59AK1S','20210817'],
  [20.45957,-100.0939533,'691AR6','20210818'],
  [19.50703,-101.66732,'109FE3','20210818'],
  [19.76166,-99.21564,'GCXU5256894','20210819'],
  [19.65980,-99.14161,'68AP1L','20210820'],
  [19.60013,-99.18663,'LF12452','20210824'],
  [20.945751,-89.610364,'YYS576E','20210824'],
  [19.64471,-99.19660,'LB62035','20210825'],
  [19.39856,-99.04213,'M34BHM','20210825'],
  [19.60375,-99.15548,'LC49451','20210828'],
  [26.58906,-109.16844,'TR358','20210830'],
  [25.425777,-100.997634,'L48AHH','20210830'],



  [20.666921,-101.335128,'MSDU9802563','20210901'],         
  [22.022221,-100.845013,'94AM2R','20210902'],
  [19.31653,-97.93504,'LC97803','20210904'],
  [20.53628,-100.72198,'99AR5V','20210905'],
  [19.89174,-101.04005,'LB99858','20210907'],
  [19.35358,-98.96330,'LF21357','20210908'],
  [19.875672,-98.938901,'84AJ9M','20210910'],
  [19.69408,-99.20334,'579ED1','20210911'],
  [20.26812,-99.86821,'902AT7','20210911'],
  [19.45996,-99.22713,'7000ZP','20210914'],
  [20.599408333333333,-101.14078333333333,'650EV8','20210917'],
  [19.54927,-99.15201,'LF28961','20210917'],
  [22.95976,-100.46698,'152AP1','20210917'],
  [19.83061,-99.90588,'98AB3S//TCKU6867221','20210917'],
  [19.07860,-98.16052,'63AS2B','20210918'],
  [17.909988,-94.935650,'58AE2P','20210921'],
  [19.72099,-99.21067,'LF01031','20210921'],
  [19.714469,-99.207519,'848EJ6','20210921'],
  [19.50091,-99.08641,'NZN6303','20210921'],
  [20.855398,-100.42976,'TEMU0076568/MRKU8215164','20210922'],
  [19.26971,-99.66000,'20AG3N','20210922'],
  [19.85452,-101.14513,'12AN4D','20210923'],
  [20.50938,-100.57443,'LD04777','20210923'],
  [19.68522,-99.20571,'LF12452','20210923'],
  [19.87396,-101.38008,'02AN8D','20210924'],
  [19.75648,-102.47891,'67AK3K','20210925'],
  [19.65328,-99.18212,'LC33274','20210927'],
  [19.63621,-99.19702,'CSNU6257660','20210927'],
  [19.61449,-99.05485,'837AP3','20210927'],
  [20.58412,-101.01500,'56AH6C','20210927'],
  [19.52801,-99.17713,'08AF9V','20210928'],
  [19.50386,-99.01496,'855ES1','20210930'],


  [19.86302,-102.41966333333333,'20AN8D','20211002'],      
  [19.44428,-99.18777,'LF25027','20211003'],
  [19.34568,-99.307,'HS1216E','20211004'],
  [19.60287,-98.94690,'35AL4M','20211006'],
  [20.51352,-100.91961,'LD79111','20211008'],
  [19.12415,-98.26110,'21AR8Y','20211011'],
  [20.47642,-100.6440,'040DC2','20211012'],
  [19.66351,-99.13828,'42AT3C','20211014'],
  [20.57290,-100.32504,'92AS4X','20211014'],
  [18.99165,-102.10340,'23AH9Z','20211015'],
  [21.477996,-101.192430,'83AM2M','20211015'],
  [20.58795,-101.03462,'594EV8','20211015'],
  [19.62361,-99.19238,'323ET1','20211016'],
  [19.63723,-99.15744,'56AH6C','20211020'],
  [19.15935,-98.18581,'930FE9','20211020'],
  [19.82493,-98.90971,'84AE3Y','20211020'],
  [19.55631,-99.1874,'LE06796','20211021'],
  [19.64243,-99.10619,'LF15369','20211021'],
  [19.68384,-99.18556,'13AF4H','20211022'],
  [20.52100,-100.54535,'55-AT-5C','20211022'],
  [19.52768,-99.16100,'66AE6X','20211026'],
  [19.48362,-99.15654,'616EV8','20211028'],
  [25.612291,-100.839949,'77AC5R','20211030'],

  [22.81632,-100.48710,'023AS5','20211101'],                
  [19.49504,-99.27500,'LB31052','20211101'],
  [17.62107,-93.77957,'D57AUX','20211102'],
  [20.54211,-100.73257,'LC14899','20211104'],
  [19.67152,-98.95539,'81AE6H','20211105'],
  [19.59857,-99.29242,'ULN922C','20211105'],
  [19.260108,-99.624290,'11AA9V','20211108'],
  [19.64832,-99.14109,'GU5070A','20211109'],
  [19.67663,-101.36140,'633EH7','20211110'],
  [19.32330,-98.49936,'73AP1L','20211111'],
  [18.82752,-97.11125,'34AE7C','20211111'],
  [21.02545,-101.53693,'39AB5Z','20211112'],
  [19.40821,-98.93630,'LF25025','20211112'],
  [22.197289,-100.920137,'UZU050A','20211113'],
  [25.51010,-100.86858,'LB95552','20211115'],
  [19.50798,-99.17546,'16AC2P','20211116'],
  [18.01514,-94.39201,'155DT9','20211116'],
  [18.8872000,-96.9671000,'97AT1K','20211116'],
  [20.619529,-103.208066,'76AM5A','20211116'],
  [19.63527,-99.19943,'15AF4H','20211119'],
  [19.566282,-99.206200,'LD76114','20211120'],
  [19.309092,-99.047243,'NZK7029','20211122'],
  [19.04406,-104.23510,'TRLU1724836/CGMU5517977','20211123'],
  [19.96609,-99.16838,'96AE7C','20211123'],
  [19.301502,-98.409488,'262ER9','20211124'],
  [19.634357,-99.115122,'04AU6A','20211126'],
  [20.95456,-101.51224,'85AE8J','20211127'],
  [19.42295,-98.98217,'E08BHN','20211129'],
  [20.58120,-103.10133,'LB68167','20211129'],
  [19.63316,-99.05068,'LB31589','20211130'],



  [19.65709,-98.87012,'91AB8T','20211201'],
  [21.09763,-101.62584,'04AB7Y','20211201'],
  [19.63509,-99.05159,'79AS2Y','20211202'],
  [19.98564,-99.50179,'76AB2S','20211202'],
  [18.93466,-97.69060,'85AK8V','20211204'],
  [19.910015,-98.337949,'SL96695','20211206'],
  [19.48377,-99.15712,'26AJ4S','20211207'],
  [19.58170,-98.98261,'LF53340','20211207'],
  [19.635324,-99.19919,'FCIU9888910/FFAU1877091','20211207'],
  [29.01587,-110.96214,'35AU7J','20211207'],
  [19.75216,-99.22948,'951DE8','20211208'],
  [19.66364,-99.13816,'70AP1L','20211208'],
  [19.63496,-99.11194,'44AG1P','202112014'],
  [20.1824,-100,1369,'PONU1883477','202112014'],
  [19.61310,-99.19788,'LB84711','202112015'],
  [20.52094,-100.75118,'63AN4B','202112015'],
  [19.54656,-99.18281,'LD43016','202112015'],
  [20.57072,-100.89528,'26AU8W','202112016'],
  [19.992362,-99.47008,'02AT4H','202112016'],
  [19.95107,-99.40312,'75AN3X','202112017'],
  [19.42050,-99.09772,'252AH8','202112017'],
  [19.48377,-99.15712,'57AJ9M','202112021'],
  [19.61273,-99.15422,'90AM7D','202112021'],
  [19.29389,-99.55022,'07AH4P','202112022'],
  [19.466407,-101.753098,'MSCU5162232/FANU1497860','202112022'],
  [18.867072,-96.909693,'09AM7A','202112022'],
  [19.29785,-98.88045,'934FE9','202112022'],
  [19.68906,-99.20287,'LB94359','202112023'],
  [19.277180,-97.524060,'431ES4','202112024'],
  [19.635278,-99.306154,'042DT9','202112026'],
  [19.65577,-99.06170,'LD77063','202112027'],
  [19.880497,-101.203113,'TGHU6368810','202112027'],
  [19.54277,-99.01523,'NWN5997','202112029'],
  [19.63727,-99.04879,'92AG9N','202112030'],
  [19.61565,-99.77590,'32AJ9K','202112031'],

  [19.72800,-99.14830,'NPZ6190','20220103'],
  [20.59003,-101.13423,'48AN4V','20220108'],
  [26.04342,-98.29468,'LF54017','20220109'],
  [19.64860,-101.24972,'JV27422','20220110'],
  [19.536388,-99.148211,'NJD2077','20220111'],
  [19.70177,-99.08477,'72AS7T','20220113'],
  [19.62324,-99.02700,'52AS5U','20220113'],
  [19.28043,-99.55622,'456AS8','20220113'],
  [19.64621,-99.17957,'LC81280','20220114'],
  [19.284732,-99.126640,'H82AZF','20220115'],
  [19.08325,-98.16020,'97AS6A','20220117'],
  [20.584689,-103.284197,'JU91570','20220117'],
  [20.56777,-103.30534,'10AP8T','20220117'],
  [19.842195,-101.020376,'662EW3','20220118'],
  [19.64586,-99.14007,'LB31052','20220119'],
  [19.64863,-99.14989,'LF15369','20220119'],
  [21.28962,-102.23637,'29AB4A','20220119'],
  [32.53427,-115.42877,'19AU6K','20220120'],
  [20.53802,-100.60493,'590EY8','20220120'],
  [20.54864,-100.58283,'82AF4R','20220121'],
  [20.60172,-100.95389,'878FF3','20220125'],
  [19.91437,-99.25331,'22AT9G','20220125'],
  [19.66664,-99.16943,'252AH8','20220126'],
  [28.67939,-106.08881,'19AN9H','20220127'],  
  [20.378591,-101.842572,'87AJ8D','20220128'],
  [19.62923,-99.13474,'LB33781','20220131'],

  [22.165851,-100.987114,'VEB296A','20220203'],
  [20.50104,-100.21442,'54AC7B','20220206'],
  [20.50104,-100.21442,'51AH3M','20220206'],
  [19.709961,-99.206315,'65AP5Z','20220207'],
  [20.009371,-99.508295,'96AN2J','20220207'],
  [19.305843,-98.890445,'FPM5911','20220208'],
  [19.67761,-99.22476,'42AT3C','20220209'],
  [19.53677,-99.05495,'155DT9','20220209'],
  [20.58279,-101.00788,'25AF4K','20220210'],
  [22.255200,-100.823720,'43AM2B','20220210'],
  [19.985626112041334,-99.50199978143915,'91AJ3H','20220211'],
  [24.75860,-107.35873,'322DE5','20220214'],
  [20.279729,-99.899891,'42AR7B','20220215'],
  [20.43715,-103.54063,'05AK7A','20220215'],
  [19.62409,-99.19053,'34AL4T','20220217'],
  [19.52845,-99.07974,'90AK6L','20220217'],
  [19.638518,-99.154931,'20AL1S','20220218'],
  [19.859764,-100.093889,'31AS9J','20220218'],
  [19.63548,-99.11093,'LD85852','20220221'],
  [19.62520,-99.07787,'7000ZP','20220222'],
  [19.833976,-99.268717,'27AR6B','20220222'],
  [19.493916,-99.092850,'K41ANB','20220222'],
  [18.76893833333333,-96.44586,'35AR7B','20220222'],
  [18.59497,-96.19688,'48AT3B','20220223'],
  [19.63229,-99.04864,'06AH6W','20220228'],

  [20.67195,-100.04507,'970AL5','20220301'],
  [22.213434,-100.988665,'49AM5A','20220301'],
  [20.56823,-100.88134,'038DT9','20220301'],
  [19.146471,-98.223026,'379EW3','20220301'],
  [19.67467,-99.80523,'CSNU7637808','20220302'],
  [20.57808,-100.96086,'984EW2','20220303'],
  [19.500684,-99.037975,'PCD2617','20220303'],
  [20.57650,-103.35238,'937EX3','20220304'],
  [19.50365,-99.09011,'CMAU5193524/CMAU4925960','20220307'],
  [19.33288,-98.96898,'12AS3C','20220308'],
  [20.40053,-100.02650,'20AV7E','20220308'],
  [19.482991,-99.198582,'NZE1071','20220308'],
  [18.86473,-97.37234,'55AS5C','20220309'],
  [20.57200,-100.26240,'542EH8','20220309'],
  [19.63522,-99.20624,'70AS2J','20220309'],
  [19.30541,-98.74564,'803EE4','20220309'],
  [19.62108,-99.02821,'LF46462','20220309'],
  [19.35217,-98.64043,'475DC4','20220309'],
  [19.53836,-99.18187,'LF55850','20220310'],
  [20.58245,-101.00271,'665EV8','20220315'],
  [19.635172,-99.112255,'LF53491','20220315'],
  [19.38306,-98.47531,'LC33274','20220316'],
  [20.57182,-100.41484,'LF54601','20220316'],
  [19.62796,-99.09264,'5317ZP','20220317'],
  [19.30521,-98.49027,'75AP1L','20220317'],
  [19.62151,-99.00254,'064EX4','20220317'],
  [19.480654,-99.067009,'561YWX','20220318'],
  [19.596133,-99.183376,'80AP6V','20220318'],
  [25.81513,-100.37748,'652EV8','20220318'],
  [20.333899,-99.938873,'98AK7L','20220318'],
  [20.58199,-101.00086,'02AG1E','20220318'],
  [19.24270,-98.85987,'89AK6P','20220320'],
  [18.82727,-97.32023,'PAA6526','20220321'],
  [19.68252,-99.20382,'302FE9','20220322'],
  [20.03087,-98.74010,'57AA3K','20220322'],
  [19.26765,-98.91821,'72AX7B','20220323'],
  [19.64507,-99.19631,'LB68554','20220324'],
  [19.298344,-98.771883,'93AD7H','20220329'],
  [20.72297,-100.47130,'67AP6V','20220330'],
  [20.85979,-100.42755,'24AU4W','20220330'],
  [19.646200,-99.262330,'56AT9L','20220330'],
  [18.93253,-97.68541,'651AR4','20220331'],

  [19.62805,-99.18433,'22AJ9Z','20220401'],
  [22.11143,-100.91003,'043YD1','20220402'],
  [19.51956,-98.89496,'18AR9L','20220405'],
  [19.66767,-99.00412,'LE05683','20220405'],
  [20.42936,-103.55390,'81AS2S','20220405'],
  [19.49348,-99.15943,'23AJ4S','20220405'],
  [20.96525,-101.52043,'96AC3X','20220406'],
  [18.84032,-97.39183,'02AH4P','20220407'],
  [20.89874,-101.01357,'06AU3K','20220408'],
  [16.80364,-99.83564,'05AX3R','20220408'],
  [17.99841,-93.39662,'38AA3F','20220409'],
  [22.52119,-100.66497,'995UM3','20220412'],
  [19.64048,-99.13565,'LC87929','20220412'],
  [20.36585,-99.97851,'82AL6N','20220414'],
  [18.01742,-98.1330,'88AU2B','20220418'],
  [20.62210,-103.27677,'6848ZP','20220419'],
  [19.60922,-99.18668,'7291ZP','20220419'],
  [20.52887,-100.73763,'93AN7Z','20220419'],
  [19.63595,-99.19802,'TCKU6938665/MSKU1644560','20220420'],
  [19.638033,-99.003293,'34AF5B','20220421'],
  [20.50306,-100.58396,'23AV7E','20220422'],
  [19.70656,-99.18821,'45AR5B','20220425'],
  [19.63493,-99.05156,'72AS7T','20220427'],
  [19.61602,-99.20284,'965AL4','20220427'],
  [19.64903,-99.08743,'LE06984','20220427'],
  [21.16947,-100.56956,'42AT3C','20220429'],
  [19.56900,-99.20801,'48AK4P','20220429'],
  [19.308460,-98.886491,'069EV4','20220429'],
  [19.36683,-99.53964,'89AJ3S','20220429'],

  [18.96310,-98.75398,'LC51612','20220501'],
  [20.43817,-103.21729,'APZU3689700//TCLU7351788','20220504'],
  [19.72313,-99.18662,'LF62807','20220504'],
  [20.96537,-101.52221,'69AE1K','20220504'],
  [19.517563,-99.133989,'DH5946','20220506'],
  [19.24741,-98.86998,'61AT3B','20220506'],
  [18.28059,-101.90472,'SEGU4731050','20220509'],
  [19.4243,-101.605,'098-FE-3','20220509'],
  [20.40102,-100.61845,'24AV7E','202205010'],
  [19.33518,-98.71318,'756DE3','20220510'],
  [25.402005,-100.977838,'FFY130A','20220510'],
  [19.44855,-99.12590,'20AR3M','20220512'],
  [19.34427,-97.75403,'86UL7C/S81UL7C','20220512'],
  [25.66404,-100.32469,'50AS3M','20220512'],
  [19.59988,-99.20099,'LB95539','20220513'],
  [20.522083,-100.170666,'44AC2U','20220513'],
  [20.436402,-103.542048,'TTNU5184228','20220513'],
  [19.69186,-99.20385,'CSLU1869460','20220519'],
  [19.17432,-98.30675,'65AJ9W','20220519'],
  [20.571102,-100.314924,'740DD7','20220519'],
  [19.27609,-98.43121,'216AR6','20220523'],
  [20.59528,-103.32235,'32AF5J','20220525'],
  [18.97772,-97.81312,'59AM4D','20220525'],
  [18.93079,-97.68123,'MRSU5841681//TCKU7452106','20220527'],
  [19.96653,-99.89623,'47AA3F','20220527'],
  [18.92902,-97.77040,'36AE7C','20220527'],
  [20.463051,-102.903601,'MSKU1843345/MSKU7484411','20220527'],
  [19.67781,-100.53012,'516AR6','20220527'],
  [18.97571,-97.80797,'34AE7C','20220527'],
  [19.89332,-99.34624,'472XN6','20220530'],
  [19.51005,-99.21345,'LD79614','20220530'],
  [18.372745,-101.892645,'SUDU8640256/MRKU5635190','20220530'],
  [20.39974,-100.02549,'25AB8S','20220531'],
  [19.19453,-98.33170,'973FD6','20220531'],
  [21.03012,-101.54564,'21AX9V','20220531'],

  [18.82654,-96.71578,'82AL6N','20220601'],
  [19.30229,-98.78718,'05AK2G','20220601'],
  [17.91431,-97.36235,'LE44863','20220602'],
  [20.15133,-99.73998,'33AN1F','20220602'],
  [19.82260,-101.01150,'LF61823','20220602'],
  [19.84054,-99.27782,'01AM6B','20220602'],
  [19.0990680,-97.6700000,'897AL2','20220603'],
  [17.431776,-91.642961,'WLK968A','20220606'],
  [20.57947,-103.10021,'TRHU6100109/TCLU5938502','20220609'],
  [19.63150,-99.04982,'718AE3','20220610'],
  [19.65991,-99.06356,'66AP4Y','20220614'],
  [18.70004,-99.24947,'04AK5D','20220615'],
  [19.76861,-98.94512,'69AT4W','20220616'],
  [23.07677,-100.49998,'LF56915','20220616'],
  [19.523215,-99.142581,'PDH6157','20220617'],
  [19.83254,-99.27753,'09TX1V','20220620'],
  [19.635783,-97.123029,'MRSU4941496','20220621'],
  [19.166830,-96.265621,'819EX5','20220621'],
  [23.11375,-100.50990,'20AK6L','20220624'],
  [20.59503,-100.99188,'86AN8J','20220624'],
  [20.080921,-98.739073,'NCC8077','20220624'],
  [19.29904,-98.4099,'911AU2','20220624'],
  [19.54952,-101.52535,'738EH7','20220627'],
  [19.35022,-98.62273,'68AB7R','20220628'],
  [21.13336,-100.54047,'77AP1L','20220629'],
  [19.27233,-98.45947,'317ET1','20220629'],
  [18.80810,-96.64067,'92AJ3X','20220630'],
  [19.26450,-98.43198,'069DJ1','20220630'],

  [20.09308,-101.10523,'50AM3A','20220702'],
  [29.93769,-106.94800,'03AC8C','20220704'],
  [18.84016,-97.34504,'4696ZP','20220705'],
  [19.90184,-100.78924,'12AN4D','20220706'],
  [20.048782,-99.421078,'664EW3','20220706'],
  [19.96774,-99.53902,'88AF2K','20220707'],
  [19.64189,-99.05492,'621ER6','20220708'],
  [19.60440,-99.20633,'572AU4','20220711'],
  [18.765559,-96.455401,'13AM9A','20220712'],
  [19.30095,-98.4522,'429AL8','20220712'],
  [19.72719,-99.09473,'72AB9Y','20220713'],
  [19.62362,-99.14047,'LE57653','20220713'],
  [19.64370,-99.05322,'LE16380','20220713'],
  [19.27432,-98.43077,'20AJ2L','20220714'],
  [20.48198,-100.80856,'516EP9','20220715'],
  [25.303297,-101.085909,'93AN8S','20220717'],
  [21.21431,-101.77372,'21AS3C','20220718'],
  [19.72314,-99.18653,'47AL7M','20220718'],
  [19.95398,-99.40897,'CAAU655853-4-GYCU576539-8','20220719'],
  [19.95062,-99.61838,'43AF4T','20220719'],
  [20.54682,-101.22012,'57AT5C','20220719'],
  [20.60992,-101.21814,'LB90453','20220719'],
  [20.58053,-100.98932,'16AL5N','20220720'],
  [19.47791,-99.20408,'A207AC','20220720'],
  [17.46450,-93.63600,'16AS3C','20220720'],
  [20.543088333333333,-101.19009,'10AX9D','20220721'],
  [20.410385,-103.215073,'58AL3W','20220726'],
  [19.66425,-99.19873,'277FE9','20220727'],
  [20.131635,-99.728090,'333DS1','20220727'],
  [19.69601668805127,-99.08019780960873,'95AM8A','20220727'],
  [20.39019,-99.07872,'E99ARF','20220727'],
  [20.61333,-103.32429,'10AJ7Z','20220727'],
  [19.850697,-99.285019,'72AB6A','20220728'],
  [19.43708,-99.20035,'31AU1P','20220728'],
  [19.77597,-99.21792,'25AA2V','20220729'],
  [20.397765,-103.554463,'TCLU8574106,//,TEMU6196811-PONU8039262,//SUDU6791269','20220730'],

  [19.62777,-99.14030,'LE72628','20220801'],
  [19.29867,-99.06206,'82AL7T','20220801'],
  [19.73979,-99.21136,'03AR9U','20220802'],
  [19.209756,-98.350461,'64AS2K','20220802'],
  [19.63628,-99.10851,'A643AG','20220803'],
  [19.54578,-99.23636,'07AF4R','20220803'],
  [20.09308,-101.10523,'50AM3A','20220804'],
  [19.85134,-99.28491,'65FA5A','20220804'],
  [19.65577,-99.19484,'LF13768','20220805'],
  [19.863448,-90.486760,'DKH3239','20220805'],
  [20.53772,-100.69315,'67AC8B','20220805'],
  [19.88152,-101.20057,'44AS2K','20220808'],
  [19.30653,-98.74560,'81AR5B','20220808'],
  [19.07800,-98.28983,'83AG6D','20220809'],
  [19.597627,-99.199119,'68AN1J','20220809'],
  [19.27971,-98.93257,'LC11481','20220809'],
  [19.25151,-98.39602,'28AX3X','20220809'],
  [19.68373,-99.07394,'75AL4T','20220810'],
  [19.31019,-98.49768,'44AN5Z','20220811'],
  [19.99529,-99.48099,'OOLU4338122/OOLU0368704','20220811'],
  [20.46884,-100.63762,'544DH2','20220811'],
  [21.610122,-100.758704,'62AU5H','20220812'],
  [18.82921,-97.12267,'27AN8D','20220812'],
  [20.82528,-100.44568,'06AB8Z','20220812'],
  [23.65658,-103.54131,'84AJ9M','20220815'],
  [19.251867,-98.444108,'72AS6A','20220816'],
  [20.41776,-103.556227,'MRKU5231963/MSKU9281727','20220816'],
  [19.07649,-98.15220,'LB95544','20220817'],
  [19.85845,-99.87878,'43AA8N','20220817'],
  [19.97512,-99.44532,'824ER7','20220817'],
  [18.93602,-97.69381,'875AK3','20220818'],
  [19.98685,-99.44818,'PCN9584','20220823'],
  [20.58725,-100.34616,'TLLU5954380/SEGU4463825','20220823'],
  [18.85208,-96.36317,'589EF9','20220823'],
  [24.51213,-100.28627,'766EP7','20220824'],
  [24.65035,-100.29131,'790AH5','20220824'],
  [18.01464,-101.95826,'59AF9S','20220826'],
  [24.677050,-100.299280,'74TZ2T','20220827'],
  [19.00921,-97.47554,'82UD2T','20220827'],
  [19.17033,-98.30233,'36AP4X','20220828'],
  [23.32018,-102.95875,'77AN6Z','20220828'],
  [20.423757,-103.480724,'417EY8','20220829'],
  [19.64304,-99.13616,'36AT7K','20220830'],
  [19.30529,-98.84036,'53AJ8Z','20220830'],
  [19.268103,-98.430425,'187DD8','20220831'],
  [19.46159,-98.45159,'25UL9G','20220831'],

  [19.29697,-98.86747,'11AL5N','20220901'],
  [19.302171,-98.425757,'713DC4','20220902'],
  [19.83466,-101.28434,'59AJ3H','20220904'],
  [25.59238,-100.87897,'34UG8T/26UE2P','20220905'],
  [25.809962,-100.272243,'10AC2E','20220906'],
  [19.667169,-99.199528,'81AT6Y','20220906'],
  [20.58231,-103.31562,'47AA3F','20220906'],
  [18.76841,-96.44523,'38AY1P','20220907'],
  [19.30824,-99.55748,'LB84711','20220907'],
  [25.61643,-100.93677,'91AM4E','20220907'],
  [21.083033,-102.287490,'91UE7C','20220908'],
  [20.24873,-99.83865,'475EY4','20220910'],
  [20.08059,-99.64619,'614DD5','20220910'],
  [19.75934,-99.21378,'59AH3M','20220910'],
  [22.16956,-100.96142,'12AA8N','20220913'],
  [19.558899,-99.760166,'25AE5Y','20220913'],
  [20.58223,-100.99218,'SU-330','20220914'],
  [19.70532,-98.7496,'065EX4','20220920'],
  [19.58927,-98.55377,'28AJ1D','20220921'],
  [19.56160,-99.27505,'97AF3J','20220921'],
  [19.593654,-101.296390,'09AT7W','20220921'],
  [20.48401,-102.93955,'179EY1','20220922'],
  [19.63583,-99.19952,'633EH7','20220923'],
  [20.59931,-101.18396,'02AU8K','20220923'],
  [24.71548,-100.30583,'12AS3J','20220924'],
  [19.48337,-98.45896,'17AU1K','20220926'],
  [19.75254,-99.12971,'LF22400','20220929'],
  [19.830285,-99.903883,'18AL7X','20220930'],



  [25.88553,-100.21955,'77AP7R','20221001'],
  [15.72778,-93.26706,'40AX9D','20221003'],
  [20.41635,-103.55691,'MSKU9424406,/,MSKU9625925//MRKU7711350/TCKU7507580','20221003'],
  [19.830729,-99.905778,'GLDU9613241/CMAU8695104','20221004'],
  [24.98134,-100.49275,'13UE3P/88UB3B','20221005'],
  [23.55718,-100.62700,'AX9155','20221005'],
  [19.750252,-99.132287,'CAIU9594570/MEDU7138139','20221007'],
  [20.543281,-101.137557,'49AT9V','20221007'],
  [20.54669,-103.30163,'935YD7','20221009'],
  [19.03674,-98.02031,'932FE9','20221010'],
  [19.26952,-98.42712,'916FE9','20221011'],
  [20.73782,-102.91112,'17AR1B','20221011'],
  [20.61778,-103.09816,'029ES5','20221011'],
  [18.97992,-98.23367,'05AS3J','20221012'],
  [19.07828,-98.20152,'24AU7W','20221017'],
  [19.84797,-98.94993,'LF27676','20221018'],
  [19.5851334,-98.5417826,'45AM2L','20221019'],
  [19.78144,-98.96832,'SS76500','20221019'],
  [18.368458,-101.89488,'APHU7165360-APHU7322670//CMAU9314820','20221020'],
  [19.342697,-98.593245,'26AU4P','20221020'],
  [27.31982,-109.71904,'LF53491','20221020'],
  [21.316335,-102.045534,'SEGU4444234','20221021'],
  [18.81764,-97.41192,'07AU3Z','20221022'],
  [19.75001,-99.21320,'68AP1L','20221023'],
  [19.24896,-98.87337,'89AD7L','20221025'],
  [19.043609,-98.013736,'858YG3/771YF9','20221025'],
  [19.769181,-99.208252,'WHSU6532028','20221025'],
  [25.18314,-100.72965,'57964/57185','20221026'],
  [19.984352,-99.506722,'TLLU5760980/MSKU0473539','20221030'],
  [19.09529,-98.40678,'85AU2B-20AV7E-91AU2B','20221031'],

  
  [20.52269,-100.17153,'47AC9B','20221101'],
  [20.413009,-103.557691,'TRHU3796518/CAXU9374540','20221106'],
  [18.67018,-99.29486,'LE71103','20221108'],
  [17.55211,-98.57577,'146-DC-9','20221109'],
  [21.29642,-102.13881,'502EV8','20221109'],
  [19.24887,-98.87210,'16AL5N','20221110'],
  [19.76281,-99.21571,'61AP6V','20221111'],
  [20.60203,-100.49131,'880EH7','20221110'],
  [20.53235,-100.64607,'LF53491','20221114'],
  [20.53261,-100.64715,'LJJ650A','20221114'],
  [19.69084,-99.18749,'85AN9W','20221116'],
  [20.57094,-103.08347,'MRKU0957104-MSKU1984575','20221117'],
  [19.56586,-98.66296,'LF28584','20221118'],
  [22.568925,-100.634969,'BMOU9612673','20221119'],
  [18.72912,-98.76283,'LF53097','20221122'],
  [19.8376635,-99.9452766,'MEDU7342543','20221123'],
  [21.13366,-86.86564,'LF48412','20221125'],
  [19.347632,-99.306983,'63AN7J','20221125'],
  [18.81884,-97.41117,'75AR5B','20221126'],
  [20.12916,-99.72788,'90AT4B','20221126'],
  [20.09411,-99.30053,'956EH6','20221126'],
  [19.082832,-98.16502,'68AK1A','20221126'],
  [20.6162,-103.2143,'MRKU0957104/MSKU1984575','20221127'],
  [19.55319,-99.06208,'LE40090','20221129'],
  [19.83064,-99.90594,'880EH7','20221129'],
  [20.55669,-100.80884,'06AA6C','20221129'],
  [24.71551,-100.30440,'57904','20221129'],
  [20.58270,-100.99212,'763AR6','20221130'],
  [19.29112,-98.41106,'81AR5B','20221130'],

  
  [19.62398,-99.21259,'601DJ3','20221201'],
  [19.62806,-99.13502,'46AX3X','20221202'],
  [22.549535,-100.651070,'94UH1M','20221202'],
  [22.13327,-100.82768,'83AN8V','20221203'],
  [19.41636,-102.02543,'67AY8P','20221205'],
  [19.74888,-99.11723,'63UA4Z','20221205'],
  [19.65748,-99.19890,'LF22692','20221206'],
  [25.80806,-100.40310,'10AR7B','20221206'],
  [19.30602,-98.42041,'LC49451','20221206'],
  [21.00706,-104.14577,'29AJ2S','20221207'],
  [19.86916,-99.33095,'7885ZP','20221208'],
  [19.064605187683732,-98.10157456610645,'986EU1','20221208'],
  [19.310069,-98.829800,'MSKU5835040/TEMU1419661','20221208'],
  [22.01913,-100.38211,'43AM2B','20221209'],
  [19.30423,-98.74500,'869DG6','20221209'],
  [19.83432,-99.26927,'77AE8L','20221209'],
  [19.74045,-99.21241,'89AS2K','20221210'],
  [19.83184,-99.91115,'LC94068','20221211'],
  [19.09586,-98.18863,'LF60582','20221213'],
  [19.82623,-99.2510,'17AG1P','20221214'],
  [19.37831,-102.04776,'89AN8H','20221215'],
  [19.71943,-99.17928,'22AJ9Z','20221215'],
  [20.641578333,-103.27891,'29AN7D','20221215'],
  [19.39945,-99.17228,'21AG8K','20221215'],
  [19.44967,-99.27746,'LF2502','20221216'],
  [18.33634,-95.82469,'50AP7L','20221217'],
  [19.27024,-98.84803,'012EN5','20221220'],
  [19.17501,-98.30694,'40AL2N','20221220'],
  [19.29782,-98.40922,'53AV8E','20221220'],
  [19.73583,-99.20650,'955ET9','20221223'],
  [22.400105282471426,-100.76208437301602,'51AP7K','20221228'],
  [19.66076,-98.85104,'429DH6','20221229'],
  [19.95759,-99.84940,'104FE3','20221230'],
  [20.09420,-99.29930,'651EV8','20221230'],

  [17.99811,-93.39660,'869DG6','20230101'],
  [20.61347,-89.95821,'869DG6','20230101'],
  [20.19696,-99.77935,'90AY3C','20230101'],
  [25.814745414290613,-100.24686411189124,'95AD9V','20230102'],
  [19.66296,-99.00448,'55AS5U','20230103'],
  [25.692028,-100.509194,'02AY4X','20230103'],
  [20.41016,-103.20788,'46AX6H','20230104'],
  [24.75688,-107.35811,'74AT7A','20230105'],
  [19.955595,-99.704751,'659EW3','20230105'],
  [24.796887,-107.351903,'VKT108B','20230105'],
  [24.81805,-107.41129,'46AX6H','20230105'],
  [19.91448,-99.85150,'531EX8','20230106'],
  [19.379604,-99.245405,'04AP6K','20230106'],
  [24.59190,-107.39069,'A556AG','20230106'],
  [21.01718,-100.44789,'790AH5','20230106'],
  [20.09486,-98.71814,'PCN9584','20230107'],
  [18.08859,-95.40887,'504EV8','20230107'],
  [26.133819,-100.145361,'10TX1A','20230107'],
  [19.42479,-99.02526,'LC33274','20230109'],
  [19.04362,-98.01326,'616EV8','20230109'],
  [20.90008,-97.67372,'67AR2M','20230109'],
  [19.846139,-99.97797,'30UA8K','20230110'],
  [19.320106,-99.323184,'NXH4830','20230110'],
  [22.14473,-100.98170,'PCN9584','20230110'],
  [20.12900,-99.72756,'73AN5Z','20230111'],
  [20.52571,-103.24860,'LFS4017','20230111'],
  [17.99811,-93.39660,'869DG6','20230111'],
  [22.391283,-100.773416,'09AY8L','20230111'],
  [20.53280,-100.64640,'52AH2M','20230112'],
  [22.169588,-100.963937,'09AM9A','20230112'],
  [19.59869,-99.19736,'633EH7','20230112'],
  [21.008210,-101.408676,'27AK3K','20230112'],
  [19.83710,-99.94417,'11AL9X','20230112'],
  [20.09486,-98.71814,'PCN9584','20230112'],
  [19.29575,-101.88418,'06AA2V','20230112'],
  [20.09486,-98.71814,'PCN9584','20230113'],
  [19.25547,-98.40146,'56AL2N','20230113'],
  [28.49868355,-100.92145427,'55AJ9M','20230113'],
  [30.555813,-111.140299,'49AJ9M','20230114'],
  [23.198039,-106.198736,'09AJ8M','20230114'],
  [28.49868355,-100.92145427,'55AJ9M','20230114'],
  [18.98222756,-97.82646933,'51AJ9M','20230115'],
  [24.82610,-100.34252,'17AN8P','20230115'],
  [19.34914,-99.20111,'LC98084','20230115'],
  [20.07436,-99.61191,'23AF1Z','20230115'],
  [20.50306,-100.58396,'23AV7E','20230117'],
  [19.75194,-99.19941,'615FE4','20230117'],
  [19.26699,-98.48255,'33AU8R','20230117'],
  [19.64903,-99.08743,'LE06984','20230117'],
  [21.16947,-100.56956,'42AT3C','20230117'],
  [19.377933,-99.057562,'30AP6K','20230118'],
  [19.302837,-97.776162,'10AS6M','20230118'],
  [20.55512,-100.56624,'827ER9','20230119'],
  [19.82115,-99.24494,'892EH7','20230119'],
  [18.10315,-101.912499,'20AA2V','20230119'],
  [19.24751,-98.87023,'302FE9','20230119'],
  [20.49182,-101.48652,'858AE6','20230120'],
  [19.39177,-98.97779,'66AN1J','20230120'],
  [25.826696,-100.260971,'35AP6K','20230121'],
  [18.993581,-98.853596,'92AD7H','20230121'],
  [16.73222,-92.04023,'71AC4Y','20230121'],
  [22.35525,-100.79909,'55AA6T','20230123'],
  [19.47431,-99.23722,'LF45616','20230123'],
  [18.64834,-97.45343,'078EV4','20230123'],
  [20.44308,-103.54603,'67AN5Z','20230123'],
  [18.68504,-88.39542,'15AF8X','20230123'],
  [19.93082,-99.38151,'837AP3','20230124'],
  [19.50462,-99.30013,'35AE7H','20230124'],
  [19.56900,-99.20801,'48AK4P','20230124'],
  [19.36683,-99.53964,'89AJ3S','20230124'],
  [22.53601,-105.53714,'27AJ7P','20230125'],
  [18.96310,-98.75398,'LC51612','20230125'],
  [19.83436,-99.87182,'95AS2K','20230125'],
  [20.40102,-100.61845,'24AV7E','20230126'],
  [18.86041,-96.88058,'69AV9E','20230126'],
  [24.81914,-100.33720,'81AG7S','20230127'],
  [20.57580,-100.39077,'01AR4B','20230128'],
  [19.19453,-98.33170,'973FD6','20230128'],
  [19.44855,-99.12590,'20AR3M','20230128'],
  [19.27200,-98.43188,'858EG3','20230128'],
  [17.92553,-94.81612,'40AR9U','20230129'],
  [20.59511,-103.32273,'81AL2P','20230129'],
  [25.64291,-103.52473,'16AJ2E','20230129'],
  [20.83203,-100.44131,'07AG6K','20230130'],
  [19.30229,-98.78718,'868ES4','20230130'],
  [19.45059,-98.91010,'015EV9','20230130'],
  [31.874677,-112.850555,'31AY7Z','20230131'],
  [22.172446,-101.015182,'11AM9A','20230131'],
  [27.06856,-104.81991,'27AM3G','20230131'],
  [21.90048,-99.55142,'85AX3X','20230131'],
  [18.893921666666664,-100.75595166666666,'28AJ5J','20230131'],
  [19.09325,-98.20736,'537AR5','20230201'],
  [19.27432,-98.43077,'20AJ2L','20230201'],
  [18.95915,-99.21144,'LF48948','20230202'],
  [21.21431,-101.77372,'21AS3C','20230203'],
  [21.29016,-102.26722,'72AN5Z','20230203'],
  [19.95062,-99.61838,'3963CM','20230204'],
  [20.50377,-100.76403,'05AS5J','20230204'],
  [19.47715,-99.08609,'97AK1A','20230204'],
  [19.739759,-99.211990,'74AA5U','20230205'],
  [20.53240,-100.62809,'89AJ4C','20230207'],
  [19.39955,-99.05825,'Y42BGR','20230207'],
  [19.69667,-98.97378,'82AE3P','20230207'],
  [17.91431,-97.36235,'LE44863','20230207'],
  [19.35907,-101.92047,'58AX5E','20230208'],
  [19.302559,-99.089373,'44AM1B','20230208'],
  [19.96774,-99.53902,'88AF2K','20230208'],
  [22.066845,-100.839726,'40AU3Y','20230208'],
  [19.72719,-99.09473,'45AV6T','20230208'],
  [20.53212,-100.18449,'928FE9','20230208'],
  [19.54952,-101.52535,'738EH7','20230209'],
  [18.79202,-96.42307,'29AV7E','20230209'],
  [19.63059,-99.16750,'45AV6T','20230209'],
  [19.913093,-99.239568,'03AJ7T','20230209'],
  [18.79613,-97.75784,'44AR5Y','20230210'],
  [20.54273,-100.20111,'71AY1P','20230210'],
  [26.57443,-109.15620,'32AZ4R','20230210'],
  [19.35022,-98.62273,'68AB7R','20230210'],
  [19.15210,-98.28909,'41AL2N','20230211'],
  [20.09308,-101.10523,'17AM8U','20230211'],
  [20.85141,-100.43203,'106FE3','20230212'],
  [19.90072,-100.85123,'27AS2K','20230213'],
  [29.93769,-106.94800,'03AC8C','20230213'],
  [19.5826,-99.0265,'615FE4','20230213'],
  [19.69184,-99.07965,'45AU6T','20230213'],
  [19.08327,-98.28874,'67AR2M','20230213'],
  [20.048782,-99.421078,'664EW3','20230214'],
  [19.317022,-98.796302,'11AZ1S','20230215'],
  [20.23827,-102.38820,'12AL3N','20230215'],
  [20.57385,-100.91976,'827ER9','20230215'],
  [19.58099,-99.20557,'LF81890','20230216'],
  [19.244212,-98.389071,'57AJ9M','20230216'],
  [20.09936,-99.69511,'429AL8','20230216'],
  [19.57383,-99.02199,'12AG9S','20230216'],
  [20.05872,-99.59515,'429AL8','20230216'],
  [24.496692,-100.295086,'31AP6K','20230217'],
  [19.75976,-99.21541,'17AM8U','20230217'],
  [23.30992,-100.56531,'74AK3N','20230217'],
  [20.52567,-100.52990,'71AT5N','20230217'],
  [20.57871,-100.96705,'44AK1D','20230221'],
  [19.86390,-98.94376,'66AP4Y','20230221'],
  [19.98365,-99.50854,'92AL8D','20230221'],
  [19.10391,-103.77628,'03AH8N','20230221'],
  [19.28940,-98.42057,'43AR2B','20230222'],
  [19.10531,-98.28104,'65AF5H','20230222'],
  [24.00364,-104.75752,'62AJ5J','20230222'],
  [19.913434,-99.851961,'89AF9H','20230222'],
  [19.32984,-98.24457,'M75ARZ','20230222'],
  [20.42157,-103.39413,'07AM4Z','20230223'],
  [19.65454,-99.19773,'90AL9T','20230223'],
  [19.34732,-98.61187,'79AD9H','20230223'],
  [19.24305,-98.85978,'87AZ5K','20230223'],
  [19.98469,-99.44193,'27AZ8A','20230224'],
  [19.050453,-98.035072,'91AY8L','20230224'],
  [20.54682,-101.22012,'57AT5C','20230224'],
  [19.4664,-99.2394,'068EW3','20230224'],
  [19.3712,-99.0572,'80AG6S','20230225'],
  [20.423698,-103.195834,'11AJ1M','20230226'],
  [19.66425,-99.19873,'277FE9','20230227'],
  [20.810313,-89.796296,'09AP6K','20230227'],
  [23.49385,-102.13531,'43AX7U','20230227'],
  [14.883862,-92.310342,'58AE2P','20230228'],
  [22.517767,-100.664292,'97AP8K','20230228'],
  [18.55051,-101.97131,'636EH7','20230228'],
  [22.00733,-100.83697,'73AS6Z','20230228'],
  [20.044400,-99.438609,'54AU4Y','20230228'],
  [20.60783,-101.21442,'70AT5N','20230301'],
  [19.6395,-99.189843,'961EU5','20230301'],
  [19.852672,-99.287609,'71AH2P','20230301'],
  [22.405390,-100.732902,'31AM3B','20230301'],
  [20.32511,-99.93488,'21AF9L','20230301'],
  [19.63736,-99.15344,'79AX4C','20230301'],
  [21.06102,-100.49364,'3963CM','20230302'],
  [20.62157,-103.30247,'12AJ7B','20230302'],
  [20.56646,-100.87256,'17AZ1V','20230302'],
  [19.04373,-98.08181,'76AE8L','20230302'],
  [19.69886,-99.20493,'81AF3J','20230302'],
  [19.429851,-90.712724,'31AY1M','20230303'],
  [18.012040,-101.959259,'05AL9D','20230303'],
  [18.563587,-96.149685,'998DF2','20230303'],
  [19.07727,-98.15086,'38AY1P','20230304'],
  [18.617813,-92.164707,'78AD9H','20230304'],
  [24.66223,-100.29623,'04AY7D','20230304'],
  [20.59379,-103.32277,'28AU4V','20230305'],
  [19.83419,-99.87220,'87AK2N','20230306'],
  [19.597627,-99.199119,'LC90111','20230306'],
  [23.65658,-103.54131,'84AJ9M','20230306'],
  [19.24026,-98.38530,'625ET4','20230306'],
  [19.251867,-98.444108,'LE06984','20230307'],
  [25.720428,-100.264430,'69AD9H','20230307'],
  [19.85845,-99.87878,'43AA8N','20230307'],
  [20.39019,-99.07872,'88AM1A','20230307'],
  [22.916516,-100.469130,'55AJ9M','20230307'],
  [20.527171,-103.478531,'05AK9M','20230307'],
  [19.29867,-99.06206,'54AU3T','20230307'],
  [19.280999,-98.449561,'60AJ2N','20230307'],
  [19.63628,-99.10851,'A643AG','20230307'],
  [20.09308,-101.10523,'06AY4Z','20230307'],
  [22.13254,-100.82526,'38AX2X','20230307'],
  [20.2766,-99.8904,'38AG8H','20230307'],
  [22.918139,-100.469899,'980AG2','20230308'],
  [20.53772,-100.69315,'67AC8B','20230308'],
  [19.50424,-98.40414,'LF73025','20230308'],
  [19.376098,-97.617885,'71AR7M','20230308'],
  [20.419746,-103.440512,'65AS2V','20230308'],
  [18.83776,-97.08069,'25AF5P','20230308'],
  [19.61063,-99.23253,'81AF3J','20230308'],
  [22.86602,-100.48186,'88AM1A','20230309'],
  [19.27666,-98.42927,'94AX9A','20230309'],
  [19.88152,-101.20057,'44AS2K','20230309'],
  [25.064331,-100.599729,'66AL1K','20230310'],
  [19.07800,-98.28983,'06AY4Z','20230310'],
  [19.241746,-98.858440,'30AP6K','20230310'],
  [19.54585854558758,-98.91260591036723,'62UH8C','20230310'],
  [19.679739994255502,-103.23550209231159,'11AL7F','20230311'],
  [19.43720,-98.99839,'49AS3J','20230311'],
  [26.02948,-98.23749,'45AX3X','20230311'],
  [25.68596,-100.47363,'45AZ9T','20230312'],
  [25.70713,-100.48987,'45AZ9T','20230312'],
  [19.11929,-98.01155,'87AR5P','20230313'],
  [18.99170,-102.09904,'43AB3S','20230313'],
  [19.51420,-99.16177,'17FA4B','20230314'],
  [24.08141,-100.38655,'734ER8','20230314'],
  [20.077684,-99.347294,'55AP6V','20230314'],
  [16.94199,-96.71028,'81AT6C','20230314'],
  [19.60346,-99.21042,'93AS2K','20230314'],
  [24.23913,-100.38037,'568AR6','20230314'],
  [19.240187,-98.385028,'23AH9Z','20230316'],
  [25.81224,-100.38422,'20AN5N','20230317'],
  [19.13043,-97.65197,'LD78270','20230317'],
  [18.86167,-97.55609,'02AP3Y','20230318'],
  [20.50104,-101.47868,'88AZ4U','20230318'],
  [20.66219520748338,-103.20374236945479,'JX46265','20230318'],
  [19.24460,-98.38932,'97AH3P','20230318'],
  [26.53319,-99.99749,'36AG8S','20230318'],
  [18.01618,-93.73426,'63AJ5J','20230318'],
  [24.51213,-100.28627,'766EP7','20230321'],
  [19.24355,-98.38896,'909EN6','20230321'],
  [19.64304,-99.13616,'36AT7K','20230321'],
  [19.92398,-99.37313,'19AB1Z','20230323'],
  [22.10308,-100.82912,'15AC6W','20230323'],
  [20.33985,-102.20542,'15AC6W','20230323'],
  [19.04365,-98.11649,'69AN5Z','20230323'],
  [19.8910686,-99.2398109,'35AE7H','20230323'],
  [20.88495,-100.41544,'02AU8K','20230324'],
  [20.588434,-101.057911,'69AB2S','20230324'],
  [25.855589,-100.291886,'11AK8H','20230324'],
  [19.93606,-101.15417,'19AX6E','20230324'],
  [19.896787,-99.348856,'74AJ7E','20230325'],
  [19.75173,-99.12992,'22AL9D','20230325'],
  [18.998531,-98.839239,'50AT6W','20230325'],
  [22.99429,-98.93534,'01AT9X','20230325'],
  [19.532074,-99.068812,'07AP6K','20230326'],
  [24.190928,-100.379064,'979AG2','20230326'],
  [19.24350,-98.38879,'20AA3D','20230327'],
  [17.26874,-97.67356,'87AU2B','20230327'],
  [19.487278,-99.025712,'55AP6K','20230328'],
  [16.70722,-92.61513,'06AY4Z','20230328'],
  [24.95492,-107.75982,'26AU3Y','20230328'],
  [18.94443,-103.89784,'KN6389','20230328'],
  [25.808343,-100.281878,'04AP6K','20230328'],
  [20.070612,-99.367759,'10AJ1M','20230328'],
  [19.29697,-98.86747,'11AL5N','20230328'],
  [19.36738,-99.10332,'312FE9','20230329'],
  [22.09324,-100.88337,'10AZ7E','20230329'],
  [19.37159,-96.37949,'31AZ2H','20230329'],
  [20.56977,-100.48856,'81AE6H','20230329'],
  [18.99170,-102.10152,'15AS2K','20230329'],
  [18.798986,-96.603061,'38AP7K','20230330'],
  [23.74443,-106.78694,'539AL8','20230330'],
  [21.061806,-101.735333,'16AM7D','20230330'],
  [19.830095,-99.892500,'TCNU8517058','20230330'],
  [19.36816,-98.86262,'45AU6T','20230331'],
  [19.506913,-97.501199,'18AS6S','20230331'],
  [25.809962,-100.272243,'10AC2E','20230331'],
  [18.86546,-97.37550,'625ET4','20230331'],
  [20.483552,-100.116161,'73AE3P','20230402'],
  [19.95109,-99.61760,'82AT2F','20230403'],
  [19.329802,-98.698724,'94AU7F','20230403'],
  [20.61764,-101.40210,'84AM5F','20230403'],
  [20.58231,-103.31562,'47AA3F','20230403'],
  [20.09369,-99.30169,'30AY1D','20230403'],
  [19.70532,-98.7496,'065EX4','20230403'],
  [22.090577,-100.882492,'61AK4P','20230404'],
  [19.762340,-99.215667,'03AM9A','20230404'],
  [19.09529,-98.40678,'85AU2B-20AV7E-91AU2B','20230404'],
  [19.65281,-99.18735,'10AD9V','20230405'],
  [25.674990,-100.668145,'68AY1M','20230405'],
  [19.28639,-98.88039,'LD75911','20230405'],
  [15.977366,-93.640816,'45AN8U','20230405'],
  [21.81024,-101.10390,'08AE6J','20230405'],
  [19.732048,-99.210513,'58AY9N','20230405'],
  [19.72955,-98.77807,'106FE3','20230405'],
  [25.60614,-100.89915,'07AC6U','20230406'],
  [25.907126,-100.201186,'96AM4B','20230408'],
  [19.75254,-99.12971,'LF22400','20230408'],
  [20.54862,-100.20999,'106FE3','20230409'],
  [24.72778,-103.84628,'66AE8L','20230410'],
  [24.98134,-100.49275,'13UE3P/88UB3B','20230410'],
  [22.464204,-97.907982,'BSIU9836386','20230410'],
  [19.501618,-99.166257,'80AD9H','20230411'],
  [20.51293,-100.56961,'55AG9D','20230411'],
  [33.553049,-115.230836,'JV88924','20230411'],
  [19.03674,-98.02031,'932FE9','20230411'],
  [27.31982,-109.71904,'LF53491','20230412'],
  [19.335472,-98.713635,'CMAU6252010/APHU7219511','20230412'],
  [23.63813,-100.63743,'33AX3X','20230412'],
  [20.43872,-100.51004,'82AY7H','20230412'],
  [22.51981,-100.66355,'46AH2M','20230412'],
  [19.95055,-99.60182,'70AX6E','20230413'],
  [19.95055,-99.60182,'83AD8U','20230413'],
  [19.651659,-99.197001,'TGBU9893342','20230413'],
  [19.602765,-99.203262,'FSCU7216120/HLXU847122','20230413'],
  [19.086694,-98.203219,'53AJ9M','20230413'],
  [19.44827,-97.67803,'SEGU9412902//SEKU9259906','20230414'],
  [19.72908,-99.15421,'01AK7R','20230414'],
  [24.19844,-100.37902,'115AU5','20230414'],
  [19.17511,-98.30764,'17AP3N','20230414'],
  [18.621931,-91.935142,'94AM9A','20230415'],
  [20.29045,-99.94804,'9191ZP','20230415'],
  [20.72719,-103.56104,'LD40017','20230417'],
  [20.8987,-101.0712,'83AR8B','20230417'],
  [19.687035,-99.203136,'96AM8A','20230417'],
  [20.245839,-99.835882,'08AM3B','20230418'],
  [19.83468,-99.27085,'MSKU0822440/TCKU6870082','20230418'],
  [19.361360,-101.922076,'HLBU1882736','20230418'],
  [19.54699,-99.19835,'85AF2P','20230419'],
  [20.54599,-100.20560,'94AR5B','20230419'],
  [19.52606,-99.17928,'73AS8G','20230419'],
  [16.54611,-94.56685,'690EZ1','20230420'],
  [25.830465,-100.243814,'32AP6K','20230420'],
  [18.61040,-90.78124,'68AN6F','20230420'],
  [27.16390,-99.67180,'33AG2B','20230420'],
  [20.65706,-103.37878,'75AX7C','20230420'],
  [23.411084,-100.594266,'MX53186','20230421'],
  [19.630742,-99.164909,'87AL1U','20230421'],
  [19.31670,-98.80379,'24AZ1H','20230421'],
  [19.78842,-101.26359,'GZ7423G','20230421'],
  [19.68595,-99.26834,'45AS3Y','20230422'],
  [22.8608,-102.6241,'TGBU5761230','20230422'],
  [19.556672,-99.184624,'PAA9478','20230422'],
  [19.366811,-98.410245,'TEMU9883848','20230423'],
  [19.65921,-99.18922,'79AD3D','20230423'],
  [19.27425,-98.43116,'29AL2N','20230423'],
  [22.74767,-100.49416,'73AS6Z','20230425'],
  [19.63752,-99.16515,'85AN9W','20230425'],
  [19.24887,-98.87210,'16AL5N','20230425'],
  [19.432034,-101.895069,'SZLU9358322','20230425'],
  [20.16345,-99.75139,'05AY9L','20230426'],
  [20.53235,-100.64607,'LF53491','20230426'],
  [18.77243,-98.89595,'094AR5','20230426'],
  [19.901728,-97.224625,'91AD7H','20230426'],
  [20.046404,-103.554535,'CMAU4407935/TCLU8818075','20230426'],
  [18.72912,-98.76283,'LF53097','20230427'],
  [20.58235,-103.31751,'012EN5','20230427'],
  [19.61532,-99.02764,'308EW2','20230427'],
  [20.12916,-99.72788,'90AT4B','20230427'],
  [19.082832,-98.16502,'68AK1A','20230427'],
  [22.70125,-100.50186,'904EF9','20230427'],
  [19.74888,-99.11723,'63UA4Z','20230427'],
  [25.48297,-103.35709,'9191ZP','20230428'],
  [22.17365,-100.95237,'679EY7','20230428'],
  [19.64322,-99.19547,'24AX3B','20230428'],
  [19.65748,-99.19890,'LF22692','20230428'],
  [19.86916,-99.33095,'7885ZP','20230428'],
  [19.064605187683732,-98.10157456610645,'986EU1','20230428'],
  [18.875069,-96.915250,'TTNU4897836','20230428'],
  [19.75326,-99.09356,'LD79111','20230428'],
  [20.53493,-100.70947,'70AA4J','20230429'],
  [19.330377,-97.962473,'HLBU3149158','20230429'],
  [23.943122,-100.447972,'78AT4W','20230429'],
  [24.651013,-100.291266,'014UM4','20230429'],
  [19.908545,-100.45499,'CAIU9677479','20230503'],
  [16.211607,-93.858984,'CMCU9012477','20230503'],
  [19.04405,-98.008291,'90AG9D','20230503'],
  [20.170432,-99.757212,'893EY5','20230504'],
  [20.57008,-100.48107,'82AH6M','20230504'],
  [19.29066,-98.46615,'97AT1K','20230504'],
  [27.07424,-109.48107,'20AS8H','20230505'],
  [19.55246,-99.18471,'6442ZP','20230505'],
  [19.42446,-98.21675,'HLXU3514659/FCIU6304886','20230505'],
  [23.54287,-105.75683,'13BA1C','20230505'],
  [19.12523,-98.26212,'963FF2','20230505'],
  [21.048236,-101.562533,'YMMU6729450','20230505'],
  [18.785241,-96.507446,'96AN2J','20230505'],
  [21.1204,-102.4553,'TCKU6339590/CMAU9364754','20230506'],
  [23.16490,-100.52367,'LE74906','20230506'],
  [20.491627,-103.149937,'FFAU4435025/SEGU6425038','20230506'],
  [24.91193,-100.41626,'TCNU8774652/TXGU6920848','20230506'],
  [20.07387,-101.97050,'96AN2T','20230506'],
  [20.575418,-103.181145,'CMAU4734136/SEKU5853993','20230507'],
  [19.59951,-98.57918,'HLXU3396279/UACU3561173','20230507'],
  [18.00503,-101.96267,'MRSU4746864/MRSU5145261','20230507'],
  [20.928117,-103.213073,'25AP5U','20230508'],
  [26.46972,-109.06503,'87AZ5K','20230508'],
  [20.05561,-99.19242,'MRKU6382516/MRKU5893933','20230508'],
  [19.310069,-98.829800,'CMCU9012332','20230508'],
  [19.060596,-91.085999,'72AP7K','20230509'],
  [20.507328,-100.147575,'LATU9003047/LATU9003068','20230509'],
  [22.01913,-100.38211,'43AM2B','20230509'],
  [20.61946,-103.20836,'JW29609','20230510'],
  [19.30423,-98.74500,'869DG6','20230510'],
  [20.6162,-103.2143,'MRKU0957104/MSKU1984575','20230510'],
  [19.52761,-98.48075,'164EV8','20230510'],
  [19.55319,-99.06208,'LE40090','20230510'],
  [19.68084,-98.98532,'01AX5B','20230511'],
  [20.5596446990967,-103.103942871094,'BEAU5626972/FFAU4430487','20230511'],
  [19.300711,-98.749193,'89AR1Z','20230511'],
  [19.12382,-98.26091,'23AN4C','20230511'],
  [19.62806,-99.13502,'46AX3X','20230511'],
  [20.094346,-99.300180,'FFAU2616214/TGBU8601934','20230512'],
  [19.41636,-102.02543,'67AY8P','20230512'],
  [19.43963,-99.27478,'A191AA','20230512'],
  [22.39789,-100.76217,'33AG8S','20230512'],
  [19.40229,-99.05804,'59AD9S','20230513'],
  [19.02439,-97.95609,'70AS2J','20230513'],
  [24.49642,-100.29434,'LF81890','20230514'],
  [32.526774,-116.346938,'TCLU1126990/TCLU1151695','20230514'],
  [20.27723,-99.89185,'46AH2M','20230514'],
  [25.54893233310317,-100.94332941818097,'99AM8A','20230515'],
  [19.32004,-98.72580,'47AT8W','20230515'],
  [19.743857,-88.703641,'69AL1K','20230516'],
  [20.166026,-96.957274,'MEDU9004627','20230516'],
  [19.28291,-101.87925,'TCKU3552220/CMAU1991300','20230516'],
  [18.38778,-92.79688,'155DT9','20230516'],
  [24.75953,-100.31403,'889FF2','20230517'],
  [20.90085,-101.36835,'801AM4','20230517'],
  [25.69335,-100.16920,'LE74906','20230517'],
  [21.06217,-100.49476,'37AN7Z','20230517'],
  [18.555396,-101.970038,'TCLU9110692,//CMAU4446382','20230517'],
  [25.16811,-100.72033,'80AY9H','20230518'],
  [20.57674,-100.84098,'47AH2M','20230518'],
  [20.138151,-98.194961,'TLLU1244100','20230518'],
  [22.338946,-100.797548,'21AY1M','20230518'],
  [19.83184,-99.91115,'LC94068','20230519'],
  [19.58220,-99.20439,'LF83567','20230519'],
  [23.197426,-106.194319,'61AJ1N','20230519'],
  [19.30962,-103.60744,'TCKU3719175/CAIU6381360','20230519'],
  [19.351228,-101.914508,'TEMU2989123','20230519'],
  [20.199357,-99.780977,'GBU8725299/FFAU3621920','20230519'],
  [19.135048,-98.271768,'CMCU9015650','20230519'],
  [19.87598,-98.93877,'Y42BGR','20230519'],
  [19.53459,-98.48417,'45AU6T','20230519'],
  [21.57868,-101.66400,'56AU5C','20230520'],
  [19.74687,-99.22897,'MYC4081','20230521'],
  [19.09586,-98.18863,'399AT1','20230521'],
  [20.64316,-100.45470,'62AL4N','20230521'],
  [19.75410,-99.14782,'461AL4','20230522'],
  [19.37831,-102.04776,'89AN8H','20230522'],
  [19.26949,-98.42701,'76AE8L','20230522'],
  [18.000498,-93.619803,'38AP7K','20230522'],
  [20.85806,-101.30988,'LF46517','20230522'],
  [20.54904,-103.03782,'505EV8','20230523'],
  [19.39945,-99.17228,'21AG8K','20230523'],
  [19.30016,-98.48243,'41AD9R','20230523'],
  [20.49570,-100.13174,'JW36585','20230524'],
  [31.87368,-112.84989,'08AF6P','20230524'],
  [21.36078,-101.97268,'14AZ7U','20230524'],
  [19.34922,-98.66961,'48AX9W','20230524'],
  [20.374335,-102.666773,'SEGU5237247','20230524'],
  [18.36457,-95.78971,'LC90111','20230524'],
  [19.98535,-99.50292,'MRSU4989139/CAAU5227043','20230525'],
  [25.79375,-100.52113,'05AJ4K','20230525'],
  [20.32783,-102.26011,'402AH6','20230525'],
  [19.76065,-99.21551,'47AN5Y','20230526'],
  [18.84174,-98.44380,'43AH2M','20230526'],
  [20.450352,-102.883087,'DFSU7153134','20230526'],
  [19.11627,-98.15998,'04BA5R','20230526'],
  [17.85367,-91.78736,'75AL9T','20230526'],
  [19.17501,-98.30694,'40AL2N','20230526'],
  [19.747545,-99.132296,'652YH1','20230527'],
  [19.87539,-100.19142,'14AT2F','20230528'],
  [19.98585,-99.44291,'84AL6N','20230528'],
  [19.29782,-98.40922,'53AV8E','20230528'],
  [25.74682,-100.51545,'10AP6K','20230528'],
  [18.066645,-101.931409,'TCLU6249468','20230529'],
  [19.18370,-98.31855,'21AX9V','20230529'],
  [19.35100,-98.64247,'A309AA','20230529'],
  [19.48037,-99.12956,'999EF4','20230529'],
  [18.82337,-97.24573,'D57AUX','20230530'],
  [20.44866,-100.08033,'281FE9','20230530'],
  [19.24447,-98.88077,'94AZ3H','20230530'],
  [19.34771,-99.30695,'414EX2','20230531'],
  [23.19631,-100.53338,'326ER9','20230531'],
  [19.72096,-99.20860,'THRU4514408','20230601'],
  [19.28043,-99.55622,'456AS8','20230601'],
  [18.89693,-97.03969,'87AU2B','20230601'],
  [20.89874,-101.01357,'06AU3K','20230601'],
  [19.248261,-98.872180,'21AY1M','20230602'],
  [18.86473,-97.37234,'55AS5C','20230602'],
  [19.55193,-99.07010,'8524NL','20230602'],
  [19.218287,-90.840415,'94AM9A','20230603'],
  [18.82702,-97.30445,'521EH8','20230603'],
  [18.399840,-92.799098,'09AJ8M','20230604'],
  [19.27768,-99.58868,'31AP6K','20230605'],
  [19.63062,-97.12785,'38AU9R','20230605'],
  [19.70493,-98.86287,'LE01094','20230605'],
  [20.083753,-90.202815,'09AJ8M','20230605'],
  [19.25724,-99.16104,'53AL5D','20230606'],
  [19.594783,-99.201863,'TCNU3380152','20230606'],
  [19.96134,-99.54472,'TCLU9103760/CAIU7428327','20230606'],
  [20.62041,-103.3220,'956AL6','20230607'],
  [23.54185,-103.16347,'051EN4','20230607'],
  [19.43077,-102.02782,'73AJ1U','20230607'],
  [19.52609,-99.74419,'HLBU9841013/SEGU9106409','20230608'],
  [19.50588,-97.50316,'61AK8W','20230608'],
  [22.00625833,-100.836413,'43AK3X','20230609'],
  [19.04613,-98.11827,'15AL4N','20230609'],
  [20.27899,-99.89781,'64AP6Z','20230610'],
  [19.4573,-96.7466,'SEKU6443099','20230610'],
  [20.85979,-100.42755,'24AU4W','20230610'],
  [19.43225,-99.05128,'34AC1C','20230610'],
  [19.450514,-98.909988,'74AP7K','20230611'],
  [22.400105282471426,-100.76208437301602,'51AP7K','20230612'],
  [23.716417,-101.87431,'GLDU7610500/FFAU1606150','20230612'],
  [20.573898,-103.101019,'TCLU8711791','20230612'],
  [20.50444,-100.84872,'53AS3J','20230612'],
  [21.277990,-100.591203,'08AM3B','20230612'],
  [20.99231,-101.49671,'84AU2B','20230613'],
  [21.30583,-102.09125,'71AC7B','20230613'],
  [20.175087,-102.195634,'HLXU1280675/TCLU3142558','20230613'],
  [20.31432,-99.93266,'299FE9','20230613'],
  [20.29434,-99.92672,'73BA7Y','20230613'],
  [22.20706,-97.871022,'TRLZ402180','20230613'],
  [19.36683,-99.53964,'89AJ3S','20230613'],
  [24.757664,-101.159212,'TCNU6219732','20230613'],
  [21.02192,-101.40822,'92AH7L','20230613'],
  [20.734967,-88.318113,'41AM5A','20230613'],
  [19.473306,-99.199486,'TGBU5726352','20230613'],
  [19.41578,-102.03435,'82AG6S','20230614'],
  [19.211544,-103.739809,'PCIU8769809/PCIU9550672','20230614'],
  [20.724372,-103.503735,'63AU2T','20230614'],
  [16.40861,-93.80291,'CMCZ810740','20230614'],
  [19.75549,-98.62964,'70AY7P','20230614'],
  [23.13901,-102.42437,'BEAU4053217/CMAU5874370','20230615'],
  [19.27840,-98.46270,'069DJ1','20230615'],
  [19.21789,-96.25776,'81AE6H','20230615'],
  [18.43749,-99.17515,'39AX8W','20230615'],
  [19.90216,-100.79040,'85AY7R','20230615'],
  [27.95691,-110.75458,'96AX8B','20230616'],
  [18.26318,-95.69812,'54AP2J','20230616'],
  [20.17623,-99.76199,'48AF2P','20230616'],
  [19.137543,-98.27444,'638DH4','20230616'],
  [20.083823,-99.664701,'24AZ1H','20230616'],
  [19.89234,-103.52095,'TCNU2244399','20230617'],
  [25.84286,-100.24558,'10AC1P','20230618'],
  [19.993598,-99.474293,'OOCU7393493/BMOU4813588','20230619'],
  [19.83774,-99.24157,'855ES1','20230619'],
  [19.605137,-99.205398,'BEAU5787806/FFAU28813','20230619'],
  [20.57609,-103.35281,'19AE1U','20230621'],
  [18.792889,-102.023194,'MRKU3950700','20230621'],
  [19.135443,-104.265585,'91AP4X','20230622'],
  [18.76747,-96.44999,'09AN9Z','20230622'],
  [20.45657,-103.51431,'729DT9','20230623'],
  [19.12208,-98.25892,'92AZ7Y','20230623'],
  [23.84717,-105.26862,'15AH4P','20230623'],
  [21.858643,-101.149562,'55AC9B','20230623'],
  [19.30274,-98.74945,'51AX9W','20230623'],
  [19.67583,-99.06997,'666DH8','20230623'],
  [20.75337,-101.34732,'12AH3P','20230623'],
  [19.512233,-101.701659,'BSIU9524069/OOLU4932577','20230623'],
  [25.785109,-100.178604,'HLBU2176056/TCLU8219122','20230624'],
  [20.37420,-102.66559,'85AY7R','20230624'],
  [21.28663,-102.27055,'25AX3U','20230624'],
  [18.35711,-97.35978,'12AY6N','20230624'],
  [19.98385,-99.44097,'35AL1U','20230625'],
  [20.59181846538466,-101.13580778460864,'MSKU6851317/MRKU0296133','20230626'],
  [19.63579,-99.11066,'LF88452','20230626'],
  [19.28246,-98.45195,'LC36301','20230626'],
  [27.53783,-109.84678,'34AY1M','20230626'],
  [20.371713,-99.989326,'SEGU3631159','20230626'],
  [20.51434,-100.76453,'22AV7E','20230626'],
  [19.43574,-101.80064,'MSKU7771875/TCLU7627185','20230627'],
  [19.6806438,-101.3466752,'MSKU77771875,MVIU200572720,TCKU7627185','20230627'],
  [23.37312,-106.49228,'LF10446','20230627'],
  [21.411449,-101.850646,'405XN1','20230627'],
  [19.26322,-98.43030,'53AB6J','20230628'],
  [19.03565,-97.98681,'85AY7R','20230628'],
  [17.24014,-99.52375,'09AY6N','20230629'],
  [23.281977,-100.557739,'21AS3J','20230629'],
  [25.59671,-100.87533,'97AG3X','20230629'],
  [21.33224,-102.10684,'04FA1A','20230629'],
  [19.19043,-98.32677,'20AA3D','20230629'],
  [19.70967,-101.33816,'TRHU3201712','20230630'],
  [19.30919,-98.74543,'36AJ9M','20230630'],
  [20.298503,-103.557592,'CMAU8528489/CMAU6686844','20230630'],
  [19.89384,-99.34677,'MRKU4222226/HASU4558152','20230630'],
  [20.54569,-100.58922,'82AT8Y','20230630'],
  [19.931823,-103.52951,'70AP7Y','20230702'],
  [20.34921,-102.63053,'91AM7D','20230702'],
  [28.36282,-100.94305,'55AN9R','20230702'],
  [19.19527,-100.10015,'78AY2T','20230702'],
  [19.25847,-98.42922,'91AB6A','20230703'],
  [17.92981,-99.37375,'4690ZP','20230703'],
  [22.17403,-100.96075,'53AS5U','20230703'],
  [19.66022,-99.19841,'94AD7S','20230704'],
  [25.42017,-100.97090,'CMAU4509372/TXGU6683854','20230704'],
  [24.71748,-103.87750,'98AF5B','20230704'],
  [19.28181,-98.42655,'68AM5N','20230704'],
  [20.35505,-100.29881,'LF10446','20230704'],
  [19.61539,-99.02715,'91AE7E','20230705'],
  [19.33637,-98.70931,'30AJ1K','20230705'],
  [19.78188,-103.51018,'39AG4M','20230705'],
  [20.407628,-103.228836,'TCNU4493298/CMAU5331794','20230706'],
  [16.2648396,-94.1301735,'13AR1K','20230706'],
  [20.67540,-101.46581,'95BB1J','20230706'],
  [22.13727,-100.85180,'06AU3K','20230706'],
  [19.504133,-90.703632,'91AY8L','20230706'],
  [19.901868,-100.792209,'85AR7M','20230707'],
  [25.23518,-100.78427,'62AL3U','20230707'],
  [19.30215,-98.48858,'LE74906','20230707'],
  [19.84409,-101.28233,'WHSU5651911/WHSU6606136','20230707'],
  [21.80861,-100.73047,'004ES5','20230707'],
  [18.528072,-96.092330,'LC90111','20230708'],
  [19.93186,-100.61840,'--','20230708'],
  [20.449969,-103.513442,'CAXU6133365/GLDU5163341','20230708'],
  [19.215254,-103.726302,'PCIU8806219/PCLU8009669','20230709'],
  [25.04548,-100.57864,'915EP9','20230709'],
  [19.30762,-98.93911,'56AP8K','20230709'],
  [19.902695,-98.788994,'01AJ7F','20230710'],
  [18.03259,-101.9503,'05AY4Z','20230710'],
  [25.803246,-100.41156,'RB4373B','20230710'],
  [23.66541,-100.60727,'LF10446','20230710'],
  [20.00043,-99.14015,'BMOU4968825/PCIU9370639','20230710'],
  [23.55689,-100.62703,'21AG6V','20230711'],
  [19.97142,-99.43280,'15FA4A','20230711'],
  [21.1986,-102.4425,'CMAU8818955//APZU4400180','20230711'],
  [19.9459,-99.3938,'82AY7H','20230712'],
  [20.17359,-102.16196,'62AZ1Y','20230712'],
  [26.79774,-99.86703,'546AT9','20230712'],
  [22.569201,-100.634490,'31AE9L','20230713'],
  [20.497396,-100.132531,'62BA5Y','20230713'],
  [17.25891,-99.51640,'LC94068','20230713'],
  [19.54641,-103.43074,'99AV7C','20230713'],
  [18.85028,-97.27395,'48AJ1Y','20230714'],
  [20.01692,-99.15921,'TCNU6626255','20230715'],
  [18.86506,-97.38918,'39AU1Z','20230715'],
  [21.94986,-100.83193,'0494NR','20230715'],
  [19.23166,-98.37617,'82AE3P','20230716'],
  [19.51677,-99.20351,'7894ZP','20230716'],
  [22.576075,-100.621104,'81AM4B','20230716'],
  [19.75357,-99.14781,'A609AF','20230717'],
  [19.61449,-99.19074,'29AU1T','20230717'],
  [20.68458,-103.24837,'09AX9D','20230718'],
  [24.10274,-107.05060,'638DH4','20230718'],
  [19.08189,-98.205151,'258EG4','20230718'],
  [19.88092,-101.14648,'261YH7','20230719'],
  [18.97913,-97.81744,'52BA2M','20230719'],
  [20.36308,-99.97673,'99AS8Z','20230719'],
  [20.62021,-103.20262,'20AK6L','20230720'],
  [19.71223,-99.20378,'75AY5S','20230721'],
  [25.51181,-100.86783,'47AD3P','20230721'],
  [19.088096,-103.775906,'MSKU0973642/CIPU5088033','20230721'],
  [19.62453,-99.31739,'LD45328','20230721'],
  [20.09508,-99.29896,'69AD5T','20230721'],
  [19.63326,-99.11738,'298DE3','20230723'],
  [28.743513,-105.995220,'80AJ9M','20230723'],
  [23.85272,-100.50114,'393DT7','20230723'],
  [28.15939,-110.98082,'560AS8','20230723'],
  [20.61573,-103.24380,'66AN4W','20230726'],
  [19.04369,-98.01387,'33AK9P','20230726'],
  [18.97614,-97.80842,'307ES2','20230726'],
  [20.503609,-100.766643,'19FA5B','20230726'],
  [22.00862,-100.83686,'45AP1P','20230726'],
  [19.381634,-98.164293,'CMCZ810173','20230727'],
  [31.07644,-110.09607,'12AR8J','20230727'],
  [20.58693,-101.03449,'229DE4','20230727'],
  [21.2894,-102.1802,'622DH8','20230727'],
  [21.10146,-101.78290,'49AS3J','20230728'],
  [25.19913,-100.73925,'57AE2P','20230728'],
  [25.42081,-100.88102,'TCLU4856903/TCLU8869494','20230729'],
  [19.70832,-99.19390,'96AE7C','20230729'],
  [21.238098,-101.305076,'82AJ6D','20230729'],
  [19.34747,-98.60905,'97AT1K','20230729'],
  [19.94435,-99.38989,'94AU7F','20230729'],
  [20.16698,-90.14026,'21AV7E','20230729'],
  [19.58570,-99.02226,'DRYU9512084/KKFU777351','20230729'],
  [21.60484,-100.75778,'62AU6C','20230730'],
  [26.60250,-109.17974,'87AH3W','20230730'],
  [26.54199,-109.12707,'91AH7L','20230730'],
  [20.17517,-102.19041,'65AZ1Y','20230730'],
  [25.64742,-102.10689,'05AG6K','20230731'],
  [19.539851,-98.903086,'TGBU5622893','20230731'],
  [18.05294,-93.94992,'38AW1Y','20230731'],
  [19.561575,-99.141861,'PEE6871','20230731'],
  [25.49433,-100.87351,'65AY1M','20230731'],
  [22.295087,-100.964892,'SEKU5966393/SEKU4520004','20230801'],
  [19.52459,-101.57010,'06AU3K','20230801'],
  [26.63476,-100.01747,'07AY5D','20230801'],
  [19.84048,-99.27856,'CSNU8559335/BEAU5757519','20230801'],
  [22.80925,-100.48717,'27AH8U','20230802'],
  [18.292423,-101.90492,'26BA8W','20230802'],
  [26.57521,-109.15693,'32AZ4R','20230802'],
  [20.78172,-102.78607,'224AF2','20230802'],
  [20.51146,-100.87111,'18AH5G','20230803'],
  [19.894256,-99.852413,'NKYU5938756','20230803'],
  [19.78257,-99.16323,'79BA9U','20230805'],
  [19.557688,-97.316295,'MAGU5317651','20230805'],
  [19.788328,-99.225766,'UACU4768139','20230805'],
  [19.099323,-98.233364,'46AB7S','20230807'],
  [19.58350,-101.30704,'MSMU2075643','20230807'],
  [24.24702,-100.38142,'61AP5S','20230808'],
  [32.53310,-115.40796,'19AZ8E','20230808'],
  [20.001406,-99.495287,'038EP4','20230808'],
  [19.85753,-99.30446,'82AY7H','20230809'],
  [22.52218,-100.66203,'64FA5A','20230809'],
  [20.4787,-101.4972,'MSMU8745953','20230809'],
  [18.13062,-101.90690,'TCLU9794569','20230809'],
  [19.30194,-98.74944,'57AJ9M','20230809'],
  [20.56816,-100.49711,'46AX6H','20230810'],
  [19.94034,-99.77197,'44AX5E','20230810'],
  [19.99248,-99.47087,'05AW6Y','20230811'],
  [21.379993,-101.92003,'FFAU2619152/FFAU1901808','20230812'],
  [24.606645,-100.272321,'80AX6D','20230812'],
  [19.60722,-99.18976,'LF85904','20230814'],
  [19.604344,-99.206468,'MSDU5569638//CAIU7797731','20230814'],
  [19.592547,-99.201848,'TLLU5181551/MAGU5569191','20230814'],
  [20.55721,-100.81415,'937EX3','20230814'],
  [19.6405,-96.9953,'CAAU6440963','20230815'],
  [19.049349,-98.030192,'54AE2P','20230816'],
  [19.1334583333333,-98.24806666666667,'68AF6N','20230816'],
  [19.89130,-101.02118,'CGMU5301503','20230816'],
  [19.44655,-99.00009,'298DE3','20230816'],
  [19.90812,-100.75360,'SEGU4660887/TCNU3616546','20230817'],
  [18.8198,-97.1619,'CMCU9017036','20230817'],
  [18.921130,-104.016164,'MRKU3747293','20230818'],
  [20.458840,-103.252990,'CLHU3913514/UACU4128189','20230818'],
  [19.816234,-99.240961,'UACU5660452/FANU1998923','20230818'],
  [19.50058,-99.04694,'MMP055A','20230818'],
  [20.21717,-99.21952,'LC98084','20230818'],
  [19.297119,-98.867940,'MRSU4313041','20230818'],
  [17.94803,-92.78536,'98AP5L','20230819'],
  [19.943727,-99.84259,'037EH6','20230819'],
  [27.447236,-104.957389,'97AR8H','20230819'],
  [23.8956,-103.1260,'81AS2A','20230820'],
  [19.09269,-98.23403,'86AX1C','20230821'],
  [19.26018,-98.96934,'52AX26','20230822'],
  [18.97864,-99.23127,'88AC9T','20230822'],
  [17.910877,-94.9245772,'20AJ2D','20230822'],
  [23.54536,-100.62731,'090EG9','20230823'],
  [20.315901666666665,-99.93280166666666,'16AS8V','20230823'],
  [19.74481,-98.96500,'87AZ8T','20230824'],
  [19.77548,-99.21744,'CSNU6272053,//CSLU6164241','20230824'],
  [19.14335,-98.22172,'55AS5U','20230824'],
  [18.03118,-101.95111,'APHU7163428','20230824'],
  [21.28502,-100.59280,'82AY7H','20230825'],
  [18.81933,-97.24585,'LF53353','20230826'],
  [27.712086,-110.376216,'L9427','20230826'],
  [18.98479,-98.17136,'A207AC','20230826'],
  [23.638720,-100.603660,'08AK1R','20230827'],
  [21.67099,-102.29002,'TLLU4356568/TCNU7765640','20230828'],
  [19.59286,-99.05057,'LC68814','20230828'],
  [19.6046622977149,-99.20518822205021,'TRHU7214554','20230829'],
  [20.64689,-100.28649,'LF51731','20230829'],
  [19.62725,-98.55282,'TRHU4825349/MRSU6092377','20230830'],
  [19.75687,-99.21472,'EMCU1705076/EMCU1703875','20230830'],
  [21.30813,-102.02875,'39AP5S','20230830'],
  [19.578613,-99.026261,'30AA3P','20230831'],
  [19.34235,-98.40129,'70AZ1Z','20230831'],
  [18.92208,-97.69552,'41AE3M','20230831'],
  [19.89610,-100.89797,'MSMU1511254','20230901'],
  [17.586646666666663,-99.38730166666667,'10AD1W','20230901'],
  [18.50088,-99.19022,'09AY6N','20230902'],
  [21.65967,-101.58697,'356EL5','20230902'],
  [19.4653,-97.5756,'MSCU7489353/WEDU8091910','20230902'],
  [19.62725,-98.55282,'IKSU4002185','20230902'],
  [19.87595,-100.19033,'80AT9U','20230902'],
  [21.99134,-100.82799,'773EG4','20230903'],
  [19.91095,-100.47852,'FFAU1531811/SEGU5143575','20230903'],
  [19.27493,-98.88363,'69AE2Y','20230904'],
  [26.57489,-109.15667,'LG00669','20230905'],
  [19.30009,-99.04593,'88AU2B','20230905'],
  [19.68272,-99.21317,'385FE8','20230906'],
  [19.75177,-98.75636,'14AZ1U','20230906'],
  [25.59872,-108.51537,'37AU9R','20230906'],
  [20.895421,-89.448701,'81AD9H','20230906'],
  [19.97355,-99.43537,'07AS2U','20230907'],
  [19.90177,-100.78738,'85AN9W','20230907'],
  [25.66657,-100.31216,'LC12516','20230907'],
  [19.376469,-99.699950,'68AC7B','20230907'],
  [18.94423,-103.92295,'22AX7D','20230908'],
  [19.429894,-98.991044,'04BA5F','20230909'],
  [19.89063,-100.974116,'TEMU7107634/BSIU9601381','20230909'],
  [17.25114,-95.05113,'480AF1','20230909'],
  [22.97563,-102.69705,'13AY1J','20230909'],
  [19.899924,-98.778653,'89AV1R','20230910'],
  [18.81195,-96.66046,'24AX6V','20230910'],
  [20.074690,-88.909438,'15AF8X','20230910'],
  [19.021679,-88.361685,'15AF8X','20230911'],
  [25.30798,-100.80210,'16AB6Y','20230911'],
  [24.83355,-103.68685,'A736AG','20230911'],
  [18.81509,-97.20831,'14AH7R','20230911'],
  [19.83726,-99.94376,'TRHU7260884/TGBU9996042','20230912'],
  [19.55170,-101.52125,'MRSU6311515/MESU5750508','20230912'],
  [19.60474,-99.03678,'CSLU2086480/CSLU2248299','20230912'],
  [19.99671,-99.49128,'MSMU1709960,MEDU5489216','20230912'],
  [19.96278,-99.42310,'52AP7X','20230912'],
  [19.56226,-99.20153,'E08BHN','20230913'],
  [19.51157,-99.21346,'78AN9B','20230913'],
  [19.233353,-98.378396,'02BA1U','20230913'],
  [20.64840,-100.43142,'37AX7V','20230916'],
  [19.40625,-103.53076,'08AW5Z','20230916'],
  [17.986321,-93.193724,'68AD9H','20230918'],
  [19.727893,-99.20985,'BMOU4069748','20230919'],
  [20.54846,-103.03563,'46AX6H','20230919'],
  [18.8504,-96.9148,'87BB4Y','20230919'],
  [20.03032,-99.17365,'CAIU8284097/TEMU8523718','20230920'],
  [19.67293,-101.22125,'SEKU6041280//TCNU3632676','20230920'],
  [21.74293,-100.68685,'88AM1A','20230920'],
  [19.5845,-101.3055,'BHCU5017843/CAAU5961524','20230920'],
  [19.934486,-99.383301,'MRSU4792983','20230920'],
  [24.00552,-104.7335266666666,'13AX3X','20230920'],
  [19.366978331444063,-99.5677329060714,'43AH2M','20230921'],
  [19.40100,-97.42464,'51AX2C','20230921'],
  [19.044102,-98.012796,'39AA1P','20230922'],
  [18.007253,-93.660165,'84AM8B','20230922'],
  [19.85831,-100.08206,'18AX5E','20230922'],
  [19.044102,-98.012796,'663DV7','20230922'],
  [19.682596,-103.474368,'12AH3P','20230925'],
  [21.03029,-100.45817,'LF95550','20230925'],
  [20.70221,-103.36675,'5261ZR','20230925'],
  [20.321623,-102.170318,'47AM5A','20230925'],
  [19.301454,-98.764856,'BMOU5288988/CMAU4986441','20230927'],
  [20.44463,-103.18698,'TGBU5530591','20230927'],
  [19.90095,-100.77998,'20AF4P','20230927'],
  [24.49975,-104.12919,'5434ZP','20230927'],
  [19.84026,-99.27838,'MRSU3298600/MRSU4509728','20230928'],
  [20.58016,-100.98658,'MRKU4542826/HASU4169103','20230928'],
  [19.8902,-100.72407,'TCLU4657558','20230928'],
  [19.95855,-99.06712,'38AY1P','20230928'],
  [26.574417,-109.156206,'LC68814','20230928'],
  [19.551629,-101.521604,'TCNU6649501','20230929'],
  [25.68621,-100.24769,'29AW4Y','20230929'],
  [1809968,-92.86521,'10AY8L','20230930'],
  [19.044649,-98.828971,'094EV4','20230930'],
  [19.92550,-99.82838,'MEDU6825547/MEDU7766813','20230930'],
  [19.87724,-100.23584,'WHSU2001764','20230930'],
  [19.997458,-99.488663,'CMCU9017680','20231001'],
  [20.59763,-101.07663,'44AF7E','20231003'],
  [20.075712,-99.312714,'KKFU7865093,TRLU7571375','20231004'],
  [20.59763,-103.32500,'5261ZR','20231004'],
  [19.638000,-99.156201,'21BA5Y','20231005'],
  [22.44326,-102.33581,'TEMU3177171','20231005'],
  [19.97974,-99.43665,'13AL4N','20231005'],
  [17.052256,-96.640207,'86AU2B','20231005'],
  [19.24750,-98.87023,'052EV4','20231006'],
  [19.101218,-103.776888,'WHSU6532877','20231006'],
  [15.90027,-97.08451,'69AF7X','20231007'],
  [19.63846,-96.98881,'TCLU3571589','20231009'],
  [20.71211,-100.35310,'61AB8C','20231010'],
  [19.24024,-98.38526,'773EG4','20231010'],
  [20.077271,-99.239778,'CSNU7680656/OOLU9282181','20231010'],
  [19.92989,-98.89254,'TGHU6858571/TGBU4916276','20231011'],
  [19.50222,-99.11943,'16AM8U','20231011'],
  [19.65841,-98.99926,'LE07955','20231012'],
  [20.00742,-102.23680,'226AF2','20231012'],
  [19.61961,-98.54950,'01AH3P','20231012'],
  [20.94524,-101.44482,'MSDU9023497/MEDU9062393','20231012'],
  [19.220221,-98.362212,'S/D','20231013'],
  [19.625520,-99.175641,'LF83567','20231013'],
  [22.076821,-100.893805,'47AB2S','20231013'],
  [15.846910,-93.440735,'543EY1','20231013'],
  [19.34500,-99.06127,'09AR3B','20231013'],
  [19.62667,-99.14480,'09AY8L','20231014'],
  [20.12009,-99.72136,'LE01031','20231014'],
  [19.4343,-101.820660000,'43BB3I','20231014'],
  [17.24824,-95.05140,'902AG2','20231014'],
  [19.503837,-98.465509,'24AJ7P','20231014'],
  [21.38975,-101.87535,'S/D','20231015'],
  [19.39444,-98.52529166666666,'358CZ3','20231017'],
  [19.661265945177,-99.198457686716,'GCXU5138361','20231017'],
  [20.419990,-103.200570,'DFSU6635830//TCU3763871','20231017'],
  [22.01097,-100.83864,'61AY6T','20231018'],
  [19.447827119583977,-98.12799641140442,'47AB8W','20231018'],
  [98.40816,-19.310536,'540ED8','20231018'],
  [20.53209,-100.66292,'62AR5B','20231018'],
  [20.419990,-100.659850,'91AZ4E','20231018'],
  [19.244570,-103.696810,'FFAU2043350/TGHU9544466','20231018'],
  [20.13158,-99.72776,'931FE9','20231018'],
  [25.03693,-100.55785,'89AF1S','20231019'],
  [20.59040,-101.13330,'299FE9','20231019'],
  [19.29386,-98.87666,'379FE8','20231019'],
  [25.89955,-100.21027,'28AL4Y','20231019'],
  [19.131100,-98.273400,'PCIU6062000','20231019'],
  [19.694104,-99.080619,'72AH5P','20231020'],
  [20.6010041,-101.4385340,'62AD7R','20231020'],
  [23.16504,-102.85523,'89AN8V','20231020'],
  [19.83879,-99.94970,'DRYU9688788/ECMU9640937','20231020'],
  [19.407085,-101.570712,'CGMU6919991','20231020'],
  [19.603001,-99.203401,'55AP6K','20231020'],
  [20.81083394850148,-102.80532624058121,'78AY2T','20231021'],
  [20.66966,-101.20895,'68AY3X','20231021'],
  [19.40290,-98.07892,'HASU4229223//GESU6559639','20231022'],
  [19.606117,-99.193446,'61AB1J','20231022'],
  [18.076049,-94.271413,'96AN2J','20231022'],
  [19.49462,-99.34555,'15FA4A','20231023'],
  [25.63495,-99.65525,'33AY1M','20231023'],
  [20.554555710289016,-100.83201403181971,'26AR7N','20231023'],
  [18.05060,-93.91402,'88AZ8T','20231024'],
  [20.25299,-99.84451,'01AP7L','20231024'],
  [19.79190,-99.22806,'91AL2P','20231024'],
  [21.427240,-101.238260,'61AD7R','20231025'],
  [23.46316,-100.60827,'68AE6D','20231026'],
  [18.870758,-97.736907,'49AC1F','20231027'],
  [20.14699,-98.28283,'LF91443','20231027'],
  [18.856016,-97.392082,'MRKU6714230','20231027'],
  [22.50560,-102.38216,'LC94068','20231027'],
  [22.006229,-100.835963,'TCKU1088384/ZCSU6915782','20231028'],
  [20.03441,-99.55735,'89AC7U','20231028'],
  [19.359985,-99.223094,'PBJ3932','20231028'],
  [20.56304,-103.07949,'15BA3W','20231029'],
  [19.14139,-98.26104,'62AJ3F','20231029'],
  [19.95123,-99.61444,'CLHU9096915/MSKU9967548','20231031'],
  [18.972405,-97.797555,'93AN8S','20231031'],
  [25.446100,-100.850990,'72AY5N','20231031'],
  [17.969737,-102.191513,'CMAU7533032/CMAU6207659','20231101'],
  [19.36704,-99.11654,'21BA7U','20231101'],
  [19.65073,-101.24813,'84AX2Y','20231101'],
  [20.002172122503865,-99.4851587288352,'21AH1D','20231102'],
  [18.055420,-93.997310,'14AN6B','20231102'],
  [19.88697,-100.32556,'CUP5294604/SEKU5651164','20231102'],
  [18.90096,-96.98462,'83AJ9M','20231103'],
  [25.74667,-100.51168,'98AH2P','20231103'],
  [19.575270,-99.020040,'CXDU2291170,GAOU6864942','20231103'],
  [23.31172,-98.79899,'GATU4443536,TRLU6509749','20231104'],
  [20.019261,-99.523667,'MRKU7550039','20231104'],
  [18.86672,-969339,'04AL5D','20231105'],
  [18.05700,-94.18217,'08AX3X','20231105'],
  [18.05347,-94.14078,'62AX7D','20231105'],
  [19.551934,-98.494336,'TRHU8425947','20231105'],
  [19.407473333,-103.53036666,'TCNU5804075/TRLU9745115','20231105'],
  [19.29670,-98.88112,'917FE9','20231106'],
  [20.018537,-99.523301,'71AY1E','20231106'],
  [25.149295,-107.962293,'26AP7A','20231106'],
  [19.758950,-99.215050,'CBHU7012941/DFSU7170759','20231106'],
  [20.545347648511655,-100.74403014484513,'416EX2','20231106'],
  [17.72611,-97.35275,'LF42119','20231106'],
  [25.64324,-103.50088,'LF43481','20231107'],
  [19.70194,-98.97261,'MSKU7329035,MSKU7351867','20231107'],
  [19.63746,-99.15781,'TCKU6256177','20231107'],
  [19.31642,-98.73716,'88BA4R','20231108'],
  [19.507111,-97.499628,'14AM1D','20231108'],
  [19.15639,-98.29177,'23AM8J','20231108'],
  [19.21556,-98.35719,'19AY1R','20231108'],
  [19.481168,-101.72892,'TLLU4344689/TGHU6339853','20231108'],
  [19.42650,-99.09192,'779FE5','20231109'],
  [19.556199,-99.185255,'846DF6','20231109'],
  [18.03174,-93.82038,'67AY1M','20231109'],
  [22.264104,-100.86629,'BMOU9609670','20231110'],
  [19.517139,-99.105834,'92AG1P','20231110'],
  [25.59459,-100.8800216,'27AP6K','20231110'],
  [19.903227,-100.798439,'MRSU4734499/MRSU5942624','20231111'],
  [20.5881946,-101.052776,'46AG5T','20231111'],
  [19.87632,-101.14275,'46AG5T','20231111'],
  [20.52559,-100.75042,'06AD2S','20231111'],
  [19.982794,-99.510211,'PCIU9409026','20231112'],
  [19.905504,-100.745575,'TCNU3071554,TCNU5027503','20231112'],
  [21.62375,-100.74222,'20BA5A','20231112'],
  [18.823915,-97.234573,'TCNU3474652/TRHU8148380','20231113'],
  [23.681393,-101.915409,'APHU6825900/TCKU6832354','20231114'],
  [19.976305,-99.533579,'02AX6X','20231114'],
  [21.65743,-101.63050,'096DP1','20231114'],
  [18.221484999999998,-101.89676666666668,'MSMU4888299/TRHU8623480','20231114'],
  [19.99351,-99.47404,'47AJ2S','20231115'],
  [20.5390675,-100.1701191,'46AG5T','20231115'],
  [16.88067,-93.41750,'21AJ1N','20231115'],
  [20.3993,-100.0243,'28AY8H','20231115'],
  [20.3993,-100.0243,'82AY7H','20231116'],
  [19.83785,-99.94768,'TGBU8907483/MRSU6390303','20231116'],
  [19.52557,-97.40915,'MSKU1321124/MRKU5696894','20231116'],
  [19.11284,-98.25312,'49AM5A','20231116'],
  [19.87636,-101.25126,'MSMU7308964/MSMU5721074','20231116'],
  [19.15158,-98.288738,'50BC3L','20231117'],
  [19.83779308,-99.94732101,'TRHU7547494','20231117'],
  [32.49699,-116.89898,'15AZ3R','20231118'],
  [20.471891,-101.130517,'GESU3485790,FANU1369535','20231118'],
  [28.88665,-110.95495,'LF11011','20231119'],
  [21.94345,-101.33844,'18BB9L','20231120'],
  [19.78583,-99.22399,'874AH5','20231120'],
  [26.12982,-99.56693,'89AY1M','20231121'],
  [19.14268,-98.26621,'13AM1D','20231122'],
  [25.70409,-100.59932,'38AY1M','20231122'],
  [19.8378381,-99.9476317,'MSKU6377770/BMOU4911558','20231122'],
  [19.830797,-99.905635,'MRSU5598289/MRSU6141949','20231123'],
  [24.52008,-100.28277,'06AL4N','20231123'], 
  [19.624592,-99.045054,'80AY4M','20231123'],
  [27.24561,-104.93346,'62BC2D','20231124'],
  [19.99619,-99.48939,'GAOU6650811','20231124'],
  [22.818624,-102.491564,'CMAU2342413/DRYU2591420','20231124'],
  [22.84504,-100.48402,'29AW4Y','20231126'], 
  [19.37725,-99.07765,'06AT9E','20231127'],
  [19.08405,-98.18908,'CMCU9016030','20231128'],
  [18.997584,-98.358829,'66AJ6L','20231128'],
  [19.14088,-98.26,'CAIU4789286/MSMU8618624','20231128'],
  [19.89135,-100.99735,'95BA6U','20231129'],
  [19.486395,-99.028727,'33AN6Z','20231130'],
  [19.14368,-98.27493,'947AM9','20231130'],
  [20.27755,-99.89269,'90AJ1C','20231130'],
  [19.441170,-101.882360,'TGBU4090800,TGBU8569684','20231130'],
  [20.125451,-99.348522,'LG00254,01/12/2023','5451120'],
  [20.53766,-100.69496,'CMAU2422859/TLLU2008814','20231201'],
  [25.66620,-100.32520,'LD78270','20231202'],
  [22.56950,-100.63468,'46AG5T','20231202'],
  [19.885005,-99.238703,'89AR8B','20231202'],
  [25.123259,-101.109505,'FCIU7438473','20231203'],
  [19.312308,-98.744745,'54AP8K','20231205'],
  [17.068434,-93.575258,'06AM1B','20231205'],
  [22.00810,-100.83738,'95AD3N','20231205'],
  [20.400781,-100.392075,'68L963','20231206'],
  [20.53741,-103.12407,'65AN5Z','20231207'],
  [20.983742,-101.542045,'28AX8D','20231208'],
  [19.80506,-98.66870,'05AN4V','20231208'],
  [17.92698,-95.00436,'70AM1R','20231208'],
  [21.140105,-86.860572,'UTC349H','20231209'],
  [20.38079,-101.86796,'81AL2P','20231210'],
  [18.0570576,-101.9371417,'MRKU0782156/HASU1547257','20231210'],
  [19.91240,-100.46157,'88AT1U','20231211'],
  [18.78813,-96.55723,'37AZ5Z','20231211'],
  [19.50495,-99.13310,'FXEU232041','20231212'],
  [19.121309,-98.243957,'81AM4B,12/12/2023','0145019'],
  [22.13548,-100.91651,'79AD9H','20231213'],
  [25.75979,-102.97868,'36AY1M','20231213'],
  [20.43631,-101.69504,'68AK1A','20231213'],
  [19.220978,-96.220317,'MSMU1666322/TEMU5520628','20231214'],
  [19.90023,-98.78073,'23AY9M','20231214'],
  [18.82534,-97.31062,'11AY8L','20231215'],
  [21.42232,-101.84057,'64BB9K','20231215'],
  [20.085892,-99.6738,'TRHU6455011/CMAU6543057','15/12/2023'],
  [19.998515,-99.488388,'21AN5W,15/12/2023','2761319'],
  [16.39906,-95.10572,'117DU5','20231216'],
  [19.759214,-99.214561,'85AD8U','20231217'],
  [21.79504,-102.02070,'38AY1M','20231219'],
  [18.55891,-99.21248,'09AY6N','20231219'],
  [20.01661,-99.51871,'A643AG','20231219'],
  [19.432969,-99.173566,'R74TZ,19/12/2023','3256419'],
  [20.00595,-99.502738,'13AS5Z','20231220'],
  [19.58923,-98.52250,'01BA5F,20/12/2023','6940519'],
  [19.30326833333333,-98.76103166666665,'95AB5S,20/12/2023','8162219'],
  [23.486787,-102.143654,'TRHU4132940/CAAU6560697','20231221'],
  [23.73891,-101.84569,'CMAU1830265','20231221'],
  [19.90347,-101.12508,'878EH7','20231221'],
  [19.0208611,-98.2659167,'49AH1A','20231221'],
  [18.9251695,-97.6681355,'TTNU8885005','20231222'],
  [20.562370,-101.137993,'04BB4M','20231222'],
  [20.265794,-99.862206,'MEDU2589546,22/12/2023,20.254950','6870999'],
  [19.33604,-9072714,'69AL1K','20231222'],
  [16.556360,-92.489220,'207EY9','20231223'],
  [22.297708,-100.811333,'94BA8Y','20231223'],
  [20.08899,-99.68387,'59BB4Y','20231224'],
  [19.86911,-99.87105,'LF59960','20231224'],
  [20.44748,-103.55078,'46AX6H','20231225'],
  [20.35983,-99.96760,'39AY3M','20231226'],
  [20.568239,-100.302105,'51AY5Z,26/12/2023','8239520'],
  [20.008658,-101.899766,'TRHU4925754','20231227'],
  [20.25930,-99.85150,'21AX9V','20231227'],
  [20.5324666,-100.6257312,'59AT6K','20231227'],
  [32.547265,-116.955349,'741CE8','20231227'],
  [19.07645,-98.29330,'05AG6K','20231227'],
  [20.58137,-100.36528,'922FE9','20231227'],
  [19.62011,-99.02858,'65AY1M,28/12/2023','7339519'],
  [19.63840,-99.01897,'80BB9D','20231228'],
  [20.065955,-99.602486,'96AH5P','20231228'],
  [19.293208,-98.881638,'24AJ1N','20231228'],
  [19.60715,-103.361768,'MSKU3261003','20231229'],
  [20.625514,-103.28915,'FWRU0202610/FWRU0114843','20231229'],
  [26.50855,-100.00867,'83AX3X','20231230'],

  [21.017713,-86.854361,'73AP7K','20240101'],
  [17.889722,-94.955218,'89AD7H','20240101'],
  [25.81129,-100.27353,'41AS3J','20240102'],
  [19.75865,-99.21506,'07AY5M','20240102'],
  [18.518966,-88.425539,'07AP6K','20240102'],
  [18.61533,-92.19858,'45AS3Y','20240102'],
  [25.446838,-101.06745,'CMAU0787478/CAIU9604488','20240103'],
  [19.82388,-99.22069,'LC47942','20240103'],
  [18.83119,-97.50732,'45AN5Z','20240103'],
  [17.958337,-93.419015,'WMJ474B','20240103'],
  [20.90403,-100.40517,'32AZ5Z','20240103'],
  [20.85162,-100.43194,'20BA5A','20240104'],
  [20.906319,-100.406738,'A69765A','20240104'],
  [19.94028,-101.62540,'31BA7R','20240104'],
  [27.08218,-104.83937,'CGMU5559740','20240104'],
  [20.37611,-103.57652,'39AU9R','20240104'],
  [21.11955,-102.45588,'62AE5U','20240104'],
  [22.395052,-101.353077,'38AP7K','20240104'],
  [20.07378,-99.36426,'208EY6','20240104'],
  [19.94050,-101.62586,'21AH1M','20240104'],
  [20.080714,-99.641392,'SUDU8829471/TRHU4647648','20240104'],
  [20.510707,-89.996368,'MSDU7464050','20240105'],
  [18.94431,-102.08572,'SEGU4953988,CAIU9609108','20240105'],
  [20.29082,-99.92163,'CAAU6284475//TCNU1867352','20240105'],
  [19.88154,-101.20041,'MSKU4144681/SUDU7773228','20240105'],
  [19.991928,-99.467799,'TRHU4154913/TCNU717436','20240105'],
  [20.56203,-100.53075,'78AY2T','20240105'],
  [32.02052,-113.40194,'18BB4P','20240106'],
  [21.00469,-89.56061,'39AK9R','20240106'],
  [19.577103,-98.508998,'02BA6F','20240106'],
  [17.186594,-93.567274,'MSDU5375524/MSMU4720704','20240106'],
  [20.31707,-99.93234,'29AF4L','20240106'],
  [22.00845,-102.35693,'CMAU9250026,SEKU5775490','20240106'],
  [19.67795,-101.232846,'GCXU5674343,CAAU6520502','20240107'],
  [19.931423,-103.529745,'CIPU5056355/MRSU5482494','20240107'],
  [19.19745,-101.91491,'GCXU5674343,CAAU6520502','20240107'],
  [20.3522343,-101.1865725,'81AH1K','20240107'],
  [20.863957,-89.492858,'05AM9A','20240107'],
  [23.1948181,-100.5329592,'14AS8V','20240107'],
  [21.28950,-102.20828,'73AC7B','20240107'],
  [18.86064186114897,-97.38306388590179,'92UG5D/81UD6U','20240107'],
  [16.04993,-95.39768,'902AG2','20240108'],
  [25.85481,-100.02524,'MAXU1392152','20240108'],
  [19.50290,-99.17623,'LF61779','20240108'],
  [19.29028,-99.53819,'55AJ1T','20240108'],
  [20.175000,-102.194100,'FANU1095516/HLBU9635898','20240108'],
  [19.452959,-96.734340,'50AP6K','20240108'],
  [25.45333,-101.07891,'01AY9P','20240108'],
  [25.45333,-101.07891,'16AK5D','20240108'],
  [19.69978,-99.23939,'07AG6K','20240109'],
  [18.35040,-101.89900,'SMNU7695050','20240109'],
  [19.90220,-100.79018,'CAIU8613567,TCLU6733261','20240109'],
  [25.81892,-100.25901,'LF89647','20240109'],
  [19.54472,-99.18294,'372FE8','20240109'],
  [20.5988,-101.1837,'INBU5206689/LCRU3911050','20240109'],
  [20.09541,-99.69066,'74AT7A','20240109'],
  [19.45509,-99.22010,'278FE9','20240109'],
  [20.27886,-99.89097,'41AL2N','20240109'],
  [21.67125426767944,-97.85981926630535,'77AV5G','20240109'],
  [20.02242,-99.45975,'52BC2B','20240109'],
  [19.13248,-104.33332,'01AN5Z','20240109'],
  [19.608868109158013,-99.2256815107694,'MEDU4241267,CAIU4817080','20240110'],
  [21.69709,-97.80837,'77A57X','20240110'],
  [19.941941666666665,-101.62791833333334,'MRKU5907810/TLLU5922058','20240110'],
  [18.752347795489825,-96.42279841137702,'TCKU7332991','20240110'],
  [18.5118663,-101.9748537,'ZCSU8913388/ZCSU7117447','20240110'],
  [19.732454,-98.616814,'96AP1L','20240110'],
  [19.35458,-103.56548,'APZU3430977/APZU3941431','20240110'],
  [20.23346,-99.81995,'601AL6','20240111'],
  [20.035664,-99.177798,'TGHU0844396/MSDU1431337','20240111'],
  [19.98567,-99.44199,'51AB2C','20240111'],
  [20.70763,-102.95671,'CMAU4207080','20240111'],
  [19.20455,-101.90774,'APZU4639010/CMAU8050445','20240111'],
  [18.83043,-97.5060,'04AX9D','20240111'],
  [22.72335,-100.49693,'10AY4Z','20240111'],
  [19.574924,-98.513,'96AG2N','20240111'],
  [25.79165,-100.46219,'94AT4F','20240111'],
  [19.900664,-100.400778,'TCNU4610619,PCIU8671685','20240111'],
  [19.981252,-99.439837,'HMCU1105813','20240111'],
  [19.411140,-103.525280,'EGHU3918796/EITU0108350','20240111'],
  [25.0267320,-100.5506977,'79AG7T','20240111'],
  [20.57208,-103.10170,'46AX6H','20240111'],
  [24.25431,-99.43354,'56AR7N','20240111'],
  [25.0149,-100.5344,'10AU3Z','20240112'],
  [21.644969,-102.276903,'TCKU6091800','20240112'],
  [20.4301098,-103.1931700,'72AB6A','20240112'],
  [20.822468,-100.443638,'TCLU8788535,MRKU5509252','20240112'],
  [19.63037,-97.11998,'39AD1A','20240112'],
  [23.47093,-106.56891,'35AU7J','20240112'],
  [20.40082,-100.39103,'MSKU8734351/SUDU6929430','20240112'],
  [18.05247,-94.21548,'90AM7D','20240112'],
  [18.55222,-101.97071,'SEKU5970177/FFAU4898164','20240114'],
  [18.3141268,-101.9005670,'MRSU5255850/MRKU5072096','20240114'],
  [25.18781,-101.09314,'05AD3U','20240114'],
  [18.94425,-103.90577,'TCKU3935868/CMAU0314881','20240114'],
  [25.509764,-100.868875,'20AK6L','20240114'],
  [20.416804,-103.55667,'CMAU7059812/CMAU7664578','20240114'],
  [21.88893,-100.78580,'82AY7H','20240114'],
  [18.510126,-101.974666,'MRSU3058342,MRSU6062691','20240114'],
  [19.54967,-98.49288,'52AZ8X','20240114'],
  [21.9998,-102.3602,'CMAU7059812/CMAU7664578','20240114'],
  [18.92871,-104.08298,'760VV7','20240114'],
  [19.13928,-98.26780,'NY6294A','20240114'],
  [19.27905,-98.42856,'68AM5N','20240115'],
  [19.89466,-100.73262,'387FD1','20240115'],
  [22.316710,-100.803970,'72AL2C','20240115'],
  [19.30130,-98.40980,'71AY1P','20240115'],
  [19.296531891572116,-98.87072683561969,'23AC1P','20240115'],
  [18.94426871363029,-103.90803954826889,'FBIU0340610//TRHU3958100','20240116'],
  [20.094204,-99.300590,'SEKU5823181/GAOU6026868','20240116'],
  [20.413200,-103.557950,'FANU1881480/FANU3109250','20240116'],
  [23.91624,-106.92810,'23BA7U','20240116'],
  [19.2433,-97.7515,'16AU9A','20240116'],
  [25.403638,-100.799202,'38AY1M','20240116'],
  [19.41053,-103.52608,'FCIU9052134/MAGU5738115','20240117'],
  [19.630661,-97.118506,'HLXU1190566/HLBU2365980','20240117'],
  [24.71461,-101.23089,'LD36191','20240117'],
  [23.5574156,-100.6275240,'24AN7B','20240117'],
  [20.64954,-103.04719,'FCIU9052134/MAGU5738115','20240117'],
  [19.635829,-99.197252,'46AN7H','20240117'],
  [20.58538,-100.36246,'309FE9','20240117'],
  [24.668528,-100.298516,'32AY1M','20240117'],
  [19.83382,-99.92965,'09AD1W','20240117'],
  [20.6746271,-101.0017905,'FXEU939049','20240117'],
  [19.94343,-98.87740,'LC38746','20240117'],
  [22.272804,-100.859755,'29AC1G','20240117'],
  [19.30521,-98.84073,'71BB9K','20240117'],
  [18.99520,-97.86655,'53AB5X','20240117'],
  [20.3901,-103.3037,'ONEU2027835/ONEU2026905','20240118'],
  [17.02390,-99.65286,'NJA6601','20240118'],
  [19.53629,-99.06919,'KN6401','20240118'],
  [19.536919,-99.068877,'CMCU9014628','20240118'],
  [19.28115,-98.43845,'LF89823','20240118'],
  [19.04909,-98.03049,'54AP2J','20240118'],
  [19.301942856064947,-98.40994625529784,'220DJ3','20240118'],
  [19.64550,-99.01517,'43AH9H','20240118'],
  [18.763381666666664,-97.48328500000001,'14AX3X','20240118'],
  [21.80613030863469,-100.72628316442595,'348EG9','20240119'],
  [19.551925,-99.209418,'065EV4','20240119'],
  [19.37041,-99.54364,'381FE8','20240119'],
  [20.590054,-101.134079,'TCLU3238285','20240119'],
  [20.94902,-104.06538,'590ET4','20240119'],
  [22.34524,-100.79857,'63BB4Y','20240119'],
  [20.27885,-99.89102,'74BA7Y','20240119'],
  [17.679159,-95.078384,'TEMU2356681,TLLU2100582','20240119'],
  [20.4355791,-103.5480852,'BEAU5067215/FFAU1529286','20240119'],
  [24.73876,-101.18368,'73AP7X','20240119'],
  [18.99949,-97.88016,'42BA9W','20240119'],
  [19.9985154,-99.48754,'UETU4089410/CMAU3812471','20240119'],
  [18.01733,-94.38508,'45FA2M','20240120'],
  [25.84740,-100.24602,'83AS3D','20240120'],
  [18.839919,-97.521948,'TGCU2101426','20240120'],
  [20.94941,-100.42172,'OOCU4913170/APZU4704497','20240120'],
  [19.48215,-99.16550,'5113ZR','20240120'],
  [17.58810,-98.96817,'28AZ7U','20240120'],
  [18.52881,-91.08659,'06AT9E','20240120'],
  [18.041658,-93.875393,'69AM3B','20240120'],
  [20.125,-99.7238,'GCXU5785455','20240120'],
  [19.63011,-99.78051,'BMOU5157864','20240120'],
  [24.74468,-101.17197,'HLBU1018673,MSDU4089660','20240121'],
  [17.95046,-99.35959,'10AY6N','20240121'],
  [20.45776,-100.09150,'40AR7B','20240121'],
  [20.412937,-103.5577,'SEGU2696160/APZU4709842','20240121'],
  [22.0405546,-100.8555130,'32AN7B','20240121'],
  [18.83000,-97.50546,'33BB4Y','20240121'],
  [18.566357,-101.967166,'GESU6086623/TGBU4933150','20240122'],
  [23.720633,-101.8693,'CXDU2272797/CMAU5738033','20240122'],
  [19.831528,-99.213500,'TCLU2029146/FBIU0349006','20240122'],
  [19.084368,-98.049650,'TEMU2356681/TLLU2100582','20240122'],
  [20.363839,-102.140147,'492DD8','20240122'],
  [18.890527,-99.167017,'98AY3M','20240122'],
  [19.23164,-98.32545,'5073ZR','20240122'],
  [19.33924255204657,-96.49759728476428,'55AY9J','20240122'],
  [18.999021,-98.237236,'JLU4973','20240122'],
  [21.924625,-101.364968,'09AM9A','20240122'],
  [19.99345,-99.47223,'92AZ7Y','20240122'],
  [19.264357,-103.680789,'CMAU3651994/BEAU5954170','20240122'],
  [19.548686,-101.527475,'AMCU9380922','20240122'],
  [20.000274,-99.490668,'CAAU6588354/TRHU7948809','20240122'],
  [31.00667,-110.30797,'69AP7K','20240122'],
  [20.653734,-101.306415,'65BB6P','20240123'],
  [18.92654,-99.19606,'49FA2G','20240123'],
  [19.9969,-99.4805,'11AX3X','20240123'],
  [22.0064,-100.836,'39AG8S','20240123'],
  [31.00667,-110.30797,'69AP7K','20240123'],
  [19.43054,-97.38867,'09AN5J','20240123'],
  [22.552376221378886,-100.65002080371248,'89AN8H','20240123'],
  [32.10436977153617,-113.75269500246877,'87AH3W','20240124'],
  [20.11759,-99.71953,'78BB9D','20240124'],
  [20.03627,-99.56104,'71AY1P','20240124'],
  [21.6939800,-101.0281655,'58BA4T','20240124'],
  [22.77330,-98.32803,'85AC2E','20240124'],
  [19.26141,-103.68197,'16AU4U','20240124'],
  [20.57273,-103.31887,'33AP7X','20240124'],
  [26.57733,-109.15874,'02BC8T','20240124'],
  [26.501995,-100.193139,'62BA5Y','20240125'],
  [25.73225,-100.39548,'LF90958','20240125'],
  [18.00495,-101.96264,'MRSU6149066','20240125'],
  [19.281554,-98.450388,'55AP6K','20240125'],
  [19.339046,-98.207742,'15AM6A','20240125'],
  [20.17970,-102.27006,'33AP7X','20240125'],
  [20.4370071,-103.5422747,'TGBU9474883/UETU5810304','20240125'],
  [19.62926,-103.46450,'CMAU2036774','20240125'],
  [22.00691,-100.83613,'35AX4X','20240125'],
  [19.9353333,-103.5299625,'TGCU0011452/TRHU8216227','20240125'],
  [23.399709,-102.235438,'GLDU3383727/TGHU3231000','20240125'],
  [27.27716,-99.61136,'27985B','20240125'],
  [20.004784,-99.502296,'2148PH','20240125'],
  [22.79085,-100.48890,'39AY1J','20240125'],
  [27.29564,-99.60150,'91AS9V','20240125'],
  [22.0463905,-100.8573457,'TGHU6764235/BEAU4721975','20240126'],
  [21.81635,-101.01085,'69AR5B','20240126'],
  [19.05552,-98.05583,'75BB9K','20240126'],
  [19.942643,-101.629871,'ONEU5039784/ONEU1185190','20240126'],
  [20.55415,-100.78442,'49FA1G','20240126'],
  [25.82729,-103.59488,'32AM4V','20240126'],
  [18.8304,-97.5058,'733EV8','20240126'],
  [19.643551,-99.035169,'PCD6992','20240126'],
  [19.057988,-98.068712,'12AN3X','20240126'],
  [20.5434393,-100.4802640,'32AN7B','20240126'],
  [19.35026,-99.11556,'LC51577','20240126'],
  [20.3794130,-103.5764940,'BMOU6573022,UACU5584753','20240126'],
  [19.63961,-99.14751,'TEMU8289488,SEKU4317487','20240126'],
  [19.1960786,-101.9160342,'MSDU7423220,TGBU5647280','20240126'],
  [24.39533,-100.38770,'56AR7N','20240126'],
  [17.998843,-93.575881,'984AG2','20240126'],
  [20.36532,-102.83957,'46BB9V','20240126'],
  [20.36532,-102.83957,'55AU3T','20240126'],
  [20.36532,-102.83957,'104AE4','20240126'],
  [19.38623,-98.93790,'38AH3K','20240127'],
  [20.29404,-99.92482,'24AU3K','20240127'],
  [19.343083,-99.061350,'04BA5F','20240127'],
  [20.41465,-103.55729,'TGBU5603208','20240127'],
  [18.618754,-92.245825,'97AP8K','20240127'],
  [18.00613,-97.35574,'42BA9W','20240127'],
  [19.75962,-99.14078,'TCNU6999372/CMAU4991453','20240127'],
  [23.38346,-100.58642,'10AY4Z','20240127'],
  [19.9086675,-100.4553639,'TCLU782351-8/CIPU5119746','20240127'],
  [20.72004,-100.36423,'393DT7','20240127'],
  [23.42150,-100.59678,'21AX1E','20240128'],
  [20.65171,-103.04412,'FCIU5986971/CMAU0629791','20240128'],
  [20.34394,-99.94933,'10AY4Z','20240128'],
  [18.62144,-92.04259,'49AJ9M','20240128'],
  [23.703196,-101.889824,'TRHU3108111','20240128'],
  [23.8428395,-100.5089787,'62AD7R','20240128'],
  [24.12783,-103.30578,'FDCU0250355/TCLU6306700','20240128'],
  [19.9345605,-103.5303382,'24AN6B','20240128'],
  [19.331155,-98.544133,'06AC4R','20240128'],
  [23.80747,-100.56466,'89AU8X','20240128'],
  [19.033055,-97.979370,'67AM3B','20240128'],
  [23.6167908,-100.6065472,'56BA4T','20240129'],
  [23.72033,-101.86969,'CMAU0629791/FCIU5986972','20240129'],
  [24.73916,-101.18145,'39AU9R','20240129'],
  [24.4969221,-100.2939604,'12AY1N','20240129'],
  [17.46617,-93.63466,'74BA9Y','20240129'],
  [20.67256,-103.36625,'LF56512','20240129'],
  [19.350512,-98.666950,'25AR7N','20240129'],
  [18.8661,-97.3754,'29AL2N','20240129'],
  [19.3263498,-99.0868937,'61AD7R','20240129'],
  [21.73502,-102.27667,'TCLU6194534/TEMU3639053','20240129'],
  [20.83047024810029,-103.80918635463591,'094AR5','20240130'],
  [19.3263498,-99.0868937,'61AD7R','20240130'],
  [19.355083,-101.917388,'CMAU704260,CMAU7695373','20240130'],
  [19.07053,-98.13104,'64AN7S','20240130'],
  [18.0096711,-101.9602042,'67BA9T','20240130'],
  [18.5563133,-101.9699089,'81AH1K','20240130'],
  [19.355819,-99.006619,'CMCZ810486','20240130'],
  [19.597507,-99.198954,'297AK3','20240130'],
  [20.571016,-103.101439,'CMAU8431797/CMAU5398587','20240130'],
  [19.196389,-101.916336,'GCXU5586725','20240130'],
  [24.67224,-100.29861,'05BA5R','20240130'],
  [21.99210,-100.82865,'1QAN75','20240130'],
  [21.64649,-102.27822,'SEKU5798448//CMAU7662765','20240130'],
  [20.90759,-100.40636,'73AS9F','20240130'],
  [20.724808,-101.286640,'60BB8P','20240131'],
  [19.637920,-99.177067,'DRYU9107918','20240131'],
  [21.305076,-102.091358,'CSLU2153608,/TEMU0153685','20240131'],
  [21.13979,-99.62008,'5073ZR','20240131'],
  [20.74445,-88.23034,'31AL1K','20240131'],
  [20.4353936,-103.5476732,'30AK7C','20240131'],
  [20.4073890,-103.2298867,'69AL2C','20240131'],
  [22.456530,-102.343840,'CMAU4679430','20240131'],
  [19.57769,-97.27304,'TGBU9354361/TCLU9262801','20240131'],
  [20.0825460,-99.6261689,'08AK1R','20240131'],
  [19.940055,-99.786636,'93AU7F','20240131'],
  [19.6315415,-99.1330612,'81AH1K','20240131'],
  [20.9639350,-101.5212112,'30AK7C','20240131'],
  [19.340795,-97.977229,'OTPU6112230','20240131'],
  [17.781565,-101.473191,'90AY6H','20240131'],
  [19.93499,-99.79563,'93AU7F','20240201'],
  [20.4219145,-103.5554660,'70AL2C','20240201'],
  [16.74635,-93.33859,'CMCU6032850','20240201'],
  [25.7071,-100.1998,'21FA6B','20240201'],
  [22.5685231,-100.6337114,'42AM4D','20240201'],
  [19.02511,-98.31280,'61AL6J','20240201'],
  [20.3753161,-103.5764300,'21AU6W','20240201'],
  [23.2114255,-100.5382144,'13AY1N','20240201'],
  [19.5348156,-101.5458072,'36AN6B','20240201'],
  [21.289637,-102.23543,'MRSU3606404','20240201'],
  [19.22724,-98.37178,'5312ZP','20240201'],
  [19.9854603,-99.4424094,'32AX9R','20240201'],
  [18.328806666666665,-95.81136666666667,'CMCU9018074','20240201'],
  [19.282161,-98.439708,'75AS4V','20240201'],
  [21.3083666,-100.6000120,'77AL2C','20240201'],
  [19.525115,-101.569169,'BHCU5034413/CMAU7005879','20240202'],
  [20.413416,-103.557583,'FSCU5939990','20240202'],
  [18.5737861,-101.9693214,'73BB9H','20240202'],
  [23.1646961,-100.5242850,'28AN7B','20240202'],
  [19.52684,-99.17834,'LF22400','20240202'],
  [18.9270450,-104.0803414,'70AE6Y','20240202'],
  [19.9302106,-103.5291397,'38AH7C','20240202'],
  [19.1241,-98.261,'35BB4Z','20240202'],
  [18.7178386,-102.0177849,'TCNU2034387/TEMU7895650','20240202'],
  [19.97284,-98.523494,'TCKU7501052','20240202'],
  [19.24336,-98.38816,'25AN1D','20240202'],
  [19.08427,-99.22599,'714DC8','20240202'],
  [23.80755,-100.56473,'29BA1W','20240202'],
  [18.00958,-93.69056,'23AC1P','20240203'],
  [24.09144,-100.38262,'19BA7U','20240203'],
  [18.619680,-92.092108,'91AR5Y','20240203'],
  [21.68964,-102.28528,'49AR6B','20240203'],
  [16.84487,-95.01840,'38AG7S','20240203'],
  [17.24268,-93.56123,'68BC1T','20240203'],
  [26.5176148,-100.0044745,'65BC7C','20240203'],
  [20.0827975,-101.1367157,'BSIU2742747,FCIU2934803','20240203'],
  [20.58806,-101.05499,'427EH9','20240203'],
  [19.62355,-103.343147,'CMAU8645166/TCLU5030536','20240203'],
  [22.3453211,-100.7963087,'30AN7B','20240203'],
  [19.24263,-98.85880,'824EV8','20240203'],
  [19.4074165,-103.5293400,'SUDU5576533/MSKU1667924','20240203'],
  [20.472063,-100.107205,'62AR5H','20240203'],
  [19.347583,-99.676278,'10AY4Z','20240203'],
  [19.43421,-101.81367,'MSKU4064483/MRKU6514356','20240203'],
  [25.17483,-100.72903,'75AS9F','20240204'],
  [20.900072,-87.433941,'80AD9H','20240204'],
  [19.539781,-101.548660,'MSKU0088569/TEMU5761081','20240204'],
  [20.9341081,-102.6593927,'WBPU7086741/FCIU7336213','20240204'],
  [20.9651421,-101.5205109,'HLBU8065191/HLBU1585682','20240204'],
  [21.2893490,-102.2363369,'HLXU8328929/HLXU8511360','20240204'],
  [25.03072,-100.55591,'51AZ8X','20240204'],
  [18.78176,-102.01451,'MRKU3331114,MSKU0117522','20240204'],
  [23.7150,-101.8759,'SEKU4328584/CMAU9451020','20240205'],
  [21.4572000,-101.2008647,'58BA4T','20240205'],
  [17.69086,-93.90815,'246EY5','20240205'],
  [20.0807370,-99.6412210,'30AN7B','20240206'],
  [23.981948,-100.425246,'97AZ4R','20240206'],
  [19.8813645,-101.1465204,'DRYU9065427/FANU3146870','20240206'],
  [20.3760651,-103.5764817,'36AG4N','20240206'],
  [21.758629,-100.988636,'APZU4508299','20240206'],
  [21.930873,-100.847481,'APZU4508299','20240206'],
  [19.1741,-98.3074,'CIPO508264/565044','20240206'],
  [20.8140246,-100.4490952,'12AY1N','20240206'],
  [23.386243152538857,-100.58633410797323,'71AR1B','20240207'],
  [19.3568426,-101.9188815,'86AN6B','20240207'],
  [20.5550030,-100.2196899,'77AL2C','20240207'],
  [20.4126350,-103.5578244,'192EX5','20240207'],
  [20.018660,-99.525318,'18AS3J','20240207'],
  [19.5047696,-101.6557400,'72BB9H','20240207'],
  [26.93764,-99.79139,'25BB6Y','20240207'],
  [17.349231438096478,-93.58643618172891,'06AM1B','20240207'],
  [20.88902,-89.69947,'21AX9V','20240207'],
  [18.236602,-97.299135,'41AE3X','20240208'],
  [19.923500,-99.843870,'74AS9F','20240208'],
  [20.9652771,-101.5205305,'53AD7R','20240208'],
  [20.8262038,-102.7941654,'194EX5','20240208'],
  [19.1204930,-103.7752400,'27AU6W','20240208'],
  [20.4367793,-103.5428170,'46AM4D','20240208'],
  [19.8307573,-99.9062229,'24AU6W','20240208'],
  [18.24890,-97.30441,'317ET1','20240208'],
  [19.20445,-96.26453,'94UP4W','20240208'],
  [19.5137,-98.4696,'BSIU8077310//TLLU4813371','20240208'],
  [22.09083,-105.21852,'72BB9K','20240208'],
  [17.85543,-91.78624,798,'54AE2P','20240208'],
  [18.53511,-92.63813,'49AX9W/729DT9','20240208'],
  [19.83596,-98.95078,'72AR5B','20240208'],
  [19.50787141763959,-97.33524403292566,'CAAU6274482','20240209'],
  [27.2958096,-99.6015047,'60AD7R','20240209'],
  [18.345414,-101.9001057,'38BD6E','20240209'],
  [21.76158,-100.69399,'98BA8Y','20240209'],
  [20.49558,-100.13076,'901EV8','20240209'],
  [18.64972,-90.26556,'54AE2P','20240209'],
  [23.16507,-100.52410,'95AZ3H','20240209'],
  [19.29672,-98.88113,'913FE9','20240209'],
  [30.44235,-106.52177,'81BB8P','20240209'],
  [19.26472,-99.60758,'931FE9','20240209'],
  [21.76548,-100.69763,'73AA4J','20240209'],
  [20.416352,-101.728312,'BEAU5736748,/,GCXU5596642/TCKU6894818/MRKU4184527','20240209'],
  [19.118705,-103.77534,'TCLU9590626/CMAU6364452','20240209'],
  [18.09901,-94.38916,'88AT1U','20240210'],
  [28.471935,-105.833939,'07AX9D','20240210'],
  [20.16620,-99.75324,'419FF1','20240210'],
  [24.0923513,-100.3817627,'57BA4T','20240210'],
  [20.5727600,-100.4697684,'NYKU9919939/NYKU9836897','20240210'],
  [18.838,-96.3694,'43AP4N','20240210'],
  [22.0418466,-100.8561362,'UACU5941233/BMOU1430245','20240210'],
  [17.69841,-92.53665,'54AP2J','20240210'],
  [19.7552,-103.1631,'WHSU6708945','20240210'],
  [24.7425,-101.1769,'85UF1G','20240210'],
  [20.501363919957885,-90.00007917765053,'CMCU9013730','20240211'],
  [23.7444,-101.8381,'CMAU0572211/TRHU1940689','20240211'],
  [18.013170,-94.578193,'79AR2M','20240211'],
  [19.57799,-99.76229,'14AYAZ','20240211'],
  [18.515081,-88.369631,'677AK7','20240211'],
  [19.13990,-103.77173,'TCLU964598/CMAU614596','20240211'],
  [18.818074,-97.225310,'48TX1V','20240212'],
  [21.2893531,-102.2364715,'FANU1820309/HLBU2461006','20240212'],
  [20.5842,-100.4861,'BMOU2443761/CAXU3173425','20240212'],
  [26.57996,-104.04957,'773EG4','20240212'],
  [19.9084421,-100.4536694,'81AH1K','20240212'],
  [19.1957216,-101.9159837,'TTNU1191656','20240212'],
  [19.270822,-98.429385,'71AP7K','20240212'],
  [19.3119,-103.6032,'SEKU4442581','20240212'],
  [18.84993,-97.53835,'52BC4U','20240212'],
  [19.908402,-100.453571,'TTNU1191656','20240212'],
  [20.2613118,-87.4039025,'28AN7B','20240212'],
  [21.0111211,-101.4873395,'60AD7R','20240213'],
  [17.46392,-93.63626,'58BC1T','20240213'],
  [19.24578,-98.88219,'388DD6','20240213'],
  [19.692266,-99.079637,'05AM9A','20240213'],
  [22.34491,-100.79717,'91AY6H','20240213'],
  [19.956396,-99.583670,'TLLU7985327,TCNU8613703','20240213'],
  [22.1309571,-100.8239854,'56BA4T','20240213'],
  [19.68537,-99.8119,'88AE9G','20240213'],
  [24.299056,-103.412247,'SUDU6903693,/MRSU5309719','20240213'],
  [19.99346,-99.47216,'92AZ7Y','20240213'],
  [22.20811,-97.86490,'59AA5R','20240213'],
  [18.615664,-92.198948,'06AM9A','20240213'],
  [19.15364,-98.29013,'71AN5Z','20240213'],
  [19.194598,-101.916281,'APHU6778330,FSCU7137294','20240213'],
  [19.983364,-99.440481,'MSMU6101020,BMOU6131674','20240213'],
  [19.983364,-99.440481,'LNR069A','20240213'],
  [19.90305,-100.79634,'LF44814','20240214'],
  [19.1928315,-99.5250865,'22AU6W','20240214'],
  [21.25646,-101.67312,'73AP7X','20240214'],
  [19.8757996,-100.1862547,'90AZ4E','20240214'],
  [19.269180,-103.674247,'CMAU9319204,GESU6784136','20240214'],
  [23.6604475,-100.6069435,'CAIU4027979/MSDU4290377','20240214'],
  [19.995719211410705,-99.8762265865595,'45AN8V','20240214'],
  [20.4461486,-100.0777887,'41AM4D','20240214'],
  [22.215811666666664,-97.94313666666667,'A97226A','20240214'],
  [19.75309,-103.174307,'MRKU4068229','20240214'],
  [21.74583928690562,-100.6869178185307,'911AU2','20240214'],
  [18.30672,-101.90410,'TCNU7336380//TEMU8975050','20240214'],
  [19.52350,-99.20216,'24AT4C','20240215'],
  [19.35116,-98.97168,'71AT9U','20240215'],
  [20.38999,-101.95893,'38AX2X','20240215'],
  [18.29400,-101.90541,'27BD4E/83BB2U','20240215'],
  [19.0920551,-98.1916351,'15AZ9E','20240215'],
  [23.418924,-102.22147,'CMAU7452820/TCNU7135976','20240215'],
  [19.5347801,-101.5457725,'OOCU8274502/CSNU7106669','20240215'],
  [20.635236,-101.156730,'49AG8S','20240215'],
  [19.8073366,-101.2813329,'64BA9T','20240215'],
  [19.302320,-99.622707,'18AN6D','20240215'],
  [19.95558,-99.05131,'58BC1T','20240215'],
  [19.61252,-97.03352,'TXGU5396905/GESU5517705','20240216'],
  [18.34626,-101.90007,'MRSU4576412/MRSU6398599','20240216'],
  [20.32868,-102.26406,'48AM5A','20240216'],
  [18.3512571,-101.8987660,'43AM4D/24AU6W','20240216'],
  [19.966878,-99.013421,'TXGU5396905/GESU5517705','20240216'],
  [20.08553,-99.67081,'283FE9','20240216'],
  [19.5061801,-101.6616834,'38AK3S','20240216'],
  [18.77634,-102.01412,'84BB2U','20240216'],
  [25.6693,-100.2837,'92AZ7Y','20240216'],
  [19.99511,-99.47836,'MSMU8283372/MEDU8589420','20240216'],
  [22.8553371,-100.4824864,'41AM4D','20240216'],
  [19.451808,-98.927274,'53AJ9M','20240216'],
  [25.6951,-100.571,'TRHU2251489/TCLU7430980','20240216'],
  [19.528655,-99.223171,'CAIU7420199,FANU3440308','20240216'],
  [19.71794,-99.20824,'79AG7T','20240217'],
  [25.709646,-100.54966,'CAIU7340597/CMAU9590556','20240217'],
  [23.727127,-101.86159,'FCIU9094361/CMAU7683691','20240217'],
  [20.275822,-102.39326,'APZU3370116/BMOU1279210','20240217'],
  [23.7502000,-101.8300000,'TRLU9131668/CMAU0329156','20240217'],
  [22.06488,-100.86841,'85BC9U','20240217'],
  [24.745251,-101.171519,'CMAU9590556','20240218'],
  [20.5085871,-87.7192685,'32AN7B','20240218'],
  [18.816638,-97.415578,'60AP7K','20240218'],
  [18.816971,-97.413910,'28AY1M','20240218'],
  [19.65700,-99.14418,'15BA7U','20240219'],
  [22.863711,-100.48095,'663EW3','20240219'],
  [19.321955,-98.725243,'05BA5F','20240219'],
  [20.698992,-87.144051,'31AL1K','20240219'],
  [19.463319,-97.576291,'98AY3M','20240219'],
  [19.939575,-99.788208,'FCIU9057922,FFAU3994986','20240219'],
  [25.82743,-103.59467,'18AY3S','20240219'],
  [19.6124,-97.0325,'CAXU9072366/MEDU4352018','20240220'],
  [20.0107,-99.8781,'99AZ2W','20240220'],
  [20.39651,-101.99632,'69AY1M','20240220'],
  [21.2896941,-102.2348217,'45AM4D','20240220'],
  [19.87909,-103.51985,'SEGU4628186//GESU6897636','20240220'],
  [20.4361400,-103.5419389,'73AE6Y','20240220'],
  [21.2896941,-102.2348217,'70AL2C','20240220'],
  [19.32404,-99.62167,'11AS5U','20240220'],
  [19.194772,-101.91588,'CMAU8475202/TCNU4619956','20240220'],
  [19.8368950,-99.9430497,'39AK7C','20240220'],
  [18.90723,-98.42990,'LF73474','20240220'],
  [20.72717,-88.57529,'90AM8A','20240220'],
  [19.9021863,-100.7922675,'38AK3S','20240220'],
  [19.18579,-101.92727,'89BA4H','20240221'],
  [20.95479,-101.43978,'85AU2B','20240221'],
  [21.1188541,-102.4566837,'11AY1N','20240221'],
  [20.33997,-102.206182,'TCNU2937840/CMAU5673924','20240221'],
  [19.998925,-99.491502,'TCKU6256840/TGHU6050500','20240221'],
  [20.535162,-100.711703,'CMAU7684018/CRSU9368849','20240221'],
  [19.85479,-99.29106,'70AS2J','20240221'],
  [20.25458,-99.84577,'73AS8G','20240221'],
  [19.9240230,-99.8471730,'74AL2C','20240221'],
  [19.44236,-101.88411,'TCLU1883042/TLLU7820311','20240221'],
  [21.23846,-100.58300,'28AS9V','20240221'],
  [19.50005,-98.38979,'MSMU4294867','20240221'],
  [18.02989,-93.80998,'TCLU6684552/TCLU8958077','20240221'],
  [18.79754,-97.76104,'50BB9X','20240221'],
  [19.7118780,-101.3357010,'67BA9T','20240221'],
  [19.62815,-99.16469,'17BA7U','20240222'],
  [20.57042,-103.10150,'01AD1W','20240222'],
  [19.31980,-98.72577,'001DD4','20240222'],
  [21.62021,-98.987718,'TCLU1337740//CGMU5316611','20240222'],
  [23.07340,-99.70348,'38AW1Y','20240222'],
  [21.33008,-101.99313,'GLDU5736016','20240222'],
  [20.4364708,-103.5432665,'58BA4T','20240222'],
  [19.9230273,-99.8379155,'69AL2C','20240222'],
  [22.8795410,-100.4808140,'62AD7R','20240222'],
  [22.7508766,-102.4933470,'28AH4G','20240223'],
  [21.850358,-102.264565,'TRHU3868243/TRHU1936077','20240223'],
  [18.56649,-101.96716,'TCKU7611023//TCLU7854458','20240223'],
  [19.18773,-98.32336,'21AJ7P','20240223'],
  [24.74268,-101.17647,'S/D','20240224'],
  [19.47179,-98.33808,'MAGU5699894/TCNU2062563','20240224'],
  [19.5509249,-98.4944084,'TLLU5102320/TCNU4650627','20240224'],
  [19.9413001,-101.6270247,'22AN6B','20240224'],
  [23.72033,-101.86963,'TRHU1936077//TRHU3868243','20240224'],
  [19.25579,-98.40157,'19AX7D','20240224'],
  [19.9082640,-100.4543775,'30AK7C','20240224'],
  [18.73599,-91.62435,'54AE2P','20240225'],
  [17.98633,-93.19359,'05AM9A','20240225'],
  [20.5418225,-101.1381512,'30AN7B','20240225'],
  [18.55237,-101.97087,'ONEU1026109','20240225'],
  [25.241535,-101.09147,'APZU2110726/FCIU5676600','20240225'],
  [18.75884,-102.01144,'CMAU6690340/SEGU5348894','20240225'],
  [19.5051325,-101.6576017,'67BA9T','20240225'],
  [18.992698,-91.185412,'56AE2P','20240225'],
  [22.07293,-101.17330,'73AS6Z','20240226'],
  [20.65418,-101.28984,'590ET4','20240226'],
  [19.8816530,-101.2001832,'31AK7C','20240226'],
  [19.32032,-98.51343,'21AG8K','20240226'],
  [20.50556,-100.14347,'371FE8','20240226'],
  [18.850252,-97.537570,'MEDU4407342/MEDU7838507','20240226'],
  [19.476333,-98.309338,'CAIU9533466','20240226'],
  [18.496895,-88.307644,'93AA6F','20240226'],
  [19.655455,-99.144107,'058EV4','20240226'],
  [17.89678,-94.13551,'43BA9W','20240227'],
  [19.6339425,-90.6719947,'64BB9H','20240227'],
  [19.318188,-98.812638,'02AS6A','20240227'],
  [22.5688299,-100.6355937,'90AH6A','20240227'],
  [20.000308,-99.488981,'24AX7W','20240227'],
  [19.00884,-99.173363,'91AT4B','20240227'],
  [19.301416,-98.409850,'GESU6930740/TGHU6378000','20240227'],
  [19.875886,-100.191202,'MRSU6298481/MRSU5027150','20240227'],
  [19.563441,-98.501185,'CMAU4689720','20240227'],
  [18.717633,-102.017662,'TCNU5154920/TCNU4703922','20240227'],
  [25.754628,-100.500570,'09UA3Z','20240227'],
  [20.82443,-102.79516,'85BB2U','20240227'],
  [19.298507,-98.875988,'53AJ9M','20240227'],
  [15.71728,-96.60247,'58AE2P','20240228'],
  [19.87980,-100.29549,'54AU3T','20240228'],
  [18.52758,-99.19908,'613DD5','20240228'],
  [17.59306,-93.72515,'32AZ4R','20240228'],
  [20.37253,-103.57619,'CMAU7615043/BEAU4658189','20240228'],
  [22.7361821,-100.4955770,'67BB9H','20240228'],
  [25.688773,-100.303735,'81AK2X','20240228'],
  [24.74973,-101.16535,'TCNU3455713/TCLU4955110','20240228'],
  [19.905961,-100.570089,'MRKU0648065','20240228'],
  [20.821609,-102.797213,'CMAU8602462','20240228'],
  [19.62998,-99.00458,'62AZ1Y','20240228'],
  [17.95142,-95.10037,'MRKU2330453,MSKU1928801','20240228'],
  [19.58554,-98.54288,'MSKU9680028,TCNU8111388','20240228'],
  [19.356546,-101.918464,'FFAU5389599','20240228'],
  [18.558868,-101.969183,'CMAU4603584/APHU6920316','20240228'],
  [17.85765248989468,-94.10577404329875,'35BD4E','20240228'],
  [24.70794,-100.30210,'45AJ7J','20240228'],
  [17.46347,-93.63637,'22FA3G','20240229'],
  [19.69585,-99.20455,'909FE9','20240229'],
  [19.3063,-97.79777,'KN6401','20240229'],
  [20.53545,-100.68383,'10AV7E','20240229'],
  [27.1157183,-109.4611516,'24AX7W','20240229'],
  [21.7277,-102.2765,'TCKU3395712/MSKU3530545','20240229'],
  [19.12933,-98.26622,'99AG3X','20240229'],
  [20.6990586,-88.1354865,'64BB9H','20240229'],
  [22.862106,-102.62456,'SEGU6218473','20240229'],
  [18.57699,-90.60571,'01FA3J','20240229'],
  [19.740290,-99.212761,'MSKU6544563','20240229'],
  [19.071609,-98.134046,'LG04156','20240229'],
  [19.05537,-98.05727,'51AZ8X','20240229'],
  [19.120036,-103.775223,'CMAU3533100','20240229'],
  [23.708390,-101.883870,'SEGU6218473','20240229'],
  [19.19727,-101.91476,'AXIU1959866,/MRKU0809679','20240229'],
  [19.753046,-98.589212,'CMAU3483843','20240229'],
  [22.948296,-102.56147,'93AZ7N','20240229'],
  [19.86488,-101.26229,'MRSU6562803/MRSU5790671','20240229'],
  [19.28363,-99.52229,'76UU4U','20240229'],
  [21.1192051,-102.4566694,'FFAU2871009/TRHU7028797','20240301'],
  [20.58769,-103.11545,'SEKU6278029/CMAU3579441','20240301'],
  [19.893037,-100.658161,'CAIU8624200,BMOU4351509','20240301'],
  [26.3386013,-100.0719982,'09AK1R','20240301'],
  [20.725369,-101.286952,'F96AHR','20240301'],
  [21.8358,-102.289,'CMAU3579441//SEKU6278029','20240301'],
  [19.893424,-100.603444,'86BA2M','20240301'],
  [20.81888,-87.87031,'39AK9R','20240301'],
  [19.49657,-99.19123,'LG11659','20240301'],
  [19.878164,-100.220389,'MEKU0848314/MRKU0775882','20240301'],
  [18.075556,-101.926032,'TCNU3131289','20240301'],
  [20.371899,-97.150013,'CGMU5310167/CGMU5369782','20240301'],
  [19.878164,-100.220389,'MEKU0848314/MRKU0775882','20240301'],
  [19.878164,-100.220389,'MEKU0848314/MRKU0775882','20240301'],
  [18.001153190112916,-94.59229487116421,'1566XC/5117XB','20240301'],
  [19.92328,-99.842824,'MRKU8756390/MSKU0543003','20240301'],
  [19.878164,-100.220389,'MRKU8756390/MSKU0543003','20240301'],
  [19.151521,-101.979781,'FBLU0107080','20240301'],
  [21.1221945,-100.5496182,'75AL2C','20240301'],
  [29.533348,-101.55176,'MSKU0676330/MRSU5218286','20240301'],
  [20.63014,-89.93071,'38AY1M','20240302'],
  [19.245384,-98.864376,'01BA6F','20240302'],
  [16.894909,-95.011555,'58AE2P','20240302'],
  [20.599270,-101.185217,'CMAU6286030/CMAU4327505','20240302'],
  [19.50025,-101.69540,'TCLU1611297//TCLU1611297','20240302'],
  [19.88485,-100.26527,'ONEU1324510,TCLU5594095','20240302'],
  [27.1165326,-99.6962159,'21AN6B','20240303'],
  [23.67933,-101.91773,'TCNU6507256//TCKU6231142','20240303'],
  [22.0418566,-100.8559304,'62AD7R','20240303'],
  [18.8508863,-97.5392390,'32AN7B','20240303'],
  [20.1319675,-90.1677537,'23AN6B','20240303'],
  [19.2434236,-98.3882184,'14AY1N','20240303'],
  [20.90032,-87.43383,'73AM3B','20240303'],
  [19.17506,-98.30697,'32AE9L','20240304'],
  [18.617488,-98.751632,'47UL5G','20240304'],
  [23.7295331178466,-101.8588839548488,'FCIU2576628,MSCU6229120','20240304'],
  [20.541896,-101.137819,'MRKU2809472/MRKU4385619','20240304'],
  [19.63167,-99.05022,'914FE9','20240305'],
  [21.1023866,-101.7838080,'37AN6B','20240305'],
  [20.95472,-100.42318,'89AF1S','20240305'],
  [19.8818,-101.1999,'MRKU0510564/BEAU6345162','20240305'],
  [18.13158,-94.43640,'371FE8','20240305'],
  [25.45911,-101.14025,'01AY9P','20240305'],
  [19.269354,-98.427418,'76AD9H','20240305'],
  [19.120317,-103.77527,'TLLU4645133/TLLU7625932','20240306'],
  [19.50045,-101.86920,'224AF2','20240306'],
  [19.54318,-99.27764,'56BBP7V','20240306'],
  [19.310066,-98.745404,'76AM5A','20240306'],
  [25.5098878,-100.8685870,'24AN6B','20240306'],
  [25.3853558,-100.7962184,'58BA4T','20240306'],
  [24.760421,-101.156691,'CAIU8959950/SEKU5487826','20240307'],
  [22.846763333333335,-102.56300000000002,'TCNU5603384,TCLU9563488','20240307'],
  [17.46266,-93.63644,'88AN8H','20240307'],
  [19.913408,-99.851970,'605AJ6','20240307'],
  [23.503746,-98.882285,'27AP6K','20240307'],
  [24.888581,-99.671112,'SEKU9265323/TEMU9880622','20240307'],
  [19.45936,-99.22262,'372FE8','20240307'],
  [19.888931,-100.700901,'MEKU34800416/MEKU4559238','20240307'],
  [21.76673,-100.69969,'77AT8E','20240307'],
  [21.3944340,-101.2500840,'57BA4T','20240307'],
  [18.85422,-97.35670,'288EU1','20240307'],
  [20.27839,-99.89146,'72AS7T','20240307'],
  [24.65029,-100.29106,'WHSU2951495/WHSU2865273','20240307'],
  [19.441634084915247,-101.88126919082703,'SUDU1435630','20240308'],
  [19.6230345,-99.1621639,'605AJ6','20240308'],
  [22.153196,-98.17079,'82AV4G','20240308'],
  [19.54901,-99.18362,'96TX6F','20240308'],
  [19.316024,-98.737867,'49AP6K','20240308'],
  [20.51307,-100.60150,'72AS7T','20240308'],
  [19.30607,-97.79325,'75AN3X','20240308'],
  [18.62731,-96.24493,'40AK4X','20240308'],
  [17.42273,-99.46590,'21AX9V','20240309'],
  [25.79340,-100.46064,'32AR9U','20240309'],
  [25.82090,-100.10473,'11AM9G','20240309'],
  [18.79858,-96.59869,'41AY3M','20240310'],
  [19.876,-101.1422,'MRKU4156952,MRKU3910230','20240310'],
  [19.21213,-103.73969,'FSCU8381617/SEKU6262613','20240310'],
  [20.77847,-102.76078,'CMAU6405340/TCKU6356535','20240310'],
  [24.74828,-101.16683,'41UG9N','20240310'],
  [19.63582,-96.99302,'GCXU5852907/CMAU7626238','20240310'],
  [19.68242,-99.20200,'85AN9W','20240310'],
  [18.78010,-102.01383,'FYCU7015920/MEDU4943690/MEDU7793629','20240310'],
  [20.10176,-99.69833,'62AZ1Y','20240310'],
  [19.934528,-99.795969,'CMAU9563099/CMAU7663761','20240310'],
  [25.44678,-101.06712,'CMAU4399249','20240311'],
  [19.19543,-101.91632,'MRKU2000554/HASU4862632','20240311'],
  [19.3567750,-101.9187794,'72AY2C','20240311'],
  [20.91651,-89.69111,'72AS3E','20240311'],
  [19.692367,-99.080048,'10AK1R','20240311'],
  [19.43950,-99.09972,'93AS3A','20240311'],
  [19.54541,-101.53585,'MAGU2405403','20240311'],
  [19.831289,-99.907481,'MRKU4961294/MRKU5524853','20240311'],
  [19.130362,-98.267429,'24AJ1N','20240311'],
  [19.418203,-99.002544,'045EV4','20240311'],
  [24.74241,-101.17691,'CMAU4399249','20240311'],
  [20.485633333333332,-97.80480000000001,'J371843','20240311'],
  [20.3220911,-102.5112245,'22AU6W','20240311'],
  [19.30181,-103.63060,'SEGU4961284/TCNU3370431','20240312'],
  [24.7471,-101.1685,'TCLU9819116/BMOU6989431','20240312'],
  [23.404958,-100.591672,'27BC5A','20240312'],
  [21.565778,-104.958753,'91AY8L','20240312'],
  [26.509989,-100.008410,'15AN6B','20240312'],
  [21.762492,-100.696966,'12AN3X','20240312'],
  [22.956586,-102.555800,'TGBU8707356-TRHU5872896','20240313'],
  [18.839613,-96.748812,'CMCU6020720','20240313'],
  [19.684726,-101.329182,'TGHU3121560,MRSU5883516','20240313'],
  [19.35673,-101.91888,'TRHU6923827//MAGU5456298','20240313'],
  [19.6324270,-103.4663102,'TLLU5260753/FANU1202002','20240313'],
  [21.4908403,-101.7614370,'54AC2U','20240313'],
  [20.82178993,-100.43381339,'98AR8H','20240313'],
  [24.742891,-101.174995,'CMAU7490326/CMAU8595105','20240314'],
  [22.74722,-102.38984,'CMAU7490326/CMAU8595105','20240314'],
  [19.63603,-99.05189,'63BB4Y','20240314'],
  [25.4616873,-100.8377334,'32AN7B','20240314'],
  [24.19419,-100.37907,'620EZ5','20240314'],
  [19.27491,-98.43829,'LF85182','20240314'],
  [21.444947,-102.248030,'344AU5','20240314'],
  [21.9002648,-101.3988912,'59AM7N','20240314'],
  [18.781533763940132,-96.46041708979291,'91AT4B','20240314'],
  [20.06555,-99.21715,'39AJ3J','20240314'],
  [18.87859,-96.95418,'21AF5N','20240314'],
  [22.14559,-100.90775,'57AM9T','20240314'],
  [22.13183786017159,-100.82401619312593,'64UR3M','20240314'],
  [19.84205,-99.27797,'MSCU7703172/MSDU7040833','20240314'],
  [22.86220,-102.62460,'DFSU2248617,TCKU1549001','20240315'],
  [19.42412,-101.92261,'WHLU4304023/WHLU4319846','20240315'],
  [24.11288,-100.37974,'44BA5F','20240315'],
  [23.80652,-100.564979,'MNBU3021736/MNBU3785550','20240315'],
  [21.49269,-100.72613,'LUN004A','20240315'],
  [18.00503,-101.96269,'MSDU5150723/TCNU3290193','20240315'],
  [23.7276,-101.8611,'TEMU2865837/APHU6743247','20240315'],
  [19.710751,-98.964960,'24AU3K','20240315'],
  [19.195401666666665,-101.91611666666668,'MRKU4863683/MSKU9633643','20240315'],
  [23.721943,-98.997148,'MNBU9172030','20240315'],
  [20.95748,-104.08178,'53AJ8Z','20240315'],
  [19.410534,-103.526089,'TGHU5235833//TGHU5236911','20240315'],
  [19.892685,-103.520857,'SEGU1999769','20240315'],
  [19.69355,-99.20356,'371FE8','20240315'],
  [16.92317,-93.45367,'23FA3G','20240316'],
  [19.410627,-103.52603,'GESU6932824//APHU7281457','20240316'],
  [25.78334,-100.30326,'15BD1D','20240316'],
  [18.42031,-101.90266,'33AX8E','20240316'],
  [19.91202666666667,-99.852495,'FFAU4836922,FFAU5312200','20240316'],
  [19.13299,-99.48596,'24AS9V','20240316'],
  [32.50456,-116.96547,'47AK4P','20240316'],
  [20.881997,-100.416337,'15AN6B','20240316'],
  [21.80097,-100.71615,'90AX4P','20240316'],
  [18.630161,-96.248926,'CMCU6026395/CMCU6032850','20240316'],
  [20.40312,-100.02935,'92AN9J','20240317'],
  [22.42603,-100.31815,'06AH2L','20240317'],
  [21.46102,-100.71485,'14AG9S','20240317'],
  [19.87930,-100.13823,'MRKU1091400,/,MRKU0240094/MRSU5590251,MRSU4952021','20240317'],
  [17.60626,-95.08221,'51AM5A','20240317'],
  [18.01276,-94.41992,'64AN7S','20240317'],
  [25.75727,-108.97371,'JV98579','20240317'],
  [18.24161,-91.37152,'36AJ3W','20240317'],
  [19.69433,-99.08075,'22BA7U','20240318'],
  [20.0812743,-99.6472942,'28AU6W','20240318'],
  [20.0822111,-101.1367177,'67BA9T','20240318'],
  [19.63296,-97.02782,'LD34486','20240318'],
  [19.35130,-99.58840,'18AU7X','20240318'],
  [18.29342666666667,-101.90483333333334,'MRSU3669819','20240318'],
  [25.6219640,-100.8245782,'15AN6B','20240319'],
  [25.569717,-100.959930,'799DX4','20240319'],
  [19.55973,-98.49901,'S/D','20240319'],
  [20.4130071,-103.557757,'24AN6B','20240319'],
  [18.83731,-96.73536,'21AX7D','20240319'],
  [22.0083426,-100.8371179,'63BB9H','20240319'],
  [19.65063,-99.19656,'CHSU8035571/GAOU6868103','20240319'],
  [19.90832,-100.45523,'06AY4Z','20240319'],
  [18.35313,-101.89826,'91AY3C','20240320'],
  [22.1318602,-100.8248093,'48AM9N','20240320'],
  [19.875923,-100.177142,'CMAU6308546/CMAU3485276','20240320'],
  [19.06786,-98.12106,'LF76355','20240320'],
  [19.27124,-98.42523,'55BB7V','20240320'],
  [19.429604,-98.991811,'57AP8K','20240320'],
  [15.13607,-92.44809,'HAMU1481409/HAMU1481374','20240320'],
  [25.695743,-100.575588,'46BA5F','20240320'],
  [18.75307,-102.00787,'MSKU8629417','20240320'],
  [24.256383,-99.434866,'22AT7E','20240320'],
  [19.21301782217281,-103.73988854858536,'858AE6','20240320'],
  [20.275783,-103.550069,'CGMU5110195/CGMU5032385','20240321'],
  [25.2854,-101.0851,'206VV4','20240321'],
  [20.2906171,-99.9214944,'95AZ4R','20240321'],
  [19.874210,-101.228372,'MNBU3880222-MMAU1185201','20240321'],
  [19.4099,-99.6136,'85AS9V','20240321'],
  [19.560108,-98.762936,'16UN5D','20240321'],
  [26.5018436,-100.0120880,'10AK1R','20240321'],
  [19.78385,-101.16022,'MSKU5732355/SUDU7510366','20240321'],
  [22.410074,-97.906231,'NKCR66364','20240321'],
  [24.25095,-99.43158,'TCLU1217806','20240321'],
  [19.942643,-101.629931,'UACU5585209-BEAU4193271','20240321'],
  [18.496460,-97.454257,'30AP6K','20240321'],
  [22.06750,-100.89032,'48AT5C','20240321'],
  [17.06358,-96.70506,'23AH8S','20240321'],
  [20.54213,-100.74737,'586DC2','20240321'],
  [19.50601,-101.661591,'CGMU9392414/OTPU6341788','20240321'],
  [17.27523328371419,-96.915234933806,'16US1S','20240321'],
  [19.68750,-98.98026,'29AB2U','20240322'],
  [20.03421,-99.55554,'26AR9R','20240322'],
  [20.34967833333333,-103.5958166666666,'TCLU9645457/CMAU6251780','20240322'],
  [18.02877,-101.95227,'MRKU6655251,MRSU5245276','20240322'],
  [27.365388,-99.563882,'16AX7D','20240322'],
  [18.985297,-99.211145,'26AP6K','20240322'],
  [19.740486,-99.212061,'SEGU9429715,/,TEMU9225770/BMOU9615409/CGMU5354490','20240322'],
  [19.062720869400312,-98.09451237712629,'57BB3D','20240322'],
  [20.576541,-100.417636,'SEGU9180598','20240322'],
  [19.043744,-98.013941,'98AN9T','20240322'],
  [19.25426,-98.39862,'43UN5X','20240322'],
  [21.53283,-102.24384,'CAIU9327866/CAMU6425537','20240322'],
  [24.74212,-101.17727,'79AT9U','20240323'],
  [19.99662,-99.48923,'MSKU9963584/TCNU2740090','20240323'],
  [19.451469438121705,-104.36582531781089,'73AA4J','20240323'],
  [22.165155,-101.079989,'HASU4003010','20240323'],
  [20.269363,-102.441642,'CMAU7323777/TCLU9132710','20240324'],
  [19.17631,-99.47046,'62AL8F','20240324'],
  [20.12049,-99.72177,'LG07655','20240324'],
  [20.7893588,-100.4238735,'62AD7R','20240324'],
  [19.53556,-101.54340,'MSKU5075939/TLLU2241322','20240324'],
  [25.634355,-103.521406,'NIDU2210500/FCIU6654919','20240325'],
  [16.76751,-93.29556,'LF91443','20240325'],
  [22.00876,-100.83778,'65AS9F','20240325'],
  [19.5766,-99.2081,'76AJ5Z','20240325'],
  [19.505931,-99.170264,'NYKU3679790/TRHU1854430','20240325'],
  [20.434908,-101.70087,'92AU7F','20240325'],
  [24.59306,-100.26651,'46AK4P','20240325'],
  [23.71754,-99.00232,'MRSU5492872/MRSU5033050','20240326'],
  [20.497033,-100.13305,'TCNU1832314/MRSU5825232','20240326'],
  [19.44724,-99.22051,'435DE1','20240326'],
  [19.58666,-99.20392,'922FE9','20240326'],
  [19.261097,-103.68262,'MSKU0669007','20240326'],
  [19.57629,-99.04153,'53BC2B','20240326'],
  [20.40047,-102.73338,'MRKU3607199/MSKU0659035','20240326'],
  [19.616,-99.041,'908FE9','20240326'],
  [18.641169,-101.9672944,'73BD6P','20240326'],
  [21.7041795,-101.0222262,'40AB4B','20240326'],
  [22.39488,-101.35298,'21AX9V','20240326'],
  [20.17048,-99.75718,'729DT9','20240326'],
  [18.5523,-101.9709,'CMAU4569670,CMAU4569670','20240326'],
  [19.91408,-99.36183,'TGHU9764769,CSNU6330360','20240326'],
  [19.05326,-98.05215,'85BB2U','20240326'],
  [28.656313,-106.061566,'NKCR66802','20240326'],
  [19.760963,-99.215889,'TEMU6243087','20240327'],
  [19.95254,-99.40615,'CSNU698284,FFAU3264592','20240327'],
  [20.407405,-103.230263,'MSCU5375454/FFAU2124359','20240327'],
  [19.910666,-100.482262,'WHSU6875583','20240327'],
  [21.8652,-99.5015,'CHIU9043777','20240327'],
  [19.11465,-98.25172,'16AN4D','20240327'],
  [20.329550,-102.244049,'48AM5A','20240327'],
  [19.12413,-98.26130,'136DC9','20240327'],
  [18.944027,-103.921358,'APZU4896363//SEGU1784210','20240327'],
  [19.261308,-103.682016,'TCKU7608159/MRSU3399734','20240327'],
  [21.371708,-101.901955,'14AG9S','20240328'],
  [21.21487,-100.80948,'LG07655','20240328'],
  [19.37712,-99.29593,'47AT8J','20240328'],
  [19.90222333,-100.7926000,'MRKU5392850/TCKU6873899','20240328'],
  [20.373536666,-102.67385,'TCNU2487064/TCNU2883363','20240328'],
  [19.449702,-98.894531,'68AM7A','20240328'],
  [20.90352,-89.63326,'191AR1','20240328'],
  [19.973489,-101.751134,'WHSU6573304','20240328'],
  [19.109578,-97.505227,'63BD6G','20240328'],
  [19.6680782,-99.0674932,'5115ZRCB','20240328'],
  [19.993020,-99.470161,'18AX6E','20240328'],
  [23.6171308,-100.6065542,'67BB9H','20240329'],
  [19.38715,-98.94548,'385FE8','20240329'],
  [19.4115130,-103.5254674,'19AN6J','20240329'],
  [28.58301,-105.95996,'06AT9E','20240330'],
  [18.69983,-91.69729,'910AU2','20240330'],
  [18.871943,-96.350152,'04AM9A','20240330'],
  [18.06226,-94.04077,'45AR7B','20240330'],
  [19.536836,-90.692655,'73AM3B','20240331'],
  [19.90842,-100.45310,'CAAU6668763/MSKU0907487','20240331'],
  [18.02597,-91.66788,'18BC6V','20240331'],
  [20.17518,-102.18914,'TRHU8434002/TRHU6827715','20240331'],
  [20.17476,-102.18684,'MRKU7608871/MRKU0246636','20240331'],
  [18.052162,-94.123501,'04AM9A','20240331'],
  [22.095442,-102.2739,'TRHU6323519/CAAU5775319','20240331'],
  [20.4130736,-103.5576819,'HLBU1658310/FANU1444958','20240401'],
  [21.24203,-101.29970,'87BB9W','20240401'],
  [18.754311,-103.72441,'MEDU9763182','20240401'],
  [25.7415,-103.4303,'54AK9H','20240401'],
  [19.29838,-98.77148,'LF91443','20240402'],
  [20.516663,-100.162987,'FCIU9009890','20240402'],
  [25.77021,-100.31630,'86AX1C','20240402'],
  [19.902462,-100.794177,'CMAU4417131//CMAU3483313','20240402'],
  [22.71943,-100.49705,'01AU7X','20240402'],
  [16.92876,-99.80121,'371FE8','20240402'],
  [21.2896975,-102.2368995,'73BD6P','20240402'],
  [23.22903,-100.54271,'45AX5H','20240402'],
  [18.47715,-96.01288,'89AC7U','20240403'],
  [19.201372,-101.911766,'TRHU4495139,/TCLU9302967','20240403'],
  [19.264072,-103.68048,'GCXU5652745/MRKU0671337','20240403'],
  [21.3797085,-101.9223099,'57BA4T','20240403'],
  [19.82032,-99.24586,'LF87848','20240403'],
  [19.92830,-98.87164,'S/D','20240403'],
  [18.84953,-97.31364,'346AS5','20240403'],
  [23.682743,-101.913635,'CRSU1000300/CAIU2890245','20240403'],
  [19.197123333333,-96.1592183333331,'15AV9F','20240403'],
  [20.68971,-103.44818,'22BB7K','20240403'],
  [22.037397,-102.28018,'SEGU6283695/TLLU2798730','20240403'],
  [17.06990,-93.57547,'15AZ8E','20240403'],
  [19.86127510684542,-99.31754730689714,'18AM8F','20240403'],
  [25.83726,-100.21473,'83AR5B','20240404'],
  [19.87563,-100.18872,'MSKU1040050/MRSU5920327','20240404'],
  [20.59471,-101.23507,'81AE7W','20240404'],
  [20.4114710,-102.7439130,'37AN6B','20240404'],
  [32.54852,-116.04608,'TCLU8644965/GESU6102998','20240404'],
  [16.78498,-96.35944,'LG07655','20240404'],
  [19.32335,-90.74502,'04BA5R','20240404'],
  [20.43645,-103.54329,'SEKU4520750//CMAU8127001','20240404'],
  [32.56624,-115.94141,'TCLU1845510/TCLU5405463','20240404'],
  [19.5509155,-99.1497937,'494YG3','20240404'],
  [20.54158,-101.13744,'HLBU9786786','20240404'],
  [22.703266,-102.449146,'TRHU4647885/FFAU4924514','20240404'],
  [18.12147,-95.74742,'LG08349','20240404'],
  [21.3051503,-102.0911209,'HLBU2641815,HLXU1371965','20240404'],
  [20.419955,-103.555902,'SUDU6157418','20240404'],
  [19.9083978,-100.4526142,'MRKU6363291,MRSU5447750/TCKU6428743SEKU4456203','20240405'],
  [19.23258,-98.37692,'96AC3X','20240405'],
  [21.12825,-101.38515,'200DE5','20240405'],
  [23.710427,-101.881306,'CMAU1920271//CMAU1248948','20240405'],
  [19.444998,-101.871117,'MRKU7245159/MRKU7245159','20240405'],
  [19.3029000,-98.4095000,'47BA5Y','20240405'],
  [19.54895,-101.52612,'MRKU7245159/MRKU7245159','20240405'],
  [20.390042,-103.300372,'FSCU5794746','20240405'],
  [19.330429,-97.767280,'069EV4','20240406'],
  [20.678346,-103.017527,'NYKU3675882','20240406'],
  [18.00808,-101.96090,'MRKU5906692,/MRKU6381340','20240406'],
  [18.5553838,-101.9700427,'47AM4D','20240406'],
  [31.31287,-109.20661,'CMAU8535976/TCNU3685980','20240406'],
  [23.93797,-100.45283,'27AH8U','20240406'],
  [20.57002,-100.31043,'LF98018','20240406'],
  [20.390039,-103.300694,'TLLU4414746','20240406'],
  [22.22027,-101.05764,'39AX9D','20240406'],
  [32.395604,-114.490179,'93AC7F','20240406'],
  [22.394375,-101.351138,'96AL7J','20240406'],
  [19.40421,-99.01196,'45AE9S','20240406'],
  [17.99913,-93.39905,'59AY3M','20240407'],
  [19.549751,-98.494214,'25AZ1H','20240407'],
  [17.87331,-99.39927,'23AY9M','20240407'],
  [19.21984,-98.36274,'13AJ2D','20240407'],
  [20.10497488824869,-99.70002062458184,'01AH8L','20240408'],
  [23.80860,-106.86811,'53AJ8Z','20240408'],
  [18.87746,-103.78287,'MEDU9764548','20240408'],
  [20.06257,-99.20461,'89AG9D','20240408'],
  [16.463566,-94.297201,'20BC4F','20240408'],
  [19.284831,-98.442572,'81BD4H','20240408'],
  [19.31310,-99.07618,'299FE9','20240408'],
  [26.88295,-99.82109,'29AS9V','20240408'],
  [20.02409,-99.45903,'19AN9H','20240408'],
  [23.464015,-105.844843,'08AM9A','20240408'],
  [20.3715521,-103.5764302,'38AH7C','20240408'],
  [19.9026478,-100.7952345,'37AN6B','20240408'],
  [19.119934,-103.77515,'FDCU0252569/TCNU6415838','20240408'],
  [19.64228,-99.01222,'49AZ1Y','20240408'],
  [19.935478,-103.53016,'TLLU8325082','20240409'],
  [18.68178,-96.32509,'S/D','20240409'],
  [19.422009,-99.358540,'A609AF','20240409'],
  [21.3056843,-102.0912077,'60AM7N','20240409'],
  [17.982242,-93.023002,'31AM3B','20240409'],
  [19.62297,-99.04570,'81BB4Y','20240409'],
  [21.737318,-102.277047,'TLLU4503294/CMAU8855624','20240409'],
  [20.045814,-103.554749,'TCLU6194534','20240409'],
  [19.64142,-97.02077,'MSDU4635273','20240409'],
  [19.40644,-103.53041,'CMAU5601673','20240410'],
  [19.97161,-101.76014,'CAIU6204575/TLLU2154871','20240410'],
  [20.5373738,-100.4900982,'15AN6B','20240410'],
  [28.56115,-105.95433,'99AS5E','20240410'],
  [22.127597,-100.824768,'06AS3J','20240410'],
  [21.78861,-100.71132,'87AH3W','20240410'],
  [19.27920,-98.88231,'737YF6','20240410'],
  [19.585972,-99.203750,'TCNU283363/TCNU2883363','20240410'],
  [19.78851,-99.22206,'FFAU5140517,TRHU8494681','20240411'],
  [20.62849,-103.34848,'29AE5D','20240411'],
  [23.75901,-101.8177,'CMAU2793340/CMAU2792088','20240411'],
  [18.90739,-97.01981,'82AY7H','20240411'],
  [19.54892,-98.79050,'163DG1','20240411'],
  [17.46314,-93.63642,'722AL3','20240411'],
  [18.85930,-97.55163,'LG08349','20240411'],
  [20.58938,-101.09605,'CIPU5007622,MRKU2483010','20240411'],
  [17.59599,-99.39619,'48AX9W','20240411'],
  [18.82079,-96.69971,'81AX2H','20240411'],
  [19.5921,-98.5656,'CMCU9016066','20240411'],
  [19.609275,-96.399148,'SZLU9106465','20240411'],
  [23.723892,-101.865433,'CMAU2792236//FSCU8066955','20240411'],
  [22.700318,-102.449472,'TCLU5983327//BEAU4851963','20240411'],
  [16.21087,-93.85761,'26BB6Y','20240411'],
  [19.290184,-98.92149,'20BC7U','20240412'],
  [18.56694,-101.96695,'CMAU4306400/TCKU6253882','20240412'],
  [18.16843,-101.90360,'NYKU3679790/TRHU1854430','20240412'],
  [19.352428,-98.667818,'27AU4W','20240412'],
  [19.996602,-99.486219,'CAIU9084302','20240412'],
  [21.773392,-102.293783,'GCXU2351711/GCXU2351898','20240412'],
  [19.660387,-101.237363,'TRLU7346332,CMAU5295130','20240412'],
  [25.18618,-100.73187,'14AS2W','20240412'],
  [19.908691,-100.455188,'TRHU7746813/MRSU6547630','20240412'],
  [19.251178,-98.859148,'30AP6K','20240413'],
  [20.390827,-101.973700,'CMAU9000109','20240413'],
  [19.30827,-98.49483,'A609AF','20240413'],
  [19.85577,-100.04160,'MSKU0154207/MSKU9883560','20240413'],
  [19.00447,-98.32535,'15AP6V','20240413'],
  [22.751013,-102.422512,'56AJ9M','20240414'],
  [20.077561,-103.519085,'GCXU2341729','20240414'],
  [18.75744,-91.57627,'S/D','20240414'],
  [22.94548,-100.46300,'S/D','20240414'],
  [20.483939,-103.248430,'TCNU2324257','20240414'],
  [22.03285,-100.85088,'83AE3P','20240414'],
  [22.86715636765009,-100.16511630954446,'997DE9','20240414'],
  [20.095416,-99.299126,'FDCU0147481','20240414'],
  [22.460239,-102.346361,'CMAU2792468','20240414'],
  [19.55005,-98.49356,'85BC3W','20240414'],
  [19.60174,-99.18874,'278FE9','20240415'],
  [21.72740,-102.27686,'FCIU3742615','20240415'],
  [19.64229,-99.19509,'CXDU1815251','20240415'],
  [21.3232,-100.6173,'27BC5A','20240415'],
  [21.373346,-104.707919,'57BD2S','20240416'],
  [18.812304,-97.507703,'45AE8L','20240416'],
  [19.62424,-99.04650,'07AX9D','20240416'],
  [20.66051,-100.43158,'301FE9','20240416'],
  [20.87497,-101.3364083,'23UG7A','20240416'],
  [19.108690,-98.023230,'729DT9','20240416'],
  [19.94489,-99.39076,'78AY2T','20240416'],
  [19.537989,-99.043209,'NMA8116','20240416'],
  [21.489393,-101.765377,'50AC9M','20240416'],
  [19.5351328,-101.5447030,'08BB4J','20240417'],
  [21.81603,-101.01060,'77BA9U','20240417'],
  [18.7989,-96.6028,'11AS3J','20240417'],
  [20.091538,-99.683195,'16AU9A','20240417'],
  [20.0551467,-99.5951581,'LE72102','20240417'],
  [27.675966,-105.147928,'21AX7D','20240417'],
  [24.09185,-100.38242,'87AU9Y','20240417'],
  [20.36407,-102.14018,'805DT5','20240417'],
  [20.4981066,-100.8057927,'54AC2U','20240417'],
  [19.37708,-97.71314,'53AS4H','20240417'],
  [20.03641,-99.56248,'23AK8U','20240418'],
  [31.59288,-106.39602,'LF57715','20240418'],
  [19.49114,-99.14299,'Z50BGE','20240418'],
  [20.53244,-100.62252,'42AZ7S','20240418'],
  [19.353860,-101.916592,'MRKU5997100/MRKU4531821','20240418'],
  [19.875941,-101.141781,'71AB2S','20240418'],
  [18.566661,-101.96668,'MSKU9441240','20240418'],
  [21.6278340,-101.0681224,'61AD7R','20240418'],
  [18.10399,-95.43892,'35AE7C','20240418'],
  [20.39088,-103.11410,'75BB3D','20240418'],
  [20.50450,-100.59213,'66AH7L','20240418'],
  [19.9966161,-99.4795295,'27AU6W','20240418'],
  [20.09498,-99.29961,'37AU9R','20240418'],
  [18.78208,-96.48370,'35AE7C','20240418'],
  [18.79911,-96.60325,'191AR1','20240418'],
  [19.99227,-99.46945,'456ES1','20240418'],
  [18.79051,-96.56631,'80BA9Y','20240419'],
  [20.9148565,-100.4085274,'68BA9T','20240419'],
  [19.62974,-99.19351,'917FE9','20240419'],
  [19.56866,-98.65603,'85BD1L','20240419'],
  [18.490878,-89.273776,'32AY1M','20240419'],
  [18.82619,-97.30502,'065EX4','20240419'],
  [23.725718,-101.863332,'TCKU2040024/SEGU1181751','20240419'],
  [19.643404,-99.055555,'59BA7F','20240419'],
  [19.40046,-99.38353,'76AA8J','20240419'],
  [19.15612,-98.29196,'938EX3','20240419'],
  [19.0440811,-98.0136925,'99AH2L','20240419'],
  [20.89673,-101.05570,'45AY9M','20240419'],
  [23.16428,-100.52463,'90AU8X','20240420'],
  [18.62288,-90.38414,'61AY6T','20240420'],
  [23.6772521,-100.6093249,'08AK1R','20240420'],
  [20.05726270913408,-98.4715315050315,'36BA5X','20240420'],
  [21.28960,-102.23544,'S/D','20240420'],
  [20.41514,-103.55723,'MSKU9219612/TLLU5123410','20240420'],
  [20.141847,-99.733854,'64AY1M','20240420'],
  [20.390097,-103.30413,'MRSU5021698/CAAU6401205','20240420'],
  [21.8069231,-100.7243172,'15AN6B','20240421'],
  [19.8811165,-101.2044779,'22AN6B','20240421'],
  [22.1338700,-100.8255204,'60AD7R','20240421'],
  [20.73535,-100.49188,'02AR6T','20240421'],
  [20.390303,-103.30593,'CMAU2300402','20240421'],
  [19.261305,-103.682106,'TCKU6519972/SUDU8573250','20240421'],
  [17.90994,-94.939091,'CMCZ810552','20240421'],
  [20.27118,-99.87735,'02AR6T','20240422'],
  [18.78643,-102.05008,'HASU4181813/MRKU2090218','20240422'],
  [20.5455180,-101.2179375,'10AK1R','20240422'],
  [23.10159,-102.45160,'CMAU2300402','20240422'],
  [19.13451,-98.27125,'64FA5A','20240422'],
  [19.42535,-99.35424,'LC81357','20240422'],
  [21.94227,-99.24436,'58AH8N','20240422'],
  [25.84232,-100.24485,'150DC4','20240422'],
  [19.9726,-101.755,'FSCU5755800','20240422'],
  [20.56225,-103.32495,'LE76307','20240422'],
  [21.07473,-100.51145,'99AS8Z','20240423'],
  [22.77450,-102.57910,'LG11057','20240423'],
  [20.9547586,-100.4234719,'30AN7B','20240423'],
  [23.1644383,-100.5242335,'69BB9H','20240423'],
  [18.90771,-97.69335,'63AY1M','20240423'],
  [19.30629,-98.49136,'78AX4P','20240423'],
  [21.2896011,-102.2354745,'39AN6B','20240423'],
  [22.5699733,-100.6330477,'75AL2C','20240423'],
  [20.33995,-102.20537,'TCLU8724649/TRHU4397298','20240423'],
  [17.05336,-93.56612,'45AK4P','20240423'],
  [17.73115,-99.50112,'559AT4','20240423'],
  [19.908572,-100.452436,'SUDU6822971/MRKU0587801','20240423'],
  [19.043569,-98.008678,'68AM3B','20240424'],
  [18.972063,-102.087435,'77AB2S','20240424'],
  [16.442409,-94.264279,'55BB6G','20240424'],
  [18.846880,-97.302728,'48AJ9M','20240424'],
  [23.533784,-103.148616,'684M7A','20240424'],
  [19.67842,-99.07244,'10AY8L','20240424'],
  [21.71470,-100.68747,'43AG9D','20240424'],
  [21.62727,-100.73367,'311DE6','20240424'],
  [19.76979,-99.21638,'32AM4V','20240424'],
  [20.90068,-87.43237,'31AP6K','20240425'],
  [21.3056946,-102.0909845,'HLBU2889798/TCNU3276997','20240425'],
  [19.631037,-103.464325,'TRHU7440530/MRKU7858309','20240425'],
  [19.9360446,-103.5300920,'73BD6P','20240425'],
  [19.064149,-98.103350,'94AU5R','20240425'],
  [19.908551,-100.453664,'FFAU4769927/TRHU8050060','20240425'],
  [19.931918,-103.529573,'CMAU3596310/TCNU4612545','20240425'],
  [20.55249,-100.44846,'371FE8','20240425'],
  [21.0268,-102.6052,'TGCU2044809/TEMU3963627','20240425'],
  [19.663406,-99.136682,'47AM4D','20240425'],
  [19.49937,-101.69678,'10AX9D','20240425'],
  [19.45091,-98.91592,'380FE8','20240426'],
  [23.245,-106.4402,'209EY3','20240426'],
  [19.600648,-98.939461,'260EU6','20240426'],
  [19.46526,-99.09918,'17FA4B','20240426'],
  [19.45593,-99.08933,'LE13049','20240426'],
  [25.79879,-100.45423,'68AM7A','20240426'],
  [19.727437,-99.184339,'FCIU7501513','20240426'],
  [20.826711,-102.793830,'66AG7V','20240426'],
  [19.2539863,-103.6883720,'82AH1K','20240426'],
  [23.984888,-100.423710,'64AC7B','20240426'],
  [19.152351,-101.979052,'FFAU4905617//GAOU6809610','20240426'],
  [19.244376,-98.389153,'76AY2M','20240426'],
  [20.5806,-100.485,'MRKU3416588/CAAU6533222','20240426'],
  [23.6173565,-100.6078564,'29AU6W','20240427'],
  [19.40991,-99.61365,'22AX3X','20240427'],
  [23.676109,-100.608840,'24BA5Y','20240427'],
  [19.63717,-99.19428,'MRKU0391001/PONU0077190','20240427'],
  [18.87692289701238,-102.07809614918354,'NN1703B','20240427'],
  [19.625703,-99.224599,'NSG2499','20240427'],
  [22.64713,-100.51480,'155DC4','20240427'],
  [19.54763,-99.18354,'LF91443','20240427'],
  [20.17514,-102.19163,'511VY4','20240427'],
  [21.07473,-100.51002,'90AH7W','20240428'],
  [19.21109,-103.73902,'378VY7','20240428'],
  [19.90112,-100.40193,'TCKU7633146/GCXU5590047','20240428'],
  [19.90895,-100.449748,'MRKU0059018/GESU3847230/CAAU6550702/MRSU4818223','20240428'],
  [23.1664,-100.5254,'36AE8D','20240428'],
  [19.2694,-103.674,'SEGU6349004/MAGU5738650','20240428'],
  [20.1437,-99.7333,'TLLU7895687/SEKU6060490','20240428'],
  [18.83928,-96.75043,'460R8P','20240428'],
  [18.83324,-97.29727,'MRKU8698083/TRHU6187201','20240428'],
  [22.94282,-102.689983,'MRKU4752186/MRSU4183312','20240429'],
  [20.8745988,-101.3359527,'69BB9H','20240429'],
  [19.5061,-101.6617,'PCIU8880495/PCIU9528302','20240429'],
  [21.11470,-102.46108,'63AZ7S','20240429'],
  [20.628361,-89.933222,'01AM9A','20240429'],
  [19.8753108,-100.1860684,'HLBU2162052,HLBU2846477','20240429'],
  [19.64936,-99.19367,'TRLU7341243/TLLU8867191','20240429'],
  [16.98504,-99.25614,'01AH8L','20240429'],
  [19.58194,-99.02312,'LE77457','20240429'],
  [20.41637,-100.04396,'17AP3N','20240429'],
  [20.6711291,-101.2986625,'69BB9H','20240429'],
  [23.750251,-101.829834,'TCNU6586698','20240429'],
  [19.93627,-103.53035,'IKSU2516966','20240430'],
  [20.40041,-100.02978,'42AN5Z','20240430'],
  [19.60892,-99.18688,'6442ZP','20240430'],
  [20.087287,-99.531491,'54AJ9M','20240430'],
  [19.65867,-99.20823,'LF88484','20240430'],
  [21.773392,-102.293783,'S/D','20240430'],
  [19.11781,-98.01300,'86AY3M','20240430'],
  [19.875486,-100.192100,'CMAU46885808/SEKU5864128','20240430'],
  [19.81118,-97.22644,'86AY3M','20240430'],
  [20.479058,-103.265936,'41AF1K','20240430'],
  [20.58915,-101.19630,'40AU9R','20240501'],
  [20.53226,-100.62227,'28BE7F','20240501'],
  [20.081512,-101.13685,'MRSU6010424,TRHU5623759','20240501'],
  [20.207595,-99.793219,'MSMU8917414/MSDU7491420','20240501'],
  [19.054154,-98.050183,'13AM9A','20240501'],
  [19.231370,-101.881803,'TRHU5623759/MRSU6010424','20240501'],
  [19.540762,-98.905410,'CMAU8755435,TGHU8146160','20240501'],
  [20.67397,-101.21460,'62BE9U','20240501'],
  [20.571421,-103.101419,'UETU5620963','20240502'],
  [19.1028958,-104.3076905,'65BA9T','20240502'],
  [26.88273,-99.82177,'10AU7X','20240502'],
  [19.17104,-99.47779,'13BA1W','20240502'],
  [16.79153,-99.36564,'LD04777','20240502'],
  [22.070570,-100.883532,'79AS8Z','20240502'],
  [25.67566,-100.36476,'10AZ7E','20240502'],
  [19.54208,-99.18215,'22AG3N','20240502'],
  [18.605102,-101.970543,'MRSU5588526/TRHU8172643','20240502'],
  [20.08272,-99.62725,'70AY7P','20240502'],
  [19.9439000,-99.7197000,'MSKU9647539,TCNU7781575','20240502'],
  [23.66703,-100.60785,'24BA5Y','20240503'],
  [19.58259980425456,-99.27108228244816,'561EU1','20240503'],
  [19.293954,-99.524277,'08AH2A','20240503'],
  [19.31844,-99.08436,'NBE682A','20240503'],
  [25.68596,-100.47202,'10AZ7E','20240503'],
  [19.89459,-98.77154,'05AY9L','20240503'],
  [20.8232638,-102.7957307,'25AN6B','20240503'],
  [19.53326,-101.55093,'MRKU0927517/MRKU4211197','20240504'],
  [19.98395,-99.44116,'CMAU1188216','20240504'],
  [24.09192,-100.38238,'08AU7X','20240504'],
  [23.13839,-102.42537,'78AL4S','20240504'],
  [19.69407,-99.33033,'46AD8R','20240504'],
  [19.880162,-99.859560,'EMCU8773792','20240504'],
  [21.76157,-100.69386,'43AN7L','20240504'],
  [19.56738,-99.20926,'90AM7D','20240504'],
  [19.170738333333336,-103.75885000000001,'HASU4588558/MRKU2293766','20240504'],
  [19.507659999999998,-101.66888333333333,'MRKU1055655/MRKU4044720','20240505'],
  [20.09473,-99.30053,'TLLU4953898//TLLU7750153','20240505'],
  [18.884071,-96.875185,'47BA5F','20240505'],
  [19.936302,-103.530396,'61UF5G','20240505'],
  [21.2893048,-102.2662189,'FANU3258361/HLBU2564909','20240505'],
  [20.710055,-103.2871,'FBLU4914500','20240505'],
  [22.60418,-101.70737,'6849ZP','20240505'],
  [22.661279,-101.999277,'63AR9A','20240505'],
  [25.18609,-100.73178,'54AJ7P','20240505'],
  [20.082519,-101.13689,'MRSU4860429/GCXU5821465','20240505'],
  [19.53369,-101.55050,'MRKU4973166/MSKU0602826','20240506'],
  [21.49122,-102.24475,'80BB4Y','20240506'],
  [16.61815,-93.60391,'15BA5Y','20240506'],
  [20.83278,-100.44117,'Querétaro','20240506'],
  [19.555055,-99.141254,'PEH5902','20240506'],
  [27.083761,-104.843079,'TCLU8836649','20240506'],
  [25.03952,-105.41778,'11BA1C','20240506'],
  [19.033816,-91.114579,'CMCU9014248','20240506'],
  [18.02170,-101.95523,'TRHU4365650/BEAU6207332','20240506'],
  [16.86084,-99.89044,'22AG3N','20240507'],
  [19.84426,-99.27994,'24AX6W','20240507'],
  [22.734469,-102.341595,'69AY1M','20240507'],
  [25.893840,-100.224388,'34AL2N','20240507'],
  [17.911954,-94.935208,'92AX2C','20240507'],
  [20.40347,-102.03077,'56AJ9M','20240507'],
  [19.55912,-99.19204,'927FE9','20240507'],
  [18.289426,-92.822245,'CMCU9015331/CMCU9016770','20240507'],
  [19.6337,-98.5596,'CMAU8687747/CMAU6154728','20240507'],
  [24.374323,-100.395086,'90AH7L','20240507'],
  [21.423855,-102.253858,'SEKU6029068/MAGU5500596','20240508'],
  [20.0823,-101.1368,'MRSU5585738,MRSU4649057','20240508'],
  [18.5548,-101.9702,'HASU4417128,MRSU5055419','20240508'],
  [19.5494,-101.5254,'CAAU6515867,GCXU5567936','20240508'],
  [19.6251,-101.2785,'MSKU1312586,MSKU1288638','20240508'],
  [18.99665,-99.19661,'22AG3N','20240508'],
  [20.49714,-100.13283,'LD36181','20240508'],
  [19.04397,-98.01168,'04AM9A','20240508'],
  [24.258223,-99.435757,'79AK9H','20240508'],
  [19.948444,-99.635615,'MRKU3195131/MRKU6454909','20240508'],
  [18.02941,-101.95198,'SUDU7694495,MSKU6936374','20240509'],
  [25.620012,-100.070912,'79AK9H','20240509'],
  [20.12981,-99.72799,'PBJ5715','20240509'],
  [19.3020278,-98.7438889,'43AX6C','20240509'],
  [20.274114,-102.405106,'TCKU6419171/MTSU9608157','20240509'],
  [19.29658,-98.88106,'LF89636','20240509'],
  [19.85063,-99.28495,'CSNU7887068,FFAU2757573','20240509'],
  [19.22501,-98.83595,'51AF2M','20240509'],
  [22.948352,-102.561015,'TLLU7984784/TCNU4703650','20240509'],
  [23.518344,-100.621446,'41AM4D','20240509'],
  [19.62470,-101.27872,'MRKU2812331/TLLU5249902','20240509'],
  [19.5773,-99.1962,'33AS1H','20240510'],
  [19.413553,-98.201718,'14AM1D','20240510'],
  [19.54234,-99.0141,'70AM1R','20240510'],
  [23.70982,-101.88205,'TCNU4703650,TLLU7984784','20240510'],
  [19.784430,-99.179225,'CSNU7545297','20240510'],
  [19.486471,-99.148276,'JBHU319733','20240510'],
  [18.558027,-101.969439,'MSCU5685110/MSCU5673567','20240510'],
  [21.07904,-100.51430,'052AH6','20240511'],
  [21.816166,-101.010576,'FDCU0293927','20240511'],
  [19.19494,-98.33223,'70AM1R','20240511'],
  [19.410818,-99.182608,'58BA4T','20240511'],
  [16.25625971251385,-92.11955238960577,'123EV9','20240511'],
  [21.140061,-102.434972,'55AJ9U','20240511'],
  [20.21418,-102.35900,'80AT7G','20240511'],
  [20.17529,-102.19294,'56AT6G','20240511'],
  [22.72248,-100.49700,'32BA1W','20240512'],
  [21.893909,-99.419362,'CAMU8672773/CMAU7597799','20240512'],
  [18.97818,-97.80280,'52AH2M','20240512'],
  [18.848302,-96.786038,'59AK9X','20240512'],
  [22.27180,-100.81947,'10AC6U','20240513'],
  [20.508087,-100.76535,'HASU1423507/MRKU6883020','20240513'],
  [19.31025,-98.97322,'95AP6N','20240513'],
  [20.511866,-100.600591,'MRKU6198983/MRSU6525250','20240513'],
  [19.1165209302819,-98.23869090704717,'84AR3B','20240513'],
  [19.99415,-99.47086,'14BA6Y','20240513'],
  [22.2802,-97.8942,'55BC1T','20240514'],
  [21.18281,-86.83163,'71BB8U','20240514'],
  [19.457907,-99.152693,'MRSU6025091','20240514'],
  [19.457907,-99.152693,'HASU4220870','20240514'],
  [21.81701,-101.01008,'87AU3Z','20240514'],
  [17.85314,-99.40675,'LF85182','20240514'],
  [25.55855,-103.42742,'579DC4','20240514'],
  [18.99286,-103.84045,'TCNU2285124//SEKU4325060','20240514'],
  [27.245369,-104.932927,'76AY2M','20240514'],
  [18.808137,-91.470571,'28BA8F','20240514'],
  [19.3199095,-99.0745890,'28AN7B','20240514'],
  [19.5568000,-98.7458000,'MRSU4371834,TCKU7504365','20240514'],
  [20.146483,-98.280803,'04AC8U','20240514'],
  [19.79680,-99.23137,'42BA5F','20240514'],
  [19.579,-99.1948,'262ES1','20240515'],
  [18.67496,-96.31513,'86AZ8T','20240515'],
  [19.29056,-99.53718,'79AN5Y','20240515'],
  [19.901808,-100.78802,'SUDU7616337','20240515'],
  [21.81256,-101.01345,'70AR5B','20240515'],
  [20.39004,-103.30157,'MRKU6484550,/MRSU6134240','20240515'],
  [20.65176,-101.37421,'HASU1026460,MSKU7055593','20240515'],
  [20.397984,-103.251779,'69AK4Z','20240515'],
  [19.33336,-99.19029,'372FE8','20240515'],
  [19.71996,-88.67450,'54AJ9M','20240515'],
  [19.18399,-98.31912,'17AJ1T','20240515'],
  [25.03326,-100.56007,'33AN6Z','20240515'],
  [19.29032,-98.91825,'04AD2A','20240515'],
  [19.908731,-100.4548,'SZLU9555379','20240515'],
  [21.86077,-100.90667,'19AX7D','20240515'],
  [19.150663,-101.980446,'TRHU6926528/MRSU4991500','20240516'],
  [20.404516,-102.73745,'TRIU8083196','20240516'],
  [20.51763,-100.16420,'76AE8L','20240516'],
  [26.40885,-109.01749,'456ES1','20240516'],
  [19.24344,-98.38827,'82AS5F','20240516'],
  [18.41536,-99.93735,'04AP7L','20240516'],
  [18.552334,-101.970959,'WHSU5408199/WHSU6805312','20240516'],
  [18.616536,-92.205541,'72AP7K','20240516'],
  [22.056451,-102.280142,'33BA5Y','20240516'],
  [21.2896388,-102.2424164,'25AN7B','20240516'],
  [19.354774,-101.917304,'TRHU8448135','20240516'],
  [25.5921,-100.8794,'CMAU4973573/CMAU7643385','20240516'],
  [19.04202666666667,-98.28072166666668,'27889T','20240516'],
  [21.69530,-105.05119,'456ES1','20240517'],
  [18.851110,-97.256899,'13AM9A','20240517'],
  [19.23934,-98.42548,'LG01158','20240517'],
  [21.00078,-104.13663,'456ES1','20240517'],
  [17.21506,-99.53069,'384FE8','20240517'],
  [19.40512,-99.71402,'TRHU6296413','20240517'],
  [20.76754,-101.33487,'88AW9W','20240517'],
  [19.30819,-98.83339,'62AT4B','20240517'],
  [20.39674,-101.99702,'346EH1','20240518'],
  [20.67158,-101.21143,'68AL6N','20240518'],
  [19.312851,-103.601293,'CAIU9741156/TCNU8048541','20240518'],
  [18.94421,-103.90556,'CMAU8901967,TGCU0004957','20240518'],
  [20.12840,-100.66801,'LF48328','20240518'],
  [19.57504,-99.01974,'76BB9K','20240519'],
  [20.084472,-99.667444,'22AH1D','20240519'],
  [23.66245,-100.60715,'55BA5Y','20240519'],
  [25.59271,-100.87894,'TCKU1737164/CMAU1872759','20240520'],
  [24.60320,-100.27162,'96AU6X','20240520'],
  [19.35897,-99.16970,'LF88487','20240520'],
  [18.113819,-95.459243,'03AM9A','20240520'],
  [19.975797,-99.049136,'HLBU3455420','20240520'],
  [25.457903,-100.897358,'96AN2J','20240520'],
  [19.51646,-101.60808,'40AX8W','20240520'],
  [20.80875,-101.43543,'32AN8B','20240520'],
  [27.42536,-99.53210,'49AH2M','20240520'],
  [18.08699,-99.22808,'91AT4B','20240520'],
  [19.701426,-88.652845,'64AM3B,///501AG2','20240520'],
  [19.247253333333333,-103.69598333333333,'UACU8415870','20240520'],
  [19.639142,-99.153571,'83AL6N','20240520'],
  [9.63060,-97.13119,'MRSU3303136/MRSU3639115','20240520'],
  [18.01407,-94.39500,'23BD2J','20240521'],
  [18.112755,-95.457723,'28BA8F','20240521'],
  [22.99399,-99.73107,'81AY7H','20240521'],
  [19.476383,-98.309229,'98AC5J','20240521'],
  [19.893906,-103.522095,'TGHU1507996/TLLU8276178/TGBU2417299','20240521'],
  [19.156237,-98.29195,'743ET2','20240521'],
  [16.77622,-99.21993,'91AT4B','20240521'],
  [18.83890,-96.74298,'07AR7B','20240521'],
  [24.49376,-100.29758,'14BA1W','20240522'],
  [20.58570,-101.02615,'03BA9Y','20240522'],
  [19.70007,-99.08444,'11AS5U','20240522'],
  [19.09663,-98.22897,'85BC3W','20240522'],
  [19.691128,-99.187673,'53BA7F','20240522'],
  [19.09751,-98.23077,'625ET4','20240522'],
  [26.740000,-99.894700,'03AR7B','20240522'],
  [19.359614,-99.456784,'CMAU8084487','20240522'],
  [25.50015,-100.87135,'3799ZR','20240522'],
  [22.08694,-100.87979,'29UK6W','20240522'],
  [18.79893,-96.60279,'97AH3P','20240522'],
  [16.45294,-98.3688,'01AY3D','20240522'],
  [18.80371,-98.93207,'68AM7A','20240522'],
  [19.8663815,-97.2207762,'03BA5F','20240523'],
  [19.258916,-98.411895,'SEGU6089801','20240523'],
  [19.79382,-99.22908,'910EN3','20240523'],
  [26.5829585,-100.0819570,'23AN6B','20240523'],
  [18.84078,-97.26214,'125EV9','20240523'],
  [19.32701,-90.74235,'67AZ1Y','20240523'],
  [21.289418,-102.246403,'43AH2M','20240523'],
  [24.09184,-100.38247,'87AU9Y','20240523'],
  [21.66031,-100.57333,'64AS3Y','20240523'],
  [26.34076,-100.07117,'34AU5C','20240523'],
  [18.01432243484456,-94.3944967423668,'88AN8H','20240523'],
  [20.09423,-99.29993,'17AS3J','20240523'],
  [20.57162,-103.10134,'CMAU3864541/CMAU6549137','20240523'],
  [23.70109,-101.89228,'BMOU5610271','20240524'],
  [30.542897,-111.113880,'02AS6M','20240524'],
  [20.82359,-102.79554,'CMAU6549137/CMAU3864541','20240524'],
  [25.695163,-100.571583,'19AX8X','20240524'],
  [19.38133,-99.08404,'LF88484','20240524'],
  [20.09357,-99.30253,'TLLU8134430,/,MRKU3077057/MRKU6053146/HASU4415742','20240524'],
  [20.37440,-102.66569,'776FE5','20240524'],
  [18.80315,-96.62276,'85BD1L','20240524'],
  [17.69836,-92.53641,'69AV9E','20240524'],
  [20.4370081,-103.5424404,'54AC2U','20240524'],
  [20.5351,-103.232079,'CMAU5855662/BMOU5610271','20240524'],
  [22.28329,-100.85473,'26AS7A','20240524'],
  [19.68764,-98.59256,'960DD8','20240524'],
  [19.342941,-90.730209,'25AF8X','20240524'],
  [20.01592,-99.46549,'18AX7D','20240524'],
  [30.86842,-110.65772,'456ES1','20240524'],
  [24.29739,-103.4114,'92AP8X/84AY9H','20240525'],
  [19.35990,-98.97933,'456ES1','20240525'],
  [19.408015,-103.528847,'CMAU3621270/TRHU1988126','20240525'],
  [20.59266,-101.14073,'MRSU3776150,MSKU9351608','20240525'],
  [18.52285,-96.37800,'90AM7D','20240525'],
  [20.07346,-99.36457,'115DP2','20240525'],
  [18.916288,-98.484738,'TRA1131','20240525'],
  [20.52708,-100.17775,'54BA5Y','20240525'],
  [18.94326,-103.92136,'MAGU5746276,MAGU5746276','20240525'],
  [19.6248,-101.2788,'HASU4148081,CIPU5219133','20240526'],
  [20.37535,-103.57627,'BSIU8065598,TCNU5201024','20240526'],
  [24.255705,-99.434436,'TGBU5530499','20240526'],
  [20.07228,-101.13658,'MSKU8368761,HASU5022210','20240526'],
  [22.4344,-100.7101,'49AM5E','20240526'],
  [20.3900041,-103.3018479,'21AU6W','20240527'],
  [24.60339,-100.27167,'58AX6D','20240527'],
  [18.00736,-95.25784,'22AX9R','20240527'],
  [20.459432,-97.852463,'377FE9','20240527'],
  [19.73707,-98.61949,'22FA6B','20240527'],
  [19.661336,-98.579925,'73AM3B','20240527'],
  [21.96384,-102.28516,'89BD4E','20240527'],
  [19.760763,-99.215578,'58AT6U','20240527'],
  [23.02715,-105.93034,'CGMU6932381','20240528'],
  [22.928612,-100.456773,'45BA5F','20240528'],
  [20.58271,-103.095726,'SUDU7541006/CAIU2387858','20240528'],
  [19.196903,-101.915984,'44AX5E','20240528'],
  [19.473248,-99.161486,'FANU3223766','20240528'],
  [23.16584,-100.52523,'92AS9V','20240528'],
  [18.43785,-99.17490,'73AA4J','20240528'],
  [20.095281,-99.297026,'97AP8K','20240528'],
  [20.324331,-102.27573,'GESU6916490/GESU6257351','20240528'],
  [18.849532,-97.313351,'79BA7Y','20240528'],
  [19.375791,-98.035991,'17AM5P','20240528'],
  [18.36318,-99.85663,'89AJ4C','20240528'],
  [20.37686,-102.93358,'646AN9','20240529'],
  [19.50527,-101.65805,'MRKU9622159','20240529'],
  [19.50527,-101.65805,'MRKU9622159','20240529'],
  [21.85805,-100.75283,'15AL6N','20240529'],
  [19.392172,-97.687632,'15AM6A','20240529'],
  [25.39206,-108.12660,'456ES1','20240529'],
  [16.93926,-96.70528,'LF91443','20240529'],
  [18.046938,-94.081000,'82AJ9M','20240529'],
  [26.974584,-99.772716,'05AH3P','20240529'],
  [19.121234,-103.775245,'CMAU0349321/BMOU1299325','20240529'],
  [27.11665,-109.45968,'456ES1','20240529'],
  [18.219447115176802,-97.2888341002209,'08UR4M','20240529'],
  [19.90269,-100.79529,'CMAU7072429/SEGU4820901','20240529'],
  [22.91952,-100.46525,'CGMU5382260','20240530'],
  [20.57604,-103.35392,'17AL2N','20240530'],
  [20.55465,-100.78950,'60BB4Y','20240530'],
  [20.65188,-103.04398,'66AJ7U','20240530'],
  [22.50509,-102.36565,'48UE6D','20240530'],
  [20.95415,-100.42297,'MRKU7793213/MSKU5703320','20240530'],
  [19.63217,-99.02959,'22AX3X','20240530'],
  [19.738192,-99.211532,'KOCU5127340','20240530'],
  [19.87939,-100.13948,'WHSU5943791/WHSU6741347','20240530'],
  [20.700646,-100.337353,'51AX6D','20240530'],
  [19.34380,-99.03422,'82AJ1N','20240530'],
  [18.8648453,-90.7381516,'07AM9A','20240530'],
  [21.28961,-102.23420,'827ER9','20240530'],
  [18.944473,-103.9077,'CMAU4442561/CMAU9514350','20240531'],
  [20.49057,-103.26855,'827ER9','20240531'],
  [25.71909,-100.54180,'55BA5Y','20240531'],
  [17.44386,-99.47817,'22AG3N','20240531'],
  [19.345437,-99.287597,'MRSU4681505','20240531'],
  [18.99161,-98.24925,'99AS5E','20240531'],
  [17.46489,-93.63579,'61AZ1Y','20240531'],
  [19.93154,-103.52999,'858AE6','20240531'],
  [19.35024,-98.98593,'38BA5F','20240531'],
  [19.871584,-99.338808,'HASU5144970/MRKU2321214','20240531'],
  [24.92447,-103.70838,'15AZ3R','20240531'],
  [20.564042,-100.230771,'59BA4T','20240531'],
  [15.31178,-92.35563,'52AT5C','20240531'],
  [19.6248,-101.2786,'FCIU8095379','20240531'],
  [19.69778,-99.20110,'17FA3R','20240531'],
  [18.960499,-103.878609,'CAAU6655540','20240531'],
  [20.275798,-102.392926,'MRKU2260232','20240601'],
  [25.491999,-101.516856,'70BE6F','20240601'],
  [20.572551,-100.471583,'71BE6F','20240601'],
  [19.338612,-96.491821,'MRKU0046290,GESU5903919','20240601'],
  [25.79774,-100.45481,'82AZ7N','20240601'],
  [19.99385,-99.47088,'36AT5H','20240601'],
  [28.04843,-105.33004,'429AL8','20240601'],
  [20.40793,-102.74105,'MSKU1577760/MRKU3144896','20240601'],
  [19.367086,-103.562538,'09AZ2E','20240601'],
  [19.83742,-99.94443,'MSNU7468462','20240601'],
  [21.3234215,-100.6163050,'30AN7B','20240602'],
  [19.120813,-103.775198,'MRSU4408511/MRKU6082817','20240602'],
  [21.30624,-102.09067,'MAGU5351/CMAU8623017','20240602'],
  [27.18935,-99.65799,'01AP4W','20240602'],
  [19.579474,-99.021669,'37AP6K','20240602'],
  [19.287998,-98.461600,'71AN2G','20240602'],
  [19.837084,-99.94241,'MSKU9685863/MRSU6382801','20240602'],
  [18.245357,-95.668640,'45AN6Z','20240602'],
  [18.944227,-103.905465,'FBIU5299504','20240603'],
  [20.18148,-99.7663,'TGHU6346404/CMAU3566450','20240603'],
  [20.413303,-100.431461,'68AM7A','20240603'],
  [25.6130450,-100.8471345,'57BA4T','20240603'],
  [18.03035,-93.81226,'44AX5E','20240603'],
  [22.03715,-102.27995,'TCKU3740249/MEDU2874590','20240603'],
  [23.273859,-102.326833,'APHU6444776','20240603'],
  [18.9442,-103.9048,'SUDU1800408/PONU0089328','20240603'],
  [20.01809,-87.67563,'73AP7K','20240603'],
  [21.13757,-86.82619,'41BD4E','20240604'],
  [22.994313,-99.731165,'06UB3A','20240604'],
  [24.6709071,-100.2990524,'57BA4T','20240604'],
  [19.27461,-98.43526,'37BA8R','20240604'],
  [17.55079,-99.50088,'22AG3N','20240604'],
  [19.300129,-98.767204,'36AP6K','20240604'],
  [20.03525,-99.45384,'69AX6E','20240604'],
  [24.84697,-98.14661,'85AX1C','20240604'],
  
  
  ];
  