import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {InformesFuncionalidadesForm}  from "../../Catalogos";


function InformesFuncionalidadesPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
        <Catalogo Ctlogo="informesFuncionalidades" tamanio="45%" FCtlogo= { InformesFuncionalidadesForm } catalogos= {{ 0:"informes" , 1:"informesFuncionalidades"} } />
        </Grid.Col>
    </SiteWrapper>
  );
}
export default InformesFuncionalidadesPage;
