import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import PerfilesTabs from "../../components/TabsLayout.react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
var FilaAct=[];
function validaActivo(evento, FHabilitados, columna) {
  let regresa = 0;
  //console.log("evento:" + evento + " columna.id:" + columna.id + " columna.nombre:" + columna.nombre + " regresa:" + regresa);
  //console.log("FHabilitados");
  //console.log(FHabilitados);
  FHabilitados.map( (elementos, index) => {
    if (elementos.idPrivilegio==columna.id)
      {
        regresa = 1;
        //console.log("elementos.idPrivilegio:"+elementos.idPrivilegio + " columna.id:"+columna.id);
      }
    }
    )
  FilaAct[columna.id]=regresa;
  //console.log(FilaAct);
  return regresa
}
class Privilegios extends Component {
  constructor(props) {
    super(props);
    this.state = {
                     id:"",
                 nombre:"",
                estatus:false,
  Privilegios_arraylist:[],
                 accion:this.props.accion, //'nuevo'// modificar, nuevo
                 FArray:[],
  //perfilesPrivilegios:[],
            Privilegios:[],
           FHabilitados:[],
                 search:"",
             currentTap:0,
    };

    //this.Etiqueta_array = [];
    //this.perfilEtiquetaId = 0;
    //this.perfilObject = [];
    //this.perfilesObject = [];

    //this.checarEtiqueta = 0;      //temporal render
    //this.idPerfilEtiqueta = 0; //temporal render
    //this.perfilEtiquetaObject = [];
    this.Array = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      this.setState({          id:this.props.elementos.id });
      this.setState({      nombre:this.props.elementos.nombre });
      //this.setState({ descripcion:this.props.elementos.descripcion });
      //this.PrivilegiosObject = this.props.elementos;
      //console.log("this.props.elementos:");
      //console.log(this.props.elementos);
      this.cargaPrivilegios("");
      this.cargaPerfilesPrivilegios(this.props.elementos.id);
    } else {
    }
  }
  changeEvento =(evento, PerfilId, columna) => {
    //console.log("evento:"+evento + " PerfilId:"+PerfilId + " columna:");
    //console.log(columna);
    let objetoIF = { "idPerfil":PerfilId, "idPrivilegio":evento+1}
    objetoIF.idPrivilegio=columna.id
    if (FilaAct[columna.id]===0){
    //                               principal   temporal default       id     parametros opcional          state              cerrar modal       si error actualizar
    //              (apiRaiz="",              api="",metodo,id, params,                   storeFunction="",  stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", url= conf.api_raiz, filtro="" ){
      Crud_Catalogos("perfilesPrivilegios", "", "post", "", objetoIF , "" , "", "", [], "", "", "").then((returnVal) => {
        //console.log("71 returnVal");
        //console.log(returnVal);
        var tempo = this.state.FHabilitados;
        tempo.push(returnVal);
        this.setState({ FHabilitados:tempo });
        tempo=[];
        FilaAct[columna.id]=1
      })
    }else{
      Crud_Catalogos("perfilesPrivilegios"+"/"+PerfilId, "", "delete", columna.id, "" , "" , "FHabilitados").then((returnVal) => {
        console.log("PerfilId");
        console.log(PerfilId);
        var tempo = this.state.FHabilitados;
        for(let i=0; i< tempo.length; i++){
          if (tempo[i].idPrivilegio===columna.id){
            tempo.splice(i, 1);
            this.setState({ FHabilitados:tempo });
          }
        }
        //console.log(tempo);
        tempo=[];
        FilaAct[columna.id]=0;
      })
    }
  }
  componentDidUpdate = (nexProps) => {
    if (nexProps.elementos.Privilegios !== this.state.Privilegios) {
      //alert("es igual")
    } else { /*alert(this.state.Privilegios) */ }
  }
  changeName = (event) => {
    this.perfilesObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  }
  changeEstatus = (event) => {
    event.target.checked ? this.setState({ estatus: 1 }) : this.setState({ estatus: 0 });
  }

  changeEtiqueta = (etiquetaindex, privId, estatus) => {
    this.perfilEtiquetaObject = {
      "idPerfil": this.state.id,
      "idPrivilegio": privId,
      "nombre": this.props.stor.Privilegios_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[etiquetaindex].nombre
    }
    if (this.PrivilegiosArray[etiquetaindex].estatus === 1) { this.PrivilegiosObject.Privilegios.splice(etiquetaindex, 1) }
    this.PrivilegiosArray[etiquetaindex].estatus === 1 ?
      this.props.getfuntion("delete", "", this.PrivilegiosArray[etiquetaindex].id, this.perfilObject, "perfilesPrivilegios", this.props.stor.perfiles_update)
      :
      this.props.getfuntion("post", this.perfilEtiquetaObject, this.state.id, this.perfilObject, "perfilesPrivilegios", this.props.stor.perfiles_update, "privilegios");
  }


  cargaPrivilegios(filtro) {
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("privilegios", "", "get", "",[],"" , "", "", [], "", "", "").then((regresaValores) => {
    this.setState({ Privilegios:regresaValores });
    //console.log("regresaPrivilegios");
    //console.log(regresaValores);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaPerfilesPrivilegios(filtro) {
    //console.log("Filtro:"+filtro);
  //Crud_Catalogos("funcionalidades",   "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("perfilesPrivilegios", "", "get", "",[],"" , "", "", [], "", "", "/"+filtro).then((regresaValores) => {
    //this.setState({ perfilesPrivilegios:regresaValores });
    this.setState({ FHabilitados:regresaValores });
    //console.log("perfilesPrivilegios");
    //console.log(regresaValores);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }

  delete()   { this.props.getfuntion("delete", "", this.state.id); }
  crud_put() { this.props.getfuntion("put", { "id":this.state.id, "nombre":this.state.nombre,"estatus":this.state.estatus}); }
  crud_post(){ this.props.getfuntion("post",{ "nombre":this.state.nombre, "estatus":this.state.estatus }); }

  checkedEtiqueta = (indice) => {
    return this.PrivilegiosArray[indice].estatus
  }
  checarPrivilegios = (estatus, id) => {
    this.PrivilegiosArray.push({ estatus: estatus, id: id })
  }
  checarEtiquetaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.PrivilegiosArray = [];
  }
  selectedTab = (index) => {
    this.setState({ currentTap: index })
  }
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }
  render() {

    return (
      <div>

        {/*
          *Privilegios
          */
        }

        {this.cleanArrayList()}
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Privilegios" isCollapsible={false} isCollapsed={false} options={<Form.Group>
                    <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.state.Privilegios.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>
                                    <Form.Switch type="Checkbox" name="toggle" value="option1" className={"disabled"}
                                    checked={ validaActivo(index, this.state.FHabilitados, elemento) }
                                    onChange={this.estaPrivilegioActivo(elemento["clave"]) ?() => this.changeEvento(index, this.state.id, elemento): null} />
                                  </Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}

      </div>
    );
  }
}
export default Privilegios;
