import React, { useEffect, useState } from "react";
import Stomp from "stompjs";
import axios from "axios";
//import ActiveMq from "./ActiveMq";
let clientRef = null;
let instance = axios.create();
const username = "WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b";
const password = "DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn";
let lastheartbeatTelefono = null;
let lastheartbeatOne = null;
let socketOpened = false;
const url ="wss://b-c3b81277-c0d6-4396-b2c1-a6dc4c8d9b80-1.mq.us-east-1.amazonaws.com:61619";
const user = "sistemas";
const pass = "f4By4XqB8kYt";
const usuario = JSON.parse(sessionStorage.getItem("usuario"));
let client = Stomp.client(url);
const TelefonoMensajeria = ({
  topic,
  mensaje,
  usuario,
  actualValues,
  setNumero,
  setLlamar,
  setColgar,
  setTransfer,
  toggleMute,
  setContestar,
  setUsuarioEntrante,
  setEnLlamada,
  setShowIncommingCall,
  setShowTranferModal,
}) => {
  useEffect(() => {
    conectar();
  }, []);
  const conectar = () => {
    console.log("conectar al websocket: ");
    client = Stomp.client(url);
    client.heartbeat = {
      incoming: 20000,
      outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        //Conect callback
        //console.log(frame);
        if (topic && topic.length) {
          for (let t of topic) {
            client.subscribe(t, (message) => {
              if (message.body.includes("{")) {
                udate(message.body);
              }
              console.log(message.body);
            });
          }
        }
        console.log("REQUEST DATA");
        mensaje.subject = "REQUEST DATA";
        sendMessage(JSON.stringify(mensaje), usuario);
        let mensajeTemp = { ...mensaje };
        mensajeTemp.subject = "heartbeat";
        if (mensajeTemp.id === "RECIBER") {
          //telefonoPage envia latido
          mensajeTemp.msg = new Date().getTime();
          console.log("telefonoPageHeartbeat: " + mensajeTemp);
          sendMessage(JSON.stringify(mensajeTemp), usuario);
        }
      },
      (err) => {
        //error useCallback
        if (err && err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }
        console.log(err);
      }
    );
  }
  useEffect(() => {
    if (mensaje.subject) {
      sendMessage(JSON.stringify(mensaje), usuario);
    }
  }, [mensaje.subject]);
  
  const sendMessage = (msg, usuario) => {
    client.send("/topic/telefono-" + usuario, {}, msg);
  }

  const udate = (message) => {
    message = message ? JSON.parse(message) : message;
    //console.log(message);
    if (mensaje.id === "RECIBER") {
      switch (message.subject) {
        case "heartbeat-entrega":
          //console.log("telefonoPageHeartbeat RECIBER ....!");
          if (message.msg) {
            lastheartbeatOne = message.msg;
          }
          break;
        case "LOG UOUT":
          console.log("la sesion ha finalizado");
          //console.log("telefonoPageHeartbeat RECIBER ....!");
          break;
        case "ADD NUMBER":
          setNumero(message.msg.numero);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "CALL USER":
          setNumero(message.msg.numero);
          setLlamar(message.msg.llamar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "END CALL":
          setColgar(message.msg.colgar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "TRANSFER":
          setTransfer(message.msg.numero);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "MUTE":
          toggleMute();
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "ANSWER CALL":
          setContestar(message.msg.responder);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "IGNORE CALL":
          //setUsuarioEntrante(message.msg.ignorar);
          console.log("Se ha recibido un mensaje => ", message);
          break;
        case "REQUEST DATA":
          console.log("Se ha recibido un mensaje => ", message);
          sendMessage(
            JSON.stringify({
              id: "SENDER",
              subject: "INITIALIZATION",
              msg: {
                user: actualValues.user,
                enLlamada: actualValues.enllamada,
              },
            }),
            usuario
          );
          break;
        default:
          break;
      }
    }
    if (mensaje.id === "SENDER") {
      switch (message.subject) {
        case "heartbeat":
          lastheartbeatTelefono = message.msg;
          break;
        case "INITIALIZATION":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.user);
          setEnLlamada(message.msg.enLlamada);
          break;
        case "INCOMMING CALL":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.contacto);
          setShowIncommingCall(message.msg.showModal);
          break;
        case "HANGING UP":
          console.log("Se ha recibido un mensaje => ", message);
          setUsuarioEntrante(message.msg.contacto);
          setEnLlamada(false);
          setShowIncommingCall(false);
          setShowTranferModal(false);
          break;
        case "TRANSFER COMPLETED":
          setUsuarioEntrante(message.msg.contacto);
          setEnLlamada(false);
          setShowIncommingCall(false);
          setShowTranferModal(false);
        default:
          break;
      }
    }
  }
  return <></>;
};
export default TelefonoMensajeria;
