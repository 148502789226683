import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../SiteWrapper.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import CSVReader from 'react-csv-reader'
import Select from "react-select";
import { TablePageable } from "../../Helpers";
import { Button, Form, Icon } from "tabler-react";
import { timeToDatefull, timeToTimeFull } from "../../herramientas/DateFormat";
import classes from "./GpsEnvio.css";
let saving= false;
let estatus={};
const GpsEnvioPage =()=> {
  const [gpsList, setGpsList] = useState([]);
  const [gps, setGps] = useState({});
  const [dataEstatusUno, setDataEstatusUno] = useState({data: {}, actualiza: false});
  const [enviar, setEnviar] = useState(false);
  const [data, setData] = useState([]);
  const [dataEstatus, setDataEstatus] = useState({inserta: [], actualiza: []});
  const [originalFile, setOriginalFile] = useState({});

  useEffect(() => {
    getfuntion("get", "", "", "gps", "gps");
    getfuntion("get", "", "", "gpsEstatus", "estatus");
	}, []);
  useEffect(() => {
    procesarCsv();
	}, [data]);
  useEffect(() => {
    if( !dataEstatus.inserta.length && !dataEstatus.actualiza.length ){
      setData([]);
    }
	}, [dataEstatus]);
  useEffect(() => {
    if( gps.id && enviar ){
      procesarUnGps();
    }
	}, [enviar]);
  const procesarUnGps =async()=>{
    await getfuntion("get", "", "", "gps_estatus_log/ultimo/idgps/"+gps.id, "gps_estatus_log").then((returnVal) => {
      //console.log(returnVal);
      if(returnVal && (!returnVal.fechaInicio || !returnVal.fechaFin)){// si no tiene fecha fechaInicio o fechaFin se actualiza
        setDataEstatusUno({data: {...returnVal}, actualiza: true});
      }else{//se inserta
        setDataEstatusUno({
          data: {
            "idGps": gps.id,
            "imei": gps.id.imei,
            "idEstatus": 137,
            "fechaAct": null,
            "fechaInicio": null,
            "fechaFin": null,
            "descripcion": "",
            "origen": "",
            "destino": "",
            "cordenadasOrigen": null,
            "cordenadasDestino": null,
            "fijo": false
            },
          actualiza: false});
      }
    });
  }
  const procesarCsv =async()=>{
    if(data.length <= 1){
      console.log("sin datos ..!");
      return;
    }else{
      console.log(data.length);
    }
    let gpsEstatus_inserta= [];
    let gpsEstatus_actualiza= [];
    for( let i= 1; i< data.length; i++ ){
      if( data[i].length > 1 ){
        let reg= {};
        let procesa=  false;
        for( let j= 0; j< data[0].length; j++){
          if(
              (data[0][j] === "imei" && data[i][j].length)
              || (data[0][j] === "idGps" && data[i][j].length)
            ){
              procesa= true;
          }
        }
        if(procesa){
          for( let j= 0; j< data[0].length; j++){
            reg[data[0][j]]= data[i][j];
            if( data[0][j] === "idEstatus" ){//typeof data[i][j] !== "number"
              if(!Number(data[i][j])){
                reg[data[0][j]]= (estatus[data[i][j]] ? estatus[data[i][j]].id: null);
              }else{
                reg[data[0][j]]= Number(data[i][j]);
              }
              //por el momento todo es envio
              reg[data[0][j]]= 137;
            }
            if( data[0][j] === "fijo" ){//typeof data[i][j] !== "number"
              reg[data[0][j]]= (data[i][j].toLowerCase() === "true" ? true: false);
            }
          }
          await getfuntion("get", "", "", "gps_estatus_log/ultimo/idgps/"+reg.idGps, "gps_estatus_log").then((returnVal) => {
            //console.log(returnVal);
            if(returnVal && (!returnVal.fechaInicio || !returnVal.fechaFin)){// si no tiene fecha fechaInicio o fechaFin se actualiza
              let gpsEstatusTemp= {...returnVal};
              gpsEstatusTemp.idEstatus= reg.idEstatus;
              gpsEstatusTemp.fechaInicio= reg.fechaInicio;
              gpsEstatusTemp.fechaFin= reg.fechaFin;
              gpsEstatusTemp.descripcion= reg.descripcion;
              gpsEstatusTemp.origen= reg.origen;
              gpsEstatusTemp.destino= reg.destino;
              gpsEstatusTemp.cordenadasOrigen= reg.cordenadasOrigen;
              gpsEstatusTemp.cordenadasDestino= reg.cordenadasDestino;
              gpsEstatusTemp.fijo= reg.fijo;              
              gpsEstatus_actualiza.push(gpsEstatusTemp);
            }else{//se inserta
              gpsEstatus_inserta.push(reg);
            }
          });
        }
      }
    }
    console.log(gpsEstatus_inserta, gpsEstatus_actualiza);
    setDataEstatus({inserta: gpsEstatus_inserta, actualiza: gpsEstatus_actualiza});
  }

  const getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "nada", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "gps"){ 
                setGpsList(returnVal);
              }
              if (stateVar === "estatus"){ 
                for( let e of returnVal){
                  estatus[e.nombre]= e;
                }
                setGpsList(returnVal);
              }
              if (stateVar === "gps_estatus_log"){ 
                return returnVal;
              }
            }
            break;
          case "post":
            {
              if (stateVar === "gps_estatus_log_"){
                setGps({});
                setEnviar(false);
                setDataEstatusUno({data: {}, actualiza: false});
                getfuntion("get", "", "", "gps", "gps");
                saving= false;
              }
              if (stateVar === "gps_estatus_log_list"){
                setDataEstatus({...dataEstatus, inserta: []});
                getfuntion("get", "", "", "gps", "gps");
                saving= false;
              }
            }
            break;
          case "put":
            {
              if (stateVar === "gps_estatus_log"){ 
                setGps({});
                setEnviar(false);
                setDataEstatusUno({data: {}, actualiza: false});
                getfuntion("get", "", "", "gps", "gps");
                saving= false;
              }
              if (stateVar === "gps_estatus_log_list"){ 
                setDataEstatus({...dataEstatus, actualiza: []});
                getfuntion("get", "", "", "gps", "gps");
                saving= false;
              }
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
    })
  }
  const guardar =()=>{
    saving= true;
    let dataEstatusUnoTemp= {...dataEstatusUno.data};
    if(dataEstatusUnoTemp.fechaInicio)
      dataEstatusUnoTemp.fechaInicio= new Date(dataEstatusUnoTemp.fechaInicio).getTime();
    if(dataEstatusUnoTemp.fechaFin)
      dataEstatusUnoTemp.fechaFin= new Date(dataEstatusUnoTemp.fechaFin).getTime();
    if(dataEstatusUno.actualiza){
      getfuntion("put", dataEstatusUnoTemp, "", "gps_estatus_log", "gps_estatus_log");
    }else{
      getfuntion("post", dataEstatusUnoTemp, "", "gps_estatus_log", "gps_estatus_log");
    }
    console.log(dataEstatusUno)

  }
  const crearList =()=>{
    saving= true;
    let objTemp= [...dataEstatus.inserta];
    for(let e of objTemp){
      e.fechaInicio= new Date(e.fechaInicio).getTime();
      e.fechaFin= new Date(e.fechaFin).getTime();
    }
    getfuntion("post", objTemp, "", "gps_estatus_log/list", "gps_estatus_log_list");
  }
  const actualizarList =()=>{
    saving= true;
    let objTemp= [...dataEstatus.actualiza];
    for(let e of objTemp){
      e.fechaInicio= new Date(e.fechaInicio).getTime();
      e.fechaFin= new Date(e.fechaFin).getTime();
    }
    getfuntion("put", objTemp, "", "gps_estatus_log/list", "gps_estatus_log_list");
  }
  
  return (
    <SiteWrapper>
      <div className="container-fluid" style={{ height: "90vh", "font-size": "smaller"}}>{/** width: "100%",  */}        
        <div className="row">{/** Listado de gps */}
          <div className="col-12" hidden={enviar || data.length}>
            <TablePageable
              titulo={
                <h5>
                  <div className="row">
                    <div className="col">
                      <span>Listado de gps</span>
                    </div>
                    <div className="col-2" hidden={gps.id}>
                      <span className="image-upload">
                        <label for="file-input">
                          <i className="fa fa-cloud-upload btn" style={{"font-size": "24px"}}></i>
                        </label>
                        <CSVReader
                          cssClass = "image-upload" 
                          inputId = "file-input"
                          inputStyle={{color: 'red'}}
                          onFileLoaded={(data, fileInfo, originalFile) =>{
                            setData(data);
                            setOriginalFile(originalFile)
                            //console.log(data, fileInfo, originalFile)
                          }}
                        />                   
                      </span>
                    </div>
                    <div className="col" hidden={!originalFile.name || enviar}>
                      <label className="mt-4">{ originalFile? originalFile.name: "" }</label>
                    </div>
                    <div className="col" hidden={!gps.id}>
                      <i onClick={()=> setEnviar(true) } className="fa fa-send btn" style={{"font-size": "24px"}}></i>
                    </div>
                    <div className="col" hidden={!gps.id}>
                      <i
                        onClick={()=>{
                          setGps({});
                          setEnviar(false);
                          }}
                        className="fa fa-close btn " style={{"font-size": "24px"}}></i>
                    </div>
                    <div className="col" hidden={!originalFile.name}>
                      <i
                        onClick={()=>{ window.location.reload(); }}
                        className="fa fa-close btn " style={{"font-size": "24px"}}></i>
                    </div>
                  </div>
                </h5> }
              lista= { gpsList }
              columnas={
                [
                  { columna: "id", label: "Id" },
                  { columna: "nombre", label: "Nombre" },
                  { columna: "gpsEstatus.nombre", label: "gpsEstatus" },
                  { columna: "gpsHardware.nombre", label: "Hardware" },
                  { columna: "marca", label: "Marca" },
                  { columna: "imei", label: "Imei" },
                  { columna: "altaFechaGg", label: "Fecha" },
                ]
              }
              id= "id"
              selected={(e)=>{ setGps(e) }}
              csvname= "Gps Mobiles"
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>
        <div className="row">{/** Actualizar Estatus */}
          <div className="col-12" hidden={!data.length}>
            <TablePageable
              titulo={
                <h5>
                  <div className="row">
                    <div className="col">
                      <span>Actualizar Estatus</span>
                    </div>
                    <div className="col" hidden={!originalFile.name || enviar}>
                      <label className="mt-4">{ originalFile? originalFile.name: "" }</label>
                    </div>
                    <div className="col" hidden={!dataEstatus.actualiza.length}>
                      <i onClick={()=>{ if(!saving){ actualizarList()} } } className="fa fa-save btn" style={{"font-size": "24px"}}></i>
                    </div>
                    <div className="col">
                      <i
                        onClick={()=>{ window.location.reload(); }}
                        className="fa fa-close btn " style={{"font-size": "24px"}}></i>
                    </div>
                  </div>
                </h5> }
              lista= { dataEstatus.actualiza }
              columnas={
                [
                  { columna: "idGps", label: "Id" },
                  { columna: "imei", label: "Imei" },
                  { columna: "idEstatus", label: "Estatus" },
                  { columna: "fechaInicio", label: "Fecha Inicio" },
                  { columna: "fechaFin", label: "Fecha Fin" },
                  { columna: "descripcion", label: "Descripcion" },
                  { columna: "origen", label: "Origen" },
                  { columna: "destino", label: "Destino" },
                  { columna: "cordenadasOrigen", label: "Cordenadas Origen" },
                  { columna: "cordenadasDestino", label: "Cordenadas Destino" },
                  { columna: "fijo", label: "Fijo" },
                ]
              }
              id= "id"
              selected={(e)=>{ setGps(e) }}
              //csvname= "Gps Mobiles"
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>
        <div className="row">{/** Inserta Estatus */}
          <div className="col-12" hidden={!data.length}>
            <TablePageable
              titulo={
                <h5>
                  <div className="row">
                    <div className="col">
                      <span>Inserta Estatus</span>
                    </div>
                    <div className="col" hidden={!originalFile.name || enviar}>
                      <label className="mt-4">{ originalFile? originalFile.name: "" }</label>
                    </div>
                    <div className="col" hidden={!dataEstatus.inserta.length}>
                      <i onClick={()=>{ if(!saving){ crearList() }} } className="fa fa-save btn" style={{"font-size": "24px"}}></i>
                    </div>
                    <div className="col">
                      <i
                        onClick={()=>{ window.location.reload(); }}
                        className="fa fa-close btn " style={{"font-size": "24px"}}></i>
                    </div>
                  </div>
                </h5> }
              lista= { dataEstatus.inserta }
              columnas={
                [
                  { columna: "idGps", label: "Id" },
                  { columna: "imei", label: "Imei" },
                  { columna: "idEstatus", label: "Estatus" },
                  { columna: "fechaInicio", label: "Fecha Inicio" },
                  { columna: "fechaFin", label: "Fecha Fin" },
                  { columna: "descripcion", label: "Descripcion" },
                  { columna: "origen", label: "Origen" },
                  { columna: "destino", label: "Destino" },
                  { columna: "cordenadasOrigen", label: "Cordenadas Origen" },
                  { columna: "cordenadasDestino", label: "Cordenadas Destino" },
                  { columna: "fijo", label: "Fijo" },
                ]
              }
              id= "id"
              selected={(e)=>{ setGps(e) }}
              //csvname= "Gps Mobiles"
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>{/*
        {
          data: {
            "idGps": gps.id,
            "imei": gps.id.imei,
            "idEstatus": 137,
            "fechaAct": null,
            "fechaInicio": null,
            "fechaFin": null,
            "descripcion": "",
            "origen": "",
            "destino": "",
            "cordenadasOrigen": null,
            "cordenadasDestino": null,
            "fijo": false
            },
          actualiza: false}
        */}
        { gps.id && enviar &&
          <div className="row">
            <div className="col-12">
              <h4>{gps.nombre}/{gps.id}/{gps.nombre}/{gps.imei}/</h4>
            </div>
            <div className="col-4">{/** Fecha inicio */}
              <div className="form-group">
                <label for="envio">Fecha de envio</label>
                <div class="input-group mb-1 mr-sm-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="date" className="form-control" id="envio" placeholder="yyyy-mm-dd"
                        value={dataEstatusUno.data? dataEstatusUno.data.fechaInicio? timeToDatefull(dataEstatusUno.data.fechaInicio):null: null }
                      />
                    </div>
                    <div class="input-group-text">
                      <input type="time" className="form-control" id="envio" placeholder="hh:mm:ss"
                      value={dataEstatusUno.data? dataEstatusUno.data.fechaInicio? timeToTimeFull(dataEstatusUno.data.fechaInicio):null: null }
                      />
                    </div>
                  </div>                  
                </div>
                <small id="envio" className="form-text text-muted">Fecha y hora de envio</small>
              </div>
            </div>
            <div className="col-4">{/** Fecha fin */}
              <div className="form-group">
                <label for="envio">Fecha fin de envio</label>
                <div class="input-group mb-1 mr-sm-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="date" className="form-control" id="envio" placeholder="yyyy-mm-dd"
                        value={dataEstatusUno.data? dataEstatusUno.data.fechaFin? timeToDatefull(dataEstatusUno.data.fechaFin):null: null }
                      />
                    </div>
                    <div class="input-group-text">
                      <input type="time" className="form-control" id="envio" placeholder="hh:mm:ss"
                        value={dataEstatusUno.data? dataEstatusUno.data.fechaFin? timeToTimeFull(dataEstatusUno.data.fechaFin):null: null }
                      />
                    </div>
                  </div>                  
                </div>
                <small id="envio" className="form-text text-muted">Fecha y hora de envio</small>
              </div>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Origen">
                <Form.Input
                  name="origen"
                  type="text"
                  value={dataEstatusUno.data? dataEstatusUno.data.origen: null}
                  onChange={(e)=>{
                    let dataTemp= {...dataEstatusUno.data};
                    dataTemp.origen= e.target.value;
                    setDataEstatusUno({...dataEstatusUno, data: dataTemp}) }}
                />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Destino">
                <Form.Input
                  name="destino"
                  type="text"
                  value={dataEstatusUno.data? dataEstatusUno.data.destino: null}
                  onChange={(e)=>{
                    let dataTemp= {...dataEstatusUno.data};
                    dataTemp.destino= e.target.value;
                    setDataEstatusUno({...dataEstatusUno, data: dataTemp}) }}
                />
              </Form.Group>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Descripción">
                <Form.Textarea
                  name="descripcion"
                  value={dataEstatusUno.data? dataEstatusUno.data.descripcion: null}
                  onChange={(e)=>{
                    let dataTemp= {...dataEstatusUno.data};
                    dataTemp.descripcion= e.target.value;
                    setDataEstatusUno({...dataEstatusUno, data: dataTemp}) }}
                />
              </Form.Group>
            </div>
            <div className="col-4">{/** Fecha fin */}
              <div className="form-group">
                <label for="coordenadas_origen">Coordenadas origen</label>
                <div class="input-group mb-1 mr-sm-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="number" className="form-control" id="coordenadas_origen_lat" placeholder="0.00"
                        value={dataEstatusUno.data? dataEstatusUno.data.cordenadasOrigen? dataEstatusUno.data.cordenadasOrigen.split(",")[0]: null:null}
                      />
                    </div>
                    <div class="input-group-text">
                      <input type="number" className="form-control" id="coordenadas_origen_log" placeholder="0.00"
                        value={dataEstatusUno.data? dataEstatusUno.data.cordenadasOrigen? dataEstatusUno.data.cordenadasOrigen.split(",")[1]: null:null}
                      />
                    </div>
                  </div>                  
                </div>
                <small id="coordenadas_origen" className="form-text text-muted">latitud, longitud</small>
              </div>
            </div>
            <div className="col-4">{/** Fecha fin */}
              <div className="form-group">
                <label for="coordenadas_destino">Coordenadas origen</label>
                <div class="input-group mb-1 mr-sm-1">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="number" className="form-control" id="coordenadas_destino_lat" placeholder="0.00"
                        value={dataEstatusUno.data? dataEstatusUno.data.cordenadasDestino? dataEstatusUno.data.cordenadasDestino.split(",")[0]: null:null}
                      />
                    </div>
                    <div class="input-group-text">
                      <input type="number" className="form-control" id="coordenadas_destino_log" placeholder="0.00"
                        value={dataEstatusUno.data? dataEstatusUno.data.cordenadasDestino? dataEstatusUno.data.cordenadasDestino.split(",")[1]: null:null}
                      />
                    </div>
                  </div>                  
                </div>
                <small id="coordenadas_destino" className="form-text text-muted">latitud, longitud</small>
              </div>
            </div>
            <div className="col col-12 col-sm-12 col-md-4">
              <Form.Group label="Gps Fijo?">{ console.log(dataEstatusUno)}
              <Form.Switch
                name="fijo"
                label={dataEstatusUno.data? dataEstatusUno.data.fijo? "El Gps es fijo": "El gps es movil": "No hay un gps seleccionado"}
                checked= {dataEstatusUno.data? dataEstatusUno.data.fijo: null}
                //value=""
                onChange={(e)=>{
                  let dataTemp= {...dataEstatusUno.data};
                  dataTemp.fijo= e.target.checked;
                  setDataEstatusUno({...dataEstatusUno, data: dataTemp }) }}
              />
              </Form.Group>
            </div>
            {/**
              [X]gel.setFechaInicio(gpsEstatusLog.getFechaInicio());
              [X]gel.setFechaFin(gpsEstatusLog.getFechaFin());
              [X]gel.setDescripcion(gpsEstatusLog.getDescripcion());
              [X]gel.setOrigen(gpsEstatusLog.getOrigen());
              [X]gel.setDestino(gpsEstatusLog.getDestino());
              [X]gel.setCordenadasOrigen(gpsEstatusLog.getCordenadasOrigen());
              [X]gel.setCordenadasDestino(gpsEstatusLog.getCordenadasDestino());
              [X]gel.setFijo(gpsEstatusLog.getFijo());
             */}
            
            {/* ********************************************************************************************************** */}
            <div className="col-12">
              <span className="badge float-right">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="danger"
                  onClick={()=>{ setGps({}); setEnviar(false); }}
                >
                  <span className="badge">
                    <Icon link={true} name="x" />
                    Cancelar
                  </span>
                </Button>
              </span>
              <span className="badge float-right">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  onClick={()=>{ if(!saving){ guardar();  } }}
                >
                  <span className="badge">
                    <Icon link={true} name="save" />
                    Guardar
                  </span>
                </Button>
              </span>
            </div>
          </div>
        }        
      </div>
    </SiteWrapper>
  );
}

export default GpsEnvioPage;
