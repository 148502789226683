import React, { useEffect, useState } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";
import ModalLay from "../../../../components/ModalLay/ModalLay";
import { CorreosClit, Etiquetas, Contactos } from "../../../../Catalogos/Cliente/Formularios";
const Cliente =({obj, stor, mov})=> {
  const [contactos, setContactos]= useState([]);
  const [contactosTemporales, setContactosTemporales]= useState([]);
  const [clientesEtiquetas, setclientesEtiquetas]= useState([]);
  const [contactoTemporal, setContactoTemporal]= useState([]);
  const [tab, setTab]= useState(1);
  useEffect(() => {
		if(obj){
      getfuntion("get", "", "", "personas/cliente/"+obj.id, "contactos");
    }
	}, []);
  useEffect(() => {
		if( tab === 3 ){
      getfuntion("get", "", "", "clientes_contactos_temporal/"+obj.id, "contactos_temporales");
    }
    if( tab === 4 ){
      getfuntion("get", "", "", "clientesEtiquetas/"+obj.id, "clientesEtiquetas");
    }
	}, [tab]);
 const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "contactos" && returnVal) {
              for(let item of returnVal){
                if(!item.aMaterno){item.aMaterno=""}
                if(!item.aPaterno){item.aPaterno=""}
                if(!item.nombre){item.nombre=""}
              }
               setContactos(returnVal);
            }
            if (stateVar === "contactos_temporales" && returnVal) {
              let contTemporalesTemp= [];
              for(let c of returnVal){
                c.fecha= timeToDateFill(c.fecha);
                contTemporalesTemp.push(c);
              }
               setContactosTemporales(contTemporalesTemp);
            }
            if (stateVar === "clientesEtiquetas" && returnVal) {
              setclientesEtiquetas(returnVal);
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  return (
    
    <div className= "mt-1">
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Det</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }> Cont</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 3 ? " active":"")} onClick={()=> setTab(3) }> Cont Temp</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 4 ? " active":"")} onClick={()=> setTab(4) }> Etiq</a>
          </li>
        </ul>
        <div  hidden={tab !== 1}>
          <List.Group>
            <List.GroupItem action icon="user"> Cliente: {obj? obj.nombre: null } </List.GroupItem>
            <List.GroupItem action icon="phone"> Telefono: {obj? obj.telefono: null }, ext: {obj? obj.extension:null } </List.GroupItem>
            <List.GroupItem action icon="home"> Domicilio: {obj? obj.domicilio:null } </List.GroupItem>
          </List.Group>
        </div>
        <div  hidden={tab !== 2}>
          <h4>Contactos Fijos</h4>
          <Contactos
            accion={"modificar"}
            elementos={obj}
            stor={stor}
            plataforma={mov.idPlataforma}
          />
          <List.Group>
            
            {contactos.map((cont, index)=>
              <List.GroupItem action icon="mail">
                <div  className="badge" data-toggle="tooltip" data-placement="top" title={cont.correoEstatus? "Recibe correos":"No recibe correos"}
                                                                        style={{ color: cont.correoEstatus? "green": "red", "font-size": "100%" }}>
                  <span className="fa fa-flag"></span>                
                </div>
                {cont.correo}( {cont.aPaterno+" "+ cont.aMaterno+ " "+ cont.nombre} )
              </List.GroupItem>
            )}
          </List.Group>
        </div>
        <div  hidden={tab !== 3}>
          <h4>Contactos Temporales (Se borraran al finalizar los movs asociados)</h4>
          <TablePageable
            titulo={
              <div className="row">
                <div className="col">
                  <ModalLay
                    tamanio={"99%"}
                    title={"Contactos Temporales (Se borrara)"}
                    name={<i className="fa fa-cogs"  style={{"font-size": "15px"}}></i> }
                    formulario={CorreosClit}
                    datosForm={{
                      elementos: obj,
                    }}
                  />
                </div>
                <div className="col">
                  <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                    onClick={()=> getfuntion("get", "", "", "clientes_contactos_temporal/"+obj.id, "contactos_temporales") }
                  ></i></div>
              </div>
            }
            lista= { contactosTemporales }
            columnas={
              [
                { columna: "estatus", label:"Estatus" },
                { columna: "correo", label:"Correo" },
                { columna: "nombreCompleto", label:"Nombre Completo" },
                { columna: "fecha", label:"Fecha" },
              ]
            }
            id= "correo"
            selected={(e)=> setContactoTemporal(e)}
          />
        </div>
        <div  hidden={tab !== 4}>
          <h4>Etiquetas(Los contactos del cliente reciben las siguientes notificaciones)</h4>
          <Etiquetas
            accion={"modificar"}
            elementos={obj}
            stor={stor}
          />
        </div>
      </div>
    </div>
          
  );
}
export default Cliente;