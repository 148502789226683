import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
import { NotificationManager } from 'react-notifications';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class InformesFuncionalidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Funcionalidades: [],
      search: "",
      bloque: { value: 0, label: "Selecciona una opción" }
    };
  }
  componentDidMount = () => {
    console.log(currentUser)
    if (this.state.accion !== 'nuevo') {
     this.getfuntion("get", "", "", "informesFuncionalidades/informe/"+ this.props.elementos.id, "Funcionalidades");
    } else { }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      //console.log("returnVal");
      //console.log(returnVal);
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Funcionalidades") {
              this.setState({ Funcionalidades: returnVal });
            }
          }
          break;
        case "post":
          {            
            if (stateVar === "Funcionalidades" && returnVal) {
              NotificationManager.info( "Nueva columna agregada: "+ returnVal.idFuncionalidades, "Guardar");
              let funcTemp= this.state.Funcionalidades;
              funcTemp.push(returnVal);
              this.setState({ Funcionalidades: funcTemp });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Funcionalidades" && returnVal) {              
              let funcTemp= this.state.Funcionalidades;
              funcTemp.map((elemento, index) => {
                if (id === elemento.idInformes+"/"+elemento.idFuncionalidades) {
                  NotificationManager.info( "Se ha removido la columna: "+ elemento.idFuncionalidades, "Guardar");
                  funcTemp.splice(index, 1);
                }
              })
              this.setState({ Funcionalidades: funcTemp })
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ...."+ err);
        //console.log(err)
      }
    })
  }


  changeFuncionalidades = (f) => {
    let informeFuncionalidad= {idInformes: this.props.elementos.id, idFuncionalidades: f.id }

    if( this.isChecked(f.id) ){
      this.getfuntion("delete", "", this.props.elementos.id+"/"+f.id, "informesFuncionalidades", "Funcionalidades")      
    }else{
      this.getfuntion("post", informeFuncionalidad, "", "informesFuncionalidades", "Funcionalidades");
    }
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  isChecked=(id)=>{
    //Funcionalidades
    for( let item of this.state.Funcionalidades){
      if(id === item.idFuncionalidades){
        return true;
      }
    }
    return false;
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        {this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card title="Columnas que se exportaran en el informe" isCollapsible={false} isCollapsed={false}//isClosable
                  options={<Form.Group>
                    <span className="float-right">
                      <Form.Input className="mb-3" icon="search" placeholder="Buscar Nombre Columna..." position="append" onChange={this.searching} />
                    </span>

                  </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Bloque</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.funcionalidades_object
                                .filter(word => word.bloque.id === this.props.elementos.bloque &&
                                                word.nombre.toUpperCase().includes(this.state.search.toUpperCase())
                                                ).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>{elemento.bloque.nombre}</Table.Col>
                                  <Table.Col> <Form.Switch type="Checkbox" name="toggle" value="option1" checked={this.isChecked(elemento['id'])} className={"disabled"}
                                                  onChange={() => this.changeFuncionalidades(elemento) } /></Table.Col>
                                </Table.Row>
                              )
                            }

                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
          : null}
      </div>
    )
  }
}
export default InformesFuncionalidades;