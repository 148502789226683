import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card, Table } from "tabler-react";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";
import { mail } from "../../../herramientas/Forms";
/**
 * Notificaciones
 */
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
class PersonaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      aPaterno: "",
      aMaterno: "",
      skyangel: false,
      correo: "",
      correoEstatus: 0,
      telefono: "",
      whatsappEstatus: false,
      personasCorreo: [],
      crudbutonEnable: true, // Se desabilita cuando se hace click
      accion: this.props.accion, //'nuevo'// modificar, nuevo
    };
    this.personaObject = [];
    this.enableSave = this.enableSave.bind(this);
  }
  isEnable = (Accion) => {
    let borrar = "personas_borrar";
    let actualizar = "personas_editar";
    let agregar = "personas_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ aPaterno: this.props.elementos.aPaterno });
      this.setState({ aMaterno: this.props.elementos.aMaterno });
      this.setState({ skyangel: this.props.elementos.skyangel });
      this.setState({ correo: this.props.elementos.correo });
      this.setState({ correoEstatus: this.props.elementos.correoEstatus });
      this.setState({ telefono: this.props.elementos.telefono });
      this.setState({ whatsappEstatus: this.props.elementos.whatsappEstatus });
      this.personaObject = this.props.elementos;
    } else {
      this.personaObject = {
        nombre: "",
        aPaterno: "",
        aMaterno: "",
        skyangel: 0,
        correo: "",
        correoEstatus: 0,
        telefono: "",
        whatsappEstatus: false
      };
    }
  };

  enableSave = () => {
    if (
      this.state.nombre.length > 0 &&
      this.state.correo.length > 0 &&
      mail(this.state.correo).estatus
    ) {
      return false;
    } else {
      return true;
    }
  };

  changeName = (event) => {
    this.personaObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  };
  changePaterno = (event) => {
    this.personaObject.aPaterno = event.target.value;
    this.setState({ aPaterno: event.target.value });
  };
  changeMaterno = (event) => {
    this.personaObject.aMaterno = event.target.value;
    this.setState({ aMaterno: event.target.value });
  };

  changeEstatus = (event) => {
    this.personaObject.skyangel = event.target.checked ? 1 : 0;
    event.target.checked
      ? this.setState({ skyangel: 1 })
      : this.setState({ skyangel: 0 });
  };
  changeCorreoEstatus = (event) => {
    this.personaObject.correoEstatus = event.target.checked ? 1 : 0;
    this.setState({ correoEstatus: event.target.checked ? 1 : 0 });
  };
  changeTelefono = (e) => {
    if( e.target.value.toString().length >= 1 && e.target.value.toString().length <= 10){
      this.personaObject.telefono= e.target.value;
      this.setState({ telefono: e.target.value });
    }
  }
  changeWhatsappEstatus = (event) => {
    this.personaObject.whatsappEstatus = event.target.checked;
    this.setState({ whatsappEstatus: event.target.checked });
  };
  changecorreo = (event) => {
    if( mail(event.target.value).estatus ){
      this.getfuntion("get", "", "", "personas/correo?correo=" + event.target.value+"&validar="+ true, "personasCorreo" )
    }else{
      this.setState({personasCorreo: []});
    }
    this.personaObject.correo = event.target.value;
    this.setState({ correo: event.target.value });
  };
  guardarPersona = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.props.getfuntion("delete", "", this.personaObject.id);
    this.setState({ crudbutonEnable: false });
  }
  crud_put() {
    this.props.getfuntion("put", this.personaObject);
    this.setState({ crudbutonEnable: false });
  }
  crud_post() {
    this.props.getfuntion("post", this.personaObject);
    this.setState({ crudbutonEnable: false });
  }

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas",
    stateVar = "persona",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "tracking",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "validar") {
              NotificationManager.info(
                "Se ha enviado validacion de correo a " + this.state.correo,
                "Validación de correo"
              );
            }
            if (stateVar === "personasCorreo"){
              this.setState({personasCorreo: returnVal});
            }
            break;
          case "post":
            {
              if (stateVar === "personas") {
                // NotificationManager.info("Se han agregado las modificaciones de ( "+returnVal.nombre+" ), al catlogo general", "Modificación");
              }
            }
            break;
          case "put":
            {
              if (stateVar === "personas") {
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>
            ({this.props.elementos.id}) {this.props.elementos.nombre}
          </h1>
        ) : null}
        {/**
         * Formulario personas
         */}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeName}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Paterno">
                  <Form.Input
                    name="aPaterno"
                    type="text"
                    value={this.state.aPaterno}
                    onChange={this.changePaterno}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Materno">
                  <Form.Input
                    name="aMaterno"
                    type="text"
                    value={this.state.aMaterno}
                    onChange={this.changeMaterno}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="Skyangel">
                  <Form.Switch
                    name="skyangel"
                    label=" "
                    value={this.state.skyangel}
                    checked={this.state.skyangel}
                    onChange={this.changeEstatus}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col>
                <Form.Group label="Teléfono celular(10 digitos)">
                  <Form.Input
                    name="telefono"
                    type="number"
                    value={this.state.telefono}
                    placeholder="5544332211"
                    onChange={this.changeTelefono}
                  />
                  { this.state.telefono && this.state.telefono.toString().length >= 1 && this.state.telefono.toString().length < 10 ? (
                    <label style={{ color: "red" }}>
                      El numero de teléfono debe contener 10 digitos
                    </label>
                  ) : null}
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="¿Enviar notificaciones de WhatsApp?">
                  <Form.Switch
                    name="whatsappEstatus"
                    label={
                      (this.state.whatsappEstatus ? "Recibe" : "No recibe") +
                      " notificaciones de WhatsApp"
                    }
                    checked={this.state.whatsappEstatus}
                    onChange={this.changeWhatsappEstatus}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col>
                <Form.Group label="correo">
                  <Form.Input
                    name="correo"
                    type="email"
                    value={this.state.correo}
                    placeholder="example@skyangel.com"
                    onChange={this.changecorreo}
                  />
                  { this.state.correo.length > 3 ? (
                    <label style={{ color: "red" }}>
                      { mail(this.state.correo).mensaje }
                    </label>
                  ) : null}
                  { this.state.personasCorreo.length?
                    <p>
                      <h4>Los siguientes contactos estan realiconados con el correo ingresado</h4>
                      { this.state.personasCorreo.map((c,index)=>
                        <label style={{ color: "red" }} key={c.id}>
                          {c.nombre+" "+c.aPaterno+" "+c.aMaterno+"<"+c.correo+">"}
                        </label>
                      )}
                    </p>
                  :null}
                </Form.Group>
              </Grid.Col>              
              <Grid.Col>
                <Form.Group label="¿Enviar notificaciones de correo?">
                  <Form.Switch
                    name="correoestatus"
                    label={
                      (this.state.correoEstatus ? "Recibe" : "No recibe") +
                      " notificaciones de correo"
                    }
                    checked={this.state.correoEstatus}
                    onChange={this.changeCorreoEstatus}
                  />
                  {/*  onChange={this.changeCorreoEstatus}*/}
                  <div className="float-right">
                    {this.isEnable("actualizar") ? (
                      <span class="badge">
                        <Button
                          size="sm"
                          pill
                          outline
                          RootComponent="a"
                          color="primary"
                          disabled={
                            this.enableSave() || !this.state.crudbutonEnable
                          }
                          onClick={() =>
                            this.getfuntion(
                              "get",
                              "",
                              "",
                              "contacto/validar/61/" +
                                this.props.elementos.id +
                                "?mail=" +
                                this.state.correo,
                              "validar"
                            )
                          }
                        >
                          {" "}
                          <Icon link={true} name="send" />
                          Validar correo
                        </Button>
                      </span>
                    ) : null}
                  </div>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar") || this.isEnable("agregar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarPersona}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default PersonaForm;
