import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import { Table, Card } from "tabler-react";
import Select from "react-select";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";

class InformeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      descripcion: "",
      bloque: { value: null, label: "Selecciona una opción" },
      accion: this.props.accion,
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.enableSave = this.enableSave.bind(this);
    this.bloques = [];
  }
  isEnable = (Accion) => {
    let borrar = "informes_borrar";
    let actualizar = "informes_editar";
    let agregar = "informes_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  componentWillMount() {}

  componentDidMount = () => {
    if (this.props.stor.bloques_object) {
      this.props.stor.bloques_object.map((bl, index) => {
        if (bl.nombre.toUpperCase().includes("REPORT")) {
          if (this.state.accion !== "nuevo") {
            if (bl.id === this.props.elementos.bloque) {
              this.setState({ bloque: { value: bl.id, label: bl.nombre } });
            }
          }
          this.bloques.push({ value: bl.id, label: bl.nombre });
        }
      });
    }
    if (this.state.accion === "nuevo") {
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ descripcion: this.props.elementos.descripcion });
      //this.setState({bloque:this.props.elementos.bloque});
    }
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = "",
    enviar = false
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "put":
            {
              if (stateVar === "entregas") {
              }
            }
            break;
          case "post":
            {
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  enableSave = () => {
    //console.log("Enable button: "+this.state.nombre)
    if (this.state.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  guardarInforme = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };

  changeName = (event) => {
    this.setState({ nombre: event.target.value });
    //console.log("nombre: " + this.state.nombre);
  };
  changeDescripcion = (event) => {
    this.setState({ descripcion: event.target.value });
  };
  changeBloque = (e) => {
    this.setState({ bloque: e });
  };

  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
      bloque: this.state.bloque.value,
    });
  }
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", {
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
      bloque: this.state.bloque.value,
    });
  }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>{this.props.elementos.nombre}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={2}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={5}>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeName}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={5}>
                <Form.Group label="Bloque">
                  <Select
                    value={this.state.bloque}
                    onChange={this.changeBloque}
                    options={this.bloques}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={9}>
                <Form.Group label="Descripcion">
                  <Form.Input
                    name="descripcion"
                    type="text"
                    value={this.state.descripcion}
                    onChange={this.changeDescripcion}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarInforme}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default InformeForm;
