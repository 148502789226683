// @flow
import React, { Component } from "react";
import { Table, Button, Form, Icon, Card, Grid } from "tabler-react";
class SalaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      descripcion: "",
      evento: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      salasMonitoreo_arraylist: [],
      crudbutonEnable: true, // Se desabilita cuando se hace click
    };
    this.enableSave = this.enableSave.bind(this);
    this.salasMonitoreoObject = [];
    this.SalasMonitoreo_array = [];
    this.checaretiqueta = false;
  }
  isEnable = (Accion) => {
    let borrar = "salas_borrar";
    let actualizar = "salas_editar";
    let agregar = "salas_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ descripcion: this.props.elementos.descripcion });
      this.setState({ salasMonitoreo_arraylist: this.SalasMonitoreo_array });
      this.salasMonitoreoObject = this.props.elementos;
    } else {
      this.salasMonitoreoObject = { nombre: "", descripcion: "", eventos: {} };
    }
  };
  enableSave = () => {
    console.log("Enable button: " + this.state.nombre);
    if (this.state.nombre.length > 0 && this.state.descripcion.length > 0) {
      return false;
    } else {
      return true;
    }
  };
  changeName = (event) => this.setState({ nombre: event.target.value });

  changedescripcion = (event) =>
    this.setState({ descripcion: event.target.value });

  changeEvento = (evento, etiquetasId, eventoId) => {
    if (!this.state.salasMonitoreo_arraylist[evento]) {
      //add etiqueta
      for (let item of this.props.stor.eventos_object) {
        console.log("eventos_objetct " + item["id"]);
        if (item["id"] === eventoId) {
          console.log("Push item:" + item["id"] + " : " + eventoId);
          this.salasMonitoreoObject.eventos.push(item);
          break;
        }
      }
    } else {
      //deleted etiqueta
      this.salasMonitoreoObject.eventos.map((elemento, index) => {
        console.log("eventos_elemento " + elemento["id"]);
        if (elemento["id"] === eventoId) {
          console.log("Push elemento:" + elemento["id"] + " : " + eventoId);
          this.salasMonitoreoObject.eventos.splice(index, 1);
        }
        console.log(this.salasMonitoreoObject);
      });
      //console.log("Fin-->> "+this.salasMonitoreo_arraylist[evento]);
    }

    this.state.salasMonitoreo_arraylist[evento]
      ? this.props.getfuntion(
          "delete",
          "",
          eventoId + "/etiqueta/" + etiquetasId,
          this.props.stor.eventos_update,
          "eventosEtiquetas"
        )
      : this.props.getfuntion(
          "post",
          { idEvento: eventoId, idEtiqueta: etiquetasId },
          "",
          this.salasMonitoreoObject,
          "eventosEtiquetas",
          this.props.stor.eventos_update
        );

    this.state.salasMonitoreo_arraylist[evento]
      ? (this.SalasMonitoreo_array[evento] = 0)
      : (this.SalasMonitoreo_array[evento] = 1);
    this.setState({ salasMonitoreo_arraylist: this.SalasMonitoreo_array });
    //console.log(evento+" :: "+ this.state.salasMonitoreo_arraylist[evento]);
  };
  guardarSala = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
    });
  } //"eventos":[]
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", {
      nombre: this.state.nombre,
      descripcion: this.state.descripcion,
    });
  } //"eventos":[]
  //console.log("DatoEtiqueta -->"+ this.salasMonitoreoObject)

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>{this.props.elementos.nombre}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={9}>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeName}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12} lg={12}>
                <Form.Group label="Descripcion">
                  <Form.Input
                    name="descripcion"
                    type="text"
                    value={this.state.descripcion}
                    onChange={this.changedescripcion}
                    maxlength="255"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarSala}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default SalaForm;
