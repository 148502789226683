import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table, Card, Dimmer, List, Alert } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";
import { NotificationManager } from 'react-notifications';
//var user = JSON.parse(sessionStorage.getItem("usuario"));
class PanelBitacoraMonitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:this.props.id,
      result:this.props.resultados
    }
  }
  componentDidUpdate=()=>{
    if(this.props.resultados !== this.state.result){
        this.setState({result:this.props.resultados});
    }
  }
  
  render() {

      return (
      <div className="m-3">
        <Card>
          <Card.Header>
            <Card.Title>Informacion</Card.Title>
          </Card.Header>
          <Card.Body>
            <Dimmer >
            <List.Group>
              <List.GroupItem action icon="credit-card"     > REQUEST:  {this.state.result[this.props.id]["request"]}</List.GroupItem>
              <List.GroupItem action icon="truck"           > DATA: {this.state.result[this.props.id]["data"]}</List.GroupItem>
              <List.GroupItem action icon="calendar"        > ERROR:  {this.state.result[this.props.id]["error"]}</List.GroupItem>
            </List.Group>
            </Dimmer>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
export default PanelBitacoraMonitoreo;