import React, { Component } from "react";
import TabsLayout from "../../components/TabsLayout.react";
import { PersonaForm, PersonasEtiquetas, PersonaContactoSolicitanteForm } from "./Formularios";
import { from } from "rxjs";

class Personas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      Otd: [],
      ready: false,
    };
    this.tabs = [];
    /**
     * Tab's
     */
    this.PersonaF = {
      title: this.props.elementos ? this.props.elementos.nombre : "",
      icon: "user",
      panel: (
        <PersonaForm
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
    };
    this.PersonaE = {
      title: "Etiquetas",
      icon: "tag",
      panel: (
        <PersonasEtiquetas
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cerrarModal={this.props.cerrarModal}
        />
      ),
    };


    this.PersonaS = {
      title: "Personas Contacto Solicitantes",
      icon: "tag",
      panel: (
        <PersonaContactoSolicitanteForm
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.plataforma}
          cliente={this.props.cliente}
          cerrarModal={this.props.cerrarModal}
        />
      ),
    };
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
    } else {
    }
  };
  componentWillMount = () => {

    if(this.props.type && this.props.type == "Solicitantes"){
      this.tabs = [this.PersonaS];
    }else
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.tabs = [this.PersonaF, this.PersonaE];
    } else {
      this.tabs = [this.PersonaF];
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (
      <div>
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default Personas;
