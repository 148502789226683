import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';
import ModalLayout from "./ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import Mensajeria from '../socket/Mensajeria.react';
import { Form, Grid, Card, Table, Alert,Button, Icon, Select} from "tabler-react";
import Columnas from '../pages/monitoreoPages/Columnas';
import { timeToDatefull } from "../herramientas/DateFormat";
var tableHeaders= JSON.parse(localStorage.getItem('tableHeaders'));
class Catalogoslog extends React.Component {
  constructor(props) {
    super(props); 
    this.state={
      id_fila:0,
      result:this.props.resultados,
      newResult:[]
    }
  }
 handleTablaLog=(value) => {
    this.props.handleLog(value) 
  }
  componentDidMount= () => {
    const newData=[]
    this.state.result.map((res,index)=>{
      newData.push({
        ind:index,
        id_cat:res.id,
        nomb_cat:res.nombreCatalogo,
        fecha:res.accionFecha,
        metodo:res.metodo
      })   
    })
    this.setState({newResult:newData})
  }
  componentDidUpdate=()=>{
    console.log(this.props.resultados)
    if(this.props.resultados !== this.state.result){
       this.setState({result:this.props.resultados})
       const newData=[]
       this.props.resultados.map((res,index)=>{
      newData.push({
        ind:index,
        id_cat:res.id,
        nomb_cat:res.nombreCatalogo,
        fecha:res.accionFecha,
        metodo:res.metodo
      })   
    })
    this.setState({newResult:newData})
    }
  }
 
  
    render() {
        return (

            <div class="row">
                <div className="col col-12 col-md-12">
                    <Card title="Bitacora de catalogos">
                        <Card.Body>
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" 
                                    headerItems={[ { content: "ID CATALOGO" },{ content: "NOMBRE DEL CATALOGO" },
                                    { content: "FECHA" },{ content: "METODO" } ] } >
                            
                                <Table.Body>
                              {
                                this.state.newResult.map((res)=>{
                                  return(
                                  <Table.Row onClick={()=>this.handleTablaLog(res["ind"])}>
                                  <Table.Col>{res["id_cat"]}</Table.Col>
                                  <Table.Col>{res["nomb_cat"]}</Table.Col>
                                  <Table.Col>{res["fecha"]}</Table.Col>
                                  <Table.Col>{res["metodo"]}</Table.Col>
                                  </Table.Row>  
                                  )
                                })
           
                                 }                    
                                </Table.Body>
                            </Table>
                        </Card.Body>
                    </Card> 
                </div>

                
            </div>                  
    );
  }
}

export default (Catalogoslog)
