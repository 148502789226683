import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
export async function EtasN(movimientos, mtipo){
    var movimientoAcumuladores= {};
    if(movimientos.length > 0 ){
      var user= JSON.parse(sessionStorage.getItem("usuario"));
      let apiUrl="";
      if(mtipo){
        apiUrl= "movimientoAcumuladores/usuarioMovimientos/"+user.id+"/mov_tipo/"+mtipo;
      }else{
        apiUrl= "movimientoAcumuladores/usuarioMovimientos/"+user.id;        
      }
      setTimeout(function(){ 
        Crud_Catalogos(apiUrl, "movimientoAcumuladores", "get").then((returnVal) => {
          for(let movAc of returnVal){
            if(!movimientoAcumuladores[movAc.idMovimiento]){ movimientoAcumuladores[movAc.idMovimiento]={} }
            if(!movimientoAcumuladores[movAc.idMovimiento][movAc.idAcumulador.id]){
              movimientoAcumuladores[movAc.idMovimiento][movAc.idAcumulador.id]= movAc;
            }else{
              movimientoAcumuladores[movAc.idMovimiento][movAc.idAcumulador.id]= movAc;
            }
            
          }
          //movimientoAcumuladores[mov.id].acumulador= returnVal;
        }).catch(err => { console.log(err); });
      }, 2);
      return movimientoAcumuladores;
    }else{
      alert("No hat movimientos")
    }
    /*
    movimientos.map((mov, index) => {
      movimientoAcumuladores[mov.id]= {"movimiento": mov.id, "acumulador": 0 };
      setTimeout(function(){ 
        Crud_Catalogos("movimientoAcumuladores/etasMovimiento/"+mov.id, "movimientoAcumuladores", "get")
        .then((returnVal) => { movimientoAcumuladores[mov.id].acumulador= returnVal; }).catch(err => { console.log(err); });
      }, 2);    
    }); */
}