import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";

class OperadoresForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nombre: "",
      paterno: "",
      materno: "",
      vetado: "",
      medioComunicacion: "",
      licenciaTipo: "",
      licenciaVigencia: "",
      idTransportista: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Transportista: { value: 0, label: "Selecciona un Transportista " },
      noEmpleado: "",
      disabledSave: true,
      crudbutonEnable: true, // Se desabilita cuando se hace click
      rfc: "",
      username: "",
      password: "",
      logistic: false,
    };
    this.Transportista = [];
    this.enableSave = this.enableSave.bind(this);
    this.OperadoresObject = {
      /* Llave Primaria */
      id: 0,
      /* Llaves Foraneas */
      idTransportista: 0,
      /* Campos */
      nombre: "",
      paterno: "",
      materno: "",
      vetado: "",
      medioComunicacion: "",
      licenciaTipo: "",
      licenciaVigencia: "",
      rfc: "",
      username: "",
      password: "",
    };
  }
  isEnable = (Accion) => {
    let borrar = "operadores_borrar";
    let actualizar = "operadores_editar";
    let agregar = "operadores_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  componentDidMount = () => {
    /* Transportista */
    this.Transportista.push({
      value: 0,
      label: "Selecciona un Transportista ",
    });
    this.props.stor.transportistas_object.map((elemento, index) => {
      this.Transportista.push({ value: elemento.id, label: elemento.nombre });
    });

    if (this.state.accion === "nuevo") {
      this.OperadoresObject = {
        id: 0,
        nombre: "",
        paterno: "",
        materno: "",
        vetado: "",
        medioComunicacion: "",
        licenciaTipo: "",
        licenciaVigencia: "",
        idTransportista: 0,
        noEmpleado: "",
        password: "",
        rfc: "",
      };
    } else {
      if (this.props.elementos.idSkylogistic) {
        this.props
          .getfuntion(
            "get",
            "",
            "",
            "operadoresSkylogistic/",
            "operadoresSkylogistic/" + this.props.elementos.idSkylogistic
          )
          .then((returnVal) => {
            this.setState({ username: returnVal.username });
            this.setState({ rfc: returnVal.rfc });
            this.setState({ idSkylogistic: returnVal.id });

            this.OperadoresObject.username = returnVal.username;
            this.OperadoresObject.rfc = returnVal.rfc;
            this.OperadoresObject.idSkylogistic = returnVal.id;

            this.props.stor.transportistas_object.map((elemento, index) => {
              this.Transportista.push({
                value: elemento.id,
                label: elemento.nombre,
              });
            });
          })
          .catch(() => {
            alert("Operador inexistente en SkyLogistic");
          })
          .finally(() => {
            this.setState({ id: this.props.elementos.id });
            this.setState({ nombre: this.props.elementos.nombre });
            this.setState({ paterno: this.props.elementos.paterno });
            this.setState({ materno: this.props.elementos.materno });
            this.setState({ vetado: this.props.elementos.vetado });
            this.setState({
              medioComunicacion: this.props.elementos.medioComunicacion,
            });
            this.setState({ licenciaTipo: this.props.elementos.licenciaTipo });
            this.setState({
              licenciaVigencia: this.props.elementos.licenciaVigencia,
            });
            this.setState({ noEmpleado: this.props.elementos.noEmpleado });
            //this.setState({plataforma: this.props.elementos.plataforma});
            this.OperadoresObject.idTransportista =
              this.props.elementos.idTransportista;
            this.OperadoresObject.id = this.props.elementos.id;
            this.OperadoresObject.nombre = this.props.elementos.nombre;
            this.OperadoresObject.paterno = this.props.elementos.paterno;
            this.OperadoresObject.materno = this.props.elementos.materno;
            this.OperadoresObject.vetado = this.props.elementos.vetado;
            this.OperadoresObject.medioComunicacion =
              this.props.elementos.medioComunicacion;
            this.OperadoresObject.licenciaTipo =
              this.props.elementos.licenciaTipo;
            this.OperadoresObject.licenciaVigencia =
              this.props.elementos.licenciaVigencia;
            this.OperadoresObject.plataforma = this.props.elementos.plataforma;
            this.OperadoresObject = this.props.elementos;
            this.props.stor.transportistas_object.map((elemento, index) => {
              if (elemento.id === this.props.elementos.idTransportista) {
                this.Transportista.push({
                  value: elemento.id,
                  label: elemento.nombre,
                });
                this.setState({ idTransportista: this.props.elementos.nombre });
                this.setState({
                  Transportista: { value: elemento.id, label: elemento.nombre },
                });
              }
            });
          });
      } else {
        //alert("Operador inexistente en SkyLogistic")
        this.setState({ id: this.props.elementos.id });
        this.setState({ nombre: this.props.elementos.nombre });
        this.setState({ paterno: this.props.elementos.paterno });
        this.setState({ materno: this.props.elementos.materno });
        this.setState({ vetado: this.props.elementos.vetado });
        this.setState({
          medioComunicacion: this.props.elementos.medioComunicacion,
        });
        this.setState({ licenciaTipo: this.props.elementos.licenciaTipo });
        this.setState({
          licenciaVigencia: this.props.elementos.licenciaVigencia,
        });
        this.setState({ noEmpleado: this.props.elementos.noEmpleado });
        //this.setState({plataforma: this.props.elementos.plataforma});
        this.OperadoresObject.idTransportista =
          this.props.elementos.idTransportista;
        this.OperadoresObject.id = this.props.elementos.id;
        this.OperadoresObject.nombre = this.props.elementos.nombre;
        this.OperadoresObject.paterno = this.props.elementos.paterno;
        this.OperadoresObject.materno = this.props.elementos.materno;
        this.OperadoresObject.vetado = this.props.elementos.vetado;
        this.OperadoresObject.medioComunicacion =
          this.props.elementos.medioComunicacion;
        this.OperadoresObject.licenciaTipo = this.props.elementos.licenciaTipo;
        this.OperadoresObject.licenciaVigencia =
          this.props.elementos.licenciaVigencia;
        this.OperadoresObject.plataforma = this.props.elementos.plataforma;
        this.OperadoresObject = this.props.elementos;
        this.props.stor.transportistas_object.map((elemento, index) => {
          if (elemento.id === this.props.elementos.idTransportista) {
            this.Transportista.push({
              value: elemento.id,
              label: elemento.nombre,
            });
            this.setState({ idTransportista: this.props.elementos.nombre });
            this.setState({
              Transportista: { value: elemento.id, label: elemento.nombre },
            });
          }
        });
      }
    }
  };

  enableSave = () => {
    if (this.state.nombre.length && this.state.Transportista.value) {
      return false;
    } else {
      return true;
    }
  };
  changeNombre = (event) => {
    this.OperadoresObject.nombre = event.target.value;
    this.setState({ nombre: event.target.value });
  };
  changePaterno = (event) => {
    this.OperadoresObject.paterno = event.target.value;
    this.setState({ paterno: event.target.value });
  };
  changeMaterno = (event) => {
    this.OperadoresObject.materno = event.target.value;
    this.setState({ materno: event.target.value });
  };

  changeVetado = (event) => {
    if (event.target.checked) {
      this.OperadoresObject.vetado = "Sí";
      this.setState({ vetado: "Sí" });
    } else {
      this.OperadoresObject.vetado = "No";
      this.setState({ vetado: "No" });
    }
  };

  changeLogistic = () => {
   this.setState({logistic:!this.state.logistic})
  };

  changeMedioComunicacion = (event) => {
    this.OperadoresObject.medioComunicacion = event.target.value;
    this.setState({ medioComunicacion: event.target.value });
  };

  changeLicenciaTipo = (event) => {
    this.OperadoresObject.licenciaTipo = event.target.value;
    this.setState({ licenciaTipo: event.target.value });
  };

  changeLicenciaVigencia = (event) => {
    this.OperadoresObject.licenciaVigencia = event.target.value;
    this.setState({ licenciaVigencia: event.target.value });
  };

  changeNoEmpleado = (event) => {
    this.OperadoresObject.noEmpleado = event.target.value;
    this.setState({ noEmpleado: event.target.value });
  };

  changePassword = (event) => {
    this.OperadoresObject.password = event.target.value;
    this.setState({ password: event.target.value });
  };

  changeRfc = (event) => {
    this.OperadoresObject.rfc = event.target.value;
    this.setState({ rfc: event.target.value });
  };

  changeUsername = (event) => {
    this.OperadoresObject.username = event.target.value;
    this.setState({ username: event.target.value });
  };

  changeTransportista = (event) => {
    this.setState({ idTransportista: event });
    this.OperadoresObject.idTransportista = event.value;

    this.setState({ IdBloques: { value: event.value, label: event.label } }); ///this.props.stor.transportistas_object
    for (let item of this.props.stor.transportistas_object) {
      if (item.id === event.value) {
        this.setState({
          Transportista: { value: event.value, label: event.label },
        });
        break;
      }
    }
  };

  guardarOperadores = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.OperadoresObject.id);
  }
  crud_put() {
    const operadorSkylogistic = {
      nombre: this.OperadoresObject.nombre,
      apellidos:
        this.OperadoresObject.paterno + " " + this.OperadoresObject.materno,
      username: this.state.username,
      password: this.state.password,
      estatus: false,
      rfc: this.state.rfc,
      telefono: this.state.medioComunicacion,
      id: this.state.idSkylogistic,
    };

    if(!this.state.logistic) {
      this.props.getfuntion("put", this.OperadoresObject);
    } else {
    
    if(this.state.idSkylogistic) {
      this.props.getfuntion("put", operadorSkylogistic,"","operadoresSkylogistic", "operadoresSkylogistic").then((returnVal)=>{
        this.setState({ crudbutonEnable: false });
        if(returnVal.id){
          this.OperadoresObject.idSkylogistic = returnVal.id; 
        this.props.getfuntion("put", this.OperadoresObject);    
        } else{
          alert("No se guardo correctamente el Operador en SkyLogistic");
        }
      })
    } else {
      this.props.getfuntion("post", operadorSkylogistic,"","operadoresSkylogistic", "operadoresSkylogistic").then((returnVal)=>{
        if(returnVal.id){
          this.OperadoresObject.idSkylogistic = returnVal.id; 
           this.props.getfuntion("put", this.OperadoresObject);     
        } else{
          alert("No se guardo correctamente el Operador en SkyLogistic");
        }
       });
    }
  }
    
  }

  crud_post() {
    this.setState({ crudbutonEnable: false });
    if(!this.state.logistic) {
      this.props.getfuntion("post", this.OperadoresObject);
    } else {
    const operadorSkylogistic = {
      nombre: this.OperadoresObject.nombre,
      apellidos:
        this.OperadoresObject.paterno + " " + this.OperadoresObject.materno,
      username: this.state.username,
      password: this.state.password,
      estatus: false,
      rfc: this.state.rfc,
      telefono: this.state.medioComunicacion,
      id: this.state.idSkylogistic,
    };
    this.props.getfuntion("post", operadorSkylogistic,"","operadoresSkylogistic", "operadoresSkylogistic").then((returnVal)=>{
      if(returnVal.id){
        this.OperadoresObject.idSkylogistic = returnVal.id; 
         this.props.getfuntion("post", this.OperadoresObject);     
      } else{
        alert("No se guardo correctamente el Operador en SkyLogistic");
      }
     });
    }
  }
  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1> {this.props.elementos.nombre} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Vigencia">
                  <Form.Input
                    name="licenciaVigencia"
                    type="date"
                    value={this.state.licenciaVigencia}
                    onChange={this.changeLicenciaVigencia}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="Tipo de Licencia">
                  <Form.Input
                    name="licenciaTipo"
                    type="text"
                    value={this.state.licenciaTipo}
                    onChange={this.changeLicenciaTipo}
                    maxlength="10"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeNombre}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Paterno">
                  <Form.Input
                    name="paterno"
                    type="text"
                    value={this.state.paterno}
                    onChange={this.changePaterno}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Materno">
                  <Form.Input
                    name="materno"
                    type="text"
                    value={this.state.materno}
                    onChange={this.changeMaterno}
                    maxlength="50"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Vetado">
                  <Form.Switch
                    name="vetado"
                    type="Checkbox"
                    //value={this.state.vetado}
                    onChange={this.changeVetado}
                    maxlength="50"
                    checked={this.state.vetado == "Sí" ? true : false}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Numero de empleado">
                  <Form.Input
                    name="noEmpleado"
                    type="text"
                    value={this.state.noEmpleado}
                    onChange={this.changeNoEmpleado}
                    maxlength="20"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Medio de Comunicación">
                  <Form.Input
                    name="medioComunicacion"
                    type="text"
                    value={this.state.medioComunicacion}
                    onChange={this.changeMedioComunicacion}
                    maxlength="10"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={8} lg={8}>
                <Form.Group label="Transportista">
                  <Select
                    value={this.state.Transportista}
                    onChange={this.changeTransportista}
                    options={this.Transportista}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col md={12} lg={12}>
                <Form.Group label={(this.state.accion === "nuevo" ? "Crear operador en SkyLogistic" : "Modificar operador en SkyLogistic")}>
                  <Form.Switch
                    name="logistic"
                    type="Checkbox"
                    onChange={this.changeLogistic}
                    maxlength="50"
                    checked={this.state.logistic}
                  />
                </Form.Group>
              </Grid.Col>
              { this.state.logistic && <>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Nombre de usuario">
                  <Form.Input
                    name="username"
                    type="text"
                    value={this.state.username}
                    onChange={this.changeUsername}
                    maxlength="20"
                  />
                </Form.Group>
              </Grid.Col>              
              <Grid.Col md={4} lg={4}>
                <Form.Group label="RFC">
                  <Form.Input
                    name="rfc"
                    type="text"
                    value={this.state.rfc}
                    onChange={this.changeRfc}
                    maxlength="13"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4} lg={4}>
                <Form.Group label="Contraseña de SkyLogistic">
                  <Form.Input
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.changePassword}
                    maxlength="50"
                  />
                  <Form.Footer>
                    Se requieren; 8 Carácteres, Carácter especial, Letra Mayúscula, Letra minúscula, Número.
                  </Form.Footer>
                </Form.Group>
              </Grid.Col> </>
              }
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarOperadores}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default OperadoresForm;
