import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class  Operacionestipo extends Component {
  constructor(props) {
    super(props);
    this.state = { 
                                   id: "",
                               accion: this.props.accion, //'nuevo'// modificar, nuevo
                      Operacionestipo: [],
                               search:""
                 };
                  this.Operacionestipo= [];
                this.plataformasObject= [];
            this.checarOperacionestipo= 0;      //temporal render
      this.idPlataformaOperacionestipo= 0; //temporal render
 this.plataformasOperacionestipoObject= [];
             this.OperacionestipoArray= [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "plataformasoperacionestipo/plataforma/"+this.props.elementos.id,"Operacionestipo")
    }else{}
  }
getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
  Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Operacionestipo"){ this.Operacionestipo= returnVal; this.setState({ Operacionestipo: returnVal }); }
          }
          break;
        case "post":
          {
           if (stateVar === "Operacionestipo"){
              this.Operacionestipo.push(returnVal);
              this.setState({ Operacionestipo: this.Operacionestipo });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Operacionestipo"){
              this.Operacionestipo.map((elemento, index)=>
                {if(id === elemento.idPlataformaOperacionTipo){
                  this.Operacionestipo.splice(index, 1);
                }}
              )
              this.setState({ Operacionestipo: this.Operacionestipo })
            }
          }
            break;
        default:
          break;
      }
    }).catch(err =>{
      if( err.response ){
        console.log(err.response);
      }else{
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
  })
}
  
  changeOperacionestipo =(OperacionestipoIndex, OtdId, OtdStatus) => {
      this.plataformasOperacionestipoObject={
        "idPlataforma": this.plataformasObject,
        "idOperacionTipo": this.props.stor.operacionesTipo_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[OperacionestipoIndex]
      }
      
      this.OperacionestipoArray[OperacionestipoIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.OperacionestipoArray[OperacionestipoIndex].id, "plataformasoperacionestipo", "Operacionestipo")
      :
      this.getfuntion("post",this.plataformasOperacionestipoObject, "", "plataformasoperacionestipo", "Operacionestipo");

      
    }
    checkedOperacionestipo =(indice) =>{
      return this.OperacionestipoArray[indice].estatus
    }
    checarOperacionestipos =(estatus, id) =>{
      this.OperacionestipoArray.push({ estatus: estatus, id: id })
    }
    checarOperacionestipoTem =(valor) =>{
      this.checarOperacionestipo=valor;
    }
    searching =(event) =>{
      this.setState({ search: event.target.value })
    }
    cleanArrayList =() =>{
      this.OperacionestipoArray= [];
    }
  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        { this.cleanArrayList() }
        { this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Operaciones tipo"
                  isCollapsible= { false }
                  //isClosable
                  isCollapsed={ false}
                  options={ <Form.Group>
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Tipo operación..."
                                position="append"
                                onChange={this.searching}
                              />
                            </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.operacionesTipo_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["nombre"] }</Table.Col>
                                  { this.checarOperacionestipoTem(0)}
                                  { this.idPlataformaOperacionestipo="" }
                                  { 
                                    this.state.Operacionestipo.map((elemOperacionestipo, ind) =>
                                    {
                                      if(elemOperacionestipo.idOperacionTipo.id === elemento.id){
                                        { this.checarOperacionestipoTem(1)}
                                        this.idPlataformaOperacionestipo=elemOperacionestipo.idPlataformaOperacionTipo;
                                      }
                                    })
                                  }
                                  { this.checarOperacionestipos(this.checarOperacionestipo, this.idPlataformaOperacionestipo)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                          checked={ this.checkedOperacionestipo(index) }
                                                          className={"disabled"}
                                                          onChange={ () => this.changeOperacionestipo(index, elemento["id"], this.checarOperacionestipo) } /></Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default Operacionestipo
