import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import {
  Crud_Catalogos,  
} from "../../../../herramientas/Crud_Catalogos";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
/**
 * Date tools
 */
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import DateTimePicker from "react-datetime-picker";
const user = JSON.parse(sessionStorage.getItem("usuario"));
let campos= "";
let usuariosLogObject = {
  idBitacoraMonitoreo: 0,
  idUsuario: 0,
  idMovimiento: 0,
  accionFecha: "",
  descripcion: "",
  idPlataformaTipo: "",
};
const ActualizarHorariosNMP =({movimiento, stor, closeModal})=> {
  const [camposLogisticos, setCamposLogisticos]= useState([]);
  const [formulario, setFormulario]= useState({});//rutaInicio, fechaEntrega
  useEffect(() => {
    getfuntionForm(
      "get",
      "",
      "",
      "camposLogisticos/plataforma/" +
        movimiento.plataforma.id +
        "/mtipo/1",
      "camposLogisticos"
    );
    setFormulario(movimiento);
  },[]);

  const getfuntionForm = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "camposLogisticos") {
                setCamposLogisticos(returnVal);
              }
            }
            break;
          case "put":
            if (stateVar === "terrestreMovimientos") {
              if( obj.operacionEstatus
                  && obj.operacionEstatus.nombre.toUpperCase().includes("TERMINADO") ){
                    desvincularGps();
              }
              usuariosLogObject.idBitacoraMonitoreo = 2;
              usuariosLogObject.idUsuario = user.id;
              usuariosLogObject.idMovimiento = obj.id; //returnVal.id;
              usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
              usuariosLogObject.descripcion = campos; //returnVal.id;
              Crud_Catalogos(
                "usuariosLog",
                "nmp",
                "post",
                "",
                usuariosLogObject,
                "",
                ""
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const desvincularGps =() =>{
    if(formulario.movimientoGps){
      for(let mg of formulario.movimientoGps ){
        let gps= mg.gps;
        gps.estatus= 0;
        gps.idGpsTipoColocacion= null;
        gps.idUnidad= null;
        getfuntionForm("put", gps, "", "gpsFijos", "gpsFijos");
      }
    }
  }
  const crud_put = () => getfuntionForm("put", formulario, "", "terrestreMovimientos", "terrestreMovimientos");

  const terminarMovimiento = () => {
    let movimientoTemp= {... formulario};
    let opEstatus = stor.operacionesEstatus;
    for (let item of opEstatus) {
      if (item.nombre.toUpperCase().includes("TERMINADO")) {
        movimientoTemp.operacionEstatus= item;
      }
    }
    confirmAlert({
      title: "Atención",
      message:
        "¿Esta seguro de terminar el movimiento(" +
        movimiento.id +
        ") ?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            getfuntionForm(
              "put",
              movimientoTemp,
              "",
              "terrestreMovimientos",
              "terrestreMovimientos",
              "",
              closeModal
            );
          },
        },
        {
          label: "Cancelar",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  const onChangeInicioRuta = (date) => {
    setFormulario({...formulario, rutaInicio: (date ? timeToDateFill(new Date(date).getTime()): null)});
    campos= campos + " InicioRuta: " + date ? timeToDateFill(new Date(date).getTime()) : null;
  };

  const onChangeFechaEntrega = (date) => {
    setFormulario({...formulario, fechaEntrega: (date ? timeToDateFill(new Date(date).getTime()): null)});
    campos= campos + " fechaEntrega: " + date ? timeToDateFill(new Date(date).getTime()) : null;
  };

  const getCamposLogisticos = () => {
    let camposLogist = [];
    camposLogisticos.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        case "inicio_ruta":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>{" "}
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={ formulario.rutaInicio? new Date(formulario.rutaInicio): formulario.rutaInicio }
                    onChange={(e)=> onChangeInicioRuta(e) }
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => onChangeInicioRuta(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "fecha_entrega":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>{" "}
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={formulario.fechaEntrega? new Date(formulario.fechaEntrega): formulario.fechaEntrega }
                    onChange={(e)=> onChangeFechaEntrega(e) }
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => onChangeFechaEntrega(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;          
        default:
          break;
      }
    });
    return camposLogist;
  };
  return (
    <div>
      <Grid.Row>{ getCamposLogisticos() }</Grid.Row>
      <div className="float-right">
        <span class="badge">
          <Button
            size="sm"
            RootComponent="a"
            color="primary"
            onClick={() => crud_put()}
          >
            <span class="badge">
              <Icon link={true} name="save" />
              Guardar
            </span>
          </Button>
        </span>
        <span class="badge">
          <Button
            target="_blank"
            size="sm"
            RootComponent="a"
            color="danger"
            onClick={() => terminarMovimiento()}
          >
            {" "}
            <span class="badge">
              <Icon link={true} name="trash" />
              Terminar Movimiento
            </span>{" "}
          </Button>
        </span>
      </div>
    </div>
  );
}
export default ActualizarHorariosNMP;
