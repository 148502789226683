// @flow
import React, { Component, useEffect, useState } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import axios from 'axios';
import * as conf from "../../../config/config";

let valid = {
  idUnidadTipo: false,
  idUnidadMarca: false,
  placa: false,
  idUnidadEstatusOperacion: false,
};

const Unidades = ({ stor, elementos, getfuntionProps, accion, cerrarModal, }) => {
  const [formulario, setFormulario] = useState({
    idUnidadTipo: null,
    idUnidadMarca: null,
    nombre: "",
    placa: "",
    color: "",
    modelo: "",
    estatus: false,
    idUnidadEstatusOperacion: null,
    idVideo: null,
  });
  const [idUnidadTipo, setIdUnidadTipo] = useState({
    value: 0,
    label: "Seleccione una opción ",
    data: null,
  });
  const [idUnidadMarca, setIdUnidadMarca] = useState({
    value: 0,
    label: "Seleccione una opción ",
    data: null,
  });
  const [idUnidadEstatusOperacion, setIdUnidadEstatusOperacion] = useState({
    value: 0,
    label: "Seleccione una opción ",
    data: null,
  });

  const [videoDispositivo, setVideoDispositivo] = useState({});  
  const [videoDispositivoEstatus, setVideoDispositivoEstatus] = useState(false);

  const [transportistas, setTransportistas] = useState([]);
  const [unidadMarcas, setUnidadMarcas] = useState([]);
  const [unidadTipos, setUnidadTipos] = useState([]);
  const [unidadEstatusOperaciones, setUnidadEstatusOperaciones] = useState([]);
  const [transportista, setTransportista] = useState([]);
  const [crudbutonEnable, setcrudbutonEnable] = useState(true);

  const isEnable = (Accion) => {
    let borrar = "unidades_borrar";
    let actualizar = "unidades_editar";
    let agregar = "unidades_agregar";
    const privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  useEffect(() => {
    let trasnportistasTemp = [];
    let unidadMarcasTemp = [];
    let UnidadTipoTemp = [];
    let unidadEstatusOperacionTemp = [];
    stor.transportistas_object
      .filter((t) => t.tipo === 1)
      .map((elemento, index) =>
        trasnportistasTemp.push({
          value: elemento.id,
          label: elemento.nombre,
          data: elemento,
        })
      );
    stor.unidadTipo_object.map((elemento, index) =>
      UnidadTipoTemp.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      })
    );
    stor.unidadesmarcas_object.map((elemento, index) =>
      unidadMarcasTemp.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      })
    );
    stor.unidadEstatusOperacion_object.map((elemento, index) =>
      unidadEstatusOperacionTemp.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      })
    );
    setTransportistas(trasnportistasTemp);
    setUnidadMarcas(unidadMarcasTemp);
    setUnidadTipos(UnidadTipoTemp);
    setUnidadEstatusOperaciones(unidadEstatusOperacionTemp);

    if (accion === "nuevo") {
    } else {
      setFormulario({
        id: elementos.id,
        idUnidadTipo: elementos.idUnidadTipo,
        idUnidadMarca: elementos.idUnidadMarca,
        nombre: elementos.nombre ? elementos.nombre : "",
        placa: elementos.placa,
        color: elementos.color ? elementos.color : "",
        modelo: elementos.modelo ? elementos.modelo : "",
        estatus: elementos.estatus ? elementos.estatus : false,
        idUnidadEstatusOperacion: elementos.idUnidadEstatusOperacion,
        idVideo: elementos.idVideo,
      });
      getVideoDispositivo(elementos.idVideo);
      valid = {
        idUnidadTipo: elementos.idUnidadTipo? true: false,
        idUnidadMarca: elementos.idUnidadMarca? true: false,
        placa: elementos.placa? true: false,
        idUnidadEstatusOperacion: elementos.idUnidadEstatusOperacion? true: false,
      };
      setIdUnidadTipo(
        elementos.idUnidadTipo
          ? {
              value: elementos.idUnidadTipo.id,
              label: elementos.idUnidadTipo.nombre,
              data: elementos.idUnidadTipo,
            }
          : { value: 0, label: "Seleccione una opción ", data: null }
      );
      setIdUnidadMarca(
        elementos.idUnidadMarca
          ? {
              value: elementos.idUnidadMarca.id,
              label: elementos.idUnidadMarca.nombre,
              data: elementos.idUnidadMarca,
            }
          : { value: 0, label: "Seleccione una opción ", data: null }
      );
      setIdUnidadEstatusOperacion(
        elementos.idUnidadEstatusOperacion
          ? {
              value: elementos.idUnidadEstatusOperacion.id,
              label: elementos.idUnidadEstatusOperacion.nombre,
              data: elementos.idUnidadEstatusOperacion,
            }
          : { value: 0, label: "Seleccione una opción ", data: null }
      );      
    }
  }, []);
  useEffect(()=>{
    if( transportistas.length ){
      getfuntion(
        "get",
        "",
        "",
        "transportistas_unidades_ter_nav/id_unidad/" + elementos.id,
        "transportistas_unidades"
      );
    }
  },[transportistas])
  const disableSave = () => {
    let band = false;
    Object.keys(valid).forEach((key) => {
      if (!valid[key]) {
        band = true;
      }
    });
    if (band) {
      return true;
    } else {
      return false;
    }
  };

  const changeTransportista = (e) => {
    if (e) {
      for (let r1 of e) {
        let encontrado = false;
        for (let item of transportista) {
          if (item.value === r1.value) {
            encontrado = true;
          }
        }
        if (!encontrado) {
          getfuntion(
            "post",
            {
              idUnidad: elementos.id,
              idTransportista: r1.value,
            },
            "",
            "transportistas_unidades_ter_nav",
            "transportistas_unidades"
          );
        }
      }
    }

    if (e) {
      for (let item of transportista) {
        let encontrado = false;
        for (let r1 of e) {
          if (item.value === r1.value) {
            encontrado = true;
          }
        }
        if (!encontrado) {
          getfuntion(
            "delete",
            "",
            item.value + "/" + elementos.id,
            "transportistas_unidades_ter_nav",
            "transportistas_unidades"
          );
        }
      }
    } else {
      if (transportista.length === 1) {
        getfuntion(
          "delete",
          "",
          transportista[0].value + "/" + elementos.id,
          "transportistas_unidades_ter_nav",
          "transportistas_unidades"
        );
      }
    }
  };
  const obtenerTransportistaNombre = (id) => {
    for (let item of transportistas) {
      if (item.value === id) {
        return item.label;
      }
    }
    return "";
  };
  const guardarUnidad = () => {
    if (accion === "modificar") crud_put();
    else crud_post();
    cerrarModal();
  };
//84AY3S
  const getVideoDispositivo=(id)=>{
    setVideoDispositivo([]);
    setVideoDispositivoEstatus(false);
    delete axios.defaults.headers.common["Authorization"];
    if(id){
      axios.get(conf.cctv+":8443/video_back/"+"dispositivos/live/unidades/"+id ).then(res => {//:8443/video_back/
        if(res.data)
        setVideoDispositivo(res.data);
      });
      axios.get(conf.cctv+":8443/video_back/"+"dispositivos/live/unidades/estatus/"+id ).then(res => {//:8443/video_back/
        if(res.data)
          setVideoDispositivoEstatus(res.data);
      });
    }
  }

  const crud_delete = () => {
    setcrudbutonEnable(false);
    getfuntionProps("delete", "", elementos.id);
  };
  const crud_put = () => {
    setcrudbutonEnable(false);
    getfuntionProps("put", formulario);
  };
  const crud_post = () => {
    setcrudbutonEnable(false);
    //getfuntionProps("post", formulario, "", "unidades_navieras", "unidades");
    getfuntionProps("post", formulario);
  };
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "loquequieras",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "transportistas_unidades" && returnVal) {
                let transportistasUnidadesTem = [];
                returnVal.map((i, index) => {
                  transportistasUnidadesTem.push({
                    value: i.idTransportista,
                    label: obtenerTransportistaNombre(i.idTransportista),
                    data: i,
                  });
                });
                if( transportistasUnidadesTem != transportista)
                  setTransportista(transportistasUnidadesTem);
              }
            }
            break;
          case "post":
            {
              if (stateVar === "transportistas_unidades" && returnVal) {
                NotificationManager.info(
                  "Se ha vinculado la LT",
                  "Actualización"
                );
                //window.location.reload();
                getfuntion(
                  "get",
                  "",
                  "",
                  "transportistas_unidades_ter_nav/id_unidad/" + elementos.id,
                  "transportistas_unidades"
                );
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "transportistas_unidades" && returnVal) {
                NotificationManager.error(
                  "Se ha removido la LT",
                  "Actualización"
                );
                getfuntion(
                  "get",
                  "",
                  "",
                  "transportistas_unidades_ter_nav/id_unidad/" + elementos.id,
                  "transportistas_unidades"
                );
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => { console.log(err); });
  };
  return (
    <div>
      {accion === "modificar" ? (
        <h1>
          {elementos.id}({elementos.nombre})
        </h1>
      ) : null}
      <Grid.Row>
        <Grid.Col>
          <Grid.Row>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Nombre">
                <Form.Input
                  name="nombre"
                  type="text"
                  value={formulario.nombre}
                  onChange={(e) =>
                    setFormulario({ ...formulario, nombre: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Color">
                <Form.Input
                  name="color"
                  type="text"
                  value={formulario.color}
                  onChange={(e) =>
                    setFormulario({ ...formulario, color: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Modelo">
                <Form.Input
                  name="modelo"
                  type="text"
                  value={formulario.modelo}
                  onChange={(e) =>
                    setFormulario({ ...formulario, modelo: e.target.value })
                  }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Placa">
                <Form.Input
                  name="placa"
                  type="text"
                  value={formulario.placa}
                  onChange={(e) => {
                    setFormulario({ ...formulario, placa: e.target.value });
                    if (e.target.value.length) {
                      valid.placa = true;
                    } else {
                      valid.placa = false;
                    }
                  }}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Marca">
                <Select
                  value={idUnidadMarca}
                  onChange={(e) => {
                    setIdUnidadMarca(e);
                    setFormulario({ ...formulario, idUnidadMarca: e.data });
                    if (e.value > 0) {
                      valid.idUnidadMarca = true;
                    } else {
                      valid.idUnidadMarca = false;
                    }
                  }}
                  options={unidadMarcas}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Tipo de Unidad">
                <Select
                  value={idUnidadTipo}
                  onChange={(e) => {
                    setIdUnidadTipo(e);
                    setFormulario({ ...formulario, idUnidadTipo: e.data });
                    if (e.value > 0) {
                      valid.idUnidadTipo = true;
                    } else {
                      valid.idUnidadTipo = false;
                    }
                  }}
                  options={unidadTipos}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Unidad estatus operación">
                <Select
                  value={idUnidadEstatusOperacion}
                  onChange={(e) => {
                    setIdUnidadEstatusOperacion(e);
                    setFormulario({
                      ...formulario,
                      idUnidadEstatusOperacion: e.data,
                    });
                    if (e.value > 0) {
                      valid.idUnidadEstatusOperacion = true;
                    } else {
                      valid.idUnidadEstatusOperacion = false;
                    }
                  }}
                  options={unidadEstatusOperaciones}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="Estatus">
                <Form.Switch
                  type="Checkbox"
                  name="toggle"
                  checked={ formulario.estatus }
                  onChange={(e)=> setFormulario({ ...formulario, estatus: e.target.checked }) }
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={4} lg={4}>
              <Form.Group label="idVideo (id de unidad en cctv)">
                <Form.Input name="idVideo" type="text" value={ formulario.idVideo }
                  onChange={
                    (e)=>{
                      setFormulario({ ...formulario, idVideo: e.target.value });
                      getVideoDispositivo(e.target.value);
                    }
                  }
                />
                <p className="text-primary">{ videoDispositivo.id? videoDispositivo.nombre: "" }</p>
                <p className={"text-"+(videoDispositivoEstatus.estatus? "success": "danger")}>{ videoDispositivo.id? videoDispositivoEstatus.mensaje: "" }</p>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
          <br />
          <Grid.Row>
            {accion !== "nuevo" ? (
              <Grid.Col md={12} lg={12}>
                <Form.Group label="Transportista(Los cambios se aplican de forma automatica, no es necesario dar click en el botón guardar)">
                  <Select
                    value={transportista}
                    onChange={changeTransportista}
                    options={transportistas}
                    isMulti={true}
                  />
                </Form.Group>
              </Grid.Col>
            ) : null}
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <div className="float-right">
        {isEnable("actualizar") && isEnable("agregar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={disableSave() || !crudbutonEnable}
              onClick={guardarUnidad}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        {accion === "modificar" && isEnable("borrar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => crud_delete()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default Unidades;
