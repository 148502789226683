import React, { useState, useEffect, useCallback,  useMemo } from 'react'
import SiteWrapper from "../../SiteWrapper.react";
import axios from 'axios';
import { api_raiz } from "../../config/config";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import CargaIndividual from './CargaIndividual';
import CargaMasiva from './CargaMasiva';


const GpsAlmacen = () => {

    useEffect(() => {
        getData()
    }, [])

    const [data, setData] = useState([]);



    const instance = useMemo(() => {
        const instance = axios.create({
            headers: {
                Authorization: 'Bearer ' + sessionStorage.getItem("tok")
            }
        });
        return instance;
    }, [])

    const getData = useCallback(async () => {
        try {
            const response = await instance.get(api_raiz + "gps-almacen");
            setData(response.data)
        } catch (error) {
            console.log(error)
        }
    }, [])

    const columns = [{
        Header: 'Id de GPS',
        accessor: 'idAlmacen', // String-based value accessors!
        filterable: true,
    }, {
        Header: 'IMEI',
        accessor: 'imei', // String-based value accessors!
        filterable: true,

    }, {
        Header: 'Modelo',
        accessor: 'modelo',
        filterable: true,

    }, {
        Header: 'Fecha de entrada',
        accessor: 'fechaEntrada',
    }, {
        Header: 'Fecha de salida',
        accessor: 'fechaSalida',
    }]


    return (
        <SiteWrapper>
            <div className="container mt-3">
                <CargaMasiva getData={getData} />
                <CargaIndividual getData={getData} />
                <ReactTable
                    data={data}
                    columns={columns}
                    previousText='Anterior'
                    nextText='Siguiente'
                    noDataText='Sin información disponible'
                    pageText='Página'
                    ofText='de'
                    rowsText='Elementos'
                    sorted={[{
                        id: 'idAlmacen',
                        desc: true
                    }
                    ]}
                    className="mb-8"
                />
            </div>
        </SiteWrapper>
    )
}


export default GpsAlmacen;