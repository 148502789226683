import React, { Component } from "react";
import { Button, Form, Icon, Grid, Table, Card, Dimmer, List, Alert } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { NotificationManager } from 'react-notifications';
import { ImportarT, Exportar, Plantilla } from './index';
import DateTimePicker from "react-datetime-picker";
var user = JSON.parse(sessionStorage.getItem("usuario"));
//Formato de Fecha
function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class ActivosForm extends Component {
  constructor(props){
    super(props);
    this.state = {
        showIncidenciaF : false,
        incidencias     : [],
        hiddenModal     : true,
        entregas        : [],
        entregasActiva  : "",
        activos      : this.props.activos  |[],
        filas           : [],
        entregas        : [],
        unidades        : [],
        creationDateI   : "",
        creationDateF   : "",
    };

    this.encabezados=[];
    this.aPlacas=[];
  }
  hiddenmodal = () => {
    this.setState({ hiddenModal: !this.state.hiddenModal });
  }
  movimientoTerrestrePlaca(id){
    let temporal ="";
    if (id){
      temporal = this.props.movimientosTerrestres.filter(word => word.id===id);
      if (temporal[0]){
        return temporal[0].placa;
      }
    }
  }
  movimientoTerrestreEco(id){
    if (id){
      try {
        if (this.props.gps[id].iconBattery.color){
          return(
            <div>
              <div  className="badge" style={{ color:this.props.gps[id].iconBattery.color, "font-size":"80%" }}>{ this.props.gps[id].nombre }</div>
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.props.gps[id].nombre+", "+this.props.gps[id].nivel_bateria+" %" }
                                            style={{ color:this.props.gps[id].iconBattery.color, "font-size":"70%" }}>
                <span className={this.props.gps[id].iconBattery.icono}></span>
              </div>
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.props.gps[id].ultimo_reporte }
                                            style={{ color:this.props.gps[id].iconTime.color, "font-size":"70%" }}>
                <span className={this.props.gps[id].iconTime.icono}></span>
              </div>
            </div>
          );
        }
      }catch(e){
        console.log("Error de iconBatery: "+e);
      }
    }
  }
  movimientoTerrestreEActiva(id){
    let entrAtva = [];
    let entrGas = [];
    if (this.props.entregas){
      entrGas = this.props.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    else//if (this.state.entregas)
    {
      entrGas = this.state.entregas.filter(word => word.idMovimiento==id);
      if (entrGas){
        entrAtva=entrGas.filter(word => word.estatus==1);
      }
    }
    if (entrAtva[0]){
      return entrAtva[0];
    }else{
      return 0;
    }
  }
  crud_put_alerta(tmpAlerta) {
    var d = new Date();
      var mes=(d.getMonth()+1);
      var dia=d.getDate();
      var mes1 = "";
      var dia1 = "";
      mes < 10 ? mes1="0"+mes: mes1=mes;
      dia < 10 ? dia1="0"+dia: dia1=dia;
      var horas= d.getHours();
      var minutos= d.getMinutes();
      var segundos= d.getSeconds();
      var horas1= "";
      var minutos1= "";
      var segundos1= "";
      horas < 10 ? horas1="0"+horas: horas1= horas;
      minutos < 10 ? minutos1="0"+minutos: minutos1= minutos;
      segundos < 10 ? segundos1="0"+segundos: segundos1= segundos;
      tmpAlerta.atendida=1;
      tmpAlerta.atiendeFecha = d.getFullYear()+"-"+mes1+"-"+dia1+" "+horas1+":"+minutos1+":"+segundos1;
      tmpAlerta.atiendeUsuario=this.props.usuario.id;
      console.log("92 tmpAlerta");
      console.log(tmpAlerta);
    this.props.getfuntion("put", tmpAlerta, "", "terrestresAlertas","", this.props.closeModal);
  }
  cargaEntregas() {
    console.log("114 user.id");
    console.log(user.id);
    Crud_Catalogos("terrestreMovimientos/terminados/usuario/"+user.id, "", "get", "", [],"" , "",     "", []).then((returnVal) => {
      console.log("115 returnVal");
      console.log(returnVal);
      if(returnVal.length>0){
        returnVal.map((mov, index) => {
          this.state.entregas.push(
            <Table.Row>
              <Table.Col>{mov.id}</Table.Col>
              <Table.Col>{mov.plataforma.nombre}</Table.Col>
              <Table.Col>{mov.transportista.nombre}</Table.Col>
              <Table.Col>{mov.origen}</Table.Col>
              <Table.Col>{mov.placa}</Table.Col>
              <Table.Col>{mov.creationDate}</Table.Col>
              <Table.Col>{mov.gps1}</Table.Col>
            </Table.Row>
          )
        })
      }else{
        this.state.entregas.push(
        <Table.Row>
          No Hay Movimientos activos para este Usuario
        </Table.Row>
        )
      }

    this.setState({ activos:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" corredores "+" verifique el Nombre del Catalogo");}  });
  }
  cargaActivos() {
       console.log("151 Cadena Carga");
       console.log("eTerrestreMovimientosGPSActivos/terminadosU/"+user.id+"/"+ this.state.creationDateI +"/"+this.state.creationDateF);
    Crud_Catalogos("eTerrestreMovimientosGPSActivos/terminadosU/"+user.id+"/"+ this.state.creationDateI +"/"+this.state.creationDateF, "", "get", "", [],"" , "",     "", []).then((returnVal) => {
      console.log("156 returnVal");
      console.log(returnVal);
      //this.setState({ filas:returnVal });
      if(returnVal.length>0){
        returnVal.map((mov, index) => {
          this.state.filas.push(
            <Table.Row>
              <Table.Col>{mov.idMovimiento}</Table.Col>
              <Table.Col>{mov.idEntrega}</Table.Col>
              <Table.Col>{mov.plataforma}</Table.Col>
              <Table.Col>{mov.transportista}</Table.Col>
              <Table.Col>{mov.origen}</Table.Col>
              <Table.Col>{mov.placa}</Table.Col>
              <Table.Col>{mov.gps1}</Table.Col>
              <Table.Col>{mov.creationDate}</Table.Col>
            </Table.Row>
          )
        })
      }else{
        this.satate.filas.push(
        <Table.Row>
          No Hay Movimientos Activos para este Usuario
        </Table.Row>
        )
      }

    this.setState({ activos:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" activos "+" verifique el Nombre del Catalogo");}  });
  }
  onChangeInicio = (event)  => {
    console.log("183 event.target.value");
    console.log(event.target.value);
    this.setState({ creationDateI: event.target.value+" 00:00:00" });
  }
  onChangeFinal = (event)  => {
    //this.setState({ creationDateF: myFormato(date) });
    console.log("189 event.target.value");
    console.log(event.target.value);
    this.setState({ creationDateF: event.target.value+" 23:59:59" });
  }

  componentWillMount(){
    console.log("114 componentWillMount");
    this.encabezados=([{ content:"MovId" },{ content:"IdEntrega" },{ content:"Plataforma" },{ content:"Transportista" },{ content:"Origen" },{ content:"Placa" },{ content:"GPS"},{ content:"Creation"}]);
    //{ this.cargaactivos() };
  }
  componentDidMount(){

  }
  componentDidUpdate = () => {
  }

  render() {
    return (
      <div>
      <Card>
        <Card.Header>
          <Card.Options>
          <span className="float-right ml-2" style={{ "width": "35%" }}>
            <Exportar crud={this.getfuntion} catalogo={"Activos"} data={this.state.activos} />
          </span>
          <span className="float-right ml-2" style={{ "width": "35%" }}> </span>
          <span className="float-right ml-2" style={{ "width": "35%" }}>
            <Form.Group label="Inicio:">
              <Form.Input type="date" name="Inicio"
                onChange={this.onChangeInicio}
              />
            </Form.Group>
          </span>
          <span className="float-right ml-2" style={{ "width": "35%" }}> </span>
          <span className="float-right ml-2" style={{ "width": "35%" }}>
            <Form.Group label="Fin:">
              <Form.Input type="date" name="Fin"
                onChange={this.onChangeFinal}
              />
            </Form.Group>
          </span>
          <span className="float-right ml-2" style={{ "width": "35%" }}> </span>
          <span className="float-left ml-2" style={{ "width": "35%" }}>
            <Button pill size="sm" className="btn-success" onClick={ ()=> this.cargaActivos() } >Ejecutar</Button>
          </span>

          </Card.Options>
        </Card.Header>
        <Card.Body>
          <Table cards={true} striped={true} responsive={true} className="table-vcenter" headerItems={this.encabezados}>
          <Table.Body>
            {this.state.filas}
          </Table.Body>
          </Table>
        </Card.Body>
      </Card>
      </div>
    );
  }
}
export default ActivosForm;
