import React, { useEffect, useState } from "react";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../../herramientas/Crud_Catalogos";
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";
import { Button } from "react-bootstrap";

const Correos = ({ corredor, plataforma }) => {
  const [corredoresWhatsappGrupos, setContactosCorredoresWhatsappGrupos] =
    useState([]);
  const [whatsappGrupos, setWhatsappGrupos] = useState([]);
  const [whatsappGrupo, setWhatsappGrupo] = useState([]);
  const [agregar, setAgregar] = useState(false);
  const [personas, setPersonas] = useState();
  const [correosPorCorredor, setCorreosPorCorredor] = useState();

  useEffect(() => {
    getfuntion(
      "get",
      "",
      "",
      `corredores_correos/${plataforma.id}/${corredor.id}`,
      "corredores_correos"
    );
  }, []);
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas",
    stateVar = "personas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            if (stateVar === "corredores_correos") {
              setCorreosPorCorredor(returnVal);
            } else if (stateVar === "personas") {
              console.log(returnVal);
              setPersonas(returnVal);
            }
            break;
          case "post":
            if (stateVar === "corredores_correos") {
              getfuntion(
                "get",
                "",
                "",
                `corredores_correos/${plataforma.id}/${corredor.id}`,
                "corredores_correos"
              );
              setAgregar(false);
            }
            break;
          case "delete":
            if (stateVar === "corredores_correos") {
              getfuntion(
                "get",
                "",
                "",
                `corredores_correos/${plataforma.id}/${corredor.id}`,
                "corredores_correos"
              );
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (!correosPorCorredor) {
    return <p>Cargando ...</p>;
  }

  return (
    <div className="mt-1">
      <div hidden={agregar}>
        <h4>Correos asignados</h4>
        <TablePageable
          titulo={
            <div className="row">
              <div className="col">
                <Button
                  onClick={() => {
                    setAgregar(true);
                    getfuntion("get", "", "", "personas", "personas");
                  }}
                >
                  Asignar persona
                </Button>
              </div>
              <div className="col">
                <i
                  className="fa fa-refresh"
                  style={{ "font-size": "15px" }}
                  onClick={() =>
                    getfuntion(
                      "get",
                      "",
                      "",
                      `corredores_correos/${plataforma.id}/${corredor.id}`,
                      "corredores_correos"
                    )
                  }
                ></i>
              </div>
            </div>
          }
          lista={correosPorCorredor}
          columnas={[
            {
              columna: "remover",
              label: "Remover",
              form: (e) => (
                <i
                  className="fa fa-remove"
                  style={{ "font-size": "24px" }}
                  onClick={() => {
                    getfuntion(
                      "delete",
                      "",
                      e.id,
                      "corredores_correos",
                      "corredores_correos"
                    );
                  }}
                ></i>
              ),
            },
            { columna: "id", label: "Id" },
            { columna: "correo", label: "Correo" },
            { columna: "nombre", label: "Nombre" },
            { columna: "apellidoPaterno", label: "Apellido Paterno" },
            { columna: "apellidoMaterno", label: "Apellido Materno" },
            { columna: "fecha", label: "Fecha" },
          ]}
          id="id"
          selected={(e) => setWhatsappGrupo(e)}
          paginacion={true}
          elementosPorPagina={15}
        />
      </div>
      <div hidden={!agregar}>
        {personas && (
          <TablePageable
            titulo={
              <h4>
                Listado de usuarios
                <button
                  type="button"
                  class="btn btn-danger ml-3"
                  onClick={() => setAgregar(false)}
                >
                  Cancelar
                </button>
              </h4>
            }
            lista={personas}
            columnas={[
              {
                columna: "agregar",
                label: "Agregar",
                form: (e) => (
                  <i
                    className="fa fa-plus"
                    style={{ "font-size": "24px" }}
                    onClick={() => {
                      const cwt = {
                        idPlataforma: plataforma.id,
                        idCorredor: corredor.id,
                        nombre: e.nombre,
                        apellidoPaterno: e.aPaterno,
                        apellidoMaterno: e.aMaterno,
                        correo: e.correo,
                      };
                      getfuntion(
                        "post",
                        cwt,
                        "",
                        "corredores_correos",
                        "corredores_correos"
                      );
                    }}
                  />
                ),
              },
              { columna: "id", label: "Id" },
              { columna: "nombre", label: "Nombre" },
              { columna: "aPaterno", label: "Apellido Paterno" },
              { columna: "aMaterno", label: "Apellido Materno" },
              { columna: "correo", label: "Correo" },
            ]}
            id="id"
            selected={(e) => setWhatsappGrupo(e)}
            paginacion={true}
            elementosPorPagina={10}
          />
        )}
      </div>
    </div>
  );
};
export default Correos;
