//import React, { Component } from 'react';
import axios from 'axios';
import * as conf from "../config/config";
import { validarToken } from "./JWT";
import { Crud_Catalogos, Crud_error } from './Crud_Catalogos';
var user = JSON.parse(sessionStorage.getItem("usuario"));
var catalogosLogObject = { id: 0, nombreCatalogo: "", idUsuario: user? user.id: 0, accionFecha: null, metodo:"", data:[] };
var dataNavieraMovimientos="";
var dia			=0;
var mes			=0;
var anio		=0;
var hora 		=0;
var minuto	=0;
var segundo =0;
var fechas	=0;
var horas		=0;
var movimientoTipo=[];
let url=conf.api_raiz;
const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';
const fhora			= 'HH:mm:ss';
const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31]
const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31]

//catalogos a excluir
const catalogosLogBlackList=["catalogosLog", "plataformasUsuariosPerfiles/username", "usuariosLog","validacionDatos/terrestre", "validacionDatos/naviera"];
function bisiesto(year){	return year % 4 == 0 && year % 100 != 0 || year % 400 == 0; }
function serieMes(numero, año){
  var cuenta 		=0;	var siguiente	=0;	var index			=0;  cuenta=numero;
  do {
		if(bisiesto(año)){
			siguiente=sbiss[index+1];
			cuenta=cuenta-sbiss[index];
			dia=cuenta;
		}else{
			siguiente=nobis[index+1];
			cuenta=cuenta-nobis[index];
			dia=cuenta;
		}
		index=index+1;
  }	while (cuenta>siguiente);
	mes=index+1;
	dia=cuenta-1;
}
function serieAnio(valor){
  var año   =1900;  var sumadias=0;  var residuo =0;
  do {
    if(bisiesto(año)){
      año = año +1;
      sumadias = sumadias + 366;
    }else{
      año = año +1;
      sumadias = sumadias + 365;
    }
  }while ((sumadias+365)<valor)
  residuo=valor-sumadias;
	anio=año;
  serieMes(residuo, año);
}
function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);
}
function validacionNavieraMovimientos(data, ObjValidar, j){
  console.log("Entra validacionNavieraMovimientos");
  //axios.post(url+"validacionDatos/naviera", ObjValidar).then(res => {
  Crud_Catalogos("validacionDatos/naviera",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then( returnVal => {
    //if( storeFunction !== "" )
    //{ storeFunction(res.data); }
    console.log("83 returnVal");
    console.log(returnVal);
    return returnVal;
  });
  console.log("Fin validacionNavieraMovimientos");
}
function valCatalogoId(Columna, data, Id, I, Campo){
  var k=0;
  switch (Columna) {
    case "vip":
      if(Id){
        Campo=true; //this.state.transportistas[I].id;
      }else{
        Campo=false; //this.state.transportistas[I].id;
      }
      data.vip=Campo;
      break;
    case "po":
      Campo.po=Id; //this.state.transportistas[I].id;
      data.po=Campo;
      break;
		case "citaProgramada":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				//horas = fechas - Number.parseFloat(Id);
        horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);				//datos[num]= ""+anio+"-"+mes+"-"+dia+""
        serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.citaProgramada=Campo;
      break;
		case "arriboTerminalVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.arriboTerminalVacio=Campo;
      break;
		case "salidaTerminalLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.salidaTerminalLleno=Campo;
      break;
		case "arriboClienteLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.arriboClienteLleno=Campo;
      break;
		case "salidaClienteVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.salidaClienteVacio=Campo;
      break;
		case "arriboTerminalVacio2":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.arriboTerminalVacio2=Campo;
      break;
		case "retiroContenedor":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);//datos[num]= ""+anio+"-"+mes+"-"+dia+""
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.retiroContenedor=Campo;
      break;
		case "arriboClienteVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.arriboClienteVacio=Campo;
      break;
		case "salidaClienteLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.salidaClienteLleno=Campo;
      break;
		case "arriboTerminalCargado":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        data.arriboTerminalCargado=Campo;
      break;

		//case "eta":
				//fechas = parseInt(Id, 10);
				//horas = Number.parseFloat(Id)-fechas;
				//serieHoras(horas);
        //Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].eta = Campo;
    //  break;
		case "delay":
        console.log("860 ontime");
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        data.delay=Campo;
      break;
		case "wti6h":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        data.wti6h=Campo;
      break;
		case "transit":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        data.transit=Campo;
      break;
		case "ontime":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        data.ontime=Campo;
      break;
    default:
      console.log("No Existe el Campo en la Columna "+Columna);
  }
}
function cargaMovimientoTipo() {
  Crud_Catalogos("movimientosTipo", "", "get", "", [],"" , "",     "", []).then((returnVal) => {
    return returnVal;
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" movimientosTipo "+" verifique el Nombre del Catalogo");}  });
}
export function errorCom(ErrorCode=-99, Catalogo=""){
        switch(ErrorCode){
                case 400: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Solicitud incorrecta";
                case 401: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+'"'+ Catalogo +'"'+ ", Pongase en contacto con el administrador del sistema";
                case 403: return "( Codigo de error: "+ErrorCode+"),   Usted no tiene permiso para acceder a "+'"'+ Catalogo +'"'+ ", Pongase en contacto con el administrador del sistema";
                case 404: return "( Codigo de error: "+ErrorCode+"),   No se pudo acceder a "+'"'+ Catalogo +'"'+ ", intente mas tarde o pongase en contacto con el administrador del sistema";
                case 405: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Método no permitido";
                case 408: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Tiempo agotado, la solicitud no se llevó a cabo dentro del tiempo establecido";
                case 415: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servidor se niega a aceptar la solicitud porque el formato de carga útil está en un formato no compatible";
                case 500: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Algo ha ido mal en el servidor del sitio web";
                case 502: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": Si se conecta a un servidor que actúa como una puerta de enlace o proxy, Es posible que los servidores que se comunican no están de acuerdo sobre el protocolo para intercambiar datos.";
                case 503: return "( Codigo de error: "+ErrorCode+"),   "+Catalogo+": El servicio está temporalmente no disponible";
                default: return "Error desconocido";
        }
}

//Crud_Catalogos("validacionDatos/naviera",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then( returnVal => {
//                             principal   temporal default      id     parametros opcional          state              cerrar modal    si error actualizar
export function completarValidacion(data="", movimientosTipo="", pestana="", ObjValidar){
  var tokenStore="tok";
  validarToken(sessionStorage.getItem(tokenStore));
  let instance = axios.create();
  instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.defaults.headers.common['Accept'] = 'application/json';
  console.log("Inicia Completar Validacion "+pestana);
  switch (pestana) {
    case 'navieraMovimientos':{
      var j = 0;
      data.map(datos =>{
        var plataforma      = {"id":"", "nombre":"" };
        var cliente         = {"id":"", "nombre":"" };
        var corredor        = {"id":"", "nombre":"" };
        var naviera         = {"id":"", "nombre":"" };
        var operacionTipo   = {"id":"", "nombre":"" };
        var otd             = {"id":"", "nombre":"" };
        var rutasEstatus    = {"id":"", "nombre":"" }; //rutasEstatus
        var transportista   = {"id":"", "nombre":"" };
        var operacionEstatus= {"id":"", "nombre":"" };
        var movimientoTipo  = {"id":"", "nombre":"" };
        var po              = {"po":"", "existe":false};
        var objeto          = { plataforma, cliente, corredor, movimientoTipo, naviera, operacionTipo, otd, rutasEstatus, transportista, operacionEstatus, po };
        Object.keys(datos).forEach(num =>{
          switch (num) {//Inicia Switch num
            case 'idPlataforma':
              if (Number.isInteger(datos[num])){
                plataforma.id=datos[num];
              }else{
                plataforma.nombre=datos[num];
              }
              data[j].idPlataforma=plataforma;
              break;
            case 'idCliente':
              if (Number.isInteger(datos[num])){
                cliente.id=datos[num];
              }else{
                cliente.nombre=datos[num];
              }
              data[j].idCliente=cliente;
              break;
            case 'idCorredor':
              if (Number.isInteger(datos[num])){
                corredor.id=datos[num];
              }else{
                corredor.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idCorredor=corredor;
              data[j].idCorredor=corredor;
              break;
            case 'idNaviera':
              if (Number.isInteger(datos[num])){
                naviera.id=datos[num];
              }else{
                naviera.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idNaviera=naviera;
              data[j].idNaviera=naviera;
              break;
            case 'idOperacionTipo':
              if (Number.isInteger(datos[num])){
                operacionTipo.id=datos[num];
              }else{
                operacionTipo.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idOperacionTipo=operacionTipo;
              data[j].idOperacionTipo=operacionTipo;
              break;
            case 'idOtd':
              if (Number.isInteger(datos[num])){
                otd.id=datos[num];
              }else{
                otd.nombre=datos[num];
              }
              data[j].idOtd=otd;
              break;
            case 'idRuta':
              if (Number.isInteger(datos[num])){
                rutasEstatus.id=datos[num];
              }else{
                rutasEstatus.nombre=datos[num];
              }
              data[j].idRuta=rutasEstatus;
              break;
            case 'idTransportista':
              if (Number.isInteger(datos[num])){
                transportista.id=datos[num];
              }else{
                transportista.nombre=datos[num];
              }
              data[j].idTransportista=transportista;
              break;
            case 'idOperacionEstatus':
              if (Number.isInteger(datos[num])){
                operacionEstatus.id=datos[num];
              }else{
                operacionEstatus.nombre=datos[num];
              }
              data[j].idOperacionEstatus=operacionEstatus;
              break;

            case 'idMovimientoTipo':
              if (Number.isInteger(datos[num])){
                movimientoTipo.id=datos[num];
                movimientosTipo.map(ids => {
                  if(movimientoTipo.id!==""){
                    if (ids.id===movimientoTipo.id){
                      data[j].idMovimientoTipo=ids;
                    }
                  }else{
                    if (ids.nombre===movimientoTipo.nombre){
                      data[j].idMovimientoTipo=ids;
                    }
                  }
                });
              }else{
                movimientoTipo.nombre=datos[num];
                movimientosTipo.map(ids => {
                  if(movimientoTipo.id!==""){
                    if (ids.id===movimientoTipo.id){
                      data[j].idMovimientoTipo=ids;
                    }
                  }else{
                    if (ids.nombre===movimientoTipo.nombre){
                      data[j].idMovimientoTipo=ids;
                    }
                  }
                });
              }
              break;

            case 'citaProgramada':
              var citaProgramada="";
              citaProgramada=datos[num];//this.state.data[j].citaProgramada;
              valCatalogoId("citaProgramada", data[j], datos[num], j, citaProgramada);
              break;
            case 'arriboTerminalVacio':
              var arriboTerminalVacio="";
              arriboTerminalVacio=datos[num];//this.state.data[j].arriboTerminalVacio;
              valCatalogoId("arriboTerminalVacio", data[j], datos[num], j, arriboTerminalVacio);
              break;
            case 'salidaTerminalLleno':
              var salidaTerminalLleno="";
              salidaTerminalLleno=datos[num];//this.state.data[j].salidaTerminalLleno;
              valCatalogoId("salidaTerminalLleno", data[j], datos[num], j, salidaTerminalLleno);
              break;
            case 'arriboClienteLleno':
              var arriboClienteLleno="";
              arriboClienteLleno=datos[num];//this.state.data[j].arriboClienteLleno;
              valCatalogoId("arriboClienteLleno", data[j], datos[num], j, arriboClienteLleno);
              break;
            case 'salidaClienteVacio':
              var salidaClienteVacio="";
              salidaClienteVacio=datos[num];//this.state.data[j].salidaClienteVacio;
              valCatalogoId("salidaClienteVacio", data[j], datos[num], j, salidaClienteVacio);
              break;
            case 'arriboTerminalVacio2':
              var arriboTerminalVacio2="";
              arriboTerminalVacio2=datos[num];//this.state.data[j].arriboTerminalVacio2;
              valCatalogoId("arriboTerminalVacio2", data[j], datos[num], j, arriboTerminalVacio2);
              break;
            case 'retiroContenedor':
              var retiroContenedor="";
              retiroContenedor=datos[num];//this.state.data[j].retiroContenedor;
              valCatalogoId("retiroContenedor", data[j], datos[num], j, retiroContenedor);
              break;
            case 'arriboClienteVacio':
              var arriboClienteVacio="";
              arriboClienteVacio=datos[num];//this.state.data[j].arriboClienteVacio;
              valCatalogoId("arriboClienteVacio", data[j], datos[num], j, arriboClienteVacio);
              break;
            case 'salidaClienteLleno':
              var salidaClienteLleno="";
              salidaClienteLleno=datos[num];//this.state.data[j].salidaClienteLleno;
              valCatalogoId("salidaClienteLleno", data[j], datos[num], j, salidaClienteLleno);
              break;
            case 'arriboTerminalCargado':
              var arriboTerminalCargado="";
              arriboTerminalCargado=datos[num];//this.state.data[j].arriboTerminalCargado;
              valCatalogoId("arriboTerminalCargado", data[j], datos[num], j, arriboTerminalCargado);
              break;
            case 'wti6h':
              var wti6h="";
              wti6h=datos[num];//this.state.data[j].retiroContenedor;
              valCatalogoId("wti6h", data[j], datos[num], j, wti6h);
              break;
            case 'ontime':
              var ontime="";
              ontime=datos[num];//this.state.data[j].ontime;
              valCatalogoId("ontime", data[j], datos[num], j, ontime);
              break;
            case 'vip':
              var vip="";
              vip=datos[num];//this.state.data[j].ontime;
              valCatalogoId("vip", data[j], datos[num], j, vip);
              break;
            case 'po':
              po.po=datos[num];
              //{ this.valCatalogoId("po", datos[num], j, po) }
              //{ this.valYaExiste("movimientos", datos[num], j, po) }
              data[j].po=po.po;
              break;
            default:
              console.log("La Columna:"+num + " queda como está...");
              break;
          }//Fin Switch num
        });
        //var temporal="";
        console.log("469 Ini validacionNavieraMovimientos");
        //data[j]=validacionNavieraMovimientos(data, data[j], j);
        Crud_Catalogos("validacionDatos/naviera", "", "post", "", data[j]).then(returnVal =>{
          console.log("473 returnVal");
          console.log(returnVal);
          //data[j]=returnVal;
          //return returnVal;
        });
        console.log("478 data[j]");
        console.log(data[j]);

        //data[j]=
        //axios.post(url+"validacionDatos/naviera", data[j]).then(returnVal => {
        //data[j]=

        //Crud_Catalogos("validacionDatos/naviera", "", "post", "", data[j],"", "", "", [], "", "", "").then( returnVal => {
        //  console.log("83 returnVal");
        //  console.log(returnVal);
        //  return returnVal;
          //return returnVal;
        //});//.catch(e){
          //console.log("479 Error en validacionNavieraMovimientos: " + e);
        //}
        console.log("481 Fin validacionNavieraMovimientos");
        j=j+1;
      });
      //break;//Fin Completa naveiraMovimientos
      console.log("547 data");
      console.log(data);
      return data;
    }
    default:
      console.log("No se proceso nada");
      return null;
  }
/*
  switch(pestana){
    case 'navieraMovimientos':{
      return axios.post(url+"validacionDatos/naviera", ObjValidar).then(res => {
        console.log("41 res");
        console.log(res);
        return res;
      });
    }
  }
*/
}

export default completarValidacion;
