import React, { Component } from "react";
import { Card, Form, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

class UsuarioClientesFinal extends Component {
    constructor() {
        super();
        this.state = {
            clientesNavieros:[],
            pClientesNavieros:[],
        };
    }
    componentWillMount(){
      this.getfuntion("get", "", "", "cli_usuarios_clientes_final/usuario/"+this.props.usuario.id+"/plataforma/"+this.props.idPlataforma, "usuarios_clientesNavieros");
      this.getfuntion("get", "", "", "clientes_navieros/plataforma/"+this.props.idPlataforma, "clientesNavieros");
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                if (stateVar === "clientesNavieros")
                  this.setState({ clientesNavieros: returnVal });
                if (stateVar === "usuarios_clientesNavieros")
                  this.setState({ pClientesNavieros: returnVal });
                break;
              case "post":
                if (stateVar === "usuarios_clientesNavieros"){
                  let pClientesNavierosTemp= this.state.pClientesNavieros;  
                  pClientesNavierosTemp.push(returnVal);
                  this.setState({ pClientesNavieros: pClientesNavierosTemp });
                }
                break;
              case "delete":
                if (stateVar === "usuarios_clientesNavieros" && returnVal){ 
                  let varTemp= this.state.pClientesNavieros;
                  varTemp.map((elem, index )=>{
                    if(obj.idClienteNaviero === elem.idClienteNaviero ){ varTemp.splice( index, 1);  this.setState({ pClientesNavieros: varTemp }); }
                  });
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
    changeClienteNaviero=(event)=>{
      let objTemp= {idUsuario: this.props.usuario.id, idClienteNaviero: parseInt(event.target.value, 10), idPlataforma: this.props.idPlataforma };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "cli_usuarios_clientes_final","usuarios_clientesNavieros");  
      }else{
        this.getfuntion("delete", objTemp, this.props.usuario.id+"/"+parseInt(event.target.value, 10)+"/"+this.props.idPlataforma, "cli_usuarios_clientes_final","usuarios_clientesNavieros");
      }      
    }
    getClienteNaviero    =(id)=>{ for( let  item of this.state.pClientesNavieros ){  if( item.idClienteNaviero === id ){   return true; } } return false; }
    render() {
        return (
            <div>
              <Card>
                <Card.Body>
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>Id</Table.ColHeader>
                        <Table.ColHeader>ClienteNaviero  </Table.ColHeader>
                        <Table.ColHeader>estatus </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.clientesNavieros.map((t, index) =>
                        <Table.Row align="center">
                            <Table.Col>{ t.id }</Table.Col>
                            <Table.Col>{ t.nombre }</Table.Col>
                            <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getClienteNaviero(t.id) } onChange={ this.changeClienteNaviero} /></Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>                                                            
                </Card.Body>
              </Card>
                <br/>
            </div>
        );
    }
}

export default UsuarioClientesFinal;