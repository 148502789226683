export const PRIVATE_KEY= `-----BEGIN RSA PRIVATE KEY-----
MIIJKAIBAAKCAgEArTpI9itYWXllT/rPnqH5n/aBsluBRqJE4oHTDUBcgDqZZE3e
QQNA6qtsM5pKtkgfGT/IFyh0Sndcg4t/Ymki1sCVZWZ3ds1xG99+7qpGbfh0NeuB
JsqugK2Z0OEIpxCNJAk5aB19CiRkKl/qZJ670B74Cyu30WwTg1YzTBIUVTUvAaKy
4JkKp86gozvbwK+4tpUqn/7+ni1cDKp3P9d6ol1gIlFtu8+wVFOh6Rz6VyhBFz04
QR9FSi3ZYXUzSiAOK+kQBVcWfONw8vnxKSxEvMpzKB1XgqolMy1Evbk11lGVhSyj
oUgUcvEiKSucaZWkwHNIQcGgi1s8WRQ7NNneNjpmImZB0udIcGO+w7e2gd/HTjDV
hZyPWyptz4P7/vDVB4wbxo2LDmaDm2/qBrOKUoYP9cPzb+vEeqxzly5d+LjT6cUT
pgV42Bu0cYxsAAmoPwKAPmDupS+BAm56gRQXO1GSDmi8IeGUyDeo7Fj0TjTnCNDC
O3XlisiKlI2B2bZ8cX+q/mDle75qngHjA4XYq+ruFbAep/wBXU+Hu5DGL5PgI3dc
xPMMezwHu5YbrfjzjkakX1hifCZtssOp6Is2hDQOMc0rYuhw3Gx/OR3U8/LQq38q
e/+hQ1ojDv6R/cx7QO9wA+0bgKRi63cTVj4OdNLJTmBUZ7ni1CPRuVGBz28CAwEA
AQKCAgBqyRNRLUc5YOxjcWmrkI4hXgR1vpLiyAVO3FkOQi7DnVwRyS7JRQgvoZ0B
8os78xm4pujh3b5SaWJOdSABum4PlIiECbZ7NgoT9KJ63dyszgxVcOgc0bTg/iNj
8FU1J67aGBWbaRI7vlNBC/+iJ3f/xBeES171anpc7DaTHlIKdM6VY4aBjdZTu7yq
46uEDM8BPtMX7klnmO61oCAC6wJeNNGeVjQfItjAIw4cdhCcyePWP5b+DhjYsYrf
2S/U8g3KY9HMePMT6eEm540SpId7GhIDGJNpqRllgvJtQk893u0dO3djZNu2P4+f
cZLqQ6VcPbS9VG9zeLqq7x3QZr3kDFfHNtcSz6EMlC7pVlxHPmnFuiwZLRsp0X7z
yaLgtBNSRLNIP3XPOqZuhJCwefzgloQU7fEWf0epmMbyL8s9tvUnaBrM+I1pAizH
1JX+V19K8wUjCvp0yNh3bTD7LH737kqh+LGtKeOQNwgBZ0UWrdri/vvQYeB0W6v+
OHBy2aXVoc63CsZSK2ZYBkxsyDBwM7mh7sQz3QSjN8qSVj+6YMHo7mY2WvnlHMfm
uXRmVwHpad3cgGDHqB0HP5XNBs+A0WqexkEOj5nOaDgSOpjVl52Zkd+D/zZGaY0E
diJ9ek6LCuKo0SpTucDjVLnh99apDqPuasL7XQfWF9IUCq99AQKCAQEA1E0LD+Eo
xACY7DjBgagKbpt5gDx7SdA35lJnyAJgceS+uWSc+zOOvtovFiFLdzWWxDv6XW87
yqge91yptRQDOKy0e1JsIZpRExPEixwr9jPXpXVk0ynHJheiVhScIKuyZqExpyMT
UDfWMtHbCl4AEktQu9+jIhxLRJeQy3+JbvqHv74KV48652rGjtwDeH+PXJASSSY+
3Y5jrFPe/wKDSOkY7zr7miVCOT6mX76dsk2mrQKZp1FhbauPTqwhc4yY22Ii0ce7
2RuEzTArzZYxaY8hxEZAvpH9sUdVEh55JCu2NpIy6lUjFPJi41eKCDZ2mh1qhFg2
AG/ai+G6TiEkWQKCAQEA0OJR8oXp3gJqi4ZuDXdqmYQ2mpXgSoHBWSPJaoUPmUYE
Vi2ZyenoljABhXyO9rILBQR5NTRideGZcuGHb8ZgJRcZHsDJ1lGxNoTVJTdgNVUp
gNTFyGLUFHrp19z4VOOmPFZzFz79KFKKO9c3iL3b2rcwdzy7OABC4r47MB9w9ova
fk0c1b+C7sVr5mBnO+blx/5ezJ8slWwuR5BC9tVPa4iR+0/jF4Hv+HaIRuudIWMW
q4f61EPZEYr9DZidpaBXNyRnFLyjm80xfP/NoEUlYoGbRe+MrN6SNQ2448oUPZOJ
v/piRd9geYLNLg+aA2ZvVa/c3zh67NTk6bROUVI5BwKCAQEAyj4aq6XzD8UB6Qao
ULukNHon5OG5ETgqCF+4PfEFr3oZtsMCSMt00JtI/+v32DhUx9vbRecRLp254iuD
/9xwtSzRQinimKBJW8MZGOxddM0uQZDz27WQ4djh7N71XKoz6VDl3DKt011d0Eva
PqYNq1/UKSWCYpxRl6ZLaZC1e+NuLlr7vy3TD2HIhY9SPxhFmgglY0nr2WQrz56W
cJgUOQDVoBCwXsffrmjgVZVg5NrUTTyM47fC+JbWjjusIkdt1pBGW1HKwahVeqLJ
F7BcohKYOEI0q1aoNpmdkQU2oh6CFrpvhoxUP0AotCpQdEJt+aLMNgdSalgj+d2v
fX5VAQKCAQAOgVjddY0q/JH3hGtKBhvh8ztzeqrroZ0SmpiXDAxsQV91uI7wZYzg
OQ8x/NdUrEJ1o7zjA/M+al8kiiLEkUsF6Fe3Of4KIvwiZBEFF5ZXvcU3DA97/djq
Xyqz8cVQ58oYcIxnddlQnoK46xYRKdYFlykHbrE1j1cVtc4YZmaKyGVw1dwFv9nQ
yObYRaOFlmAohjKBFSCcc7m3AH6V83QjbIVupTqI3F4Uq+6EtkHgGqhtWvEBIoEF
ZdhyxEFWSg8kZiO+Xecin5orKvxVf9e7wnJM5fin3IDcrEpFjzfemss7CKEgZwWl
Cn9DDpRMXiWnFqJI4fwvTA1laT7zCLmrAoIBAAEvC5DYSH1hcf66d1E2IiaMufUq
yhtCSgyFegBaQm3aLB3nNmDtV5hV8yqGh+8XocC4fdPyT0e+0ufWGQR03IcVbh6E
z1+AdIkjHuRlf8TaqU07hMEnNdtiu304R2v4IHYLwSLPs6wWdEOy8M46dOG8J9+F
TJW9xts1+0wcb2iA/RF1UveZPaU8D/CiUGvxVO+crB2Cmffju67Sf0vJBiezDCh8
vX2RH06FdwhnB/jGDjNXfIkQSVdt4RS1XXZSYQplMQ5ooTjx7O0BpXEnVD8LLLN2
RXGbt7hAgzBXSAO6ZVwAX7xYI24v3woC7VWLRSoLz4AdFQtzBIrqA62yw7Y=
-----END RSA PRIVATE KEY-----`;