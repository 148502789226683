export function Forms(){ }
//valida correo
export function mail(email=""){
    let mail= false; var arroba= false; var domain= false;
    let mailChart= ""; var arrobaChart= ""; var domainChart= "";
    let error= "";
	  const sinbolos = ["@", "-", "_", "."];
    let textPattern = "^(?=.*[a-z]|[A-Z]|[0-9]).+$";//|(?=.*[A-Z])|(?=.*[0-9])    
    for (var i = 0; i < email.length; i++) {
      if(!arroba && !domain && email.charAt(i) === "@"){
        arrobaChart=arrobaChart+email.charAt(i); arroba= true;
        if(mailChart.length > 1 ){ mail=true }
      }
      if(!arroba && !domain){ mailChart= mailChart+email.charAt(i); }
      if(mail && arroba && email.charAt(i) !== "@"){ domainChart= domainChart+email.charAt(i); }
      //Buscar errores
      if( email.charAt(i) === " "){
        error= error + (error.length>0?", ":"")+"el correo contiene espacios";
      }else{
        if( !email.charAt(i).match(textPattern) ) {
          if( !sinbolos.includes(email.charAt(i)) ) {//String.valueOf(correo.charAt(i))
              error= error + (error.length>0?", ":"")+"el simbolo "+email.charAt(i)+" no esta permitido";
          }
        }
      }
    }
    if( domainChart.includes(".")
        && domainChart.length > 4
        && domainChart.charAt(0) !== "."
        && domainChart.charAt(domainChart.length-1) !== "."
        ){ domain= true; }
    if((!mail || !arroba || !domain) && !error.length){
      error= error + (error.length>0?", ":"")+"no parace ser una dirección de correo valida";
    }
    if(mail && arroba && domain && !error.length ){
      return { estatus: true, mensaje: error };
    }else{
      return { estatus: false, mensaje: error };
    }
}
export function text(txt= "", min= 0, max= 0){
    if(txt.length){
        if(txt.length >= min &&  txt.length <= max ){ return true;  }
        if(txt.length  < min ||  txt.length > max  ){ return false; }
    }else{ return false; }
}
/**
 * Si un valor es falso regresa true
 * Ejemplo de parametro ->  
 * {
 *  guardando: true,
 *  nombre: nombre.length > 1,
 *  correo: false,
 * }
 * @Respuesta { true }
 * @param
 * @returns 
 */
export function disabledSave(valid){
    let band= false;
    Object.keys(valid).forEach(key =>{ if( !valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
}

export default Forms;
