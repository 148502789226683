import React, { useState, useEffect, useContext } from 'react';
//import PropTypes from 'prop-types';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import { Grid, Button, Icon, Form } from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import { data } from 'jquery';
import { timeToYearmonthDay } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";
import { softphoneContext } from "../../context/softphoneContext";
import { TablePageable } from "../../Helpers";
const perfil= JSON.parse(sessionStorage.getItem("perfil"));

const ReportesLLAmadasPage = () => {
  const softphone = useContext(softphoneContext);
  const [funcionalidades, setFuncionalidades]= useState([]);
  const [troncales, setTroncales] = useState([]);
  const [fecha1, setFecha1] = useState(timeToYearmonthDay(new Date().getTime()));
  const [fecha2, setFecha2] = useState(timeToYearmonthDay(new Date().getTime()));
  const [tipoLLamada, settipoLLamada ] = useState( { value: "", label: "Todas los tipos" } );
  const [telefono, setTelefono] = useState("");
  const [grupos, setGrupos] = useState([]);
  const [ grupo, setGrupo ] = useState( { value: 0, label: "Seleccione una opción", data: null } );
  const [agentes, setAgentes] = useState([]);
  const [agente, setAgente ] = useState( { value: "", label: "Todos los agentes", data: null } );


  const [ reporte, setReporte ] = useState( []);


  

  useEffect(() => {
    crud("get", "", "", "funcionalidades/reporte_llamadas/"+perfil.id, "funcionalidades");
  },[]);

  useEffect(() => {
    if(funcionalidades.length){
      crud("get", "", "", "softphone_configuracion" , "softphonePlataforma");
    }    
  },[funcionalidades]);

  useEffect(() => {
    if(troncales.length){
      crud("get", "", "", "softphone/grupos_list" , "grupos");
    }
  },[troncales]);

  useEffect(() => {
    if(grupo.value){
      crud("get", "", "", "softphone/agentes/grupo/"+ grupo.value , "agentes");
    }
  },[grupo]);

  const crud=(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get":
              if( stateVar === "funcionalidades"){console.log(returnVal);
                setFuncionalidades(returnVal);
              }
              if( stateVar === "softphonePlataforma"){
                setTroncales(returnVal);
              }
              if( stateVar === "grupos"){
                let gruposTemp= [{ value: 0, label: "Seleccione una opción", data: null }];
                for( let g of returnVal){
                  let troncalTemp= troncales.filter(t=> t.idGrupo === g.id);
                  if( troncalTemp.length === 1){
                    g.extension= troncalTemp[0].extension;
                  }
                  gruposTemp.push({ value: g.id, label: g.name, data: g });
                  if( g.id === softphone.getValues().grupo){
                    setGrupo(gruposTemp[gruposTemp.length-1]);
                  }
                }
                setGrupos(gruposTemp);
              }

              if( stateVar === "agentes"){
                let agentesTemp= [{ value: "", label: "Todos los agentes", data: null }];
                for( let a of returnVal){
                  agentesTemp.push({ value: a.id, label: a.name+" "+ a.lastname, data: a });
                  if( a.id === softphone.getValues().idPbx){
                    setAgente(agentesTemp[agentesTemp.length-1]);
                  }
                }
                setAgentes(agentesTemp);
              }
              if( stateVar === "reporte"){
                setReporte(returnVal);
              }
            break;
            default:
                break;
        }
    }).catch(err => { console.log(err); });
  }
  {/**
    [
    {
        "nombre": "audio",
        "label": "Audio",
        "id": 3189,
        "bloque": {
            "nombre": "reporte_llamadas",
            "id": 927
        }
    },
    {
        "nombre": "exportar",
        "label": "Exportar Csv",
        "id": 3190,
        "bloque": {
            "nombre": "reporte_llamadas",
            "id": 927
        }
    },
    {
        "nombre": "grupos_agentes",
        "label": "Filtrar por Grupos y agentes",
        "id": 3188,
        "bloque": {
            "nombre": "reporte_llamadas",
            "id": 927
        }
    },
    {
        "nombre": "ver_reporte",
        "label": "Ver reporte",
        "id": 3187,
        "bloque": {
            "nombre": "reporte_llamadas",
            "id": 927
        }
    }
]
    */}
  return (
    <SiteWrapper>
      { funcionalidades.filter(f=> f.nombre === "ver_reporte").length?
        <div className='container-fluid p-6'>
          <h5> Reporte de llamadas </h5>
          <hr color='white'/>
          <div className='row mt-5'>
            <div className='col-12'>
              <TablePageable
                titulo={
                  <div className="row">
                    <div className='col'>
                      <label>Fecha inicio</label>
                      <input className="form-control" type="date" id="start" name="trip-start"
                        value={fecha1}
                        onChange={(e)=>{
                          setFecha1(e.target.value);
                          if( new Date(e.target.value).getTime() > new Date(fecha2).getTime() ){
                            setFecha2(e.target.value);
                          }else{
                            if( (new Date(fecha2).getTime() - new Date(e.target.value).getTime()) > (31*24*60*60*1000) ){                      
                              setFecha2(timeToYearmonthDay(new Date(e.target.value).getTime()+(31*24*60*60*1000)));
                            }
                          }
                        }}
                        min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                    </div>
                    <div className='col'>
                      <label>Fecha fin</label>
                      <input className="form-control" type="date" id="start" name="trip-start"
                        value={fecha2}
                        onChange={(e)=>{
                          setFecha2(e.target.value);
                          if( new Date(e.target.value).getTime() < new Date(fecha1).getTime() ){
                            setFecha1(e.target.value);
                          }else{
                            if( (new Date(e.target.value).getTime() - new Date(fecha1).getTime()) > (31*24*60*60*1000) ){                      
                              setFecha1(timeToYearmonthDay(new Date(e.target.value).getTime()-(31*24*60*60*1000)));
                            }
                          }
                        }}
                        min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                    </div>
                    <div className='col'>
                      <label>Tipo de llamada</label>
                      <Select
                        value={tipoLLamada}
                        onChange={ (e)=> settipoLLamada(e) }
                        options={[
                          { value: "", label: "Todas los tipos" },
                          { value: 'inbound', label: 'inbound'},
                          { value: 'outbound', label: 'outbound'},
                          { value: 'internal', label: 'internal'},
                          { value: 'clicktocall', label: 'clicktocall'},
                          ]} />
                    </div>
                    <div className='col' hidden={ funcionalidades.filter(f=> f.nombre === "grupos_agentes").length === 0}>
                      <label>Telefono</label>
                      <input className="form-control" type="number" value={ telefono } onChange={(e)=>setTelefono(e.target.value)} placeholder="número" />
                    </div>
                    <div className='col' hidden={ funcionalidades.filter(f=> f.nombre === "grupos_agentes").length === 0}>
                      <label>Grupos</label>
                      <Select value={grupo} onChange={ (e)=> setGrupo(e) } options={grupos} />
                    </div>
                    <div className='col' hidden={ funcionalidades.filter(f=> f.nombre === "grupos_agentes").length === 0}>
                      <label>Agentes</label>
                      <Select value={agente} onChange={ (e)=> setAgente(e) } options={agentes} />
                    </div>
                    <div className='col-1'>
                        <i className="fa fa-refresh text-primary btn"
                          style={{ fontSize: "40px"}}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Actualizar"}
                          onClick={()=>{
                            if(grupo.data){
                              crud("get", "", "",
                                "softphone/reporte?f1="+fecha1+" 00:00:00&f2="+fecha2+" 23:59:59&tipo="+tipoLLamada.value+"&phone="+telefono+"&grupo="+grupo.data.extension+"&agente="+agente.value,
                                "reporte");
                            }              
                          }}></i>
                    </div>
                  </div>
                }
                lista= { reporte }
                columnas={
                  [
                    { columna: "calldate", label: "Fecha" },
                    { columna: "accountcode", label: "Cuenta" },
                    { columna: "src", label: "Origen" },
                    { columna: "dst", label: "Destino" },
                    { columna: "duration", label: "Duración" },
                    { columna: "type", label: "Tipo"},
                    { columna: "disposition", label: "Disposición" },
                    { columna: "audio", label: "Audio",
                      form: (e)=>{
                        if(funcionalidades.filter(f=> f.nombre === "audio").length){
                          //console.log(e.uniqueid+": "+e.recordingfile);
                          return <audio controls key={e.uniqueid}>
                            <source src={e.recordingfile} type="audio/mpeg" />
                            Your browser does not support the audio element.
                          </audio>
                        }else{
                          return "";
                        }
                      }
                    },
                  ]
                }
                id= "uniqueid"/*
                selected={(e)=>{
                  const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                  if( etiquetaTemp.length !== 1){
                    alert("Precaución la incidencia: \n"+ e.nombre + " \n No esta configurada para envío de notificaciones "+ "\n Contacta a sistemas");
                  }
                }}*/
                csvname= {funcionalidades.filter(f=> f.nombre === "exportar").length?("Reporte de llamadas "+grupo.label): null}
                /*
                resaltarFuncion={(e)=>{
                  return incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false
                }}
                resaltarColor={(e)=>{
                  const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                  if( etiquetaTemp.length !== 1){
                    return "rgba(242, 151, 151, 0.41)";
                  }
                  return "rgba(58, 223, 149, 0.25)";
                }}*/
                paginacion={true}
                elementosPorPagina={15}
              />
            </div>
          </div>
        </div>
      :null   
      }
               
    </SiteWrapper>
  );
}


export default ReportesLLAmadasPage;
