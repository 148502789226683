import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { completarValidacion, errorCom } from '../completarValidacion';
import { Dimmer } from "tabler-react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Tab, Tabs, TabList, TabPanel } from './tabs-generic/index';
import TablaCatalogo from './tabla-generic/index';

var     id	=0;
var terrores=0;
let sinError=0;
var dia			=0;
var mes			=0;
var anio		=0;
var hora 		=0;
var minuto	=0;
var segundo =0;
var personasvar="";
var clientevar="";
var clientesContactos="";
var fechas	=0;
var horas		=0;
var arrayErr=[];
let Errores	=[];
let FilaErr	=[];
let Cuadros =[];
let TabTemp =[];
let TabPanelTemp=[];

const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';
const fhora			= 'HH:mm:ss';
const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31]
const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31]

function myModalMN(Muestra) {
  var x = document.getElementById("xlsModalMN");
  x.style.display = Muestra;
}
function bisiesto(year){	return year % 4 == 0 && year % 100 != 0 || year % 400 == 0; }
function pad(number) {  if (number < 10) { return '0' + number; }  return number; }
function myFormato(date) {
function pad(number) {
  if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
function serieMes(numero, año){
  var cuenta 		=0;	var siguiente	=0;	var index			=0;  cuenta=numero;
  do {
		if(bisiesto(año)){
			siguiente=sbiss[index+1];
			cuenta=cuenta-sbiss[index];
			dia=cuenta;
		}else{
			siguiente=nobis[index+1];
			cuenta=cuenta-nobis[index];
			dia=cuenta;
		}
		index=index+1;
  }	while (cuenta>siguiente);
	mes=index+1;
	dia=cuenta-1;
}
function serieAnio(valor){
  var año   =1900;  var sumadias=0;  var residuo =0;
  do {
    if(bisiesto(año)){
      año = año +1;
      sumadias = sumadias + 366;
    }else{
      año = año +1;
      sumadias = sumadias + 365;
    }
  }while ((sumadias+365)<valor)
  residuo=valor-sumadias;
	anio=año;
  serieMes(residuo, año);
}
function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarMN extends Component
{
  constructor(props) {
    super(props);
    this.state = {
      catalogo:this.props.catalogo                |"",

      //Catalogo Funcionalidades
        bloque:this.props.bloque                   |[],

//Catalogo Funcionalidades
    categorias:this.props.categorias              |[],

//Tabla Movimientos
    idPlataforma:this.props.idPlataforma            |[],
       idCliente:this.props.idCliente               |[],
      idCorredor:this.props.idCorredor              |[],
idMovimientoTipo:this.props.idMovimientoTipo        |[],
       idNaviera:this.props.idNaviera               |[],
 idOperacionTipo:this.props.idOperacionTipo         |[],
           idOtd:this.props.idOtd                   |[],
          idRuta:this.props.idRuta                  |[],
 idTransportista:this.props.idTransportista         |[],
operacionesEstatus:this.props.operacionesEstatus    |[],

//Tabla Unidades
        plataformas:this.props.plataformas          |[],
     transportistas:this.props.transportistas       |[],

//Variables
        accion:this.props.accion,
      validado:this.props.validado                |false,

//Variables de Datos
                              data:this.props.data										  |[],
                  dataUnidadTerNav:this.props.dataUnidadTerNav          |[],
        dataMovimientoUnidadTerNav:this.props.dataMovimientoUnidadTerNav|[],
            dataNavieraMovimientos:this.props.dataNavieraMovimientos	  |[],

               dataOtd:this.props.dataOtd							    |[],
          dataNavieras:this.props.dataNavieras						|[],
       dataPlataformas:this.props.dataPlataformas					|[],
   dataOperacionesTipo:this.props.dataOperacionesTipo			|[],
dataOperacionesEstatus:this.props.dataOperacionesEstatus	|[],
              dataRuta:this.props.dataRuta							  |[],
    dataMovimientoTipo:this.props.dataMovimientoTipo			|[],
    dataTransportistas:this.props.dataTransportistas			|[],
        dataCorredores:this.props.dataCorredores			    |[],
           dataCliente:this.props.dataCliente							|[],
    dataContenedorTipo:this.props.dataContenedorTipo			|[],

    };
    this.cita=0;
    this.cadenadeCarga="";
    this.usuariosLogObject = {
      "idBitacoraMonitoreo":0,
      "idUsuario":0,
      "idMovimiento":0,
      "accionFecha":"",
      "descripcion":"",
      "plataformaTipo":2
    };
  }
  componentDidMount(){
		myModalMN("none");
  }
  componentWillMount(){
    { this.cargaPlataformas() };
    { this.cargaTransportistas() };
    { this.cargaUnidadTipo() };
    { this.cargaUnidadMarca() };
    { this.cargaUnidadEstatusOperacion() };

    { this.cargaMovimientoTipo() };
  }
  componentDidUpdate(){
    //console.log("358 componentDidUpdate");
  }
  clickTab(nombreTab){
    console.log("366 Click en Tab: "+ nombreTab);
    this.setState({nombreTemporalTab: nombreTab });
    this.cadenadeCarga= nombreTab;
    switch (nombreTab) {
      case "movimientoUnidadTerNav":
        console.log("371 this.state.dataMovimientoUnidadTerNav");
        console.log(this.state.dataMovimientoUnidadTerNav);
        break;
      case "unidadTerNav":
        console.log("375 this.state.dataUnidadTerNav");
        console.log(this.state.dataUnidadTerNav);
        break;
      case "unidadGpsTerNav":
        console.log("379 this.state.dataUnidadGpsTerNav");
        console.log(this.state.dataUnidadGpsTerNav);
        break;
      case "navieraMovimientos":
        console.log("383 this.state.dataNavieraMovimientos");
        console.log(this.state.dataNavieraMovimientos);
        //TabTemp.push(<Tab id={"navieraMovimientos"} onClick={() => this.clickTab("navieraMovimientos") }>navieraMovimientos</Tab>);
        //TabPanelTemp.pop();
        TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"navieraMovimientos"} vacio={true} contenido={this.state.dataNavieraMovimientos}/></TabPanel>);
        break;
      default:
        console.log("390 Tab No Conocido");
        break;
    }
  }
  //Inicio Tabla Movimientos
  cargaPlataformas() {
    Crud_Catalogos("plataformas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ plataformas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" plataformas "+" verifique el Nombre del Catalogo");}  });
  }
  cargaTransportistas() {
    Crud_Catalogos("transportistas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ transportistas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" transportistas "+" verifique el Nombre del Catalogo");}  });
  }
  cargaUnidadTipo() {
    Crud_Catalogos("unidadTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadesTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" unidadTipo "+" verifique el Nombre del Catalogo");}  });
  }
  cargaUnidadMarca() {
    Crud_Catalogos("unidadesMarcas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ unidadesMarca:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" unidadesMarcas "+" verifique el Nombre del Catalogo");}  });
  }
  cargaUnidadEstatusOperacion() {
    Crud_Catalogos("unidadEstatusOperacion", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
      this.setState({ unidadesEstatusOperacion:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" unidadesEstatusOperacion "+" verifique el Nombre del Catalogo");}  });
  }

  cargaMovimientoTipo() {
    Crud_Catalogos("movimientosTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ movimientoTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+" movimientosTipo "+" verifique el Nombre del Catalogo");}  });
  }
  cargaIdOperacionesEstatus() {
    Crud_Catalogos("operacionesEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ operacionesEstatus:returnVal });
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
validacionNavieraMovimientos(data, ObjValidar, j){
  data.forEach((item, i) => {
    var plataforma      = {"id":"", "nombre":"" };
    var cliente         = {"id":"", "nombre":"" };
    var corredor        = {"id":"", "nombre":"" };
    var movimientoTipo  = {"id":"", "nombre":"" };
    var naviera         = {"id":"", "nombre":"" };
    var operacionTipo   = {"id":"", "nombre":"" };
    var otd             = {"id":"", "nombre":"" };
    var rutasEstatus    = {"id":"", "nombre":"" }; //rutasEstatus
    var transportista   = {"id":"", "nombre":"" };
    var operacionEstatus= {"id":"", "nombre":"" };
    var po              = {"po":data[i].po, "existe":false};
    plataforma=data[i].idPlataforma;
    cliente=data[i].idCliente;
    corredor=data[i].idCorredor;
    movimientoTipo=data[i].movimientoTipo;
    naviera=data[i].idNaviera;
    operacionTipo=data[i].idOperacionTipo;
    otd=data[i].idOtd;
    rutasEstatus=data[i].idRuta;
    transportista=data[i].idTransportista;
    operacionEstatus=data[i].idOperacionEstatus;
    //po.po=data[i].po;
    var objeto={ plataforma, cliente, corredor, movimientoTipo, naviera, operacionTipo, otd, rutasEstatus,transportista, operacionEstatus, po };
    Crud_Catalogos("validacionDatos/naviera",   "", "post", "", objeto,"", "", "", [], "", "", "").then( returnVal => {
      //data.forEach((item, i) => {
      //  console.log("1129 i: "+i + " j:"+j);
      //  if(i===j){
          //console.log("1131 i===j");
          data[i].idPlataforma = returnVal.plataforma;
          data[i].idCliente = returnVal.cliente;
          data[i].idCorredor = returnVal.corredor;
          data[i].idMovimientoTipo = returnVal.movimientoTipo;
          data[i].idNaviera = returnVal.naviera;
          data[i].idOperacionTipo = returnVal.operacionTipo;
          data[i].idOtd = returnVal.otd;
          data[i].idRuta = returnVal.rutasEstatus;
          data[i].idTransportista = returnVal.transportista;
          data[i].idOperacionEstatus = returnVal.operacionEstatus;
          data[i].po = returnVal.po.po;
      //  }
      //});
      //console.log(data);
    }).catch(err =>{
      console.log("1220 Error de validacionDatos/naviero: "+ err);
    });
  });
  //TabTemp.push(<Tab id={"navieraMovimientos"} onClick={() => this.clickTab("navieraMovimientos") }>navieraMovimientos</Tab>);
  //TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"navieraMovimientos"} vacio={true} contenido={XLSX.utils.sheet_to_json(workbook.Sheets[sheet])}/></TabPanel>);

  //{"plataforma":{"id": 2,"nombre":null},"cliente":{"id": 12,"nombre":null},"corredor":{"id": 70,"nombre":null},"movimientoTipo":{"id":1,"nombre":null},"naviera":{"id":8,"nombre":null},"operacionTipo":{"id": 3,"nombre":null},"otd":{"id":1,"nombre":null},"rutasEstatus":{"id":2,"nombre":null},"transportista":{"id":10,"nombre":null},"operacionesEstatus":{"id": 1,"nombre":null} }
  //Crud_Catalogos("validacionDatos/terrestre",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
  /*
  Crud_Catalogos("validacionDatos/naviera",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then( returnVal => {
    data[j].idPlataforma = returnVal.plataforma;
    data[j].idCliente = returnVal.cliente;
    data[j].idCorredor = returnVal.corredor;
    data[j].idMovimientoTipo = returnVal.movimientoTipo;
    data[j].idNaviera = returnVal.naviera;
    data[j].idOperacionTipo = returnVal.operacionTipo;
    data[j].idOtd = returnVal.otd;
    data[j].idRuta = returnVal.rutasEstatus;
    data[j].idTransportista = returnVal.transportista;
    data[j].idOperacionEstatus = returnVal.operacionEstatus;

    data[j].po = returnVal.po.po;
    console.log("389 Fin validacionNavieraMovimientos: data["+j+"]");
    console.log(data[j]);
    console.log("393 actualizando el State de validacionNavieraMovimientos data:");
    console.log(data);
    this.state.dataNavieraMovimientos=data;

    //console.log("391 state: data["+j+"]");
    //this.setState({dataNavieraMovimientos: data });
    //this.setState({dataNavieraMovimientos: data });
    //this.state.dataNavieraMovimientos[j]=data[j];
    //this.state.dataNavieraMovimientos[j].po = returnVal.po.po;
  }).catch(err =>{
    console.log("548 Error de validacionDatos/naviero: "+ err);
  });
  */

}
//Fin Tabla Movimientos

valYaExiste(Catalogo, Po, I, Campo){
        let iDs = "";
        Crud_Catalogos("navieraMovimientos/po/"+Po,"", "get",  "", "",     "" , "",     "", []).then((returnVal) => {
          returnVal.map(ids => {
            iDs= "id: " + ids.id
            //console.log(ids.id);
            terrores=terrores+1;
          });
        }).catch(
          d =>{
            console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");
          }
        );
}

valCatalogoId(Columna, data, Id, I, Campo){
  var k=0;
  switch (Columna) {
    case "vip":
      if(Id){
        Campo=true;
      }else{
        Campo=false;
      }
      data.vip=Campo;
      break;
    case "po":
      Campo.po=Id;
      //this.state.data[I].po = Id;
      //this.state.dataNavieraMovimientos[I].po=Campo;
      data[I].po=Campo;
      break;
		case "citaProgramada":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				//horas = fechas - Number.parseFloat(Id);
        horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);				//datos[num]= ""+anio+"-"+mes+"-"+dia+""
        serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].citaProgramada = Campo;
        //this.state.dataNavieraMovimientos[I].citaProgramada=Campo;
        data.citaProgramada=Campo;
      break;
		case "arriboTerminalVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].arriboTerminalVacio = Campo;
        //this.state.dataNavieraMovimientos[I].arriboTerminalVacio=Campo;
        data.arriboTerminalVacio=Campo;
      break;
		case "salidaTerminalLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].salidaTerminalLleno = Campo;
        //this.state.dataNavieraMovimientos[I].salidaTerminalLleno=Campo;
        data.salidaTerminalLleno=Campo;
      break;
		case "arriboClienteLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].arriboClienteLleno = Campo;
        //this.state.dataNavieraMovimientos[I].arriboClienteLleno=Campo;
        data.arriboClienteLleno=Campo;
      break;
		case "salidaClienteVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].salidaClienteVacio = Campo;
        //this.state.dataNavieraMovimientos[I].salidaClienteVacio=Campo;
        data.salidaClienteVacio=Campo;
      break;
		case "arriboTerminalVacio2":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].arriboTerminalVacio2 = Campo;
        //this.state.dataNavieraMovimientos[I].arriboTerminalVacio2=Campo;
        data.arriboTerminalVacio2=Campo;
      break;
		case "retiroContenedor":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);//datos[num]= ""+anio+"-"+mes+"-"+dia+""
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].retiroContenedor = Campo;
        //this.state.dataNavieraMovimientos[I].retiroContenedor=Campo;
        data.retiroContenedor=Campo;
      break;
		case "arriboClienteVacio":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].arriboClienteVacio = Campo;
        //this.state.dataNavieraMovimientos[I].arriboClienteVacio=Campo;
        data.arriboClienteVacio=Campo;
      break;
		case "salidaClienteLleno":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].salidaClienteLleno = Campo;
        //this.state.dataNavieraMovimientos[I].salidaClienteLleno=Campo;
        data.salidaClienteLleno=Campo;
      break;
		case "arriboTerminalCargado":
				anio=0;mes=0;dia=0;
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieAnio(fechas);
				serieHoras(horas);
        Campo=""+anio+"-"+mes+"-"+dia+" "+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].arriboTerminalCargado = Campo;
        //this.state.dataNavieraMovimientos[I].arriboTerminalCargado=Campo;
        data.arriboTerminalCargado=Campo;
      break;

		case "eta":
				//fechas = parseInt(Id, 10);
				//horas = Number.parseFloat(Id)-fechas;
				//serieHoras(horas);
        //Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].eta = Campo;
      break;
		case "delay":
        console.log("860 ontime");
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        console.log("892 Campo: "+Campo);
        //this.state.data[I].delay = Campo;
        //this.state.dataNavieraMovimientos[I].delay=Campo;
        data.delay=Campo;
      break;
		case "wti6h":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].wti6h = Campo;
        //this.state.dataNavieraMovimientos[I].wti6h=Campo;
        data.wti6h=Campo;
      break;
		case "transit":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].transit = Campo;
        //this.state.dataNavieraMovimientos[I].transit=Campo;
        data.transit=Campo;
      break;
		case "ontime":
				fechas = parseInt(Id, 10);
				horas = Number.parseFloat(Id)-fechas;
				serieHoras(horas);
        Campo=""+hora+":"+minuto+":"+segundo+"";
        //this.state.data[I].ontime = Campo;
        //this.state.dataNavieraMovimientos[I].ontime=Campo;
        data.ontime=Campo;
      break;
    default:
      console.log("No Existe el Campo en la Columna "+Columna);
  }
}
completaValidacion(data, pestana){
  console.log("Inicia Completar Validacion "+pestana);
  switch (pestana) {
    case 'navieraMovimientos':
      var j = 0;
      data.map(datos =>{
        var plataforma      = {"id":"", "nombre":"" };
        var cliente         = {"id":"", "nombre":"" };
        var corredor        = {"id":"", "nombre":"" };
        var movimientoTipo  = {"id":"", "nombre":"" };
        var naviera         = {"id":"", "nombre":"" };
        var operacionTipo   = {"id":"", "nombre":"" };
        var otd             = {"id":"", "nombre":"" };
        var rutasEstatus    = {"id":"", "nombre":"" }; //rutasEstatus
        var transportista   = {"id":"", "nombre":"" };
        var operacionEstatus= {"id":"", "nombre":"" };
        var po              = {"po":"", "existe":false};
        var objeto          = { plataforma, cliente, corredor, movimientoTipo, naviera, operacionTipo, otd, rutasEstatus, transportista, operacionEstatus, po };

        Object.keys(datos).forEach(num =>{
          switch (num) {//Inicia Switch num
            case 'idPlataforma':
              if (Number.isInteger(datos[num])){
                plataforma.id=datos[num];
              }else{
                plataforma.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idPlataforma=plataforma;
              data[j].idPlataforma=plataforma;
              break;
            case 'idCliente':
              if (Number.isInteger(datos[num])){
                cliente.id=datos[num];
              }else{
                cliente.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idCliente=cliente;
              data[j].idCliente=cliente;
              break;
            case 'idCorredor':
              if (Number.isInteger(datos[num])){
                corredor.id=datos[num];
              }else{
                corredor.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idCorredor=corredor;
              data[j].idCorredor=corredor;
              break;
            case 'idNaviera':
              if (Number.isInteger(datos[num])){
                naviera.id=datos[num];
              }else{
                naviera.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idNaviera=naviera;
              data[j].idNaviera=naviera;
              break;
            case 'idOperacionTipo':
              if (Number.isInteger(datos[num])){
                operacionTipo.id=datos[num];
              }else{
                operacionTipo.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idOperacionTipo=operacionTipo;
              data[j].idOperacionTipo=operacionTipo;
              break;
            case 'idOtd':
              if (Number.isInteger(datos[num])){
                otd.id=datos[num];
              }else{
                otd.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idOtd=otd;
              data[j].idOtd=otd;
              break;
            case 'idRuta':
              if (Number.isInteger(datos[num])){
                rutasEstatus.id=datos[num];
              }else{
                rutasEstatus.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idRuta=rutasEstatus;
              data[j].idRuta=rutasEstatus;
              break;
            case 'idTransportista':
              if (Number.isInteger(datos[num])){
                transportista.id=datos[num];
              }else{
                transportista.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idTransportista=transportista;
              data[j].idTransportista=transportista;
              break;
            case 'idOperacionEstatus':
              if (Number.isInteger(datos[num])){
                operacionEstatus.id=datos[num];
              }else{
                operacionEstatus.nombre=datos[num];
              }
              //this.state.dataNavieraMovimientos[j].idOperacionEstatus=operacionEstatus;
              data[j].idOperacionEstatus=operacionEstatus;
              break;

            case 'idMovimientoTipo':
              if (Number.isInteger(datos[num])){
                movimientoTipo.id=datos[num];
                this.state.movimientoTipo.map(ids => {
                  if(movimientoTipo.id!==""){
                    if (ids.id===movimientoTipo.id){
                      data[j].movimientoTipo=ids;
                    }
                  }else{
                    if (ids.nombre===movimientoTipo.nombre){
                      data[j].movimientoTipo=ids;
                    }
                  }
                });
              }else{
                movimientoTipo.nombre=datos[num];
                this.state.movimientoTipo.map(ids => {
                  if(movimientoTipo.id!==""){
                    if (ids.id===movimientoTipo.id){
                      data[j].movimientoTipo=ids;
                    }
                  }else{
                    if (ids.nombre===movimientoTipo.nombre){
                      data[j].movimientoTipo=ids;
                    }
                  }
                });
              }
              break;

            case 'citaProgramada':
              var citaProgramada="";
              citaProgramada=datos[num];//this.state.data[j].citaProgramada;
              { this.valCatalogoId("citaProgramada", data[j], datos[num], j, citaProgramada) }
              break;
            case 'arriboTerminalVacio':
              var arriboTerminalVacio="";
              arriboTerminalVacio=datos[num];//this.state.data[j].arriboTerminalVacio;
              { this.valCatalogoId("arriboTerminalVacio", data[j], datos[num], j, arriboTerminalVacio) }
              break;
            case 'salidaTerminalLleno':
              var salidaTerminalLleno="";
              salidaTerminalLleno=datos[num];//this.state.data[j].salidaTerminalLleno;
              { this.valCatalogoId("salidaTerminalLleno", data[j], datos[num], j, salidaTerminalLleno) }
              break;
            case 'arriboClienteLleno':
              var arriboClienteLleno="";
              arriboClienteLleno=datos[num];//this.state.data[j].arriboClienteLleno;
              { this.valCatalogoId("arriboClienteLleno", data[j], datos[num], j, arriboClienteLleno) }
              break;
            case 'salidaClienteVacio':
              var salidaClienteVacio="";
              salidaClienteVacio=datos[num];//this.state.data[j].salidaClienteVacio;
              { this.valCatalogoId("salidaClienteVacio", data[j], datos[num], j, salidaClienteVacio) }
              break;
            case 'arriboTerminalVacio2':
              var arriboTerminalVacio2="";
              arriboTerminalVacio2=datos[num];//this.state.data[j].arriboTerminalVacio2;
              { this.valCatalogoId("arriboTerminalVacio2", data[j], datos[num], j, arriboTerminalVacio2) }
              break;
            case 'retiroContenedor':
              var retiroContenedor="";
              retiroContenedor=datos[num];//this.state.data[j].retiroContenedor;
              { this.valCatalogoId("retiroContenedor", data[j], datos[num], j, retiroContenedor) }
              break;
            case 'arriboClienteVacio':
              var arriboClienteVacio="";
              arriboClienteVacio=datos[num];//this.state.data[j].arriboClienteVacio;
              { this.valCatalogoId("arriboClienteVacio", data[j], datos[num], j, arriboClienteVacio) }
              break;
            case 'salidaClienteLleno':
              var salidaClienteLleno="";
              salidaClienteLleno=datos[num];//this.state.data[j].salidaClienteLleno;
              { this.valCatalogoId("salidaClienteLleno", data[j], datos[num], j, salidaClienteLleno) }
              break;
            case 'arriboTerminalCargado':
              var arriboTerminalCargado="";
              arriboTerminalCargado=datos[num];//this.state.data[j].arriboTerminalCargado;
              { this.valCatalogoId("arriboTerminalCargado", data[j], datos[num], j, arriboTerminalCargado) }
              break;
            case 'wti6h':
              var wti6h="";
              wti6h=datos[num];//this.state.data[j].retiroContenedor;
              { this.valCatalogoId("wti6h", data[j], datos[num], j, wti6h) }
              break;
            case 'ontime':
              var ontime="";
              ontime=datos[num];//this.state.data[j].ontime;
              { this.valCatalogoId("ontime", data[j], datos[num], j, ontime) }
              break;
            case 'vip':
              var vip="";
              vip=datos[num];//this.state.data[j].ontime;
              { this.valCatalogoId("vip", data[j], datos[num], j, vip) }
              break;
            case 'po':
              po.po=datos[num];
              //{ this.valCatalogoId("po", datos[num], j, po) }
              //{ this.valYaExiste("movimientos", datos[num], j, po) }
              data[j].po=po.po;
              break;
            default:
              //console.log("La Columna:"+num + " queda como está...");
              break;
          }//Fin Switch num
          let tmpVip=data[j].vip;//this.state.data[j].idCliente;
          if( tmpVip === "" || tmpVip === null || tmpVip === undefined ){
              terrores=terrores+1;
              FilaErr[j]="Error en la Fila " + (j);
              arrayErr[terrores]="(N) La Columna Vip NO puede ser VACIA -" + ", Verifique en Excel la Fila: " + (j+1);
              NotificationManager.warning("(N) La Columna Vip no puede ser VACIA, Verifique en Excel la Fila: " + (j+1) );
              Errores.push( <div>{"(N) La Columna Vip NO puede ser VACIA, Verifique en Excel la Fila: " + (j+1) }</div> );
          }
        });
        var temporal="";
        j=j+1;
      });
      break;//Fin Completa naveiraMovimientos
  }
  this.validacionNavieraMovimientos(data, data, j);
  this.setState({dataNavieraMovimientos: data });
}
valObjeto(pestana, ObjValidar, j){
  var k=0;
  let Campo="";
  switch (pestana) {
    //Inicia Validacion UnidadTerNav
    case "idPlataforma":
      k=0;
      Campo=ObjValidar;
      this.state.plataformas.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.plataformas[k].id===ObjValidar.id){
            ObjValidar=ids;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            ObjValidar=ids;
          }
        }
        k=k+1;
      });
      this.state.dataUnidadTerNav[j].idPlataforma = ObjValidar;
      break;
    case "idTransportista":
      k=0;
      Campo=ObjValidar;
      this.state.transportistas.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.transportistas[k].id===ObjValidar.id){
            ObjValidar=ids;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            ObjValidar=ids;
          }
        }
        k=k+1;
      });
      this.state.dataUnidadTerNav[j].idTransportista=ObjValidar;
      break;
    case "idUnidadTipo":
      k=0;
      Campo=ObjValidar;
      this.state.unidadesTipo.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidadesTipo[k].id===ObjValidar.id){
            ObjValidar=ids;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            ObjValidar=ids;
          }
        }
        k=k+1;
      });
      this.state.dataUnidadTerNav[j].idUnidadTipo=ObjValidar;
      break;
    case "idUnidadMarca":
      k=0;
      Campo=ObjValidar;
      this.state.unidadesMarca.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidadesMarca[k].id===ObjValidar.id){
            ObjValidar=ids;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            ObjValidar=ids;
          }
        }
        k=k+1;
      });
      this.state.dataUnidadTerNav[j].idUnidadMarca=ObjValidar;
      break;
    case "idUnidadEstatusOperacion":
      k=0;
      Campo=ObjValidar;
      this.state.unidadesEstatusOperacion.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidadesEstatusOperacion[k].id===ObjValidar.id){
            ObjValidar=ids;
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            ObjValidar=ids;
          }
        }
        k=k+1;
      });
      this.state.dataUnidadTerNav[j].idUnidadEstatusOperacion=ObjValidar;
      break;
    //Finali Validacion UnidadTerNav

    //Inicia Validacion terrestreMovimientos
    case "idUnidad":
      console.log("571 pestana");
      console.log(pestana);
      k=0;
      Campo=ObjValidar;
      this.state.unidades.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidades[k].id===ObjValidar){
            Campo = this.state.unidades[k].id;
            this.state.dataUnidadEnsamble[j].idUnidad = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "estatus":
      k=0;
      this.state.unidadEstatusOperacion.map(ids => {
        if(ObjValidar.id!==""){
          if (this.state.unidadEstatusOperacion[k].id===ObjValidar.id){
            Campo = this.state.unidadEstatusOperacion[k];
            this.state.dataUnidades[j].estatus = Campo;
          }
        }else{
          if (this.state.unidadEstatusOperacion[k].nombre===ObjValidar.nombre){
            Campo = this.state.unidadEstatusOperacion[k];
            this.state.dataUnidades[j].estatus = Campo;
          }
        }
        k=k+1;
      });
      break;
    case "operacionTipo":
      k=0;
      this.state.operacionTipo.map(ids => {
        if (this.state.operacionTipo[k].id===ObjValidar.id){
          Campo = this.state.operacionTipo[k];
          this.state.dataTerrestreMovimientos[j].operacionTipo = Campo;
        }
        k=k+1; });
      break;
    case "otra":
      Crud_Catalogos("validacionDatos/plataformas",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then((returnVal) => {
        this.state.data[j].plataforma    = returnVal.plataforma;
        this.state.data[j].transportista = returnVal.transportista;
        this.state.data[j].operador      = returnVal.operador;

        let tempplataforma=this.state.data[j].plataforma;
        let temptransportista=this.state.data[j].transportista;
        let tempoperador=this.state.data[j].operador;

        if ( tempplataforma.nombre === "" || tempplataforma.nombre === null || tempplataforma.nombre === undefined ){
            terrores=terrores+1;
            FilaErr[j]="Error en la Fila " + (j);
            arrayErr[terrores]="La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j);
            NotificationManager.warning("La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) );
            Errores.push( <div>{"La Plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j) }</div> );
        }
        if ( temptransportista.id === null || temptransportista.id === 0)  {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( temptransportista.nombre === "" || temptransportista.nombre === null ) {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Transportista No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( tempoperador.id === null || tempoperador.id === 0)  {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
        if ( tempoperador.nombre === "" || tempoperador.nombre === null ) {
          terrores=terrores+1;
          FilaErr[j]="Error en la Fila " + (j);
          arrayErr[terrores]="El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
          NotificationManager.warning("El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
          Errores.push( <div>{"El Operador No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        }
      }).catch(err =>{
        //if( err.response ){
        console.log("567 Error de validacionDatos")
        console.log(err);
      } );
      break;
    case "plataformaTipo":
      if (ObjValidar.id>0){
      //if ( Number.isInteger(ObjValidar.id) ){
        switch ( ObjValidar.id ) {
          case 1:
            ObjValidar.nombre="Naviera";
            break;
          case 2:
            ObjValidar.nombre="Terrestre";
            break;
          case 3:
            ObjValidar.nombre="Terrestre naviera";
            break;
          default:
            break;
        }
      }
      else
      {
        switch (ObjValidar.nombre) {
          case "Naviera":
            ObjValidar.id=1;
            break;
          case "Terrestre":
            ObjValidar.id=2;
            break;
          case "Terrestre naviera":
            ObjValidar.id=3;
            break;
          default:
            break;
        }
      }
      break;
    case "movimientoTipo":
      k=0;
      this.state.movimientosTipo.map(ids => {
        if(ObjValidar.id!==""){
          if (ids.id===ObjValidar.id){
            Campo = this.state.movimientosTipo[k];
          }
        }else{
          if (ids.nombre===ObjValidar.nombre){
            Campo = this.state.movimientosTipo[k];
          }
        }
        k=k+1;
      });
      this.state.dataNavieraMovimientos[j].movimientoTipo = Campo;
      break;
    //Finali Validacion terrestreMovimiento

    default:
      console.log("Columna Desconocida");
      break;
  }
}
validaArchivo(pestana) {
    console.log("Inicia Validacion de Archivo "+pestana);
    switch (pestana) {
      case 'unidadTerNav':
        var j = 0;
        this.state.dataUnidadTerNav.map(datos =>{
          var idPlataforma = {"id":"", "nombre":"" };
          var idTransportista = {"id":"", "nombre":"" };
          var idUnidadTipo = {"id":"", "nombre":"" };
          var idUnidadMarca = {"id":"", "nombre":"" };
          var idUnidadEstatusOperacion = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'idPlataforma':
                    if (Number.isInteger(datos[num])){
                      idPlataforma.id=datos[num];
                      this.valObjeto("idPlataforma", idPlataforma, j);
                    }else{
                      idPlataforma.nombre=datos[num];
                      this.valObjeto("idPlataforma", idPlataforma, j);
                    }
                    //this.state.dataUnidadTerNav[j].idPlataforma=idPlataforma;
                    break;
                  case 'idTransportista':
                    if (Number.isInteger(datos[num])){
                      idTransportista.id=datos[num];
                      this.valObjeto("idTransportista", idTransportista, j);
                    }else{
                      idTransportista.nombre=datos[num];
                      this.valObjeto("idTransportista", idTransportista, j);
                    }
                    //this.state.dataUnidadTerNav[j].idTransportista=idTransportista;
                    break;
                  case 'idUnidadTipo':
                    if (Number.isInteger(datos[num])){
                      idUnidadTipo.id=datos[num];
                      this.valObjeto("idUnidadTipo", idUnidadTipo, j);
                    }else{
                      idUnidadTipo.nombre=datos[num];
                      this.valObjeto("idUnidadTipo", idUnidadTipo, j);
                    }
                    //this.state.dataUnidadTerNav[j].idUnidadTipo=idUnidadTipo;
                    break;
                  case 'idUnidadMarca':
                    if (Number.isInteger(datos[num])){
                      idUnidadMarca.id=datos[num];
                      this.valObjeto("idUnidadMarca", idUnidadMarca, j);
                    }else{
                      idUnidadMarca.nombre=datos[num];
                      this.valObjeto("idUnidadMarca", idUnidadMarca, j);
                    }
                    //this.state.dataUnidadTerNav[j].idUnidadMarca=idUnidadMarca;
                    break;
                  case 'idUnidadEstatusOperacion':
                    if (Number.isInteger(datos[num])){
                      idUnidadEstatusOperacion.id=datos[num];
                      this.valObjeto("idUnidadEstatusOperacion", idUnidadEstatusOperacion, j);
                    }else{
                      idUnidadEstatusOperacion.nombre=datos[num];
                      this.valObjeto("idUnidadEstatusOperacion", idUnidadEstatusOperacion, j);
                    }
                    //this.state.dataUnidadTerNav[j].idUnidadEstatusOperacion=idUnidadEstatusOperacion;
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
          var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case Unidades Terrestres Navieras
      case 'terrestreMovimientoUnidad':
        var j = 0;
        this.state.dataTerrestreMovimientoUnidad.map(datos =>{
          var idMovimiento = "";
          var unidad = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'idMovimiento':
                    if (Number.isInteger(datos[num])){
                      idMovimiento=datos[num];
                      this.valObjeto("idMovimiento", idMovimiento, j);
                    }
                    break;
                  case 'unidad':
                    if (Number.isInteger(datos[num])){
                      unidad.id=datos[num];
                      this.valObjeto("unidad", unidad, j);
                    }else{
                      unidad.nombre=datos[num];
                      this.valObjeto("unidad", unidad, j);
                    }
                    break;
                  default:
                    break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalidMovimiento="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
					temporalidMovimiento=this.state.dataTerrestreMovimientoUnidad[j].idMovimiento;
          /*
					if ( (temporalidMovimiento==="")||(temporalidMovimiento===null)||(temporalidMovimiento===undefined)||(temporalidMovimiento===0)||(temporalidMovimiento==='empty string') ){
						console.log("No puede ir Vacío el Campo 'idMovimiento' en TerrestreMovimientoUnidad");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La idMovimiento: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) El idMovimiento No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) El idMovimiento No Existe! "+this.state.TerrestreMovimientoUnidad[j].idMovimiento+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("915 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
          */
					//Fin Validacion del Campo plataforma.id
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case terrestreMovimientoUnidad
      case 'plataformas':
        var j = 0;
        this.state.dataPlataformas.map(datos =>{
          var plataformaTipo = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'plataformaTipo':
                    if (Number.isInteger(datos[num])){
                      plataformaTipo.id=datos[num];
                      this.valObjeto("plataformaTipo", plataformaTipo, j);
                    }else{
                      plataformaTipo.nombre=datos[num];
                      this.valObjeto("plataformaTipo", plataformaTipo, j);
                    }
                    this.state.dataPlataformas[j].plataformaTipo=plataformaTipo;
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataformaTipo.id
          /*
					temporalId=this.state.dataPlataformas[j].plataformaTipo.id;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'plataformaTipo.id'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataformaTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataformaTipo No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataformaTipo No Existe! "+this.state.dataPlataformas[j].plataformaTipo.nombre+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("plataformasplataformaTipo1");
              console.log("619 plataformasplataformaTipo"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataformaTipo.id
          //Validacion del Campo plataformaTipo.nombre
					temporalNom=this.state.dataPlataformas[j].plataformaTipo.nombre;
					if ( (temporalNom==="")||(temporalNom===null)||(temporalNom===undefined)||(temporalNom===0)||(temporalNom==='empty string') ){
            console.log("No puede ir Vacío el Campo 'plataformaTipo'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataformaTipo: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataformaTipo No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataformaTipo No Existe! "+this.state.dataPlataformas[j].plataformaTipo.id+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              //var elem = document.getElementById("plataformasplataformaTipo1");
              var elem = document.getElementById("plataformasplataformaTipo1");
              //if (elem === null){
              //  elem = elem.InnerHtml;
              //}
              //if(typeof elem !== null && elem !== 'undefined' ) {
                //let res= document.getElementById("result").innerHTML;
                //let res=elem.innerHTML;
                //res.style.bgcolor = "Red";
              //}
              console.log("636 plataformasplataformaTipo"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataformaTipo.nombre
          */
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case plataforma
      case 'unidades':
        var j = 0;
        this.state.dataUnidades.map(datos =>{
          var plataforma = {"id":"", "nombre":"" };
          var transportista = {"id":"", "nombre":"" };
          var unidadTipo = {"id":"", "nombre":"" };
          var estatus = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'plataforma':
                    if (Number.isInteger(datos[num])){
                      plataforma.id=datos[num];
                      this.valObjeto("plataforma", plataforma, j);
                    }else{
                      plataforma.nombre=datos[num];
                      this.valObjeto("plataforma", plataforma, j);
                    }
                    //this.state.dataUnidades[j].plataforma=plataforma;
                    break;
                  case 'transportista':
                    if (Number.isInteger(datos[num])){
                      transportista.id=datos[num];
                      this.valObjeto("transportista", transportista, j);
                    }else{
                      transportista.nombre=datos[num];
                      this.valObjeto("transportista", transportista, j);
                    }
                    //this.state.dataUnidades[j].transportista=transportista;
                    break;
                  case 'unidadTipo':
                    if (Number.isInteger(datos[num])){
                      unidadTipo.id=datos[num];
                      this.valObjeto("unidadTipo", unidadTipo, j);
                    }else{
                      unidadTipo.nombre=datos[num];
                      this.valObjeto("unidadTipo", unidadTipo, j);
                    }
                    //this.state.dataUnidades[j].unidadTipo=unidadTipo;
                    break;
                  case 'estatus':
                    if (Number.isInteger(datos[num])){
                      estatus.id=datos[num];
                      this.valObjeto("estatus", estatus, j);
                    }else{
                      estatus.nombre=datos[num];
                      this.valObjeto("estatus", estatus, j);
                    }
                    //this.state.dataUnidades[j].estatus=estatus;
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
          /*
					temporalId=this.state.dataUnidades[j].plataforma.id;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'plataforma.id' en Unidades");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataforma No Existe! "+this.state.dataUnidades[j].plataforma.nombre+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("897 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.id
          //Validacion del Campo plataforma.nombre
					temporalNom=this.state.dataUnidades[j].plataforma.nombre;
					if ( (temporalNom==="")||(temporalNom===null)||(temporalNom===undefined)||(temporalNom===0)||(temporalNom==='empty string') ){
            console.log("No puede ir Vacío el Campo 'plataforma'");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La plataforma: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La plataforma No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) La plataforma No Existe! "+this.state.dataUnidades[j].plataforma.id+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              //var elem = document.getElementById("plataformasplataforma1");
              var elem = document.getElementById("unidadesplataforma"+suma);
              console.log("914 unidadesplataforma"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.nombre
          */
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        //Errores.push( <div id={j}>{this.state.validado?"Validación Finalizada":terrores}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case Unidades

      case 'unidadEnsamble':
        var j = 0;
        this.state.dataUnidadEnsamble.map(datos =>{
          var idUnidad ="";
          var elemento = {"id":"", "nombre":"" };
          Object.keys(datos).forEach(num =>{
                switch (num) {//Inicia Switch num
                  case 'idUnidad':
                    if (Number.isInteger(datos[num])){
                      idUnidad=datos[num];
                      this.valObjeto("idUnidad", idUnidad, j);
                    }
                    break;
                  case 'elemento':
                    if (Number.isInteger(datos[num])){
                      elemento.id=datos[num];
                      this.valObjeto("elemento", elemento, j);
                    }else{
                      elemento.nombre=datos[num];
                      this.valObjeto("elemento", elemento, j);
                    }
                    break;
                  default:
                  break;
                }//Fin Switch num
            }
          );
					//Validacion de Campos No Null
					var temporalId="";
					var temporalNom="";
					//Validacion del Campo plataforma.id
					temporalId=this.state.dataUnidadEnsamble[j].idUnidad;
					if ( (temporalId==="")||(temporalId===null)||(temporalId===undefined)||(temporalId===0)||(temporalId==='empty string') ){
						console.log("No puede ir Vacío el Campo 'idUnidad' en UnidadEnsamble");
						terrores=terrores+1;
						FilaErr[j]="Error en la Fila " + (j+1);
						arrayErr[terrores]="La idUnidad: No Puede ser Null!, Verifique el Excel la Fila: " + (j+1);
            NotificationManager.warning("(N) La idUnidad No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
            Errores.push( <div>{"(N) El idUnidad No Existe! "+this.state.dataUnidadEnsamble[j].idUnidad+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
            try{
              var suma = j+1;
              var elem = document.getElementById("unidadEnsambleidUnidad"+suma);
              console.log("1280 unidadEnsambleidUnidad"+suma);
              elem.style.bgcolor = "Red";
            }catch(e){
              console.log("Error en ColorFondo! "+e);
            }
					}
					//Fin Validacion del Campo plataforma.id
          j=j+1;
        });//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				Errores.push( <div id={j}>{"Pestaña "+pestana+" Validada!"}</div> );
        console.log("Finaliza Validacion de Archivo "+pestana);
				break;//Fin Case Unidades
      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para la HOJA de EXCEL "+pestana;
        break;
    }
  }

cargarDatos = (carga, errores) => {
    let w=0;
    let errResponse=[];
    switch (this.cadenadeCarga) {
      case "navieraMovimientos":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="navieraMovimientos";
            }
            for (let i = 0; i < this.state.dataNavieraMovimientos.length; i++) {
              let nDe = 0;
              let nEn = 0;
              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataNavieraMovimientos[i],"" , "",     "", []).then((returnVal) => {
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = null;
                this.usuariosLogObject.descripcion = "Nuevo Movimiento Masivo, id=" + returnVal.id+"("+returnVal.booking+")";
                Crud_Catalogos("usuariosLog", this.cadenadeCarga, "post", "", this.usuariosLogObject);
                nEn = i+1;
                nDe = this.state.dataNavieraMovimientos.length;
                if (returnVal.id>0){
                    var filaenNavieraMovimientos = document.getElementById("FilanavieraMovimientos"+i);
                    filaenNavieraMovimientos.style.backgroundColor = "green";
                }
              }).catch(err =>{
                //try {
                  var filaenNavieraMovimientos = document.getElementById("FilanavieraMovimientos"+i);
                  filaenNavieraMovimientos.style.backgroundColor = "orange";
                //}
                console.log("1503 Error Crud_Catalogos: this.state.dataNavieraMovimientos["+i+"]" + err);
              });
            }
        break;
      case "movimientoUnidadTerNav":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="movimientoUnidadTerNav";
            }
            for (let i = 0; i < this.state.dataMovimientoUnidadTerNav.length; i++) {
              let nDe = 0;
              let nEn = 0;
              Crud_Catalogos(this.cadenadeCarga+"/"+user.id, this.cadenadeCarga, "post", "", this.state.dataMovimientoUnidadTerNav[i],"" ,"","", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataMovimientoUnidadTerNav.length;
                if (returnVal.idMovimiento>0 && returnVal.idUnidadTerNav>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataMovimientoUnidadTerNav[i]=returnVal;


                  //Refresca Colores en la Tabla UnidadEnsamble
                  var filaenmovimientoUnidadTerNav = document.getElementById("FilamovimientoUnidadTerNav"+i);
                  filaenmovimientoUnidadTerNav.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("1621 FilamovimientoUnidadTerNav" + i+ " err: " + err);
                var filaenmovimientoUnidadTerNav = document.getElementById("FilamovimientoUnidadTerNav"+i);
                filaenmovimientoUnidadTerNav.style.backgroundColor = "orange";
                console.log("1624 Error: " + err);
              });
            }
        break;
      case "unidadGpsTerNav":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="unidadGpsTerNav";
              console.log("1598 unidadGpsTerNav");
            }
            for (let i = 0; i < this.state.dataUnidadGpsTerNav.length; i++) {
              let nDe = 0;
              let nEn = 0;

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataUnidadGpsTerNav[i],"" ,"","", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataUnidadGpsTerNav.length;
                if (returnVal.idGps>0 && returnVal.idUnidadNavTer>0 && returnVal.idUnidadTipoColocacion>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  //this.state.dataUnidadGpsTerNav[i]=returnVal;

                  //Refresca Colores en la Tabla UnidadEnsamble
                  var filaenunidadGpsTerNav = document.getElementById("FilaunidadGpsTerNav"+i);
                  console.log("1663 FilaunidadGpsTerNav" + i);
                  filaenunidadGpsTerNav.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("1667 FilaunidadGpsTerNav" + i+ " err: " + err);
                //console.log("1365 FilaunidadElementos" + i);
                //try {
                  var filaenunidadGpsTerNav = document.getElementById("FilaunidadGpsTerNav"+i);
                  filaenunidadGpsTerNav.style.backgroundColor = "orange";
                //}
                console.log("1673 Error: " + err);
              });
            }
        break;
      case "unidadTerNav":
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="unidadTerNav";
              console.log("1681 unidadTerNav");
            }
            for (let i = 0; i < this.state.dataUnidadTerNav.length; i++) {
              let nDe = 0;
              let nEn = 0;

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataUnidadTerNav[i],"" ,"","", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataUnidadTerNav.length;
                console.log("1690 returnVal");
                console.log(returnVal);
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataUnidadTerNav[i]=returnVal;

                  console.log("1696 this.state.dataUnidadTerNav["+i+"]");
                  console.log(this.state.dataUnidadTerNav[i]);

                  //Refresca Colores en la Tabla UnidadEnsamble
                  var filaenunidadTerNav = document.getElementById("FilaunidadTerNav"+i);
                  console.log("1701 FilaunidadTerNav" + i);
                  filaenunidadTerNav.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("1705 FilaunidadTerNav" + i+ " err: " + err);
                  var filaenunidadTerNav = document.getElementById("FilaunidadTerNav"+i);
                  filaenunidadTerNav.style.backgroundColor = "orange";
                //}
                console.log("1709 Error: " + err);
              });
            }
        break;
      default:
    }
}
onImportExcel = file => {
    const { files } = file.target;
    Cuadros=[];
    TabTemp=[];
    TabPanelTemp=[];
    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          switch (sheet) {
            case "unidadGpsTerNav":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataUnidadGpsTerNav: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                //{ this.validaArchivo('unidadGpsTerNav') }
                TabTemp.push(<Tab id={"unidadGpsTerNav"} onClick={() => this.clickTab("unidadGpsTerNav") }>unidadGpsTerNav</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"unidadGpsTerNav"} vacio={true} contenido={this.state.dataUnidadGpsTerNav}/></TabPanel>);
              }
              break;
            case "unidadTerNav":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataUnidadTerNav: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                { this.validaArchivo('unidadTerNav') }
                TabTemp.push(<Tab id={"unidadTerNav"} onClick={() => this.clickTab("unidadTerNav") }>unidadTerNav</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"unidadTerNav"} vacio={true} contenido={this.state.dataUnidadTerNav}/></TabPanel>);
              }
              break;
            case "transportistas":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataTransportistas: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"transportistas"} onClick={() => this.clickTab("transportistas") }>transportistas</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"transportistas"} vacio={true} contenido={this.state.dataTransportistas}/></TabPanel>);
              }
              break;
            case "movimientoUnidadTerNav":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataMovimientoUnidadTerNav: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"movimientoUnidadTerNav"} onClick={() => this.clickTab("movimientoUnidadTerNav") }>movimientoUnidadTerNav</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"movimientoUnidadTerNav"} vacio={true} contenido={this.state.dataMovimientoUnidadTerNav}/></TabPanel>);
                //TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"movimientoUnidadTerNav"} contenido={false}/></TabPanel>);
              }
              break;

            case "navieraMovimientos":
              console.log("1682 Inicio Carga navieraMovimientos");
              if (workbook.Sheets.hasOwnProperty(sheet)){
                //this.setState({dataNavieraMovimientos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                { this.completaValidacion(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]), 'navieraMovimientos') }
                TabTemp.push(<Tab id={"navieraMovimientos"} onClick={() => this.clickTab("navieraMovimientos") }>navieraMovimientos</Tab>);
                //TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"navieraMovimientos"} vacio={true} contenido={XLSX.utils.sheet_to_json(workbook.Sheets[sheet])}/></TabPanel>);
              }
              console.log("1689 Fin Carga navieraMovimientos");
              break;
            //Fin de los que se van a Eliminar

            case "datos":
              console.log("1587 Carga navieraMovimientos");
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataNavieraMovimientos: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                { this.completaValidacion(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]), 'navieraMovimientos') }
                TabTemp.push(<Tab id={"navieraMovimientos"} onClick={() => this.clickTab("navieraMovimientos") }>navieraMovimientos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"navieraMovimientos"} vacio={true} contenido={this.state.dataNavieraMovimientos}/></TabPanel>);
              }
              console.log("1595 Fin navieraMovimientos");
              break;
            default:
              console.log("Hoja No Válida!: "+sheet);
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        }
        Cuadros.push
        (
          <Tabs forceRenderTabPanel={true} className={"div1"}>
                <TabList>
                  {TabTemp}
                </TabList>
                  {TabPanelTemp}
          </Tabs>
        )

        this.setState({validado:true });
        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModalMN("block");
    document.getElementById("cerrar").focus();
}

render() {
    return (
      <div className="excelMN" id="excelMN">
        <p className="etiquetaMN" id="etiquetaMN"><a href="#miModalMN">Imp</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />

				<div id="xlsModalMN" className="xlsModalMN">
					<div className="modal-contenidoMN">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-350">
						<div className="scroll-col col-sm-12">
						{
              <div>
                { Cuadros }
              </div>
						}
						</div>

					</div>

					<br></br>
					<center>
						<button id="cerrar" className="azul" onClick={()=>myModalMN('none')} >Cerrar o Corregir Archivo de Excel</button>
						<spam>   </spam>
						<button id="cargar" className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar {this.state.nombreTemporalTab}</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}
export default ImportarMN;
