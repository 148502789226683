import React, { useState, useEffect, useContext } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { Contactos, CorreosTrans, Etiquetas } from "../../../../Catalogos/Transportistas/Formularios";//{ CorreosClit, Etiquetas, Contactos }
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";
import ModalLay from "../../../../components/ModalLay/ModalLay";
import { softphoneContext } from "../../../../context/softphoneContext";
const Transportista =({obj, stor, mov})=> {
  const softphone = useContext(softphoneContext);
  const [movPatio, setMovPatio]= useState({});
  const [contactos, setContactos]= useState([]);
  const [contactosTemporales, setContactosTemporales]= useState([]);
  const [transportistasEtiquetas, setTransportistasEtiquetas]= useState([]);  
  const [contactoTemporal, setContactoTemporal]= useState([]);
  const [tab, setTab]= useState(1);
  useEffect(() => {
		if(mov.idPlataforma){
      if( [1,3].includes(mov.idPlataforma.plataformaTipo.id) ){
        getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+mov.id, "movPatio");
        getfuntion("get", "", "", "personas/transportista/"+obj.id, "contactos");
      }
      /*
      if(mov.idPlataforma.plataformaTipo.id === 3 ){
        this.getfuntion("get","","","unidadTerNav/idMovimiento/"+mov.id, "unidadTerNav");
        this.getfuntion("get","","","operadores/idmovimiento_nav/"+mov.id, "operador");
      }*/
    }
	}, []);
  useEffect(() => {
		if( tab === 3 ){
      getfuntion("get", "", "", "transportistas_contactos_temporal/"+obj.id, "contactos_temporales");
    }
    if( tab === 4 ){
      getfuntion("get", "", "", "transportistasEtiquetas/id_transportista/"+obj.id, "transportistasEtiquetas");
    }
	}, [tab]);
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "", enviar) => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "movPatio" && returnVal){ //navieras
              setMovPatio(returnVal);
            }
            if (stateVar === "contactos" && returnVal) {
              for(let item of returnVal){
                if(!item.aMaterno){item.aMaterno=""}
                if(!item.aPaterno){item.aPaterno=""}
                if(!item.nombre){item.nombre=""}
              }
               setContactos(returnVal);
            }
            if (stateVar === "contactos_temporales" && returnVal) {
              let contTemporalesTemp= [];
              for(let c of returnVal){
                c.fecha= timeToDateFill(c.fecha);
                contTemporalesTemp.push(c);
              }
               setContactosTemporales(contTemporalesTemp);
            }
            if (stateVar === "transportistasEtiquetas" && returnVal) {
              setTransportistasEtiquetas(returnVal);
            }
          }
          break;

        default:
          break;
      }
    }).catch(err => { console.log(err);  });
  }
  return (

    <div className= "mt-1">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Det</a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }> Cont</a>
        </li>
        <li className="nav-item" hidden={ ![1,3].includes(mov.idPlataforma? mov.idPlataforma.plataformaTipo.id: mov.plataforma.plataformaTipo.id)}>
          <a className={"nav-link"+ (tab === 3 ? " active":"")} onClick={()=> setTab(3) }> Cont Temp</a>
        </li>
        <li className="nav-item">
          <a className={"nav-link"+ (tab === 4 ? " active":"")} onClick={()=> setTab(4) }> Etiq</a>
        </li>
      </ul>
      <div  hidden={tab !== 1}>
        <List.Group>
          <List.GroupItem action icon="truck"> Transportista: {obj? obj.nombre:null } </List.GroupItem>
          <List.GroupItem action icon="calendar">Contacto: { obj? obj.contactoNombre:null }</List.GroupItem>
          <List.GroupItem action icon="phone"> Telefono: { obj? obj.telefono1:null } </List.GroupItem>
          <List.GroupItem action icon="phone"> Telefono: { obj? obj.telefono2:null } </List.GroupItem>
          <List.GroupItem action icon="mail"> Email: { obj? obj.correo:null }</List.GroupItem>
          { [1,3].includes(mov.idPlataforma && mov.idPlataforma.plataformaTipo.id) &&
            <List.Group>
              <List.GroupItem action icon="mail"> Unidad:         { movPatio? (movPatio.tractorMarca+" "+movPatio.tractorColor+" "+movPatio.tractorModelo+" "+movPatio.tractorPlaca): "" } </List.GroupItem>
              <List.GroupItem action icon="mail">
                Operador:{ 
                  (movPatio? movPatio.operadorNombre: "")
                  +", contacto: "
                }
                <span>
                  { Number(movPatio.operadorContacto) && !Number(softphone.getLlamarClic())?
                    <i
                      className="fa fa-volume-control-phone text-primary btn"
                      data-toggle="tooltip" data-placement="top" title={"Realizar llamada a "+ movPatio.operadorContacto}
                      style={{ fontSize: "24px"}}
                      onClick={()=>{
                        if(movPatio && !Number(softphone.getLlamarClic())){
                          softphone.setLlamarClic(movPatio.operadorContacto);
                          console.log(movPatio.operadorContacto);
                        }
                      }}
                    ></i>
                    : null
                  }
                  {movPatio? movPatio.operadorContacto : ""}
                </span>
              </List.GroupItem>
            </List.Group>
          }
          { [1].includes(mov.idPlataforma && mov.idPlataforma.plataformaTipo.id) ?
            <List.Group>
              <List.GroupItem action icon="mail"> Observaciones:  { movPatio.observaciones }</List.GroupItem>
            </List.Group>: null
          }
          {/** Terrestre */}
        </List.Group>
      </div>
      <div  hidden={tab !== 2}>
        <h4>Contactos Fijos</h4>
        <Contactos
          accion={"modificar"}
          elementos={obj}
          stor={stor}
          plataforma={mov.idPlataforma? mov.idPlataforma: mov.plataforma}
        />
        <List.Group>
          
          {contactos.map((cont, index)=>
            <List.GroupItem action icon="mail">
              <div  className="badge" data-toggle="tooltip" data-placement="top" title={cont.correoEstatus? "Recibe correos":"No recibe correos"}
                                                                      style={{ color: cont.correoEstatus? "green": "red", "font-size": "100%" }}>
                <span className="fa fa-flag"></span>                
              </div>
              {cont.correo}( {cont.aPaterno+" "+ cont.aMaterno+ " "+ cont.nombre} )
            </List.GroupItem>
          )}
        </List.Group>
      </div>
      <div  hidden={ ![1,3].includes(mov.idPlataforma? mov.idPlataforma.plataformaTipo.id: mov.plataforma.plataformaTipo.id) || tab !== 3}>
        <h4>Contactos Temporales (Se borraran al finalizar los movs asociados)</h4>
        <TablePageable
          titulo={
            <div className="row">
              <div className="col">
                <ModalLay
                  tamanio={"99%"}
                  title={"Contactos Temporales (Se borrara)"}
                  name={<i className="fa fa-cogs"  style={{"font-size": "15px"}}></i> }
                  formulario={CorreosTrans}
                  datosForm={{
                    elementos: obj,
                  }}
                />
              </div>
              <div className="col">
                <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                  onClick={()=> getfuntion("get", "", "", "transportistas_contactos_temporal/"+obj.id, "contactos_temporales") }
                ></i></div>
            </div>
          }
          lista= { contactosTemporales }
          columnas={
            [
              { columna: "estatus", label:"Estatus" },
              { columna: "correo", label:"Correo" },
              { columna: "nombreCompleto", label:"Nombre Completo" },
              { columna: "fecha", label:"Fecha" },
            ]
          }
          id= "correo"
          selected={(e)=> setContactoTemporal(e)}
        />
      </div>
      <div  hidden={tab !== 4}>
        <h4>Etiquetas(Los contactos del transportista reciben las siguientes notificaciones)</h4>
        <Etiquetas
          accion={"modificar"}
          elementos={obj}
          stor={stor}
        />
      </div>
    </div>

  );
}
export default Transportista;
