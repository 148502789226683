import React, { useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";

import {MenuChatBot} from "./MenuChatBot.react";
import {Pie} from "./Pie.react";
import {LoadingScreen} from "../../components/LoadingScreen";

const ChatBotPage = () => {
	
	//Variables check loading y error
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);

	return (
		<SiteWrapper>
				<div className="container">
					<MenuChatBot 
						setLoading={setLoading}
						setError={setError}
						setData={setData}
					/>
					<Pie
						data={data}
					/>


				</div>
			{loading ? (
				<LoadingScreen
					loading={loading}
				/>
			):null}
			
		</SiteWrapper>
	);
};

export default ChatBotPage;
