import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";
import axios from 'axios';
import Modal from 'react-modal';
import * as conf from "../../config/config";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';

const customStyles = {
    content: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: '0%',
        transform: 'translate(-50%, -50%)',
        border: '0px',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        'background-color': 'rgba(0,0,0,0.5)'
    }
};

class ControladorPatioForm extends Component {
    constructor() {
        super();
        this.state = {
            id: "",
            search: "",
            corredores: [],
            modalIsOpen: false,
            usuarios: [],
            usuariosBackup: [],
            textBuscar: '',
            nombreCorredor: "",
            controladoresLocalidades: [],
            idCorredor: 0,
        };
        this.corredoresObject = [];
        this.controladoresLocalidades = [];
        this.closeModal = this.closeModal.bind(this);

    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    componentDidMount = () => {
        let instance = axios.create();
        var tokenStore = "tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz + "corredores").then(res => {
            const corredores = res.data
            this.setState({ corredores })
        });

        // const usuarios = res.data
        axios.get(conf.api_raiz + "usuarios")
            .then(res => {
                this.setState({
                    usuarios: res.data,
                    usuariosBackup: res.data
                })
            }).catch(error => {
                alert("error" + error)
            })
    }

    changeCorredor = (usuarioID) => {
        let arrayes = [];
        this.state.controladoresLocalidades.map((controladorLocalidad, index) =>
            arrayes.push(controladorLocalidad.idUsuario)
        );
        let instance = axios.create();
        var tokenStore = "tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';

        if (arrayes.indexOf(usuarioID) === -1) {
            axios.post(conf.api_raiz + "controladoresLocalidades", {
                "idCorredor": this.state.idCorredor,
                "idUsuario": usuarioID,
            }).then(res => {

                this.actualizainformacion(this.state.idCorredor);

            });
        }
        if (arrayes.indexOf(usuarioID) > -1) {
            axios.delete(conf.api_raiz + "controladoresLocalidades/" + this.state.idCorredor + "/" + usuarioID).then(res => {
                this.actualizainformacion(this.state.idCorredor);
            });
        }

    }

    checkedUsuarios = (idUsuario) => {

        let arrayes = [];
        this.state.controladoresLocalidades.map((controladorLocalidad, index) =>
            arrayes.push(controladorLocalidad.idUsuario)
        );
        for (let index = 0; index < arrayes.length; index++) {
            if (arrayes[index] == idUsuario) {
                return 1;
            }
        }
        return 0;
    }

    actualizainformacion = (id) => {
        let instance = axios.create();
        var tokenStore = "tok";
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + sessionStorage.getItem(tokenStore);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.defaults.headers.common['Accept'] = 'application/json';
        axios.get(conf.api_raiz + "controladoresLocalidades/corredor/" + id).then(res => {
            const controladoresLocalidades = res.data
            this.setState({ controladoresLocalidades })
        });
    }

    agregarUsuario(nombre, idCorredor) {
        this.actualizainformacion(idCorredor);
        this.setState({ idCorredor });
        this.setState({ modalIsOpen: true });
        this.setState({ nombreCorredor: nombre });
    }

    searching = (event) => {
        this.setState({ search: event.target.value })
    }

    filter(event) {
        var text = event.target.value
        const data = this.state.usuariosBackup

        const newData = data.filter(function (item) {
            const itemData = item.username.toUpperCase()
            const textData = text.toUpperCase()
            return itemData.indexOf(textData) > -1
        })
        this.setState({
            usuarios: newData,
            textBuscar: text,
        })
    }

    render() {
        return (
            <Page.Card>
                <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                >
                    <Table.Header align="center">
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>NOMBRE</Table.ColHeader>
                        <Table.ColHeader>NOMBRE CORTO</Table.ColHeader>
                        <Table.ColHeader>LATITUD</Table.ColHeader>
                        <Table.ColHeader>LONGITUD</Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                        {this.state.corredores.map((corredor, index) =>
                            <Table.Row align="center" onClick={() => this.agregarUsuario(corredor.nombre, corredor.id)}>
                                <Table.Col >
                                    {corredor.id}
                                </Table.Col>
                                <Table.Col>
                                    {corredor.nombre}
                                </Table.Col>
                                <Table.Col>
                                    {corredor.nombreCorto}
                                </Table.Col>
                                <Table.Col>
                                    {corredor.latitud}
                                </Table.Col>
                                <Table.Col>
                                    {corredor.longitud}
                                </Table.Col>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                >
                    <Card>
                        <Card.Header>
                            <Card.Title>Usuarios de corredor {this.state.nombreCorredor} </Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="container">

                                <Form.Input class="mb-3"
                                    placeholder="Buscar..."
                                    icon="search"
                                    position="append"
                                    value={this.state.text}
                                    onChange={(text) => this.filter(text)} />

                                <Table
                                    cards={true}
                                    striped={true}
                                    responsive={true}
                                    className="table-vcenter">

                                    <Table.Header align="center">
                                        <Table.ColHeader>ID</Table.ColHeader>
                                        <Table.ColHeader>Nombre</Table.ColHeader>
                                        <Table.ColHeader>Username</Table.ColHeader>
                                        <Table.ColHeader>Agregar Usuario</Table.ColHeader>

                                    </Table.Header>
                                    <Table.Body>

                                        {this.state.usuarios.map((usuario, index) =>
                                            <Table.Row align="center">
                                                <Table.Col>
                                                    {usuario.id}
                                                </Table.Col>
                                                <Table.Col>
                                                    {usuario.persona.nombre}
                                                </Table.Col>
                                                <Table.Col>
                                                    {usuario.username}
                                                </Table.Col>
                                                <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                    checked={this.checkedUsuarios(usuario.id)}
                                                    className={"disabled"}
                                                    onChange={() => this.changeCorredor(usuario.id)} /></Table.Col>

                                            </Table.Row>
                                        )
                                        }
                                    </Table.Body>
                                </Table>
                            </div>

                        </Card.Body>
                        <Card.Footer>
                            <Button.List align="right">
                                <Button onClick={() => { this.closeModal() }} color="danger">Cerrar</Button>
                            </Button.List>
                        </Card.Footer>
                    </Card>

                </Modal>
            </Page.Card>

        )
    }
}

export default ControladorPatioForm;
