import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from '../../../../herramientas/Crud_Catalogos';

const VincularDispositivo =({ whatsappConfiguracion })=> {
  const [estatus, setEstatus]= useState({
    "code": 200,
    "data": {
      "Connected": false,
      "LoggedIn": false
    },
    "success": true
  });

  useEffect(() => {
    if(whatsappConfiguracion.id){
      getfuntion("get", "", "", "whatsapp/v2/plataforma/"+whatsappConfiguracion.idPlataforma+"/estatus/","estatus");
    }
  }, []);
  const [qr, setQr]= useState(
    {
      "code": 200,
      "data": {
        "QRCode": null
      },
      "success": true
    }
  );

  //https://wasky2.lambicall.com/api/instance/1/qr

  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "correoPlantillas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "estatus" && returnVal.success){
              setEstatus(returnVal);
              if(returnVal.data && (!returnVal.data.Connected || !returnVal.data.LoggedIn)){
                getfuntion("get", "", "", "whatsapp/v2/plataforma/"+whatsappConfiguracion.idPlataforma+"/qr/","qr");
              }
            }
            if( stateVar === "qr" && returnVal.success){
              setQr(returnVal);
            }
            break;
          default:
            break;
        }
      }).catch(err =>{ console.log("Error desconocido ....");  })
  }
  return (
    <div className="container">
      <h3>Estatus</h3>
      <div className="row">
        {
          estatus.data
          && (!estatus.data.Connected
          || !estatus.data.LoggedIn)
          ?
            <div className="col-4">          
              <img src={qr.data && qr.data.QRCode? qr.data && qr.data.QRCode: "images/loadPicture.png"} />            
            </div>
          : 
          null
        }
        <div className="col">
          <ul className="list-group list-group-flush mt-8">
            <li className="list-group-item">
              <span className="float-left">Intancia</span>
              <span className="float-right">{whatsappConfiguracion.intanciaId}</span>
            </li>
            <li className="list-group-item">
              <span className="float-left">Teléfono</span>
              <span className="float-right">{whatsappConfiguracion.telefono}</span>
            </li>
            <li className="list-group-item">
              <span className="float-left">Campaña</span>
              <span className="float-right">{whatsappConfiguracion.campaign}</span>
            </li>
            <li className="list-group-item">
              <span className="float-left">Conectado</span>
              <span className="float-right">{estatus.data.Connected? "Si": "No"}</span>
            </li>
            <li className="list-group-item">
              <span className="float-left">Logueado</span>
              <span className="float-right">{estatus.data.LoggedIn? "Si": "No"}</span>
            </li>
          </ul>
        </div>
      </div>
      <button type="button" className="btn btn-primary btn-lg btn-block"
        onClick={()=>{
          setEstatus({
            "code": 200,
            "data": {
              "Connected": false,
              "LoggedIn": false
            },
            "success": true
          });
          setQr(
            {
              "code": 200,
              "data": {
                "QRCode": null
              },
              "success": true
            }
          )
          getfuntion("get", "", "", "whatsapp/v2/plataforma/"+whatsappConfiguracion.idPlataforma+"/estatus/","estatus")
        }
      }>
        <i className="fa fa-refresh text-primary" style={{"font-size": "36px"}}></i> Actualizar
      </button>
    </div>
  )
}

export default VincularDispositivo
