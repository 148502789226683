import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import FetchData from './FetchData.react';
import CheckForm from './CheckForm.react';

export const MenuDashboardTerrestre = ({ setLoading, setError, setData }) => {
  const idUsuario = JSON.parse(sessionStorage.getItem('currentUser'))[0].usuario
    .id;
  const idPerfil = JSON.parse(sessionStorage.getItem('currentUser'))[0].perfil
    .id;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Bloques
  const [bloques, setBloques] = useState([]);
  const [bloque, setBloque] = useState({
    value: 0,
    label: 'Seleccione un tipo de contador',
  });
  //Funcionalidades
  const [funcionalidades, setFuncionalidades] = useState([]);
  const [funcionalidad, setFuncionalidad] = useState({
    value: 0,
    label: 'Seleccione un contador',
  });
  //Plataformas
  const [plataforma, setPlataforma] = useState({
    value: 0,
    label: 'Seleccione una plataforma',
  });
  const [plataformas, setPlataformas] = useState([]);
  //Transportistas
  const [transportista, setTransportista] = useState({
    value: 0,
    label: 'Seleccione un transportista',
  });
  const [transportistas, setTransportistas] = useState([]);
  //Intervalos
  const [semana, setSemana] = useState([]);
  const [mes, setMes] = useState([]);
  const [anio, setAnio] = useState(2024);
  const [fechaInicio, setFechaInicio] = useState([]);
  const [fechaFin, setFechaFin] = useState([]);

  const [temporalidad, setTemporalidad] = useState('');
  const [mostrarMes, setMostrarMes] = useState(false);
  const [mostrarSemana, setMostrarSemana] = useState(false);
  const [periodoPersonalizado, setPeriodoPersonalizado] = useState(false);
  //Variables check form
  const [errorBloque, setErrorBloque] = useState(false);
  const [errorFuncionalidad, setErrorFuncionalidad] = useState(false);
  const [errorPlataforma, setErrorPlataforma] = useState(false);
  const [errorTransportista, setErrorTransportista] = useState(false);
  const [errorMes, setErrorMes] = useState(false);
  const [errorSemana, setErrorSemana] = useState(false);
  const [errorAnio, setErrorAnio] = useState(false);

  const semanas = [];
  for (let i = 1; i < 54; i++) {
    semanas.push({ value: i, label: i });
  }

  // const anios =[];
  // for(let i=2019;i<(new Date().getFullYear());i++){
  // 	anios.push({"value": i, "label": i});
  // }

  let anios = [
    { value: 2024, label: '2024' },
    { value: 2023, label: '2023' },
    { value: 2022, label: '2022' },
  ];

  let meses = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  useEffect(() => {
    //setMes({ value: 0, label: "Elija un mes" });
    //setAnio({ value: (new Date().getFullYear()), label: (new Date().getFullYear()) });
    setAnio({ value: 2024, label: '2024' });
    //setSemana({ value: 0, label: "Seleccione la semana" });
    FetchData(
      'bloque/usuario/' + idUsuario + '/perfil/' + idPerfil,
      'Bloques',
      setBloques,
      setLoading,
      setError
    );
  }, []);

  useEffect(() => {
    if (bloque.value != 0) {
      FetchData(
        'funcionalidades/bloque/' + bloque.value + '/perfil/' + idPerfil,
        'Funcionalidades',
        setFuncionalidades,
        setLoading,
        setError
      );
      setFuncionalidad({ value: 0, label: 'Seleccione un contador ' });
    }
  }, [bloque]);

  useEffect(() => {
    if (funcionalidad.value != 0) {
      FetchData(
        'plataformas/usuario/' + idUsuario + '/tipo/2',
        'Plataformas',
        setPlataformas,
        setLoading,
        setError
      );
      setPlataforma({ value: 0, label: 'Seleccione una plataforma' });
    }
  }, [funcionalidad]);

  useEffect(() => {
    if (plataforma.value != 0) {
      FetchData(
        'transportistas/plataforma/' + plataforma.value,
        'Transportistas',
        setTransportistas,
        setLoading,
        setError
      );
      setTransportista({ value: 0, label: 'Seleccione un transportista' });
    }
  }, [plataforma]);

  const changeTemporalidad = (e) => {
    setTemporalidad(e.target.value);
    temporalidad == 'mes' ? setMostrarMes(true) : setMostrarMes(false);
  };

  useEffect(() => {
    temporalidad == 'mes' ? setMostrarMes(true) : setMostrarMes(false);
    temporalidad == 'semana' ? setMostrarSemana(true) : setMostrarSemana(false);
    //temporalidad=='anio'? setMostrarSemana(true):setMostrarSemana(false);
    temporalidad == 'periodoPersonalizado'
      ? setPeriodoPersonalizado(true)
      : setPeriodoPersonalizado(false);
  }, [temporalidad]);

  const check = () => {
    CheckForm(
      {
        idUsuario,
        bloque,
        funcionalidad,
        plataforma,
        transportista,
        semana,
        mes,
        anio,
        fechaInicio,
        fechaFin,
        temporalidad,
      },
      {
        setData,
        setLoading,
        setError,
        setErrorBloque,
        setErrorFuncionalidad,
        setErrorPlataforma,
        setErrorTransportista,
        setErrorMes,
        setErrorSemana,
        setErrorAnio,
      }
    );
  };

  return (
    <div>
      <div className="row" style={{ textAlign: 'center' }}>
        <div className="col-12">
          <h1 style={{ marginTop: '0.5em' }}>Dashboard Terrestre</h1>
        </div>
        <div className="col-4">
          <label className="form-label">Tipo de contador</label>
          <Select
            onChange={(opcion) => {
              setBloque(opcion);
            }}
            options={bloques}
            hideSelectedOptions
            value={bloque}
          />
          {errorBloque ? (
            <div style={{ color: '#dc3545' }}>
              Elija un tipo de contador antes de continuar.
            </div>
          ) : null}
        </div>

        <div className="col-2">
          <label className="form-label">Año</label>
          <Select
            onChange={(opcion) => {
              setAnio(opcion);
            }}
            options={anios}
            hideSelectedOptions
            value={anio}
          />
          {/* {errorAnio ? (
						<div style={{ color: "#dc3545" }}>
							Elija un Anio.
						</div>
					) : null} */}
        </div>

        <div className="col-4">
          <input
            type="radio"
            name="tempo"
            cheked={true}
            value="mes"
            onChange={changeTemporalidad}
          />
          <label className="form-label">Mes</label>
          <input
            type="radio"
            name="tempo"
            value="semana"
            onChange={changeTemporalidad}
          />
          <label className="form-label">Semana</label>
          <input
            type="radio"
            name="tempo"
            value="anio"
            onChange={changeTemporalidad}
          />
          <label className="form-label">Año</label>
          <label className="form-label">Periodo personalizado</label>
          <input
            type="radio"
            name="tempo"
            value="periodoPersonalizado"
            onChange={changeTemporalidad}
          />

          {mostrarMes ? (
            <Select
              isMulti
              onChange={(opcion) => {
                setMes(opcion);
                //alert(Object.keys(opcion));
                //alert(opcion.label);
                //alert(opcion.value);
                //setMes(Object.keys(opcion));
                //setMes(opcion['1']);
                /*alert(opcion['1'].label);
							if(opcion['2'])
							   alert(opcion['2'].label);*/
                //alert(mes['1'].label);
              }}
              options={meses}
              hideSelectedOptions
              value={mes}
              placeholder={'Seleccione los Meses'}
            />
          ) : null}
          {mostrarSemana ? (
            <Select
              isMulti
              onChange={(opcion) => {
                setSemana(opcion);
                alert('La semana:' + opcion['1']?.label);
              }}
              options={semanas}
              hideSelectedOptions
              value={semana}
              placeholder={'Seleccione la semana'}
            />
          ) : null}

          {periodoPersonalizado ? (
            <div>
              <label htmlFor="fechaIni" className="form-label">
                Fecha Inicial
              </label>
              <input
                onChange={(opcion) => {
                  setFechaInicio(opcion.target.value);
                }}
                type="date"
                id="fechaIni"
                name="fechaIni"
              ></input>

              <label htmlFor="fechaFin" className="form-label">
                Fecha fin
              </label>
              <input
                onChange={(opcion) => {
                  var fecha = new Date(opcion.target.value);
                  fecha.setDate(fecha.getDate() + 1);
                  setFechaFin(fecha.toISOString().split('T')[0]);
                }}
                type="date"
                id="fechaFin"
                name="fechaFin"
              ></input>
            </div>
          ) : null}

          {errorMes && mostrarMes ? (
            <div style={{ color: '#dc3545' }}>Elija un mes.</div>
          ) : null}

          {errorSemana && mostrarSemana ? (
            <div style={{ color: '#dc3545' }}>Elija una semana.</div>
          ) : null}
        </div>
      </div>

      {bloque.value != 0 ? (
        <div className="row mt-3" style={{ textAlign: 'center' }}>
          <div className="col-4">
            <label className="form-label">Contador</label>
            <Select
              onChange={(opcion) => {
                setFuncionalidad(opcion);
              }}
              options={funcionalidades}
              hideSelectedOptions
              value={funcionalidad}
            />
            {errorFuncionalidad ? (
              <div style={{ color: '#dc3545' }}>
                Elija un contador antes de continuar.
              </div>
            ) : null}
          </div>

          {funcionalidad.value != 0 &&
          !funcionalidad.label.includes('general') ? (
            <div className="col-4">
              <label className="form-label">Plataformas</label>
              <Select
                onChange={(opcion) => {
                  setPlataforma(opcion);
                }}
                options={plataformas}
                hideSelectedOptions
                value={plataforma}
              />
              {errorPlataforma ? (
                <div style={{ color: '#dc3545' }}>
                  Elija una plataforma antes de continuar.
                </div>
              ) : null}
            </div>
          ) : null}

          {funcionalidad.value != 0 &&
          funcionalidad.label.includes('Tipos') &&
          funcionalidad.label.includes('línea de transporte') ? (
            <div className="col-4">
              <label className="form-label">Transportistas</label>
              <Select
                onChange={(opcion) => {
                  setTransportista(opcion);
                }}
                options={transportistas}
                hideSelectedOptions
                value={transportista}
              />
              {errorTransportista ? (
                <div style={{ color: '#dc3545' }}>
                  Elija un transportista antes de continuar.
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="row mt-3">
        <div className="col text-center">
          <button className="btn btn-info" onClick={check}>
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};
