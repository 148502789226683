import React,{useState}from 'react';
import { useTimer } from 'react-timer-hook';

const Timer = ({ expiryTimestamp,bandera }) => {
    const handleChange = event => {
        
        bandera(true);
    };    
    
    const {
      totalSeconds,
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({ expiryTimestamp, onExpire: () => handleChange() });

    return (
        <div style={{textAlign: 'center'}}>
          <div style={{fontSize: '40px'}}>
            <span>{hours < 10 ? "0" + hours : hours}</span>:<span>{minutes < 10 ? "0" + minutes: minutes}</span>:<span>{seconds < 10 ? "0" + seconds : seconds}</span>
          </div>
        </div>
      );
}
    
export default Timer;