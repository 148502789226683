import React, { useEffect, useState } from "react";
import { List } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../../herramientas/Crud_Catalogos';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";
import ModalLay from "../../../../components/ModalLay/ModalLay";
import { CorreosClit, Etiquetas, Contactos } from "../../../../Catalogos/Cliente/Formularios";
import { mail, text, disabledSave } from '../../../../herramientas/Forms';
import NotificacionesAutomaticas from './NotificacionesAutomaticas';
let guardando= false;
const ContactosMov =({obj, stor, mov})=> {
  const [crearEditar, setCrearEditar]= useState(false);
  const [contacto, setContacto] = useState(
    {
      "idPlataforma": mov.idPlataforma.id,
      "idMovimiento": mov.id,
      "correo": "",
      "correoEstatus": true,
      "nombreCompleto": "",
      "telefono": "",
      "whatsappEstatus": false,
      "fecha": null
    });
  const [contactos, setContactos]= useState([]);
  const [guardar, setGuardar]= useState(false);
  const [tab, setTab]= useState(1);
  useEffect(() => {
		if(mov.id){
      getfuntion("get", "", "", "movimiento_contactos_temporal/"+mov.idPlataforma.id+"/"+mov.id, "contactos");
    }
	}, []);

  useEffect(() => {
    if( tab === 2 ){
      getfuntion("get", "", "", "clientesEtiquetas/"+obj.id, "clientesEtiquetas");
    }
	}, [tab]);
  const reset=()=>{
    guardando= false;
    setContacto(
      {
        "idPlataforma": mov.idPlataforma.id,
        "idMovimiento": mov.id,
        "correo": "",
        "correoEstatus": true,
        "nombreCompleto": "",
        "telefono": "",
        "whatsappEstatus": false,
        "fecha": null
      });
    setCrearEditar(false);
  }
 const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "contactos" && returnVal) {
               setContactos(returnVal);
            }/*
            if (stateVar === "contactos_temporales" && returnVal) {
              let contTemporalesTemp= [];
              for(let c of returnVal){
                c.fecha= timeToDateFill(c.fecha);
                contTemporalesTemp.push(c);
              }
               setContactosTemporales(contTemporalesTemp);
            }*/
          }
          break;
        case "post":
          {
            if (stateVar === "contactos" && returnVal) {
              reset();
              NotificationManager.success(
                `Registro agregado ${returnVal.nombreCompleto} .`,
                "Guardar"
              );
               getfuntion("get", "", "", "movimiento_contactos_temporal/"+mov.idPlataforma.id+"/"+mov.id, "contactos");
            }
          }
          break;
        case "put":
          {
            if (stateVar === "contactos" && returnVal) {
              reset();
              NotificationManager.success(
                `Registro actualizado ${returnVal.nombreCompleto} .`,
                "Guardar"
              );
                getfuntion("get", "", "", "movimiento_contactos_temporal/"+mov.idPlataforma.id+"/"+mov.id, "contactos");
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  return (    
    <div className= "mt-1">
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1) }> Cont Temp</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }> Etiq</a>
          </li>
          <li className="nav-item">
            <a className={"nav-link"+ (tab === 3 ? " active":"")} onClick={()=> setTab(3) }> Notif. Auto.</a>
          </li>
        </ul>
        <div  hidden={tab !== 1}>
          <h4>Contactos Temporales (Se borraran al finalizar el movimiento)</h4>
          <div hidden={ crearEditar }>
            <TablePageable
              titulo={
                <div className="row">
                  <div className="col">
                    <i className="fa fa-plus"  style={{"font-size": "15px"}}
                      onClick={()=> setCrearEditar(true)}
                    ></i>
                  </div>
                  <div className="col">
                    <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                      onClick={()=>{
                        if( mov.id )
                          getfuntion("get", "", "", "movimiento_contactos_temporal/"+mov.idPlataforma.id+"/"+mov.id, "contactos");
                      }}
                    ></i>
                  </div>
                </div>
              }
              lista= { contactos }
              columnas={
                [
                  { columna: "edit", label:"Edit",
                    form: (e)=> <i className="fa fa-edit"  style={{"font-size": "15px"}} onClick={()=> setCrearEditar(true)}></i>
                  },
                  { columna: "correoEstatus", label:"Correo Estatus" },
                  { columna: "correo", label:"Correo" },
                  { columna: "nombreCompleto", label:"Nombre Completo",  },
                  { columna: "telefono", label:"Telefono" },
                  { columna: "whatsappEstatus", label:"Whatsapp Estatus" },
                ]
              }
              id= "correo"
              selected={(e)=>{
                setContacto(e);
              }}
            />
          </div>
          <div hidden={ !crearEditar } className="row">
            <div className="col"><h2>{ contacto.fecha? "Editar Contacto": "Agregar Contacto"} </h2></div>
            <div className="col">
              <button id="cancelar" name ="reset" className="btn-danger" onClick={()=> reset()}>
                <span className="fa fa-close"  style={{"font-size": "15px"}}>Cancelar</span>
              </button>
            </div>
            <div className="col-12">
              <form>
                <div className="form-group">
                  <label for="nombre">Nombre completo</label>
                  <input type="text" className="form-control" id="nombre" aria-describedby="nombreHelp" placeholder="Nombre Paterno Materno"
                    value={contacto.nombreCompleto} onChange={ (e)=>{ if(text( e.target.value, 0, 150)) setContacto({ ...contacto, nombreCompleto: e.target.value}) }} />
                  <small id="nombreHelp" className="form-text text-muted">Este nombre podria utilizarse en las notificaciones de correo y se mostrara en formularios.</small>
                  { contacto.nombreCompleto.length && !text( contacto.nombreCompleto, 1, 150) ?
                    <small id="nombreHelp" className="form-text text-danger">Maximo 150 caracteres</small>
                  : null}
                </div>
                <div className="form-group">
                  <label for="correo">Dirección de correo</label>
                  <input type="email" className="form-control" id="correo" aria-describedby="correoHelp" placeholder="correo@dominio.extencion"
                    value={contacto.correo} onChange={ (e)=> setContacto({ ...contacto, correo: e.target.value})} />
                  <small id="correoHelp" className="form-text text-muted">En esta cuenta se recibiran las notificaciones del servicio {mov.id}.</small>
                  { contacto.correo && contacto.correo.length &&
                  (!mail(contacto.correo).estatus || !contacto.fecha && contactos.filter(c=> c.correo === contacto.correo).length)?
                    <small id="correoHelp" className="form-text text-danger">{mail(contacto.correo).mensaje}
                    {contactos.filter(c=> c.correo === contacto.correo).length ? "NO se pueden duplicar los correos": ""}.</small>
                    : null}
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="correoEstatusCheck" aria-describedby="correoEstatusHelp"
                    checked={contacto.correoEstatus} onChange={ (e)=>setContacto({ ...contacto, correoEstatus: e.target.checked}) } />
                  <label className="form-check-label" for="correoEstatusCheck">Activar envio de correo</label>
                  <small id="correoEstatusHelp" className="form-text text-muted">
                    { contacto.correoEstatus? "El envío de notificaciones por correo esta activo": "El envío de notificaciones por correo esta inactivo"}
                  </small>
                </div>
                <div className="form-group">
                  <label for="telefono">Teléfono(10 digitos)</label>
                  <input type="number" className="form-control" id="telefono" aria-describedby="telefonoHelp" placeholder="5544332211"
                    value={contacto.telefono} onChange={ (e)=>{ if(text( e.target.value, 1, 10)) setContacto({ ...contacto, telefono: e.target.value}) }} />
                  <small id="telefonooHelp" className="form-text text-muted">Ingresa tu teléfono a 10 digitos, podria usarse para recibir notificaciones via whatsapp</small>
                  { contacto.telefono.length && !text( contacto.telefono, 1, 150) ?
                    <small id="telefonooHelp" className="form-text text-danger">Deen ser 10 digitos</small>
                  : null}
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="whatsappEstatusCheck" aria-describedby="whatsappEstatusHelp"
                    checked={contacto.whatsappEstatus} onChange={ (e)=>setContacto({ ...contacto, whatsappEstatus: e.target.checked}) } />
                  <label className="form-check-label" for="whatsappEstatusCheck">Activar envio de whatsapp</label>
                  <small id="whatsappEstatusHelp" className="form-text text-muted">
                    { contacto.whatsappEstatus? "El envío de notificaciones a whatsapp esta activo": "El envío de notificaciones a whatsapp esta inactivo"}
                  </small>
                </div>                
              </form>
              <span className="float-right">
                <button id="guardar" name ="guardar" className="btn-primary"
                disabled={
                  disabledSave({
                    guardando: !guardando,
                    nombre: contacto.nombreCompleto.length > 1,
                    correo: mail(contacto.correo).estatus,
                  })
                }
                  onClick={()=>{
                    if(!guardando){
                      guardando= true;
                      getfuntion((contacto.fecha? "put": "post"), contacto, "", "movimiento_contactos_temporal", "contactos");
                    }
                  }}>
                  <span className="fa fa-save"  style={{"font-size": "15px"}}> Guaradar</span>
                </button>
              </span>
            </div>
          </div>
        </div>
        <div  hidden={tab !== 2}>
          <h4>Etiquetas(Los contactos del cliente reciben las siguientes notificaciones)</h4>
          <Etiquetas
            accion={"modificar"}
            elementos={obj}
            stor={stor}
          />
        </div>
        <div  hidden={tab !== 3}>
          <h4>Configuración de notificaciones automaticas(Arroba Azul)</h4>
          <NotificacionesAutomaticas movimiento={mov} />
        </div>
      </div>
    </div>
          
  );
}
export default ContactosMov;