import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import Select from "react-select";

class GPS extends Component {
    constructor(props) {
      super(props);
      this.state = {
                 gps : { value:0, label:"Seleccione un GPS " },
                 gpsTipo : { value:0, label:"Seleccione un Tipo de Colocacion " },
                  accion : "post", //'nuevo'// modificar, nuevo
                  gpss: [],
                  unidadGps: [],
        };
             this.gpsTipo=[];
               this.valid={ gpsTipo:false, gps:false };
    }
    componentDidMount =() => {
      this.getfuntion("get", "", "", "gps/idestatus/1","gps");      
      this.props.stor.unidadTipoColocacion_Object.map((elemento, index) => this.gpsTipo.push({ value:elemento.id, label:elemento.nombre, data: elemento }) );

      if(this.state.accion !== 'nuevo'){
      }else{
      }
      this.clear();
    }
    isValid=(campo) =>{
      switch (campo) {
        case "gps":
          return this.valid.gps;
          break;
        case "gpsTipo":
          return this.valid.gpsTipo;
          break;
        default:
          break;
      }
    }
    clear=()=>{
      this.gps={"idUnidadNavTer": this.props.elementos.id,"idGps":null,"idUnidadTipoColocacion": null};
      this.getfuntion("get", "", "", "unidadGpsTerNav/idUnidadNavTer/"+this.props.elementos.id,"unidadGps");
      this.valid={ gpsTipo:false, gpss:false };
    }
    changeGpsTipo =(event) =>{ console.log(event);
      this.gps.idUnidadTipoColocacion= event.value;
      this.setState({gpsTipo: event});
      this.valid.gpsTipo= event.value > 0? true: false;
    }
    changeGps =(event) =>{ console.log(event);
      this.gps.idGps= event.value;
      this.setState({gps: event});
      this.valid.gps= event.value > 0? true: false;
    }

    disableSave =() =>{
      if (this.valid.gps && this.valid.gpsTipo) {
        return false;
      }else{
        return true;
      }
    }

    crud_post =() =>{
      this.getfuntion("post", this.gps, "", "unidadGpsTerNav","unidadGps");
    }
    crud_delete =(gps) =>{
      this.getfuntion("delete", gps, gps.idUnidadNavTer+"/"+gps.idGps+"/"+gps.idUnidadTipoColocacion, "unidadGpsTerNav","unidadGps");
    }
    
    getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
      Crud_Catalogos(catalogo, "loquequieras", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
          switch (metodo) {
            case "post":
              {
                if (stateVar === "unidadGps"&& returnVal){
                  this.setState({ gps : { value:0, label:"Seleccione un GPS Fijo " } });
                  this.setState({ gpsTipo : { value:0, label:"Seleccione un Tipo de Colocacion " } });
                  this.clear();
                  let gpssTem= this.state.gpss;
                  gpssTem.map((g, index)=>{
                    if( g.id === returnVal.idGps){
                        gpssTem.splice(index, 1);
                        this.setState({ gpss: gpssTem });
                      }
                    });
                }
              } break;
            case "get":
              {
                if (stateVar === "gps" && returnVal){
                  let gpssTem=[];
                  returnVal.map((elemento, index) => gpssTem.push({ value: elemento.id, label: elemento.nombre, data: elemento }) );
                  this.setState({ gpss: gpssTem});
                }
                if (stateVar === "unidadGps"&& returnVal){
                  this.setState({ unidadGps: returnVal});
                }
              } break;
            case "delete":
              {
                if (stateVar === "unidadGps"){
                  this.clear();
                }
              } break;
            default:
              break;
          }
        }).catch(err =>{
          if( err.response ){
            console.log(err.response);
          }else{
            /**
             * Error
             */
            console.log("Error desconocido ....");
            console.log(err)
          }
      })
    }
  //<Button pill  size="sm" outline color="primary" onClick={ ()=> this.AlertasEdit(elemento.alertaCategoria.id, elemento.alertaTipo.id) }><Icon name="edit"/></Button>
    render(){
      return (
        <div>
            <div>
              <Grid.Row>
                <Grid.Col>
                  <Card
                    title=  { <div>Unidad: 
                              {this.props.elementos.idUnidadTipo?this.props.elementos.idUnidadTipo.nombre:""}, 
                              {this.props.elementos.idUnidadMarca?this.props.elementos.idUnidadMarca.nombre:""},
                              {this.props.elementos.modelo},
                              {this.props.elementos.placa}, 
                              {this.props.elementos.color}
                            </div>}
                    isCollapsible={ false }
                    isCollapsed={ false}
                    body={
                    <div>
                      <Grid.Row>
                      <Grid.Col sm={12} md={4}>
                        <Form.Group label="GPS">
                            <Select value={this.state.gps} onChange={this.changeGps} options={this.state.gpss} />
                        </Form.Group>
                        <Form.Group label="Tipo de GPS">
                            <Select value={this.state.gpsTipo} onChange={this.changeGpsTipo} options={this.gpsTipo} />
                        </Form.Group>
                        <div className="float-right">
                          <span class="badge">
                            <Button size="sm" RootComponent="a" color="primary" disabled= { this.disableSave() } onClick={ ()=>this.crud_post() } >
                              <span class="badge"><Icon link={true} name="save" />Guardar</span>
                            </Button>
                          </span>
                        </div>
                      </Grid.Col>

                      <Grid.Col sm={12} md={8}>
                          <Form.Group>
                            <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                              <Table.Header>
                                <Table.ColHeader>GPS (idGG)</Table.ColHeader>
                                <Table.ColHeader>GPS Tipo</Table.ColHeader>
                                <Table.ColHeader>Acción</Table.ColHeader>
                              </Table.Header>
                              <Table.Body>
                              {
                                this.state.unidadGps.map((elemento, index) =>
                                  <Table.Row>
                                    <Table.Col>{ this.getGpsNombre(elemento.idGps)+"("+elemento.idGps+")"  }</Table.Col>
                                    <Table.Col>{ this.getTipoColocacionNombre(elemento.idUnidadTipoColocacion) }</Table.Col>
                                    <Table.Col><Button pill  size="sm" outline color="primary" onClick={ ()=> this.crud_delete(elemento) }><Icon name="trash"/></Button></Table.Col>
                                  </Table.Row>
                                )
                              }
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col sm={12} md={12}>
                          Valida todos tus datos antes de guardar, del lado derecho podras asignar los GP's Fijos...
                        </Grid.Col>
                      </Grid.Row>
                    </div>
                  }
                  />
                </Grid.Col>

              </Grid.Row>
            </div>
        </div>
      )
    }
    getGpsNombre=(id)=>{
      for(let item of this.state.gpss){
        if( item.value === id){ return item.label}
      }
      return "n/a";
    }
    getTipoColocacionNombre=(id)=>{
      for(let item of this.gpsTipo){
        if( item.value === id){ return item.label}
      }
      return "n/a";
    }
  }

  export default GPS;
