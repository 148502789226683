import React, { Component, useState, useEffect } from "react";
import { Dimmer, Button, Icon, Card, Table, Grid, Form, Alert } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../herramientas/DateFormat";
import Select from "react-select";
import {NotificationContainer, NotificationManager} from 'react-notifications';


const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
var contadorCarga= 0;
var disabledButon= false;




export const AddMovimientoGpsForm = (props)=>{
  const [disabledButon, setDisabledButon] = useState(false);
  const [esFull, setEsFull] = useState(false);
  const [po1, setPo1] = useState("");
  const [gpsAgregado, setGpsAgregado] = useState([]);
  const [gpsList, setGpsList] = useState([]);
  const [idGps, setIdGps] = useState({value:0,label:"Seleccione un GPS"});
  const [tipoGps, setTipoGps] = useState({ value: 0, label:"Seleccione una opción" });
  const [tractorModelo, setTractorModelo] = useState("");
  const [tractorMarca, setTractorMarca] = useState("");
  const [tractorColor, setTractorColor] = useState("");
  const [tractorPlaca, setTractorPlaca] = useState("");
  const [remolquePlaca, setRemolquePlaca] = useState("");
  const [idRemolque, setIdRemolque] = useState({ value: 0, label:"Seleccione una Opción" });
  const [economicot, setEconomicot] = useState("");
  const [economicor, setEconomicor] = useState("");
  const [operadorNombre, setOperadorNombre] = useState("");
  const [operadorContacto, setOperadorContacto] = useState("");
  const [operadorLicencia, setOperadorLicencia] = useState("");
  const [observaciones, setObservaciones] = useState("");
  /**
                                     * MOVIMIENTO SECUNDARIO
                                     */
  const [po2, setPo2] = useState("");
  const [idMovFull, setIdMovFull] = useState({ value: 0, label:"Seleccione un Movimiento" });
  const [gpsAgregadosFull, setGpsAgregadosFull] = useState([]);
  const [gpsListFull, setGpsListFull] = useState([]);
  const [idGpsFull, setIdGpsFull] = useState({ value: 0, label:"Seleccione un GPS" });
  const [tipoGpsFull, setTipoGpsFull] = useState({ value: 0, label:"Seleccione una Opción" });
  const [remolquePlacaFull, setRemolquePlacaFull] = useState("");
  const [idRemolqueFull, setIdRemolqueFull] = useState({ value: 0, label:"Seleccione una Opción" });
  const [economicorFull, setEconomicorFull] = useState("");
  const [observacionesFull, setObservacionesFull] = useState("");
  const [movimientosSinGps, setMovimientosSinGps] = useState([]);
  const [gpsGateEtiquetas, setGpsGateEtiquetas] = useState(null);
  const [updateMSG, setUpdateMSG] = useState(false);

  const [gpsDisponible, setGpsDisponible] = useState([]);

  const [direccionGPS, setDireccionGPS] = useState("");
  const [nivelBateria, setNivelBateria] = useState(0);
  const [ultimoReporte, setUltimoReporte] = useState("");
  
  const [colocacionFecha, setColocacionFecha] = useState(null);
  
  const [esFullFull, setEsFullFull] = useState(null);
  
  const [userId, setUserId] = useState(props.userId);

  const [idMovPrimario, setIdMovPrimario] = useState(props.idMovimiento);
  const [idMovPatio, setIdMovPatio] = useState(null);
  const [idMovPatioFull, setIdMovPatioFull] = useState(null);
  
  const [nuevo1, setNuevo1] = useState(true);
  const [nuevoFull, setNuevoFull] = useState(true);

  var movimiento= [];
  
  var idRemolques=[ { value: 0, label:"Seleccione una Opción", data:[] },
                            { value: 1, label:"PLATAFORMA",         data: {idRemolque: 1, nombre: "PLATAFORMA"} },
                            { value: 2, label:"CHASIS 40",          data: {idRemolque: 2, nombre: "CHASIS 40"} },
                            { value: 3, label:"CHASIS 20",          data: {idRemolque: 3, nombre: "CHASIS 20"} },
                            { value: 4, label:"CHASIS ABATIBLE",    data: {idRemolque: 4, nombre: "CHASIS ABATIBLE"} },
                            { value: 5, label:"TORTON",             data: {idRemolque: 5, nombre: "TORTON"} }
                          ];
        /**
         * Datos viejos
         */
        const [gpsAgregadoOld, setGpsAgregadoOld] = useState([]);
        const [gpsAgregadosFullOld, setGpsAgregadosFullOld] = useState([]);
  
        /**
         * Datos nuevos
         */
  let movimientosPatios =   { "idMovimiento": props.idMovimiento, "economicor": economicor, "economicot": economicot,"idRemolque": { "idRemolque": idRemolque.value, "nombre":idRemolque.label }, "operadorNombre": operadorNombre, "operadorContacto": operadorContacto, "operadorLicencia": operadorLicencia, "tractorModelo": tractorModelo, "tractorMarca": tractorMarca, "tractorColor": tractorColor, "tractorPlaca": tractorPlaca, "esFull": esFull, "remolquePlaca": remolquePlaca, "colocacionFecha": colocacionFecha, "observaciones": observaciones, "id" : idMovPatio};
  
  let movimientosPatiosFull =   { "idMovimiento": idMovFull, "economicor": economicorFull, "economicot": economicot,"idRemolque": { "idRemolque": idRemolqueFull.value, "nombre": idRemolqueFull.label }, "operadorNombre": operadorNombre, "operadorContacto": operadorContacto, "operadorLicencia": operadorLicencia, "tractorModelo": tractorModelo, "tractorMarca": tractorMarca, "tractorColor": tractorColor, "tractorPlaca": tractorPlaca, "esFull": esFullFull, "remolquePlaca": "", "colocacionFecha": null, "observaciones": observacionesFull, "id": idMovPatioFull};
  
  var movimientosGps =   { "idMovimiento": 0, "gps": { "id": 0 }, "gpsTipo": { "id": 0 } };
  var movimientosGpsFull =   { "idMovimiento": 0, "gps": { "id": 0 }, "gpsTipo": { "id": 0 } };
  var gpsGG =   { "gps": 0, "plataforma": 0, "estatus": 0 };
  var gpsTipos =   [{ value: 0, label:"Seleccione una opción" }, { value: 1, label:"PRINCIPAL" }, { value: 2, label:"DESECHABLE" }, { value: 3, label:"CINTURÓN" }, { value: 4, label:"RESPALDO" }, { value: 5, label:"CANDADO" }];
  let gpsDisponibles =   [];
  
  var usuariosLogObject = {
            "idBitacoraMonitoreo": 0,
            "idUsuario": 0,
            "idMovimiento": 0,
            "accionFecha": "",
            "descripcion": "",
            "idPlataformaTipo" : 0
          };
   
  useEffect(() => {
      setDisabledButon (false);
     
    //  getfuntion("get", [], "", "gpsMobile/idGpsEstatus/1", "gpsDisponible"); //Gps Disponibles
    
    getfuntion("get", [], "", "gps/pagina/0/total/100", "gpsDisponible"); //Todos los estatus
    //  getfuntion("get", [], "", "gpsMobile/idGpsEstatus/4", "gpsAsignado"); //Gps Disponibles
     getfuntion("get", [], "", "globalMobile/movimientos_sin_gps", "movimiento_s_gps");//Movimientos sin gps para full
     getfuntion("get", [], "", "navieraMovimientos/"+props.idMovimiento, "movimiento").then((movNav) => {
    movimiento= movNav;
     getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+props.idMovimiento, "movimientosPatio");
    });    
     getfuntion("get", [], "", "gpsGateEtiquetas/PLATAFORMA/"+props.plataformaid, "gpsGateEtiquetas");

    
  },[props.idMovimiento] )  
  
  const setMovPatio=(patio)=>{
    
    setIdRemolque ({ value: patio.idRemolque.idRemolque , label: patio.idRemolque.nombre, data: patio.idRemolque } );
    setOperadorNombre   ( patio.operadorNombre    );
    setOperadorContacto ( patio.operadorContacto  );
    setOperadorLicencia ( patio.operadorLicencia  );
    setTractorModelo    ( patio.tractorModelo     );
    setTractorMarca     ( patio.tractorMarca      );
    setTractorColor     ( patio.tractorColor      );
    setTractorPlaca     ( patio.tractorPlaca      );
    setRemolquePlaca    ( patio.remolquePlaca     );
    setObservaciones    ( patio.observaciones     );
    setEconomicor       ( patio.economicor? patio.economicor: "");
    setEconomicot       ( patio.economicot? patio.economicot: "");
    setIdMovPatio       ( patio.id                );
    setColocacionFecha  ( patio.colocacionFecha);
    // setIdMovPatioFull   ( patio.esFull? patio.esFull:0 );

  }
  const setMovPatioFull=(patio)=>{
    setIdRemolqueFull ({ value: patio.idRemolque.idRemolque , label: patio.idRemolque.nombre, data: patio.idRemolque } );
    // setOperadorNombreFull   ( patio.operadorNombre    );
    // setOperadorContactoFull ( patio.operadorContacto  );
    // setOperadorLicenciaFull ( patio.operadorLicencia  );
    // setTractorModeloFull (      patio.tractorModelo     );
    // setTractorMarcaFull  (      patio.tractorMarca      );
    // setTractorColorFull (       patio.tractorColor      );
    // setTractorPlacaFull (        patio.tractorPlaca      );
    setRemolquePlacaFull ( patio.remolquePlaca                   );
    setObservacionesFull ( patio.observaciones                   );
    setEconomicorFull    ( patio.economicor? patio.economicor: "");
    setIdMovPatioFull    ( patio.id                              );
    setEsFullFull        ( patio.esFull? patio.esFull:0      );
    // setEconomicotFull ( patio.economicot? patio.economicot: "");
  }
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "post":
          {
            if (stateVar === "movimientosPatios") { }
            if (stateVar === "movimientoGp") { return true ;}
            if (stateVar === "asignar_gps") { return true ;}            
          }
          break;
        case "get":
          {
            if (stateVar === "gpsGateEtiquetas") { 
                setGpsGateEtiquetas(returnVal);
                setUpdateMSG(true); //Valida Etiquetas(De no estar la  equieteta no se ra posible configurar)
            }
            if (stateVar === "gpsEstatus") { return returnVal; }
            if (stateVar === "movimiento") { return returnVal; }
            if (stateVar === "gpsestatus") { return returnVal; }
            if (stateVar === "movimientoFull") { return returnVal; }
            if (stateVar === "movimientosPatioFull") { return returnVal; }
            
            if (stateVar === "movimientosPatio") {                
                if(returnVal.id){
                    setNuevo1 (false);
                    
                    if(returnVal.esFull){                       
                        setEsFull(true);
                        if(!movimiento.movimientoGps[0]){//No tiene GPS, aqui se valida el el mov full
                            getfuntion("get", [], "", "navieraMovimientos/"+returnVal.esFull, "movimientoFull").then((movFull) => {
                              if(!movFull.movimientoGps[0]){//No importa el orden, no hay GPS asignados(conservamos el mov seleccionado como primario)
                                setPo1 (movimiento.id+"-"+movimiento.po+" - "+movimiento.contenedor);
                                setPo2 (movFull.id+" - "+movFull.po+" - "+movFull.contenedor);
                                setIdMovFull({value: movFull.id, label: movFull.id+" - "+movFull.po+" - "+movFull.contenedor, data: movFull });
                                movimientosPatios= returnVal;
                                                                
                                setMovPatio(returnVal);
                                
                                
                                getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {                                    
                                    if(movPatioFull.id){
                                      
                                      setNuevoFull (false);
                                        movimientosPatiosFull= movPatioFull;
                                        
                                        setMovPatioFull(movPatioFull);
                                    }else{
                                        movimientosPatiosFull= returnVal;
                                        
                                        setMovPatioFull(returnVal);
                                    }
                                });
                              }else{//El Movimiento selecionado pasa como full
                                setIdMovPrimario (movFull.idMovimiento)
                                setPo2 (movimiento.id+" - "+movimiento.po+" - "+movimiento.contenedor);
                                setPo1 (movFull.id+" - "+movFull.po+" - "+movFull.contenedor);
                                
                                setIdMovFull({ value: movimiento.id, label: movimiento.id+" - "+movimiento.po+" - "+movimiento.contenedor, data: movimiento });
                                
                                getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {
                                    if(movPatioFull.id){
                                        setNuevoFull (false);
                                        movimientosPatios= movPatioFull;
                                        setMovPatio(movPatioFull);
                                        
                                        movimientosPatiosFull= returnVal;
                                        
                                        setMovPatioFull(returnVal);
                                    }else{
                                      
                                      movimientosPatios= returnVal;
                                      
                                        setMovPatio(returnVal);
                                        
                                        
                                        movimientosPatiosFull= returnVal;
                                        
                                        setMovPatioFull(returnVal);
                                    }
                                    
                                });
                                
                                let gpsAgregadoTemp = gpsAgregado;
                                let gpsAgregadoOldTemp = gpsAgregadoOld
                                for(let mgps of movFull.movimientoGps){
                                      
                                      getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                      
                                        gpsAgregadoTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id})
                                        gpsAgregadoOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id})
                                      
                                      });
                                    
                                }
                                
                                setGpsAgregado(gpsAgregadoTemp);
                                      
                                setGpsAgregadoOld(gpsAgregadoOldTemp);
                                
                                gpsAgregadoTemp = gpsAgregadosFull;
                                gpsAgregadoOldTemp = gpsAgregadosFullOld;
                                for(let mgps of movFull.movimientoGps){
                                                                     
                                    if( mgps.gps.idGg > 20 ){
                                      getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                        
                                        gpsAgregadoTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id });
                                        gpsAgregadoOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id});
                                        
                                      
                                      });
                                    }else{
                                      
                                      gpsAgregadoTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0", id_movGps: mgps.id });
                                      gpsAgregadoOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0", id_movGps: mgps.id });
                                      
                                    }
                                    
                                }
                                setGpsAgregadosFull(gpsAgregadoTemp);
                                setGpsAgregadosFullOld(gpsAgregadoOldTemp);
                                                                
                              }
                            });

                        }else{//Si hay GPS, Se queda como proimario()agregamos gps a lista
                          setPo1(movimiento.id+" - "+movimiento.po+" - "+movimiento.contenedor);  
                          
                          movimientosPatios= returnVal;
                          
                          setMovPatio(returnVal);
                          let gpsAgregadoTemp = gpsAgregado
                          let gpsAgregadoOldTemp = gpsAgregadoOld
                            
                            for(let mgps of movimiento.movimientoGps){
                                
                             
                                
                                  getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                    
                                    gpsAgregadoTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id});
                                    gpsAgregadoOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id});

                                    
                                    
                                  });
                                
                            }
                            
                            setGpsAgregado(gpsAgregadoTemp);
                            setGpsAgregadoOld(gpsAgregadoOldTemp)
                                                       
                            getfuntion("get", [], "", "navieraMovimientos/"+returnVal.esFull, "movimientoFull").then((movFull) => {
                              setPo2 (movFull.id+" - "+movFull.po+" - "+movFull.contenedor);  
                              setIdMovFull ({ value: movFull.id, label: movFull.id+" - "+movFull.po+" - "+movFull.contenedor, data: movFull });
                                
                                getfuntion("get", [], "", "movimientosPatio/idMovimiento/"+returnVal.esFull, "movimientosPatioFull").then((movPatioFull) => {
                                    
                                    if(movPatioFull.id){
                                        setNuevoFull (false);
                                        movimientosPatiosFull= movPatioFull;
                                        setMovPatioFull(movPatioFull);
                                    }else{
                                        movimientosPatiosFull= returnVal;
                                        setMovPatioFull(returnVal);
                                       
                                    }
                                });
                                if(movFull.movimientoGps[0]){
                                  let gpsAgregadosFullTemp = gpsAgregadosFull;
                                  let gpsAgregadosFullOldTemp = gpsAgregadosFullOld;
                                  
                                  for(let mgps of movFull.movimientoGps){
                                      
                                        if( mgps.gps.idGg > 20 ){
                                          getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                            

                                            gpsAgregadosFullTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id});
                                            gpsAgregadosFullOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id});
                                                                                          
                                          });
                                        }else{
                                          gpsAgregadosFullTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0", id_movGps: mgps.id });
                                          gpsAgregadosFullOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0", id_movGps: mgps.id });
                                                                                    
                                        }
                                    }
                                    setGpsAgregadosFull(gpsAgregadosFullTemp);
                                    setGpsAgregadosFullOld(gpsAgregadosFullOldTemp);

                                }else{
                                    let gpsAddTemp = gpsAgregadosFull;
                                    for(let mgps of movimiento.movimientoGps){
                                        
                                        if( mgps.gps.idGg > 20 ){
                                          getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                            
                                            gpsAddTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id });
                                                                                          
                                          });
                                        }else{
                                          gpsAddTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: "", nivelbateria: "0", id_movGps: mgps.id })
                                          
                                        }
                                    }
                                    setGpsAgregadosFull(gpsAddTemp);
                                    setGpsAgregadosFullOld(gpsAddTemp);
                                  
                                }                               
                            });                            
                        }
                    }else{//Editar, No es full
                        setIdMovPrimario(props.idMovimiento);
                      
                        setPo1 (movimiento.id+" - "+movimiento.po+" - "+movimiento.contenedor);
                       
                        movimientosPatios = returnVal;
                       
                        setMovPatio(returnVal);
                       
                        let gpsAddTemp = gpsAgregado;
                        let gpsAgregadoOldTemp = gpsAgregadoOld;
                        if(movimiento.movimientoGps[0]){//Tiene GPS, agregamos los GPS'S a la lista
                            for(let mgps of movimiento.movimientoGps){
                              
                                  getfuntion("get", "", "", "gpsestatus/plataforma/"+movimiento.idPlataforma.idGg+"/gps/"+mgps.gps.idGg, "gpsestatus").then((gpsEstatus) => {
                                    
                                    gpsAddTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id})  
                                    gpsAgregadoOldTemp.push({id: mgps.gps.id, nombre: mgps.gps.nombre, tipoid: mgps.gpsTipo.id, tipo: mgps.gpsTipo.nombre, direccion: gpsEstatus.direccion, nivelbateria: gpsEstatus.nivel_bateria, ultimo_reporte: gpsEstatus.ultimo_reporte, id_movGps: mgps.id})  
                                  });
                                
                            }
                            
                            setGpsAgregado(gpsAddTemp);
                            setGpsAgregadoOld(gpsAgregadoOldTemp);
                                                       
                        }
                    }
                }else{
                    setPo1(movimiento.id+" - "+movimiento.po+" - "+movimiento.contenedor);
                    
                }
            }
            if (stateVar === "gpsDisponible") {
             
                returnVal.content.map((elemento, index) => {
                    
                  gpsDisponibles.push({ value: elemento.id, label: elemento.nombre });
                    
                });
                
                setGpsDisponible(gpsDisponibles);
                
                setGpsList (gpsDisponibles);
               
                setGpsListFull (gpsDisponibles );
                
            }
            if (stateVar === "gpsAsignado") {
              let gpDispo = gpsDisponible;
              gpDispo = gpDispo.concat( returnVal );
            
              returnVal.map((elemento, index) => {
                  gpsDisponibles.push({ value: elemento.id, label: elemento.nombre });
              });
              
              setGpsList (gpsDisponibles);
              
              setGpsListFull (gpsDisponibles );
          }
            if(stateVar === "datosGps"){
             
              setDireccionGPS (returnVal.direccion); 
              setNivelBateria (returnVal.nivel_bateria);
              setUltimoReporte(returnVal.ultimo_reporte);
              
            }
            if(stateVar === "datosGpsSGPS"){
             
              setDireccionGPS (returnVal.direccion);
                setNivelBateria (returnVal.nivel_bateria);
                setUltimoReporte(returnVal.ultimo_reporte);
            }
            if(stateVar === "movimiento_s_gps"){
                let movSinGpsTem= movimientosSinGps;
                returnVal.map((elemento, index) => {
                  if( props.idMovimiento !== elemento.idMovimiento ){
                    movSinGpsTem.push({ value: elemento.idMovimiento, label: elemento.idMovimiento+" - "+elemento.po+" - "+elemento.contenedor, data: elemento });
                  }
                });
                setMovimientosSinGps (movSinGpsTem);   
            }
            if(stateVar==="gpss"){
              
              let listaGps = []
              
              let encontrado = false;
              returnVal.map((elemento, intex)=>{
                for (let item of gpsAgregado) {encontrado=elemento.id===item.id?true:encontrado}  
                if (!encontrado){listaGps.push({value:elemento.id, label: elemento.nombre})}
                
              })
              setGpsList (listaGps);
              
            }
            if(stateVar==="gpssFull"){
              
              let listaGps = []
              
              let encontrado = false;
              returnVal.map((elemento, intex)=>{
                for (let item of gpsAgregadosFull) {encontrado=elemento.id===item.id?true:encontrado}  
                if (!encontrado){listaGps.push({value:elemento.id, label: elemento.nombre})}
                
              })
              setGpsListFull (listaGps);
              
            }
          } 
            break;
        
        default:
          break;
      }
    })
  } 
  
  if (!gpsList[0]){
    
    if (gpsDisponible[0]){
      setGpsList(gpsDisponible);
    }else{
      setGpsList(["id=0"])
      
    }
    
  }
  
  if (!gpsListFull[0]){
    
    if (gpsDisponible[0]){
      setGpsListFull(gpsDisponible);
    }else{
      setGpsListFull(["id=0"])
      
    }
    
  }
  
  const changeEconomicor      = (event) => {movimientosPatios['economicor']         = event.target.value; setEconomicor         ( event.target.value );}
  const changeEconomicorF     = (event) => {movimientosPatiosFull['economicor']     = event.target.value; setEconomicorFull     ( event.target.value );}
  const changeplacaRemolque   = (event) => {movimientosPatios['remolquePlaca']      = event.target.value; setRemolquePlaca      ( event.target.value );}
  const changeplacaRemolqueF  = (event) => {movimientosPatiosFull['remolquePlaca']  = event.target.value; setRemolquePlacaFull  ( event.target.value );}
  const changeObservaciones   = (event) => {movimientosPatios['observaciones']      = event.target.value; setObservaciones      ( event.target.value );}
  const changeObservacionesF  = (event) => {movimientosPatiosFull['observaciones']  = event.target.value; setObservacionesFull  ( event.target.value );}
  const changeEconomicot      = (event) => {movimientosPatios['economicot']         = event.target.value; movimientosPatiosFull['economicot']       = event.target.value; setEconomicot       ( event.target.value );}
  const changeModeloTractor   = (event) => {movimientosPatios['tractorModelo']      = event.target.value; movimientosPatiosFull['tractorModelo']    = event.target.value; setTractorModelo    ( event.target.value );}
  const changemarcaTractor    = (event) => {movimientosPatios['tractorMarca']       = event.target.value; movimientosPatiosFull['tractorMarca']     = event.target.value; setTractorMarca     ( event.target.value );}
  const changecolorTractor    = (event) => {movimientosPatios['tractorColor']       = event.target.value; movimientosPatiosFull['tractorColor']     = event.target.value; setTractorColor     ( event.target.value );}
  const changeplacaTractor    = (event) => {movimientosPatios['tractorPlaca']       = event.target.value; movimientosPatiosFull['tractorPlaca']     = event.target.value; setTractorPlaca     ( event.target.value );}
  const changenombreOperador  = (event) => {movimientosPatios['operadorNombre']     = event.target.value; movimientosPatiosFull['operadorNombre']   = event.target.value; setOperadorNombre   ( event.target.value );}
  const changemedioContacto   = (event) => {movimientosPatios['operadorContacto']   = event.target.value; movimientosPatiosFull['operadorContacto'] = event.target.value; setOperadorContacto ( event.target.value );}
  const changelicencia        = (event) => {movimientosPatios['operadorLicencia']   = event.target.value; movimientosPatiosFull['operadorLicencia'] = event.target.value; setOperadorLicencia ( event.target.value );}
  const changeTipoPlataforma        = (event) => {movimientosPatios['idRemolque']         = event.data;         movimientosPatiosFull['idRemolque']       = event.data; setIdRemolque ( event ); setIdRemolqueFull(event)}
  const changeTipoGps               = (event) => setTipoGps     ( event );
  const changeTipoGpsFull           = (event) => setTipoGpsFull ( event );
  const changeMovimientoFull  = (event) => { if(nuevoFull){ setIdMovFull ( event );} }
  const changeGps             = (event) => { getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+event.value, "datosGps");     setIdGps     ( event ); };
  const changeGpsFull         = (event) => { getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+event.value, "datosGpsSGPS"); setIdGpsFull ( event ); };
  const changeEsFull          = (event) => { setEsFull ( event.target.checked)};
 
  const buscaGps= (event)=>{
    let texto=event.target.value;
    let tecla=event.key;
    
    let gpsListbu = gpsList;
    texto=tecla.length==1?texto+tecla:texto;
    texto=tecla=="Backspace"?texto.substring(0,texto.length-1):texto;
    
    
    if (texto){
        getfuntion("get", "", "", "gps/nombre/"+texto+"/pag/100", "gpss")
    } else {
      setGpsList(gpsListbu)
    }
    
  }
  const buscaGpsFull= (event)=>{
    let texto=event.target.value;
    let tecla=event.key;
    
    let gpsListbu = gpsListFull;
    texto=tecla.length==1?texto+tecla:texto;
    texto=tecla=="Backspace"?texto.substring(0,texto.length-1):texto;
    
    
    if (texto){
        getfuntion("get", "", "", "gps/nombre/"+texto+"/pag/100", "gpssFull")
    } else {
      setGpsListFull(gpsListbu)
    }
    
  }

  const agregarGpsaLista = ()=>{
    

    setGpsAgregado([...gpsAgregado, {id: idGps.value, nombre: idGps.label, tipoid: tipoGps.value, tipo: tipoGps.label, direccion: direccionGPS, nivelbateria: nivelBateria, ultimo_reporte: ultimoReporte} ]);
   
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    let gpsAgregadoTemp=[...gpsAgregado, {id: idGps.value, nombre: idGps.label, tipoid: tipoGps.value, tipo: tipoGps.label, direccion: direccionGPS, nivelbateria: nivelBateria} ];
    gpsDisponible.map((elemento, index) => {
        let found= false;
        
        for(let item of gpsAgregadoTemp){ if(elemento.value === item.id){ found = true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
   
    setGpsList ( gpsListTem );
  
    setTipoGps ( { value: 0, label:"Seleccione una opción" });
    setIdGps   ( { value: 0, label:"Seleccione un GPS" } );
  }
  

  const agregarGpsaListaFull = () => { 
    setGpsAgregadosFull ([...gpsAgregadosFull, {id: idGpsFull.value, nombre: idGpsFull.label, tipoid: tipoGpsFull.value, tipo: tipoGpsFull.label, direccion: direccionGPS, nivelbateria: nivelBateria, ultimo_reporte: ultimoReporte} ]);
    
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    let gpsAgregadoTemp= [...gpsAgregadosFull, {id: idGpsFull.value, nombre: idGpsFull.label, tipoid: tipoGpsFull.value, tipo: tipoGpsFull.label, direccion: direccionGPS, nivelbateria: nivelBateria, ultimo_reporte: ultimoReporte} ];
    gpsDisponible.map((elemento, index) => {
        let found= false;
        for(let item of gpsAgregadoTemp){ if(elemento.value === item.id){ found= true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
    setGpsListFull ( gpsListTem );
    setTipoGpsFull ( { value: 0, label:"Seleccione una opción" });
    setIdGpsFull   ( { value: 0, label:"Seleccione un GPS" });
  }
 
  
  const recuperarGPS = (gpsAgregadoRec) => { 
    
    var recGps={gps: gpsAgregadoRec.id , plataforma:16, estatus: 1}
    
    getfuntion("delete", recGps, "", "movimientoGps/"+gpsAgregadoRec.id_movGps, "gpsRecuperar").then((gpsEstatus) => {
     });
  }
  
  const agregarGPS = () => {
    if(disabledButon){ 
      
    }else{ setDisabledButon (true);
      let errores= false;
      
      if(idRemolque.value>0){
          if(gpsAgregado.length > 0 || gpsAgregadoOld !== gpsAgregado){
              if(idMovFull.value > 0){
                  if(gpsAgregadosFull.length>0 || gpsAgregadosFullOld !== gpsAgregadosFull){}else{ NotificationManager.warning("Se ha detectado que quiere hacer full con: "+idMovFull.label+" (pero, no ha completado los datos)", "Guardar" ); return;}
              }
              NotificationManager.info("Guardando datos ... ", "Guardar" );
              
              let movFull= idMovFull.value;
              if(idMovFull.value > 0 && esFull){
                  movimientosPatios['esFull'] = movFull;
                  setEsFull(movFull);
                  movimientosPatiosFull['idMovimiento']= movFull;
                  setIdMovFull(movFull);
                  movimientosPatiosFull['esFull'] = idMovPrimario;
                  setEsFullFull(idMovPrimario);
              } else { movimientosPatios['esFull'] = null; }            
              //if(idMovFull.value > 0){ movimientosPatiosFull['esFull'] = idMovPrimario; } else { movimientosPatiosFull['esFull'] = null; }            
              //movimientosPatiosFull['idMovimiento']=movFull;
              
              if(nuevo1 && !movimientosPatios.id){
                  
                  getfuntion("post", movimientosPatios, "", "movimientosPatio", "movimientosPatios").catch(err => {
                      errores= true;
                      NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                      return;
                  });                
              }
              if(!nuevo1 && movimientosPatios.id){
                  
                  getfuntion("put", movimientosPatios, "", "movimientosPatio", "movimientosPatios").catch(err => {
                      errores= true;
                      NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                      return;
                  });
              }
              
              
              gpsAgregadoOld.map((viejo, index)=>{
                
                let eliminar= true;
                  for(let nuevo of gpsAgregado){
                    
                    if(viejo.id == nuevo.id){
                          eliminar= false;
                          
                      }
                     
                  }
                  
                  if(eliminar){ 
                   
                    recuperarGPS(viejo); 
                  }
                 
              });
              contadorCarga = gpsAgregado.length;
              
              for(let item of gpsAgregado){
                  let actualizar = false;
                  let datoViejo = false;
                  for(let viejo of gpsAgregadoOld){ 
                    
                      if(viejo.id == item.id){
                        datoViejo= true
                        
                        if(viejo.tipoid !== item.tipoid){
                          actualizar= true;
                        }

                      };
                      
                      
                  }

                  movimientosGps.idMovimiento = idMovPrimario;
                  movimientosGps.gps.id       = item.id;
                  movimientosGps.gpsTipo.id   = item.tipoid;

                  gpsGG.gps          =   item.id;
                  gpsGG.plataforma   =   props.plataformaid;
                  gpsGG.estatus      =   2;
                  
                  if( (actualizar ||  !datoViejo) ){
                      /** Asignar Gps en GG */
                      
                             
                              getfuntion("post", movimientosGps, "", "movimientoGps/idUsuario/"+userId, "movimientoGp").then((movGps) => {
                                
                                usuariosLogObject.idBitacoraMonitoreo = 5;
                                  usuariosLogObject.idUsuario = userId;
                                  usuariosLogObject.idMovimiento = idMovPrimario;
                                  usuariosLogObject.idPlataformaTipo = 1;
                                  usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                                  
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", usuariosLogObject);
                                 
                                  NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                                  contadorCarga--;
                                  // if( contadorCarga === 0 ){ props.refrescar(); }
                              }).catch(err => {
                                  usuariosLogObject.idBitacoraMonitoreo = 5;
                                  usuariosLogObject.idUsuario = props.userId;
                                  usuariosLogObject.idMovimiento = idMovPrimario;
                                  usuariosLogObject.idPlataformaTipo = 1;
                                  usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  usuariosLogObject.descripcion = "Error al asignar gps: "+item.nombre+"("+item.id+")";
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", usuariosLogObject);
                                  console.log(`err`, err)
                                  errores= true;
                                  
                                  NotificationManager.error("Hubo un error al vincular el gps al movimiento", "Error" );
                              });
                          
                      
                  }
                
              }
              /**
               * Mov Full
               */
              
              if(idMovFull.value > 0){
                  if(nuevoFull && !movimientosPatiosFull.id){
                      
                      getfuntion("post", movimientosPatiosFull, "", "movimientosPatio", "movimientosPatios").catch(err => {
                          errores= true;
                          NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                          return;
                      });                
                  }
                  if(!nuevoFull && movimientosPatiosFull.id){
                      
                      getfuntion("put", movimientosPatiosFull, "", "movimientosPatio", "movimientosPatios").catch(err => {
                          errores= true;
                          NotificationManager.error("Error al guardar datos del tracto y del operador", "Error" );
                          return;
                      });
                  }
                  

                  gpsAgregadosFullOld.map((viejo, index)=>{
                      let eliminar= true;
                      for(let nuevo of gpsAgregadosFull){
                          if(viejo.id == nuevo.id){
                              eliminar= false;                      
                           
                          }
                      }
                      if(eliminar){ recuperarGPS(viejo); }
                  });
                  contadorCarga= contadorCarga + gpsAgregadosFull.length ;
                  for(let item of gpsAgregadosFull){
                      let actualizar= false;
                      let datoViejo= false;
                      for(let viejo of gpsAgregadosFullOld){ 
                          if(viejo.id == item.id){datoViejo= true;}
                          if(viejo.tipoid !== item.tipoid){
                              actualizar= true;
                          }
                      }
                      movimientosGpsFull.idMovimiento = idMovFull.value;
                      movimientosGpsFull.gps.id = item.id;
                      movimientosGpsFull.gpsTipo.id = item.tipoid;
                      if( (actualizar ||  !datoViejo)  ){                        
                          gpsGG.gps          =   item.id;
                          gpsGG.plataforma   =   props.plataformaid;
                          gpsGG.estatus      =   2;
                          let foundMov= false;
                          for(let item1 of gpsAgregado){ if(item1.id == item.id){ foundMov= true; } }
                          if(!foundMov){
                              /** Asignar Gps en GG */            
                              
                                  
                                  /** Asignar a movimientoGps */
                                  getfuntion("post", movimientosGpsFull, "", "movimientoGps/idUsuario/"+props.userId, "movimientoGp");
                                  /** Cambiar Estatus de gps  */
                                  getfuntion("get", [], "", "gps/idGps/"+item.id+"/idGpsEstatus/2");
                                  NotificationManager.info("Gps: "+item.nombre+" agregado", "Guardado" );
                                  contadorCarga--;
                                  
                                  usuariosLogObject.idBitacoraMonitoreo = 5;
                                  usuariosLogObject.idUsuario = props.userId;
                                  usuariosLogObject.idMovimiento = idMovFull.value;
                                  usuariosLogObject.idPlataformaTipo = 1;
                                  usuariosLogObject.accionFecha = timeToDateFill(new Date().getTime());
                                  usuariosLogObject.descripcion = "Asignacion de GPS: "+item.nombre+"("+item.id+")";
                                  Crud_Catalogos("usuariosLog", "nada", "post", "", usuariosLogObject);
                                  
                                  // if( contadorCarga === 0 ){ props.refrescar(); }

                                  
                                                        
                          }
                      }
                                            
                  }                
              }
          }else{ NotificationManager.warning("No se han agregado GPS'S", "Atención" ); }              
      }else{
          NotificationManager.warning("Tipo de plataforma requerido ..!", "Atención" );
      }
    }
    setTimeout(props.hiddenmodal,  1000*10);//1000*10
  }
  

  const eliminarGps = (index, idGps) => {

    let gpsAgregadoTemp = [...gpsAgregado];
    gpsAgregadoTemp.splice(index,1);
    
    setGpsAgregado (gpsAgregadoTemp);

    /**Libera lista de gps's */
    let gpsListTem= [];
    gpsDisponible.map((elemento, index) => {
        let found= false;
        for(let item of gpsAgregadoTemp){ if(elemento.value === item.id){ found= true;} }
        if(!found){ gpsListTem.push(elemento); }
    });
    
    setGpsList (gpsListTem);
    
    //getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+idGps, "datosGps");
  }
  
  const eliminarGpsSGPS = (index, idGpsSGPS) => {
    let gpsAgregadosFullTemp  = [...gpsAgregadosFull];
    gpsAgregadosFullTemp.splice(index, 1);
    
    setGpsAgregadosFull (gpsAgregadosFullTemp);
    /** Ocultar gps seleccionados */
    let gpsListTem= [];
    
    gpsDisponible.map((elemento, index) => {
        let found= false;
        for(let item of gpsAgregadosFullTemp){ if(elemento.value === item.id){ found= true;}  }
        if(!found){ gpsListTem.push(elemento); }
    });
    setGpsListFull ( gpsListTem );
    //getfuntion("get", [], "", "gpsestatus/plataforma/16/gps/"+idGpsSGPS, "datosGpsSGPS");
  }

  
    return (
        <div>
          <div class="row">
            <div className="col col-12 col-sm-12 col-md-12 ">
              { updateMSG?
                <div hidden={gpsGateEtiquetas.id}>
                  <Alert type="danger" icon="alert-octagon"> Pide a sistemas que configure la etiqueta plataforma en gpsgate, para poder asignar gps</Alert>
                </div>: null
              } 
            </div>
            <div className="col col-12 col-sm-12 col-md-12 "> {/*Datos de tractor */}
              <h3>TRACTO</h3>
              <div class="row">
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Modelo Tractor">     <Form.Input name="modeloTractor"  type="text" value={tractorModelo} onChange={changeModeloTractor}/>    </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Marca Tractor">      <Form.Input name="marcaTractor"  type="text"  value={tractorMarca} onChange={changemarcaTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Color Tractor">      <Form.Input name="colorTractor"  type="text"  value={tractorColor} onChange={changecolorTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Placa Tractor">      <Form.Input name="placaTractor"  type="text"  value={tractorPlaca} onChange={changeplacaTractor}/>      </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Tipo Plataforma(requerido)">     <Select value={idRemolque} onChange={changeTipoPlataforma} options={idRemolques} />  </Form.Group>
                </div>
                <div className="col col-12 col-sm-12 col-md-2 ">
                  <Form.Group label="Economico(Tracto)">     <Form.Input name="economicot"  type="text" value={economicot} onChange={changeEconomicot}/>    </Form.Group>
                </div>
              </div>
            </div>            
          </div>

          <hr style={ estilo } />

          <div class="row"> {/* Datos del operador */}
            <h3>OPERADOR</h3>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Nombre Operador">    <Form.Input name="nombreOperador"  type="text" value={operadorNombre} onChange={changenombreOperador}/>  </Form.Group>  
            </div>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Medio Contacto">     <Form.Input name="medioContacto"   type="text" value={operadorContacto} onChange={changemedioContacto}/>   </Form.Group>  
            </div>
            <div className="col col-12 col-sm-12 col-md-4 ">
              <Form.Group label="Licencia">           <Form.Input name="licencia"        type="text" value={operadorLicencia} onChange={changelicencia}/>        </Form.Group>   
            </div>
          </div>

          <hr style={ estilo } />

          <div class="row">{/** Asignacion de gps */}
            <Card title={<h3>GPS()</h3>} isCollapsible>
                <Card.Body>
                  <div class="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Placa Remolque">     <Form.Input name="placaRemolque"  type="text" value={remolquePlaca} onChange={changeplacaRemolque}/>    </Form.Group> 
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Economico (remolque)">     <Form.Input name="economicor"  type="text" value={economicor} onChange={changeEconomicor}/>    </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Obseraciones">           <Form.Textarea name="observaciones"        value={observaciones}     onChange={changeObservaciones}/>   </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="ID MOVIMIENTO - PO - CONTENEDOR">  <Form.Input name="movRoot"  type="text"  value={po1} />                </Form.Group>
                    </div>
                   
                    <div className="col col-12 col-sm-12 col-md-3 ">
                   
                      <Form.Group label="NOMBRE GPS">     <Select value={idGps} onChange={changeGps} onKeyDown={buscaGps} options={gpsList} />  </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="TIPO GPS"><Select value={tipoGps} onChange={changeTipoGps} options={gpsTipos} />              </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Button onClick={() => {agregarGpsaLista()}} pill color="primary" outline disabled={!idGps.value || !tipoGps.value}><i class="fa fa-plus" data-toggle="tooltip" data-placement="top" title="agregar GPS"></i></Button>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-12 ">
                      <Table  cards={true}    striped={true}  responsive={true}   className="table-vcenter">
                        <Table.Header align="center">
                          <Table.ColHeader>   ID                  </Table.ColHeader>
                          <Table.ColHeader>   NOMBRE GPS          </Table.ColHeader>
                          <Table.ColHeader>   TIPO GPS            </Table.ColHeader>
                          <Table.ColHeader>   DATOS GPS           </Table.ColHeader>
                          <Table.ColHeader>   NIVEL BATERIA GPS   </Table.ColHeader>
                          <Table.ColHeader>   ULTIMO REPORTE      </Table.ColHeader>
                          <Table.ColHeader>   BORRAR              </Table.ColHeader>                            
                        </Table.Header>
                        <Table.Body>
                          { gpsAgregado.map((gpsDis, index) =>
                            <Table.Row align="center">
                              <Table.Col> {gpsDis.id}        </Table.Col>
                              <Table.Col> {gpsDis.nombre}    </Table.Col>
                              <Table.Col> {gpsDis.tipo}      </Table.Col>
                              <Table.Col> {gpsDis.direccion} </Table.Col>
                              <Table.Col>
                                {gpsDis.nivelbateria <= "100" && gpsDis.nivelbateria >= "80" ? <Icon prefix="fa" name="battery-4" />: null}
                                {gpsDis.nivelbateria <= "79" && gpsDis.nivelbateria >= "55" ? <Icon prefix="fa" name="battery-3" />: null}
                                {gpsDis.nivelbateria <= "54" && gpsDis.nivelbateria >= "41" ? <Icon prefix="fa" name="battery-2" />: null}
                                {gpsDis.nivelbateria <= "40" && gpsDis.nivelbateria >= "25" ? <Icon prefix="fa" name="battery" />: null}
                                {gpsDis.nivelbateria >= "0" && gpsDis.nivelbateria <= "24"? <Icon prefix="fa" name="battery-0" />: null}
                                {gpsDis.nivelbateria} %
                              </Table.Col>
                              <Table.Col> {gpsDis.ultimo_reporte} </Table.Col>
                              <Table.Col> <Button onClick={() => eliminarGps(index, gpsDis.id)} color="danger">Borrar</Button>   </Table.Col>
                            </Table.Row>
                          )}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                  <div class="row">

                  </div>
                </Card.Body>
            </Card>
          </div>
          
          <hr style={ estilo } />
          
          <div class="row">{/**  Movimientos en full, debe de tomarse como una nueva asignacion*/}
            <Form.Switch type="Checkbox" label={esFull?"Movimento en full": "Colocar en full"} checked={esFull} onChange={ changeEsFull } />
            
            { esFull?
              <Card title={<h3>MOVIMIENTO FULL</h3>} isCollapsible>
                <Card.Body>
                  <div class="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Placa Remolque"> <Form.Input name="placaRemolqueF"  type="text"  value={remolquePlacaFull} onChange={changeplacaRemolqueF}/>    </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Economico (Remolque)">      <Form.Input name="economicorF"      type="text"  value={economicorFull} onChange={changeEconomicorF}/>    </Form.Group>  
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Obseraciones">   <Form.Textarea name="observacionesF"            value={observacionesFull}     onChange={changeObservacionesF}/>   </Form.Group>  
                    </div>


                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="ID MOVIMIENTO- PO -CONTENEDOR FULL"><Select value={idMovFull} onChange={changeMovimientoFull} options={ movimientosSinGps } /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="NOMBRE GPS FULL"><Select value={idGpsFull} onChange={changeGpsFull} onKeyDown={buscaGpsFull} options={gpsListFull} /></Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="TIPO GPS FULL"> <Select value={tipoGpsFull} onChange={changeTipoGpsFull} options={gpsTipos} /> </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Button onClick={() => {agregarGpsaListaFull()}} pill color="primary" outline disabled={!idGpsFull.value || !tipoGpsFull.value}><i class="fa fa-plus" data-toggle="tooltip" data-placement="top" title="agregar GPS"></i></Button>
                    </div>

                    <div className="col col-12 col-sm-12 col-md-12 ">
                      <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                        <Table.Header align="center">
                          <Table.ColHeader>   ID                  </Table.ColHeader>
                          <Table.ColHeader>   NOMBRE GPS          </Table.ColHeader>
                          <Table.ColHeader>   TIPO GPS            </Table.ColHeader>                            
                          <Table.ColHeader>   DATOS GPS           </Table.ColHeader>
                          <Table.ColHeader>   NIVEL BATERIA GPS   </Table.ColHeader>
                          <Table.ColHeader>   ULTIMO REPORTE      </Table.ColHeader>
                          <Table.ColHeader>   BORRAR              </Table.ColHeader>                            
                          </Table.Header>
                        <Table.Body>
                          { gpsAgregadosFull.map((gpsDis, index) =>
                            <Table.Row align="center">
                              <Table.Col> {gpsDis.id}         </Table.Col>
                              <Table.Col> {gpsDis.nombre}     </Table.Col>
                              <Table.Col> {gpsDis.tipo}       </Table.Col>
                              <Table.Col> {gpsDis.direccion}  </Table.Col>
                              <Table.Col>
                                {gpsDis.nivelbateria <= "100" && gpsDis.nivelbateria >= "80" ? <Icon prefix="fa" name="battery-4" />: null}
                                {gpsDis.nivelbateria <= "79" && gpsDis.nivelbateria >= "55" ? <Icon prefix="fa" name="battery-3" />: null}
                                {gpsDis.nivelbateria <= "54" && gpsDis.nivelbateria >= "41" ? <Icon prefix="fa" name="battery-2" />: null}
                                {gpsDis.nivelbateria <= "40" && gpsDis.nivelbateria >= "25" ? <Icon prefix="fa" name="battery" />: null}
                                {gpsDis.nivelbateria >= "0" && gpsDis.nivelbateria <= "24"? <Icon prefix="fa" name="battery-0" />: null}
                                {gpsDis.nivelbateria} %
                              </Table.Col>
                              <Table.Col> {gpsDis.ultimo_reporte}  </Table.Col>
                              <Table.Col>
                                <Button onClick={() => eliminarGpsSGPS(index, gpsDis.id)} color="danger">Borrar</Button>
                              </Table.Col>
                            </Table.Row>
                          )}                        
                        </Table.Body>
                      </Table> 
                    </div>
                  </div>
                </Card.Body>
            </Card>
            :null}
          
          </div>

          <Grid.Row>
            <Grid.Col md={6}>
                <Form.Group >
            
                </Form.Group>
            </Grid.Col>
            <Grid.Col md={6}>
                <Form.Group >
                <Button.List align="right">
                    <Button onClick={() => {agregarGPS()}} color="primary" disabled={disabledButon} >Guardar</Button>
                </Button.List>
                </Form.Group>
            </Grid.Col>
          </Grid.Row>


           
      </div>
    );
  
    

}

