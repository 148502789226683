//React
import React from "react";
// @flow
import { Error404Page } from "tabler-react";

function Error404(props) {
  return <Error404Page />;
}

export default Error404;
