import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import {EtaConfiguracionesForm}  from "../../Catalogos";

function EtaConfiguracionesPage() {
  return (
    <SiteWrapper>
        <Grid.Col sm={12} lg={12}> 
        <Catalogo Ctlogo="etaConfiguracion" tamanio="45%" FCtlogo= { EtaConfiguracionesForm } catalogos= {{ 0: "etaConfiguracion"}}/>
        </Grid.Col>
    </SiteWrapper>
  );
}
export default EtaConfiguracionesPage;