import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { TransportistasForm } from "../..";
import Unidades from "./Navieras/Unidades";
import ImportarUnidades from "./Navieras/ImportarUnidades";
import { Exportar, Plantilla } from "../../../herramientas/Excel";

/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";

class Transportista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      transportistas: [],
      transportistasDisponibles: [],
      selectedRow: -1,
      hiddenModals: false,
      search: "",
      pagination: [],
      pagina: 1,
      pagesize: 10,
      agregar: false,
      unidades: [],
    };
    this.colum = [];
    this.pagination = [];
    this.content = [];
    this.transportistasPagination = [];
    this.transportistasDisponiblesPagination = [];
  }

  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.plataformasObject = this.props.elementos;
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize,
        "transportistas"
      );
    } else {
    }
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "transportistas",
    stateVar = "transportistas",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "transportistas") {
                if (returnVal.content) {
                  this.setState({ transportistas: returnVal.content });
                  this.paginacion(returnVal);
                  this.transportistasPagination = returnVal;
                }
              }
              if (stateVar === "Unidades" && returnVal) {
                this.setState({ unidades: returnVal });
              }
              if (stateVar === "transportistasDisponibles") {
                if (returnVal.content) {
                  this.setState({
                    transportistasDisponibles: returnVal.content,
                  });
                  this.paginacion(returnVal);
                  this.transportistasDisponiblesPagination = returnVal;
                }
              }
            }
            break;
          case "post":
            {
              if (stateVar === "transportistas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se ha agregado el transportista al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  let transportistasTem = this.state.transportistas;
                  transportistasTem.unshift(returnVal);
                  this.setState({ transportistas: transportistasTem });
                  this.getfuntion(
                    "post",
                    {
                      plataforma: this.props.elementos,
                      transportista: returnVal,
                    },
                    "",
                    "plataformasTransportistas",
                    "plataformasTransportistas"
                  );
                }
              }
              if (stateVar === "plataformasTransportistas") {
                if (returnVal.plataforma) {
                  NotificationManager.info(
                    "El transportista ahora pertenece a la plataforma( " +
                      this.props.elementos.nombre +
                      " )",
                    "Nuevo"
                  );

                  if (this.state.agregar) {
                    let transportistasTem =
                      this.state.transportistasDisponibles;
                    transportistasTem.map((item, index) => {
                      if (item.id == returnVal.transportista.id) {
                        transportistasTem.splice(index, 1);
                        let transportistaTem = this.state.transportistas;
                        transportistaTem.unshift(item);
                        this.setState({ transportistas: transportistaTem });
                      }
                    });
                    this.setState({
                      transportistasDisponibles: transportistasTem,
                    });
                  }
                  this.setState({ search: "" });
                  this.setState({ agregar: false });
                }
                this.hiddenmodal();
              }
            }
            break;
          case "put":
            {
              if (stateVar === "transportistas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se han agregado las modificaciones del transportista al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Modificación"
                  );
                  let transportistasTem = this.state.transportistas;
                  for (let item of transportistasTem) {
                    if (item.id == returnVal.id) {
                      item = returnVal;
                    }
                  }
                  this.setState({ transportistas: transportistasTem });
                  this.hiddenmodal();
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "transportistas") {
                if (returnVal) {
                  //Modificar
                  //this.getfuntion("delete", { "plataforma": this.props.elementos, "transportista": returnVal }, this.props.elementos.id+"/"+id, "plataformasClientes", "plataformasClientes")
                }
              }
              if (stateVar === "plataformasTransportistas") {
                if (returnVal) {
                  let transportistasTem = this.state.transportistas;
                  transportistasTem.map((item, index) => {
                    if (item.id == obj.transportista) {
                      transportistasTem.splice(index, 1);
                      this.setState({ transportistas: transportistasTem });
                    }
                  });
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
    this.setState({ metodo: "get" });
  };
  table_headers = () => {
    this.content = [];
    let tra = this.state.agregar
      ? this.state.transportistasDisponibles
      : this.state.transportistas;
    if (tra) {
      if (tra[0]) {
        Object.keys(tra[0]).forEach((key) =>
          this.content.push({ content: key })
        );
        this.content.push({
          content: this.state.agregar ? "agregar" : "editar",
        });
        if (!this.state.agregar) {
          this.content.push({ content: "Unidades" });
          this.content.push({ content: "eliminar" });
        }
      }
    }
  };
  paginacion = (pag) => {
    this.pagination = pag;
    this.pagination.content = [];
    this.setState({ pagination: this.pagination });
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
    if (
      this.state.transportistas
        .filter((word) =>
          word.nombre.toUpperCase().includes(event.target.value.toUpperCase())
        )
        .sort().length == 0 &&
      !event.target.value.toUpperCase().includes("/")
    ) {
      this.paginarNombre(event.target.value);
    }
  };

  paginarNombre = (nombre) => {
    if (this.state.agregar) {
      //http://localhost:8000/transportistas/disponibles/plataforma/61/transportista/ALMER/pagina/0/total/100
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/disponibles/plataforma/" +
          this.props.elementos.id +
          "/transportista/" +
          nombre +
          "/pagina/0/total/" +
          this.state.pagesize,
        "transportistasDisponibles"
      );
    } else {
      //http://localhost:8000/transportistas/plataforma/61/transportista/AF/pagina/0/total/100
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/plataforma/" +
          this.props.elementos.id +
          "/transportista/" +
          nombre +
          "/pagina/0/total/" +
          this.state.pagesize,
        "transportistas"
      );
    }
  };
  TransportistasDisponibles = () => {
    this.setState({ search: "" });
    this.setState({ agregar: true });
    this.setState({ pagina: 1 });
    if (!this.state.transportistasDisponibles[0]) {
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/disponibles/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize,
        "transportistasDisponibles"
      );
    }
    this.paginacion(this.transportistasDisponiblesPagination);
  };
  cancelar = () => {
    this.setState({ search: "" });
    this.setState({ agregar: false });
    this.setState({ pagina: 1 });
    this.paginacion(this.transportistasPagination);
  };
  _editCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <ModalLay
          tamanio={"90%"}
          title="Modificar"
          name={
            <Button pill size="sm" outline color="primary">
              <Icon name="edit" />
            </Button>
          }
          formulario={TransportistasForm}
          datosForm={{
            accion: "modificar",
            plataforma: this.props.elementos,
            getfuntion: this.getfuntion,
            stor: this.props.stor,
            elementos: elemento,
          }}
        />
      </Table.Col>
    );
    if (this.props.plataforma) {
      if (
        this.props.plataforma.plataformaTipo.nombre
          .toUpperCase()
          .includes("TERRESTRE NAVIERA")
      ) {
        this.colum.push(
          <Table.Col>
            <ModalLay
              tamanio={"98%"}
              title="Unidades"
              name={
                <Button pill size="sm" outline color="primary">
                  <Icon name="truck" />
                </Button>
              }
              formulario={Unidades}
              datosForm={{
                accion: "nuevo",
                elementos: this.props.elementos,
                idTransportista: elemento,
                stor: this.props.stor,
                Unidades: [],
                unidadTerNav: true,
                getfuntion: this.getfuntion,
              }}
            />
          </Table.Col>
        );
      }
    }
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Remover"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "delete",
                {
                  plataforma: this.props.elementos.id,
                  transportista: elemento.id,
                },
                this.props.elementos.id + "/" + elemento.id,
                "plataformasTransportistas",
                "plataformasTransportistas"
              )
            }
          >
            <Icon name="trash" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  _addCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Agregar"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "post",
                { plataforma: this.props.elementos, transportista: elemento },
                "",
                "plataformasTransportistas",
                "plataformasTransportistas"
              )
            }
          >
            <Icon name="plus" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  render() {
    return (
      <div>
        {/**
         *   Elementos de plataformas
         */}
        {this.state.accion === "modificar" ? (
          <div>
            <Grid.Row>
              <Grid.Col>
                {!this.state.agregar ? (
                  <Card
                    title="La plataforma esta configurada con los siguientes transportistas"
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div>
                        <div
                          hidden={
                            this.props.elementos
                              ? this.props.elementos.plataformaTipo.id !== 3
                                ? true
                                : false
                              : true
                          }
                        >
                          <span
                            className="float-right ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Carga masiva de Unidades"}
                          >
                            <ImportarUnidades
                              idPlataforma={this.props.elementos}
                              stor={this.props.stor}
                            />
                          </span>
                          <span
                            className="float-right ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Plantilla de unidades"}
                          >
                            <Plantilla
                              crud={this.getfuntion}
                              catalogo="UnidadesTerrestreNaviera"
                            />
                          </span>
                          {this.state.unidades.length > 0 ? (
                            <span
                              className="float-right ml-2"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={"Exportar unidades a excel"}
                            >
                              <Exportar
                                crud={this.getfuntion}
                                catalogo={
                                  "Unidades(" +
                                  (this.props.elementos
                                    ? this.props.elementos.nombre
                                    : "") +
                                  ")"
                                }
                                data={this.state.unidades}
                              />
                            </span>
                          ) : null}
                          <span
                            className="float-right ml-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Descargar Unidades"}
                          >
                            <Button
                              pill
                              size="sm"
                              outline
                              color="primary"
                              onClick={() =>
                                this.getfuntion(
                                  "get",
                                  "",
                                  "",
                                  "unidadTerNav/idPlataforma/" +
                                    this.props.elementos.id,
                                  "Unidades"
                                )
                              }
                            >
                              <Icon name="download" />
                            </Button>
                          </span>
                        </div>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Nuevo transportista"}
                        >
                          <Button
                            pill
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => this.TransportistasDisponibles()}
                          >
                            <Icon name="plus" />
                          </Button>
                        </span>
                        <span className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar transportista..."
                            position="append"
                            value={this.state.search}
                            onChange={this.searching}
                            onKeyDown={this._searchingkey}
                          />
                        </span>
                      </div>
                    }
                    body={
                      <div>
                        {this.state.transportistas ? (
                          <Form.Group>
                            <Table
                              cards={true}
                              striped={true}
                              responsive={true}
                              className="table-vcenter"
                              {...this.table_headers()}
                              headerItems={this.content}
                            >
                              <Table.Body>
                                {this.state.transportistas
                                  .filter((word) =>
                                    word.nombre
                                      .toUpperCase()
                                      .includes(this.state.search.toUpperCase())
                                  )
                                  .sort()
                                  .map((elemento, index) => (
                                    <Table.Row
                                      style={
                                        this.state.selectedRow === index
                                          ? {
                                              "background-color":
                                                "rgba(95, 231, 50, 0.30)",
                                            }
                                          : null
                                      }
                                      onClick={() => {
                                        this.setState({ selectedRow: index });
                                      }}
                                    >
                                      {(this.colum = [])}
                                      {
                                        (Object.keys(elemento).forEach(
                                          (key, index) => {
                                            this.colum.push(
                                              <Table.Col>
                                                {" "}
                                                {elemento[key]}{" "}
                                              </Table.Col>
                                            );
                                          }
                                        ),
                                        this._editCol(elemento))
                                      }
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        ) : null}
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-4">
                            <nav aria-label="...">
                              <ul class="pagination">
                                <li className="page-item">
                                  <span
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number > 0
                                          ? this.paginar(
                                              this.state.pagination.number - 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Anterior
                                  </span>
                                </li>
                                {this.getpaginationLinks()}
                                <li class="page-item">
                                  <a
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number <
                                          this.state.pagination.totalPages
                                          ? this.paginar(
                                              this.state.pagination.number + 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Siguiente
                                  </a>
                                </li>
                                <li class="page-item">
                                  <h3>
                                    <div className="row">
                                      <div className="col-4 text-right">
                                        {" "}
                                        <Form.Input
                                          name="pag212"
                                          type="number"
                                          value={this.state.pagina}
                                          onKeyDown={this._buscar}
                                          onChange={this._pag}
                                        />{" "}
                                      </div>
                                      <div className="col-8 text-left">
                                        {" "}
                                        /
                                        {this.state.pagination
                                          ? this.state.pagination.totalPages
                                            ? this.state.pagination.totalPages
                                            : 1
                                          : 0}
                                        Páginas{" "}
                                      </div>
                                    </div>
                                  </h3>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-6 text-right">
                                {" "}
                                Total por página{" "}
                              </div>
                              <div className="col-6">
                                {" "}
                                <Form.Input
                                  name="pagTotal"
                                  type="number"
                                  value={this.state.pagesize}
                                  onChange={this._pagesize}
                                  onKeyDown={this._changePagesize}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <Card
                    title="Acontinuación se muestra del catalogo general los transportistas que puede utilizar(Si no se encuentra agregar uno nuevo)"
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Cancelar"}
                        >
                          <Button
                            pill
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => this.cancelar()}
                          >
                            <Icon name="x" />
                          </Button>
                        </span>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Crear nuevo transportista"}
                        >
                          <ModalLay
                            tamanio={"70%"}
                            title="Nuevo"
                            name={
                              <Button pill size="sm" outline color="primary">
                                <Icon name="plus" />
                              </Button>
                            }
                            formulario={TransportistasForm}
                            datosForm={{
                              accion: "nuevo",
                              getfuntion: this.getfuntion,
                              stor: this.props.stor,
                            }}
                          />
                        </span>
                        <span className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar Transportista..."
                            value={this.state.search}
                            position="append"
                            onChange={this.searching}
                            onKeyDown={this._searchingkey}
                          />
                        </span>
                      </div>
                    }
                    body={
                      <div>
                        <Form.Group>
                          <Grid.Row>
                            <Grid.Col>
                              <Dimmer
                                active={
                                  !this.state.transportistasDisponibles[0]
                                }
                                loader
                              ></Dimmer>
                            </Grid.Col>
                          </Grid.Row>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                            {...this.table_headers()}
                            headerItems={this.content}
                          >
                            <Table.Body>
                              {this.state.transportistasDisponibles
                                .filter((word) =>
                                  word.nombre
                                    .toUpperCase()
                                    .includes(this.state.search.toUpperCase())
                                )
                                .sort()
                                .map((elemento, index) => (
                                  <Table.Row
                                    style={
                                      this.state.selectedRow === index
                                        ? {
                                            "background-color":
                                              "rgba(95, 231, 50, 0.30)",
                                          }
                                        : null
                                    }
                                    onClick={() => {
                                      this.setState({ selectedRow: index });
                                    }}
                                  >
                                    {(this.colum = [])}
                                    {
                                      (Object.keys(elemento).forEach(
                                        (key, index) => {
                                          this.colum.push(
                                            <Table.Col>
                                              {" "}
                                              {elemento[key]}{" "}
                                            </Table.Col>
                                          );
                                        }
                                      ),
                                      this._addCol(elemento))
                                    }
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-4">
                            <nav aria-label="...">
                              <ul class="pagination">
                                <li className="page-item">
                                  <span
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number > 0
                                          ? this.paginar(
                                              this.state.pagination.number - 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Anterior
                                  </span>
                                </li>
                                {this.getpaginationLinks()}
                                <li class="page-item">
                                  <a
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number <
                                          this.state.pagination.totalPages
                                          ? this.paginar(
                                              this.state.pagination.number + 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Siguiente
                                  </a>
                                </li>
                                <li class="page-item">
                                  <h3>
                                    <div className="row">
                                      <div className="col-4 text-right">
                                        {" "}
                                        <Form.Input
                                          name="pag212"
                                          type="number"
                                          value={this.state.pagina}
                                          onKeyDown={this._buscar}
                                          onChange={this._pag}
                                        />{" "}
                                      </div>
                                      <div className="col-8 text-left">
                                        {" "}
                                        /
                                        {this.state.pagination
                                          ? this.state.pagination.totalPages
                                            ? this.state.pagination.totalPages
                                            : 1
                                          : 0}
                                        Páginas{" "}
                                      </div>
                                    </div>
                                  </h3>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-6 text-right">
                                {" "}
                                Total por página{" "}
                              </div>
                              <div className="col-6">
                                {" "}
                                <Form.Input
                                  name="pagTotal"
                                  type="number"
                                  value={this.state.pagesize}
                                  onChange={this._pagesize}
                                  onKeyDown={this._changePagesize}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
              </Grid.Col>
            </Grid.Row>
          </div>
        ) : null}
      </div>
    );
  }
  paginar = (pag) => {
    if (this.state.agregar) {
      if (this.state.search.length > 0) {
        this.getfuntion(
          "get",
          "",
          "",
          "transportistas/disponibles/plataforma/" +
            this.props.elementos.id +
            "/transportista/" +
            this.state.search +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "transportistasDisponibles"
        );
      } else {
        this.getfuntion(
          "get",
          "",
          "",
          "transportistas/disponibles/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "transportistasDisponibles"
        );
      }
      this.setState({ pagina: pag + 1 });
    } else {
      if (this.state.search.length > 0) {
        this.getfuntion(
          "get",
          "",
          "",
          "transportistas/plataforma/" +
            this.props.elementos.id +
            "/transportista/" +
            this.state.search +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "transportistas"
        );
      } else {
        this.getfuntion(
          "get",
          "",
          "",
          "transportistas/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "transportistas"
        );
      }
      this.setState({ pagina: pag + 1 });
    }
  };
  getpaginationLinks = () => {
    var links = [];
    var numberPag = this.pagination.number;
    if (this.pagination) {
      for (let i = numberPag; i < numberPag + 5; i++) {
        if (i === numberPag) {
          links.push(
            <li class="page-item active" aria-current="page">
              {" "}
              <span class="page-link">
                {" "}
                {i + 1} <span class="sr-only">(current)</span>{" "}
              </span>{" "}
            </li>
          );
        } else {
          if (i < this.pagination.totalPages) {
            links.push(
              <li class="page-item">
                <a class="page-link" onClick={() => this.paginar(i)}>
                  {i + 1}
                </a>
              </li>
            );
          }
        }
      }
    }
    return links;
  };
  _changePagesize = (e) => {
    if (e.key === "Enter") {
      this.paginar(0);
    }
  };
  _searchingkey = (e) => {
    if (e.key === "Enter") {
      if (e.target.value == 0) {
        this.paginar(0);
      }
    }
  };
  _pagesize = (e) => this.setState({ pagesize: e.target.value });
  _buscar = (e) => {
    if (e.key === "Enter") {
      if (this.pagination.totalPages) {
        this.paginar(e.target.value - 1);
      }
    }
  };
  _pag = (event) => {
    this.setState({
      pagina:
        event.target.value > this.pagination.totalPages
          ? this.pagination.totalPages
          : this.pagination.totalPages
          ? event.target.value
          : 1,
    });
  };
}

export default Transportista;
