import * as conf from "../../config/config";
import axios from 'axios';
//import $ from "jquery";
var user= JSON.parse(sessionStorage.getItem("usuario"));
var cat= {};
export function AlertasTAll(movimiento){
    var tokenStore="tok";
    let instance = axios.create();
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);

    return axios.get(conf.api_raiz+"plataformasAlertas/usuario/"+user.id).then(categorias => {
      if( categorias.data ){
        cat= {};
        categorias.data.map((c, index) =>{
          if( c.alertaCategoria ){
            if( cat[c.plataforma] ){
              if( cat[c.plataforma][c.alertaTipo.id] ){
                cat[c.plataforma][c.alertaTipo.id]= c;
              }else{ cat[c.plataforma][c.alertaTipo.id]= c; }
            }else{
              cat[c.plataforma]={};
              cat[c.plataforma][c.alertaTipo.id]= c;
            }
          }
        });
      }

      return axios.get(conf.api_raiz+"terrestresAlertas/movimiento/"+movimiento.id).then(res => {
        if(res.data){
          var alertas= {};
          res.data.map((alerta, index) =>{
              if( movimiento.idPlataforma ){
                if( cat[movimiento.idPlataforma.id] ){
                  if(alerta.idAlertaTipo){
                    if(cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo ] ){
                      if( cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ){
                        if( alertas[cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo] ){
                          alertas[cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({
                            alert: alerta,
                            color: cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                            tipo: cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre
                          });
                        }else{
                          alertas[cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo]= [];
                          alertas[cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({
                            alert: alerta,
                            color: cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                            tipo:  cat[ movimiento.idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre
                          });
                        }
                      }
                    }else{
                      if(!alertas["fa-bell"] ){
                        alertas["fa-bell"]= [];
                      }
                      alertas["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido" });
                    }
                  }
                }else{
                  if(!alertas["fa-bell"] ){
                        alertas["fa-bell"]= [];
                      }
                  alertas["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido" });
                }
              }else{
                if(!alertas["fa-bell"] ){
                  alertas["fa-bell"]= [];
                }
                alertas["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido" });

              }
          });
          return alertas;
        }
      });
      }).catch(errors => console.log(errors));

}

  export default AlertasTAll;
