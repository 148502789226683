import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { Dimmer } from "tabler-react";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Tab, Tabs, TabList, TabPanel } from './tabs-generic/index';
import TablaCatalogo from './tabla-generic/index';

var     id	=0;
var terrores=0;
let sinError=0;
var dia			=0;
var mes			=0;
var anio		=0;
var hora 		=0;
var minuto	=0;
var segundo =0;
var personasvar="";
var clientevar="";
var clientesContactos="";
var fechas	=0;
var horas		=0;
var arrayErr=[];
let Errores	=[];
let FilaErr	=[];
let Cuadros =[];
let TabTemp =[];
let TabPanelTemp=[];

const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';
const fhora			= 'HH:mm:ss';
const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31]
const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31]

function myModalN(Muestra) {
  var x = document.getElementById("xlsModalCC");
  x.style.display = Muestra;
}
function bisiesto(year){	return year % 4 == 0 && year % 100 != 0 || year % 400 == 0; }
function pad(number) {  if (number < 10) { return '0' + number; }  return number; }
function myFormato(date) {
function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
function serieMes(numero, año){
  var cuenta 		=0;	var siguiente	=0;	var index			=0;  cuenta=numero;
  do {
		if(bisiesto(año)){
			siguiente=sbiss[index+1];
			cuenta=cuenta-sbiss[index];
			dia=cuenta;
		}else{
			siguiente=nobis[index+1];
			cuenta=cuenta-nobis[index];
			dia=cuenta;
		}
		index=index+1;
  }	while (cuenta>siguiente);
	mes=index+1;
	dia=cuenta-1;
}
function serieAnio(valor){
  var año   =1900;  var sumadias=0;  var residuo =0;
  do {
    if(bisiesto(año)){
      año = año +1;
      sumadias = sumadias + 366;
    }else{
      año = año +1;
      sumadias = sumadias + 365;
    }
  }while ((sumadias+365)<valor)
  residuo=valor-sumadias;
	anio=año;
  serieMes(residuo, año);
}
function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);
}
var user = JSON.parse(sessionStorage.getItem("usuario"));
class ImportarCC extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",
                 cliente:this.props.cliente                 |"",

  //Tabla Movimientos
               idPersona:this.props.idPersona               |[],
               idCliente:this.props.idCliente               |[],

//Variables
                  accion:this.props.accion,
                validado:this.props.validado                |false,

//Variables de Datos
									  data:this.props.data										|[],
						 dataCliente:this.props.dataCliente					    |[],
						 dataPersona:this.props.dataPersona 						|[],
       nombreTemporalTab:this.props.nombreTemporalTab       |"",
    };
    this.cita=0;
    this.cadenadeCarga="";
    this.usuariosLogObject = {
      "idBitacoraMonitoreo":0,
      "idUsuario":0,
      "idMovimiento":0,
      "accionFecha":"",
      "descripcion":"",
      "plataformaTipo":2
    };
  }
  componentDidMount(){
		myModalN("none");
  }
  componentWillMount(){
    switch (this.props.catalogo) {
      case "clientesContactos":
        break;
      default:
        //console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }
  }

//Fin Tabla Movimientos
valObjeto(ObjValidar, j){
  console.log("333 this.state.data");
  console.log(this.state.data);
  //https://one.skynavieros.com:8080/skyone/validacionDatos/clientesContactos
  //{ "persona":{"id":0,"nombre":"Gerardo","aPaterno":"Altamirano","aMaterno":"Perez","correo":"gerardo.villarreal@skyangel.com.mx"}, "cliente":{"id":0,"nombre":"49 CALLE 18-40, ZONA 12 CIUDAD DE GUATEMALA"} }
  //Crud_Catalogos("validacionDatos/clientesContactos",   "", "post", "", objeto,"", "", "", [], "", "", "").then((returnVal) => {
  Crud_Catalogos("validacionDatos/clientesContactos",   "", "post", "", ObjValidar,"", "", "", [], "", "", "").then( returnVal => {
    console.log("339 returnVal");
    console.log(returnVal);
    //this.state.data[j] =null;
    this.state.data[j].id = 0;
    this.state.data[j].idPersona = returnVal.persona;
    this.state.data[j].idCliente = returnVal.cliente;

    let temppersona=returnVal.persona;//this.state.data[j].idPersona;
    if( temppersona === "" || temppersona === null || temppersona === undefined ){
        terrores=terrores+1;

        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="(N) La persona No Existe! en la BD, Verifique en Excel la Fila: " + (j+1);
        NotificationManager.warning("(N) La persona No Existe! en la BD, Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"(N) La persona No Existe! "+ObjValidar.persona.nombre + " "+ObjValidar.persona.aPaterno+" "+ObjValidar.persona.aMaterno+" en la BD, Verifique en Excel la Fila: " + (j+1) }</div> );
        var elem = document.getElementById("FilaclientesContactos"+j);
        console.log("353 FilaclientesContactos" + j);
        elem.style.backgroundColor = "Red";

    }

    let tempcliente=returnVal.cliente;//this.state.data[j].idCliente;
    if( tempcliente === "" || tempcliente === null || tempcliente === undefined ){
        terrores=terrores+1;
        this.state.data[j].idCliente = ObjValidar.cliente;
        FilaErr[j]="Error en la Fila " + (j);
        arrayErr[terrores]="(N) El Cliente NO existe " + ", Verifique en Excel la Fila: " + (j+1);
        NotificationManager.warning("(N) El Cliente NO existe " + ", Verifique en Excel la Fila: " + (j+1) );
        Errores.push( <div>{"(N) El Cliente NO Existe " + ObjValidar.cliente.nombre + ", Verifique en Excel la Fila: " + (j+1) }</div> );
        var elem = document.getElementById("FilaclientesContactos"+j);
        console.log("374 FilaclientesContactos" + j);
        elem.style.backgroundColor = "Red";

    }

  }).catch(err =>{
    console.log("343 Error de validacionDatos: "+ err);

    }
  );
}
valYaExiste(Catalogo, Po, I, Campo){
        let iDs = "";
        Crud_Catalogos("navieraMovimientos/po/"+Po,"", "get",  "", "",     "" , "",     "", []).then((returnVal) => {
          returnVal.map(ids => {
            iDs= "id: " + ids.id
            //console.log(ids.id);
            terrores=terrores+1;
          });
        }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  	    console.log("No Existe el Catalogo Definido");
}

validaArchivo() {
    //console.log("Inicia Validacion de Archivo ");
    switch (this.props.catalogo) {
      case 'clientesContactos':
        var j = 0;
        console.log("391 this.state.data");
        console.log(this.state.data);
        this.state.data.map( datos =>
          {
            console.log("401 datos: ");
            console.log(datos);
            var persona         = {"id":"", "nombre":"", "aPaterno":"", "aMaterno":"", "correo":"" };
            var cliente         = {"id":"", "nombre":"" };
            //var cliente         = this.props.cliente;
            if (this.props.cliente){
              console.log("404 Desde No Catalogo");
              console.log(this.props.cliente);
              cliente         = this.props.cliente;
            }
            var objeto          = { persona, cliente };
            Object.keys(datos).forEach( num =>{
                  switch (num) {//Inicia Switch num
                    case 'idPersona':
                      //if (Number.isInteger(datos[num])){
                      //  if (Number.isInteger(datos[num])>0){
                      //    persona.id=datos[num];
                      //  }else{
                      //    persona.id=0;
                      //  }
                      //}
                      break;
                    case 'nombre':
                      persona.nombre=datos[num];
                      break;
                    case 'aPaterno':
                      persona.aPaterno=datos[num];
                      break;
                    case 'aMaterno':
                      persona.aMaterno=datos[num];
                      break;
                    case 'correo':
                      persona.correo=datos[num];
                      break;
                    case 'telefono':
                      persona.telefono=datos[num];
                      break;
                    case 'idCliente':
                      if (Number.isInteger(datos[num])){
                        cliente.id=datos[num];
                      }else{
                        cliente.nombre=datos[num];
                      }
                      break;
                    default:
                      //cliente = this.props.cliente;
                    break;
                  }//Fin Switch num
                },
            );
            this.valObjeto(objeto, j);
  					var temporal="";
            j=j+1;
        });
				//  ******************* Aquí iba el codigo para Guardar los Datos  *********************** Ahora es en cargarDatos
				//terrores=terrores+1;
				Errores.push( <div id={j}>{"Archivo Validado!"}</div> );
				break;//Fin Case Movimientos
      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para este Catalogo";
        break;
    }
    //this.setState({validado:true });
    //console.log(this.state.data);
		//console.log("Fin Validacion de Archivo: "+ terrores);
  }
clickTab(nombreTab){
  console.log("468 this.state.data");
  console.log(this.state.data);
  this.setState({nombreTemporalTab: nombreTab });
  this.cadenadeCarga= nombreTab;
  console.log("472 this.state.dataCliente: ");
  console.log(this.state.dataCliente);
  console.log("474 this.state.dataPersona: ");
  console.log(this.state.dataPersona);
}

	cargarDatos = (carga, errores) => {
		console.log("477 Cargar Datos Correctos e:" + errores);
    console.log("478 " + this.cadenadeCarga);
    let w=0;
    let errResponse=[];
    switch (this.cadenadeCarga) {
      case "personas":
            console.log("486 this.state.dataPersona");
            console.log(this.state.dataPersona);
            if (this.cadenadeCarga===null){
              this.cadenadeCarga="personas";
              console.log("490 personas");
            }
            for (let i = 0; i < this.state.dataPersona.length; i++) {
              let nDe = 0;
              let nEn = 0;
              console.log("495 this.state.dataPersona["+i+"]");
              console.log(this.state.dataPersona[i]);

              Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataPersona[i],"" , "",     "", []).then((returnVal) => {
                nEn = i+1;
                nDe = this.state.dataPersona.length;
                if (returnVal.id>0){
                  //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
                  this.state.dataPersona[i]=returnVal;

                  console.log("505 this.state.dataPersona["+i+"]");
                  console.log(this.state.dataPersona[i]);
                  var psnaNombre = "";
                  var psnaPaterno = "";
                  var psnaMaterno = "";

                  console.log("513 El id de Persona es: "+returnVal.id);
                  psnaNombre=this.state.dataPersona[i].nombre;
                  psnaPaterno=this.state.dataPersona[i].aPaterno;
                  psnaMaterno=this.state.dataPersona[i].aMaterno;

                  //Refresca Colores en la Tabla Principal "clientesContactos"
                  carga.map( (dato, j) => {
                    if (dato.nombre==psnaNombre){
                      if (dato.aPaterno==psnaPaterno){
                        if (dato.aMaterno==psnaMaterno){
                          console.log("536 La persona en clientesContactos es la: " + j);
                          dato.idPersona = returnVal;
                          var filaenTabla = document.getElementById("FilaclientesContactos"+j);
                          console.log("539 FilaclientesContactos" + j);
                          filaenTabla.style.backgroundColor = "#ffffff";
                        }
                      }
                    }
                  });
                  var filaenPersona = document.getElementById("Filapersonas"+i);
                  console.log("537 Filapersonas" + i);
                  filaenPersona.style.backgroundColor = "green";
                }
              }).catch(err =>{
                console.log("536 Error: " + err);
                var filaenPersona = document.getElementById("Filapersonas"+i);
                console.log("538 Filapersonas" + i);
                filaenPersona.style.backgroundColor = "orange";
              });

            }
        break;
      case "cliente":
        console.log("550 cliente");
        console.log(this.state.dataCliente);
        if (this.cadenadeCarga===null){
          this.cadenadeCarga="cliente";
          console.log("550 cliente");
        }
        for (let i = 0; i < this.state.dataCliente.length; i++) {
          let nDe = 0;
          let nEn = 0;
          console.log("dataCliente");
          console.log(this.state.dataCliente[i]);
          Crud_Catalogos(this.cadenadeCarga, this.cadenadeCarga, "post", "", this.state.dataCliente[i],"" , "",     "", []).then((returnVal) => {
            nEn = i+1;
            nDe = this.state.dataCliente.length;
            if (returnVal.id>0){
              //Asigna el id registrado en la BD al JSON de Carga de dataPersona[i]
              this.state.dataCliente[i]=returnVal;
              console.log("561   this.state.dataCliente["+i+"]");
              console.log(this.state.dataCliente[i]);
              var cteNombre="";

              console.log("565 El id del Clientes es: "+returnVal.id);
              cteNombre=this.state.dataCliente[i].nombre;

              //Refresca Colores en la Tabla Principal "clientesContactos"
              carga.map( (dato, k) => {
                console.log("569 dato "+k);
                console.log(dato);
                if ( dato.idCliente.nombre===cteNombre ){
                  console.log("584 El Cliente en clientesContactos es la: " + k);
                  dato.idCliente = returnVal;
                  var filaenTabla = document.getElementById("FilaclientesContactos"+k);
                  console.log("587 FilaclientesContactos" + k);
                  filaenTabla.style.backgroundColor = "#ffffff";
                }
              });
              var filaenCliente = document.getElementById("Filacliente"+i);
              console.log("578 Filacliente" + i);
              filaenCliente.style.backgroundColor = "green";
            }
          }).catch(err =>{
            console.log("585 Error: " + err);
            var filaenCliente = document.getElementById("Filacliente"+i);
            console.log("584 Filacliente" + i);
            filaenCliente.style.backgroundColor = "orange";
          });
        }
        break;
      case "clientesContactos":
        if (this.cadenadeCarga===null){
          this.cadenadeCarga="clientesContactos";
          console.log("625 clientesContactos");
        }
        for (let i = 0; i < carga.length; i++) {
          let nDe = 0;
          let nEn = 0;

            Crud_Catalogos(this.props.catalogo, this.catalogo, "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
              nEn = i+1;
              nDe = carga.length;
              if (returnVal.id>0){
                console.log("returnVal.id>0");
                carga[i]=returnVal;
                var filaenClientesContactos = document.getElementById("FilaclientesContactos"+i);
                console.log("578 FilaclientesContactos" + i);
                filaenClientesContactos.style.backgroundColor = "green";
                console.log("Carga Masiva en Tabla: " + this.props.catalogo + " Carga de "+nEn+ " de " + nDe+ " Registros! ");
                //console.log("Carga Finalizada! ");
                //NotificationManager.information("Carga Masiva en Tabla: " + this.props.catalogo," Carga de "+nEn+ " de " + nDe+ " Registros! ");
                //myModalN("none");
              }
            }).catch(err =>{
              console.log("612 Error: " + err);
              var filaenCliente = document.getElementById("FilaclientesContactos"+i);
              console.log("614 FilaclientesContactos" + i);
              filaenCliente.style.backgroundColor = "orange";
            });

        }
        break;
      default:
    }
	}
  onImportExcel = file => {
		//this.state.data= [];
    const { files } = file.target;
    //console.log("fileName");
    //console.log(files[0].name);
    Cuadros=[];
    TabTemp=[];
    TabPanelTemp=[];

    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          //console.log("996 Hoja: " + sheet);
          switch (sheet) {
            case "personas":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                this.setState({dataPersona: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
                TabTemp.push(<Tab id={"personas"} onClick={() => this.clickTab("personas") }>personas</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"personas"} contenido={this.state.dataPersona}/></TabPanel>);

              }
              break;
            case "cliente":
              //console.log("Clientes");
              if (workbook.Sheets.hasOwnProperty(sheet)) {
                this.setState({dataCliente: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                TabTemp.push(<Tab id={"cliente"} onClick={() => this.clickTab("cliente") } >cliente</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"cliente"} contenido={this.state.dataCliente}/></TabPanel>);
              }
              break;
            case "clientesContactos":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                console.log("707 clientesContactos");
                console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                this.setState({ data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                TabTemp.push(<Tab id={"clientesContactos"} onClick={() => this.clickTab("clientesContactos") } >clientesContactos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"clientesContactos"} contenido={this.state.data}/></TabPanel>);
              }
              { this.validaArchivo() }
              break;
            case "datos":
              if (workbook.Sheets.hasOwnProperty(sheet)){
                console.log("717 clientesContactos");
                console.log(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));

                this.setState({ data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet]) });
                TabTemp.push(<Tab>clientesContactos</Tab>);
                TabPanelTemp.push(<TabPanel><TablaCatalogo catalogo={"clientesContactos"} contenido={this.state.data}/></TabPanel>);
              }
              { this.validaArchivo() }
              break;

            default:
              console.log("Hoja No Válida!");
          }
          //if (workbook.Sheets.hasOwnProperty(sheet)) {
          //  this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          //}
        }
        Cuadros.push
        (
          <Tabs forceRenderTabPanel={true} className={"div1"}>
                <TabList>
                  {TabTemp}
                </TabList>
                  {TabPanelTemp}
          </Tabs>
        )

        this.setState({validado:true });
        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModalN("block");
    document.getElementById("cerrar").focus();
}

render() {
    return (
      <div className="excelCC" id="excelCC">
        <p className="etiquetaCC" id="etiquetaCC"><a href="#miModalCC">Imp</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />

				<div id="xlsModalCC" className="xlsModalCC">
					<div className="modal-contenidoCC">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-350">
						<div className="scroll-col col-sm-12">
						{
              //this.state.validado===false ?

                //<div><br></br>Verificando Errores ..!! <Dimmer active={true} loader></Dimmer> por favor Espere!</div>:
              //terrores===0?
              <div>
                { Cuadros }
              </div>//:Errores
						}
						</div>

					</div>

					<br></br>
					<center>
						<button id="cerrar" className="azul" onClick={()=>myModalN('none')} >Cerrar o Corregir Archivo de Excel</button>
						<spam>   </spam>
						<button id="cargar" className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar {this.state.nombreTemporalTab}</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}
export default ImportarCC;
