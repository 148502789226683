import React from "react";
import TableMenu from "../../components/Sidebar/TableMenu";
import classes from "./backdrop.module.css";

const SideMenu = (props) => {
  return (
    <>
      <TableMenu
        flagSideB={props.flagSideBar}
        methflagSideB={props.setflagSideBar}
      >
        {props.children}
      </TableMenu>
      {props.flagSideBar && (
        <div className={classes.backdrop} onClick={props.setflagSideBar} />
      )}
    </>
  );
};

export default SideMenu;
