import * as React from "react";
import { Grid } from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Catalogo from "../../components/Catalogo";
import { AcumuladoresForm } from "../../Monitoristas";

import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function getfuntion(
  metodo = "get",
  obj = [],
  id = "",
  catalogo = "",
  stateVar = "",
  hiddenModl = ""
) {
  Crud_Catalogos(
    catalogo,
    "perfiles",
    metodo,
    id,
    obj,
    "",
    stateVar,
    hiddenModl,
    []
  )
    .then((returnVal) => {
      switch (metodo) {
        case "get":
          if (stateVar === "funcionalidades") {
            for (let item of returnVal) {
              if (item.nombre == "Acumuladores") {
                return true;
              }
            }
            window.history.back();
            window.location.href = "403";
          }
          break;
        default:
          break;
      }
    })
    .catch((err) => {
      console.log("Error desconocido ...." + err);
    });
}
function AcumuladoresPage() {
  getfuntion(
    "get",
    "",
    "",
    "funcionalidades/Administracion/" + currentUser[0].perfil.id,
    "funcionalidades"
  );
  return (
    <SiteWrapper>
      <Grid.Col sm={12} lg={12}>
        <Catalogo
          Ctlogo="acumuladores"
          tamanio="45%"
          FCtlogo={AcumuladoresForm}
          catalogos={{ 0: "privilegios", 1: "acumuladores" }}
        />
      </Grid.Col>
    </SiteWrapper>
  );
}
export default AcumuladoresPage;
