import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { mail, text, disabledSave } from '../../../herramientas/Forms';
class CorreosClit extends Component {
  constructor(props) {
    super(props);
    this.state = {  correo:"", nombreCompleto:"", estatus:false, contactos: [] };
    this.accion= "nuevo";
    this.valid = {  correo: false};
    this.contactoObject= {  idCliente: this.props.elementos? this.props.elementos.id: 0, correo: "", estatus: false, nombreCompleto: "" }
  }
  isValid=(campo) =>{
    if(this.valid[campo]){
      return this.valid[campo];
    }else{ return false }
  }
  componentDidMount = () => {
    this.getfuntion("get", "", "", "clientes_contactos_temporal/"+this.props.elementos.id, "contactos");
   }
  crud_put  = () =>  this.getfuntion("put", this.contactoObject, "", "clientes_contactos_temporal", "contactos");
  crud_post = () =>  this.getfuntion("post", this.contactoObject, "", "clientes_contactos_temporal", "contactos");
  changeCorreo      = (event)  => {
                                    this.contactoObject.correo= event.target.value; 
                                    this.setState({ correo: event.target.value    });
                                    this.valid.correo= mail(event.target.value).estatus;
                                  }
  changeEstatus     = (event) => { this.contactoObject.estatus      = event.target.checked; this.setState({ estatus:      event.target.checked  }); }
  changeNombreCompleto= (event) => {
                                    this.contactoObject.nombreCompleto= event.target.value;
                                    this.setState({ nombreCompleto:  event.target.value  });
                                    //this.valid.nombreCompleto= text(event.target.value, 2, 255);
                                 }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "clientes", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "contactos") { this.setState({ contactos: returnVal }); }
          }
          break;
        case "post":
          {
            if (stateVar === "contactos") { this.state.contactos.push(returnVal); this.cancelar(); }
          }
        case "put":
          {
            if (stateVar === "contactos") {
              for(let item of this.state.contactos){
                if(item.idCliente == returnVal.idCliente && item.correo == returnVal.correo){
                  item= returnVal;
                  this.cancelar();
                  break;
                }
              }
              //this.state.contactos.push(returnVal);
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log(err);});
  }
  setEdit =(indice)=>{
    this.contactoObject= this.state.contactos[indice];
    this.setState({ correo:       this.state.contactos[indice].correo       });
    this.setState({ nombreCompleto:  this.state.contactos[indice].nombreCompleto  });
    this.setState({ estatus:      this.state.contactos[indice].estatus      });
    this.valid.correo= mail(this.state.contactos[indice].correo).estatus;
    this.accion= "modificar";
  }
  cancelar =()=>{
    this.contactoObject= {  idCliente: this.props.elementos.id, correo: "", estatus: false, nombreCompleto: "" };
    this.setState({ nombreCompleto:  " " });
    this.setState({ correo:       ""    });
    this.setState({ estatus:      false });
    this.accion= "nuevo";
  }

  render() {
    return (
      <Card title="Correos" isCollapsible isCollapsed={false} body={
          <div>
            <Grid.Row>
              <Grid.Col sm={4} lg={4} className="border border-left-0 border-right-8 border-bottom-0 border-top-0 border-dark" >
                <Form.Group label="Correo">
                  <Form.Input
                    type="text" name="correo" feedback="Ingresa correo valido" value={this.state.correo} placeholder="mail@skyangel.com"
                    invalid={!this.isValid("correo") && this.state.correo.length > 0} valid={this.isValid("correo") && this.state.correo.length > 0 }
                    onChange={this.changeCorreo}  maxlength="100"
                  />
                </Form.Group>
                <Form.Group label="Nombre Completo">
                  <Form.Textarea name="nombreCompleto" type="text" value={ this.state.nombreCompleto } onChange={this.changeNombreCompleto} maxlength="255"/>
                </Form.Group>
                <Form.Group label="Estatus">
                  <Form.Switch type="Checkbox" name="toggle" checked={this.state.estatus} onChange={this.changeEstatus} />
                </Form.Group>
                <span className="float-right ml-2">
                    <Button size="sm"color="danger" onClick={ ()=> this.cancelar() } >
                      <span class="badge"><Icon link={true} name="x" /></span>
                    </Button>
                </span>
                <span className="float-right">
                    <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={ disabledSave(this.valid) } onClick={this.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
                      <span class="badge"><Icon link={true} name="save" /></span>
                    </Button>
                </span>
              </Grid.Col>
              <Grid.Col>
                <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                  <Table.Header>
                    <Table.ColHeader>Correo</Table.ColHeader>
                    <Table.ColHeader>Estatus</Table.ColHeader>
                    <Table.ColHeader>Descripción</Table.ColHeader>
                    <Table.ColHeader>Editar</Table.ColHeader>
                  </Table.Header>
                  <Table.Body>
                    {
                      this.state.contactos.map((cont, index) =>
                        <Table.Row>
                          <Table.Col>{cont['correo']}</Table.Col>
                          <Table.Col> <Form.Switch type="Checkbox" name="index" checked={ cont['estatus'] } />  </Table.Col>
                          <Table.Col>{cont["nombreCompleto"]}</Table.Col>
                          <Table.Col><Button color="primary" onClick={ () => this.setEdit(index) } pill outline size="sm">  <Icon link={true} name="edit" /> </Button></Table.Col>
                        </Table.Row>
                      )
                    }
                  </Table.Body>
                </Table>
              </Grid.Col>
            </Grid.Row>
          </div>}
      />
    )
  }
}

export default CorreosClit
