import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid,  } from "tabler-react";
import { Crud_Catalogos } from '../../../../herramientas/Crud_Catalogos';
import Select from 'react-select';
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
import { TablePageable } from "../../../../Helpers";
const PlantillasWhatsApp =({ stor, plataforma, whatsappConfiguracion })=> {
  const [whatsappPlantillas, setWhatsappPlantillas]= useState([]);
  const [acumuladores, setAcumuladores]= useState([]);
  const [acumulador, setAcumulador]= useState({ value: 0, label: "Selecciona un acumulador ", data: {} });
  const [currentplantilla, setCurrentplantilla]= useState({ value: 0, label: "Selecciona una plantilla ",
    data: {
      "idPlataforma": plataforma.id,
      "idAcumulador": null,//object
      "nombre": "",
      "descripcion": "",
      "plantilla": ""
    }
  });
  const [whatsappCamposList, setWhatsappCamposList]= useState([]);
  const [whatsappCampos, setWhatsappCampos]= useState([]);
  const [whatsappCampo, setWhatsappCampo]= useState({value: 0, label: "Selecciona una opción", data:{} });

  const [vistaPrevia, setVistaPrevia]= useState("");
  const [tab, setTab]= useState(1);
  //getPlantilla:"",
  useEffect(() => {
    if( whatsappConfiguracion.id ){
      let acumuladoresTemp= [];
      acumuladoresTemp.push({ value: 0, label: "Selecciona un acumulador ", data: null });

      stor.acumuladores_object.map((a)=>{
        acumuladoresTemp.push({ value: a.id, label: a.nombre, data: a })
      });
      setAcumuladores(acumuladoresTemp);
      getfuntion("get", "", "", "whatsapp_plantillas/plataforma/"+plataforma.id,"whatsapp_plantillas");
    }
  }, []);
  useEffect(() => {
    if(currentplantilla.value){
      const plataformaTipo= [1,3].includes(plataforma.plataformaTipo.id)? 1: 2;
      getfuntion("get", "", "", "whatsapp_campos/plataforma_tipo/"+plataformaTipo,"whatsapp_campos_list");
      setVistaPrevia(currentplantilla.data.plantilla);
    }else{
      setWhatsappCampo({value: 0, label: "Selecciona una opción", data:{} });
      setWhatsappCampos([]);
      setVistaPrevia("");
      setTab(1);
    }
  }, [currentplantilla]);

  useEffect(() => {
    if(whatsappCampos.length && currentplantilla.data.id){
      const vistaPreviaTemp= currentplantilla.data.plantilla;
      let camposTemp= "";
      for(let wc of whatsappCampos){
        const wcTemp= whatsappCamposList.filter( c=> c.value === wc.idWhatsappCampo);
        camposTemp= camposTemp+(camposTemp.length? "\n":"")+ (wcTemp.length === 1 ? wcTemp[0].label : "");
      }
      setWhatsappCampo({value: 0, label: "Selecciona una opción", data:{} });
      setVistaPrevia(vistaPreviaTemp.replace("${columnas}", camposTemp));
    }
  }, [whatsappCampos]);

  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "correoPlantillas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "whatsapp_plantillas"){
                let whatsappPlantillasTemp= [{ value: 0, label: "Selecciona una plantilla ", data: {} }];
                if( returnVal && returnVal.length > 0 ){
                  returnVal.map((wp)=> whatsappPlantillasTemp.push({ value: wp.id, label: wp.nombre, data: wp }) );
                  setWhatsappPlantillas(whatsappPlantillasTemp);
                }
              }
              if( stateVar === "whatsapp_campos_list"){
                let whatsappCamposListTemp= [{value: 0, label: "Selecciona una opción", data:{} }];
                for( let c of returnVal ){
                  whatsappCamposListTemp.push({value: c.id, label: c.label+" ("+c.campoNombre+")", data:c });
                }
                setWhatsappCamposList(whatsappCamposListTemp);
                getfuntion("get", "", "", "whatsapp_campos_conf/plantilla/"+currentplantilla.value,"whatsapp_campos");
              }
              if( stateVar === "whatsapp_campos"){
                setWhatsappCampos(returnVal);
              }
            }
            break;
          case "post":
            {
              if (stateVar === "whatsapp_plantillas"){
                if(returnVal.id){
                  let whatsappPlantillasTemp= whatsappPlantillas;
                  whatsappPlantillasTemp.push({ value: returnVal.id, label: returnVal.nombre, data: returnVal });
                  setWhatsappPlantillas(whatsappPlantillasTemp);
                  NotificationManager.info("Plantilla creada correctamente( "+returnVal.nombre+" )", "Nuevo");
                }
              }
              if( stateVar === "whatsapp_campos"){
                setWhatsappCampos(returnVal);
                NotificationManager.info("Campos modificados", "Modificación");
              }
            }
            break;
          case "put":
            {
              if (stateVar === "whatsapp_plantillas"){
                if(returnVal.id){
                  let whatsappPlantillasTemp= whatsappPlantillas;
                  for(let i= 0; i< whatsappPlantillasTemp.length; i++){
                    if(whatsappPlantillasTemp[i].value === returnVal.id){
                      whatsappPlantillasTemp[i]= { value: returnVal.id, label: returnVal.nombre, data: returnVal };
                      setWhatsappPlantillas(whatsappPlantillasTemp);
                      NotificationManager.info("Plantilla modificada correctamente( "+returnVal.nombre+" )", "Modificado");
                      break;
                    }
                  }
                }
              }
            }
            break;
          case "delete":
            if( stateVar === "whatsapp_campos" && stateVar){
              NotificationManager.info("Campo removido", "Remover");
              getfuntion("get", "", "", "whatsapp_campos/plantilla/"+currentplantilla.value,"whatsapp_campos");
            }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          console.log(err)
        }
    })
  }
  
  const changeAcumulador=(event) =>{
    let plantillaEncontrada= false;
    setCurrentplantilla({ value: 0, label: "Selecciona una plantilla ", data: {} });
    setAcumulador(event);
    for(let wp of whatsappPlantillas){     
      if( wp.data.id && wp.data.idAcumulador.id === event.value ){
        setCurrentplantilla(wp);
        plantillaEncontrada= true;
      }
    }
    if(!plantillaEncontrada){
      setCurrentplantilla(
        {
          value: 0,
          label: "Selecciona una plantilla ",
          data: {
            "idPlataforma": plataforma.id,
            "idAcumulador": event.data,//object
            "nombre": "",
            "descripcion": "",
            "plantilla": ""
          }
        }
      )
    }
  }
  const changePlantillas=(event) =>{
    setCurrentplantilla(event);
    for(let a of acumuladores){
      if( event.data.id && event.data.idAcumulador.id === a.value ){
        setAcumulador(event);
      }
    }
  }
  const ordenarCampos=(camposArray)=>{
    for( let i= 0; i< camposArray.length; i++ ){
      camposArray[i].orden= i+1;
    }
    return camposArray;
  }
  return (
    <div>
      <div hidden= {!whatsappConfiguracion.id}>
        {/**
          *   Configuracion de correo
          */
        }
        <div className="row" style={{height: "100%"}}>
          <div className="col">
            <div className="row">
              <div className="col">
                <Form.Group label="Acumulador">
                  <Select
                    value={acumulador}
                    onChange={changeAcumulador}
                    options={acumuladores}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group label="Plantillas">
                  <Select
                    value={currentplantilla}
                    onChange={changePlantillas}
                    options={whatsappPlantillas}
                  />
                </Form.Group>
              </div>
              <div className="col">
                <button
                  className="btn btn-primary"
                  onClick={()=> getfuntion("get", "", "", "whatsapp_plantillas/plataforma/"+plataforma.id,"whatsapp_plantillas")}>
                  <Icon name="refresh-cw"/>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Plantilla</a>
                  </li>
                  <li className="nav-item">
                    <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=>{if(currentplantilla.data.id)setTab(2) }}> Campos</a>
                  </li>
                </ul>
                <div  hidden={tab !== 1}>
                  <h4>Plantilla</h4>
                  {/**
                   *  this.id = id;
                      this.idPlataforma = idPlataforma;
                      this.idAcumulador = idAcumulador;
                      this.nombre = nombre;
                      this.descripcion = descripcion;
                      this.plantilla = plantilla;
                  */}
                  <form>
                    <div className="form-group">
                      <label for="nombrePlantilla">Nombre</label>
                      <input type="text" className="form-control" id="nombrePlantilla"
                        placeholder="Ingresa nombre para la plantilla"
                        value={currentplantilla.data.nombre? currentplantilla.data.nombre: "" }
                        onChange={(e)=>{
                          let objTemp= {...currentplantilla};
                          objTemp.data.nombre= e.target.value;
                          setCurrentplantilla(objTemp)
                        }}
                      />
                      <small id="nombreHelp" className="form-text text-muted">Nombre con el cual sera identificada la plantilla</small>
                    </div>
                    <div className="form-group">
                      <label for="descripcionPlantilla">Descripción</label>
                      <input type="text" className="form-control" id="descripcionPlantilla"
                        placeholder="Ingresa descripción para la plantilla"
                        value={currentplantilla.data.descripcion? currentplantilla.data.descripcion: "" }
                        onChange={(e)=>{
                          let objTemp= {...currentplantilla};
                          objTemp.data.descripcion= e.target.value;
                          setCurrentplantilla(objTemp)
                        }}
                      />
                      <small id="descripcionHelp" className="form-text text-muted">Descripción con el cual sera identificada la plantilla</small>
                    </div>
                    <div className="form-group">
                      <label for="plantilla">Plantilla</label>
                      <textarea className="form-control" id="plantilla" rows="15"
                        value={currentplantilla.data.plantilla? currentplantilla.data.plantilla: "" }
                        onChange={(e)=>{
                          let objTemp= {...currentplantilla};
                          objTemp.data.plantilla= e.target.value;
                          setCurrentplantilla(objTemp)
                        }}
                      >
                      </textarea>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={()=>{
                        if(currentplantilla.data.id){
                          getfuntion("put", currentplantilla.data, "", "whatsapp_plantillas", "whatsapp_plantillas")
                        }else{
                          getfuntion("post", currentplantilla.data, "", "whatsapp_plantillas", "whatsapp_plantillas")
                        }
                      }}>
                        <Icon name="loader"/> Guardar
                    </button>
                  </form>
                </div>
                <div  hidden={tab !== 2}>
                  <div className="row">
                    <div className="col">
                      <Select
                        value={whatsappCampo}
                        onChange={(e)=> setWhatsappCampo(e) }
                        options={whatsappCamposList.filter(cl => !whatsappCampos.filter(c=> c.idWhatsappCampo === cl.value).length )}
                      />
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Agregar"
                        disabled={!whatsappCampo.value}
                        onClick={()=>{
                          let whatsappCamposTemp= [...whatsappCampos];
                          whatsappCamposTemp.push({
                            "idWhatsappPlantillas": currentplantilla.value,
                            "idWhatsappCampo": whatsappCampo.value,
                            "orden": 0
                          });
                          setWhatsappCampos(ordenarCampos(whatsappCamposTemp));
                        }}>
                          <Icon name="plus"/>
                      </button>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-primary float-right"
                        onClick={()=>{
                          console.log(whatsappCampos);
                          if(whatsappCampos.length){
                            //getfuntion("put", currentplantilla.data, "", "whatsapp_plantillas", "whatsapp_plantillas")
                            getfuntion("post", whatsappCampos, "", "whatsapp_campos_conf", "whatsapp_campos");
                          }
                        }}>
                          <Icon name="loader"/> Guardar
                      </button>
                    </div>
                  </div>
                  <hr/>
                  <TablePageable
                    titulo={ <h4>Campos</h4> }
                    lista= { whatsappCampos }
                    columnas={
                      [
                        { columna: "idWhatsappCampo", label:"id"},
                        { columna: "remover", label:"Remover",
                          form: (e)=>
                            <i className="fa fa-remove" style={{"font-size": "24px"}}
                              onClick={()=>{
                                let whatsappCamposTemp= [...whatsappCampos];
                                for(let i= 0; i< whatsappCamposTemp.length; i++){
                                  if(
                                    whatsappCamposTemp[i].idWhatsappPlantillas === e.idWhatsappPlantillas
                                    && whatsappCamposTemp[i].idWhatsappCampo === e.idWhatsappCampo ){
                                    whatsappCamposTemp.splice(i, 1);
                                  }
                                }
                                setWhatsappCampos(ordenarCampos(whatsappCamposTemp));
                                /*getfuntion(
                                  "delete",
                                  "",
                                  e.idWhatsappPlantillas+"/"+e.idWhatsappCampo,
                                  "whatsapp_campos_conf",
                                  "whatsapp_campos_conf");*/
                              }}>
                            </i> },
                        { columna: "ordenar", label:"Ordenar",
                          form: (e)=>{
                            let whatsappCamposTemp= [...whatsappCampos];
                            let indice= 0;
                            for( let i= 0; i< whatsappCamposTemp.length; i++ ){
                              if( whatsappCamposTemp[i].idWhatsappCampo === e.idWhatsappCampo){
                                indice= i;
                              }
                            }
                            return <div className="row">
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-12">
                                      <i hidden={ indice <= 0}
                                          className="fa fa-caret-up btn p-0"
                                          style={{"font-size":"16px"}}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={"Estableser orden "+ (indice)}
                                          onClick={()=>{                                            
                                            const whatsappCampoTemp= whatsappCamposTemp[indice-1];
                                            whatsappCamposTemp[indice-1]= e;
                                            whatsappCamposTemp[indice]= whatsappCampoTemp;
                                            //setWhatsappCampos(whatsappCamposTemp);
                                            setWhatsappCampos(ordenarCampos(whatsappCamposTemp));
                                          }}
                                        >
                                      </i>
                                    </div>
                                    <div className="col-12">
                                      <i hidden={ indice >= whatsappCamposTemp.length-1 }
                                        className="fa fa-caret-down btn p-0"
                                        style={{"font-size":"16px"}}
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={"Estableser orden "+ (indice+2)}
                                        onClick={()=>{                                          
                                          const whatsappCampoTemp= whatsappCamposTemp[indice+1];
                                          whatsappCamposTemp[indice+1]= e;
                                          whatsappCamposTemp[indice]= whatsappCampoTemp;
                                          //setWhatsappCampos(whatsappCamposTemp);
                                          setWhatsappCampos(ordenarCampos(whatsappCamposTemp));
                                        }}
                                      >
                                      </i>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  {e.orden}
                                </div>
                              </div>
                          }
                        },
                        { columna: "campo", label:"Campo",
                          form: (e)=>{
                          const campoTemp= whatsappCamposList.filter( c=> c.value === e.idWhatsappCampo);
                          return campoTemp.length === 1? campoTemp[0].data.campoNombre: "";
                          }
                        },
                        { columna: "label", label:"Etiqueta",
                          form: (e)=>{
                          const campoTemp= whatsappCamposList.filter( c=> c.value === e.idWhatsappCampo);
                          return campoTemp.length === 1? campoTemp[0].label: "";
                          }
                        }
                      ]
                    }
                    id= "idWhatsappCampo"
                    //selected={(e)=> }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <h4>Vista previa</h4>
            <svg width="100%" height="100%">
              <rect width="100%" height="93%" style={{fill: "rgba(230, 223, 204, 1)", "stroke-width": "3", stroke: "rgb(0,0,0)" }} />
              <rect width="100%" height="50" style={{fill: "rgb(18, 140, 126)", "stroke-width": "3", stroke: "rgb(0,0,0)" }} />
              <text x="35" y="35" style={{font: "bold 20px sans-serif"}}>Nombre del grupo</text>
              <rect x="5" y="100" rx="20" ry="20" width="93%" height="55%" style={{fill: "rgb(255, 255, 255)", "stroke-width": "3" }} />
              { vistaPrevia.split("\n").map((l, index)=> <text x="20" y={122+(index*18)} style={{font: "bold 16px sans-serif", color:"black"}}>{l}</text>) }
              <rect x="5" y="85%" rx="20" ry="20" width="93%" height="6%" style={{fill: "rgb(255, 255, 255)", "stroke-width": "3" }} />
              <text x="100" y="89%" style={{font: "bold 20px sans-serif", color:"black"}}>Mensaje</text>
              <svg x="40" y="87%" xmlns="http://www.w3.org/2000/svg" width="2%" height="2%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
              <svg x="70" y="87%" xmlns="http://www.w3.org/2000/svg" width="2%" height="2%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              <svg x="95%" y="87%" xmlns="http://www.w3.org/2000/svg" width="2%" height="2%" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
            </svg>
          </div>
        </div>
      </div>
      <div hidden= {whatsappConfiguracion.id}>
        <h3> Esta plataforma no se encuentra configurada para envio de whatsApp</h3>
      </div>      
    </div>
  )
}

export default PlantillasWhatsApp
