import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import React, { useState, useEffect } from "react";
import { TablePageable } from "../../Helpers";
import { MDBDataTable } from 'mdbreact';

const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
  return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    switch (metodo) {
      case "get":
        {
          switch (stateVar) {

            default: return returnVal;
          }
        }
      default: return returnVal;
    }
  })
}


export const Desglose = ({index}) => {
  const idUsuario = JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id;
  const [data, setData] = useState([]);
  const [columnas, setColumnas] = useState([]);
  let listaColumnas = []; 
  let resultSet = [];

  useEffect(() => {
    switch (index.tipo) {
      case "Alertas generales":
          getfuntion(
            "get",[],"","informesDesglose/desglose/alertasGeneralTerrestre/"+idUsuario+"/"+index.mes+"/"+index.id,"alertas",""
          ).then((res)=>{
            res.forEach(r => {
              resultSet.push(r);
            });
            setData(resultSet)
          })
        break;
      case "Alertas por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/alertasTransportistaTerrestre/"+index.mes+"/"+index.plataforma+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Entregas generales":
        getfuntion(
          "get",[],"","informesDesglose/desglose/entregasGeneralTerrestre/"+idUsuario+"/"+index.mes+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Entregas por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/entregasTransportistaTerrestre/"+index.mes+"/"+index.plataforma+"/"+index.id,"alertas",""
        ).then((res)=>{
          console.log(res);
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Incidencias generales":
        getfuntion(
          "get",[],"","informesDesglose/desglose/incidenciasGeneralTerrestre/"+idUsuario+"/"+index.mes+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Incidencias por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/incidenciasTransportistaTerrestre/"+index.mes+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Movimientos generales":
        getfuntion(
          "get",[],"","informesDesglose/desglose/movimientosGeneralTerrestre/"+idUsuario+"/"+index.mes+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Movimientos por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/movimientosTransportistaTerrestre/"+index.mes+"/"+index.plataforma+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Tipos de alertas por plataforma":
        getfuntion(
          "get",[],"","informesDesglose/desglose/alertasTipoPlataformaTerrestre/"+index.mes+"/"+index.id+"/"+index.plataforma,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Tipos de alertas por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/alertasTipoTransportistaTerrestre/"+	index.mes + "/" + index.plataforma + "/" + index.transportista + "/" + index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Tipos de incidencias por plataforma":
        getfuntion(
          "get",[],"","informesDesglose/desglose/incidenciasTipoPlataformaTerrestre/"+index.mes+"/"+index.plataforma+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      case "Tipos de incidencias por línea de transporte":
        getfuntion(
          "get",[],"","informesDesglose/desglose/incidenciasTipoTransportistaTerrestre/"+index.mes+"/"+index.plataforma+"/"+index.id,"alertas",""
        ).then((res)=>{
          res.forEach(r => {
            resultSet.push(r);
          });
          setData(resultSet)
        })
        break;
      default:
        break;
    }
  }, []);
  useEffect(() => {
    if (data.length > 0) {
      let lista = [];
      listaColumnas = Object.keys(data[0]);
      listaColumnas.forEach(item => {
        lista.push({ label: item, field:item, sort:'asc', width: 200 })
      });
      setColumnas(lista);
    }
  }, [data]);
    
    const rows = {
      columns: columnas,
      rows: data
    } 
    console.log(rows);
    return(
      /*
      <TablePageable
        titulo={ <h3> {index.nombre} </h3> }
        lista= { data }
        columnas={columnas}
      />
      
      <Tabla
        title={index.nombre}
        listaColumnas={listaColumnas}
        data={data}
      />
      */
      <MDBDataTable
      striped
      bordered
      small
      data={rows}
      />

    )
}

export default React.memo(Desglose);