import FetchData from "./FetchData.react";

const CheckForm = (variables, sets) => {
	//Checks
	variables.bloque.value === 0 ? sets.setErrorBloque(true) : sets.setErrorBloque(false);
	variables.funcionalidad.value === 0
		? sets.setErrorFuncionalidad(true)
		: sets.setErrorFuncionalidad(false);
	variables.plataforma.value === 0
		? sets.setErrorPlataforma(true)
		: sets.setErrorPlataforma(false);
	variables.transportista.value === 0
		? sets.setErrorTransportista(true)
		: sets.setErrorTransportista(false);
	//variables.mes.value  ? sets.setErrorMes(true) : sets.setErrorMes(false);
	let errorMes = false;
	if(variables.mes){
		 //errorMes = variables.mes.value === 0 ? true : false;
     	if ((variables.mes !== undefined) && (variables.mes.length > 0) ){
     		sets.setErrorMes(false);
			errorMes=false;
     	}
     	else
     	{
     	   sets.setErrorMes(true);
			errorMes=true;
     	}
	}

	//((variables.mes !== undefined) && (variables.mes.length > 0))? sets.setErrorMes(false) : sets.setErrorMes(true);
	//sets.setErrorMes(true);
 
 

//	let errorMes = variables.mes.value === 0 ? true : false;
	//let errorMes = ((variables.mes !== undefined) && (variables.mes.length > 0)) ? true : false;
	variables.semana.value === 0 ? sets.setErrorSemana(true) : sets.setErrorSemana(false);
	let errorSemana = variables.semana.value === 0 ? true : false;

	let errorFuncionalidad = variables.funcionalidad.value === 0 ? true : false;
	let errorPlataforma = variables.plataforma.value === 0 ? true : false;
	let errorTransportista = variables.transportista.value === 0 ? true : false;
	//Acciones 
	switch (variables.funcionalidad.label) {
		case "Alertas terrestre generales":
			if (variables.temporalidad=='semana'&&!errorSemana){
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
				     variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);
                  
				}

				FetchData(
					"informes/contador/alertasGeneralTerrestreSemanas/" +
						variables.idUsuario +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
				                //alert("Alertas Generales: todas las semanas: " +todasLasSemanas);
				/*let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );				
                let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				let finFormated = new Date(fin).toISOString().slice(0, 10);
				//alert(inicioFormated);
				//alert(finFormated);*/


			}

			if (!( errorFuncionalidad || errorMes )&&(variables.temporalidad=='mes') ){				
				//let lastDayOfMonth  = new Date(variables.anio.value, variables.mes.value , 0);

				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");
                //todosLosMeses.slice(0, -1)

				FetchData(
					"informes/contaDashboard/alertasGeneralTerrestreMeses/" +
						variables.idUsuario +						
						"/"+todosLosMeses.slice(0, -1)
						+
						"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);

				// FetchData(
				// 	"informes/contaDashboard/alertasGeneralTerrestre/" +
				// 		variables.idUsuario +
				// 		"/"+ variables.anio.value +"-"+variables.mes.value+
				// 		"-01/"+ variables.anio.value+"-"+variables.mes.value+"-"+"31",
				// 	"Resultados",
				// 	sets.setData,
				// 	sets.setLoading,
				// 	sets.setError,
				// 	variables.mes.value
				// );

			}
			if (variables.temporalidad=='anio') {
				FetchData(
					"informes/contaDashboard/alertasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.anio.value+ 
						"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if (variables.temporalidad=='periodoPersonalizado') {                
				console.log("variables:::"+variables.fechaInicio);
				console.log("variables:::"+variables.fechaFin);
				FetchData(
					"informes/contaDashboard/alertasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.fechaInicio+ 
						"/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			
			
			break;
		case "Alertas terrestre por línea de transporte":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
				
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);


				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
				     variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);
                  
				}


				FetchData(
					"informes/contador/alertasTransportistaTerrestreSemanas/" +
					variables.plataforma.value +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);


			}

			if (!( errorPlataforma || errorMes )&&(variables.temporalidad=='mes')&&variables.mes) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");
				//alert(todosLosMeses);
				//alert(variables.mes);S		  
				//variables.mes.map((v) => alert(v.value));     
				//alert(variables.mes['1'].value);

				FetchData(
					"informes/contaDashboard/alertasTransportistaTerrestreMeses/" +
						variables.plataforma.value+
						"/"+todosLosMeses.slice(0, -1)
						+
						"/"+ variables.anio.value
						,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			else{				
				   sets.setErrorMes(true);				
			}
			if ((variables.temporalidad=='anio')) {
				FetchData(
					"informes/contaDashboard/alertasTransportistaTerrestre/" +
						variables.plataforma.value+
						"/"+ variables.anio.value + "-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);	
			}
			if ((variables.temporalidad=='periodoPersonalizado')) {
				FetchData(
					"informes/contaDashboard/alertasTransportistaTerrestre/" +
						variables.plataforma.value +
						"/"+ variables.fechaInicio + "/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);	
			}
			break;
		case "Entregas terrestre generales":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );				
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
				     variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);
                  
				}
				FetchData(
					"informes/contador/entregasGeneralTerrestreSemanas/" +
						variables.idUsuario +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if (!( errorFuncionalidad || errorMes )&&(variables.temporalidad=='mes')) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");

					
         

				FetchData(
					"informes/contaDashboard/entregasGeneralTerrestreMeses/" +
					variables.idUsuario +						
					"/"+todosLosMeses.slice(0, -1)
					+
					"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='anio')) {
				FetchData(
					"informes/contaDashboard/entregasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.anio.value + "-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);

			}
			if (variables.temporalidad=='periodoPersonalizado') {                
				console.log("variables:::"+variables.fechaInicio);
				console.log("variables:::"+variables.fechaFin);
				FetchData(
					"informes/contaDashboard/entregasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.fechaInicio+ 
						"/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			break;
		case "Entregas terrestre por línea de transporte":

		if (variables.temporalidad=='semana'){
			// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
			// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
			
			// let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
			// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
			// let finFormated = new Date(fin).toISOString().slice(0, 10);
			// alert(inicioFormated);
			// alert(finFormated);
			let todasLasSemanas=""
			if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
				 variables.semana.map((v) => {todasLasSemanas+=v.value+",";
				 }
				);
			  
			}


			FetchData(
				"informes/contador/entregasTransportistaTerrestreSemanas/" +
				variables.plataforma.value +
					"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
				"Resultados",
				sets.setData,
				sets.setLoading,
				sets.setError,
				variables.mes.value
			);
		}


			if (!( errorPlataforma || errorMes )&&(variables.temporalidad=='mes')) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");

				FetchData(
					"informes/contaDashboard/entregasTransportistaTerrestreMeses/" +		
					variables.plataforma.value+
					"/"+todosLosMeses.slice(0, -1)
					+
					"/"+ variables.anio.value
					,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if (variables.temporalidad=='anio') {
				FetchData(
					"informes/contaDashboard/entregasTransportistaTerrestre/" +		
						variables.plataforma.value+"/"+ variables.anio.value+ 
						"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='periodoPersonalizado')) {
				FetchData(
					"informes/contaDashboard/entregasTransportistaTerrestre/" +
						variables.plataforma.value +
						"/"+ variables.fechaInicio + "/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);	
			}
			break;
		case "Incidencias terrestre generales":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
				
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
					 variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);				  
				}
				FetchData(
					"informes/contador/incidenciasGeneralTerrestreSemanas/" +
						variables.idUsuario +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);






			}

			if (!( errorFuncionalidad || errorMes )&&(variables.temporalidad=='mes')) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");

				FetchData(
					"informes/contaDashboard/incidenciasGeneralTerrestreMeses/" +
						variables.idUsuario +					
						"/"+todosLosMeses.slice(0, -1)
						+
						"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='anio')) {
				FetchData(
					"informes/contaDashboard/incidenciasGeneralTerrestre/" +
						variables.idUsuario +
						"/" +
						variables.anio.value +"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if (variables.temporalidad=='periodoPersonalizado') {                
				console.log("variables:::"+variables.fechaInicio);
				console.log("variables:::"+variables.fechaFin);
				FetchData(
					"informes/contaDashboard/incidenciasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.fechaInicio+ 
						"/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			break;
		case "Incidencias terrestre por línea de transporte":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
				
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
					 variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);				  
				}
				
				FetchData(
					"informes/contador/incidenciasTransportistaTerrestreSemanas/" +
					variables.plataforma.value +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);






			}
			if (!( errorPlataforma || errorMes )&&(variables.temporalidad=='mes')) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");

				FetchData(
					"informes/contaDashboard/incidenciasTransportistaTerrestreMeses/" +
					variables.plataforma.value+"/"+ 
					todosLosMeses.slice(0, -1)
					+
					"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='anio')) {
				FetchData(
					"informes/contaDashboard/incidenciasTransportistaTerrestre/" +
					variables.plataforma.value+"/"+variables.anio.value+"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='periodoPersonalizado')) {
				FetchData(
					"informes/contaDashboard/incidenciasTransportistaTerrestre/" +
						variables.plataforma.value +
						"/"+ variables.fechaInicio + "/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);	
			}

			break;
		case "Movimientos terrestre generales":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
					 variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);				  
				}
				FetchData(
					"informes/contador/movimientosGeneralTerrestreSemanas/" +
						variables.idUsuario +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			if (!( errorFuncionalidad || errorMes )&&variables.temporalidad=='mes') {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");

				let lastDayOfMonth  = new Date(variables.anio.value, variables.mes.value , 0);
				FetchData(
					"informes/contaDashboard/movimientosGeneralTerrestreMeses/" +
						variables.idUsuario +
						"/"+todosLosMeses.slice(0, -1)
						+
						"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if (variables.temporalidad=='anio') {
				let lastDayOfMonth  = new Date(variables.anio.value, variables.mes.value , 0);
				FetchData(
					"informes/contaDashboard/movimientosGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.anio.value +"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			if (variables.temporalidad=='periodoPersonalizado') {                
				console.log("variables:::"+variables.fechaInicio);
				console.log("variables:::"+variables.fechaFin);
				FetchData(
					"informes/contaDashboard/alertasGeneralTerrestre/" +
						variables.idUsuario +
						"/"+ variables.fechaInicio+ 
						"/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			break;
		case "Movimientos terrestre por línea de transporte":
			if (variables.temporalidad=='semana'){
				// let unoDeEnero  = new Date(variables.anio.value, 0 , 0);
				// let inicio=unoDeEnero.setDate(unoDeEnero.getDate() +( 7*(variables.semana.value)-6)  );
                // let fin = unoDeEnero.setDate(new Date(inicio).getDate() +7  );                
				// let inicioFormated = new Date(inicio).toISOString().slice(0, 10);
				// let finFormated = new Date(fin).toISOString().slice(0, 10);
				// alert(inicioFormated);
				// alert(finFormated);
				let todasLasSemanas=""
				if ((variables.semana !== undefined) && (variables.semana.length > 0) ){
					 variables.semana.map((v) => {todasLasSemanas+=v.value+",";
					 }
					);				  
				}
				FetchData(
					"informes/contador/movimientosTransportistaTerrestreSemanas/" +
					variables.plataforma.value +
						"/"+ todasLasSemanas.slice(0, -1)+"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			if (!( errorPlataforma || errorMes )&&(variables.temporalidad=='mes')) {
				let todosLosMeses=""
				if ((variables.mes !== undefined) && (variables.mes.length > 0) )
				     variables.mes.map((v) => todosLosMeses+=v.value+",");


				FetchData(
					"informes/contaDashboard/movimientosTransportistaTerrestreMeses/" +
						variables.plataforma.value+"/"+ 
						todosLosMeses.slice(0, -1)
						+
						"/"+ variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='anio')) {
				FetchData(
					"informes/contaDashboard/movimientosTransportistaTerrestre/" +
						variables.plataforma.value+"/"+variables.anio.value+
						"-01-01/"+ variables.anio.value+"-12-31",
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='periodoPersonalizado')) {
				FetchData(
					"informes/contaDashboard/movimientosTransportistaTerrestre/" +
						variables.plataforma.value +
						"/"+ variables.fechaInicio + "/"+ variables.fechaFin,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);	
			}
			break;
		case "Tipos de alertas terrestre por plataforma":



			
			if ((variables.temporalidad=='mes')) {
				let todosLosMeses="";
				variables.mes.map((v) => todosLosMeses+=v.value+",");			
				
				FetchData(
					"informes/contador/alertasTipoPlataformaTerrestreMeses/" + variables.plataforma.value
						+"/"+todosLosMeses +"/"+variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}

			
			
			if ((variables.temporalidad=='semana')) {
			 
							let todasLasSemanas="";
							variables.semana.map((v) => todasLasSemanas+=v.value+",");			
							
							FetchData(
								"informes/contador/alertasTipoPlataformaTerrestreSemanas/" + variables.plataforma.value
									+"/"+todasLasSemanas +"/"+variables.anio.value,
								"Resultados",
								sets.setData,
								sets.setLoading,
								sets.setError,
								variables.mes.value
							);
						}			
			break;


		case "Tipos de alertas terrestre por línea de transporte":
			if ((variables.temporalidad=='mes')&&(variables.mes !== undefined) ) {
				let todosLosMeses="";
				variables.mes.map((v) => todosLosMeses+=v.value+",");			
				FetchData(
					"informes/contador/alertasTipoTransportistaTerrestreMeses/" +
						variables.plataforma.value +
						"/" +
						variables.transportista.value +
						"/" +
						todosLosMeses.slice(0, -1) +
						"/" + variables.anio.value
						,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='semana')&&(variables.semana !== undefined) /*!( errorTransportista || errorMes )&&variables.mes.value/*&& (variables.mes.length > 0)*/) {
				let todasLasSemanas="";
				variables.semana.map((v) => todasLasSemanas+=v.value+",");			
				FetchData(
					"informes/contador/alertasTipoTransportistaTerrestreSemanas/" +
						variables.plataforma.value +
						"/" +
						variables.transportista.value +
						"/" +
						todasLasSemanas +
						"/" + variables.anio.value
						,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			break;
		case "Tipos de incidencias terrestre por plataforma":
			if ((variables.temporalidad=='mes')&&(variables.mes !== undefined) && (variables.mes.length > 0)) {
				let todosLosMeses="";
				variables.mes.map((v) => todosLosMeses+=v.value+",");			
				FetchData(
					"informes/contador/incidenciasTipoPlataformaTerrestreMeses/" + variables.plataforma.value
					+"/"+todosLosMeses +"/"+variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			if ((variables.temporalidad=='semana')&&(variables.semana !== undefined) && (variables.semana.length > 0)) {
				let todasLasSemanas="";
				variables.semana.map((v) => todasLasSemanas+=v.value+",");			
				FetchData(
					"informes/contador/incidenciasTipoPlataformaTerrestreSemanas/" + variables.plataforma.value
					+"/"+todasLasSemanas +"/"+variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}
			break;
		case "Tipos de incidencias terrestre por línea de transporte":

		if ((variables.temporalidad=='mes')&&/*!( errorTransportista || errorMes )&&*/(variables.mes !== undefined) && (variables.mes.length > 0)) {
			let todosLosMeses="";
			variables.mes.map((v) => todosLosMeses+=v.value+",");			
			FetchData(
				"informes/contador/incidenciasTipoTransportistaTerrestreMeses/" +
				variables.plataforma.value +
				"/" +
				variables.transportista.value +
				"/" +
				todosLosMeses +
				"/" + variables.anio.value,
				"Resultados",
				sets.setData,
				sets.setLoading,
				sets.setError,
				variables.mes.value
			);
		}

			if ((variables.temporalidad=='semana')&&/*!( errorTransportista || errorMes )&&*/(variables.semana !== undefined) && (variables.semana.length > 0)) {
				let todasLasSemanas="";
				variables.semana.map((v) => todasLasSemanas+=v.value+",");			
				FetchData(
					"informes/contador/incidenciasTipoTransportistaTerrestreSemanas/" +
					variables.plataforma.value +
					"/" +
					variables.transportista.value +
					"/" +
					todasLasSemanas +
					"/" + variables.anio.value,
					"Resultados",
					sets.setData,
					sets.setLoading,
					sets.setError,
					variables.mes.value
				);
			}




			
			break;
		default:
			break;
	}
};

export default CheckForm;