import React, {  useEffect, useState} from "react";
import { List, Button, Icon } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateNumbers } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import { TablePageable } from "../../../../Helpers";

const SolicitudesCliente =({solicitud, fresh})=> {
  const [clientes, setClientes]= useState([]);
  const [cliente, setCliente]= useState([]);
  const [ clienteS, setclienteS]= useState(solicitud.clienteS? JSON.parse(solicitud.clienteS): null);

  useEffect(() => {
    getfuntion("get", "", "", "cliente", "clientes");
	}, []);
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if( stateVar === "clientes" && returnVal){
              setClientes(returnVal);
              //temp.push(obj.nombre+": "+timeToDateFill(new Date(returnVal.fecha).getTime())+", "+ returnVal.ubicacion);
            }
          }
          break;
        case "put":
          {
            if( stateVar === "solicitudes_cliente"){
              NotificationManager.info( "Solicitud actualizada", "Actualizacion" );
              fresh();
            }
          }
          break;
        case "post":
          {
            if( stateVar === "cliente"){
              return returnVal;
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { if (err.response) { console.log(err.response);  } else {console.log("Error desconocido ...." + err); } });
  }
  const crearCliente=()=>{
    let solicitudTemp= {...solicitud};
    getfuntion("post", clienteS, "", "cliente", "cliente").then((clienteRest) => {
      getfuntion(
        "post",
        { idPlataforma: solicitudTemp.idPlataforma.id, idCliente: clienteRest.id, },
        "",
        "plataformasClientes",
        "plataformasClientes"
      );
      getfuntion(
        "post",
        {idUsuario: solicitudTemp.idUsuarioCrea, idCliente: clienteRest.id, idPlataforma: solicitudTemp.idPlataforma.id },
        "",
        "cli_usuarios_clientes",
        "usuarios_clientes"
      );
      solicitudTemp.idCliente= clienteRest;
      getfuntion(
        "put",
        solicitudTemp,
        "",
        "solicitudes_cliente",
        "solicitudes_cliente"
      );
    });
  }
  const vincular=()=>{
    let solicitudTemp= {...solicitud};
    getfuntion(
      "post",
      { idPlataforma: solicitudTemp.idPlataforma.id, idCliente: cliente.id, },
      "",
      "plataformasClientes",
      "plataformasClientes"
    );

    getfuntion(
      "post",
      {idUsuario: solicitudTemp.idUsuarioCrea, idCliente: cliente.id, idPlataforma: solicitudTemp.idPlataforma.id },
      "",
      "cli_usuarios_clientes",
      "usuarios_clientes"
    );
    solicitudTemp.idCliente= cliente;
    getfuntion(
      "put",
      solicitudTemp,
      "",
      "solicitudes_cliente",
      "solicitudes_cliente"
    );
  }

  return (
    <div className= "mt-1">
      {clienteS &&
        <p>cliente=  
          nombre: { clienteS.nombre}
          , nombre corto: {clienteS.nombreCorto}
          , domicilio: {clienteS.domicilio}
          , contacto: {clienteS.contacto}
          , telefono: {clienteS.telefono}
          , extension: {clienteS.extension}
          , nota: {clienteS.nota}
          , latitud: {clienteS.latitud}
          , longitud: {clienteS.longitud}
          . Si el registro no existe en el sistema, 
            <a onClick={()=> 
              confirmAlert({
                title: 'Atención',
                message: '¿Esta usted seguro de utilizar el registro '+clienteS.nombre+'?',
                buttons: [
                  {
                    label: 'Confirmar',
                    onClick: () => { crearCliente() }
                  },
                  {
                    label: 'Cancelar',
                    onClick: () => {return false}
                  }
                ]
              })
            }
            href={null} className="btn text-primary"> crear registro con los datos proporcionados.</a>
        </p>
      }
      <TablePageable
        titulo={
          <div>            
            <button
              type="button"
              className="btn btn-primary float-right ml-4"
              hidden={!cliente.id}
              onClick={()=>
                confirmAlert({
                  title: 'Atención',
                  message: '¿Esta usted seguro de utilizar el registro '+cliente.nombre+'?',
                  buttons: [
                    {
                      label: 'Confirmar',
                      onClick: () => { vincular() }
                    },
                    {
                      label: 'Cancelar',
                      onClick: () => {return false}
                    }
                  ]
                })
              }
              >
              <i className="fa fa-plus" style={{"font-size": "24px"}}>Vincular a plataforma</i>
            </button>
            <h3 className="float-left"> Clientes </h3>            
          </div> }
        lista= { clientes }
        columnas={ [
          { columna: "id", label: "Id" },
          { columna: "nombre", label: "Nombre" },
          { columna: "domicilio", label: "Domicilio" },
          { columna: "contacto", label: "Contacto" },
          { columna: "telefono", label: "Tel" },          
        ] }
        id= "id"
        selected={(e)=> { setCliente(e) }}
        //csvname= ""
      />
    </div>

  );
}
export default SolicitudesCliente;
/*
{
            "nombre": "MYM AUTOS",
            "nombreCorto": "MYM AUTOS",
            "domicilio": "Rio Blanquito",
            "contacto": "",
            "telefono": "",
            "extension": "",
            "nota": "",
            "vip": null,
            "latitud": null,
            "longitud": null,
            "estatus": 1,
            "idGeocerca": "",
            "idPlataformaTipo": 1,
            "cron": "00:00:00",
            "wt": "12:00:00",
            "id": 48823
        }
*/
