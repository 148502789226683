import React, { Component } from "react";

import { Container, Grid, Icon, Button } from "tabler-react";
import Select from "react-select";

var tema= [{ value: 0, label: "Selecciona  un tema" }, { value: 1, label: "Defaul" }, { value: 2, label: "Oscuro" }];
var user= JSON.parse(sessionStorage.getItem("usuario"));
var perfil= JSON.parse(sessionStorage.getItem("perfil"));
var currentTema= user ?localStorage.getItem('skyonetema'+user.id): 0;

class Template extends Component{
  constructor(props) {
    super(props);
    this.state = { };
                 

  }
  getStyles =() =>{

  }
  componentDidMount =() => {
  }
  componentDidUpdate =() =>{
}
  
  render() {
    return (
      <div>         
        <div name="textplantilla"></div>
        <div>
          
        </div>
      </div>
    );
  }
}
export default Template;
