//React
import React, { useMemo } from 'react';
//Componentes externos
import { Button, Container } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAdd,
  faBell,
  faImage,
  faRotateLeft,
} from '@fortawesome/free-solid-svg-icons';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';
//Componentes
import { Loading } from '../../components/Loading/Loading';
import SiteWrapper from '../../SiteWrapper.react';
import CustomTable from '../../components/CustomTable/CustomTable';
import ModalLay from '../../components/ModalLay/ModalLay';
import DatosMovimientoGpsForm from '../../AgregarGps/MovimientoGps/DatosMovimientoGpsForm';
import MovimientoIncidencias from '../../AgregarGps/MovimientoGps/MovimientoIncidencias';
import { MovimientoGpsEvidenciaModal } from '../../components/MovimientoGpsEvidenciaModal/MovimientoGpsEvidenciaModal';
//Hooks
import { useGetData } from '../../hooks/useGetData';
import { useMutationData } from '../../hooks/useMutationData';
//Funciones
import { getfuntion } from './MovimientoPatioPageHelpers';
//Estilo
import classes from './MovimientosPatioPage.module.css';

/**
 * MovimientosPatioPage
 * @description: Pantalla que muestra la lista de reacciones generales
 * @date 16/10/2023.
 * @returns JSX del Screen.
 */
export const MovimientosPatioPage = () => {
  /* --------------------------- Variables ----------------------------------*/
  var user = JSON.parse(sessionStorage.getItem('usuario') ?? '');
  /* ----------------------------- Hooks ------------------------------------*/
  //Se encarga de traer la información
  const queryMovimientos = useGetData<any>({
    url: `globalMobile/movimientos_sin_gps`,
    name: ['movimientos', 'sin_gps'],
  });
  //Se encarga de traer la información
  const queryIncidencias = useGetData<any>({
    url: `incidencias`,
    name: ['incidencias'],
  });
  //Hook para mandar la petición
  const movimientoMutation = useMutationData({
    url: 'navieraMovimientos',
  });
  //Hook para mandar la petición
  const navieraMovimientosStatusMutation = useMutationData({
    url: 'navieraMovimientos',
  });
  //Hook para mandar la petición
  const movimientosPatioMutation = useMutationData({
    url: 'movimientosPatio',
  });
  //Hook para mandar la petición
  const gpsRemoverActivoMutation = useMutationData({
    url: 'gps/remover_activo',
    reload: ['movimientos', 'sin_gps'],
  });
  /* ------------------------------ Funciones -------------------------------*/
  //Función para resetear movimientos
  const reset = async (id: any) => {
    try {
      //Get navieraMovimientos
      const { data: movNav } = await movimientoMutation.mutateAsync({
        id,
        tipo: 'get',
      });
      const movimientoT = movNav;
      //Get movimientosPatio
      const { data: mPatio } = await movimientosPatioMutation.mutateAsync({
        id: `idMovimiento/${id}`,
        tipo: 'get',
      });
      const mPatio1 = mPatio;
      if (mPatio.esFull) {
        // Get full movement
        const { data: movNavFull } = await movimientoMutation.mutateAsync({
          id: mPatio.esFull,
          tipo: 'get',
        });

        if (movNavFull) {
          for (let item of movNavFull.movimientoGps) {
            //Eliminar GPS full
            const recGps = {
              gps: item.gps.id,
              plataforma: movNavFull.idPlataforma.id,
              estatus: 1,
              idMovimiento: item.idMovimiento,
            };
            await gpsRemoverActivoMutation.mutateAsync({
              data: recGps,
              tipo: 'post',
            });
          }
          await navieraMovimientosStatusMutation.mutateAsync({
            id: `movimiento/${movNavFull.id}/operacionestatus/1`,
            tipo: 'put',
          });
          // Reset patio
          const { data: mPatioFull } =
            await movimientosPatioMutation.mutateAsync({
              id: `idMovimiento/${movNavFull.id}`,
              tipo: 'get',
            });
          await movimientosPatioMutation.mutateAsync({
            id: mPatioFull.id,
            tipo: 'delete',
          });
        }
      }
      //Eliminar movimiento patio
      await movimientosPatioMutation.mutateAsync({
        id: mPatio1.id,
        tipo: 'delete',
      });
      //Remover GPS's
      for (let item of movimientoT.movimientoGps) {
        await navieraMovimientosStatusMutation.mutateAsync({
          id: `movimiento/${item.idMovimiento}/operacionestatus/1`,
          tipo: 'put',
        });

        const recGps = {
          gps: item.gps.id,
          plataforma: movimientoT.idPlataforma.id,
          estatus: 1,
          idMovimiento: item.idMovimiento,
        };
        await gpsRemoverActivoMutation.mutateAsync({
          data: recGps,
          tipo: 'post',
        });
      }
      NotificationManager.info(`Reseteo del movimiento ${id} exitoso`);
    } catch (error) {
      NotificationManager.info(
        'Error al momento de resetear movimiento y/o gps.'
      );
    }
  };
  /* ------------------------------- Columnas -------------------------------*/
  const columns = useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'Movimiento',
        accessorKey: 'idMovimiento',
        accessorFn: (row) => row.idMovimiento.toString(),
      },
      {
        header: 'Fecha de creación',
        accessorKey: 'creationDate',
        accessorFn: (row) =>
          moment(row.creationDate).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        header: 'Cita',
        accessorKey: 'cita',
        accessorFn: (row) =>
          row.cita ? moment(row.cita).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        header: 'Estatus operación',
        accessorKey: 'idOperacionEstatus',
        accessorFn: (row) => {
          if (row.idOperacionEstatus === 1) {
            return 'Sin GPS';
          } else if (row.idOperacionEstatus === 2) {
            return 'En ruta';
          }
        },
      },
      {
        id: 'gps',
        header: 'Gps',
        accessorFn: (row) =>
          row.gps
            ? row.gps
            : row.esfull
            ? 'full con: ' + row.esfull
            : row.programado
            ? 'programado'
            : '',
        cell: (info) => info.getValue(),
      },
      {
        header: 'IMEI',
        accessorKey: 'imei',
      },
      {
        header: 'Po',
        accessorKey: 'po',
      },
      {
        header: 'Booking',
        accessorKey: 'booking',
      },
      {
        header: 'Contenedor',
        accessorKey: 'contenedor',
      },
      {
        header: 'Transportista',
        accessorKey: 'transportista',
      },
      {
        header: 'Localidad',
        accessorKey: 'localidad',
      },
      {
        header: 'Tipo de movimiento',
        accessorKey: 'movimientoTipo',
      },
      {
        header: 'Corredor',
        accessorKey: 'corredor',
      },
      {
        header: 'Plataforma',
        accessorKey: 'plataforma',
      },
      {
        header: 'Acciones',
        cell: (info) => (
          <fieldset className="btn-group" role="group" aria-label="Ejemplo">
            {/** Modal para agregar un GPS*/}
            <ModalLay
              tamanio={'99%'}
              title="GPS Movimiento"
              name={
                <Button title="Agregar Gps" className={classes.boton}>
                  <FontAwesomeIcon icon={faAdd} />
                </Button>
              }
              formulario={DatosMovimientoGpsForm}
              datosForm={{
                movimientos: queryMovimientos.data,
                movimiento: {
                  idMovimiento: info.row.original.idMovimiento,
                  movimientoTipo: info.row.original.movimientoTipo,
                  corredor: info.row.original.corredor,
                  esFull: info.row.original.esFull,
                  po: info.row.original.po,
                  contenedor: info.row.original.contenedor,
                },
                idMovimiento: info.row.original.idMovimiento,
                usuario: user.persona,
                plataformaid: info.row.original.plataformaId,
                refetch: queryMovimientos.refetch,
              }}
            />
            {/** Modal para agregar una nueva incidencia*/}
            <ModalLay
              tamanio={'98%'}
              title="Nueva incidencia"
              name={
                <Button title="Nueva incidencia" className={classes.boton}>
                  <FontAwesomeIcon icon={faBell} />
                </Button>
              }
              formulario={MovimientoIncidencias}
              datosForm={{
                accion: 'nuevo',
                incidencias: queryIncidencias.data,
                idusuario: user.id,
                idmovimiento: info.row.original.idMovimiento,
                getfuntion: getfuntion,
              }}
            />
            {/** Modal para ver las evidencias de colocación*/}
            {info.row.original.idOperacionEstatus === 2 && (
              <ModalLay
                tamanio={1200}
                title="Evidencias colocación"
                name={
                  <Button title="Agregar Gps" className={classes.boton}>
                    <FontAwesomeIcon icon={faImage} />
                  </Button>
                }
                formulario={MovimientoGpsEvidenciaModal}
                datosForm={{
                  movimiento_id: info.row.original.idMovimiento,
                  gps: info.row.original.gps,
                }}
              />
            )}
            {/** Botón para resetear el movimiento y los gps's*/}
            {(info.row.original.gps ||
              (!info.row.original.gps && info.row.original.esfull)) && (
              <label className="transparent-input thumbnail">
                <Button
                  title="Resetear"
                  className={classes.boton}
                  onClick={() => reset(info.row.original.idMovimiento)}
                >
                  <FontAwesomeIcon icon={faRotateLeft} />
                </Button>
              </label>
            )}
          </fieldset>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [queryMovimientos, queryIncidencias, getfuntion, reset]
  );
  /* -------------------------------- return --------------------------------*/
  //Return loading
  if (queryMovimientos.isLoading || queryIncidencias.isLoading) {
    return <Loading />;
  }
  //Return principal
  return (
    <SiteWrapper>
      <Container fluid className="p-4">
        <CustomTable
          {...{
            title: 'Movimientos patio',
            data: queryMovimientos.data,
            columns,
            refetch: queryMovimientos.refetch,
          }}
        />
      </Container>
    </SiteWrapper>
  );
};
