import React, { useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";

import {MenuDashboardTerrestre} from "./MenuDashboardTerrestre.react";
import {ContentDashboardTerrestre} from "./ContentDashboardTerrestre.react";
import {LoadingScreen} from "../../components/LoadingScreen";

const DashboardTerrestrePage = () => {
	
	//Variables check loading y error
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);

	return (
		<SiteWrapper>
				<div className="container">
					<MenuDashboardTerrestre 
						setLoading={setLoading}
						setError={setError}
						setData={setData}
					/>
					<ContentDashboardTerrestre
						data={data}
					/>
				</div>
			{loading ? (
				<LoadingScreen
					loading={loading}
				/>
			):null}
			
		</SiteWrapper>
	);
};

export default DashboardTerrestrePage;
