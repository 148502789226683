import React, { useEffect, useState } from "react";
import { Button, Icon} from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { NotificationManager } from "react-notifications";
import { TablePageable } from "../../../Helpers";
//import { TablePageable } from "../../../Helpers";


const TarifarioSubasta = ({
    stor,
    plataforma,
}) => {
    const [formulario, setFormulario] = useState({
      localidad: "",
      tarifa: 0,
      terminal: "",
      idPlataforma: plataforma.id,
    });

    const [tarifarioSubastasList, setTarifarioSubastasList] = useState([]);
    const [crudbutonEnable, setcrudbutonEnable] = useState(true);
    const [nuevo, setNuevo]= useState(false);

    const isEnable = (Accion) => {
        let borrar = "corredores_borrar";
        let actualizar = "corredores_editar";
        let agregar = "corredores_agregar";
        const privilegios = stor.privilegios_object;
        var perfil = JSON.parse(sessionStorage.getItem("perfil"));
        for (let i = 0; i < privilegios.length; i++) {
          if (Accion === "borrar") {
            if (privilegios[i].clave == borrar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
          if (Accion === "actualizar") {
            if (privilegios[i].clave == actualizar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
          if (Accion === "agregar") {
            if (privilegios[i].clave == agregar) {
              if (
                perfil.privilegios.filter(
                  (word) => word.idPrivilegio == privilegios[i].id
                ).length > 0
              ) {
                return true;
              }
            }
          }
        }
        return false;
      };

    useEffect(() => {
      getfuntion( "get", "", "", "tarifarioSubasta/plataforma/" + plataforma.id, "tarifarioSubasta" );
    },[]);

    const disableSave = () => {
        if( formulario.localidad.length)
          return false;
        return true;
    };
    const crud_delete = () => {
      console.log(formulario);
        if(formulario.id){
          setcrudbutonEnable(false);
          getfuntion("delete", formulario, formulario.id, "tarifarioSubasta", "tarifarioSubasta" );
        }
      };
      const crud_put = () => {
        if(formulario.id){
          setcrudbutonEnable(false);
          getfuntion("put", formulario, "", "tarifarioSubasta", "tarifarioSubasta" );
        }
      };
      const crud_post = () => {
        if(!formulario.id){
          setcrudbutonEnable(false);
          getfuntion("post", formulario, "", "tarifarioSubasta", "tarifarioSubasta" );
        }
      };
      const clear =()=>{
        setFormulario({
            localidad: "",
            tarifa: 0,
            terminal: null,
            idPlataforma: plataforma.id,
        });
        setNuevo(false);
      }

    const getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "", stateVar = "",hiddenModl = "" ) => {
        Crud_Catalogos( catalogo, "plataforma", metodo, id, obj, "", stateVar, hiddenModl, [])
          .then((returnVal) => {
            switch (metodo) {
              case "get":
                {
                  if (stateVar === "tarifarioSubasta" && returnVal) {
                    setTarifarioSubastasList(returnVal);
                  }
                }
                break;
              case "post":
                {
                  if (stateVar === "tarifarioSubasta" && returnVal) {
                    clear();
                    let tarifarioSubastaTemp= [...tarifarioSubastasList];
                    tarifarioSubastaTemp.unshift(returnVal);
                    setTarifarioSubastasList(tarifarioSubastaTemp); 
                    setcrudbutonEnable(true);
                    NotificationManager.info( "Se a generado registro nuevo: "+returnVal.localidad, "Registro guardado");
                  }
                }
                break;
              case "put":
                {
                  if (stateVar === "tarifarioSubasta" && returnVal) {
                    clear();
                    setcrudbutonEnable(true);
                    getfuntion( "get", "", "", "tarifarioSubasta/plataforma/" + plataforma.id, "tarifarioSubasta" );
                    NotificationManager.info( "Se a actualizado registro: "+returnVal.localidad, "Registro guardado");
                  }
                }
                break;
              case "delete":
                {
                  if (stateVar === "tarifarioSubasta") {
                    clear();
                    if( returnVal ){
                      NotificationManager.info( "Se ha borrado registro: "+obj.localidad, "Borrar registro");
                      getfuntion( "get", "", "", "tarifarioSubasta/plataforma/" + plataforma.id, "tarifarioSubasta" );
                    }else{
                      NotificationManager.error( "No ha sido posible borrar registro: "+obj.localidad, "Borrar registro");
                    }
                  }
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => { console.log(err); });
      };

  return (
    <div>
      <div hidden={ nuevo || formulario.id }>
      <button type="button" className="btn btn-outline-primary" onClick={()=>setNuevo(true)}>Nuevo</button>
      </div>
      <div className="row" hidden={ !nuevo && !formulario.id }>
        <div className="col">
          <div className="form-group">
            <label for="localidad">Localidad</label>
            <input
              type="text"
              className="form-control"
              id="localidad"
              placeholder="Ingresa localidad"
              aria-describedby="localidadHelp"
              value={formulario.localidad}
              onChange={(e) => setFormulario({ ...formulario, localidad: e.target.value })}
            />
            <small id="localidadHelp" className="form-text text-muted">Ingresa el nombre de la localidad.</small>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label for="tarifa">Tarifa</label>
            <input
              type="text"
              className="form-control"
              id="tarifa"
              placeholder="Ingresa Tarifa"
              aria-describedby="tarifaHelp"
              value={formulario.tarifa}
              onChange={(e) => setFormulario({ ...formulario, tarifa: e.target.value })}
            />
            <small id="tarifaHelp" className="form-text text-muted">Ingresa tarifa para la localidad {formulario.localidad}.</small>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label for="terminal">Terminal</label>
            <input
              type="text"
              className="form-control"
              id="terminal"
              placeholder="Ingresa Terminal"
              aria-describedby="terminalHelp"
              value={formulario.terminal}
              onChange={(e) => setFormulario({ ...formulario, terminal: e.target.value })}
            />
            <small id="terminalHelp" className="form-text text-muted">Ingresa terminal para la localidad {formulario.localidad}.</small>
          </div>
        </div>
        <div className="float-right">
          {isEnable("actualizar") && isEnable("agregar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={disableSave() || !crudbutonEnable}
                onClick={()=>{ formulario.id? crud_put() : crud_post();}}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          { isEnable("borrar") ? (
            <span className="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => crud_delete()}
                disabled={!crudbutonEnable}
              >
                <span className="badge">
                  <Icon link={true} name="trash" color="red" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
          <span className="badge">
            <button type="button" className="btn btn-outline-primary btn-sm text-danger" onClick={()=>clear()}>
              <i class="fa fa-close" style={{"font-size": "24px"}}></i>
            </button>
          </span>
        </div>
      </div>
      <hr/>
      <hr/>
      <div className="row" hidden={!tarifarioSubastasList || !tarifarioSubastasList.length || nuevo || formulario.id }>
        <div className="col-12">
          <TablePageable
            titulo={ <h5>Tarifario Localidades de la plataforma {plataforma.nombre}</h5> }
            lista= { tarifarioSubastasList }
            columnas={
              [
                { columna: "id", label: "Id" },
                { columna: "localidad", label: "Localidad" },
                { columna: "tarifa", label: "Tarifa" },
                { columna: "terminal", label: "Terminal" },
              ]
            }
            id= "id"
            selected={(e)=>{ setFormulario(e) }}
            csvname= {"tarifario Localidades de la plataforma "+ plataforma.nombre}
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
        </div>
      </div>
    </div>
  )
}

export default TarifarioSubasta;
