import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../../herramientas/Crud_Catalogos";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
/**
 * Date tools
 */
import { timeToDateFill, fechaValida } from "../../../../herramientas/DateFormat";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
const user = JSON.parse(sessionStorage.getItem("usuario"));
const perfil = JSON.parse(sessionStorage.getItem("perfil"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}

class ActualizarHorariosTerrestres extends Component {
  constructor(props) {    
    super(props);
    this.state = {
      camposLogisticos: [],
      fechaArribo: "",
      fechaSalida: "",
      tiempoEstadia: "",
      tiempoTraslado: "",
      fechaCambio: "",
      citaCarga: "",
      citaDescarga: "",
      inicioRuta: "",
      arriboUnidadContenedor: "",
      salidaUnidadContenedor: "",
      arriboTerminalCargado: "",
      arriboDescarga: "",
      entregaDocumentos: "",
      ingresoBodega: "",
      //campo                   : "",
      ruta: { value: 0, label: "Selecciona Estatus de ruta" },
    };
    this.movimientoObj = [];
    this.entrega = {
      arriboDescarga: "",
      arriboTerminalCargado: "",
      arriboUnidadContenedor: "",
      campo: "",
      citaCarga: "",
      citaDescarga: "",
      contacto: "",
      destino: "",
      direccion: "",
      entregaDocumentos: "",
      estatus: 0,
      eta: 0,
      fechaAlta: "",
      fechaArribo: "",
      fechaCambio: "",
      fechaSalida: "",
      idDestino: 1,
      idEntrega: 0,
      idMovimiento: 0,
      idRuta: 0,
      ingresoBodega: "",
      inicioRuta: "",
      latitud: 0,
      longitud: 0,
      orden: 1,
      salidaUnidadContenedor: "",
      tiempoEstadia: null,
      tiempoTraslado: null,
    };
    this.rutasEstatus = [];
  }

  componentWillMount = () => {
    this.getfuntion(
      "get",
      "",
      "",
      "camposLogisticos/plataforma/" +
        this.props.movimiento.plataforma.id +
        "/mtipo/1",
      "camposLogisticos"
    ); //this.props.movimiento.movimientoTipo.id
    this.rutasEstatus.push({ value: 0, label: "Selecciona Estatus de ruta" });
    this.props.rutasEstatusObject.map((elemento, index) => {
      this.rutasEstatus.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      });
    });
  };
  componentDidMount = () => {
    try {
      this.entrega = this.props.idEntregaActiva;
    } catch (e) {
      console.log(e);
    }

    this.movimientoObj = this.props.movimiento;
    this.entrega.idMovimiento = this.props.movimiento.id;
    this.entrega.campo = "";
    if (this.state.accion !== "nuevo") {
      for (let item of this.rutasEstatus) {
        if (item.value == this.props.idEntregaActiva.idRuta) {
          this.setState({ ruta: item });
        }
      }
      this.setState({
        tiempoEstadia: this.props.idEntregaActiva.tiempoEstadia
          ? this.props.idEntregaActiva.tiempoEstadia
          : "",
      });
      this.setState({
        tiempoTraslado: this.props.idEntregaActiva.tiempoTraslado
          ? this.props.idEntregaActiva.tiempoTraslado
          : "",
      });
      this.setState({
        fechaCambio: this.props.idEntregaActiva.fechaCambio
          ? new Date(this.props.idEntregaActiva.fechaCambio)
          : "",
      });
      this.setState({
        fechaArribo: this.props.idEntregaActiva.fechaArribo
          ? new Date(this.props.idEntregaActiva.fechaArribo)
          : "",
      });
      this.setState({
        fechaSalida: this.props.idEntregaActiva.fechaSalida
          ? new Date(this.props.idEntregaActiva.fechaSalida)
          : "",
      });
      this.setState({
        citaCarga: this.props.idEntregaActiva.citaCarga
          ? new Date(this.props.idEntregaActiva.citaCarga)
          : "",
      });
      this.setState({
        citaDescarga: this.props.idEntregaActiva.citaDescarga
          ? new Date(this.props.idEntregaActiva.citaDescarga)
          : "",
      });
      this.setState({
        inicioRuta: this.props.idEntregaActiva.inicioRuta
          ? new Date(this.props.idEntregaActiva.inicioRuta)
          : "",
      });
      this.setState({
        arriboUnidadContenedor: this.props.idEntregaActiva
          .arriboUnidadContenedor
          ? new Date(this.props.idEntregaActiva.arriboUnidadContenedor)
          : "",
      });
      this.setState({
        salidaUnidadContenedor: this.props.idEntregaActiva
          .salidaUnidadContenedor
          ? new Date(this.props.idEntregaActiva.salidaUnidadContenedor)
          : "",
      });
      this.setState({
        arriboTerminalCargado: this.props.idEntregaActiva.arriboTerminalCargado
          ? new Date(this.props.idEntregaActiva.arriboTerminalCargado)
          : "",
      });
      this.setState({
        arriboDescarga: this.props.idEntregaActiva.arriboDescarga
          ? new Date(this.props.idEntregaActiva.arriboDescarga)
          : "",
      });
      this.setState({
        entregaDocumentos: this.props.idEntregaActiva.entregaDocumentos
          ? new Date(this.props.idEntregaActiva.entregaDocumentos)
          : "",
      });
      this.setState({
        ingresoBodega: this.props.idEntregaActiva.ingresoBodega
          ? new Date(this.props.idEntregaActiva.ingresoBodega)
          : "",
      });
    }
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "camposLogisticos") {
                this.setState({ camposLogisticos: returnVal });
              }
            }
            break;
          case "put":
            if (stateVar === "movimientos") {
              this.usuariosLogObject.idBitacoraMonitoreo = 2;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = obj.id; //returnVal.id;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Actualizacion de Entrega de Movimiento Terrestre id:" +
                obj.id +
                " Campo: " +
                this.state.campo; //returnVal.id;
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
            }
            if (stateVar === "entregas") {
              this.props.cerrarModal();
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.stor.privilegios_object;
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          this.privilegio = privilegios[i];
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
  crud_put = () =>
    this.getfuntion("put", this.entrega, "", "entregas", "entregas");

  terminarMovimiento = () => {
    let opEstatus = this.props.stor.operacionesEstatus;
    for (let item of opEstatus) {
      if (item.nombre.toUpperCase().includes("TERMINADO")) {
        this.movimientoObj.operacionEstatus = item;
      }
    }
    confirmAlert({
      title: "Atención",
      message:
        "¿Esta seguro de terminar el movimiento(" +
        this.props.movimiento.id +
        ") ?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            this.props.getfuntion(
              "put",
              this.movimientoObj,
              "",
              "terrestreMovimientos",
              "terrestreMovimientos",
              "",
              this.props.closeModal
            );
          },
        },
        {
          label: "Cancelar",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };
  changeRuta = (event) => {
    this.entrega.idRuta = event.value;
    this.setState({ ruta: event });
    this.entrega.campo = this.entrega.campo + " idRuta: " + this.entrega.idRuta;
  };
  onChangeTiempoEstadia = (event) => {
    this.entrega.tiempoEstadia = event.target.value + ":00";
    this.setState({ tiempoEstadia: event.target.value + ":00" });
    this.entrega.campo =
      this.entrega.campo + " tiempoEstadia: " + event.target.value + ":00";
  };
  onChangeTiempoTraslado = (event) => {
    this.entrega.tiempoTraslado = event.target.value + ":00";
    this.setState({ tiempoTraslado: event.target.value + ":00" });
    this.entrega.campo =
      this.entrega.campo + " tiempoTraslado: " + event.target.value + ":00";
  };
  onChangeFechaCambio = (date) => {
    this.entrega.fechaCambio = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ fechaCambio: date });
    this.entrega.campo =
      this.entrega.campo + " fechaCambio: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeFechaArribo = (date) => {
    this.entrega.fechaArribo = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ fechaArribo: date });
    this.entrega.campo =
      this.entrega.campo + " fechaArribo: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeFechaSalida = (date) => {
    this.entrega.fechaSalida = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ fechaSalida: date });
    this.entrega.campo =
      this.entrega.campo + " fechaSalida: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeCitaDescarga = (date) => {
    this.entrega.citaDescarga = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ citaDescarga: date });
    this.entrega.campo =
      this.entrega.campo + " citaDescarga: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeCitaCarga = (date) => {
    this.entrega.citaCarga = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ citaCarga: date });
    this.entrega.campo =
      this.entrega.campo + " citaCarga: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeInicioRuta = (date) => {
    this.entrega.inicioRuta = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ inicioRuta: date });
    this.entrega.campo =
      this.entrega.campo + " InicioRuta: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeArriboUnidadContenedor = (date) => {
    this.entrega.arriboUnidadContenedor = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ arriboUnidadContenedor: date });
    this.entrega.campo =
      this.entrega.campo + " arriboUnidadContenedor: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeSalidaUnidadContenedor = (date) => {
    this.entrega.salidaUnidadContenedor = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ salidaUnidadContenedor: date });
    this.entrega.campo =
      this.entrega.campo + " salidaUnidadContenedor: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeArriboTerminalCargado = (date) => {
    this.entrega.arriboTerminalCargado = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ arriboTerminalCargado: date });
    this.entrega.campo =
      this.entrega.campo + " arriboTerminalCargado: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeArriboDescarga = (date) => {
    this.entrega.arriboDescarga = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ arriboDescarga: date });
    this.entrega.campo =
      this.entrega.campo + " arriboDescarga: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeEntregaDocumentos = (date) => {
    this.entrega.entregaDocumentos = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ entregaDocumentos: date });
    this.entrega.campo =
      this.entrega.campo + " entregaDocumentos: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };
  onChangeIngresoBodega = (date) => {
    this.entrega.ingresoBodega = date
      ? timeToDateFill(new Date(date).getTime())
      : null;
    this.setState({ ingresoBodega: date });
    this.entrega.campo =
      this.entrega.campo + " ingresoBodega: " + date
        ? timeToDateFill(new Date(date).getTime())
        : "vacio";
  };

  getCamposLogisticos = () => {
    let camposLogist = [];
    this.state.camposLogisticos.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case "cita_carga":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.citaCarga}
                    onChange={this.onChangeCitaCarga}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeCitaCarga(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "cita_descarga":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.citaDescarga}
                    onChange={this.onChangeCitaDescarga}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeCitaDescarga(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "inicio_ruta":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.inicioRuta}
                    onChange={this.onChangeInicioRuta}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeInicioRuta(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "fecha_arribo":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.fechaArribo}
                    onChange={this.onChangeFechaArribo}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeFechaArribo(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "fecha_salida":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.fechaSalida}
                    onChange={this.onChangeFechaSalida}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeFechaSalida(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_unidad_contenedor":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.arriboUnidadContenedor}
                    onChange={this.onChangeArriboUnidadContenedor}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() =>
                      this.onChangeArriboUnidadContenedor(new Date())
                    }
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "salida_unidad_contenedor":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.salidaUnidadContenedor}
                    onChange={this.onChangeSalidaUnidadContenedor}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() =>
                      this.onChangeSalidaUnidadContenedor(new Date())
                    }
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_terminal_cargado":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.arriboTerminalCargado}
                    onChange={this.onChangeArriboTerminalCargado}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() =>
                      this.onChangeArriboTerminalCargado(new Date())
                    }
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "arribo_descarga":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.arriboDescarga}
                    onChange={this.onChangeArriboDescarga}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeArriboDescarga(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "entrega_documentos":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.entregaDocumentos}
                    onChange={this.onChangeEntregaDocumentos}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeEntregaDocumentos(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;
        case "ingreso_bodega":
          {
            camposLogist.push(
              <Grid.Col md={4} lg={4}>
                <Form.Group label={cLog.nombre}>
                  <DateTimePicker
                    format="y-MM-dd h:mm:ss a"
                    value={this.state.ingresoBodega}
                    onChange={this.onChangeIngresoBodega}
                  />
                  <span> </span>
                  <Button
                    target="_blank"
                    size="sm"
                    color="primary"
                    onClick={() => this.onChangeIngresoBodega(new Date())}
                  >
                    <span class="badge">
                      <Icon link={true} name="update" />
                      Now
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            );
          }
          break;

        default:
          break;
      }
    });
    return camposLogist;
  };
  render() {
    return (      
      <div>
        {/*console.log(this.entrega)*/}        
        {this.state.accion === "modificar" ? (
          <h1> {this.props.idEntrega.destino} </h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group isRequired label="Estatus ruta">
              {" "}
              <Select
                value={this.state.ruta}
                onChange={this.changeRuta}
                options={this.rutasEstatus}
              />{" "}
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>{this.getCamposLogisticos()}</Grid.Row>
        <Grid.Row>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Fecha Cambio">
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                value={this.state.fechaCambio}
                onChange={this.onChangeFechaCambio}
              />
              <span> </span>
              <Button
                target="_blank"
                size="sm"
                color="primary"
                onClick={() => this.onChangeFechaCambio(new Date())}
              >
                <span class="badge">
                  <Icon link={true} name="update" />
                  Now
                </span>
              </Button>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Tiempo Estadia">
              <Form.Input
                type="time"
                name="estadia"
                value={this.state.tiempoEstadia}
                onChange={this.onChangeTiempoEstadia}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4} lg={4}>
            <Form.Group label="Tiempo Traslado">
              <Form.Input
                type="time"
                name="traslado"
                value={this.state.tiempoTraslado}
                onChange={this.onChangeTiempoTraslado}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col>
            <Form.Group className="text-red" label={ (this.entrega.inicioRuta != null && fechaValida(this.entrega.inicioRuta) === false)
              || (this.entrega.fechaArribo != null && fechaValida(this.entrega.fechaArribo) === false)
              || (this.entrega.fechaSalida != null && fechaValida(this.entrega.fechaSalida) === false) 
              ||(this.entrega.citaCarga != null && fechaValida(this.entrega.citaCarga) === false) ? <h4>"No puedes actualizar los datos (Botón de Guardar inhabilitado) ya que una fecha esta incorrecta"</h4> : null }>              
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        {/*<div>{ fechaValida(this.entrega.inicioRuta) }</div>*/}
        <div className="float-right">        
            <span class="badge"> 
              <Button
                disabled =  { !( (this.entrega.inicioRuta == null || (this.entrega.inicioRuta && fechaValida(this.entrega.inicioRuta) === true )) 
                  && ( this.entrega.fechaArribo == null || (this.entrega.fechaArribo && fechaValida(this.entrega.fechaArribo) === true )) 
                  && ( this.entrega.fechaSalida == null || (this.entrega.fechaSalida && fechaValida(this.entrega.fechaSalida) === true )) 
                  && ( this.entrega.citaCarga == null || (this.entrega.citaCarga && fechaValida(this.entrega.citaCarga) === true )) ) } 
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.crud_put()}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>         
          <span class="badge">
          {this.estaPrivilegioActivo("terminar_movimiento_terrestre") ?
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="danger"
              onClick={() => this.terminarMovimiento()}
            >
              {" "}
              <span class="badge">
                <Icon link={true} name="trash" />
                Terminar Movimiento
              </span>{" "}
            </Button>
             :null }
          </span>
        </div>
      </div>
    );
  }
}
export default ActualizarHorariosTerrestres;
