import React, { Component } from "react";
import { Form } from "tabler-react";
import TabsLayout from "../../../components/TabsLayout.react";
import Select from "react-select";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
import {
  UsuarioClientes,
  UsuarioClientesFinal,
  UsuarioCorredores,
  UsuarioLocalidades,
  UsuarioMovimientosTipo,
  UsuarioTransportistas
} from "./";

var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


class UsuarioCliente extends Component {
    constructor() {
        super();
        this.state = {
            currentTap: 0,
            plataforma: {value: 0, label:"Seleccione una plataforma"},
        };
        this.tabs = [];
        this.plataformas= [];
    }
    componentWillMount(){
      this.props.plataformas.map((plt, index) => {
        this.plataformas.push({ value: plt.id, label: plt.nombre, data: plt });
      });
    }
    selectedTab = (index) => this.setState({ currentTap: index });
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
      return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
          /*** filtro de variables  */
          switch (metodo) {
            case "get":
                if (stateVar === "perfil"){ return returnVal }
              break;
            default:
              break;
          }
        }).catch(err =>{
            console.log(err);
      })
    }
    changePlataforma=(e)=>{
      this.setState({ plataforma: e});
      this.tabs = [
        {
          title: "Usuarios Clientes",
          icon: "user",
          panel: <UsuarioClientes  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        },
        {
          title: "Usuarios ClientesFinal",
          icon: "user",
          panel: <UsuarioClientesFinal  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        },
        {
          title: "Usuarios Corredores",
          icon: "user",
          panel: <UsuarioCorredores  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        },
        {
          title: "Usuarios Localidades",
          icon: "user",
          panel: <UsuarioLocalidades  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        },
        {
          title: "Usuarios Movimientos Tipo",
          icon: "user",
          panel: <UsuarioMovimientosTipo  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        },
        {
          title: "Usuarios Transportistas",
          icon: "user",
          panel: <UsuarioTransportistas  usuario={this.props.usuario}  idPlataforma={e.value} />,
          close: false,
        }        
      ];
    }
    
    render() {
        return (
            <div>
              <Form.Group label="Solo se muestran las plataformas asignadas">
                <Select value={this.state.plataforma} onChange={this.changePlataforma} options={this.plataformas} />
              </Form.Group>
              { this.state.plataforma.value?
                <TabsLayout
                  tabs={this.tabs}
                  selectedTab={this.state.currentTap}
                  selectTab={this.selectedTab}
                />
                :null
              }
              <br/>
            </div>
        );
    }
}

export default UsuarioCliente;