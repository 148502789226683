import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class  Otd extends Component {
  constructor(props) {
    super(props);
    this.state = { 
                      id: "",
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
                     Otd:[],
                  search:""
                 };
                               this.Otd= [];
                 this.plataformasObject= [];
                         this.checarOtd= 0;      //temporal render
                   this.idPlataformaOtd= 0; //temporal render
              this.plataformasOtdObject= [];
                          this.otdArray= [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "plataformasOtd/idPlataforma/"+this.props.elementos.id,"Otd")
    }else{
      this.plataformasObject= {
                                "id": "",
                                "nombre": "",
                                "clave": "",
                                "corredores": [],
                                "plataformaTipo": {
                                  "id": "",
                                  "nombre": ""
                                }
                              };
    }
  }
/*
  shouldComponentUpdate(nextProps, nextState) {
    console.log(nextProps, nextState);
    console.log(this.props, this.state);
    
    if(this.state.Otd !== nextState.Otd){
      console.log("update")
      return true
    }else{
      return false;  
    } 
  }
  */
getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
  Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Otd"){ this.Otd= returnVal; this.setState({ Otd: returnVal }); }
          }
          break;
        case "post":
          {
           if (stateVar === "Otd"){
              this.Otd.push(returnVal);
              this.setState({ Otd: this.Otd });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Otd"){
              this.Otd.map((elemento, index)=>
                {if(id === elemento.id){
                  this.Otd.splice(index, 1);
                }}
              )
              this.setState({ Otd: this.Otd })
            }
          }
            break;
        default:
          break;
      }
    }).catch(err =>{
      if( err.response ){
        console.log(err.response);
      }else{
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
  })
}
  
  changeOtd =(OtdIndex, OtdId, OtdStatus) => {
      this.plataformasOtdObject={
        "idPlataforma": this.plataformasObject,
        "idOtd": this.props.stor.otd_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[OtdIndex]
      }  
      
      this.otdArray[OtdIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.otdArray[OtdIndex].id, "plataformasOtd", "Otd")
      :
      this.getfuntion("post",this.plataformasOtdObject, "", "plataformasOtd", "Otd");
    }
    checkedOtd =(indice) =>{
      return this.otdArray[indice].estatus
    }
    checarOtds =(estatus, id) =>{
      this.otdArray.push({ estatus: estatus, id: id })
    }
    checarOtdTem =(valor) =>{
      this.checarOtd=valor;
    }
    searching =(event) =>{
      this.setState({ search: event.target.value })
    }
    cleanArrayList =() =>{
      this.otdArray= []
    }
  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        { this.cleanArrayList() }
        { this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Otd"
                  isCollapsible={ false }
                  //isClosable
                  isCollapsed= { false}
                  options={ <Form.Group>
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Otd..."
                                position="append"
                                onChange={this.searching}
                              />
                            </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.otd_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["nombre"] }</Table.Col>
                                  <Table.Col>{ elemento["descripcion"] }</Table.Col>
                                  { this.checarOtdTem(0)}
                                  { this.idPlataformaOtd="" }
                                  { 
                                    this.state.Otd.map((elemOtd, ind) =>
                                    {
                                      if(elemOtd.idOtd.id === elemento.id){
                                        { this.checarOtdTem(1)}
                                        this.idPlataformaOtd=elemOtd.id;
                                      }
                                    })
                                  }
                                  { this.checarOtds(this.checarOtd, this.idPlataformaOtd)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                          checked={ this.checkedOtd(index) }
                                                          className={"disabled"}
                                                          onChange={ () => this.changeOtd(index, elemento["id"], this.checarOtd) } /></Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default Otd
