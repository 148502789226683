import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import './excel.css';
import './modal.css'
import $ from 'jquery';
//import * as ACTIONS from '../../store/actions/actions';
//import { connect } from 'react-redux';
//import ModalLayout from "../../components/ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../Crud_Catalogos';
import { Dimmer } from "tabler-react";
//import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

var     id	=0;
var terrores=0;
let sinError=0;
var dia			=0;
var mes			=0;
var anio		=0;
var hora 		=0;
var minuto	=0;
var segundo =0;

var fechas	=0;
var horas		=0;
var arrayErr=[];
let Errores	=[];
let FilaErr	=[];

const fdatetime	= 'yyyy-mm-dd HH:mm:ss';
const fdate			= 'yyyy-mm-dd';
const fhora			= 'HH:mm:ss';
const nobis 		= [31,28,31,30,31,30,31,31,30,31,30,31]
const sbiss 		= [31,29,31,30,31,30,31,31,30,31,30,31]


function myModal(Muestra) {
  console.log("ImportarC: "+Muestra);
  var x = document.getElementById("xlsModalC");
  x.style.display = Muestra;
}
function bisiesto(year){	return year % 4 == 0 && year % 100 != 0 || year % 400 == 0; }
function pad(number) {  if (number < 10) { return '0' + number; }  return number; }
function myFormato(date) {  console.log(date);	var nuevos = new Date(date);
  return nuevos.getFullYear() + '-' + pad(nuevos.getMonth() + 1) + '-' + pad(nuevos.getDate());
}
function serieMes(numero, año){  //console.log("Numero: "+ numero + " y Año:" + año + " Recibido");
  var cuenta 		=0;	var siguiente	=0;	var index			=0;  cuenta=numero;
  do {
		if(bisiesto(año)){			//console.log("Año " + año + " bisiesto");
			siguiente=sbiss[index+1];
			cuenta=cuenta-sbiss[index];
			dia=cuenta;
		}else{			//console.log("Año " + año + " No bisiesto");
			siguiente=nobis[index+1];
			cuenta=cuenta-nobis[index];
			dia=cuenta;			//console.log("Cuenta:" + cuenta + " siguiente:"+siguiente);
		}
		index=index+1;
  }	while (cuenta>siguiente);
	mes=index+1;
	dia=cuenta-1;  //console.log("Fin Mes: " + mes + " Dia: " + dia);
}
function serieAnio(valor){
  var año   =1900;  var sumadias=0;  var residuo =0;  //console.log("Valor Recibido");  //console.log(valor);
  do {
    if(bisiesto(año)){
      año = año +1;
      sumadias = sumadias + 366;
    }else{
      año = año +1;
      sumadias = sumadias + 365;
    }
  }while ((sumadias+365)<valor)
  residuo=valor-sumadias;
	anio=año;
  serieMes(residuo, año);  //console.log("Año: " + año + " Mes: " + mes + " Dia: " + dia );
}
function serieHoras(valor){
	var temporal=0;
	var residuo=0;
	temporal = valor;

	temporal = temporal*24;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	hora 			= parseInt(temporal, 10);	//console.log("temporal: "+temporal);	//console.log("hora: "+hora);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	minuto			= parseInt(temporal, 10);	//console.log("temporal: "+temporal);	//console.log("minuto: "+minuto);

	temporal = residuo;
	temporal = temporal*60;
	residuo = Number.parseFloat(temporal)-parseInt(temporal, 10);
	segundo			= parseInt(temporal, 10);	//console.log("temporal: "+temporal);	//console.log("segundo: "+segundo);
}

class Importar extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                |"",

  //Catalogo Funcionalidades
                 bloque:this.props.bloque                   |[],

  //Catalogo Funcionalidades
              categorias:this.props.categorias              |[],

  //Catalogo gpsFijos
              plataforma:this.props.plataforma              |[],

  //Tabla gps
              idCorredor:this.props.idCorredor              |[],
              gpsEstatus:this.props.gpsEstatus              |[],
             gpsHardware:this.props.gpsHardware             |[],

//Catalogo unidadEnsamble
         unidadElementos:this.props.unidadElementos         |[],
//Valores por Default
                idUnidad:this.props.idUnidad                |0,

  //Variables
             movimientos:this.props.movimientos							|[],
             privilegios:this.props.privilegios							|[],

                  accion:this.props.accion,
                validado:this.props.validado                |false,
									  data:this.props.data										|[],

    };
    this.cita=0;
  }
  componentDidMount(){
    //Oculta el Modal
		myModal("none");
  }
  componentWillMount(){
    //console.log("Will Mount this.props.catalogo: " + this.props.catalogo);
    switch (this.props.catalogo) {
      case "gps":
        //console.log("Carga de Catalogos dependientes en gps");
        {
        this.cargaIdCorredor();
        this.cargaGpsEstatus();
        this.cargaGpsHardware();
        }
        break;
      case "gpsFijos":
        //console.log("Carga de Catalogos dependientes en gpsFijos");
        {
        this.cargaPlataforma();
        this.cargaGpsHardware();
        }
        break;
      case "funcionalidades":
        //console.log("Carga de Catalogos dependientes en Funcionalidades");
        { this.cargaBloque() };
        break;
      case "privilegios":
        //console.log("Carga de Catalogos dependientes en Privilegios");
        { this.cargaCategorias() };
        break;
      case "unidadEnsamble":
        //console.log("174 Carga de Catalogos dependientes en unidadEnsamble");
        console.log("Valor por Default de idUnidad: " + this.state.idUnidad);
        { this.cargaElemento() };
        break;

      default:
        console.log("Sin Catalogos Dependientes Definidos en: " + this.props.catalogo );
        break;
    }
  }

//Inicio Catalogo unidadEnsamble
cargaElemento() {
    //console.log("categoria");
    Crud_Catalogos("unidadElementos", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
    this.setState({ elemento:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo unidadEnsamble

//Inicio Catalogo Privilegios
cargaCategorias() {
  //console.log("categoria");
  Crud_Catalogos("categoria", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ categorias:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo Privilegios

//Inicio Catalogo Funcionalidades
cargaBloque() {
  //console.log("bloque");
  Crud_Catalogos("bloque", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ bloque:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo Funcionalidades

//Inicio Catalogo GPS
cargaGpsEstatus() {
  //console.log("gpsEstatus");
  Crud_Catalogos("gpsEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ gpsEstatus:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaGpsHardware() {
  //console.log("gpsHardware");
  Crud_Catalogos("gpsHardware", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ gpsHardware:returnVal });
}).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo GPS

//Inicio Tabla Movimientos
cargaIdCorredor() {
  //console.log("IdCorredor");
  Crud_Catalogos("corredores", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ corredores:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdCliente() {
  //console.log("IdCliente");
  Crud_Catalogos("cliente", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ clientes:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdMovimientosTipo() {
  //console.log("IdMovimientosTipo");
  Crud_Catalogos("movimientosTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ movimientosTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdNavieras() {
  //console.log("IdNavieras");
  Crud_Catalogos("navieras", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ navieras:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdOperacionesEstatus() {
  //console.log("IdOperacionesEstatus");
  Crud_Catalogos("operacionesEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ operacionesEstatus:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdOperacionesTipo() {
  //console.log("IdOperacionesTipo");
  Crud_Catalogos("operacionesTipo", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ operacionesTipo:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdOtd() {
  //console.log("IdOtd");
  Crud_Catalogos("otd", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ otd:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdPlataformas() {
  //console.log("IdPlataformas");
  Crud_Catalogos("plataformas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ plataformas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}

//Inicio Catalogo GPSFijos
cargaPlataforma() {
  //console.log("IdPlataformas");
  Crud_Catalogos("plataformas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ plataforma:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo GPSFijos

//Inicio Catalogo GPS
cargaCorredor() {
  //console.log("IdCorredor");
  Crud_Catalogos("corredores", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ corredores:returnVal });
  console.log("465 returnVal");
  console.log(returnVal);
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Catalogo GPSFijos

cargaIdRutasEstatus() {
  //console.log("IdRutasEstatus");
  Crud_Catalogos("rutasEstatus", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ rutasEstatus:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaIdTransportistas() {
  //console.log("IdTransportistas");
  Crud_Catalogos("transportistas", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ transportistas:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
cargaMovimientos(){
	//console.log("Movimientos");
  Crud_Catalogos("movimientos", ""           , "get", "", [],"" , "",     "", []).then((returnVal) => {
  this.setState({ movimientos:returnVal });
  }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
}
//Fin Tabla Movimientos

valYaExiste(Catalogo, Po, I, Campo){
	var k=0;
  switch (Catalogo) {
    case "movimientos":
      k=0;
      this.state.movimientos.map(ids => {//console.log("ids "+ ids);
        if (this.state.movimientos[k].po===Po){//Campo.po=Po; //this.state.bloque[I].id;//this.state.data[I].movimientos = Campo;
					terrores=terrores+1;//NotificationManager.warning("El Po !" + Po + "! YA Existe! en la BD, Eliminelo del Archivo de Excel en la Fila: " + (I+1) );
        } k=k+1;
      });
      break;
    default:
  	console.log("No Existe el Catalogo Definido");
  }
}
valCatalogoId(Catalogo, Id, I, Campo){
  var k=0;
  switch (Catalogo) {
    case "bloque"://console.log("Inicia BloqueId");
      k=0;
      this.state.bloque.map(ids => {
        if (this.state.bloque[k].id===Id){
          Campo.id=Id; //this.state.bloque[I].id;
          this.state.data[I].bloque = Campo;
        } k=k+1;
      });
      //console.log("Termina BloqueId");
      break;
    case "categoria"://console.log("Inicia categoriasId");
      k=0;
      this.state.categorias.map(ids => {
        if (this.state.categorias[k].id===Id){
          Campo.id=Id; //this.state.categorias[I].id;
          this.state.data[I].categorias = Campo;
          console.log("Campo");
          console.log(Campo);
        } k=k+1;
      });
      //console.log("Termina categoriasId");
      break;
    case "plataforma":
      k=0;
      this.state.plataforma.map(ids => {
        if (this.state.plataforma[k].id===Id){
          Campo.id=Id; //this.state.gpsEstatus[I].id;
          this.state.data[I].plataforma = Campo;
        } k=k+1; });
      break;
    case "gpsEstatus":
      k=0;
      this.state.gpsEstatus.map(ids => {
        if (this.state.gpsEstatus[k].id===Id){
          Campo.id=Id; //this.state.gpsEstatus[I].id;
          this.state.data[I].gpsEstatus = Campo;
        } k=k+1; });
      break;
    case "gpsHardware":
      k=0;
      this.state.gpsHardware.map(ids => {
        if (this.state.gpsHardware[k].id===Id){
          Campo.id=Id; //this.state.gpsHardware[I].id;
          this.state.data[I].gpsHardware = Campo;
        } k=k+1; });
      break;
    case "elemento":
      k=0;
      this.state.elemento.map(ids => {
        if (this.state.elemento[k].id===Id){
          Campo.id=Id; //this.state.elemento[I].id;
          this.state.data[I].elemento = Campo;
        } k=k+1; });
      break;

    default:
    console.log("No Existe el Catalogo Definido");
  }
}

getCatalogoId(Catalogo, Nombre, I, Campo){
  console.log("565 Catalogo:"+Catalogo+" Nombre:" + Nombre + " I:" +I +" Campo:")
  console.log(Campo);
  var k=0;
  switch (Catalogo) {
    case "bloque"://console.log("Inicia BloqueId");
      k=0;
      this.state.bloque.map(ids => {
        if (this.state.bloque[k].nombre===Nombre){
              Campo.id=this.state.bloque[k].id;
              this.state.data[I].bloque = Campo;
        } k=k+1;
      });
      //console.log("Termina BloqueId");
      break;
    case "plataforma":
      //console.log("Inicia categoriaId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      let tempoplataforma=[];
      tempoplataforma = this.state.plataforma.filter(ids => (ids.nombre===Nombre));
      if (tempoplataforma.length>0){
        Campo.id=tempoplataforma[0].id
        this.state.data[I].plataforma = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].plataforma = Campo;
      }
      //console.log("Termina plataformaId");
      break;
    case "categoria":
      //console.log("Inicia categoriaId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      let tempoCat=[];
      tempoCat = this.state.categorias.filter(ids => (ids.nombre===Nombre));
      if (tempoCat.length>0){
        Campo.id=tempoCat[0].id
        this.state.data[I].categorias = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].categorias = Campo;
      }
      //console.log("Termina categoriaId");
      break;
    case "idCorredor":
      console.log("593 Inicia categoriaId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      console.log("this.state.idCorredor");
      console.log(this.state.idCorredor);
      let tempoCorredor=[];
      tempoCorredor = this.state.idCorredor.filter(ids => (ids.nombre===Nombre));
      if (tempoCorredor.length>0){
        Campo.id=tempoCorredor[0].id
        this.state.data[I].idCorredor = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].idCorredor = Campo;
      }
      //console.log("Termina categoriaId");
      break;

    case "gpsEstatus":
      console.log("Inicia categoriaId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      let tempoEstatus=[];
      tempoEstatus = this.state.gpsEstatus.filter(ids => (ids.nombre===Nombre));
      if (tempoEstatus.length>0){
        Campo.id=tempoEstatus[0].id
        this.state.data[I].gpsEstatus = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].gpsEstatus = Campo;
      }
      //console.log("Termina categoriaId");
      break;
    case "gpsHardware":
      console.log("Inicia categoriaId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      let tempoHardware=[];
      tempoHardware = this.state.gpsHardware.filter(ids => (ids.nombre===Nombre));
      if (tempoHardware.length>0){
        Campo.id=tempoHardware[0].id
        this.state.data[I].gpsHardware = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].gpsHardware = Campo;
      }
      //console.log("Termina categoriaId");
      break;
    case "elemento":
      console.log("Inicia elementoId Catalogo:"+Catalogo+" Nombre:"+Nombre);
      let tempoelemento=[];
      tempoelemento = this.state.elemento.filter(ids => (ids.nombre===Nombre));
      if (tempoelemento.length>0){
        Campo.id=tempoelemento[0].id
        this.state.data[I].elemento = Campo;
      }else{
        Campo.id=0;
        this.state.data[I].elemento = Campo;
      }
      //console.log("Termina elementoId");
      break;

		case "po":
      k=0;
      break;
    default:
    NotificationManager.warning("No Existe el Catalogo Definido");
  }
}

  validaArchivo() {
    console.log("Inicia Validacion de Archivo para: " + this.props.catalogo);
    switch (this.props.catalogo) {

      case 'funcionalidades'://Inicia Case Funcionalidades
        console.log('Validando funcionalidades');
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
            console.log("num: " +num);
              switch (num) {
                case 'bloque':
                      var bloque = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("bloque", datos[num], i, bloque) }
                        if (bloque.id===0 || bloque.id===null || bloque.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El Bloque id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("bloque.nombre:"+bloque.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        bloque.nombre=datos[num];
                        { this.getCatalogoId("bloque", datos[num], i, bloque) }
                        if (bloque.id===0 || bloque.id===null || bloque.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                default://FuncionalidadesObject[num] = datos[num];
                break;
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        //console.log("terrores: " + terrores);
        //var err = terrores;
        if (terrores>0){
					//arrayErr[terrores+1]="Post Deshabilidado por " + err + " Errores";
          //NotificationManager.warning("Post Deshabilidado por " + err + " Errores", "Alerta");
          //console.log(datos);
        }else{
					//terrores=terrores+10;
					//Errores.push( <div>{"Archivo Validado!"}</div> );
          //this.state.data.map(datos =>{
            //this.props.crud("post", datos);
						//arrayErr[terrores+1]="Post en Catalogo: " + this.props.catalogo;
          NotificationManager.info("Archivo Correcto!", "Alerta");
            //console.log(datos);
          //});
        }
        break;//Fin Case Funcionalidades

      case 'privilegios'://Inicia Case Privilegios
        console.log('Validando privilegios');
        var i = 0;
        this.state.data.forEach(datos =>{
          console.log("datos");
          console.log(datos);
          if ( (datos["categorias"]==="")||(datos["categorias"]===undefined)||(datos["categorias"]===null) ) {
            console.log("No existe categoria "+ datos["categorias"]);
          }else{
            var categorias = { "id":"", "nombre":"", "icono":"","menuBloque":"" }
            if (Number.isInteger(datos["categorias"])){//console.log("705 categorias es Numero");
              { this.valCatalogoId("categoria", datos["categorias"], i, categorias) }
              if (categorias.id===0 || categorias.id===null || categorias.id===""){
                terrores=terrores+1; console.log("No existe la Categoria: "+datos["categorias"]+ " en la fila:"+(i+1));
                FilaErr[i]="Error en la Fila " + (i+1);
                arrayErr[terrores]="La Categoria id:" + datos["categorias"] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                Errores.push( <div>{"La Categoria id:" + datos["categorias"] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
              }
            }else{
                categorias.nombre=datos["categorias"];
                { this.getCatalogoId("categoria", datos["categorias"], i, categorias) }
                if (categorias.id===0 || categorias.id===null || categorias.id===""){
                terrores=terrores+1; console.log("No existe la Categoria: "+categorias.nombre+ " en la fila:"+(i+1));
                FilaErr[i]="Error en la Fila " + (i+1);
                arrayErr[terrores]="La Categoria !" + categorias.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                //NotificationManager.warning("El Bloque !" + bloque.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
                Errores.push( <div>{"La Categoria !" + categorias.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
              }
            }
          }
          i=i+1;
        })
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case Privilegios

      case 'gps'://Inicia Case GPS
        console.log('Validando funcionalidades');
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
            console.log("num: " +num);
              switch (num) {

                case 'idCorredor':
                      var idCorredor = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("idCorredor", datos[num], i, idCorredor) }
                        if (idCorredor.id===0 || idCorredor.id===null || idCorredor.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idCorredor id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idCorredor id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idCorredor id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("idCorredor.nombre:"+idCorredor.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        idCorredor.nombre=datos[num];
                        { this.getCatalogoId("idCorredor", datos[num], i, idCorredor) }
                        if (idCorredor.id===0 || idCorredor.id===null || idCorredor.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El idCorredor !" + idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El idCorredor !" + idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El idCorredor !" + idCorredor.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'gpsEstatus':
                      var gpsEstatus = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsEstatus", datos[num], i, gpsEstatus) }
                        if (gpsEstatus.id===0 || gpsEstatus.id===null || gpsEstatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsEstatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsEstatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsEstatus id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("gpsEstatus.nombre:"+gpsEstatus.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        gpsEstatus.nombre=datos[num];
                        { this.getCatalogoId("gpsEstatus", datos[num], i, gpsEstatus) }
                        if (gpsEstatus.id===0 || gpsEstatus.id===null || gpsEstatus.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsEstatus !" + gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsEstatus !" + gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsEstatus !" + gpsEstatus.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'gpsHardware':
                      var gpsHardware = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("gpsHardware.nombre:"+gpsHardware.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        gpsHardware.nombre=datos[num];
                        { this.getCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;

                default://gpsObject[num] = datos[num];
                break;

              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case GPS

      case 'gpsFijos'://Inicia Case gpsFijos
        console.log('Validando gpsFijos');
        var i = 0;
        this.state.data.map(datos =>{
          Object.keys(datos).forEach(num =>{
            console.log("num: " +num);
              switch (num) {
                case 'gpsHardware':
                      var gpsHardware = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("gpsHardware.nombre:"+gpsHardware.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        gpsHardware.nombre=datos[num];
                        { this.getCatalogoId("gpsHardware", datos[num], i, gpsHardware) }
                        if (gpsHardware.id===0 || gpsHardware.id===null || gpsHardware.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"El gpsHardware !" + gpsHardware.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;
                case 'plataforma':
                      var plataforma = { "id":"", "nombre":"" }
                      console.log("Number.isInteger(datos[num])");
                      console.log(Number.isInteger(datos[num]));
                      if (Number.isInteger(datos[num])){
                        { this.valCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }else{
                        console.log("plataforma.nombre:"+plataforma.nombre + " datos[num]:"+datos[num] + " i:"+i)
                        plataforma.nombre=datos[num];
                        { this.getCatalogoId("plataforma", datos[num], i, plataforma) }
                        if (plataforma.id===0 || plataforma.id===null || plataforma.id===""){
                          terrores=terrores+1;
													FilaErr[i]="Error en la Fila " + (i+1);
													arrayErr[terrores]="La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                          //NotificationManager.warning("El plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
													Errores.push( <div>{"La plataforma !" + plataforma.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                        }
                      }
                  break;

                default:
                  //FuncionalidadesObject[num] = datos[num];
                break;
              }
            }
          );
          i=i+1;
        });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
        if (terrores>0){
        }else{
          NotificationManager.info("Archivo Correcto!", "Alerta");
        }
        break;//Fin Case gpsFijos

    case 'unidadEnsamble'://Inicia Case unidadEnsamble
      console.log('Validando unidadEnsamble');
      var i = 0;
      this.state.data.map(datos =>{
        Object.keys(datos).forEach(num =>{
          console.log("num: " +num);
            switch (num) {
              case 'elemento':
                    var elemento = { "id":"", "nombre":"" }
                    console.log("Number.isInteger(datos[num])");
                    console.log(Number.isInteger(datos[num]));
                    if (Number.isInteger(datos[num])){
                      { this.valCatalogoId("elemento", datos[num], i, elemento) }
                      if (elemento.id===0 || elemento.id===null || elemento.id===""){
                        terrores=terrores+1;
												FilaErr[i]="Error en la Fila " + (i+1);
												arrayErr[terrores]="El elemento id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                        //NotificationManager.warning("El elemento id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
												Errores.push( <div>{"El elemento id:" + datos[num] + " No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                      }
                    }else{
                      console.log("elemento.nombre:"+elemento.nombre + " datos[num]:"+datos[num] + " i:"+i)
                      elemento.nombre=datos[num];
                      { this.getCatalogoId("elemento", datos[num], i, elemento) }
                      if (elemento.id===0 || elemento.id===null || elemento.id===""){
                        terrores=terrores+1;
												FilaErr[i]="Error en la Fila " + (i+1);
												arrayErr[terrores]="El elemento !" + elemento.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1);
                        //NotificationManager.warning("El elemento !" + elemento.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) );
												Errores.push( <div>{"El elemento !" + elemento.nombre + "! No Existe! en la BD, Verifique en Excel la Fila: " + (i+1) }</div> );
                      }
                    }
                //unidadEnsamble[num] = datos[num];
                break;
            }
          }
        );
        i=i+1;
      });
//  ******************* Aquí es el codigo para Cargar los Datos  *********************** Ahora es con el Boton cargarDatos
      if (terrores>0){
      }else{
        NotificationManager.info("Archivo Correcto!", "Alerta");
      }
      break;//Fin Case unidadEnsamble

      default:
				NotificationManager.warning="No esta Defina una Carga Masiva para este Catalogo";
        break;
    }
    this.setState({validado:true });
    console.log("this.state.data");
    console.log(this.state.data);
		console.log("Fin Validacion de Archivo: "+ terrores);
  }

	cargarDatos = (carga, errores) => {
		console.log("1009 Cargar Datos Correctos e:" + errores);
    let w=0;
    let errResponse=[];
    for (let i = 0; i < carga.length; i++) {
      if (errores==0){
        Crud_Catalogos(this.props.catalogo, ""           , "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          if(i === carga.length-1){
            this.props.crud();
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
        }).catch(err =>{
          if(i === carga.length-1){
            this.props.crud();
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
          errResponse.push({datos: carga[i]});
          if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
        });
      }else{
        if ( (FilaErr[i]!="") || (FilaErr[i]!=null) || (FilaErr[i]!=undefined) ){
          console.log("No se Ejecuto la Carga de la Fila:" + i);
        }else{
          Crud_Catalogos(this.props.catalogo, ""           , "post", "", carga[i],"" , "",     "", []).then((returnVal) => {
          if(i === carga.length-1){
            this.props.crud();
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
          }).catch(err =>{
            if(i === carga.length-1){
            this.props.crud();
            NotificationManager.success("Carga Masiva en Catalogo: " + this.props.catalogo, "Finalizada! ");
            myModal("none");
          }
            errResponse.push({datos: carga[i]});
            if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
          });
        }
      }
    }
    {/*
			carga.map(datos =>{
        if (errores==0){
          Crud_Catalogos(this.props.catalogo, ""           , "post", "", datos,"" , "",     "", []).then((returnVal) => {
            }).catch(err =>{
              errResponse.push({datos});
              if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
            });

          //NotificationManager.success("Post en Catalogo: " + this.props.catalogo, "Exitoso! Fila: " + w);
        }else{
          if ( (FilaErr[w]!="") || (FilaErr[w]!=null) || (FilaErr[w]!=undefined) ){
  					console.log("No se Ejecuto la Carga de la Fila:" + w);
  				}else{
            Crud_Catalogos(this.props.catalogo, ""           , "post", "", datos,"" , "",     "", []).then((returnVal) => {
            }).catch(err =>{
              errResponse.push({datos});
              if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}
            });
  				}
        }
				w=w+1;
      });
      this.props.crud();
		*/}
	}

  onImportExcel = file => {
		//this.state.data= [];
    const { files } = file.target;
    console.log("fileName");
    console.log(files[0].name);

    const fileReader = new FileReader();
    fileReader.onload = event => {
      try {
        const { result } = event.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        for (const sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            console.log("sheet: " + sheet);
            //this.setState({catalogo: sheet });//this.props.data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            this.setState({data: XLSX.utils.sheet_to_json(workbook.Sheets[sheet])});
          }
        }
        //console.log(this.state.data);
        console.log("817 Carga de Catalogos dependientes en unidadEnsamble");
        console.log("818 Valor por Default de idUnidad: " + this.state.idUnidad);

        console.log('Carga de Archivo Exitosa！');
        id=0;
        terrores=0;
				sinError=0;
        { this.validaArchivo() }
      } catch (e) {
        console.log("Error! "+e);
      }
    };
    fileReader.readAsBinaryString(files[0]);
		myModal("block");
}

render() {
    return (
      <div className="excelC" id="excelC">
        <p className="etiquetaC" id="etiquetaC"><a href="#miModalC">IMP</a></p>
        <input type='file' id="file" className="file" accept='.xlsx, .xls' onChange={this.onImportExcel} />

				<div id="xlsModalC" className="xlsModalC">
					<div className="modal-contenidoC">
					<center><h2>Confirme su Carga</h2></center>
					<div className="container-200">
						<div className="scroll-col col-sm-12">
						{
              this.state.validado===false ? <div><br></br>Verificando Errores ..!! <Dimmer active={true} loader></Dimmer> por favor Espere!</div>:
              terrores==0? <div>Archivo Correcto</div>:Errores
						}
						</div>
					</div>

					<br></br>
					<center>
						<button className="azul" onClick={()=>myModal('none')} >Cerrar y Corregir Archivo de Excel</button>
						<spam>   </spam>
						<button className="azul" onClick={()=>this.cargarDatos(this.state.data, terrores)} >Cargar Datos Correctos</button>
					</center>
					</div>
				</div>
      </div>
    );
  }
}

export default Importar;
