// @flow

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers";
import { createStore } from "redux";
import "./index.css";
import "./c3jscustom.css";
import App from "./App.react";
import {SoftphoneProvider} from './context/softphoneContext';
let store = createStore(rootReducer);

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <SoftphoneProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </SoftphoneProvider>,
    rootElement
  );
} else {
  throw new Error("Could not find root element to mount to!");
}
