import React, { useState, useEffect } from "react";
import Reloj from "./Reloj";

const Temporizador = (props) => {
  const [minutos, setMinutos] = useState("00");
  const [horas, setHoras] = useState("00");
  const [asunto, setAsunto] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(false);
  const [timePassed, setTimePassed] = useState(0);
  const [tiemposLocales, setTiemposLocales] = useState([]);

  /**
   * Función para limitar el campo de horas y darle formato.
   */
  const inputHoursHandler = (event) => {
    let numero = +event.target.value;
    numero <= 9
      ? setHoras(`0${numero}`)
      : numero <= 59
      ? setHoras(`${numero}`)
      : setHoras(`59`);
  };
  /**
   * Función para limitar el campo de minutos y darle formato.
   */
  const inputMinutesHandler = (event) => {
    let numero = +event.target.value;
    numero <= 9
      ? setMinutos(`0${numero}`)
      : numero <= 59
      ? setMinutos(`${numero}`)
      : setMinutos(`59`);
  };

  /**
   * Función para agregar un temporizador.
   */
  const empezarHandler = (event) => {
    event.preventDefault();
    let tiempo = +horas * 60 + +minutos;
    let inicio = new Date();
    let elemento = {
      idMovimiento: props.movimiento.id,
      minutos: tiempo,
      creacion: inicio,
      asunto: asunto,
      idUsuario: props.usuario,
      idPlataforma: props.movimiento.plataforma? props.movimiento.plataforma.id : props.movimiento.idPlataforma.id,
      placa: props.movimiento.placa,
      cartaPorte: props.movimiento.cartaPorte,
      idCorredor: props.movimiento.corredor? props.movimiento.corredor.id : props.movimiento.idCorredor.id
    };
    props.addToArray(elemento);
    setTiemposLocales((prev) => {
      return [
        ...prev,
        <li
          class="list-group-item list-group-item-primary"
          key={elemento.idMovimiento}
        >
          <p>
            <strong>Movimiento:</strong> {elemento.idMovimiento}
          </p>
          <p>
            <strong>Tiempo establecido: </strong>
            {elemento.minutos} minutos
          </p>
          <p>
            <strong>Asunto: </strong>
            {elemento.asunto}
          </p>
        </li>,
      ];
    });
    setTimeRemaining(tiempo * 60);
    setMinutos("00");
    setHoras("00");
    setAsunto("");
  };

  /**
   * Función para preparar los datos que se enviarán a la animación del reloj
   */
  useEffect(() => {
    setTiemposLocales(
      props.tiempos.map((tiempo) => {
        if (tiempo.idMovimiento === props.movimiento) {
          let transcurrido =
            (new Date().getTime() - new Date(tiempo.creacion).getTime()) / 1000;
          setTimeRemaining(Math.trunc(tiempo.minutos * 60));
          setTimePassed(Math.trunc(transcurrido));
        }
        return (
          <li class="list-group-item" key={tiempo.idMovimiento}>
            <p>
              <strong>Movimiento:</strong> {tiempo.idMovimiento}
            </p>
            <p>
              <strong>Tiempo establecido:</strong> {tiempo.minutos} minutos
            </p>
            <p>
              <strong>Tiempo transcurrido: </strong>
              {(
                (new Date().getTime() - new Date(tiempo.creacion).getTime()) /
                60000
              ).toFixed(2)}{" "}
              minutos
            </p>
            <p>
              <strong>Asunto: </strong>
              {tiempo.asunto}
            </p>
          </li>
        );
      })
    );
  }, []);

  return (
    <>
      <form>
        <div className=" row justify-content-center">
          {timeRemaining && (
            <Reloj time={timeRemaining} timepassed={timePassed} />
          )}
        </div>
        <div>
          <div className="row justify-content-center">
            <small id="emailHelp" className="form-text text-muted">
              Establecer la cantidad de horas y minutos del cronómetro.
            </small>
          </div>
          <div className="row justify-content-center">
            <div className="form-group">
              <label htmlFor="horas">Horas:</label>
              <input
                type="number"
                step="1"
                min="0"
                max="59"
                value={horas}
                onInput={inputHoursHandler}
              />
            </div>
            <div className="form-group" style={{ marginLeft: "2px" }}>
              <label htmlFor="minutos">Minutos:</label>
              <input
                type="number"
                step="1"
                min="0"
                max="59"
                value={minutos}
                onInput={inputMinutesHandler}
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div class="form-group">
            <label htmlFor="asunto">
              Asunto<strong style={{ color: "red" }}>*</strong>
            </label>
            <textarea
              class="form-control"
              id="asunto"
              rows="4"
              value={asunto}
              onChange={(event) => {
                setAsunto(event.target.value);
              }}
            />
          </div>
        </div>
        <ul className="list-group">{tiemposLocales}</ul>
        <button
          className="btn btn-primary"
          onClick={empezarHandler}
          disabled={asunto === "" ? true : false}
        >
          Empezar
        </button>
      </form>
    </>
  );
};

export default Temporizador;
