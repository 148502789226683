import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { ClientesForm } from "./../../";
import ImportarContactos from "./ImportarContactos";

/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";

class Clientes extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Clientes: [],
      ClientesDisponibles: [],
      selectedRow: -1,
      hiddenModals: false,
      search: "",
      pagination: [],
      pagina: 1,
      pagesize: 10,
      agregar: false,
    };
    this.colum = [];
    this.pagination = [];
    this.content = [];
    this.clientesPafination = [];
    this.clientesDisponiblesPafination = [];
  }
  
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      this.plataformasObject = this.props.elementos;
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize,
        "clientes"
      );
    } else {
    }
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "cliente",
    stateVar = "clientes",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "clientes") {               
                if (returnVal.content) {
                  this.setState({ Clientes: returnVal.content });
                  this.paginacion(returnVal);
                  this.clientesPafination = returnVal;
                }
              }
              if (stateVar === "ClientesDisponibles") {
                if (returnVal.content) {
                  this.setState({ ClientesDisponibles: returnVal.content });
                  this.paginacion(returnVal);
                  this.clientesDisponiblesPafination = returnVal;
                }
              }
            }
            break;
          case "post":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se ha agregado el cliente al catlogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  let clientesTem = this.state.Clientes;
                  clientesTem.unshift(returnVal);
                  this.setState({ Clientes: clientesTem });
                  this.getfuntion(
                    "post",
                    {
                      idPlataforma: this.props.elementos.id,
                      idCliente: returnVal.id,
                    },
                    "",
                    "plataformasClientes",
                    "plataformasClientes"
                  );
                }
              }
              if (stateVar === "plataformasClientes") {
                if (returnVal.idPlataforma) {
                  NotificationManager.info(
                    "El cliente ahora pertenece a la plataforma( " +
                      this.props.elementos.nombre +
                      " )",
                    "Nuevo"
                  );

                  if (this.state.agregar) {
                    let clientesTem = this.state.ClientesDisponibles;
                    clientesTem.map((item, index) => {
                      if (item.id == returnVal.idCliente) {
                        clientesTem.splice(index, 1);
                        let clientessTem = this.state.Clientes;
                        clientessTem.unshift(item);
                        this.setState({ Clientes: clientessTem });
                      }
                    });
                    this.setState({ ClientesDisponibles: clientesTem });
                  }
                  this.setState({ search: "" });
                  this.setState({ agregar: false });
                }
                this.hiddenmodal();
              }
            }
            break;
          case "put":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se han agregado las modificaciones del cliente al catlogo general( " +
                      returnVal.nombre +
                      " )",
                    "Modificación"
                  );
                  let clientesTem = this.state.Clientes;
                  for (let item of clientesTem) {
                    if (item.id == returnVal.id) {
                      item = returnVal;
                    }
                  }
                  this.setState({ Clientes: clientesTem });
                  this.hiddenmodal();
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "clientes") {
                if (returnVal) {
                  this.getfuntion(
                    "delete",
                    { idPlataforma: this.props.elementos.id, idCliente: id },
                    this.props.elementos.id + "/" + id,
                    "plataformasClientes",
                    "plataformasClientes"
                  );
                }
              }
              if (stateVar === "plataformasClientes") {
                if (returnVal) {
                  let clientesTem = this.state.Clientes;
                  clientesTem.map((item, index) => {
                    if (item.id == obj.idCliente) {
                      clientesTem.splice(index, 1);
                      this.setState({ Clientes: clientesTem });
                    }
                  });
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
    this.setState({ metodo: "get" });
  };
  table_headers = () => {
    this.content = [];
    let cli = this.state.agregar
      ? this.state.ClientesDisponibles
      : this.state.Clientes;
    if (cli) {
      if (cli[0]) {
        Object.keys(cli[0]).forEach((key) =>
          this.content.push({ content: key })
        );
        this.content.push({
          content: this.state.agregar ? "agregar" : "editar",
        });
        if (!this.state.agregar) {
          this.content.push({ content: "eliminar" });
        }
      }
    }
  }; //CMA DSV AIR & SEA SA DE CV_GROUPE SEB MEXICO S.A. DE C.V. SERVICIOS EMPRESARIALES ZIMAG SA DE CV	 SAN PEDRO BARRIENTOS  TLANEPANTLA EDO DE MEXICO 54010-5502
  paginacion = (pag) => {
    this.pagination = pag;
    this.pagination.content = [];
    this.setState({ pagination: this.pagination });
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
    if (
      this.state.Clientes.filter((word) =>
        word.nombre.toUpperCase().includes(event.target.value.toUpperCase())
      ).sort().length == 0 &&
      !event.target.value.toUpperCase().includes("{")
    ) {
      this.paginarNombre(encodeURIComponent(event.target.value));
    }
  };

  paginarNombre = (nombre) => {
    //cliente/plataforma/2/cliente/3d/pagina/0/total/2
    if (this.state.agregar) {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/disponibles/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize +
          "/cliente?cliente=" +
          nombre,
        "ClientesDisponibles"
      );
    } else {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize +
          "/cliente?cliente=" +
          nombre,
        "clientes"
      );
    }
  };
  ClientesDisponibles = () => {
    this.setState({ search: "" });
    this.setState({ agregar: true });
    this.setState({ pagina: 1 });
    if (!this.state.ClientesDisponibles[0]) {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/disponibles/plataforma/" +
          this.props.elementos.id +
          "/pagina/0/total/" +
          this.state.pagesize,
        "ClientesDisponibles"
      );
    }
    this.paginacion(this.clientesDisponiblesPafination);
  };
  cancelar = () => {
    this.setState({ search: "" });
    this.setState({ agregar: false });
    this.setState({ pagina: 1 });
    this.paginacion(this.clientesPafination);
  };
  _editCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <ModalLay
          tamanio={"90%"}
          title="Modificar"
          name={
            <Button pill size="sm" outline color="primary">
              <Icon name="edit" />
            </Button>
          }
          formulario={ClientesForm}
          datosForm={{
            accion: "modificar",
            plataforma: this.props.elementos,
            getfuntion: this.getfuntion,
            stor: this.props.stor,
            elementos: elemento,
          }}
        />
      </Table.Col>
    );
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Remover"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "delete",
                {
                  idPlataforma: this.props.elementos.id,
                  idCliente: elemento.id,
                },
                this.props.elementos.id + "/" + elemento.id,
                "plataformasClientes",
                "plataformasClientes"
              )
            }
          >
            <Icon name="trash" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  _addCol = (elemento) => {
    this.colum.push(
      <Table.Col>
        <span data-toggle="tooltip" data-placement="top" title={"Agregar"}>
          <Button
            pill
            size="sm"
            outline
            color="primary"
            onClick={() =>
              this.getfuntion(
                "post",
                {
                  idPlataforma: this.props.elementos.id,
                  idCliente: elemento.id,
                },
                "",
                "plataformasClientes",
                "plataformasClientes"
              )
            }
          >
            <Icon name="plus" />
          </Button>
        </span>
      </Table.Col>
    );
  };
  render() {
    return (
      <div>
        {/**
         *   Elementos de plataformas
         */}
        {this.state.accion === "modificar" ? (
          <div>
            <Grid.Row>
              <Grid.Col>
                {!this.state.agregar ? (
                  <Card
                    title="La plataforma esta configurada con los siguientes clientes"
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div>
                        <span className="float-right ml-2">
                          <div>
                            <ImportarContactos
                              idPlataforma={this.props.elementos.id}
                            />
                          </div>
                        </span>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Nuevo cliente"}
                        >
                          <Button
                            pill
                            size="sm"
                            outline
                            color="primary"
                            onClick={() => this.ClientesDisponibles()}
                          >
                            <Icon name="plus" />
                          </Button>
                        </span>
                        <span className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar Clientes..."
                            position="append"
                            value={this.state.search}
                            onChange={this.searching}
                            onKeyDown={this._searchingkey}
                          />
                        </span>
                      </div>
                    }
                    body={
                      <div>
                        {this.state.Clientes ? (
                          <Form.Group>
                            <Table
                              cards={true}
                              striped={true}
                              responsive={true}
                              className="table-vcenter"
                              {...this.table_headers()}
                              headerItems={this.content}
                            >
                              <Table.Body>
                                {this.state.Clientes.filter((word) =>
                                  word.nombre
                                    .toUpperCase()
                                    .includes(this.state.search.toUpperCase())
                                )
                                  .sort()
                                  .map((elemento, index) => (
                                    <Table.Row
                                      style={
                                        this.state.selectedRow === index
                                          ? {
                                              "background-color":
                                                "rgba(95, 231, 50, 0.30)",
                                            }
                                          : null
                                      }
                                      onClick={() => {
                                        this.setState({ selectedRow: index });
                                      }}
                                    >
                                      {(this.colum = [])}
                                      {
                                        (Object.keys(elemento).forEach(
                                          (key, index) => {
                                            this.colum.push(
                                              <Table.Col>
                                                {" "}
                                                {key === "idTarifarioSubasta" && elemento[key] ? elemento[key].localidad : elemento[key]}{" "}
                                              </Table.Col>
                                            );
                                          }
                                        ),
                                        this._editCol(elemento))
                                      }
                                    </Table.Row>
                                  ))}
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        ) : null}
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-4">
                            <nav aria-label="...">
                              <ul class="pagination">
                                <li className="page-item">
                                  <span
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number > 0
                                          ? this.paginar(
                                              this.state.pagination.number - 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Anterior
                                  </span>
                                </li>
                                {this.getpaginationLinks()}
                                <li class="page-item">
                                  <a
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number <
                                          this.state.pagination.totalPages
                                          ? this.paginar(
                                              this.state.pagination.number + 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Siguiente
                                  </a>
                                </li>
                                <li class="page-item">
                                  <h3>
                                    <div className="row">
                                      <div className="col-4 text-right">
                                        {" "}
                                        <Form.Input
                                          name="pag212"
                                          type="number"
                                          value={this.state.pagina}
                                          onKeyDown={this._buscar}
                                          onChange={this._pag}
                                        />{" "}
                                      </div>
                                      <div className="col-8 text-left">
                                        {" "}
                                        /
                                        {this.state.pagination
                                          ? this.state.pagination.totalPages
                                            ? this.state.pagination.totalPages
                                            : 1
                                          : 0}
                                        Páginas{" "}
                                      </div>
                                    </div>
                                  </h3>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-6 text-right">
                                {" "}
                                Total por página{" "}
                              </div>
                              <div className="col-6">
                                {" "}
                                <Form.Input
                                  name="pagTotal"
                                  type="number"
                                  value={this.state.pagesize}
                                  onChange={this._pagesize}
                                  onKeyDown={this._changePagesize}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                ) : (
                  <Card
                    title="Acontinuación se muestra del catalogo general los cliendes que puede utilizar(Si no se encuentra agregar uno nuevo)"
                    isCollapsible={false}
                    //isClosable
                    isCollapsed={false}
                    options={
                      <div>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Cancelar"}
                        >
                          <Button
                            pill
                            size="sm"
                            outline
                            color="danger"
                            onClick={() => this.cancelar()}
                          >
                            <Icon name="x" />
                          </Button>
                        </span>
                        <span
                          className="float-right ml-2"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={"Crear nuevo cliente"}
                        >
                          <ModalLay
                            tamanio={"70%"}
                            hiddenModal={this.state.hiddenModals}
                            Fclose={this.hiddenmodal}
                            title="Nuevo"
                            name={
                              <Button pill size="sm" outline color="primary">
                                <Icon name="plus" />
                              </Button>
                            }
                            formulario={ClientesForm}
                            datosForm={{
                              accion: "nuevo",
                              plataforma: this.props.elementos,
                              getfuntion: this.getfuntion,
                              stor: this.props.stor,
                            }}
                          />
                        </span>
                        <span className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar Clientes..."
                            value={this.state.search}
                            position="append"
                            onChange={this.searching}
                            onKeyDown={this._searchingkey}
                          />
                        </span>
                      </div>
                    }
                    body={
                      <div>
                        <Form.Group>
                          <Grid.Row>
                            <Grid.Col>
                              <Dimmer
                                active={!this.state.ClientesDisponibles[0]}
                                loader
                              ></Dimmer>
                            </Grid.Col>
                          </Grid.Row>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                            {...this.table_headers()}
                            headerItems={this.content}
                          >
                            <Table.Body>
                              {this.state.ClientesDisponibles.filter((word) =>
                                word.nombre
                                  .toUpperCase()
                                  .includes(this.state.search.toUpperCase())
                              )
                                .sort()
                                .map((elemento, index) => (
                                  <Table.Row
                                    style={
                                      this.state.selectedRow === index
                                        ? {
                                            "background-color":
                                              "rgba(95, 231, 50, 0.30)",
                                          }
                                        : null
                                    }
                                    onClick={() => {
                                      this.setState({ selectedRow: index });
                                    }}
                                  >
                                    {(this.colum = [])}
                                    {
                                      (Object.keys(elemento).forEach(
                                        (key, index) => {
                                          this.colum.push(
                                            <Table.Col>
                                              {" "}
                                              {elemento[key]}{" "}
                                            </Table.Col>
                                          );
                                        }
                                      ),
                                      this._addCol(elemento))
                                    }
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                        <div className="row">
                          <div className="col-4"></div>
                          <div className="col-4">
                            <nav aria-label="...">
                              <ul class="pagination">
                                <li className="page-item">
                                  <span
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number > 0
                                          ? this.paginar(
                                              this.state.pagination.number - 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Anterior
                                  </span>
                                </li>
                                {this.getpaginationLinks()}
                                <li class="page-item">
                                  <a
                                    class="page-link"
                                    onClick={() =>
                                      this.state.pagination
                                        ? this.state.pagination.number <
                                          this.state.pagination.totalPages
                                          ? this.paginar(
                                              this.state.pagination.number + 1
                                            )
                                          : null
                                        : null
                                    }
                                  >
                                    Siguiente
                                  </a>
                                </li>
                                <li class="page-item">
                                  <h3>
                                    <div className="row">
                                      <div className="col-4 text-right">
                                        {" "}
                                        <Form.Input
                                          name="pag212"
                                          type="number"
                                          value={this.state.pagina}
                                          onKeyDown={this._buscar}
                                          onChange={this._pag}
                                        />{" "}
                                      </div>
                                      <div className="col-8 text-left">
                                        {" "}
                                        /
                                        {this.state.pagination
                                          ? this.state.pagination.totalPages
                                            ? this.state.pagination.totalPages
                                            : 1
                                          : 0}
                                        Páginas{" "}
                                      </div>
                                    </div>
                                  </h3>
                                </li>
                              </ul>
                            </nav>
                          </div>
                          <div className="col-2">
                            <div className="row">
                              <div className="col-6 text-right">
                                {" "}
                                Total por página{" "}
                              </div>
                              <div className="col-6">
                                {" "}
                                <Form.Input
                                  name="pagTotal"
                                  type="number"
                                  value={this.state.pagesize}
                                  onChange={this._pagesize}
                                  onKeyDown={this._changePagesize}
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                )}
              </Grid.Col>
            </Grid.Row>
          </div>
        ) : null}
      </div>
    );
  }
  //this.getfuntion("get", "", "", "cliente/plataforma/"+this.props.elementos.id+"/pagina/0/total/"+this.state.pagesize, "clientes");
  paginar = (pag) => {
    if (this.state.agregar) {
      if (this.state.search.length > 0) {
        this.getfuntion(
          "get",
          "",
          "",
          "cliente/disponibles/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize +
            "/cliente?cliente=" +
            this.state.search,
          "ClientesDisponibles"
        );
      } else {
        this.getfuntion(
          "get",
          "",
          "",
          "cliente/disponibles/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "ClientesDisponibles"
        );
      }
      this.setState({ pagina: pag + 1 });
    } else {
      if (this.state.search.length > 0) {
        this.getfuntion(
          "get",
          "",
          "",
          "cliente/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize +
            "/cliente?cliente=" +
            this.state.search,
          "clientes"
        );
      } else {
        this.getfuntion(
          "get",
          "",
          "",
          "cliente/plataforma/" +
            this.props.elementos.id +
            "/pagina/" +
            pag +
            "/total/" +
            this.state.pagesize,
          "clientes"
        );
      }
      this.setState({ pagina: pag + 1 });
    }
  };
  getpaginationLinks = () => {
    var links = [];
    var numberPag = this.pagination.number;
    if (this.pagination) {
      for (let i = numberPag; i < numberPag + 5; i++) {
        if (i === numberPag) {
          links.push(
            <li class="page-item active" aria-current="page">
              {" "}
              <span class="page-link">
                {" "}
                {i + 1} <span class="sr-only">(current)</span>{" "}
              </span>{" "}
            </li>
          );
        } else {
          if (i < this.pagination.totalPages) {
            links.push(
              <li class="page-item">
                <a class="page-link" onClick={() => this.paginar(i)}>
                  {i + 1}
                </a>
              </li>
            );
          }
        }
      }
    }
    return links;
  };
  _changePagesize = (e) => {
    if (e.key === "Enter") {
      this.paginar(0);
    }
  };
  _searchingkey = (e) => {
    if (e.key === "Enter") {
      if (e.target.value == 0) {
        this.paginar(0);
      }
    }
  };
  _pagesize = (e) => this.setState({ pagesize: e.target.value });
  _buscar = (e) => {
    if (e.key === "Enter") {
      if (this.pagination.totalPages) {
        this.paginar(e.target.value - 1);
      }
    }
  };
  _pag = (event) => {
    this.setState({
      pagina:
        event.target.value > this.pagination.totalPages
          ? this.pagination.totalPages
          : this.pagination.totalPages
          ? event.target.value
          : 1,
    });
  };
}

export default Clientes;
