import React, { Component } from "react";
import "./excel.css";
function subCadena(str, I) {
  var res = str.slice(0, I);
  return res;
}
function obtenAtributos() {
  var tempo = window.location.href;
  var itemp = tempo.length;
  var i = itemp;
  do {
    let stext = tempo.charAt(i);
    if (stext == "/") {
      return subCadena(tempo, i) + "/manuales/";
      break;
    }
    i = i - 1;
  } while (i > 0);
  return subCadena(tempo, i) + "/manuales/";
}
let nombreManual = "";
class Manual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catalogo: this.props.catalogo | "",
      url: this.props.url | "",
      download: this.props.download | "",

      //Variables
      accion: this.props.accion,
      data: this.props.data | [],
    };
    this.cita = 0;
  }
  componentDidMount() {}
  componentWillMount() {
    switch (this.props.catalogo) {
      case "navieraMovimientos":
        nombreManual =
          "SkyOne-Carga-Masiva-Multiple-de-Movimientos-Navieros-V001";
        break;
      case "gps":
        nombreManual = "SkyOne-CargaMasivaGPSs";
        break;
      default:
        nombreManual = this.props.catalogo;
        break;
    }
    this.setState({ url: obtenAtributos() });
    this.setState({ download: "" + nombreManual + ".pdf" });
  }

  render() {
    return (
      <a
        download={this.state.download}
        href={this.state.url + this.state.download}
        disabled={this.state.url != "" ? false : true}
        className="btn btn-light"
        title="Descargar Manual"
      >
        Manual
      </a>
    );
  }
}

export default Manual;
