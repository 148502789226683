//React
import React, { useState } from 'react';
//Componentes externos
import { Container, Button, Form } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

/**
 * CustomTableExportModal
 * @description: Modal que exporta la información a un excel
 * @date 19/07/2024.
 * @returns JSX del componente.
 */
export const CustomTableExportModal = (props) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  /* ----------------------------- useState's -------------------------------*/
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  /* ------------------------------ Funciones -------------------------------*/
  const mapDataToColumns = (data, columns) => {
    return data.map((row) => {
      const mappedRow = {};
      columns.forEach((column) => {
        const key = column.columnDef?.header;
        if (key !== 'Acciones') {
          if (column.accessorFn) {
            mappedRow[key] = column.accessorFn(row.original);
          } else {
            mappedRow[key] = row.original[key];
          }
        }
      });
      return mappedRow;
    });
  };

  //Función que maneja la exportación de los datos
  const handleExport = () => {
    const rowData = mapDataToColumns(props.data, props.columns);
    if (startDate && endDate) {
      //Se elimina el mensaje de error
      setErrorMessage('');
      const ws = XLSX.utils.json_to_sheet(
        rowData.filter(
          (row) =>
            new Date(row['Fecha de creación']) >= new Date(startDate) &&
            new Date(row['Fecha de creación']) <= new Date(endDate)
        )
      );
      const wb = { Sheets: { datos: ws }, SheetNames: ['datos'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        `${props.title} ${startDate} ${endDate}` + fileExtension
      );
    } else if (startDate || endDate) {
      //Mensaje de error
      setErrorMessage('Por favor selecciona la fecha faltante.');
    } else {
      //Se elimina el mensaje de error
      setErrorMessage('');
      const ws = XLSX.utils.json_to_sheet(rowData);
      const wb = { Sheets: { datos: ws }, SheetNames: ['datos'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, props.title + fileExtension);
    }
  };
  /* -------------------------------- return --------------------------------*/
  //Return princiapl
  return (
    <Container fluid className="p-0">
      <div
        className="d-flex flex-column overflow-auto"
        style={{ maxWidth: '100%', overflowX: 'auto' }}
      >
        <Form.Group className="mb-3">
          <Form.Label>Fecha inicial</Form.Label>
          <Form.Control
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Fecha final</Form.Label>
          <Form.Control
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </Form.Group>
        <p>
          Nota: Si no se seleccionan las fechas se exporta toda la información.
        </p>
        {errorMessage && <p className="text-danger mb-3">{errorMessage}</p>}
        <Button onClick={handleExport}>Exportar</Button>
      </div>
    </Container>
  );
};
