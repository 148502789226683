import React, { useState, useEffect, useRef } from 'react'
import { Grid, Card, Form, Table } from "tabler-react";
import Select from "react-select";
import { TablePageable } from "../../Helpers";
import SiteWrapper from "../../SiteWrapper.react";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';

export const CorreosProgramados = () => {
  let contenedores;
  const [correos, setCorreosProgramados] = useState([]);

        useEffect(() => {
        obtenerCorreosPendientes();

        }, [])

    const obtenerCorreosPendientes = async () => {
        let correo = [...correos];
        await getfuntion("get", [], "", "correosProgramados", "correosProgramados", "").then((cps) => {
            cps.forEach(cp => {
                cp.contenedor = Contenedores(cp.contenedor);
                correo.push(cp);
            });
        });
        
        setCorreosProgramados(correo);
  
    }

  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            switch (stateVar) {

              default: return returnVal;
            }
          }
        default: return returnVal;
      }
    })
  }
  let contenedorDato = (text = "") => {
    contenedores += text + "\n";
  }
  let Contenedores = (campoContenedor) => {
    contenedores = "";
    let arregloCampoContenedor = []; 
    arregloCampoContenedor = campoContenedor.split(",");
    arregloCampoContenedor.forEach(contenedorDato);
    return contenedores;
  }
  return (
    <SiteWrapper> 
      <br/>
      <div className="container">       
          {
            correos.length > 0 ?
            <TablePageable
                titulo={ <h3> Correos programados </h3> }
                lista= { correos }
                columnas={
                  [
                    { columna: "nombre_cliente", label: "Cliente" },
                    { columna: "contenedor", label: "Contenedor" },
                    { columna: "fecha_envio_programado", label: "Fecha de envío programado" },
                    { columna: "fecha_salida_origen", label: "Fecha de salida origen" }, 
                    { columna: "nivel_riesgo", label: "Nivel de riesgo" },
                    { columna: "num_registro", label: "Numero de registro" },
                    { columna: "fecha_eta_colocacion", label: "ETA de colocación" },
                    { columna: "plataforma", label: "Plataforma" }, 
                    { columna: "num_despacho", label: "Número de despacho" }
                  ]
                }
                id= "id"
            />
              :
              <span className='h1 bg-red-lightest' align="center">No hay correos programados para su envío</span>
          }
      </div>
    </SiteWrapper>
  )
}

export default CorreosProgramados;