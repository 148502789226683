// @flow
import React, { Component } from "react";
import { Button, Form, Icon } from "tabler-react";

class ClientesContactosForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      clienteId: 0,
      personaId: 0,
      clienteNombre: "",
      personaNombre: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      disabledSave: true,
    };
  }

  componentDidMount = () => {
    if (this.state.accion === "nuevo") {
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ clienteId: this.props.elementos.idCliente });
      this.setState({ personaId: this.props.elementos.idPersona });
    }
  };

  changeCliente = (event) => {
    this.setState({ clienteId: event.target.value });
    console.log(
      "Cliente: put { id:" +
        this.state.id +
        "} cliente: { id:" +
        this.state.clienteId +
        "} persona {id:" +
        this.state.personaId +
        "}"
    );
  };
  changePersona = (event) => {
    this.setState({ personaId: event.target.value });
    console.log(
      "Persona: put { id:" +
        this.state.id +
        "} cliente: { id:" +
        this.state.clienteId +
        "} persona {id:" +
        this.state.personaId +
        "}"
    );
  };

  guardarCliente = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };

  delete() {
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.props.getfuntion("put", {
      id: this.state.id,
      cliente: { id: this.state.clienteId },
      persona: { id: this.state.personaId },
    });
  }
  crud_post() {
    this.props.getfuntion("post", {
      cliente: { id: this.state.clienteId },
      persona: { id: this.state.personaId },
    });
  }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>Clientes Contactos:{this.props.elementos.id}</h1>
        ) : null}
        <Form.Group label="id">
          <Form.Input name="id" disabled type="number" value={this.state.id} />
        </Form.Group>

        <Form.Group label="Cliente">
          <Form.Select
            name="Clientes"
            value={this.state.clienteId}
            onChange={this.changeCliente}
          >
            <option value={0}>Selecciona un Cliente</option>
            {this.props.stor.clientes_object.map((elemento, index) => (
              <option value={elemento["id"]}>{elemento["nombre"]}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group label="Persona">
          <Form.Select
            name="Persona"
            value={this.state.personaId}
            onChange={this.changePersona}
          >
            <option value={0}>Selecciona una Persona</option>
            {this.props.stor.personas_object.map((elemento, index) => (
              <option value={elemento["id"]}>{elemento["nombre"]}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <div className="float-right">
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={
                this.state.personaId > 0
                  ? this.state.clienteId > 0
                    ? false
                    : true
                  : true
              }
              onClick={this.guardarCliente}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
          {this.state.accion === "modificar" ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default ClientesContactosForm;
