import React from "react";
import { Table } from "tabler-react";
import { Exportar } from "../../herramientas/Excel";
const IncidenciasMovimientoView = (props) => {
  const getIncidenciaNombre = (id) => {
    for (let item of props.incidenciasTotales) {
      if (id === item.id) {
        return item.nombre;
      }
    }
    return "";
  };
  return (
    <div>
      <span className="float-right ml-2" style={{ width: "200px" }}>
        <Exportar catalogo={"Incidencias"} data={props.incidenciasExcel} />
      </span>
      <Table
        cards={true}
        striped={true}
        responsive={true}
        className="table-vcenter bg-light"
        headerItems={[
          { content: "ID" },
          { content: "Incidencia" },
          { content: "Comentario" },
          { content: "Creado" },
          { content: "Atencion Usuario" },
        ]}
      >
        <Table.Body>
          {props.incidencias
            .filter((word) =>
              word.comentario
                .toUpperCase()
                .includes(props.searchIncidencias.toUpperCase())
            )
            .sort()
            .map((elemento) =>
              [193].includes(props.plataforma) ? ( //traxion
                <Table.Row>
                  <Table.Col>{elemento.idEntregaIncidencia}</Table.Col>
                  <Table.Col>{elemento.incidencia.nombre}</Table.Col>
                  <Table.Col>{elemento.comentario}</Table.Col>
                  <Table.Col>{elemento.fecha}</Table.Col>
                  <Table.Col>
                    {elemento.monitorista.nombre +
                      " " +
                      elemento.monitorista.aPaterno +
                      " " +
                      elemento.monitorista.aMaterno}
                  </Table.Col>
                </Table.Row>
              ) : (
                <Table.Row>
                  <Table.Col>{elemento.idEntregaIncidencia}</Table.Col>
                  <Table.Col>
                    {getIncidenciaNombre(elemento.idIncidencia)}
                  </Table.Col>
                  <Table.Col>{elemento.comentario}</Table.Col>
                  <Table.Col>{elemento.creado}</Table.Col>
                  <Table.Col>{elemento.atencionUsuario}</Table.Col>
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default IncidenciasMovimientoView;
