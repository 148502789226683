import React, { Component } from "react";
import { Page, Card, Grid, Form, Button, Dropdown, Table } from "tabler-react";

var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));


class UsuarioConsolaView extends Component {
    constructor() {
        super();
        this.state = {
            plataformas:[]
        };

    }

    getFuncionalidad=(elemento)=>{
        if(!this.props.funcionalidades){ return false; }
        for(let item of this.props.funcionalidades){
          if(item.nombre === elemento ){ return true; break;}
        }
        return false;
      }
    render() {
        return (
            <Page.Card>                
                { this.props.bloque == "Consola_Naviera" && this.getFuncionalidad("Consola Naviera")?
                <Form.Group label="Selecciona la Plataforma Navieras">
                    <Form.Select>
                     <option value={0}>Seleccione una Opcion</option>
                    <option value={1}>Plataforma</option>
                    </Form.Select>
                </Form.Group>
                :null}
                { this.props.bloque == "Consola_Terrestre" && this.getFuncionalidad("Consola Terrestre")?
                <Form.Group label="Selecciona la Plataforma Terrestre">
                    <Form.Select>
                     <option value={0}>Seleccione una Opcion</option>
                    <option value={1}>Plataforma</option>
                    </Form.Select>
                </Form.Group>
                :null}
            </Page.Card>
        );
    }
}

export default UsuarioConsolaView;
