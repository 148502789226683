import React, { Component } from "react";
import { Card, Form, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

class UsuarioMovimientosTipo extends Component {
    constructor() {
        super();
        this.state = {
            movimientosTipos:[],
            pMovimientosTipos:[],
        };
    }
    componentWillMount(){
      this.getfuntion("get", "", "", "cli_usuarios_movimiento_tipo/usuario/"+this.props.usuario.id+"/plataforma/"+this.props.idPlataforma, "usuarios_movimientosTipos");
      this.getfuntion("get", "", "", "movimientosTipo/plataforma/"+this.props.idPlataforma, "movimientosTipos");
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                if (stateVar === "movimientosTipos")
                  this.setState({ movimientosTipos: returnVal });
                if (stateVar === "usuarios_movimientosTipos")
                  this.setState({ pMovimientosTipos: returnVal });
                break;
              case "post":
                if (stateVar === "usuarios_movimientosTipos"){
                  let pMovimientosTiposTemp= this.state.pMovimientosTipos;  
                  pMovimientosTiposTemp.push(returnVal);
                  this.setState({ pMovimientosTipos: pMovimientosTiposTemp });
                }
                break;
              case "delete":
                if (stateVar === "usuarios_movimientosTipos" && returnVal){ 
                  let varTemp= this.state.pMovimientosTipos;
                  varTemp.map((elem, index )=>{
                    if(obj.idMovimientoTipo === elem.idMovimientoTipo ){ varTemp.splice( index, 1);  this.setState({ pMovimientosTipos: varTemp }); }
                  });
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
    changeMovimientosTipo=(event)=>{
      let objTemp= {idUsuario: this.props.usuario.id, idMovimientoTipo: parseInt(event.target.value, 10), idPlataforma: this.props.idPlataforma };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "cli_usuarios_movimiento_tipo","usuarios_movimientosTipos");  
      }else{
        this.getfuntion("delete", objTemp, this.props.usuario.id+"/"+parseInt(event.target.value, 10)+"/"+this.props.idPlataforma, "cli_usuarios_movimiento_tipo","usuarios_movimientosTipos");
      }      
    }
    getMovimientosTipo    =(id)=>{ for( let  item of this.state.pMovimientosTipos ){  if( item.idMovimientoTipo === id ){   return true; } } return false; }
    render() {
        return (
            <div>
              <Card>
                <Card.Body>
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>Id</Table.ColHeader>
                        <Table.ColHeader>movimientosTipo  </Table.ColHeader>
                        <Table.ColHeader>estatus </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.movimientosTipos.map((t, index) =>
                        <Table.Row align="center">
                            <Table.Col>{ t.id }</Table.Col>
                            <Table.Col>{ t.nombre }</Table.Col>
                            <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getMovimientosTipo(t.id) } onChange={ this.changeMovimientosTipo} /></Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>                                                            
                </Card.Body>
              </Card>
                <br/>
            </div>
        );
    }
}

export default UsuarioMovimientosTipo;