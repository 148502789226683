import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../herramientas/Crud_Catalogos';
import { timingSafeEqual } from "crypto";
class  Operacionestipo extends Component {
  constructor(props) {
    super(props);
    this.state = { 
                                   id: "",
                               accion: this.props.accion, //'nuevo'// modificar, nuevo
                   Operacionesestatus: [],
                               search:""
                 };
                  this.Operacionesestatus= [];
                this.plataformasObject= [];
            this.checarOperacionesestatus= 0;      //temporal render
      this.idPlataformaOperacionesestatus= 0; //temporal render
 this.plataformasOperacionesestatusObject= [];
             this.OperacionesestatusArray= [];
  }

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({id: this.props.elementos.id});
      this.plataformasObject= this.props.elementos;
      this.getfuntion("get", "", "", "plataformasoperacionesestatus/plataforma/"+this.props.elementos.id,"Operacionesestatus")
    }else{}
  }
getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
  Crud_Catalogos(catalogo, "plataformas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Operacionesestatus"){ this.Operacionesestatus= returnVal; this.setState({ Operacionesestatus: returnVal }); }
          }
          break;
        case "post":
          {
           if (stateVar === "Operacionesestatus"){
              this.Operacionesestatus.push(returnVal);
              this.setState({ Operacionestipo: this.Operacionesestatus });
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Operacionesestatus"){
              this.Operacionesestatus.map((elemento, index)=>
                {if(id === elemento.idPlataformaOperacionEstatus){
                  this.Operacionesestatus.splice(index, 1);
                }}
              )
              this.setState({ Operacionesestatus: this.Operacionesestatus })
            }
          }
            break;
        default:
          break;
      }
    }).catch(err =>{
      if( err.response ){
        console.log(err.response);
      }else{
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
  })
}
  
    changeOperacionesestatus =(OperacionesEstatusIndex, OtdId, OtdStatus) => {
      this.plataformasOperacionesestatusObject={
        "idPlataforma": this.plataformasObject,
        "idOperacionEstatus": this.props.stor.operacionesEstatus_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[OperacionesEstatusIndex]
      }
      
      this.OperacionesestatusArray[OperacionesEstatusIndex].estatus === 1 ?
      this.getfuntion("delete", "", this.OperacionesestatusArray[OperacionesEstatusIndex].id, "plataformasoperacionesestatus", "Operacionesestatus")
      :
      this.getfuntion("post",this.plataformasOperacionesestatusObject, "", "plataformasoperacionesestatus", "Operacionesestatus");

      
    }
    checkedOperacionesestatus =(indice) =>{
      return this.OperacionesestatusArray[indice].estatus
    }
    checarOperacionesestatuss =(estatus, id) =>{
      this.OperacionesestatusArray.push({ estatus: estatus, id: id })
    }
    checarOperacionesestatusTem =(valor) =>{
      this.checarOperacionesestatus=valor;
    }
    searching =(event) =>{
      this.setState({ search: event.target.value })
    }
    cleanArrayList =() =>{
      this.OperacionesestatusArray= [];
    }
  render(){
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        { this.cleanArrayList() }
        { this.state.accion === "modificar" ?
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Operaciones Estatus"
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={ false}
                  options={ <Form.Group>
                              <Form.Input
                                className="mb-3"
                                icon="search"
                                placeholder="Buscar Operación estatus..."
                                position="append"
                                onChange={this.searching}
                              />
                            </Form.Group>}
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Status</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.operacionesEstatus_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{ elemento["id"] }</Table.Col>
                                  <Table.Col>{ elemento["nombre"] }</Table.Col>
                                  { this.checarOperacionesestatusTem(0)}
                                  { this.idPlataformaOperacionesestatus="" }
                                  { 
                                    this.state.Operacionesestatus.map((elemOperacionesestatus, ind) =>
                                    {
                                      if(elemOperacionesestatus.idOperacionEstatus.id === elemento.id){
                                        { this.checarOperacionesestatusTem(1)}
                                        this.idPlataformaOperacionesestatus=elemOperacionesestatus.idPlataformaOperacionEstatus;
                                      }
                                    })
                                  }
                                  { this.checarOperacionesestatuss(this.checarOperacionesestatus, this.idPlataformaOperacionesestatus)}
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                          checked={ this.checkedOperacionesestatus(index) }
                                                          className={"disabled"}
                                                          onChange={ () => this.changeOperacionesestatus(index, elemento["id"], this.checarOperacionesestatus) } /></Table.Col>
                                </Table.Row>
                              )
                            }
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>
            
            </Grid.Row>
          </div>
        :null}
      </div>
    )
  }
}

export default Operacionestipo
