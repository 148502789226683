import React, { Component } from "react";
import { Table, Button, Icon, Form } from "tabler-react";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
import EntregaFrom from "./EntregaForm";
import ModalLay from "../../../../components/ModalLay/ModalLay";
import EtaCalculadaTerrestre from "../EtaCalculadaTerrestre";

class Entregas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entregas: [],
      hiddenModals: false,
      destinos: [],
      movimientoTerrestre: "",
      elementos: [],
      etasCalculadas: {},
      eliminada: false
    };
    this.etasCalculadas = {};
    this.searchingentregas = [];
    this.entregas = [];
    this.movimientoAcumuladoresTerrestre = {};
  }
  componentWillMount = () => {
    //this.getfuntion("get", "", "", "entregas/movimiento/" + this.props.obj.id, "entregas");
    this.getfuntion(
      "get",
      "",
      "",
      "destinos/plataforma/" + this.props.obj.plataforma.id,
      "destinos"
    );
  };
  componentDidMount = () => {
    if (this.props.entregas) {
      this.setState({ entregas: this.props.entregas });
      this.entregas = this.props.entregas;
      this.entregas.map((entrega, index) => {
        this.AcumuladoresEtaTerrestre(entrega.idEntrega);
      });
    }
  };

  AcumuladoresEtaTerrestre = (idEntrega) => {
    var conteo = 0;
    Crud_Catalogos(
      "terrestreEntregasAcumuladores/etasEntrega/" + idEntrega,
      "terrestreEntregasAcumuladores",
      "get"
    )
      .then((returnVal) => {
        if (returnVal > 0) {
          this.etasCalculadas[idEntrega] = returnVal;
          this.setState({ etasCalculadas: this.etasCalculadas });
        } else {
        }
        //return returnVal;
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        } else {
          /**
           * Error
           */
        }
      });
    setTimeout(() => {
      return conteo;
    }, 1000);
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "entregas",
    stateVar = "entregas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "entregasTerrestre",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "destinos" && returnVal[0].id) {
                this.setState({ destinos: returnVal });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "entregas" && returnVal.idEntrega) {
                this.hiddenmodal();
                this.entregas.push(returnVal);
                this.setState({ entregas: this.entregas });
              }
            }
            break;
          case "put":
            {
              if (stateVar === "entregas" && returnVal.idEntrega) {
                this.hiddenmodal();
                //Colocamos todas las entregas en estatus 0, solo si el estatus de la entrega ha cambiado
                if (returnVal.estatus !== obj.estatus || returnVal.estatus) {
                  this.entregas.map(
                    (et, index) => (this.entregas[index].estatus = 0)
                  );
                }
                //Sustituir entrega actualizada
                this.entregas.map((et, index) => {
                  if (et.idEntrega === returnVal.idEntrega) {
                    this.entregas[index] = returnVal;
                    this.setState({ entregas: this.entregas });
                  }
                });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "entregas" && returnVal === true) {
                this.hiddenmodal();
                for (let i = 0; i < this.entregas.length; i++) {
                  if (this.entregas[i].idEntrega == id) {
                    this.entregas.splice(i, 1);
                    this.setState({ entregas: this.entregas });
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  changeEstatus = (e) => {
    for (let item of this.state.entregas) {
      if (item.idEntrega === parseInt(e.target.value, 10)) {
        let entTem = item;
        entTem.estatus = e.target.checked ? 1 : 0;
        this.getfuntion("put", entTem, "", "entregas", "entregas");
        if (e.target.checked) {
          this.props.setIdEntregaActiva(entTem);
        } else {
          this.props.setIdEntregaActiva({});
        }
        break;
      }
    }
  };
  render() {
    return (
      <div className="mt-1">
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <ModalLay
              tamanio={800}
              title="Nueva"
              name={
                <Button pill size="sm" outline color="primary">
                  <span data-toggle="tooltip"
                          data-placement="top"
                          title={"Crear Nueva Entrega"}>
                    <Icon name="plus" />
                  </span>
                </Button>
              }
              formulario={EntregaFrom}
              datosForm={{
                accion: "nuevo",
                idmovimiento: this.props.obj.id,
                destinos: this.state.destinos,
                stor: this.props,
                getfuntion: this.getfuntion,
                closeModal: this.hiddenmodal,
              }}
            />
          </div>
          <div className="col">
            <Button pill size="sm" outline color="primary" onClick={() => this.setState({eliminada:!this.state.eliminada})}>
              <Icon name={this.state.eliminada ? "eye" : "eye-off" } />
              {this.state.eliminada ? "Ver Activas" : "Ver Canceladas" }
            </Button>
          </div>
        </div>
        <div className="col" style={{color: '#0992A7'}}><span>{!this.state.eliminada ? "Entregas Activas" : "Entregas Canceladas"}</span></div>
        <Table
          cards={true}
          striped={true}
          responsive={true}
          className="table-vcenter bg-light"
          headerItems={[
            { content: "Destino" },
            { content: "Activa" },
            { content: "Carta Porte" },
            { content: "Arribo" },
            { content: "Salida" },
            { content: "Editar" },
            { content: "ETAS" },
            { content: "FechaAlta" },
            { content: "DIRECCIÓN" },
          ]}
        >
          <Table.Header></Table.Header>
          <Table.Body>
            {this.state.entregas.filter(e => this.state.eliminada ? (e.cancelada || e.canceladaManual) : (!e.cancelada && !e.canceladaManual))
              .sort(function (a, b) {
                //actualizar el state de entregas, no solo hacer el PUT
                if (a["orden"] === null) {
                  return -1;
                }
                if (a["orden"] > b["orden"]) {
                  return 1;
                }
                if (a["orden"] < b["orden"]) {
                  return -1;
                }
                return 0;
              })
              .map((elemento, index) => (
                <>
                  <Table.Row
                    style={
                      elemento.estatus
                        ? { "background-color": "rgba(95, 231, 50, 0.30)" }
                        : null
                    }
                  >
                    <Table.Col>{elemento.destino}</Table.Col>

                    <Table.Col>
                      <Form.Switch
                        type="Checkbox"
                        name="toggle"
                        value={elemento.idEntrega}
                        checked={elemento.estatus}
                        onChange={this.changeEstatus}
                      />
                    </Table.Col>
                    <Table.Col>{elemento.cartaPorte}</Table.Col>
                    <Table.Col>{elemento.fechaArribo}</Table.Col>

                    <Table.Col>{elemento.fechaSalida}</Table.Col>

                    <Table.Col>
                      <ModalLay
                        tamanio={800}
                        title="Editar"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <Icon name="edit" />
                          </Button>
                        }
                        formulario={EntregaFrom}
                        datosForm={{
                          accion: "modificar",
                          idmovimiento: this.props.obj.id,
                          destinos: this.state.destinos,
                          elementos: elemento,
                          stor: this.props,
                          getfuntion: this.getfuntion,
                          closeModal: this.hiddenmodal,
                        }}
                      />
                    </Table.Col>

                    <Table.Col>
                      <ModalLay
                        tamanio={1200}
                        title="Eta"
                        name={
                          <Button pill size="sm" outline color="primary">
                            <Icon name="message-circle" />
                            <span>
                              {this.state.etasCalculadas[elemento.idEntrega]
                                ? this.state.etasCalculadas[elemento.idEntrega]
                                : null}
                            </span>
                          </Button>
                        }
                        formulario={EtaCalculadaTerrestre}
                        datosForm={{
                          accion: "modificar",
                          idEntrega: elemento.idEntrega,
                          destinos: this.state.destinos,
                          elementos: elemento,
                          stor: this.props,
                          getfuntion: this.getfuntion,
                          closeModal: this.hiddenmodal,
                        }}
                      />
                    </Table.Col>

                    <Table.Col>{elemento.fechaAlta}</Table.Col>
                    <Table.Col>{elemento.direccion}</Table.Col>
                  </Table.Row>
                </>
              ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
export default Entregas;
