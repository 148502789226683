import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid, Card, Table, Dimmer } from "tabler-react";
import { confirmAlert } from 'react-confirm-alert';
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import { PersonasForm } from "../..";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { TablePageable } from "../../../Helpers";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";

const Contactos =({accion, elementos, plataforma, stor})=> {
  const [contacto, setContacto]= useState({});
  const [contactoAgregar, setContactoAgregar]= useState({});
  const [contactos, setContactos]= useState([]);
  const [contactosList, setContactosList]= useState([]);
  const [agregar, setAgregar]= useState(false);
  /*
    this.state = {
      contactos: [],
      clientesContactos: [],
      Personas: [],
      FHabilitados: [],
      searchP: "",
      searchM: "",
      searchN: "",
      searchCorreo: "",
      currentTap: 0,
      pagination: [],
      pagina: 1,
      pagesize: 10,
    };
  */
  useEffect(() => {
    if ( accion !== "nuevo") {
      getfuntion( "get", "", "", "transportistasContactos/transportista/" + elementos.id, "contactos");
    }
  }, []);
  useEffect(() => {
    if(agregar)
      getfuntion( "get", "", "", "personas", "ContactosDisponibles");
  }, [agregar]);

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "personas",
    stateVar = "personas",
    hiddenModl = ""
  ) => {
    let catTemp = "";
    if (metodo === "post" && catalogo === "personas") {
      catTemp = "personas/plataforma/" + ( plataforma ? plataforma.id : 61);
    } else {
      catTemp = catalogo;
    }
    return Crud_Catalogos(
      catTemp,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "contactos") {
                for (let item of returnVal) {
                  if(item.persona){
                    item.persona.correoEstatus = (item.persona.correoEstatus? true:false);
                    if (!item.persona.aMaterno)
                    item.persona.aMaterno = "";
                    if (!item.persona.aPaterno)
                      item.persona.aPaterno = "";
                    if (!item.persona.nombre)
                      item.persona.nombre = "";
                  }
                }
                setContactos(returnVal);
              }
              if (stateVar === "ContactosDisponibles" && returnVal) {
                  let personasTemp=[];
                  for(let p of returnVal){
                    if(p.aPaterno === null)  p.aPaterno= "";
                    if(p.aMaterno === null)  p.aMaterno= "";
                    if(p.nombre === null)  p.nombre= "";
                    personasTemp.push(p);
                  }
                  setContactosList( personasTemp );
              }
            }
            break;
          case "post":
            {
              if (stateVar === "personas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se ha agregado contacto al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  getfuntion(
                    "post",
                    { transportista: elementos, persona: returnVal },
                    "",
                    "transportistasContactos",
                    "transportistasContactos"
                  );
                }
              }
              if (stateVar === "transportistasContactos") {
                if (returnVal.persona) {
                  NotificationManager.info(
                    "El contacto ahora pertenece al transportista( " +
                      elementos.nombre +
                      " )",
                    "Nuevo"
                  );
                  let contactosTem = [...contactos];
                  returnVal.persona.correoEstatus = (returnVal.persona.correoEstatus? true:false);
                  contactosTem.push(returnVal);
                  setContactos(contactosTem);
                  setAgregar(false);
                  setContactoAgregar({});
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "transportistasContactos") {
                if (returnVal) {
                  setContacto({});
                  getfuntion( "get", "", "", "transportistasContactos/transportista/" + elementos.id, "contactos");
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      {/**
       *   Contactos del transportista
       */}
      {accion === "modificar" && (
        <div>
          <Grid.Row>
            <Grid.Col>
              {!agregar &&
                <div>
                  <h> El Transportista {elementos.nombre } cuenta con los siguientes contactos </h>
                  <TablePageable
                    titulo={
                      <div className="row">
                        <div className="col">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Nuevo contacto"}
                            onClick={() => setAgregar(true)}
                          >
                            <h3 className="text-primary"><Icon name="plus" /></h3>
                          </span>
                        </div>
                        <div className="col ml-1" hidden={!contacto.id}>
                          <span data-toggle="tooltip" data-placement="top" title={"Remover"}
                            onClick={() =>
                              confirmAlert({
                                title: 'Atención',
                                message: '¿Esta seguro de remover el contacto: '+contacto.persona.nombre+') ?',
                                buttons: [
                                  {
                                    label: 'Confirmar',
                                    onClick: () => {
                                      getfuntion(
                                        "delete",
                                        contacto,
                                        elementos.id + "/" + contacto.persona.id,
                                        "transportistasContactos/borrar",
                                        "transportistasContactos"
                                      );
                                    }
                                  },
                                  {
                                    label: 'Cancelar',
                                    onClick: () => {return false}
                                  }
                                ]
                              })
                            }
                          >
                            <h3 className="text-danger"><Icon name="trash" /></h3>
                          </span>
                        </div>
                        <div className="col ml-1" hidden={!contacto.id}>
                          <ModalLay
                            tamanio={"90%"}
                            title="Modificar"
                            name={
                              <h4 className="text-primary"> <Icon name="edit" /> </h4>
                            }
                            formulario={PersonasForm}
                            datosForm={{
                              accion: "modificar",
                              getfuntion: getfuntion,
                              stor: stor,
                              elementos: contacto.persona,
                              plataforma: plataforma,
                            }}
                          />
                        </div>
                        <div className="col">
                          <i className="fa fa-refresh"  style={{"font-size": "15px"}}
                            onClick={()=> getfuntion( "get", "", "", "transportistasContactos/transportista/" + elementos.id, "contactos") }
                          ></i>
                        </div>
                      </div>
                    }
                    lista= { contactos }
                    columnas={
                      [
                        { columna: "persona.nombre", label:"Nombre" },
                        { columna: "persona.aPaterno", label:"Paterno" },
                        { columna: "persona.aMaterno", label:"Materno" },
                        { columna: "persona.correo", label:"Correo" },
                        { columna: "persona.correoEstatus", label:"Envia Correo" },
                      ]
                    }
                    id= "id"
                    selected={(e)=> setContacto(e)}
                  />
                </div>}
              { agregar &&
                <div>
                  <h5>Acontinuación se muestra del catalogo general, los contactos ya agregados disponibles</h5>
                  <TablePageable
                    titulo={
                      <div className="row">
                        <div className="col">
                          <span>
                            <ModalLay
                              tamanio={"80%"}
                              title="Nuevo"
                              name={
                                <h3
                                  className="text-primary"
                                  data-toggle="tooltip" data-placement="top" title={"Crear nuevo contacto"}>
                                  <Icon name="plus" />
                                </h3>
                              }
                              formulario={PersonasForm} 
                              datosForm={{
                                accion: "nuevo",
                                elementos: [],
                                getfuntion: getfuntion,
                                stor: stor,
                              }}
                            />
                          </span>
                        </div>
                        <div className="col ml-1" hidden={ !contactoAgregar.id}>
                          < span data-toggle="tooltip" data-placement="top" title={"Vincular"}
                            onClick={() =>
                              getfuntion(
                                "post",
                                { transportista: elementos, persona: contactoAgregar },
                                "",
                                "transportistasContactos",
                                "transportistasContactos"
                              )
                            }
                          >
                              <h3 className="text-primary"><Icon name="check-square" /></h3>
                          </span>
                        </div>
                        <div className="col ml-1">
                          <span
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Cancelar"}
                            onClick={() =>{ setAgregar(false); setContactoAgregar({});}}
                          >
                            <h3 className="text-danger"><Icon name="x" /></h3>
                          </span>
                        </div>
                      </div>
                    }
                    lista= { contactosList }
                    columnas={
                      [
                        { columna: "nombre", label:"Nombre" },
                        { columna: "aPaterno", label:"Paterno" },
                        { columna: "aMaterno", label:"Materno" },
                        { columna: "correo", label:"Correo" },
                        { columna: "correoEstatus", label:"Envia Correo" },
                      ]
                    }
                    id= "id"
                    selected={(e)=> setContactoAgregar(e)}
                  />{/*this._addCol(elemento)*/}
                </div>
              }
            </Grid.Col>
          </Grid.Row>
        </div>
      ) }
    </div>
  );
}
export default Contactos;
