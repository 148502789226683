import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import { SketchPicker } from "react-color";
//import Select from 'react-select';
class AlertasCategoriasForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      nombre: "",
      logo: "",
      color: "",
      tiempoAtencion: "",
      idPlataforma: "",
      crudbutonEnable: true, // Se desabilita cuando se hace click
      accion: this.props.accion,
      horaVida: "",
    };

    this.enableSave = this.enableSave.bind(this);
  }
  isEnable = (Accion) => {
    let borrar = "alertas_categorias_borrar";
    let actualizar = "alertas_categorias_editar";
    let agregar = "alertas_categorias_agregar";
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  componentDidMount = () => {
    //console.log(this.props);
    if (this.state.accion === "nuevo") {
    } else {
      this.setState({ id: this.props.elementos.id });
      this.setState({ nombre: this.props.elementos.nombre });
      this.setState({ logo: this.props.elementos.logo });
      this.setState({ color: this.props.elementos.color });
      this.setState({ Vida: this.props.elementos.tiempoAtencion });
      this.setState({ idPlataforma: this.props.elementos.idPlataforma });
    }
  };

  enableSave = () => {
    console.log("Enable button: " + this.state.nombre);
    if (this.state.nombre.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  changeName = (event) => {
    this.setState({ nombre: event.target.value });
  };
  changeLogo = (event) => {
    this.setState({ logo: event.target.value });
    console.log(event.target.value);
  };
  changeColor = (color) => {
    this.setState({ color: color.hex });
  };
  changeHoraVida = (event) => {
    this.setState({ Vida: event.target.value });
    console.log(event.target.value);
  };
  changeTiempoAtencion = (event) => {
    this.setState({ tiempoAtencion: event.target.value });
  };
  changeIdPlataforma = (event) => {
    this.setState({ idPlataforma: event.target.value });
  };
  guardarCategoria = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  //<Form.Input name="color" type="text" value={ this.state.color } onChange={this.changeColor} maxlength="30"/>
  delete() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("delete", "", this.state.id);
  }
  crud_put() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("put", {
      id: this.state.id,
      nombre: this.state.nombre,
      logo: this.state.logo,
      color: this.state.color,
      tiempoAtencion: this.state.Vida,
      idPlataforma: this.state.idPlataforma,
    });
  }
  // crud_put()  {console.log(this.state.id,this.state.nombre,this.state.logo,this.state.color,this.state.tiempoAtencion, this.state.idPlataforma,); }
  crud_post() {
    this.setState({ crudbutonEnable: false });
    this.props.getfuntion("post", {
      id: this.state.id,
      nombre: this.state.nombre,
      logo: this.state.logo,
      color: this.state.color,
      tiempoAtencion: this.state.Vida,
      idPlataforma: this.state.idPlataforma,
    });
  }

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>{this.props.elementos.nombre}</h1>
        ) : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={3} lg={3}>
                <Form.Group label="id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={9} lg={9}>
                <Form.Group label="Nombre">
                  <Form.Input
                    name="nombre"
                    type="text"
                    value={this.state.nombre}
                    onChange={this.changeName}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={5} lg={5}>
                <Form.Group label="Logo">
                  <Form.Select
                    className="fa"
                    name="logo"
                    onChange={this.changeLogo}
                    value={this.state.logo}
                  >
                    <option value="fa-exclamation">
                      &#xf12a; &nbsp;Panico
                    </option>
                    <option value="fa-battery-empty">
                      &#xf244; &nbsp;Bateria
                    </option>
                    <option value="fa-truck">&#xf0d1; &nbsp;Parada</option>
                    <option value="fa-globe">&#xf0ac; &nbsp;GPS</option>
                    <option value="fa-location-arrow">
                      &#xf124; &nbsp;Geolocalizacion
                    </option>
                    <option value="fa-signal">&#xf012; &nbsp;Jamming</option>
                    <option value="fa-arrow-right">&#xf061; &nbsp;Ruta</option>
                    <option value="fa-question">&#xf128; &nbsp;SN</option>
                    <option value="fa-flag">&#xf024; &nbsp;Bandera</option>
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={5} lg={5}>
                <Form.Group label="Tiempo Limite">
                  <Form.MaskedInput
                    name="Vida"
                    placeholder="00:00:00"
                    mask={[/\d/, /\d/, ":", /\d/, /\d/, ":", /\d/, /\d/]}
                    onChange={this.changeHoraVida}
                    maxlength="30"
                    value={this.state.Vida}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Color">
                  <SketchPicker
                    color={this.state.color}
                    onChangeComplete={this.changeColor}
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>

            <Grid.Row>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Tiempo Atencion">
                  <Form.Input
                    name="tiempoAtencion"
                    type="text"
                    value={this.state.tiempoAtencion}
                    onChange={this.changeTiempoAtencion}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={6} lg={6}>
                <Form.Group label="Plataforma">
                  <Form.Input
                    name="idPlataforma"
                    type="text"
                    value={this.state.idPlataforma}
                    onChange={this.changeIdPlataforma}
                    maxlength="30"
                  />
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar" || this.isEnable("agregar")) ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarCategoria}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          {this.state.accion === "modificar" && this.isEnable("borrar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => this.delete()}
                disabled={!this.state.crudbutonEnable}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default AlertasCategoriasForm;
