import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../../herramientas/Crud_Catalogos";
import Select from "react-select";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";
class Unidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: "post", //'nuevo'// modificar, nuevo
      idUnidadTipo: { value: 0, label: "Seleccione una opción ", data: null },
      idUnidadMarca: { value: 0, label: "Seleccione una opción ", data: null },
      nombre: "",
      placa: "",
      color: "",
      modelo: "",
      estatus: "",
      idUnidadEstatusOperacion: {
        value: 0,
        label: "Seleccione una opción ",
        data: null,
      },
      search: "",
      Unidades: [],
      gpsUnidad: [],
      gpss: [],
      addgps: false,
      searchGps: "",
    };
    this.destinos = [];
    this.UnidadesObj = {
      idPlataforma: this.props.elementos,
      idTransportista: this.props.idTransportista,
      idUnidadTipo: {
        id: 0,
      },
      idUnidadMarca: {
        id: 0,
      },
      nombre: "",
      placa: "",
      color: "",
      modelo: "",
      estatus: true,
      idUnidadEstatusOperacion: {
        id: 0,
      },
    };
    this.valid = {
      idUnidadTipo: false,
      idUnidadMarca: false,
      placa: false,
      idUnidadEstatusOperacion: false,
    };
    this.UnidadsMarcas = [];
    this.unidadesTipo = [];
    this.unidadesEstatusOperacion = [];
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
    } else {
    }
    this.UnidadsMarcas.push({
      value: 0,
      label: "Seleccione una opción ",
      data: null,
    });
    this.props.stor.unidadesmarcas_object.map((elemento, index) => {
      this.UnidadsMarcas.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      });
    });

    this.unidadesTipo.push({
      value: 0,
      label: "Seleccione una opción ",
      data: null,
    });
    this.props.stor.unidadTipo_object.map((elemento, index) => {
      this.unidadesTipo.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      });
    });

    this.unidadesEstatusOperacion.push({
      value: 0,
      label: "Seleccione una opción ",
      data: null,
    });
    this.props.stor.unidadEstatusOperacion_object.map((elemento, index) => {
      this.unidadesEstatusOperacion.push({
        value: elemento.id,
        label: elemento.nombre,
        data: elemento,
      });
    });
    this.getfuntion(
      "get",
      "",
      "",
      "unidadTerNav/idPlataforma/" +
        this.props.elementos.id +
        "/idTransportista/" +
        this.props.idTransportista.id,
      "Unidades"
    );
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "transportistas",
    stateVar = "transportistas",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "transportistas") {
                if (returnVal.content) {
                  this.setState({ transportistas: returnVal.content });
                  this.paginacion(returnVal);
                  this.transportistasPagination = returnVal;
                }
              }
              if (stateVar === "Unidades" && returnVal) {
                this.setState({ Unidades: returnVal });
              }
              if (stateVar === "gpsUnidad" && returnVal) {
                this.setState({ gpsUnidad: returnVal });
              }
              if (stateVar === "unidadGpsTerNav") {
                return returnVal;
              }
              if (stateVar === "gpss") {
                this.setState({ gpss: returnVal });
              }
            }
            break;
          case "post":
            {
              if (stateVar === "Unidades" && returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del la unidad ( " +
                    returnVal.nombre +
                    " )",
                  "Modificación"
                );
                let unidadesTem = this.state.Unidades;
                unidadesTem.unshift(returnVal);
                this.setState({ Unidades: unidadesTem });
              }
              if (stateVar === "transportistas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se ha agregado el transportista al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  let transportistasTem = this.state.transportistas;
                  transportistasTem.unshift(returnVal);
                  this.setState({ transportistas: transportistasTem });
                  this.getfuntion(
                    "post",
                    {
                      plataforma: this.props.elementos,
                      transportista: returnVal,
                    },
                    "",
                    "plataformasTransportistas",
                    "plataformasTransportistas"
                  );
                }
              }
              if (stateVar === "unidadGpsTerNav") {
                return returnVal;
              }
            }
            break;
          case "put":
            {
              if (stateVar === "Unidades" && returnVal.id) {
                NotificationManager.info(
                  "Se han agregado las modificaciones del la unidad ( " +
                    returnVal.nombre +
                    " )",
                  "Modificación"
                );
                let unidadesTem = this.state.Unidades;
                for (let item of unidadesTem) {
                  if (item.id == returnVal.id) {
                    item = returnVal;
                  }
                }
                this.setState({ Unidades: unidadesTem });
              }
              if (stateVar === "gps" && returnVal.id) {
                NotificationManager.info(
                  "Se ha cambiado el estatus de gps ( " +
                    returnVal.nombre +
                    " )",
                  "Modificación"
                );
                let gpsTemp = this.state.gpsUnidad;
                for (let item of gpsTemp) {
                  if (item.id == returnVal.id) {
                    item = returnVal;
                  }
                }
                this.setState({ gpsUnidad: gpsTemp });
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "Unidades") {
                if (returnVal) {
                  let unidadesTem = this.state.Unidades;
                  unidadesTem.map((item, index) => {
                    if (item.id == id) {
                      unidadesTem.splice(index, 1);
                      this.setState({ Unidades: unidadesTem });
                    }
                  });
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
              if (stateVar === "gpsUnidad") {
                if (returnVal) {
                  let gpsUnidadTem = this.state.gpsUnidad;
                  gpsUnidadTem.map((item, index) => {
                    if (item.id == obj) {
                      gpsUnidadTem.splice(index, 1);
                      this.setState({ gpsUnidad: gpsUnidadTem });
                    }
                  });
                  NotificationManager.info(
                    "Se a removido correctamente",
                    "Borrar"
                  );
                } else {
                  NotificationManager.error("No se ha podido borrar", "Borrar");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  isValid = (campo) => {
    switch (campo) {
      case "idUnidadTipo":
        return this.valid.idUnidadTipo;
      case "idUnidadMarca":
        return this.valid.idUnidadMarca;
      case "placa":
        return this.valid.placa;
      case "idUnidadEstatusOperacion":
        return this.valid.idUnidadEstatusOperacion;
      default:
        return false;
        break;
    }
  };

  changeIdUnidadTipo = (event) => {
    this.setState({ idUnidadTipo: event });
    if (event.value > 0) {
      this.valid.idUnidadTipo = true;
    } else {
      this.valid.idUnidadTipo = false;
    }
    this.UnidadesObj.idUnidadTipo = event.data;
  };

  changeIdUnidadMarca = (event) => {
    this.setState({ idUnidadMarca: event });
    this.setState({
      nombre: event.label + "  " + this.state.modelo + "  " + this.state.placa,
    });
    this.UnidadesObj.nombre =
      event.label + "  " + this.state.modelo + "  " + this.state.placa;

    if (event.value > 0) {
      this.valid.idUnidadMarca = true;
    } else {
      this.valid.idUnidadMarca = false;
    }
    this.UnidadesObj.idUnidadMarca = event.data;
  };

  changeIdUnidadEstatusOperacion = (event) => {
    this.setState({ idUnidadEstatusOperacion: event });
    if (event.value > 0) {
      this.valid.idUnidadEstatusOperacion = true;
    } else {
      this.valid.idUnidadEstatusOperacion = false;
    }
    this.UnidadesObj.idUnidadEstatusOperacion = event.data;
  };
  changeNombre = (event) => {
    this.setState({ nombre: event.target.value });
    this.UnidadesObj.nombre = event.target.value;
  };

  changePlaca = (event) => {
    this.setState({ placa: event.target.value });
    this.setState({
      nombre:
        this.state.idUnidadMarca.label +
        "  " +
        this.state.modelo +
        "  " +
        event.target.value,
    });
    this.UnidadesObj.nombre =
      this.state.idUnidadMarca.label +
      "  " +
      this.state.modelo +
      "  " +
      event.target.value;

    if (event.target.value.length > 3) {
      this.valid.placa = true;
    } else {
      if (event.target.value.length <= 3) {
        this.valid.placa = false;
      }
    }
    this.UnidadesObj.placa = event.target.value;
  };
  changeColor = (event) => {
    this.setState({ color: event.target.value });
    this.UnidadesObj.color = event.target.value;
  };
  changeModelo = (event) => {
    this.setState({ modelo: event.target.value });
    this.setState({
      nombre:
        this.state.idUnidadMarca.label +
        "  " +
        event.target.value +
        "  " +
        this.state.placa,
    });
    this.UnidadesObj.nombre =
      this.state.idUnidadMarca.label +
      "  " +
      event.target.value +
      "  " +
      this.state.placa;

    this.UnidadesObj.modelo = event.target.value;
  };
  changeEstatus = (event) => {
    this.setState({ estatus: event.target.checked });
    this.UnidadesObj.estatus = event.target.checked;
  };

  UnidadEdit = (elemento) => {
    if (elemento.idUnidadMarca) {
      this.setState({
        idUnidadMarca: {
          value: elemento.idUnidadMarca.id,
          label: elemento.idUnidadMarca.nombre,
        },
      });
    }
    if (elemento.idUnidadTipo) {
      this.setState({
        idUnidadTipo: {
          value: elemento.idUnidadTipo.id,
          label: elemento.idUnidadTipo.nombre,
        },
      });
    }
    if (elemento.idUnidadEstatusOperacion) {
      this.setState({
        idUnidadEstatusOperacion: {
          value: elemento.idUnidadEstatusOperacion.id,
          label: elemento.idUnidadEstatusOperacion.nombre,
        },
      });
    }
    this.setState({ nombre: elemento.nombre });
    this.setState({ placa: elemento.placa });
    this.setState({ color: elemento.color });
    this.setState({ modelo: elemento.modelo });
    this.setState({ estatus: elemento.estatus });
    this.UnidadesObj = elemento;
    this.valid = {
      idUnidadTipo: true,
      idUnidadMarca: true,
      placa: true,
      idUnidadEstatusOperacion: true,
    };
    this.setState({ accion: "modificar" });
    this.getfuntion("get", "", "", "gps/idunidad/" + elemento.id, "gpsUnidad");
    this.setState({ gpsUnidad: [] });
    this.setState({ addGps: false });
    this.setState({ searchGps: "" });
  };

  crud_put = () => {
    this.getfuntion("put", this.UnidadesObj, "", "unidadTerNav", "Unidades");
    this.setState({ accion: "nuevo" });

    this.setState({
      idUnidadMarca: { value: 0, label: "Seleccione una opción ", data: null },
    });
    this.setState({
      idUnidadTipo: { value: 0, label: "Seleccione una opción ", data: null },
    });
    this.setState({
      idUnidadEstatusOperacion: {
        value: 0,
        label: "Seleccione una opción ",
        data: null,
      },
    });
    this.setState({ nombre: "" });
    this.setState({ placa: "" });
    this.setState({ color: "" });
    this.setState({ modelo: "" });
    this.setState({ estatus: false });

    this.UnidadesObj = {
      idPlataforma: this.props.elementos,
      idTransportista: this.props.idTransportista,
      idUnidadTipo: {
        id: 0,
      },
      idUnidadMarca: {
        id: 0,
      },
      nombre: "",
      placa: "",
      color: "",
      modelo: "",
      estatus: true,
      idUnidadEstatusOperacion: {
        id: 0,
      },
    };
    this.valid = {
      idUnidadTipo: false,
      idUnidadMarca: false,
      placa: false,
      idUnidadEstatusOperacion: false,
    };
  };
  crud_delete = (id) => {
    this.getfuntion("delete", "", id, "unidadTerNav", "Unidades");
  };
  delete_gps = (id) => {
    this.getfuntion(
      "get",
      "",
      "",
      "unidadGpsTerNav/idGps/" + id,
      "unidadGpsTerNav"
    ).then((returnVal) => {
      if (!returnVal) {
        NotificationManager.error(
          "Ha ocurrido un error al borrar el Gps",
          "Modificación"
        );
      } else {
        for (let item of returnVal) {
          if (item.idUnidadNavTer === this.UnidadesObj.id) {
            this.getfuntion(
              "delete",
              id,
              item.idUnidadNavTer +
                "/" +
                item.idGps +
                "/" +
                item.idUnidadTipoColocacion,
              "unidadGpsTerNav",
              "gpsUnidad"
            );
          } else {
            NotificationManager.error(
              "Ha ocurrido un error al borrar el Gps",
              "Modificación"
            );
          }
        }
      }
    });
  };
  add_gps = (gps) => {
    let unidadGpsTem = {
      idUnidadNavTer: this.UnidadesObj.id,
      idGps: gps.id,
      idUnidadTipoColocacion: 1, //disponible
    };
    this.getfuntion(
      "post",
      unidadGpsTem,
      "",
      "unidadGpsTerNav",
      "unidadGpsTerNav"
    ).then((returnVal) => {
      if (!returnVal) {
        NotificationManager.error(
          "Ha ocurrido un error al vincular el GPS",
          "Modificación"
        );
      } else {
        let gpsTemp = this.state.gpsUnidad;
        gpsTemp.push(gps);
        this.setState({ gpsUnidad: gpsTemp });
        /**
         * Aqui tenemos que cambiar el estatus del gps
         */
        gps.gpsEstatus = { id: 5, nombre: "Fijo", estatus: 1 };
        this.getfuntion("put", gps, "", "gps", "gps");
        NotificationManager.info(
          "Se han agregado el GPS(" +
            gps.nombre +
            ") la unidad ( " +
            this.UnidadesObj.nombre +
            " )",
          "Modificación"
        );
      }
    });
  };
  crud_post = () => {
    this.getfuntion("post", this.UnidadesObj, "", "unidadTerNav", "Unidades");
    this.setState({ accion: "nuevo" });

    this.setState({
      idUnidadMarca: { value: 0, label: "Seleccione una opción ", data: null },
    });
    this.setState({
      idUnidadTipo: { value: 0, label: "Seleccione una opción ", data: null },
    });
    this.setState({
      idUnidadEstatusOperacion: {
        value: 0,
        label: "Seleccione una opción ",
        data: null,
      },
    });
    this.setState({ nombre: "" });
    this.setState({ placa: "" });
    this.setState({ color: "" });
    this.setState({ modelo: "" });
    this.setState({ estatus: false });

    this.UnidadesObj = {
      idPlataforma: this.props.elementos,
      idTransportista: this.props.idTransportista,
      idUnidadTipo: {
        id: 0,
      },
      idUnidadMarca: {
        id: 0,
      },
      nombre: "",
      placa: "",
      color: "",
      modelo: "",
      estatus: true,
      idUnidadEstatusOperacion: {
        id: 0,
      },
    };
    this.valid = {
      idUnidadTipo: false,
      idUnidadMarca: false,
      placa: false,
      idUnidadEstatusOperacion: false,
    };
  };

  disableSave = () => {
    let band = false;
    Object.keys(this.valid).forEach((key) => {
      if (!this.valid[key]) {
        band = true;
      }
    });
    if (band) {
      return true;
    } else {
      return false;
    }
  };
  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  addGps = () => {
    //this.getfuntion("get", "", "", "gps/nombre/gl1545/activos/pagina/0/total/100", "gpss");
    this.getfuntion("get", "", "", "gps/activos/pagina/0/total/10", "gpss");
    this.setState({ addgps: true });
  };
  searchGps = (e) => {
    if (this.state.gpss.content) {
      this.setState({ searchGps: e.target.value });
      if (
        this.state.gpss.content
          .filter((word) =>
            word.nombre.toUpperCase().includes(e.target.value.toUpperCase())
          )
          .sort().length <= 5
      ) {
        this.getfuntion(
          "get",
          "",
          "",
          "gps/nombre/" + e.target.value + "/activos/pagina/0/total/100",
          "gpss"
        );
      }
    }
  };

  guardarUnidades = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  enableAddGps = (gps) => {
    for (let item of this.state.gpsUnidad) {
      if (item.id === gps.id) {
        return false;
      }
    }
    if (gps.gpsEstatus.id !== 1) {
      return false;
    }
    return true;
  };
  render() {
    return (
      <div>
        {/**
         *   Configuracion de correo
         */}
        <div>
          <Grid.Row>
            <Grid.Col>
              <Card
                title="Unidades"
                isCollapsible={false}
                //isClosable
                isCollapsed={false}
                //options={ }
                body={
                  <div>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        <Grid.Col sm={12} md={4} className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar por placa..."
                            position="append"
                            onChange={this.searching}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col sm={12} md={4}>
                        <Form.Group label="Unidad Tipo">
                          <Select
                            value={this.state.idUnidadTipo}
                            onChange={this.changeIdUnidadTipo}
                            options={this.unidadesTipo}
                          />
                        </Form.Group>
                        <Form.Group label="Marca">
                          <Select
                            value={this.state.idUnidadMarca}
                            onChange={this.changeIdUnidadMarca}
                            options={this.UnidadsMarcas}
                          />
                        </Form.Group>
                        <Form.Group label="Unidad Estatus Operacion">
                          <Select
                            value={this.state.idUnidadEstatusOperacion}
                            onChange={this.changeIdUnidadEstatusOperacion}
                            options={this.unidadesEstatusOperacion}
                          />
                        </Form.Group>
                        <Form.Group label="Nombre">
                          <Form.Input
                            type="text"
                            name="nombre"
                            value={this.state.nombre}
                            placeholder="Ingresa nombre ..."
                            onChange={this.changeNombre}
                          />
                        </Form.Group>
                        <Form.Group label="Placa">
                          <Form.Input
                            type="text"
                            name="placa"
                            feedback="Al menos 4 caracteres"
                            value={this.state.placa}
                            placeholder="Ingresa placa ..."
                            invalid={
                              this.state.placa != null
                                ? !this.isValid("placa") &&
                                  this.state.placa.length > 0
                                : false
                            }
                            valid={
                              this.state.placa != null
                                ? this.isValid("placa") &&
                                  this.state.placa.length > 0
                                : false
                            }
                            onChange={this.changePlaca}
                          />
                        </Form.Group>
                        <Form.Group label="Color">
                          <Form.Input
                            type="text"
                            name="color"
                            value={this.state.color}
                            placeholder="Ingresa color ..."
                            onChange={this.changeColor}
                          />
                        </Form.Group>
                        <Form.Group label="Modelo">
                          <Form.Input
                            type="text"
                            name="modelo"
                            value={this.state.modelo}
                            placeholder="Ingresa modelo ..."
                            onChange={this.changeModelo}
                          />
                        </Form.Group>
                        <Form.Group label="Estatus">
                          <Form.Switch
                            type="Checkbox"
                            name="toggle"
                            value="estaus"
                            checked={this.state.estatus}
                            onChange={this.changeEstatus}
                          />
                        </Form.Group>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="primary"
                              disabled={this.disableSave()}
                              onClick={this.guardarUnidades}
                            >
                              <span class="badge">
                                <Icon link={true} name="save" />
                                Guardar
                              </span>
                            </Button>
                          </span>
                        </div>
                        <div
                          hidden={this.state.accion !== "modificar"}
                          className="mt-8 border border-bottom-0 border-left-0 border-right-0 border-primary"
                        >
                          <span className="float-left mt-2">
                            {" "}
                            <h3>
                              {" "}
                              GPS de la unidad: {this.state.nombre}{" "}
                            </h3>{" "}
                          </span>
                          <span
                            className="float-right mt-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Agregar gps"}
                          >
                            <Button
                              pill
                              size="sm"
                              outline
                              color="primary"
                              onClick={() => this.addGps()}
                            >
                              <Icon name="plus" />
                            </Button>
                          </span>
                          <Form.Group>
                            <Table
                              cards={true}
                              striped={true}
                              responsive={true}
                              className="table-vcenter"
                            >
                              <Table.Header>
                                <Table.ColHeader>ID</Table.ColHeader>
                                <Table.ColHeader>nombre</Table.ColHeader>
                                <Table.ColHeader>remover</Table.ColHeader>
                              </Table.Header>
                              <Table.Body>
                                {this.state.gpsUnidad.map((elemento, index) => (
                                  <Table.Row>
                                    <Table.Col>{elemento["id"]}</Table.Col>
                                    <Table.Col>{elemento["nombre"]}</Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="danger"
                                        onClick={() =>
                                          this.delete_gps(elemento.id)
                                        }
                                      >
                                        <Icon name="trash" />
                                      </Button>{" "}
                                    </Table.Col>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>
                          </Form.Group>
                          <div hidden={!this.state.addgps}>
                            <Form.Group
                              label={
                                <div>
                                  Solo se pueden utilizar gps con estatus
                                  disponible
                                  <span className="float-right">
                                    <Form.Input
                                      type="text"
                                      name="gps"
                                      value={this.state.searchGps}
                                      placeholder="Ingresa nombre gps ..."
                                      onChange={this.searchGps}
                                    />
                                  </span>
                                </div>
                              }
                            >
                              <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                className="table-vcenter"
                              >
                                <Table.Header>
                                  <Table.ColHeader>ID</Table.ColHeader>
                                  <Table.ColHeader>nombre</Table.ColHeader>
                                  <Table.ColHeader>gps estatus</Table.ColHeader>
                                  <Table.ColHeader>Agregar</Table.ColHeader>
                                </Table.Header>
                                <Table.Body>
                                  {this.state.gpss.content
                                    ? this.state.gpss.content
                                        .filter((word) =>
                                          word.nombre
                                            .toUpperCase()
                                            .includes(
                                              this.state.searchGps.toUpperCase()
                                            )
                                        )
                                        .sort()
                                        .map((elemento, index) => (
                                          <Table.Row>
                                            <Table.Col>
                                              {elemento["id"]}
                                            </Table.Col>
                                            <Table.Col>
                                              {elemento["nombre"]}
                                            </Table.Col>
                                            <Table.Col>
                                              {elemento["gpsEstatus"].nombre}
                                            </Table.Col>
                                            <Table.Col>
                                              {this.enableAddGps(elemento) ? (
                                                <Button
                                                  pill
                                                  size="sm"
                                                  outline
                                                  color="primary"
                                                  onClick={() =>
                                                    this.add_gps(elemento)
                                                  }
                                                >
                                                  <Icon name="plus" />
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </Table.Col>
                                          </Table.Row>
                                        ))
                                    : null}
                                </Table.Body>
                              </Table>
                            </Form.Group>
                          </div>
                        </div>
                      </Grid.Col>

                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>nombre</Table.ColHeader>
                              <Table.ColHeader>placa</Table.ColHeader>
                              <Table.ColHeader>modelo</Table.ColHeader>
                              <Table.ColHeader>color</Table.ColHeader>
                              <Table.ColHeader>idUnidadMarca</Table.ColHeader>
                              <Table.ColHeader>idUnidadTipo</Table.ColHeader>
                              <Table.ColHeader>
                                idUnidadEstatusOperacion
                              </Table.ColHeader>
                              <Table.ColHeader>estatus</Table.ColHeader>
                              <Table.ColHeader>Editar</Table.ColHeader>
                              <Table.ColHeader>Borrar</Table.ColHeader>
                            </Table.Header>
                            <Table.Body>
                              {this.state.Unidades.length > 0
                                ? this.state.Unidades.filter((word) =>
                                    word.placa
                                      .toUpperCase()
                                      .includes(this.state.search.toUpperCase())
                                  )
                                    .sort()
                                    .map((elemento, index) => (
                                      <Table.Row>
                                        <Table.Col>{elemento["id"]}</Table.Col>
                                        <Table.Col>
                                          {elemento["nombre"]}
                                        </Table.Col>
                                        <Table.Col>
                                          {elemento["placa"]}
                                        </Table.Col>
                                        <Table.Col>
                                          {elemento["modelo"]}
                                        </Table.Col>
                                        <Table.Col>
                                          {elemento["color"]}
                                        </Table.Col>
                                        <Table.Col>
                                          {elemento["idUnidadMarca"].nombre}
                                        </Table.Col>
                                        <Table.Col>
                                          {elemento["idUnidadTipo"].nombre}
                                        </Table.Col>
                                        <Table.Col>
                                          {
                                            elemento["idUnidadEstatusOperacion"]
                                              .nombre
                                          }
                                        </Table.Col>
                                        <Table.Col>
                                          <Form.Switch
                                            type="Checkbox"
                                            name="toggle"
                                            value={index}
                                            checked={elemento["estatus"]}
                                          />
                                        </Table.Col>
                                        <Table.Col>
                                          <Button
                                            pill
                                            size="sm"
                                            outline
                                            color="primary"
                                            onClick={() =>
                                              this.UnidadEdit(elemento)
                                            }
                                          >
                                            <Icon name="edit" />
                                          </Button>{" "}
                                        </Table.Col>
                                        <Table.Col>
                                          <Button
                                            pill
                                            size="sm"
                                            outline
                                            color="primary"
                                            onClick={() =>
                                              this.crud_delete(elemento.id)
                                            }
                                          >
                                            <Icon name="trash" />
                                          </Button>{" "}
                                        </Table.Col>
                                      </Table.Row>
                                    ))
                                : null}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tus datos antes de guardar ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
              />
            </Grid.Col>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

export default Unidades;
