import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
//import $ from "jquery";
var user = JSON.parse(sessionStorage.getItem("usuario"));
var movimientos = {};
var cat = {};
let categoriasNombres;
let categoriasObj;
let apiUrl = "";
export async function AlertasN(datos, mtipo) {
  movimientos = {};
  let plataformas = [];
  if (datos) {
    datos.map((mov, index) => {
      //Plataformas
      if (!plataformas.find((element) => element === mov.idPlataforma.id)) {
        plataformas.push(mov.idPlataforma.id);
      }
      if (movimientos[mov.id]) {
        movimientos[mov.id].push(mov);
      } else {
        movimientos[mov.id] = [];
        movimientos[mov.id] = mov;
      }
    });
  }
  return await getfuntion(
    "get",
    "",
    "",
    "plataformasAlertas/usuario/" + user.id,
    "plataformasAlertas"
  )
    .then((categorias) => {
      if (categorias) {
        cat = {};
        categoriasNombres = {};
        categoriasObj = {};
        let categoriaActual = null;
        categorias.map((c, index) => {
          if (categoriaActual !== c.plataforma) {
            categoriaActual = c.plataforma;
            if (!categoriasNombres[categoriaActual]) {
              categoriasNombres[categoriaActual] = [];
            }
          }
          if (
            !categoriasNombres[categoriaActual].find(
              (element) => element === c.alertaCategoria.nombre
            )
          ) {
            categoriasNombres[categoriaActual].push(c.alertaCategoria.nombre);
            categoriasObj[c.alertaCategoria.nombre] = c;
          }
          if (c.alertaCategoria) {
            if (cat[c.plataforma]) {
              if (cat[c.plataforma][c.alertaTipo.id]) {
                cat[c.plataforma][c.alertaTipo.id] = c;
              } else {
                cat[c.plataforma][c.alertaTipo.id] = c;
              }
            } else {
              cat[c.plataforma] = {};
              cat[c.plataforma][c.alertaTipo.id] = c;
            }
          }
        });
      }
      if (mtipo) {
        apiUrl =
          "navierasAlertas/usuarioMovimientos/" +
          user.id +
          "/mov_tipo/" +
          mtipo;
      } else {
        apiUrl = "navierasAlertas/usuarioMovimientos_todas/" + user.id;
      }
      return getfuntion("get", "", "", apiUrl, "plataformasAlertas").then(
        (res) => {
          if (res) {
            var alertas = {};
            var alertasCat = {};
            res.map((alerta, index) => {
              if (alertas[alerta.idMovimiento]) {
                if (movimientos[alerta.idMovimiento]) {
                  if (movimientos[alerta.idMovimiento].idPlataforma) {
                    if (cat[movimientos[alerta.idMovimiento].idPlataforma.id]) {
                      if (alerta.idAlertaTipo) {
                        if (
                          cat[movimientos[alerta.idMovimiento].idPlataforma.id][
                            alerta.idAlertaTipo
                          ]
                        ) {
                          if (
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria.logo
                          ) {
                            if (
                              alertas[alerta.idMovimiento][
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.logo
                              ]
                            ) {
                              alertas[alerta.idMovimiento][
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.logo
                              ].push({
                                alert: alerta,
                                color:
                                  cat[
                                    movimientos[alerta.idMovimiento]
                                      .idPlataforma.id
                                  ][alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.nombre,
                                tiempo:
                                  cat[
                                    movimientos[alerta.idMovimiento]
                                      .idPlataforma.id
                                  ][alerta.idAlertaTipo].alertaCategoria
                                    .tiempoAtencion,
                              });
                            } else {
                              alertas[alerta.idMovimiento][
                                cat[
                                  movimientos[
                                    alerta.idMovimiento
                                  ].idPlataforma.id
                                ][alerta.idAlertaTipo].alertaCategoria.logo
                              ] = [];
                              alertas[alerta.idMovimiento][
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.logo
                              ].push({
                                alert: alerta,
                                color:
                                  cat[
                                    movimientos[alerta.idMovimiento]
                                      .idPlataforma.id
                                  ][alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.nombre,
                                tiempo:
                                  cat[
                                    movimientos[alerta.idMovimiento]
                                      .idPlataforma.id
                                  ][alerta.idAlertaTipo].alertaCategoria
                                    .tiempoAtencion,
                              });
                            }
                            if (
                              !alertasCat[
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.nombre
                              ]
                            ) {
                              alertasCat[
                                cat[
                                  movimientos[
                                    alerta.idMovimiento
                                  ].idPlataforma.id
                                ][alerta.idAlertaTipo].alertaCategoria.nombre
                              ] = [];
                            }
                            alertasCat[
                              cat[
                                movimientos[alerta.idMovimiento].idPlataforma.id
                              ][alerta.idAlertaTipo].alertaCategoria.nombre
                            ].push({
                              alert: alerta,
                              color:
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria.color,
                              logo: cat[
                                movimientos[alerta.idMovimiento].idPlataforma.id
                              ][alerta.idAlertaTipo].alertaCategoria.logo,
                              tiempo:
                                cat[
                                  movimientos[alerta.idMovimiento].idPlataforma
                                    .id
                                ][alerta.idAlertaTipo].alertaCategoria
                                  .tiempoAtencion,
                            });
                          }
                        } else {
                          if (!alertas[alerta.idMovimiento]["fa-bell"]) {
                            alertas[alerta.idMovimiento] = {};
                            alertas[alerta.idMovimiento]["fa-bell"] = [];
                          }
                          alertas[alerta.idMovimiento]["fa-bell"].push({
                            alert: alerta,
                            color: "blue",
                            tipo: "Desconocido",
                            tiempo: "00:00:00",
                          });
                          if (!alertasCat["Desconocido"]) {
                            alertasCat["Desconocido"] = [];
                          }
                          alertasCat["Desconocido"].push({
                            alert: alerta,
                            color: "blue",
                            logo: "fa-bell",
                            tiempo: "00:00:00",
                          });
                        }
                      }
                    } else {
                      if (!alertas[alerta.idMovimiento]["fa-bell"]) {
                        alertas[alerta.idMovimiento] = {};
                        alertas[alerta.idMovimiento]["fa-bell"] = [];
                      }
                      alertas[alerta.idMovimiento]["fa-bell"].push({
                        alert: alerta,
                        color: "blue",
                        tipo: "Desconocido",
                        tiempo: "00:00:00",
                      });
                      if (!alertasCat["Desconocido"]) {
                        alertasCat["Desconocido"] = [];
                      }
                      alertasCat["Desconocido"].push({
                        alert: alerta,
                        color: "blue",
                        logo: "fa-bell",
                        tiempo: "00:00:00",
                      });
                    }
                  }
                }
              } else {
                if (movimientos[alerta.idMovimiento]) {
                  if (movimientos[alerta.idMovimiento].idPlataforma) {
                    if (cat[movimientos[alerta.idMovimiento].idPlataforma.id]) {
                      if (
                        cat[movimientos[alerta.idMovimiento].idPlataforma.id][
                          alerta.idAlertaTipo
                        ]
                      ) {
                        alertas[alerta.idMovimiento] = {};
                        alertas[alerta.idMovimiento][
                          cat[movimientos[alerta.idMovimiento].idPlataforma.id][
                            alerta.idAlertaTipo
                          ].alertaCategoria.logo
                        ] = [];
                        //alertas[alerta.idMovimiento]["icono"]= cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo ];
                        alertas[alerta.idMovimiento][
                          cat[movimientos[alerta.idMovimiento].idPlataforma.id][
                            alerta.idAlertaTipo
                          ].alertaCategoria.logo
                        ].push({
                          alert: alerta,
                          color:
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria.color,
                          tipo: cat[
                            movimientos[alerta.idMovimiento].idPlataforma.id
                          ][alerta.idAlertaTipo].alertaCategoria.nombre,
                          tiempo:
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria
                              .tiempoAtencion,
                        });
                        if (
                          !alertasCat[
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria.nombre
                          ]
                        ) {
                          alertasCat[
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria.nombre
                          ] = [];
                        }
                        alertasCat[
                          cat[movimientos[alerta.idMovimiento].idPlataforma.id][
                            alerta.idAlertaTipo
                          ].alertaCategoria.nombre
                        ].push({
                          alert: alerta,
                          color:
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria.color,
                          logo: cat[
                            movimientos[alerta.idMovimiento].idPlataforma.id
                          ][alerta.idAlertaTipo].alertaCategoria.logo,
                          tiempo:
                            cat[
                              movimientos[alerta.idMovimiento].idPlataforma.id
                            ][alerta.idAlertaTipo].alertaCategoria
                              .tiempoAtencion,
                        });
                      } else {
                        // nothing
                        alertas[alerta.idMovimiento] = {};
                        alertas[alerta.idMovimiento]["fa-bell"] = [];
                        alertas[alerta.idMovimiento]["fa-bell"].push({
                          alert: alerta,
                          color: "blue",
                          tipo: "Desconocido",
                          tiempo: "00:00:00",
                        });
                        if (!alertasCat["Desconocido"]) {
                          alertasCat["Desconocido"] = [];
                        }
                        alertasCat["Desconocido"].push({
                          alert: alerta,
                          color: "blue",
                          logo: "fa-bell",
                          tiempo: "00:00:00",
                        });
                      }
                    } else {
                      alertas[alerta.idMovimiento] = {};
                      alertas[alerta.idMovimiento]["fa-bell"] = [];
                      alertas[alerta.idMovimiento]["fa-bell"].push({
                        alert: alerta,
                        color: "blue",
                        tipo: "Desconocido",
                        tiempo: "00:00:00",
                      });
                      if (!alertasCat["Desconocido"]) {
                        alertasCat["Desconocido"] = [];
                      }
                      alertasCat["Desconocido"].push({
                        alert: alerta,
                        color: "blue",
                        logo: "fa-bell",
                        tiempo: "00:00:00",
                      });
                    }
                  }
                }
              }
            });
            let respuesta = {
              alertas: alertas,
              alertasCat: alertasCat,
              categoriasNombres: categoriasNombres,
              plataformas: plataformas,
              categoriasObj: categoriasObj,
            };
            return respuesta;
          }
        }
      );
    })
    .catch((errors) => console.log(errors));
  async function getfuntion(
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "entregas",
    stateVar = "gpsestatus"
  ) {
    return Crud_Catalogos(catalogo, "gpsestatus", metodo, id, obj, "", stateVar)
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            return returnVal;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
export default AlertasN;
