import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { Form, Button, Header, Grid, Alert, Loader } from "tabler-react";
import axios from 'axios';
import { api_raiz } from "../../config/config";

const CargaIndividual = ({ getData }) => {


  const model = useRef()

  const entryImei = useRef()
  const entryDate = useRef()

  const exitImei = useRef()
  const exitDate = useRef()

  const [isEntryLoading, setIsEntryLoading] = useState(false);

  const [isExitLoading, setIsExitLoading] = useState(false);

  const [entryError, setEntryError] = useState();

  const [exitError, setExitError] = useState();

  useEffect(() => {
    const now = new Date();
    const parsedDate = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${(now.getDate()).toString().padStart(2, '0')} 00:00:00`
    entryDate.current = parsedDate
    exitDate.current = parsedDate

  }, [])

  const instance = useMemo(() => {
    const instance = axios.create({
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem("tok")
      }
    });
    return instance;
  }, [])



  const submitEntry = useCallback(async (event) => {
    event.preventDefault()
    setEntryError("")
    if (entryImei.current && model.current && entryDate.current) {
      try {
        setIsEntryLoading(true)
        const response = await instance.post(api_raiz + "gps-almacen", {
          imei: entryImei.current,
          modelo: model.current,
          fechaEntrada: entryDate.current
        });
        if (!response.data.idAlmacen) {
          throw new Error()
        }
        getData()
      }
      catch (error) {
        setEntryError(error.toString())
      } finally {
        setIsEntryLoading(false)
      }
    }

  }, [])


  const submitExit = useCallback(async (event) => {
    event.preventDefault()
    setExitError("")
    if (exitImei.current && exitDate.current) {
      try {
        setIsExitLoading(true)
        const response = await instance.put(api_raiz + "gps-almacen", {
          imei: exitImei.current,
          fechaSalida: exitDate.current
        });
        if (response.data.idAlmacen == null) {
          throw new Error()
        }
        getData()
      }
      catch (error) {
        setExitError(error?.response?.data)
      } finally {
        setIsExitLoading(false)
      }

    }
  }, [])

  const setEntryDate = useCallback((value) => {
    const parsedDate = parseDate(value)
    entryDate.current = parsedDate;
  }, [])

  const setExitDate = useCallback((value) => {
    const parsedDate = parseDate(value)
    exitDate.current = parsedDate;
  }, [])

  const parseDate = useCallback((value) => {
    const month = (value.getMonth() + 1).toString().padStart(2, '0');
    const day = value.getDate().toString().padStart(2, '0');
    return value.getFullYear() + "-" + month + "-" + day + " " + "00:00:00"
  }, [])

  return (
    <Grid.Row>
      <Grid.Col>
        <Form onSubmit={submitEntry} >
          <Form.FieldSet >
            <Header.H4>Entrada individual</Header.H4>
            <Form.Group label="IMEI" isRequired>
              <Form.Input name="entryImei" onChange={event => entryImei.current = event.target.value} />
            </Form.Group>
            <Form.Group label="Modelo" isRequired>
              <Form.Input name="modelo" onChange={event => model.current = event.target.value} />
            </Form.Group>
            <Form.Group label="Fecha de entrada" isRequired>
              <Form.DatePicker name="entryDate" onChange={setEntryDate} />
            </Form.Group>
            <Button className="mt-3" icon="layout" type="submit" value="Submit" >Subir entrada</Button>
          </Form.FieldSet>
        </Form>
        {entryError && <Alert type="danger" hasExtraSpace>
          <strong>Error subiendo nuevo elemento</strong> {entryError} Verifica que este GPS sea nuevo en el registro
        </Alert>}
        {isEntryLoading && <Loader />}
      </Grid.Col>
      <Grid.Col>
        <Form onSubmit={submitExit}>
          <Form.FieldSet>
            <Header.H4>Salida individual</Header.H4>
            <Form.Group label="IMEI" isRequired>
              <Form.Input name="exitImei" onChange={event => exitImei.current = event.target.value} />
            </Form.Group>
            <Form.Group label="Fecha de salida" isRequired>
              <Form.DatePicker name="exitDate" onChange={setExitDate} />
            </Form.Group>
            <Button className="mt-3" icon="layout" type="submit" value="Submit" >Subir salida</Button>
          </Form.FieldSet>
        </Form>
        {exitError && <Alert type="danger" hasExtraSpace>
          <strong>Error subiendo registrando salida</strong> {exitError}
        </Alert>}
        {isExitLoading && <Loader />}
      </Grid.Col>
    </Grid.Row>
  )
}

export default CargaIndividual