//React
import React from "react";
// @flow
import { Error400Page } from "tabler-react";

function Error400(props) {
  return <Error400Page />;
}

export default Error400;
