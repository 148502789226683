// @flow
import React, { useEffect, useState } from "react";
import { Button, Icon} from "tabler-react";
import { NotificationManager } from "react-notifications";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
import { TablePageable } from "../../../../Helpers";
const Cuentas = ({
  stor,
  plataforma,
}) => {
  const [formulario, setFormulario] = useState({
    nombre: "",
    nombreCorto: "",
    estatus: false,
    idPlataforma: plataforma.id,
  });
  const [cuentasList, setCuentasList] = useState([]);
  const [crudbutonEnable, setcrudbutonEnable] = useState(true);
  const [nuevo, setNuevo]= useState(false);
  const isEnable = (Accion) => {
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    const privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    getfuntion( "get", "", "", "cuentas/plataforma/" + plataforma.id, "cuentas" );
  },[]);

  const disableSave = () => {
    if( formulario.nombre.length && formulario.nombreCorto.length )
      return false;
    return true;
  };
  const crud_delete = () => {
    if(formulario.id){
      setcrudbutonEnable(false);
      getfuntion("delete", formulario, formulario.id, "cuentas", "cuentas" );
    }
  };
  const crud_put = () => {
    if(formulario.id){
      setcrudbutonEnable(false);
      getfuntion("put", formulario, "", "cuentas", "cuentas" );
    }
  };
  const crud_post = () => {
    if(!formulario.id){
      setcrudbutonEnable(false);
      getfuntion("post", formulario, "", "cuentas", "cuentas" );
    }
  };
  const clear =()=>{
    setFormulario({
      nombre: "",
      nombreCorto: "",
      estatus: false,
      idPlataforma: plataforma.id,
    });
    setNuevo(false);
  }
  const getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "", stateVar = "",hiddenModl = "" ) => {
    Crud_Catalogos( catalogo, "plataforma", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "cuentas" && returnVal) {
                setCuentasList(returnVal);
              }
            }
            break;
          case "post":
            {
              if (stateVar === "cuentas" && returnVal) {
                clear();
                let cuentasTemp= [...cuentasList];
                cuentasTemp.unshift(returnVal);
                setCuentasList(cuentasTemp); 
                setcrudbutonEnable(true);
                NotificationManager.info( "Se a generado registro nuevo: "+returnVal.nombre, "Registro guardado");
              }
            }
            break;
          case "put":
            {
              if (stateVar === "cuentas" && returnVal) {
                clear();
                setcrudbutonEnable(true);
                getfuntion( "get", "", "", "cuentas/plataforma/" + plataforma.id, "cuentas" );
                NotificationManager.info( "Se a actualizado registro: "+returnVal.nombre, "Registro guardado");
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "cuentas") {
                clear();
                if( returnVal ){
                  NotificationManager.info( "Se ha borrado registro: "+obj.nombre, "Borrar registro");
                  getfuntion( "get", "", "", "cuentas/plataforma/" + plataforma.id, "cuentas" );
                }else{
                  NotificationManager.error( "No ha sido posible borrar registro: "+obj.nombre, "Borrar registro");
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => { console.log(err); });
  };
  return (
    <div>
      <div hidden={ nuevo || formulario.id }>
        <button type="button" className="btn btn-outline-primary" onClick={()=>setNuevo(true)}>Nuevo</button>
      </div>
      <div className="row" hidden={ !nuevo && !formulario.id }>
        <div className="col">
          <div className="form-group">
            <label for="nombre">Nombre</label>
            <input
              type="text"
              className="form-control"
              id="nombre"
              placeholder="Ingresa nombre"
              aria-describedby="nombrelHelp"
              value={formulario.nombre}
              onChange={(e) => setFormulario({ ...formulario, nombre: e.target.value })}
            />
            <small id="nombrelHelp" className="form-text text-muted">Ingresa el nombre de la cuenta.</small>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label for="nombre_corto">Nombre corto</label>
            <input
              type="text"
              className="form-control"
              id="nombre_corto"
              placeholder="Ingresa nombre Corto"
              aria-describedby="nombreCortolHelp"
              value={formulario.nombreCorto}
              onChange={(e) => setFormulario({ ...formulario, nombreCorto: e.target.value })}
            />
            <small id="nombreCortolHelp" className="form-text text-muted">Ingresa el nombre corto para cuenta {formulario.nombre}.</small>
          </div>
        </div>
        <div className="col">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="estatus"
              checked={ formulario.estatus }
              onChange={(e)=> setFormulario({ ...formulario, estatus: e.target.checked }) }
              />
            <label class="form-check-label" for="exampleCheck1">Estatus</label>
          </div>
        </div>
        <div className="float-right">
          {isEnable("actualizar") && isEnable("agregar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={disableSave() || !crudbutonEnable}
                onClick={()=>{ formulario.id? crud_put(): crud_post();}}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
          { isEnable("borrar") ? (
            <span className="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                onClick={() => crud_delete()}
                disabled={!crudbutonEnable}
              >
                <span className="badge">
                  <Icon link={true} name="trash" color="red" />
                  Borrar
                </span>
              </Button>
            </span>
          ) : null}
          <span className="badge">
            <button type="button" className="btn btn-outline-primary btn-sm text-danger" onClick={()=>clear()}>
              <i class="fa fa-close" style={{"font-size": "24px"}}></i>
            </button>
          </span>
        </div>
      </div>
      <hr/>
      <hr/>
      <div className="row" hidden={!cuentasList || !cuentasList.length || nuevo || formulario.id }>
        <div className="col-12">
          <TablePageable
            titulo={ <h5>Cuentas de la plataforma {plataforma.nombre}</h5> }
            lista= { cuentasList }
            columnas={
              [
                { columna: "id", label: "Id" },
                { columna: "nombre", label: "Nombre" },
                { columna: "nombreCorto", label: "Nombre corto" },
                { columna: "estatus", label: "Estatus" },
              ]
            }
            id= "id"
            selected={(e)=>{ setFormulario(e) }}
            csvname= {"Cuentas ne la plataforma "+ plataforma.nombre}
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
        </div>
      </div>
    </div>
  );
};
export default Cuentas;
