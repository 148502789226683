import React, { Component } from "react";
import { Form, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos } from '../../../herramientas/Crud_Catalogos';
import Select from 'react-select';
/**
 * Notificaciones
 */
import { NotificationManager} from 'react-notifications';
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
class PersonasEtiquetas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      Etiquetas: [],
      search: "",
      plataforma: { value: 0, label:"Todas las plataformas", data:null }
    };
    this.Etiquetas = [];
    this.EtiquetaTemp = "";
    this.checarEtiqueta = 0;      //temporal render
    this.personasEtiquetasObject = {
      "idPersona": this.props.elementos.id,
      "idEtiqueta": 0,
      "idPlataforma": 0
    };
    this.EtiquetasArray = [];
    this.plataformas= [];
  }
  componentWillMount = () =>{
    this.plataformas.push({ value: 0, label:"Todas las plataformas", data:null });
    currentUser.map((usr, index) => this.plataformas.push({ value: usr.plataforma.id, label: usr.plataforma.nombre, search: usr.plataforma.nombre }));
  }
  
  componentDidMount = () => {
    if (this.state.accion !== 'nuevo') {
      if(this.props.plataforma){
        this.getfuntion("get", "", "", "personasEtiquetas/plataforma/"+this.props.plataforma.id+"/persona/"+this.props.elementos.id, "Etiquetas");
        this.personasEtiquetasObject.idPlataforma= this.props.plataforma.id;
      }else{
        this.getfuntion("get", "", "", "personasEtiquetas/persona/"+this.props.elementos.id, "Etiquetas");
      }
    } else { }
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Etiquetas") { this.Etiquetas = returnVal; this.setState({ Etiquetas: returnVal }); }
          }
          break;
        case "post":
          {
            if (stateVar === "Etiquetas") {
              this.Etiquetas.push(returnVal);
              this.setState({ Etiquetas: this.Etiquetas });
              NotificationManager.success("Etiqueta agregada", "Guardado");
            }
          }
          break;
        case "delete":
          {
            if (stateVar === "Etiquetas") {
              this.Etiquetas.map((elemento, index) => {
                if (this.personasEtiquetasObject.idEtiqueta === elemento.idEtiqueta) {
                  this.Etiquetas.splice(index, 1);
                }
              }
              )
              this.setState({ Etiquetas: this.Etiquetas });
              NotificationManager.success("Etiqueta removida", "Guardado");
            }
          }
          break;
        default:
          break;
      }
    }).catch(err => {
      if (stateVar === "Etiquetas" && metodo == "delete") {
        NotificationManager.error("No se ha podido remover la etiqueta", "Error");
      }
      if (err.response) {
        console.log(err.response);
      } else {
        /**
         * Error
         */
        console.log("Error desconocido ....");
        console.log(err)
      }
    })
  }

  changeEtiqueta = (etiq, val) => {
    if( this.personasEtiquetasObject.idPlataforma > 0 ){
      this.personasEtiquetasObject.idEtiqueta= this.props.stor.etiquetas_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort()[val].id ;      
      this.EtiquetasArray[val].estatus === 1 ?
        this.getfuntion("delete", "", this.props.elementos.id+"/"+etiq+"/"+this.personasEtiquetasObject.idPlataforma, "personasEtiquetas", "Etiquetas")
      : 
        this.getfuntion("post", this.personasEtiquetasObject, "", "personasEtiquetas", "Etiquetas");
    }else{
      NotificationManager.warning("No se ha seleccionado una plataforma", "Cuidado");
    }
  }
  checkedNaviera = () => {
    return this.checarEtiqueta;
  }
  checarEtiquetaArray = (estatus, id) => {
    this.EtiquetasArray.push({ estatus: estatus, id: id })
  }
  checarEtiquetaTem = (valor) => {
    this.checarEtiqueta = valor;
  }
  searching = (event) => {
    this.setState({ search: event.target.value })
  }
  cleanArrayList = () => {
    this.EtiquetasArray = [];
  }
  changePlataforma = (e) =>{
    this.setState({plataforma: e });
    this.personasEtiquetasObject.idPlataforma= e.value;
    if(e.value === 0 ){
      this.getfuntion("get", "", "", "personasEtiquetas/persona/"+this.props.elementos.id, "Etiquetas");
    }else{
      this.getfuntion("get", "", "", "personasEtiquetas/plataforma/"+e.value+"/persona/"+this.props.elementos.id, "Etiquetas");   
    }    
  }
  render() {
    return (
      <div>
        {this.cleanArrayList()}
          <div>
            <Grid.Row>
              <Grid.Col>
                <Card
                  title="Etiquetas"
                  isCollapsible={false}
                  //isClosable
                  isCollapsed={false}
                  options={
                    <nav className="navbar navbar-expand-lg navbar-light">
                      <ul className="navbar-nav mr-auto">
                        <li className="nav-item active" hidden={ this.props.plataforma }>
                          <a>
                            <div className="float-right" style={{ "width": "160px" }}>
                              <Select value={this.state.plataforma} onChange={this.changePlataforma} options={ this.plataformas } />
                            </div>
                          </a>
                        </li>
                        <li className="nav-item active">
                          <a>
                            <div className="float-right" style={{ "width": "160px" }}>
                              <Form.Input className="mb-3" icon="search" placeholder="Buscar Etiqueta..." position="append" onChange={this.searching} />
                            </div>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  }
                  body={
                    <div>
                      <Form.Group>
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header>
                            <Table.ColHeader>ID</Table.ColHeader>
                            <Table.ColHeader>Nombre</Table.ColHeader>
                            <Table.ColHeader>Descripción</Table.ColHeader>
                            <Table.ColHeader>Asignada</Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            {
                              this.props.stor.etiquetas_object.filter(word => word.nombre.toUpperCase().includes(this.state.search.toUpperCase())).sort().map((elemento, index) =>
                                <Table.Row>
                                  <Table.Col>{elemento["id"]}</Table.Col>
                                  <Table.Col>{elemento["nombre"]}</Table.Col>
                                  <Table.Col>{elemento["descripcion"]}</Table.Col>
                                  { this.EtiquetaTemp = "" }
                                  {this.checarEtiquetaTem(0)}
                                  {
                                    this.state.Etiquetas.map((etiq, ind) => {
                                      if (etiq.idEtiqueta === elemento.id) {
                                        { this.checarEtiquetaTem(1) }
                                        this.EtiquetaTemp = etiq.idEtiqueta;
                                      }
                                    })
                                  }
                                  { this.checarEtiquetaArray(this.checarEtiqueta, this.EtiquetaTemp) }
                                  <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                    checked={this.checkedNaviera()}
                                    className={"disabled"}
                                    onChange={() => this.changeEtiqueta(elemento["id"], index ) } /></Table.Col>
                                </Table.Row>
                              )
                            }
                            {console.log()}
                          </Table.Body>
                        </Table>
                      </Form.Group>
                    </div>
                  }
                />
              </Grid.Col>

            </Grid.Row>
          </div>
        
      </div>
    )
  }
}

export default PersonasEtiquetas
