import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../SiteWrapper.react";
import '../navBarItems/MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import AlertasUnidades from "../../components/AlertasUnidades";
import PanelUsRuta from "./PanelUsRuta";
/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";
import { from } from "rxjs";
import AlertasUnidadesSinRuta from "../../components/AlertasUnidadesSinRuta";
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));

class MonitoreoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAlerta: [],
      alertas: [],
      manual: false,
      transportistas: []
    };
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
  }

  componentWillMount=() =>{}
  componentDidMount =() => {}
  componentDidUpdate=()=>{
    if( !this.state.transportistas.length && this.props.transportistas_object.length){
      let transportistasTemp= [];
      for(let t of this.props.transportistas_object){
        transportistasTemp.push({value: t.id, label: t.nombre, data: t});
      }
      this.setState({transportistas: transportistasTemp });
    }
  }
  getCurrentAlerta=()       =>{ return this.state.currentAlerta };
  setCurrentAlerta=(alerta) =>{ this.setState({ currentAlerta: alerta }) };
  setManual=(m) =>{ this.setState({ manual: !this.state.manual }) };
  setAlertas=(alertas)      =>{ if(this.state.alertas !== alertas){this.setState({ alertas: alertas })} }
  getAlertas=()             =>{ return this.state.alertas;}
  getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /*** filtro de variables  */
        switch (metodo) {
          case "get":            
              if (stateVar === "columnas"){

              }            
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
    })
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}else{ return false }
        break;
      }
    }
  }
  render() {
    return (
      <>
      {<LoadStore catalogos={["privilegios", "incidencias", "transportistas", "alertasTipos"]}/>}
      <SiteWrapper>
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "100vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
              <div>
                <AlertasUnidades
                 /* Ctlogo="terrestresAlertasSinRuta/noAtendidas"
                  tamanio="45%"
                  FCtlogo= { <div>AlertasUnidades</div> }
                  catalogos= {{ 0: "alertasunidades/noAtendidas"} }
                  setCurrentAlerta={this.setCurrentAlerta}
                  getCurrentAlerta= { this. getCurrentAlerta }
                  setAlertas={ this.setAlertas }
                  alertas={this.state.alertas}
                  manual={this.state.manual}*/
                />
                {/*<AlertasUnidadesSinRuta/>*/}
              </div>             
              {/*<div>
                <PanelUsRuta 
                  currentAlerta={ this.state.currentAlerta }
                  setCurrentAlerta={this.setCurrentAlerta}
                  manual={this.state.manual}
                  setManual={this.setManual}
                  incidencias={ this.props.incidencias_object }
                  transportistas={ this.state.transportistas }
                  getAlertas={ this.getAlertas}
                  setAlertas={ this.setAlertas }
                  alertasTipos= {this.props.alertasTipos_object}
                />
              </div>*/}
            </SplitterLayout>
          </div>

        </Grid.Col>
      </SiteWrapper>
  
      </>
    );
  }
}


export default MonitoreoPage;
