import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';

class Show extends React.Component{
    constructor(props) {
        super(props);
        this.state = { udateM: false
        }
      }
    
    componentDidMount =() => {
        this.setState({ udateM: this.props.update_mensaje_object+" montando ..!" });
    }
    componentDidUpdate =() => {
        if( !this.props.udate){ }else{
            this.props.shouldSendMessage(this.props.update_mensaje_object)
        }//alert(this.props.update_mensaje_object)
    }
    render(){
        return (
            <div></div>
        );
    }
}
function mapStateToProps(state){
    return{
                update_mensaje_object: state.catalogos_reducer.update_mensaje  
    }
  }
  
  function mapDispachToProps(dispach){
   return{
          updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status))
   }
  }
  
  export default connect(mapStateToProps, mapDispachToProps)(Show)