import React, { Component } from "react";
import { Card, Form, Table } from "tabler-react";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";

class UsuarioCorredores extends Component {
    constructor() {
        super();
        this.state = {
            corredores:[],
            pCorredores:[],
        };
    }
    componentWillMount(){
      this.getfuntion("get", "", "", "cli_usuarios_corredores/usuario/"+this.props.usuario.id+"/plataforma/"+this.props.idPlataforma, "usuarios_corredores");
      this.getfuntion("get", "", "", "corredores/plataforma/"+this.props.idPlataforma, "corredores");
    }
    getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
        return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
            /*** filtro de variables  */
            switch (metodo) {
              case "get":
                if (stateVar === "corredores")
                  this.setState({ corredores: returnVal });
                if (stateVar === "usuarios_corredores")
                  this.setState({ pCorredores: returnVal });
                break;
              case "post":
                if (stateVar === "usuarios_corredores"){
                  let pCorredoresTemp= this.state.pCorredores;  
                  pCorredoresTemp.push(returnVal);
                  this.setState({ pCorredores: pCorredoresTemp });
                }
                break;
              case "delete":
                if (stateVar === "usuarios_corredores" && returnVal){ 
                  let varTemp= this.state.pCorredores;
                  varTemp.map((elem, index )=>{
                    if(obj.idCorredor === elem.idCorredor ){ varTemp.splice( index, 1);  this.setState({ pCorredores: varTemp }); }
                  });
                }
                break;
              default:
                break;
            }
          }).catch(err =>{
              console.log(err);
        })
      }
    changeCorredor=(event)=>{
      let objTemp= {idUsuario: this.props.usuario.id, idCorredor: parseInt(event.target.value, 10), idPlataforma: this.props.idPlataforma };
      if( event.target.checked ){
        this.getfuntion("post", objTemp, "", "cli_usuarios_corredores","usuarios_corredores");  
      }else{
        this.getfuntion("delete", objTemp, this.props.usuario.id+"/"+parseInt(event.target.value, 10)+"/"+this.props.idPlataforma, "cli_usuarios_corredores","usuarios_corredores");
      }      
    }
    getCorredor    =(id)=>{ for( let  item of this.state.pCorredores ){  if( item.idCorredor === id ){   return true; } } return false; }
    render() {
        return (
            <div>
              <Card>
                <Card.Body>
                  <Table cards={true} striped={true} responsive={true} className="table-vcenter" >
                    <Table.Header align="center">
                        <Table.ColHeader>Id</Table.ColHeader>
                        <Table.ColHeader>Corredor  </Table.ColHeader>
                        <Table.ColHeader>estatus </Table.ColHeader>
                    </Table.Header>
                    <Table.Body>
                      {this.state.corredores.map((t, index) =>
                        <Table.Row align="center">
                            <Table.Col>{ t.id }</Table.Col>
                            <Table.Col>{ t.nombre }</Table.Col>
                            <Table.Col><Form.Switch type="Checkbox" name="toggle" value={t.id} checked= { this.getCorredor(t.id) } onChange={ this.changeCorredor} /></Table.Col>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>                                                            
                </Card.Body>
              </Card>
                <br/>
            </div>
        );
    }
}

export default UsuarioCorredores;