import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import Select from "react-select";

//import "./App.css";
 
const allowedExtensions = ["csv"];
const plataformas = [
    { value: '557', label: 'Egoba' },
    { value: '193', label: 'AFN' },
    { value: '1513', label: 'Bisonte' }
  ]

const TerminadoMasivo = () => {
    // This state will store the parsed data
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [file, setFile] = useState("");
    const [plataforma, setPlataforma] = useState(557);
    const [ids,setIds] = useState("");

    useEffect(() => {
        //setPlataforma(plataforma);
        //alert(plataforma);
        if(ids)
        getfuntion("get", [], "", "terrestreMovimientos/TerminaMovimientosMasivamenteEgoba/"+plataforma+"/"+ids, "")
    
    },[ids,plataforma]);

    const handleFileChange = (e) => {
        setError("");
 
        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];

            const fileExtension =inputFile?.type.split("/")[1];
            if (
                !allowedExtensions.includes(fileExtension)
            ) {
                setError("Ese archivo no es válido. Introduzca un .csv !!!");
                return;
            }
            
            // If input type is correct set the state
            setFile(inputFile);
        }
    };
    const handleParse = () => {
        if (!file) return alert("El archivo no es csv");        
        let reader = new FileReader();
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, {
                header: true,
            });
            const parsedData = csv?.data;
            const rows = Object.keys(parsedData[0]);
            const columns = Object.values(parsedData[0]);
            const res = rows.reduce((acc, e, i) => {
                return [...acc, [[e], columns[i]]];
            }, []);
            const stringData = parsedData.reduce((result, item) => {
              return `${result}${item.id},`
            }, "")  
            setIds(stringData);
             setData(res);
        };
        reader.readAsText(file);

        getfuntion("get", [], "", "terrestreMovimientos/TerminaMovimientosMasivamenteEgoba/"+plataforma+"/"+ids, "")

    };
 
    const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
      return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        //setTrackPoints({ returnVal });
        switch (metodo) {
          case "get":
            {
              switch (stateVar) {
  
                default: return returnVal;
              }
            }
        }
      })
    }

    return (
        <div className="App">
            <h1 className="geeks">Terminado Masivo de Movimientos</h1>
            <h3>Para movimientos de Plataforma Egoba</h3>
            <div className="container">

            <div style={{width: '300px'}}>

            <Select
            onChange={(plataforma) => {
                setPlataforma(plataforma.value);
                //alert(plataforma.value);
            }}
            //options={bloques}
            options={plataformas}
            hideSelectedOptions
            //value={plataforma}
          />
            </div>


                <label
                    htmlFor="csvInput"
                    style={{ display: "block" }}
                >
                    Ingrese CSV
                </label>
                <input
                    onChange={handleFileChange}
                    id="csvInput"
                    name="file"
                    type="File"
                />
                <div><br/>
                    <button onClick={handleParse}>
                        Subir
                    </button>
                </div>
                <div style={{ marginTop: "3rem" }}>
                   {ids}
                </div>
            </div>
        </div>
    );
};

export default TerminadoMasivo;