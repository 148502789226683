import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import Select from "react-select";
import { Exportar } from '../herramientas/Excel';
import { timeToDateFill, timeToDatefull } from "../herramientas/DateFormat";

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var currentPage= 0;
var currentPageIncidencias= 0;
var currentPageGps= 0;
var currentPagePatio= 0;
var movsArray= {};
var descargando= false;

Date.prototype.getWeekNumber = function(){
  var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
  return Math.ceil((((d - yearStart) / 86400000) + 1)/7)
};

class MovimientosToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: [],
      incidencias: [],
      gps: [],
      patio: [],
      semana: 1,
      fecha1: timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),
      fecha2: timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() ),
      pagina: 1,
      pagesize: 500,
      plataforma: { value: 0, label: "Todas ...", search: "" },
      currentPage: 0,
      currentPageIncidencias: 0,
      currentPageGps: 0,
      currentPagePatio: 0,
      totalElements: 0,
      totalElementsGps: 0,
      totalElementsIncidencias: 0,
      totalElementsPatio: 0,
      apiValue: "1"

    };
    this.elementos = [];
    this.plataformas=[];
  }
  componentDidMount = () => {
      if(this.props.plataformaTipo === "Naviera"){
        this.onChangeSemana();
      }

      this.setState({ tableheaderItems: this.content });
      this.setState({ funcionalidadesCol: this.funcionalidadesCol });

      this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
      currentUser.map((usr, index) => {
        if(usr.plataforma.plataformaTipo.id === 1 || usr.plataforma.plataformaTipo.id === 3 ){
          this.plataformas.push({ value: usr.plataforma.id, label: usr.plataforma.nombre, search: usr.plataforma.nombre });
        }
      });
  }
  cargarMovimientos=(plataforma, fecha1, fecha2, size)=>{

    let plt= plataforma? plataforma !== null? plataforma: this.state.plataforma.value: this.state.plataforma.value;
    let f1= fecha1? fecha1 : this.state.fecha1;
    let f2= fecha2? fecha2 : this.state.fecha2;
    let pagsize= size? size: this.state.pagesize;

    if(this.props.plataformaTipo === "Naviera"){
      if( plt === 0 ){
        this.getfuntion("get", "", "", "navieraMovimientos/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "Navieras");///pagina/"+currentPage+"/total/"+pagsize
      }else{
        this.getfuntion("get", "", "", "navieraMovimientos/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "Navieras");
      }
    }
  }
  cargarGps=(plataforma, fecha1, fecha2, size)=>{
    let plt= plataforma? plataforma !== null? plataforma: this.state.plataforma.value: this.state.plataforma.value;
    let f1= fecha1? fecha1 : this.state.fecha1;
    let f2= fecha2? fecha2 : this.state.fecha2;
    let pagsize= size? size: this.state.pagesize;

    if(this.props.plataformaTipo === "Naviera"){
      if( plt === 0 ){
        this.getfuntion("get", "", "", "gpsLog/usuario/"+currentUser[0].usuario.id+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59/pagina/"+currentPageGps+"/total/"+pagsize, "gps");
      }else{
        this.getfuntion("get", "", "", "gpsLog/usuario/"+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59/pagina/"+currentPageGps+"/total/"+pagsize, "gps");
      }
    }
  }
  cargarPatio=(plataforma, fecha1, fecha2, size)=>{
    let plt= plataforma? plataforma !== null? plataforma: this.state.plataforma.value: this.state.plataforma.value;
    let f1= fecha1? fecha1 : this.state.fecha1;
    let f2= fecha2? fecha2 : this.state.fecha2;
    let pagsize= size? size: this.state.pagesize;

    if(this.props.plataformaTipo === "Naviera"){
      if( plt === 0 ){
        this.getfuntion("get", "", "", "movimientosPatio/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "patio");
      }else{
        this.getfuntion("get", "", "", "movimientosPatio/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "patio");
      }
    }
  }
  cargarIncidencias=(plataforma, fecha1, fecha2, size)=>{
    let plt= plataforma? plataforma !== null? plataforma: this.state.plataforma.value: this.state.plataforma.value;
    let f1= fecha1? fecha1 : this.state.fecha1;
    let f2= fecha2? fecha2 : this.state.fecha2;
    let pagsize= size? size: this.state.pagesize;

    if(this.props.plataformaTipo === "Naviera"){
      if( plt === 0 ){
        this.getfuntion("get", "", "", "movimientoIncidencias/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "incidencias");
      }else{
        this.getfuntion("get", "", "", "movimientoIncidencias/"+(this.state.apiValue === "1"? "usuario/":"fecha_creacion/")+currentUser[0].usuario.id+"/plt/"+plt+"/fecha1/"+f1+" 00:00:00/fecha2/"+f2+" 23:59:59", "incidencias");
      }
    }
  }

  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "navieraMovimientos", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if (stateVar === "Navieras" && returnVal) {              
              if( returnVal){
                //this.setState({ totalElements: returnVal.totalElements});
                //currentPage++;
                //this.setState({ elementos: returnVal });
                this.elementos = this.elementos.concat(returnVal);
                returnVal.map((m,inx)=>{
                  if(!movsArray[m.id]){
                    movsArray[m.id]= m;
                  };
                });
                
                this.setState({ elementos: this.elementos });
                
                var dataTemp= [];
                this.elementos.map((filas, index) => {
                  Object.keys(filas).forEach(key =>{
                      if(!dataTemp[index]){
                        dataTemp[index]={}
                      }
                      if(filas[key] !== null){
                        if ( typeof filas[key] === 'object'){
                          switch (key) {
                            case "persona":
                              dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
                              break;
                            case "movimientoGps":
                              filas[key].map((gps, indice)=> dataTemp[index]["gps-"+gps.gpsTipo.nombre]= gps.gps.nombre );
                              break;
                            case "idCliente":
                              dataTemp[index][key]= filas[key].nombre;
                              dataTemp[index]["Dirección cliente"]= filas[key].domicilio;
                              //dataTemp[index][key]= filas[key].nombreCorto;
                              dataTemp[index]["Nombre corto cliente"]= filas[key].nombreCorto;
                              
                              break;
                              case "idTransportista":
                                 
                                dataTemp[index]["Nombre corto transportista"]= filas[key].nombreCorto;
                                
                                break;
  
                            default:
                                if ((filas[key].nombre!="")&&(filas[key].nombre!=undefined)) {
                                  dataTemp[index][key]=filas[key].nombre;
                                }else{
                                  if ((filas[key].id!="")&&(filas[key].id!=undefined)) {
                                    dataTemp[index][key]=filas[key].id;
                                  }else{
                                    dataTemp[index][key]="Vari(@)s ";
                                  }
                                }
                          }
                        }else{
                          dataTemp[index][key]=filas[key];
                        }
                      }else{
                        dataTemp[index][key]="";
                      }
                  });                    
                  dataTemp[index]["origen"]=  filas.idMovimientoTipo.id === 1 ? filas.idCorredor.nombre: filas.destino;
                  dataTemp[index]["destino"]=  filas.destino;
                  /**
                   * Tiempo estadia
                   */
                  //1	arribo_cliente_lleno	Arribo a dirección de entrega
                  //1	salida_cliente_vacio	Salida de dirección de entrega
                  //2	arribo_cliente_vacio	Arribo a dirección de entrega
                  //2	salida_cliente_lleno	Salida de dirección de entrega
                  let horas= 0;
                  let minutos= 0;
                  let tiempoT= 0;
                  if( filas.idMovimientoTipo.id === 1 ){
                    tiempoT= new Date(filas.salidaClienteVacio).getTime() - new Date(filas.arriboClienteLleno).getTime();
                    horas= Math.floor( tiempoT/(1000*60*60) );
                    minutos= Math.floor( (tiempoT%(1000*60*60))/(1000*60) );
                    dataTemp[index]["estadia"]= horas+ " hrs, "+ minutos+" min";
                  }
                  if( filas.idMovimientoTipo.id === 2 ){
                    tiempoT= new Date(filas.salidaClienteLleno).getTime() - new Date(filas.arriboClienteVacio).getTime();
                    horas= Math.floor( tiempoT/(1000*60*60) );
                    minutos= Math.floor( (tiempoT%(1000*60*60))/(1000*60) );
                    dataTemp[index]["estadia"]= horas+ " hrs, "+ minutos+" min";
                  }

                });
                this.elementos= dataTemp;
                this.setState({ elementos: dataTemp });
                this.cargarPatio();
              }////


            }
            if (stateVar === "incidencias" && returnVal) {
              let incidenciasTemp= this.state.incidencias;
              incidenciasTemp= incidenciasTemp.concat(returnVal);
              this.setState({ incidencias: incidenciasTemp });

              if( returnVal ){
                //this.setState({ totalElementsIncidencias: returnVal.totalElements});
                
                descargando= false;
                let incidencias={};
                incidenciasTemp.map((inc, index)=>{
                  if( !incidencias[inc.idMovimiento] ){ incidencias[inc.idMovimiento]= [] }
                  incidencias[inc.idMovimiento].push(inc);
                });
                var dataTemp= [];
                this.elementos.map((filas, index) => {
                  Object.keys(filas).forEach(key =>{
                      if(!dataTemp[index]){
                        dataTemp[index]={}
                      }
                      if(filas[key] !== null){
                          dataTemp[index][key]=filas[key];
                      }else{
                        dataTemp[index][key]="";
                      }
                  });
                });
                this.elementos.map((mov, index)=>{
                  if( incidencias[mov.id] ){
                    incidencias[mov.id].map((inc, indice)=>{
                      dataTemp[index]["No incidencias"]= incidencias[mov.id].length;
                      dataTemp[index]["incidencia-"+(indice+1)]= "fecha: "+inc.fechaCreado+"| incidencia: "+inc.incidencia.nombre+"| tipo: "+inc.incidencia.incidenciaTipo.nombre+"| comentario:"+inc.comentario;
                    });
                  }                
                });
                this.elementos= dataTemp;
                this.setState({ elementos: dataTemp });
              }             
            }
            if (stateVar === "patio" && returnVal) {
              let patioTemp= this.state.patio;
              patioTemp= patioTemp.concat(returnVal);
              this.setState({ patio: patioTemp });

              if( returnVal ){
                //this.setState({ totalElementsPatio: returnVal.totalElements});
                
                  let patio={};
                  patioTemp.map((pt, index)=>{
                    if( !patio[pt.idMovimiento] ){ patio[pt.idMovimiento]= pt }
                    //patio[pt.idMovimiento].push(pt);
                  });
                  var dataTemp= [];
                  this.elementos.map((filas, index) => {
                    Object.keys(filas).forEach(key =>{
                        if(!dataTemp[index]){
                          dataTemp[index]={}
                        }
                        if(key !== "movimientoGps" && key !== "incidencia" ){
                          dataTemp[index][key]= [];
                        }
                        if(filas[key] !== null){
                          if ( typeof filas[key] === 'object'){
                            //console.log(filas[key]+"=>> "+ key+"("+typeof filas[key]+")")
                            switch (key) {
                              case "persona":
                                dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
                                break;
                              case "movimientoGps":
                                if( movsArray[filas.id]){
                                  if( movsArray[filas.id][key].length === 0 ){
                                    //movsArray[filas.id][key].map((gps, indice)=> dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre);
                                    console.log(filas.id+"=> sin gps(buscar)" );
                                  }
                                }
                                //filas[key].map((gps, indice)=> dataTemp[index]["gps"+indice+1]= gps.gps.nombre);
                                break;
                              case "incidencias":
                                //dataTemp[index][key]= filas[key];
                                break;
                              case "idCliente":
                                dataTemp[index][key]= filas[key].nombre;
                                dataTemp[index]["Dirección cliente"]= filas[key].domicilio;
                                break;                                
                              default:
                                  if ((filas[key].nombre!="")&&(filas[key].nombre!=undefined)) {
                                    dataTemp[index][key]=filas[key].nombre;
                                  }else{
                                    if ((filas[key].id!="")&&(filas[key].id!=undefined)) {
                                      dataTemp[index][key]=filas[key].id;
                                    }else{
                                      dataTemp[index][key]="Vari(@)s ";
                                    }
                                  }
                            }
                          }else{
                            dataTemp[index][key]=filas[key];
                          }
                        }else{
                          dataTemp[index][key]="";
                        }
                    });
                  });
                  this.elementos.map((mov, index)=>{
                    if( movsArray[mov.id]){
                      if( movsArray[mov.id]["movimientoGps"].length === 0 ){
                        if( patio[mov.id] ){
                          if( movsArray[parseInt(patio[mov.id].esFull, 10)] ){
                            movsArray[parseInt(patio[mov.id].esFull, 10)]["movimientoGps"].map((gps, indice)=> dataTemp[index]["gps-"+gps.gpsTipo.nombre]= gps.gps.nombre+".");
                          }
                        }
                      }else{//si el gps es full, buscar en el otro mov un gps distinto de full
                        if(movsArray[mov.id]["movimientoGps"].length === 1){//primero validamos que solo tenga un gps
                          if(movsArray[mov.id]["movimientoGps"].filter(word => word.gps.nombre.toUpperCase().includes("FULL")).length === 1){//detectamos si hay full
                            if( patio[mov.id] ){
                              if( movsArray[parseInt(patio[mov.id].esFull, 10)] ){
                                movsArray[parseInt(patio[mov.id].esFull, 10)]["movimientoGps"].map((gps, indice)=> dataTemp[index]["gps-"+gps.gpsTipo.nombre]= gps.gps.nombre+".");
                              }
                            }
                          }
                        }
                      }
                    }
                    if( patio[mov.id] ){/*
                      patio[mov.id].map((pt, indice)=>{
                        dataTemp[index]["full"]= pt.esFull;
                        dataTemp[index]["operador"]= pt.operadorNombre;
                        dataTemp[index]["telefono"]= pt.operadorContacto;
                        dataTemp[index]["Placa tractor"]= pt.tractorPlaca;
                        dataTemp[index]["Placa remolque"]= pt.remolquePlaca;
                        dataTemp[index]["economicor"]= pt.economicor;
                        dataTemp[index]["economicot"]= pt.economicot;
                      });*/
                      dataTemp[index]["full"]= patio[mov.id].esFull;
                      dataTemp[index]["operador"]= patio[mov.id].operadorNombre;
                      dataTemp[index]["telefono"]= patio[mov.id].operadorContacto;
                      dataTemp[index]["Placa tractor"]= patio[mov.id].tractorPlaca;
                      dataTemp[index]["Placa remolque"]= patio[mov.id].remolquePlaca;
                      dataTemp[index]["economicor"]= patio[mov.id].economicor;
                      dataTemp[index]["economicot"]= patio[mov.id].economicot;
                    }               
                  });
                  this.elementos= dataTemp;
                  this.setState({ elementos: dataTemp });
                  this.cargarIncidencias();
              }          
            }           
          }
          break;
        default:
          break;
      }
    }).catch(err => { descargando= false; alert(" Ha ocurrido un error presione en actualizar nuevamente"); if (err.response) {console.log(err.response);} else { console.log(err); } });
  }
  buscar=(plataforma, fecha1, fecha2, size)=>{
    if( !descargando ){
      descargando= true;
      currentPage= 0;
      this.setState({ totalElements: 0 });
      this.elementos = [];
      this.setState({ elementos: [] });

      currentPageIncidencias= 0;
      this.setState({ totalElementsIncidencias: 0 });
      this.setState({ incidencias: [] });

      currentPageGps= 0;
      this.setState({ totalElementsGps: 0 });
      this.setState({ gps: [] });

      currentPagePatio= 0;
      this.setState({ totalElementsPatio: 0 });
      this.setState({ patio: [] });
      
      this.cargarMovimientos(plataforma, fecha1, fecha2, size);
    } 
  }
  changePlataformas = (event) =>  this.setState({ plataforma: event });
  onChangeFecha1    = (date)  =>  {
    this.setState({ fecha1: date.target.value });
    //fecha1: timeToDatefull( new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime()-(1000*60*60*24*7)).getTime() ),
    //  fecha2: timeToDatefull(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() ),
  }
  onChangeFecha2    = (date)  =>  {
    this.setState({ fecha2: date.target.value }); }

  onChangeSemana    = (date)  => {
    let semanaTemp= !date ? new Date().getWeekNumber()-1: date.target.value;
    this.setState({ semana: semanaTemp });
    var temDateT= new Date( new Date(new Date().getFullYear()+"-01-01 00:00:00").getTime()+(1000*60*60*24*7*(semanaTemp-1))  );
	  var firstT = temDateT.getDate() - temDateT.getDay()+1;
    var lastT = firstT + 6; // last day is the first day + 6 
    //if( firstT < 0 ){ temDateT= new Date(temDateT.setMonth(temDateT.getMonth()-1) ); }
    var firstdayT = timeToDatefull( new Date(temDateT.setDate(firstT)).getTime() ); //new Date(temDateT.setDate(firstT)).toUTCString();
    
    let temLast= new Date(temDateT.setDate(lastT));
    if( firstT <= 0 ){ temLast= new Date(temDateT.setMonth(temLast.getMonth()+1) ); }
    var lastdayT =  timeToDatefull( temLast.getTime()); //new Date(temDateT.setDate(lastT)).toUTCString();
    this.setState({ fecha1: firstdayT });
    this.setState({ fecha2: lastdayT });
  }

  changeApi=(e)=>{
    this.setState({ apiValue: e.target.value });
  }
  render() {
    return (
      <div>
        {/**
          *   Elementos de plataformas
          */
        }
        <Card>
          <Card.Header>
            <Card.Options>
              <span className="float-right ml-2" style={{ "width": "20%" }}>
                <div class="btn-group" role="group" aria-label="Basic radio toggle button group" onChange={this.changeApi}>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" value="1" checked={this.state.apiValue==="1"} /><label>Cita Programada</label>
                  <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" value="2" checked={this.state.apiValue==="2"} /><label>Fecha creación</label>
                </div> 
              </span>
              <span className="float-right ml-2" style={{ "width": "50%" }}>
                <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} />
              </span>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Form.Input name="id" onChange={this.onChangeSemana} type="number" value={this.state.semana} />
              </span>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <input type="date" id="start" name="trip-start" value={ this.state.fecha1 } onChange={ this.onChangeFecha1 }></input>
              </span>
              <span className="float-right ml-2"> - </span>
              <span className="float-right ml-2" style={{ "width": "35%" }}>
                <input type="date" id="start" name="trip-start" value={ this.state.fecha2 } onChange={ this.onChangeFecha2 }></input>
              </span>
              <span className="float-right ml-2" style={{ "width": "25%" }}>
                <Button pill size="sm" outline color="primary" onClick={ ()=> this.buscar() } ><Icon name="refresh-cw" /></Button>
              </span>              
              <span className="float-right ml-2" style={{ "width": "25%" }} hidden={ descargando? true: false }>
                <Exportar crud={this.getfuntion} catalogo={"Movimientos Terminados"} data={this.state.elementos} />
              </span>
            </Card.Options>
          </Card.Header>
          <Card.Body>
          <h3>Movimientos</h3>
            <div class="progress">              
              <div class="progress-bar" role="progressbar" style={{"width": ((100/this.state.totalElements )* this.state.elementos.length)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.totalElements )* this.state.elementos.length)+"% - "+this.state.elementos.length}</div>
            </div>
            <br/>{/*
            <h3>Gps's</h3>
            <div class="progress">              
              <div class="progress-bar" role="progressbar" style={{"width": ((100/this.state.totalElementsGps )* this.state.gps.length)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.totalElementsGps )* this.state.gps.length)+"% - "+this.state.gps.length}</div>
            </div> */}
            <h3>Incidencias</h3>
            <div class="progress">              
              <div class="progress-bar" role="progressbar" style={{"width": ((100/this.state.totalElementsIncidencias )* this.state.incidencias.length)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.totalElementsIncidencias )* this.state.incidencias.length)+"% - "+this.state.incidencias.length}</div>
            </div>
            <h3>Patio</h3>
            <div class="progress">              
              <div class="progress-bar" role="progressbar" style={{"width": ((100/this.state.totalElementsPatio )* this.state.patio.length)+"%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{Math.round((100/this.state.totalElementsPatio )* this.state.patio.length)+"% - "+this.state.patio.length}</div>
            </div>            
          </Card.Body>
          <Card.Footer>
          </Card.Footer>
        </Card>
      </div>
    )
  }
}
export default MovimientosToExcel