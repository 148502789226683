import React, {  useEffect, useState} from "react";
import { List, Button, Icon } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateNumbers } from "../../../../herramientas/DateFormat";
import { confirmAlert } from 'react-confirm-alert';
import { NotificationManager } from 'react-notifications';
import { TablePageable } from "../../../../Helpers";
import { CSVLink } from "react-csv";
import { SolicitudesCliente, SolicitudesTransportista } from "./";
import ModalLay from "../../../../components/ModalLay/ModalLay";
const columnas= [
  { columna: "idSolicitud", label: "Id" },
  { columna: "idMovimiento", label: "IdMovimiento" },
  { columna: "idPlataforma.nombre", label: "Plataforma" },
  { columna: "contenedor", label: "Contenedor" },
  { columna: "idCliente.nombre", label: "Cliente" },
  { columna: "idCorredor.nombre", label: "Corredor" },
  { columna: "idLocalidad.nombre", label: "Localidad" },
  { columna: "idClienteNaviero.nombre", label: "Cliente final" },
  { columna: "idMovimientoTipo.nombre", label: "Tipo de movimiento" },
  { columna: "idOperacionTipo.nombre", label: "Tipo de operación" },
  { columna: "idTransportista.nombre", label: "Transportista" },
];
let solicitudIds= [];
const SolicitudesNavieras =({SolicitudesNavierasContar})=> {
  const [solicitudes, setSolicitudes]= useState([]);
  const [solicitud, setSolicitud]= useState({});
  useEffect(() => {
    //setSolicitudes([]);
		getfuntion("get", "", "", "solicitudes_cliente/usuario_patios_check_pendiente", "solicitudes");
	}, []);
  const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          {
            if( stateVar === "solicitudes" && returnVal){
              setSolicitudes(returnVal);
              //temp.push(obj.nombre+": "+timeToDateFill(new Date(returnVal.fecha).getTime())+", "+ returnVal.ubicacion);
            }
          }
          break;
        case "put":
            {
              if (stateVar === "check") {
                SolicitudesNavierasContar();
              }               
            }
            break;
        default:
          break;
      }
    }).catch(err => { if (err.response) { console.log(err.response);  } else {console.log("Error desconocido ...." + err); } });
  }
  const getDataToCsv=()=>{
    let csvDataTemp= [];
    solicitudIds= [];
    if(columnas.length){
      let columnasTemp= [];      
      for( let c of columnas ){
        columnasTemp.push(c.label);
      }
      csvDataTemp.push(columnasTemp);
      for( let i of solicitudes.filter(s=> s.idMovimiento !== null) ){
        let columnasDataTemp= [];
        for( let c of columnas ){
          columnasDataTemp.push(getValor(i, c.columna));
        }
        csvDataTemp.push(columnasDataTemp);
        if( i.idMovimiento){
          solicitudIds.push(i.idSolicitud);
        }
      }
    }
    return csvDataTemp;
  }
  const getValor=(valor, nombre)=>{               
    const nombreTemp= nombre.split(".");
    if( nombreTemp ){
      if(1 === nombreTemp.length)
        return valor[nombreTemp[0]];
      if(2 === nombreTemp.length){
        return valor[nombreTemp[0]]? valor[nombreTemp[0]][nombreTemp[1]]: ""; 
      }
      if(3 === nombreTemp.length){
        return valor[nombreTemp[0]] ?
          valor[nombreTemp[0][nombreTemp[1]]]
            ? valor[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]
          :""
        :"";
      }
    }
    return valor[nombre];
  }
  const fresh =()=>{
    setSolicitud({});
    setSolicitudes([]);
    getfuntion("get", "", "", "solicitudes_cliente/usuario_patios_check_pendiente", "solicitudes");
  }
  return (

    <div className= "mt-1">
      <TablePageable
        titulo={
          <div>
            <h3 className="float-left"> Solicitudes pendientes </h3>
            <span className="float-left ml-3">
              <CSVLink
                data={getDataToCsv()}
                filename = { "Solicitudes-"+timeToDateNumbers(new Date().getTime())+".csv" }
                onClick={()=>{
                  confirmAlert({
                    title: 'Atención',
                    message: '¿Quiere descargar y marcar seguimiento con patios ?',
                    buttons: [
                      {
                        label: 'Marcar seguimiento',
                        onClick: () => {
                          getfuntion("put", solicitudIds, "", "solicitudes_cliente/check", "check");
                        }
                      },
                      {
                        label: 'Solo descargar',
                        onClick: () => {return false}
                      }
                    ]
                  });
                }}
              >
                <i className="fa fa-cloud-download" style={{"font-size": "24px"}}> Check</i>
              </CSVLink>
            </span>
            <span className="float-left ml-3">
              { solicitud.idSolicitud && !solicitud.idCliente &&
                <ModalLay
                  tamanio={"90%"}
                  title="Catalogos de clientes"
                  name={
                    <span
                      style={{ color: "blue", "font-size": "200%" }}
                    >
                      <Icon name="edit" /> Cliente
                    </span>
                  }
                  formulario={ SolicitudesCliente }
                  datosForm={{
                    solicitud: solicitud,
                    fresh: fresh
                  }}
                />
              }
            </span>
            <span className="float-left ml-3">
              { solicitud.idSolicitud && !solicitud.idTransportista &&
                <ModalLay
                  tamanio={"90%"}
                  title="Catalogos de clientes"
                  name={
                    <span
                      style={{ color: "blue", "font-size": "200%" }}
                    >
                      <Icon name="edit" /> Transportista
                    </span>
                  }
                  formulario={ SolicitudesTransportista }
                  datosForm={{
                    solicitud: solicitud,
                    fresh: fresh
                  }}
                />
              }
            </span>
            <span className="float-left ml-3">
              <i
                className="fa fa-refresh"
                style={{"font-size": "24px"}}
                onClick={()=> {fresh(); }}
              ></i>
            </span>
          </div> }
        lista= { solicitudes }
        columnas={ columnas }
        id= "idSolicitud"
        selected={setSolicitud}
        csvname= "Solicitudes"
        resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
        resaltarColor={"rgba(223, 58, 58, 0.47)"}
      />
    </div>

  );
}
export default SolicitudesNavieras;
