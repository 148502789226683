import React, { Component } from "react";
import { Button, Form, Icon, Grid, Card } from "tabler-react";
import Select from "react-select";
import DateTimePicker from "react-datetime-picker";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { ClientesForm } from "../../Catalogos";
import { TransportistasForm } from "../../Catalogos";
import ModalLay from "../../components/ModalLay/ModalLay";
/**
 * Date tools
 */
import { timeToDateFill } from "../../herramientas/DateFormat";
/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";
import CotizacionMovimientoTransportistas from "./CotizacionMovimientoTransportistas";

var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

class MonitoreoNavieroForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conteo:-2,
      hiddenModals: false,
      id: 0,
      idCorredor: { value: 0, label: "No se ha seleccionado plataforma" },
      idCliente: { value: 0, label: "No se ha seleccionado plataforma" },
      idClienteNaviero: { value: 0, label: "No se ha seleccionado plataforma" },
      idMovimientoTipo: { value: 0, label: "No se ha seleccionado plataforma" },
      idNaviera: { value: 0, label: "No se ha seleccionado plataforma" },
      idOperacionTipo: { value: 0, label: "No se ha seleccionado plataforma" },
      idPlataforma: { value: 0, label: "Selecciona una plataforma" },
      idTransportista: { value: 0, label: "No se ha seleccionado plataforma" },    
      idResponsableMovimiento: { value: 0, label: "Selecciona Ejecutivo" },
      entregaVacio: false,
      vip: false,
      tipoOperacion: 0,
      booking: "",
      po: "",
      // inicioRutaVacio: "",
      // ingresoCliente: "",
      // ingresoBodegaCarga: "",
      contenedor: "",
      citaProgramada: "",
      citaProgramadaTerminal: "",
      citaColocacionGps: "",
      sello: "",
      viaje: "",
      cp: "",
      referencia: "",
      eco: "",
      modalidad: "",
      empacador: "",
      estatusActual: "",
      variables: [],
      selectedOption: null,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      corredores: [],
      localidad: { value: 0, label: "Selecciona una localidad" },
      navieras: [],
      clientesNavieros: [],
      clientes: [],
      operacionesTipo: [],
      movimientosTipo: [],
      transportistas: [],     
      responsablesMovimiento: [],
      contenedoresTipo: [],
      contenedorTipo: {
        value: 0,
        label: "Selecciona el tipo de carga",
        data: [],
      },
      contenedorSize: { value: 0, label: "Selecciona el tamaño" },
      destino: "",
      nota: "",
      opcion: true,
      crudbutonEnable: true, // Se desabilita cuando se hace click
      //Patio
      operadorNombre: "",
      operadorContacto: "",
      operadorLicencia: "",
      tractorModelo: "",
      tractorMarca: "",
      tractorColor: "",
      tractorPlaca: "",
      economicot:"",
      economicor:"",
      sencilloFull: false,      
    };
    this.enableSave = this.enableSave.bind(this);
    this.MovimientosObject = {     
      idCorredor: {
        id: "",
        nombre: "",
        nombreCorto: "",
        latitud: "",
        longitud: "",
        estatus: "",
      },
      idLocalidad: null,
      idCliente: {
        id: "",
        nombre: "",
        nombreCorto: "",
        domicilio: "",
        contacto: "",
        telefono: "",
        extension: "",
        nota: "",
        vip: "",
        latitud: "",
        longitud: "",
        estatus: "",
      },
      idMovimientoTipo: { id: "", nombre: "" },     
      idResponsableMovimiento: null,
      idNaviera: { id: "1", nombre: "Sin naviera" },
      idClienteNaviero: null,
      idOperacionEstatus: { id: 1, nombre: "Sin GPS" },
      idOperacionTipo: { id: "", nombre: "" },
      idOtd: { id: 0, nombre: "Pendiente", descripcion: "" },
      idPlataforma: {
        id: "",
        nombre: "",
        clave: "",
        plataformaTipo: { id: "", nombre: "" },
        corredores: [],
      },
      idRuta: { id: 1, nombre: "**UNIDAD PROGRAMADA**" },
      idTransportista: {
        id: "",
        nombre: "",
        nombreCorto: "",
        contactoNombre: "",
        telefono1: "",
        telefono2: "",
        correo: "",
      },
      entregaVacio: 0,
      booking: "",
      po: "",
      // inicioRutaVacio: "",
      // ingresoCliente: "",
      // ingresoBodegaCarga: "",      
      contenedor: "",
      citaProgramada: null,
      citaProgramadaTerminal: null,
      citaColocacionGps: null,
      contenedorTipo: 1,
      contenedorSize: 0,
      destino: "",
      nota: "",
      sello: "",
      //"eta": "00:00:00",
      ontime: "00:00:00",
      delay: "00:00:00",
      wti6h: "00:00:00",
      transit: "00:00:00",
      actualizarFull: 0,
      vip: false,
      reaccion: false,
      viaje: "",
      cp: "",
      referencia: "",
      eco: "",
      modalidad: "",
      empacador: "",
      estatusActual: "",
      sencilloFull: false,
    };
    this.clientes = [];
    this.transportistas = [];    
    this.responsablesMovimiento = [];
    this.plataformas = [];
    this.navieras = [];
    this.operaciones = [];
    this.estatusOperacion = [];
    this.movimientoTipos = [];
    this.corredores = [];
    this.localidades = [{ value: 0, label: "Selecciona una localidad" }];
    this.estatusRutas = [];
    this.contenedorSizes = [
      { value: 0, label: "Selecciona el tamaño" },
      { value: 20, label: "20" },
      { value: 40, label: "40" },
      { value: 6, label: "CAJA SECA 53\""},
      { value: 17, label: "40 HC"},
    ];
    this.movimentoPatio = {
      id: null,
      idMovimiento: null,
      idRemolque: { idRemolque: 1, nombre: "PLATAFORMA" },
      operadorNombre: null,
      operadorContacto: null,
      operadorLicencia: null,
      operadorNombre2: null,
      operadorContacto2: null,
      operadorLicencia2: null,
      tractorModelo: null,
      tractorMarca: null,
      tractorColor: null,
      tractorPlaca: null,
      esFull: null,
      remolquePlaca: null,
      colocacionFecha: null,
      observaciones: null,
      economicot: null,
      economicor: null,
      idLocalidad: null,
      idUsuario: null,
    };
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 1,
    };
  }
  isEnable = (Accion) => {
    //let borrar= "clientes_borrar";

    let actualizar = "movimientos_editar";
    let agregar = "movimientos_agregar";

    let actualizarCliente = "clientes_editar";
    let agregarCliente = "clientes_agregar";

    let actualizarTransportista = "transportistas_editar";
    let agregarTransportista = "transportistas_agregar";

    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "actualizarCliente") {
        if (privilegios[i].clave == actualizarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarCliente") {
        if (privilegios[i].clave == agregarCliente) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      if (Accion === "actualizarTransportista") {
        if (privilegios[i].clave == actualizarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregarTransportista") {
        if (privilegios[i].clave == agregarTransportista) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }

      //if(Accion === "borrar"){ if( privilegios[i].clave == borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio == privilegios[i].id).length > 0 ){return true}}}
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "cliente",
    stateVar = "clientes",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if( stateVar === "variables" && returnVal){
                if ( !this.MovimientosObject.variables ) {
                  this.MovimientosObject.variables={};
                  for(let v of returnVal){
                    switch (v.tipo) {
                      case "String":  this.MovimientosObject.variables[v.nombre]= "";
                        break;
                      case "DateTime":  this.MovimientosObject.variables[v.nombre]= null;
                        break;
                      case "Any":
                        switch (v.nombre) {
                          case "cuenta_espejo":
                            this.MovimientosObject.variables[v.nombre]={};
                            this.MovimientosObject.variables[v.nombre]["url"]= "";
                            this.MovimientosObject.variables[v.nombre]["usuario"]= "";
                            this.MovimientosObject.variables[v.nombre]["password"]= "";
                            break;
                          default:
                            break;
                        }
                        break;
                      case "Boolean": this.MovimientosObject.variables[v.nombre]= false;
                        break;   
                      default:
                        break;
                    }
                  }
                }
                this.setState({ variables: returnVal});   
              }
              if (stateVar === "corredores") {
                this.corredores = [];
                returnVal.map((corredor, index) =>
                  this.corredores.push({
                    value: corredor.id,
                    label: corredor.nombre,
                    data: corredor,
                  })
                );
                this.setState({ corredores: this.corredores });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idCorredor: { value: 0, label: "Selecciona un corredor" },
                  });
                }
              }
              if (stateVar === "contenedorTipo") {
                let ContTipoTemp = [];
                ContTipoTemp.push({
                  value: 0,
                  label: "Selecciona el tipo de carga",
                  data: [],
                });
                returnVal.map((contTipo, index) => {
                  if (this.props.accion == "nuevo") {
                    if (contTipo.id === 1) {
                      this.setState({
                        contenedorTipo: {
                          value: contTipo.id,
                          label: contTipo.nombre,
                          data: contTipo,
                        },
                      });
                      this.MovimientosObject.contenedorTipo = contTipo.id;
                    }
                  } else {
                    if (this.props.elementos.contenedorTipo === contTipo.id) {
                      this.setState({
                        contenedorTipo: {
                          value: contTipo.id,
                          label: contTipo.nombre,
                          data: contTipo,
                        },
                      });
                      this.MovimientosObject.contenedorTipo = contTipo.id;
                    }
                  }
                  ContTipoTemp.push({
                    value: contTipo.id,
                    label: contTipo.nombre,
                    data: contTipo,
                  });
                });
                this.setState({ contenedoresTipo: ContTipoTemp });
              }
              if (stateVar === "navieras") {
                this.navieras = [];
                returnVal.map((nav, index) =>
                  this.navieras.push({
                    value: nav.id,
                    label: nav.nombre,
                    data: nav,
                  })
                );
                this.setState({ navieras: this.navieras });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idNaviera: { value: 0, label: "Selecciona una naviera" },
                  });
                }
              }
              if (stateVar === "clientes") {
                this.clientes = [];
                if (returnVal.content.length > 0) {
                  returnVal.content.map((client, index) =>
                    this.clientes.push({
                      value: client.id,
                      label: client.nombre,
                      data: client,
                    })
                  );
                  this.setState({ clientes: this.clientes });
                  if (this.props.accion == "nuevo") {
                    this.setState({
                      idCliente: { value: 0, label: "Selecciona un cliente" },
                    });
                  }
                } else {
                  this.setState({
                    idCliente: { value: 0, label: "No hay clientes" },
                  });
                }
              }
              if (stateVar === "clientesNavieros") {
                let clientesNav = [];
                if (returnVal.content.length > 0) {
                  returnVal.content.map((client, index) =>
                    clientesNav.push({
                      value: client.id,
                      label: client.nombre,
                      data: client,
                    })
                  );
                  this.setState({ clientesNavieros: clientesNav });
                  if (this.props.accion == "nuevo") {
                    this.setState({
                      idCliente: { value: 0, label: "Selecciona un cliente" },
                    });
                  }
                } else {
                  this.setState({
                    idClienteNaviero: { value: 0, label: "No hay clientes" },
                  });
                }
              }
              if (stateVar === "operacionesTipo") {
                this.operaciones = [];
                returnVal.map((op, index) =>
                  this.operaciones.push({
                    value: op.id,
                    label: op.nombre,
                    data: op,
                  })
                );
                this.setState({ operacionesTipo: this.operaciones });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idOperacionTipo: {
                      value: 0,
                      label: "Selecciona un Tipo de operación",
                    },
                  });
                }
              }
              if (stateVar === "movimientosTipo") {
                this.movimientoTipos = [];
                returnVal.map((mt, index) =>
                  this.movimientoTipos.push({
                    value: mt.id,
                    label: mt.nombre,
                    data: mt,
                  })
                );
                this.setState({ movimientosTipo: this.movimientoTipos });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idMovimientoTipo: {
                      value: 0,
                      label: "Selecciona un Tipo de movimiento",
                    },
                  });
                }
              }
              if (stateVar === "transportistas") {
                this.transportistas = [];
                returnVal.map((t, index) => {
                  this.transportistas.push({
                    value: t.id,
                    label: t.nombre,
                    data: t,
                  });
                });
                this.setState({ transportistas: this.transportistas });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idTransportista: {
                      value: 0,
                      label: "Selecciona un Transportistas",
                    },
                  });
                }
              }              
              if (stateVar === "responsablesMovimiento") {
                this.responsablesMovimiento = [];
                returnVal.map((rm, index) => {
                  this.responsablesMovimiento.push({
                    value: rm.id,
                    label: rm.nombre + " " + rm.aPaterno + " " + rm.aMaterno,
                    data: rm,
                  });
                });
                this.setState({ responsablesMovimiento: this.responsablesMovimiento });
                if (this.props.accion == "nuevo") {
                  this.setState({
                    idResponsableMovimiento: {
                      value: 0,
                      label: "Selecciona un Ejecutivo",
                    },
                  });
                }
              }
              if (stateVar === "movimientosPatio") {
                return returnVal;
               }
              if (stateVar === "conteo") {
                return returnVal;
              }
 
            }
            break;
          case "post":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  this.getfuntion(
                    "post",
                    {
                      idPlataforma: this.state.idPlataforma.value,
                      idCliente: returnVal.id,
                    },
                    "",
                    "plataformasClientes",
                    "plataformasClientes"
                  );
                  NotificationManager.info(
                    "Se ha agregado el cliente al catlogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  this.clientes = this.state.clientes;
                  this.clientes.push({
                    value: returnVal.id,
                    label: returnVal.nombre,
                    data: returnVal,
                  });
                  this.MovimientosObject.idCliente = returnVal;
                  this.setState({ clientes: this.clientes });

                  this.setState({
                    idCliente: {
                      value: returnVal.id,
                      label: returnVal.nombre,
                      data: returnVal,
                    },
                  });
                }
              }
              if (stateVar === "plataformasClientes") {
                if (returnVal.idPlataforma) {
                  NotificationManager.info(
                    "El cliente ahora pertenece a la plataforma( " +
                      this.state.idPlataforma.value +
                      " )",
                    "Nuevo"
                  );
                }
                this.hiddenmodal();
              }
              if (stateVar === "navieraMovimientos" && returnVal.id) {
                if (
                  [292, 61].includes(this.state.idPlataforma.value) &&
                  (this.movimentoPatio.operadorNombre ||
                    this.movimentoPatio.operadorContacto ||
                    this.movimentoPatio.operadorLicencia ||
                    this.movimentoPatio.tractorModelo ||
                    this.movimentoPatio.tractorMarca ||
                    this.movimentoPatio.tractorColor ||
                    this.movimentoPatio.tractorPlaca)
                ) {
                  this.movimentoPatio.idMovimiento = returnVal.id;
                  if (this.movimentoPatio.id) {
                    this.getfuntion(
                      "put",
                      this.movimentoPatio,
                      "",
                      "movimientosPatio",
                      "movimientosPatio"
                    );
                  } else {
                    this.getfuntion(
                      "post",
                      this.movimentoPatio,
                      "",
                      "movimientosPatio",
                      "movimientosPatio"
                    );
                  }
                }
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = timeToDateFill(
                  new Date().getTime()
                );
                this.usuariosLogObject.descripcion = "Nuevo Movimiento";
                Crud_Catalogos(
                  "usuariosLog",
                  "bitacoraMov",
                  "post",
                  "",
                  this.usuariosLogObject
                );              
                //if(this.MovimientosObject.contenedor==""||this.MovimientosObject.booking==""||this.MovimientosObject.destino=="")
                if (["", "vacio","vacío", "pendiente"].includes(this.MovimientosObject.contenedor.toLowerCase().trim())
                ||["", "vacio","vacío", "pendiente"].includes(this.MovimientosObject.booking.toLowerCase().trim())
                ||["", "vacio","vacío", "pendiente"].includes(this.MovimientosObject.destino.toLowerCase().trim())
                )
                {
                 // alert("correo Enviado: contenedor"+this.MovimientosObject.contenedor);
                this.getfuntion(
                  "post",
                  {
                    idPlataforma: 0,
                    idCliente: 0,
                  },
                  "",        
                  "mail/arrobanaviera/"+this.usuariosLogObject.idMovimiento+"/11",
                  "sendMail"
                );
              }
              }
            }
            break;
          case "put":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se han agregado las modificaciones del cliente al catlogo general( " +
                      returnVal.nombre +
                      " )",
                    "Modificación"
                  );
                  let clientesTem = this.state.clientes;
                  for (let item of clientesTem) {
                    if (item.id == returnVal.id) {
                      item = returnVal;
                    }
                  }
                  this.setState({ clientes: clientesTem });
                  this.hiddenmodal();
                }
              }
              if (stateVar === "navieraMovimientos" && returnVal.id) {
                if (
                  [292, 61].includes(this.state.idPlataforma.value) &&
                  (this.movimentoPatio.operadorNombre ||
                    this.movimentoPatio.operadorContacto ||
                    this.movimentoPatio.operadorLicencia ||
                    this.movimentoPatio.tractorModelo ||
                    this.movimentoPatio.tractorMarca ||
                    this.movimentoPatio.tractorColor ||
                    this.movimentoPatio.tractorPlaca)
                ) {
                  if (this.movimentoPatio.id) {
                    this.getfuntion(
                      "put",
                      this.movimentoPatio,
                      "",
                      "movimientosPatio",
                      "movimientosPatio"
                    );
                  } else {
                    this.getfuntion(
                      "post",
                      this.movimentoPatio,
                      "",
                      "movimientosPatio",
                      "movimientosPatio"
                    );
                  }
                }
                this.usuariosLogObject.idBitacoraMonitoreo = 1;
                this.usuariosLogObject.idUsuario = user.id;
                this.usuariosLogObject.idMovimiento = returnVal.id;
                this.usuariosLogObject.idPlataformaTipo = 1;
                this.usuariosLogObject.accionFecha = timeToDateFill(
                  new Date().getTime()
                );
                this.usuariosLogObject.descripcion =
                  "Actualización de Movimiento";
                Crud_Catalogos(
                  "usuariosLog",
                  "bitacoraMov",
                  "post",
                  "",
                  this.usuariosLogObject
                );
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
          console.log(err);
      });
  };
  getfuntionTrans = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "transportistas",
    stateVar = "transportistas",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "post":
            {
              if (stateVar === "transportistas") {
                if (returnVal.id) {
                  this.getfuntionTrans(
                    "post",
                    {
                      plataforma: this.props.elementos.idPlataforma,
                      transportista: returnVal,
                    },
                    "",
                    "plataformasTransportistas",
                    "plataformasTransportistas"
                  );
                  NotificationManager.info(
                    "Se ha agregado el transportista al catlogo general( " +
                      returnVal.nombre +
                      " )",
                    "Nuevo"
                  );
                  this.transportistas = this.state.transportistas;
                  this.transportistas.push({
                    value: returnVal.id,
                    label: returnVal.nombre,
                    data: returnVal,
                  });
                  this.MovimientosObject.idTransportista = returnVal;
                  this.setState({ transportistas: this.transportistas });
                  this.setState({
                    idTransportista: {
                      value: returnVal.id,
                      label: returnVal.nombre,
                      data: returnVal,
                    },
                  });
                }
              }
              if (stateVar === "plataformasTransportistas") {
                if (returnVal.plataforma) {
                  NotificationManager.info(
                    "El transportista ahora pertenece a la plataforma( " +
                      returnVal.plataforma.nombre +
                      " )",
                    "Nuevo"
                  );
                }
                this.hiddenmodal();
              }
            }
            break;
          case "put":
            {
              if (stateVar === "transportistas") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Se han agregado las modificaciones del transportista al catalogo general( " +
                      returnVal.nombre +
                      " )",
                    "Modificación"
                  );
                  let transportistasTem = this.state.transportistas;
                  for (let item of transportistasTem) {
                    if (item.id == returnVal.id) {
                      item = returnVal;
                    }
                  }
                  this.transportistas = transportistasTem;
                  this.setState({ transportistas: transportistasTem });
                  this.setState({
                    idTransportista: {
                      value: returnVal.id,
                      label: returnVal.nombre,
                      data: returnVal,
                    },
                  });
                  this.hiddenmodal();
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("Error desconocido ....");
        }
      });
  };
  componentWillMount = () => {};
  componentDidMount = () => {    
    /////////////////////////////////////////////////////////////////////////////////
    currentUser.map((elemento, index) => {
      if (
        elemento.plataforma.plataformaTipo.nombre
          .toUpperCase()
          .includes("NAVIERA")
      ) {
        this.plataformas.push({
          value: elemento.plataforma.id,
          label: elemento.plataforma.nombre,
          data: elemento.plataforma,
        });
      }
    });
    this.props.stor.localidades_object.filter(l => l.estatus ).map((l, index) =>
      this.localidades.push({ value: l.id, label: l.nombre, data: l })
    );
    this.getfuntion("get", "", "", "contenedorTipo", "contenedorTipo");
    if (this.state.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "variables/" + this.props.elementos.idPlataforma.id,
        "variables"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "corredores/plataforma/" + this.props.elementos.idPlataforma.id,
        "corredores"
      );
      let contenedorSizeLabel= "";
      if( this.props.elementos.contenedorSize ){
        const contenedorSizeTemp= this.contenedorSizes.filter( c => c.value === this.props.elementos.contenedorSize);
        contenedorSizeLabel= contenedorSizeTemp.length === 1? contenedorSizeTemp[0].label: "";
      }
      this.setState({
        contenedorSize: this.props.elementos.contenedorSize
          ? {
              value: this.props.elementos.contenedorSize,
              label: contenedorSizeLabel
            }
          : { value: 0, label: "Selecciona el tamaño" },
      });
      this.setState({
        localidad: this.props.elementos.idLocalidad
          ? {
              value: this.props.elementos.idLocalidad.id,
              label: this.props.elementos.idLocalidad.nombre,
            }
          : { value: 0, label: "Selecciona una localidad" },
      });
      this.setState({
        destino: this.props.elementos.destino
          ? this.props.elementos.destino
          : "",
      });
      this.setState({
        nota: this.props.elementos.nota ? this.props.elementos.nota : "",
      });
      this.setState({ id: this.props.elementos.id });
      this.setState({
        idNaviera: {
          value: this.props.elementos.idNaviera.id,
          label: this.props.elementos.idNaviera.nombre,
        },
      });
      this.setState({
        opcion: this.props.elementos.idNaviera.id > 1 ? true : false,
      });
      this.setState({ viaje: this.props.elementos.viaje });
      this.setState({ cp: this.props.elementos.cp });
      this.setState({ referencia: this.props.elementos.referencia });
      this.setState({ eco: this.props.elementos.eco });
      this.setState({ modalidad: this.props.elementos.modalidad });
      this.setState({ estatusActual: this.props.elementos.estatusActual });
      this.setState({ empacador: this.props.elementos.empacador });
      this.setState({
        idResponsableMovimiento: this.props.elementos.idResponsableMovimiento
          ? {
              value: this.props.elementos.idResponsableMovimiento.id,
              label: this.props.elementos.idResponsableMovimiento.nombre + " " + this.props.elementos.idResponsableMovimiento.aPaterno
              + " " + this.props.elementos.idResponsableMovimiento.aMaterno ,
            }
          : { value: 0, label: "Selecciona Ejecutivo" },
      });
      /**
       * Cargar catalogos
       */
      let idPlt = this.props.elementos.idPlataforma.id;
      if (idPlt) {
        this.getfuntion(
          "get",
          "",
          "",
          "corredores/plataforma/" + idPlt,
          "corredores"
        );
        this.getfuntion(
          "get",
          "",
          "",
          "navieras/plataforma/" + idPlt,
          "navieras"
        );
        if (this.props.elementos.idCliente) {
          let nombre = this.props.elementos.idCliente.nombre;
          this.getfuntion(
            "get",
            "",
            "",
            "cliente/plataforma/" +
              idPlt +
              "/pagina/0/total/100" +
              "/cliente?cliente=" +
              encodeURIComponent(nombre),
            "clientes"
          );
        } else {
          this.getfuntion(
            "get",
            "",
            "",
            "cliente/plataforma/" + idPlt + "/pagina/0/total/100",
            "clientes"
          );
        }
        if (this.props.elementos.idClienteNaviero) {
          let nombre = this.props.elementos.idClienteNaviero.nombre;
          this.getfuntion(
            "get",
            "",
            "",
            "clientes_navieros/plataforma/" +
              idPlt +
              "/cliente/" +
              nombre +
              "/pagina/0/total/100",
            "clientesNavieros"
          );
        } else {
          this.getfuntion(
            "get",
            "",
            "",
            "clientes_navieros/plataforma/" + idPlt + "/pagina/0/total/100",
            "clientesNavieros"
          );
        }
        this.getfuntion(
          "get",
          "",
          "",
          "operacionesTipo/plataforma/" + idPlt,
          "operacionesTipo"
        );
        this.getfuntion(
          "get",
          "",
          "",
          "movimientosTipo/plataforma/" + idPlt,
          "movimientosTipo"
        );
        // Trae los transportistas
        this.getfuntion(
          "get",
          "",
          "",
          "transportistas/plataforma/" + idPlt,
          "transportistas"
        );        
        this.getfuntion(
          "get",
          "",
          "",
          "personas/ejecutivosFerromex",
          "responsablesMovimiento"
        );
        if ([292, 61].includes(idPlt)) {
          this.getfuntion(
            "get",
            "",
            "",
            "movimientosPatio/idMovimiento/" + this.props.elementos.id,
            "movimientosPatio"
          ).then((mPatio) => {
            this.movimentoPatio = mPatio;
            if (!mPatio.id) {
              this.movimentoPatio.idMovimiento = this.props.elementos.id;
              this.movimentoPatio.idRemolque = {
                idRemolque: 1,
                nombre: "PLATAFORMA",
              };
            } else {
              this.setState({ operadorNombre: mPatio.operadorNombre });
              this.setState({ operadorContacto: mPatio.operadorContacto });
              this.setState({ operadorLicencia: mPatio.operadorLicencia });

              this.setState({ tractorModelo: mPatio.tractorModelo });
              this.setState({ tractorMarca: mPatio.tractorMarca });
              this.setState({ tractorColor: mPatio.tractorColor });
              this.setState({ tractorPlaca: mPatio.tractorPlaca });
            }
          });
        }
      }
      if (this.props.elementos.idPlataforma) {
        this.setState({
          idPlataforma: {
            value: this.props.elementos.idPlataforma.id,
            label: this.props.elementos.idPlataforma.nombre,
            data: this.props.elementos.idPlataforma,
          },
        });
      } else {
        this.setState({
          idPlataforma: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idOperacionTipo) {
        this.setState({
          idOperacionTipo: {
            value: this.props.elementos.idOperacionTipo.id,
            label: this.props.elementos.idOperacionTipo.nombre,
          },
        });
      } else {
        this.setState({
          idOperacionTipo: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idTransportista) {
        this.setState({
          idTransportista: {
            value: this.props.elementos.idTransportista
              ? this.props.elementos.idTransportista.id
              : 0,
            label: this.props.elementos.idTransportista
              ? this.props.elementos.idTransportista.nombre
              : "Sin transportista",
            data: this.props.elementos.idTransportista
              ? this.props.elementos.idTransportista
              : null,
          },
        });
      } else {
        this.setState({
          idTransportista: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idMovimientoTipo) {
        this.setState({
          idMovimientoTipo: {
            value: this.props.elementos.idMovimientoTipo.id,
            label: this.props.elementos.idMovimientoTipo.nombre,
          },
        });
      } else {
        this.setState({
          idMovimientoTipo: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idCorredor) {
        this.setState({
          idCorredor: {
            value: this.props.elementos.idCorredor.id,
            label: this.props.elementos.idCorredor.nombre,
          },
        });
      } else {
        this.setState({
          idCorredor: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idCliente) {
        this.setState({
          idCliente: {
            value: this.props.elementos.idCliente.id,
            label: this.props.elementos.idCliente.nombre,
            data: this.props.elementos.idCliente,
          },
        });
      } else {
        this.setState({
          idCliente: { value: 0, label: "Seleccione Una opción" },
        });
      }

      if (this.props.elementos.idClienteNaviero) {
        this.setState({
          idClienteNaviero: {
            value: this.props.elementos.idClienteNaviero.id,
            label: this.props.elementos.idClienteNaviero.nombre,
            data: this.props.elementos.idClienteNaviero,
          },
        });
      } else {
        this.setState({
          idClienteNaviero: { value: 0, label: "Seleccione Una opción" },
        });
      }

      this.setState({ booking: this.props.elementos.booking });
      this.setState({ po: this.props.elementos.po });
      
//      this.setState({ inicioRutaVacio: this.props.elementos.InicioRutaVacio });
      //   if (this.props.elementos.inicioRutaVacio != null) {
      //      this.setState({
      //       inicioRutaVacio: new Date(this.props.elementos.inicioRutaVacio),
      //   });
      //   }
      // this.setState({ ingresoCliente: this.props.elementos.ingresoCliente });
      // this.setState({ ingresoBodegaCarga: this.props.elementos.ingresoBodegaCarga });

      this.setState({ entregaVacio: this.props.elementos.entregaVacio });
      this.setState({ vip: this.props.elementos.vip });
      this.setState({ contenedor: this.props.elementos.contenedor });
      this.setState({ tipoOperacion: this.props.elementos.tipoOperacion });
      this.setState({ sencilloFull: this.props.elementos.sencilloFull });

      if (this.props.elementos.citaProgramada != null) {
        this.setState({
          citaProgramada: new Date(this.props.elementos.citaProgramada),
        });
      }

      if (this.props.elementos.citaProgramadaTerminal != null) {
        this.setState({
          citaProgramadaTerminal: new Date(this.props.elementos.citaProgramadaTerminal),
        });
      }

      if (this.props.elementos.citaColocacionGps != null) {
        this.setState({
          citaColocacionGps: new Date(this.props.elementos.citaColocacionGps),
        });
      }

      this.setState({ sello: this.props.elementos.sello });
      this.MovimientosObject = {...this.props.elementos};
      if(this.MovimientosObject.variables)
        this.MovimientosObject.variables= JSON.parse(this.MovimientosObject.variables);
    } else {
      if(this.plataformas.length && this.plataformas.length === 1 ){
        this.changePlataformas(this.plataformas[0]);
      }
    }
  };
  enableSave = () => {
    if (
      this.state.idPlataforma.value > 0 &&
      this.state.idCliente.value > 0 &&
      this.state.idTransportista.value > 0 &&
      this.state.idOperacionTipo.value > 0 &&
      this.state.idMovimientoTipo.value > 0 &&
      this.state.idCorredor.value > 0
    ) {
      if (this.state.idPlataforma.data.zonaHoraria) {
        if (
          this.state.idPlataforma.data.zonaHoraria
            .toUpperCase()
            .includes("MEX") &&
          this.state.idPlataforma.data.plataformaTipo.id == 1
        ) {
          if (this.state.localidad.value > 0) {
            return false;
          } else {
            return true;
          }
        }
      }
      return false;
    } else {
      return true;
    }
  };

  guardarMovimiento = () => { 
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();

  /*  
  
  //cambios para validacion de contenedor
  if (this.state.accion === "modificar") { this.crud_put();}
    else {        
        this.getfuntion(
        "get",
        "",
        "",        
        "navieraMovimientos/contenedor/"+this.state.contenedor,
        "conteo"
      ).then((returnVal) => {
        this.setState({ conteo: returnVal });
        this.state.conteo=returnVal;
        //721,7,2,1,130,130,95,4,259,6
        if(this.state.conteo&&(this.MovimientosObject.idPlataforma.id==721||this.MovimientosObject.idPlataforma.id==7||this.MovimientosObject.idPlataforma.id==2||this.MovimientosObject.idPlataforma.id==1||this.MovimientosObject.idPlataforma.id==130||this.MovimientosObject.idPlataforma.id==95||this.MovimientosObject.idPlataforma.id==4||this.MovimientosObject.idPlataforma.id==259||this.MovimientosObject.idPlataforma.id==6)  ){
       // if(this.state.conteo&&(this.MovimientosObject.idPlataforma.id==61)  ){  
          alert("El contenedor no es válida debido a que se encuentra en un movimiento activo o fue usado recientemente en importación/exportación "+this.MovimientosObject.idNaviera.nombre+" "+this.MovimientosObject.idPlataforma.nombre )}
        else{this.crud_post();}
      }).catch(console.log("catch"));          
    }*/
    this.props.cerrarModal();
  };

  //************ Combos ***********************
  changePlataformas = (event) => {
    currentUser.map((elemento, index) => {
      if (event.value == elemento.plataforma.id) {
        this.MovimientosObject.idPlataforma = elemento.plataforma;
      }
    });
    this.setState({ idPlataforma: event });
    this.setState({ idCorredor: { value: 0, label: "Cargando corredores" } });
    this.setState({ idNaviera: { value: 0, label: "Cargando navieras" } });
    this.setState({ idCliente: { value: 0, label: "Cargando Clientes" } });
    this.setState({
      idOperacionTipo: { value: 0, label: "Cargando Tipos de operación" },
    });
    this.setState({
      idMovimientoTipo: { value: 0, label: "Cargando Tipos de movimiento" },
    });
    this.setState({
      idTransportista: { value: 0, label: "Cargando Transportistas" },
    });
    this.getfuntion(
      "get",
      "",
      "",
      "variables/" + event.value,
      "variables"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "corredores/plataforma/" + event.value,
      "corredores"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "navieras/plataforma/" + event.value,
      "navieras"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "cliente/plataforma/" + event.value + "/pagina/0/total/100",
      "clientes"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "clientes_navieros/plataforma/" + event.value + "/pagina/0/total/100",
      "clientesNavieros"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "operacionesTipo/plataforma/" + event.value,
      "operacionesTipo"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "movimientosTipo/plataforma/" + event.value,
      "movimientosTipo"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "transportistas/plataforma/" + event.value,
      "transportistas"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "personas/ejecutivosFerromex",
      "responsablesMovimiento"
    );
  };
  changeNaviera = (event) => {
    this.MovimientosObject.idNaviera = event.data;
    this.setState({ idNaviera: event });
  };

  changeCliente = (event) => {
    this.MovimientosObject.idCliente = event.data;
    this.setState({ idCliente: event });
  };
  changeClienteNaviero = (event) => {
    this.MovimientosObject.idClienteNaviero = event.data;
    this.setState({ idClienteNaviero: event });
  };

  changeContenedorTipo = (event) => {
    this.MovimientosObject.contenedorTipo = event.value;
    this.setState({ contenedorTipo: event });
  };
  changeContenedorSize = (event) => {
    this.MovimientosObject.contenedorSize = event.value;
    this.setState({ contenedorSize: event });
  };
  changeDestino = (event) => {
    this.MovimientosObject.destino = event.target.value;
    this.setState({ destino: event.target.value });
  };
  changeNota = (event) => {
    if (event.target.value.length <= 2000) {
      this.MovimientosObject.nota = event.target.value;
      this.setState({ nota: event.target.value });
    }
  };

  changeCorredor = (event) => {
    this.corredores.map((elemento, index) => {
      if (event.value == elemento.data.id) {
        this.MovimientosObject.idCorredor = elemento.data;
      }
    });
    this.setState({ idCorredor: event });
  };
  changeLocalidad = (event) => {
    this.MovimientosObject.idLocalidad = event.data;
    this.setState({ localidad: event });
  };
  changeMovimientoTipo = (event) => {
    this.movimientoTipos.map((elemento, index) => {
      if (event.value == elemento.data.id) {
        this.MovimientosObject.idMovimientoTipo = elemento.data;
      }
    });
    this.setState({ idMovimientoTipo: event });
  };
  changeResponsableMovimiento = (event) => {
    this.MovimientosObject.idResponsableMovimiento = event.data;
    this.setState({ idResponsableMovimiento: event });
  }  
  changeTransportista = (event) => {
    this.transportistas.map((elemento, index) => {
      if (event.value == elemento.data.id) {
        this.MovimientosObject.idTransportista = elemento.data;
      }
    });
    this.setState({ idTransportista: event });
  };
  changeOperacionesTipo = (event) => {
    this.operaciones.map((elemento, index) => {
      if (event.value == elemento.data.id) {
        this.MovimientosObject.idOperacionTipo = elemento.data;
      }
    });
    this.setState({ idOperacionTipo: event });
  };
  //Campos Normales
  changeBooking = (event) => {
    this.MovimientosObject.booking = event.target.value;
    this.setState({ booking: event.target.value });
  };
  changePo = (event) => {
    this.MovimientosObject.po = event.target.value;
    this.setState({ po: event.target.value });
  };

 /* changeInicioRutaVacio = (event) => {
    this.MovimientosObject.inicioRutaVacio = event.target.value;
    this.setState({ inicioRutaVacio: event.target.value });
  };*/
/*
  changeInicioRutaVacio = (date) => {
    this.MovimientosObject.inicioRutaVacio = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ inicioRutaVacio: date })
      : this.setState({ inicioRutaVacio: date });
  };

  // changeIngresoCliente = (event) => {
  //   this.MovimientosObject.ingresoCliente = event.target.value;
  //   this.setState({ ingresoCliente: event.target.value });
  // };
  changeIngresoCliente = (date) => {
    this.MovimientosObject.ingresoCliente = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ ingresoCliente: date })
      : this.setState({ ingresoCliente: date });
  };


  // changeIngresoBodegaCarga = (event) => {
  //   this.MovimientosObject.ingresoBodegaCarga = event.target.value;
  //   this.setState({ ingresoBodegaCarga: event.target.value });
  // };


  changeIngresoBodegaCarga = (date) => {
    this.MovimientosObject.ingresoBodegaCarga = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ ingresoBodegaCarga: date })
      : this.setState({ ingresoBodegaCarga: date });
  };
*/
  changeEntregaVacio = (event) => {
    this.MovimientosObject.entregaVacio = event.target.checked ? 1 : 0;
    event.target.checked
      ? this.setState({ entregaVacio: 1 })
      : this.setState({ entregaVacio: 0 });
  };
  changeVip = (event) => {
    this.MovimientosObject.vip = event.target.checked;
    this.setState({ vip: event.target.checked });
  };
  changeSencilloFull = (event) => {
    this.MovimientosObject.sencilloFull = event.target.checked;
    this.setState({ sencilloFull: event.target.checked });
  };
  changeOpcion = (event) => {
    this.setState({ opcion: event.target.checked });
  };

  changeContenedor = (event) => {
    this.MovimientosObject.contenedor = event.target.value;
    this.setState({ contenedor: event.target.value });
  };
  changeTipoOperacion = (event) => {
    this.MovimientosObject.tipoOperacion = event.target.value;
    this.setState({ tipoOperacion: event.target.value });
  };

  changeViaje = (event) => {
    this.MovimientosObject.viaje = event.target.value;
    this.setState({ viaje: event.target.value });
  };
  changeCp = (event) => {
    this.MovimientosObject.cp = event.target.value;
    this.setState({ cp: event.target.value });
  };
  changeReferencia = (event) => {
    this.MovimientosObject.referencia = event.target.value;
    this.setState({ referencia: event.target.value });
  };
  changeEco = (event) => {
    this.MovimientosObject.eco = event.target.value;
    this.setState({ eco: event.target.value });
  };
  changeModalidad = (event) => {
    this.MovimientosObject.modalidad = event.target.value;
    this.setState({ modalidad: event.target.value });
  };
  changeEmpacador = (event) => {
    this.MovimientosObject.empacador = event.target.value;
    this.setState({ empacador: event.target.value });
  };
  changeEstatusActual = (event) => {
    this.MovimientosObject.estatusActual = event.target.value;
    this.setState({ estatusActual: event.target.value });
  };
  changeSello = (event) => {
    this.MovimientosObject.sello = event.target.value;
    this.setState({ sello: event.target.value });
  };

  onChangeDate = (date) => {
    this.MovimientosObject.citaProgramada = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ citaProgramada: date })
      : this.setState({ citaProgramada: date });
  };

  onChangeCitaProgramadaTerminal = (date) => {
    this.MovimientosObject.citaProgramadaTerminal = timeToDateFill( new Date(date).getTime() );
    date != null
      ? this.setState({ citaProgramadaTerminal: date })
      : this.setState({ citaProgramadaTerminal: date });
  };

  onChangeCitaColocacionGps = (date) => {
    this.MovimientosObject.citaColocacionGps = timeToDateFill( new Date(date).getTime() );
    date != null
      ? this.setState({ citaColocacionGps: date })
      : this.setState({ citaColocacionGps: date });
  };

  crud_put() {
    this.props.Fclose();
    let copyToSave= {...this.MovimientosObject};
    if(copyToSave.variables)
      copyToSave.variables= JSON.stringify(copyToSave.variables);
    this.getfuntion(
      "put",
      copyToSave,
      "",
      "navieraMovimientos",
      "navieraMovimientos"
    );
    this.setState({ crudbutonEnable: false });
  }

  crud_post() {
    this.props.Fclose();
    let copyToSave= {...this.MovimientosObject};
    if(copyToSave.variables)
      copyToSave.variables= JSON.stringify(copyToSave.variables);
      this.getfuntion(
      "post",
      copyToSave,
      "",
      "navieraMovimientos",
      "navieraMovimientos"
    );
    this.setState({ crudbutonEnable: false });
  }

  changeValueCliente = (e) => {
    if (this.state.idPlataforma.value > 0 && e) {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.state.idPlataforma.value +
          "/pagina/0/total/100" +
          "/cliente?cliente=" +
          encodeURIComponent(e),
        "clientes"
      );
    }
  };
  changeValueClienteNaviero = (e) => {
    if (this.state.idPlataforma.value > 0 && e) {
      //e.key !== ' ' &&
      this.getfuntion(
        "get",
        "",
        "",
        "clientes_navieros/plataforma/" +
          this.state.idPlataforma.value +
          "/cliente/" +
          e +
          "/pagina/0/total/300",
        "clientesNavieros"
      );
    }
  };
  
  getVariableValor=(variables, nombre)=>{
    const nombreTemp= nombre.split(".");
    if( nombreTemp ){
      if(1 === nombreTemp.length)
        return variables[nombreTemp[0]];
      if(2 === nombreTemp.length)
        return variables[nombreTemp[0]]? variables[nombreTemp[0]][nombreTemp[1]]: null; 
        //return variables[nombreTemp[0]][nombreTemp[1]];
      if(3 === nombreTemp.length)
        return  variables[nombreTemp[0]] ?
                variables[nombreTemp[0][nombreTemp[1]]]
                  ? variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]
                :null
              :null;
        //return variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]];
    }
    return variables[nombre];
  }

  getForm=(tipo, longitud, nombre, etiqueta)=>{
    {/*

                      variables: {
                        ejecutivo: "",
                        referencia_facturacion: "",
                        referencia_chubb: "",
                        cita_colocacion: null,
                        cuenta_espejo:{
                          url:"",
                          usuario:"",
                          password: ""},
                        modalidad: false
                      } */}
    let valor= null;
    if( this.MovimientosObject.variables ){
      valor= this.getVariableValor(this.MovimientosObject.variables, nombre)
    }
    switch (tipo) {
      case "String":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="text"
                value={ this.MovimientosObject.variables && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "Date":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="date"
                value={ this.MovimientosObject.variables && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "Time":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="time"
                value={ this.MovimientosObject.variables && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "Number":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="number"
                value={ this.MovimientosObject.variables && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "Double":
        return (
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              {" "}
              <Form.Input
                name={nombre}
                type="number"
                value={ this.MovimientosObject.variables && valor ? valor : "" }//{this.state[nombre]}
                onChange={(e)=>{
                  if(e.target.value.length <= longitud){                    
                    const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.value });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e.target.value;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.value;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.value;
                  }
                }}
                maxlength="30"
              />
            </Form.Group>{" "}
          </Grid.Col>);
      case "DateTime":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                value={ this.MovimientosObject.variables && valor ? new Date(valor) :null }//{this.state[nombre]}
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                    this.setState({ [nombreTemp[nombreTemp.length-1]]: e });
                    if(1 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(2 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e ?timeToDateFill( new Date(e).getTime()): e;
                    if(3 === nombreTemp.length)
                      this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e ?timeToDateFill( new Date(e).getTime()): e;
                }}
              />
            </Form.Group>
          </Grid.Col>)
      case "Any":
        switch (nombre) {
          case "cuenta_espejo":
            let colsTemp= [] ;
            colsTemp.push(this.getForm("String", 400, "cuenta_espejo.url", "Url (Cuenta espejo)"));
            colsTemp.push(this.getForm("String", 200, "cuenta_espejo.usuario", "Usuario (Cuenta espejo)"));
            colsTemp.push(this.getForm("String", 200, "cuenta_espejo.password", "Password (Cuenta espejo)"));
            return colsTemp;
          default:
            break;
        }
        break;
      case "Boolean":
        return(
          <Grid.Col md={4}>
            <Form.Group label={etiqueta}>
              <Form.Switch
                name={nombre}
                checked={ valor }
                //label= { valor? "Full": "Sencillo" }
                onChange={(e)=>{
                  const nombreTemp= nombre.split(".");
                  //this.setState({ [nombreTemp[nombreTemp.length-1]]: e.target.checked });
                  if(1 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0]]= e.target.checked;
                  if(2 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0]][nombreTemp[1]]= e.target.checked;
                  if(3 === nombreTemp.length)
                    this.MovimientosObject.variables[nombreTemp[0][nombreTemp[1]][nombreTemp[2]]]= e.target.checked;
                }}
              />
            </Form.Group>
          </Grid.Col>)    
      default:
        break;
    }
  }
  render() {       
    const { selectedOption } = this.state;
    return (
      <div>
        {this.state.accion === "modificar" ? (
          <h1>{this.props.elementos.nombre}</h1>
        ) : null}        
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Grid.Row>
              <Grid.Col md={4} lg={2}>
                <Form.Group label="Id">
                  <Form.Input
                    name="id"
                    disabled
                    type="number"
                    value={this.state.id}
                  />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={4} lg={4}>
                <Form.Group label="Plataforma">
                  <Select
                    value={this.state.idPlataforma}
                    onChange={this.changePlataformas}
                    options={this.plataformas}
                    isDisabled={this.plataformas.length && this.plataformas.length === 1}
                  />
                </Form.Group>
              </Grid.Col>
              {this.state.idPlataforma.value > 0 ? (
                  <Grid.Col md={2}>
                    <Form.Switch
                      name="opcion"
                      label={this.state.opcion ? "naviera" : "cliente dir"}
                      checked={this.state.opcion}
                      onChange={this.changeOpcion}
                    />
                  </Grid.Col>
              ) : null}
              {this.state.idPlataforma.value > 0 ? (
                this.state.opcion ? (
                  <Grid.Col md={4}>
                    <Form.Group label="Naviera">
                      <Select
                        value={this.state.idNaviera}
                        onChange={this.changeNaviera}
                        options={this.state.navieras}
                        isLoading={
                          this.state.navieras.length == 0 ? true : false
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                ) : (
                  <Grid.Col md={4}>
                    <Form.Group label="Cliente Direct.">
                      <Select
                        value={this.state.idClienteNaviero}
                        onChange={this.changeClienteNaviero}
                        options={this.state.clientesNavieros}
                        isLoading={
                          this.state.clientesNavieros.length == 0
                            ? true
                            : false
                        }
                        onInputChange={this.changeValueClienteNaviero}
                      />
                    </Form.Group>
                  </Grid.Col>
                )
              ) : null}

              <Grid.Col md={4}>
                <Form.Group label="Cliente">
                  <Grid.Row>
                    <Grid.Col>
                      <Select
                        value={this.state.idCliente}
                        onChange={this.changeCliente}
                        options={this.state.clientes}
                        isLoading={
                          this.state.clientes.length == 0 ? true : false
                        }
                        onInputChange={this.changeValueCliente}
                      />
                      {this.state.idCliente.value > 0 ? (
                        <p>{this.state.idCliente.data.domicilio}</p>
                      ) : null}
                    </Grid.Col>
                    {this.state.idPlataforma.value > 0 &&
                    (this.isEnable("actualizarCliente") ||
                      this.isEnable("agregarCliente")) ? (
                      <Grid.Col md={2} lg={2}>
                        {this.isEnable("agregarCliente") ? (
                          <span className="float-right">
                            <ModalLay
                              tamanio="85%"
                              title={"Nuevo"}
                              name={
                                <span
                                  className="fa fa-plus ml-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    "Nuevo cliente(Antes de crear  valida que no exista)"
                                  }
                                ></span>
                              }
                              formulario={ClientesForm}
                              datosForm={{
                                accion: "nuevo",
                                plataforma: this.state.idPlataforma.data,
                                elementos: this.state.idCliente.data,
                                getfuntion: this.getfuntion,
                                stor: this.props.stor,
                              }}
                            />
                          </span>
                        ) : null}
                        {this.isEnable("actualizarCliente") ? (
                          <span className="float-right">
                            {this.state.idCliente.value > 0 ? (
                              <ModalLay
                                tamanio="85%"
                                title="Modificar"
                                name={
                                  <span
                                    className="fa fa-edit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                      "Modificar (" +
                                      this.state.idCliente.label +
                                      ")"
                                    }
                                  ></span>
                                }
                                formulario={ClientesForm}
                                datosForm={{
                                  accion: "modificar",
                                  plataforma: this.state.idPlataforma.data,
                                  elementos: this.state.idCliente.data,
                                  getfuntion: this.getfuntion,
                                  stor: this.props.stor,
                                }}
                              />
                            ) : null}
                          </span>
                        ) : null}
                      </Grid.Col>
                    ) : null}
                  </Grid.Row>
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Tipo de Operaciones">
                  <Select
                    value={this.state.idOperacionTipo}
                    onChange={this.changeOperacionesTipo}
                    options={this.state.operacionesTipo}
                    isLoading={
                      this.state.operacionesTipo.length == 0 ? true : false
                    }
                  />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group label="Tipo de Movimiento">
                  <Select
                    value={this.state.idMovimientoTipo}
                    onChange={this.changeMovimientoTipo}
                    options={this.state.movimientosTipo}
                    isLoading={
                      this.state.movimientosTipo.length == 0 ? true : false
                    }
                  />
                </Form.Group>
              </Grid.Col>

              <Grid.Col md={4}>
                <Form.Group 
                label="Corredores">
                  <Select
                    value={this.state.idCorredor}
                    onChange={this.changeCorredor}
                    options={this.state.corredores}
                    isLoading={this.state.corredores.length == 0 ? true : false}
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Localidad">
                  <Select
                    value={this.state.localidad}
                    onChange={this.changeLocalidad}
                    options={
                      this.state.idPlataforma.data && this.state.idPlataforma.data.zonaHoraria.toUpperCase().includes("MEX")?
                        this.localidades.filter(l=> !l.value || l.data.pais.toUpperCase().includes("MX"))
                      : this.localidades.filter(l=> !l.value || !l.data.pais.toUpperCase().includes("MX"))
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Transportistas">
                  <Grid.Row>
                    <Grid.Col>
                      <Select
                        value={this.state.idTransportista}
                        onChange={this.changeTransportista}
                        options={this.state.transportistas}
                        isLoading={
                          this.state.transportistas.length == 0 ? true : false
                        }
                      />
                    </Grid.Col>
                    {this.state.idPlataforma.value > 0 &&
                    (this.isEnable("actualizarTransportista") ||
                      this.isEnable("agregarTransportista")) ? (
                      <Grid.Col md={2} lg={2}>
                        {this.isEnable("agregarTransportista") ? (
                          <span className="float-right">
                            <ModalLay
                              tamanio="85%"
                              title={"Nuevo"}
                              name={
                                <span
                                  className="fa fa-plus ml-2"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    "Nuevo Transportista(Antes de crear  valida que no exista)"
                                  }
                                ></span>
                              }
                              formulario={TransportistasForm}
                              datosForm={{
                                accion: "nuevo",
                                plataforma: this.state.idPlataforma.data,
                                elementos: this.state.idTransportista.data,
                                getfuntion: this.getfuntionTrans,
                                stor: this.props.stor,
                              }}
                            />
                          </span>
                        ) : null}
                        {this.isEnable("actualizarTransportista") ? (
                          <span className="float-right">
                            {this.state.idTransportista.value > 0 ? (
                              <ModalLay
                                tamanio="85%"
                                title="Modificar"
                                name={
                                  <span
                                    className="fa fa-edit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                      "Modificar (" +
                                      this.state.idTransportista.label +
                                      ")"
                                    }
                                  ></span>
                                }
                                formulario={TransportistasForm}
                                datosForm={{
                                  accion: "modificar",
                                  plataforma: this.state.idPlataforma.data,
                                  elementos: this.state.idTransportista.data,
                                  getfuntion: this.getfuntionTrans,
                                  stor: this.props.stor,
                                }}
                              />
                            ) : null}
                          </span>
                        ) : null}
                      </Grid.Col>
                    ) : null}
                  </Grid.Row>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Tipo de carga">
                  <Select
                    value={this.state.contenedorTipo}
                    onChange={this.changeContenedorTipo}
                    options={this.state.contenedoresTipo}
                    isLoading={
                      this.state.contenedoresTipo.length == 0 ? true : false
                    }
                  />
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={4}>
                <Form.Group label="Tamaño">
                  <Select
                    value={this.state.contenedorSize}
                    onChange={this.changeContenedorSize}
                    options={this.contenedorSizes}
                  />
                </Form.Group>
              </Grid.Col>

              <Card title="Datos">
                <Grid.Col md={12} xl={12}>
                  <Grid.Row>
                    <Grid.Col md={4}>
                      <Form.Group label="Booking">
                        {" "}
                        <Form.Input
                          name="booking"
                          type="text"
                          value={this.state.booking}
                          onChange={this.changeBooking}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label={"Po(Wo)"}>
                        {" "}
                        <Form.Input
                          name="po"
                          type="text"
                          value={this.state.po}
                          onChange={this.changePo}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    {/* {(this.state.idPlataforma.value ==61
                      || this.state.idPlataforma.value==530
                      || this.state.idPlataforma.value==952
                      || this.state.idPlataforma.value==985
                      || this.state.idPlataforma.value==1117
                    ) &&this.state.idMovimientoTipo.value < 3?
                    <><br/>
                    <Grid.Col md={4}>
                      <Form.Group label="InicioRutaVacio">
                        {" "}                       
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.inicioRutaVacio}
                          onChange={this.changeInicioRutaVacio}
                        />
                   
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="IngresoCliente">
                        {" "}                        
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.ingresoCliente}
                          onChange={this.changeIngresoCliente}
                        />


                      </Form.Group>
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <Form.Group label="IngresoBodegaCarga">
                        {" "}                        
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.ingresoBodegaCarga}
                          onChange={this.changeIngresoBodegaCarga}
                        />
                      </Form.Group>
                    </Grid.Col>
                    </>
                    :console.log(this.state.idMovimientoTipo.value)}

                     */}

                    <Grid.Col md={4}>
                      <Form.Group label="Sello">
                        {" "}
                        <Form.Input
                          name="sello"
                          type="text"
                          value={this.state.sello}
                          onChange={this.changeSello}
                          maxlength="20"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Contenedor">
                        {" "}
                        <Form.Input
                          name="contenedor"
                          type="text"
                          value={this.state.contenedor}
                          onChange={this.changeContenedor}
                          maxlength="30"
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <Form.Group label="Entrega vacio">
                        <Form.Switch
                          name="entregaVacio"
                          label=""
                          value={this.state.entregaVacio}
                          checked={this.state.entregaVacio}
                          onChange={this.changeEntregaVacio}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <Form.Group  label={"Vip"} >
                        <Form.Switch
                          name="vip"
                          label=""
                          value={this.state.vip}
                          checked={this.state.vip}
                          onChange={this.changeVip}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={2}>
                      <Form.Group  label={"Sencillo/Full"} >
                        <Form.Switch
                          name="sencilloFull"
                          label=""
                          value={this.state.sencilloFull}
                          checked={this.state.sencilloFull}
                          onChange={this.changeSencilloFull}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Cita programada (Cliente)">
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.citaProgramada}
                          onChange={this.onChangeDate}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Cita Programada(Terminal)">
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.citaProgramadaTerminal}
                          onChange={this.onChangeCitaProgramadaTerminal}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Cita colocación GPS">
                        <DateTimePicker
                          format="y-MM-dd h:mm:ss a"
                          value={this.state.citaColocacionGps}
                          onChange={this.onChangeCitaColocacionGps}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={4}>
                      <Form.Group label="Destino">
                        {" "}
                        <Form.Input
                          name="destino"
                          type="text"
                          value={this.state.destino}
                          onChange={this.changeDestino}
                          maxlength="30"
                        />
                      </Form.Group>{" "}
                    </Grid.Col>
                    {[
                      10, 94, 100, 101, 126, 227, 325, 326, 327, 292, 61,
                    ].includes(this.state.idPlataforma.value) ? (
                      <Grid.Col md={4}>
                        <Form.Group label="Modalidad">
                          {" "}
                          <Form.Input
                            name="modalidad"
                            type="text"
                            value={this.state.modalidad}
                            onChange={this.changeModalidad}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                    ) : null}
                    {[530, 61].includes(this.state.idPlataforma.value) ? (
                      <Grid.Col md={4}>
                        <Form.Group label="Empacador">
                          {" "}
                          <Form.Input
                            name="empacador"
                            type="text"
                            value={this.state.empacador}
                            onChange={this.changeEmpacador}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                    ) : null}
                    <Grid.Col md={4}>
                      <Form.Group
                        label={
                          <Form.Label aside={this.state.nota.length + "/2000"}>
                            Nota
                          </Form.Label>
                        }
                      >
                        {" "}
                        <Form.Textarea
                          value={this.state.nota}
                          name="nota"
                          onChange={this.changeNota}
                          placeholder="Nota del movimiento.."
                          rows={6}
                        />{" "}
                      </Form.Group>{" "}
                    </Grid.Col>
                    {[886].includes(this.state.idPlataforma.value) ? (
                      <Grid.Col md={4}>
                        <Form.Group label="Cotizar Movimiento">
                          {" "}
                          <ModalLay                
                              tamanio={"90%"}
                              title="Cotizar Movimiento"
                              name={ 
                              <Button pill size="sm" outline color="primary">
                                  <div
                                  className={"fa fa-plus"}
                                  style={{ color: "blue" }}
                                  ></div>
                              </Button>
                              }
                              formulario={CotizacionMovimientoTransportistas}
                              datosForm={{                                                                                                                                               
                                idPlataforma: this.state.idPlataforma.value,
                                idMovimiento: this.state.id,                                                                                
                            }}
                          />
                        </Form.Group>{" "}
                      </Grid.Col>                                         
                    ) : null}
                    {[886].includes(this.state.idPlataforma.value) ? (
                        <Grid.Col md={4}>
                        <Form.Group label="Ejecutivo">
                          <Select
                            value={this.state.idResponsableMovimiento}
                            onChange={this.changeResponsableMovimiento}
                            options={this.state.responsablesMovimiento}
                            isLoading={
                              this.state.responsablesMovimiento.length == 0 ? true : false
                            }
                          />
                        </Form.Group>
                      </Grid.Col>   
                    ) : null }
                    {/*[886].includes(this.state.idPlataforma.value) ? (
                        <Grid.Col md={4}>
                        <Form.Group label="Tipo de servicio">
                          <Select
                             value={this.state.idMovimientoTipo}
                             onChange={this.changeMovimientoTipo}
                             options={this.state.movimientosTipo}
                             isLoading={
                               this.state.movimientosTipo.length == 0 ? true : false
                             }
                          />
                        </Form.Group>
                      </Grid.Col>   
                    ) : null */}                       
                    {this.state.variables.map((v, inex)=>
                      this.getForm(v.tipo, v.longitud, v.nombre, v.etiqueta)
                      )} 
                  </Grid.Row>
                  {[292, 61].includes(this.state.idPlataforma.value) ? (
                    <Grid.Row>
                      <Grid.Col md={4}>
                        <Form.Group label="Viaje">
                          {" "}
                          <Form.Input
                            name="viaje"
                            type="text"
                            value={this.state.viaje}
                            onChange={this.changeViaje}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="CP">
                          {" "}
                          <Form.Input
                            name="cp"
                            type="text"
                            value={this.state.cp}
                            onChange={this.changeCp}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Referencia">
                          {" "}
                          <Form.Input
                            name="referencia"
                            type="text"
                            value={this.state.referencia}
                            onChange={this.changeReferencia}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Eco">
                          {" "}
                          <Form.Input
                            name="eco"
                            type="text"
                            value={this.state.eco}
                            onChange={this.changeEco}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Estatus Actual">
                          {" "}
                          <Form.Input
                            name="estatusActual"
                            type="text"
                            value={this.state.estatusActual}
                            onChange={this.changeEstatusActual}
                            maxlength="30"
                          />
                        </Form.Group>{" "}
                      </Grid.Col>
                    </Grid.Row>
                  ) : null}
                  {[292, 61, 886].includes(this.state.idPlataforma.value) ? (
                    <Grid.Row>
                      {/*  Patio   */}
                      <Grid.Col md={4}>
                        <Form.Group label="Operador Nombre">
                          <Form.Input
                            name="opname"
                            type="text"
                            value={this.state.operadorNombre}
                            onChange={(e) => {
                              this.setState({ operadorNombre: e.target.value });
                              this.movimentoPatio.operadorNombre =
                                e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Operador Contacto">
                          <Form.Input
                            name="opCont"
                            type="text"
                            value={this.state.operadorContacto}
                            onChange={(e) => {
                              this.setState({
                                operadorContacto: e.target.value,
                              });
                              this.movimentoPatio.operadorContacto =
                                e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={4}>
                        <Form.Group label="Operador Licencia">
                          <Form.Input
                            name="opLic"
                            type="text"
                            value={this.state.operadorLicencia}
                            onChange={(e) => {
                              this.setState({
                                operadorLicencia: e.target.value,
                              });
                              this.movimentoPatio.operadorLicencia =
                                e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="Unidad Modelo">
                          <Form.Input
                            name="uniMod"
                            type="text"
                            value={this.state.tractorModelo}
                            onChange={(e) => {
                              this.setState({ tractorModelo: e.target.value });
                              this.movimentoPatio.tractorModelo =
                                e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="Unidad Marca">
                          <Form.Input
                            name="uniMarca"
                            type="text"
                            value={this.state.tractorMarca}
                            onChange={(e) => {
                              this.setState({ tractorMarca: e.target.value });
                              this.movimentoPatio.tractorMarca = e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="Unidad Color">
                          <Form.Input
                            name="uniColor"
                            type="text"
                            value={this.state.tractorColor}
                            onChange={(e) => {
                              this.setState({ tractorColor: e.target.value });
                              this.movimentoPatio.tractorColor = e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="Unidad Placa">
                          <Form.Input
                            name="uniPlaca"
                            type="text"
                            value={this.state.tractorPlaca}
                            onChange={(e) => {
                              this.setState({ tractorPlaca: e.target.value });
                              this.movimentoPatio.tractorPlaca = e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="TRACTO ECO">
                          <Form.Input
                            name="uniColor"
                            type="text"
                            value={this.state.economicot}
                            onChange={(e) => {
                              this.setState({ economicot: e.target.value });
                              this.movimentoPatio.economicot = e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={3}>
                        <Form.Group label="REMOLQUE ECO">
                          <Form.Input
                            name="uniColor"
                            type="text"
                            value={this.state.economicor}
                            onChange={(e) => {
                              this.setState({ economicor: e.target.value });
                              this.movimentoPatio.economicor = e.target.value;
                            }}
                            maxlength="30"
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  ) : null}
                </Grid.Col>
              </Card>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          {this.isEnable("actualizar") || this.isEnable("agregar") ? (
            <span class="badge">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={this.enableSave() || !this.state.crudbutonEnable}
                onClick={this.guardarMovimiento}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
export default MonitoreoNavieroForm;
