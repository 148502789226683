import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { timingSafeEqual } from "crypto";
import {
  Crud_Catalogos,
  Crud_error,
} from "../../../herramientas/Crud_Catalogos";
import Select from "react-select";
import { from } from "rxjs";

class Actualizacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //id
      id: this.props.elementos.id,
      //Campos
      nombreReglaEvento: "",
      nombreCampo: { value: 0, label: "Selecciona una opción" },
      campoMovimiento: "",
      nombreGrupo: "",
      //Foraneas
      idRutaEstatus: { value: 0, label: "Selecciona una opción" },
      idPlataforma: 0,
      incidencias: [],
      incidencia: { value: 0, label: "Elige una opción" },
      actualizacionAutomatica: [],
      plataformaCamposLogisticos: [],
      search: "",
      crear: true, // true nuevo, false editar
    };

    this.rutasEstatus = [];
    this.actualizacionAutomatica = [];
    this.camposLogisticos = [];
    this.campos = [];
    this.actualizacionAutomaticaObject = {
      id: 0,
      idPlataforma: this.props.elementos.id,
      idRutaEstatus: 0,
      nombreReglaEvento: "",
      nombreCampo: "",
      campoMovimiento: "",
      nombreGrupo: "",
      idIncidencia: null,
    };
    this.estatusRutas = [];
  }

  componentDidMount = () => {
    if (this.props.accion !== "nuevo") {
      this.getfuntion(
        "get",
        "",
        "",
        "rutasEstatus/plataforma/" + this.props.elementos.id,
        "rutasEstatus"
      );
      this.getfuntion("get", "", "", "camposLogisticos", "camposLogisticos");
      this.getfuntion(
        "get",
        "",
        "",
        "actualizacionAutomatica/plataforma/" + this.props.elementos.id,
        "actualizacionAutomatica"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "incidencias/idplataforma/" + this.props.elementos.id,
        "incidencias"
      );
      this.setState({ idPlataforma: this.props.elementos.id });
    }
  };

  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "loquequieras",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "actualizacionAutomatica") {
                this.actualizacionAutomatica = returnVal;
                this.setState({ actualizacionAutomatica: returnVal });
              }
              if (stateVar === "rutasEstatus") {
                let rutasEstatusTem = [];
                returnVal.map((inc, index) =>
                  rutasEstatusTem.push({ value: inc.id, label: inc.nombre })
                );
                this.rutasEstatus = rutasEstatusTem;
              }
              if (stateVar === "incidencias") {
                let incidenciasTem = [];
                returnVal.map((inc, index) =>
                  incidenciasTem.push({ value: inc.id, label: inc.nombre })
                );
                this.setState({ incidencias: incidenciasTem });
              }
              if (stateVar === "camposLogisticos") {
                this.camposLogisticos = returnVal;
                //primero cargamos despuies de cargar los campos logisticos, cargamos la configuracion de la plataforma
                this.getfuntion(
                  "get",
                  "",
                  "",
                  "plataformaCamposLogisticos/plataforma/" +
                    this.props.elementos.id,
                  "plataformaCamposLogisticos"
                );
              }
              if (stateVar === "plataformaCamposLogisticos") {
                this.plataformaCamposLogisticos = returnVal;
                this.setState({ plataformaCamposLogisticos: returnVal });
                if (returnVal.length > 0) {
                  returnVal.map((elemento, index) => {
                    this.camposLogisticos.map((campoLogismap, index) => {
                      if (elemento.idCamposLogisticos === campoLogismap.id) {
                        if (
                          this.props.elementos.plataformaTipo.nombre ===
                          "Terrestre"
                        ) {
                          if (campoLogismap.idMovimientoTipo === 3) {
                            this.campos.push({
                              value: campoLogismap.nombreCampo,
                              label: campoLogismap.nombre,
                            });
                          }
                        } else {
                          if (campoLogismap.idMovimientoTipo === 1) {
                            this.campos.push({
                              value: campoLogismap.nombreCampo,
                              label: campoLogismap.nombre + "(impo)",
                            });
                          }
                          if (campoLogismap.idMovimientoTipo === 2) {
                            this.campos.push({
                              value: campoLogismap.nombreCampo,
                              label: campoLogismap.nombre + "(expo)",
                            });
                          }
                        }
                      }
                    });
                  });
                }
              }
            }
            break;
          case "post":
            {
              if (stateVar === "actualizacionAutomatica") {
                this.limpiar();
                this.actualizacionAutomatica.push(returnVal);
                this.setState({
                  actualizacionAutomatica: this.actualizacionAutomatica,
                });
                this.actualizacionAutomaticaObject = {
                  id: 0,
                  idPlataforma: this.props.elementos.id,
                  idRutaEstatus: 0,
                  nombreReglaEvento: "",
                  nombreCampo: "",
                  campoMovimiento: "",
                  nombreGrupo: "",
                  idIncidencia: null,
                };
              }
            }
            break;
          case "put":
            {
              if (stateVar === "actualizacionAutomatica") {
                if (returnVal.id) {
                  this.limpiar();
                  for (let item of this.actualizacionAutomatica) {
                    if (item.id === returnVal.id) {
                      item = returnVal;
                      this.setState({
                        actualizacionAutomatica: this.actualizacionAutomatica,
                      });
                      break;
                    }
                  }
                }
              }
            }
            break;
          case "delete":
            {
              if (stateVar === "actualizacionAutomatica") {
                for (let i = 0; i < this.actualizacionAutomatica.length; i++) {
                  if (this.actualizacionAutomatica[i].id === id) {
                    this.actualizacionAutomatica.splice(i, 1);
                    this.setState({
                      actualizacionAutomatica: this.actualizacionAutomatica,
                    });
                    break;
                  }
                }
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  limpiar = () => {
    this.setState({ nombreReglaEvento: "" });
    this.setState({
      nombreCampo: { value: 0, label: "Selecciona una opción" },
    });
    this.setState({ campoMovimiento: "" });
    this.setState({
      idRutaEstatus: { value: 0, label: "Selecciona una opción" },
    });
    this.setState({ nombreGrupo: "" });
    this.setState({ incidencia: { value: 0, label: "Selecciona una opción" } });
    this.setState({ crear: true }); //se cambia a crear
    this.actualizacionAutomaticaObject = {
      id: 0,
      idPlataforma: this.props.elementos.id,
      idRutaEstatus: 0,
      nombreReglaEvento: "",
      nombreCampo: "",
      campoMovimiento: "",
      nombreGrupo: "",
      idIncidencia: null,
    };
  };
  disableSave = () => {
    if (
      this.state.nombreReglaEvento.length > 0 &&
      this.state.idRutaEstatus.value > 0 &&
      this.state.nombreGrupo.length > 0 &&
      this.state.nombreCampo.value
    ) {
      return false;
    } else {
      return true;
    }
  };

  changeNombreReglaEvento = (event) => {
    this.actualizacionAutomaticaObject.nombreReglaEvento = event.target.value;
    this.setState({ nombreReglaEvento: event.target.value });
  };

  changeNombreCampo = (event) => {
    this.actualizacionAutomaticaObject.nombreCampo = event.label;
    this.setState({ nombreCampo: event });

    //campoMovimiento
    this.actualizacionAutomaticaObject.campoMovimiento = event.value;
    this.setState({ campoMovimiento: event.value });
  };

  changeNombreGrupo = (event) => {
    this.actualizacionAutomaticaObject.nombreGrupo = event.target.value;
    this.setState({ nombreGrupo: event.target.value });
  };

  changerutasEstatus = (event) => {
    this.actualizacionAutomaticaObject.idRutaEstatus = event.value;
    this.setState({ idRutaEstatus: event });
  };
  changeIdIncidencia = (event) => {
    this.actualizacionAutomaticaObject.idIncidencia = event.value;
    this.setState({ incidencia: event });
  };

  searching = (event) => {
    this.setState({ search: event.target.value });
  };
  crud_delete = (clave) => {
    this.getfuntion(
      "delete",
      "",
      clave,
      "actualizacionAutomatica",
      "actualizacionAutomatica"
    );
  };
  crud_put = () => {
    this.getfuntion(
      "put",
      this.actualizacionAutomaticaObject,
      "",
      "actualizacionAutomatica",
      "actualizacionAutomatica"
    );
  };
  crud_post = () => {
    this.getfuntion(
      "post",
      this.actualizacionAutomaticaObject,
      "",
      "actualizacionAutomatica",
      "actualizacionAutomatica"
    );
  };

  guardar = () => {
    if (this.state.crear) {
      this.crud_post();
    } else {
      this.crud_put();
    }
    this.props.cerrarModal();
  };
  getIdRutaById = (id) => {
    if (id === null) {
      return "";
    }
    for (let item of this.rutasEstatus) {
      if (item.value === id) {
        return item.label;
      }
    }
    return id;
  };
  getIdIncidenciaById = (id) => {
    if (id === null) {
      return "";
    }
    for (let item of this.state.incidencias) {
      if (item.value === id) {
        return item.label;
      }
    }
    return id;
  };
  setEdit = (obj) => {
    this.actualizacionAutomaticaObject = obj;
    this.setState({ nombreReglaEvento: obj.nombreReglaEvento });
    for (let item of this.campos) {
      if (item.label === obj.nombreCampo) {
        this.setState({ nombreCampo: item });
      }
    }
    this.setState({ campoMovimiento: obj.campoMovimiento });
    for (let item of this.rutasEstatus) {
      if (item.value === obj.idRutaEstatus) {
        this.setState({ idRutaEstatus: item });
      }
    }
    this.setState({ nombreGrupo: obj.nombreGrupo });
    for (let item of this.state.incidencias) {
      if (item.value === obj.idIncidencia) {
        this.setState({ incidencia: item });
      }
    }
    this.setState({ crear: false });
  };

  render() {
    return (
      <div>
        <div>
          <Grid.Row>
            <Grid.Col>
              <Card
                title="Alertas"
                isCollapsible={false}
                isCollapsed={false}
                body={
                  <div>
                    <h2>{this.state.crear ? "Nuevo" : "Editar"}</h2>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        <Grid.Col sm={12} md={4} className="float-right">
                          <Form.Input
                            className="mb-3"
                            icon="search"
                            placeholder="Buscar Actualizacion..."
                            position="append"
                            onChange={this.searching}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col sm={12} md={4}>
                        {
                          //console.log( "251 this.state.actualizacionAutomatica" ),
                          //console.log( this.state.actualizacionAutomatica )
                        }
                        <Form.Group label="Plataforma">
                          <Form.Input
                            disabled={true}
                            type="number"
                            name="id"
                            feedback="id"
                            value={this.state.id}
                            placeholder="Id"
                            onChange={this.changeId}
                          />
                        </Form.Group>

                        <Form.Group label="Nombre Regla Evento (*)">
                          <Form.Input
                            type="text"
                            value={this.state.nombreReglaEvento}
                            onChange={this.changeNombreReglaEvento}
                          />
                        </Form.Group>

                        <Form.Group label="Nombre Campo (*)">
                          <Select
                            value={this.state.nombreCampo}
                            onChange={this.changeNombreCampo}
                            options={this.campos}
                          />
                        </Form.Group>

                        <Form.Group label="Campo Movimiento">
                          <Form.Input
                            disabled={true}
                            type="text"
                            value={this.state.campoMovimiento}
                          />
                        </Form.Group>

                        <Form.Group label="Estatus Ruta (*)">
                          <Select
                            value={this.state.idRutaEstatus}
                            onChange={this.changerutasEstatus}
                            options={this.rutasEstatus}
                          />
                        </Form.Group>

                        <Form.Group label="Nombre Grupo (*)">
                          <Form.Input
                            type="text"
                            value={this.state.nombreGrupo}
                            onChange={this.changeNombreGrupo}
                          />
                        </Form.Group>

                        <Form.Group label="Incidencia (crea incidencia y envia correo), no requerido">
                          <Select
                            value={this.state.incidencia}
                            onChange={this.changeIdIncidencia}
                            options={this.state.incidencias}
                          />
                        </Form.Group>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="danger"
                              onClick={() => this.limpiar()}
                            >
                              <span class="badge">
                                <Icon link={true} name="x" />
                              </span>
                            </Button>
                          </span>
                        </div>
                        <div className="float-right">
                          <span class="badge">
                            <Button
                              size="sm"
                              RootComponent="a"
                              color="primary"
                              disabled={this.disableSave()}
                              onClick={this.guardar}
                            >
                              <span class="badge">
                                <Icon link={true} name="save" />
                                Guardar
                              </span>
                            </Button>
                          </span>
                        </div>
                      </Grid.Col>

                      <Grid.Col sm={12} md={8}>
                        <Form.Group>
                          <Table
                            cards={true}
                            striped={true}
                            responsive={true}
                            className="table-vcenter"
                          >
                            <Table.Header>
                              <Table.ColHeader>ID</Table.ColHeader>
                              <Table.ColHeader>
                                Nombre Regla Evento
                              </Table.ColHeader>
                              <Table.ColHeader>Nombre Campo</Table.ColHeader>
                              <Table.ColHeader>Nombre Grupo</Table.ColHeader>
                              <Table.ColHeader>Estatus Ruta</Table.ColHeader>
                              <Table.ColHeader>Incidencia</Table.ColHeader>
                              <Table.ColHeader>Editar</Table.ColHeader>
                              <Table.ColHeader>Revover</Table.ColHeader>

                              {/**  <Table.ColHeader>Acción</Table.ColHeader> */}
                            </Table.Header>
                            <Table.Body>
                              {this.state.actualizacionAutomatica
                                .filter((word) =>
                                  word.nombreCampo
                                    .toUpperCase()
                                    .includes(this.state.search.toUpperCase())
                                )
                                .sort()
                                .map((elemento, index) => (
                                  <Table.Row>
                                    <Table.Col>{elemento["id"]}</Table.Col>
                                    <Table.Col>
                                      {elemento["nombreReglaEvento"]}
                                    </Table.Col>
                                    <Table.Col>
                                      {elemento["nombreCampo"]}
                                    </Table.Col>
                                    <Table.Col>
                                      {elemento["nombreGrupo"]}
                                    </Table.Col>
                                    <Table.Col>
                                      {this.getIdRutaById(
                                        elemento["idRutaEstatus"]
                                      )}
                                    </Table.Col>
                                    <Table.Col>
                                      {this.getIdIncidenciaById(
                                        elemento["idIncidencia"]
                                      )}
                                    </Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="primary"
                                        onClick={() => this.setEdit(elemento)}
                                      >
                                        <Icon name="edit" />
                                      </Button>
                                    </Table.Col>
                                    <Table.Col>
                                      <Button
                                        pill
                                        size="sm"
                                        outline
                                        color="primary"
                                        onClick={() =>
                                          this.crud_delete(elemento["id"])
                                        }
                                      >
                                        <Icon name="trash" />
                                      </Button>
                                    </Table.Col>
                                  </Table.Row>
                                ))}
                            </Table.Body>
                          </Table>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col sm={12} md={12}>
                        Valida todos tús datos antes de guardar ...
                      </Grid.Col>
                    </Grid.Row>
                  </div>
                }
              />
            </Grid.Col>
          </Grid.Row>
        </div>
      </div>
    );
  }
}

export default Actualizacion;
