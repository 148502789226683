import classes from "./Divider.module.css";
import React from "react";

const Divider = (props) => {
  return (
    <div className={classes.wrapper} style={{ marginTop: props.marginTop }}>
      <div
        className={`${classes.divt} ${props.dotOn ? classes.divdot : ""} ${
          classes.divider
        }`}
      />
    </div>
  );
};

export default Divider;
