import React, { useEffect, useState } from "react";
import { Crud_Catalogos, Crud_error } from '../../../../herramientas/Crud_Catalogos';
import { timeToDateFill } from "../../../../herramientas/DateFormat";
import { TablePageable } from "../../../../Helpers";
import { NotificationManager } from "react-notifications";
import { Form } from "tabler-react";
const usuario = JSON.parse(sessionStorage.getItem("usuario"));

let guardando= false;
const GpsEspejo =({ movimiento })=> {
  const [gpss, setGpss]= useState([]);
  const [gps, setGps]= useState({});
  const [movimientoPatio, setMovimientoPatio]= useState({});
  const [movimientoDeGps, setMovimientoDeGps]= useState({});
  const [movimientoPatioGps, setMovimientoPatioGps]= useState({});
  const [copiar, setCopiar]= useState(true);
  const [guardandoState, setGuardandoState]= useState(false);
  useEffect(() => {
		if(movimiento.id){
      getfuntion("get", "", "", "movimientoGps/para_espejo", "gpss");
      getfuntion("get", "", "", "movimientosPatio/idMovimiento/"+movimiento.id, "movimientoPatio");
    }
	}, []);
  useEffect(() => {
		if(gps.id){
      getfuntion("get", "", "", "navieraMovimientos/"+gps.idMovimiento, "movimientoDeGps");
      getfuntion("get", "", "", "movimientosPatio/idMovimiento/"+gps.idMovimiento, "movimientoPatioGps");
    }
	}, [gps]);
  const cancelar=()=>{
    setGps({});
    setMovimientoDeGps({});
    setMovimientoPatioGps({});
    guardando= false;
    setGuardandoState(false);
  }

  const guardar= async () =>{
    if(guardando || !gps.id)
      return false;
    ///////////////////////////////////////////////////////////// movimiento patio
    if(copiar){
      let movimientoPatioTemp= {...movimientoPatioGps};
      movimientoPatioTemp.id= movimientoPatio.id;
      movimientoPatioTemp.idMovimiento= movimiento.id;
      movimientoPatioTemp.esFull= movimientoPatio.esFull;
      movimientoPatioTemp.colocacionFecha= movimientoPatio.colocacionFecha;
      setMovimientoPatio(movimientoPatioTemp);
      await getfuntion(
        (movimientoPatioTemp.id? "put": "post"),
        movimientoPatioTemp,
        "",
        "movimientosPatio",
        "movimientoPatio"
      ).then((movGps) => {
        NotificationManager.success(
          "Se ha guardado información de unidad y operador",
          "Guardado"
        );
      }).catch((err) => {
        NotificationManager.error(
          "Error al guardar datos del tracto y del operador",
          "Error"
        );
        guardando = false;// disabledButon es false, se inicia evento guardar
        setGuardandoState(false);
        return;
      });
    }
    ///////////////////////////////////////////////////////////// asignar gps
    const movimientosGps= {...gps};
    let movimientosObj= {
      "idMovimiento": movimiento.id,
      "espejo": true,
      "gps": movimientosGps.gps,
      "gpsTipo": movimientosGps.gpsTipo
    };
    await getfuntion( "post", movimientosObj, "", "movimientoGps", "movimientoGp" ).then((movGps) => {
      NotificationManager.success(
        "Gps: " + movGps.gps.nombre + " agregado",
        "Guardado"
      );
    })
    .catch((err) => { console.log(err);
      NotificationManager.error(
        "Hubo un error al vincular el gps al movimiento",
        "Error"
      );
    });
    ///////////////////////////////////////////////////////////// bitacora de movimiento
    const usuariosLogObject = {
      idBitacoraMonitoreo: 5,
      idUsuario: usuario.id,
      idMovimiento: movimiento.id,
      accionFecha: timeToDateFill( new Date().getTime()),
      descripcion: "Asignacion de GPS: " + movimientosObj.gps.nombre + "(" + movimientosObj.gps.id + ")",
      idPlataformaTipo: 1,
    };
    await getfuntion( "post", usuariosLogObject, "", "usuariosLog", "usuariosLog" ).then((returnLog) => {
      NotificationManager.info(
        "Bitacora de movimiento guardada",
        "Guardado"
      );
    });
    ///////////////////////////////////////////////////////////// etiquetado gate
    if (movimientosObj.gps.id > 20) {
      const gpsGG = {
        gps: movimientosObj.gps.id,
        plataforma: movimiento.idPlataforma.id,
        estatus: 2,
        idMovimiento: movimiento.id,
      };
      await getfuntion(
        "post",
        gpsGG,
        "",
        "gps/asignar_gps",
        "asignar_gps"
      ).then((respuesta) => {
        NotificationManager.info(
          "Etiqueta de gate colocada",
          "Guardado"
        );
      })
      .catch((err) => {
      });
    }
    cancelar();
  }

 const getfuntion = (metodo = "get", obj = [], id = "", catalogo = "personas", stateVar = "personas", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "plataformas", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      /**
       * filtro de variables
       */
      switch (metodo) {
        case "get":
          if (stateVar === "gpss" && returnVal) {
            setGpss(returnVal);
          }
          if (stateVar === "movimientoPatio" && returnVal) {
            setMovimientoPatio(returnVal);
            if(returnVal.id){
              setCopiar(false);
            }
          }
          if (stateVar === "movimientoDeGps" && returnVal) {
            setMovimientoDeGps(returnVal);
          }
          if (stateVar === "movimientoPatioGps" && returnVal) {
            setMovimientoPatioGps(returnVal);
          }          
          break;
        case "post":
          if (stateVar === "movimientoGp") {
            return returnVal
          }
          break
        case "post":
          if (stateVar === "movimientoGp") {
            return returnVal
          }
          break
        default:
          break;
      }
    }).catch(err => { console.log(err) });
  }
  /*
    [
      {
        "id": 486015,
        "idMovimiento": 629782,
        "espejo": false,
        "gps": {
          "id": 3,
          "nombre": "NO CUMPLIO",
          "marca": "xxxxxxxx",
          "imei": null,
          "sim": "xxxxxxxxxxx",
          "latitud": 0,
          "longitud": 0,
          "altaFecha": null,
          "idGg": 3,
          "estatus": 0,
          "idCorredor": {
            "id": 1,
            "nombre": "PENDIENTE CORREDOR",
            "nombreCorto": "PENDIENTE CORREDOR",
            "latitud": 0,
            "longitud": 0,
            "estatus": null,
            "tipoCorredor": "F",
            "pais": null,
            "plataformaTipo": {
              "id": 1,
              "nombre": "Naviera"
            }
          },
          "bateriaExterna": null,
          "cajaNema": null,
          "altaFechaGg": null,
          "productividad": 570,
          "gpsHardware": {
            "id": 6,
            "nombre": "GL300 d3m0v3",
            "tecnologia": "2G",
            "fijo": false,
            "desechable": null
          },
          "gpsEstatus": {
            "id": 1,
            "nombre": "Disponible",
            "estatus": 1,
            "color": "#18bc00"
          }
        },
        "gpsTipo": {
          "id": 1,
          "nombre": "Principal"
        }
      }
    ]
  */
  return (
    
    <div className= "mt-1">
      <div>
      <h4>Detalle de Movimiento {movimiento.idPlataforma.nombre} id: {movimiento.id}</h4>{/**  GLW5540 */}
        <div>
          <ul className="list-group">
            <li className="list-group-item disabled">Contenedor: {movimiento.contenedor? movimiento.contenedor: ""}</li>
            <li className="list-group-item">Booking: {movimiento.booking? movimiento.booking: ""}</li>
            <li className="list-group-item">PO: {movimiento.po? movimiento.po: ""}</li>
            <li className="list-group-item">Cliente: {movimiento.idCliente? movimiento.idCliente.nombre: ""}</li>
            <li className="list-group-item">Transportista: {movimiento.idTransportista? movimiento.idTransportista.nombre: ""}</li>
            <li className="list-group-item"> Unidad(placa/marca/modelo/color): 
              { movimientoPatio.id? 
                (movimientoPatio.tractorPlaca
                  +"/"+movimientoPatio.tractorMarca
                  +"/"+movimientoPatio.tractorModelo
                  +"/"+movimientoPatio.tractorColor)
              : ""}
            </li>
            <li className="list-group-item">Economico tractor: {movimientoPatio.economicot? movimientoPatio.economicot: ""}</li>
            <li className="list-group-item">Economico remolque: {movimientoPatio.economicor? movimientoPatio.economicor: ""}</li>
            <li className="list-group-item">Operador: {movimientoPatio.operadorNombre? movimientoPatio.operadorNombre: ""}</li>
            <li className="list-group-item">Operador telefono:: {movimientoPatio.operadorContacto? movimientoPatio.operadorContacto: ""}</li>
          </ul>
        </div>
        <br/>
        <hr style={{height:"8px", color: "gray", backgroundColor: "gray"}}/>
        <br/>
        {!gps.id?
          <TablePageable
            titulo={ <h5> Seleccione un gps </h5> }
            lista= { gpss }
            columnas={
              [
                { columna: "idMovimiento", label: "Movimiento de referencia" },
                { columna: "espejo", label: "Espejo" },
                { columna: "gpsTipo.nombre", label: "Tipo de colocación" },
                { columna: "gps.nombre", label: "Gps" },/*
                { columna: "seleccionar", label: "Seleccionar",
                  //valor: (e)=> incidencias.filter(i => i.incidencia.id === e.id  ).length? true: false,
                  form: (e)=>
                    <i className="fa fa-check-square-o" style={{fontSize: "24px", color: "green"}}
                      onClick={()=> alert(e.gps.nombre)}></i>
                },*/
              ]
            }
            id= "id"
            selected={(e)=>{ setGps(e) }}
            //csvname= {"gps para espejo "}
            //resaltarFuncion={(e)=> gps.filter(g => i.incidencia.id === e.id  ).length ? true: false}
            resaltarColor={"rgba(58, 223, 149, 0.25)"}
            paginacion={true}
            elementosPorPagina={6}
          />
        :null}        
        {gps.id?
          <div>
            <h4>Detalle de gps
              {gps.gps? gps.gps.nombre: ""} 
              {" "+ (movimientoDeGps.id? movimientoDeGps.idPlataforma.nombre:"")+" "}
              id: {(movimientoDeGps.id? movimientoDeGps.id: "")}
            </h4>{/**  GLW5540 621970 */}
            <ul className="list-group">
              <li className="list-group-item disabled">Contenedor: {movimientoDeGps.contenedor? movimientoDeGps.contenedor: ""}</li>
              <li className="list-group-item">Booking: {movimientoDeGps.booking? movimientoDeGps.booking: ""}</li>
              <li className="list-group-item">PO: {movimientoDeGps.po? movimientoDeGps.po: ""}</li>
              <li className="list-group-item">Cliente: {movimientoDeGps.idCliente? movimientoDeGps.idCliente.nombre: ""}</li>
              <li className="list-group-item">Transportista: {movimientoDeGps.idTransportista? movimientoDeGps.idTransportista.nombre: ""}</li>
              <li className="list-group-item"> Unidad(placa/marca/modelo/color): 
                { movimientoPatioGps.id? 
                  (movimientoPatioGps.tractorPlaca
                    +"/"+movimientoPatioGps.tractorMarca
                    +"/"+movimientoPatioGps.tractorModelo
                    +"/"+movimientoPatioGps.tractorColor)
                : ""}
              </li>
              <li className="list-group-item">Economico tractor: {movimientoPatioGps.economicot? movimientoPatioGps.economicot: ""}</li>
              <li className="list-group-item">Economico remolque: {movimientoPatioGps.economicor? movimientoPatioGps.economicor: ""}</li>
              <li className="list-group-item">Operador: {movimientoPatioGps.operadorNombre? movimientoPatioGps.operadorNombre: ""}</li>
              <li className="list-group-item">Operador telefono:: {movimientoPatioGps.operadorContacto? movimientoPatioGps.operadorContacto: ""}</li>
            </ul>
            <span className="float-right">
              <span>
                <Form.Switch
                  name="enProtocolo"
                  type="Checkbox"
                  label={
                    copiar
                      ? "Intalar gps, Copiar información de unidad y operador"
                      : "Solo intalar gps"
                  }
                  checked={copiar}
                  onChange={(e)=> setCopiar(e.target.checked)}
                />
              </span>
              <span>
                <button type="button" className="btn btn-primary ml-3" disabled={ guardandoState}
                  onClick={()=> guardar()}>
                  <i className="fa fa-save"></i>
                </button>
              </span>
              <span className="ml-3">
                <button type="button" className="btn btn-danger"
                  onClick={()=> cancelar()}>
                    <i className="fa fa-close"></i>
                </button>
              </span>
            </span>
          </div>
          : null
        }        
      </div>
    </div>
          
  );
}
export default GpsEspejo;