import React,  { useState, useEffect } from 'react';
import { Button, Icon, Table } from 'tabler-react';
import { Crud_Catalogos } from '../herramientas/Crud_Catalogos';
import ModalLay from './ModalLay/ModalLay';
import DetalleAlerta from './DetalleAlerta';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));


export const ListarAlertasPorCategorias = ({idAlertaCategoria, atendida, plataformas, refrescar}) => {
//console.log(idAlertaCategoria);
//console.log(atendida);
//console.log(plataformas);

const [alertasPorIdCategoriaNavieras, setAlertasPorIdCategoriasNavieras] = useState([]);
const [alertasPorIdCategoriaTerrestres, setAlertasPorIdCategoriasTerrestres] = useState([]);

useEffect(() => {
    listar();  
}, [atendida, idAlertaCategoria, plataformas]);

const atenderAlertaNaviera = (idAlerta, idUsuario) => {       
   crud("put", {}, "", "navierasAlertas/atender/" + idAlerta + "/" + idUsuario, "atenderAlertaNaviera");
}

const atenderAlertaTerrestre = (idAlerta, idUsuario) => {       
    crud("put", {}, "", "terrestresAlertas/atender/" + idAlerta + "/" + idUsuario, "atenderAlertaTerrestre");    
 }

const listar = () =>{
    if(plataformas === "navieras"){
        if(atendida === false){
            crud("get", "", "", "alertas/listaNoAtendidasNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoria, "alertasNoAtendidas"); 
        }  else {
            crud("get", "", "", "alertas/listaAtendidasNavieras/" + currentUser[0].usuario.id + "/" + idAlertaCategoria, "alertasAtendidas"); 
        } 
    }
    if(plataformas === "terrestres"){
        if(atendida === false){
            crud("get", "", "", "terrestresAlertas/listaNoAtendidasTerrestres/" + currentUser[0].usuario.id + "/" + idAlertaCategoria, "alertasNoAtendidasTerrestres"); 
        }  else {
            crud("get", "", "", "terrestresAlertas/listaAtendidasTerrestres/" + currentUser[0].usuario.id + "/" + idAlertaCategoria, "alertasAtendidasTerrestres"); 
        } 
    }   
}

const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get": 
            if(stateVar === "alertasAtendidas") {
              let alertasAtendidasTemp = [];
              returnVal.map((i, index) => alertasAtendidasTemp.push({data:i}));
              //console.log("alertas atendidas: " + returnVal);
              setAlertasPorIdCategoriasNavieras(alertasAtendidasTemp);              
            }
            if(stateVar === "alertasNoAtendidas"){
              let alertasNoAtendidasTemp = [];
              if(returnVal){
                returnVal.map((i, index) => alertasNoAtendidasTemp.push({data:i}));
              }              
              //console.log("alertas no atendidas: " +returnVal);
              setAlertasPorIdCategoriasNavieras(alertasNoAtendidasTemp); 
            } 
            if(stateVar === "alertasAtendidasTerrestres") {
              let alertasAtendidasTerrestresTemp = [];
              returnVal.map((i, index) => alertasAtendidasTerrestresTemp.push({data:i}));
              //console.log("alertas atendidas: " + returnVal);
              setAlertasPorIdCategoriasTerrestres(alertasAtendidasTerrestresTemp);              
            }   
            if(stateVar === "alertasNoAtendidasTerrestres"){
              let alertasNoAtendidasTerrestresTemp = [];
              if(returnVal){
                returnVal.map((i, index) => alertasNoAtendidasTerrestresTemp.push({data:i}));
              }              
              //console.log("alertas no atendidas: " +returnVal);
              setAlertasPorIdCategoriasTerrestres(alertasNoAtendidasTerrestresTemp); 
            }                     
                break;  
            case "put":
                if(stateVar === "atenderAlertaNaviera") {
                    //console.log(returnVal);
                    listar();
                    refrescar();
                }
                if(stateVar === "atenderAlertaTerrestre") {
                    //console.log(returnVal);
                    listar();
                    refrescar();
                }
                break;          
            default:
                break;
        }
    }).catch(err => { console.log(err); });
} 


  return (
    <>  
        {
            plataformas === "navieras" ?
            alertasPorIdCategoriaNavieras.length > 0 ? ( 
                <Table cards={true} striped={true} responsive={true} className="table-vcenter">           
                <Table.Header>
                    <Table.ColHeader>PLATAFORMA</Table.ColHeader>
                    <Table.ColHeader>IDMOV</Table.ColHeader>                
                    <Table.ColHeader>ALERTAID</Table.ColHeader>
                    <Table.ColHeader>TRIGGERNAME</Table.ColHeader>
                    <Table.ColHeader>GEOCERCA</Table.ColHeader>
                    <Table.ColHeader>FECHA-GPSGATE</Table.ColHeader>
                    { atendida !== true ? 
                        <>
                            <Table.ColHeader>ATENDER</Table.ColHeader>
                            {/*<Table.ColHeader>INCIDENCIA</Table.ColHeader>*/}
                            <Table.ColHeader>DETALLE</Table.ColHeader>
                            <Table.ColHeader>UBICACION</Table.ColHeader>
                        </>
                    : null }
                    
                </Table.Header>                                 
                {alertasPorIdCategoriaNavieras.map((acn) =>  
                    <Table.Body>                  
                    <Table.Row>
                        <Table.Col>{acn.data.plataforma}</Table.Col>
                        <Table.Col>{acn.data.id_movimiento}</Table.Col>                    
                        <Table.Col>{acn.data.id_alerta}</Table.Col>
                        <Table.Col>{acn.data.trigger_name}</Table.Col>
                        <Table.Col>{acn.data.trigger_value}</Table.Col>
                        <Table.Col>{acn.data.fecha}</Table.Col>
                    {atendida !== true ?
                        <>
                            <Table.Col>
                                <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                    onClick={() => atenderAlertaNaviera(acn.data.id_alerta, currentUser[0].usuario.id)}                                
                                >
                                <div
                                    className={"fa fa-bell"}
                                    style={{ color: "blue" }}
                                ></div>
                                </Button>
                            </Table.Col>                           
                            <Table.Col>
                                <ModalLay
                                    tamanio={"60%"}
                                    title="Detalle de la Alerta"
                                    name={
                                    <Button pill size="sm" outline color="primary">
                                        <div
                                        className={"fa fa-bell"}
                                        style={{ color: "green" }}
                                        ></div>
                                    </Button>
                                    }
                                    formulario={DetalleAlerta}
                                    datosForm={{
                                        idAlerta: acn.data.id_alerta,
                                        esquema: "naviera"                                        
                                    }}
                                />                    
                            </Table.Col>
                            <Table.Col>                    
                                <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                    onClick={() =>
                                    window.open(
                                        "https://maps.google.com/?q=" +
                                        acn.data.latitud +
                                        "," +
                                        acn.data.longitud +
                                        "&t=m",
                                        "sharer",
                                        "width=900,height=600,scrollbars=NO"
                                    )
                                    }
                                >
                                    <div
                                    className={"fa fa-map-marker"}
                                    style={{ color: "green|" }}
                                    ></div>
                                </Button>                    
                            </Table.Col>
                        </>
                    : null
                    }
                        
                    </Table.Row> 
                    </Table.Body>                                      
                )}                         
            </Table>    
            ) : (
                <div></div>
            )
            : 
            alertasPorIdCategoriaTerrestres.length > 0 ? ( 
                <Table cards={true} striped={true} responsive={true} className="table-vcenter">           
                <Table.Header>
                    <Table.ColHeader>PLATAFORMA</Table.ColHeader>
                    <Table.ColHeader>IDMOV</Table.ColHeader>                
                    <Table.ColHeader>ALERTAID</Table.ColHeader>
                    <Table.ColHeader>TRIGGERNAME</Table.ColHeader>
                    <Table.ColHeader>GEOCERCA</Table.ColHeader>
                    <Table.ColHeader>FECHA-GPSGATE</Table.ColHeader>
                    { atendida !== true ? 
                        <>
                            <Table.ColHeader>ATENDER</Table.ColHeader>
                            {/*<Table.ColHeader>INCIDENCIA</Table.ColHeader>*/}
                            <Table.ColHeader>DETALLE</Table.ColHeader>
                            <Table.ColHeader>UBICACION</Table.ColHeader>
                        </>
                    : null }
                    
                </Table.Header>                                 
                {alertasPorIdCategoriaTerrestres.map((act) =>  
                    <Table.Body>                  
                    <Table.Row>
                        <Table.Col>{act.data.plataforma}</Table.Col>
                        <Table.Col>{act.data.id_movimiento}</Table.Col>                    
                        <Table.Col>{act.data.id_alerta}</Table.Col>
                        <Table.Col>{act.data.trigger_name}</Table.Col>
                        <Table.Col>{act.data.trigger_value}</Table.Col>
                        <Table.Col>{act.data.fecha}</Table.Col>
                    {atendida !== true ?
                        <>
                            <Table.Col>
                                <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                    onClick={() => atenderAlertaTerrestre(act.data.id_alerta, currentUser[0].usuario.id)} 
                                >
                                <div
                                    className={"fa fa-bell"}
                                    style={{ color: "blue" }}
                                ></div>
                                </Button>
                            </Table.Col>                            
                            <Table.Col>
                                <ModalLay
                                    tamanio={"60%"}
                                    title="Atender Alerta"
                                    name={
                                    <Button pill size="sm" outline color="primary">
                                        <div
                                        className={"fa fa-bell"}
                                        style={{ color: "green" }}
                                        ></div>
                                    </Button>
                                    }
                                    formulario={DetalleAlerta}
                                    datosForm={{
                                        idAlerta: act.data.id_alerta,
                                        esquema: "terrestre"                                        
                                    }}
                                />                    
                            </Table.Col>
                            <Table.Col>                    
                                <Button
                                    pill
                                    size="sm"
                                    outline
                                    color="primary"
                                    onClick={() =>
                                    window.open(
                                        "https://maps.google.com/?q=" +
                                        act.data.latitud +
                                        "," +
                                        act.data.longitud +
                                        "&t=m",
                                        "sharer",
                                        "width=900,height=600,scrollbars=NO"
                                    )
                                    }
                                >
                                    <div
                                    className={"fa fa-map-marker"}
                                    style={{ color: "green|" }}
                                    ></div>
                                </Button>                    
                            </Table.Col>
                        </>
                    : null
                    }
                        
                    </Table.Row> 
                    </Table.Body>                                      
                )}                         
            </Table>    
            ) : (
                <div></div>
            )         
        }            
    </>
  )
}

export default ListarAlertasPorCategorias;