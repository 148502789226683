import React from "react";

import classes from "./Smartphone.module.css";

const Tecla = React.memo(({ character, appendFunction, customStyles }) => {
  return (
    <button
      className={`btn ${classes.tecla}`}
      style={{
        fontSize: 25,
        width: "30%",
        //margin: 2,
        color: "white",
        fontWeight: "bold",
        ...customStyles,
        borderRadius: 0,
      }}
      onClick={appendFunction}
    >
      {character}
    </button>
  );
});

export default Tecla;
