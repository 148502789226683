import  React, { Component } from "react";
import {Grid, StampCard} from "tabler-react";
import SiteWrapper from "../../SiteWrapper.react";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
const estilo= {color: "red", size: 100, "border-width": "5px", padding: "0px", "margin-top": "0.5em"};
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var naviera=    false;
var terrestreNav= false;
var terrestre=  false;
//test
class AdminstracionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminstracion: [],
      plataforma:[],
      gps:[],
      alertas:[],
      catalogos:[],
      unidades:[]
    };
    this.funcionalidades= [];
  }
  componentWillMount =() =>{
    for(let item of currentUser){
      if( item.plataforma.plataformaTipo.nombre.toUpperCase() === "NAVIERA"){             naviera=true; }
      if( item.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE NAVIERA" ){  terrestreNav=true; }
      if( item.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE" ){          terrestre=true; }
    }
    this.getfuntion("get", "", "", "funcionalidades/Administracion/"+currentUser[0].perfil.id, "funcionalidades");
  }
  getfuntion = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":
          {
            if (stateVar === "funcionalidades") {
              let adminstracion= [];
              let plataforma=[];
              let gps=[];
              let alertas=[];
              let catalogos=[];
              let unidades=[];

              for(let item of returnVal){
                switch (item.nombre) {
                  case "Usuarios":          adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="users" header={<a href="/usuarios"><small>Usuarios</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Perfiles":          adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="eye" header={<a href="/perfiles"><small>Perfiles</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Clientes":
                    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                      <StampCard color="primary" icon="user" header={<a href="/clientes"><small>Clientes</small></a>} footer={""} />
                      </Grid.Col>);
                    break;
                  case "ClientesContactos":
                    adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                      <StampCard color="primary" icon="user" header={<a href="/clientesContactos"><small>ClientesContactos</small></a>} footer={""} />
                      </Grid.Col>);
                    break;
                  case "Coberturas":
                    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                        <StampCard color="primary" icon="map" header={<a href="/coberturas"><small>Coberturas</small></a>} footer={""} />
                      </Grid.Col>);
                  break;
                  case "Personas":          adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="users" header={<a href="/personas"><small>Personas</small></a>} footer={""} />
                                                          </Grid.Col>  );
                    break;
                  case "Privilegios": if(currentUser[0].perfil.nombre== "Administrador"){
                                            adminstracion.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="list" header={<a href="/privilegios"><small>Privilegios</small></a>}/>
                                                          </Grid.Col>);}
                    break;/** Plataformas */
                  case "Bitacora monitoreo": if(currentUser[0].perfil.nombre== "Administrador"){
                                            plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="edit" header={<a href="/bitacoramonitoreo"><small>Bitacora monitoreo</small></a>}/>
                                                                </Grid.Col>);}
                    break;
                  case "Acumuladores":      if(currentUser[0].perfil.nombre== "Administrador"){
                                            plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="battery" header={<a href="/acumuladores"><small>Acumuladores</small></a>}/>
                                                                </Grid.Col> );}
                    break;
                  case "Otd":               if(naviera || terrestreNav){
                                            plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="database" header={<a href="/otd"><small>Otd'S</small></a>} footer={""} />
                                                                </Grid.Col>);}
                    break;
                  case "Navieras":            if(naviera || terrestreNav){
                                              plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="anchor" header={<a href="/navieras"><small>Navieras</small></a>}/>
                                                                </Grid.Col> );
                                              plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="anchor" header={<a href="/clientes_navieros"><small>Clientes navieros</small></a>}/>
                                                                </Grid.Col> );
                                              }
                    break;
                  case "Plataformas":         plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="package" header={<a href="/plataformas"><small>Plataformas</small></a>}/>
                                                                </Grid.Col> );
                    break;
                  case "Transportistas":      plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="truck" header={<a href="/transportistas"><small>Transportistas</small></a>} footer={""} />
                                                                </Grid.Col>);
                    break;
                  case "Plataformas tipo":    plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="airplay" header={<a href="/plataformastipo"><small>Plataformas tipo</small></a>}/>
                                                                </Grid.Col> );
                    break;
                  case "Corredores":          plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="git-merge" header={<a href="/corredores"><small>Corredores</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Salas":               plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="airplay" header={<a href="/salasMonitoreo"><small>Salas</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Destinos":            if(terrestre){
                                              plataforma.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="truck" header={<a href="/destinos"><small>Destinos</small></a>} footer={""} />
                                                                </Grid.Col>);}
                    break;/** GPS */
                  case "GPS":                 if(naviera || terrestreNav){
                                              gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="crosshair" header={<a href="/gps"><small>GPS'S</small></a>} footer={""} />
                                                                </Grid.Col>);}
                    break;
                  case "GPS Fijos":           if(terrestre){
                                              gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="crosshair" header={<a href="/gpsFijos"><small>GPS Fijos</small></a>} footer={""} />
                                                                </Grid.Col>);}
                    break;
                  case "GPS estatus":         gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="flag" header={<a href="/gpsestatus"><small>GPS estatus</small></a>} footer={""} />
                                                                </Grid.Col>);
                    break;
                  case "GPS hardware":        gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="hard-drive" header={<a href="/gpshardware"><small>GPS hardware</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "GPS tipo colocación": gps.push(         <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="compass" header={<a href="/gpstipocolocacion"><small>GPS tipo colocación</small></a>}/>
                                                                </Grid.Col>);
                    break;/**Alertas */
                  case "Alertas Categorias":  alertas.push(     <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="users" header={<a href="/alertasCategorias"><small>Alertas Categorias</small></a>} footer={""} />
                                                                </Grid.Col>);
                    break;
                  case "Alertas Tipos":       alertas.push(     <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="users" header={<a href="/alertasTipos"><small>Alertas Tipos</small></a>} footer={""} />
                                                                </Grid.Col>);
                    break;/**Catalogos */
                  case "Bloques":             if(currentUser[0].perfil.nombre== "Administrador"){
                                              catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="bookmark" header={<a href="/bloques"><small>Bloques</small></a>} footer={""} />
                                                                </Grid.Col>);}
                    break;
                  case "Funcionalidades":     if(currentUser[0].perfil.nombre== "Administrador"){
                                              catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="sliders" header={<a href="/funcionalidades"><small>Funcionalidades</small></a>}/>
                                                                </Grid.Col>);}
                    break;
                  case "Categorias":          if(currentUser[0].perfil.nombre== "Administrador"){
                                              catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="slack" header={<a href="/categoria"><small>Categorias</small></a>}/>
                                                                </Grid.Col>);}
                    break;
                  case "Etiquetas":           if(currentUser[0].perfil.nombre== "Administrador"){
                                              catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="tag" header={<a href="/etiquetas"><small>Etiquetas</small></a>}/>
                                                                </Grid.Col>);}
                    break;
                  case "whatsapp_grupos":     catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="users" header={<a href="/whatsapp_grupos"><small>WhatsApp grupos</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Movimientos Tipo":    if(currentUser[0].perfil.nombre== "Administrador"){
                                              catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="chevrons-up" header={<a href="/movimientostipo"><small>Movimientos Tipo</small></a>}/>
                                                                </Grid.Col>);}
                    break;
                  case "Informes":            catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="user" header={<a href="/informes"><small>Informes</small></a>} footer={""} />
                                                                </Grid.Col>)
                    break;
                  case "Incidencias":         catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="zap" header={<a href="/incidencias"><small>Incidencias</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Incidencia tipos":    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="copy" header={<a href="/incidenciatipo"><small>Incidencia tipos</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Controladores de patios": catalogos.push( <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                    <StampCard color="primary" icon="map-pin" header={<a href="/patios"><small>Controladores de patios</small></a>}/>
                                                                  </Grid.Col>);
                    break;
                  case "Operadores":          catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="activity" header={<a href="/operadores"><small>Operadores</small></a>} footer={""} />
                                                                </Grid.Col>   );
                    break;
                  case "Rutas estatus":       catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="terminal" header={<a href="/rutasestatus"><small>Rutas estatus</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Operaciones Tipo":    catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="activity" header={<a href="/operacionestipo"><small>Operaciones Tipo</small></a>} footer={""} />
                                                                </Grid.Col>);
                    break;
                  case "Operaciones Estatus": catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                  <StampCard color="primary" icon="bar-chart-2" header={<a href="/operacionesEstatus"><small>Operaciones Estatus</small></a>}/>
                                                                </Grid.Col>);
                    break;
                  case "Service Failures": catalogos.push(   <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                <StampCard color="primary" icon="alert-octagon" header={<a href="/serviceFailures"><small>Service Failures</small></a>}/>
                                                              </Grid.Col>);
                    break;  
                    /**Unidades */
                  case "Unidades":                  if(terrestre){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidades"><small>Unidades</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;
                  case "unidades_navieras":
                                          unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                            <StampCard color="primary" icon="truck" header={<a href="/unidades_navieras"><small>Unidades Navieras</small></a>} footer={""} />
                                                          </Grid.Col>);
                    break;
                  case "Unidades Marcas":           if(terrestre || terrestreNav){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidadesMarcas"><small>Unidades Marcas</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;
                  case "Unidades Tipos":            if(terrestre || terrestreNav){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidadTipo"><small>Unidades Tipos</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;
                  case "Unidad Elemento":           if(terrestre){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidadElementos"><small>Unidad Elemento</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;
                  case "Unidad Estatus Operacion":  if(terrestre || terrestreNav){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidadEstatusOperacion"><small>Unidad Estatus Operacion</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;
                  case "Unidad Tipo Colocacion":    if(terrestre || terrestreNav){
                                                    unidades.push(  <Grid.Col sm={3} lg={3} className= "mt-1">
                                                                      <StampCard color="primary" icon="truck" header={<a href="/unidadTipoColocacion"><small>Unidad Tipo Colocacion</small></a>} footer={""} />
                                                                    </Grid.Col>);}
                    break;

                  default:
                    break;
                }
              }
              this.setState({adminstracion: adminstracion});
              this.setState({plataforma: plataforma});
              this.setState({gps: gps });
              this.setState({alertas: alertas });
              this.setState({catalogos: catalogos});
              this.setState({unidades: unidades});





            }
          }
          break;
        default:
          break;
      }
    }).catch(err => { console.log("Error desconocido ...."+ err); })
  }
  render(){
    return (
      <SiteWrapper>
        <div className="container mt-3">
          { this.state.adminstracion.length > 0 ? //**********************************************************Admistracion
            <div>
              <hr style={ estilo } />
              <h5>Administración</h5>
              <Grid.Row>  {this.state.adminstracion}  </Grid.Row>
            </div>
          : null}
          { this.state.plataforma.length > 0 ?//*************************************************************Plataforma
            <div>
              <hr style={ estilo } />
              <h5>Plataformas</h5>
              <Grid.Row>{ this.state.plataforma } </Grid.Row>
            </div>
          :null}
          { this.state.gps.length > 0 ?//********************************************************************GPS
            <div>
              <hr style={ estilo } />
              <h5>GPS</h5>
              <Grid.Row>{this.state.gps} </Grid.Row>
            </div>
          :null}
          { this.state.alertas.length > 0 ?//****************************************************************Alertas
            <div>
              <hr style={ estilo } />
              <h5>Alertas</h5>
              <Grid.Row>{this.state.alertas}</Grid.Row>
            </div>
          :null}
          { this.state.catalogos.length > 0 ?//**************************************************************Catalogos
            <div>
              <hr style={ estilo } />
              <h5>Catálogos</h5>
              <Grid.Row>{this.state.catalogos}
              </Grid.Row>
            </div>
          :null}
          { this.state.unidades.length > 0 ?//***************************************************************Unidades
            <div>
              <hr style={ estilo } />
              <h5>Unidades</h5>
              <Grid.Row>{this.state.unidades}</Grid.Row>
            </div>
          :null}
        </div>
      </SiteWrapper>
    );
  }
}
export default AdminstracionPage;
